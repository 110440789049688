import { InspectionReport } from '@domain/capacity-authorization/inspection-report';
import { Facility } from '@domain/facility';
import { Media } from '@domain/media';
import { UUID } from '@domain/lib';

export interface CapacityAuthorization {
  id: UUID;

  authorizationId: Media['id'] | undefined;

  pvId: Media['id'] | undefined;

  permanentCapacity: number;

  pasaCapacity: number | undefined;

  uhrCapacity: number | undefined;

  temporaryCapacity: number;

  daycareCapacity: number;

  supportCapacity: number;

  bedSocialCare: number | undefined;

  dates: { start: Date; end: Date };

  begin: Date | undefined;

  facilityId: Facility['id'];

  createdAt?: Date;

  updatedAt?: Date;

  facility(): Promise<Facility>;

  authorization(): Promise<Media | undefined>;

  pv(): Promise<Media | undefined>;

  inspectionReports(): Promise<InspectionReport[]>;

  authorizedCapacity(): number;
}

export type CapacityAuthorizationProperties = Omit<
  CapacityAuthorization,
  'facility' | 'authorization' | 'pv' | 'inspectionReports' | 'authorizedCapacity'
>;
