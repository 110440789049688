import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EPRD } from '@domain/eprd-errd';

@Component({
  selector: 'ehp-eprd-name',
  templateUrl: './eprd-name.component.html',
  styleUrls: ['./eprd-name.component.scss'],
})
export class EprdNameComponent implements OnChanges {
  @Input() public eprd!: EPRD;

  public name: string = 'E.P.R.D.';

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['eprd']) {
      this.updateName();
    }
  }

  private updateName(): void {
    if (!this.eprd) {
      this.name = 'E.P.R.D.';
    } else {
      this.name = `E.P.R.D. ${this.eprd.accountingYear}`;
    }
  }
}
