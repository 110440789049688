import {Serializable} from "@application/framework/lib";
import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Year} from "@domain/lib";


export interface HealthFundingDocumentParams {
  accountingYear?: Year;
}


export interface SerializedHealthFundingSearchParams extends SerializedDocumentParams<HealthFundingDocumentParams> {

}

export abstract class HealthFundingSearchParams extends DocumentListParams implements Serializable<SerializedHealthFundingSearchParams> {


  public abstract setAccountingYear(year: Year | undefined): this;
  
  public abstract override serialize(): SerializedHealthFundingSearchParams;

  public abstract override deserialize(params?: SerializedHealthFundingSearchParams): this;

}


