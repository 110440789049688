import { DateFormatter } from '@application/framework/date/date.formatter';
import { format as dateFormat, intlFormat } from 'date-fns';
import { fr } from 'date-fns/locale';
import { DateString } from '@application/framework/date';

export class DateFnsFormatter implements DateFormatter {
  private locale: Map<string, Locale> = new Map([
    ['fr', fr],
    ['fr-FR', fr],
  ]);

  constructor(private readonly configuration: { defaultLocalCode: string }) {}

  public format(date: Date, format: string | Record<string, string>, locale?: string): DateString {
    locale = locale ?? this.configuration.defaultLocalCode;

    if (typeof format === 'string') {
      return dateFormat(date, format, { locale: locale ? this.locale.get(locale) : undefined });
    } else {
      return intlFormat(date, format, { locale: locale ? this.locale.get(locale)?.code : undefined });
    }
  }

  public toUTCIsoString(date: Date): DateString {
    const offset = -1 * date.getTimezoneOffset();

    const utc = new Date(date);
    utc.setMinutes(utc.getMinutes() + offset);
    return utc.toISOString();
  }
}
