import { Component, OnDestroy, ViewContainerRef } from '@angular/core';
import { PageTitleAccessor } from '@easyhpad-ui/app/framework/title/page-title.accessor';
import { DashboardPageComponent } from '@easyhpad-ui/app/bundles/dashboard/modules/public/pages/dashboard-page/dashboard-page.component';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { selectIsFacilityOnboarding } from '@easyhpad-ui/app/store/global.selector';
import { FacilityOnboardingPageComponent } from '@easyhpad-ui/app/bundles/facility/modules/public/pages';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnDestroy {
  private current: 'dashboard' | 'onboarding';

  private destroy$ = new Subject<void>();

  constructor(
    private readonly title: PageTitleAccessor,
    private readonly viewContainer: ViewContainerRef,
    private readonly store: Store<AppState>,
  ) {
    this.viewContainer.createComponent(DashboardPageComponent);
    this.current = 'dashboard';

    this.store
      .select(selectIsFacilityOnboarding)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOnboarding => (isOnboarding ? this.setOnboarding() : this.setDashboard()));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Replace content by the main dashboard page.
   * @private
   */
  private setDashboard(): void {
    if (this.current === 'dashboard') {
      return;
    }
    this.title.set('Tableau de bord');
    this.viewContainer.clear();
    this.viewContainer.createComponent(DashboardPageComponent);
    this.current = 'dashboard';
  }

  /**
   * Replace content by the Facility onboarding page.
   * @private
   */
  private setOnboarding(): void {
    if (this.current === 'onboarding') {
      return;
    }
    this.title.set("Configuration de l'établissement");
    this.viewContainer.clear();
    this.viewContainer.createComponent(FacilityOnboardingPageComponent);
    this.current = 'onboarding';
  }
}
