import { Injectable } from '@angular/core';
import { AsyncReadOnlyCollection } from '@application/framework/store/collection';
import { DashboardWidgetContentReference } from '@easyhpad-ui/app/bundles/dashboard/interfaces/dashboard-widget-content.reference';
import {
  WidgetAuthorizedCapacitiesComponent,
  WidgetEPRDERRDComponent,
  WidgetFundingComponent,
  WidgetValidateIndicatorsComponent,
} from '@easyhpad-ui/app/bundles/dashboard/modules/widgets/components';
import { DashboardConfiguration } from '@easyhpad-ui/app/bundles/dashboard/interfaces/dashboard-configuration.interface';
import { WidgetCPOMComponent } from '@easyhpad-ui/app/bundles/cpom/components/widget-cpom/widget-cpom.component';
import { WidgetOccupationRateComponent } from '@easyhpad-ui/app/bundles/dashboard/modules/widgets/components/widget-occupation-rate/widget-occupation-rate.component';

@Injectable({
  providedIn: 'root',
})
export class MainDashboardWidgetCollection implements AsyncReadOnlyCollection<DashboardWidgetContentReference<any>> {
  public static readonly config: DashboardConfiguration = {
    columns: 12,
  };

  private readonly defaultSizeAndPositions: Pick<DashboardWidgetContentReference, 'size' | 'position'> = {
    size: { columns: 6, rows: 1 },
  };

  private readonly widgets: Map<string, DashboardWidgetContentReference> = new Map();

  constructor() {
    this.widgets.set(WidgetAuthorizedCapacitiesComponent.name, {
      ...this.defaultSizeAndPositions,
      widget: {
        content: WidgetAuthorizedCapacitiesComponent,
        inputs: {},
      },
      size: { columns: 7, rows: 1 },
      position: { x: 0, y: 0 },
    });

    this.widgets.set(WidgetFundingComponent.name, {
      widget: {
        content: WidgetFundingComponent,
        inputs: {},
      },
      size: { columns: 5, rows: 3 },
      position: { x: 7, y: 0 },
    });

    this.widgets.set(WidgetValidateIndicatorsComponent.name, {
      widget: {
        content: WidgetValidateIndicatorsComponent,
        inputs: {},
      },
      size: { columns: 5, rows: 1 },
      position: { x: 0, y: 1 },
    });

    this.widgets.set(WidgetOccupationRateComponent.name, {
      widget: {
        content: WidgetOccupationRateComponent,
        inputs: {},
      },
      size: { columns: 2, rows: 1 },
      position: { x: 5, y: 1 },
    });

    this.widgets.set(WidgetCPOMComponent.name, {
      widget: {
        content: WidgetCPOMComponent,
        inputs: {},
      },
      size: { columns: 2, rows: 1 },
      position: { x: 0, y: 2 },
    });

    this.widgets.set(WidgetEPRDERRDComponent.name, {
      widget: {
        content: WidgetEPRDERRDComponent,
        inputs: {},
      },
      size: { columns: 5, rows: 1 },
      position: { x: 2, y: 2 },
    });

    /*this.widgets.set(WidgetCalculatorComponent.name, {
      widget: {
        content: WidgetCalculatorComponent,
        inputs: {},
      },
      size: {columns: 5, rows: 2},
      position: {x: 7, y: 1}
    });*/
  }

  public list(): Promise<DashboardWidgetContentReference<any>[]> {
    return Promise.resolve(Array.from(this.widgets.values()));
  }

  public async get(reference: string): Promise<DashboardWidgetContentReference<any> | undefined> {
    return this.widgets.get(reference);
  }

  public has(reference: string): Promise<boolean> {
    return Promise.resolve(this.widgets.has(reference));
  }
}
