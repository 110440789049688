import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isPositiveFundingAmount} from "@domain/funding";


@ValidatorConstraint({name: 'IsPositiveFundingAmount', async: false})
export class IsPositiveFundingAmountConstraint implements ValidatorConstraintInterface {

  public validate(amount: any, args?: ValidationArguments): boolean {
    return isPositiveFundingAmount(amount);
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'amount is not a positive funding amount.';
  }
}

export function IsPositiveFundingAmount(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsPositiveFundingAmountConstraint
    });
  };
}
