import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export interface ERRDDocumentParams {
}


export interface SerializedERRDSearchParams extends SerializedDocumentParams<ERRDDocumentParams> {

}

export abstract class ERRDSearchParams extends DocumentListParams implements Serializable<SerializedERRDSearchParams> {


  public abstract override serialize(): SerializedERRDSearchParams;

  public abstract override deserialize(params?: SerializedERRDSearchParams): this;

}



