import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {CustomerFormComponent, CustomerSelectComponent} from './components';
import {FormModule} from "@easyhpad-ui/app/library/form/form.module";
import {ReactiveFormsModule} from "@angular/forms";
import {
  CustomerFormBuilder
} from "@easyhpad-ui/app/bundles/customer/modules/form/services/customer-form-builder/customer-form-builder";
import {AddressModule} from "@easyhpad-ui/app/bundles/address/address.module";
import {PhoneModule} from "@easyhpad-ui/app/bundles/phone/phone.module";


@NgModule({
  imports: [
    CommonModule,
    AutocompleteLibModule,
    TranslationModule,
    FormModule,
    ReactiveFormsModule,
    AddressModule,
    PhoneModule,
  ],
  declarations: [
    CustomerSelectComponent,
    CustomerFormComponent
  ],
  exports: [
    CustomerSelectComponent,
    CustomerFormComponent
  ],
  providers: [
    CustomerFormBuilder
  ]
})
export class CustomerFormModule {
}
