import { AuthorizationChecker } from '@application/bundles/authorization';
import { ForbiddenError } from '@application/bundles/authorization/error';
import { CreateNewUserCommand } from '@application/bundles/user/commands/create-new-user.command';
import { UserCreationFailEvent } from '@application/bundles/user/events/user-creation-fail.event';
import { UserCreationSuccessEvent } from '@application/bundles/user/events/user-creation-success.event';
import { USER_FEATURE } from '@application/bundles/user/user.token';
import { CommandBus, HandleCommand } from '@application/framework/command-query';
import { CommandHandler } from '@application/framework/command-query/handler.interface';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { Translator } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { CreateUser, User, UserRepository } from '@domain/user';

@HandleCommand({
  command: CreateNewUserCommand,
})
export class CreateUserCommandHandler implements CommandHandler<CreateNewUserCommand, User> {
  @ProvideLogger() private readonly logger!: Logger;

  constructor(
    private repository: UserRepository,
    private validator: ObjectValidator,
    private sanitizers: SanitizerLibrary,
    private authorization: AuthorizationChecker,
    private commandBus: CommandBus,
    private eventDispatcher: EventDispatcher,
    private translator: Translator,
  ) {}

  public async handle(command: CreateNewUserCommand): Promise<User> {
    const { dto } = command;

    try {
      await this.checkAccess();

      const user = await this.sanitize(dto)
        .then(dto => this.validate(dto))
        .then(dto => this.repository.create(dto));

      this.eventDispatcher.dispatch(new UserCreationSuccessEvent(user));

      return user;
    } catch (e) {
      await this.catchError(e);
      return Promise.reject();
    }
  }

  private async sanitize(dto: CreateUser): Promise<CreateUser> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.error('User creation : sanitizer fail', e);
      throw new SanitizationFailError();
    }

    return dto;
  }

  private async validate(dto: CreateUser): Promise<CreateUser> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.error('User creation : validation fail', e);
      throw new ValidationError();
    }

    return dto;
  }

  private async checkAccess(): Promise<void> {
    if (!(await this.authorization.isGranted([{ feature: USER_FEATURE, value: 'create' }]))) {
      this.logger.error('User creation : unauthorized');
      throw new ForbiddenError();
    }
  }

  private async catchError(e: any) {
    const error = new ErrorNormalizer().normalize(e);
    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = await this.translator.translate('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = await this.translator.translate("Vous n'êtes pas autorisé à créer un nouvel utilisateur.");
    }

    this.eventDispatcher.dispatch(new UserCreationFailEvent(message, error.stack, error));
  }
}
