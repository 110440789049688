import { PhoneFromStringTransformer } from '@application/bundles/phone/transformer/phone-from-string.transformer';
import { PhoneNumber } from '@domain/phone/phone.interface';
import { PhoneNumberImpl } from '@implementations/bundles/phone/implementations/phone-number';

export class GooglePhoneFromStringTransformer implements PhoneFromStringTransformer {
  public reverseTransform(phone: PhoneNumber): string {
    return phone.number;
  }

  public transform(value: string, region?: string): PhoneNumber {
    return new PhoneNumberImpl(value, region);
  }
}
