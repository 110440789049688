import { Component, HostBinding, Input } from '@angular/core';
import { MEDIA_FILE_TYPES } from '@domain/media';

@Component({
  selector: 'ehp-media-icon',
  templateUrl: './media-icon.component.html',
  styleUrls: ['./media-icon.component.scss'],
})
export class MediaIconComponent {
  @Input() public type: MEDIA_FILE_TYPES | undefined;

  @HostBinding('class')
  public get classes(): string {
    const classes = ['icon', 'media-icon'];

    if (this.type) {
      classes.push(`type-${this.type.toString()}`);
    }

    return classes.join(' ');
  }
}
