import { DynamicFormElementType, DynamicFormNode } from '@easyhpad-ui/app/library/form/contracts/dynamic-form';
import { WithControlValidators } from '@easyhpad-ui/app/library/form/contracts/dynamic-field-attributes';
import { TranslatableString } from '@application/framework/translation';

export interface DynamicPanelGroup<C extends { [k in keyof C]: DynamicFormElementType<any> }, N extends string = string>
  extends DynamicFormNode<'panel'>,
    WithControlValidators {
  /**
   * Label display in progress bar
   */
  label: string | TranslatableString;

  /**
   * A machine-readable step name.
   */
  name: N;

  /**
   * The field collection
   */
  fields: Record<keyof C, DynamicFormElementType<any>>;
}

export interface DynamicPanelsGroup<P extends string | number | symbol = string> extends DynamicFormNode<'panels'> {
  panels: Record<P, DynamicPanelGroup<any>>;
  submitLabel?: string | TranslatableString;
}
