export enum EHPAD_DEMAIN_TYPE {
  OTHER = "other",
  EHPAD_OUTSIDE = "ehpad_outside",
  TEMPORARY_LODGING = "temporary_lodging",
  THIRD_PLACE = "third_place",
  TERRITORIAL_RESOURCE_CENTER = "territorial resource center",
}


export const EhpadDemainTypes = new Set(Object.values(EHPAD_DEMAIN_TYPE));


export interface EhpadDemainType {

  type: EHPAD_DEMAIN_TYPE,

  label: string;
}

