<ng-container *ngIf="formArray">

  <ehp-form-array-errors [formArray]="formArray" [labels]="labels"></ehp-form-array-errors>

  <table [ngClass]="{'empty': !rows || rows.length === 0}" class="editable-table">
    <thead>
    <tr>
      <th></th>
      <th class="required">{{'Nom' | ehp_translate}}</th>
      <th class="required">{{'Montant' | ehp_translate}}</th>
      <th>{{'Commentaire' | ehp_translate}}</th>
      <th>{{'Action' | ehp_translate}}</th>
    </tr>
    </thead>
    <tbody *ngIf="rows && rows.length > 0; else emptyRows">
    <tr *ngFor="let row of rows; let index = index" [formGroup]="row">

      <td class="index">{{index + 1}}</td>

      <td>
        <input class="form-control" ehp-form-control formControlName="name" type="text">
      </td>

      <td>
        <p-inputNumber [locale]="locale" [maxFractionDigits]="2" [minFractionDigits]="2" [min]="0" [step]="0.01" class="form-control"
                       ehp-form-control formControlName="amount" mode="decimal"></p-inputNumber>
      </td>

      <td>
        <textarea class="form-control" ehp-form-control formControlName="comment"></textarea>
      </td>

      <td class="actions">
        <button (click)="removeRow(index)" class="is-icon small icon-delete delete" type="button">
          <span class="label">{{'Supprimer la ligne' | ehp_translate}}</span>
        </button>
      </td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td></td>
      <td class="amount-label">{{'Total' | ehp_translate}}:</td>
      <td>{{amount | currency}}</td>
      <td></td>
      <td></td>
    </tr>
    </tfoot>
  </table>

  <button (click)="addRow()" class="button secondary outline"
          type="button">{{'Ajouter une ligne' | ehp_translate}}</button>
</ng-container>

<ng-template #emptyRows>

  <ehp-empty-block class="empty-rows">
    {{'Aucune ligne pour le moment' | ehp_translate}}.
    <button (click)="addRow()" class="link" type="button">{{'Ajouter une ligne' | ehp_translate}}</button>
  </ehp-empty-block>

</ng-template>


