import {AgnosticSanitizer} from "@application/framework/sanitizer/implementations/sanitizer.implementation";
import {SanitizerLibrary} from "@application/framework/sanitizer/sanitizer";
import {from, Observable} from "rxjs";

export class ReactiveSanitizer extends AgnosticSanitizer implements SanitizerLibrary {


  public sanitize$<E, R = E>(object: E): Observable<R> {
    return from(this.sanitize<E, R>(object));
  }


}
