import {PhoneValidator} from "@application/bundles/phone/validator/phone.validator";
import {PhoneNumber} from "@domain/phone/phone.interface";
import {ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface} from "class-validator";

@ValidatorConstraint({name: 'isValidPhoneNumber', async: true})
export class IsValidPhoneNumber implements ValidatorConstraintInterface {
  
  public constructor(private validator: PhoneValidator) {
  }

  public async validate(value: PhoneNumber, validationArguments?: ValidationArguments): Promise<boolean> {
    return this.validator.isValidPhone(value);
  }

  public defaultMessage(validationArguments?: ValidationArguments): string {
    return 'This phone must be a valid phone number';
  }

}
