import {WindowStorageStoreAdapter} from "./abstract-window-storage.store.adapter";

export class LocalStorageStoreAdapter extends WindowStorageStoreAdapter {

  protected store: Storage;

  constructor(prefix = '') {
    super(prefix);
    this.store = this.storageAvailable('localStorage') ? window.localStorage : new Storage();
  }


}
