import {InvalidHttpAuthorizationTokenError} from "@application/bundles/authentification/errors";
import {CommandBus} from "@application/framework/command-query";
import {HttpAuthorizationToken} from "@application/framework/http";
import {
  AutoRenewHttpBearerAuthorizationToken
} from "@implementations/bundles/backend/easyhpad/implementations/autorenew-http-bearer-authorization.token";
import {
  EasyHpadBarerAuthorizationToken
} from "@implementations/bundles/backend/easyhpad/implementations/bearer-authorization-token";
import {
  HttpAuthorizationResponse
} from "@implementations/bundles/backend/easyhpad/interfaces/http-authorization-response.interface";
import {EasyHpadBearerHttpValidator} from "@implementations/bundles/backend/easyhpad/validators/bearer-http.validator";

export class EasyHpadBearerHttpTokenFactory {

  constructor(private commandBus: CommandBus) {
  }


  public createFromHttpAuthorizationResponse(response: HttpAuthorizationResponse): HttpAuthorizationToken<string> {

    if (!EasyHpadBearerHttpValidator.isMaybeValid(response.access_token)) {
      throw new InvalidHttpAuthorizationTokenError('Invalid token : access_token must be a valid string');
    }

    if (typeof response.expires_in === 'number') {
      const token = new AutoRenewHttpBearerAuthorizationToken(this.commandBus);
      token.value = response.access_token;
      token.setExpiresIn(response.expires_in);
      return token;
    } else {
      return new EasyHpadBarerAuthorizationToken(response.access_token);
    }

  }
}
