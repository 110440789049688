import { CreatePMPDto, PMPListParams, PMPRepository, UpdatePMPDto } from '@application/bundles/pmp';
import { serialize, unserialize } from '@application/framework/backend';
import { Service } from '@application/framework/di';
import { Facility } from '@domain/facility';
import { PMP } from '@domain/pmp';
import { EasyPMP } from '@implementations/bundles/backend/easyhpad';
import { DocumentRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { CreatePMPDtoImpl, PMPImpl, UpdatePMPDtoImpl } from '@implementations/bundles/pmp';

@Service()
export class EasyPMPRepository
  extends DocumentRepository<PMPListParams, any, PMP, EasyPMP, PMP['id'], CreatePMPDto, UpdatePMPDto>
  implements PMPRepository
{
  constructor() {
    super('pmp', {
      from: input => serialize<EasyPMP>(unserialize(PMPImpl, input)),
      to: output => unserialize(PMPImpl, output),
      create: (input: CreatePMPDto) => serialize<any>(unserialize(CreatePMPDtoImpl, input)),
      update: (input: UpdatePMPDto) => serialize<any>(unserialize(UpdatePMPDtoImpl, input)),
    });
  }

  public latest(params?: PMPListParams): Promise<PMP[]> {
    return this.backend
      .get<{ entities: EasyPMP[] }>(`pmp/latest`, this.convertParams(params))
      .then(response => this.parseResponse(response))
      .then(response => (response.entities ? response.entities.map(entity => this.toEntity(entity)) : []))
      .then(transforms => Promise.all(transforms));
  }

  public latestForFacility(facilityId: Facility['id']): Promise<PMP | undefined> {
    return this.backend
      .get<{ latest: EasyPMP | null }>(`facility/${facilityId}/pmp/latest`)
      .then(response => this.parseResponse(response))
      .then(response => (response.latest ? this.toEntity(response.latest) : undefined));
  }
}
