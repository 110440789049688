import {Media} from '@domain/media';
import {FileDeposit} from './file-deposit';
import {UUID, Year} from '@domain/lib';
import {DOCUMENT_TYPES} from '@domain/document';

/**
 * A FileDepositItem represents a set of files submitted for a FileDeposit.
 * These files can be categorized with a year and a type.
 *
 * @since 1.1.0
 */
export interface FileDepositItem {
  id: UUID;

  type: DOCUMENT_TYPES;

  subType?: string | null;

  mediaId: Media['id'];

  parentId: FileDeposit['id'];

  year: Year | null;

  lock: boolean;
}
