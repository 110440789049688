import { Component, Input, OnInit } from '@angular/core';
import { SourcedData } from '@application/bundles/sourced-data';
import { DateFormatter } from '@application/framework/date';
import { isUrl } from '@domain/lib/string';

@Component({
  selector: 'ehp-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss'],
})
export class SourceComponent implements OnInit {
  @Input() public data: SourcedData<any>;

  public name: string | undefined;

  public url: string | undefined;

  public date: string | undefined;

  constructor(private readonly dateFormatter: DateFormatter) {}

  public ngOnInit() {
    if (this.data) {
      if (this.data.source) {
        this.name = this.data.source;
      }

      if (this.data.sourceUrl && isUrl(this.data.sourceUrl)) {
        this.url = this.data.sourceUrl;
      } else if (this.data.source && isUrl(this.data.source)) {
        this.url = this.data.source;
      }

      if ((this.data.latestUpdate as any) instanceof Date) {
        this.date = this.dateFormatter.format(this.data.latestUpdate, { month: 'long', year: 'numeric' });
      }
    }
  }
}
