import { PointGirValuationRepository } from '@application/bundles/gir/repositories/point-gir-valuation.repository';
import { Year } from '@domain/lib';
import { ConsolidatedPointGirValuation } from '@domain/gir';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { Service } from '@application/framework/di';
import { EasyConsolidatedPointGirValuation } from '@implementations/bundles/backend/easyhpad';
import { DateParser } from '@application/framework/date';

@Service({
  id: PointGirValuationRepository,
})
export class EasyPointGirValuationRepository implements PointGirValuationRepository {
  private readonly basePath = 'gir/valuations';

  constructor(
    private readonly backend: EasyBackendApi,
    private readonly dateParser: DateParser,
  ) {}

  public findByYear(year: Year): Promise<ConsolidatedPointGirValuation[]> {
    return this.backend
      .get<{ entities: EasyConsolidatedPointGirValuation[] }>(`${this.basePath}/${year.toString()}`)
      .then(response => response.body.entities)
      .then(entities => this.mapEntities(entities));
  }

  private mapEntities(entities: EasyConsolidatedPointGirValuation[]): ConsolidatedPointGirValuation[] {
    return entities.map(entity => ({
      ...entity,
      latestUpdate: this.dateParser.parse(entity.latestUpdate),
    }));
  }
}
