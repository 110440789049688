import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { QueryBus } from '@application/framework/command-query';
import { GetHealthTaxonomyTermQuery } from '@application/bundles/health-funding';
import { isValidTaxonomyTermIdType } from '@domain/taxonomy';
import { HealthTaxonomyType, isHealthTaxonomyType } from '@domain/health-funding';

@ValidatorConstraint({ name: 'IsHealthTaxonomyTermId', async: true })
export class IsHealthTaxonomyTermIdConstraint implements ValidatorConstraintInterface {
  constructor(private readonly queryBus: QueryBus) {}

  public async validate(id: any, args?: ValidationArguments): Promise<boolean> {
    if (!Array.isArray(args?.constraints) || !isHealthTaxonomyType(args?.constraints[0])) {
      throw new Error(`Taxonomy type is missing or invalid. Provided : ${args?.constraints[0]}`);
    }

    if (!isValidTaxonomyTermIdType(id)) {
      return false;
    }

    const taxonomy = args?.constraints[0] as HealthTaxonomyType;
    const term = await this.queryBus.request(new GetHealthTaxonomyTermQuery(taxonomy, id));
    return !!term;
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'Provided id is not a existant term id';
  }
}

export function IsHealthTaxonomyTermId(taxonomy: HealthTaxonomyType, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [taxonomy],
      validator: IsHealthTaxonomyTermIdConstraint,
    });
  };
}
