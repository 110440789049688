import {Injectable} from "@angular/core";
import {UserBundle as ApplicationUserBundle} from "@application/bundles/user/user.bundle";
import {QueryBus} from "@application/framework/command-query";
import {Container} from "@application/framework/di/container";
import {
  IsUniqueEmail
} from "@implementations/bundles/user/validators/unique-email/class-validator-unique-email.validator";

@Injectable({
  providedIn: 'root'
})
export class UserBundle {

  private static build = false;

  public constructor(private container: Container) {
    this.container.bind(IsUniqueEmail, IsUniqueEmail, QueryBus);
  }

  public register() {

    if (UserBundle.build) {
      return;
    }

    ApplicationUserBundle.build(this.container);
    UserBundle.build = true;
  }
}
