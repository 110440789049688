import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[ehpDraggableHandle]',
})
export class DraggableHandleDirective {

  constructor(public elementRef: ElementRef<HTMLElement>) {
  }

}
