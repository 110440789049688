import {isLocalMedia, isMedia} from "@domain/media";
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';

@ValidatorConstraint({name: 'IsLocalMediaOrMedia', async: false})
export class IsLocalMediaOrMediaConstraint implements ValidatorConstraintInterface {

  public validate(media: any, args: ValidationArguments) {
    return isLocalMedia(media) || isMedia(media);
  }

  public defaultMessage(args: ValidationArguments) {
    return 'This media must be a local media or a media.';
  }
}

export function IsLocalMediaOrMedia(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsLocalMediaOrMediaConstraint,
    });
  };
}
