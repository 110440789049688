import {Role, RoleCollection} from "@domain/authorization";

export class AgnosticRoleCollection implements RoleCollection {


  public constructor(private readonly roles: Role[]) {
  }

  public all(): Role[] {
    return this.roles;
  }

  public contain(role: Role): boolean {
    return !!this.roles.find(r => r.name === role.name);
  }

  public find(fn: (role: Role) => boolean): Role | undefined {
    return this.roles.find(fn);
  }

}
