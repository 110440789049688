import {StoreCurrentUserCommand} from "@application/bundles/user/commands/store-current-user.command";
import {UserUpdateSuccessEvent} from "@application/bundles/user/events/user-update/user-update-success.event";
import {GetCurrentUserQuery} from "@application/bundles/user/queries/get-current-user.query";
import {CommandBus, QueryBus} from "@application/framework/command-query";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";

@HasEventSubscribers()
export class UpdateCurrentUserSubscriber {

  constructor(private readonly queryBus: QueryBus, private readonly commandBus: CommandBus) {
  }

  @OnEvent({
    event: UserUpdateSuccessEvent
  })
  private async updateCurrentUser(event: UserUpdateSuccessEvent) {
    const current = await this.queryBus.request(new GetCurrentUserQuery());

    if (current && current.id === event.user.id) {

      await this.commandBus.execute(new StoreCurrentUserCommand(event.user));
    }
  }

}
