import {DependencyFundingItem} from "@domain/dependency-funding/dependency-funding.types";
import {
  DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  DAYCARE_DEPENDENCIES_FUNDING_MODES,
  DaycareDependencyFundingMode
} from "@domain/dependency-funding/daycare-dependency-funding.types";

export interface DaycareDependencyFunding<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES, V> extends DependencyFundingItem<DaycareDependencyFundingMode<M>, V> {
  id: number;
  activityAllowance: number | undefined;
  comment?: string;
}

export interface DayCareDependencySingleGIRAmountFunding extends DaycareDependencyFunding<DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.SINGLE_GIR_AMOUNT, number> {
}


export interface DayCareDependencyOtherFunding extends DaycareDependencyFunding<DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.OTHER, number> {

}



