import { AddInspectionReportDto } from '@application/bundles/capacity-authorization/dtos';
import { UpdateInspectionReportDto } from '@application/bundles/capacity-authorization/dtos/update-inspection-report.dto';
import { InspectionReportDtoFactory } from '@application/bundles/capacity-authorization/factories/inspection-report.dto.factory';
import { AddInspectionReportDtoImpl } from '@implementations/bundles/capacity-authorization/dtos/add-inspection-report.dto';
import { UpdateInspectionReportDtoImpl } from '@implementations/bundles/capacity-authorization/dtos/update-inspection-report.dto';

export class InspectionReportDtoFactoryImpl implements InspectionReportDtoFactory {
  public createAddInspectionReportDtoFromUnSafeValues(values: Partial<AddInspectionReportDto>): AddInspectionReportDto {
    const impl = new AddInspectionReportDtoImpl();

    impl.medias = values.medias || [];
    impl.mediaIds = values.mediaIds || [];

    if (values.date) {
      impl.date = new Date(values.date);
    }

    return impl;
  }

  public createUpdateInspectionReportDtoFromUnSafeValues(
    values: Partial<UpdateInspectionReportDto>,
  ): UpdateInspectionReportDto {
    const impl = new UpdateInspectionReportDtoImpl();
    impl.id = values.id || '';
    impl.mediaIds = values.mediaIds || [];
    impl.mediaToAdd = values.mediaToAdd || [];
    impl.mediaToRemove = values.mediaToRemove || [];

    if (values.date) {
      impl.date = new Date(values.date);
    }

    return impl;
  }
}
