import { AssignRolesToUserCommandHandler } from '@application/bundles/authorization/commands/handlers/assign-roles-to-user/assign-roles-to-user.command.handler';
import { OnUnauthorizedAccessSubscriber } from '@application/bundles/authorization/events/subscribers/on-unauthorized-access/on-unauthorized-access.subscriber';
import { GetAssignableRolesQueryHandler } from '@application/bundles/authorization/queries/handlers/get-assignable-roles.query.handler';
import { Bundle } from '@application/framework/bundle';
import { AbstractBundle } from '@application/framework/bundle/bundle.abstract';
import { Container } from '@application/framework/di/container';
import { CrudPermissionVoter } from '@application/bundles/authorization/voters';
import { DOCUMENT_FEATURE } from '@application/bundles/document';

@Bundle({
  commandHandlers: [AssignRolesToUserCommandHandler],
  queryHandlers: [GetAssignableRolesQueryHandler],
  eventSubscribers: [OnUnauthorizedAccessSubscriber],
  voters: [new CrudPermissionVoter<any>(DOCUMENT_FEATURE, (subject, token) => Promise.resolve(false))],
})
export class AuthorizationBundle extends AbstractBundle {
  private static instance: AuthorizationBundle | undefined;

  public static build(container: Container): AuthorizationBundle {
    if (!(AuthorizationBundle.instance instanceof AuthorizationBundle)) {
      AuthorizationBundle.instance = new AuthorizationBundle(container);
    }

    return AuthorizationBundle.instance;
  }
}
