import {AuthorizationChecker, CRUD_ACTIONS} from "@application/bundles/authorization";
import {CAPACITY_AUTHORIZATION_FEATURE} from "@application/bundles/capacity-authorization/capacity-authorization.token";
import {CapacityAuthorization} from "@domain/capacity-authorization";
import {Facility} from "@domain/facility";

export class CapacityAuthorizationAuthorizationChecker {


  constructor(private readonly authorizationChecker: AuthorizationChecker) {
  }

  public canCreate(facility?: Facility): Promise<boolean> {
    return this.authorizationChecker.isGranted([{feature: CAPACITY_AUTHORIZATION_FEATURE, value: CRUD_ACTIONS.CREATE}]);
  }

  public canEdit(authorization: CapacityAuthorization): Promise<boolean> {
    return this.authorizationChecker.isGranted(
      [
        {feature: CAPACITY_AUTHORIZATION_FEATURE, value: CRUD_ACTIONS.UPDATE}
      ],
      authorization
    );
  }

  public canDelete(authorization: CapacityAuthorization): Promise<boolean> {
    return this.authorizationChecker.isGranted(
      [
        {feature: CAPACITY_AUTHORIZATION_FEATURE, value: CRUD_ACTIONS.DELETE}
      ],
      authorization
    );
  }


}
