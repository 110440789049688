import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Filter, FilterFormItem, FiltersForm } from '@easyhpad-ui/app/library/filter/interfaces';
import { resizeFormArray } from '@easyhpad-ui/app/library/form';

@Injectable()
export class FiltersFormBuilder {
  public operators = [
    { key: '>', label: 'est supérieur à', types: ['number'] },
    { key: '>=', label: 'est supérieur ou equal à', types: ['number'] },
    { key: '<', label: 'est inférieur à', types: ['number'] },
    { key: '<=', label: 'est inférieur ou equal à', types: ['number'] },
    { key: '=', label: 'est equal à', types: ['number', 'date'] },
    { key: '=', label: 'avant le', types: ['date'] },
  ];

  constructor(private readonly fb: FormBuilder) {}

  public buildFiltersForm(values?: Array<Partial<Filter>>): FiltersForm {
    return this.fb.array([this.buildFilterItemForm()]);
  }

  public buildFilterItemForm(values?: Partial<Filter>): FilterFormItem {
    return this.fb.group({
      key: new FormControl(values?.key, [Validators.required]),
      op: new FormControl(values?.value, [Validators.required]),
      value: new FormControl(values?.value, [Validators.required]),
    });
  }

  public addFilterItem(form: FiltersForm, values?: Partial<Filter>): void {
    form.push(this.buildFilterItemForm());
  }

  public removeFilterItem(form: FiltersForm, index: number): void {
    form.removeAt(index);
  }

  public patchFormValues(form: FiltersForm, values: Array<Partial<Filter>>): void {
    const generator = (value?: Partial<Filter>) => this.buildFilterItemForm(value);

    resizeFormArray(form, values, generator);
    form.patchValue(values);
  }
}
