import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, from, mergeMap, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {QueryBus} from "@application/framework/command-query";
import {CustomerActions,} from "./customer.actions";
import {ListCustomersQuery} from "@application/bundles/customer/queries/list-customers.query";
import {Customer} from "@domain/customer";
import {ErrorActions} from "@easyhpad-ui/app/bundles/errors/store";
import {CurrentCustomerAccessor} from "@application/bundles/customer/implementation";

@Injectable()
export class CustomerEffects {

  loadCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.loadAdminCustomers),
      mergeMap((action) => from(this.queryBus.request<Customer[]>(new ListCustomersQuery(action.params)))),
      map((customers) => CustomerActions.setAdminCustomers({customers})),
      catchError(error => of(ErrorActions.catchError(error)))
    )
  );


  loadAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.loadAvailableCustomers),
      mergeMap((action) => from(this.queryBus.request<Customer[]>(new ListCustomersQuery()))),
      map((customers) => CustomerActions.setAvailableCustomers({customers})),
      catchError(error => of(ErrorActions.catchError(error)))
    )
  );

  setCurrentCustomer$ = createEffect(() =>
      this.actions$.pipe(
        ofType(CustomerActions.setCurrentCustomer),
        map((action) => this.currentCustomerAccessor.set(action.customer))
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private queryBus: QueryBus,
    private readonly currentCustomerAccessor: CurrentCustomerAccessor
  ) {
  }
}

