import {AbstractControl, FormArray} from "@angular/forms";
import {EMPTY_VALUE} from "@application/framework/lib";

export function resizeFormArray<T = any>(formArray: FormArray, values: T[], childControlFn: (value?: T) => AbstractControl) {

  if (!formArray) {
    return;
  }

  const length = values.length > formArray.controls.length ? values.length : formArray.controls.length;
  const remove: number[] = [];

  for (const index of Array(length).keys()) {

    const value = values.length > index ? values[index] : EMPTY_VALUE;
    
    if (!formArray.at(index)) {
      formArray.insert(index, childControlFn(value !== EMPTY_VALUE ? value : undefined));
    } else if (value === EMPTY_VALUE) {
      remove.push(index);
    }
  }

  remove.reverse().forEach(index => formArray.removeAt(index));
}
