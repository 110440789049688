import { ETP_CATEGORIES, ETPCategoriesValues } from '@domain/etp/etp-categories';
import { ETPCategory } from '@domain/etp';
import { isValidObject } from '@application/framework/lib';

export function isValidETPCategoryType(type: any): type is ETP_CATEGORIES {
  return ETPCategoriesValues.has(type);
}

export function isETPCategory(category: any): category is ETPCategory {
  return (
    isValidObject(category) &&
    'label' in (category as ETPCategory) &&
    'type' in (category as ETPCategory) &&
    isValidETPCategoryType(category.type) &&
    typeof category.label === 'string'
  );
}
