import {getPreviousClosestYear} from "@application/framework/lib";
import {Year} from "@domain/lib";
import {PMPValue} from "@domain/pmp/types";
import {NationalPMPIndicatorRepository} from "@application/bundles/pmp";
import {NationalPMPIndicator} from "@domain/pmp";
import {configuration} from "@easyhpad-ui/environments/configuration";

export class NationalPMPIndicatorRepositoryImpl implements NationalPMPIndicatorRepository {

  private readonly indicators: Map<Year, PMPValue>;

  constructor() {
    this.indicators = new Map([...configuration.nationalPMPIndicators].sort((a, b) => b[0] - a[0]));
  }

  public async list(): Promise<NationalPMPIndicator[]> {
    return Array.from(this.indicators.entries())
      .map(([year, amount]) => ({year, value: amount}));
  }

  public async getForYear(year: number): Promise<NationalPMPIndicator | undefined> {
    const amount = this.indicators.get(year);

    return amount !== undefined ? {year, value: amount} : undefined;
  }

  public async getLatest(needle?: Year): Promise<NationalPMPIndicator | undefined> {
    if (this.indicators.size === 0) {
      return undefined;
    }

    if (needle === undefined) {
      needle = new Date().getFullYear()
    }

    const year = getPreviousClosestYear(needle, Array.from(this.indicators.keys()));

    if (year === undefined) {
      return undefined;
    }

    const amount = this.indicators.get(year);

    return amount !== undefined ? {year, value: amount} : undefined;
  }

}
