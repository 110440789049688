import { Injectable } from '@angular/core';
import { joinPath } from '@application/framework/lib';

@Injectable()
export class SimulationUrlProvider {
  public static readonly base = 'simulation';

  public static readonly publicSchema = {
    overview: { path: '', title: 'Simulations' },
  };

  public getPublicSimulationDashboardUrl(): Promise<string> {
    return Promise.resolve(joinPath('/', SimulationUrlProvider.base, SimulationUrlProvider.publicSchema.overview.path));
  }
}
