import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import {
  HealthTaxonomyRepositoryProvider,
  ListHealthTaxonomyTermsQuery,
  ListHealthTaxonomyTermsQueryResult,
} from '@application/bundles/health-funding';

@HandleQuery({
  query: ListHealthTaxonomyTermsQuery,
})
export class ListHealthTaxonomyTermsQueryHandler implements QueryHandler<ListHealthTaxonomyTermsQuery> {
  constructor(private readonly provider: HealthTaxonomyRepositoryProvider) {}

  public async handle(query: ListHealthTaxonomyTermsQuery): Promise<ListHealthTaxonomyTermsQueryResult> {
    const repository = this.provider.provide(query.taxonomy);
    return repository.list();
  }
}
