import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RefreshCurrentUserCommand } from '@application/bundles/user/commands/refresh-current-user.command';
import { StoreCurrentUserCommand } from '@application/bundles/user/commands/store-current-user.command';
import { UserAuthorizationTokenFactory } from '@application/bundles/user/factories/authorization-token/authorization-token.factory';
import { UserDtoFactory } from '@application/bundles/user/factories/user-dto.factory';
import { CurrentUserAccessor } from '@application/bundles/user/implementations/current-user-accessor/current-user.accessor';
import { UserRepository } from '@domain/user';
import { InitialTrainingRepository } from '@domain/user/initial-training.repository';
import { JobRepository } from '@domain/user/job.repository';
import { StudyLevelRepository } from '@domain/user/study-level.repository';
import { EASYHPAD_EMAIL_PASSWORD_AUTH_STRATEGY } from '@easyhpad-ui/app/bundles/backend/easyhpad/easyhpad.tokens';
import { CommandQueryModule } from '@easyhpad-ui/app/framework/command-query/command-query.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator/validator.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { InitialTrainingRepositoryImpl } from '@implementations/bundles/backend/easyhpad/repositories/initial-training/initial-training.repository';
import { JobRepositoryImpl } from '@implementations/bundles/backend/easyhpad/repositories/job/job.repository';
import { StudyLevelRepositoryImpl } from '@implementations/bundles/backend/easyhpad/repositories/study-level/study-level.repository';
import { EasyUserRepository } from '@implementations/bundles/backend/easyhpad/repositories/user/easy-user.repository';
import { EasyCreateUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-create-user.transformer';
import { EasyUpdateUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-update-user.transformer';
import { EasyUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-user.transformer';
import { CurrentUserReactiveAccessor } from '@implementations/bundles/user/accessors/current-user-reactive/current-user-reactive.accessor';
import { CreateUserDtoImpl } from '@implementations/bundles/user/dto/create-user.dto';
import { UpdateUserDtoImpl } from '@implementations/bundles/user/dto/update-user.dto';
import { UserDtoFactoryImpl } from '@implementations/bundles/user/dto/user-dto.factory';
import {
  createUserDtoValidator,
  updateUserDtoValidator,
} from '@implementations/bundles/user/validators/create-user-dto.validator';

import { UserRoutingModule } from './user-routing.module';
import { UserBundle } from './user.bundle';
import { CURRENT_USER_REACTIVE_ACCESSOR, EMAIL_PASSWORD_AUTH_STRATEGY } from './user.tokens';
import { AgnosticPasswordStrengthChecker } from '@application/bundles/user/implementations';
import { PasswordStrengthChecker } from '@application/bundles/user';
import { RequestResetPasswordCommand } from '@implementations/bundles/backend/easyhpad/commands';
import { UpdateCurrentUserDtoImpl } from '@implementations/bundles/user/dto/update-current-user.dto';
import { validateClassValidatorObject } from '@implementations/framework/validator';
import { QueryBus } from '@application/framework/command-query';
import { GetUserPermissionsQuery, GetUserRolesQuery } from '@application/bundles/user/queries';

@NgModule({
  imports: [
    CommonModule,
    CommandQueryModule.forChild({
      bypass: [
        RefreshCurrentUserCommand,
        StoreCurrentUserCommand,
        RequestResetPasswordCommand,
        GetUserRolesQuery,
        GetUserPermissionsQuery,
      ],
    }),
    UserRoutingModule,
    ReactiveFormsModule,
    ValidatorModule.forChild({
      validators: [
        { objectType: CreateUserDtoImpl, validator: createUserDtoValidator },
        { objectType: UpdateUserDtoImpl, validator: updateUserDtoValidator },
        { objectType: UpdateCurrentUserDtoImpl, validator: validateClassValidatorObject },
      ],
    }),
    FormModule,
    TranslationModule,
  ],
  providers: [
    {
      provide: CurrentUserAccessor,
      useValue: new CurrentUserAccessor(),
    },
    {
      provide: UserRepository,
      useClass: EasyUserRepository,
      deps: [EasyBackendApi, EasyUserTransformer, EasyCreateUserTransformer, EasyUpdateUserTransformer],
    },
    {
      provide: CURRENT_USER_REACTIVE_ACCESSOR,
      useClass: CurrentUserReactiveAccessor,
      deps: [CurrentUserAccessor],
    },
    {
      provide: EMAIL_PASSWORD_AUTH_STRATEGY,
      useExisting: EASYHPAD_EMAIL_PASSWORD_AUTH_STRATEGY,
    },
    {
      provide: InitialTrainingRepository,
      useClass: InitialTrainingRepositoryImpl,
    },
    {
      provide: JobRepository,
      useClass: JobRepositoryImpl,
    },
    {
      provide: StudyLevelRepository,
      useClass: StudyLevelRepositoryImpl,
    },
    {
      provide: UserDtoFactory,
      useClass: UserDtoFactoryImpl,
    },
    {
      provide: UserAuthorizationTokenFactory,
      useClass: UserAuthorizationTokenFactory,
      deps: [QueryBus],
    },
    {
      provide: PasswordStrengthChecker,
      useClass: AgnosticPasswordStrengthChecker,
    },
    UserBundle,
  ],
})
export class UserModule {
  constructor(private bundle: UserBundle) {
    this.bundle.register();
  }
}
