import {GetPMPQuery, PMPRepository} from "@application/bundles/pmp";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {PMP} from "@domain/pmp";

@HandleQuery({
  query: GetPMPQuery
})
export class GetPMPQueryHandler implements QueryHandler<GetPMPQuery, PMP> {

  constructor(private readonly repository: PMPRepository) {
  }

  public async handle(query: GetPMPQuery): Promise<PMP> {
    return this.repository.get(query.id);
  }
}
