import { Year } from '@domain/lib';

export interface FundingAmountsByYear<D> {
  state: 'partial' | 'complete';

  year: Year;

  occupancyRate: number;

  total: {
    /**
     * The value reduced by the occupancy rate.
     */
    value: number;

    /**
     * Value computed with an occupation rate equals to 100%
     */
    fullyValue: number;
  };

  renewableAmount: {
    /**
     * The value reduced by the occupancy rate.
     */
    value: number;

    /**
     * Value computed with an occupation rate equals to 100%
     */
    fullyValue: number;
  };

  nonRenewableAmount: {
    /**
     * Current value
     */
    value: number;

    /**
     * Value computed with an occupation rate equals to 100%
     */
    fullyValue: number;
  };

  documents: Array<D>;
}
