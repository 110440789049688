import {DocumentSearchParamsFactory, SerializedDocumentParams} from "@application/framework/search/list-params";
import {
  DocumentListParamsImpl
} from "@application/framework/search/implementations/document-list-params/document-list-params";

export class AgnosticDocumentParamsFactory implements DocumentSearchParamsFactory<DocumentListParamsImpl> {

  public create(): DocumentListParamsImpl {
    return new DocumentListParamsImpl<any>();
  }


  public fromSerialized(serialized: SerializedDocumentParams): DocumentListParamsImpl {
    return this.create().deserialize(serialized);
  }
}

