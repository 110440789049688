import {HttpAuthorizationTokenStore} from "@application/framework/http";
import {EasyBackendApi} from "@implementations/bundles/backend/easyhpad/api";
import {EASYHPAD_BEARER_AUTH_TOKEN} from "@implementations/bundles/backend/easyhpad/backend.tokens";

export class EasyHpadLogoutUseCase {


  constructor(
    private readonly backend: EasyBackendApi,
    private readonly store: HttpAuthorizationTokenStore
  ) {
  }


  public async execute(): Promise<void> {
    await this.backend.get('auth/logout')
      .then(() => this.store.remove(EASYHPAD_BEARER_AUTH_TOKEN));
  }
}
