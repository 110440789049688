<div class="data">
  <div *ngIf="current" class="current">{{'Page'|ehp_translate}} : {{current.value}}</div>
</div>

<div class="actions-list">
  <button #prevButton (click)="prev()" class="is-icon icon-arrow small ghost secondary prev"
          type="button">
    <span>{{'Page précédente' | ehp_translate}}</span>
  </button>

  <button #nextButton (click)="next()" class="is-icon icon-arrow small ghost secondary next"
          type="button">
    <span>{{'Page suivante' | ehp_translate}}</span>
  </button>

</div>

