import { ForecastCostByEtpRepository } from '@application/bundles/etp';
import { EasyRepositoryAbstract } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { ForecastCostByETP } from '@domain/etp';
import { Facility } from '@domain/facility';
import { RequestParams } from '@application/framework/http';
import { EasySearchQueryParams } from '@implementations/bundles/backend/easyhpad/interfaces/easy-search-options';
import { Service } from '@application/framework/di';
import { Year } from '@domain/lib';

@Service()
export class EasyForecastCostByEtpRepository extends EasyRepositoryAbstract implements ForecastCostByEtpRepository {
  constructor() {
    super('etp');
  }

  public listLatest(params?: {
    facilityIds?: Array<Facility['id']>;
  }): Promise<{ entities: ForecastCostByETP[]; year: Year }> {
    const requestParams = { ...this.convertParams(params), latest: true };

    return this.backend
      .get<{ entities: ForecastCostByETP[]; extras: { year: Year } }>(this.basePath, requestParams)
      .then(response => this.parseResponse(response))
      .then(body => ({ entities: body.entities, year: body.extras.year }));
  }

  protected override convertParams(params?: { facilityIds?: Array<Facility['id']> }): RequestParams {
    const p: EasySearchQueryParams<never> = {};

    if (params && Array.isArray(params.facilityIds)) {
      p.facility_ids = params.facilityIds;
    }

    return this.searchTransformer.toRequestParams(p);
  }
}
