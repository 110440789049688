import {CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT} from "@application/bundles/capacity-authorization";
import {
  CapacityAuthorizationDeletedEvent
} from "@application/bundles/capacity-authorization/events/delete-authorization/capacity-authorization-deleted.event";
import {
  CapacityAuthorizationDeletionFailEvent
} from "@application/bundles/capacity-authorization/events/delete-authorization/capacity-authorization-deletion-fail.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStreamLibrary, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class DeleteCapacityAuthorizationEventSubscriber {
  
  constructor(private readonly noticeStream: NoticeStreamLibrary) {
  }

  @OnEvent({
    event: CapacityAuthorizationDeletedEvent
  })

  public async pushNoticeOnAuthorizationDeletion(event: CapacityAuthorizationDeletedEvent): Promise<void> {

    this.noticeStream.getStream().push(this.noticeStream.getTranslatableNotice(
      new TranslatableString("L'autorisation de capacité a été supprimée avec succès.", {}, CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT),
      NoticeType.SUCCESS
    ));

  }

  @OnEvent({
    event: CapacityAuthorizationDeletionFailEvent
  })
  public async pushNoticeOnCustomerDeletionFail(event: CapacityAuthorizationDeletionFailEvent): Promise<void> {
    let message: string | TranslatableString = event.reason;

    if (!message) {

      message = new TranslatableString(
        "Une erreur est survenue lors de la suppression  de l'autorisation de capacité.",
        {},
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.getStream().push(this.noticeStream.getTranslatableNotice(message, NoticeType.ERROR));
  }
}
