import { createAction, props } from '@ngrx/store';
import { BalanceSheet } from '@domain/balance-sheet';

export enum BALANCE_SHEET_ACTION_TYPES {
  PUBLIC_LOAD = '[BalanceSheet] Load sheets',
  PUBLIC_LOADED = '[BalanceSheet] Set sheets',
  PUBLIC_ADD = '[BalanceSheet] Add sheets in entities',
  SELECT_SINGLE_SHEET = '[BalanceSheet] Select single sheet',
  RESET = '[BalanceSheet] Reset balance sheet store',
}

const loadBalanceSheets = createAction(
  BALANCE_SHEET_ACTION_TYPES.PUBLIC_LOAD,
  props<{
    params?: any;
  }>(),
);

const setBalanceSheets = createAction(
  BALANCE_SHEET_ACTION_TYPES.PUBLIC_LOADED,
  props<{
    sheets: BalanceSheet[];
  }>(),
);

const selectBalanceSheet = createAction(
  BALANCE_SHEET_ACTION_TYPES.SELECT_SINGLE_SHEET,
  props<{
    id: BalanceSheet['id'] | null;
  }>(),
);

const addBalanceSheets = createAction(
  BALANCE_SHEET_ACTION_TYPES.PUBLIC_ADD,
  props<{
    sheets: BalanceSheet[];
  }>(),
);

const resetBalanceSheetStore = createAction(BALANCE_SHEET_ACTION_TYPES.RESET);

export const balanceSheetActions = Object.freeze({
  loadBalanceSheets,
  setBalanceSheets,
  selectBalanceSheet,
  addBalanceSheets,
  resetBalanceSheetStore,
});
