import {AddCapacityAuthorizationDto} from "@application/bundles/capacity-authorization/dtos";
import {CapacityAuthorizationDtoFactory} from "@application/bundles/capacity-authorization/factories";
import {
  AddCapacityAuthorizationDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/add-capacity-authorization.dto";
import {
  UpdateCapacityAuthorizationDto
} from "@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto";
import {
  UpdateCapacityAuthorizationDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/update-capacity-authorization.dto";

export class CapacityAuthorizationDtoFactoryImpl implements CapacityAuthorizationDtoFactory {


  public createAddCapacityAuthorizationDtoFromUnSafeValues(values: Partial<AddCapacityAuthorizationDto>): AddCapacityAuthorizationDto {
    return new AddCapacityAuthorizationDtoImpl(values as AddCapacityAuthorizationDto);
  }

  public createUpdateCapacityAuthorizationDtoFromUnSafeValues(values: Partial<UpdateCapacityAuthorizationDto>): UpdateCapacityAuthorizationDto {
    return new UpdateCapacityAuthorizationDtoImpl(values as UpdateCapacityAuthorizationDto);
  }
}
