import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {WidgetContentDirective} from "@easyhpad-ui/app/bundles/dashboard/directives/widget-content.directive";

@Component({
  selector: 'ehp-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'dashboard-widget'
  }
})
export class DashboardWidgetComponent {

  @Input() public title = '';

  @ViewChild(WidgetContentDirective) public content!: WidgetContentDirective;

  constructor() {
  }

}



