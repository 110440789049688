<ng-content select="label, [label]"></ng-content>

<ng-content select=".description"></ng-content>

<div [ngClass]="{'has-suffix': !!suffix, 'has-prefix': !!prefix}" class="control-container">

  <ng-content select="[ehp-form-control]"></ng-content>
  <span *ngIf="prefix" [innerHTML]="prefix" class="prefix"></span>
  <span *ngIf="suffix" [innerHTML]="suffix" class="suffix"></span>

  <div [ngClass]="{'pending': pending, 'valid': !hasErrors, 'async': async}" class="validation-indicator"></div>

</div>

<ehp-form-errors *ngIf="displayErrors && hasErrors" [errors]="errors"
                 class="errors-container"></ehp-form-errors>
