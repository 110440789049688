import { CreateGMPDto } from '@application/bundles/gmp';
import { Facility } from '@domain/facility';
import { ResidentGIRList } from '@domain/gir';
import { LocalMedia } from '@domain/media';
import { IsValidFacilityId } from '@implementations/bundles/facility';
import { IsLocalMedia } from '@implementations/bundles/media';
import { IsDate, IsDefined, IsNumber, IsPositive } from 'class-validator';

export class CreateGmpDtoImpl implements CreateGMPDto {
  @IsNumber()
  @IsPositive()
  public value!: number;

  @IsDate()
  public date!: Date;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  @IsLocalMedia()
  public pv!: LocalMedia;

  @IsDefined()
  public residents!: ResidentGIRList;

  constructor(values?: Partial<CreateGMPDto>) {
    if (values?.date) {
      this.date = values.date;
    }
    if (values?.value) {
      this.value = values.value;
    }

    if (values?.facilityId) {
      this.facilityId = values.facilityId;
    }
    if (values?.pv) {
      this.pv = values.pv;
    }
    if (values?.residents) {
      this.residents = values.residents;
    }
  }
}
