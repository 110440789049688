import {createAction, props} from '@ngrx/store';
import {CreateEhpadDemainDto, EhpadDemainListParams} from "@application/bundles/ehpad-demain";
import {EhpadDemain} from "@domain/ehpad-demain";


export enum EHPAD_DEMAIN_ACTION_TYPES {
  ADMIN_LOAD = '[Ehpad de Demain] Load documents',
  ADMIN_LOADED = '[Ehpad de Demain] Set documents',
  CREATE = '[Ehpad de Demain] create new document',
  CREATED = '[Ehpad de Demain] new document created',
  GET = '[Ehpad de Demain] Get document',
  ADD = '[Ehpad de Demain] Add document in store',
  SET_CURRENT = '[Ehpad de Demain] Set current',
  RESET = '[Ehpad de Demain] Reset ehpad de demain store',

}

const loadEhpadDemain = createAction(EHPAD_DEMAIN_ACTION_TYPES.ADMIN_LOAD, props<{ params?: EhpadDemainListParams }>());

const addManyEhpadDemain = createAction(EHPAD_DEMAIN_ACTION_TYPES.ADMIN_LOADED, props<{ documents: EhpadDemain[] }>());

const addEhpadDemain = createAction(EHPAD_DEMAIN_ACTION_TYPES.ADMIN_LOADED, props<{ document: EhpadDemain }>());

const setCurrentEhpadDemain = createAction(EHPAD_DEMAIN_ACTION_TYPES.SET_CURRENT, props<{ id: EhpadDemain['id'] | null }>());

const getEhpadDemain = createAction(EHPAD_DEMAIN_ACTION_TYPES.GET, props<{ id: EhpadDemain['id'] }>());

const createEhpadDemain = createAction(EHPAD_DEMAIN_ACTION_TYPES.CREATE, props<{ dto: CreateEhpadDemainDto }>());

const ehpadDemainCreated = createAction(EHPAD_DEMAIN_ACTION_TYPES.CREATED, props<{ document: EhpadDemain }>());

const resetEhpadDemainStore = createAction(EHPAD_DEMAIN_ACTION_TYPES.RESET);

export const EhpadDemainActions = Object.freeze({
  loadEhpadDemain,
  setEhpadDemain: addManyEhpadDemain,
  createEhpadDemain,
  ehpadDemainCreated,
  getEhpadDemain,
  setCurrentEhpadDemain,
  resetEhpadDemainStore
})
