import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PhoneFormatter } from '@application/bundles/phone/formatter/phone.formatter';
import { Logger } from '@application/framework/logger';

@Directive({
  selector: 'input[type="tel"][ehpPhoneFormatter]',
})
export class PhoneFormatterDirective {
  constructor(
    private ngControl: NgControl,
    private formatter: PhoneFormatter,
    private readonly logger: Logger,
  ) {}

  @HostListener('keyup', ['$event.target.value'])
  public onKeyUp(value: any) {
    try {
      value = this.formatPhoneNumber(value);
    } catch (e: any) {
      this.logger.warning(e);
    }

    this.ngControl.control?.setValue(value);
  }

  private formatPhoneNumber(value: string): string {
    try {
      value = this.formatter.formatNumberString(value);
    } catch (e: any) {
      this.logger.warning(e);
    }

    return value;
  }
}
