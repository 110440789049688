<div class="widget-content">
  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <ehp-translatable-string [replacement]="{year: currentYear}" [value]="label"></ehp-translatable-string>
      </ng-template>

      <ng-container *ngIf="data.get(currentYear) as funding" [ngTemplateOutletContext]="{$implicit: funding}"
                    [ngTemplateOutlet]="fundingTpl">
      </ng-container>

    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <ehp-translatable-string [replacement]="{year: previousYear}" [value]="label"></ehp-translatable-string>
      </ng-template>

      <ng-container *ngIf="data.get(previousYear) as funding" [ngTemplateOutletContext]="{$implicit: funding}"
                    [ngTemplateOutlet]="fundingTpl">
      </ng-container>

    </p-tabPanel>

  </p-tabView>
</div>


<ng-template #fundingTpl let-funding>

  <ng-container *ngIf="funding && funding.length">

    <div class="funding-container">
      <ng-container *ngFor="let row of funding">


        <div class="funding">
          <ehp-translatable-string [value]="row.label" class="label root-label"></ehp-translatable-string>


          <div class="columns">

            <div class="column real">
              <span class="title">{{'Réalisé' | ehp_translate}}</span>

              <ng-container *ngIf="!row.real || row.real.isEmpty">
                <div class="empty">-</div>
              </ng-container>

              <ng-container *ngIf="row.real && !row.real.isEmpty">
                <ng-container [ngTemplateOutletContext]="{item: row.real}"
                              [ngTemplateOutlet]="block"></ng-container>
              </ng-container>

            </div>

            <div class="column forecast">
              <span class="title">{{'Notifié' | ehp_translate}}</span>

              <ng-container *ngIf="!row.forecast || row.forecast.isEmpty">
                <ng-container [ngTemplateOutletContext]="{type: row.type}"
                              [ngTemplateOutlet]="emptyData"></ng-container>
              </ng-container>

              <ng-container *ngIf="row.forecast && !row.forecast.isEmpty">
                <ng-container [ngTemplateOutletContext]="{item: row.forecast}"
                              [ngTemplateOutlet]="block"></ng-container>
              </ng-container>
            </div>

          </div>


        </div>

        <ng-template #block let-item="item">


            <ng-container *ngIf="!item.isEmpty">
              <div class="amount-container">
                <span class="value">{{item.amount|currency}}</span>
              </div>

            </ng-container>

            <ng-container *ngIf="item.details && item.details.length">
              <div *ngFor="let detail of item.details" class="detail">
                <span class="label">{{detail.label}}&nbsp;:</span>
                <span class="value">{{detail.amount|currency}}</span>
              </div>
            </ng-container>

        </ng-template>

      </ng-container>

    </div>

  </ng-container>

</ng-template>


<ng-template #emptyData let-type="type">

  <div class="empty">

    <span *ngIf="!isSingle">-</span>

    <ng-container *ngIf="isSingle" [ngSwitch]="type">
      <ng-container *ngSwitchCase="'health'" class="empty funding">
        <p class="message">{{'Aucun financement soin' | ehp_translate}}</p>
        <a *ngIf="actions.health" [routerLink]="actions.health"
           class="target-link">{{'Nouvel archivage' | ehp_translate}}</a>
      </ng-container>

      <ng-container *ngSwitchCase="'dependency'">
        <p class="message">{{'Aucun financement dépendance' | ehp_translate}}</p>
        <a *ngIf="actions.dependency" [routerLink]="actions.dependency"
           class="target-link">{{'Nouvel archivage' | ehp_translate}}</a>
      </ng-container>

      <ng-container *ngSwitchCase="'accommodation'">
        <p class="message">{{'Aucun financement hébergement' | ehp_translate}}</p>
        <a *ngIf="actions.accommodation" [routerLink]="actions.accommodation"
           class="target-link">{{'Nouvel archivage' | ehp_translate}}</a>
      </ng-container>
    </ng-container>

  </div>

</ng-template>
