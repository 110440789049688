import {UnauthorizedAccessEvent} from "@application/bundles/authorization/events/unauthorized-access.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";

@HasEventSubscribers()
export class OnUnauthorizedAccessSubscriber {
  
  public constructor(
    private readonly translator: Translator,
    private readonly noticeStream: NoticeStream
  ) {
  }

  @OnEvent({
    event: UnauthorizedAccessEvent
  })
  private async pushUnauthorizedAccessNotice(event: UnauthorizedAccessEvent) {
    let message = event.reason;

    if (!message) {
      message = await this.translator.translate("Vous n'êtes pas autorisé à accéder à cette page.")
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }
}
