import {Injectable} from "@angular/core";
import {POSITION} from "@application/framework/lib";
import {LocalMedia, Media} from "@domain/media";
import {MediasViewerComponent} from "@easyhpad-ui/app/bundles/media/components/medias-viewer/medias-viewer.component";
import {
  MediaViewerMode,
  MediaViewerOptions
} from "@easyhpad-ui/app/bundles/media/interfaces/media-viewer-options.interface";
import {MediaViewerRef} from "@easyhpad-ui/app/bundles/media/services/media-viewer-reference";

@Injectable({
  providedIn: 'root'
})
export class MediaPreview {

  public open(medias: Array<Media | LocalMedia>, options: MediaViewerOptions): MediaViewerRef {

    const reference = new MediaViewerRef(options.container.createComponent(MediasViewerComponent));

    reference
      .setMode(options.mode || MediaViewerMode.DIALOG)
      .setPosition(options.position || POSITION.BOTTOM)
      .setMedias(medias)
      .setFullscreen(options.acceptFullscreen || false)
      .setClosable(options.acceptClosable || true)
      .dispatchChange();


    if (medias[0]) {
      reference.openMedia(medias[0]);
    }

    return reference;
  }
}
