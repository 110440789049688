import { UUID } from '@domain/lib';
import { FILE_DEPOT_STATES, FileDeposit } from '@domain/file-deposit';
import { User } from '@domain/user';

export interface FileDepositTransition {
  id: UUID;

  fileDepositId: FileDeposit['id'];

  from: FILE_DEPOT_STATES | null;

  to: FILE_DEPOT_STATES;

  timestamp: Date;

  comment?: string | null;

  ownerId?: User['id'] | null;
}
