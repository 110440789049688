import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'ehp-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ]
})
export class ToggleComponent implements OnChanges, ControlValueAccessor {

  @Input() public checked: boolean = false;

  @Input() public disabled: boolean = false;

  @Input('label-on') public onLabel: string = 'Oui';

  @Input('label-off') public offLabel: string = 'Non';

  @Input('label') public label: string | undefined;

  public displayedlabel: string;

  @Output() public change: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('attr.role') public role = 'checkbox';

  @HostBinding('attr.aria-checked')
  public get ariaChecked() {
    return this.checked;
  }

  public get nodeName(): string {
    return 'ehp-toggle';
  }

  public get value() {
    return this.checked;
  }

  public set value(value: any) {
    this.writeValue(value);
  }

  constructor() {
    this.displayedlabel = this.offLabel;

  }

  @HostListener('click')
  public onClick() {
    this.checked = !this.checked;
    this.displayedlabel = this.checked ? this.onLabel : this.offLabel;
    this._onChange(this.checked);
    this.change.emit(this.checked);
  }

  /**
   * @inheritDoc
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {

    if (changes['checked']) {
      this.displayedlabel = changes['checked'].currentValue ? this.onLabel : this.offLabel;
    }
  }

  public registerOnChange(fn: (value: boolean) => void): void {
    this._onChange = fn;
  }

  public writeValue(obj: any): void {
    this.checked = !!obj;
  }

  public onTouched = (): void => undefined;

  /**
   * @inheritDoc
   * @param fn
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * @inheritDoc
   * @param isDisabled
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _onChange = (value: boolean): void => undefined;
}
