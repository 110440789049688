import { Customer } from '@domain/customer';
import { Stringifiable } from '@domain/lib';
import { isValidCustomerIdType } from '@application/bundles/customer/validators/is-valid-customer/is-valid-customer.validator';

export class CustomerCacheKey implements Stringifiable {
  private readonly id: Customer['id'];

  constructor(customer: Customer | Customer['id']) {
    this.id = !isValidCustomerIdType(customer) ? customer.id : customer;
  }

  public toString(): string {
    return `__customer_${this.id.toString()}`;
  }
}
