import { UpdateAccommodationFundingDto } from '@application/bundles/accommodation-funding';
import { Facility } from '@domain/facility';
import { LocalMedia, Media } from '@domain/media';
import { IsArray, IsDate, IsNumber, IsOptional, IsUUID, Max, Min, ValidateNested } from 'class-validator';
import { isPercentage } from '@domain/lib/math/percentage/percentage';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { AccommodationDailyRateDto } from '@implementations/bundles/accommodation-funding/dto/accommodation-daily-rate.dto';
import { IsPositiveFundingAmount } from '@implementations/bundles/funding/validators';
import { isPositiveFundingAmount } from '@domain/funding';
import { IsValidFacilityId } from '@implementations/bundles/facility';

export class UpdateAccommodationFundingDtoImpl implements UpdateAccommodationFundingDto {
  @IsUUID()
  public id!: AccommodationFunding['id'];

  @IsDate()
  public date!: Date;

  @IsOptional()
  @IsPositiveFundingAmount()
  public permanentAmount?: number | null;

  @IsOptional()
  @IsPositiveFundingAmount()
  public temporaryAmount?: number | null;

  @IsOptional()
  @IsPositiveFundingAmount()
  public daycareAmount?: number | null;

  @IsArray()
  @ValidateNested({ each: true })
  public dailyRates!: Array<AccommodationDailyRateDto>;

  @IsNumber()
  @Min(0)
  @Max(100)
  @IsOptional()
  public occupationRate: number | undefined;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  @IsOptional()
  public pvId?: Media['id'];

  @IsOptional()
  public pv!: LocalMedia | Media;

  @IsOptional()
  public reportId?: Media['id'];

  @IsOptional()
  public report!: LocalMedia | Media;

  constructor(values?: Partial<UpdateAccommodationFundingDto>) {
    if (values) {
      if (values.id) {
        this.id = values.id;
      }

      if (values.date) {
        this.date = values.date;
      }

      if (Array.isArray(values.dailyRates)) {
        this.dailyRates = values.dailyRates;
      }

      if (isPercentage(values.occupationRate)) {
        this.occupationRate = values.occupationRate;
      }

      if (values.facilityId) {
        this.facilityId = values.facilityId;
      }

      if (values.pvId) {
        this.pvId = values.pvId;
      }

      if (values.pv) {
        this.pv = values.pv;
      }

      if (values.reportId) {
        this.reportId = values.reportId;
      }

      if (values.report) {
        this.report = values.report;
      }

      if (isPositiveFundingAmount(values.permanentAmount)) {
        this.permanentAmount = values.permanentAmount;
      }

      if (isPositiveFundingAmount(values.temporaryAmount)) {
        this.temporaryAmount = values.temporaryAmount;
      }

      if (isPositiveFundingAmount(values.daycareAmount)) {
        this.daycareAmount = values.daycareAmount;
      }
    }
  }
}
