import {createReducer, on} from "@ngrx/store";
import {ErrorActions} from './error.actions';
import {ErrorStoreState} from "./error.state";

const initialState: ErrorStoreState = {};

export const errorReducer = createReducer(
  initialState,
  on(ErrorActions.catchError, state => ({
    ...state,
  })),
  on(ErrorActions.displayError, (state) => ({
    ...state,
  })),
);
