<header class="dialog-header">
  <h2 class="title">
    <ehp-translatable-string [value]="title"></ehp-translatable-string>
  </h2>
</header>

<div class="dialog-content">
  <ul class="list">

    <li *ngFor="let column of columns">
      <ehp-toggle (change)="toggle(column.key)" [checked]="isVisible(column.key)"
                  id="column-{{column.key}}"></ehp-toggle>
      <label for="column-{{column.key}}">
        {{ column.label }}
      </label>
    </li>
  </ul>

</div>

<div class="dialog-actions">
  <button (click)="close()" class="primary" type="button">{{'Fermer' | ehp_translate }}</button>
</div>
