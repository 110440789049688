import {Component, Input} from '@angular/core';
import {Address} from "@domain/address";

@Component({
  selector: 'ehp-address-one-line',
  templateUrl: './address-one-line.component.html',
  styleUrls: ['./address-one-line.component.scss']
})
export class AddressOneLineComponent {
  
  @Input() public address: Address | undefined;
}
