import {Container} from "@application/framework/di";
import {
  ProvideDecoratorAdapter,
  ServiceDecoratorAdapter
} from "@application/framework/di/interfaces/decorator-adapters.interface";
import {Provider} from "@application/framework/di/interfaces/service-metadata.interface";
import {EventSubscribersRegistry} from "@application/framework/event/event-subscribers.registry";
import {Logger} from "@application/framework/logger";

export interface ApplicationComponents {

  defaultContainer: Container;

  logger: Provider<Logger>;

  eventSubscriberRegistry: Provider<EventSubscribersRegistry>;

  serviceDecorator?: ServiceDecoratorAdapter;

  provideDecorator?: ProvideDecoratorAdapter;
}


export * from './functions/register-application-components';
