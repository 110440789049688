import {Command} from "@application/framework/command-query";
import {HealthFunding} from "@domain/health-funding";

export class OpenHealthFundingDeletionDialogCommand implements Command<void> {

  constructor(
    public readonly document: HealthFunding,
    public readonly onDeletion?: (document: HealthFunding) => void
  ) {
  }
}
