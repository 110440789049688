import {DependencyFunding} from "@domain/dependency-funding";
import {
  CreateDaycareDependencyFundingDto,
  CreatePermanentDependencyFundingDto,
  CreateTemporaryDependencyFundingDto,
  UpdateDaycareDependencyFundingDto,
  UpdatePermanentDependencyFundingDto,
  UpdateTemporaryDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {LocalMedia, Media} from "@domain/media";

export interface CreateDependencyFundingDto extends Omit<DependencyFunding, 'id' | 'year' | 'permanent' | 'temporary' | 'daycare' | 'facility' | 'reportId' | 'documentId' | 'hasReport' | 'document' | 'report' | 'createdAt' | 'updatedAt'> {

  permanent: CreatePermanentDependencyFundingDto;

  temporary: CreateTemporaryDependencyFundingDto<any, unknown>;

  daycare: CreateDaycareDependencyFundingDto<any, unknown>;

  reportId?: Media['id'];

  documentId?: Media['id'];

  document: LocalMedia;

  report?: LocalMedia;


}


export interface UpdateDependencyFundingDto extends Omit<DependencyFunding, 'year' | 'facility' | 'createdAt' | 'updatedAt' | 'permanent' | 'temporary' | 'daycare' | 'report' | 'reportId' | 'document' | 'documentId' | 'hasReport'> {

  permanent: CreatePermanentDependencyFundingDto | UpdatePermanentDependencyFundingDto;

  temporary: CreateTemporaryDependencyFundingDto<any, unknown> | UpdateTemporaryDependencyFundingDto<any, unknown>;

  daycare: CreateDaycareDependencyFundingDto<any, unknown> | UpdateDaycareDependencyFundingDto<any, unknown>;

  reportId?: Media['id'];

  documentId?: Media['id'];

  document: LocalMedia | Media;

  report?: LocalMedia | Media;
}
