import {
  FacilityPopulate,
  FacilitySearchParams,
  SerializedFacilitySearchParams,
} from '@application/bundles/facility/facility-search-params';
import { Customer } from '@domain/customer';
import { isMaybeACustomer, isValidCustomerIdType } from '@application/bundles/customer';
import { PaginationParams } from '@application/framework/search';
import { isValidObject } from '@application/framework/lib';
import { Facility } from '@domain/facility';
import { keys } from '@domain/lib/object/object';

export class AgnosticFacilitySearchParams implements FacilitySearchParams {
  private customerId: Customer['id'] | undefined;

  private pagination: PaginationParams | undefined;

  private populate: FacilityPopulate | undefined;

  private searchIds: Array<Facility['id']> | undefined;

  private includeInactiveFacilities?: boolean;

  public setCustomer(customer: Customer | Customer['id']): this {
    if (isValidCustomerIdType(customer)) {
      this.customerId = customer;
    } else if (isMaybeACustomer(customer)) {
      this.customerId = customer.id;
    }

    return this;
  }

  public setPagination(pagination: PaginationParams | null): this {
    if (pagination === null) {
      this.pagination = undefined;
    } else if (Number.isInteger(pagination.limit)) {
      this.pagination = pagination;
    }
    return this;
  }

  public setPopulate(populate: FacilityPopulate | null): this {
    this.populate = populate === 'customer' ? populate : undefined;
    return this;
  }

  public setFacilityIds(ids: Array<Facility['id']> | null): this {
    if (Array.isArray(ids) && ids.length > 0) {
      this.searchIds = ids;
    } else {
      this.searchIds = undefined;
    }

    return this;
  }

  public includeInactive(include?: boolean): this {
    if (include === true) {
      this.includeInactiveFacilities = true;
    } else {
      this.includeInactiveFacilities = false;
    }

    return this;
  }

  public serialize(): SerializedFacilitySearchParams {
    const serialized: SerializedFacilitySearchParams = {};

    if (isValidCustomerIdType(this.customerId)) {
      serialized.customerId = this.customerId;
    }

    if (this.pagination) {
      serialized.pagination = this.pagination;
    }

    if (this.populate) {
      serialized.populate = this.populate;
    }

    const search: SerializedFacilitySearchParams['search'] = {};

    if (this.searchIds) {
      search.id = this.searchIds;
    }

    if (keys(search).length > 0) {
      serialized.search = search;
    }

    if (this.includeInactiveFacilities === false || this.includeInactiveFacilities === true) {
      serialized.includeInactive = this.includeInactiveFacilities;
    }

    return serialized;
  }

  public deserialize(params?: SerializedFacilitySearchParams): FacilitySearchParams {
    const searchParams = new AgnosticFacilitySearchParams();

    if (isValidObject(params)) {
      if (isValidCustomerIdType(params.customerId)) {
        searchParams.setCustomer(params.customerId);
      }

      if (params?.pagination) {
        searchParams.setPagination(params.pagination);
      }

      if (params?.populate) {
        searchParams.setPopulate(params.populate);
      }

      if (isValidObject(params.search)) {
        if (params.search?.id && Array.isArray(params.search.id)) {
          searchParams.setFacilityIds(params.search.id);
        }
      }

      if (params.includeInactive === true || params.includeInactive === false) {
        searchParams.includeInactive(params.includeInactive);
      }
    }

    return searchParams;
  }
}
