export function isValidResidentTotalType(total: any): total is number {
  return total !== null && typeof total === "number";
}

export function isValidResidentTotalNumber(total: number): total is number {
  return Number.isSafeInteger(total) && total >= 0;
}

export function isValidResidentTotal(total: any): total is number {
  return isValidResidentTotalType(total) && isValidResidentTotalNumber(total);
}
