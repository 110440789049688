import { Command } from '@application/framework/command-query';
import { FileDeposit } from '@domain/file-deposit';

export class RequestTransitionToCustomerFileDepositCommand implements Command {
  constructor(
    public readonly deposit: FileDeposit,
    public readonly comment?: string,
    public readonly lockItems?: boolean,
  ) {}
}
