import { Injectable } from '@angular/core';
import { joinPath } from '@application/framework/lib';
import { AuthorizationChecker } from '@application/bundles/authorization';
import { ActivityAnnex } from '@domain/activity-annex';
import { BalanceSheet } from '@domain/balance-sheet';

@Injectable()
export class BalanceSheetUrlsProvider {
  public static readonly base = 'balance-sheet';

  public static readonly publicSchema = {
    list: { path: '', title: 'Comptes de gestion' },
    create: { path: 'create', title: 'Déposer un nouveau compte de gestion' },
    show: { path: ':id', title: 'Compte de gestion' },
    edit: { path: ':id/edit', title: 'Modifier le compte de gestion' },
  };

  constructor(public readonly authorization: AuthorizationChecker) {}

  public getPublicList(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList(): string {
    return joinPath('/', BalanceSheetUrlsProvider.base);
  }

  public getPublicShow(id: BalanceSheet['id']): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicShow(id));
  }

  public getUnsafePublicShow(id: ActivityAnnex['id']): string {
    return joinPath('/', BalanceSheetUrlsProvider.base, id.toString());
  }
}
