import {Injectable} from "@angular/core";
import {BedCareValidator} from "@easyhpad-ui/app/bundles/capacity-authorization/validators/group/bed-care.validator";
import {PASAURHValidator} from "@easyhpad-ui/app/bundles/capacity-authorization/validators/group/pasa-uhr.validator";

@Injectable({
  providedIn: 'root'
})
export class CapacityAuthorizationValidators {


  public bedCareGroupValidator(bedCareControlName: string, permanentCapacityControlName: string, temporaryCapacityControlName: string): BedCareValidator["validate"] {
    return new BedCareValidator(bedCareControlName, permanentCapacityControlName, temporaryCapacityControlName).validate;
  }

  public PasaUhrGroupValidator(
    permanentCapacityControlName: string,
    pasaCapacityControlName: string,
    uhrCapacityControlName: string
  ): PASAURHValidator['validate'] {
    return new PASAURHValidator(permanentCapacityControlName, pasaCapacityControlName, uhrCapacityControlName).validate;
  }
}
