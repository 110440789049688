import {Notice, NoticeStream} from "@application/framework/notice";
import {Subject, Subscription} from "rxjs";

export class NoticeStreamImpl implements NoticeStream {

  private channel: Subject<Notice> = new Subject();

  private subscriptions: Set<Subscription> = new Set();

  constructor() {
  }

  public push(notice: Notice): NoticeStream {
    this.channel.next(notice);
    return this;
  }

  public subscribe(callable: (notice: Notice) => any): void {
    this.subscriptions.add(this.channel.subscribe(callable));
  }

  public close() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  
}
