import { UpdateCPOMDto } from '@application/bundles/cpom';
import { Command } from '@application/framework/command-query';
import { CPOM } from '@domain/cpom';

export class UpdateCPOMCommand implements Command<CPOM> {
  constructor(
    public id: CPOM['id'],
    public readonly cpom: UpdateCPOMDto,
  ) {}
}
