import {HealthFundingDeletedEvent, HealthFundingDeletionFailEvent} from "@application/bundles/health-funding";
import {HEALTH_FUNDING_TRANSLATE_CONTEXT} from "@application/bundles/health-funding/health-funding.token";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnHealthFundingDeletionEventSubscriber {

  constructor(private readonly noticeStream: NoticeStream) {
  }

  @OnEvent({
    event: HealthFundingDeletedEvent
  })
  private async pushNoticeOnHealthFundingDeletionSuccess(event: HealthFundingDeletedEvent): Promise<void> {

    const message = new TranslatableString(
      "Le <strong>financement soin {{year}}</strong> a été supprimé avec succès.",
      {year: event.healthFunding.year},
      HEALTH_FUNDING_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  @OnEvent({
    event: HealthFundingDeletionFailEvent
  })
  private pushNoticeOnHealthFundingDeletionFail(event: HealthFundingDeletionFailEvent): void {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la suppression du financement soin.',
        undefined,
        HEALTH_FUNDING_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }
}
