import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EhpadDemainTypeLabelComponent } from '@easyhpad-ui/app/bundles/ehpad-demain/modules/ui/components/ehpad-demain-type-label/ehpad-demain-type-label.component';
import { FacilityFormModule } from '@easyhpad-ui/app/bundles/facility/modules/form/facility-form.module';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { FilterModule } from '@easyhpad-ui/app/library/filter/filter.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { TooltipModule } from 'primeng/tooltip';
import { EhpadDemainLinkComponent } from './components/ehpad-demain-link/ehpad-demain-link.component';
import { EhpadDemainListFiltersComponent } from './components/ehpad-demain-list-filters/ehpad-demain-list-filters.component';
import { EhpadDemainListItemComponent } from './components/ehpad-demain-list-item/ehpad-demain-list-item.component';
import { EhpadDemainListComponent } from './components/ehpad-demain-list/ehpad-demain-list.component';

@NgModule({
  imports: [
    CommonModule,
    ReusableComponentsModule,
    TranslationModule,
    TooltipModule,
    FormModule,
    FilterModule,
    FacilityFormModule,
  ],
  declarations: [
    EhpadDemainListComponent,
    EhpadDemainListItemComponent,
    EhpadDemainLinkComponent,
    EhpadDemainListFiltersComponent,
    EhpadDemainTypeLabelComponent,
  ],
  exports: [
    EhpadDemainListComponent,
    EhpadDemainLinkComponent,
    EhpadDemainListFiltersComponent,
    EhpadDemainTypeLabelComponent,
  ],
})
export class EhpadDemainUiModule {}
