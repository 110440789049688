import { ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentViewProcessorProvider } from '@easyhpad-ui/app/bundles/documents/contracts/document-view';
import { DocumentSingleViewProcessorInstance } from '@easyhpad-ui/app/bundles/documents/contracts/document-single-view';

export interface DocumentEditViewContainer {
  readonly formContainer: ViewContainerRef;

  readonly route: ActivatedRoute;
}

export type DocumentEditViewProcessorInstance = DocumentSingleViewProcessorInstance;

export interface DocumentEditViewProcessor {
  /**
   * Attach subcomponents in the parent view.
   * @param parent
   */
  buildEditView: (parent: DocumentEditViewContainer) => DocumentEditViewProcessorInstance;
}

/**
 * Provide the processor to build a Document create page component for a type of document
 */
export type DocumentEditViewProvider<C extends DocumentEditViewProcessor> = DocumentViewProcessorProvider<'edit', C>;
