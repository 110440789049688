import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {CapacityAuthorization, CapacityAuthorizationRepository} from "@domain/capacity-authorization";
import {ListCapacityAuthorizationsQuery} from "../list-capacity-authorizations.query";
import {
  ListCapacityAuthorizationParamsImpl
} from "@application/bundles/capacity-authorization/implementations/list-capacity-authorization-params";

@HandleQuery({
  query: ListCapacityAuthorizationsQuery
})
export class ListCapacityAuthorizationQueryHandler implements QueryHandler<ListCapacityAuthorizationsQuery, CapacityAuthorization[]> {


  public constructor(private readonly repository: CapacityAuthorizationRepository) {
  }

  public handle(query: ListCapacityAuthorizationsQuery): Promise<CapacityAuthorization[]> {
    return this.repository.list(new ListCapacityAuthorizationParamsImpl().deserialize(query.params));
  }

}
