import { AfterViewInit, Component, Injector, OnDestroy, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentViewResolver } from '@easyhpad-ui/app/bundles/documents/services';
import {
  DocumentViewMediaReference,
  DocumentViewProcessorProvider,
} from '@easyhpad-ui/app/bundles/documents/contracts/document-view';
import {
  DocumentShowViewContainer,
  DocumentShowViewProcessor,
  DocumentShowViewProcessorInstance,
  ShowDocumentActions,
} from '@easyhpad-ui/app/bundles/documents';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { TranslatableString } from '@application/framework/translation';
import { Facility } from '@domain/facility';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { CdkVirtualScrollableElement } from '@angular/cdk/scrolling';
import { DocumentsActions } from '@easyhpad-ui/app/bundles/documents/contracts/document-action';

@Component({
  selector: 'ehp-document-single-view-page',
  templateUrl: './document-show-view-page.component.html',
  styleUrls: ['./document-show-view-page.component.scss'],
  hostDirectives: [
    {
      directive: CdkVirtualScrollableElement,
    },
  ],
})
export class DocumentShowViewPageComponent implements AfterViewInit, OnDestroy, DocumentShowViewContainer {
  public readonly documentId = new BehaviorSubject<string | undefined>(undefined);

  public readonly facilityId$ = new BehaviorSubject<Facility['id'] | undefined>(undefined);

  public readonly facilityIds$ = new BehaviorSubject<Array<Facility['id']> | undefined>(undefined);

  public readonly medias$ = new Subject<DocumentViewMediaReference[] | undefined>();

  public readonly actions$ = new Subject<DocumentsActions<'create' | 'edit' | 'delete'>>();

  public title: Array<string | TranslatableString> = [];

  @ViewChild('content', { read: ViewContainerRef })
  public contentContainer!: ViewContainerRef;

  @ViewChild('afterTitle', { read: ViewContainerRef })
  public afterTitleContainer!: ViewContainerRef;

  private viewIsInit = false;

  private processorName: Type<DocumentShowViewProcessor> | undefined;

  private processorInstance: DocumentShowViewProcessorInstance | undefined;

  private providerChange$ = new Subject<void>();

  private destroy$ = new Subject<void>();

  constructor(
    public readonly route: ActivatedRoute,
    private readonly resolver: DocumentViewResolver,
    private readonly injector: Injector,
  ) {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.loadProcessor(data['documentType']);
    });

    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.has('id')) {
        this.documentId.next(params.get('id') as string);
      }
    });
  }

  public ngAfterViewInit() {
    this.viewIsInit = true;

    // Prevent error for change detection
    const timer = setTimeout(() => {
      this.render();
      clearTimeout(timer);
    }, 0);
  }

  public ngOnDestroy() {
    this.processorInstance?.destroy();
    this.destroy$.next();
  }

  private render() {
    if (!this.processorName) {
      return;
    }

    const processor = this.injector.get(this.processorName);
    this.processorInstance = processor.buildShowView(this);

    this.processorInstance.title.pipe(takeUntil(this.providerChange$)).subscribe(trails => {
      this.title = [...trails];
    });

    this.processorInstance.medias.pipe(takeUntil(this.providerChange$)).subscribe(medias => this.medias$.next(medias));

    this.processorInstance.facilityId
      .pipe(takeUntil(this.providerChange$))
      .subscribe(facilityId => this.updateFacilityIds(facilityId));

    this.processorInstance.actions
      .pipe(takeUntil(this.providerChange$))
      .subscribe(actions => this.updateActions(actions));

    this.processorInstance.attachDocumentId(this.documentId);
  }

  private processorChange(): void {
    this.providerChange$.next();
    this.render();
  }

  private loadProcessor(type?: DocumentViewProcessorProvider<any, any>['documentType']): void {
    if (!type) {
      throw new Error(
        'Missing document type in currentRoute data for the current "show" view. The processor cannot be retrieve',
      );
    }

    const provider = this.resolver.resolve(type, 'show');

    if (!provider) {
      throw new Error(`Missing Document view processor provider for ${type.toString()} (show)`);
    }

    this.processorName = provider.processor;

    if (this.viewIsInit) {
      this.processorChange();
    }
  }

  private updateFacilityIds(facilityId: Facility['id'] | Array<Facility['id']>): void {
    let id: Facility['id'] | undefined;
    let ids: Array<Facility['id']> | undefined;

    if (isValidFacilityIdType(facilityId)) {
      id = facilityId;
    } else if (Array.isArray(facilityId) && facilityId.length === 1 && isValidFacilityIdType(facilityId[0])) {
      id = facilityId[0];
    } else if (Array.isArray(facilityId)) {
      ids = facilityId;
    }

    if (this.facilityId$.value !== id) {
      this.facilityId$.next(id);
    }

    this.facilityIds$.next(ids);
  }

  private updateActions(actions: ShowDocumentActions) {
    const update: DocumentsActions<'create' | 'edit' | 'delete'> = {
      create: undefined,
      edit: undefined,
      delete: undefined,
    };

    if (actions.edit) {
      update.edit = {
        ...actions.edit,
        classes: 'button is-icon primary outline icon-edit',
      };
    }

    if (actions.delete) {
      update.delete = {
        ...actions.delete,
        classes: 'button is-icon delete outline icon-delete',
      };
    }

    if (actions.create) {
      update.create = {
        ...actions.create,
        label: new TranslatableString('Nouvel archivage'),
        classes: 'button primary',
      };
    }

    this.actions$.next(update);
  }
}
