export interface Role<Name = AVAILABLE_ROLES> {
  id?: number;

  name: Name;

  label: string;
}

export interface RoleCollection {
  contain(role: Pick<Role, 'name'>): boolean;

  find(fn: (role: Role) => boolean): Role | undefined;

  all(): Role[];
}

export enum AVAILABLE_ROLES {
  DEFAULT = 'user',
  ADMIN = 'admin',
  CLIENT_MANAGER = 'client_manager',
  FACILITIES_MANAGER = 'facilities_manager',
}
