import {EPRDERRDDocuments} from "@domain/eprd-errd/eprd-errd-documents";


export const eprdErrdDocumentListPropertyMap = new Map<keyof EPRDERRDDocuments, keyof EPRDERRDDocuments>([
  ['completeDocumentId', "completeDocument"],
  ['payrollDocumentId', 'payrollDocument'],
  ['financialAnnexDocumentId', 'financialAnnexDocument'],
  ['activityAnnexDocumentId', 'activityAnnexDocument'],
  ['reportId', 'reportDocument']
]);
