import {GetHealthFundingQuery, HealthFundingRepository} from "@application/bundles/health-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {HealthFunding} from "@domain/health-funding";

@HandleQuery({
  query: GetHealthFundingQuery
})
export class GetHealthFundingQueryHandler implements QueryHandler<GetHealthFundingQuery, HealthFunding> {

  constructor(private readonly repository: HealthFundingRepository) {
  }

  public async handle(query: GetHealthFundingQuery): Promise<HealthFunding> {
    return this.repository.get(query.id);
  }
}
