import {Command} from "@application/framework/command-query";
import {ERRD} from "@domain/eprd-errd";

export class OpenERRDDeletionDialogCommand implements Command<ERRD> {


  constructor(
    public readonly errd: ERRD,
    public readonly onDeletion?: (errd: ERRD) => void
  ) {
  }
}
