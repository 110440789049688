import {Component, OnDestroy} from '@angular/core';
import {
  GetLatestCapacityAuthorizationQuery
} from "@application/bundles/capacity-authorization/queries/get-latest-capacity-authorization.query";
import {QueryBus} from "@application/framework/command-query";
import {CapacityAuthorization} from "@domain/capacity-authorization";
import {Facility} from "@domain/facility";
import {DashboardFacilityAccessor} from "@implementations/bundles/facility/accessor/dashboard-facility-accessor";
import {Subscription} from "rxjs";
import {
  CapacityAuthorizationUrlsProvider
} from "@easyhpad-ui/app/bundles/capacity-authorization/providers/capacity-authorization-urls/capacity-authorization-urls.provider";

@Component({
  selector: 'ehp-widget-facility-capacity-authorization',
  templateUrl: './facility-capacity-authorization-widget.component.html',
  styleUrls: ['./facility-capacity-authorization-widget.component.scss']
})
export class FacilityCapacityAuthorizationWidgetComponent implements OnDestroy {


  public facility: Facility | undefined;

  public authorization: CapacityAuthorization | undefined;

  public url: string = '';

  public createUrl: string | null | undefined;

  private subscription: Subscription;

  constructor(
    private readonly accessor: DashboardFacilityAccessor,
    private readonly queryBus: QueryBus,
    private readonly urls: CapacityAuthorizationUrlsProvider
  ) {
    this.subscription = this.accessor.onChange((facility) => this.facilityChange(facility));
    this.urls.getPublicCreate().then(url => this.createUrl = url);
    this.facility = this.accessor.get();
    this.facilityChange(this.facility);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private facilityChange(facility: Facility | undefined) {
    this.facility = facility;

    if (facility !== undefined) {
      this.queryBus.request(new GetLatestCapacityAuthorizationQuery(facility))
        .then(authorization => this.authorizationChange(authorization));
    } else {
      this.authorizationChange(undefined);
    }
  }

  private authorizationChange(authorization: CapacityAuthorization | undefined): void {
    this.authorization = authorization;
    this.url = authorization ? this.urls.getUnsafePublicShow(authorization.id) : '';
  }


}
