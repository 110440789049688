import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {
  MEDIA_PREVIEW_FEATURE,
  MediaViewerChanges,
  PreviewComponentInterface
} from "@easyhpad-ui/app/bundles/media/interfaces";
import {LocalMedia, Media} from "@domain/media";
import {SpreadsheetFactory} from "@easyhpad-ui/app/bundles/media/spreadsheet/spreadsheet.factory";
import {SpreadSheet, SpreadsheetPreview} from "@easyhpad-ui/app/bundles/media/spreadsheet/spreadsheet.interface";
import {SpreadsheetPreviewHostDirective} from "@easyhpad-ui/app/bundles/media/directives";

@Component({
  selector: 'ehp-preview-spreadsheet',
  templateUrl: './preview-spreadsheet.component.html',
  styleUrls: ['./preview-spreadsheet.component.scss']
})
export class PreviewSpreadsheetComponent implements PreviewComponentInterface, OnChanges, AfterViewInit {

  @Input() public media?: Media | LocalMedia;

  public supports: Set<MEDIA_PREVIEW_FEATURE> = new Set();

  public loading: boolean = false;

  public spreadsheet: SpreadSheet | undefined;

  public isSupportedPreviewFile: boolean = false;

  public downloadUrl: string | undefined;

  @ViewChild(SpreadsheetPreviewHostDirective) previewContainer!: SpreadsheetPreviewHostDirective;

  private preview: SpreadsheetPreview | undefined;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly factory: SpreadsheetFactory,
  ) {

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['media']) {
      this.displayMedia();
    }
  }

  public ngAfterViewInit() {
    if (!this.preview) {
      this.displayMedia();
    }
  }


  public parentChange(changes: MediaViewerChanges) {

    if (changes.resize && this.preview) {
      this.preview.resize();
    }
  }

  private async displayMedia(): Promise<void> {

    if (!this.previewContainer || !this.media) {
      return;
    }


    this.loading = true;

    const viewer = await this.factory.createSpreadSheetViewer(this.previewContainer.nativeElement);

    if (this.media) {

      if (this.factory.support(this.media)) {
        this.isSupportedPreviewFile = true;
        this.preview = await viewer.open(await this.media.buildUrl());
      } else {
        this.isSupportedPreviewFile = false;
        this.downloadUrl = await this.media.buildUrl().then(url => url.toString());
      }
    }


    this.loading = false;
  }


}
