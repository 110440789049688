import { TPER } from '@domain/eprd-errd';
import { isValidObject } from '@application/framework/lib';
import { isPositiveFundingAmount } from '@domain/funding';
import { isETPCategory } from '@domain/etp';

export function isValidTPER(tper: any): tper is TPER {
  return (
    isValidObject(tper) &&
    isETPCategory((tper as TPER).category) &&
    isPositiveFundingAmount((tper as TPER).amount) &&
    typeof (tper as TPER).etp === 'number' &&
    !isNaN((tper as TPER).etp) &&
    isFinite((tper as TPER).etp) &&
    (typeof (tper as TPER).comment === 'string' || (tper as TPER).comment === undefined)
  );
}
