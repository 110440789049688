export interface Transformer<Input, Output> {

  /**
   * Transforms a value from the original representation to a transformed representation.
   * @param value
   */
  transform(value: Input): Output;

  /**
   * Transforms a value from the transformed representation to its original
   * representation.
   * @param value
   */
  reverseTransform(value: Output): Input;
}


export interface AsyncTransformer<Input, Output> {

  /**
   * Transforms a value from the original representation to a transformed representation.
   * @param value
   */
  transform(value: Input): Promise<Output>;

  /**
   * Transforms a value from the transformed representation to its original
   * representation.
   * @param value
   */
  reverseTransform(value: Output): Promise<Input>;
}
