import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidTemporaryDependencyFundingIdType} from "@application/bundles/dependency-funding";

@ValidatorConstraint({name: 'IsValidTemporaryDependencyFundingId', async: true})
export class IsValidTemporaryDependencyFundingIdConstraint implements ValidatorConstraintInterface {

  public validate(id: any, args?: ValidationArguments): Promise<boolean> {
    return Promise.resolve(isValidTemporaryDependencyFundingIdType(id));
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'ID is not a valid temporary dependency funding ID.';
  }
}

export function IsValidTemporaryDependencyFundingId(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidTemporaryDependencyFundingIdConstraint
    });
  };
}
