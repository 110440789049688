import { NgModule } from '@angular/core';
import {
  ChartResidentGirListDonutComponent,
  ChartResidentGirListLineComponent,
  PointGirValuationListComponent,
} from '@easyhpad-ui/app/bundles/gir/modules/ui/components';
import { PieChartModule } from '@swimlane/ngx-charts';
import { CommonModule } from '@angular/common';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { TableModule } from 'primeng/table';
import { ChipsModule } from 'primeng/chips';
import { TooltipModule } from 'primeng/tooltip';
import { TooltipModule as EhpTooltipModule } from '@easyhpad-ui/app/library/tooltip/tooltip.module';
import { SourcedDataModule } from '@easyhpad-ui/app/library/sourced-data/sourced-data.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';

@NgModule({
  declarations: [ChartResidentGirListLineComponent, ChartResidentGirListDonutComponent, PointGirValuationListComponent],
  imports: [CommonModule, PieChartModule, TranslationModule, TooltipModule, TableModule, ChipsModule, EhpTooltipModule, SourcedDataModule, FormModule],
  exports: [ChartResidentGirListLineComponent, ChartResidentGirListDonutComponent, PointGirValuationListComponent],
})
export class GirUIModule {}
