import { FilterDefinition } from '@easyhpad-ui/app/library/filter/interfaces';

export const NUMBER_FILTER_OPERATORS: FilterDefinition['operators'] = [
  { op: '>', label: 'est supérieur à' },
  { op: '>=', label: 'est supérieur ou equal à' },
  { op: '<', label: 'est inférieur à' },
  { op: '<=', label: 'est inférieur ou equal à' },
  { op: '=', label: 'est equal à' },
];

export const DATE_FILTER_OPERATORS: FilterDefinition['operators'] = [
  { op: '=', label: 'est égale à' },
  { op: '>', label: 'après le' },
  { op: '<', label: 'avant le' },
];

export function createNumberFilterDefinition(base: Omit<FilterDefinition, 'operators'>): FilterDefinition {
  return {
    ...base,
    operators: [...NUMBER_FILTER_OPERATORS],
    inputType: 'number',
  };
}

export function createDateFilterDefinition(base: Omit<FilterDefinition, 'operators'>): FilterDefinition {
  return {
    ...base,
    operators: [...DATE_FILTER_OPERATORS],
    inputType: 'date',
  };
}
