import {
  CreateActivityAnnexCommandHandler,
  DeleteActivityAnnexCommandHandler,
  UpdateActivityAnnexCommandHandler
} from "@application/bundles/activity-annex/commands/handlers";
import {
  OnActivityAnnexCreationEventSubscriber,
  OnActivityAnnexDeletionEventSubscriber,
  OnActivityAnnexUpdateEventSubscriber
} from "@application/bundles/activity-annex/events/subscribers";
import {
  GetActivityAnnexQueryHandler,
  GetLatestActivityAnnexQueryHandler,
  HasActivityAnnexForYearQueryHandler,
  ListActivityAnnexQueryHandler
} from "@application/bundles/activity-annex/queries/handlers";
import {Bundle} from "@application/framework/bundle";
import {Service} from "@application/framework/di";
import {ActivityAnnexSearchParamsFactory} from "@application/bundles/activity-annex/activity-annex-search.params";
import {AgnosticDocumentParamsFactory} from "@application/framework/search/implementations";

@Bundle({
  commandHandlers: [
    CreateActivityAnnexCommandHandler,
    UpdateActivityAnnexCommandHandler,
    DeleteActivityAnnexCommandHandler
  ],
  queryHandlers: [
    ListActivityAnnexQueryHandler,
    GetActivityAnnexQueryHandler,
    GetLatestActivityAnnexQueryHandler,
    HasActivityAnnexForYearQueryHandler
  ],
  eventSubscribers: [
    OnActivityAnnexCreationEventSubscriber,
    OnActivityAnnexUpdateEventSubscriber,
    OnActivityAnnexDeletionEventSubscriber
  ],
  providers: [
    {provide: ActivityAnnexSearchParamsFactory, useClass: AgnosticDocumentParamsFactory}
  ]
})
@Service()
export class ActivityAnnexBundle {

  constructor() {
  }
}
