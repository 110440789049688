import { EhpadDemainHealthFunding } from '@domain/health-funding';
import { EhpadDemain } from '@domain/ehpad-demain';
import { GetEhpadDemainQuery, isValidEhpadDemainIdType } from '@application/bundles/ehpad-demain';
import { QueryBus } from '@application/framework/command-query';
import { Provide } from '@application/framework/di';
import { isValidObject } from '@application/framework/lib';
import { isValidEhpadDemainHealthFundingIdType } from '@application/bundles/health-funding';
import { isValidFundingAmount } from '@domain/funding';
import { UUID } from '@domain/lib';

export class EhpadDemainHealthFundingImpl implements EhpadDemainHealthFunding {
  public id!: UUID;

  public name: string = '';

  public amount: number = 0;

  public ehpadDemainId: EhpadDemain['id'] | undefined;

  @Provide() private readonly queryBus!: QueryBus;

  constructor(defaults?: Partial<Omit<EhpadDemainHealthFunding, 'hasEhpadDemain' | 'ehpadDemain'>>) {
    if (isValidObject(defaults)) {
      if (isValidEhpadDemainHealthFundingIdType(defaults?.id)) {
        this.id = defaults?.id;
      }

      if (typeof defaults?.name === 'string') {
        this.name = defaults.name;
      }

      if (isValidFundingAmount(defaults?.amount)) {
        this.amount = defaults?.amount;
      }

      if (isValidEhpadDemainIdType(defaults?.ehpadDemainId)) {
        this.ehpadDemainId = defaults?.ehpadDemainId;
      }
    }
  }

  public hasEhpadDemain(): boolean {
    return isValidEhpadDemainIdType(this.ehpadDemainId);
  }

  public ehpadDemain(): Promise<EhpadDemain | undefined> {
    if (!isValidEhpadDemainIdType(this.ehpadDemainId)) {
      return Promise.resolve(undefined);
    }

    return this.queryBus.request<EhpadDemain>(new GetEhpadDemainQuery(this.ehpadDemainId));
  }
}
