import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RequestParams } from '@application/framework/http/request.interface';
import { LatestGMPWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';
import { GetPmpWidgetContentQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-pmp-widget-content.query';
import { DateParser } from '@application/framework/date';
import { GetLatestGMPWidgetContentQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-latest-gmp-widget-content.query';

type BackendWidgetContent = Omit<LatestGMPWidgetContent, 'date'> & { date?: string };

@HandleQuery({
  query: GetLatestGMPWidgetContentQuery,
})
export class GetLatestGMPWidgetContentQueryHandler
  implements QueryHandler<GetLatestGMPWidgetContentQuery, LatestGMPWidgetContent>
{
  constructor(
    private readonly backend: EasyBackendApi,
    private readonly parser: DateParser,
  ) {}

  public async handle(query: GetPmpWidgetContentQuery): Promise<LatestGMPWidgetContent> {
    const params: RequestParams = {};

    if (Array.isArray(query.ids) && query.ids.length > 0) {
      params['facility_ids'] = query.ids.join(', ');
    }

    const response = await this.backend.get<BackendWidgetContent>('/widgets/gmp', params);
    return this.buildContent(response.body);
  }

  private buildContent(content: BackendWidgetContent): LatestGMPWidgetContent {
    let date: Date | undefined;

    if (typeof content.date === 'string' && content.date) {
      date = this.parser.fromISOString(content.date);
    }

    return { ...content, date };
  }
}
