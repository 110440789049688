import { isValidObject } from '@application/framework/lib';
import { EhpadDemainHealthFunding } from '@domain/health-funding';
import { isValidFundingAmount } from '@domain/funding';
import { isValidEhpadDemainIdType } from '@application/bundles/ehpad-demain';
import { isUUID } from '@domain/lib';

export function isValidEhpadDemainHealthFundingIdType(id: any): id is EhpadDemainHealthFunding['id'] {
  return isUUID(id);
}

export function isMaybeEhpadDemainHealthFunding(funding: any): funding is Omit<EhpadDemainHealthFunding, 'id'> {
  return (
    isValidObject(funding) &&
    typeof (funding as EhpadDemainHealthFunding).name === 'string' &&
    isValidFundingAmount((funding as EhpadDemainHealthFunding).amount) &&
    typeof (funding as EhpadDemainHealthFunding).hasEhpadDemain === 'function' &&
    typeof (funding as EhpadDemainHealthFunding).ehpadDemain === 'function' &&
    (isValidEhpadDemainIdType((funding as EhpadDemainHealthFunding).ehpadDemainId) ||
      (funding as EhpadDemainHealthFunding).ehpadDemainId === undefined)
  );
}

export function isEhpadDemainHealthFunding(funding: any): funding is EhpadDemainHealthFunding {
  return (
    isMaybeEhpadDemainHealthFunding(funding) &&
    isValidEhpadDemainHealthFundingIdType((funding as EhpadDemainHealthFunding).id)
  );
}
