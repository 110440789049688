import {ERRDRepository} from "@application/bundles/eprd-errd";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {ERRD} from "@domain/eprd-errd";
import {ListERRDQuery} from "../../list-errd.query";
import {AgnosticERRDSearchParams} from "@application/bundles/eprd-errd/implementations";

@HandleQuery({
  query: ListERRDQuery
})
export class ListERRDQueryHandler implements QueryHandler<ListERRDQuery, ERRD[]> {

  constructor(private readonly repository: ERRDRepository) {
  }

  public async handle(query: ListERRDQuery): Promise<ERRD[]> {
    return this.repository.list(new AgnosticERRDSearchParams().deserialize(query.params));
  }
}
