import { UpdateFacilityDto } from '@application/bundles/facility/dto';
import { AsyncTransformer } from '@application/framework/core';
import { EasyFacilityUpdateDto } from '@implementations/bundles/backend/easyhpad/interfaces';
import { AbstractEasyFacilityTransformer } from '@implementations/bundles/backend/easyhpad/transformers/facility/abstract-facility.transformer';

export class EasyFacilityUpdateDtoTransformer
  extends AbstractEasyFacilityTransformer
  implements AsyncTransformer<UpdateFacilityDto, EasyFacilityUpdateDto>
{
  public async transform(value: UpdateFacilityDto): Promise<EasyFacilityUpdateDto> {
    const defaults = await this.toEasyFacility(value);

    return {
      ...defaults,
      state: value.state,
      address: this.addressTransformer.transform(value.address),
    };
  }

  public async reverseTransform(value: EasyFacilityUpdateDto): Promise<UpdateFacilityDto> {
    const defaults = await this.fromEasyFacility(value);
    return {
      ...defaults,
      state: value.state,
      address: this.addressTransformer.reverseTransform(value.address),
    };
  }
}
