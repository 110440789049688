import { LocalMedia, Media } from '@domain/media';
import { Observable } from 'rxjs';

export interface MediaPreviewSearchResult<M extends Media | LocalMedia> {
  index: number;

  media: M;
}

export interface MediaPreviewCommand<M extends Media | LocalMedia> {
  action: 'open' | 'close';

  media?: M;
}

export interface MediaRef<M extends Media | LocalMedia> {
  /**
   *  Using search to find media in Iterable
   * @param medias
   */
  search(medias: Iterable<M>): M | null;
}

export interface MediaPreviewList<M extends Media | LocalMedia> extends Iterable<M> {
  /**
   * Get the count of medias
   */
  readonly size: number;

  /**
   * Get current open media
   */
  readonly current: Observable<M | undefined>;

  /**
   * Verify if media is in the list
   * @param media
   */
  has(media: M): boolean;

  /**
   * Add new media in list
   * @param media
   */
  add(media: M): void;

  /**
   * Remove a media from list.
   * @param media
   * @param openFirst - Re-open the first media
   * @return removed media, or null if media is not found.
   */
  remove(media: M, openFirst?: boolean): M | null;

  /**
   * Return the first media from the list or undefined if list is empty.
   */
  first(): M | undefined;

  /**
   * Find a media and index.
   * @param predicate
   */
  search(predicate: (media: M, index?: number) => boolean): MediaPreviewSearchResult<M> | undefined;

  /**
   * Replace previous media at index.
   * @param index if index === -1, add media in list
   * @param media media to set
   */
  replace(index: number, media: M): void;

  /**
   * Replace all content list
   * @param iterable
   */
  change(iterable: Iterable<M>): void;

  /**
   * Remove all medias from the list
   */
  clear(): void;

  /**
   * Find media using reference, and set media as open
   * @param reference
   */
  open(reference: MediaRef<M>): void;

  /**
   * Dispatch close command, with media from reference if media exist in list.
   * @param reference
   */
  close(reference?: MediaRef<M>): void;
}
