import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MediaFactory, MediaUploader } from '@application/bundles/media';
import { MediaBucket } from '@domain/media';
import { MediaPreview } from '@easyhpad-ui/app/bundles/media/services/media-preview/media-preview';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { ResizableModule } from '@easyhpad-ui/app/library/resizable/resizable.module';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { EasyMediaBucket } from '@implementations/bundles/backend/easyhpad/buckets/easy-media.bucket';
import { EasyMediaTransformer } from '@implementations/bundles/backend/easyhpad/transformers/media/easy-media.transformer';
import { EasyTemporaryMediaTransformer } from '@implementations/bundles/backend/easyhpad/transformers/media/easy-temporary-media.transformer';
import { AngularMediaUploaderAdapter } from '@implementations/bundles/media/adapters/angular-media-uploader.adapter';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { PreviewHostDirective, SpreadsheetPreviewHostDirective } from './directives';
import { SpreadsheetFactory } from '@easyhpad-ui/app/bundles/media/spreadsheet/spreadsheet.factory';
import { TableModule } from 'primeng/table';
import { DockModule } from 'primeng/dock';
import { BadgeModule } from '@easyhpad-ui/app/library/badge';
import { MediaLoaderPipe } from './pipes/load-media.pipe';
import {
  MediaDownloadButtonComponent,
  MediaIconComponent,
  MediaItemComponent,
  MediaPreviewButtonComponent,
  MediasViewerComponent,
  PreviewPdfComponent,
  PreviewSpreadsheetComponent,
  PreviewUnknownMediaComponent,
} from './components';

@NgModule({
  declarations: [
    MediasViewerComponent,
    PreviewHostDirective,
    PreviewUnknownMediaComponent,
    PreviewPdfComponent,
    MediaItemComponent,
    PreviewSpreadsheetComponent,
    SpreadsheetPreviewHostDirective,
    MediaLoaderPipe,
    MediaDownloadButtonComponent,
    MediaPreviewButtonComponent,
    MediaIconComponent,
  ],
  imports: [CommonModule, TranslationModule, PdfViewerModule, ResizableModule, TableModule, DockModule, BadgeModule],
  exports: [
    MediaDownloadButtonComponent,
    MediasViewerComponent,
    MediaItemComponent,
    MediaPreviewButtonComponent,
    MediaLoaderPipe,
    MediaIconComponent,
  ],
  providers: [
    {
      provide: MediaUploader,
      useClass: AngularMediaUploaderAdapter,
      deps: [HttpClient, EasyTemporaryMediaTransformer],
    },
    {
      provide: EasyMediaTransformer,
      useClass: EasyMediaTransformer,
      deps: [MediaFactory],
    },
    {
      provide: EasyTemporaryMediaTransformer,
      useClass: EasyTemporaryMediaTransformer,
      deps: [MediaFactory, EasyMediaTransformer],
    },
    {
      provide: MediaBucket,
      useClass: EasyMediaBucket,
      deps: [EasyBackendApi, MediaUploader, EasyMediaTransformer],
    },
    MediaPreview,
    SpreadsheetFactory,
  ],
})
export class MediaModule {}
