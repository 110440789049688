import { DateParser } from '@application/framework/date';
import { Service } from '@application/framework/di';
import { EasyFundingUpdateRateAbstractRepository } from './easy-funding-update-rate.abstract.repository';
import { AccommodationFundingUpdateRateRepository } from '@application/bundles/funding';

@Service()
export class EasyAccommodationFundingUpdateRateRepository
  extends EasyFundingUpdateRateAbstractRepository
  implements AccommodationFundingUpdateRateRepository
{
  constructor(dateParser: DateParser) {
    super('funding/accommodations/update-rates', dateParser);
  }
}
