import {ContainerRegistry} from "@application/framework/di";
import {
  injectServiceInPropertyDescriptor
} from "@application/framework/di/functions/inject-service-in-property-descriptor.function";
import {Logger} from "@application/framework/logger";

export function ProvideLogger(): Function;
export function ProvideLogger(name?: string): Function;
export function ProvideLogger(name?: string): PropertyDecorator {

  return (target, propertyKey) => {

    const channel = name || target.constructor.name || '';
    let logger!: Logger;

    injectServiceInPropertyDescriptor(target, propertyKey, Logger, {
      set: (value: Logger) => {
        logger = value;
      },
      get: (): any => {
        if (logger !== undefined) {
          return logger;
        }
        logger = ContainerRegistry.defaultContainer.get(Logger);
        logger = logger.channel(channel);
        return logger;
      },
    })

    Object.defineProperty(target, propertyKey, {
      configurable: true,
      enumerable: true,
    });
  }
}
