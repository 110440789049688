<p-accordion [(activeIndex)]="openPanelsIndex" [multiple]="true">

    <p-accordionTab *ngFor="let item of expected">

        <ng-template pTemplate="header">
            <span class="header-label">
                 <ehp-document-type-label [type]="item.type" class="text"></ehp-document-type-label>

                <ng-container *ngIf="count(item.type) as count">

                    <span [ngClass]="{'validate':count.min && count.current >= count.min}" class="count">
                        <span [innerHTML]="count.current.toString()" class="current"></span>
                        <span *ngIf="count.max" [innerHTML]="" class="max">{{count.max }}</span>
                    </span>

                </ng-container>
            </span>

        </ng-template>

        <ng-template pTemplate="content">
            <ul class="expected-type-items">

                <ng-container *ngIf="item.expected && item.expected.length > 0">
                    <li *ngFor="let expected of item.expected">

                        <span *ngIf="expected.year; else latestTemplate" [innerHTML]="expected.year"
                              class="label"></span>

                        <ng-template #latestTemplate>
                            <span class="label">{{'Dernier document en date' | ehp_translate }}</span>
                        </ng-template>

                        <ul class="medias">
                            <li *ngFor="let media of expected.medias"
                                [ngClass]="{validate: isValidateMedia(item.type, media, expected.year)}">

                                <ehp-file-deposit-media-type-label [key]="media.type" [type]="item.type" class="label">
                                </ehp-file-deposit-media-type-label>

                                <span *ngIf="media.required" class="required">{{'obligatoire' | ehp_translate}}</span>

                            </li>
                        </ul>

                    </li>

                </ng-container>


            </ul>
        </ng-template>

    </p-accordionTab>

</p-accordion>
