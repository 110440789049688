<ng-container *ngIf="state === 'login'; else resetState">
  <ehp-translatable-string *ngIf="message" [value]="message" class="message success"></ehp-translatable-string>
  <ehp-user-login-form (onLogin)="dispatchLogin()"></ehp-user-login-form>
</ng-container>


<ng-template #resetState>

  <ng-container *ngIf="state === 'reset'; else forgottenState">

    <p class="description">
      {{'Vous pouvez utiliser le mot de passe proposé ou définir vous même un nouveau de passe.' | ehp_translate}}
    </p>

    <form (submit)="submit()" *ngIf="form" [formGroup]="form">

      <ehp-password-updater ehp-form-control formControlName="password"
                            id="password" required></ehp-password-updater>

      <div *ngIf="loginEnable" class="forgotten-container">
        <button (click)="moveToLogin()" class="link" type="button">
          {{'Se connecter' | ehp_translate}}
          <i aria-hidden="true" class="icon icon-arrow ehp-icons"></i>
        </button>
      </div>

      <div class="actions">
        <button [disabled]="!form.valid" class="primary" type="submit">
          {{ 'Réinitialiser mon mot de passe' | ehp_translate }}
        </button>
      </div>
    </form>
  </ng-container>
</ng-template>

<ng-template #forgottenState>
  <ng-container *ngIf="state === 'forgotten'; else failState">

    <ehp-translatable-string *ngIf="message" [value]="message" class="message warning"></ehp-translatable-string>
    <ehp-forgotten-password-form></ehp-forgotten-password-form>
  </ng-container>
</ng-template>


<ng-template #failState>
  <ehp-translatable-string *ngIf="message" [value]="message" class="message error"></ehp-translatable-string>
</ng-template>


