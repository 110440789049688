import {DispatchableEvent, EventSubscriberMetadata} from "@application/framework/event";
import {MetadataReflection} from "@application/framework/lib";

const ON_EVENT_METADATA_KEY = Symbol('EHP_ON_EVENT_SUBSCRIBERS');

const metadataAccessor = new MetadataReflection();

export function registerMethodAsEventSubscriber(target: Object, methodName: string | symbol, events: Array<DispatchableEvent>, metadata: EventSubscriberMetadata) {

  let subscribers: Map<string | symbol, { metadata: EventSubscriberMetadata, events: Array<DispatchableEvent> }> = metadataAccessor.getMetadata(ON_EVENT_METADATA_KEY, target);

  if (subscribers === undefined) {
    subscribers = new Map();
  }

  subscribers.set(methodName, {events, metadata});
  metadataAccessor.defineMetadata(ON_EVENT_METADATA_KEY, subscribers, target);
}

export function getMethodRegisteredAsEventSubscriber(target: Object): Map<string | symbol, { metadata: EventSubscriberMetadata, events: Array<DispatchableEvent> }> | undefined {

  return metadataAccessor.getMetadata(ON_EVENT_METADATA_KEY, target);
}
