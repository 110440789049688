<div *ngIf="deposits && deposits.length > 0" class="message">

  <ehp-plural [count]="deposits.length" class="text">
    <span single>{{'Un dépôt de fichier est ouvert pour l\'un de vos établissements.'| ehp_translate }}</span>
    <span plural>{{'Plusieurs dépôts de fichier sont ouverts pour vos établissements.'| ehp_translate }}</span>
  </ehp-plural>

  <a *ngIf="path" [routerLink]="path" class="button file-deposit-link">
    <i aria-hidden="true" class="icon icon-deposit ehp-icon"></i>
    <ehp-plural [count]="deposits.length">
      <span single>{{'Accéder au dépôt'| ehp_translate }}</span>
      <span plural>{{'Accéder au dépôts'| ehp_translate }}</span>
    </ehp-plural>
  </a>
</div>

