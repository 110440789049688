import { Injectable } from '@angular/core';
import {
  DocumentViewProcessorProvider,
  DocumentViewType,
} from '@easyhpad-ui/app/bundles/documents/contracts/document-view';

@Injectable({
  providedIn: 'root',
})
export class DocumentViewResolver {
  private static registry = new Map<string | symbol, DocumentViewProcessorProvider<any, any>>();

  private get registry() {
    return DocumentViewResolver.registry;
  }

  /**
   * Store a new Document view provider in st
   * @param provider
   */
  public static register(provider: DocumentViewProcessorProvider<any, any>): void {
    const key = DocumentViewResolver.getKey(provider.documentType, provider.viewType);

    if (DocumentViewResolver.registry.has(key)) {
      throw new Error(
        `You can use only one processor for ${provider.documentType.toString()} (${provider.viewType}) in ${
          DocumentViewResolver.name
        }`,
      );
    }

    DocumentViewResolver.registry.set(key, provider);
  }

  /**
   * Build a registry key for provider.
   * @param documentType
   * @param viewType
   * @private
   */
  private static getKey(
    documentType: DocumentViewProcessorProvider<any, any>['documentType'],
    viewType: DocumentViewType,
  ): string {
    return `${documentType.toString()}:${viewType};`;
  }

  public resolve<C = any>(
    documentType: string | symbol,
    viewType: DocumentViewType,
  ): DocumentViewProcessorProvider<any, C> | undefined {
    const key = this.getKey(documentType, viewType);
    return this.registry.get(key);
  }

  /**
   * Static method alias
   * @param documentType
   * @param viewType
   * @see `DocumentViewResolver.getKey`
   */
  private getKey(
    documentType: DocumentViewProcessorProvider<any, any>['documentType'],
    viewType: DocumentViewType,
  ): string {
    return DocumentViewResolver.getKey(documentType, viewType);
  }
}
