import { TranslatableString } from '@application/framework/translation';

import { HasDynamicField } from './dynamic-component';
import { DynamicFormNode } from './dynamic-form';
import { Type } from '@angular/core';
import {
  WithActivation,
  WithChanges,
  WithControlValidators,
  WithInitialValue,
} from '@easyhpad-ui/app/library/form/contracts/dynamic-field-attributes';

export interface DynamicNoopField<V = any>
  extends DynamicFormNode<'noop'>,
    WithControlValidators,
    WithInitialValue<V | undefined> {}

/**
 * Form field element base
 */
export interface DynamicField<V = any, C = Type<HasDynamicField<any>>>
  extends DynamicFormNode<'field'>,
    Omit<DynamicNoopField<V>, 'formElementType'>,
    WithChanges<V | null | undefined>,
    WithActivation {
  /**
   * Label of field
   */
  label?: string | TranslatableString;

  /**
   * Description displayed after the input
   */
  description?: string | TranslatableString;

  /**
   * Name to set on field in HTML
   */
  name?: string;

  /**
   * Component used to display field
   */
  component: () => C;
}
