import {
  CreateAccommodationFundingCommandHandler,
  DeleteAccommodationFundingCommandHandler,
  UpdateAccommodationFundingCommandHandler,
} from '@application/bundles/accommodation-funding/commands/handlers';
import {
  OnAccommodationFundingCreationEventSubscriber,
  OnAccommodationFundingDeletionEventSubscriber,
  OnAccommodationFundingUpdateEventSubscriber,
} from '@application/bundles/accommodation-funding/events/subscribers';
import {
  GetAccommodationFundingQueryHandler,
  HasAccommodationFundingForYearQueryHandler,
  ListAccommodationFundingQueryHandler,
  ListAccommodationFundingUpdateRatesQueryHandler,
} from '@application/bundles/accommodation-funding/queries/handlers';
import { Bundle } from '@application/framework/bundle';
import { Service } from '@application/framework/di';

@Bundle({
  commandHandlers: [
    CreateAccommodationFundingCommandHandler,
    UpdateAccommodationFundingCommandHandler,
    DeleteAccommodationFundingCommandHandler,
  ],
  queryHandlers: [
    ListAccommodationFundingQueryHandler,
    GetAccommodationFundingQueryHandler,
    HasAccommodationFundingForYearQueryHandler,
    ListAccommodationFundingUpdateRatesQueryHandler,
  ],
  eventSubscribers: [
    OnAccommodationFundingCreationEventSubscriber,
    OnAccommodationFundingUpdateEventSubscriber,
    OnAccommodationFundingDeletionEventSubscriber,
  ],
})
@Service()
export class AccommodationFundingBundle {}
