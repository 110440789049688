<ng-container *ngIf="form" [formGroup]="form">
  <ehp-form-field class="name">
    <label [for]="getControlId('name')">{{'Nom' | ehp_translate }}</label>
    <input [id]="getControlId('name')" ehp-form-control formControlName="name"
           placeholder="{{'Nom du financement' | ehp_translate}}"
           type="text">
  </ehp-form-field>

  <ehp-form-field class="amount">
    <label [for]="getControlId('amount')">{{'Montant' | ehp_translate }}</label>
    <input [id]="getControlId('amount')" ehp-form-control formControlName="amount" min="0"
           placeholder="{{'Montant du financement' | ehp_translate}}"
           type="number">
  </ehp-form-field>

  <ehp-form-field class="comment">
    <label [for]="getControlId('comment')">{{'Commentaire' | ehp_translate }}</label>
    <textarea [id]="getControlId('comment')" ehp-form-control formControlName="comment"></textarea>
  </ehp-form-field>
</ng-container>
