import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {RouteSailor} from "@easyhpad-ui/app/framework/routing";
import {Customer} from "@domain/customer";

@Component({
  selector: 'ehp-admin-customer-create-page',
  templateUrl: './admin-customer-create-page.component.html',
  styleUrls: ['./admin-customer-create-page.component.scss']
})
export class AdminCustomerCreatePageComponent {

  constructor(
    private sailor: RouteSailor,
    private route: ActivatedRoute,
  ) {
  }


  public async onCreation(customer: Customer): Promise<void> {
    await this.sailor.catchAndRedirect(this.route, 'admin/customer');
  }

}
