<header class="widget-header">

  <h3 class="widget-title title">{{'Simulateurs' | ehp_translate }}</h3>

</header>

<div class="widget-content">


  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header">
        {{'G.M.P.' | ehp_translate}}
      </ng-template>

      <ehp-gmp-calculator></ehp-gmp-calculator>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        {{'Tarif dépendance' | ehp_translate}}
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        {{'Dotation soin' | ehp_translate}}
      </ng-template>
    </p-tabPanel>
  </p-tabView>


</div>

