import {Component, forwardRef, Inject, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel
} from "@angular/forms";
import {SelectOption} from "@implementations/forms/select-option.interface";
import {isTariffOption, isValidTariffOptionType, TARIFF_OPTIONS, TariffOption} from "@domain/cpom";
import {Subject, takeUntil, tap} from "rxjs";
import {isSelectOption} from "@implementations/forms";
import {TariffOptionCollection} from "@application/bundles/cpom";

const selector = 'ehp-tariff-option-select';

@Component({
  selector,
  templateUrl: './form-cpom-tariff-option-select.component.html',
  styleUrls: ['./form-cpom-tariff-option-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCpomTariffOptionSelectComponent),
      multi: true
    }
  ]
})
export class FormCpomTariffOptionSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() public required = false;

  @Input() public multiple: boolean = false;

  public readonly select: FormControl<SelectOption[] | SelectOption | null | undefined> = new FormControl();

  public options: SelectOption<TARIFF_OPTIONS>[];

  private control!: FormControl<any>;

  private destroy: Subject<void> = new Subject();

  public get nodeName(): string {
    return selector;
  }

  public get value() {
    return this.select.value;
  }

  public set value(value: any) {
    this.writeValue(value);
  }

  constructor(
    @Inject(Injector) private injector: Injector,
    private readonly tariffCollection: TariffOptionCollection
  ) {
    this.options = this.tariffCollection.list().map(option => ({value: option.type, label: option.label}));
    //this.select.valueChanges.pipe(takeUntil(this.destroy)).subscribe(value => this.setValue(value));
  }


  public ngOnInit(): void {
    this.setComponentControl();
  }

  public ngOnDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.select.disable() : this.select.enable();
  }


  public selectChange() {
    const value = this.select.value;

    let update: any;

    if (Array.isArray(value)) {
      update = value.map(option => {
        if (isSelectOption(option)) {
          return this.tariffCollection.get(option.value as TARIFF_OPTIONS);
        } else {
          return option;
        }
      });
    } else if (isSelectOption<TARIFF_OPTIONS>(value)) {
      update = this.tariffCollection.get(value.value);
    } else if (isTariffOption(value) || value === null || value === undefined) {
      update = value;
    }

    if (!this.multiple && Array.isArray(update)) {

      update = update[0];
    }


    this.onChange(update);
    this.control.setValue(update);

    return;

  }

  public compareFn(a?: SelectOption, b?: SelectOption) {

    if (this.multiple) {
      return a?.value === b?.value;
    } else if (Array.isArray(b)) {
      b = b[0];
    }
    return a?.value === b?.value;
  }


  public writeValue(value: TariffOption[] | TariffOption | SelectOption[] | SelectOption | TARIFF_OPTIONS | undefined): void {

    if (Array.isArray(value)) {

      value = value.map(option => isTariffOption(option) ? ({
        value: option.type,
        label: option.label
      }) : option as SelectOption);

    } else if (isTariffOption(value)) {
      value = {value: value.type, label: value.label}
    } else if (isValidTariffOptionType(value)) {
      const option = this.tariffCollection.get(value) as TariffOption;
      value = {value: option?.type, label: option?.label};
      if (this.control && this.control.value === value) {
        this.control.patchValue(option);
      }
    }


    if (!Array.isArray(value)) {
      value = [value] as any;
    }

    this.select.patchValue(value as any);
  }


  private onChange = (_value: SelectOption[] | SelectOption | undefined): void => undefined;

  private setComponentControl(): void {
    const injectedControl = this.injector.get(NgControl);

    switch (injectedControl.constructor) {
      case NgModel: {
        const {control, update} = injectedControl as NgModel;

        this.control = control;

        this.control.valueChanges.pipe(
          tap((value: any) => update.emit(value)),
          takeUntil(this.destroy),
        ).subscribe();

        break;
      }
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as FormControl;
        break;
      }
    }
  }


}
