import { isValidObject } from '@application/framework/lib';
import { EPRD } from '@domain/eprd-errd';
import { isUUID } from '@domain/lib';

export function isValidEPRDIdType(id: any): id is EPRD['id'] {
  return isUUID(id);
}

export function isMaybeAnEPRD(eprd: any): eprd is { id: EPRD['id'] } {
  return isValidObject(eprd) && 'id' in eprd && isValidEPRDIdType((eprd as EPRD).id);
}
