import {HttpClient as AngularHttpClient, HttpErrorResponse, HttpParams, HttpResponse} from "@angular/common/http";
import {HttpRequestClient} from "@application/framework/http";
import {Request, REQUEST_METHOD, Response} from "@application/framework/http/request.interface";
import {catchError, firstValueFrom, map, Observable, of} from "rxjs";

export class AngularHttpClientWrapper implements HttpRequestClient {


  constructor(private angularClient: AngularHttpClient) {
  }


  public get<R>(request: Request<REQUEST_METHOD.GET>): Promise<Response<R>> {

    return this.execute<R>(this.angularClient.get<R>(request.url.toString(), {
      ...this.parseRequestOptions(request),
      observe: "response"
    }));

  }

  public post<R>(request: Request<REQUEST_METHOD.POST>): Promise<Response<R>> {
    return this.execute(this.angularClient.post<R>(request.url.toString(), request.body, {observe: "response"}));

  }

  public patch<R>(request: Request<REQUEST_METHOD.PATCH>): Promise<Response<R>> {
    return this.execute<R>(this.angularClient.patch<R>(request.url.toString(), request.body, {observe: "response"}));

  }

  public delete<R>(request: Request<REQUEST_METHOD.DELETE>): Promise<Response<R>> {
    return this.execute<R>(this.angularClient.delete<R>(request.url.toString(), {observe: "response"}));
  }

  public request<R>(request: Request): Promise<Response<R>> {
    throw new Error('Method not implemented')
  }


  private parseRequestOptions(request: Request): Record<any, any> {

    let options = {};

    if (request.params) {
      const params = new HttpParams();

      for (const [key, value] of Object.entries(request.params)) {
        params.append(key, value);
      }

      options = {...options, params: request.params};
    }

    return options;
  }


  private execute<R>(observable: Observable<HttpResponse<R>>): Promise<Response<R>> {

    return firstValueFrom(observable.pipe(
      map(response => this.buildResponse<R>(response)),
      catchError((error: HttpErrorResponse) => of(this.buildResponse<R>(error))),
    ));
  }

  private buildResponse<Body = unknown>(response: HttpResponse<Body> | HttpErrorResponse): Response<Body> {

    return {
      body: 'body' in response ? response.body || {} as Body : {} as Body,
      status: response.status
    }
  }


}
