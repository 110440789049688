import { AppState } from '@easyhpad-ui/app/store';
import { AUTHENTIFICATION_STORE_KEY } from './authentication.key';
import { createSelector } from '@ngrx/store';
import { AVAILABLE_ROLES } from '@domain/authorization';
import { deserializeAccount } from '@easyhpad-ui/app/bundles/authentification/store/authentification.reducer';

const authentificationState = (state: AppState) => state[AUTHENTIFICATION_STORE_KEY];

export const selectAuthentificationCheck = createSelector(authentificationState, state => state.checked);

export const selectAuthentificationLogged = createSelector(authentificationState, state => state.logged);

export const selectAccountState = createSelector(authentificationState, state => state.account);

/**
 * Get the current logged account.
 */
export const selectCurrentAccount = createSelector(selectAccountState, state => {
  return state ? deserializeAccount(state) : state;
});

/**
 * Check if the current account is authenticated.
 */
export const selectIsAuthenticated = createSelector(selectCurrentAccount, account =>
  account ? account.isAuthenticated : false,
);

/**
 * Check if the current account is administrator
 */
export const selectIsAdministratorAccount = createSelector(selectCurrentAccount, account => {
  if (!account) {
    return false;
  }

  return account.roles.all().findIndex(role => role.name === AVAILABLE_ROLES.ADMIN) !== -1;
});
