import { Component } from '@angular/core';

@Component({
  selector: 'ehp-widget-calculator',
  templateUrl: './widget-calculator.component.html',
  styleUrls: ['./widget-calculator.component.scss']
})
export class WidgetCalculatorComponent {

}
