import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Document } from '@domain/document';
import { DocumentStoreActions } from './document.action';
import { map, mergeMap, Observable, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';

export abstract class DocumentEffet<Entity extends Document, P = object, C = any, U = Partial<Entity>> {
  /**
   * Load public documents on action
   */
  public loadPublicDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.loadDocumentList),
      mergeMap(action => this.loadDocuments(action.params)),
      map(documents => this.actions.documentListLoaded({ documents })),
    ),
  );

  public loadSingleDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.openDocument),
      mergeMap(action => this.loadDocument(action.id)),
      map(document => this.actions.documentOpened({ document })),
    ),
  );

  public updateSingleDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.updateDocument),
      switchMap(action => this.updateDocument(action.id, action.update)),
      map(document => this.actions.documentUpdated({ document })),
    ),
  );

  /**
   * Collection of actions
   * @protected
   */
  protected abstract get actions(): DocumentStoreActions<Entity, Entity['id']>;

  protected constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  /**
   * Load multiple documents.
   *
   * @param params
   * @protected
   */
  protected abstract loadDocuments(params?: P): Observable<Entity[]>;

  /**
   * Load single document.
   *
   * @param id
   * @protected
   */
  protected abstract loadDocument(id: Entity['id']): Observable<Entity>;

  /**
   * Update the document.
   * @param id
   * @param update
   * @protected
   */
  protected abstract updateDocument(id: Entity['id'], update: U): Observable<Entity>;
}
