import {InvalidEventSubscriberTypeError} from "@application/framework/event/errors/invalid-event-subscriber-type.error";
import {MissingEventNameError} from "@application/framework/event/errors/missing-event-name.error";
import {MissingEventSubscriberError} from "@application/framework/event/errors/missing-event-subscriber.error";
import {EventSubscribersContainer} from "@application/framework/event/event-subscribers.container";
import {
  DispatchableEvent,
  EventSubscriber,
  EventSubscriberMetadata,
  EventSubscriberOptions
} from "@application/framework/event/event.type";
import {getEmptySubscriberMetadata} from "@application/framework/event/functions/get-empty-subscriber-metadata";
import {
  AgnosticEventSubscribersContainer
} from "@application/framework/event/implementations/agnostic-event-subscribers-container/agnostic-event-subscribers.container";


type FilterOptions = any;

export abstract class EventSubscribersRegistry<F = FilterOptions> {

  private static readonly _containers: Map<string, EventSubscribersContainer<any>> = new Map();

  public static get containers() {
    return this._containers;
  }

  protected get containers(): Map<string, EventSubscribersContainer<any>> {
    return EventSubscribersRegistry.containers;
  }

  public static registerSubscriber<T extends DispatchableEvent>(event: T, subscriber: EventSubscriber<T>, metadata: EventSubscriberMetadata): void {

    if (!event.name) {
      throw new MissingEventNameError();
    }

    if (subscriber === undefined || subscriber === null) {
      throw new MissingEventSubscriberError();
    }

    if (typeof subscriber !== 'function') {
      throw new InvalidEventSubscriberTypeError(`${event.name} : Subscriber must be a valid function, ${typeof subscriber} receive.`);
    }


    const container = this.getSubscriberContainer<T>(event);
    container.set(subscriber, metadata || getEmptySubscriberMetadata());
    this.containers.set(event.name, container);
  }

  public static clear(): void {
    this.containers.clear();
  }

  protected static getSubscriberContainer<T extends DispatchableEvent>(event: DispatchableEvent): EventSubscribersContainer<T> {
    let container = this.containers.get(event.name);

    if (container === undefined) {
      container = new AgnosticEventSubscribersContainer();
    }

    return container;
  }

  public abstract isEmpty(): boolean;

  /**
   * Determine if event is registered.
   * @param event
   */
  public abstract has(event: DispatchableEvent): boolean;

  /**
   * Determine if event has subscribers.
   * @param event
   */
  public abstract hasSubscribers<T extends DispatchableEvent>(event: T): boolean;

  public abstract set<T extends DispatchableEvent>(event: T, subscriber: EventSubscriber<T>): void;

  public abstract set<T extends DispatchableEvent>(event: T, subscriber: EventSubscriber<T>, metadata: EventSubscriberMetadata): void;

  public abstract get<T extends DispatchableEvent>(event: T): EventSubscriber<T>[];

  public abstract get<T extends DispatchableEvent>(event: DispatchableEvent, filter: Partial<EventSubscriberOptions>): EventSubscriber<T>[];

  public abstract remove<T extends DispatchableEvent>(event: T): void;

  public abstract remove<T extends DispatchableEvent>(event: T, subscriber: EventSubscriber<T>): void;

  public abstract remove<T extends DispatchableEvent>(event: T, subscriber: EventSubscriber<T>[]): void;

}
