/**
 * AbstractVoter is an abstract default implementation of a voter.
 */
import {VoteResult} from "@application/bundles/authorization/vote-results.type";
import {Attribute, Voter} from "./abstractions/voter.interface";
import {AuthentificationToken} from "@application/bundles/authentification";

/**
 * Typescript version of Symfony voter
 */
export abstract class AbstractVoter implements Voter {

  /**
   * @inheritDoc
   */
  public async vote(token: AuthentificationToken, subject: any, attributes: Attribute[]): Promise<VoteResult> {
    // abstain vote by default in case none of the attributes are supported
    let vote = VoteResult.ABSTAIN;


    for (const attribute of attributes) {
      if (!this.supports(attribute, subject)) {
        continue;
      }

      vote = VoteResult.DENIED;

      if (await this.voteOnAttribute(attribute, subject, token)) {
        // grant access as soon as at least one attribute returns a positive response
        return VoteResult.GRANTED
      }
    }

    return vote;
  }

  /**
   * Determines if the attribute and subject are supported by this voter.
   *
   *
   * @return bool True if the attribute and subject are supported, false otherwise
   * @param attribute
   * @param subject
   */
  protected abstract supports(attribute: Attribute, subject: any): boolean;

  /**
   * Perform a single access check operation on a given attribute, subject and token.
   * It is safe to assume that $attribute and $subject already passed the "supports()" method check.
   *
   * @return bool
   * @param attribute
   * @param subject
   * @param token
   */
  protected abstract voteOnAttribute(attribute: Attribute, subject: any, token: AuthentificationToken): Promise<boolean>;
}
