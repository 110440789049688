<form (submit)="submit()" *ngIf="form" [formGroup]="form" noValidate>


  <div class="form-row">

    <div class="row-header">
      <h2 class="row-title title">{{"Informations sur l'utilisateur" | ehp_translate }}</h2>
    </div>

    <div class="row-content">

      <div class="columns">
        <ehp-form-field class="required">
          <label for="firstname">{{'Prénom' | ehp_translate }}</label>
          <input ehp-form-control formControlName="firstname" id="firstname" required type="text">
        </ehp-form-field>


        <ehp-form-field class="required">
          <label for="lastname">{{'Nom' | ehp_translate }}</label>
          <input ehp-form-control formControlName="lastname" id="lastname" required type="text">
        </ehp-form-field>
      </div>

      <ehp-form-field>
        <label for="email">{{'Adresse e-mail' | ehp_translate }}</label>
        <p class="description">
          {{"Il s’agit de l’adresse e-mail utilisée par l’utilisateur pour se connecter. Une adresse e-mail par utilisateur." | ehp_translate}}
          <br/></p>
        <input ehp-form-control formControlName="email" id="email" required
               type="email">

      </ehp-form-field>

      <ehp-form-field>
        <label for="roles">{{"Rôle de l'utilisateur" | ehp_translate }}</label>
        <ehp-user-roles-input
          ehp-form-control
          formControlName="roles"
          id="roles"
        ></ehp-user-roles-input>
      </ehp-form-field>

      <ehp-form-field class="form-field">
        <ehp-toggle
          ehp-form-control
          formControlName="blocked"
          label="{{ 'Utilisateur bloqué' | ehp_translate }}">
        </ehp-toggle>

      </ehp-form-field>

    </div>

  </div>

  <div class="form-row">

    <div class="row-header">
      <h2 class="row-title title">{{"Rattachement à un client" | ehp_translate }}</h2>
    </div>

    <div class="row-content">

      <ehp-form-field *ngIf="customers$">
        <label for="customerId">{{"Client rattaché à l'utilisateur" | ehp_translate }}</label>
        <ehp-customer-select
          [customers$]="customers$"
          ehp-form-control
          formControlName="customerId"
          id="customerId"
        ></ehp-customer-select>
      </ehp-form-field>

      <ehp-form-field *ngIf="facilities$">
        <label for="customerId">{{"Établissements assigné à l'utilisateur" | ehp_translate }}</label>
        <ehp-multi-facility-select
          [source$]="facilities$"
          ehp-form-control
          formControlName="facilityIds"
          id="facilityIds"
        ></ehp-multi-facility-select>
      </ehp-form-field>

    </div>

  </div>

  <div class="form-row">

    <div class="row-header">
      <h2 class="row-title title">{{"Informations complémentaires" | ehp_translate }}</h2>
    </div>

    <div class="row-content">

      <ehp-form-field class="form-field">
        <label>{{'Poste occupé' | ehp_translate }}</label>
        <ehp-async-select [options]="jobOptions$" ehp-form-control formControlName="job"></ehp-async-select>
      </ehp-form-field>

      <ehp-form-field class="form-field">
        <label>{{'Formation initiale' | ehp_translate }}</label>

        <ehp-async-select [options]="initialTrainingOption$" ehp-form-control
                          formControlName="initialTraining"></ehp-async-select>
      </ehp-form-field>

      <ehp-form-field class="form-field">
        <label>{{'Niveau d\'étude actuel' | ehp_translate }}</label>

        <ehp-async-select [options]="studyLevelOptions$" ehp-form-control
                          formControlName="studyLevel"></ehp-async-select>
      </ehp-form-field>

    </div>

  </div>

  <div class="actions">
    <button [disabled]="!form.valid" class="primary" type="submit">
      <ehp-translatable-string [innerHTML]="submitLabel"></ehp-translatable-string>
    </button>

  </div>
</form>
