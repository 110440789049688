import { PhoneNumber } from '@domain/phone';


/**
 * Check if the provided object implements the PhoneNumber interface.
 * @param phone - The object to be checked.
 * @returns {boolean} - Returns true if the object implements the PhoneNumber interface, false otherwise.
 */
export function isPhoneNumber(phone: any): phone is PhoneNumber {
  return (
    phone !== null &&
    typeof phone === 'object' &&
    typeof (phone as PhoneNumber).number === 'string' &&
    typeof (phone as PhoneNumber).region === 'string' &&
    typeof (phone as PhoneNumber).toString === 'function'
  );
}
