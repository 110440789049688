import {LoginCommand, TryAutoReconnectCommand} from "@application/bundles/authentification";
import {CommandBus, CommandHandler, HandleCommand} from "@application/framework/command-query";
import {
  EasyBackendReconnectStrategy
} from "@implementations/bundles/backend/easyhpad/strategies/authentification/reconnect.strategy";

@HandleCommand({
  command: TryAutoReconnectCommand
})
export class TryAutoReconnectCommandHandler implements CommandHandler<TryAutoReconnectCommand> {

  constructor(
    private readonly commandBus: CommandBus,
    private readonly strategy: EasyBackendReconnectStrategy
  ) {
  }

  public handle(command: TryAutoReconnectCommand): Promise<any> {
    return this.commandBus.execute(new LoginCommand(this.strategy));
  }


}
