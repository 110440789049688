import { UpdateCustomerDto } from '@application/bundles/customer/dto/update-customer.dto';
import { CustomerDtoFactory } from '@application/bundles/customer/factories';
import { Transformer } from '@application/framework/core';
import { EasyUpdateCustomerDto } from '@implementations/bundles/backend/easyhpad/interfaces';
import { Provide, Service } from '@application/framework/di';
import { EasyCommonCustomerTransformer } from '@implementations/bundles/backend/easyhpad/transformers/customer/easy-common-customer/easy-common-customer.transformer';
import { Address } from '@domain/address';
import {
  EasyAddress,
  EasyCreateAddress,
} from '@implementations/bundles/backend/easyhpad/interfaces/easy-address.interface';

@Service()
export class EasyCustomerUpdateDtoTransformer implements Transformer<UpdateCustomerDto, EasyUpdateCustomerDto> {
  @Provide() private readonly commonTransformer!: EasyCommonCustomerTransformer;
  @Provide() private readonly factory!: CustomerDtoFactory;

  public transform(value: UpdateCustomerDto): EasyUpdateCustomerDto {
    return {
      ...this.commonTransformer.transform(value),
      address: value.address ? this.transformAddress(value.address) : undefined,
      billingAddress: value.billingAddress ? this.transformAddress(value.billingAddress) : undefined,
    };
  }

  public reverseTransform(value: EasyUpdateCustomerDto & { id: UpdateCustomerDto['id'] }): UpdateCustomerDto {
    return this.factory.newUpdateCustomerDtoFromUnsafeValues({
      ...this.commonTransformer.reverseTransform(value),
      id: value.id,
    });
  }

  public transformAddress(address: Omit<Address, 'id'>): EasyCreateAddress;
  public transformAddress(address: Address): EasyAddress;

  public transformAddress(address: Omit<Address, 'id'> | Address): EasyCreateAddress | EasyAddress {
    if ('id' in address && address.id) {
      return this.commonTransformer.addressTransformer.transform(address);
    }
    return this.commonTransformer.addressTransformer.transformCreateAddress(address);
  }

  public reverseTransformAddress(address: EasyCreateAddress): Omit<Address, 'id'>;
  public reverseTransformAddress(address: EasyAddress): Address;

  public reverseTransformAddress(address: EasyCreateAddress | EasyAddress): Omit<Address, 'id'> | Address {
    if ('id' in address && address.id) {
      return this.commonTransformer.addressTransformer.reverseTransform(address);
    }
    return this.commonTransformer.addressTransformer.reverseTransformCreateAddress(address);
  }
}
