import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import {
  AccommodationFundingUpdateRateRepository,
  ListAccommodationFundingUpdateRatesQuery,
} from '@application/bundles/accommodation-funding';
import { UpdateRate } from '@domain/funding/entities/update-rate';

@HandleQuery({
  query: ListAccommodationFundingUpdateRatesQuery,
})
export class ListAccommodationFundingUpdateRatesQueryHandler
  implements QueryHandler<ListAccommodationFundingUpdateRatesQuery>
{
  constructor(private readonly repository: AccommodationFundingUpdateRateRepository) {}

  public async handle(query: ListAccommodationFundingUpdateRatesQuery): Promise<UpdateRate[]> {
    const rates = await this.repository.find();

    return rates.sort((a, b) => {
      return a.date.getTime() < b.date.getTime() ? 1 : -1;
    });
  }
}
