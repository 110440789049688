import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { UpdateRate } from '@domain/funding';
import { HealthFundingUpdateRateRepository, ListHealthFundingUpdateRatesQuery } from '@application/bundles/funding';

@HandleQuery({
  query: ListHealthFundingUpdateRatesQuery,
})
export class ListHealthUpdateRatesQueryHandler implements QueryHandler<ListHealthFundingUpdateRatesQuery> {
  constructor(private readonly repository: HealthFundingUpdateRateRepository) {}

  public async handle(query: ListHealthFundingUpdateRatesQuery): Promise<UpdateRate[]> {
    const rates = await this.repository.find();

    return rates.sort((a, b) => {
      return a.date.getTime() < b.date.getTime() ? 1 : -1;
    });
  }
}
