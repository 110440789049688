import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidBillingMode} from "@domain/customer";

@ValidatorConstraint({name: 'isBillingMode', async: false})
export class isBillingModeConstraint implements ValidatorConstraintInterface {

  public validate(option: any, args?: ValidationArguments): boolean {
    return isValidBillingMode(option);
  }

  public defaultMessage(args?: ValidationArguments): string {
    return 'This mode cannot be used as valid Billing mode.';
  }
}


export function IsBillingMode(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: isBillingModeConstraint,
    });
  };
}
