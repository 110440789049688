<div class="top">
    <a class="logo-wrapper" routerLink="/">
        <span class="visually-hidden">{{'Retour au tableau de bord' | ehp_translate}}</span>
        <ehp-logo aria-hidden="true"></ehp-logo>
    </a>

    <ehp-current-customer-selector *ngIf="customerSelectorEnabled"></ehp-current-customer-selector>

    <div class="selector-container">
        <ehp-current-facilities-selector></ehp-current-facilities-selector>
        <ehp-file-deposits-button></ehp-file-deposits-button>
    </div>

</div>

<div class="bottom">

    <div class="nav-container">
        <nav class="branding-nav">
            <ul class="menu root">

                <ng-container *ngIf="isOnboarded$ | async">

                    <li class="root-item">
                        <a
                                [routerLinkActiveOptions]="{exact:true}"
                                ariaCurrentWhenActive="page"
                                class="link-dashboard label "
                                routerLink="/"
                                routerLinkActive="active">
                            {{ 'Tableau de bord' | ehp_translate }}
                        </a>
                    </li>

                    <li *ngIf="URLS.benchmarking" class="root-item">
                        <a
                                [routerLinkActiveOptions]="{exact:true}"
                                [routerLink]="URLS.benchmarking"
                                class="link-benchmarking label "
                                routerLinkActive="active">
                            {{ 'Benchmarking' | ehp_translate }}
                        </a>
                    </li>

                    <li *ngIf="URLS.simulation" class="root-item">
                        <a
                                [routerLinkActiveOptions]="{exact:true}"
                                [routerLink]="URLS.simulation"
                                class="link-simulation label "
                                routerLinkActive="active">
                            {{ 'Aide à la simulation' | ehp_translate }}
                        </a>
                    </li>

                    <li class="root-item has-sub-menu">

                      <span class="link-data label">
                        {{ 'Mes Données' | ehp_translate }}
                      </span>

                        <ul class="menu sub-menu">
                            <li *ngIf="canAccess('contrats')">
                                <span class="link-contrats label group-label">{{ 'Autorisations & contrats' | ehp_translate }}</span>
                                <ul class="menu sub-menu">
                                    <li *ngIf="canAccess(FEATURE.capacity_authorization)">
                                        <a [routerLink]="URLS.capacity_authorization" routerLinkActive="active">
                                            {{ 'Autorisation de capacité' | ehp_translate }}
                                        </a>
                                    </li>

                                    <li *ngIf="canAccess(FEATURE.cpom) && URLS.cpom">
                                        <a [routerLink]="URLS.cpom" routerLinkActive="active">
                                            {{ 'C.P.O.M.' | ehp_translate }}
                                        </a>
                                    </li>

                                    <li *ngIf="canAccess(FEATURE.gmp) && URLS.gmp">
                                        <a [routerLink]="URLS.gmp" routerLinkActive="active">
                                            {{ 'G.M.P.' | ehp_translate }}
                                        </a>
                                    </li>

                                    <li *ngIf="canAccess(FEATURE.pmp) && URLS.pmp">
                                        <a [routerLink]="URLS.pmp" routerLinkActive="active">
                                            {{ 'P.M.P.' | ehp_translate }}
                                        </a>
                                    </li>

                                    <li *ngIf="canAccess(FEATURE.ehpad_demain) && URLS.ehpad_demain">
                                        <a [routerLink]="URLS.ehpad_demain" routerLinkActive="active">
                                            {{ 'EHPAD de demain' | ehp_translate }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <span class="separator"></span>
                            <li *ngIf="canAccess('financial')">
                                <span class="link-financial label group-label">{{ 'Financements' | ehp_translate }}</span>
                                <ul class="menu sub-menu">

                                    <li *ngIf="canAccess(FEATURE.health_funding) && URLS.health_funding">
                                        <a [routerLink]="URLS.health_funding" routerLinkActive="active">
                                            {{ 'Financement soin' | ehp_translate }}
                                        </a>
                                    </li>

                                    <li *ngIf="canAccess(FEATURE.dependency_funding) && URLS.dependency_funding">
                                        <a [routerLink]="URLS.dependency_funding" routerLinkActive="active">
                                            {{ 'Financement dépendance' | ehp_translate }}
                                        </a>
                                    </li>

                                    <li *ngIf="canAccess(FEATURE.accommodation_funding) && URLS.accommodation_funding">
                                        <a [routerLink]="URLS.accommodation_funding" routerLinkActive="active">
                                            {{ 'Financement hébergement' | ehp_translate }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <span class="separator"></span>
                            <li *ngIf="canAccess('budget')">
                                <span class="link-budget label group-label">{{ 'Budgets' | ehp_translate }}</span>

                                <ul class="menu sub-menu">
                                    <li *ngIf="canAccess(FEATURE.eprd) && URLS.eprd">
                                        <a [routerLink]="URLS.eprd" routerLinkActive="active">
                                            {{ 'E.P.R.D' | ehp_translate }}
                                        </a>
                                    </li>

                                    <li *ngIf="canAccess(FEATURE.errd) && URLS.errd">
                                        <a [routerLink]="URLS.errd" routerLinkActive="active">
                                            {{ 'E.R.R.D' | ehp_translate }}
                                        </a>
                                    </li>

                                    <li *ngIf="canAccess(FEATURE.activity_annex) && URLS.activity_annex">
                                        <a [routerLink]="URLS.activity_annex" routerLinkActive="active">
                                            {{ "Annexe activité" | ehp_translate }}
                                        </a>
                                    </li>


                                    <li *ngIf="canAccess(FEATURE.balance_sheet) && URLS.balance_sheet">
                                        <a [routerLink]="URLS.balance_sheet" routerLinkActive="active">
                                            {{ 'Comptes de gestion' | ehp_translate }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li *ngIf="URLS.library" class="root-item">
                        <a
                                [routerLinkActiveOptions]="{exact:true}"
                                [routerLink]="URLS.library"
                                class="link-library label "
                                routerLinkActive="active">
                            {{ 'Bibliothèque' | ehp_translate }}
                        </a>
                    </li>

                    <span class="separator"></span>

                </ng-container>


                <li *ngIf="URLS.support">
                    <a [routerLinkActiveOptions]="{exact:true}" [routerLink]="URLS.support" class="link-support label"
                       routerLinkActive="active">
                        {{ 'Assistance' | ehp_translate }}
                    </a>
                </li>

                <!--<li>
                  <button (click)="openCalculator()" class="link-calculator link label" type="button">
                    {{ 'Simulateurs' | ehp_translate }}
                  </button>
                </li>-->


                <li *ngIf="canAccess('administration')" class="has-sub-menu">
                    <span class="link-administration label group-label">{{ 'Administration' | ehp_translate }}</span>
                    <ul class="menu sub-menu">
                        <li *ngIf="canAccess(FEATURE.customer) && URLS.customer_admin">
                            <a [routerLink]="URLS.customer_admin" class="label" routerLinkActive="active">
                                {{ 'Clients' | ehp_translate }}
                            </a>
                        </li>
                        <li *ngIf="canAccess(FEATURE.user) && URLS.user_admin">
                            <a [routerLink]="URLS.user_admin" class="label" routerLinkActive="active">
                                {{ 'Utilisateurs' | ehp_translate }}
                            </a>
                        </li>
                        <li *ngIf="canAccess(FEATURE.facility) && URLS.facility_admin">
                            <a [routerLink]="URLS.facility_admin" class="label" routerLinkActive="active">
                                {{ 'Établissements' | ehp_translate }}
                            </a>
                        </li>
                        <li *ngIf="canAccess(FEATURE.file_deposit) && URLS.file_deposit">
                            <a [routerLink]="URLS.file_deposit" class="label" routerLinkActive="active">
                                {{ 'Dépôts de fichier' | ehp_translate }}
                            </a>
                        </li>

                        <li *ngIf="canAccess(FEATURE.demo) && URLS.demo">
                            <a [routerLink]="URLS.demo" class="label" routerLinkActive="active">
                                {{ 'Démo' | ehp_translate }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>

    <div class="user-container">

        <ng-container *ngIf="(user$ | async) as user">

            <div *ngIf="user" class="user-wrapper">
                <div class="name-container">
                    <h3 class="username">{{user.displayName}}</h3>
                    <a [routerLink]="URLS.user_profile" class="button is-icon icon-gear small ghost primary">
                        <span class="label">{{'Modifier mon profile' | ehp_translate }}</span>
                    </a>
                </div>
                <a class="logout-link" routerLink="{{ logoutPath }}">{{ 'Se déconnecter'|ehp_translate }}</a>
            </div>

        </ng-container>

    </div>

</div>
