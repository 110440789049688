import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export interface CPOMDocumentParams {
}


export interface SerializedCPOMSearchParams extends SerializedDocumentParams<CPOMDocumentParams> {
}


export abstract class ListCPOMParams extends DocumentListParams implements Serializable<SerializedCPOMSearchParams> {

}
