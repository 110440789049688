import { DependenciesInjectionConfiguration } from '@application/framework/di';
import { injectServiceInPropertyDescriptor } from '@application/framework/di/functions/inject-service-in-property-descriptor.function';
import { resolveToTypeWrapper } from '@application/framework/di/functions/resolve-type';
import { setProvideReflectionMetadata } from '@application/framework/di/functions/set-provide-reflection-metadata.function';
import { ProvideOptions } from '@application/framework/di/interfaces/provide-options.interface';
import { ServiceIdentifier } from '@application/framework/di/types/service-identifier';
import { TypeOrIdentifier } from '@application/framework/di/types/type-or-identifier';
import { Constructable } from '@application/framework/lib';

export function Provide(): Function;
export function Provide(typeFn?: (type?: never) => Constructable<unknown>): Function;
export function Provide(serviceName?: string | Symbol): Function;
export function Provide(
  typeOrIdentifier?: TypeOrIdentifier | ServiceIdentifier,
): PropertyDecorator | ParameterDecorator {
  return (target, propertyKey, index) => {
    const options: ProvideOptions = {
      propertyKey,
      index,
      ...resolveToTypeWrapper(typeOrIdentifier, target, propertyKey, index),
    };

    if (propertyKey !== undefined && options.id !== null) {
      injectServiceInPropertyDescriptor(target, propertyKey, options.id);
    } else if (index !== undefined) {
      target = setProvideReflectionMetadata(target, index, options);
      DependenciesInjectionConfiguration.provideDecorator(target, options);
    }
  };
}
