import {FinancialAnnex, isFinancialAnnex, isFinancialAnnexData, PartialActivityAnnex} from "@domain/eprd-errd";
import {IsFundingAmount, IsPositiveFundingAmount} from "@implementations/bundles/funding/validators";
import {IsOptional} from "class-validator";
import {IsValidFacilityId} from "@implementations/bundles/facility";
import {Facility} from "@domain/facility";
import {isPositiveFundingAmount, isValidFundingAmount} from "@domain/funding";
import {isValidFacilityIdType} from "@application/bundles/facility";
import {LocalCommonAbstractDtoType} from "@implementations/bundles/eprd-errd";
import {FinancialAnnexImpl} from "@implementations/bundles/eprd-errd/implementations";
import {isPartialActivityAnnex} from "@domain/activity-annex";


export abstract class LocalCommonAbstractDto implements LocalCommonAbstractDtoType {

  @IsPositiveFundingAmount()
  public expense: number = 0;

  @IsPositiveFundingAmount()
  public revenue: number = 0;

  @IsFundingAmount()
  @IsOptional()
  public CAF: number | undefined;

  public activityAnnex!: PartialActivityAnnex;

  public financialAnnex!: FinancialAnnex;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  constructor(defaults?: Partial<LocalCommonAbstractDtoType>) {

    if (defaults) {

      if (isPositiveFundingAmount(defaults.revenue)) {
        this.revenue = defaults.revenue;
      }

      if (isPositiveFundingAmount(defaults.expense)) {
        this.expense = defaults.expense;
      }

      if (isValidFundingAmount(defaults.CAF)) {
        this.CAF = defaults.CAF;
      }

      if (isPartialActivityAnnex(defaults.activityAnnex)) {
        this.activityAnnex = defaults.activityAnnex;
      }

      if (isFinancialAnnex(defaults.financialAnnex)) {
        this.financialAnnex = defaults.financialAnnex;
      } else if (isFinancialAnnexData(defaults.financialAnnex)) {
        this.financialAnnex = new FinancialAnnexImpl(defaults.financialAnnex);
      }

      if (isValidFacilityIdType(defaults.facilityId)) {
        this.facilityId = defaults.facilityId;
      }

    }
  }

}
