<ng-autocomplete
  #autocomplete
  (inputChanged)="onChangeSearch($event)"
  (inputCleared)="cleared($event)"
  (selected)="selected($event)"
  [data]="customers"
  [disabled]="disabled"
  [itemTemplate]="itemTemplate"
  [notFoundTemplate]="notFoundTemplate"
  [placeholder]="placeholder"
  [searchKeyword]="keyword"
>
</ng-autocomplete>

<ng-template #itemTemplate let-item>
  <span [innerHTML]="item.name"></span>
</ng-template>

<ng-template #notFoundTemplate>
  <span>{{'Aucun client trouvé'|ehp_translate}}</span>
</ng-template>

