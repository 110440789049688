<ehp-page-header>

  <h1 class="title page-title" pageTitle>
    <span class="trail">{{ 'Administration' | ehp_translate }}</span>
    <span class="trail">{{ 'Nouveau client' | ehp_translate }}</span>
  </h1>

</ehp-page-header>

<ehp-page-content>
  <ehp-customer-form (submitted)="onCreation($event)" class="form" mode="create"></ehp-customer-form>
</ehp-page-content>
