import {Attribute} from "@application/bundles/authorization";
import {AuthentificationToken} from "@application/bundles/authentification";

export abstract class DecisionManager {

  /**
   * Decides whether the access is possible or not.
   *
   * @param token
   * @param attributes
   * @param object
   *
   * @return bool true if the access is granted, false otherwise
   */
  public abstract decide(token: AuthentificationToken, attributes: Attribute[], object: any): Promise<boolean>;
}


