import {GetAssignableRolesQuery} from "@application/bundles/authorization/queries";
import {RoleRepository} from "@application/bundles/authorization/role.repository";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {RoleCollection} from "@domain/authorization";
import {User} from "@domain/user";
import {AuthentificationTokenStore} from "@application/bundles/authentification";

@HandleQuery({
  query: GetAssignableRolesQuery
})
export class GetAssignableRolesQueryHandler implements QueryHandler<GetAssignableRolesQuery, RoleCollection> {

  constructor(
    private readonly tokenStorage: AuthentificationTokenStore,
    private readonly roleRepository: RoleRepository
  ) {
  }

  public async handle(query: GetAssignableRolesQuery): Promise<RoleCollection> {
    const token = this.tokenStorage.getToken();
    return this.roleRepository.getAssignableRolesByUser(token.id as User['id']);
  }

}
