import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export interface GMPDocumentParams {
}


export interface SerializedGMPSearchParams extends SerializedDocumentParams<GMPDocumentParams> {
}


export abstract class GMPSearchParams extends DocumentListParams implements Serializable<SerializedGMPSearchParams> {

}
