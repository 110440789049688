import { UpdateCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto';
import { InspectionReport } from '@domain/capacity-authorization';
import { LocalMedia, Media } from '@domain/media';
import { AbstractPartialCapacityAuthorization } from '@implementations/bundles/capacity-authorization/dtos/partial-capacity-authorization.abstract';
import { IsLocalMediaOrMedia, IsMediaId } from '@implementations/bundles/media';
import { IsArray, IsOptional } from 'class-validator';

export class UpdateCapacityAuthorizationDtoImpl
  extends AbstractPartialCapacityAuthorization
  implements UpdateCapacityAuthorizationDto
{
  @IsOptional()
  @IsMediaId()
  public authorizationId?: Media['id'];

  @IsOptional()
  @IsLocalMediaOrMedia()
  public authorization?: LocalMedia | Media;

  @IsOptional()
  @IsMediaId()
  public pvId?: Media['id'];

  @IsOptional()
  @IsLocalMediaOrMedia()
  public pv?: LocalMedia | Media;

  @IsArray()
  @IsOptional()
  public inspectionReports: Array<
    Omit<InspectionReport, 'id' | 'name' | 'medias' | 'authorization'> & { id?: InspectionReport['id'] }
  > = [];

  constructor(values: UpdateCapacityAuthorizationDto) {
    super(values);
    this.authorizationId = values.authorizationId;
    this.authorization = values.authorization;
    this.pvId = values.pvId;
    this.pv = values.pv;
    this.inspectionReports = values.inspectionReports;
  }
}
