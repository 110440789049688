import {Injectable} from "@angular/core";
import {joinPath} from "@application/framework/lib";
import {
  DependencyFundingAuthorizationChecker,
  DependencyFundingRepository
} from "@application/bundles/dependency-funding";
import {DependencyFunding} from "@domain/dependency-funding";

@Injectable()
export class DependencyFundingUrlsProvider {

  public static readonly base = 'dependency';

  public static readonly publicSchema = {
    list: {path: '', title: 'Financements dépendance'},
    create: {path: 'create', title: 'Créer un nouveau financement dépendance'},
    show: {path: ':id', title: 'Financement dépendance'},
    edit: {path: ':id/edit', title: 'Modifier le financement dépendance'},
  }

  constructor(
    public readonly authorization: DependencyFundingAuthorizationChecker,
    private readonly repository: DependencyFundingRepository
  ) {
  }

  public getPublicList(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList(): string {
    return joinPath('/', DependencyFundingUrlsProvider.base);
  }

  public getPublicCreate(): Promise<string | undefined> {
    return this.authorization.canCreate()
      .then(can => can ? this.getUnsafePublicCreate() : undefined);
  }

  public getUnsafePublicCreate(): string {
    return joinPath('/', DependencyFundingUrlsProvider.base, DependencyFundingUrlsProvider.publicSchema.create.path);
  }

  public getPublicShow(id: DependencyFunding['id']): Promise<string | undefined> {
    return this.authorization.canRead()
      .then(can => can ? this.getUnsafePublicShow(id) : undefined);
  }

  public getUnsafePublicShow(id: DependencyFunding['id']): string {
    return joinPath('/', DependencyFundingUrlsProvider.base, id.toString());
  }

  public async getPublicUpdate(id: DependencyFunding['id']): Promise<string | undefined> {
    const gmp = await this.repository.get(id);

    return this.authorization.canUpdate(gmp)
      .then(can => can ? this.getUnsafePublicUpdate(id) : undefined);
  }

  public getUnsafePublicUpdate(id: DependencyFunding['id']): string {
    return joinPath('/', DependencyFundingUrlsProvider.base, id.toString(), 'edit');
  }

}
