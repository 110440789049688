import { AfterViewInit, Component, Injector, OnDestroy, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { TranslatableString } from '@application/framework/translation';
import { ActivatedRoute } from '@angular/router';
import { DocumentViewResolver } from '@easyhpad-ui/app/bundles/documents/services';
import {
  DocumentEditViewContainer,
  DocumentEditViewProcessor,
  DocumentEditViewProcessorInstance,
} from '@easyhpad-ui/app/bundles/documents/contracts/document-edit-view';
import { DocumentViewProcessorProvider } from '@easyhpad-ui/app/bundles/documents/contracts/document-view';

@Component({
  selector: 'ehp-document-edit-view-page',
  templateUrl: './document-edit-view-page.component.html',
  styleUrl: './document-edit-view-page.component.scss',
  host: {
    class: 'document-edit-view-page',
  },
})
export class DocumentEditViewPageComponent implements AfterViewInit, OnDestroy, DocumentEditViewContainer {
  @ViewChild('title', { read: ViewContainerRef })
  public readonly titleContainer!: ViewContainerRef;

  @ViewChild('form', { read: ViewContainerRef })
  public readonly formContainer!: ViewContainerRef;

  public readonly title$ = new Subject<Array<string | TranslatableString>>();

  public readonly documentId = new BehaviorSubject<string | undefined>(undefined);

  private processorName: Type<DocumentEditViewProcessor> | undefined;

  private processorInstance: DocumentEditViewProcessorInstance | undefined;

  private providerChange$ = new Subject<void>();

  private viewIsInit = false;

  private destroy$ = new Subject<void>();

  constructor(
    public readonly route: ActivatedRoute,
    private readonly resolver: DocumentViewResolver,
    private readonly injector: Injector,
  ) {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.loadProcessor(data['documentType']);
    });

    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.has('id')) {
        this.documentId.next(params.get('id') as string);
      }
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.providerChange$.next();
    this.processorInstance?.destroy();
  }

  public ngAfterViewInit() {
    this.viewIsInit = true;

    // Prevent error for change detection
    const timer = setTimeout(() => {
      this.render();
      clearTimeout(timer);
    }, 0);
  }

  private render() {
    if (!this.processorName) {
      return;
    }
    const processor = this.injector.get(this.processorName);
    this.processorInstance = processor.buildEditView(this);

    this.processorInstance.title.pipe(takeUntil(this.providerChange$)).subscribe(trails => {
      this.title$.next(trails);
    });

    this.processorInstance.attachDocumentId(this.documentId);
  }

  private loadProcessor(type?: DocumentViewProcessorProvider<any, any>['documentType']): void {
    if (!type) {
      throw new Error(
        'Missing document type in route data for the current "edit" view. The processor cannot be retrieve',
      );
    }

    const provider = this.resolver.resolve(type, 'edit');

    if (!provider) {
      throw new Error(`Missing Document view processor provider for ${type.toString()} (edit)`);
    }

    this.processorName = provider.processor;

    if (this.viewIsInit) {
      this.processorChange();
    }
  }

  private processorChange(): void {
    this.providerChange$.next();
    this.render();
  }
}
