import {Component, Input} from '@angular/core';
import {HealthFunding} from "@domain/health-funding";

@Component({
  selector: 'ehp-health-funding-list',
  templateUrl: './health-funding-list.component.html',
  styleUrls: ['./health-funding-list.component.scss'],
  host: {
    'class': 'document-list'
  }
})
export class HealthFundingListComponent {

  @Input() public documents: HealthFunding[] = [];

}
