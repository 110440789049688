<ehp-page-header class="document-view-header document-list-view-page-header">

  <div class="page-title-container" pageTitle>
    <h1 *ngIf="title$ | async as trails" class="title page-title">
      <ehp-translatable-string
        *ngFor="let trail of trails"
        [value]="trail"
        class="trail">
      </ehp-translatable-string>
    </h1>
  </div>


</ehp-page-header>

<ehp-page-content>
  <ng-container #form></ng-container>
</ehp-page-content>
