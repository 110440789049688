import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResidentGirListFactory } from '@application/bundles/gir/factories/resident-gir-list/resident-gir-list.factory';
import { QueryBus } from '@application/framework/command-query';
import { Subscription } from '@application/framework/reactive';
import { TranslatableString } from '@application/framework/translation';
import { roundCapacity } from '@domain/capacity-authorization';
import { Facility } from '@domain/facility';
import { ComputableResidentGIRList, GIR } from '@domain/gir';
import { CapacityAuthorizationUrlsProvider } from '@easyhpad-ui/app/bundles/capacity-authorization/providers/capacity-authorization-urls/capacity-authorization-urls.provider';
import { AuthorizedCapacitiesWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';
import { GetAuthorizedCapacityWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-authorized-capacity-widget.query';
import { selectDashboardFacilities } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.selector';
import { AppState } from '@easyhpad-ui/app/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ehp-widget-authorized-capacities',
  templateUrl: './widget-authorized-capacities.component.html',
  styleUrls: ['./widget-authorized-capacities.component.scss'],
  host: {
    '[class.has-gir-bar]': '!!girList',
  },
})
export class WidgetAuthorizedCapacitiesComponent implements OnInit, OnDestroy {
  public isEmpty: boolean = false;

  public total: number | undefined;

  public permanent: number | undefined;

  public temporary: number | undefined;

  public dayCare: number | undefined;

  public bedSocialCare: TranslatableString | undefined;

  public average: { value: number; total: number } = { value: 0, total: 0 };

  public girList: ComputableResidentGIRList | undefined;

  public createUrl: string | null | undefined;

  private loading: boolean = false;

  private url: string = '';

  private subscription: Subscription | undefined;

  constructor(
    private readonly store: Store<AppState>,
    private readonly queryBus: QueryBus,
    private readonly urls: CapacityAuthorizationUrlsProvider,
    private readonly girListFactory: ResidentGirListFactory,
  ) {}

  public ngOnInit() {
    this.urls.getPublicCreate().then(url => (this.createUrl = url));

    this.subscription = this.store
      .select(selectDashboardFacilities)
      .subscribe(facilities => this.facilitiesChanges(facilities));
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public isDisplayableValue(value: any) {
    return value !== undefined && !isNaN(value) && isFinite(value);
  }

  private async facilitiesChanges(facilities: Array<Facility['id']>): Promise<void> {
    this.reset();
    this.loading = true;

    this.queryBus
      .request<AuthorizedCapacitiesWidgetContent>(new GetAuthorizedCapacityWidgetQuery(facilities))
      .then(content => {
        if (content.facilityId && !content.id) {
          this.isEmpty = true;
        }

        this.setAuthorizedCapacities(content);
        this.setGirList(content);
        this.loading = false;
      });
  }

  private setAuthorizedCapacities(values: AuthorizedCapacitiesWidgetContent): void {
    this.total = roundCapacity(values.total);
    this.permanent = roundCapacity(values.permanentCapacity);
    this.temporary = roundCapacity(values.temporaryCapacity);
    this.dayCare = roundCapacity(values.daycareCapacity);

    if (values.average) {
      this.average = {
        value: values.average.value,
        total: values.average.total,
      };
    }

    const bedSocialCare = Math.round((values.bedSocialCare + Number.EPSILON) * 100) / 100;

    if (bedSocialCare > 0) {
      const message =
        bedSocialCare > 1
          ? "Dont <strong>{{count}}</strong> lits habilités à l'aide sociale"
          : "Dont <strong>{{count}}</strong> lit habilité à l'aide sociale";
      this.bedSocialCare = new TranslatableString(message, { count: bedSocialCare });
    }
  }

  private setGirList(values: AuthorizedCapacitiesWidgetContent): void {
    if (!values.gir) {
      return;
    }

    const gir = new Map<GIR, number>([
      [GIR.GIR1, values.gir.GIR1],
      [GIR.GIR2, values.gir.GIR2],
      [GIR.GIR3, values.gir.GIR3],
      [GIR.GIR4, values.gir.GIR4],
      [GIR.GIR5, values.gir.GIR5],
      [GIR.GIR6, values.gir.GIR6],
    ]);

    this.girList = this.girListFactory.createComputableResidentGIRList(gir);
  }

  private reset() {
    this.isEmpty = false;
    this.url = '';

    this.total = undefined;
    this.permanent = undefined;
    this.temporary = undefined;
    this.dayCare = undefined;
    this.bedSocialCare = undefined;

    this.average = { value: 0, total: 0 };
    this.girList = undefined;
  }
}
