import { createReducer, on } from '@ngrx/store';
import { FileDepositStoreState } from './file-deposit.state';
import { FileDepositActions } from './file-deposit.actions';

const initialState: FileDepositStoreState = {
  loading: false,
  current: null,
  entities: [],
  onboarding: [],
};

export const fileDepositReducer = createReducer(
  initialState,
  on(FileDepositActions.loadFileDeposits, state => ({
    ...state,
    loading: true,
  })),
  on(FileDepositActions.setFileDeposits, (state, { deposits }) => ({
    ...state,
    loading: false,
    entities: deposits,
  })),
  on(FileDepositActions.loadSingleFileDeposit, state => ({
    ...state,
    loading: true,
  })),
  on(FileDepositActions.setCurrentFileDeposit, (state, { deposit }) => ({
    ...state,
    current: deposit,
    loading: false,
  })),
  on(FileDepositActions.loadOnboardingFileDeposit, state => ({
    ...state,
    loading: true,
  })),
  on(FileDepositActions.setOnboardingFileDeposit, (state, { deposits }) => ({
    ...state,
    loading: false,
    onboarding: deposits,
  })),
);
