import { Dialog } from '@angular/cdk/dialog';
import { CommandBus, CommandHandler, HandleCommand } from '@application/framework/command-query';
import { ConfirmDeletionDialogComponent } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component';
import { ConfirmDeletionDialogData } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface';
import { DialogConfiguration } from '@easyhpad-ui/app/library/dialog';
import { OpenEPRDDeletionDialogCommand } from '@easyhpad-ui/app/bundles/eprd-errd/commands';
import { DeleteEPRDCommand } from '@application/bundles/eprd-errd';
import { EPRD } from '@domain/eprd-errd';

@HandleCommand({
  command: OpenEPRDDeletionDialogCommand,
})
export class OpenEPRDDeletionDialogCommandHandler implements CommandHandler<OpenEPRDDeletionDialogCommand, void> {
  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus,
  ) {}

  public async handle(command: OpenEPRDDeletionDialogCommand): Promise<void> {
    const { eprd, onDeletion } = command;

    const data: ConfirmDeletionDialogData = {
      options: [{ key: eprd.id, label: `E.P.R.D. ${eprd.accountingYear}` }],
    };

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<EPRD>(new DeleteEPRDCommand(eprd));

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    };

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, { ...DialogConfiguration, data });
    dialog.closed.subscribe(subscriber);
  }
}
