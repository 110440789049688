import { isValidEhpadDemainIdType } from '@application/bundles/ehpad-demain';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { isValidMediaIdType } from '@application/bundles/media';
import { QueryBus } from '@application/framework/command-query';
import { Provide } from '@application/framework/di';
import { EHPAD_DEMAIN_TYPE, EhpadDemain, EhpadDemainType, isValidEhpadDemainTypeValue } from '@domain/ehpad-demain';
import { Facility } from '@domain/facility';
import { isPositiveFundingAmount } from '@domain/funding';
import { UUID } from '@domain/lib';
import { Media, MediaBucket } from '@domain/media';
import { EhpadDemainTypeCollection } from '@implementations/bundles/ehpad-demain/collections';

export class EhpadDemainImpl implements EhpadDemain {
  public id!: UUID;

  public name!: string;

  public amount!: number;

  public comment: string | undefined;

  public start!: Date;

  public end!: Date;

  public typeId!: EHPAD_DEMAIN_TYPE;

  public mediasIds: Array<Media['id']> = [];

  public conventionId: Media['id'] | undefined;

  public otherConventionId: Media['id'] | undefined;

  public facilitiesIds: Array<Facility['id']> = [];

  public createdAt?: Date;

  public updatedAt?: Date;

  @Provide()
  private readonly mediaBucket!: MediaBucket;

  @Provide()
  private readonly queryBus!: QueryBus;

  @Provide()
  private readonly typeCollection!: EhpadDemainTypeCollection;

  public get hasConvention(): boolean {
    return !!this.conventionId;
  }

  constructor(
    values?: Partial<
      Omit<EhpadDemain, 'medias' | 'convention' | 'otherAuthorityConvention' | 'otherConvention' | 'facilities'>
    >,
  ) {
    if (values) {
      if (isValidEhpadDemainIdType(values.id)) {
        this.id = values.id;
      }

      if (values.name) {
        this.name = values.name;
      }

      if (isPositiveFundingAmount(values.amount)) {
        this.amount = values.amount;
      }

      if (values.comment) {
        this.comment = values.comment;
      }

      if (values.start instanceof Date) {
        this.start = values.start;
      }

      if (values.end instanceof Date) {
        this.end = values.end;
      }

      if (isValidEhpadDemainTypeValue(values.typeId)) {
        this.typeId = values.typeId;
      }

      if (Array.isArray(values.mediasIds)) {
        this.mediasIds = values.mediasIds;
      }

      if (Array.isArray(values.facilitiesIds)) {
        this.facilitiesIds = values.facilitiesIds;
      }

      this.conventionId = isValidMediaIdType(values.conventionId) ? values.conventionId : undefined;
      this.otherConventionId = isValidMediaIdType(values.otherConventionId) ? values.otherConventionId : undefined;
      this.createdAt = values.createdAt;
      this.updatedAt = values.updatedAt;
    }
  }

  public type(): Promise<EhpadDemainType | undefined> {
    return this.typeCollection.get(this.typeId);
  }

  public medias(): Promise<Media[]> {
    if (Array.isArray(this.mediasIds) && this.mediasIds.length >= 1) {
      return Promise.all(this.mediasIds.map(id => this.mediaBucket.get(id)));
    }

    return Promise.resolve([]);
  }

  public convention(): Promise<Media | undefined> {
    if (isValidMediaIdType(this.conventionId)) {
      return this.mediaBucket.get(this.conventionId);
    }
    return Promise.resolve(undefined);
  }

  public otherConvention(): Promise<Media | undefined> {
    if (isValidMediaIdType(this.otherConventionId)) {
      return this.mediaBucket.get(this.otherConventionId);
    }

    return Promise.resolve(undefined);
  }

  public facilities(): Promise<Facility[]> {
    if (Array.isArray(this.facilitiesIds) && this.facilitiesIds.length > 0) {
      return Promise.all(this.facilitiesIds.map(id => this.queryBus.request<Facility>(new GetFacilityQuery(id))));
    }

    return Promise.resolve([]);
  }
}
