import {GetFacilityQuery} from "@application/bundles/facility/query/get-facility.query";
import {HandleQuery} from "@application/framework/command-query";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {Facility} from "@domain/facility";
import {FacilityRepository} from "@domain/facility/facility.repository";

@HandleQuery({
  query: GetFacilityQuery
})
export class GetFacilityQueryHandler implements QueryHandler<GetFacilityQuery, Facility> {


  constructor(private readonly repository: FacilityRepository) {
  }

  public handle(query: GetFacilityQuery): Promise<Facility> {
    return this.repository.get(query.id);
  }

}
