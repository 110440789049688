import {CreatePermanentDependencyFundingDtoImpl} from "./create-permanent-dependency-funding.dto";
import {
  isValidPermanentDependencyFundingIdType,
  UpdatePermanentDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {IsValidPermanentDependencyFundingId} from "@implementations/bundles/dependency-funding/validators";

export class UpdatePermanentDependencyFundingDtoImpl extends CreatePermanentDependencyFundingDtoImpl implements UpdatePermanentDependencyFundingDto {

  @IsValidPermanentDependencyFundingId()
  public id!: number;

  constructor(values?: Partial<UpdatePermanentDependencyFundingDto>) {
    super(values);

    if (values && isValidPermanentDependencyFundingIdType(values.id)) {
      this.id = values.id;
    }
  }

}
