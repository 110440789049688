import {Component, Input} from '@angular/core';
import {GMP} from "@domain/gmp";

@Component({
  selector: 'ehp-gmp-list-item',
  templateUrl: './gmp-list-item.component.html',
  styleUrls: ['./gmp-list-item.component.scss'],
  host: {
    'class': 'document-list-item'
  }
})
export class GmpListItemComponent {

  @Input() public gmp!: GMP;

}
