<header class="widget-header">

  <h3 class="widget-title title">{{'Mes indicateurs validés' | ehp_translate }}</h3>

</header>

<div class="widget-content">

  <ng-container *ngIf="indicators">

    <ng-container *ngFor="let indicator of indicatorArray">

      <div *ngIf="indicator" class="block">

        <div class="block-content">
          <div class="name">
            <ehp-translatable-string [value]="indicator.label" class="label"></ehp-translatable-string>
            <span *ngIf="indicator.date" class="date">{{'Validé le' | ehp_translate}} {{indicator.date}}</span>
          </div>

          <div *ngIf="indicator.count > 0; else empty" class="content">
            <div class="indicator">
              <span class="value">{{indicator.value | number:'1.0-0'}}</span>
            </div>

            <a *ngIf="indicator.url" [routerLink]="indicator.url" class="show-link target-link">
              {{'Voir le' | ehp_translate}} {{ indicator.label }}
            </a>
          </div>


          <ng-template #empty>
            <div class="empty">
              <p>{{indicator.emptyLabel}}</p>

              <a *ngIf="this.urls[indicator.type]" [routerLink]="this.urls[indicator.type]" class="button target-link">
                {{'Nouvel archivage' | ehp_translate}}
              </a>
            </div>
          </ng-template>
        </div>

        <ehp-average-message
          *ngIf="indicator.average"
          [total]="indicator.average.total"
          [value]="indicator.average.value"
          class="average-message"
        ></ehp-average-message>

      </div>


    </ng-container>

  </ng-container>


</div>

<div class="widget-footer">
</div>
