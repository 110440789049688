import {LocalMediaImpl, MediaImpl, TemporaryMediaImpl} from "@application/bundles/media";
import {Service} from "@application/framework/di";
import {LocalMedia, Media, TemporaryMedia} from "@domain/media/media.interface";

@Service()
export class MediaFactory {

  
  public createLocalMediaFromFile(file: File): LocalMedia {
    return new LocalMediaImpl(file);
  }

  public createMedia(values: Partial<Media>): Media {
    return new MediaImpl(values);
  }

  public createTemporaryMedia(media: Media): TemporaryMedia {
    return new TemporaryMediaImpl(media);
  }

}
