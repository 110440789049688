import { CreateFacilityDto } from '@application/bundles/facility/dto';
import { isFacilityState } from '@application/bundles/facility/validators';
import { Address } from '@domain/address';
import { Customer } from '@domain/customer';
import { FACILITY_STATE, FinessNumber } from '@domain/facility';
import { FACILITY_TYPE, SOCIAL_CLEARANCE } from '@domain/facility/types';
import { PhoneNumber } from '@domain/phone/phone.interface';

export class CreateFacilityDtoImpl implements CreateFacilityDto {
  public name: string;

  public type: FACILITY_TYPE;

  public address: Address;

  public state?: FACILITY_STATE;

  public customerId?: Customer['id'];

  public socialClearance: SOCIAL_CLEARANCE;

  public finessGeo?: FinessNumber;

  public finessLegal?: FinessNumber;

  public managingOrganization?: string;

  public phone?: PhoneNumber;

  constructor(values: CreateFacilityDto) {
    this.name = values.name;
    this.address = values.address;
    this.customerId = values.customerId;
    this.socialClearance = values.socialClearance;
    this.type = values.type;
    this.phone = values.phone;
    this.finessGeo = values.finessGeo;
    this.finessLegal = values.finessLegal;
    this.managingOrganization = values.managingOrganization;

    if (isFacilityState(values.state)) {
      this.state = values.state;
    }
  }
}
