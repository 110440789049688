export interface SelectionChange<T> {

  added: T[];

  removed: T[];

  current: T[];

  previous: T[];
}
