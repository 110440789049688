import { GIR } from './gir';

export type ResidentGIRList = Record<GIR, number>;

export interface ComputableResidentGIRList {
  get(level: GIR): number;

  unsafe(level: GIR): number | any;

  sum(): number;

  all(): Array<{ gir: GIR; value: number }>;
}
