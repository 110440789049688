import {GetCPOMQuery, CPOMRepository} from "@application/bundles/cpom";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {CPOM} from "@domain/cpom";

@HandleQuery({
  query: GetCPOMQuery
})
export class GetCPOMQueryHandler implements QueryHandler<GetCPOMQuery, CPOM> {

  constructor(private readonly repository: CPOMRepository) {
  }

  public async handle(query: GetCPOMQuery): Promise<CPOM> {
    return this.repository.get(query.id);
  }
}
