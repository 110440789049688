<header class="widget-header">
  <h3 class="widget-title title">{{"Option tarifaire C.P.O.M." | ehp_translate }}</h3>
</header>

<div class="widget-content">
  <ehp-tariff-option-value
    *ngIf="cpom && cpom.tariffOption; else noData"
    [option]="cpom.tariffOption"
    [postalCode]="facility?.address?.postalCode"
    [year]="cpom.year"
    class="tariff-option">
  </ehp-tariff-option-value>
</div>

<div class="widget-footer">
  <div *ngIf="cpom" class="date">
    {{ cpom.start | date:'MM/yyyy' }} - {{ cpom.end | date:'MM/yyyy' }}
  </div>

  <a *ngIf="url" [routerLink]="url" class="target-link">{{ "Consulter" }}</a>
</div>


<ng-template #noData>
  <div class="empty">
    <p>{{'Aucun C.P.O.M. renseigné pour cet établissement' | ehp_translate}}</p>
    <a *ngIf="createUrl" [routerLink]="createUrl" class="target-link">{{'Nouvel archivage' | ehp_translate}}</a>
  </div>
</ng-template>
