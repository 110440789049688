import {Component, HostListener, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {QueryBus} from "@application/framework/command-query";
import {Router} from "@angular/router";
import {AccommodationFunding} from "@domain/accommodation-funding";
import {AccommodationFundingUrlsProvider} from "@easyhpad-ui/app/bundles/accommodation-funding/providers/";
import {GetAccommodationFundingQuery} from "@application/bundles/accommodation-funding";

@Component({
  selector: 'ehp-accommodation-funding-link',
  templateUrl: './accommodation-funding-link.component.html',
  styleUrls: ['./accommodation-funding-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.link]': 'isLink',
    '[attr.role]': 'role',
  }
})
export class AccommodationFundingLinkComponent implements OnChanges {


  @Input() public funding!: AccommodationFunding;

  @Input() public refId!: AccommodationFunding['id'];

  private url: string | null | undefined;

  public get isLink(): boolean {
    return !!this.url;
  }

  public get role(): string | undefined {
    return this.isLink ? 'link' : undefined;
  }

  constructor(
    private readonly queryBus: QueryBus,
    private readonly urls: AccommodationFundingUrlsProvider,
    private readonly router: Router
  ) {
  }

  @HostListener('click', ['$event'])
  public async click(event: MouseEvent) {
    if (!this.isLink) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.url !== undefined && this.url !== null) {
      return this.router.navigateByUrl(this.url);
    }

    return false;

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['refId']) {

      if (changes['refId'].previousValue !== changes['refId'].currentValue) {
        this.loadAnnex();
      }
    }

    if (changes['funding']) {
      if (changes['funding'].previousValue !== changes['funding'].currentValue) {
        this.fundingHasChange();
      }
    }
  }

  private loadAnnex(): void {

    if (this.refId) {
      this.queryBus.request<AccommodationFunding>(new GetAccommodationFundingQuery(this.refId)).then(funding => {
        this.funding = funding;
        this.fundingHasChange();
      });

    }
  }

  private setUrl(): void {
    this.url = null;

    if (this.funding) {
      this.url = this.urls.getUnsafePublicShow(this.funding.id);
    }

  }

  private fundingHasChange(): void {
    this.setUrl();
  }
}
