import {getPreviousClosestNumber} from "../number/number.function";

/**
 * Alias of getPreviousClosestNumber() function
 * @param current
 * @param years
 * @see getPreviousClosestNumber
 */
export function getClosestYear(current: number, years: number[]): number | undefined {
  return getPreviousClosestNumber(current, years, true);
}


/**
 * Alias of getPreviousClosestNumber() function
 * @param current
 * @param years
 * @see getPreviousClosestNumber
 */
export function getPreviousClosestYear(current: number, years: number[]): number | undefined {
  return getPreviousClosestNumber(current, years);
}
