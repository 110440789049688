import {createReducer, on} from "@ngrx/store";
import {ERRDStoreState} from "./eprd-errd.store.state";
import {ERRDActions} from "@easyhpad-ui/app/bundles/eprd-errd/store/errd.actions";


const initialPublicState: ERRDStoreState['public'] = {
  loading: false,
  loaded: false,
  entities: [],
  page: 0,
  sort: undefined
}

const initialState: ERRDStoreState = {
  current: undefined,
  public: {
    ...initialPublicState
  }
};

export const errdReducer = createReducer(
  initialState,
  on(ERRDActions.loadPublicERRDDocuments, state => ({
    ...state,
    public: {...initialPublicState, ...state.public, loading: true}
  })),
  on(ERRDActions.setPublicERRDDocuments, (state, {documents}) => ({
    ...state,
    public: {...initialPublicState, ...state.public, entities: documents, loading: false, loaded: true}
  })),
  on(ERRDActions.resetERRDStore, () => ({
    ...initialState
  }))
);
