import { Facility } from '@domain/facility';
import { ResidentGIRList } from '@domain/gir';
import { GMP } from '@domain/gmp';
import { Media } from '@domain/media';

export class GMPImpl implements GMP {
  public id!: GMP['id'];

  public date!: Date;

  public value!: number;

  public residents!: ResidentGIRList;

  public facilityId!: Facility['id'];

  public pvId?: Media['id'];

  public readonly createdAt?: Date;

  public readonly updatedAt?: Date;

  public get year() {
    return this.date.getFullYear();
  }

  constructor(values?: Omit<GMP, 'year' | 'national'>) {
    if (values) {
      this.id = values.id;
      this.date = values.date;
      this.value = values.value;
      this.residents = values.residents;
      this.pvId = values.pvId;
      this.facilityId = values.facilityId;
      this.createdAt = values.createdAt;
      this.updatedAt = values.updatedAt;
    }
  }
}
