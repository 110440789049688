import { isValidObject } from '@application/framework/lib';
import { NonRecurringHealthFunding } from '@domain/health-funding';
import { isUUID } from '@domain/lib';
import { isValidTaxonomyTermIdType } from '@domain/taxonomy';

export function isValidNonRecurringHealthFundingIdType(id: any): id is NonRecurringHealthFunding['id'] {
  return isUUID(id);
}

export function isMaybeNonRecurringHealthFunding(funding: any): funding is Omit<NonRecurringHealthFunding, 'id'> {
  return (
    isValidObject(funding) &&
    (funding.comment === undefined || (typeof funding.comment === 'string' && funding.comment)) &&
    (funding.categoryId === undefined || isValidTaxonomyTermIdType((funding as NonRecurringHealthFunding).categoryId))
  );
}

export function isNonRecurringHealthFunding(funding: any): funding is NonRecurringHealthFunding {
  return (
    isMaybeNonRecurringHealthFunding(funding) &&
    isValidNonRecurringHealthFundingIdType((funding as NonRecurringHealthFunding).id)
  );
}
