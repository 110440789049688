import { Component, OnDestroy, OnInit } from '@angular/core';
import { Color } from '@swimlane/ngx-charts/lib/utils/color-sets';
import { ScaleType } from '@swimlane/ngx-charts';
import { QueryBus } from '@application/framework/command-query';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { selectDashboardFacilities } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.selector';
import { Subscription } from 'rxjs';
import { Facility } from '@domain/facility';
import { GetOccupationRateWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-occupation-rate-widget.query';
import { OccupationRateWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';

@Component({
  selector: 'ehp-widget-occupation-rate',
  templateUrl: './widget-occupation-rate.component.html',
  styleUrls: ['./widget-occupation-rate.component.scss'],
})
export class WidgetOccupationRateComponent implements OnInit, OnDestroy {
  public value: number = 0;

  public data = [
    {
      name: 'Occupation',
      value: 0,
    },
  ];

  public colors: Color = {
    name: 'custom',
    selectable: false,
    group: ScaleType.Linear,
    domain: [
      'var(--ehp--color--secondary--800)',
      'var(--ehp--color--secondary--500)',
      'var(--ehp--color--secondary--300)',
    ],
  };

  public chartOptions = {
    bigSegments: 0,
    smallSegments: 0,
    startAngle: 0,
    angleSpan: 360,
    showText: false,
    tooltipDisabled: true,
  };

  private subscription: Subscription | undefined;

  constructor(
    private readonly queryBus: QueryBus,
    private readonly store: Store<AppState>,
  ) {
    this.setValue(0);
  }

  public ngOnInit() {
    this.subscription = this.store
      .select(selectDashboardFacilities)
      .subscribe(facilities => this.facilitiesChanges(facilities));
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private setValue(average: number) {
    this.value = average;
    this.data = [{ name: '', value: average }];
  }

  private facilitiesChanges(facilities: Array<Facility['id']>) {
    this.queryBus.request<OccupationRateWidgetContent>(new GetOccupationRateWidgetQuery(facilities)).then(content => {
      this.setValue(content.value);
    });
  }
}
