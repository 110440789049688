import { EPRD, LocalEPRD } from '@domain/eprd-errd';
import { isValidEPRDIdType } from '@application/bundles/eprd-errd';
import { Media, MediaBucket } from '@domain/media';
import { Provide } from '@application/framework/di';
import { isValidMediaIdType } from '@application/bundles/media';
import { isValidYear, UUID, Year } from '@domain/lib';

export class EPRDImpl implements EPRD {
  public id!: UUID;

  public accountingYear!: Year;

  public depositDate: Date | undefined;

  public details: LocalEPRD[] = [];

  public activityAnnexDocumentId!: Media['id'];

  public completeDocumentId!: Media['id'];

  public financialAnnexDocumentId!: Media['id'];

  public payrollDocumentId!: Media['id'];

  public reportId: Media['id'] | undefined;

  @Provide()
  protected readonly mediaBucket!: MediaBucket;

  public get isSingle(): boolean {
    return this.details.length <= 1;
  }

  public get singleChild(): LocalEPRD | undefined {
    return this.isSingle ? this.details[0] : undefined;
  }

  constructor(defaults?: Partial<EPRD>) {
    if (defaults) {
      if (isValidEPRDIdType(defaults.id)) {
        this.id = defaults.id;
      }

      if (isValidYear(defaults.accountingYear)) {
        this.accountingYear = defaults.accountingYear;
      }

      if (defaults.depositDate instanceof Date) {
        this.depositDate = defaults.depositDate;
      }

      if (Array.isArray(defaults.details)) {
        this.details = defaults.details;
      }

      if (isValidMediaIdType(defaults.completeDocumentId)) {
        this.completeDocumentId = defaults.completeDocumentId;
      }
      if (isValidMediaIdType(defaults.payrollDocumentId)) {
        this.payrollDocumentId = defaults.payrollDocumentId;
      }

      if (isValidMediaIdType(defaults.financialAnnexDocumentId)) {
        this.financialAnnexDocumentId = defaults.financialAnnexDocumentId;
      }

      if (isValidMediaIdType(defaults.activityAnnexDocumentId)) {
        this.activityAnnexDocumentId = defaults.activityAnnexDocumentId;
      }

      if (isValidMediaIdType(defaults.reportId)) {
        this.reportId = defaults.reportId;
      }
    }
  }

  public completeDocument(): Promise<Media> {
    if (!isValidMediaIdType(this.completeDocumentId)) {
      return Promise.reject(`${this.completeDocumentId} is not a valid media id`);
    }
    return this.mediaBucket.get(this.completeDocumentId);
  }

  public payrollDocument(): Promise<Media | undefined> {
    if (!isValidMediaIdType(this.payrollDocumentId)) {
      return Promise.resolve(undefined);
    }
    return this.mediaBucket.get(this.payrollDocumentId);
  }

  public financialAnnexDocument(): Promise<Media | undefined> {
    if (!isValidMediaIdType(this.financialAnnexDocumentId)) {
      return Promise.resolve(undefined);
    }
    return this.mediaBucket.get(this.financialAnnexDocumentId);
  }

  public activityAnnexDocument(): Promise<Media | undefined> {
    if (!isValidMediaIdType(this.activityAnnexDocumentId)) {
      return Promise.resolve(undefined);
    }
    return this.mediaBucket.get(this.activityAnnexDocumentId);
  }

  public reportDocument(): Promise<Media | undefined> {
    if (!isValidMediaIdType(this.reportId)) {
      return Promise.resolve(undefined);
    }
    return this.mediaBucket.get(this.reportId);
  }
}
