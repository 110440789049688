import { Provide } from '@application/framework/di';

import {
  CreateHealthFundingDto,
  HealthFundingRepository,
  HealthFundingSearchParams,
  SerializedHealthFundingSearchParams,
  UpdateHealthFundingDto,
} from '@application/bundles/health-funding';
import { HealthFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers';
import { DocumentRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { HealthFunding } from '@domain/health-funding';
import { EasyHealthFunding } from '@implementations/bundles/backend/easyhpad';

export class EasyHealthFundingRepository
  extends DocumentRepository<
    HealthFundingSearchParams,
    SerializedHealthFundingSearchParams,
    HealthFunding,
    EasyHealthFunding
  >
  implements HealthFundingRepository
{
  @Provide() private readonly entityTransformer!: HealthFundingTransformer;

  constructor() {
    super('funding/health/entities', {
      from: input => this.entityTransformer.reverseTransform(input),
      to: output => this.entityTransformer.transform(output),
      create: (input: CreateHealthFundingDto) => this.entityTransformer.reverseTransformCreateDto(input),
      update: (input: UpdateHealthFundingDto) => this.entityTransformer.reverseTransformUpdateDto(input),
    });
  }
}
