export * from './list/list';
export * from './list/named-list';
export * from './object/object';
export * from './math/percentage/percentage';
export * from './date/year';
export * from './string';
export * from './uuid';

export interface Stringifiable {
  toString: () => string;
}

export type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

export type Year = number;

export type ObjectKey = string | number | symbol;
