import {Dialog} from "@angular/cdk/dialog";
import {DeleteInspectionReportCommand} from "@application/bundles/capacity-authorization/commands";
import {CommandBus, CommandHandler, HandleCommand} from "@application/framework/command-query";
import {InspectionReport} from "@domain/capacity-authorization";
import {
  ConfirmDeletionDialogComponent
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component";
import {
  ConfirmDeletionDialogData
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface";
import {DialogConfiguration} from "@easyhpad-ui/app/library/dialog";
import {OpenDeleteInspectionReportDialogCommand} from '../open-delete-inspection-report-dialog.command';

@HandleCommand({
  command: OpenDeleteInspectionReportDialogCommand
})
export class OpenDeleteInspectionReportDialogCommandHandler implements CommandHandler<OpenDeleteInspectionReportDialogCommand, void> {

  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus
  ) {
  }

  public async handle(command: OpenDeleteInspectionReportDialogCommand): Promise<void> {
    const {report, onDeletion} = command;
    const authorization = await report.authorization();
    const data: ConfirmDeletionDialogData = {
      options: [{key: report.id, label: report.name}]
    }

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const command = new DeleteInspectionReportCommand(report.id, authorization.id);
        const deleted = await this.commandBus.execute<InspectionReport>(command);

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    }

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, {...DialogConfiguration, data});
    dialog.closed.subscribe(subscriber);
  }

}
