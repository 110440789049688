import {DispatchableEvent, EventName} from "@application/framework/event/event.type";

export abstract class AbstractDispatchableEvent implements DispatchableEvent {

  /**
   * Use class constructor name
   */
  public get name(): EventName {
    return this.constructor.name;
  }
}
