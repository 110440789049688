import {
  CreateTemporaryDependencyFundingDto,
  UpdateTemporaryDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {DEPENDENCIES_FUNDING_MODES, TEMPORARY_DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding";


export abstract class TemporaryDependencyFundingDtoFactory {

  public abstract buildCreateDtoFromUnsafeValues<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = unknown>(values: Partial<CreateTemporaryDependencyFundingDto<M, V>>): CreateTemporaryDependencyFundingDto<M, V>;

  public abstract buildUpdateDtoFromUnsafeValues<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = unknown>(values: Partial<UpdateTemporaryDependencyFundingDto<M, V>>): UpdateTemporaryDependencyFundingDto<M, V>;

}
