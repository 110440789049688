import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[panelHeader]'
})
export class PanelHeaderDirective {

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
