<ehp-form-field>
  <label *ngIf="label" [innerHTML]="label"></label>

  <p-multiSelect
    #select
    (onChange)="selectChange($event)"
    [(ngModel)]="selection"
    [disabled]="disabled"
    [optionLabel]="optionLabel"
    [options]="facilities"
    display="chip"
    ehp-form-control
  optionValue="id"
  ></p-multiSelect>

</ehp-form-field>
