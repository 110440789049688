import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import {
  AccommodationFundingAmountRepository,
  FundingAmountsByYear,
  GetLatestAccommodationFundingAmountsQuery,
} from '@application/bundles/funding';
import { AccommodationFunding } from '@domain/accommodation-funding';

@HandleQuery({
  query: GetLatestAccommodationFundingAmountsQuery,
})
export class GetLatestAccommodationFundingAmountsQueryHandler
  implements QueryHandler<GetLatestAccommodationFundingAmountsQuery>
{
  constructor(private readonly repository: AccommodationFundingAmountRepository) {}

  public handle(query: GetLatestAccommodationFundingAmountsQuery): Promise<FundingAmountsByYear<AccommodationFunding>> {
    return this.repository.findLatestOne(query.facilityIds);
  }
}
