import {MissingQueryConstructorError} from "@application/framework/command-query/errors";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {
  QueryConstructor,
  QueryHandlerFactory,
  QueryHandlerRegistry
} from "@application/framework/command-query/query-handler.registry";
import {Query} from "@application/framework/command-query/query.interface";
import {Service} from "@application/framework/di";
import {Logger, ProvideLogger} from "@application/framework/logger";


@Service()
export class QueryHandlersAgnosticRegistry extends QueryHandlerRegistry implements QueryHandlerRegistry {

  @ProvideLogger() private readonly logger!: Logger;

  /**
   * @inheritDoc
   * @param query
   */
  public get<Q extends Query, R = any>(query: Q): QueryHandler<Q, R> | undefined {

    if (!query) {
      throw new MissingQueryConstructorError(`Constructor is missing on query "${query}".`);
    }

    const factory = this.store.get(Object.getPrototypeOf(query).constructor.name);
    
    return factory !== undefined ? factory(query) : factory;
  }

  /**
   * @inheritDoc
   * @param query
   * @param handlerFactory
   */
  public register<Q extends Query, R = any>(query: QueryConstructor<Q>, handlerFactory: QueryHandlerFactory<Q, R>) {

    QueryHandlersAgnosticRegistry.register(query, handlerFactory);
    this.logger.info(`Register handler for query "${query.name}"`, handlerFactory.name);
  }

}
