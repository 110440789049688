<ehp-page-header>

  <h1 class="title page-title" pageTitle>{{ 'Tableau de bord'|ehp_translate }}</h1>

  <div actions class="title-container">
    <div class="mode-toggle">
      <button (click)="switchModeTo('blocks')" [ngClass]="{'active': isBlocksMode}" class="is-icon icon-dashboard"
              type="button"></button>
      <button (click)="switchModeTo('list')" [ngClass]="{'active': isListMode}" class="is-icon icon-dashboard-table"
              type="button"></button>
    </div>
  </div>
</ehp-page-header>

<ehp-page-content>

  <ehp-file-deposits-dashboard-message></ehp-file-deposits-dashboard-message>

  <ehp-dashboard *ngIf="isBlocksMode" [config]="configuration" [widgets]="widgets"></ehp-dashboard>

  <ehp-dashboard-table *ngIf="isListMode"></ehp-dashboard-table>

</ehp-page-content>
