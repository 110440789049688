import {CreateTemporaryDependencyFundingDtoImpl} from "./create-temporary-dependency-funding.dto";
import {
  isValidTemporaryDependencyFundingIdType,
  UpdateTemporaryDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {DEPENDENCIES_FUNDING_MODES, TEMPORARY_DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding";
import {IsValidPermanentDependencyFundingId} from "@implementations/bundles/dependency-funding/validators";

export class UpdateTemporaryDependencyFundingDtoImpl<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = any> extends CreateTemporaryDependencyFundingDtoImpl<M, V> implements UpdateTemporaryDependencyFundingDto<M, V> {

  @IsValidPermanentDependencyFundingId()
  public id!: number;

  constructor(values?: Partial<UpdateTemporaryDependencyFundingDto<M, V>>) {
    super(values);

    if (values && isValidTemporaryDependencyFundingIdType(values.id)) {
      this.id = values.id;
    }
  }

}
