import {Injector, StaticProvider} from "@angular/core";
import {Container} from "@application/framework/di";
import {LazyContainer} from "@application/framework/di/containers/lazy-container";
import {ServiceMetadata} from "@application/framework/di/interfaces/service-metadata.interface";
import {ContainerIdentifier} from "@application/framework/di/types/container-identifier.type";
import {ServiceIdentifier} from "@application/framework/di/types/service-identifier";
import {createStaticProviderFromServiceMetadata} from "@easyhpad-ui/app/framework/di/functions/create-static-provider";
import {wrapServiceIdentifier} from "@easyhpad-ui/app/framework/di/functions/wrap-service-identifier.function";

export class LazyAngularContainer extends LazyContainer implements Container {

  private static readonly ID = Symbol('ANGULAR_DI_CONTAINER');

  private readonly tokens: Set<StaticProvider> = new Set();

  private injector: Injector;

  constructor(id?: ContainerIdentifier) {
    super(id ?? LazyAngularContainer.ID);
    this.injector = Injector.create({providers: []});
  }

  /**
   * @inheritDoc
   * @param identifier
   */
  public override has<T = unknown>(identifier: ServiceIdentifier<T>): boolean {
    return this.injector.get(wrapServiceIdentifier(identifier), null, {optional: true}) === null;
  }

  /**
   * @inheritDoc
   * @param token
   */
  public override get<T = unknown>(token: ServiceIdentifier<T>): T {
    if (this.container !== undefined) {
      return this.container.get<T>(token);
    }
    return this.injector.get(token);
  }

  /**
   * @inheritDoc
   * @param metadata
   */
  public override set<T = unknown>(metadata: ServiceMetadata<T>): boolean {

    if (this.container !== undefined) {
      return this.container.set(metadata);
    }

    this.metadataMaps.set(metadata.id, metadata);

    const token = createStaticProviderFromServiceMetadata(metadata);
    this.tokens.add(token);
    this.injector = Injector.create({providers: Array.from(this.tokens), parent: this.injector});

    return true;
  }
}
