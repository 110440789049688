import { DocumentRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { EasyActivityAnnex } from '@implementations/bundles/backend/easyhpad';
import { Provide } from '@application/framework/di';
import { ActivityAnnex } from '@domain/activity-annex';
import {
  ActivityAnnexRepository,
  ActivityAnnexSearchParams,
  SerializedActivityAnnexSearchParams,
} from '@application/bundles/activity-annex';
import { EasyActivityAnnexTransformer } from '@implementations/bundles/backend/easyhpad/transformers/activity-annex/easy-activity-annex.transformer';

export class EasyActivityAnnexRepository
  extends DocumentRepository<
    ActivityAnnexSearchParams,
    SerializedActivityAnnexSearchParams,
    ActivityAnnex,
    EasyActivityAnnex
  >
  implements ActivityAnnexRepository
{
  @Provide() private readonly entityTransformer!: EasyActivityAnnexTransformer;

  constructor() {
    super('activity-annex', {
      from: input => this.entityTransformer.transform(input),
      to: output => this.entityTransformer.reverseTransform(output),
      create: input => this.entityTransformer.transformCreate(input),
      update: input => this.entityTransformer.transformUpdate(input),
    });
  }
}
