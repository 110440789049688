import { AnonymousToken } from '@application/bundles/authentification/token/anonymous.token';
import { UserAuthentificationToken } from '@application/bundles/user/authentification/user-authentification-token';
import { User } from '@domain/user';
import { AuthentificationToken } from '@application/bundles/authentification';
import { QueryBus } from '@application/framework/command-query';
import { GetUserPermissionsQuery, GetUserPermissionsQueryResult } from '@application/bundles/user/queries';
import { AgnosticRoleCollection } from '@application/bundles/authorization/implementations/agnostic-role-collection/agnostic-role-collection';

export class UserAuthorizationTokenFactory {
  constructor(private readonly queryBus: QueryBus) {}

  /**
   * Create a new authorization token based on user's information
   * @param user
   */
  public async createAuthorizationTokenForUser(user: User | undefined): Promise<AuthentificationToken> {
    if (user !== undefined) {
      const permissions = await this.queryBus.request<GetUserPermissionsQueryResult>(
        new GetUserPermissionsQuery(user.id),
      );

      return new UserAuthentificationToken(user, new AgnosticRoleCollection(user.roles), permissions);
    }

    return new AnonymousToken();
  }
}
