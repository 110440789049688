import {
  CreatePMPCommandHandler,
  DeletePMPCommandHandler,
  UpdatePMPCommandHandler,
} from '@application/bundles/pmp/commands/handlers';
import { OnPMPEventsSubscriber } from '@application/bundles/pmp/events/subscribers';
import {
  GetLatestNationalPMPIndicatorQueryHandler,
  GetLatestPMPCollectionQueryHandler,
  GetLatestPMPQueryHandler,
  GetPMPQueryHandler,
  HasPMPForYearQueryHandler,
  ListPMPQueryHandler,
} from '@application/bundles/pmp/queries/handlers';
import { Bundle } from '@application/framework/bundle';
import { Service } from '@application/framework/di';

@Bundle({
  commandHandlers: [CreatePMPCommandHandler, UpdatePMPCommandHandler, DeletePMPCommandHandler],
  queryHandlers: [
    ListPMPQueryHandler,
    GetPMPQueryHandler,
    GetLatestPMPQueryHandler,
    GetLatestPMPCollectionQueryHandler,
    HasPMPForYearQueryHandler,
    GetLatestNationalPMPIndicatorQueryHandler,
  ],
  eventSubscribers: [OnPMPEventsSubscriber],
})
@Service()
export class PMPBundle {
  constructor() {}
}
