export type UUID = string;

/**
 * Generic type for abstract class definitions.
 *
 * Explanation: This describes a newable Function with a prototype Which is
 * what an abstract class is - no constructor, just the prototype.
 */
export type AbstractConstructable<T> = NewableFunction & { prototype: T };

/**
 * Generic type for class definitions.
 * Example usage:
 * ```
 * function createSomeInstance(myClassDefinition: Constructable<MyClass>) {
 *   return new myClassDefinition()
 * }
 * ```x
 */
export type Constructable<T> = new (...args: any[]) => T;

export type PropertyTypeOf<T> = T[keyof T];

export type Class<T> = Constructable<T> | AbstractConstructable<T>;

export type Common<T1, T2> = Pick<T1 | T2, Extract<keyof T1, keyof T2>>;

export enum POSITION {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export interface Serializable<S> {
  serialize(): S;
}

export interface DeSerializable<S, T> {
  deserialize(value: S): T;
}

export type Stored<T, ID = UUID | number> = T & { id: ID };
