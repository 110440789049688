import {DAYCARE_DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding";

export interface EasyDaycareDependencyFunding {

  id: number;

  funding_mode: DAYCARE_DEPENDENCIES_FUNDING_MODES;


  unique_price: number | null | undefined;


  price_gir1_2: number | null | undefined;


  price_gir3_4: number | null | undefined;


  price_gir5_6: number | null | undefined;

  activity_abattement: number | null | undefined;

  amount_package: number;

  amount_other: number | null | undefined;

  comment_other: string | null | undefined;


}


export interface CreateEasyDaycareDependencyFundingDto extends Omit<EasyDaycareDependencyFunding, 'id'> {
}

export interface UpdateEasyDaycareDependencyFundingDto extends EasyDaycareDependencyFunding {
}
