import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {CreateCPOMFundingDtoImpl, UpdateCPOMFundingDtoImpl} from "@implementations/bundles/cpom";
import {validateOrReject} from "class-validator";

export const createCPOMFundingDtoValidator: ValidateFunction<CreateCPOMFundingDtoImpl> = async (dto: CreateCPOMFundingDtoImpl): Promise<CreateCPOMFundingDtoImpl> => {
  await validateOrReject(dto);
  return dto;
}

export const updateCPOMFundingDtoValidator: ValidateFunction<UpdateCPOMFundingDtoImpl> = async (dto: UpdateCPOMFundingDtoImpl): Promise<UpdateCPOMFundingDtoImpl> => {
  await validateOrReject(dto);
  return dto;
}
