import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, EMPTY, from, mergeMap, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {QueryBus} from "@application/framework/command-query";
import {EhpadDemainActions,} from "./ehpad-demain.actions";
import {GetEhpadDemainQuery, ListEhpadDemainQuery} from "@application/bundles/ehpad-demain";
import {ErrorActions} from "@easyhpad-ui/app/bundles/errors/store";
import {EhpadDemain} from "@domain/ehpad-demain";
import {Store} from "@ngrx/store";

@Injectable()
export class EhpadDemainEffects {

  loadEhpadDemain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EhpadDemainActions.loadEhpadDemain),
      mergeMap((action) => from(this.queryBus.request<EhpadDemain[]>(new ListEhpadDemainQuery(action.params?.serialize())))),
      map((documents) => EhpadDemainActions.setEhpadDemain({documents})),
      catchError(error => of(ErrorActions.catchError(error)))
    )
  );

  getEhpadDemain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EhpadDemainActions.getEhpadDemain),
      mergeMap((action) => {
        return from(this.queryBus.request<EhpadDemain>(new GetEhpadDemainQuery(action.id)))
          .pipe(catchError(error => {
            this.store.dispatch(ErrorActions.catchError({error}))
            return EMPTY;
          }))
      }),
      concatMap((document) => [
        EhpadDemainActions.setCurrentEhpadDemain({id: document.id})
      ]),
    )
  );

  constructor(
    private actions$: Actions,
    private queryBus: QueryBus,
    private readonly store: Store
  ) {
  }
}

