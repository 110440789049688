import {GetCurrentHealthFundingQuery, HealthFundingRepository} from "@application/bundles/health-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {HealthFunding} from "@domain/health-funding";

@HandleQuery({
  query: GetCurrentHealthFundingQuery
})
export class GetCurrentHealthFundingQueryHandler implements QueryHandler<GetCurrentHealthFundingQuery, HealthFunding | undefined> {


  constructor(private readonly repository: HealthFundingRepository) {
  }

  public async handle(query: GetCurrentHealthFundingQuery): Promise<HealthFunding | undefined> {
    const results = await this.repository.list();

    return results[0];
  }
}
