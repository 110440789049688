import {NgModule} from "@angular/core";
import {Logger} from "@application/framework/logger";
import {AgnosticSanitizerRegistry} from "@application/framework/sanitizer/implementations/sanitizers.registry";
import {SanitizerLibrary, SanitizerRegistry} from "@application/framework/sanitizer/sanitizer";
import {ReactiveSanitizer} from "@implementations/framework/sanitizer/reactive-sanitizer";

@NgModule({
  providers: [
    {
      provide: SanitizerRegistry,
      useValue: new AgnosticSanitizerRegistry(),
    },
    {
      provide: ReactiveSanitizer,
      useClass: ReactiveSanitizer,
      deps: [SanitizerRegistry, Logger]
    },
    {
      provide: SanitizerLibrary,
      useExisting: ReactiveSanitizer
    },
  ]
})
export class SanitizerModule {
}
