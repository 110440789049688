import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { CreateDemoCommand } from '@application/bundles/demo';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { Customer } from '@domain/customer';
import { EasyCustomer } from '@implementations/bundles/backend/easyhpad';
import { EasyCustomerTransformer } from '@implementations/bundles/backend/easyhpad/transformers';

@HandleCommand({
  command: CreateDemoCommand,
})
export class CreateDemoCommandHandler implements CommandHandler<CreateDemoCommand> {
  private readonly url = 'demo';

  constructor(
    private readonly backend: EasyBackendApi,
    private readonly transformer: EasyCustomerTransformer,
  ) {}

  public handle(command: CreateDemoCommand): Promise<Customer | null> {
    return this.backend
      .post<{ entity: EasyCustomer }>(this.url, command.payload)
      .then(response => this.transformer.reverseTransform(response.body.entity))
      .then(() => null);
  }
}
