import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

class DatesValidatorsCollection {
  /**
   * Verify that the date from the control is greater than the requested date.
   * @param date - The request date.
   */
  public min(date: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = this.transformControlValue(control);

      if (value instanceof Date && value < date) {
        return { dateMin: { min: this.formatDate(date) } };
      }

      return null;
    };
  }

  /**
   * Verify that the date from the control is lower than the requested date.
   * @param date - The request date.
   */
  public max(date: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = this.transformControlValue(control);

      if (value instanceof Date && value > date) {
        return { dateMax: { max: this.formatDate(date) } };
      }

      return null;
    };
  }

  /**
   * Transform date string value from control to date.
   * @param control - The control that contains the value.
   */
  private transformControlValue(control: AbstractControl) {
    let value = control.value;

    if (typeof value === 'string' && value !== '') {
      value = new Date(value);
    }
    return value;
  }

  /**
   * Create a human-readable date string.
   * @param date - The date.
   */
  private formatDate(date: Date): string {
    return date.toLocaleDateString();
  }
}

export const DateValidators = Object.freeze(new DatesValidatorsCollection());
