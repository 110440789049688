export class InvalidQueryConstructorError extends Error {
}


export class InvalidQueryHandlerFactoryError extends Error {
}


export class MissingQueryConstructorError extends Error {
}
