import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ehp-form-array-errors',
  templateUrl: './form-array-errors.component.html',
  styleUrls: ['./form-array-errors.component.scss'],
})
export class FormArrayErrorsComponent implements OnChanges, OnDestroy {
  @Input() formArray: FormArray | null | undefined;

  @Input() labels: Map<string, string> = new Map();

  public errors: Array<{ label: string; errors: ValidationErrors | null }> = [];

  private subscription: Subscription | undefined;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['formArray']) {
      this.unsubscribe();

      if (this.formArray) {
        this.formArray.statusChanges.subscribe(() => this.setErrors());
      }

      this.setErrors();
    }
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

  private setErrors() {
    if (this.formArray) {
      this.errors = [];
      this.setErrorFromFormArray(this.formArray, []);
    }
  }

  private setErrorFromAbstract(control: AbstractControl<any>, name: string[]) {
    switch (control.constructor.name) {
      case FormArray.name:
        this.setErrorFromFormArray(control as FormArray, name);
        break;
      case FormGroup.name:
        this.setErrorFromFormGroup(control as FormGroup, name);
        break;

      case FormControl.name:
        this.setErrorFromFormControl(control as FormControl, name);
        break;
    }
  }

  private setErrorFromFormArray(root: FormArray, name: string[]): void {
    if (root.errors !== null) {
      this.errors.push({ label: this.getLabelFromName(name), errors: root.errors });
    }

    if (root.controls && root.controls.length > 0) {
      root.controls.forEach((control, index) => {
        this.setErrorFromAbstract(control, [...name, `Ligne ${index + 1}`]);
      });
    }
  }

  private setErrorFromFormGroup(root: FormGroup<any>, name: string[]) {
    if (root) {
      if (root.errors) {
        this.errors.push({ label: this.getLabelFromName(name), errors: root.errors });
      }

      for (const [controlName, control] of Object.entries(root.controls)) {
        let label = controlName;

        if (this.labels && this.labels.has(controlName)) {
          label = this.labels.get(controlName) as string;
        }

        this.setErrorFromAbstract(control, [...name, label]);
      }
    }
  }

  private setErrorFromFormControl(root: FormControl, name: string[]) {
    if (root && root.errors) {
      this.errors.push({ label: this.getLabelFromName(name), errors: root.errors });
    }
  }

  private getLabelFromName(name: string[]): string {
    return name.join(' / ');
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
