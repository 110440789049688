import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DraggableCalculatorComponent } from './components';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { DraggableModule } from '@easyhpad-ui/app/library/draggable';
import { StoreModule } from '@ngrx/store';
import { CALCULATOR_STORE_KEY, calculatorReducer } from './store';
import { GmpUIModule } from '@easyhpad-ui/app/bundles/gmp/modules/ui/gmp-ui.module';
import { HealthFundingUiModule } from '@easyhpad-ui/app/bundles/health-funding/modules/ui/health-funding-ui.module';

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    DraggableModule,
    StoreModule.forFeature(CALCULATOR_STORE_KEY, calculatorReducer),
    GmpUIModule,
    HealthFundingUiModule,
  ],
  declarations: [DraggableCalculatorComponent],
})
export class CalculatorModule {}
