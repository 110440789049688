import { Injectable } from '@angular/core';
import {
  CommandConstructor,
  CommandHandlerRegistry,
} from '@application/framework/command-query/command-handler.registry';
import { QueryConstructor, QueryHandlerRegistry } from '@application/framework/command-query/query-handler.registry';
import { Logger } from '@application/framework/logger';
import { environment } from '@easyhpad-ui/environments/environment';
import { BusBuildOptions } from '@implementations/framework/command-query/bus-build-options.interface';
import { AuthentificationState } from '@application/bundles/authentification';

@Injectable({
  providedIn: 'root',
})
export class BusBuildOptionsImpl implements BusBuildOptions {
  public static readonly bypassEnqueue: Set<CommandConstructor<any> | QueryConstructor<any>> = new Set();

  public logger: { use: Logger; silent?: boolean };

  public get bypassEnqueue(): Array<CommandConstructor<any> | QueryConstructor<any>> {
    return Array.from(BusBuildOptionsImpl.bypassEnqueue.values());
  }

  public set bypassEnqueue(constructors: Array<CommandConstructor<any> | QueryConstructor<any>>) {
    constructors.forEach(c => BusBuildOptionsImpl.bypassEnqueue.add(c));
  }

  constructor(
    public readonly commandRegistry: CommandHandlerRegistry,
    public readonly queryRegistry: QueryHandlerRegistry,
    public readonly authentificationState: AuthentificationState,
    logger: Logger,
  ) {
    this.logger = { use: logger, silent: environment.production };
  }
}
