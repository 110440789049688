import { CreateDemo } from '@application/bundles/demo';
import { IsArray, IsString } from 'class-validator';

export class CreateDemoDto implements CreateDemo {
  @IsString()
  public name!: string;

  @IsArray()
  @IsString({ each: true })
  public facilities!: Array<string>;
}
