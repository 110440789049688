import { CreateRecurringHealthFundingDtoImpl } from './create-recurring-health-funding.dto';
import {
  isValidRecurringHealthFundingIdType,
  UpdateRecurringHealthFundingDto,
} from '@application/bundles/health-funding';
import { UUID } from '@domain/lib';

export class UpdateRecurringHealthFundingDtoImpl
  extends CreateRecurringHealthFundingDtoImpl
  implements UpdateRecurringHealthFundingDto
{
  public id!: UUID;

  constructor(values?: Partial<UpdateRecurringHealthFundingDto>) {
    super(values);

    if (values && isValidRecurringHealthFundingIdType(values.id)) {
      this.id = values.id;
    }
  }
}
