import { CommandBus, CommandHandler, HandleCommand } from '@application/framework/command-query';
import { ResetPasswordCommand } from '@application/bundles/user/commands/reset-password.command';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { CheckResetPasswordTokenValidityCommand } from '@application/bundles/user';

@HandleCommand({
  command: ResetPasswordCommand,
})
export class ResetPasswordCommandHandler
  implements CommandHandler<ResetPasswordCommand, { changed: boolean; tokenValid: boolean }>
{
  private readonly basePath = '/user/retrieve-password';

  constructor(
    private readonly backend: EasyBackendApi,
    private readonly commandBus: CommandBus,
  ) {}

  public async handle(command: ResetPasswordCommand): Promise<{ changed: boolean; tokenValid: boolean }> {
    const user = await this.commandBus.execute(new CheckResetPasswordTokenValidityCommand(command.token));

    if (!user) {
      return { changed: false, tokenValid: false };
    }

    return this.backend
      .post<{ message: string }>(`${this.basePath}/${command.token}`, { new_password: command.password })
      .then(() => ({ changed: true, tokenValid: true }))
      .catch(() => ({ changed: false, tokenValid: true }));
  }
}
