import {Component, Input, ViewEncapsulation} from '@angular/core';
import {FundingCollection} from "@domain/funding";
import {EhpadDemainHealthFunding} from "@domain/health-funding";

@Component({
  selector: 'ehp-ehpad-demain-funding-table',
  templateUrl: './ehpad-demain-funding-table.component.html',
  styleUrls: ['./ehpad-demain-funding-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EhpadDemainFundingTableComponent {

  @Input() public funding!: FundingCollection<EhpadDemainHealthFunding>;
}
