import { TaxonomyTerm } from '@domain/taxonomy';
import { Query } from '@application/framework/command-query';
import { HealthTaxonomyType } from '@domain/health-funding';

export type GetHealthTaxonomyTermResult<T extends TaxonomyTerm = TaxonomyTerm> = T | undefined;

export class GetHealthTaxonomyTermQuery implements Query {
  constructor(
    public taxonomy: HealthTaxonomyType,
    public id: TaxonomyTerm['id'],
  ) {}
}
