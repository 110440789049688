import { AuthentificationState } from '@application/bundles/authentification/abstraction/authentification-state';
import { MissingAuthentificationStrategyError } from '@application/bundles/authentification/errors';
import { AuthentificationFailEvent } from '@application/bundles/authentification/events/authentification-fail.event';
import { AuthentificationSuccessEvent } from '@application/bundles/authentification/events/authentification-success.event';
import { DisconnectFailEvent } from '@application/bundles/authentification/events/disconnect-fail.event';
import { DisconnectSuccessEvent } from '@application/bundles/authentification/events/disconnect-success.event';
import { DispatchableEvent, EventDispatcher } from '@application/framework/event';
import { Constructable } from '@application/framework/lib';
import { Logger } from '@application/framework/logger';
import {
  Authenticator,
  ConnectCredential,
  ConnectionResult,
  DisconnectCredential,
  DisconnectionResult,
} from '@application/bundles/authentification/abstraction';
import { AuthentificationStrategy } from '@application/bundles/authentification/abstraction/authentication-strategy';

export class AuthenticatorImpl<T> implements Authenticator {
  private strategy: AuthentificationStrategy<T> | undefined;
  private authenticators: Map<AuthentificationStrategy, Authenticator> = new Map();

  public constructor(
    private eventDispatcher: EventDispatcher,
    private authenticationState: AuthentificationState,
    private logger: Logger,
  ) {}

  /**
   * @inheritDoc
   * @param credentials
   */
  public async connect<T = any>(credentials?: ConnectCredential): Promise<ConnectionResult<T | undefined>> {
    if (this.strategy === undefined) {
      this.logger.error(`Missing authentification strategy in ${AuthenticatorImpl.name} class`);
      throw new MissingAuthentificationStrategyError();
    }

    const response: ConnectionResult<any> = { success: false, extras: undefined, token: undefined };

    try {
      const result = await this.strategy.login({ credentials });
      response.success = result.success;
      response.extras = result.result;

      if (result.success) {
        const token = await this.strategy.buildToken(result).then();
        this.eventDispatcher.dispatch(new AuthentificationSuccessEvent(token));
        response.token = token;
      } else {
        this.eventDispatcher.dispatch(
          new AuthentificationFailEvent(
            'Authentification strategy return result with success = false',
            undefined,
            result,
          ),
        );
      }
    } catch (error: unknown) {
      this.catchError(error, AuthentificationFailEvent);
    }

    this.authenticationState.markAsChecked();

    return response;
  }

  /**
   * @inheritDoc
   * @param credentials
   */
  public async disconnect<T = any>(credentials?: DisconnectCredential): Promise<DisconnectionResult<T | undefined>> {
    if (this.strategy === undefined) {
      this.logger.error(`Missing authentification strategy in ${AuthenticatorImpl.name} class`);
      throw new MissingAuthentificationStrategyError();
    }

    let value: any;

    try {
      const result = await this.strategy.logout({ credentials });
      value = result.result;

      if (result.success) {
        this.eventDispatcher.dispatch(new DisconnectSuccessEvent());
      } else {
        this.eventDispatcher.dispatch(new DisconnectFailEvent());
      }
    } catch (error) {
      this.catchError(error, DisconnectFailEvent);
    }

    return { success: false, extras: value, token: undefined };
  }

  /**
   * @inheritDoc
   * @param strategy
   */
  public withStrategy(strategy: AuthentificationStrategy): Authenticator {
    if (!this.authenticators.has(strategy)) {
      const authenticator = new AuthenticatorImpl(this.eventDispatcher, this.authenticationState, this.logger);
      authenticator.setStrategy(strategy);
      this.authenticators.set(strategy, authenticator);
    }

    return this.authenticators.get(strategy) as Authenticator;
  }

  protected setStrategy(strategy: AuthentificationStrategy<T>): any {
    this.strategy = strategy;
  }

  private catchError<A extends DispatchableEvent>(error: unknown, eventConstructor: Constructable<A>) {
    let event: A | undefined;

    if (error instanceof Error) {
      event = new eventConstructor(error.message, error);
    } else if (typeof error === 'string') {
      event = new eventConstructor(error);
    } else {
      event = new eventConstructor();
    }

    if (event) {
      this.eventDispatcher.dispatch(event);
    }
  }
}
