import {AddInspectionReportDto} from "@application/bundles/capacity-authorization/dtos";
import {LocalMedia, Media} from "@domain/media";
import {IsArray, IsDate, IsOptional} from "class-validator";

export class AddInspectionReportDtoImpl implements AddInspectionReportDto {

  @IsDate()
  public date!: Date;

  @IsArray()
  @IsOptional()
  public mediaIds: Array<Media["id"]> = [];

  @IsArray()
  public medias!: LocalMedia[];
  
}
