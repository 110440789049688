import { AuthorizationChecker, CRUD_ACTIONS } from '@application/bundles/authorization';
import { FACILITY_FEATURE } from '@application/bundles/facility/token';
import { Facility } from '@domain/facility';

export class FacilityAuthorizationChecker {
  constructor(private readonly authorizationChecker: AuthorizationChecker) {}

  public canManage() {
    return this.authorizationChecker.isGranted([{ feature: FACILITY_FEATURE, value: CRUD_ACTIONS.MANAGE }]);
  }

  public canRead(): Promise<boolean> {
    return this.authorizationChecker.isGranted([{ feature: FACILITY_FEATURE, value: CRUD_ACTIONS.READ }]);
  }

  public canCreate(): Promise<boolean> {
    return this.authorizationChecker.isGranted([{ feature: FACILITY_FEATURE, value: CRUD_ACTIONS.CREATE }]);
  }

  public canEdit(facility?: Facility): Promise<boolean> {
    return this.authorizationChecker.isGranted([{ feature: FACILITY_FEATURE, value: CRUD_ACTIONS.UPDATE }], facility);
  }

  public async canEditAllProperties(facility: Facility): Promise<boolean> {
    return this.authorizationChecker.isGranted([{ feature: FACILITY_FEATURE, value: CRUD_ACTIONS.UPDATE }], facility);
  }

  public canEditOnlyRestrictedProperties(facility: Facility): Promise<boolean> {
    return this.authorizationChecker.isGranted([{ feature: FACILITY_FEATURE, value: CRUD_ACTIONS.UPDATE }], facility);
  }

  public canDelete(facility?: Facility): Promise<boolean> {
    return this.authorizationChecker.isGranted([{ feature: FACILITY_FEATURE, value: CRUD_ACTIONS.DELETE }], facility);
  }
}
