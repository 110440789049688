import {
  InvalidPointListError,
  InvalidResidentListError,
  MissingPointGIRListError,
  MissingResidentGIRListError,
} from '@application/bundles/gmp/errors';
import { CalculatorComputationError } from '@application/bundles/gmp/errors/calculator-computation.error';
import { safeDivide } from '@application/framework/lib';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { GIR, GIRValues, isPointsGIRList, isResidentGIRList, PointsGIRList } from '@domain/gir';
import { ComputableResidentGIRList } from '@domain/gir/resident-gir.list';
import { roundGMPAmount } from '@domain/gmp';

export class GMPCalculator {
  @ProvideLogger() private readonly logger!: Logger;

  private residents: ComputableResidentGIRList | undefined;

  private points: PointsGIRList | undefined;

  constructor(residents?: ComputableResidentGIRList, points?: PointsGIRList) {
    if (residents) {
      this.setResidents(residents);
    }

    if (points) {
      this.setPoints(points);
    }
  }

  public setResidents(residents: ComputableResidentGIRList): this {
    if (!isResidentGIRList(residents)) {
      throw new InvalidResidentListError(`Resident list provided in G.M.P. calculator isn't valid.`);
    }
    this.residents = residents;

    return this;
  }

  public setPoints(points: PointsGIRList): this {
    if (!isPointsGIRList(points)) {
      throw new InvalidPointListError(`Points list provided in G.M.P. calculator isn't valid.`);
    }
    this.points = points;
    return this;
  }

  public getResidentTotal(): number {
    if (this.residents === undefined) {
      throw new MissingResidentGIRListError('Missing resident list');
    }

    return this.residents.sum();
  }

  public getPointsTotal(): number {
    this.throwOnMissingLists();
    this.throwOnInvalidLists();

    return Array.from(GIRValues)
      .map(gir => this.computeFor(gir))
      .reduce((a, b) => a + b, 0);
  }

  /**
   * G.M.P. value = (GIR point * GIR resident number) ÷ residents total
   */
  public compute(): number {
    this.throwOnMissingLists();
    this.throwOnInvalidLists();

    try {
      return roundGMPAmount(
        Math.round((safeDivide(this.getPointsTotal(), this.getResidentTotal()) + Number.EPSILON) * 100) / 100,
      );
    } catch (e: any) {
      this.logger.error('CalculatorComputationError ::', e);
      throw new CalculatorComputationError();
    }
  }

  public computeFor(gir: GIR): number {
    this.throwOnMissingLists();
    this.throwOnInvalidLists();

    return (this.points?.get(gir) || 0) * (this.residents?.get(gir) || 0);
  }

  private throwOnMissingLists(): void {
    if (this.residents === undefined) {
      throw new MissingResidentGIRListError('Missing resident list');
    }

    if (this.points === undefined) {
      throw new MissingPointGIRListError('Missing points list');
    }
  }

  private throwOnInvalidLists(): void {
    if (!isResidentGIRList(this.residents)) {
      throw new InvalidResidentListError(`Resident list provided in G.M.P. calculator isn't valid.`);
    }

    if (!isPointsGIRList(this.points)) {
      throw new InvalidPointListError(`Points list provided in G.M.P. calculator isn't valid.`);
    }
  }
}
