import { LocalMedia, Media, MEDIA_PERSISTANCE_STATE, MediaRef, TemporaryMedia } from '@domain/media';
import { isValidMediaIdType } from '@application/bundles/media';

export function isLocalMedia(media: any): media is LocalMedia {
  return typeof media === 'object' && media !== null && media.file instanceof File;
}

export function isMedia(media: any): media is Media {
  return (
    typeof media === 'object' &&
    media !== null &&
    isValidMediaIdType(media.id) &&
    media.persistance === MEDIA_PERSISTANCE_STATE.STORED &&
    typeof media.buildUrl === 'function'
  );
}

export function isTemporaryMedia(media: any): media is TemporaryMedia {
  return (
    typeof media === 'object' &&
    media !== null &&
    isValidMediaIdType(media.id) &&
    media.persistance === MEDIA_PERSISTANCE_STATE.TEMPORARY &&
    typeof media.media === 'function' &&
    isMedia(media.media()) &&
    media.media().id === media.id &&
    typeof media.markForDeletion === 'function' &&
    typeof media.markForPersist === 'function'
  );
}

export function isMediaRef(ref: any): ref is MediaRef {
  return typeof ref === 'object' && ref !== null && isValidMediaIdType(ref.id) && ref.isRef;
}
