import { DateParser } from '@application/framework/date';
import { Service } from '@application/framework/di';
import { EasyFundingUpdateRateAbstractRepository } from './easy-funding-update-rate.abstract.repository';
import { DependencyFundingUpdateRateRepository } from '@application/bundles/funding';

@Service()
export class EasyDependencyUpdateRateRepository
  extends EasyFundingUpdateRateAbstractRepository
  implements DependencyFundingUpdateRateRepository
{
  constructor(dateParser: DateParser) {
    super('funding/dependency/update-rates', dateParser);
  }
}
