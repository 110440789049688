<ng-container *ngIf="funding">

  <h3 class="title item-title m0">
    <span class="icon ehp-icon icon-file"></span>

    <ehp-accommodation-funding-link [funding]="funding"></ehp-accommodation-funding-link>
  </h3>

  <span class="date">
    {{ funding.year }}
  </span>

  <span class="amount">
    <ng-container *ngIf="funding.amount">{{funding.amount | currency}}</ng-container>
  </span>

  <span class="occupation-rate">
    <ng-container *ngIf="funding.occupationRate">
      {{ funding.occupationRate }}%
    </ng-container>
  </span>

  <span class="facility">
    <ehp-facility-link [fid]="funding.facilityId"></ehp-facility-link>
  </span>




  <div class="actions-list actions">

    <ehp-accommodation-funding-link
      [funding]="funding"
      class="is-icon icon-arrow ghost button"
      pTooltip="{{'Voir' | ehp_translate}}"
      tooltipPosition="bottom">

      <span class="label">{{"Consulter le financement hébergement" | ehp_translate }}</span>

    </ehp-accommodation-funding-link>

  </div>


</ng-container>
