import {Collection, ListCollectionParams} from "@application/framework/store/collection";
import {
  InMemoryAgnosticStore
} from "@application/framework/store/implementations/agnostic-store/in-memory-agnostic-store";

export class InMemoryAgnosticCollection<T = any> extends InMemoryAgnosticStore<T> implements Collection<T> {

  list(params?: ListCollectionParams | undefined): T[] {
    return Array.from(this.store.values());
  }

}
