import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DocumentListImpl } from '@easyhpad-ui/app/library/document';
import { Skeleton } from '@easyhpad-ui/app/bundles/reusable-components/components/skeleton';
import { QueryBus } from '@application/framework/command-query';
import { AccommodationFunding } from '@domain/accommodation-funding';
import {
  ListAccommodationFundingQuery,
  SerializedAccommodationFundingSearchParams,
} from '@application/bundles/accommodation-funding';

@Component({
  selector: 'ehp-other-accommodation-funding-list',
  templateUrl: './other-accommodation-funding-list.component.html',
  styleUrls: ['./other-accommodation-funding-list.component.scss'],
})
export class OtherAccommodationFundingListComponent implements OnChanges {
  @Input() public funding!: AccommodationFunding;

  public list = new DocumentListImpl<AccommodationFunding>();

  public SKELETON_TYPE = Skeleton.DOCUMENT_LIST_ITEM;

  constructor(private readonly queryBus: QueryBus) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.funding) {
      this.list.setLoading(true);
      this.buildParams()
        .then(params => this.queryBus.request(new ListAccommodationFundingQuery(params)))
        .then(documents => this.dispatchOtherFunding(documents))
        .finally(() => this.list.setLoading(false));
    }
  }

  private async buildParams(): Promise<SerializedAccommodationFundingSearchParams> {
    const params: SerializedAccommodationFundingSearchParams = {};

    if (this.funding) {
      params.facilities = [this.funding.facilityId];
    }

    return params;
  }

  private dispatchOtherFunding(documents: AccommodationFunding[]) {
    if (this.funding) {
      documents = documents.filter(document => document.id !== this.funding.id);
    }
    this.list.set(documents);
  }
}
