import {ModuleWithProviders, NgModule} from '@angular/core';
import {Cache, CacheManager} from "@application/framework/cache";
import {
  MemoryCacheManager
} from "@implementations/framework/cache/implementations/memory-cache-manager/memory-cache-manager";


@NgModule()
export class CacheModule {

  private static created = false;

  constructor() {
  }

  public static forRoot(): ModuleWithProviders<CacheModule> {

    if (CacheModule.created) {
      throw new Error('CacheModule.forRoot() used more than once');
    }

    return {
      ngModule: CacheModule,
      providers: [
        {
          provide: CacheManager,
          useValue: new MemoryCacheManager()
        }
      ]
    };
  }

  public static forChild(config: { caches?: Array<{ key: string | Symbol, cache: Cache }> }): ModuleWithProviders<CacheModule> {

    if (Array.isArray(config.caches)) {
      config.caches.forEach(c => MemoryCacheManager.set(c.key, c.cache));
    }
    
    return {
      ngModule: CacheModule,
    };
  }

}
