import { isValidFacilityIdType } from '@application/bundles/facility';
import { Facility, isActiveFacility } from '@domain/facility';
import { FACILITY_STORE_KEY } from '@easyhpad-ui/app/bundles/facility/store';
import {
  facilityStoreSerializer,
  StoreSerializedFacility,
} from '@easyhpad-ui/app/bundles/facility/store/serializers/facility/facility-store.serializer';

import { AppState } from '@easyhpad-ui/app/store';
import { createSelector } from '@ngrx/store';
import { FacilityStoreState } from './facility.state';

const deserializeArray = (facilities: StoreSerializedFacility[]): Facility[] =>
  facilities.map(f => facilityStoreSerializer.deserialize(f));

export const selectFacilityState = (state: AppState) => state[FACILITY_STORE_KEY];

export const selectFacilityAdmin = createSelector(selectFacilityState, (state: FacilityStoreState) => state.admin);

export const selectFacilityAdminLoading = createSelector(
  selectFacilityAdmin,
  (state: FacilityStoreState['admin']) => state.loading,
);

export const selectFacilityAdminEntities = createSelector(selectFacilityAdmin, (state: FacilityStoreState['admin']) =>
  state.entities.map(f => facilityStoreSerializer.deserialize(f as any)),
);

const selectAccountFacilitiesState = createSelector(selectFacilityState, (state: FacilityStoreState) => state.account);

export const selectAllFacilitiesForAccount = createSelector(
  selectAccountFacilitiesState,
  (state: FacilityStoreState['account']) => deserializeArray(state.all),
);

export const selectSelectedFacilitiesForAccount = createSelector(
  selectAccountFacilitiesState,
  (state: FacilityStoreState['account']) => deserializeArray(state.all.filter(f => state.selected.includes(f.id))),
);

export const selectActiveFacilitiesForAccount = createSelector(
  selectAccountFacilitiesState,
  (state: FacilityStoreState['account']) => deserializeArray(state.all.filter(isActiveFacility)),
);

export const selectCurrentFacilitiesForAccount = createSelector(
  selectAccountFacilitiesState,
  (state: FacilityStoreState['account']) => {
    if (state.selected.length === 0) {
      return deserializeArray(state.all);
    }

    return deserializeArray(state.all.filter(f => state.selected.includes(f.id)));
  },
);

const selectFacilityPublic = createSelector(selectFacilityState, (state: FacilityStoreState) => state.public);

export const selectFacility = (id: Facility['id']) =>
  createSelector(selectFacilityPublic, state => {
    return isValidFacilityIdType(id) && state.entities[id]
      ? facilityStoreSerializer.deserialize(state.entities[id] as StoreSerializedFacility)
      : undefined;
  });

export const selectFacilityNames = createSelector(selectFacilityState, (state: FacilityStoreState) => state.names);

export const selectFacilityName = (id: Facility['id']) =>
  createSelector(selectFacilityNames, state => {
    return isValidFacilityIdType(id) ? state[id] : undefined;
  });
