import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EHPAD_DEMAIN_TYPE } from '@domain/ehpad-demain';
import { EhpadDemainTypeCollection } from '@implementations/bundles/ehpad-demain/collections';

@Component({
  selector: 'ehp-ehpad-demain-type-label',
  templateUrl: './ehpad-demain-type-label.component.html',
  styleUrl: './ehpad-demain-type-label.component.scss',
})
export class EhpadDemainTypeLabelComponent implements OnChanges {
  @Input() public type: EHPAD_DEMAIN_TYPE | undefined;

  public label: string | undefined;

  constructor(private readonly labels: EhpadDemainTypeCollection) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('type' in changes) {
      this.updateLabel();
    }
  }

  private updateLabel() {
    if (this.type === undefined) {
      this.label = undefined;
      return;
    }

    this.labels.get(this.type).then(t => {
      this.label = t?.label;
    });
  }
}
