import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FundingCollection } from '@domain/funding';
import { HealthTaxonomyType, NonRecurringHealthFunding } from '@domain/health-funding';

@Component({
  selector: 'ehp-non-recurring-funding-table',
  templateUrl: './non-recurring-funding-table.component.html',
  styleUrls: ['./non-recurring-funding-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NonRecurringFundingTableComponent {
  @Input() public funding!: FundingCollection<NonRecurringHealthFunding>;

  public taxonomy: HealthTaxonomyType = 'nonRecurring';
}
