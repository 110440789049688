import { MediaCollectionImpl, MediaUploader } from '@application/bundles/media';
import { LocalMedia, Media, MediaBucket, TemporaryMedia } from '@domain/media';
import { MediaCollection } from '@domain/media/media-collection';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { EasyMediaTransformer } from '@implementations/bundles/backend/easyhpad/transformers/media/easy-media.transformer';

export class EasyMediaBucket implements MediaBucket {
  private readonly basePath = 'media';

  public constructor(
    private readonly backend: EasyBackendApi,
    private readonly uploader: MediaUploader,
    private readonly transformer: EasyMediaTransformer,
  ) {}

  public get(id: Media['id']): Promise<Media> {
    return this.backend
      .get<{ entity: EasyMedia }>(`${this.basePath}/${id}`)
      .then(response => this.transformer.reverseTransform(response.body.entity));
  }

  public add(media: LocalMedia): Promise<TemporaryMedia> {
    const transfert = this.uploader.upload(media);

    return new Promise((resolve, reject) => {
      transfert.onComplete(media => resolve(media));
      transfert.onError(e => reject(e));
    });
  }

  public store(medias: LocalMedia[]): Promise<MediaCollection<TemporaryMedia>> {
    const transfert = this.uploader.uploadMany(...medias);
    return new Promise((resolve, reject) => {
      transfert.onComplete(medias => resolve(new MediaCollectionImpl(medias)));
      transfert.onError(e => reject(e));
    });
  }

  public async delete(id: Media['id']): Promise<Media> {
    const media = this.get(id);
    return this.backend.delete(`${this.basePath}/${id}`).then(() => media);
  }
}
