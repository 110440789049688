import { isValidObject } from '@application/framework/lib';
import {
  DAYCARE_DEPENDENCIES_FUNDING_MODES,
  DaycareDependencyFunding,
  hasTemporaryFundingMode,
} from '@domain/dependency-funding';
import { isUUID } from '@domain/lib';

export function isValidDaycareDependencyFundingIdType<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = any, V = unknown>(
  id: any,
): id is DaycareDependencyFunding<M, V>['id'] {
  return isUUID(id);
}

export function isMaybeADaycareDependencyFunding<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = any, V = unknown>(
  funding: any,
): funding is Omit<DaycareDependencyFunding<M, V>, 'id'> {
  return (
    isValidObject(funding) &&
    hasTemporaryFundingMode(funding) &&
    (typeof (funding as DaycareDependencyFunding<M, V>).activityAllowance === 'number' ||
      (funding as DaycareDependencyFunding<M, V>).activityAllowance === undefined)
  );
}

export function isDaycareDependencyFunding<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = any, V = unknown>(
  funding: any,
): funding is DaycareDependencyFunding<M, V> {
  return (
    isMaybeADaycareDependencyFunding(funding) &&
    isValidDaycareDependencyFundingIdType((funding as DaycareDependencyFunding<M, V>).id)
  );
}
