import { Media } from '@domain/media';
import { LocalERRD } from './local-errd';
import { UUID } from '@domain/lib';

export interface ERRD {
  id: UUID;

  accountingYear: number;

  depositDate: Date | undefined;

  readonly isSingle: boolean;

  readonly singleChild: LocalERRD | undefined;

  details: LocalERRD[];

  completeDocumentId: Media['id'];

  payrollDocumentId: Media['id'];

  financialAnnexDocumentId: Media['id'];

  activityAnnexDocumentId: Media['id'];

  reportId: Media['id'] | undefined;

  completeDocument(): Promise<Media>;

  payrollDocument(): Promise<Media>;

  financialAnnexDocument(): Promise<Media>;

  activityAnnexDocument(): Promise<Media>;

  reportDocument(): Promise<Media | undefined>;
}
