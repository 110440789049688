import { Component, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { isValidMediaIdType } from '@application/bundles/media';
import { isLocalMedia, isMedia, LocalMedia, Media, MediaBucket } from '@domain/media';
import { MediaViewerOptions } from '@easyhpad-ui/app/bundles/media/interfaces';
import { MediaPreview } from '@easyhpad-ui/app/bundles/media/services/media-preview/media-preview';

@Component({
  selector: 'ehp-media-preview-button',
  templateUrl: './media-preview-button.component.html',
  styleUrls: ['./media-preview-button.component.scss'],
})
export class MediaPreviewButtonComponent implements OnChanges {
  @Input() public mediaId!: Media['id'];

  @Input() public media!: LocalMedia | Media | undefined;

  public get valid(): boolean {
    return isMedia(this.media) || isLocalMedia(this.media) || isValidMediaIdType(this.media);
  }

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly previewer: MediaPreview,
    private readonly bucket: MediaBucket,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if ('mediaId' in changes) {
      this.mediaIdChange();
    }
  }

  public async openPreview() {
    if (this.media) {
      this.openPreviewWindow(this.media);
      return;
    }

    this.loadMedia().then(media => {
      if (media) this.openPreviewWindow(media);
    });
  }

  private async loadMedia(): Promise<Media | undefined> {
    if (!isValidMediaIdType(this.mediaId)) {
      return;
    }

    const media = await this.bucket.get(this.mediaId);
    this.media = media;
    return media;
  }

  private mediaIdChange(): void {
    if (isMedia(this.media) && this.media.id !== this.mediaId) {
      this.media = undefined;
    }
  }

  private openPreviewWindow(media: Media | LocalMedia): void {
    const options: MediaViewerOptions = {
      container: this.viewContainer,
      acceptClosable: true,
      acceptFullscreen: true,
    };

    this.previewer.open([media], options);
  }
}
