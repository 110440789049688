import {
  CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
  CapacityAuthorizationCreatedEvent,
  CapacityAuthorizationCreationFailEvent
} from "@application/bundles/capacity-authorization";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnCapacityAuthorizationCreationEventsSubscriber {

  constructor(
    private readonly noticeStream: NoticeStream,
  ) {
  }

  @OnEvent({
    event: CapacityAuthorizationCreatedEvent
  })
  public async pushNoticeOnCreateCustomerSuccess(event: CapacityAuthorizationCreatedEvent): Promise<void> {

    const facility = await event.authorization.facility();

    const message = new TranslatableString(
      "L'autorisation de capacité pour l'établissement <strong>{{name}}</strong> a été créée avec succès.",
      {name: facility.name},
      CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({message, type: NoticeType.SUCCESS});
  }

  @OnEvent({
    event: CapacityAuthorizationCreationFailEvent
  })
  public async pushNoticeOnCreateCustomerFail(event: CapacityAuthorizationCreationFailEvent): Promise<void> {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        "Une erreur est survenue lors de la création de l'autorisation de capacité.",
        undefined,
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT
      )
    }

    this.noticeStream.push({message, type: NoticeType.ERROR});
  }
}
