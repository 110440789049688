import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Facility } from '@domain/facility';
import { FacilityUrls } from '@easyhpad-ui/app/bundles/facility/services/facility-urls/facility-urls';

@Component({
  selector: 'ehp-facilities-id-card',
  templateUrl: './facilities-id-card.component.html',
  styleUrls: ['./facilities-id-card.component.scss'],
})
export class FacilitiesIdCardComponent implements OnChanges {
  @Input() facilities: Facility[] = [];

  public urls: Map<Facility['id'], string | undefined> = new Map();

  constructor(private readonly facilityUrlsProvider: FacilityUrls) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['facilities']) {
      this.facilitiesHasChanges();
    }
  }

  private async facilitiesHasChanges(): Promise<void> {
    const urls: Map<Facility['id'], string | undefined> = new Map();

    if (!Array.isArray(this.facilities)) {
      this.urls = urls;
      return;
    }

    for (const facility of this.facilities) {
      if (this.urls.has(facility.id)) {
        urls.set(facility.id, this.urls.get(facility.id));
      } else {
        const url = await this.facilityUrlsProvider.getPublicShow(facility.id);
        urls.set(facility.id, url ? url : undefined);
      }
    }

    this.urls = urls;
  }
}
