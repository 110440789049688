import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Facility } from '@domain/facility';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FacilitySelectComponent } from '@easyhpad-ui/app/bundles/facility/modules/form/components/facility-select/facility-select.component';
import { NativeChangeObserver } from '@easyhpad-ui/app/framework/native-change-observer';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import {
  selectActiveFacilitiesForAccount,
  selectAllFacilitiesForAccount,
} from '@easyhpad-ui/app/bundles/facility/store';

@Component({
  selector: 'ehp-account-facility-selector',
  templateUrl: './account-facility-selector.component.html',
  styleUrls: ['./account-facility-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountFacilitySelectorComponent),
      multi: true,
    },
  ],
})
export class AccountFacilitySelectorComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Input() public id: string | undefined;

  @Input() public scope: 'active' | 'all' = 'active';

  @Output() public facilityChange: EventEmitter<Facility | null> = new EventEmitter();

  public facilities$!: Observable<Facility[]>;

  public required: boolean = false;

  public readonly: boolean = false;

  @ViewChild(FacilitySelectComponent) private select?: FacilitySelectComponent;

  private value: any;

  private isDisabled: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<AppState>,
    private readonly nativeChanges: NativeChangeObserver,
    private readonly elementRef: ElementRef,
  ) {
    this.nativeChanges
      .observe(this.elementRef, 'required')
      .pipe(takeUntil(this.destroy$))
      .subscribe(change => (this.required = coerceBooleanProperty(change.currentValue)));

    this.nativeChanges
      .observe(this.elementRef, 'readonly')
      .pipe(takeUntil(this.destroy$))
      .subscribe(change => (this.readonly = coerceBooleanProperty(change.currentValue)));
  }

  public ngOnInit() {
    const selector = this.scope === 'all' ? selectAllFacilitiesForAccount : selectActiveFacilitiesForAccount;

    this.facilities$ = this.store.select(selector).pipe(takeUntil(this.destroy$));
  }

  public ngAfterViewInit(): void {
    if (this.select) {
      this.select.registerOnTouched(this.onTouched);
      this.select.registerOnChange(this.onChange);
      this.select.writeValue(this.value);
      this.select.setDisabledState(this.isDisabled);
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public writeValue(value: any): void {
    this.value = value;
    if (this.select) {
      this.select.writeValue(value);
    }
  }

  public onChange = (value: Facility['id'] | null): void => undefined;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
    if (this.select) {
      this.select.registerOnChange(fn);
    }
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;

    if (this.select) {
      this.select.registerOnTouched(fn);
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    if (this.select) {
      this.select.setDisabledState(isDisabled);
    }
  }

  public facilityHasChange(facility: Facility | null) {
    this.facilityChange.emit(facility);
  }
}
