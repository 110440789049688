import { AuthorizationChecker } from '@application/bundles/authorization';
import { ForbiddenError } from '@application/bundles/authorization/error';
import { CommandBus, HandleCommand } from '@application/framework/command-query';
import { CommandHandler } from '@application/framework/command-query/handler.interface';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { Translator } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { UpdateCurrentUser, User, UserRepository } from '@domain/user';
import { CurrentUserAccessor } from '@application/bundles/user/implementations';
import { UpdateCurrentUserCommand } from '@application/bundles/user/commands/update-current-user.command';
import { CurrentUserUpdateSuccessEvent } from '@application/bundles/user/events/user-update/current-user-update.success.event';
import { CurrentUserUpdateFailEvent } from '@application/bundles/user/events/user-update/current-user-update-fail.event';

@HandleCommand({
  command: UpdateCurrentUserCommand,
})
export class UpdateCurrentUserCommandHandler implements CommandHandler<UpdateCurrentUserCommand, User> {
  @ProvideLogger() private readonly logger!: Logger;

  constructor(
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly repository: UserRepository,
    private readonly eventDispatcher: EventDispatcher,
    private readonly commandBus: CommandBus,
    private readonly authorization: AuthorizationChecker,
    private readonly translator: Translator,
    private readonly accessor: CurrentUserAccessor,
  ) {}

  public async handle(command: UpdateCurrentUserCommand): Promise<User> {
    try {
      const current = this.getCurrentUser();

      const updated = await this.sanitize(command.update)
        .then((dto) => this.validate(dto))
        .then((dto) => this.repository.update(current.id, dto))
        .then(() => this.repository.get(current.id));

      this.eventDispatcher.dispatch(new CurrentUserUpdateSuccessEvent(updated));
      return updated;
    } catch (e) {
      const error = await this.catchError(e, command.update);
      return Promise.reject(error);
    }
  }

  private async sanitize(dto: UpdateCurrentUser): Promise<UpdateCurrentUser> {
    try {
      return await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.error('User update : sanitizer fail', e);
      throw new SanitizationFailError();
    }
  }

  private async validate(dto: UpdateCurrentUser): Promise<UpdateCurrentUser> {
    try {
      return await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.error('User update : validator fail', e);
      throw new ValidationError();
    }
  }

  private async catchError(e: any, dto: UpdateCurrentUser): Promise<Error> {
    const error = new ErrorNormalizer().normalize(e);

    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = await this.translator.translate('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à mettre à jour l'utilisateur <strong>{{name}}</strong>.",
        { name: dto.firstname },
      );
    }

    this.eventDispatcher.dispatch(new CurrentUserUpdateFailEvent(message, error.stack, error));
    return error;
  }

  private getCurrentUser() {
    const user = this.accessor.get();
    if (!user) {
      this.logger.error('User update : validator fail');
      throw new ValidationError('Missing current user');
    }

    return user;
  }
}
