import {ChangeableAccessor} from "@application/framework/core";
import {Facility} from "@domain/facility";
import {Subject, Subscription} from "rxjs";

export class DashboardFacilityAccessor implements ChangeableAccessor<Facility | undefined> {

  private readonly change = new Subject<Facility | undefined>();

  private facility: Facility | undefined;

  public get(): Facility | undefined {
    return this.facility;
  }

  public set(item: Facility | undefined): void {
    this.facility = item;
    this.change.next(this.facility);
  }

  public onChange(callable: (value: Facility | undefined) => void): Subscription {
    return this.change.subscribe(callable);
  }

}
