<header class="dialog-header">
  <h2 class="title">{{"Création / Modification d'un administrateur"  | ehp_translate}}</h2>
</header>

<div class="dialog-content">


  <p>
    {{ 'Vous êtes sur le point de créer ou de modifier une utilisateur avec le rôle Administrateur'|ehp_translate }}
  </p>
  <p>
    <strong class="message">
      {{ "Le rôle Administrateur donne accès à l'ensemble des droits, veillez à ne l'attribuer qu'à des personnes de confiance."|ehp_translate }}
    </strong>.
  </p>


  <ul *ngIf="data.options" class="">
    <li *ngFor="let option of data.options">
      <ehp-translatable-string [value]="option.label"></ehp-translatable-string>
    </li>
  </ul>

</div>

<div class="dialog-actions">
  <button (click)="confirm()" class="warning" type="button">{{'J\'ai compris et je confirme'| ehp_translate}}</button>
  <button (click)="cancel()" class="ghost" type="button">{{'Annuler'| ehp_translate}}</button>
</div>

