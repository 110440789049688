import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {HttpAuthorizationTokenStore} from "@application/framework/http";
import {environment} from "@easyhpad-ui/environments/environment";
import {EASYHPAD_BEARER_AUTH_TOKEN} from "@implementations/bundles/backend/easyhpad/backend.tokens";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthentificationInterceptor implements HttpInterceptor {


  private readonly publicPaths = [
    '/auth/login',
    '/auth/refresh'
  ];

  constructor(private readonly store: HttpAuthorizationTokenStore) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.isEasyHpadBackendApiRequest(req)) {
      req = req.clone({
        withCredentials: true,
      });

      if (!this.isPublicEasyHpadBackendApiRequest(req)) {
        const token = this.store.get(EASYHPAD_BEARER_AUTH_TOKEN);

        if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: token.toString(),
            },
          });
        }

      }
    }


    return next.handle(req);
  }


  private isEasyHpadBackendApiRequest(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.backendUrl.toString());


  }

  private isPublicEasyHpadBackendApiRequest(req: HttpRequest<any>): boolean {
    const url = new URL(req.url);
    return this.publicPaths.includes(url.pathname);
  }


}
