export type PasswordStrengthRequirements = 'lowUpper' | 'length' | 'specialChar' | 'number';


export interface PasswordCheckResult {

  strength: number;

  requirements: Record<PasswordStrengthRequirements, boolean>;

  valid: boolean;
}


export abstract class PasswordStrengthChecker {

  public abstract readonly minLength: number;

  public abstract readonly specialChar: Set<string>;

  public abstract check(password: string): PasswordCheckResult;

  public abstract getProposal(): string;

}
