export type Ttl = number;

export type CacheKey = { toString(): string; } | string;

export type CacheStore = {

  has(key: CacheKey): Promise<boolean>;

  get<T>(key: CacheKey): Promise<T | undefined>;

  pick<T, V extends keyof T>(key: CacheKey, name: V): Promise<T[V] | undefined>;

  set<T>(key: CacheKey, data: T, ttl?: Ttl): Promise<CacheStore>;

  resolve<T>(key: CacheKey, fn: () => Promise<T>, ttl: Ttl | undefined): Promise<T>;

  delete(...keys: Array<CacheKey>): Promise<void>;

  reset(): Promise<void>;

  ttl(key: string): Promise<number>;

}

export abstract class Cache<T = unknown> {

  public abstract has(key: CacheKey): Promise<boolean>;

  public abstract get(key: CacheKey): Promise<T | undefined>;

  public abstract pick<V extends keyof T>(key: CacheKey, name: V): Promise<T[V] | undefined>;

  public abstract set(key: CacheKey, value: T, ttl?: Ttl): Promise<void>;

  public abstract delete(...keys: Array<CacheKey>): Promise<void>;

  public abstract reset(): Promise<void>;

  public abstract wrap(key: CacheKey, fn: () => Promise<T>, ttl?: Ttl): Promise<T>;

}

export type Config = {
  ttl?: Ttl;
  isCacheable?: (val: unknown) => boolean;
};


export type StoreConfig = Config;

export type FactoryConfig<T> = T & Config;

export type FactoryStore<S extends CacheStore, T extends object = never> = (
  config?: FactoryConfig<T>,
) => S | Promise<S>;

export type Stores<S extends CacheStore, T extends object> =
  | CacheStore
  | FactoryStore<S, T>;

export type CachingConfig<T> = StoreConfig | FactoryConfig<T>;

