import { CreateNewFileDepositItemCommand } from '@application/bundles/file-deposit/commands/create-new-file-deposit-item.command';
import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { joinPath } from '@application/framework/lib';
import { FileDeposit, FileDepositItem, isFileDepositId } from '@domain/file-deposit';
import { MediaBucket, TemporaryMedia } from '@domain/media';

import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { FILE_DEPOSIT_URI } from '@implementations/bundles/backend/easyhpad/config/file-deposit';
import { parseBackendResponse } from '@implementations/bundles/backend/functions';

@HandleCommand({
  command: CreateNewFileDepositItemCommand,
})
export class CreateFileDepositItemCommandHandler implements CommandHandler<CreateNewFileDepositItemCommand> {
  constructor(
    private readonly backend: EasyBackendApi,
    private readonly mediaBucket: MediaBucket,
  ) {}

  public async handle(command: CreateNewFileDepositItemCommand): Promise<any> {
    const { item } = command;

    let media: TemporaryMedia | undefined;

    try {
      media = await this.mediaBucket.add(item.media);

      const payload = {
        type: item.type,
        mediaId: media.id,
      };

      return this.backend
        .post<{ entities: FileDepositItem[] }>(this.buildEndpoint(item.fileDepositId), { items: [payload] })
        .then(response => parseBackendResponse(response))
        .then(body => body.entities[0]);
    } catch (e) {
      if (media !== undefined) {
        await media.markForDeletion();
      }

      throw e;
    }
  }

  private buildEndpoint(id: FileDeposit['id']): string {
    if (!isFileDepositId(id)) {
      throw new Error(`${id} is not a valid FileDeposit ID`);
    }
    return joinPath(FILE_DEPOSIT_URI, `${id}/items`);
  }
}
