import { CreateAccommodationFundingDto } from '@application/bundles/accommodation-funding';
import { Facility } from '@domain/facility';
import { LocalMedia } from '@domain/media';
import { IsLocalMedia } from '@implementations/bundles/media';
import { IsArray, IsDate, IsNumber, IsOptional, Max, Min, ValidateNested } from 'class-validator';
import { isPercentage } from '@domain/lib/math/percentage/percentage';
import { AccommodationDailyRateDto } from '@implementations/bundles/accommodation-funding/dto/accommodation-daily-rate.dto';
import { IsValidFacilityId } from '@implementations/bundles/facility';
import { IsPositiveFundingAmount } from '@implementations/bundles/funding/validators';
import { isPositiveFundingAmount } from '@domain/funding';

export class CreateAccommodationFundingDtoImpl implements CreateAccommodationFundingDto {
  @IsDate()
  public date!: Date;

  @IsOptional()
  @IsPositiveFundingAmount()
  public permanentAmount?: number | null;

  @IsOptional()
  @IsPositiveFundingAmount()
  public temporaryAmount?: number | null;

  @IsOptional()
  @IsPositiveFundingAmount()
  public daycareAmount?: number | null;

  @IsArray()
  @ValidateNested({ each: true })
  public dailyRates!: Array<AccommodationDailyRateDto>;

  @IsNumber()
  @Min(0)
  @Max(100)
  @IsOptional()
  public occupationRate: number | undefined;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  @IsLocalMedia()
  public pv!: LocalMedia;

  @IsOptional()
  @IsLocalMedia()
  public report!: LocalMedia;

  constructor(values?: Partial<CreateAccommodationFundingDto>) {
    if (values) {
      if (values?.date) {
        this.date = values.date;
      }

      if (Array.isArray(values.dailyRates)) {
        this.dailyRates = values.dailyRates;
      }

      if (isPercentage(values?.occupationRate)) {
        this.occupationRate = values.occupationRate;
      }

      if (values?.facilityId) {
        this.facilityId = values.facilityId;
      }

      if (values?.pv) {
        this.pv = values.pv;
      }

      if (values?.report) {
        this.report = values.report;
      }

      if (isPositiveFundingAmount(values.permanentAmount)) {
        this.permanentAmount = values.permanentAmount;
      }

      if (isPositiveFundingAmount(values.temporaryAmount)) {
        this.temporaryAmount = values.temporaryAmount;
      }

      if (isPositiveFundingAmount(values.daycareAmount)) {
        this.daycareAmount = values.daycareAmount;
      }
    }
  }
}
