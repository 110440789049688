import {isValidMediaIdType} from "@application/bundles/media";
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';

@ValidatorConstraint({name: 'IsValidMediaId', async: true})
export class IsValidMediaIdConstraint implements ValidatorConstraintInterface {

  public validate(id: any, args: ValidationArguments) {
    return isValidMediaIdType(id);
  }

  public defaultMessage(args: ValidationArguments) {
    return 'This ID is not a valid media ID.';
  }
}


export function IsMediaId(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidMediaIdConstraint,
    });
  };
}
