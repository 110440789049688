import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { FacilityActions, selectFacilityName } from '@easyhpad-ui/app/bundles/facility/store';
import { Facility } from '@domain/facility';
import { Subscription } from 'rxjs';
import { AppState } from '@easyhpad-ui/app/store';

@Component({
  selector: 'ehp-facility-name',
  templateUrl: './facility-name.component.html',
  styleUrls: ['./facility-name.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FacilityNameComponent implements OnChanges, OnDestroy {
  @Input() public fid: Facility['id'] | undefined;

  @Input() public facility: Facility | undefined;

  public name: Facility['name'] | undefined;

  private subscription: Subscription | undefined;

  constructor(private readonly store: Store<AppState>) {}

  public ngOnChanges(changes: SimpleChanges) {
    if ('fid' in changes) {
      this.selectFacilityName(this.fid);

      if (changes['fid'].currentValue === undefined && 'facility' in changes) {
        this.setFacility(changes['facility'].currentValue);
      }
    } else if ('facility' in changes) {
      this.setFacility(this.facility);
    }
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

  private selectFacilityName(id: Facility['id'] | undefined) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (id === undefined) {
      this.name = undefined;
      return;
    }

    this.subscription = this.store.select(selectFacilityName(id)).subscribe(name => {
      this.name = name;
    });

    this.store.dispatch(FacilityActions.loadFacilityName({ id }));
  }

  private setFacility(facility: Facility | undefined) {
    this.facility = facility;
    this.name = this.facility?.name;

    if (this.fid !== facility?.id) {
      this.fid = facility?.id;
    }

    if (facility && this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
