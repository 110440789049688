import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpAuthorizationTokenStore } from '@application//framework/http';
import { AuthentificationState } from '@application/bundles/authentification/abstraction/authentification-state';
import { LoginCommand } from '@application/bundles/authentification/commands/login.command';
import { LogoutCommand } from '@application/bundles/authentification/commands/logout.command';
import { TryAutoReconnectCommand } from '@application/bundles/authentification/commands/try-autoreconnect.command';
import { AuthenticatorImpl } from '@application/bundles/authentification/implementations/authenticator/authenticator';
import { HttpAuthorizationTokenAgnosticStore } from '@application/bundles/authentification/storage/http-authorization-token-agnostic.store';
import { Bundle } from '@application/framework/bundle';
import { EventDispatcher } from '@application/framework/event';
import { Logger } from '@application/framework/logger';
import { Authenticator, AuthentificationTokenStore } from '@application/bundles/authentification/abstraction';
import { ForgottenPasswordPageComponent } from '@easyhpad-ui/app/bundles/authentification/pages/forgotten-password/forgotten-password-page.component';
import { AuthentificationUrlsProvider } from '@easyhpad-ui/app/bundles/authentification/providers/urls/authentification-urls.provider';
import { CommandQueryModule } from '@easyhpad-ui/app/framework/command-query/command-query.module';
import { TranslationModule } from '../../framework/translation/translation.module';
import { ReusableComponentsModule } from '../reusable-components/reusable-components.module';
import { AuthentificationBundle } from './authentification.bundle';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { AccessImageComponent } from './components/access-image/access-image.component';
import { ResetPasswordPageComponent } from '@easyhpad-ui/app/bundles/authentification/pages';
import { CheckResetPasswordTokenValidityCommand } from '@application/bundles/user';
import { ResetPasswordCommand } from '@application/bundles/user/commands/reset-password.command';
import { AgnosticAuthentificationTokenStore } from '@application/bundles/authentification/storage/agnostic-token-storage/agnostic-authentification-token.store';
import { UserFormModule } from '@easyhpad-ui/app/bundles/user/modules/form/user-form.module';
import { StoreModule } from '@ngrx/store';
import { AUTHENTIFICATION_STORE_KEY, AuthentificationEffects, authentificationReducer } from './store';
import { ReduxAuthentificationState } from './services';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [
    LoginPageComponent,
    LogoutPageComponent,
    ForgottenPasswordPageComponent,
    AccessImageComponent,
    ResetPasswordPageComponent,
  ],
  imports: [
    StoreModule.forFeature(AUTHENTIFICATION_STORE_KEY, authentificationReducer),
    EffectsModule.forFeature([AuthentificationEffects]),
    CommonModule,
    TranslationModule,
    ReusableComponentsModule,
    UserFormModule,
    CommandQueryModule.forRoot({
      bypass: [
        TryAutoReconnectCommand,
        LoginCommand,
        LogoutCommand,
        CheckResetPasswordTokenValidityCommand,
        ResetPasswordCommand,
      ],
    }),
  ],
  providers: [
    AuthentificationUrlsProvider,
    {
      provide: HttpAuthorizationTokenStore,
      useValue: new HttpAuthorizationTokenAgnosticStore(),
    },
    {
      provide: AuthentificationTokenStore,
      useValue: new AgnosticAuthentificationTokenStore(),
    },
    {
      provide: AuthentificationState,
      useClass: ReduxAuthentificationState,
    },
    {
      provide: Authenticator,
      useClass: AuthenticatorImpl,
      deps: [EventDispatcher, AuthentificationState, Logger],
    },
  ],
  exports: [AccessImageComponent],
})
@Bundle({
  imports: [AuthentificationBundle],
})
export class AuthentificationModule {}
