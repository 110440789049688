import {HttpAuthorizationToken, HttpAuthorizationTokenStore} from "@application/framework/http";
import {StoreKey} from "@application/framework/store";
import {
  InMemoryAgnosticStore
} from "@application/framework/store/implementations/agnostic-store/in-memory-agnostic-store";

export class HttpAuthorizationTokenAgnosticStore extends InMemoryAgnosticStore<HttpAuthorizationToken<any>> implements HttpAuthorizationTokenStore {


  public override remove(key: StoreKey) {
    const token = this.get(key);

    if (typeof token?.destroy === "function") {
      token.destroy();
    }

    super.remove(key);
  }
}
