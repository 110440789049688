import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {HealthFunding} from "@domain/health-funding";

export class HealthFundingCreatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly healthFunding: HealthFunding) {
    super();
  }
}
