import {GetJobValuesQuery} from "@application/bundles/user/queries/get-job-values.query";
import {HandleQuery} from "@application/framework/command-query";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {Provide} from "@application/framework/di";
import {Job} from "@domain/user/job.interface";
import {JobRepository} from "@domain/user/job.repository";

@HandleQuery({
  query: GetJobValuesQuery
})
export class GetJobValuesHandler implements QueryHandler<GetJobValuesQuery, Job[]> {

  @Provide()
  private readonly repository!: JobRepository;


  public handle(query: GetJobValuesQuery): Promise<Job[]> {
    return this.repository.list();
  }
}
