import {
  CreateDependencyFundingDto,
  DependencyFundingDtoFactory,
  UpdateDependencyFundingDto,
} from '@application/bundles/dependency-funding';
import {
  CreateDependencyFundingDtoImpl,
  UpdateDependencyFundingDtoImpl,
} from '@implementations/bundles/dependency-funding/dto';

export class DependencyFundingDtoFactoryImpl implements DependencyFundingDtoFactory {
  public buildCreateDtoFromUnsafeValues(values: Partial<CreateDependencyFundingDto>): CreateDependencyFundingDto {
    return new CreateDependencyFundingDtoImpl(values);
  }

  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdateDependencyFundingDto>): UpdateDependencyFundingDto {
    return new UpdateDependencyFundingDtoImpl(values);
  }
}
