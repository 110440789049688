import { CommonModule, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SettingsStore } from '@application/bundles/application/store/settings-store';
import { AuthorizationChecker } from '@application/bundles/authorization';
import { FacilityAuthorizationChecker } from '@application/bundles/facility/authorization-checker';
import { FacilityBundle } from '@application/bundles/facility/facility-bundle';
import { FacilityDtoFactory } from '@application/bundles/facility/factories/facility-dto.factory';
import { CurrentFacilitiesStore } from '@application/bundles/facility/store';
import { SynchroneCollectionAsynchroneAdapter } from '@application/framework/store/adpaters/async-collection/synchrone-collection-asynchrone.adapter';
import { InMemoryAgnosticCollection } from '@application/framework/store/implementations/agnostic-collection/in-memory-agnostic.collection';
import { FacilityTypeRepository } from '@domain/facility/facility-type.repository';
import { FacilityRepository } from '@domain/facility/facility.repository';
import { SocialClearanceRepository } from '@domain/facility/social-clearance.repository';
import { AddressModule } from '@easyhpad-ui/app/bundles/address/address.module';
import { FacilityManageGuard } from '@easyhpad-ui/app/bundles/facility/guards/facility-manage/facility-manage.guard';
import {
  CURRENT_FACILITIES_COLLECTION,
  DASHBOARD_FACILITES_COLLECTION,
} from '@easyhpad-ui/app/bundles/facility/injection.token';
import { FacilityAccessChecker } from '@easyhpad-ui/app/bundles/facility/services/authorization-checker/facility-access-checker.service';
import { FacilityUrls } from '@easyhpad-ui/app/bundles/facility/services/facility-urls/facility-urls';
import { DiModule } from '@easyhpad-ui/app/framework/di/di-module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator/validator.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import {
  EasyFacilityRepository,
  EasyFacilityTypeRepository,
  EasySocialClearanceRepository,
} from '@implementations/bundles/backend/easyhpad/repositories';
import { DashboardFacilityAccessor } from '@implementations/bundles/facility/accessor/dashboard-facility-accessor';
import { CreateFacilityDtoImpl } from '@implementations/bundles/facility/dto/create-facility.dto';
import { UpdateFacilityDtoImpl } from '@implementations/bundles/facility/dto/update-facility.dto';
import { FacilityDtoFactoryImpl } from '@implementations/bundles/facility/factories/facility-dto.factory';
import { CurrentFacilitiesStoreImpl } from '@implementations/bundles/facility/store/current-facilities.store';
import { validateCreateFacilityDtoImpl } from '@implementations/bundles/facility/validators/create-facility.validator';
import { validateUpdateFacilityDtoImpl } from '@implementations/bundles/facility/validators/update-facility.validator';
import { ConfirmFacilityDeletionDialogComponent } from './components/confirm-facility-deletion-dialog/confirm-facility-deletion-dialog.component';
import { DashboardFacilitiesStore } from '@implementations/bundles/facility';
import { StoreModule } from '@ngrx/store';
import { FACILITY_STORE_KEY, FacilityEffects, facilityReducer } from '@easyhpad-ui/app/bundles/facility/store';
import { QueryBus } from '@application/framework/command-query';
import { EffectsModule } from '@ngrx/effects';
import { Bundle } from '@application/framework/bundle';
import { FacilityStoreUpdateSubscriber } from '@easyhpad-ui/app/bundles/facility/events/subscribers/facility-store-update/facility-store-update.subscriber';
import { RouterLink } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    DiModule,
    StoreModule.forFeature(FACILITY_STORE_KEY, facilityReducer),
    EffectsModule.forFeature([FacilityEffects]),
    ValidatorModule.forChild({
      validators: [
        { objectType: CreateFacilityDtoImpl, validator: validateCreateFacilityDtoImpl },
        { objectType: UpdateFacilityDtoImpl, validator: validateUpdateFacilityDtoImpl },
      ],
    }),
    TranslationModule,
    ReactiveFormsModule,
    FormModule,
    NgIf,
    AddressModule,
    RouterLink,
  ],
  providers: [
    FacilityAccessChecker,
    FacilityManageGuard,
    FacilityUrls,
    {
      provide: FacilityRepository,
      useClass: EasyFacilityRepository,
    },
    {
      provide: FacilityTypeRepository,
      useClass: EasyFacilityTypeRepository,
    },
    {
      provide: SocialClearanceRepository,
      useClass: EasySocialClearanceRepository,
    },
    {
      provide: FacilityDtoFactory,
      useClass: FacilityDtoFactoryImpl,
    },
    {
      provide: FacilityAuthorizationChecker,
      useClass: FacilityAuthorizationChecker,
      deps: [AuthorizationChecker],
    },
    {
      provide: CURRENT_FACILITIES_COLLECTION,
      useValue: new SynchroneCollectionAsynchroneAdapter(new InMemoryAgnosticCollection()),
    },
    {
      provide: DASHBOARD_FACILITES_COLLECTION,
      useValue: new SynchroneCollectionAsynchroneAdapter(new InMemoryAgnosticCollection()),
    },
    {
      provide: CurrentFacilitiesStore,
      useClass: CurrentFacilitiesStoreImpl,
      deps: [CURRENT_FACILITIES_COLLECTION, QueryBus, SettingsStore],
    },
    {
      provide: DashboardFacilityAccessor,
      useValue: new DashboardFacilityAccessor(),
    },
    {
      provide: DashboardFacilitiesStore,
      useClass: DashboardFacilitiesStore,
      deps: [CURRENT_FACILITIES_COLLECTION],
    },
  ],
  declarations: [ConfirmFacilityDeletionDialogComponent],
})
@Bundle({
  imports: [FacilityBundle],
  eventSubscribers: [FacilityStoreUpdateSubscriber],
})
export class FacilityModule {}
