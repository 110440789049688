import { Dialog } from '@angular/cdk/dialog';
import { CommandBus, CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { ConfirmDeletionDialogComponent } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component';
import { ConfirmDeletionDialogData } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface';
import { DialogConfiguration } from '@easyhpad-ui/app/library/dialog';
import { DateFormatter } from '@application/framework/date';
import { OpenAccommodationFundingDeletionDialogCommand } from '@easyhpad-ui/app/bundles/accommodation-funding/commands';
import { DeleteAccommodationFundingCommand } from '@application/bundles/accommodation-funding';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';

@HandleCommand({
  command: OpenAccommodationFundingDeletionDialogCommand,
})
export class OpenAccommodationFundingDeletionDialogCommandHandler
  implements CommandHandler<OpenAccommodationFundingDeletionDialogCommand, void>
{
  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus,
    private readonly dateFormatter: DateFormatter,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: OpenAccommodationFundingDeletionDialogCommand): Promise<void> {
    const { document, onDeletion } = command;
    const facility = await this.queryBus.request(new GetFacilityQuery(document.facilityId));

    const data: ConfirmDeletionDialogData = {
      options: [
        { key: document.id, label: `${facility.name} ${this.dateFormatter.format(document.date, 'dd/MM/yyyy')}` },
      ],
    };

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<AccommodationFunding>(
          new DeleteAccommodationFundingCommand(document),
        );

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    };

    this.dialog
      .open<boolean>(ConfirmDeletionDialogComponent, { ...DialogConfiguration, data })
      .closed.subscribe(subscriber);
  }
}
