import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslationModule} from "../../../../framework/translation/translation.module";
import {ReusableComponentsModule} from "../../../reusable-components/reusable-components.module";

import {TabViewModule} from "primeng/tabview";
import {GmpUIModule} from "@easyhpad-ui/app/bundles/gmp/modules/ui/gmp-ui.module";
import {
  WidgetAuthorizedCapacitiesComponent,
  WidgetCalculatorComponent,
  WidgetEPRDERRDComponent,
  WidgetFundingComponent,
  WidgetOccupationRateComponent,
  WidgetValidateIndicatorsComponent
} from "./components";
import {DashboardModule} from "@easyhpad-ui/app/bundles/dashboard/dashboard.module";
import {RouterModule} from "@angular/router";
import {GirUIModule} from "@easyhpad-ui/app/bundles/gir/modules/ui/gir-ui.module";
import {GaugeModule} from "@swimlane/ngx-charts";


@NgModule({
  declarations: [
    WidgetAuthorizedCapacitiesComponent,
    WidgetValidateIndicatorsComponent,
    WidgetFundingComponent,
    WidgetCalculatorComponent,
    WidgetEPRDERRDComponent,
    WidgetOccupationRateComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    ReusableComponentsModule,
    TabViewModule,
    GmpUIModule,
    DashboardModule,
    RouterModule,
    GirUIModule,
    GaugeModule,
  ]
})
export class DashboardWidgetsModule {
}
