import {AddInspectionReportDto} from "@application/bundles/capacity-authorization/dtos";
import {Command} from "@application/framework/command-query";
import {CapacityAuthorization, InspectionReport} from "@domain/capacity-authorization";

export class AddInspectionReportCommand implements Command<InspectionReport> {


  public constructor(public readonly report: AddInspectionReportDto, public readonly authorizationId: CapacityAuthorization['id']) {
  }
}
