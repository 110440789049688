import { CRUD_ACTIONS } from '@application/bundles/authorization';
import { Transformer } from '@application/framework/core';
import { Permission } from '@domain/authorization';
import { PERMISSION_ENTITIES } from '@implementations/bundles/backend/easyhpad/authorization/permission-entities';
import { EasyPermissionsItemInterface } from '@implementations/bundles/backend/easyhpad/interfaces/easy-permissions-item.interface';

export class EasyPermissionItemsTransformer implements Transformer<Permission[], EasyPermissionsItemInterface[]> {
  public transform(permissions: Permission<PERMISSION_ENTITIES, string>[]): EasyPermissionsItemInterface[] {
    const items: Map<PERMISSION_ENTITIES, Array<{ action: string; owned: boolean }>> = new Map();

    permissions.forEach((permission) => {
      const allowed = items.get(permission.feature) ?? [];
      allowed.push({ action: permission.type, owned: permission.owned });
      items.set(permission.feature, allowed);
    });

    return Array.from(items.entries()).map(([entity, allowed]) => ({ entity, allowed }));
  }

  public reverseTransform(items: EasyPermissionsItemInterface[]): Permission[] {
    const permissions: Permission[] = [];

    items.forEach((item) => {
      item.allowed.forEach((allowed) => {
        permissions.push({
          owned: allowed.owned,
          type: allowed.action as CRUD_ACTIONS,
          feature: item.entity,
        });
      });
    });

    return permissions;
  }
}
