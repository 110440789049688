import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { CheckResetPasswordTokenValidityCommand } from '@application/bundles/user';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { User } from '@domain/user';

@HandleCommand({
  command: CheckResetPasswordTokenValidityCommand,
})
export class CheckResetPasswordTokenValidityCommandHandler
  implements CommandHandler<CheckResetPasswordTokenValidityCommand, Pick<User, 'displayName'> | undefined>
{
  private readonly basePath = '/user/retrieve-password';

  constructor(private readonly backend: EasyBackendApi) {}

  public handle(command: CheckResetPasswordTokenValidityCommand): Promise<Pick<User, 'displayName'> | undefined> {
    return this.backend
      .get<{ name: string; surname: string; email: string; id: number }>(`${this.basePath}/${command.token}`)
      .then(response => response.body)
      .then(partialUser => ({ displayName: `${partialUser.name} ${partialUser.surname}`.trim() }))
      .catch(() => undefined);
  }
}
