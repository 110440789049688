import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Translator } from '@application/framework/translation';
import { TranslateLoader, TranslateModule, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslatableStringComponent } from './components/translatable-string/translatable-string.component';
import { EhpTranslatePipe } from './pipes/translate/translate.pipe';
import { TranslatorImpl } from './services/translator/translator-impl.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './app/ui/assets/i18n/', '.json');
}

const nxgTranslationModuleConfig: TranslateModuleConfig = {
  defaultLanguage: 'fr',
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
};

@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule.forRoot(nxgTranslationModuleConfig)],
  declarations: [EhpTranslatePipe, TranslatableStringComponent],
  providers: [
    {
      provide: Translator,
      useClass: TranslatorImpl,
    },
  ],
  exports: [EhpTranslatePipe, TranslatableStringComponent],
})
export class TranslationModule {}
