import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  DynamicAbstractControlField,
  DynamicFormElementTypeComponent,
  DynamicSelectField,
  DynamicSelectOption,
} from '@easyhpad-ui/app/library/form/contracts';
import { AbstractControl, FormControl, NgModel } from '@angular/forms';
import { isObservable, Subject, takeUntil } from 'rxjs';
import { syncControlsDirty, syncControlsTouch } from '@easyhpad-ui/app/library/form/functions';
import { FormControlDirective } from '@easyhpad-ui/app/library/form/directives/form-control/form-control.directive';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'ehp-dynamic-select-field',
  templateUrl: './dynamic-select-field.component.html',
  styleUrl: './dynamic-select-field.component.scss',
})
export class DynamicSelectFieldComponent
  implements DynamicFormElementTypeComponent<DynamicSelectField>, OnInit, AfterViewInit, OnDestroy
{
  @Input() public readonly field!: DynamicAbstractControlField<DynamicSelectField>;

  public options: DynamicSelectOption[] = [];

  /**
   * Current selected Options
   */
  public selected: DynamicSelectOption | undefined;

  /**
   * Dropdown placeholder
   */
  public placeholder: string = 'Sélectionner une option';

  /**
   * Select Form control reference
   * @private
   */
  @ViewChild('select', { read: FormControlDirective }) private formControl!: FormControlDirective;

  /**
   * Angular select NgModel Directive
   * @private
   */
  @ViewChild('select', { read: NgModel }) private readonly ngModel!: NgModel;

  private destroy$ = new Subject<void>();

  public get control(): FormControl | null {
    return this.field.control ? (this.field.control as FormControl) : null;
  }

  public get debug() {
    return {
      control: {
        touched: this.control?.touched,
        errors: this.control?.errors,
        valid: this.control?.valid,
      },
    };
  }

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  /**
   * @inheritDoc
   */
  public ngOnInit() {
    this.field.options.pipe(takeUntil(this.destroy$)).subscribe(options => this.updateOptions(options));

    if (isObservable(this.field.initialValue)) {
      this.field.initialValue.subscribe((value: any) => this.setInitialValue(value));
    }

    if (this.control) {
      this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => this.patchInternalValue(value));
    }
  }

  public ngAfterViewInit(): void {
    if (this.control) {
      if (this.ngModel) {
        this.linkModelAndControl(this.ngModel, this.control);
      }

      if (this.formControl) {
        this.formControl.attachControl(this.control);
      }
    }

    this.changeDetector.detectChanges();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectChange(event: DropdownChangeEvent) {
    const value = (event.value as DynamicSelectOption).value;
    this.updateValue(value);
  }

  /**
   * Update field control value.
   * @param value
   */
  public updateValue(value: string | number | null | undefined): void {
    this.control?.setValue(value);
  }

  private updateOptions(options: Array<DynamicSelectOption>) {
    this.options = options;

    // Reset the current value if the selected option is not in provided options.
    if (this.selected) {
      const value = this.selected.value;
      const allowed = options.map(option => option.value);

      if (!allowed.includes(value)) {
        this.selected = undefined;
        this.updateValue(null);
      }
    }
  }

  private linkModelAndControl(model: NgModel, control: AbstractControl): void {
    syncControlsDirty(model.control, control);
    syncControlsTouch(model.control, control);

    if (control.dirty) {
      model.control.markAsDirty();
    }

    if (control.touched) {
      model.control.markAsTouched();
    }
  }

  private setInitialValue(value: DynamicSelectOption | DynamicSelectOption['value'] | null | undefined) {
    if (this.control?.touched || this.control?.dirty) {
      return;
    }

    this.patchInternalValue(value);

    if (
      (value === null || value === undefined) &&
      (this.control?.value === null || this.control?.value === undefined)
    ) {
      return;
    }
    this.control?.patchValue(value);
  }

  private patchInternalValue(value: DynamicSelectOption | DynamicSelectOption['value'] | null | undefined) {
    if (value === null || value === undefined) {
      if (this.selected !== undefined) {
        this.selected = undefined;
      }
      return;
    }

    const values = this.options.map(opt => opt.value);
    if (this.isOption(value) && values.includes(value.value)) {
      this.selected = this.options.find(opt => opt.value === value.value);
    } else if (typeof value === 'string' || typeof (value as any) === 'number') {
      this.selected = this.options.find(opt => opt.value === value);
    }
  }

  private isOption(option: any): option is DynamicSelectOption {
    return typeof option === 'object' && option !== null && 'value' in option && 'label' in option;
  }
}
