import {PhoneNumber} from "@domain/phone/phone.interface";

export abstract class PhoneValidator {

  public abstract validate(phone: PhoneNumber): Promise<PhoneNumber>;

  public abstract validatePhoneString(phone: string, region?: string): Promise<PhoneNumber>;

  public abstract isValidPhone(phone: PhoneNumber): Promise<boolean>;

}
