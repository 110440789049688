import { CreateGMPDto } from '@application/bundles/gmp';
import { Service } from '@application/framework/di';
import { Transformer } from '@application/framework/core';
import { EasyGMPCreateDto } from '@implementations/bundles/backend/easyhpad';
import { EasyGMPCommonTransformer } from '@implementations/bundles/backend/easyhpad/transformers/gmp/easy-gmp-common.transformer';
import { CreateGmpDtoImpl } from '@implementations/bundles/gmp';

@Service()
export class EasyCreateGmpDtoTransformer implements Transformer<CreateGMPDto, EasyGMPCreateDto> {
  constructor(private readonly transformer: EasyGMPCommonTransformer) {}

  public transform(value: CreateGMPDto): EasyGMPCreateDto {
    return {
      ...this.transformer.transform(value),
      pvId: value.pvId || '',
    };
  }

  public reverseTransform(value: EasyGMPCreateDto): CreateGMPDto {
    return new CreateGmpDtoImpl({
      ...this.transformer.reverseTransform(value),
      pvId: value.pvId,
    });
  }
}
