import {CreateEhpadDemainDto, EhpadDemainDtoFactory, UpdateEhpadDemainDto} from "@application/bundles/ehpad-demain";
import {CreateEhpadDemainDtoImpl} from "@implementations/bundles/ehpad-demain/dto/create-ehpad-demain.dto";
import {UpdateEhpadDemainDtoImpl} from "@implementations/bundles/ehpad-demain/dto/update-ehpad-demain.dto";

export class EhpadDemainDtoFactoryImpl implements EhpadDemainDtoFactory {
  buildCreateEhpadDemainDtoFromUnsafeValues(values: Partial<CreateEhpadDemainDto>): CreateEhpadDemainDto {
    return new CreateEhpadDemainDtoImpl(values);
  }

  buildUpdateEhpadDemainDtoFromUnsafeValues(values: Partial<UpdateEhpadDemainDto>): UpdateEhpadDemainDto {
    return new UpdateEhpadDemainDtoImpl(values);
  }

}
