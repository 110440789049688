import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FiltersFormBuilder } from '@easyhpad-ui/app/library/filter/services/filters-form/filters-form.builder';
import { FilterDefinition, FilterOperator } from '@easyhpad-ui/app/library/filter/interfaces';

@Component({
  selector: 'ehp-advanced-filters-form',
  templateUrl: './advanced-filters-form.component.html',
  styleUrls: ['./advanced-filters-form.component.scss'],
})
export class AdvancedFiltersFormComponent implements OnInit {
  @Input() public definitions!: Array<FilterDefinition>;

  @Input() public initialValues: Array<{ key: string; op: FilterOperator; value: any }> = [];

  @Output() public valueChanges = new EventEmitter();

  @Output() public validityChanges = new EventEmitter();

  public form!: FormArray<FormGroup>;

  public names = new Map<number, string>();

  constructor(private readonly fb: FiltersFormBuilder) {}

  public ngOnInit() {
    this.form = this.fb.buildFiltersForm();

    this.form.valueChanges.subscribe(change => this.valueHasChange(change));
    this.form.statusChanges.subscribe(change => this.validityChanges.emit(change === 'VALID'));

    if (Array.isArray(this.initialValues) && this.initialValues.length > 0) {
      this.fb.patchFormValues(this.form, this.initialValues);
    }
  }

  public addFilter() {
    this.fb.addFilterItem(this.form);
  }

  public removeFilter(index: number): void {
    this.names.delete(index);
    this.fb.removeFilterItem(this.form, index);
  }

  public patchFilterValue(index: number, value: any): void {
    const values = this.form.value;
    values[index] = value;
    this.form.patchValue([...values]);
  }

  public getControlPanelName(index: number) {
    let name = this.names.get(index);

    if (name === undefined) {
      name = `Filtre ${index + 1}`;
      this.names.set(index, name);
    }

    return name;
  }

  public valueHasChange(changes: Array<any>): void {
    changes = changes.filter(change => {
      return !(!change.key || !change.op || !(change.value !== null && change.value !== undefined));
    });

    this.valueChanges.emit(changes);
  }

  public updatePanelName(index: number, key: string | null) {
    let name = `Filtre ${index + 1}`;

    if (key && this.definitions.find(def => def.key === key)) {
      name = this.definitions.find(def => def.key === key)?.label as string;
    }

    this.names.set(index, name);
  }
}
