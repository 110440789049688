import { CreateFacilityDto, UpdateFacilityDto } from '@application/bundles/facility/dto';
import { isEmptyContact } from '@application/bundles/facility/validators';
import { PhoneFromStringTransformer } from '@application/bundles/phone/transformer/phone-from-string.transformer';
import { AsyncTransformer } from '@application/framework/core';
import { Facility, FinessNumber } from '@domain/facility';
import { FacilityTypeRepository } from '@domain/facility/facility-type.repository';
import { SocialClearanceRepository } from '@domain/facility/social-clearance.repository';
import {
  EasyFacility,
  EasyFacilityCreateDto,
  EasyFacilityUpdateDto,
} from '@implementations/bundles/backend/easyhpad/interfaces';
import { EasyAddressTransformer } from '@implementations/bundles/backend/easyhpad/transformers/easy-address/easy-address.transformer';

interface FacilityCommonProperties extends Omit<Facility, 'id' | 'createdAt' | 'updatedAt' | 'state' | 'address'> {}

interface EasyFacilityCommonProperties
  extends Omit<EasyFacility, 'id' | 'created_at' | 'updated_at' | 'customer' | 'users' | 'state' | 'address'> {}

export abstract class AbstractEasyFacilityTransformer
  implements AsyncTransformer<Partial<Facility>, Partial<EasyFacility>>
{
  protected addressTransformer = new EasyAddressTransformer();

  public constructor(
    private readonly phoneTransformer: PhoneFromStringTransformer,
    private readonly facilityTypeRepository: FacilityTypeRepository,
    private readonly socialClearanceRepository: SocialClearanceRepository,
  ) {}

  public abstract transform(value: Facility): Promise<any>;

  public abstract reverseTransform(value: EasyFacility): Promise<any>;

  protected async fromEasyFacility(
    value: EasyFacility | EasyFacilityCreateDto | EasyFacilityUpdateDto,
  ): Promise<FacilityCommonProperties> {
    const facility: FacilityCommonProperties = {
      name: value.name,
      type: value.type,
      socialClearance: value.socialClearance,
      phone: value.phone ? this.phoneTransformer.transform(value.phone) : undefined,
      finessGeo: value.geoFiness ? new FinessNumber(value.geoFiness) : undefined,
      finessLegal: value.legalFiness ? new FinessNumber(value.legalFiness) : undefined,
      managingOrganization: value.managingOrganization || undefined,
      customerId: value.customerId ? value.customerId : undefined,
    };

    if (value.contact) {
      const contact = {
        id: value.contact.id,
        name: value.contact.name || '',
        email: value.contact.email || '',
        job: '',
        phone: value.contact.phone ? this.phoneTransformer.transform(value.contact.phone) : undefined,
      };

      if (!isEmptyContact(contact)) {
        facility.contact = contact;
      }
    }

    return facility;
  }

  protected async toEasyFacility(
    value: Facility | UpdateFacilityDto | CreateFacilityDto,
  ): Promise<EasyFacilityCommonProperties> {
    const easyFacility: EasyFacilityCommonProperties = {
      name: value.name,
      type: value.type,
      socialClearance: value.socialClearance,
      customerId: value.customerId ? value.customerId : null,
      geoFiness: value.finessGeo ? value.finessGeo.raw : '',
      legalFiness: value.finessLegal ? value.finessLegal.raw : '',
      managingOrganization: value.managingOrganization,
      phone: value.phone ? this.phoneTransformer.reverseTransform(value.phone) : null,
      pole: null,
    };

    if (value.contact && !isEmptyContact(value.contact)) {
      easyFacility.contact = {
        id: value.contact?.id || undefined,
        name: value.contact?.name || undefined,
        email: value.contact?.email || undefined,
        phone: value.contact?.phone ? this.phoneTransformer.reverseTransform(value.contact?.phone) : null,
      };
    }

    return easyFacility;
  }
}
