import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Facility } from '@domain/facility';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { Observable, Subscription } from 'rxjs';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'ehp-facility-select',
  templateUrl: './facility-select.component.html',
  styleUrls: ['./facility-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FacilitySelectComponent),
      multi: true,
    },
  ],
})
export class FacilitySelectComponent implements OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() public id: string | undefined;

  @Input() public loading$: Observable<boolean> | undefined;

  @Input() public source$: Observable<Facility[]> | undefined;

  @Input() public required: boolean = false;

  @Input() public readonly: boolean = false;

  @Output() public facilityChange: EventEmitter<Facility | null> = new EventEmitter();

  @ViewChild('autocomplete') public autocomplete: AutocompleteComponent | undefined;

  public facilities: Facility[] = [];

  public value: Facility['id'] | null = null;

  public disabled: boolean = false;

  public keyword = 'name';

  private subscription: Subscription | undefined;

  public get nodeName(): string {
    return 'ehp-facility-select';
  }

  public get selectedFacility(): Facility | undefined {
    if (!isValidFacilityIdType(this.value)) {
      return;
    }

    return this.findFacilityOption(this.value);
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('source$' in changes) {
      this.unsubscribe();

      if (changes['source$'].currentValue instanceof Observable) {
        this.subscription = this.source$?.subscribe(facilities => this.updateFacilityOptions(facilities));
      }
    }
  }

  public ngAfterViewInit(): void {
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

  public onChange = (value: Facility['id'] | null): void => undefined;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: Facility | Facility['id'] | null | undefined): void {
    if (isValidFacilityIdType(value)) {
      this.value = value;
    } else if (typeof value === 'object' && value && isValidFacilityIdType(value.id)) {
      this.value = value.id;
    } else {
      this.value = null;
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Dispatch the new value in 'onChange' and in the Facility emitter
   */
  public dispatchChange(event: DropdownChangeEvent): void {
    this.onChange(event.value ?? null);
    this.facilityChange.emit(this.findFacilityOption(event.value));
  }

  /**
   * Update the internal facilities list and prevent wrong value.
   * @param facilities
   * @private
   */
  private updateFacilityOptions(facilities: Array<Facility>): void {
    // Ensure the current value is an ID of provided facilities.
    if (this.value) {
      const ids = new Set(facilities.map(facility => facility.id));

      if (!ids.has(this.value)) {
        this.writeValue(null);
      }
    }

    this.facilities = facilities;
  }

  /**
   * Search a facility in the current facilities array, using id.
   * @param id
   * @private
   */
  private findFacilityOption(id: Facility['id']): Facility | undefined {
    return this.facilities.find(facility => facility.id === id);
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
