import { TARIFF_OPTIONS } from '@domain/cpom/tariff-option';
import {
  EasyCPOM,
  EasyCPOMDetailFunding,
  EasyCreateCPOMDetailFunding,
  EasyFacility,
  EasyUpdateCPOMDetailFunding,
} from '@implementations/bundles/backend/easyhpad';
import { UUID } from '@domain/lib';

export interface EasyCPOMDetail {
  id: UUID;

  facilityId: EasyFacility['id'];

  cpom_id: EasyCPOM['id'];

  option: TARIFF_OPTIONS;

  bed_social_care?: number | null;

  fundings: EasyCPOMDetailFunding[] | undefined;
}

export interface EasyCreateCPOMDetail extends Omit<EasyCPOMDetail, 'id' | 'fundings' | 'cpom_id'> {
  fundings: EasyCreateCPOMDetailFunding[];
}

export interface EasyUpdateCPOMDetail extends Omit<EasyCPOMDetail, 'fundings' | 'cpom_id'> {
  cpom_id?: EasyCPOM['id'];

  fundings: Array<EasyCreateCPOMDetailFunding | EasyUpdateCPOMDetailFunding>;
}
