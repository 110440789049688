import {Command} from "@application/framework/command-query";
import {InspectionReport} from "@domain/capacity-authorization";
import {
  InspectionReportEditDialogData
} from "@easyhpad-ui/app/bundles/capacity-authorization/modules/form/components/dialog-inspection-report-edit/inspection-report-edit-dialog.interface";

export class OpenEditInspectionReportDialogCommand implements Command {

  public readonly options: InspectionReportEditDialogData;

  constructor(
    options: Omit<InspectionReportEditDialogData, 'mode'> & { report: InspectionReport },
    public readonly onClose?: (report: InspectionReport | undefined) => any
  ) {
    this.options = {
      ...options,
      mode: 'edit',
      report: options.report
    }
  }

}
