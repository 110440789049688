import {
  CreateEhpadDemainHealthFundingDto,
  CreateExceptionalHealthFundingDto,
  CreateHealthFundingDto,
  CreateNonRecurringHealthFundingDto,
  CreateRecurringHealthFundingDto
} from "@application/bundles/health-funding";
import {CommonHealthFundingDtoAbstract} from "./common-health-funding.dto.abstract";
import {IsArray, IsOptional} from "class-validator";
import {isValidObject} from "@application/framework/lib";
import {IsLocalMedia} from "@implementations/bundles/media";
import {isLocalMedia, LocalMedia} from "@domain/media";

export class CreateHealthFundingDtoImpl extends CommonHealthFundingDtoAbstract implements CreateHealthFundingDto {

  @IsArray()
  public recurringFunding: CreateRecurringHealthFundingDto[] = [];

  @IsArray()
  public nonRecurringFunding: CreateNonRecurringHealthFundingDto[] = [];

  @IsArray()
  public exceptionalFunding: CreateExceptionalHealthFundingDto[] = [];

  @IsArray()
  public ehpadDemainFunding: CreateEhpadDemainHealthFundingDto[] = [];

  @IsLocalMedia()
  public document!: LocalMedia;

  @IsLocalMedia()
  @IsOptional()
  public report?: LocalMedia;


  constructor(values?: Partial<CreateHealthFundingDto>) {
    super(values);

    if (isValidObject(values)) {

      if (Array.isArray(values.recurringFunding)) {
        this.recurringFunding = values.recurringFunding;
      }

      if (Array.isArray(values?.nonRecurringFunding)) {
        this.nonRecurringFunding = values.nonRecurringFunding;
      }

      if (Array.isArray(values?.exceptionalFunding)) {
        this.exceptionalFunding = values.exceptionalFunding;
      }

      if (Array.isArray(values?.ehpadDemainFunding)) {
        this.ehpadDemainFunding = values.ehpadDemainFunding;
      }

      if (isLocalMedia(values?.document)) {
        this.document = values?.document;
      }

      if (isLocalMedia(values?.report)) {
        this.report = values?.report;
      }
    }
  }

}
