import {Subscription} from '@application/framework/reactive';
import {Facility} from '@domain/facility';

/**
 * @deprecated - use Redux state instead
 */
export abstract class CurrentFacilitiesStore {
  /**
   * List all the facilities that can be selected by the current user.
   */
  public abstract available(): Promise<Facility[]>;

  /**
   * Add facility in list
   * @param facility
   */
  public abstract add(facility: Facility): Promise<void>;

  /**
   * Store and replace previous facilities
   * @param facilities
   */
  public abstract set(...facilities: Facility[]): Promise<void>;

  /**
   * Get stored facilities
   */
  public abstract get(): Promise<Facility[]>;

  /**
   * Remove all stored facilities
   */
  public abstract clear(): Promise<void>;

  /**
   * Set change subscriber
   * @param subscriber
   */
  public abstract onChange(subscriber: (facilities: Facility[]) => any): Subscription;

  public abstract hydrate(): void;
}
