import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NativeChangeObserver} from "@easyhpad-ui/app/framework/native-change-observer";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Subject} from "rxjs";

@Component({
  selector: 'ehp-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true
    }
  ]
})
export class PasswordInputComponent implements OnChanges, AfterViewInit, ControlValueAccessor {


  @Input() public hidden: boolean = true;

  @Output('visibility') public visibilityChange: Subject<boolean> = new Subject();

  @Input() public id!: string;

  public disable: boolean = false;

  public required: boolean = false;

  public value: string = '';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly nativeChanges: NativeChangeObserver,
  ) {

    this.nativeChanges.observe<string | undefined>(this.elementRef, 'id').subscribe(change => {
      this.id = change.currentValue || '';
    });

    this.nativeChanges.observe(this.elementRef, 'required').subscribe(change => {
      this.required = coerceBooleanProperty(change.currentValue);
    });

  }


  public ngOnChanges(changes: SimpleChanges) {

    if (changes['hidden']) {

      const hidden = !!changes['hidden'].currentValue;

      if (hidden !== !!changes['hidden'].previousValue) {
        this.visibilityChange.next(hidden);
      }
    }
  }

  public ngAfterViewInit() {
    this.elementRef.nativeElement.removeAttribute('id');
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  public valuesHasChange(value: string) {
    if (value !== this.value) {
      this._onChange(value);
    }
  }

  public writeValue(password: string | undefined): void {
    this.value = password ? password.toString() : '';
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }


  public toggleVisibility() {
    this.hidden = !this.hidden;
    this.visibilityChange.next(this.hidden);
  }


  private _onChange = (value: string | null | undefined): void => undefined;
}
