import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FacilityFormModule } from '@easyhpad-ui/app/bundles/facility/modules/form/facility-form.module';
import { MediaModule } from '@easyhpad-ui/app/bundles/media/media.module';
import { PMPLinkComponent } from '@easyhpad-ui/app/bundles/pmp/modules/ui/components/pmp-link/pmp-link.component';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { PmpValueComponent } from '@easyhpad-ui/app/bundles/pmp/modules/ui/components/pmp-value/pmp-value.component';
import { PmpListItemComponent } from './components/pmp-list-item/pmp-list-item.component';
import { TooltipModule } from 'primeng/tooltip';
import { PmpListComponent } from './components/pmp-list/pmp-list.component';
import { PmpOtherListComponent } from './components/pmp-other-list/pmp-other-list.component';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { FacilityUiModule } from '@easyhpad-ui/app/bundles/facility/modules/ui/facility-ui.module';
import { PmpListFiltersComponent } from './components/pmp-list-filters/pmp-list-filters.component';
import { FilterModule } from '@easyhpad-ui/app/library/filter/filter.module';
import { PmpAverageComponent } from './components/pmp-average/pmp-average.component';
import { DashboardModule } from '@easyhpad-ui/app/bundles/dashboard/dashboard.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationModule,
    FacilityFormModule,
    FormModule,
    MediaModule,
    TooltipModule,
    ReusableComponentsModule,
    FacilityUiModule,
    FilterModule,
    DashboardModule,
    RouterModule,
  ],
  declarations: [
    PMPLinkComponent,
    PmpValueComponent,
    PmpListItemComponent,
    PmpListComponent,
    PmpOtherListComponent,
    PmpListFiltersComponent,
    PmpAverageComponent,
  ],
  exports: [
    PMPLinkComponent,
    PmpValueComponent,
    PmpListComponent,
    PmpOtherListComponent,
    PmpListFiltersComponent,
    PmpAverageComponent,
  ],
})
export class PMPUIModule {}
