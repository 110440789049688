import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthentificationSuccessEvent } from '@application/bundles/authentification/events/authentification-success.event';
import { EventDispatcher } from '@application/framework/event';
import { AuthentificationUrlsProvider } from '@easyhpad-ui/app/bundles/authentification/providers/urls/authentification-urls.provider';
import { RouteSailor } from '@easyhpad-ui/app/framework/routing';
import { configuration } from '@easyhpad-ui/environments/configuration';
import { filter, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { selectCurrentAccount } from '@easyhpad-ui/app/bundles/authentification/store';

@Component({
  selector: 'ehp-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  host: {
    class: 'authentification-page',
  },
})
export class LoginPageComponent implements OnDestroy {
  public readonly urls = {
    forgottenPassword: '',
  };

  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private eventDispatcher: EventDispatcher,
    private routeSailor: RouteSailor,
    private urlsProvider: AuthentificationUrlsProvider,
    private readonly store: Store<AppState>,
  ) {
    this.redirect = this.redirect.bind(this);

    this.subscription = this.store
      .select(selectCurrentAccount)
      .pipe(filter(account => !!(account && account.isAuthenticated)))
      .subscribe(this.redirect);

    this.urls.forgottenPassword = this.urlsProvider.getForgottenPassword();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.eventDispatcher.registry.remove(AuthentificationSuccessEvent, this.redirect);
  }

  public navigateToForgotten() {
    this.routeSailor.router.navigateByUrl(this.urlsProvider.getForgottenPassword());
  }

  private redirect(): void {
    this.routeSailor.catchAndRedirect(this.route, configuration.homePath);
  }
}
