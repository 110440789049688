import {Dialog} from "@angular/cdk/dialog";
import {CommandBus, CommandHandler, HandleCommand} from "@application/framework/command-query";
import {
  ConfirmDeletionDialogComponent
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component";
import {
  ConfirmDeletionDialogData
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface";
import {DialogConfiguration} from "@easyhpad-ui/app/library/dialog";
import {
  OpenActivityAnnexDeletionDialogCommand
} from "@easyhpad-ui/app/bundles/activity-annex/commands/open-activity-annex-deletion-dialog.command";
import {DeleteActivityAnnexCommand} from "@application/bundles/activity-annex";
import {ActivityAnnex} from "@domain/activity-annex";
import {DateFormatter} from "@application/framework/date";

@HandleCommand({
  command: OpenActivityAnnexDeletionDialogCommand
})
export class OpenActivityAnnexDeletionDialogCommandHandler implements CommandHandler<OpenActivityAnnexDeletionDialogCommand, void> {

  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus,
    private readonly dateFormatter: DateFormatter
  ) {
  }

  public async handle(command: OpenActivityAnnexDeletionDialogCommand): Promise<void> {
    const {document, onDeletion} = command;


    const facility = await document.facility();

    const data: ConfirmDeletionDialogData = {
      options: [{key: document.id, label: `${facility.name} ${this.dateFormatter.format(document.date, 'dd/MM/yyyy')}`}]
    }

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<ActivityAnnex>(new DeleteActivityAnnexCommand(document));

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    }

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, {...DialogConfiguration, data});
    dialog.closed.subscribe(subscriber);
  }

}
