import {
  CreateDaycareDependencyFundingDto,
  DaycareDependencyFundingDtoFactory,
  UpdateDaycareDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {
  CreateDaycareDependencyFundingDtoImpl,
  UpdateDaycareDependencyFundingDtoImpl
} from "@implementations/bundles/dependency-funding/dto";
import {DAYCARE_DEPENDENCIES_FUNDING_MODES, DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding";

export class DaycareDependencyFundingDtoFactoryImpl implements DaycareDependencyFundingDtoFactory {

  public buildCreateDtoFromUnsafeValues<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = unknown>(values: Partial<CreateDaycareDependencyFundingDto<M, V>>): CreateDaycareDependencyFundingDto<M, V> {
    return new CreateDaycareDependencyFundingDtoImpl<M, V>(values);
  }

  public buildUpdateDtoFromUnsafeValues<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = unknown>(values: Partial<UpdateDaycareDependencyFundingDto<M, V>>): UpdateDaycareDependencyFundingDto<M, V> {
    return new UpdateDaycareDependencyFundingDtoImpl<M, V>(values);
  }


}
