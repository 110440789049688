import { CreateFacilityDto } from '@application/bundles/facility/dto';
import { AsyncTransformer } from '@application/framework/core';
import { EasyFacilityCreateDto } from '@implementations/bundles/backend/easyhpad/interfaces';
import { AbstractEasyFacilityTransformer } from '@implementations/bundles/backend/easyhpad/transformers/facility/abstract-facility.transformer';
import { EasyAddress } from '@implementations/bundles/backend/easyhpad/interfaces/easy-address.interface';

export class EasyFacilityCreateDtoTransformer
  extends AbstractEasyFacilityTransformer
  implements AsyncTransformer<CreateFacilityDto, EasyFacilityCreateDto>
{
  public async transform(value: CreateFacilityDto): Promise<EasyFacilityCreateDto> {
    const defaults = await this.toEasyFacility(value);

    const address: Omit<EasyAddress, 'id'> & Pick<Partial<EasyAddress>, 'id'> = this.addressTransformer.transform(
      value.address,
    );

    if ('id' in address) {
      delete address.id;
    }

    return {
      ...defaults,
      state: value.state,
      address: address,
    };
  }

  public async reverseTransform(value: EasyFacilityCreateDto): Promise<CreateFacilityDto> {
    const defaults = await this.fromEasyFacility(value);
    return {
      ...defaults,
      state: value.state,
      address: this.addressTransformer.reverseTransformCreateAddress(value.address),
    };
  }
}
