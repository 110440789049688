import {Dialog} from "@angular/cdk/dialog";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {InspectionReport} from "@domain/capacity-authorization";
import {
  OpenAddInspectionReportDialogCommand
} from "@easyhpad-ui/app/bundles/capacity-authorization/commands/open-add-inspection-report-dialog.command";
import {
  OpenEditInspectionReportDialogCommand
} from "@easyhpad-ui/app/bundles/capacity-authorization/commands/open-edit-inspection-report-dialog.command";

import {
  InspectionReportEditDialogComponent
} from "@easyhpad-ui/app/bundles/capacity-authorization/modules/form/components/dialog-inspection-report-edit/inspection-report-edit-dialog.component";
import {DialogConfiguration} from "@easyhpad-ui/app/library/dialog";

@HandleCommand({
  command: [
    OpenAddInspectionReportDialogCommand,
    OpenEditInspectionReportDialogCommand
  ]
})
export class OpenEditInspectionReportDialogCommandHandler implements CommandHandler<OpenEditInspectionReportDialogCommand, void> {

  constructor(private readonly dialog: Dialog) {
  }

  public async handle(command: OpenEditInspectionReportDialogCommand | OpenAddInspectionReportDialogCommand): Promise<void> {

    const reference = this.dialog.open<InspectionReport>(InspectionReportEditDialogComponent, {
      ...DialogConfiguration,
      autoFocus: 'dialog',
      data: command.options,
    });

    if (command.onClose !== undefined) {
      reference.closed.subscribe(command.onClose);
    }


  }
}
