import {ListCustomersQuery} from "@application/bundles/customer/queries/list-customers.query";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {Customer} from "@domain/customer";
import {CustomerRepository} from "@application/bundles/customer";

@HandleQuery({
  query: ListCustomersQuery
})
export class ListCustomersQueryHandler implements QueryHandler<ListCustomersQuery, Customer[]> {

  constructor(private readonly repository: CustomerRepository) {
  }

  public handle(query: ListCustomersQuery): Promise<Customer[]> {
    return this.repository.list(query.params);
  }
}
