import {
  DynamicFormElementType,
  DynamicFormNode,
  DynamicPanelsGroup,
  DynamicStepperGroup,
} from '@easyhpad-ui/app/library/form/contracts';

export function isDynamicFormNode(node: any): node is DynamicFormNode<any> {
  return (
    typeof node === 'object' && node !== null && typeof (node as DynamicFormNode<any>).formElementType === 'string'
  );
}

export function isDynamicStepperGroup(node: any): node is DynamicStepperGroup {
  return isDynamicFormNode(node) && (node as DynamicFormElementType<any>).formElementType === 'stepper';
}

export function isDynamicPanelsGroup(node: any): node is DynamicPanelsGroup {
  return isDynamicFormNode(node) && (node as DynamicFormElementType<any>).formElementType === 'panels';
}
