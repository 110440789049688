import { Transformer } from '@application/framework/core';

import { NonRecurringHealthFunding } from '@domain/health-funding';
import { Common } from '@application/framework/lib';
import {
  CreateNonRecurringHealthFundingDto,
  NonRecurringHealthFundingDtoFactory,
  UpdateNonRecurringHealthFundingDto,
} from '@application/bundles/health-funding';
import { Service } from '@application/framework/di';
import {
  CreateEasyNonRecurringHealthFundingDto,
  EasyNonRecurringHealthFunding,
  UpdateEasyNonRecurringHealthFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/health-funding/easy-non-recurring-funding';

type CommonProperties = Common<
  NonRecurringHealthFunding,
  Common<CreateNonRecurringHealthFundingDto, UpdateNonRecurringHealthFundingDto>
>;

type EasyCommonProperties = Common<
  EasyNonRecurringHealthFunding,
  Common<CreateEasyNonRecurringHealthFundingDto, UpdateEasyNonRecurringHealthFundingDto>
>;

@Service()
export class NonRecurringHealthFundingTransformer
  implements Transformer<EasyNonRecurringHealthFunding, NonRecurringHealthFunding>
{
  constructor(private readonly dtoFactory: NonRecurringHealthFundingDtoFactory) {}

  public transform(value: EasyNonRecurringHealthFunding): NonRecurringHealthFunding {
    return {
      ...this.transformCommonProperties(value),
      id: value.id,
    };
  }

  public transformCreateDto(value: CreateEasyNonRecurringHealthFundingDto): CreateNonRecurringHealthFundingDto {
    return this.dtoFactory.buildCreateDtoFromUnsafeValues({
      ...this.transformCommonProperties(value),
    });
  }

  public transformUpdateDto(value: UpdateEasyNonRecurringHealthFundingDto): UpdateNonRecurringHealthFundingDto {
    return this.dtoFactory.buildUpdateDtoFromUnsafeValues({
      ...this.transformCommonProperties(value),
      id: value.id,
    });
  }

  public reverseTransform(value: NonRecurringHealthFunding): EasyNonRecurringHealthFunding {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
    };
  }

  public reverseTransformCreateDto(value: CreateNonRecurringHealthFundingDto): CreateEasyNonRecurringHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value),
    };
  }

  public reverseTransformUpdateDto(value: UpdateNonRecurringHealthFundingDto): UpdateEasyNonRecurringHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
    };
  }

  private transformCommonProperties(value: EasyCommonProperties): CommonProperties {
    return {
      amount: value.amount,
      comment: value.comment || undefined,
      categoryId: value.categoryId ?? undefined,
    };
  }

  private reverseTransformCommonProperties(value: CommonProperties): EasyCommonProperties {
    return {
      amount: value.amount,
      categoryId: value.categoryId,
      comment: value.comment,
    };
  }
}
