import { FundingAmountRepository } from '@application/bundles/funding/repositories/funding-amount.repository';
import { FundingAmountsByYear } from '@application/bundles/funding';
import {
  EasyRepositoryAbstract,
  EasyRepositoryTransformer,
} from '@implementations/bundles/backend/easyhpad/repositories/base';
import { Facility } from '@domain/facility';
import { RequestParams } from '@application/framework/http';
import { EasySearchQueryParams } from '@implementations/bundles/backend/easyhpad/interfaces/easy-search-options';

export abstract class AbstractEasyFundingAmountRepository<Entity, EasyEntity>
  extends EasyRepositoryAbstract<Entity, EasyEntity>
  implements FundingAmountRepository<Entity>
{
  protected constructor(basePath: string, transformer: EasyRepositoryTransformer<Entity, EasyEntity>) {
    super(basePath, transformer);
  }

  /**
   * @inheritDoc
   * @param facilityIds
   */
  public async findLatestOne(facilityIds?: Array<Facility['id']>): Promise<FundingAmountsByYear<Entity>> {
    const response = await this.backend.get<{ entity: FundingAmountsByYear<EasyEntity> }>(
      `${this.basePath}/latest`,
      this.convertParams({ facilityIds }),
    );

    const stats = this.parseResponse(response).entity;
    const documents = await this.transformEasyEntities(stats.documents);

    return { ...stats, documents };
  }

  protected override convertParams(params?: any): RequestParams {
    const p: EasySearchQueryParams<any> = {};

    if (params.facilityIds) {
      p.facility_ids = params.facilityIds;
    }
    return this.searchTransformer.toRequestParams(p);
  }

  private async transformEasyEntities(entities: EasyEntity[]): Promise<Entity[]> {
    if (!this.transformer || !this.transformer.to) {
      throw new Error(`Transformer is missing in ${this.constructor.name}`);
    }

    return Promise.all(entities.map(entity => this.transformEasyEntity(entity)));
  }

  private async transformEasyEntity(entity: EasyEntity): Promise<Entity> {
    if (!this.transformer || !this.transformer.to) {
      throw new Error(`Transformer is missing in ${this.constructor.name}`);
    }

    return this.transformer.to(entity);
  }
}
