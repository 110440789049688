import { Facility } from '@domain/facility';
import {
  DocumentListParams,
  PaginationParams,
  SerializedDocumentParams,
} from '@application/framework/search/list-params';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { Sort } from '@application/framework/search/sort';
import { isSort } from '@application/framework/search';

export class DocumentListParamsImpl<S extends string = string> implements DocumentListParams<S> {
  private facilities: Array<Facility['id']> = [];

  private pagination: PaginationParams | undefined;

  private sort: Sort<S> | undefined;

  private dates: { start: Date; end?: Date } | undefined;

  public setFacilities(...facilities: Array<Facility['id'] | Facility>): this {
    const ids: Array<Facility['id']> = [];

    facilities.forEach((facility) => {
      if (isValidFacilityIdType(facility)) {
        ids.push(facility);
      } else if (isValidFacilityIdType(facility.id)) {
        ids.push(facility.id);
      }
    });

    this.facilities = ids;
    return this;
  }

  public setPagination(pagination: PaginationParams | null): this {
    if (pagination === null) {
      this.pagination = undefined;
    } else if (Number.isInteger(pagination.limit)) {
      this.pagination = pagination;
    }
    return this;
  }

  public setSort(option: Sort<S> | null | undefined): this {
    this.sort = isSort(option) ? option : undefined;
    return this;
  }

  public setDates(start: Date | null | undefined, end?: Date | null | undefined): this {
    if (!(start instanceof Date)) {
      this.dates = undefined;
    } else {
      this.dates = { start, end: end instanceof Date ? end : undefined };
    }

    return this;
  }

  public serialize(): SerializedDocumentParams {
    const params: SerializedDocumentParams = {};

    if (Array.isArray(this.facilities) && this.facilities.length > 0) {
      params['facilities'] = this.facilities;
    }

    if (this.pagination) {
      params.pagination = this.pagination;
    }

    if (this.dates && (this.dates.start as any) instanceof Date) {
      params.dates = this.dates;
    }

    if (this.sort) {
      params.sort = { ...this.sort };
    }

    return params;
  }

  public deserialize<P = any>(params?: SerializedDocumentParams): this {
    if (typeof params === 'object' && params !== null) {
      if (Array.isArray(params.facilities) && params.facilities.length > 0) {
        this.facilities = params.facilities.filter(isValidFacilityIdType);
      }

      if (params.pagination && Number.isInteger(params.pagination)) {
        this.pagination = params.pagination;
      }

      if (params.dates) {
        this.dates = { ...params.dates };
      }
    }

    return this;
  }
}
