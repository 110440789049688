import {Year} from "@domain/lib";

export function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}


export function isValidYear(value: any): value is number {
  return typeof value === 'number' && !isNaN(value) && value >= 1 && value <= 9999;
}

/**
 * Get 1er january and 31st december of year
 * @param year
 */
export function getDatesIntervalForYear(year: Year): { start: Date; end: Date } {
  const start = new Date(year, 0, 1);
  const end = new Date(year, 11, 31, 23, 59, 59, 999);

  return {start, end};
}
