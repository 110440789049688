<ng-container [formGroup]="form">

  <ehp-form-errors *ngIf="form.errors" [errors]="form.errors"></ehp-form-errors>

  <ehp-form-field>
    <label for="password">{{'Mot de passe' | ehp_translate}}</label>
    <ehp-password-input (visibility)="visibilityChange($event)" [hidden]="hidden"
                        ehp-form-control formControlName="password" id="password"></ehp-password-input>
  </ehp-form-field>

  <ehp-form-field>
    <label for="confirm-password">{{'Confirmer le mot de passe' | ehp_translate}}</label>
    <input [type]="hidden ? 'password' : 'text'" autocomplete="off" ehp-form-control formControlName="confirm"
           id="confirm-password">
  </ehp-form-field>


  <ul class="requirements">
    <span [attr.data-strength]="strength" class="progress"></span>
    <li *ngFor="let requirement of requirements" [ngClass]="{'validate':requirement.validate}">
      <ehp-translatable-string [value]="requirement.label"></ehp-translatable-string>
    </li>

  </ul>
</ng-container>
