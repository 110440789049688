import {EPRDRepository} from "@application/bundles/eprd-errd";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {EPRD} from "@domain/eprd-errd";
import {ListEPRDQuery} from "../../list-eprd.query";
import {AgnosticEPRDSearchParams} from "@application/bundles/eprd-errd/implementations";

@HandleQuery({
  query: ListEPRDQuery
})
export class ListEPRDQueryHandler implements QueryHandler<ListEPRDQuery, EPRD[]> {

  constructor(private readonly repository: EPRDRepository) {
  }

  public async handle(query: ListEPRDQuery): Promise<EPRD[]> {
    return this.repository.list(new AgnosticEPRDSearchParams().deserialize(query.params));
  }
}
