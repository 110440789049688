<ehp-page-header class="document-view-header document-list-view-page-header">

  <div class="page-title-container" pageTitle>
    <h1 *ngIf="title$ | async as trails" class="title page-title">
      <ehp-translatable-string
        *ngFor="let trail of trails"
        [value]="trail"
        class="trail">
      </ehp-translatable-string>
    </h1>
  </div>


  <div actions class="actions-list actions">
    <ng-container *ngIf="actions$ | async as actionList">
      <ehp-document-action *ngIf="actionList.create" [action]="actionList.create"></ehp-document-action>
    </ng-container>
  </div>


</ehp-page-header>

<ehp-page-content>

  <ehp-table-container class="document-view-page-content document-list-view-page-content">

    <div class="table-header document-list-header document-list-view-page-quick-filters">
      <ng-container #quickFilters></ng-container>
    </div>

    <div class="list document-list-view-page-quick-items">
      <ng-container #documents></ng-container>
    </div>
  </ehp-table-container>

</ehp-page-content>

