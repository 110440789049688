export interface FinancialAnnexData {

  permanentRevenue: number;

  healthRevenue: number;

  dependencyRevenue: number;

  permanentExpense: number;

  healthExpense: number;

  dependencyExpense: number;

  accommodationEmployeesExpense?: number | undefined;

  healthEmployeesExpense?: number | undefined;

  dependencyEmployeesExpense?: number | undefined;

}


export interface FinancialAnnex extends FinancialAnnexData {

  permanentSubTotal(): number;

  dependencySubTotal(): number;

  healthSubTotal(): number;

  revenue(): number;

  expense(): number;
}
