import { Component, HostBinding, Input } from '@angular/core';
import { FILE_DEPOT_STATES } from '@domain/file-deposit';
import { TranslatableString } from '@application/framework/translation';

@Component({
  selector: 'ehp-file-deposit-state',
  templateUrl: './file-deposit-state.component.html',
  styleUrls: ['./file-deposit-state.component.scss'],
})
export class FileDepositStateComponent {
  @Input() public state!: FILE_DEPOT_STATES;

  private readonly labels: Map<FILE_DEPOT_STATES, TranslatableString> = new Map([
    [FILE_DEPOT_STATES.DRAFT, new TranslatableString('Brouillon')],
    [FILE_DEPOT_STATES.WAITING_CUSTOMER, new TranslatableString('En cours')],
    [FILE_DEPOT_STATES.REVIEW, new TranslatableString('En traitement')],
    [FILE_DEPOT_STATES.CLOSE, new TranslatableString('Fermé')],
    [FILE_DEPOT_STATES.ARCHIVED, new TranslatableString('Archivé')],
  ]);

  @HostBinding('class')
  public get classes(): string {
    let classes = 'file-deposit-state';

    if (this.state) {
      classes += ` state-` + this.state;
    }

    return classes;
  }

  public get label(): TranslatableString | string {
    return this.state && this.labels.get(this.state) ? (this.labels.get(this.state) as TranslatableString) : this.state;
  }
}
