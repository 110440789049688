<div *ngIf="data && data.total" class="line">
  <ng-container *ngFor="let part of data.values">

    <ng-container *ngIf="displayTooltip && part.label; else noTooltip">
      <div *ngIf="part.value" [ngStyle]="{'width.%': part.percentage }" class="part"
           pTooltip="{{part.label}}&nbsp;: {{part.value}}"
           tooltipPosition="top"></div>
    </ng-container>

    <ng-template #noTooltip>
      <div *ngIf="part.value" [ngStyle]="{'width.%': part.percentage }" class="part"></div>
    </ng-template>

  </ng-container>
</div>

<ul *ngIf="displayLabel" class="labels-list">
  <ng-container *ngFor="let part of data.values">
    <li *ngIf="part.value" class="label">
      <ehp-translatable-string [value]="part.label" class="name"></ehp-translatable-string>
      <span class="value">{{part.value}}</span>
    </li>
  </ng-container>
</ul>
