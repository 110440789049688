import {Query} from "@application/framework/command-query";
import {CapacityAuthorization} from "@domain/capacity-authorization";
import {Facility} from "@domain/facility";
import {Year} from "@domain/lib";

export class GetApplicableCapacityAuthorizationQuery implements Query<CapacityAuthorization> {

  constructor(public readonly facility: Facility, public readonly year: Year) {
  }
}
