import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ErrorMessagesDictionary } from '@implementations/forms/error-messages.interface';
import { FORM_ERROR_DICTIONARY } from '../../form.token';

@Component({
  selector: 'ehp-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent implements OnChanges {
  public static errorsMessage: Map<string, string> = new Map();

  @Input('errors') public controlErrors: ValidationErrors | null | undefined;

  public errors: Array<{ key: string; message: string }> = [];

  constructor(@Inject(FORM_ERROR_DICTIONARY) private errorsDictionary: ErrorMessagesDictionary) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['controlErrors']) {
      const change = changes['controlErrors'];
      this.updateErrorList(change.currentValue);
    }
  }

  private updateErrorList(errors?: ValidationErrors | null) {
    if (!errors) {
      this.errors = [];
      return;
    }

    const keys = Object.keys(errors).filter((k) => errors[k]);

    if (keys.length === 0) {
      this.errors = [];
      return;
    }

    const previous = this.errors.map((error) => error.key);
    const remove = previous.filter((x) => !keys.includes(x));
    const add = keys.filter((x) => !previous.includes(x));

    this.errors = this.errors.filter((error) => remove.indexOf(error.key) === -1);

    for (const key of add) {
      if (this.hasErrorMessage(key) && false) {
        this.displayErrorMessage(key, this.getErrorMessage(key), true);
      } else {
        this.errorsDictionary.get(key, { options: errors[key] }).then((message) => {
          this.storeErrorMessage(key, message);
          this.displayErrorMessage(key, message);
        });
      }
    }

    // this.errors = errorMessages;
  }

  private hasErrorMessage(key: string): boolean {
    return FormErrorsComponent.errorsMessage.has(key) && typeof FormErrorsComponent.errorsMessage.get(key) === 'string';
  }

  private getErrorMessage(key: string): string {
    return FormErrorsComponent.errorsMessage.get(key) ?? '';
  }

  private storeErrorMessage(key: string, message?: string): void {
    FormErrorsComponent.errorsMessage.set(key, message ?? '');
  }

  private displayErrorMessage(key: string, message: string, skipVerification = false): void {
    let canBePush = true;

    if (!skipVerification && this.controlErrors) {
      canBePush = Object.keys(this.controlErrors).includes(key);
    }

    if (canBePush) {
      this.errors.push({ key, message });
    }
  }
}
