import {
  CreateDependencyFundingDto,
  DependencyFundingRepository,
  DependencyFundingSearchParams,
  SerializedDependencyFundingSearchParams,
  UpdateDependencyFundingDto,
} from '@application/bundles/dependency-funding';
import { Provide } from '@application/framework/di';
import { EasyDependencyFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers/dependency-funding/easy-dependency-funding.transformer';
import { DocumentRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { DependencyFunding } from '@domain/dependency-funding';
import { EasyDependencyFunding } from '@implementations/bundles/backend/easyhpad';

export class EasyDependencyFundingRepository
  extends DocumentRepository<
    DependencyFundingSearchParams,
    SerializedDependencyFundingSearchParams,
    DependencyFunding,
    EasyDependencyFunding
  >
  implements DependencyFundingRepository
{
  @Provide() private readonly entityTransformer!: EasyDependencyFundingTransformer;

  constructor() {
    super('funding/dependency/entities', {
      from: input => this.entityTransformer.reverseTransform(input),
      to: output => this.entityTransformer.transform(output),
      create: (input: CreateDependencyFundingDto) => this.entityTransformer.reverseTransformCreateDto(input),
      update: (input: UpdateDependencyFundingDto) => this.entityTransformer.reverseTransformUpdateDto(input),
    });
  }
}
