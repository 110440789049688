import { AgnosticPermissionCollection } from '@application/bundles/authorization/implementations/agnostic-permission-collection/agnostic-permission-collection';
import { AgnosticRoleCollection } from '@application/bundles/authorization/implementations/agnostic-role-collection/agnostic-role-collection';
import { AuthentificationToken } from '@application/bundles/authentification';

export class AnonymousToken implements AuthentificationToken {
  public readonly isAuthenticated = false;

  public readonly customerReference: undefined;

  public readonly roles = new AgnosticRoleCollection([]);

  public readonly permissions = new AgnosticPermissionCollection([]);

  public get id(): number {
    return 0;
  }

  public get name(): string {
    return 'Anonyme';
  }

  public get provider(): string {
    return 'authentification';
  }
}
