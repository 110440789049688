import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import {
  DependencyFundingAmountRepository,
  FundingAmountsByYear,
  GetLatestDependencyFundingAmountsQuery,
} from '@application/bundles/funding';
import { DependencyFunding } from '@domain/dependency-funding';

@HandleQuery({
  query: GetLatestDependencyFundingAmountsQuery,
})
export class GetLatestDependencyFundingAmountsQueryHandler
  implements QueryHandler<GetLatestDependencyFundingAmountsQuery>
{
  constructor(private readonly repository: DependencyFundingAmountRepository) {}

  public handle(query: GetLatestDependencyFundingAmountsQuery): Promise<FundingAmountsByYear<DependencyFunding>> {
    return this.repository.findLatestOne(query.facilityIds);
  }
}
