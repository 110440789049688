import { FacilityVoter } from '@application/bundles/facility/authorization';
import { CreateFacilityCommandHandler } from '@application/bundles/facility/commands/handlers/create-facility/create-facility.command.handler';
import { DeleteFacilityCommandHandler } from '@application/bundles/facility/commands/handlers/delete-facility/delete-facility.command.handler';
import { UpdateFacilityCommandHandler } from '@application/bundles/facility/commands/handlers/update-facility/update-facility.command.handler';
import { SetAuthorizedFacilitiesEventSubscriber } from '@application/bundles/facility/events/load-user-facilities/set-authorized-facilities-event.subscriber';
import { OnFacilityCreationEventSubscriber } from '@application/bundles/facility/events/subscribers/on-facility-creation/on-facility-creation.subscriber';
import { OnFacilityDeletionSubscriber } from '@application/bundles/facility/events/subscribers/on-facility-deletion/on-facility-deletion.subscriber';
import { OnFacilityUpdateEventSubscriber } from '@application/bundles/facility/events/subscribers/on-facility-update/on-facility-update.subscriber';
import { GetFacilityQueryHandler } from '@application/bundles/facility/query/handlers/get-facility/get-facility.query.handler';
import { ListAuthorizedFacilitiesQueryHandler } from '@application/bundles/facility/query/handlers/list-authorized-facilities/list-authorized-facilities-query.handler';
import { ListCustomerFacilitiesQueryHandler } from '@application/bundles/facility/query/handlers/list-customer-facilities/list-customer-facilities.query.handler';
import { ListFacilitiesQueryHandler } from '@application/bundles/facility/query/handlers/list-facilities/list-facilities.query.handler';
import { ListUserFacilitiesQueryHandler } from '@application/bundles/facility/query/handlers/list-user-facilities/list-user-facilities.query.handler';
import { Bundle } from '@application/framework/bundle';
import { AbstractBundle } from '@application/framework/bundle/bundle.abstract';

@Bundle({
  commandHandlers: [CreateFacilityCommandHandler, UpdateFacilityCommandHandler, DeleteFacilityCommandHandler],
  queryHandlers: [
    GetFacilityQueryHandler,
    ListFacilitiesQueryHandler,
    ListAuthorizedFacilitiesQueryHandler,
    ListCustomerFacilitiesQueryHandler,
    ListUserFacilitiesQueryHandler,
  ],
  eventSubscribers: [
    OnFacilityCreationEventSubscriber,
    OnFacilityUpdateEventSubscriber,
    OnFacilityDeletionSubscriber,
    SetAuthorizedFacilitiesEventSubscriber,
  ],
  voters: [new FacilityVoter()],
})
export class FacilityBundle extends AbstractBundle {}
