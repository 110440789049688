import { CreateCPOMDto, StoredCPOM, UpdateCPOMDto } from '@application/bundles/cpom';
import { DateParser } from '@application/framework/date/date.parser';
import { Service } from '@application/framework/di';
import { Common } from '@application/framework/lib';
import { Transformer } from '@application/framework/core';
import { CPOM } from '@domain/cpom';
import { EasyCPOM, EasyCreateCPOM, EasyUpdateCPOM } from '@implementations/bundles/backend/easyhpad';
import { CPOMImpl } from '@implementations/bundles/cpom';
import { EasyCPOMDetailsTransformer } from './easy-cpom-details.transformer';
import { DateFormatter } from '@application/framework/date';
import { CreateCPOMAnnex, UpdateCPOMAnnex } from '@application/bundles/cpom/dto/cpom-annex.dto';
import {
  EasyCreateCPOMAnnex,
  EasyUpdateCPOMAnnex,
} from '@implementations/bundles/backend/easyhpad/interfaces/cpom/easy-cpom-annex';

type CommonProperties = Omit<Common<CPOM, Common<CreateCPOMDto, UpdateCPOMDto>>, 'document' | 'children' | 'annexes'>;
type EasyCommonProperties = Omit<
  Common<EasyCPOM, Common<EasyCreateCPOM, EasyUpdateCPOM>>,
  'documentId' | 'details' | 'annexes'
>;

@Service()
export class EasyCPOMTransformer implements Transformer<EasyCPOM, CPOM> {
  constructor(
    private readonly detailsTransformer: EasyCPOMDetailsTransformer,
    private readonly dateParser: DateParser,
    private readonly dateFormatter: DateFormatter,
  ) {}

  public transform(value: EasyCPOM): StoredCPOM {
    const cpom = new CPOMImpl({
      ...this.transformCommonProperties(value),
      id: value.id,
      documentId: value.documentId,
      children: [],
      annexes: value.annexes,
    });

    cpom.children = value.details
      .map(child => this.detailsTransformer.transform(child))
      .map(child => {
        child.parent = cpom;
        return child;
      });

    return cpom;
  }

  public reverseTransform(value: StoredCPOM): EasyCPOM {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
      documentId: value.documentId,
      details: value.children.map(child => this.detailsTransformer.reverseTransform(child)),
      annexes: value.annexes,
    };
  }

  public reverseTransformCreate(value: CreateCPOMDto): EasyCreateCPOM {
    return {
      ...this.reverseTransformCommonProperties(value),
      documentId: value.documentId || '',
      details: value.children.map(child => this.detailsTransformer.reverseTransformCreate(child)),
      annexes: !Array.isArray(value.annexes) ? [] : value.annexes.map(v => this.transformCreateAnnex(v)),
    };
  }

  public reverseTransformUpdate(value: UpdateCPOMDto): EasyUpdateCPOM {
    return {
      ...this.reverseTransformCommonProperties(value),
      documentId: value.documentId || '',
      details: value.children.map(child => this.detailsTransformer.reverseTransformUpdate(child)),
      annexes: !Array.isArray(value.annexes)
        ? []
        : value.annexes.map(annex => {
            if ('id' in annex) {
              return this.transformUpdateAnnex(annex);
            }
            return this.transformCreateAnnex(annex);
          }),
    };
  }

  private transformCommonProperties(value: EasyCommonProperties): CommonProperties {
    return {
      date: this.dateParser.parse(value.beginAt),
      otherServices: value.otherServices.map(service => ({
        name: service.name,
        category: { key: service.category, label: service.category },
      })),
    };
  }

  private reverseTransformCommonProperties(value: CommonProperties): EasyCommonProperties {
    return {
      beginAt: this.dateFormatter.toUTCIsoString(value.date),
      otherServices: value.otherServices.map(service => ({ name: service.name, category: service.category.key })),
    };
  }

  private transformCreateAnnex(value: CreateCPOMAnnex): EasyCreateCPOMAnnex {
    return {
      name: value.name,
      mediaId: value.mediaId ?? '',
    };
  }

  private transformUpdateAnnex(value: UpdateCPOMAnnex): EasyUpdateCPOMAnnex {
    return {
      id: value.id,
      name: value.name,
      mediaId: value.mediaId ?? '',
    };
  }
}
