import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {
  FacilityCPOMWidgetComponent
} from "@easyhpad-ui/app/bundles/cpom/components/widget-facility-cpom/facility-cpom-widget.component";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {WidgetCPOMComponent} from "@easyhpad-ui/app/bundles/cpom/components/widget-cpom/widget-cpom.component";
import {CPOMUIModule} from "@easyhpad-ui/app/bundles/cpom/modules/ui/cpom-ui.module";
import {ReusableComponentsModule} from "@easyhpad-ui/app/bundles/reusable-components/reusable-components.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslationModule,
    CPOMUIModule,
    ReusableComponentsModule
  ],
  declarations: [
    FacilityCPOMWidgetComponent,
    WidgetCPOMComponent
  ],
  exports: [
    FacilityCPOMWidgetComponent,
    WidgetCPOMComponent
  ]
})
export class CPOMDashboardModule {

}
