import { DocumentRepositoryV2 } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { BalanceSheet } from '@domain/balance-sheet';
import { BalanceSheetRepository } from '@application/bundles/balance-sheet';

export class EasyBalanceSheetRepository
  extends DocumentRepositoryV2<any, any, BalanceSheet>
  implements BalanceSheetRepository
{
  constructor() {
    super('balance-sheet/entities');
  }
}
