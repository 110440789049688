import {TransformationFailedError, Transformer} from "@application/framework/core";
import {SOCIAL_CLEARANCE, SocialClearance} from "@domain/facility/types";
import {SelectOption} from "@implementations/forms/select-option.interface";

export class SocialClearanceSelectOptionTransformer implements Transformer<SocialClearance, SelectOption> {


  public transform(value: SocialClearance): SelectOption {
    if (!value) {
      throw new TransformationFailedError('Missing SocialClearance');
    }

    if (!value.type) {
      throw new TransformationFailedError('Missing property "type" in SocialClearance');
    }

    if (!value.label) {
      throw new TransformationFailedError('Missing property "label" in SocialClearance');
    }

    return {value: value.type, label: value.label}
  }

  public reverseTransform(option: SelectOption): SocialClearance {
    if (!option) {
      throw new TransformationFailedError('Missing option');
    }

    if (!option.value) {
      throw new TransformationFailedError('Missing property "value" in SelectOption');
    }

    if (!option.label) {
      throw new TransformationFailedError('Missing property "label" in SelectOption');
    }

    return {type: option.value as SOCIAL_CLEARANCE, label: option.label};
  }
}
