import {Component, OnDestroy} from '@angular/core';
import {GetCurrentLocalCPOMQuery, StoredLocalCPOM} from "@application/bundles/cpom";
import {QueryBus} from "@application/framework/command-query";
import {Subscription} from "@application/framework/reactive";
import {Facility} from "@domain/facility";
import {CPOMUrlsProvider} from "@easyhpad-ui/app/bundles/cpom";
import {DashboardFacilityAccessor} from "@implementations/bundles/facility/accessor/dashboard-facility-accessor";
import {TariffOptionAmount} from "@domain/cpom";

@Component({
  selector: 'ehp-facility-cpom-widget',
  templateUrl: './facility-cpom-widget.component.html',
  styleUrls: ['./facility-cpom-widget.component.scss']
})
export class FacilityCPOMWidgetComponent implements OnDestroy {

  public facility: Facility | undefined;

  public cpom: StoredLocalCPOM | undefined;

  public amount: TariffOptionAmount | undefined;

  public url: string | undefined;

  public createUrl: string | undefined;

  private subscription: Subscription;

  constructor(
    private readonly accessor: DashboardFacilityAccessor,
    private readonly queryBus: QueryBus,
    private readonly urls: CPOMUrlsProvider,
  ) {
    this.facilityChange(this.accessor.get());
    this.subscription = this.accessor.onChange((facility) => this.facilityChange(facility));
    this.urls.getPublicCreate().then(url => this.createUrl = url);
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }


  private async facilityChange(facility: Facility | undefined) {

    this.facility = facility;

    if (facility !== undefined) {
      this.cpom = await this.queryBus.request<StoredLocalCPOM | undefined>(new GetCurrentLocalCPOMQuery(facility.id));
    }

    this.localCPOMHasChange();

  }

  private localCPOMHasChange() {
    this.url = '';

    if (this.cpom?.parent?.id) {
      this.urls.getPublicShow(this.cpom.parent.id).then(url => this.url);
    }
  }
}
