import { Injectable } from '@angular/core';
import { FacilityAuthorizationChecker } from '@application/bundles/facility/authorization-checker';
import { joinPath } from '@application/framework/lib';
import { Facility } from '@domain/facility';
import { FacilityAccessChecker } from '@easyhpad-ui/app/bundles/facility/services/authorization-checker/facility-access-checker.service';

@Injectable({
  providedIn: 'root',
})
export class FacilityUrls {
  public static readonly base = 'facility';

  public static readonly admin = {
    list: '',
    create: 'create',
    update: ':id',
  };

  public static readonly public = {
    show: ':id',
    update: ':id/edit',
  };

  constructor(
    public readonly authorization: FacilityAuthorizationChecker,
    public readonly access: FacilityAccessChecker,
  ) {}

  public async getAdminList(): Promise<string | null> {
    return this.access.canAccessToAdminList().then(can => (!can ? null : this.getAdminListWithoutAuthorizationCheck()));
  }

  public getAdminListWithoutAuthorizationCheck(): string {
    return '/' + joinPath('admin', FacilityUrls.base, FacilityUrls.admin.list);
  }

  public async getAdminCreate(): Promise<string | null> {
    return this.access.canAccessToAdminCreate().then(can => (!can ? null : this.getUnsafeAdminCreateUrl()));
  }

  public async getAdminUpdateSchema(): Promise<string | null> {
    return '/' + joinPath('admin', FacilityUrls.base, FacilityUrls.admin.update);
  }

  public async getAdminUpdate(id: number): Promise<string | null> {
    return '/' + joinPath('admin', FacilityUrls.base, id.toString());
  }

  public getUnsafeAdminCreateUrl(): string {
    return '/' + joinPath('admin', FacilityUrls.base, FacilityUrls.admin.create);
  }

  public async getPublicShow(id: Facility['id']): Promise<string | null> {
    return this.authorization.canRead().then(can => (!can ? null : this.getPublicShowWithoutAuthorizationCheck(id)));
  }

  public getPublicShowWithoutAuthorizationCheck(id: Facility['id']): string {
    return '/' + joinPath(FacilityUrls.base, id.toString());
  }

  public async getPublicUpdateSchema(): Promise<string | null> {
    return '/' + joinPath(FacilityUrls.base, FacilityUrls.public.update);
  }

  public async getPublicUpdate(facility: Facility): Promise<string | null> {
    return this.access
      .canAccessToPublicEdit(facility)
      .then(can => (!can ? null : this.getPublicUpdateWithoutAuthorizationCheck(facility.id)));
  }

  public getPublicUpdateWithoutAuthorizationCheck(id: Facility['id']): string {
    return '/' + joinPath(FacilityUrls.base, id.toString(), 'edit');
  }
}
