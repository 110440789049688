import { CapacityAuthorization } from '@domain/capacity-authorization/capacity-authorization';
import { Media } from '@domain/media';
import { UUID } from '@domain/lib';

export interface InspectionReport {
  id: UUID;

  name: { toString: () => string };

  date: Date;

  mediaIds: Array<Media['id']>;

  medias(): Promise<Media[]>;

  authorization(): Promise<CapacityAuthorization>;
}
