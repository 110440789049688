import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RequestParams } from '@application/framework/http/request.interface';
import { EprdErrdWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';
import { GetEprdErrdWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-eprd-errd-widget.query';

@HandleQuery({
  query: GetEprdErrdWidgetQuery,
})
export class GetEprdErrdWidgetQueryHandler implements QueryHandler<GetEprdErrdWidgetQuery, EprdErrdWidgetContent> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: GetEprdErrdWidgetQuery): Promise<EprdErrdWidgetContent> {
    const params: RequestParams = {};

    if (Array.isArray(query.facilities) && query.facilities.length > 0) {
      params['facility_ids'] = query.facilities.join(', ');
    }

    const response = await this.backend.get<EprdErrdWidgetContent>('/widgets/eprd-errd', params);
    return response.body;
  }
}
