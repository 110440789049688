<ng-container *ngIf="options; else wait">

  <select (blur)="onTouched()" (input)="onTouched()" (ngModelChange)="dispatchChange($event)" [(ngModel)]="selected"
          [compareWith]="compareFn" [disabled]="disabled" [multiple]="multiple">

    <option *ngIf="options.length !== 0 && (!required || emptyOption)" [ngValue]="null">
      {{ '- Sélectionner une option -' | ehp_translate }}
    </option>

    <option *ngIf="options.length === 0" [ngValue]="null">
      {{ '- Aucune option disponible -'|ehp_translate }}
    </option>

    <option *ngFor="let opt of options" [ngValue]="opt" [selected]="compareFn(opt, selected)">
      <ehp-translatable-string [value]="opt.label"></ehp-translatable-string>
    </option>
  </select>

</ng-container>

<ng-template #wait>

  <div class="form-element loading-element">
    <span class="loader"></span>
    {{ 'Chargement des données' | ehp_translate }}
  </div>

</ng-template>
