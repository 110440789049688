import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { DisconnectSuccessEvent } from '@application/bundles/authentification/events/disconnect-success.event';
import { Store } from '@ngrx/store';
import { DisconnectFailEvent } from '@application/bundles/authentification/events/disconnect-fail.event';
import { FacilityActions } from '@easyhpad-ui/app/bundles/facility/store';
import { FacilityCreatedEvent } from '@application/bundles/facility/events/facility-created.event';
import { FacilityUpdatedEvent } from '@application/bundles/facility/events/facility-update/facility-updated.event';
import { FacilityDeletedEvent } from '@application/bundles/facility/events/delete-facility/facility-deleted.event';
import { AppState } from '@easyhpad-ui/app/store';

@HasEventSubscribers()
export class FacilityStoreUpdateSubscriber {
  constructor(private readonly store: Store<AppState>) {}

  @OnEvent({ event: [DisconnectSuccessEvent, DisconnectFailEvent] })
  private resetFacilityStore(): void {
    this.store.dispatch(FacilityActions.resetFacilityStore());
  }

  @OnEvent({ event: [FacilityCreatedEvent, FacilityUpdatedEvent, FacilityDeletedEvent] })
  private reloadAvailableFacilities(): void {
    this.store.dispatch(FacilityActions.loadAccountFacilities());
  }
}
