import {
  CreateEhpadDemainHealthFundingDto,
  EhpadDemainHealthFundingDtoFactory,
  UpdateEhpadDemainHealthFundingDto
} from "@application/bundles/health-funding";
import {
  CreateEhpadDemainHealthFundingDtoImpl,
  UpdateEhpadDemainHealthFundingDtoImpl
} from "@implementations/bundles/health-funding/dto";

export class EhpadDemainHealthFundingDtoFactoryImpl implements EhpadDemainHealthFundingDtoFactory {
  
  public buildCreateDtoFromUnsafeValues(values: Partial<CreateEhpadDemainHealthFundingDto>): CreateEhpadDemainHealthFundingDto {
    return new CreateEhpadDemainHealthFundingDtoImpl(values);
  }

  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdateEhpadDemainHealthFundingDto>): UpdateEhpadDemainHealthFundingDto {
    return new UpdateEhpadDemainHealthFundingDtoImpl(values);
  }

}
