import {Notice} from "@application/framework/notice/notice.interface";
import {TranslatableNotice} from "@application/framework/notice/notices/translatable.notice";
import {TranslatableString, Translator} from "@application/framework/translation";
import {NoticeStream} from "./notice-stream";


export abstract class NoticeStreamLibrary {


  public constructor(public readonly defaultStream: NoticeStream, private readonly translator: Translator) {

  }

  public abstract getStream(streamName?: string | Symbol): NoticeStream;

  public getTranslatableNotice(message: string | TranslatableString, type: Notice['type'], icon?: Notice['icon']): TranslatableNotice {
    return new TranslatableNotice(this.translator, message, type, icon);

  }

}
