import { Component, Input, ViewEncapsulation } from '@angular/core';
import { GMP } from '@domain/gmp';
import { Skeleton } from '@easyhpad-ui/app/bundles/reusable-components/components/skeleton';
import { WritableDocumentList } from '@easyhpad-ui/app/library/document';

@Component({
  selector: 'ehp-gmp-list',
  templateUrl: './gmp-list.component.html',
  styleUrls: ['./gmp-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'document-list',
  },
})
export class GmpListComponent {
  @Input('documents') public list!: WritableDocumentList<GMP>;

  public SKELETON_TYPE = Skeleton.DOCUMENT_LIST;
}
