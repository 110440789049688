import {
  CreateHealthFundingDto,
  HealthFundingSearchParams,
  UpdateHealthFundingDto
} from "@application/bundles/health-funding";
import {HealthFunding} from "@domain/health-funding";

export abstract class HealthFundingRepository {

  public abstract list(params?: HealthFundingSearchParams): Promise<HealthFunding[]>;

  public abstract get(id: HealthFunding['id']): Promise<HealthFunding>;

  public abstract create(healthFunding: CreateHealthFundingDto): Promise<HealthFunding>;

  public abstract update(id: HealthFunding['id'], update: Partial<UpdateHealthFundingDto>): Promise<HealthFunding>;

  public abstract delete(id: HealthFunding['id']): Promise<HealthFunding>;
}
