import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { SendWelcomeEmailCommand, SendWelcomeEmailCommandResult } from '@application/bundles/user';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { User } from '@domain/user';

@HandleCommand({
  command: SendWelcomeEmailCommand,
})
export class SendWelcomeEmailCommandHandler implements CommandHandler<SendWelcomeEmailCommand> {
  private readonly action = 'send_welcome_email';

  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(command: SendWelcomeEmailCommand): Promise<SendWelcomeEmailCommandResult> {
    return this.backend
      .post<{ success: boolean }>(this.getPath(command.id), { action: this.action })
      .then(response => response.body.success);
  }

  private getPath(id: User['id']): string {
    return `user/${id}/actions`;
  }
}
