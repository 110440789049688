export interface CreateDemo {
  /**
   * The customer name
   */
  name: string;

  /**
   * An array of facility names
   */
  facilities: Array<string>;
}
