import {Subscription} from "@application/framework/reactive";

export interface Accessor<T> {

  get(): T;

  set(item: T): void;
}

export interface AsyncAccessor<T> {

  get(): Promise<T>;

  set(item: T): Promise<void>;
}

export interface ChangeableAccessor<T> extends Accessor<T> {

  onChange(callable: (value: T) => void): Subscription;

}

export interface AsyncChangeableAccessor<T> extends Accessor<T> {
  onChange(callable: (value: T) => void): Subscription;
}
