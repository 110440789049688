import {
  GetUserPermissionsQuery,
  GetUserPermissionsQueryResult,
} from '@application/bundles/user/queries/get-user-permissions.query';
import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { PermissionCollection } from '@domain/authorization';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { EasyPermissionsCollection } from '@implementations/bundles/backend/easyhpad/authorization/permission-collection/easy-permissions-collection';
import { EasyPermissionsItemInterface } from '@implementations/bundles/backend/easyhpad/interfaces/easy-permissions-item.interface';
import { EasyPermissionItemsTransformer } from '@implementations/bundles/backend/easyhpad/transformers/permissions/easy-permission-items.transformer';
import { User } from '@domain/user';

@HandleQuery({
  query: GetUserPermissionsQuery,
})
export class GetUserPermissionsQueryHandler
  implements QueryHandler<GetUserPermissionsQuery, GetUserPermissionsQueryResult>
{
  private readonly transformer: EasyPermissionItemsTransformer = new EasyPermissionItemsTransformer();

  private cache: Map<User['id'], PermissionCollection> = new Map();

  private timers: Map<User['id'], any> = new Map();

  constructor(private readonly backend: EasyBackendApi) {}

  public handle(query: GetUserPermissionsQuery): Promise<GetUserPermissionsQueryResult> {
    const cached = this.cache.get(query.id);

    if (cached) {
      return Promise.resolve(cached);
    }

    return this.backend
      .get<{ permissions: EasyPermissionsItemInterface[] }>(`user/${query.id}/permissions`)
      .then(response => this.transformer.reverseTransform(response.body.permissions))
      .then(permissions => new EasyPermissionsCollection(permissions))
      .then(collection => this.cacheCollection(query.id, collection));
  }

  private cacheCollection(id: User['id'], collection: EasyPermissionsCollection) {
    let timer = this.timers.get(id);

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      clearTimeout(timer);
      this.cache.delete(id);
      this.timers.delete(id);
    }, 5000);

    this.cache.set(id, collection);
    this.timers.set(id, timer);

    return collection;
  }
}
