import {Constructable} from "@application/framework/lib";

export interface Sanitizable<T> extends Constructable<T> {
}

export type SanitizeFunction<Unsafe = Sanitizable<any>, Safe = any> = (element: Unsafe) => Promise<Safe>;

export abstract class SanitizerRegistry {

  /**
   * Register new sanitize for an object
   * @param objectType
   * @param sanitize
   */
  public abstract register<E, R = E>(objectType: Sanitizable<E>, sanitize: SanitizeFunction<E, R>): void;

  /**
   * Get sanitizers set
   * @param object
   */
  public abstract get<E, R = E>(object: E): Set<SanitizeFunction<E, R>>;
}


export abstract class SanitizerLibrary {

  /**
   * Run sanitization
   * @param object
   */
  public abstract sanitize<T, R = T>(object: T): Promise<R>;

}
