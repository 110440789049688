import {Command} from "@application/framework/command-query";
import {AccommodationFunding} from "@domain/accommodation-funding";

export class OpenAccommodationFundingDeletionDialogCommand implements Command<void> {

  constructor(
    public readonly document: AccommodationFunding,
    public readonly onDeletion?: (document: AccommodationFunding) => void
  ) {
  }
}
