import {
  CreateRecurringHealthFundingDto,
  RecurringHealthFundingDtoFactory,
  UpdateRecurringHealthFundingDto
} from "@application/bundles/health-funding";
import {
  CreateRecurringHealthFundingDtoImpl,
  UpdateRecurringHealthFundingDtoImpl
} from "@implementations/bundles/health-funding/dto";

export class RecurringHealthFundingDtoFactoryImpl implements RecurringHealthFundingDtoFactory {


  public buildCreateDtoFromUnsafeValues(values: Partial<CreateRecurringHealthFundingDto>): CreateRecurringHealthFundingDto {
    return new CreateRecurringHealthFundingDtoImpl(values);
  }


  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdateRecurringHealthFundingDto>): UpdateRecurringHealthFundingDto {
    return new UpdateRecurringHealthFundingDtoImpl(values);
  }

}
