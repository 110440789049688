import { Transformer } from '@application/framework/core';
import { Address } from '@domain/address';
import { EasyAddress } from '@implementations/bundles/backend/easyhpad/interfaces/easy-address.interface';

export class EasyAddressTransformer implements Transformer<Address, EasyAddress> {
  public transform(address: Address): EasyAddress {
    return {
      id: address.id ?? 0,
      ...this.transformCreateAddress(address),
    };
  }

  public reverseTransform(easyAddress: EasyAddress): Address {
    return {
      id: easyAddress.id ? easyAddress.id : undefined,
      addressLine1: easyAddress.street,
      postalCode: easyAddress.postalCode,
      locality: easyAddress.locality,
      countryCode: easyAddress.countryCode ? easyAddress.countryCode : undefined,
    };
  }

  public transformCreateAddress(address: Omit<Address, 'id'>): Omit<EasyAddress, 'id'> {
    return {
      street: address.addressLine1,
      postalCode: address.postalCode,
      locality: address.locality,
      countryCode: 'FR',
    };
  }

  public reverseTransformCreateAddress(address: Omit<EasyAddress, 'id'>): Omit<Address, 'id'> {
    return {
      addressLine1: address.street,
      postalCode: address.postalCode,
      locality: address.locality,
      countryCode: 'FR',
    };
  }
}
