import {HasCPOMForYearQuery, CPOMRepository} from "@application/bundles/cpom";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";

@HandleQuery({
  query: HasCPOMForYearQuery
})
export class HasCPOMForYearQueryHandler implements QueryHandler<HasCPOMForYearQuery, boolean> {

  constructor(private readonly repository: CPOMRepository) {
  }

  public async handle(query: HasCPOMForYearQuery): Promise<boolean> {
    throw new Error(`handle method not implemented in ${this.constructor.name}`);
  }
}
