import {
  CreatePermanentDependencyFundingDto,
  UpdatePermanentDependencyFundingDto,
} from "@application/bundles/dependency-funding";

export abstract class PermanentDependencyFundingDtoFactory {

  public abstract buildCreateDtoFromUnsafeValues(values: Partial<CreatePermanentDependencyFundingDto>): CreatePermanentDependencyFundingDto;

  public abstract buildUpdateDtoFromUnsafeValues(values: Partial<UpdatePermanentDependencyFundingDto>): UpdatePermanentDependencyFundingDto;

}
