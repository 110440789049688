import { Dialog } from '@angular/cdk/dialog';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { DeleteGMPCommand } from '@application/bundles/gmp';
import { CommandBus, CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { Facility } from '@domain/facility';
import { GMP } from '@domain/gmp';
import { OpenGMPDeletionDialogCommand } from '@easyhpad-ui/app/bundles/gmp/commands/open-gmp-deletion-dialog.command';
import { ConfirmDeletionDialogComponent } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component';
import { ConfirmDeletionDialogData } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface';
import { DialogConfiguration } from '@easyhpad-ui/app/library/dialog';

@HandleCommand({
  command: OpenGMPDeletionDialogCommand,
})
export class OpenGMPDeletionDialogCommandHandler implements CommandHandler<OpenGMPDeletionDialogCommand, void> {
  constructor(
    private readonly dialog: Dialog,
    private readonly queryBus: QueryBus,
    private readonly commandBus: CommandBus,
  ) {}

  public async handle(command: OpenGMPDeletionDialogCommand): Promise<void> {
    const { gmp, onDeletion } = command;

    const facility: Facility = await this.queryBus.request(new GetFacilityQuery(gmp.facilityId));

    const data: ConfirmDeletionDialogData = {
      options: [{ key: gmp.id, label: `G.M.P. ${gmp.year} - ${facility.name}` }],
    };

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<GMP>(new DeleteGMPCommand(gmp));

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    };

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, { ...DialogConfiguration, data });
    dialog.closed.subscribe(subscriber);
  }
}
