import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CreateERRDCommand,
  CreateERRDDto,
  EPRD_ERRD_TRANSLATE_CONTEXT,
  ERRDAuthorizationChecker,
  ERRDCreatedEvent,
  ERRDCreationFailEvent,
} from '@application/bundles/eprd-errd';
import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers';
import { SanitizationFailError, SanitizerLibrary } from '@application/framework/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ObjectValidator, ValidationError } from '@application/framework/validator';
import { ERRD } from '@domain/eprd-errd';
import { isLocalMedia, LocalMedia, MediaBucket, TemporaryMedia } from '@domain/media';
import { ERRDRepository } from '@application/bundles/eprd-errd/repositories';
import { eprdErrdDocumentListPropertyMap } from '@application/bundles/eprd-errd/eprd-errd-document-list-property-map';
import { EPRDERRDDocuments } from '@domain/eprd-errd/eprd-errd-documents';

@HandleCommand({
  command: CreateERRDCommand,
})
export class CreateERRDCommandHandler implements CommandHandler<CreateERRDCommand, ERRD> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: ERRDRepository,
    private readonly authorization: ERRDAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
  ) {}

  public async handle(command: CreateERRDCommand): Promise<ERRD> {
    const { errd } = command;
    try {
      const created = await this.sanitize(errd)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.storePvAndCreateERRD(dto));

      this.eventDispatcher.dispatch(new ERRDCreatedEvent(created));

      return created;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: CreateERRDDto): Promise<CreateERRDDto> {
    if (!(await this.authorization.canCreate())) {
      this.logger.error('E.R.R.D creation : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: CreateERRDDto): Promise<CreateERRDDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('E.R.R.D creation : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: CreateERRDDto): Promise<CreateERRDDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('E.R.R.D creation : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à créer un nouvel E.R.R.D.",
        undefined,
        EPRD_ERRD_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new ERRDCreationFailEvent(message));

    return error;
  }

  private async storePvAndCreateERRD(dto: CreateERRDDto): Promise<ERRD> {
    const documents: TemporaryMedia[] = [];

    const documentsPropertiesMap: Map<
      keyof EPRDERRDDocuments | 'reportId',
      keyof EPRDERRDDocuments | 'reportDocument'
    > = new Map([...eprdErrdDocumentListPropertyMap.entries(), ['reportId', 'reportDocument']]);

    for (const [idProperty, documentProperty] of documentsPropertiesMap.entries()) {
      if (isLocalMedia(dto[documentProperty])) {
        const media = dto[documentProperty] as LocalMedia;
        const document = await this.mediaBucket.add(media);
        dto[idProperty] = document.id as any;
        documents.push(document);
      }
    }

    try {
      return this.repository.create(dto);
    } catch (e) {
      for (const document of documents) {
        await document.markForDeletion();
      }

      throw e;
    }
  }
}
