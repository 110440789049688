import { SubFunding } from '@domain/funding';
import { UUID } from '@domain/lib';
import { RecurringTaxonomyTerm } from '@domain/health-funding/taxonomies';

export interface RecurringHealthFunding extends SubFunding {
  id: UUID;

  name: string | undefined;

  categoryId?: RecurringTaxonomyTerm['id'];
}
