import { Service } from '@application/framework/di';
import { DependencyFunding } from '@domain/dependency-funding';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Service()
export class DependencyFundingAuthorizationChecker {
  constructor(private readonly authorization: DocumentAuthorization) {}

  public canRead(funding?: DependencyFunding) {
    return this.authorization.canReadDocument(funding);
  }

  public canCreate() {
    return this.authorization.canCreateDocument();
  }

  public canUpdate(funding: DependencyFunding) {
    return this.authorization.canEditDocument(funding);
  }

  public canDelete(funding: DependencyFunding) {
    return this.authorization.canDeleteDocument(funding);
  }
}
