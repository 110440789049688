import {CreatePMPDto, PMPDtoFactory, UpdatePMPDto} from "@application/bundles/pmp";
import {CreatePMPDtoImpl, UpdatePMPDtoImpl} from "@implementations/bundles/pmp";

export class PMPDtoFactoryImpl implements PMPDtoFactory {

  public buildCreatePMPDtoFromUnsafeValues(values: Partial<CreatePMPDto>): CreatePMPDto {
    return new CreatePMPDtoImpl(values);
  }

  public buildUpdatePMPDtoFromUnsafeValues(values: Partial<UpdatePMPDto>): UpdatePMPDto {
    return new UpdatePMPDtoImpl(values);
  }

}
