import { CreateCPOMFundingDto, isStoredCPOMFunding, UpdateCPOMFundingDto } from '@application/bundles/cpom';
import { Service } from '@application/framework/di';
import { Common } from '@application/framework/lib';
import { Transformer } from '@application/framework/core';
import { CPOMFunding } from '@domain/cpom';
import {
  EasyCPOMDetailFunding,
  EasyCreateCPOMDetailFunding,
  EasyUpdateCPOMDetailFunding,
} from '@implementations/bundles/backend/easyhpad';
import { CPOMFundingImpl } from '@implementations/bundles/cpom/implementations/cpom-funding';

type CommonProperties = Common<CPOMFunding, Common<CreateCPOMFundingDto, UpdateCPOMFundingDto>>;

type EasyCommonProperties = Common<
  EasyCPOMDetailFunding,
  Common<EasyCreateCPOMDetailFunding, EasyUpdateCPOMDetailFunding>
>;

@Service()
export class EasyCPOMFundingTransformer implements Transformer<EasyCPOMDetailFunding, CPOMFunding> {
  public transform(value: EasyCPOMDetailFunding): CPOMFunding {
    return new CPOMFundingImpl({
      ...this.transformCommonProperties(value),
      id: value.id,
    });
  }

  public reverseTransform(value: CPOMFunding): EasyCPOMDetailFunding {
    const easy: EasyCommonProperties & { id: EasyCPOMDetailFunding['id'] } = {
      ...this.reverseTransformCommonProperties(value),
      id: '',
    };

    if (isStoredCPOMFunding(value)) {
      easy.id = value.id;
    }

    return easy;
  }

  public transformCreate(value: EasyCreateCPOMDetailFunding): CreateCPOMFundingDto {
    return {
      ...this.transformCommonProperties(value),
    };
  }

  public reverseTransformCreate(value: CreateCPOMFundingDto): EasyCreateCPOMDetailFunding {
    return {
      ...this.reverseTransformCommonProperties(value),
    };
  }

  public transformUpdate(value: EasyUpdateCPOMDetailFunding): UpdateCPOMFundingDto {
    return new CPOMFundingImpl({
      ...this.transformCommonProperties(value),
      id: value.id,
    });
  }

  public reverseTransformUpdate(value: UpdateCPOMFundingDto): EasyUpdateCPOMDetailFunding {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
    };
  }

  private transformCommonProperties(value: EasyCommonProperties): CommonProperties {
    return {
      name: value.name,
      amount: value.amount,
      comment: value.comment ? value.comment : undefined,
    };
  }

  private reverseTransformCommonProperties(value: CommonProperties): EasyCommonProperties {
    return {
      name: value.name,
      amount: value.amount,
      comment: value.comment,
    };
  }
}
