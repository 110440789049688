<ehp-page-header>

  <h1 class="title page-title" pageTitle>

    <span class="trail">
      <ng-container *ngIf="state === 'form'">{{ 'Dépôt initial de vos documents' | ehp_translate }}</ng-container>
        <ehp-plural *ngIf="state !== 'form'" [count]="deposits.length">
          <span single>{{ 'Dépôt initial de vos documents' | ehp_translate }}</span>
          <span plural>{{ 'Dépôts initiaux des établissements' | ehp_translate }}</span>
        </ehp-plural>
      </span>

    <ehp-facility-name
      (click)="setListState()"
      *ngIf="selected"
      [fid]="selected.facilityId"
      class="trail">
    </ehp-facility-name>
  </h1>
</ehp-page-header>

<ehp-page-content>

  <ng-container *ngIf="selected && validationStep; else fileDepositForm">

    <div class="validation-message">
      <p>Vous êtes sur le point de déposer les premiers documents en lien avec votre établissement.
        Ces documents vous permettront après analyse par nos équipes de profiter pleinement de notre application.</p>

      <p>La liste des document vous a été transmis par mail, et dans le cas où ce mail ne vous serait pas parvenu,
        vous retrouverez la liste des documents à l'étape suivante.</p>

      <button (click)="startDeposit(selected)" class="button primary" type="button">
        {{'Commencer le dépôt des documents' | ehp_translate }}
      </button>
    </div>


  </ng-container>

  <ng-template #fileDepositForm>

    <ng-container *ngIf="selected && state === 'form'; else noSingleDeposit">
      <ehp-file-deposit-load-form (onChange)="load()" (onTransition)="removeSelected()"
                                  [deposit]="selected"></ehp-file-deposit-load-form>
    </ng-container>

    <ng-template #noSingleDeposit>

      <div class="document-list file-deposit-list">

        <header class="header document-list-header">
          <div class="title">{{'Nom du dépôt' | ehp_translate}}</div>
          <div class="date">{{'Date d\'ouverture' | ehp_translate}}</div>
          <div class="state">{{'État' | ehp_translate}}</div>
          <div class="actions">{{'Actions' | ehp_translate}}</div>
        </header>

        <div *ngFor="let deposit of deposits" class="document-list-item file-deposit-list-item">

          <div (click)="selectDeposit(deposit)"
               [ngClass]="{activable: deposit.state === FILE_DEPOT_STATES.WAITING_CUSTOMER}"
               class="item-title">
            <span class="icon ehp-icon icon-deposit"></span>
            <ehp-file-deposit-name [deposit]="deposit"></ehp-file-deposit-name>
          </div>

          <div class="date">
            <span>{{deposit.date | date:'d MMMM y'}}</span>
          </div>

          <div class="state-container">
            <ehp-file-deposit-state [state]="deposit.state"></ehp-file-deposit-state>
          </div>

          <div class="actions-list actions">

            <button
              (click)="selectDeposit(deposit)"
              *ngIf="deposit.state === FILE_DEPOT_STATES.WAITING_CUSTOMER"
              class="is-icon icon-arrow ghost button"
              pTooltip="{{'Ajouter des fichiers' | ehp_translate}}"
              tooltipPosition="bottom"
              type="button">

              <span class="label">{{'Ajouter des fichiers au dépôt' | ehp_translate }}</span>

            </button>

          </div>
        </div>

      </div>


    </ng-template>

  </ng-template>


</ehp-page-content>
