import { DateString } from '@application/framework/date';
import { EasyCPOMDetail, EasyCreateCPOMDetail, EasyUpdateCPOMDetail } from '@implementations/bundles/backend/easyhpad';
import { OTHER_SERVICE_CATEGORIES } from '@domain/cpom/services';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { UUID } from '@domain/lib';
import { EasyCPOMAnnex, EasyCreateCPOMAnnex, EasyUpdateCPOMAnnex } from './easy-cpom-annex';

export interface EasyCPOM {
  id: UUID;

  documentId: EasyMedia['id'];

  beginAt: DateString;

  details: EasyCPOMDetail[];

  annexes: Array<EasyCPOMAnnex>;

  otherServices: Array<{ name: string; category: OTHER_SERVICE_CATEGORIES }>;
}

export interface EasyCreateCPOM extends Omit<EasyCPOM, 'id' | 'details' | 'annexes'> {
  details: EasyCreateCPOMDetail[];
  annexes: EasyCreateCPOMAnnex[];
}

export interface EasyUpdateCPOM extends Omit<EasyCPOM, 'id' | 'details' | 'annexes'> {
  details: Array<EasyCreateCPOMDetail | EasyUpdateCPOMDetail>;
  annexes: Array<EasyCreateCPOMAnnex | EasyUpdateCPOMAnnex>;
}
