import {OverlayModule} from "@angular/cdk/overlay";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {TranslationModule} from "../../framework/translation/translation.module";
import {ActionsListComponent} from './components/actions-list/actions-list.component';
import {BlockedIconComponent} from './components/blocked-icon/blocked-icon.component';
import {ConfirmDeletionDialogComponent} from './components/confirm-deletion-dialog/confirm-deletion-dialog.component';
import {DateIntervalComponent} from './components/date-interval/date-interval.component';
import {LogoComponent} from "./components/logo/logo.component";
import {OverviewItemListComponent} from './components/overview-item-list/overview-item-list.component';
import {PageContentComponent} from './components/page-content/page-content.component';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {SkeletonComponent} from './components/skeleton/skeleton.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {EmptyBlockComponent} from './components/empty-block/empty-block.component';
import {PluralComponent} from './components/plural/plural.component';
import {CountableLabelComponent} from './components/countable-label/countable-label.component';
import {EmptyValueComponent} from './components/empty-value/empty-value.component';
import {TypeofPipe} from "@easyhpad-ui/app/bundles/reusable-components/pipes/typeof.pipe";

@NgModule({
  declarations: [
    LogoComponent,
    BlockedIconComponent,
    ConfirmDeletionDialogComponent,
    PageHeaderComponent,
    ActionsListComponent,
    PageContentComponent,
    SkeletonComponent,
    OverviewItemListComponent,
    StepperComponent,
    DateIntervalComponent,
    EmptyBlockComponent,
    PluralComponent,
    CountableLabelComponent,
    EmptyValueComponent,
    TypeofPipe
  ],
  imports: [
    CommonModule,
    TranslationModule,
    OverlayModule
  ],
  exports: [
    LogoComponent,
    BlockedIconComponent,
    PageHeaderComponent,
    ActionsListComponent,
    PageContentComponent,
    StepperComponent,
    DateIntervalComponent,
    SkeletonComponent,
    EmptyBlockComponent,
    PluralComponent,
    CountableLabelComponent,
    EmptyValueComponent,
    TypeofPipe,

  ],
  providers: [
    TypeofPipe
  ]
})
export class ReusableComponentsModule {

}
