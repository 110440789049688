import { Component, Input } from '@angular/core';
import { AccommodationFunding } from '@domain/accommodation-funding';

@Component({
  selector: 'ehp-accommodation-funding-list-item',
  templateUrl: './accommodation-funding-list-item.component.html',
  styleUrls: ['./accommodation-funding-list-item.component.scss'],
  host: {
    class: 'document-list-item',
  },
})
export class AccommodationFundingListItemComponent {
  @Input() public funding!: AccommodationFunding;
}
