import { Transformer } from '@application/framework/core';
import { TPER } from '@domain/eprd-errd';
import { EasyTPER } from '@implementations/bundles/backend/easyhpad';
import { ETPCategoryCollection } from '@application/bundles/etp/etp-category-collection';
import { Provide, Service } from '@application/framework/di';

@Service()
export class EasyTPERTransformer implements Transformer<TPER, EasyTPER> {
  @Provide()
  private readonly tperCollection!: ETPCategoryCollection;

  public transform(value: TPER): EasyTPER {
    return {
      amount: value.amount,
      category: value.category.type,
      etp: value.etp,
      comment: value.comment,
    };
  }

  public reverseTransform(value: EasyTPER): TPER {
    const category = this.tperCollection.get(value.category) || { type: value.category, label: value.category };
    return {
      category,
      amount: value.amount,
      etp: value.etp,
      comment: value.comment,
    };
  }
}
