import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { DraggableDirective } from '@easyhpad-ui/app/library/draggable';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { CalculatorActions } from '@easyhpad-ui/app/bundles/calculator/store';
import { CALCULATOR_TYPE } from '@easyhpad-ui/app/bundles/calculator/types';

@Component({
  selector: 'ehp-calculator',
  templateUrl: './draggable-calculator.component.html',
  styleUrls: ['./draggable-calculator.component.scss'],
  hostDirectives: [
    {
      directive: DraggableDirective,
    },
  ],
  host: {
    '[class.collapsed]': 'collapsed',
  },
})
export class DraggableCalculatorComponent implements AfterViewInit {
  @ViewChild('dragHandler') public handler: ElementRef<HTMLElement> | undefined;

  public type: CALCULATOR_TYPE = CALCULATOR_TYPE.HEALTH_ALLOCATION;

  public readonly CALCULATOR_TYPES = CALCULATOR_TYPE;

  public collapsed = false;

  constructor(
    private readonly draggableControls: DraggableDirective,
    private readonly store: Store<AppState>,
  ) {}

  public ngAfterViewInit(): void {
    if (this.handler && this.handler.nativeElement) {
      this.draggableControls.setDragHandler(this.handler.nativeElement);
    }
  }

  public moveTo(x: 'left' | 'right', y: 'top' | 'bottom') {
    this.draggableControls.moveTo(x, y);
  }

  public close(): void {
    this.store.dispatch(CalculatorActions.close());
  }

  public toggleCollapse() {
    this.collapsed = !this.collapsed;
  }
}
