import { DynamicField } from './dynamic-field';
import { WithMinAndMax, WithStep } from './dynamic-field-attributes';

export interface DynamicInputField<T, V = any> extends DynamicField<V> {
  type: T;

  prefix?: string;

  suffix?: string;
}

export type DynamicTextInputField = DynamicInputField<'text', string>;

export type DynamicNumberInputField = DynamicInputField<'number', number> & WithStep & WithMinAndMax;

export type DynamicDateInputField = DynamicInputField<'date', Date> & WithStep & WithMinAndMax<Date>;
