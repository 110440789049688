import { Transformer } from '@application/framework/core';
import { PermanentDependencyFunding } from '@domain/dependency-funding';
import {
  CreatePermanentDependencyFundingDto,
  UpdatePermanentDependencyFundingDto,
} from '@application/bundles/dependency-funding';
import { Common } from '@application/framework/lib';
import {
  CreateEasyPermanentDependencyFundingDto,
  EasyPermanentDependencyFunding,
  UpdateEasyPermanentDependencyFundingDto,
} from '../../interfaces/dependency-funding/easy-permanent-dependency-funding';
import { Service } from '@application/framework/di';

type CommonProperties = Common<
  PermanentDependencyFunding,
  Common<CreatePermanentDependencyFundingDto, UpdatePermanentDependencyFundingDto>
>;

type EasyCommonProperties = Common<
  EasyPermanentDependencyFunding,
  Common<CreateEasyPermanentDependencyFundingDto, UpdateEasyPermanentDependencyFundingDto>
>;

@Service()
export class EasyPermanentDependencyFundingTransformer
  implements Transformer<EasyPermanentDependencyFunding, PermanentDependencyFunding>
{
  public transform(value: EasyPermanentDependencyFunding): PermanentDependencyFunding {
    return {
      ...this.transformCommon(value),
      id: value.id,
    };
  }

  public transformCreateDto(value: CreateEasyPermanentDependencyFundingDto): CreatePermanentDependencyFundingDto {
    return {
      ...this.transformCommon(value),
    };
  }

  public transformUpdateDto(value: UpdateEasyPermanentDependencyFundingDto): UpdatePermanentDependencyFundingDto {
    return {
      ...this.transformCommon(value),
      id: value.id,
    };
  }

  public reverseTransform(value: PermanentDependencyFunding): EasyPermanentDependencyFunding {
    return {
      ...this.reverseTransformCommon(value),
      id: value.id,
    };
  }

  public reverseTransformCreateDto(
    value: CreatePermanentDependencyFundingDto,
  ): CreateEasyPermanentDependencyFundingDto {
    return {
      ...this.reverseTransformCommon(value),
    };
  }

  public reverseTransformUpdateDto(
    value: UpdatePermanentDependencyFundingDto,
  ): UpdateEasyPermanentDependencyFundingDto {
    return {
      ...this.reverseTransformCommon(value),
      id: value.id,
    };
  }

  private transformCommon(value: EasyCommonProperties): CommonProperties {
    return {
      firstGIRGroup: { amount: value.price_gir1_2 || 0, residents: value.num_gir1_2 || undefined },
      secondGIRGroup: { amount: value.price_gir3_4 || 0, residents: value.num_gir3_4 || undefined },
      thirdGIRGroup: { amount: value.price_gir5_6 || 0, residents: value.num_gir5_6 || undefined },
      pointGIR: value.gir_dep_value || undefined,
      activityAllowance: value.activity_abattement || undefined,
    };
  }

  private reverseTransformCommon(value: CommonProperties): EasyCommonProperties {
    return {
      price_gir1_2: value.firstGIRGroup.amount,
      price_gir3_4: value.secondGIRGroup.amount,
      price_gir5_6: value.thirdGIRGroup.amount,
      num_gir1_2: value.firstGIRGroup.residents,
      num_gir3_4: value.secondGIRGroup.residents,
      num_gir5_6: value.thirdGIRGroup.residents,
      gir_dep_value: value.pointGIR,
      activity_abattement: value.activityAllowance,
    };
  }
}
