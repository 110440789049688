import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  AccommodationFundingCreatedEvent,
  AccommodationFundingCreationFailEvent,
  AccommodationFundingRepository,
  CreateAccommodationFundingCommand,
  CreateAccommodationFundingDto,
} from '@application/bundles/accommodation-funding';
import { AccommodationFundingAuthorizationChecker } from '@application/bundles/accommodation-funding/accommodation-funding-authorization-checker';
import { ACCOMMODATION_FUNDING_TRANSLATE_CONTEXT } from '@application/bundles/accommodation-funding/accommodation-funding.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { MediaBucket, TemporaryMedia } from '@domain/media';
import { AccommodationFunding } from '@domain/accommodation-funding';

@HandleCommand({
  command: CreateAccommodationFundingCommand,
})
export class CreateAccommodationFundingCommandHandler
  implements CommandHandler<CreateAccommodationFundingCommand, AccommodationFunding>
{
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: AccommodationFundingRepository,
    private readonly authorization: AccommodationFundingAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: CreateAccommodationFundingCommand): Promise<AccommodationFunding> {
    const { funding } = command;
    try {
      const created = await this.sanitize(funding)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.storePvAndCreateAccommodationFunding(dto));

      this.eventDispatcher.dispatch(new AccommodationFundingCreatedEvent(created));

      return created;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: CreateAccommodationFundingDto): Promise<CreateAccommodationFundingDto> {
    if (!(await this.authorization.canCreate())) {
      this.logger.error('AccommodationFunding creation : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: CreateAccommodationFundingDto): Promise<CreateAccommodationFundingDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('AccommodationFunding creation : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: CreateAccommodationFundingDto): Promise<CreateAccommodationFundingDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('AccommodationFunding creation : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à créer un nouveau Financement hébergement ..",
        undefined,
        ACCOMMODATION_FUNDING_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new AccommodationFundingCreationFailEvent(message));

    return error;
  }

  private async storePvAndCreateAccommodationFunding(
    dto: CreateAccommodationFundingDto,
  ): Promise<AccommodationFunding> {
    const temporaryDocuments = new Set<TemporaryMedia>();

    try {
      if (dto.pv) {
        const pv = await this.mediaBucket.add(dto.pv);
        dto.pvId = pv.id;
        temporaryDocuments.add(pv);
      }

      if (dto.report) {
        const report = await this.mediaBucket.add(dto.report);
        dto.reportId = report.id;
        temporaryDocuments.add(report);
      }

      return this.repository.create(dto);
    } catch (e) {
      for (const document of temporaryDocuments) {
        await document.markForDeletion();
      }

      throw e;
    }
  }
}
