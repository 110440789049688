export enum AuthorizationTokenType {
  BEARER = 'Bearer'
}

export interface HttpAuthorizationToken<T> {

  /**
   * token type
   */
  type: AuthorizationTokenType;

  /**
   * token value
   */
  value: T;

  /**
   * Who provide this token
   */
  provider: string | Symbol;

  /**
   * Transform token to string
   */
  toString: () => string;

  destroy?: () => void;

}
