import {
  CreateDependencyFundingCommandHandler,
  DeleteDependencyFundingCommandHandler,
  UpdateDependencyFundingCommandHandler
} from "@application/bundles/dependency-funding/commands/handlers";
import {
  OnDependencyFundingCreationEventSubscriber,
  OnDependencyFundingDeletionEventSubscriber,
  OnDependencyFundingUpdateEventSubscriber
} from "@application/bundles/dependency-funding/events/subscribers";
import {
  GetCurrentDependencyFundingQueryHandler,
  GetDependencyFundingQueryHandler,
  HasDependencyFundingForYearQueryHandler,
  ListDependencyFundingQueryHandler,
  ListOtherDependencyFundingQueryHandler
} from "@application/bundles/dependency-funding/queries/handlers";
import {Bundle} from "@application/framework/bundle";
import {Service} from "@application/framework/di";

@Bundle({
  commandHandlers: [
    CreateDependencyFundingCommandHandler,
    UpdateDependencyFundingCommandHandler,
    DeleteDependencyFundingCommandHandler
  ],
  queryHandlers: [
    ListDependencyFundingQueryHandler,
    GetDependencyFundingQueryHandler,
    GetCurrentDependencyFundingQueryHandler,
    HasDependencyFundingForYearQueryHandler,
    ListOtherDependencyFundingQueryHandler
  ],
  eventSubscribers: [
    OnDependencyFundingCreationEventSubscriber,
    OnDependencyFundingUpdateEventSubscriber,
    OnDependencyFundingDeletionEventSubscriber
  ]
})
@Service()
export class DependencyFundingBundle {
}
