import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {StoredLocalCPOM} from "@application/bundles/cpom";
import {QueryBus} from "@application/framework/command-query";
import {ListOtherLocalCpomQuery} from "@application/bundles/cpom/queries/list-other-local-cpom.query";

@Component({
  selector: 'ehp-local-cpom-others-table',
  templateUrl: './local-cpom-others-table.component.html',
  styleUrls: ['./local-cpom-others-table.component.scss']
})
export class LocalCpomOthersTableComponent implements OnChanges {


  @Input() public localCPOM!: StoredLocalCPOM;

  public others: StoredLocalCPOM[] = [];


  constructor(private readonly queryBus: QueryBus) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.others = [];

    if (this.localCPOM) {
      this.queryBus.request<StoredLocalCPOM[]>(new ListOtherLocalCpomQuery(this.localCPOM)).then(documents => {
        this.others = documents;
      });
    }
  }
}
