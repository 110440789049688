import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrentCustomerAccessor } from '@application/bundles/customer/implementation/current-customer-accessor/current-customer.accessor';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { ListAuthorizedFacilitiesQuery } from '@application/bundles/facility/query/list-authorized-facilities.query';
import { QueryBus } from '@application/framework/command-query';
import { Subscription } from '@application/framework/reactive';
import { Facility } from '@domain/facility';
import { FacilitySelectComponent } from '@easyhpad-ui/app/bundles/facility/modules/form/components/facility-select/facility-select.component';
import { UniqueFacilitySelectorSource } from '@easyhpad-ui/app/bundles/facility/modules/form/services/unique-facility-selector.source';
import { NativeChangeObserver } from '@easyhpad-ui/app/framework/native-change-observer';
import { map, Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'ehp-unique-facility-selector',
  templateUrl: './unique-facility-selector.component.html',
  styleUrls: ['./unique-facility-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniqueFacilitySelectorComponent),
      multi: true,
    },
  ],
})
export class UniqueFacilitySelectorComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Input() public name!: string;

  @Input() public id: string | undefined;

  @Input() public scope: 'active' | 'all' = 'active';

  @Output() public facilityChange: EventEmitter<Facility | null> = new EventEmitter();

  public required: boolean = false;

  public readonly: boolean = false;

  public facilities$: Observable<Facility[]> = of([]);

  @ViewChild(FacilitySelectComponent) private select?: FacilitySelectComponent;

  private uniqueSource: UniqueFacilitySelectorSource | undefined;

  private readonly input: Subject<Facility[]> = new Subject<Facility[]>();

  private value: Facility['id'] | undefined;

  private isDisabled: boolean = false;

  private subscriptions: Set<Subscription> = new Set();

  private allFacilities: Facility[] = [];

  constructor(
    private readonly queryBus: QueryBus,
    private readonly customer: CurrentCustomerAccessor,
    private readonly nativeChanges: NativeChangeObserver,
    private readonly elementRef: ElementRef,
  ) {
    this.subscriptions.add(this.customer.onChange(() => this.loadFacilities()));

    this.subscriptions.add(
      this.nativeChanges.observe(this.elementRef, 'required').subscribe(change => {
        this.required = coerceBooleanProperty(change.currentValue);
      }),
    );

    this.subscriptions.add(
      this.nativeChanges.observe(this.elementRef, 'readonly').subscribe(change => {
        this.readonly = coerceBooleanProperty(change.currentValue);
      }),
    );
  }

  public ngOnInit() {
    if (!this.name) {
      this.isDisabled = true;
      this.setDisabledState(true);
      return;
    }
    this.uniqueSource = UniqueFacilitySelectorSource.create(this.name).attachSource(this.input.asObservable());

    this.facilities$ = this.uniqueSource.facilities().pipe(
      map(facilities => {
        const ids = new Set(facilities.map(facility => facility.id));

        if (isValidFacilityIdType(this.value)) {
          ids.add(this.value);
        }
        return this.allFacilities.filter(facility => ids.has(facility.id));
      }),
    );

    this.loadFacilities();
  }

  public ngAfterViewInit(): void {
    if (this.select) {
      this.select.registerOnTouched(this.onTouched);
      this.select.registerOnChange(this.onChange);
      this.select.writeValue(this.value);
      this.select.setDisabledState(this.isDisabled);
    }
  }

  public ngOnDestroy() {
    if (this.value) {
      this.uniqueSource?.deselect(this.value);
    }

    this.uniqueSource?.destroy();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public writeValue(value: Facility['id']): void {
    this.value = value;

    if (this.select) {
      this.select.writeValue(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
    if (this.select) {
      this.select.registerOnChange(fn);
    }
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;

    if (this.select) {
      this.select.registerOnTouched(fn);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this.name) {
      this.isDisabled = isDisabled;
    }

    if (this.select) {
      this.select.setDisabledState(isDisabled);
    }
  }

  public facilityHasChange(facility: Facility | null) {
    const previousId = this.value;

    this.value = facility ? facility.id : undefined;

    if (isValidFacilityIdType(previousId)) {
      if (!facility || facility.id !== previousId) {
        this.uniqueSource?.deselect(previousId);
      }
    }
    if (facility) {
      this.uniqueSource?.select(facility);
    }

    this.facilityChange.emit(facility);
  }

  private onChange = (value: Facility | Facility[] | undefined): void => undefined;

  private loadFacilities() {
    this.queryBus
      .request<Facility[]>(new ListAuthorizedFacilitiesQuery(undefined, this.scope === 'all'))
      .then(facilities => {
        this.allFacilities = facilities;
        this.input.next(facilities);
      });
  }
}
