import {NgModule} from "@angular/core";
import {
  ConfirmCustomerDeletionDialogComponent,
  CustomerChipComponent
} from "@easyhpad-ui/app/bundles/customer/modules/ui/components";
import {ReactiveFormsModule} from "@angular/forms";
import {FormModule} from "@easyhpad-ui/app/library/form/form.module";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    ConfirmCustomerDeletionDialogComponent,
    CustomerChipComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormModule,
    TranslationModule
  ],
  exports: [
    CustomerChipComponent
  ]
})
export class CustomerUiModule {
}
