/** A hero's name can't match the given regular expression */
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {FinessNumber} from "@domain/facility";


export const finessNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (typeof control.value !== "string" || control.value === '') {
    return null;
  }

  return !FinessNumber.validate(control.value) ? {invalidFinessNumber: {value: control.value}} : null;
}
