import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GetExpectedMediaListQuery } from '@application/bundles/file-deposit';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { FileDeposit, isFileDepositId } from '@domain/file-deposit';
import { ExpectedMediaList } from '@application/bundles/file-deposit/contracts';

@HandleQuery({
  query: GetExpectedMediaListQuery,
})
export class GetExpectedMediaListQueryHandler implements QueryHandler<GetExpectedMediaListQuery> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: GetExpectedMediaListQuery): Promise<ExpectedMediaList> {
    return this.backend
      .get<{ entity: ExpectedMediaList }>(this.buildEndpoint(query.id))
      .then(response => response.body)
      .then(body => body.entity);
  }

  private buildEndpoint(id: FileDeposit['id']): string {
    if (!isFileDepositId(id)) {
      throw new Error(`${id} is not a valid FileDeposit ID`);
    }
    return `/file-deposits/entities/${id}/list/expected`;
  }
}
