import {
  isLocalMedia,
  isMedia,
  isTemporaryMedia,
  LocalMedia,
  Media,
  MEDIA_FILE_TYPES,
  MEDIA_TYPES,
  TemporaryMedia
} from "@domain/media";

export function getTypeFromMimeType(mimeType: string): MEDIA_FILE_TYPES {

  switch (mimeType) {
    case "application/pdf":
      return MEDIA_FILE_TYPES.PDF;

    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
    case "application/vnd.ms-excel.sheet.macroenabled.12":
      return MEDIA_FILE_TYPES.EXCEL;

    case "image/avif":
    case "image/bmp":
    case "image/gif":
    case "image/vnd.microsoft.icon":
    case "image/jpeg":
    case "image/png":
    case "image/svg+xml":
    case "image/tiff":
    case "image/webp":
      return MEDIA_FILE_TYPES.IMAGE;

    default:
      return MEDIA_FILE_TYPES.OTHER;

  }
}

export function getMediaType(media: LocalMedia | TemporaryMedia | Media): MEDIA_TYPES {

  if (isLocalMedia(media)) {
    return MEDIA_TYPES.LOCAL;
  }
  if (isTemporaryMedia(media)) {
    return MEDIA_TYPES.TEMPORARY;
  }

  if (isMedia(media)) {
    return MEDIA_TYPES.MEDIA;
  }

  throw new Error('media is not a valid Media');
}
