import { NgModule } from '@angular/core';
import { FundingBundle } from '@application/bundles/funding/funding.bundle';
import { Bundle } from '@application/framework/bundle';
import {
  AccommodationFundingAmountRepository,
  DependencyFundingAmountRepository,
  DependencyFundingUpdateRateRepository,
  HealthFundingAmountRepository,
  HealthFundingUpdateRateRepository,
} from '@application/bundles/funding';
import {
  EasyAccommodationFundingStatsRepository,
  EasyDependencyFundingAmountRepository,
  EasyDependencyUpdateRateRepository,
  EasyHealthFundingAmountRepository,
  EasyHealthFundingUpdateRateRepository,
} from '@implementations/bundles/backend/easyhpad';
import { extractDependencies } from '@application/framework/di';

@NgModule({
  providers: [
    {
      provide: AccommodationFundingAmountRepository,
      useClass: EasyAccommodationFundingStatsRepository,
      deps: extractDependencies(EasyAccommodationFundingStatsRepository),
    },
    {
      provide: DependencyFundingAmountRepository,
      useClass: EasyDependencyFundingAmountRepository,
      deps: extractDependencies(EasyDependencyFundingAmountRepository),
    },
    {
      provide: HealthFundingAmountRepository,
      useClass: EasyHealthFundingAmountRepository,
      deps: extractDependencies(EasyHealthFundingAmountRepository),
    },
    {
      provide: DependencyFundingUpdateRateRepository,
      useClass: EasyDependencyUpdateRateRepository,
      deps: extractDependencies(EasyDependencyUpdateRateRepository),
    },
    {
      provide: HealthFundingUpdateRateRepository,
      useClass: EasyHealthFundingUpdateRateRepository,
      deps: extractDependencies(EasyHealthFundingUpdateRateRepository),
    },
  ],
})
@Bundle({ imports: [FundingBundle] })
export class FundingModule {}
