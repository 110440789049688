import { AsyncTransformer } from '@application/framework/core';
import { User } from '@domain/user';
import { EasyUser } from '@implementations/bundles/backend/easyhpad/interfaces/easy-user.interface';
import { EasyUserCommonTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-user-common.transformer';
import { UserImpl } from '@implementations/bundles/user';
import { EasyCustomerTransformer } from '@implementations/bundles/backend/easyhpad/transformers';
import { Provide } from '@application/framework/di';
import { EasyRoleTransformer } from '@implementations/bundles/backend/easyhpad/transformers/role/easy-role-transformer';

@Provide()
export class EasyUserTransformer implements AsyncTransformer<User, EasyUser> {
  @Provide() private readonly customerTransformer!: EasyCustomerTransformer;

  private readonly roleTransformer: EasyRoleTransformer = new EasyRoleTransformer();

  constructor(private readonly commonTransformer: EasyUserCommonTransformer) {}

  public async transform(value: User): Promise<EasyUser> {
    const common = await this.commonTransformer.transform(value);

    return {
      ...common,
      id: value.id,
      facilityIds: value.facilityIds ?? [],
      createdAt: value.createdAt,
      updatedAt: value.updatedAt,
      roles: Array.isArray(value.roles) ? value.roles.map(r => this.roleTransformer.transform(r)) : [],
    };
  }

  public async reverseTransform(value: EasyUser): Promise<User> {
    const common = await this.commonTransformer.reverseTransform(value);

    return new UserImpl({
      ...common,
      id: value.id,
      createdAt: value.createdAt,
      updatedAt: value.updatedAt,
      facilityIds: value.facilityIds,
      roles: Array.isArray(value.roles) ? value.roles.map(r => this.roleTransformer.reverseTransform(r)) : [],
    });
  }
}
