import {PartialActivityAnnex} from "@domain/eprd-errd";
import {isValidAnnexActivityDaysCount} from "@domain/activity-annex";
import {isPercentage} from "@domain/lib/math/percentage/percentage";

export class PartialActivityAnnexImpl implements PartialActivityAnnex {

  public days: number | undefined;

  public occupationRate: number | undefined;


  constructor(defaults?: Partial<PartialActivityAnnex>) {

    if (defaults) {

      if (isPercentage(defaults.occupationRate)) {
        this.occupationRate = defaults.occupationRate;
      }

      if (isValidAnnexActivityDaysCount(defaults.days)) {
        this.days = defaults.days;
      }
    }
  }

}
