import {CPOMFunding} from "@domain/cpom";
import {isPositiveFundingAmount} from "@domain/funding/is-valid-amount/is-valid-amount.validator";
import {isValidObject} from "@application/framework/lib";


export function isCPOMFunding(funding: any): funding is CPOMFunding {
  return isValidObject(funding)
    && typeof funding.name === 'string'
    && isPositiveFundingAmount(funding.amount)
    && (typeof funding.comment === 'string' || funding.comment === undefined);
}


export function isEmptyCPOMFunding(cpomFunding: CPOMFunding): boolean {
  return !cpomFunding.name && !cpomFunding.amount && !cpomFunding.comment;
}
