<p-panel *ngFor="let row of form.controls; let index = index" [toggleable]="true">
    <ng-template pTemplate="header">
        <div class="filter-panel-header">
            {{ getControlPanelName(index) }}

            <div class="actions">
                <button (click)="removeFilter(index)" class="is-icon small icon-delete delete" type="button">
                    <span class="label">{{'Supprimer la ligne' | ehp_translate}}</span>
                </button>
            </div>
        </div>
    </ng-template>

    <ehp-filter (keyChange)="updatePanelName(index, $event)" (onChange)="patchFilterValue(index, $event)"
                [definitions]="definitions" [formGroup]="row" [initialValue]="initialValues[index]"></ehp-filter>

</p-panel>

<button (click)="addFilter()" [disabled]="!form.valid" class="secondary outline add" type="button">
    {{'Ajouter un filtre' | ehp_translate}}
</button>

