import { Year } from '../../lib';
import { DepartmentCode } from '../../geo';

export interface PointGirValuation {
  /**
   * Valuation amount
   */
  amount: number;

  /**
   * Year of Point G.I.R
   */
  year: Year;

  /**
   * Department Attached
   */
  departmentCode: DepartmentCode;

  source: string;

  sourceUrl?: string;

  latestUpdate: Date;
}
