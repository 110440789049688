import {Logger} from "@application/framework/logger";
import {Translator} from "@application/framework/translation";
import {ErrorMessagesDictionary} from "@implementations/forms/error-messages.interface";

export enum FORM_ERROR_TYPE {
  REQUIRED = 'required',
  EMAIL = 'email'
}

export class ErrorMessages implements ErrorMessagesDictionary {

  public constructor(private translator: Translator, private logger: Logger) {
  }

  public get(key: string, params?: Record<string, any>): Promise<string> {
    return this.translator.translate(key, params, 'form_errors');
  }

}
