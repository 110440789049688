import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetCustomerQuery } from '@application/bundles/customer/queries/get-customer.query';
import { QueryBus } from '@application/framework/command-query';
import { Customer } from '@domain/customer';

@Component({
  selector: 'ehp-customer-chip',
  templateUrl: './customer-chip.component.html',
  styleUrls: ['./customer-chip.component.scss'],
})
export class CustomerChipComponent implements OnChanges {
  @Input() public customer: Customer | undefined;

  @Input() public customerId!: Customer['id'] | undefined;

  public name: Customer['name'] | undefined;

  constructor(private readonly queryBus: QueryBus) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['customerId']) {
      this.customerIdHasChange();
    }

    if (changes['customer']) {
      this.customerHasChange();
    }
  }

  private customerIdHasChange() {
    if (this.customerId === undefined) {
      this.name = '';
      return;
    }

    this.queryBus.request<Customer>(new GetCustomerQuery(this.customerId)).then(customer => {
      this.customer = customer;
      this.customerHasChange();
    });
  }

  private customerHasChange() {
    this.name = '';
    if (this.customer) {
      this.name = this.customer.name;
    }
  }
}
