import { isValidCustomerIdType } from '@application/bundles/customer';
import { UpdateCustomerDto } from '@application/bundles/customer/dto/update-customer.dto';
import { CustomerCommonAbstractDto } from '@implementations/bundles/customer/dto/customer-common.abstract.dto';
import { IsValidCustomerId } from '@implementations/bundles/customer/validators/class-validator';

export class UpdateCustomerDtoImpl extends CustomerCommonAbstractDto implements UpdateCustomerDto {
  @IsValidCustomerId()
  public id!: UpdateCustomerDto['id'];

  constructor(defaults?: Partial<UpdateCustomerDto>) {
    super(defaults);

    if (defaults && isValidCustomerIdType(defaults.id)) {
      this.id = defaults.id;
    }
  }
}
