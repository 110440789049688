import { UpdateCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto';
import { Transformer } from '@application/framework/core';
import { UUID } from '@domain/lib';
import { EasyUpdateCapacityAuthorization } from '@implementations/bundles/backend/easyhpad/interfaces/easy-capacity-authorization.interface';
import { EasyInspectionReport } from '@implementations/bundles/backend/easyhpad/interfaces/easy-inspection-report';
import { EasyCapacityAuthorizationAbstractTransformer } from '@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-capacity-authorization.abstract.transformer';

export class EasyUpdateCapacityAuthorizationTransformer
  extends EasyCapacityAuthorizationAbstractTransformer
  implements Transformer<UpdateCapacityAuthorizationDto, EasyUpdateCapacityAuthorization>
{
  public transform(value: UpdateCapacityAuthorizationDto): EasyUpdateCapacityAuthorization {
    let inspectionReports: Pick<EasyInspectionReport, 'id' | 'date' | 'mediaIds'>[] = [];

    if (Array.isArray(value.inspectionReports)) {
      inspectionReports = value.inspectionReports.map(report => ({
        id: report.id as UUID,
        date: this.dateFormatter.toUTCIsoString(report.date),
        mediaIds: report.mediaIds,
      }));
    }

    return {
      ...this.transformCommonProperties(value),
      inspections: inspectionReports,
    };
  }

  public reverseTransform(value: EasyUpdateCapacityAuthorization): UpdateCapacityAuthorizationDto {
    throw new Error('Method reverseTransform not implemented in EasyUpdateCapacityAuthorizationTransformer');
  }
}
