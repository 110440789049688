import { Directive } from '@angular/core';

@Directive({
  selector: '[ehpPrefix]'
})
export class PrefixDirective {

  constructor() { }

}
