import { Attribute, CRUD_ACTIONS } from '@application/bundles/authorization';
import { ForbiddenError } from '@application/bundles/authorization/error';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import {
  CreateGMPCommand,
  CreateGMPDto,
  GMPCreatedEvent,
  GMPCreationFailEvent,
  GMPRepository,
} from '@application/bundles/gmp';
import { GMPAuthorizationChecker } from '@application/bundles/gmp/gmp-authorization-checker';
import { GMP_FEATURE, GMP_TRANSLATE_CONTEXT } from '@application/bundles/gmp/gmp.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { Facility } from '@domain/facility';
import { GMP } from '@domain/gmp';
import { MediaBucket, TemporaryMedia } from '@domain/media';

@HandleCommand({
  command: CreateGMPCommand,
})
export class CreateGMPCommandHandler implements CommandHandler<CreateGMPCommand, GMP> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: GMPRepository,
    private readonly authorization: GMPAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: CreateGMPCommand): Promise<GMP> {
    const { gmp } = command;
    try {
      const created = await this.sanitize(gmp)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.storePvAndCreateGMP(dto));

      this.eventDispatcher.dispatch(new GMPCreatedEvent(created));

      return created;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: CreateGMPDto): Promise<CreateGMPDto> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(dto.facilityId));

    const attributes: Attribute[] = [{ feature: GMP_FEATURE, value: CRUD_ACTIONS.CREATE }];

    if (!(await this.authorization.canCreate())) {
      this.logger.error('G.M.P. creation : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: CreateGMPDto): Promise<CreateGMPDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('G.M.P. creation : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: CreateGMPDto): Promise<CreateGMPDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('G.M.P. creation : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à créer un nouveau G.M.P..",
        undefined,
        GMP_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new GMPCreationFailEvent(message));

    return error;
  }

  private async storePvAndCreateGMP(dto: CreateGMPDto): Promise<GMP> {
    let pv: TemporaryMedia | undefined;

    try {
      if (dto.pv) {
        pv = await this.mediaBucket.add(dto.pv);
        dto.pvId = pv.id;
      }

      return this.repository.create(dto);
    } catch (e) {
      if (pv !== undefined) {
        await pv.markForDeletion();
      }

      throw e;
    }
  }
}
