import { isValidActivityAnnexIdType, UpdateActivityAnnexDto } from '@application/bundles/activity-annex';
import { Facility } from '@domain/facility';
import { isLocalMedia, isMedia, LocalMedia, Media } from '@domain/media';
import { IsDate, IsNumber, IsOptional, Max, Min } from 'class-validator';
import { IsValidFacilityId } from '@implementations/bundles/facility';
import { IsLocalMediaOrMedia, IsMediaId } from '@implementations/bundles/media';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { isValidMediaIdType } from '@application/bundles/media';
import { isPercentage } from '@domain/lib/math/percentage/percentage';
import { ActivityAnnex, isValidAnnexActivityDaysCount } from '@domain/activity-annex';
import { IsValidActivityAnnexId } from '@implementations/bundles/activity-annex/validators';

export class UpdateActivityAnnexDtoImpl implements UpdateActivityAnnexDto {
  @IsValidActivityAnnexId()
  public id!: ActivityAnnex['id'];

  @IsDate()
  public date!: Date;

  @IsDate()
  @IsOptional()
  public depositDate!: Date;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  @IsMediaId()
  @IsOptional()
  public documentId!: Media['id'];

  @IsLocalMediaOrMedia()
  public document!: LocalMedia | Media;

  @IsNumber()
  @Min(0)
  @Max(100)
  public occupationRate!: number;

  @IsNumber()
  @Min(0)
  @Max(366)
  public days!: number;

  constructor(values?: Partial<UpdateActivityAnnexDto>) {
    if (values) {
      if (isValidActivityAnnexIdType(values.id)) {
        this.id = values.id;
      }

      if (isValidFacilityIdType(values.facilityId)) {
        this.facilityId = values.facilityId;
      }

      if (values.date instanceof Date) {
        this.date = values.date;
      }

      if (values.depositDate instanceof Date) {
        this.depositDate = values.depositDate;
      }

      if (isLocalMedia(values.document) || isMedia(values.document)) {
        this.document = values.document;
      }

      if (isValidMediaIdType(values.documentId)) {
        this.documentId = values.documentId;
      }

      if (isPercentage(values.occupationRate)) {
        this.occupationRate = values.occupationRate;
      }

      if (typeof values.days === 'number') {
        if (values.date instanceof Date && isValidAnnexActivityDaysCount(values.days, values.date.getFullYear())) {
          this.days = values.days;
        } else if (isValidAnnexActivityDaysCount(this.days)) {
          this.days = values.days;
        }
      }
    }
  }
}
