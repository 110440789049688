import {Year} from '@domain/lib';
import {DOCUMENT_TYPES} from "@domain/document";

/**
 * The FileDepositList represents the list of documents
 * that the user will need to upload in order to complete their FileDeposit.
 *
 * @since 1.1.0
 */
export interface FileDepositList {
  /**
   * The list of expected items.
   */
  items: FileDepositListItem[];
}

/**
 * The FileDepositListItem represents an excepted document in a FileDepositList.
 * Each item represents the desired document type as well as the years for which a document is expected.
 *
 * @since 1.1.0
 */
export interface FileDepositListItem {
  /**
   * The desired document type
   */
  type: DOCUMENT_TYPES;

  /**
   * The list of expected years
   */
  years?: Year[];

  /**
   * Determine if excepted item is the last of type.
   */
  latest?: true;
}
