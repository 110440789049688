import {CreateERRDDto, UpdateERRDDto} from "@application/bundles/eprd-errd";
import {
  CreateERRDDtoImpl,
  CreateLocalERRDDtoImpl,
  UpdateERRDDtoImpl,
  UpdateLocalERRDDtoImpl
} from "@implementations/bundles/eprd-errd";
import {ERRDDtoFactory} from "@application/bundles/eprd-errd/factories/errd-dto/errd-dto.factory";

export class ErrdDtoFactoryImpl implements ERRDDtoFactory {
  public buildCreateDtoFromUnsafeValues(values: Partial<CreateERRDDto>): CreateERRDDto {
    values.details = Array.isArray(values.details) ? values.details.map(d => new CreateLocalERRDDtoImpl(d)) : [];
    return new CreateERRDDtoImpl(values);
  }

  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdateERRDDto>): UpdateERRDDto {
    values.details = Array.isArray(values.details) ? values.details.map(d => new UpdateLocalERRDDtoImpl(d)) : [];
    return new UpdateERRDDtoImpl(values);
  }

}
