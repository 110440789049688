import {DAYCARE_DEPENDENCIES_FUNDING_MODES, DaycareDependencyFunding} from "@domain/dependency-funding";

export interface CreateDaycareDependencyFundingDto<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES, V> extends Omit<DaycareDependencyFunding<M, V>, 'id' | 'mode' | 'value'> {

  mode: DAYCARE_DEPENDENCIES_FUNDING_MODES;

  value: V;

}

export interface UpdateDaycareDependencyFundingDto<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES, V> extends Omit<DaycareDependencyFunding<M, V>, 'mode' | 'value'> {

  mode: DAYCARE_DEPENDENCIES_FUNDING_MODES;

  value: V;

}
