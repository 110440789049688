import { CommonHealthFundingDtoAbstract } from '@implementations/bundles/health-funding/dto/health-funding/common-health-funding.dto.abstract';
import {
  CreateEhpadDemainHealthFundingDto,
  CreateExceptionalHealthFundingDto,
  CreateNonRecurringHealthFundingDto,
  CreateRecurringHealthFundingDto,
  isValidHealthFundingIdType,
  UpdateEhpadDemainHealthFundingDto,
  UpdateExceptionalHealthFundingDto,
  UpdateHealthFundingDto,
  UpdateNonRecurringHealthFundingDto,
  UpdateRecurringHealthFundingDto,
} from '@application/bundles/health-funding';
import { isValidObject } from '@application/framework/lib';
import { IsArray, IsOptional } from 'class-validator';
import { IsLocalMediaOrMedia } from '@implementations/bundles/media';
import { isLocalMedia, isMedia, LocalMedia, Media } from '@domain/media';
import { HealthFunding } from '@domain/health-funding';

export class UpdateHealthFundingDtoImpl extends CommonHealthFundingDtoAbstract implements UpdateHealthFundingDto {
  public id!: HealthFunding['id'];

  @IsArray()
  public recurringFunding: Array<CreateRecurringHealthFundingDto | UpdateRecurringHealthFundingDto> = [];

  @IsArray()
  public nonRecurringFunding: Array<CreateNonRecurringHealthFundingDto | UpdateNonRecurringHealthFundingDto> = [];

  @IsArray()
  public exceptionalFunding: Array<CreateExceptionalHealthFundingDto | UpdateExceptionalHealthFundingDto> = [];

  @IsArray()
  public ehpadDemainFunding: Array<CreateEhpadDemainHealthFundingDto | UpdateEhpadDemainHealthFundingDto> = [];

  @IsLocalMediaOrMedia()
  public document!: LocalMedia | Media;

  @IsLocalMediaOrMedia()
  @IsOptional()
  public report?: LocalMedia | Media;

  constructor(values?: Partial<UpdateHealthFundingDto>) {
    super(values);

    if (isValidObject(values)) {
      if (isValidHealthFundingIdType(values?.id)) {
        this.id = values?.id;
      }

      if (Array.isArray(values.recurringFunding)) {
        this.recurringFunding = values.recurringFunding;
      }

      if (Array.isArray(values?.nonRecurringFunding)) {
        this.nonRecurringFunding = values.nonRecurringFunding;
      }

      if (Array.isArray(values?.exceptionalFunding)) {
        this.exceptionalFunding = values.exceptionalFunding;
      }

      if (Array.isArray(values?.ehpadDemainFunding)) {
        this.ehpadDemainFunding = values.ehpadDemainFunding;
      }

      if (isLocalMedia(values?.document) || isMedia(values?.document)) {
        this.document = values?.document;
      }

      if (isLocalMedia(values?.report) || isMedia(values?.report)) {
        this.report = values?.report;
      }
    }
  }
}
