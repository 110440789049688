import { AbstractEasyFundingAmountRepository } from '@implementations/bundles/backend/easyhpad/repositories/funding/abstract-easy-funding-amount.repository';
import { HealthFundingAmountRepository } from '@application/bundles/funding';
import { EasyHealthFunding } from '@implementations/bundles/backend/easyhpad';
import { Provide } from '@application/framework/di';
import { HealthFunding } from '@domain/health-funding';
import { HealthFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers';
import { CreateHealthFundingDto, UpdateHealthFundingDto } from '@application/bundles/health-funding';

export class EasyHealthFundingAmountRepository
  extends AbstractEasyFundingAmountRepository<HealthFunding, EasyHealthFunding>
  implements HealthFundingAmountRepository
{
  @Provide() private readonly entityTransformer!: HealthFundingTransformer;

  constructor() {
    super('funding/health/amounts', {
      from: input => this.entityTransformer.reverseTransform(input),
      to: output => this.entityTransformer.transform(output),
      create: (input: CreateHealthFundingDto) => this.entityTransformer.reverseTransformCreateDto(input),
      update: (input: UpdateHealthFundingDto) => this.entityTransformer.reverseTransformUpdateDto(input),
    });
  }
}
