import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressModule } from '@easyhpad-ui/app/bundles/address/address.module';
import { CustomerFormModule } from '@easyhpad-ui/app/bundles/customer/modules';
import { FacilityEditFormComponent } from '@easyhpad-ui/app/bundles/facility/components/facility-edit-form/facility-edit-form.component';
import { FacilityFormBuilder } from '@easyhpad-ui/app/bundles/facility/services/form-builder/form-builder.service';
import { PhoneModule } from '@easyhpad-ui/app/bundles/phone/phone.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import {
  AccountFacilitySelectorComponent,
  AccountMultiFacilitySelectorComponent,
  CurrentMultiFacilitySelectorComponent,
  DynamicFacilityFieldComponent,
  FacilitySelectComponent,
  MultiFacilitySelectComponent,
  UniqueFacilitySelectorComponent,
} from './components';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    ReactiveFormsModule,
    AddressModule,
    CustomerFormModule,
    PhoneModule,
    FormModule,
    AutocompleteLibModule,
    MultiSelectModule,
    FormsModule,
    DropdownModule,
    ListboxModule,
  ],
  declarations: [
    FacilityEditFormComponent,
    FacilitySelectComponent,
    AccountFacilitySelectorComponent,
    CurrentMultiFacilitySelectorComponent,
    UniqueFacilitySelectorComponent,
    AccountMultiFacilitySelectorComponent,
    MultiFacilitySelectComponent,
    DynamicFacilityFieldComponent,
  ],
  exports: [
    FacilityEditFormComponent,
    FacilitySelectComponent,
    AccountFacilitySelectorComponent,
    CurrentMultiFacilitySelectorComponent,
    UniqueFacilitySelectorComponent,
    AccountMultiFacilitySelectorComponent,
    MultiFacilitySelectComponent,
  ],
  providers: [FacilityFormBuilder],
})
export class FacilityFormModule {}
