import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {PhoneFormatter} from "@application/bundles/phone/formatter/phone.formatter";
import {PhoneFromStringTransformer} from "@application/bundles/phone/transformer/phone-from-string.transformer";
import {PhoneValidator} from "@application/bundles/phone/validator/phone.validator";
import {Container} from "@application/framework/di/container";
import {PhoneNumberValidator} from "@easyhpad-ui/app/bundles/phone/validators/phone-number/phone-number.validator";
import {FormModule} from "@easyhpad-ui/app/library/form/form.module";
import {GooglePhoneNumberFormatter} from "@implementations/bundles/phone/formatter/google-phone-number.formatter";
import {PhoneBundle} from "@implementations/bundles/phone/phone.bundle";
import {
  GooglePhoneFromStringTransformer
} from "@implementations/bundles/phone/transformer/google-phone-from-string.transformer";
import {GooglePhoneValidator} from "@implementations/bundles/phone/validator/google-phone-validator";
import {PhoneInputComponent} from './components/phone-input/phone-input.component';
import {PhoneFormatterDirective} from './directives/phone-formatter/phone-formatter.directive';


@NgModule({
  declarations: [
    PhoneInputComponent,
    PhoneFormatterDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormModule
  ],
  providers: [
    PhoneNumberValidator,
    {
      provide: PhoneValidator,
      useClass: GooglePhoneValidator
    },
    {
      provide: PhoneFormatter,
      useClass: GooglePhoneNumberFormatter
    },
    {
      provide: PhoneFromStringTransformer,
      useClass: GooglePhoneFromStringTransformer
    }
  ],
  exports: [PhoneInputComponent]
})
export class PhoneModule {

  constructor(container: Container) {
    PhoneBundle.build(container);
  }
}
