import {Query} from "@application/framework/command-query";
import {AccommodationFunding} from "@domain/accommodation-funding";
import {Facility} from "@domain/facility";

export class GetAccommodationFundingForYearQuery implements Query<AccommodationFunding | undefined> {

  constructor(public readonly facilityId: Facility['id'], public readonly year: number) {
  }

}
