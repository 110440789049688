<div class="authentification-container">
  <ehp-logo></ehp-logo>
  <h1 class="title page-title">
    <ehp-translatable-string [value]="title"></ehp-translatable-string>
  </h1>

  <ehp-user-reset-password-form [token]="token" class="auth-form"></ehp-user-reset-password-form>
</div>

<div class="image-container">
  <ehp-access-image></ehp-access-image>
</div>
