import {CustomerSearchParams, CustomerSortKey, SerializedCustomerSearchParams} from "@application/bundles/customer";
import {Sort, SORT_ORDER} from "@application/framework/search/sort";
import {isSort, PaginationParams} from "@application/framework/search";

export class AgnosticCustomerSearchParams implements CustomerSearchParams {

  private sort: Sort<CustomerSortKey> | undefined;

  private name: string | undefined;

  private pagination: PaginationParams | undefined;

  public setSort(option: Sort<CustomerSortKey> | null | undefined): this {
    this.sort = isSort(option) ? option : undefined;
    return this;
  }

  public searchName(name: string | null | undefined): this {
    this.name = typeof name === "string" && name ? name : undefined;
    return this;
  }


  public setPagination(pagination: PaginationParams | null): this {
    if (pagination === null) {
      this.pagination = undefined;
    } else if (Number.isInteger(pagination.limit)) {
      this.pagination = pagination;
    }
    return this;
  }


  public serialize(): SerializedCustomerSearchParams {

    const serialized: SerializedCustomerSearchParams = {
      sort: this.sort || {key: "name", order: SORT_ORDER.ASC}
    };

    const search = {} as any;

    if (this.name) {
      search.name = this.name;
    }

    if (Object.keys(search).length > 0) {
      serialized.search = search;
    }

    if (this.pagination) {
      serialized.pagination = this.pagination;
    }

    return serialized;
  }
}
