<p-table
  #dt1
  [globalFilterFields]="['department.name', 'department.code']"
  [scrollable]="true"
  [tableStyle]="{'min-width': '50rem'}"
  [value]="values"
  scrollHeight="400px"
  styleClass="p-datatable-striped"
>
  <ng-template pTemplate="caption">
    <div class="title-container">
      <h2 class="title">
        {{'Valorisation du point G.I.R.' | ehp_translate}}
        <ng-container *ngIf="year">
          {{'pour l\'année'}} {{ year }}
        </ng-container>
      </h2>


      <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input (input)="filter(dt1, $event)" class="form-element" pInputText
                           placeholder="{{'Filtrer par département' | ehp_translate}}"
                           type="text"/>
                </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th id="departmentCode">
        {{ 'Code'| ehp_translate }}
      </th>

      <th id="department">
        {{ 'Département'| ehp_translate }}
      </th>

      <th class="amount-cell" id="amount" style="min-width:10rem">
        {{'Valorisation' | ehp_translate }}
      </th>

    </tr>
  </ng-template>
  <ng-template let-valorisation pTemplate="body">
    <tr>
      <td headers="departmentCode">
        {{ valorisation?.department?.code }}
      </td>

      <td class="department-cell" headers="department">
        {{ valorisation?.department?.name }}
      </td>

      <td class="amount-cell" headers="amount">
        <span class="amount">
          <span class="value">{{ valorisation.amount|currency }}</span>

          <ng-container *ngIf="valorisation.source">

            <ng-template #tooltipContent>
              <ehp-source [data]="valorisation"></ehp-source>
            </ng-template>

            <i [tooltip]="tooltipContent" class="info ehp-icons icon-help" ehp-tooltip></i>
          </ng-container>

        </span>


      </td>

    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">{{'Aucune valorisation point G.I.R. trouvée' | ehp_translate}}</td>
    </tr>
  </ng-template>
</p-table>
