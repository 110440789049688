import { isValidObject } from '@application/framework/lib';
import { ExceptionalHealthFunding } from '@domain/health-funding';
import { isUUID } from '@domain/lib';
import { isValidTaxonomyTermIdType } from '@domain/taxonomy';

export function isValidExceptionalHealthFundingIdType(id: any): id is ExceptionalHealthFunding['id'] {
  return isUUID(id);
}

export function isMaybeExceptionalHealthFunding(funding: any): funding is Omit<ExceptionalHealthFunding, 'id'> {
  return (
    isValidObject(funding) &&
    (funding.comment === undefined || (typeof funding.comment === 'string' && funding.comment)) &&
    (funding.categoryId === undefined || isValidTaxonomyTermIdType((funding as ExceptionalHealthFunding).categoryId))
  );
}

export function isExceptionalHealthFunding(funding: any): funding is ExceptionalHealthFunding {
  return (
    isMaybeExceptionalHealthFunding(funding) &&
    isValidExceptionalHealthFundingIdType((funding as ExceptionalHealthFunding).id)
  );
}
