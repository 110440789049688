import { PropertySerializerMap } from '@application/framework/backend/contracts';
import { BACKEND_SERIALIZER_METADATA } from '@application/framework/backend/metadata';
import { MetadataReflection } from '@application/framework/lib';

const reflection = new MetadataReflection();

/**
 *  Retrieves the mapping function Map from the target's metadata.
 * @param target - The target object
 */
export const extractPropertySerializerMetadata = (target: any): PropertySerializerMap => {
  let metadata: PropertySerializerMap | undefined = reflection.getMetadata(
    BACKEND_SERIALIZER_METADATA.PROPERTY_NAME,
    target,
  );

  if (!(metadata instanceof Map)) {
    metadata = new Map();
  }
  return metadata;
};

/**
 * Retrieves the list of properties to remove when serializing to the backend from the target's metadata.
 * @param target - The target object
 */
export const extractExcludedPropertiesMetadata = (target: any): Set<string | number | symbol> => {
  let metadata: Set<string | number | symbol> | undefined = reflection.getMetadata(
    BACKEND_SERIALIZER_METADATA.EXCLUDE,
    target,
  );

  if (!(metadata instanceof Set)) {
    metadata = new Set();
  }

  return metadata;
};
