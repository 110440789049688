import { Attribute, ROLE_FEATURE, Voter, VoteResult } from '@application/bundles/authorization';
import { AVAILABLE_ROLES, isAttribute, Role } from '@domain/authorization';
import { AuthentificationToken } from '@application/bundles/authentification';

export class RoleVoter implements Voter<Role> {
  public async vote(
    token: AuthentificationToken,
    subject: any,
    attributes: Attribute<string | Role>[],
  ): Promise<VoteResult> {
    let result = VoteResult.ABSTAIN;

    const roles = await this.extractRoleNames(token);

    attributes = attributes.filter(attribute => isAttribute(attribute) && attribute.feature === ROLE_FEATURE);

    for (const attribute of attributes) {
      if (!attribute || !this.isValidValue(attribute.value)) {
        continue;
      }

      const name = typeof attribute.value === 'object' ? attribute.value.name : attribute.value;

      result = VoteResult.DENIED;

      for (const role of roles) {
        if (name === role) {
          return VoteResult.GRANTED;
        }
      }
    }

    return result;
  }

  private extractRoleNames(token: AuthentificationToken): AVAILABLE_ROLES[] {
    return token.roles.all().map(role => role.name);
  }

  private isValidValue(value: any): value is string | symbol | object {
    return value !== null && (typeof value === 'string' || typeof value === 'object' || typeof value === 'symbol');
  }
}
