import {PhonePropertySanitizer} from "@application/bundles/phone/sanitizer/phone-property.sanitizer";
import {Container} from "@application/framework/di/container";
import {SanitizerRegistry} from "@application/framework/sanitizer/sanitizer";
import {CreateCustomerDtoImpl} from "@implementations/bundles/customer/dto/create-customer.dto";
import {UpdateCustomerDtoImpl} from "@implementations/bundles/customer/dto/update-customer.dto";

export class CustomerBundle {

  private static instance: CustomerBundle | undefined;

  private constructor(private container: Container) {

    const registry = container.get(SanitizerRegistry);
    registry.register(CreateCustomerDtoImpl, new PhonePropertySanitizer<'phone'>('phone').sanitize);
    registry.register(UpdateCustomerDtoImpl, new PhonePropertySanitizer<'phone'>('phone').sanitize);
  }

  public static build(container: Container): CustomerBundle {

    if (!(CustomerBundle.instance instanceof CustomerBundle)) {
      CustomerBundle.instance = new CustomerBundle(container);
    }

    return CustomerBundle.instance;
  }
}
