import { USER_FEATURE } from '@application/bundles/user/user.token';
import { PermissionCollection, RoleCollection } from '@domain/authorization';
import { isValidUserIdType, User } from '@domain/user';
import { Customer } from '@domain/customer';
import { AuthentificationToken } from '@application/bundles/authentification';

export class UserAuthentificationToken implements AuthentificationToken {
  public readonly provider: Symbol = USER_FEATURE;

  public readonly isAuthenticated: boolean;

  public readonly customerReference: Customer['id'] | undefined;

  public readonly id: User['id'];

  public readonly name: string;

  constructor(
    private readonly user: User,
    public readonly roles: RoleCollection,
    public readonly permissions: PermissionCollection,
  ) {
    this.id = user.id;
    this.name = this.user.displayName;
    this.isAuthenticated = isValidUserIdType(this.id);
    this.customerReference = user.customerId;
  }
}
