import {Dialog} from "@angular/cdk/dialog";
import {DeleteCPOMCommand, StoredCPOM} from "@application/bundles/cpom";
import {CommandBus, CommandHandler, HandleCommand} from "@application/framework/command-query";
import {OpenCPOMDeletionDialogCommand} from "@easyhpad-ui/app/bundles/cpom/commands/open-cpom-deletion-dialog.command";
import {
  ConfirmDeletionDialogComponent
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component";
import {
  ConfirmDeletionDialogData
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface";
import {DialogConfiguration} from "@easyhpad-ui/app/library/dialog";

@HandleCommand({
  command: OpenCPOMDeletionDialogCommand
})
export class OpenCPOMDeletionDialogCommandHandler implements CommandHandler<OpenCPOMDeletionDialogCommand, void> {


  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus
  ) {
  }

  public async handle(command: OpenCPOMDeletionDialogCommand): Promise<void> {
    const {cpom, onDeletion} = command;


    const data: ConfirmDeletionDialogData = {
      options: [{key: cpom.id, label: `C.P.O.M. ${cpom.year}`}]
    }

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<StoredCPOM>(new DeleteCPOMCommand(cpom));

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    }

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, {...DialogConfiguration, data});
    dialog.closed.subscribe(subscriber);
  }

}
