import { PermissionCollection } from '@domain/authorization/permission.interface';
import { RoleCollection } from '@domain/authorization/role';
import { Customer } from '@domain/customer';

export interface AuthentificationToken<ID = string | number> {
  id: ID;

  /**
   * Who provide the token
   */
  provider: string | Symbol;

  /**
   * token name used when token must be displayed
   */
  name: string;

  /**
   * Check if current account is authenticated
   */
  readonly isAuthenticated: boolean;

  /**
   * Get the id of customer attached to this token
   */
  readonly customerReference: Customer['id'] | undefined;

  /**
   * Get roles associated to this token
   */
  readonly roles: RoleCollection;

  /**
   * Get permission collection associated to this token
   */
  readonly permissions: PermissionCollection;
}
