import { Component, Input } from '@angular/core';
import { EhpadDemain } from '@domain/ehpad-demain';

@Component({
  selector: 'ehp-ehpad-demain-list',
  templateUrl: './ehpad-demain-list.component.html',
  styleUrls: ['./ehpad-demain-list.component.scss'],
  host: {
    class: 'document-list',
  },
})
export class EhpadDemainListComponent {
  @Input() public documents: EhpadDemain[] | undefined;
}
