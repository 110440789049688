import { createReducer, on } from '@ngrx/store';
import { DashboardStoreState } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.state';
import { DashboardActions } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.actions';

const initialState: DashboardStoreState = {
  view: 'block',
  facilityIds: [],
};

export const dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.setDashboardMode, (state, { mode }) => ({
    ...state,
    view: mode,
  })),
  on(DashboardActions.setFacilities, (state, { facilityIds }) => ({
    ...state,
    facilityIds: [...facilityIds],
  })),
  on(DashboardActions.reset, () => ({
    ...initialState,
  })),
);
