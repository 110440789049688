import { Address } from '@domain/address';
import { Facility } from '@domain/facility';
import { PhoneNumber } from '@domain/phone/phone.interface';
import { BillingMode } from '@domain/customer/billing-mode';
import { UUID } from '@domain/lib';

export interface Customer {
  id: UUID;

  name: string;

  billingMode: BillingMode;

  address?: Address;

  billingAddress: Address | undefined;

  phone?: PhoneNumber;

  facilities: Facility[];

  blocked: boolean;

  createdAt: Date;

  updatedAt: Date;
}
