import { FileDepositSearchParams, SerializedFileDepositSearchParams } from '../file-deposit-search-params';
import { Facility } from '@domain/facility';
import { FileDeposit, isFileDepositState } from '@domain/file-deposit';
import { isValidObject } from '@application/framework/lib';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { isMaybeACustomer, isValidCustomerIdType } from '@application/bundles/customer';
import { keys } from '@domain/lib';
import { Customer } from '@domain/customer';

export class AgnosticFileDepositSearchParams implements FileDepositSearchParams {
  private customerId: Customer['id'] | undefined;

  private facilities: Array<Facility['id']> | undefined;

  private states: FileDeposit['state'] | Array<FileDeposit['state']> | undefined;

  public setCustomer(customer: Customer | Customer['id']): this {
    if (isValidCustomerIdType(customer)) {
      this.customerId = customer;
    } else if (isMaybeACustomer(customer)) {
      this.customerId = customer.id;
    }

    return this;
  }

  public setFacilities(ids: Array<Facility['id']> | null): this {
    this.facilities = Array.isArray(ids) ? ids : undefined;
    return this;
  }

  public setStates(state: FileDeposit['state'] | Array<FileDeposit['state']>): this {
    this.states = undefined;
    if (Array.isArray(state) || isFileDepositState(state)) {
      this.states = state;
    }
    return this;
  }

  public deserialize(params?: SerializedFileDepositSearchParams): FileDepositSearchParams {
    const searchParams = new AgnosticFileDepositSearchParams();

    if (isValidObject(params)) {
      if (isValidCustomerIdType(params.customerId)) {
        searchParams.setCustomer(params.customerId);
      }

      if (params?.facilities) {
        if (Array.isArray(params?.facilities)) {
          searchParams.setFacilities(params.facilities);
        } else if (isValidFacilityIdType(params.facilities)) {
          searchParams.setFacilities([params.facilities]);
        }
      }

      if (isValidObject(params.search)) {
        if (params.search?.states) {
          if (Array.isArray(params.search.states) || isFileDepositState(params?.search.states)) {
            searchParams.setStates(params.search.states);
          }
        }
      }
    }

    return searchParams;
  }

  public serialize(): SerializedFileDepositSearchParams {
    const serialized: SerializedFileDepositSearchParams = {};

    if (isValidCustomerIdType(this.customerId)) {
      serialized.customerId = this.customerId;
    }

    if (Array.isArray(this.facilities)) {
      serialized.facilities = this.facilities;
    }
    const search: SerializedFileDepositSearchParams['search'] = {};

    if (this.states) {
      search.states = this.states;
    }

    if (keys(search).length > 0) {
      serialized.search = search;
    }

    return serialized;
  }
}
