import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NoticeStream, NoticeStreamLibrary} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {NoticeStreamImpl} from "@implementations/framework/notice/notice-stream.impl";
import {NoticeStreamLibraryImpl} from "@implementations/framework/notice/notice-stream.library";
import {NoticeComponent} from './components/notice/notice.component';
import {ToastComponent} from './components/toast/toast.component';


@NgModule({
  declarations: [
    ToastComponent,
    NoticeComponent
  ],
  imports: [
    CommonModule,
    TranslationModule
  ],
  providers: [
    {
      provide: NoticeStream,
      useClass: NoticeStreamImpl,
    },
    {
      provide: NoticeStreamLibrary,
      useClass: NoticeStreamLibraryImpl,
      deps: [NoticeStream, Translator]
    }
  ],
  exports: [ToastComponent]
})
export class NoticeModule {
}
