<form (submit)="submit()" *ngIf="form" [formGroup]="form" noValidate>

  <ehp-form-field>
    <label for="name">{{'Nom du client' | ehp_translate }}</label>
    <input ehp-form-control formControlName="name" id="name" type="text">
  </ehp-form-field>

  <ehp-form-field>
    <label>{{'Adresse de correspondance' | ehp_translate }}</label>
    <ehp-address-input ehp-form-control formControlName="address"></ehp-address-input>
  </ehp-form-field>

  <ehp-form-field>
    <label>{{'Mode de facturation' | ehp_translate }}</label>
    <ehp-select [options]="billingModeOptions" [required]="true" ehp-form-control
                formControlName="billingMode"></ehp-select>
  </ehp-form-field>

  <ehp-form-field *ngIf="displayBillingAddress">
    <label>{{'Adresse de facturation' | ehp_translate }}</label>
    <ehp-address-input ehp-form-control formControlName="billingAddress"></ehp-address-input>

  </ehp-form-field>

  <ehp-form-field>
    <label for="phone">{{'Numéro de téléphone' | ehp_translate }}</label>
    <ehp-phone-input ehp-form-control formControlName="phone" input-id="phone"></ehp-phone-input>
  </ehp-form-field>

  <div class="actions">
    <button [disabled]="!form.valid" class="primary">{{ buttonLabel | ehp_translate }}</button>
  </div>

</form>
