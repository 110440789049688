import {Bundle} from "@application/framework/bundle";
import {Service} from "@application/framework/di";
import {
  CreateEPRDCommandHandler,
  CreateERRDCommandHandler,
  DeleteEPRDCommandHandler,
  DeleteERRDCommandHandler,
  UpdateEPRDCommandHandler,
  UpdateERRDCommandHandler
} from "@application/bundles/eprd-errd/commands/handlers";
import {
  OnERRDCreationEventSubscriber,
  OnERRDDeletionEventSubscriber,
  OnERRDUpdateEventSubscriber
} from "@application/bundles/eprd-errd/events/subscribers";
import {
  GetEPRDQueryHandler,
  ListEPRDForYearQueryHandler,
  ListEPRDQueryHandler,
  ListERRDForYearQueryHandler,
  ListERRDQueryHandler
} from "@application/bundles/eprd-errd/queries/handlers";

@Bundle({
  commandHandlers: [
    CreateEPRDCommandHandler,
    UpdateEPRDCommandHandler,
    DeleteEPRDCommandHandler,
    CreateERRDCommandHandler,
    UpdateERRDCommandHandler,
    DeleteERRDCommandHandler
  ],
  queryHandlers: [
    ListEPRDQueryHandler,
    GetEPRDQueryHandler,
    ListERRDQueryHandler,
    GetEPRDQueryHandler,
    ListEPRDForYearQueryHandler,
    ListERRDForYearQueryHandler
  ],
  eventSubscribers: [
    OnERRDCreationEventSubscriber,
    OnERRDUpdateEventSubscriber,
    OnERRDDeletionEventSubscriber
  ]
})
@Service()
export class EPRDERRDBundle {
}
