import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export interface SerializedEhpadDemainDocumentParams extends SerializedDocumentParams {
}

export abstract class EhpadDemainListParams extends DocumentListParams implements Serializable<SerializedEhpadDemainDocumentParams> {

}


