import {CreateFacilityDto, UpdateFacilityDto} from "@application/bundles/facility/dto";
import {FacilitySearchParams} from "@application/bundles/facility/facility-search-params";
import {Facility} from "@domain/facility/facility.interface";

export abstract class FacilityRepository {

  public abstract list(params?: FacilitySearchParams): Promise<Facility[]>;

  public abstract get(id: Facility['id']): Promise<Facility>;

  public abstract create(facility: CreateFacilityDto): Promise<Facility>;

  public abstract update(id: Facility['id'], update: UpdateFacilityDto): Promise<Facility>;
  
  public abstract delete(id: Facility['id']): Promise<Facility>;

}
