import { AsyncTransformer } from '@application/framework/core';
import { UpdateCurrentUser, UpdateUser } from '@domain/user';
import { EasyUpdateUser } from '@implementations/bundles/backend/easyhpad/interfaces';
import { EasyUserCommonTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-user-common.transformer';
import { Role } from '@domain/authorization';

export class EasyUpdateUserTransformer implements AsyncTransformer<UpdateUser, EasyUpdateUser> {
  public constructor(private readonly commonTransformer: EasyUserCommonTransformer) {}

  public async transform(value: UpdateCurrentUser | UpdateUser): Promise<EasyUpdateUser> {
    let serialized: any = await this.commonTransformer.transform(value as any);

    if ('password' in value) {
      serialized = { ...serialized, password: value.password };
    }

    if ('roles' in value && Array.isArray(value.roles) && value.roles.length > 0) {
      serialized.roles = (value.roles as Role[]).map(role => role.name);
    } else {
      serialized.roles = undefined;
    }

    return serialized;
  }

  public async reverseTransform(value: EasyUpdateUser): Promise<UpdateUser> {
    const common = await this.commonTransformer.reverseTransform(value);
    return {
      ...common,
      id: undefined as any,
      roles: Array.isArray(value.roles) ? value.roles.map(name => ({ name, label: name }) as Role) : [],
    };
  }
}
