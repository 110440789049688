import { Bundle } from '@application/framework/bundle';
import {
  ListLatestCostByEtpIndicatorsQueryHandler,
  ListLatestForecastCostByEtpQueryHandler,
} from '@application/bundles/etp/queries/handlers';

@Bundle({
  queryHandlers: [ListLatestCostByEtpIndicatorsQueryHandler, ListLatestForecastCostByEtpQueryHandler],
})
export class ETPBundle {}
