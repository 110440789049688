import {Logger} from "@application/framework/logger";
import {SanitizerLibrary, SanitizerRegistry} from "@application/framework/sanitizer/sanitizer";

export class AgnosticSanitizer implements SanitizerLibrary {

  private logger: Logger;

  public constructor(private registry: SanitizerRegistry, logger: Logger) {
    this.logger = logger.channel('SanitizerLibrary');
  }

  public async sanitize<T, R = T>(object: T): Promise<R> {
    const sanitizers = this.registry.get(object);

    if (sanitizers?.size === 0) {
      this.logger.debug(`0 sanitizer provided for ${Object.getPrototypeOf(object).constructor.name}`);
      return Promise.resolve(object as unknown as R);
    }

    for (const sanitizer of sanitizers) {
      object = await sanitizer(object) as any;
    }

    return object as unknown as R;
  }

}
