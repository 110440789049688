import { Component, OnDestroy, OnInit } from '@angular/core';
import { Year } from '@domain/lib';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { Subscription } from 'rxjs';
import { selectDashboardFacilities } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.selector';
import { Facility } from '@domain/facility';
import { QueryBus } from '@application/framework/command-query';
import { GetFundingWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-funding-widget.query';
import {
  FundingWidget,
  FundingWidgetYearItem,
} from '@easyhpad-ui/app/bundles/dashboard/interfaces/widgets/funding-widget';
import { isValidFundingAmount } from '@domain/funding';
import { HealthFundingUrlsProvider } from '@easyhpad-ui/app/bundles/health-funding/providers/url-provider/health-funding-urls.provider';
import { DependencyFundingUrlsProvider } from '@easyhpad-ui/app/bundles/dependency-funding/providers/url-provider/dependency-funding-urls.provider';
import { AccommodationFundingUrlsProvider } from '@easyhpad-ui/app/bundles/accommodation-funding/providers';
import { TranslatableString } from '@application/framework/translation';
import { FundingWidgetDataItem } from '@easyhpad-ui/app/bundles/dashboard/modules/widgets/contracts';

@Component({
  selector: 'ehp-widget-funding',
  templateUrl: './widget-funding.component.html',
  styleUrls: ['./widget-funding.component.scss'],
})
export class WidgetFundingComponent implements OnInit, OnDestroy {
  public readonly currentYear: Year;

  public readonly previousYear: Year;

  public readonly label = 'Financements {{ year }}';

  public isSingle: boolean = true;

  public actions: { health?: string; dependency?: string; accommodation?: string } = {};

  public readonly data: Map<Year, FundingWidgetDataItem<any>[]> = new Map();

  private subscription: Subscription | undefined;

  constructor(
    private readonly store: Store<AppState>,
    private readonly queryBus: QueryBus,
    private readonly healthUrls: HealthFundingUrlsProvider,
    private readonly dependencyUrls: DependencyFundingUrlsProvider,
    private readonly accommodationUrls: AccommodationFundingUrlsProvider,
  ) {
    this.currentYear = new Date().getFullYear();
    this.previousYear = this.currentYear - 1;

    this.healthUrls.getPublicCreate().then(url => (this.actions.health = url));
    this.dependencyUrls.getPublicCreate().then(url => (this.actions.dependency = url));
    this.accommodationUrls.getPublicCreate().then(url => (this.actions.accommodation = url));
  }

  public ngOnInit() {
    this.subscription = this.store.select(selectDashboardFacilities).subscribe(facilities => {
      this.facilitiesChange(facilities);
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private facilitiesChange(facilities: Array<Facility['id']>) {
    this.queryBus.request<FundingWidget>(new GetFundingWidgetQuery(facilities)).then(widget => {
      this.isSingle = facilities.length <= 1;
      this.data.set(widget.current.year, this.buildData(widget.current));
      this.data.set(widget.previous.year, this.buildData(widget.previous));
    });
  }

  private buildData(item: FundingWidgetYearItem): Array<FundingWidgetDataItem<any>> {
    return [
      this.createHealthDataItem(item),
      this.createDependencyDataItem(item),
      this.createAccommodationDataItem(item),
    ];
  }

  private createHealthDataItem(values: FundingWidgetYearItem): FundingWidgetDataItem<'health'> {
    return {
      type: 'health',
      label: new TranslatableString('Soin'),
      forecast: {
        isEmpty: !isValidFundingAmount(values.health?.total),
        amount: values.health?.total || 0,
        details:
          typeof values.health?.recurring === 'number'
            ? [
                {
                  label: 'Dont reconductible',
                  amount: values.health.recurring,
                },
              ]
            : [],
      },
      real: {
        isEmpty: true,
        amount: 0,
        details: [],
      },
    };
  }

  private createDependencyDataItem(values: FundingWidgetYearItem): FundingWidgetDataItem<'dependency'> {
    const apaDetail = (apa: number | null | undefined): Array<{ label: 'A.P.A.'; amount: number }> => {
      if (isValidFundingAmount(apa)) {
        return [{ label: 'A.P.A.', amount: apa }];
      }
      return [];
    };

    return {
      type: 'dependency',
      label: new TranslatableString('Dépendance'),
      forecast: {
        isEmpty: !isValidFundingAmount(values.dependency?.forecast),
        amount: values.dependency?.forecast || 0,
        details: apaDetail(values.dependency?.forecastApa),
      },
      real: {
        isEmpty: !isValidFundingAmount(values.dependency?.real),
        amount: values.dependency?.real || 0,
        details: apaDetail(values.dependency?.realApa),
      },
    };
  }

  private createAccommodationDataItem(values: FundingWidgetYearItem): FundingWidgetDataItem<'accommodation'> {
    return {
      type: 'accommodation',
      label: new TranslatableString('Hébergement'),
      forecast: {
        isEmpty: !isValidFundingAmount(values.accommodation?.forecast),
        amount: values.accommodation?.forecast || 0,
        details: [],
      },
      real: {
        isEmpty: !isValidFundingAmount(values.accommodation?.real),
        amount: values.accommodation?.real || 0,
        details: [],
      },
    };
  }
}
