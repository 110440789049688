import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GetHealthTaxonomyTermQuery, GetHealthTaxonomyTermResult } from '@application/bundles/health-funding';
import { HealthTaxonomyType } from '@domain/health-funding';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { TaxonomyTerm } from '@domain/taxonomy';
import { keys } from '@domain/lib';
import { joinPath } from '@application/framework/lib';
import { Logger } from '@application/framework/logger';

@HandleQuery({
  query: GetHealthTaxonomyTermQuery,
})
export class GetHealthTaxonomyTermQueryHandler implements QueryHandler<GetHealthTaxonomyTermQuery> {
  private base = 'funding/health/taxonomy';

  private paths: Record<HealthTaxonomyType, string> = {
    recurring: 'recurring',
    nonRecurring: 'non-recurring',
    exceptional: 'exceptional',
  };

  constructor(
    private readonly backend: EasyBackendApi,
    private readonly logger: Logger,
  ) {}

  public async handle(query: GetHealthTaxonomyTermQuery): Promise<GetHealthTaxonomyTermResult> {
    return this.backend
      .get<{ entity: TaxonomyTerm }>(joinPath(this.getPath(query.taxonomy), query.id))
      .then(response => response.body.entity)
      .catch(e => {
        this.logger.warning(e.message ?? e);
        return undefined;
      });
  }

  private getPath(type: HealthTaxonomyType): string {
    if (!keys(this.paths).includes(type)) {
      throw new Error(`Error on health taxonomy terms load. The taxonomy "${type}" is not a health taxonomy.`);
    }

    return joinPath(this.base, this.paths[type]);
  }
}
