import {Command} from "@application/framework/command-query";
import {CapacityAuthorization} from "@domain/capacity-authorization";

export class OpenDeleteCapacityAuthorizationDialogCommand implements Command<void> {


  constructor(public readonly authorization: CapacityAuthorization, public readonly onDeletion?: (authorization: CapacityAuthorization) => void) {
  }

}
