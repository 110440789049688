import { AsyncTransformer } from '@application/framework/core';
import { Facility } from '@domain/facility';
import { EasyFacility } from '@implementations/bundles/backend/easyhpad/interfaces';
import { AbstractEasyFacilityTransformer } from '@implementations/bundles/backend/easyhpad/transformers/facility/abstract-facility.transformer';

export class EasyFacilityTransformer
  extends AbstractEasyFacilityTransformer
  implements AsyncTransformer<Facility, EasyFacility>
{
  public async transform(value: Facility): Promise<EasyFacility> {
    const defaults = await this.toEasyFacility(value);

    return {
      ...defaults,
      id: value.id,
      created_at: value.createdAt,
      updated_at: value.updatedAt,
      state: value.state,
      address: this.addressTransformer.transform(value.address),
    };
  }

  public async reverseTransform(value: EasyFacility): Promise<Facility> {
    const defaults = await this.fromEasyFacility(value);
    return {
      ...defaults,
      id: value.id,
      createdAt: value.created_at,
      updatedAt: value.updated_at,
      state: value.state,
      address: this.addressTransformer.reverseTransform(value.address),
    };
  }
}
