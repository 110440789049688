import {StudyLevel} from "@domain/user/study-level.interface";
import {StudyLevelRepository} from "@domain/user/study-level.repository";
import {NotFoundError} from "rxjs";

export class StudyLevelRepositoryImpl extends StudyLevelRepository {


  private levels: StudyLevel[] = [
    {id: "bac", level: "Niveau bac ou équivalent"},
    {id: "bac_3", level: "Entre Bac et Bac +3"},
    {id: "bac_3_5", level: "Entre Bac+3 et Bac +5"},
    {id: "bac_5_plus", level: "+ de Bac +5"}
  ];

  public async list(): Promise<StudyLevel[]> {
    return [...this.levels];
  }

  public async get(id: StudyLevel["id"]): Promise<StudyLevel> {
    const level = this.levels.find(level => level.id === id);

    if (!level) {
      throw new NotFoundError(`Study level with id ${id} is not found`);
    }

    return {id: level.id, level: level.level};
  }

}
