import {Facility} from "@domain/facility";
import {Sort} from "@application/framework/search/sort";


export interface PaginationParams {
  limit: number;

  start?: number;
}

export interface SerializedParams<P = any, S extends string = string> {

  pagination?: PaginationParams;

  dates?: { start: Date, end?: Date }

  sort?: Sort<S>;

  params?: P;
}

export interface SerializedDocumentParams<P = any> extends SerializedParams<P> {
  facilities?: Array<Facility['id']>;

}

export abstract class DocumentListParams<S extends string = string> {

  public abstract setFacilities(...facilities: Array<Facility['id'] | Facility>): this;

  public abstract setPagination(pagination: PaginationParams | null): this;

  public abstract setSort(option: Sort<S> | null | undefined): this;

  public abstract setDates(start: Date | null | undefined, end?: Date | null | undefined): this;

  public abstract serialize(): SerializedDocumentParams;

  public abstract deserialize(params?: SerializedDocumentParams): this;
}


export abstract class DocumentSearchParamsFactory<P extends DocumentListParams, S = SerializedDocumentParams<any>> {

  public abstract create(): P;


  public abstract fromSerialized(serialized: S): P;
}




