import {ForbiddenError} from "@application/bundles/authorization/error";
import {
  AccommodationFundingDeletedEvent,
  AccommodationFundingDeletionFailEvent,
  AccommodationFundingRepository,
  DeleteAccommodationFundingCommand
} from "@application/bundles/accommodation-funding";
import {
  AccommodationFundingAuthorizationChecker
} from "@application/bundles/accommodation-funding/accommodation-funding-authorization-checker";
import {
  ACCOMMODATION_FUNDING_TRANSLATE_CONTEXT
} from "@application/bundles/accommodation-funding/accommodation-funding.token";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {TranslatableString} from "@application/framework/translation";
import {AccommodationFunding} from "@domain/accommodation-funding";


@HandleCommand({
  command: DeleteAccommodationFundingCommand
})
export class DeleteAccommodationFundingCommandHandler implements CommandHandler<DeleteAccommodationFundingCommand, AccommodationFunding> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private repository: AccommodationFundingRepository,
    private authorization: AccommodationFundingAuthorizationChecker,
    private eventDispatcher: EventDispatcher,
  ) {
  }

  public async handle(command: DeleteAccommodationFundingCommand): Promise<AccommodationFunding> {

    const {funding} = command;
    try {
      const deleted = await this.checkAccess(funding)
        .then(() => this.repository.delete(funding.id));

      this.eventDispatcher.dispatch(new AccommodationFundingDeletedEvent(deleted));

      return deleted;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }


  private async checkAccess(funding: AccommodationFunding): Promise<AccommodationFunding> {

    if (!await this.authorization.canDelete(funding)) {
      this.logger.error('AccommodationFunding deletion : Forbidden');
      throw new ForbiddenError();
    }

    return funding;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à supprimer ce Financement hébergement ..",
        undefined,
        ACCOMMODATION_FUNDING_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new AccommodationFundingDeletionFailEvent(message));

    return error;
  }

}
