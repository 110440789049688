import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {CustomerFormModule} from "@easyhpad-ui/app/bundles/customer/modules";
import {
  ConfirmAdministratorCreationDialogComponent,
  CurrentUserFormComponent,
  ForgottenPasswordFormComponent,
  UserEditFormComponent,
  UserLoginFormComponent,
  UserResetPasswordFormComponent,
  UserRolesInputComponent
} from "./components";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {FormModule} from "@easyhpad-ui/app/library/form/form.module";
import {FacilityFormModule} from "@easyhpad-ui/app/bundles/facility/modules/form/facility-form.module";

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    ReactiveFormsModule,
    FormModule,
    CustomerFormModule,
    FacilityFormModule
  ],
  declarations: [
    UserLoginFormComponent,
    ForgottenPasswordFormComponent,
    UserEditFormComponent,
    UserRolesInputComponent,
    UserResetPasswordFormComponent,
    ConfirmAdministratorCreationDialogComponent,
    CurrentUserFormComponent
  ],
  exports: [
    UserLoginFormComponent,
    ForgottenPasswordFormComponent,
    UserEditFormComponent,
    UserResetPasswordFormComponent,
    ConfirmAdministratorCreationDialogComponent,
    CurrentUserFormComponent
  ]
})
export class UserFormModule {
}
