import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Translator } from '@application/framework/translation';
import { MediaModule } from '@easyhpad-ui/app/bundles/media/media.module';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import {
  DynamicDateInputFieldComponent,
  DynamicFloatInputFieldComponent,
  DynamicFormComponent,
  DynamicFormElementComponent,
  DynamicFormGroupComponent,
  DynamicFormPanelsComponent,
  DynamicInputFieldComponent,
  DynamicMediaFieldComponent,
  DynamicNoticeListComponent,
  DynamicSelectFieldComponent,
  DynamicStepperComponent,
} from '@easyhpad-ui/app/library/form/components/dynamics';
import { DynamicTaxonomyTreeSelectorComponent } from '@easyhpad-ui/app/library/form/components/dynamics/dynamic-taxonomy-tree-selector/dynamic-taxonomy-tree-selector.component';
import { FormErrorsComponent } from '@easyhpad-ui/app/library/form/components/form-errors/form-errors.component';
import { PasswordUpdaterComponent } from '@easyhpad-ui/app/library/form/components/password-updater/password-updater.component';
import { TaxonomyTreeSelectorComponent } from '@easyhpad-ui/app/library/form/components/taxonomy-tree-selector/taxonomy-tree-selector.component';
import { ToggleComponent } from '@easyhpad-ui/app/library/form/components/toggle/toggle.component';
import { FORM_ERROR_DICTIONARY } from '@easyhpad-ui/app/library/form/form.token';
import { PanelModule } from '@easyhpad-ui/app/library/panel/panel.module';
import { ErrorMessages } from '@implementations/forms/error-messages';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { ListboxModule } from 'primeng/listbox';
import { TreeSelectModule } from 'primeng/treeselect';
import { TranslationModule } from '../../framework/translation/translation.module';
import { AsyncSelectComponent } from './components/aync-select/async-select.component';
import { DateIntervalInputComponent } from './components/date-interval/date-interval-input.component';
import { FormArrayErrorsComponent } from './components/form-array-errors/form-array-errors.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { ListBoxComponent } from './components/list-box/list-box.component';
import { MediaUploaderComponent } from './components/media-uploader/media-uploader.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { SelectComponent } from './components/select/select.component';
import { YearChipsInputComponent } from './components/year-chips-input/year-chips-input.component';
import { FormControlDirective } from './directives/form-control/form-control.directive';
import { PrefixDirective } from './directives/prefix/prefix.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslationModule,
    ListboxModule,
    FormsModule,
    ChipsModule,
    MediaModule,
    CdkStepperModule,
    ReusableComponentsModule,
    DropdownModule,
    PanelModule,
    InputNumberModule,
    TreeSelectModule,
  ],
  declarations: [
    AsyncSelectComponent,
    ToggleComponent,
    FormFieldComponent,
    FormErrorsComponent,
    PrefixDirective,
    FormControlDirective,
    DateIntervalInputComponent,
    MediaUploaderComponent,
    ListBoxComponent,
    FormArrayErrorsComponent,
    PasswordInputComponent,
    PasswordUpdaterComponent,
    SelectComponent,
    YearChipsInputComponent,
    DynamicFormComponent,
    DynamicFormElementComponent,
    DynamicMediaFieldComponent,
    DynamicInputFieldComponent,
    DynamicSelectFieldComponent,
    DynamicDateInputFieldComponent,
    DynamicStepperComponent,
    DynamicFormPanelsComponent,
    DynamicFloatInputFieldComponent,
    TaxonomyTreeSelectorComponent,
    DynamicTaxonomyTreeSelectorComponent,
    DynamicFormGroupComponent,
    DynamicNoticeListComponent,
  ],
  providers: [
    {
      provide: FORM_ERROR_DICTIONARY,
      useClass: ErrorMessages,
      deps: [Translator],
    },
  ],
  exports: [
    AsyncSelectComponent,
    ToggleComponent,
    DateIntervalInputComponent,
    FormFieldComponent,
    FormErrorsComponent,
    FormControlDirective,
    MediaUploaderComponent,
    ListBoxComponent,
    FormArrayErrorsComponent,
    PasswordInputComponent,
    PasswordUpdaterComponent,
    SelectComponent,
    YearChipsInputComponent,
    DynamicFormComponent,
    DynamicFormElementComponent,
    DynamicFloatInputFieldComponent,
    TaxonomyTreeSelectorComponent,
    DynamicFormGroupComponent,
    DynamicDateInputFieldComponent,
  ],
})
export class FormModule {}
