import { CreateGMPDto, GMPRepository, GMPSearchParams, UpdateGMPDto } from '@application/bundles/gmp';
import { Provide, Service } from '@application/framework/di';
import { EasyCreateGmpDtoTransformer } from '@implementations/bundles/backend/easyhpad/transformers/gmp/easy-create-gmp-dto.transformer';
import { EasyGmpTransformer } from '@implementations/bundles/backend/easyhpad/transformers/gmp/easy-gmp.transformer';
import { EasyUpdateGmpDtoTransformer } from '@implementations/bundles/backend/easyhpad/transformers/gmp/easy-update-gmp-dto.transformer';
import { DocumentRepository } from '@implementations/bundles/backend/easyhpad/repositories/base/document.repository.abstract';
import { GMP } from '@domain/gmp';
import { EasyGMP } from '@implementations/bundles/backend/easyhpad';
import { Facility } from '@domain/facility';

@Service({
  id: GMPRepository,
})
export class EasyGMPRepository extends DocumentRepository<GMPSearchParams, any, GMP, EasyGMP> implements GMPRepository {
  @Provide() private readonly entityTransformer!: EasyGmpTransformer;

  @Provide() private readonly createTransformer!: EasyCreateGmpDtoTransformer;

  @Provide() private readonly updateTransformer!: EasyUpdateGmpDtoTransformer;

  constructor() {
    super('gmp', {
      from: input => this.entityTransformer.transform(input),
      to: output => this.entityTransformer.reverseTransform(output),
      create: (input: CreateGMPDto) => this.createTransformer.transform(input),
      update: (input: UpdateGMPDto) => this.updateTransformer.transform(input),
    });
  }

  public listLatest(params?: GMPSearchParams): Promise<GMP[]> {
    return this.backend
      .get<{ entities: EasyGMP[] }>(`${this.basePath}/latest`, this.convertParams(params))
      .then(response => this.parseResponse(response))
      .then(body => Promise.all(body.entities.map(e => this.toEntity(e))));
  }

  public getLatestForFacility(facilityId: Facility['id']): Promise<GMP | undefined> {
    return this.backend
      .get<{ latest: EasyGMP | null }>(`facility/${facilityId}/gmp/latest`)
      .then(response => this.parseResponse(response))
      .then(body => (body.latest ? this.toEntity(body.latest) : undefined));
  }
}
