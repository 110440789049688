import {
  DependencyFundingItem,
  TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  TEMPORARY_DEPENDENCIES_FUNDING_MODES,
  TemporaryDependencyFundingMode
} from "@domain/dependency-funding";
import {GroupedGIRAmount} from "@domain/gir";


export interface TemporaryDependencyFunding<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES, V> extends DependencyFundingItem<TemporaryDependencyFundingMode<M>, V> {
  id: number;

  activityAllowance: number | undefined;

}

export interface TemporaryDependencyAsPermanentFunding extends TemporaryDependencyFunding<TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES.SAME_PERMANENT, GroupedGIRAmount> {
}

