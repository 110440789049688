import { UpdateGMPDto } from '@application/bundles/gmp';
import { Transformer } from '@application/framework/core';
import { Service } from '@application/framework/di';
import { EasyGMPUpdateDto } from '@implementations/bundles/backend/easyhpad';
import { EasyGMPCommonTransformer } from '@implementations/bundles/backend/easyhpad/transformers/gmp/easy-gmp-common.transformer';
import { UpdateGMPDtoImpl } from '@implementations/bundles/gmp/dto/update-gmp.dto';

@Service()
export class EasyUpdateGmpDtoTransformer implements Transformer<UpdateGMPDto, EasyGMPUpdateDto> {
  constructor(private readonly transformer: EasyGMPCommonTransformer) {}

  public transform(value: UpdateGMPDto): EasyGMPUpdateDto {
    return {
      ...this.transformer.transform(value),
      pvId: value.pvId,
    };
  }

  public reverseTransform(value: EasyGMPUpdateDto): UpdateGMPDto {
    return new UpdateGMPDtoImpl({
      ...this.transformer.reverseTransform(value),
      pvId: value.pvId,
    });
  }
}
