import { AuthentificationToken } from '@application/bundles/authentification';
import { Permission, Role } from '@domain/authorization';

export interface StoreSerializedAccount extends Omit<AuthentificationToken, 'provider' | 'roles' | 'permissions'> {
  readonly provider: string;

  /**
   * Get roles associated to this token
   */
  readonly roles: Role[];

  /**
   * Get permission collection associated to this token
   */
  readonly permissions: Permission[];
}

export interface AuthentificationStoreState {
  /**
   * Determine if the system has tried to auto reconnect the user.
   */
  checked: boolean;

  /**
   * Determine if the user is logged.
   */
  logged: boolean;

  /**
   * Logged account
   */
  account: StoreSerializedAccount | null;
}
