import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isValidCustomerIdType } from '@application/bundles/customer';
import { FacilityAuthorizationChecker } from '@application/bundles/facility/authorization-checker';
import { CreateFacilityDto, UpdateFacilityDto } from '@application/bundles/facility/dto';
import { isEmptyContact, isFacilityState } from '@application/bundles/facility/validators';
import { Common } from '@application/framework/lib';
import { Facility, FACILITY_STATE, FinessNumber } from '@domain/facility';
import { Contact } from '@domain/facility/facility-contact.interface';

import { finessNumberValidator } from '@easyhpad-ui/app/bundles/facility/form/validators/finess-number.validator';
import { isSelectOption, SelectOption } from '@implementations/forms';

type CommonProperties = Common<CreateFacilityDto, UpdateFacilityDto>;

type EditForm = FormGroup<Record<keyof UpdateFacilityDto, AbstractControl>>;

@Injectable({
  providedIn: 'root',
})
export class FacilityFormBuilder {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly facilityAuthorizationChecker: FacilityAuthorizationChecker,
  ) {}

  public async getCreationForm(): Promise<FormGroup<Record<keyof CreateFacilityDto, AbstractControl>>> {
    return this.formBuilder.group<Record<keyof CreateFacilityDto, AbstractControl>>(this.getCommonFormControl());
  }

  public async getUpdateForm(facility?: Facility): Promise<EditForm> {
    return this.formBuilder.group(this.getCommonFormControl(facility));
  }

  public getFormValuesFromFacility(facility?: Facility): Partial<Record<keyof Facility, any>> {
    const facilityValues: Partial<Record<keyof Facility, any>> = {};

    if (facility !== undefined) {
      const keys = Object.keys(facility) as Array<keyof Facility>;

      keys.forEach(key => {
        switch (key) {
          case 'finessGeo':
            facilityValues.finessGeo =
              facility.finessGeo instanceof FinessNumber ? facility.finessGeo.formatted : undefined;
            break;
          case 'finessLegal':
            facilityValues.finessLegal =
              facility.finessLegal instanceof FinessNumber ? facility.finessLegal.formatted : undefined;
            break;
          case 'customerId':
            if (isValidCustomerIdType(facility.customerId)) {
              facilityValues.customerId = facility.customerId;
            } else if (typeof facility.customerId === 'object' && facility.customerId['id']) {
              facilityValues.customerId = facility.customerId['id'];
            }
            break;
          case 'contact':
            if (!isEmptyContact(facility.contact)) {
              facilityValues.contact = this.transformFacilityContactToFormValue(facility.contact);
            }
            break;
          case 'phone':
            facilityValues.phone = facility.phone ? facility.phone.toString() : undefined;
            break;
          default:
            try {
              facilityValues[key] = structuredClone(facility[key]);
            } catch (e) {
              console.error(key, e);
            }
        }
      });
    }

    return facilityValues;
  }

  public getFacilityValuesFromForm(values: Partial<Record<keyof Facility, any>>): Partial<Facility> {
    const facility: Partial<Facility> = {};

    Object.keys(values).forEach(p => {
      if (p === 'type' && values[p]) {
        if (isSelectOption(values[p])) {
          values[p] = (values[p] as SelectOption).value;
        } else {
          values[p] = values[p];
        }
      }

      if (p === 'socialClearance' && values[p]) {
        if (isSelectOption(values[p])) {
          values[p] = (values[p] as SelectOption).value;
        } else {
          values[p] = values[p];
        }
      }

      if (p === 'finessGeo' && typeof values[p] === 'string') {
        values[p] = new FinessNumber(values[p]);
      }

      if (p === 'finessLegal' && typeof values[p] === 'string') {
        values[p] = new FinessNumber(values[p]);
      }

      if (
        p === 'customerId' &&
        typeof values[p] !== 'string' &&
        typeof values[p] !== 'number' &&
        typeof values[p]['id'] !== 'undefined'
      ) {
        values[p] = values[p]['id'];
      }

      if (p === 'state') {
        const state: any = values[p];

        if (isSelectOption<FACILITY_STATE>(state) && isFacilityState(state.value)) {
          values[p] = state.value;
        } else if (isFacilityState(state)) {
          values[p] = state;
        }
      }

      facility[p as keyof Facility] = values[p as keyof Facility];
    });

    return facility;
  }

  public addValuesInForm(form: FormGroup, values: Partial<Record<keyof Facility, any>>): void {
    Object.keys(values).map(p => {
      const control = form.get(p);
      const value = values[p as keyof Facility];
      if (p === 'contact' && control && value) {
        Object.keys(value).forEach(k => {
          const subControl = control.get(k);

          if (subControl) {
            subControl.setValue(value[k]);
          }
        });
      } else if (control) {
        control.setValue(value);
      }
    });
  }

  private getCommonFormControl(facility?: Facility): Record<keyof CommonProperties, AbstractControl> {
    const values = this.getFormValuesFromFacility(facility);

    const controls = {
      name: new FormControl(values?.name, [Validators.required]),
      type: new FormControl(values?.type, [Validators.required]),
      state: new FormControl(values?.state),
      socialClearance: new FormControl(values?.socialClearance, [Validators.required]),
      address: new FormControl(values?.address, [Validators.required]),
      customerId: new FormControl(values?.customerId, [Validators.required]),
      finessGeo: new FormControl(values?.finessGeo, {
        validators: [finessNumberValidator, Validators.required],
        updateOn: 'blur',
      }),
      finessLegal: new FormControl(values?.finessLegal, {
        validators: [finessNumberValidator, Validators.required],
        updateOn: 'blur',
      }),
      managingOrganization: new FormControl(values?.managingOrganization),
      phone: new FormControl(values?.phone),
      contact: this.formBuilder.group({
        id: new FormControl(values?.contact?.id),
        name: new FormControl(values?.contact?.name),
        email: new FormControl(values?.contact?.email, [Validators.email]),
        job: new FormControl(values?.contact?.job),
        phone: new FormControl(values?.contact?.phone),
      }),
    };

    controls.finessGeo.valueChanges.subscribe(value => this.formatFiness(controls.finessGeo, value));
    controls.finessLegal.valueChanges.subscribe(value => this.formatFiness(controls.finessLegal, value));

    return controls;
  }

  private formatFiness(control: AbstractControl, value: any): void {
    if (!control || typeof value !== 'string' || value === '') {
      return;
    }

    const formatted = FinessNumber.format(value);

    if (formatted !== value) {
      control.setValue(formatted);
    }
  }

  private transformFacilityContactToFormValue(contact: Facility['contact']): Record<keyof Contact, any> {
    return {
      id: contact?.id,
      name: contact?.name,
      email: contact?.email || '',
      phone: contact?.phone,
      job: contact?.job,
    };
  }
}
