import { ETPCategoryCollection } from '@application/bundles/etp/etp-category-collection';
import { ETP_CATEGORIES } from '@domain/etp/etp-categories';
import { ETPCategory } from '@domain/etp';

export class AgnosticTPERCollection implements ETPCategoryCollection {
  public readonly collection: Map<ETP_CATEGORIES, ETPCategory> = new Map([
    [ETP_CATEGORIES.DIRECTION, { type: ETP_CATEGORIES.DIRECTION, label: 'Direction - Administration' }],
    [ETP_CATEGORIES.COOKING, { type: ETP_CATEGORIES.COOKING, label: 'Cuisine - services généraux' }],
    [ETP_CATEGORIES.ANIMATION, { type: ETP_CATEGORIES.ANIMATION, label: 'Animation' }],
    [ETP_CATEGORIES.ASH, { type: ETP_CATEGORIES.ASH, label: 'A.S.H.' }],
    [ETP_CATEGORIES.SOCIAL_WORKER, { type: ETP_CATEGORIES.SOCIAL_WORKER, label: 'A.S.' }],
    [ETP_CATEGORIES.PSYCHOLOGIST, { type: ETP_CATEGORIES.PSYCHOLOGIST, label: 'Psychologue' }],
    [ETP_CATEGORIES.NURSE, { type: ETP_CATEGORIES.NURSE, label: 'I.D.E.' }],
    [ETP_CATEGORIES.MEDICAL_ASSISTANT, { type: ETP_CATEGORIES.MEDICAL_ASSISTANT, label: 'Auxiliaires médicaux' }],
    [ETP_CATEGORIES.MEDICAL_COORDINATOR, { type: ETP_CATEGORIES.MEDICAL_COORDINATOR, label: 'Médecin coordonnateur' }],
    [ETP_CATEGORIES.OTHER, { type: ETP_CATEGORIES.OTHER, label: 'Autre' }],
  ]);

  public has(key: ETP_CATEGORIES): boolean {
    return this.collection.has(key);
  }

  public get(key: ETP_CATEGORIES): ETPCategory {
    return this.collection.get(key) || { type: key, label: key };
  }

  public list(): ETPCategory[] {
    return Array.from(this.collection.values());
  }
}
