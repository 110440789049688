import {DEPENDENCIES_FUNDING_MODES, DependencyFundingMode} from "@domain/dependency-funding/dependency-funding.types";
import {List} from "@domain/lib/list/list";

export enum DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES {
  SINGLE_GIR_AMOUNT = "unique_price",

  OTHER = "other",
}

export type DAYCARE_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES | DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES;


export const DaycareDependencyFundingModeValues = Object.freeze(new List([
  ...Object.values(DEPENDENCIES_FUNDING_MODES),
  ...Object.values(DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES)
]));

export interface DaycareDependencyFundingMode<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN> extends DependencyFundingMode<M> {
}
