import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PublicCurrentUserProfileComponent} from './pages';
import {UserFormModule} from "@easyhpad-ui/app/bundles/user/modules/form/user-form.module";
import {ReusableComponentsModule} from "@easyhpad-ui/app/bundles/reusable-components/reusable-components.module";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";


@NgModule({
  declarations: [
    PublicCurrentUserProfileComponent
  ],
  imports: [
    CommonModule,
    UserFormModule,
    ReusableComponentsModule,
    TranslationModule
  ]
})
export class UserPublicModule {
}
