import {
  isValidEhpadDemainHealthFundingIdType,
  UpdateEhpadDemainHealthFundingDto
} from "@application/bundles/health-funding";
import {
  CreateEhpadDemainHealthFundingDtoImpl
} from "@implementations/bundles/health-funding/dto/ehpad-demain-health-funding/create-ehpad-demain-health-funding.dto";
import {EhpadDemainHealthFunding} from "@domain/health-funding";
import {isValidObject} from "@application/framework/lib";

export class UpdateEhpadDemainHealthFundingDtoImpl extends CreateEhpadDemainHealthFundingDtoImpl implements UpdateEhpadDemainHealthFundingDto {


  public id!: EhpadDemainHealthFunding['id'];

  constructor(defaults?: Partial<UpdateEhpadDemainHealthFundingDto>) {
    super(defaults);

    if (isValidObject(defaults) && isValidEhpadDemainHealthFundingIdType(defaults?.id)) {
      this.id = defaults?.id;
    }
  }
}
