import { Address } from '@domain/address';
import { Customer } from '@domain/customer';
import { Contact } from '@domain/facility/facility-contact.interface';
import { FinessNumber } from '@domain/facility/finess-number/finess-number';
import { FACILITY_STATE } from '@domain/facility/states';
import { FACILITY_TYPE, SOCIAL_CLEARANCE } from '@domain/facility/types';
import { UUID } from '@domain/lib';
import { PhoneNumber } from '@domain/phone/phone.interface';

export interface Facility {
  id: UUID;

  name: string;

  type: FACILITY_TYPE;

  socialClearance: SOCIAL_CLEARANCE;

  finessGeo?: FinessNumber;

  finessLegal?: FinessNumber;

  managingOrganization?: string;

  address: Address;

  phone?: PhoneNumber;

  customerId?: Customer['id'];

  contact?: Contact;

  state: FACILITY_STATE;

  createdAt?: Date;

  updatedAt?: Date;
}
