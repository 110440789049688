import {
  DispatchableEvent,
  EventSubscriber,
  EventSubscriberMetadata,
  EventSubscriberOptions,
  PrioritySortOption
} from "@application/framework/event";
import {EventSubscribersContainer} from "@application/framework/event/event-subscribers.container";
import {EventSubscribersRegistry} from "@application/framework/event/event-subscribers.registry";
import {getEmptySubscriberMetadata} from "@application/framework/event/functions/get-empty-subscriber-metadata";

type PrioritySortFn<T extends { metadata?: { priority?: number } }> = (a: T, b: T) => number;


export interface PrioritizedSubscriberListOptions {
  sort?: PrioritySortOption
}

export class PrioritizedSubscriberRegistry<T extends DispatchableEvent> extends EventSubscribersRegistry implements EventSubscribersRegistry {

  public isEmpty(): boolean {
    return this.containers.size === 0;
  }

  public has(event: DispatchableEvent): boolean {
    return this.containers.has(event.name);
  }

  public hasSubscribers<T extends DispatchableEvent>(event: T): boolean {
    return !this.getSubscriberContainer(event).isEmpty();
  }

  public get<T extends DispatchableEvent>(event: T, filter?: Partial<EventSubscriberOptions>): EventSubscriber<T>[] {

    let sort = PrioritySortOption.DESC;

    if (filter !== undefined && filter.sort !== undefined) {
      sort = filter.sort;
    }

    const container = this.getSubscriberContainer(event);

    return container.entries().sort(this.getPrioritySorter(sort)).map(({subscriber}) => subscriber);
  }

  public set<T extends DispatchableEvent>(event: T, subscriber: EventSubscriber<T>, metadata?: EventSubscriberMetadata): void {

    EventSubscribersRegistry.registerSubscriber(event, subscriber, metadata || getEmptySubscriberMetadata());
  }

  public remove<T extends DispatchableEvent>(event: T, subscriber?: EventSubscriber<T> | EventSubscriber<T>[]): void {

    if (subscriber === undefined) {
      this.containers.delete(event.name);
      return;
    }

    const container = this.getSubscriberContainer(event);
    subscriber = Array.isArray(subscriber) ? subscriber : [subscriber];

    subscriber.forEach(subscriber => container.remove(subscriber));

    if (container.isEmpty()) {
      this.containers.delete(event.name);
    } else {
      this.containers.set(event.name, container);
    }
  }

  private getPrioritySorter<T extends { metadata?: { priority?: number } }>(sort: PrioritySortOption): PrioritySortFn<T> {

    return (a: T, b: T): number => {

      const aP = a.metadata?.priority || 0;
      const bP = b.metadata?.priority || 0;

      if (aP === bP) {
        return 0;
      } else if (sort === PrioritySortOption.ASC) {
        return aP > bP ? -1 : 1;
      } else {
        return aP > bP ? 1 : -1;
      }
    }
  }

  private getSubscriberContainer<T extends DispatchableEvent>(event: T): EventSubscribersContainer<T> {
    return EventSubscribersRegistry.getSubscriberContainer<T>(event);
  }
}


/**
 *  public all(options?: PrioritizedSubscriberListOptions): { subscriber: EventSubscriber<T>, priority: number }[] {
 *
 *     const sort = options?.sort !== undefined ? options.sort : PrioritySortOption.DESC;
 *     const sorter = this.getPrioritySorter(sort);
 *
 *     return this.container.entries()
 *       .map(({subscriber, metadata}) => ({subscriber, priority: metadata.priority || 0}))
 *       .sort(sorter);
 *   }
 *
 *   public has(subscriber: EventSubscriber<T>): boolean {
 *     return this.container.has(subscriber);
 *   }
 *
 *   public get(priority?: number): EventSubscriber<T>[] {
 *
 *     if (priority !== undefined && Number.isInteger(priority)) {
 *       return this.searchByPriority(priority);
 *     }
 *
 *     return this.all().map(({subscriber}) => subscriber);
 *   }
 *
 *   public set(subscriber: EventSubscriber<T>, priority?: number): void {
 *     this.container.set(subscriber, {priority: priority || 0});
 *   }
 *
 *   public remove(subscriber: EventSubscriber<T>): void {
 *     this.container.remove(subscriber);
 *   }
 */
