import {TARIFF_OPTIONS, TariffOptionAmount} from "@domain/cpom/tariff-option";
import {Year} from "@domain/lib";

export abstract class TariffOptionAmountCollection {
  
  public abstract getCollection(type: TARIFF_OPTIONS): TariffOptionAmountCollection;

  public abstract getForYear(year: Year): Promise<TariffOptionAmount | undefined>;

}
