<ehp-form-field *ngIf="control" [prefix]="field.prefix" [suffix]="field.suffix">
  <label *ngIf="field.label" [for]="field.name">
    <ehp-translatable-string [value]="field.label"></ehp-translatable-string>
  </label>

  <p *ngIf="field.description" class="description">
    <ehp-translatable-string [value]="field.description"></ehp-translatable-string>
  </p>

  <p-inputNumber #input (onInput)="updateValue(value)" [(ngModel)]="value"
                 [locale]="locale"
                 [maxFractionDigits]="2" [minFractionDigits]="2" ehp-form-control mode="decimal"></p-inputNumber>

</ehp-form-field>
