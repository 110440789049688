import {Injectable} from "@angular/core";
import {joinPath} from "@application/framework/lib";
import {ActivityAnnexRepository} from "@application/bundles/activity-annex";
import {ActivityAnnex} from "@domain/activity-annex";
import {ActivityAnnexAuthChecker} from "@application/bundles/activity-annex/activity-annex-authorization-checker";

@Injectable()
export class ActivityAnnexUrlsProvider {

  public static readonly base = 'activity-annex';

  public static readonly publicSchema = {
    list: {path: '', title: "Annexes d'activité"},
    create: {path: 'create', title: "Créer une nouvelle annexe activité"},
    show: {path: ':id', title: "Annexe activité"},
    edit: {path: ':id/edit', title: "Modifier l'annexe activité"},
  }

  constructor(
    public readonly authorization: ActivityAnnexAuthChecker,
    private readonly repository: ActivityAnnexRepository
  ) {
  }

  public getPublicList(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList(): string {
    return joinPath('/', ActivityAnnexUrlsProvider.base);
  }

  public getPublicCreate(): Promise<string | undefined> {
    return this.authorization.canCreate()
      .then(can => can ? this.getUnsafePublicCreate() : undefined);
  }

  public getUnsafePublicCreate(): string {
    return joinPath('/', ActivityAnnexUrlsProvider.base, ActivityAnnexUrlsProvider.publicSchema.create.path);
  }

  public getPublicShow(id: ActivityAnnex['id']): Promise<string | undefined> {
    return this.authorization.canRead()
      .then(can => can ? this.getUnsafePublicShow(id) : undefined);
  }

  public getUnsafePublicShow(id: ActivityAnnex['id']): string {
    return joinPath('/', ActivityAnnexUrlsProvider.base, id.toString());
  }

  public async getPublicUpdate(id: ActivityAnnex['id']): Promise<string | undefined> {
    const gmp = await this.repository.get(id);

    return this.authorization.canUpdate(gmp)
      .then(can => can ? this.getUnsafePublicUpdate(id) : undefined);
  }

  public getUnsafePublicUpdate(id: ActivityAnnex['id']): string {
    return joinPath('/', ActivityAnnexUrlsProvider.base, id.toString(), 'edit');
  }

}
