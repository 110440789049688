import { Component, HostBinding, OnInit, ViewContainerRef } from '@angular/core';
import { CommandBus } from '@application/framework/command-query/command-bus.abstract';
import { BehaviorSubject, take } from 'rxjs';
import { PageTitleAccessor } from './framework/title/page-title.accessor';
import { StartApplicationCommand } from '@application/bundles/application/commands/start-application.command';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { Actions, ofType } from '@ngrx/effects';
import { CalculatorActions, CalculatorSelectors } from '@easyhpad-ui/app/bundles/calculator/store';
import { DraggableCalculatorComponent } from '@easyhpad-ui/app/bundles/calculator/components';
import { InitialCalculatorFactoryProps } from '@easyhpad-ui/app/bundles/calculator';
import { withLatestFrom } from 'rxjs/operators';
import { selectIsFacilityOnboarding } from '@easyhpad-ui/app/store/global.selector';
import {
  selectAuthentificationCheck,
  selectAuthentificationLogged,
  selectIsAdministratorAccount,
} from '@easyhpad-ui/app/bundles/authentification/store/authentification.selector';

@Component({
  selector: 'ehp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public authentificationChecked: boolean = false;

  public displayPrimaryMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public displayTopBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isAuthenticated: boolean = false;

  @HostBinding('class')
  public get classes(): string {
    const classes: string[] = [];

    if (this.isAuthenticated) {
      classes.push('authenticate');
    }

    if (this.displayPrimaryMenu$.value) {
      classes.push('has-primary-menu');
    }

    if (this.displayTopBar$.value) {
      classes.push('has-top-bar');
    }

    return classes.join(' ');
  }

  public constructor(
    private readonly commandBus: CommandBus,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly title: PageTitleAccessor,
  ) {
    this.refreshSubComponent = this.refreshSubComponent.bind(this);

    this.store.select(selectAuthentificationCheck).subscribe(checked => (this.authentificationChecked = checked));

    this.store.select(selectAuthentificationLogged).subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      this.refreshSubComponent();
    });

    this.store.select(selectIsAdministratorAccount).subscribe(this.refreshSubComponent);
    this.store.select(selectIsFacilityOnboarding).subscribe(this.refreshSubComponent);

    this.watchCalculator();
  }

  public ngOnInit(): void {
    this.commandBus.execute(new StartApplicationCommand());
    this.title.set('');
  }

  private watchCalculator() {
    this.actions
      .pipe(ofType(CalculatorActions.new), withLatestFrom(this.store.select(CalculatorSelectors.isOpen)))
      .subscribe(([action, isOpen]) => (isOpen ? this.openCalculator(action.props) : undefined));
  }

  private openCalculator(props?: InitialCalculatorFactoryProps): void {
    const ref = this.viewContainerRef.createComponent(DraggableCalculatorComponent);
    ref.changeDetectorRef.detectChanges();

    if (props) {
      if (props.initialPosition) {
        ref.instance.moveTo(props.initialPosition.x, props.initialPosition.y);
      }
    }

    const s = this.actions.pipe(ofType(CalculatorActions.close)).subscribe(() => {
      ref.destroy();
      this.store.dispatch(CalculatorActions.isOpen({ isOpen: false }));
      s.unsubscribe();
    });

    this.store.dispatch(CalculatorActions.isOpen({ isOpen: true }));
  }

  private refreshSubComponent() {
    this.store
      .select(selectIsAdministratorAccount)
      .pipe(withLatestFrom(this.store.select(selectIsFacilityOnboarding)), take(1))
      .subscribe(([isAdministrator, isOnboarding]) => {
        if (!this.isAuthenticated) {
          this.displayTopBar$.next(false);
          this.displayPrimaryMenu$.next(false);
          return;
        }

        if (isAdministrator) {
          this.displayPrimaryMenu$.next(true);
          this.displayTopBar$.next(false);
        } else {
          this.displayPrimaryMenu$.next(!isOnboarding);
          this.displayTopBar$.next(isOnboarding);
        }
      });
  }
}
