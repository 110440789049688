import {Attribute, DecisionManager} from "@application/bundles/authorization";
import {AuthorizationChecker} from "@application/bundles/authorization/abstractions/authorization.checker.abstract";
import {AuthentificationToken, AuthentificationTokenStore} from "@application/bundles/authentification";

export class AgnosticAuthorizationChecker implements AuthorizationChecker {

  constructor(
    private tokenStorage: AuthentificationTokenStore,
    private decisionManager: DecisionManager,
  ) {
  }

  public async getToken(): Promise<AuthentificationToken> {
    return this.tokenStorage.getToken();
  }

  public async isGranted(attributes: Attribute<any>[], subject?: any): Promise<boolean> {
    const token = await this.getToken();
    return this.decisionManager.decide(token, attributes, subject);
  }

}
