import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommandBus} from "@application/framework/command-query/command-bus.abstract";
import {QueryBus} from "@application/framework/command-query/query-bus.abstract";
import {CommandQueryModuleOptions} from "@easyhpad-ui/app/framework/command-query/interfaces/module-options";
import {BusBuildOptionsImpl} from "@easyhpad-ui/app/framework/command-query/services/bus-build-options";
import {CommandQueryBus} from "@implementations/framework/command-query/bus/bus";

@NgModule()
export class CommandQueryModule {


  private static created = false;

  public static forRoot(options?: CommandQueryModuleOptions): ModuleWithProviders<CommandQueryModule> {

    if (CommandQueryModule.created) {
      throw new Error('ValidatorModule.forRoot() used more than once');
    }

    CommandQueryModule.parseOptions(options);

    return {
      ngModule: CommandQueryModule,
      providers: [
        BusBuildOptionsImpl,
        {
          provide: QueryBus,
          useFactory: CommandQueryBus.build,
          deps: [BusBuildOptionsImpl]
        },
        {
          provide: CommandBus,
          useExisting: QueryBus,
        }
      ]
    };
  }

  public static forChild(options?: CommandQueryModuleOptions): ModuleWithProviders<CommandQueryModule> {
    CommandQueryModule.parseOptions(options);
    return {
      ngModule: CommandQueryModule,
    };
  }

  private static parseOptions(options?: CommandQueryModuleOptions) {
    if (!options) {
      return;
    }

    if (Array.isArray(options.bypass)) {
      options.bypass.forEach(c => BusBuildOptionsImpl.bypassEnqueue.add(c));
    }

  }
}
