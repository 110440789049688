import { EasyRole } from '@implementations/bundles/backend/easyhpad/interfaces/easy-role.interface';
import { EasyCustomer, EasyFacility } from '@implementations/bundles/backend/easyhpad';
import { UUID } from '@domain/lib';

export interface EasyUser {
  id: UUID;

  firstname: string;

  lastname: string;

  customerId: EasyCustomer['id'] | null | undefined;

  email: string;

  roles: Array<EasyRole>;

  jobType: null | string | undefined;

  initialTraining: null | string | undefined;

  educationLevel: null | string | undefined;

  blocked: boolean;

  facilityIds: Array<EasyFacility['id']>;

  createdAt?: Date;

  updatedAt?: Date;
}

export interface EasyCreateUser extends Omit<EasyUser, 'customer' | 'id' | 'createdAt' | 'updatedAt' | 'roles'> {
  roles?: Array<EasyRole['name']>;
}

export type EasyUpdateUser = Omit<EasyUser, 'id' | 'customer' | 'facilityIds' | 'roles' | 'createdAt' | 'updatedAt'> & {
  facilityIds?: Array<EasyFacility['id']>;

  roles?: Array<EasyRole['name']>;

  password?: string;
};
