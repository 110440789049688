import {ForbiddenError} from "@application/bundles/authorization/error";
import {
  CPOMDeletedEvent,
  CPOMDeletionFailEvent,
  CPOMRepository,
  DeleteCPOMCommand,
  StoredCPOM
} from "@application/bundles/cpom";
import {CPOMAuthorizationChecker} from "@application/bundles/cpom/cpom-authorization-checker";
import {CPOM_TRANSLATE_CONTEXT} from "@application/bundles/cpom/cpom.token";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {TranslatableString} from "@application/framework/translation";
import {CPOM} from "@domain/cpom";


@HandleCommand({
  command: DeleteCPOMCommand
})
export class DeleteCPOMCommandHandler implements CommandHandler<DeleteCPOMCommand, StoredCPOM> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private repository: CPOMRepository,
    private authorization: CPOMAuthorizationChecker,
    private eventDispatcher: EventDispatcher,
  ) {
  }

  public async handle(command: DeleteCPOMCommand): Promise<StoredCPOM> {

    const {cpom} = command;
    try {
      const deleted = await this.checkAccess(cpom)
        .then(() => this.repository.delete(cpom.id));

      this.eventDispatcher.dispatch(new CPOMDeletedEvent(deleted));

      return deleted;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }


  private async checkAccess(cpom: CPOM): Promise<CPOM> {

    if (!await this.authorization.canDelete(cpom)) {
      this.logger.error('C.P.O.M. deletion : Forbidden');
      throw new ForbiddenError();
    }

    return cpom;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à supprimer ce C.P.O.M..",
        undefined,
        CPOM_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new CPOMDeletionFailEvent(message));

    return error;
  }

}
