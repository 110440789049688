import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {DispatchableEvent} from "@application/framework/event";
import {Customer} from "@domain/customer";

export class CurrentCustomerChangeEvent extends AbstractDispatchableEvent implements DispatchableEvent {


  constructor(public readonly customer: Customer | undefined) {
    super();
  }
}
