import { AuthorizationChecker, CRUD_ACTIONS } from '@application/bundles/authorization';
import { DOCUMENT_FEATURE } from '@application/bundles/document';

export class DocumentAuthorization {
  constructor(private readonly authorization: AuthorizationChecker) {}

  public canManageDocuments(): Promise<boolean> {
    return this.authorization.isGranted([{ feature: DOCUMENT_FEATURE, value: CRUD_ACTIONS.MANAGE }]);
  }

  public canReadDocument(document?: any): Promise<boolean> {
    return this.authorization.isGranted([{ feature: DOCUMENT_FEATURE, value: CRUD_ACTIONS.READ }], document);
  }

  public canCreateDocument(document?: any): Promise<boolean> {
    return this.authorization.isGranted([{ feature: DOCUMENT_FEATURE, value: CRUD_ACTIONS.CREATE }], document);
  }

  public canEditDocument(document?: any): Promise<boolean> {
    return this.authorization.isGranted([{ feature: DOCUMENT_FEATURE, value: CRUD_ACTIONS.UPDATE }], document);
  }

  public canDeleteDocument(document?: any): Promise<boolean> {
    return this.authorization.isGranted([{ feature: DOCUMENT_FEATURE, value: CRUD_ACTIONS.DELETE }], document);
  }
}
