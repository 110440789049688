import {EhpadDemainRepository, GetEhpadDemainQuery} from "@application/bundles/ehpad-demain";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {EhpadDemain} from "@domain/ehpad-demain";

@HandleQuery({
  query: GetEhpadDemainQuery
})
export class GetEhpadDemainQueryHandler implements QueryHandler<GetEhpadDemainQuery, EhpadDemain> {

  constructor(private readonly repository: EhpadDemainRepository) {
  }

  public async handle(query: GetEhpadDemainQuery): Promise<EhpadDemain> {
    return this.repository.get(query.id);
  }
}
