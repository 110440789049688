import {ContainerRegistry} from "@application/framework/di";
import {ServiceIdentifier} from "@application/framework/di/types/service-identifier";

export const injectServiceInPropertyDescriptor = <T extends Object>(target: T, propertyKey: PropertyKey, serviceIdentifier: ServiceIdentifier, descriptor: Partial<PropertyDescriptor> = {}) => {
  let isFirstTime = true;
  let value: any;

  Object.defineProperty(target, propertyKey, {
    configurable: true,
    enumerable: true,
    set: (v: any) => {
      value = v;
    },
    get: (): any => {
      if (!isFirstTime) {
        return value;
      }

      value = ContainerRegistry.defaultContainer.get(serviceIdentifier)
      isFirstTime = false;
      return value;
    },
    ...descriptor,
  });
}
