import {
  CreateExceptionalHealthFundingDto,
  UpdateExceptionalHealthFundingDto
} from "@application/bundles/health-funding";

export abstract class ExceptionalHealthFundingDtoFactory {

  public abstract buildCreateDtoFromUnsafeValues(values: Partial<CreateExceptionalHealthFundingDto>): CreateExceptionalHealthFundingDto;

  public abstract buildUpdateDtoFromUnsafeValues(values: Partial<UpdateExceptionalHealthFundingDto>): UpdateExceptionalHealthFundingDto;

}
