import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidGMPValue} from "@domain/gmp";

@ValidatorConstraint({name: 'IsGMPValue', async: false})
export class IsGMPValueConstraint implements ValidatorConstraintInterface {

  public validate(value: any, args?: ValidationArguments): boolean {
    return isValidGMPValue(value);
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'Value is not a valid G.M.P..';
  }
}

export function IsGMPValue(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsGMPValueConstraint
    });
  };
}
