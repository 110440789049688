import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RequestParams } from '@application/framework/http/request.interface';
import { GetAuthorizedCapacityWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-authorized-capacity-widget.query';
import { AuthorizedCapacitiesWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';
import { EasyAuthorizedCapacitiesWidget } from '@implementations/bundles/backend/easyhpad/interfaces/widgets/easy-authorized-capacities-widget';

@HandleQuery({
  query: GetAuthorizedCapacityWidgetQuery,
})
export class GetAuthorizedCapacityWidgetQueryHandler
  implements QueryHandler<GetAuthorizedCapacityWidgetQuery, AuthorizedCapacitiesWidgetContent>
{
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: GetAuthorizedCapacityWidgetQuery): Promise<AuthorizedCapacitiesWidgetContent> {
    const params: RequestParams = {};

    if (Array.isArray(query.facilities) && query.facilities.length > 0) {
      params['facility_ids'] = query.facilities.join(',');
    }

    const response = await this.backend.get<EasyAuthorizedCapacitiesWidget>('/widgets/authorized-capacities', params);
    return this.getWidgetContent(response.body);
  }

  private async getWidgetContent(value: EasyAuthorizedCapacitiesWidget): Promise<AuthorizedCapacitiesWidgetContent> {
    const content: AuthorizedCapacitiesWidgetContent = {
      average: value.average,
      total: value.total,
      permanentCapacity: value.permanent_capacity,
      temporaryCapacity: value.temporary_capacity,
      daycareCapacity: value.daycare_capacity,
      bedSocialCare: value.bed_social_care,
      id: value.id,
      facilityId: value.facility_id,
    };

    if (value.gir) {
      content.gir = {
        GIR1: value.gir?.gir_1 || 0,
        GIR2: value.gir?.gir_2 || 0,
        GIR3: value.gir?.gir_3 || 0,
        GIR4: value.gir?.gir_4 || 0,
        GIR5: value.gir?.gir_5 || 0,
        GIR6: value.gir?.gir_6 || 0,
      };
    }

    return content;
  }
}
