import {List} from "@domain/lib/list/list";
import {DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding/dependency-funding.types";

export enum TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES {
  SAME_PERMANENT = "same_permanent",
}


export type TEMPORARY_DEPENDENCIES_FUNDING_MODES =
  DEPENDENCIES_FUNDING_MODES
  | TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES;

export const TemporaryDependencyFundingModeValues = Object.freeze(new List([
  ...Object.values(DEPENDENCIES_FUNDING_MODES),
  ...Object.values(TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES)
]));

export interface TemporaryDependencyFundingMode<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN> {

  key: M;

  label: string;
}
