import { Component, OnInit } from '@angular/core';
import { Notice, NoticeStream } from '@application/framework/notice';

@Component({
  selector: 'ehp-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  public notices: Notice[] = [];

  public autoCloseDelay = 3000;

  constructor(private stream: NoticeStream) {}

  ngOnInit(): void {
    this.stream.subscribe((notice) => {
      notice.dismiss = Number.isSafeInteger(notice.dismiss) ? notice.dismiss : this.autoCloseDelay;
      this.notices.push(notice);
    });
  }

  /**
   * Remove notice in array
   * @param index
   */
  public remove(index: number) {
    if (this.notices[index]) {
      this.notices = this.notices.filter((notice, i) => index !== i);
    }
  }
}
