import {Store} from "@application/framework/store";

export abstract class SettingsStore implements Store<any> {

  public abstract get<T extends any>(key: string): T;

  public abstract has(key: string): boolean;

  public abstract remove(key: string): void;

  public abstract set<T extends any>(key: string, value: T): void;

  public abstract clear(): void;
}
