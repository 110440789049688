import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PhoneFromStringTransformer } from '@application/bundles/phone/transformer/phone-from-string.transformer';
import { UserAuthorizationTokenFactory } from '@application/bundles/user/factories/authorization-token/authorization-token.factory';
import { Bundle } from '@application/framework/bundle';
import { CommandBus } from '@application/framework/command-query';
import { DateParser } from '@application/framework/date/date.parser';
import { extractDependencies } from '@application/framework/di';
import { Container } from '@application/framework/di/container';
import { EventDispatcher } from '@application/framework/event';
import { HttpRequestClient } from '@application/framework/http';
import { Logger } from '@application/framework/logger';
import { FacilityTypeRepository } from '@domain/facility/facility-type.repository';
import { SocialClearanceRepository } from '@domain/facility/social-clearance.repository';
import { InitialTrainingRepository } from '@domain/user/initial-training.repository';
import { JobRepository } from '@domain/user/job.repository';
import { StudyLevelRepository } from '@domain/user/study-level.repository';
import {
  EASYHPAD_EMAIL_PASSWORD_AUTH_STRATEGY,
  EASYHPAD_RECONNECT_STRATEGY,
} from '@easyhpad-ui/app/bundles/backend/easyhpad/easyhpad.tokens';
import { AuthentificationInterceptor } from '@easyhpad-ui/app/bundles/backend/easyhpad/interceptors/authentification/authentification.interceptor';
import { CommandQueryModule } from '@easyhpad-ui/app/framework/command-query/command-query.module';
import { EasyHpadBackendBundle } from '@implementations/bundles/backend/easyhpad';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RefreshHttpAuthorizationTokenCommand } from '@implementations/bundles/backend/easyhpad/commands/refresh-http-authorization-token.command';
import { EasyHpadBearerHttpTokenFactory } from '@implementations/bundles/backend/easyhpad/factories/bearer-http-token-factory';
import { EasyBackendAuthentificationStrategy } from '@implementations/bundles/backend/easyhpad/strategies/authentification/authentification.strategy';
import { EasyBackendReconnectStrategy } from '@implementations/bundles/backend/easyhpad/strategies/authentification/reconnect.strategy';
import { EasyAddCapacityAuthorizationTransformer } from '@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-add-capacity-authorization.transformer';
import { EasyCapacityAuthorizationTransformer } from '@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-capacity-authorization.transformer';
import { EasyUpdateCapacityAuthorizationTransformer } from '@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-update-capacity-authorization.transformer';
import { EasyFacilityCreateDtoTransformer } from '@implementations/bundles/backend/easyhpad/transformers/facility/easy-facility-create-dto/easy-facility-create-dto.transformer';
import { EasyFacilityUpdateDtoTransformer } from '@implementations/bundles/backend/easyhpad/transformers/facility/easy-facility-update-dto/easy-facility-update-dto.transformer';
import { EasyFacilityTransformer } from '@implementations/bundles/backend/easyhpad/transformers/facility/easy-facility.transformer';
import { EasyRoleTransformer } from '@implementations/bundles/backend/easyhpad/transformers/role/easy-role-transformer';
import { EasyCreateUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-create-user.transformer';
import { EasyUpdateUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-update-user.transformer';
import { EasyUserCommonTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-user-common.transformer';
import { EasyUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-user.transformer';
import { EasyHpadUseCasesLibrary } from '@implementations/bundles/backend/easyhpad/use-cases/use-cases.library';
import { DateFormatter } from '@application/framework/date';
import { CreateDemoCommandHandler } from '@implementations/bundles/backend/easyhpad/commands/handlers/create-demo/create-demo.command.handler';
import { EasyCustomerTransformer } from '@implementations/bundles/backend/easyhpad/transformers';

@NgModule({
  imports: [
    CommandQueryModule.forChild({
      bypass: [RefreshHttpAuthorizationTokenCommand],
    }),
  ],
  providers: [
    {
      provide: EasyBackendApi,
      useClass: EasyBackendApi,
      deps: [HttpRequestClient],
    },
    {
      provide: EasyHpadUseCasesLibrary,
      useClass: EasyHpadUseCasesLibrary,
      deps: [Container],
    },
    {
      provide: EASYHPAD_EMAIL_PASSWORD_AUTH_STRATEGY,
      useClass: EasyBackendAuthentificationStrategy,
      deps: extractDependencies(EasyBackendAuthentificationStrategy),
    },
    {
      provide: EasyBackendReconnectStrategy,
      useClass: EasyBackendReconnectStrategy,
      deps: [EasyHpadUseCasesLibrary, UserAuthorizationTokenFactory, EventDispatcher, Logger],
    },
    {
      provide: EASYHPAD_RECONNECT_STRATEGY,
      useExisting: EasyBackendReconnectStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthentificationInterceptor,
      multi: true,
    },
    {
      provide: EasyHpadBearerHttpTokenFactory,
      useClass: EasyHpadBearerHttpTokenFactory,
      deps: [CommandBus],
    },
    {
      provide: EasyFacilityTransformer,
      useClass: EasyFacilityTransformer,
      deps: [PhoneFromStringTransformer, FacilityTypeRepository, SocialClearanceRepository],
    },
    {
      provide: EasyFacilityCreateDtoTransformer,
      useClass: EasyFacilityCreateDtoTransformer,
      deps: [PhoneFromStringTransformer, FacilityTypeRepository, SocialClearanceRepository],
    },
    {
      provide: EasyFacilityUpdateDtoTransformer,
      useClass: EasyFacilityUpdateDtoTransformer,
      deps: [PhoneFromStringTransformer, FacilityTypeRepository, SocialClearanceRepository],
    },
    {
      provide: EasyAddCapacityAuthorizationTransformer,
      useClass: EasyAddCapacityAuthorizationTransformer,
      deps: [DateParser, DateFormatter],
    },
    {
      provide: EasyUpdateCapacityAuthorizationTransformer,
      useClass: EasyUpdateCapacityAuthorizationTransformer,
      deps: [DateParser, DateFormatter],
    },
    {
      provide: EasyCapacityAuthorizationTransformer,
      useClass: EasyCapacityAuthorizationTransformer,
      deps: [Container],
    },
    {
      provide: EasyRoleTransformer,
      useClass: EasyRoleTransformer,
    },
    {
      provide: EasyUserCommonTransformer,
      useClass: EasyUserCommonTransformer,
      deps: [JobRepository, InitialTrainingRepository, StudyLevelRepository, Logger],
    },
    {
      provide: EasyUserTransformer,
      useClass: EasyUserTransformer,
      deps: [EasyUserCommonTransformer],
    },
    {
      provide: EasyCreateUserTransformer,
      useClass: EasyCreateUserTransformer,
      deps: [EasyUserCommonTransformer],
    },
    {
      provide: EasyUpdateUserTransformer,
      useClass: EasyUpdateUserTransformer,
      deps: [EasyUserCommonTransformer],
    },
    {
      provide: CreateDemoCommandHandler,
      useClass: CreateDemoCommandHandler,
      deps: [EasyBackendApi, EasyCustomerTransformer],
    },
  ],
})
@Bundle({
  imports: [EasyHpadBackendBundle],
})
export class EasyhpadBackendModule {}
