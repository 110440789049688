import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ehp-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'page-header',
  },
})
export class PageHeaderComponent {
  @HostBinding('attr.role') public role = 'header';
}
