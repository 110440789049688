import { MissingAuthentificationCredentialsError } from '@application/bundles/authentification/errors';
import { UserAuthentificationFailEvent } from '@application/bundles/user/events/user-authentification-fail.event';
import { UserAuthentificationSuccessEvent } from '@application/bundles/user/events/user-authentification-success.event';
import { UserAuthorizationTokenFactory } from '@application/bundles/user/factories/authorization-token/authorization-token.factory';
import { Service } from '@application/framework/di';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import {
  AuthentificationStrategy,
  StrategyResult,
} from '@application/bundles/authentification/abstraction/authentication-strategy';
import { User } from '@domain/user';
import { EasyhpadBackendAuthentificationStrategyCredentials } from '@implementations/bundles/backend/easyhpad/interfaces';
import { EasyHpadUseCasesLibrary } from '@implementations/bundles/backend/easyhpad/use-cases/use-cases.library';
import { AuthentificationToken } from '@application/bundles/authentification';

@Service()
export class EasyBackendAuthentificationStrategy implements AuthentificationStrategy {
  @ProvideLogger() private readonly logger!: Logger;

  constructor(
    private readonly useCaseLibrary: EasyHpadUseCasesLibrary,
    private readonly tokenFactory: UserAuthorizationTokenFactory,
    private readonly eventDispatcher: EventDispatcher,
  ) {}

  public async login(
    credentials?: EasyhpadBackendAuthentificationStrategyCredentials,
  ): Promise<StrategyResult<User | undefined>> {
    if (
      !credentials ||
      !credentials.credentials ||
      !credentials.credentials.email ||
      !credentials.credentials.password
    ) {
      throw new MissingAuthentificationCredentialsError();
    }

    try {
      const useCase = this.useCaseLibrary.getLoginUseCase();
      const user = await useCase.execute(credentials.credentials);

      this.eventDispatcher.dispatch(new UserAuthentificationSuccessEvent(user));
      return { success: true, result: user };
    } catch (e: any) {
      this.catchLoginError(e);
    }

    return { success: false, result: undefined };
  }

  public async logout(): Promise<StrategyResult<undefined>> {
    const useCase = this.useCaseLibrary.getLogoutUseCase();

    return useCase
      .execute()
      .then(() => ({ success: true, result: undefined }))
      .catch(e => {
        this.logger.error(e);
        return { success: false, result: undefined };
      });
  }

  /**
   * @inheritDoc
   * @param result
   */
  public async buildToken(result: StrategyResult<User>): Promise<AuthentificationToken> {
    return this.tokenFactory.createAuthorizationTokenForUser(result.result);
  }

  private catchLoginError(e: any): void {
    const normalized = new ErrorNormalizer().normalize(e);
    this.logger.error(e);
    this.eventDispatcher.dispatch(new UserAuthentificationFailEvent(normalized.message, normalized.stack, normalized));
  }
}
