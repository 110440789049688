import { Transformer } from '@application/framework/core';
import {
  CreateEasyHealthFundingDto,
  EasyHealthFunding,
  UpdateEasyHealthFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/health-funding/easy-health-funding';
import { HealthFunding, RecurringHealthFunding } from '@domain/health-funding';
import { Service } from '@application/framework/di';
import { DateParser } from '@application/framework/date/date.parser';
import { DateFormatter } from '@application/framework/date';
import { Common } from '@application/framework/lib';
import {
  CreateHealthFundingDto,
  isExceptionalHealthFunding,
  isNonRecurringHealthFunding,
  isRecurringHealthFunding,
  UpdateHealthFundingDto,
} from '@application/bundles/health-funding';
import { HealthFundingImpl } from '@implementations/bundles/health-funding/implementations';
import { AgnosticFundingCollection } from '@application/bundles/funding';
import { RecurringHealthFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers/health-funding/recurring-health-funding.transformer';
import { NonRecurringHealthFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers/health-funding/non-recurring-health-funding.transformer';
import { ExceptionalHealthFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers/health-funding/exceptional-health-funding.transformer';
import { EhpadDemainHealthFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers';

type CommonProperties = Omit<
  Common<HealthFunding, Common<CreateHealthFundingDto, UpdateHealthFundingDto>>,
  'recurringFunding' | 'nonRecurringFunding' | 'exceptionalFunding' | 'ehpadDemainFunding' | 'document' | 'report'
>;

type EasyCommonProperties = Common<EasyHealthFunding, Common<CreateEasyHealthFundingDto, UpdateEasyHealthFundingDto>>;

@Service()
export class HealthFundingTransformer implements Transformer<EasyHealthFunding, HealthFunding> {
  constructor(
    private readonly dateParser: DateParser,
    private readonly dateFormatter: DateFormatter,
    private readonly recurringTransformer: RecurringHealthFundingTransformer,
    private readonly nonRecurringTransformer: NonRecurringHealthFundingTransformer,
    private readonly exceptionalTransformer: ExceptionalHealthFundingTransformer,
    private readonly ehpadDemainTransformer: EhpadDemainHealthFundingTransformer,
  ) {}

  public transform(value: EasyHealthFunding): HealthFunding {
    const recurringFunding = value.recurringFunding.map(funding => this.recurringTransformer.transform(funding));
    const nonRecurringFunding = value.uniqueFunding.map(funding => this.nonRecurringTransformer.transform(funding));
    const exceptionalFunding = value.exceptionalFunding.map(funding => this.exceptionalTransformer.transform(funding));
    const ehpadDemainFunding = value.ehpadDemainFunding.map(funding => this.ehpadDemainTransformer.transform(funding));

    const defaults: Omit<HealthFunding, 'facility' | 'year' | 'document' | 'report'> = {
      ...this.transformCommonProperties(value),
      id: value.id,
      documentId: value.arreteId,
      reportId: value.reportId || undefined,
      recurringFunding: new AgnosticFundingCollection<RecurringHealthFunding>(recurringFunding),
      nonRecurringFunding: new AgnosticFundingCollection(nonRecurringFunding),
      exceptionalFunding: new AgnosticFundingCollection(exceptionalFunding),
      ehpadDemainFunding: new AgnosticFundingCollection(ehpadDemainFunding),
      createdAt: value.createdAt ? this.dateParser.fromISOString(value.createdAt) : undefined,
      updatedAt: value.updatedAt ? this.dateParser.fromISOString(value.updatedAt) : undefined,
    };

    return new HealthFundingImpl(defaults);
  }

  public reverseTransform(value: HealthFunding): EasyHealthFunding {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
      recurringFunding: value.recurringFunding
        .toArray()
        .map(funding => this.recurringTransformer.reverseTransform(funding)),
      uniqueFunding: value.nonRecurringFunding
        .toArray()
        .map(funding => this.nonRecurringTransformer.reverseTransform(funding)),
      exceptionalFunding: value.exceptionalFunding
        .toArray()
        .map(funding => this.exceptionalTransformer.reverseTransform(funding)),
      ehpadDemainFunding: value.ehpadDemainFunding
        .toArray()
        .map(funding => this.ehpadDemainTransformer.reverseTransform(funding)),
    };
  }

  public reverseTransformCreateDto(value: CreateHealthFundingDto): CreateEasyHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value),
      recurringFunding: value.recurringFunding.map(funding =>
        this.recurringTransformer.reverseTransformCreateDto(funding),
      ),
      uniqueFunding: value.nonRecurringFunding.map(funding =>
        this.nonRecurringTransformer.reverseTransformCreateDto(funding),
      ),
      exceptionalFunding: value.exceptionalFunding.map(funding =>
        this.exceptionalTransformer.reverseTransformCreateDto(funding),
      ),
      ehpadDemainFunding: value.ehpadDemainFunding.map(funding =>
        this.ehpadDemainTransformer.reverseTransformCreateDto(funding),
      ),
    };
  }

  public reverseTransformUpdateDto(value: UpdateHealthFundingDto): UpdateEasyHealthFundingDto {
    const recurring_funding = value.recurringFunding.map(funding => {
      if (isRecurringHealthFunding(funding)) {
        return this.recurringTransformer.reverseTransformUpdateDto(funding);
      }
      return this.recurringTransformer.reverseTransformCreateDto(funding);
    });

    const unique_funding = value.nonRecurringFunding.map(funding => {
      if (isNonRecurringHealthFunding(funding)) {
        return this.nonRecurringTransformer.reverseTransformUpdateDto(funding);
      }
      return this.nonRecurringTransformer.reverseTransformCreateDto(funding);
    });

    const exceptional_funding = value.exceptionalFunding.map(funding => {
      if (isExceptionalHealthFunding(funding)) {
        return this.exceptionalTransformer.reverseTransformUpdateDto(funding);
      }
      return this.exceptionalTransformer.reverseTransformCreateDto(funding);
    });

    const ehpad_demain_funding = value.ehpadDemainFunding.map(funding => {
      if (isExceptionalHealthFunding(funding)) {
        return this.ehpadDemainTransformer.reverseTransformUpdateDto(funding);
      }
      return this.ehpadDemainTransformer.reverseTransformCreateDto(funding);
    });

    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
      recurringFunding: recurring_funding,
      uniqueFunding: unique_funding,
      exceptionalFunding: exceptional_funding,
      ehpadDemainFunding: ehpad_demain_funding,
    };
  }

  private reverseTransformCommonProperties(value: CommonProperties): EasyCommonProperties {
    return {
      date: this.dateFormatter.toUTCIsoString(value.date),
      accountingYear: value.accountingYear,
      gmpValue: value.gmp,
      pmpValue: value.pmp,
      amount: value.total,
      nonRecurringAmount: value.nonRecurringTotal || 0,
      facilityId: value.facilityId,
      arreteId: value.documentId || '',
      reportId: value.reportId ?? null,
      permanentAmount: value.permanentRecurringAmount,
      temporaryAmount: value.temporaryRecurringAmount,
      daycareAmount: value.dayCareRecurringAmount,
      supportAmount: value.supportRecurringAmount,
      recurringFunding: [],
      uniqueFunding: [],
      exceptionalFunding: [],
      ehpadDemainFunding: [],
    };
  }

  private transformCommonProperties(value: EasyCommonProperties): CommonProperties {
    return {
      date: this.dateParser.fromISOString(value.date),
      accountingYear: value.accountingYear,
      gmp: value.gmpValue,
      pmp: value.pmpValue,
      total: value.amount,
      nonRecurringTotal: value.nonRecurringAmount,
      facilityId: value.facilityId,
      documentId: value.arreteId,
      reportId: value.reportId || undefined,
      permanentRecurringAmount: value.permanentAmount || undefined,
      temporaryRecurringAmount: value.temporaryAmount || undefined,
      dayCareRecurringAmount: value.daycareAmount || undefined,
      supportRecurringAmount: value.supportAmount || undefined,
    };
  }
}
