import {DeleteRequest, GetRequest, HttpRequestClient, PatchRequest, PostRequest} from "@application/framework/http";
import {RequestParams, Response} from "@application/framework/http/request.interface";
import {environment} from "@easyhpad-ui/environments/environment";

export class EasyBackendApi {

  public static readonly url = environment.backendUrl;

  constructor(private http: HttpRequestClient) {
  }

  public get<R = Object>(path: string, params?: RequestParams): Promise<Response<R>> {
    const request = new GetRequest(this.buildSafeUrl(path));
    request.params = params;
    return this.http.get<R>(request);
  }

  public post<R = Object, Body = Record<any, any>>(path: string, data: Body): Promise<Response<R>> {
    const request = new PostRequest(this.buildSafeUrl(path), data);
    return this.http.post<R>(request);
  }

  public patch<R = Object, Body = Record<any, any>>(path: string, data: Body): Promise<Response<R>> {
    const request = new PatchRequest(this.buildSafeUrl(path), data);
    return this.http.patch<R>(request);
  }

  public delete<R = Object>(path: string): Promise<Response> {
    const request = new DeleteRequest(this.buildSafeUrl(path));
    return this.http.delete<R>(request) as Promise<Response<R>>;
  }

  private buildSafeUrl(path: string): URL {
    return new URL(`${this.removeSlash(EasyBackendApi.url.toString())}/${this.removeSlash(path)}`);
  }

  private removeSlash(string: string): string {
    return string.replace(/^\/|\/$/g, '');
  }
}
