import { createSelector } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { DASHBOARD_STORE_KEY, DashboardStoreState } from '@easyhpad-ui/app/bundles/dashboard/store';

export const selectFacilityState = (state: AppState) => state[DASHBOARD_STORE_KEY];

export const selectDashboardViewMode = createSelector(selectFacilityState, (state: DashboardStoreState) => state.view);

export const selectDashboardFacilities = createSelector(
  selectFacilityState,
  (state: DashboardStoreState) => state.facilityIds,
);
