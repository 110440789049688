import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {DispatchableEvent} from "@application/framework/event";
import {CapacityAuthorization} from "@domain/capacity-authorization";

export class CapacityAuthorizationUpdatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {


  public constructor(public readonly authorization: CapacityAuthorization) {
    super();
  }
}
