import {CreateCPOMDto, ListCPOMParams, StoredCPOM, UpdateCPOMDto} from "@application/bundles/cpom";
import {Facility} from "@domain/facility";

export abstract class CPOMRepository {

  public abstract list(params?: ListCPOMParams): Promise<StoredCPOM[]>;

  public abstract get(id: StoredCPOM['id']): Promise<StoredCPOM>;

  public abstract create(cpom: CreateCPOMDto): Promise<StoredCPOM>;

  public abstract update(id: StoredCPOM['id'], update: Partial<UpdateCPOMDto>): Promise<StoredCPOM>;

  public abstract delete(id: StoredCPOM['id']): Promise<StoredCPOM>;

  public abstract current(facilityId: Facility['id']): Promise<StoredCPOM | undefined>;
}
