import {Voter} from "@application/bundles/authorization";

export abstract class VoterRegistry {

  private static readonly voters: Set<Voter> = new Set();

  public static set(voter: Voter): void {
    VoterRegistry.voters.add(voter);
  }

  public static all(): Voter[] {
    return Array.from(VoterRegistry.voters);
  }


  /**
   * Register new voters
   * @param voter
   */
  public abstract register(...voter: Voter[]): void;


  /**
   * Get all voters
   */
  public abstract all(): Voter[];

}
