import {Command} from "@application/framework/command-query";
import {CapacityAuthorization} from "@domain/capacity-authorization";
import {Facility} from "@domain/facility";
import {AddCapacityAuthorizationDto} from "../dtos/add-capacity-authorization.dto";

export class AddCapacityAuthorizationCommand implements Command<CapacityAuthorization> {


  constructor(public readonly facility: Facility, public readonly authorization: AddCapacityAuthorizationDto) {
  }
}
