import { HandleQuery, QueryHandler } from '@application/framework/command-query';

import { FileDepositRepository } from '@application/bundles/file-deposit/repositories';
import { GetFileDepositQuery } from '../../get-file-deposit.query';
import { FileDeposit } from '@domain/file-deposit';

@HandleQuery({
  query: GetFileDepositQuery,
})
export class GetFileDepositQueryHandler implements QueryHandler<GetFileDepositQuery> {
  constructor(private readonly repository: FileDepositRepository) {}

  public async handle(query: GetFileDepositQuery): Promise<FileDeposit | undefined> {
    return this.repository.get(query.id);
  }
}
