import { CreatePMPDto } from '@application/bundles/pmp';
import { BackendExclude, BackendProperty } from '@application/framework/backend';
import { Facility } from '@domain/facility';
import { LocalMedia } from '@domain/media';
import { BackendDateTransformer } from '@implementations/bundles/backend/easyhpad/transformers';
import { IsValidFacilityId } from '@implementations/bundles/facility';
import { IsLocalMedia } from '@implementations/bundles/media';
import { IsDate, IsNumber, IsPositive } from 'class-validator';

export class CreatePMPDtoImpl implements CreatePMPDto {
  @BackendProperty('validationDate', {
    transformers: new BackendDateTransformer(),
  })
  @IsDate()
  public date!: Date;

  @IsNumber()
  @IsPositive()
  public value!: number;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  @BackendExclude()
  @IsLocalMedia()
  public pv!: LocalMedia;

  constructor(values?: Partial<CreatePMPDto>) {
    if (values?.date) {
      this.date = values.date;
    }
    if (values?.value) {
      this.value = values.value;
    }

    if (values?.facilityId) {
      this.facilityId = values.facilityId;
    }
    if (values?.pv) {
      this.pv = values.pv;
    }
  }
}
