import {CreateGMPDto, GMPDtoFactory, UpdateGMPDto} from "@application/bundles/gmp";
import {CreateGmpDtoImpl, UpdateGMPDtoImpl} from "@implementations/bundles/gmp";

export class GMPDtoFactoryImpl implements GMPDtoFactory {

  public buildCreateGMPDtoFromUnsafeValues(values: Partial<CreateGMPDto>): CreateGMPDto {
    return new CreateGmpDtoImpl(values);
  }

  public buildUpdateGMPDtoFromUnsafeValues(values: Partial<UpdateGMPDto>): UpdateGMPDto {
    return new UpdateGMPDtoImpl(values);
  }

}
