import { CommonDependencyFundingDtoAbstract } from '@implementations/bundles/dependency-funding/dto/dependency-funding/common-dependency-funding.dto.abstract';
import {
  CreateDaycareDependencyFundingDto,
  CreatePermanentDependencyFundingDto,
  CreateTemporaryDependencyFundingDto,
  isValidDependencyFundingIdType,
  UpdateDaycareDependencyFundingDto,
  UpdateDependencyFundingDto,
  UpdatePermanentDependencyFundingDto,
  UpdateTemporaryDependencyFundingDto,
} from '@application/bundles/dependency-funding';
import { isValidObject } from '@application/framework/lib';
import { IsOptional } from 'class-validator';
import { isLocalMedia, isMedia, LocalMedia, Media } from '@domain/media';
import { IsLocalMediaOrMedia } from '@implementations/bundles/media';
import { DependencyFunding } from '@domain/dependency-funding';

export class UpdateDependencyFundingDtoImpl
  extends CommonDependencyFundingDtoAbstract
  implements UpdateDependencyFundingDto
{
  public id!: DependencyFunding['id'];

  public permanent!: CreatePermanentDependencyFundingDto | UpdatePermanentDependencyFundingDto;

  public temporary!:
    | CreateTemporaryDependencyFundingDto<any, unknown>
    | UpdateTemporaryDependencyFundingDto<any, unknown>;

  public daycare!: CreateDaycareDependencyFundingDto<any, unknown> | UpdateDaycareDependencyFundingDto<any, unknown>;

  @IsLocalMediaOrMedia()
  public document!: LocalMedia | Media;

  @IsLocalMediaOrMedia()
  @IsOptional()
  public report?: LocalMedia | Media;

  constructor(values?: Partial<UpdateDependencyFundingDto>) {
    super(values);

    if (isValidObject(values)) {
      if (isValidDependencyFundingIdType(values.id)) {
        this.id = values.id;
      }

      if (values.permanent) {
        this.permanent = values.permanent;
      }

      if (values.temporary) {
        this.temporary = values.temporary;
      }

      if (values.daycare) {
        this.daycare = values.daycare;
      }

      if (isLocalMedia(values?.document) || isMedia(values?.document)) {
        this.document = values?.document;
      }

      if (isLocalMedia(values?.report) || isMedia(values?.report)) {
        this.report = values?.report;
      }
    }
  }
}
