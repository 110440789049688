import { Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isValidYear, Year } from '@domain/lib';

@Component({
  selector: 'ehp-year-chips-input',
  templateUrl: './year-chips-input.component.html',
  styleUrls: ['./year-chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YearChipsInputComponent),
      multi: true,
    },
  ],
})
export class YearChipsInputComponent implements ControlValueAccessor, OnDestroy {
  public static REGEX = /^\d+$/;

  public control = new FormControl();

  public disabled = false;

  public placeholder: string;

  private subscription: Subscription;

  constructor() {
    this.subscription = this.control.valueChanges.subscribe(changes => this.valueChange(changes));

    const now = new Date().getFullYear();

    this.placeholder = `Ex: ${now}, ${now - 1}, ${now - 2}`;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onChange = (years: Array<Year>): void => undefined;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any) {
    let years: Year[] = [];

    if (Array.isArray(obj)) {
      years = [...obj]
        .filter(value => (typeof value === 'string' && YearChipsInputComponent.REGEX.test(value)) || isValidYear(value))
        .map(value => (typeof value === 'string' ? parseInt(value) : value));
    }

    this.control.patchValue(years);
  }

  private valueChange(changes: Array<string>) {
    let update = false;
    let years: Year[] = [];

    if (Array.isArray(changes)) {
      years = [...changes]
        .filter(value => YearChipsInputComponent.REGEX.test(value))
        .map(value => parseInt(value))
        .filter(value => isValidYear(value));

      if (years.length !== changes.length) {
        update = true;
      }
    }

    if (update) {
      this.control.setValue(years);
    } else {
      this.onChange(years);
    }
  }
}
