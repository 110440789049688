import { DynamicFormElementType, DynamicFormNode } from '@easyhpad-ui/app/library/form/contracts/dynamic-form';
import { TranslatableString } from '@application/framework/translation';
import { WithControlValidators } from '@easyhpad-ui/app/library/form/contracts/dynamic-field-attributes';

export interface DynamicRepeaterField extends DynamicFormNode<'repeater'> {}

export interface DynamicStepGroup<C extends { [k in keyof C]: DynamicFormElementType<any> }, N extends string = string>
  extends DynamicFormNode<'step'>,
    WithControlValidators {
  /**
   * Label display in progress bar
   */
  label: string | TranslatableString;

  /**
   * A machine-readable step name.
   */
  name: N;

  /**
   * The field collection
   */
  fields: Record<keyof C, DynamicFormElementType<any>>;
}

export interface DynamicStepperGroup<V = any> extends DynamicFormNode<'stepper'> {
  onSubmit: (values: V) => void;
  steps: Record<string, DynamicStepGroup<any>>;
  submitLabel?: string | TranslatableString;
}
