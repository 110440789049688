import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {getDatesIntervalForYear} from "@domain/lib/date/year";
import {ERRDRepository, ListERRDForYearQuery} from "@application/bundles/eprd-errd";
import {ERRD} from "@domain/eprd-errd";
import {AgnosticERRDSearchParams} from "@application/bundles/eprd-errd/implementations";

@HandleQuery({
  query: ListERRDForYearQuery
})
export class ListERRDForYearQueryHandler implements QueryHandler<ListERRDForYearQuery> {

  constructor(private readonly repository: ERRDRepository) {
  }

  public async handle(query: ListERRDForYearQuery): Promise<ERRD[]> {

    const dates = getDatesIntervalForYear(query.year);
    const params = new AgnosticERRDSearchParams()
      .setDates(dates.start, dates.end);

    return await this.repository.list(params);
  }
}
