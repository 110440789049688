<header class="widget-header">
  <h3 class="widget-title title">{{"G.M.P." | ehp_translate }}</h3>
</header>

<div class="widget-content">

  <div *ngIf="gmp; else empty" class="amount-container">
    <p class="amount"><span *ngIf="gmp">{{gmp.value}}</span></p>
  </div>

</div>

<div class="widget-footer">
  <p *ngIf="gmp">{{'Validé le' | ehp_translate}} {{gmp.date | date:'dd/MM/YYYY'}}</p>

  <a *ngIf="gmp && url" [routerLink]="url" class="target-link button is-icon small icon-small-arrow">
    <span class="label">{{ "Voir le G.M.P." | ehp_translate}}</span>
  </a>
</div>


<ng-template #empty>
  <p>{{ 'Aucun G.M.P. archivé pour cet établissement' | ehp_translate}}.</p>

  <a *ngIf="url" [routerLink]="url" class="target-link">
    <span class="label">{{ "Ajouter un G.M.P." | ehp_translate}}</span>
  </a>
</ng-template>
