import { DateParser } from '@application/framework/date/date.parser';
import { Service } from '@application/framework/di';
import { Transformer } from '@application/framework/core';
import { GMP } from '@domain/gmp';
import { EasyGMP } from '@implementations/bundles/backend/easyhpad';
import { EasyGMPCommonTransformer } from '@implementations/bundles/backend/easyhpad/transformers/gmp/easy-gmp-common.transformer';
import { GMPImpl } from '@implementations/bundles/gmp/implementations/gmp';

@Service()
export class EasyGmpTransformer implements Transformer<GMP, EasyGMP> {
  constructor(
    private readonly transformer: EasyGMPCommonTransformer,
    private readonly dateParser: DateParser,
  ) {}

  public transform(value: GMP): EasyGMP {
    return {
      ...this.transformer.transform(value),
      id: value.id,
      pvId: value.pvId || '',
    };
  }

  public reverseTransform(value: EasyGMP): GMP {
    return new GMPImpl({
      ...this.transformer.reverseTransform(value),
      id: value.id,
      pvId: value.pvId,
      createdAt: value.createdAt ? this.dateParser.fromISOString(value.createdAt) : undefined,
      updatedAt: value.updatedAt ? this.dateParser.fromISOString(value.updatedAt) : undefined,
    });
  }
}
