import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {CustomerAdminRoutingModule} from "./customer-admin-routing.module";
import {
  AdminCustomerCreatePageComponent,
  AdminCustomerListPageComponent,
  AdminCustomerUpdatePageComponent
} from "@easyhpad-ui/app/bundles/customer/modules/admin/pages";
import {ReusableComponentsModule} from "@easyhpad-ui/app/bundles/reusable-components/reusable-components.module";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {TableModule} from "@easyhpad-ui/app/library/table/table.module";
import {CustomerFormModule} from "@easyhpad-ui/app/bundles/customer/modules";
import {ReactiveFormsModule} from "@angular/forms";
import {FormModule} from "@easyhpad-ui/app/library/form/form.module";
import {AddressModule} from "@easyhpad-ui/app/bundles/address/address.module";
import {PaginationModule} from "@easyhpad-ui/app/library/pagination";


@NgModule({
  declarations: [
    AdminCustomerListPageComponent,
    AdminCustomerCreatePageComponent,
    AdminCustomerUpdatePageComponent
  ],
  imports: [
    CommonModule,
    CustomerAdminRoutingModule,
    TranslationModule,
    ReusableComponentsModule,
    CdkTableModule,
    TableModule,
    CustomerFormModule,
    ReactiveFormsModule,
    FormModule,
    AddressModule,
    PaginationModule
  ],
  providers: []
})
export class CustomerAdminModule {
}
