<select (blur)="onTouched()" (change)="selectChange()" (input)="onTouched()" [compareWith]="compareFn"
        [formControl]="select"
        [multiple]="multiple">

  <option *ngIf="options.length !== 0" [ngValue]="null">
    {{ '- Sélectionner une option -' | ehp_translate }}
  </option>

  <option *ngIf="options.length === 0" [ngValue]="null">
    {{ '- Aucune option disponible -'|ehp_translate }}
  </option>

  <option *ngFor="let option of options" [ngValue]="option" [selected]="compareFn(option, value)">
    <ehp-translatable-string [value]="option.label"></ehp-translatable-string>
  </option>
</select>
