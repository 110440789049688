import { Component } from '@angular/core';

@Component({
  selector: 'ehp-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss']
})
export class TableContainerComponent {

}
