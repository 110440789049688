import { Dialog } from '@angular/cdk/dialog';
import { CommandBus, CommandHandler, HandleCommand } from '@application/framework/command-query';
import { ConfirmDeletionDialogComponent } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component';
import { ConfirmDeletionDialogData } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface';
import { DialogConfiguration } from '@easyhpad-ui/app/library/dialog';
import { OpenERRDDeletionDialogCommand } from '@easyhpad-ui/app/bundles/eprd-errd/commands';
import { DeleteERRDCommand } from '@application/bundles/eprd-errd';
import { ERRD } from '@domain/eprd-errd';

@HandleCommand({
  command: OpenERRDDeletionDialogCommand,
})
export class OpenERRDDeletionDialogCommandHandler implements CommandHandler<OpenERRDDeletionDialogCommand, void> {
  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus,
  ) {}

  public async handle(command: OpenERRDDeletionDialogCommand): Promise<void> {
    const { errd, onDeletion } = command;

    const data: ConfirmDeletionDialogData = {
      options: [{ key: errd.id, label: `E.R.R.D. ${errd.accountingYear}` }],
    };

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<ERRD>(new DeleteERRDCommand(errd));

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    };

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, { ...DialogConfiguration, data });
    dialog.closed.subscribe(subscriber);
  }
}
