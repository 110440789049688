import {Dialog} from "@angular/cdk/dialog";
import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {SettingsStore} from "@application/bundles/application/store/settings-store";
import {skip, Subscription} from "rxjs";
import {DialogConfiguration} from "../../../dialog";
import {SelectableTableColumnsDialogData} from "../../selectable-table-columns-dialog-data";
import {TableColumnsList} from "../../table-columns-list/table-columns-list";
import {
  SelectableTableColumnsDialogComponent
} from "../selectable-table-columns-dialog/selectable-table-columns-dialog.component";

@Component({
  selector: 'ehp-selectable-table-columns',
  templateUrl: './selectable-table-columns.component.html',
  styleUrls: ['./selectable-table-columns.component.scss'],
  host: {
    "class": "ghost",
    "[class.active]": 'active',
    "[class.hide]": '!total',
    "[attr.data-count]": "count",
    "role": "button",
    "(click)": "openDialog()"
  }
})
export class SelectableTableColumnsComponent implements OnChanges, OnDestroy {

  @Input('columns') public columns: TableColumnsList<any> | undefined;


  public label: string = '';

  public hidden: number = 0;

  public total: number = 0;

  public count: string = '';

  private subscription: Subscription | undefined;

  public get active(): boolean {
    return this.hidden !== 0;
  }


  public constructor(private readonly dialog: Dialog, private settings: SettingsStore) {
    this.setLabel();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['columns']) {
      this.columnsChange(changes['columns'].currentValue);
    }
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public openDialog(): void {

    if (this.columns === undefined) {
      return;
    }

    const data: SelectableTableColumnsDialogData = {
      columns: this.columns,
      title: this.label
    }

    this.dialog.open(SelectableTableColumnsDialogComponent, ({...DialogConfiguration, data}));
  }

  private setLabel() {

    if (this.active) {
      this.label = this.total === this.hidden ? 'Afficher des colonnes' : 'Masquer/afficher des colonnes';
    } else {
      this.label = 'Masquer des colonnes';
    }
  }

  private columnsChange(columns: TableColumnsList | undefined) {
    this.hidden = 0;
    const maskable = columns ? columns.columns.filter(c => c.maskable !== false) : [];
    this.total = maskable.length;

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (columns) {

      const name = columns.settingName;
      const selected = this.settings.get<string[]>(name);

      this.subscription = columns.keys$.pipe(skip(1)).subscribe((keys) => {

        this.hidden = this.total - keys.filter(k => maskable.map(c => c.key).includes(k)).length;
        this.setLabel();
        this.setCount();
        this.settings.set(name, keys);
      });

      if (Array.isArray(selected)) {
        const deselected = columns.keys.filter(k => !selected.includes(k));
        columns.deselect(...deselected);
      }
    }

    this.setLabel();
    this.setCount();

  }

  private setCount() {
    this.count = this.total !== 0 && this.hidden !== 0 ? `${this.total - this.hidden}/${this.total}` : '';
  }
}
