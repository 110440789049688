import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {ErrorActions} from "./error.actions";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {UnauthorizedAccessError} from "@application/bundles/authorization/error";
import {TranslatableString} from "@application/framework/translation";
import {NotFoundDocumentError} from "@application/framework/lib";

@Injectable()
export class ErrorEffects {


  catchActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorActions.catchError),
      map((action) => ErrorActions.displayError({error: action.error}))
    )
  );

  displayError$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ErrorActions.catchError),
        map((action) => {

          let message: string | TranslatableString | undefined;
          
          if (action.error && action.error.constructor.name === UnauthorizedAccessError.name) {
            message = new TranslatableString("Vous n'êtes pas autorisé à accéder à cette ressource.");
          } else if (action.error && action.error.constructor.name === NotFoundDocumentError.name) {
            message = new TranslatableString("La ressource demandée est introuvable.");
          } else {
            message = new TranslatableString("Une erreur inconnue est survenue.");
          }

          if (message) {
            this.noticeStream.push({message, dismiss: 0, type: NoticeType.ERROR});
          }
        })
      ),
    {dispatch: false}
  );

  constructor(
    private readonly actions$: Actions,
    private readonly noticeStream: NoticeStream
  ) {
  }
}

