<ehp-form-field>

  <label *ngIf="field.label" [for]="field.name">
    <ehp-translatable-string [value]="field.label"></ehp-translatable-string>
  </label>

  <div *ngIf="field.description" class="description">
    <ehp-translatable-string [value]="field.description"></ehp-translatable-string>
  </div>


  <ehp-media-uploader
    (onChange)="propagateChanges($event)"
    (onRemove)="emitRemove($event)"
    [hideFileList]="hideFileList"
    [id]="field.name"
    [max]="max"
    [medias]="media"
    [min]="min"
    [requiredFileType]="allowedFileTypes"
    [required]="required"
    ehp-form-control>
  </ehp-media-uploader>

</ehp-form-field>
