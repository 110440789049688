import { CreateGMPDto, GMPSearchParams, UpdateGMPDto } from '@application/bundles/gmp';
import { GMP } from '@domain/gmp';
import { Facility } from '@domain/facility';

export abstract class GMPRepository {
  public abstract list(params?: GMPSearchParams): Promise<GMP[]>;

  public abstract listLatest(params?: GMPSearchParams): Promise<GMP[]>;

  public abstract get(id: GMP['id']): Promise<GMP>;

  public abstract create(id: CreateGMPDto): Promise<GMP>;

  public abstract update(id: GMP['id'], update: Partial<UpdateGMPDto>): Promise<GMP>;

  public abstract delete(id: GMP['id']): Promise<GMP>;

  public abstract getLatestForFacility(facilityId: Facility['id']): Promise<GMP | undefined>;
}
