import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { authentificationActions } from '@easyhpad-ui/app/bundles/authentification/store';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  selectAuthentificationCheck,
  selectAuthentificationLogged,
} from '@easyhpad-ui/app/bundles/authentification/store/authentification.selector';
import { AuthentificationState } from '@application/bundles/authentification';

@Injectable()
export class ReduxAuthentificationState implements AuthentificationState {
  public readonly changes: Observable<{ checked: boolean; logged: boolean }>;

  private source: BehaviorSubject<{ checked: boolean; logged: boolean }>;

  private checked = false;

  private logged = false;

  constructor(private readonly store: Store<AppState>) {
    this.source = new BehaviorSubject({ checked: this.checked, logged: this.logged });
    this.changes = this.source.asObservable();

    this.store.select(selectAuthentificationCheck).subscribe(changes => {
      this.checked = changes;
      this.dispatchChanges();
    });

    this.store.select(selectAuthentificationLogged).subscribe(changes => {
      this.logged = changes;
      this.dispatchChanges();
    });
  }

  public markAsChecked(): void {
    this.store.dispatch(authentificationActions.setAuthenticationChecked());
  }

  private dispatchChanges(): void {
    this.source.next({ checked: this.checked, logged: this.logged });
  }
}
