import {UserSearchParams} from "@application/bundles/user/user-search.params";
import {CreateUser, UpdateCurrentUser, UpdateUser, User} from "./user.interface";

export abstract class UserRepository {

  public abstract list(params?: UserSearchParams): Promise<User[]>;

  public abstract get(id: User['id']): Promise<User>;

  public abstract delete(id: User['id']): Promise<User>;

  public abstract create(user: CreateUser): Promise<User>;

  public abstract update(id: User['id'], update: UpdateUser | UpdateCurrentUser): Promise<User>;
}
