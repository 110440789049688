import {RefreshCurrentUserCommand, StoreCurrentUserCommand} from "@application/bundles/user";
import {CommandBus, CommandHandler, HandleCommand} from "@application/framework/command-query";
import {User} from "@domain/user";
import {EasyBackendApi} from "@implementations/bundles/backend/easyhpad/api";
import {EasyUser} from "@implementations/bundles/backend/easyhpad/interfaces/easy-user.interface";
import {EasyUserTransformer} from "@implementations/bundles/backend/easyhpad/transformers/user/easy-user.transformer";

@HandleCommand({
  command: RefreshCurrentUserCommand
})
export class RefreshCurrentUserCommandHandler implements CommandHandler<RefreshCurrentUserCommand, User> {

  public static readonly path = 'user/me';

  public constructor(
    private readonly backend: EasyBackendApi,
    private readonly transformer: EasyUserTransformer,
    private readonly commandBus: CommandBus,
  ) {
  }

  public async handle(command: RefreshCurrentUserCommand): Promise<User> {

    const user = await this.backend.get<EasyUser>(RefreshCurrentUserCommandHandler.path)
      .then(response => response.body)
      .then(user => this.transformer.reverseTransform(user));

    await this.commandBus.execute(new StoreCurrentUserCommand(user));
    return user;
  }

}
