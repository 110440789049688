import {
  DependencyFundingCreatedEvent,
  DependencyFundingCreationFailEvent
} from "@application/bundles/dependency-funding";
import {DEPENDENCY_FUNDING_TRANSLATE_CONTEXT} from "@application/bundles/dependency-funding/dependency-funding.token";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnDependencyFundingCreationEventSubscriber {

  constructor(private readonly noticeStream: NoticeStream) {
  }

  @OnEvent({
    event: DependencyFundingCreatedEvent
  })
  private async pushNoticeOnDependencyFundingCreationSuccess(event: DependencyFundingCreatedEvent): Promise<void> {

    const message = new TranslatableString(
      "Le <strong>financement dépendance {{year}}</strong> a été créé avec succès.",
      {year: event.dependencyFunding.year},
      DEPENDENCY_FUNDING_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  @OnEvent({
    event: DependencyFundingCreationFailEvent
  })
  private pushNoticeOnDependencyFundingCreationFail(event: DependencyFundingCreationFailEvent): void {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la création du nouveau financement dépendance.',
        undefined,
        DEPENDENCY_FUNDING_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }
}
