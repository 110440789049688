import {Component, EventEmitter} from '@angular/core';
import {GetLatestPMPQuery} from "@application/bundles/pmp";
import {QueryBus} from "@application/framework/command-query";
import {Subscription} from "@application/framework/reactive";
import {Facility} from "@domain/facility";
import {PMP} from "@domain/pmp";
import {PMPUrlsProvider} from "@easyhpad-ui/app/bundles/pmp";
import {DashboardFacilityAccessor} from "@implementations/bundles/facility/accessor/dashboard-facility-accessor";

@Component({
  selector: 'ehp-facility-pmp-widget',
  templateUrl: './facility-pmp-widget.component.html',
  styleUrls: ['./facility-pmp-widget.component.scss']
})
export class FacilityPMPWidgetComponent {

  public facility: Facility | undefined;

  public pmp: PMP | undefined;

  public url: string | undefined;

  private subscription: Subscription;

  private change = new EventEmitter();


  constructor(
    private readonly accessor: DashboardFacilityAccessor,
    private readonly queryBus: QueryBus,
    private readonly urls: PMPUrlsProvider,
  ) {
    this.facility = this.accessor.get();
    this.subscription = this.accessor.onChange((facility) => this.facilityChange(facility));
  }

  private async facilityChange(facility: Facility | undefined) {

    this.facility = facility;
    let pmp: PMP | undefined;

    if (facility !== undefined) {
      pmp = await this.queryBus.request(new GetLatestPMPQuery(facility.id));
    }

    await this.pmpChange(pmp);
  }

  private async pmpChange(pmp: PMP | undefined) {
    this.pmp = pmp;
    this.url = undefined;

    if (this.pmp !== undefined) {
      this.urls.getPublicShow(this.pmp.id).then((url) => this.url = url);
    } else {
      this.urls.getPublicList().then(url => this.url = url);
    }

  }
}
