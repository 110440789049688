import {NgModule} from "@angular/core";
import {Route, RouterModule} from "@angular/router";
import {AuthGuard} from "@easyhpad-ui/app/bundles/authentification/guards/auth-guard/auth.guard";
import {
  AdminCustomerCreatePageComponent,
  AdminCustomerListPageComponent,
  AdminCustomerUpdatePageComponent
} from "@easyhpad-ui/app/bundles/customer/modules/admin/pages";
import {CustomerUrlsProvider} from "@easyhpad-ui/app/bundles/customer/providers/customer-urls/customer-urls.provider";

const routes: Route[] = [
  {
    path: CustomerUrlsProvider.adminSchema.list.path,
    component: AdminCustomerListPageComponent,
    canActivate: [AuthGuard],
    title: CustomerUrlsProvider.adminSchema.list.title
  },
  {
    path: CustomerUrlsProvider.adminSchema.create.path,
    component: AdminCustomerCreatePageComponent,
    canActivate: [AuthGuard],
    title: CustomerUrlsProvider.adminSchema.create.title
  },
  {
    path: CustomerUrlsProvider.adminSchema.update.path,
    component: AdminCustomerUpdatePageComponent,
    canActivate: [AuthGuard],
    title: CustomerUrlsProvider.adminSchema.update.title
  }
]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerAdminRoutingModule {
}
