import {Component} from '@angular/core';
import {RouteSailor} from "@easyhpad-ui/app/framework/routing";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'ehp-public-current-user-profile',
  templateUrl: './public-current-user-profile.component.html',
  styleUrls: ['./public-current-user-profile.component.scss']
})
export class PublicCurrentUserProfileComponent {


  constructor(
    private readonly route: ActivatedRoute,
    private readonly routeSailor: RouteSailor
  ) {
  }


  public redirectToHome(): void {
    this.routeSailor.catchAndRedirect(this.route, '/');
  }
}
