import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CommandBus} from "@application/framework/command-query";
import {RequestResetPasswordCommand} from "@implementations/bundles/backend/easyhpad/commands";

@Component({
  selector: 'ehp-forgotten-password-form',
  templateUrl: './forgotten-password-form.component.html',
  styleUrls: ['./forgotten-password-form.component.scss']
})
export class ForgottenPasswordFormComponent {

  @Input() public defaults: { email: string } | undefined;

  @Input() public loginEnable = false;

  @Output() public requestLoginMove = new EventEmitter();

  public readonly form: FormGroup<Record<'email', FormControl<any>>>;

  constructor(
    private formBuilder: FormBuilder,
    private commandBus: CommandBus
  ) {

    this.form = this.formBuilder.group({
      email: new FormControl(this.defaults?.email ?? '', {
        validators: [Validators.required, Validators.email],
      })
    });
  }

  public submit() {

    if (!this.form.valid) {
      return;
    }

    this.commandBus.execute(new RequestResetPasswordCommand({email: this.form.value.email}));

  }

  public moveToLogin() {
    this.requestLoginMove.emit();
  }
}
