import { Actions } from '@ngrx/effects';
import { CommandBus, QueryBus } from '@application/framework/command-query';
import { Injectable } from '@angular/core';
import { DocumentEffet } from '@easyhpad-ui/app/bundles/documents/store/document.effet';
import { DocumentStoreActions } from '@easyhpad-ui/app/bundles/documents';
import { from, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { PMP } from '@domain/pmp';
import { pmpActions } from './pmp.store';
import {
  CreatePMPDto,
  GetPMPQuery,
  ListPMPQuery,
  SerializedPMPSearchParams,
  UpdatePMPCommand,
  UpdatePMPCommandResult,
  UpdatePMPDto,
} from '@application/bundles/pmp';

@Injectable()
export class PMPEffect extends DocumentEffet<PMP, any, CreatePMPDto, UpdatePMPDto> {
  /**
   * @inheritDoc
   * @protected
   */
  protected get actions(): DocumentStoreActions<PMP, PMP['id']> {
    return pmpActions;
  }

  constructor(
    private readonly commandBus: CommandBus,
    private readonly queryBus: QueryBus,
    store: Store<AppState>,
    actions$: Actions,
  ) {
    super(actions$, store);
  }

  protected loadDocuments(params?: SerializedPMPSearchParams): Observable<PMP[]> {
    return from(this.queryBus.request(new ListPMPQuery(params)));
  }

  protected loadDocument(id: PMP['id']): Observable<PMP> {
    return from(this.queryBus.request(new GetPMPQuery(id)));
  }

  protected updateDocument(id: PMP['id'], update: UpdatePMPDto): Observable<PMP> {
    return from(this.commandBus.execute<UpdatePMPCommandResult>(new UpdatePMPCommand(update)));
  }
}
