import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  EhpadDemainRepository,
  EhpadDemainUpdatedEvent,
  EhpadDemainUpdateFailEvent,
  GetEhpadDemainQuery,
  UpdateEhpadDemainCommand,
  UpdateEhpadDemainDto,
} from '@application/bundles/ehpad-demain';
import { EHPAD_DEMAIN_TRANSLATE_CONTEXT } from '@application/bundles/ehpad-demain/ehpad-demain.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { isLocalMedia, isMedia, LocalMedia, Media, MediaBucket, TemporaryMedia } from '@domain/media';
import { EhpadDemain } from '@domain/ehpad-demain';

@HandleCommand({
  command: UpdateEhpadDemainCommand,
})
export class UpdateEhpadDemainCommandHandler implements CommandHandler<UpdateEhpadDemainCommand, EhpadDemain> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: EhpadDemainRepository,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: UpdateEhpadDemainCommand): Promise<EhpadDemain> {
    const { ehpadDemain } = command;

    try {
      const updated = await this.sanitize(ehpadDemain)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.updateEhpadDemain(dto));

      this.eventDispatcher.dispatch(new EhpadDemainUpdatedEvent(updated));

      return updated;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: UpdateEhpadDemainDto): Promise<UpdateEhpadDemainDto> {
    /* const ehpadDemain = await this.queryBus.request<EhpadDemain>(new GetEhpadDemainQuery(dto.id));

     const attributes: Attribute[] = [
       {feature: EHPAD_DEMAIN_FEATURE, value: CRUD_ACTIONS.UPDATE}
     ];

     if (!await this.authorization.canUpdate(ehpadDemain)) {
       this.logger.error('Ehpad de demain update : Forbidden');
       throw new ForbiddenError();
     }*/

    return dto;
  }

  private async sanitize(dto: UpdateEhpadDemainDto): Promise<UpdateEhpadDemainDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('Ehpad de demain update : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdateEhpadDemainDto): Promise<UpdateEhpadDemainDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('Ehpad de demain update : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à mettre à jour cette expérimentation EHPAD de Demain.",
        undefined,
        EHPAD_DEMAIN_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new EhpadDemainUpdateFailEvent(message));

    return error;
  }

  private async updateEhpadDemain(dto: UpdateEhpadDemainDto): Promise<EhpadDemain> {
    const current = await this.queryBus.request(new GetEhpadDemainQuery(dto.id));

    const attachments: {
      convention?: TemporaryMedia;
      otherConvention?: TemporaryMedia;
      medias?: TemporaryMedia[];
    } = {};

    let deleteMedias: Array<Media['id']> = [];

    if (dto.conventionId !== current.conventionId || dto.convention !== undefined) {
      if (isLocalMedia(dto.convention)) {
        attachments.convention = await this.mediaBucket.add(dto.convention);
        dto.conventionId = attachments.convention.id;
        deleteMedias.push(current.conventionId);
      } else if (isMedia(dto.convention)) {
        dto.conventionId = dto.convention.id;

        if (dto.conventionId !== current.conventionId) {
          deleteMedias.push(current.conventionId);
        }
      }
    }

    if (dto.otherConventionId !== current.otherConventionId || dto.otherConvention !== undefined) {
      if (isLocalMedia(dto.otherConvention)) {
        attachments.otherConvention = await this.mediaBucket.add(dto.otherConvention);
        dto.otherConventionId = attachments.otherConvention.id;
        deleteMedias.push(current.otherConventionId);
      } else if (isMedia(dto.otherConvention)) {
        dto.otherConventionId = dto.otherConvention.id;

        if (dto.otherConventionId !== current.otherConventionId) {
          deleteMedias.push(current.otherConventionId);
        }
      }
    }

    if (Array.isArray(dto.medias)) {
      attachments.medias = [];
      const ids: Set<Media['id']> = new Set();
      const medias: LocalMedia[] = [];

      dto.medias.forEach(media => {
        if (isLocalMedia(media)) {
          medias.push(media);
        } else if (isMedia(media)) {
          ids.add(media.id);
        }
      });

      for (const media of medias) {
        if (isLocalMedia(media)) {
          const temporary = await this.mediaBucket.add(media);
          attachments.medias.push(temporary);
          ids.add(temporary.id);
        }
      }

      deleteMedias = [...deleteMedias, ...Array.from(dto.mediaIds || []).filter(id => !ids.has(id))];

      dto.mediaIds = Array.from(ids.values());
    }

    try {
      const updated = await this.repository.update(dto.id, dto);

      for (const id of deleteMedias) {
        if (id) {
          await this.mediaBucket.delete(id);
        }
      }

      return updated;
    } catch (e) {
      if (attachments.convention) {
        await attachments.convention.markForDeletion();
      }

      if (attachments.otherConvention) {
        await attachments.otherConvention.markForDeletion();
      }

      if (attachments.medias) {
        await Promise.all(attachments.medias.map(media => media.markForDeletion()));
      }

      throw e;
    }
  }
}
