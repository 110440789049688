import {Store, StoreKey} from "@application/framework/store/store";

export class InMemoryAgnosticStore<T = any> implements Store<T> {

  protected store: Map<StoreKey, T> = new Map();

  public get(key: StoreKey): T | undefined {
    return this.store.get(key);
  }

  public set(key: StoreKey, value: T) {
    this.store.set(key, value);
  }

  public has(key: StoreKey): boolean {
    return false;
  }

  public remove(key: StoreKey): void {
  }

  public clear(): void {
    this.store.clear();
  }
}
