import { HealthFundingStoreState } from '@easyhpad-ui/app/bundles/health-funding/store/health-funding.state';
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { TaxonomyTerm } from '@domain/taxonomy';
import { healthFundingActions } from '@easyhpad-ui/app/bundles/health-funding/store/health-funding.actions';

export const healthFundingTaxonomyAdapter = createEntityAdapter<TaxonomyTerm>();

const initialState: HealthFundingStoreState = {
  taxonomies: {
    recurring: healthFundingTaxonomyAdapter.getInitialState(),
    nonRecurring: healthFundingTaxonomyAdapter.getInitialState(),
    exceptional: healthFundingTaxonomyAdapter.getInitialState(),
  },
};

export const healthFundingReducer = createReducer(
  initialState,
  on(healthFundingActions.allTermsLoaded, (state, { taxonomy, terms }) => ({
    ...state,
    taxonomies: {
      ...state.taxonomies,
      [taxonomy]: healthFundingTaxonomyAdapter.upsertMany(terms, state.taxonomies[taxonomy]),
    },
  })),
  on(healthFundingActions.termLoaded, (state, { taxonomy, term }) => ({
    ...state,
    taxonomies: {
      ...state.taxonomies,
      [taxonomy]: healthFundingTaxonomyAdapter.upsertOne(term, state.taxonomies[taxonomy]),
    },
  })),
  on(healthFundingActions.termCreated, (state, { taxonomy, term }) => ({
    ...state,
    taxonomies: {
      ...state.taxonomies,
      [taxonomy]: healthFundingTaxonomyAdapter.addOne(term, state.taxonomies[taxonomy]),
    },
  })),
);
