export enum PhoneRegionCode {
  FR = 'FR',
  DE = 'DE'
}

export interface PhoneNumber {

  number: string;

  region: string;
  
  toString(): string;
}
