import { CreateLocalCPOMDto, StoredCPOM, UpdateLocalCPOMDto } from '@application/bundles/cpom';
import { LocalMedia, Media } from '@domain/media';
import { CreateCPOMAnnex, UpdateCPOMAnnex } from './cpom-annex.dto';

export interface CreateCPOMDto
  extends Omit<
    StoredCPOM,
    'id' | 'year' | 'end' | 'children' | 'isSingle' | 'singleChild' | 'annexes' | 'document' | 'documentId'
  > {
  children: CreateLocalCPOMDto[];

  documentId?: Media['id'];

  document: LocalMedia;

  annexes?: Array<CreateCPOMAnnex>;
}

export interface UpdateCPOMDto
  extends Omit<
    StoredCPOM,
    'id' | 'year' | 'end' | 'children' | 'isSingle' | 'singleChild' | 'document' | 'documentId' | 'annexes'
  > {
  children: UpdateLocalCPOMDto[];

  documentId?: Media['id'];

  document?: LocalMedia | Media;

  annexes?: Array<CreateCPOMAnnex | UpdateCPOMAnnex>;
}
