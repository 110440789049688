import {DateParser} from "@application/framework/date/date.parser";
import {parseISO} from "date-fns";

export class DateFnsParser implements DateParser {


  public parse(value: string): Date {
    return this.fromISOString(value);
  }

  public fromISOString(value: string): Date {

    value = this.padDateString(value);

    return parseISO(value);
  }


  /**
   * Using to prevent error if year is too small
   * @param value
   * @private
   */
  private padDateString(value: string): string {

    if (value.length > 6 && value.length < 10) {
      return value.padStart(10, '0');
    }
    return value;
  }
}
