import {
  ActivityAnnexSearchParams,
  CreateActivityAnnexDto,
  UpdateActivityAnnexDto
} from "@application/bundles/activity-annex";
import {ActivityAnnex} from "@domain/activity-annex";

export abstract class ActivityAnnexRepository {

  public abstract list(params?: ActivityAnnexSearchParams): Promise<ActivityAnnex[]>;

  public abstract get(id: ActivityAnnex['id']): Promise<ActivityAnnex>;

  public abstract create(id: CreateActivityAnnexDto): Promise<ActivityAnnex>;

  public abstract update(id: ActivityAnnex['id'], update: Partial<UpdateActivityAnnexDto>): Promise<ActivityAnnex>;

  public abstract delete(id: ActivityAnnex['id']): Promise<ActivityAnnex>;

}
