import { createReducer, on } from '@ngrx/store';
import { AuthentificationStoreState, StoreSerializedAccount } from './authentification.state';
import { authentificationActions } from '@easyhpad-ui/app/bundles/authentification/store/authentification.action';
import { AuthentificationToken } from '@application/bundles/authentification';
import { AgnosticRoleCollection } from '@application/bundles/authorization/implementations/agnostic-role-collection/agnostic-role-collection';
import { AgnosticPermissionCollection } from '@application/bundles/authorization/implementations/agnostic-permission-collection/agnostic-permission-collection';

const initialState: AuthentificationStoreState = {
  checked: false,
  logged: false,
  account: null,
};

const providerMap = new Map<string, Symbol>();

export const serializeAccount = (account: AuthentificationToken): StoreSerializedAccount => {
  if (typeof account.provider === 'symbol') {
    providerMap.set(account.provider.toString(), account.provider);
  }

  return {
    ...account,
    provider: account.provider.toString(),
    roles: account.roles.all(),
    permissions: account.permissions.all(),
  };
};
export const deserializeAccount = (account: StoreSerializedAccount): AuthentificationToken => {
  const provider = providerMap.get(account.provider) ?? account.provider;

  return {
    ...account,
    provider: provider,
    roles: new AgnosticRoleCollection(account.roles),
    permissions: new AgnosticPermissionCollection(account.permissions),
  };
};

export const authentificationReducer = createReducer(
  initialState,
  on(authentificationActions.setAuthenticationChecked, state => ({
    ...state,
    checked: true,
  })),
  on(authentificationActions.setCurrentAccount, (state, { account }) => ({
    ...state,
    logged: !!(account && account.isAuthenticated),
    account: account ? serializeAccount(account) : null,
  })),
  on(authentificationActions.logout, state => ({
    ...state,
    account: null,
    logged: false,
  })),
);
