import {ActivityAnnexRepository, GetActivityAnnexForYearQuery} from "@application/bundles/activity-annex";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {getYearDateInterval} from "@application/framework/date";
import {ListActivityAnnexParamsImpl} from "@application/bundles/activity-annex/implementations";
import {ActivityAnnex} from "@domain/activity-annex";

@HandleQuery({
  query: GetActivityAnnexForYearQuery
})
export class HasActivityAnnexForYearQueryHandler implements QueryHandler<GetActivityAnnexForYearQuery, ActivityAnnex | undefined> {

  constructor(private readonly repository: ActivityAnnexRepository) {
  }

  public async handle(query: GetActivityAnnexForYearQuery): Promise<ActivityAnnex | undefined> {

    const {start, end} = getYearDateInterval(query.year);

    return this.repository.list(new ListActivityAnnexParamsImpl().setDates(start, end).setFacilities(query.facilityId))
      .then(list => list[0]);
  }
}
