import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { DateString } from '@application/framework/date';
import { CreateEasyLocalERRD, EasyLocalERRD, UpdateEasyLocalERRD } from './easy-local-errd';
import { UUID, Year } from '@domain/lib';

export interface EasyERRD {
  id: UUID;

  accountingYear: Year;

  depositDate: DateString | null | undefined;

  details: EasyLocalERRD[];

  completeDocumentId: EasyMedia['id'];

  payrollId: EasyMedia['id'];

  financialAnnexId: EasyMedia['id'];

  activityAnnexId: EasyMedia['id'];

  reportId: EasyMedia['id'] | null | undefined;
}

export interface CreateEasyERRD extends Omit<EasyERRD, 'id' | 'details'> {
  details: Array<CreateEasyLocalERRD>;
}

export interface UpdateEasyERRD extends Omit<EasyERRD, 'details'> {
  details: Array<UpdateEasyLocalERRD>;
}
