import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { PhoneValidator } from '@application/bundles/phone/validator/phone.validator';
import { PhoneRegionCode } from '@domain/phone/phone.interface';
import { configuration } from '@easyhpad-ui/environments/configuration';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberValidator implements AsyncValidator {
  private region: PhoneRegionCode = configuration.defaultPhoneNumberRegion;

  constructor(private readonly validator: PhoneValidator) {
    this.validate = this.validate.bind(this);
  }

  public async validate(control: AbstractControl): Promise<ValidationErrors | null> {
    if (!control.value) {
      return null;
    }

    return this.validator
      .validatePhoneString(control.value, this.region)
      .then(() => null)
      .catch(() => ({ invalidPhoneNumber: true }));
  }

  public forRegion(region: PhoneRegionCode): PhoneNumberValidator {
    const validator = new PhoneNumberValidator(this.validator);
    validator.region = region;
    return validator;
  }
}
