import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupportUrlsProvider} from "./providers";
import {Bundle} from "@application/framework/bundle";
import {SupportBundle} from "@application/bundles/support/support.bundle";


@Bundle({imports: [SupportBundle]})
@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    SupportUrlsProvider
  ]
})
export class SupportModule {
}
