import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CreateEhpadDemainCommand,
  CreateEhpadDemainDto,
  EhpadDemainCreatedEvent,
  EhpadDemainCreationFailEvent,
  EhpadDemainRepository,
} from '@application/bundles/ehpad-demain';
import { EHPAD_DEMAIN_TRANSLATE_CONTEXT } from '@application/bundles/ehpad-demain/ehpad-demain.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { MediaBucket, TemporaryMedia } from '@domain/media';
import { EhpadDemain } from '@domain/ehpad-demain';

@HandleCommand({
  command: CreateEhpadDemainCommand,
})
export class CreateEhpadDemainCommandHandler implements CommandHandler<CreateEhpadDemainCommand, EhpadDemain> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: EhpadDemainRepository,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: CreateEhpadDemainCommand): Promise<EhpadDemain> {
    const { ehpadDemain } = command;
    try {
      const created = await this.sanitize(ehpadDemain)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.storePvAndCreateEhpadDemain(dto));

      this.eventDispatcher.dispatch(new EhpadDemainCreatedEvent(created));

      return created;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: CreateEhpadDemainDto): Promise<CreateEhpadDemainDto> {
    /* if (!await this.authorization.canCreate()) {
       this.logger.error('Ehpad de demain creation : Forbidden');
       throw new ForbiddenError();
     }*/

    return dto;
  }

  private async sanitize(dto: CreateEhpadDemainDto): Promise<CreateEhpadDemainDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('Ehpad de demain creation : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: CreateEhpadDemainDto): Promise<CreateEhpadDemainDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('Ehpad de demain creation : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à créer une nouvelle expérimentation EHPAD de Demain.",
        undefined,
        EHPAD_DEMAIN_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new EhpadDemainCreationFailEvent(message));

    return error;
  }

  private async storePvAndCreateEhpadDemain(dto: CreateEhpadDemainDto): Promise<EhpadDemain> {
    const attachments: {
      convention?: TemporaryMedia;
      otherConvention?: TemporaryMedia;
      medias?: TemporaryMedia[];
    } = {};

    try {
      if (dto.convention) {
        attachments.convention = await this.mediaBucket.add(dto.convention);
        dto.conventionId = attachments.convention.id;
      }

      if (dto.otherConvention) {
        attachments.otherConvention = await this.mediaBucket.add(dto.otherConvention);
        dto.otherConventionId = attachments.otherConvention.id;
      }

      if (Array.isArray(dto.medias)) {
        attachments.medias = [];
        const ids: Array<TemporaryMedia['id']> = [];

        for (const media of dto.medias) {
          const temporary = await this.mediaBucket.add(media);
          attachments.medias.push(temporary);
          ids.push(temporary.id);
        }

        dto.mediaIds = ids;
      }

      return this.repository.create(dto);
    } catch (e) {
      if (attachments.convention) {
        await attachments.convention.markForDeletion();
      }

      if (attachments.otherConvention) {
        await attachments.otherConvention.markForDeletion();
      }

      if (attachments.medias) {
        await Promise.all(attachments.medias.map(media => media.markForDeletion()));
      }

      throw e;
    }
  }
}
