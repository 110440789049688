import {ReadOnlyCollection} from "@application/framework/store/collection";
import {TariffOption} from "@domain/cpom/tariff-option";

export abstract class TariffOptionCollection implements ReadOnlyCollection<TariffOption> {

  public abstract has(type: TariffOption['type']): boolean;

  public abstract list(): TariffOption[];

  public abstract get(type: TariffOption['type']): TariffOption | undefined;

}
