import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Injectable} from "@angular/core";
import {CreateCustomerDto} from "@application/bundles/customer/dto/create-customer.dto";
import {UpdateCustomerDto} from "@application/bundles/customer/dto/update-customer.dto";
import {Common} from "@application/framework/lib";
import {BILLING_MODE, Customer, isValidBillingMode, isValidBillingModeValue} from "@domain/customer";
import {BillingModeCollection} from "@implementations/bundles/customer/implementations";
import {isSelectOption} from "@implementations/forms";

type CommonProperties = Common<CreateCustomerDto, UpdateCustomerDto>;

@Injectable()
export class CustomerFormBuilder {


  public static readonly BILLING_MODE_CONTROL_NAME = 'billingMode';

  public static readonly BILLING_ADDRESS_CONTROL_NAME = 'billingAddress';


  constructor(
    private readonly formBuilder: FormBuilder,
    private billingModeCollection: BillingModeCollection
  ) {
  }


  public getCreationForm(customer?: Customer): FormGroup<Record<keyof CreateCustomerDto, AbstractControl>> {
    return this.formBuilder.group(this.getCommonControls(this.serialize(customer)));
  }

  public getUpdateForm(customer?: Customer): FormGroup<Record<keyof UpdateCustomerDto, AbstractControl>> {

    return this.formBuilder.group({
      ...this.getCommonControls(this.serialize(customer)),
      id: new FormControl(customer?.id) as AbstractControl,
    });
  }


  public serialize(customer?: Partial<Customer>): Record<keyof CommonProperties, any> {

    return {
      address: customer?.address,
      billingAddress: customer?.billingAddress,
      billingMode: customer?.billingMode?.mode ? customer.billingMode.mode : BILLING_MODE.CURRENT_ADDRESS,
      blocked: !!customer?.blocked,
      name: customer?.name,
      phone: customer?.phone

    };
  }

  public deserialize<T extends CreateCustomerDto | UpdateCustomerDto>(values: Record<keyof CommonProperties, any> & { id?: number }): T {


    const deserialized: Record<keyof CommonProperties, any> & { id?: number } = {
      name: values?.name,
      address: values?.address,
      billingAddress: values?.billingAddress,
      billingMode: undefined,
      blocked: !!values?.blocked,
      phone: values?.phone
    };

    if (isValidBillingMode(values?.billingMode)) {
      deserialized.billingMode = values?.billingMode;
    } else if (isValidBillingModeValue(values?.billingMode) && this.billingModeCollection.has(values?.billingMode as BILLING_MODE)) {
      deserialized.billingMode = this.billingModeCollection.get(values?.billingMode as BILLING_MODE);
    } else if (isSelectOption<BILLING_MODE>(values?.billingMode) && isValidBillingModeValue(values?.billingMode.value)) {
      deserialized.billingMode = this.billingModeCollection.get(values?.billingMode.value);
    }

    if (values && 'id' in values) {
      deserialized['id'] = values.id;
    }

    return deserialized as T;

  }

  public rebuildValues(form: FormGroup<Record<keyof CreateCustomerDto | keyof UpdateCustomerDto, AbstractControl>>, customer?: Partial<Customer>) {
    if (!form) {
      return;
    }

    form.patchValue(this.serialize(customer));
  }

  private getCommonControls(defaults?: Partial<Record<keyof CommonProperties, any>>): Record<keyof CommonProperties, AbstractControl> {
    return {
      name: new FormControl(defaults?.name, [Validators.required]),
      billingMode: new FormControl(defaults?.billingMode, [Validators.required]),
      address: new FormControl(defaults?.address, [Validators.required]),
      billingAddress: new FormControl(defaults?.billingAddress),
      phone: new FormControl(defaults?.phone),
      blocked: new FormControl(defaults?.blocked),
    }
  }
}
