import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  EPRD_ERRD_TRANSLATE_CONTEXT,
  EPRDAuthorizationChecker,
  EPRDRepository,
  EPRDUpdatedEvent,
  EPRDUpdateFailEvent,
  GetEPRDQuery,
  UpdateEPRDCommand,
  UpdateEPRDDto,
} from '@application/bundles/eprd-errd';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { EPRD } from '@domain/eprd-errd';
import { isLocalMedia, isMedia, LocalMedia, Media, MediaBucket, TemporaryMedia } from '@domain/media';
import { eprdErrdDocumentListPropertyMap } from '@application/bundles/eprd-errd/eprd-errd-document-list-property-map';

@HandleCommand({
  command: UpdateEPRDCommand,
})
export class UpdateEPRDCommandHandler implements CommandHandler<UpdateEPRDCommand, EPRD> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: EPRDRepository,
    private readonly authorization: EPRDAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: UpdateEPRDCommand): Promise<EPRD> {
    const { eprd } = command;
    try {
      const updated = await this.sanitize(eprd)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.updateEPRD(dto));

      this.eventDispatcher.dispatch(new EPRDUpdatedEvent(updated));

      return updated;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: UpdateEPRDDto): Promise<UpdateEPRDDto> {
    const eprd = await this.queryBus.request<EPRD>(new GetEPRDQuery(dto.id));

    if (!(await this.authorization.canUpdate(eprd))) {
      this.logger.error('E.P.R.D update : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: UpdateEPRDDto): Promise<UpdateEPRDDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('E.P.R.D update : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdateEPRDDto): Promise<UpdateEPRDDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('E.P.R.D update : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à mettre à jour cet E.P.R.D.",
        undefined,
        EPRD_ERRD_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new EPRDUpdateFailEvent(message));

    return error;
  }

  private async updateEPRD(dto: UpdateEPRDDto): Promise<EPRD> {
    const current = await this.queryBus.request(new GetEPRDQuery(dto.id));
    const documents: TemporaryMedia[] = [];

    for (const [idProperty, documentProperty] of eprdErrdDocumentListPropertyMap.entries()) {
      const media = dto[documentProperty] as LocalMedia | Media | undefined;
      const id: Media['id'] | undefined = dto[idProperty] as Media['id'];

      if (id !== current[idProperty] || isLocalMedia(media)) {
        let document: TemporaryMedia | undefined;

        if (isLocalMedia(media)) {
          document = await this.mediaBucket.add(media);
          dto[idProperty] = document.id as any;
        } else if (isMedia(media) && (media.id !== current[idProperty] || media.id !== dto[idProperty])) {
          dto[idProperty] = media?.id as any;
        }

        if (document) {
          documents.push(document);
        }
      }
    }

    try {
      return await this.repository.update(dto.id, dto);
    } catch (e) {
      for (const document of documents) {
        await document.markForDeletion();
      }

      throw e;
    }
  }
}
