import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {HttpAuthorizationToken, HttpAuthorizationTokenStore} from "@application/framework/http";
import {EasyBackendApi} from "@implementations/bundles/backend/easyhpad/api";
import {EASYHPAD_BEARER_AUTH_TOKEN} from "@implementations/bundles/backend/easyhpad/backend.tokens";
import {
  RefreshHttpAuthorizationTokenCommand
} from "@implementations/bundles/backend/easyhpad/commands/refresh-http-authorization-token.command";
import {
  EasyHpadBearerHttpTokenFactory
} from "@implementations/bundles/backend/easyhpad/factories/bearer-http-token-factory";
import {
  HttpAuthorizationResponse
} from "@implementations/bundles/backend/easyhpad/interfaces/http-authorization-response.interface";


@HandleCommand({
  command: RefreshHttpAuthorizationTokenCommand
})
export class RefreshHttpBearerAuthorizationTokenCommandHandler implements CommandHandler<RefreshHttpAuthorizationTokenCommand<any>> {

  public readonly path = 'auth/refresh';

  constructor(
    private tokenStore: HttpAuthorizationTokenStore,
    private tokenFactory: EasyHpadBearerHttpTokenFactory,
    private backend: EasyBackendApi
  ) {
  }

  public async handle<T extends HttpAuthorizationToken<any>>(command: RefreshHttpAuthorizationTokenCommand<T>): Promise<T> {
    const response = await this.backend.post<HttpAuthorizationResponse>(this.path, {});
    const token = this.tokenFactory.createFromHttpAuthorizationResponse(response.body);
    this.tokenStore.set(EASYHPAD_BEARER_AUTH_TOKEN, token);
    return token as T;
  }

}
