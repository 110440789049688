import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryBus } from '@application/framework/command-query';
import { Facility } from '@domain/facility';
import { GMP } from '@domain/gmp';
import { PMP } from '@domain/pmp';
import { Subscription } from '@application/framework/reactive';
import { GmpUrlsProvider } from '@easyhpad-ui/app/bundles/gmp';
import { PMPUrlsProvider } from '@easyhpad-ui/app/bundles/pmp';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { selectDashboardFacilities } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.selector';
import { DateFormatter } from '@application/framework/date';
import { GetLatestGMPWidgetContentQuery, GetPmpWidgetContentQuery } from '@easyhpad-ui/app/bundles/dashboard/queries';
import { LatestGMPWidgetContent, PMPWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';
import { values } from '@domain/lib';

interface Indicator<T> {
  type: 'pmp' | 'gmp';

  label: string;

  emptyLabel: string;

  loading: boolean;

  count: number;

  date?: string;

  value?: number | undefined;

  national?: number | undefined;

  average?: { value: number; total: number };

  url?: string;
}

@Component({
  selector: 'ehp-widget-validate-indicators',
  templateUrl: './widget-validate-indicators.component.html',
  styleUrls: ['./widget-validate-indicators.component.scss'],
})
export class WidgetValidateIndicatorsComponent implements OnInit, OnDestroy {
  public readonly indicators: { gmp: Indicator<GMP> | undefined; pmp: Indicator<PMP> | undefined } = {
    gmp: undefined,
    pmp: undefined,
  };

  public urls: { pmp?: string; gmp?: string } = {};

  private subscription: Subscription | undefined;

  public get indicatorArray(): Array<Indicator<any> | undefined> {
    return values(this.indicators).filter(indicator => !!indicator);
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly queryBus: QueryBus,
    private readonly gmpUrls: GmpUrlsProvider,
    private readonly pmpUrls: PMPUrlsProvider,
    private readonly dateFormatter: DateFormatter,
  ) {
    this.gmpUrls.getPublicCreate().then(url => (this.urls.gmp = url));
    this.pmpUrls.getPublicCreate().then(url => (this.urls.pmp = url));
  }

  public ngOnInit() {
    this.subscription = this.store
      .select(selectDashboardFacilities)
      .subscribe(facilities => this.facilitiesChanges(facilities));
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private async facilitiesChanges(facilities: Array<Facility['id']>): Promise<void> {
    this.reset();

    if (this.indicators.gmp) {
      this.indicators.gmp.loading = true;
    }

    if (this.indicators.pmp) {
      this.indicators.pmp.loading = true;
    }

    this.queryBus.request(new GetPmpWidgetContentQuery(facilities)).then(response => this.buildPMPValue(response));
    this.queryBus
      .request(new GetLatestGMPWidgetContentQuery(facilities))
      .then(response => this.buildGMPValue(response));
  }

  private async buildPMPValue(content: PMPWidgetContent) {
    this.indicators.pmp = {
      ...this.getDefaultPMPIndicator(),
      date: content.date ? this.dateFormatter.format(content.date, 'dd/MM/yyyy') : undefined,
      url: content.pmpId ? await this.pmpUrls.getPublicShow(content.pmpId) : undefined,
      value: content.value ?? 0,
      count: content.facilityId ? 1 : content.average ? content.average.total : 0,
      average: undefined,
    };

    if (content.average) {
      this.indicators.pmp.average = {
        value: content.average.value,
        total: content.average.total,
      };
    }

    this.indicators.pmp.loading = false;
  }

  private async buildGMPValue(content: LatestGMPWidgetContent) {
    this.indicators.gmp = {
      ...this.getDefaultGMPIndicator(),
      date: content.date ? this.dateFormatter.format(content.date, 'dd/MM/yyyy') : undefined,
      url: content.gmpId ? await this.gmpUrls.getPublicShow(content.gmpId) : undefined,
      value: content.value ?? 0,
      count: content.facilityId ? 1 : content.average ? content.average.total : 0,
      average: undefined,
    };

    if (content.average) {
      this.indicators.gmp.average = {
        value: content.average.value,
        total: content.average.total,
      };
    }

    this.indicators.gmp.loading = false;
  }

  private reset() {
    this.indicators.gmp = this.getDefaultGMPIndicator();
    this.indicators.pmp = this.getDefaultPMPIndicator();
  }

  private getDefaultGMPIndicator(): Indicator<GMP> {
    return {
      type: 'gmp',
      loading: false,
      label: 'G.M.P.',
      emptyLabel: 'Aucun G.M.P. archivé',
      count: 0,
    };
  }

  private getDefaultPMPIndicator(): Indicator<PMP> {
    return {
      type: 'pmp',
      loading: false,
      label: 'P.M.P.',
      emptyLabel: 'Aucun P.M.P. archivé',
      count: 0,
    };
  }
}
