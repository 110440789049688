<ehp-form-field *ngIf="control" [prefix]="field.prefix" [suffix]="field.suffix">
  <label *ngIf="field.label" [for]="field.name">
    <ehp-translatable-string [value]="field.label"></ehp-translatable-string>
  </label>

  <p *ngIf="field.description" class="description">
    <ehp-translatable-string [value]="field.description"></ehp-translatable-string>
  </p>

  <input #input (input)="updateValue(value)" [(ngModel)]="value" [id]="field.name"
         ehp-form-control>

</ehp-form-field>
