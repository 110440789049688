import { Component, OnDestroy } from '@angular/core';
import { CurrentFacilitiesStore } from '@application/bundles/facility/store';
import { DashboardFacilitiesStore } from '@implementations/bundles/facility';
import { Subscription } from '@application/framework/reactive';
import { MainDashboardWidgetCollection } from '@easyhpad-ui/app/bundles/dashboard/modules/public/collections/dashboard-widgets-collection';
import { DashboardWidgetContentReference } from '@easyhpad-ui/app/bundles/dashboard/interfaces/dashboard-widget-content.reference';
import { Facility } from '@domain/facility';

@Component({
  selector: 'ehp-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnDestroy {
  public mode: 'blocks' | 'list' = 'blocks';

  public widgets: DashboardWidgetContentReference<any>[] = [];

  public configuration = MainDashboardWidgetCollection.config;

  private subscription: Subscription;

  public get isBlocksMode(): boolean {
    return this.mode === 'blocks';
  }

  public get isListMode(): boolean {
    return this.mode === 'list';
  }

  constructor(
    private readonly facilitiesStore: CurrentFacilitiesStore,
    private readonly dashboardStore: DashboardFacilitiesStore,
    private readonly collection: MainDashboardWidgetCollection,
  ) {
    this.facilitiesStore.get().then(facilities => this.setFacilities(facilities));
    this.subscription = this.facilitiesStore.onChange(facilities => this.dashboardStore.set(...facilities));
    this.collection.list().then(widgets => (this.widgets = widgets));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public switchModeTo(mode: DashboardPageComponent['mode']): void {
    this.mode = mode;
  }

  private async setFacilities(facilities: Facility[]): Promise<void> {
    if (facilities.length === 0) {
      facilities = await this.facilitiesStore.available();
    }

    await this.dashboardStore.set(...facilities);
  }
}
