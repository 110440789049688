import {ConstructorProvider, FactoryProvider, StaticClassProvider, ValueProvider} from "@angular/core";
import {ServiceMetadata} from "@application/framework/di/interfaces/service-metadata.interface";
import {
  createInjectableProviderFromMetadata
} from "@easyhpad-ui/app/framework/di/functions/create-injectable-provider-from-metadata";
import {wrapServiceIdentifier} from "@easyhpad-ui/app/framework/di/functions/wrap-service-identifier.function";

type Provider = ValueProvider | StaticClassProvider | ConstructorProvider | FactoryProvider;

export const createStaticProviderFromServiceMetadata = (metadata: ServiceMetadata): Provider => {
  return {
    provide: wrapServiceIdentifier(metadata.id),
    ...createInjectableProviderFromMetadata(metadata),
  } as Provider;
}
