import {ForbiddenError} from "@application/bundles/authorization/error";
import {
  CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
  CapacityAuthorizationAuthorizationChecker
} from "@application/bundles/capacity-authorization";
import {DeleteInspectionReportCommand} from "@application/bundles/capacity-authorization/commands";
import {
  UpdateCapacityAuthorizationDto
} from "@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto";
import {
  InspectionReportDeletedEvent
} from "@application/bundles/capacity-authorization/events/delete-inspection-report/inspection-report-deleted.event";
import {
  InspectionReportDeletionFailEvent
} from "@application/bundles/capacity-authorization/events/delete-inspection-report/inspection-report-deletion-fail.event";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {SanitizationFailError} from "@application/framework/sanitizer/errors";
import {Translator} from "@application/framework/translation";
import {ValidationError} from "@application/framework/validator/errors/validation.error";
import {CapacityAuthorization, CapacityAuthorizationRepository, InspectionReport} from "@domain/capacity-authorization";
import {MediaBucket} from "@domain/media";

@HandleCommand({
  command: DeleteInspectionReportCommand
})
export class DeleteInspectionReportCommandHandler implements CommandHandler<DeleteInspectionReportCommand, InspectionReport> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly authorizationChecker: CapacityAuthorizationAuthorizationChecker,
    private readonly authorizationRepository: CapacityAuthorizationRepository,
    private readonly mediaBucket: MediaBucket,
    private readonly eventDispatcher: EventDispatcher,
    private readonly translator: Translator,
  ) {
  }


  public async handle(command: DeleteInspectionReportCommand): Promise<InspectionReport> {

    try {
      const authorization = await this.checkAccessAndGetAuthorization(command.authorizationId);

      const report = await this.deleteReport(authorization, command.id);

      this.eventDispatcher.dispatch(new InspectionReportDeletedEvent(report));
      return report;


    } catch (e) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }


  private async checkAccessAndGetAuthorization(id: CapacityAuthorization['id']): Promise<CapacityAuthorization> {

    const authorization = await this.authorizationRepository.get(id);

    if (!await this.authorizationChecker.canEdit(authorization)) {
      this.logger.error('Inspection report creation : Forbidden');
      throw new ForbiddenError();
    }

    return authorization;
  }

  private async deleteReport(authorization: CapacityAuthorization, id: InspectionReport['id']) {
    const reports = await authorization.inspectionReports();

    const index = reports.findIndex(current => current.id === id);

    if (index === -1) {
      throw new ForbiddenError(`Report (${id}) is not associated to Capacity Authorization (${authorization.id})`);
    }

    const report = reports[index];

    if (Array.isArray(report.mediaIds) && report.mediaIds.length > 0) {

      try {
        await Promise.all(report.mediaIds.map(id => this.mediaBucket.delete(id)));
      } catch (e) {
        this.logger.warning('Error during report media deletion', e);
      }

    }

    reports.splice(index, 1);

    const authorizationUpdate: Pick<UpdateCapacityAuthorizationDto, 'inspectionReports'> = {
      inspectionReports: reports
    }

    await this.authorizationRepository.update(authorization.id, authorizationUpdate);

    return report;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);

    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = await this.translator.translate('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à supprimer ce rapport d'inspection.",
        undefined,
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new InspectionReportDeletionFailEvent(message));

    return error;
  }


}
