import { CreateCustomerDto } from '@application/bundles/customer/dto/create-customer.dto';
import { UpdateCustomerDto } from '@application/bundles/customer/dto/update-customer.dto';
import { Common, isValidObject } from '@application/framework/lib';
import {
  IsBoolean,
  IsDefined,
  IsNotEmpty,
  IsNotEmptyObject,
  IsObject,
  IsOptional,
  IsString,
  Validate,
  ValidateIf,
} from 'class-validator';
import { Address } from '@domain/address';
import { BILLING_MODE, BillingMode, isValidBillingMode } from '@domain/customer';
import { IsBillingMode } from '@implementations/bundles/customer/validators/class-validator';
import { PhoneNumber } from '@domain/phone/phone.interface';
import { IsValidPhoneNumber } from '@implementations/bundles/phone/adapters/class-validator/is-valid-phone-number/is-valid-phone-number';

export abstract class CustomerCommonAbstractDto implements Common<CreateCustomerDto, UpdateCustomerDto> {
  @IsString()
  @IsNotEmpty()
  public name: string = '';

  @IsBillingMode()
  public billingMode!: BillingMode;

  @IsDefined()
  @IsNotEmptyObject()
  @IsObject()
  public address!: Address;

  @IsObject()
  @IsOptional()
  public billingAddress: Address | undefined;

  @ValidateIf((_: any, value?: PhoneNumber) => !!(value && value.number))
  @Validate(IsValidPhoneNumber)
  public phone?: PhoneNumber;

  @IsBoolean()
  @IsOptional()
  public blocked: boolean = false;

  constructor(defaults?: Partial<Common<CreateCustomerDto, UpdateCustomerDto>>) {
    if (defaults) {
      if (typeof defaults.name === 'string' && defaults.name) {
        this.name = defaults.name;
      }

      if (isValidBillingMode(defaults.billingMode)) {
        this.billingMode = defaults.billingMode;

        if (this.billingMode.mode === BILLING_MODE.BILLING_ADDRESS && isValidObject(defaults.billingAddress)) {
          this.billingAddress = defaults.billingAddress;
        }
      }

      if (isValidObject(defaults.address)) {
        this.address = defaults.address;
      }

      if (defaults.phone) {
        this.phone = defaults.phone;
      }

      if (defaults.blocked) {
        this.blocked = defaults.blocked;
      }
    }
  }
}
