<ehp-form-field *ngIf="control">
  <label *ngIf="field.label" [for]="field.name">
    <ehp-translatable-string [value]="field.label"></ehp-translatable-string>
  </label>

  <p *ngIf="field.description" class="description">
    <ehp-translatable-string [value]="field.description"></ehp-translatable-string>
  </p>

  <ehp-account-facility-selector
    [formControl]="control"
    [id]="field.name"
    [scope]="scope"
    ehp-form-control
  ></ehp-account-facility-selector>

</ehp-form-field>
