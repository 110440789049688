import {
  CreatePermanentDependencyFundingDto,
  PermanentDependencyFundingDtoFactory,
  UpdatePermanentDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {
  CreatePermanentDependencyFundingDtoImpl,
  UpdatePermanentDependencyFundingDtoImpl
} from "@implementations/bundles/dependency-funding/dto";

export class PermanentDependencyFundingDtoFactoryImpl implements PermanentDependencyFundingDtoFactory {


  public buildCreateDtoFromUnsafeValues(values: Partial<CreatePermanentDependencyFundingDto>): CreatePermanentDependencyFundingDto {
    return new CreatePermanentDependencyFundingDtoImpl(values);
  }


  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdatePermanentDependencyFundingDto>): UpdatePermanentDependencyFundingDto {
    return new UpdatePermanentDependencyFundingDtoImpl(values);
  }

}
