import {Command} from "@application/framework/command-query";
import {CPOM} from "@domain/cpom";
import {StoredCPOM} from "@application/bundles/cpom";

export class OpenCPOMDeletionDialogCommand implements Command<CPOM> {


  constructor(
    public readonly cpom: StoredCPOM,
    public readonly onDeletion?: (cpom: StoredCPOM) => void
  ) {
  }
}
