export abstract class CssUnitConverter {

  public abstract getUnit(value: string): string | null;

  public abstract toPx(value: string): number | null;

  public abstract toVw(value: string): number | null;

  public abstract toVh(value: string): number | null;
}
