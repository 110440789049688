import {Logger} from "@application/framework/logger";
import {MissingValidatorError} from "@application/framework/validator/errors";
import {ObjectValidator} from "@application/framework/validator/object-validator";
import {ObjectValidatorRegistry} from "@application/framework/validator/object-validator.registry";

export class AgnosticObjectValidator implements ObjectValidator {


  public constructor(
    private readonly registry: ObjectValidatorRegistry,
    private readonly logger: Logger,
    private readonly throwOnMissing = true,
  ) {
    this.logger = this.logger.channel(this.constructor.name);
  }

  public async validate<T>(object: T): Promise<T> {
    const validators = this.registry.get(object);

    if (validators === undefined || validators.size === 0) {
      const message = `No validator provided for ${Object.getPrototypeOf(object).constructor.name}`;

      if (this.throwOnMissing) {
        this.throwMissingValidator(message);
      } else {
        this.logger.warning(message);
      }
    }

    for (const validator of validators) {
      object = await validator(object);
    }

    return object;
  }

  private throwMissingValidator(message: string) {
    throw new MissingValidatorError(message);
  }

}
