import { Bundle } from '@application/framework/bundle';
import {
  AddCapacityAuthorizationCommandHandler,
  AddInspectionReportCommandHandler,
  DeleteCapacityAuthorizationCommandHandler,
  DeleteInspectionReportCommandHandler,
  UpdateCapacityAuthorizationCommandHandler,
  UpdateInspectionReportCommandHandler,
} from './commands/handlers';

import {
  CapacityAuthorizationUpdateEventSubscriber,
  DeleteCapacityAuthorizationEventSubscriber,
  OnCapacityAuthorizationCreationEventsSubscriber,
} from './events/subscribers';
import {
  GetApplicableCapacityAuthorizationQueryHandler,
  GetCapacityAuthorizationQueryHandler,
  GetLatestCapacityAuthorizationQueryHandler,
  ListCapacityAuthorizationQueryHandler,
} from './queries/handlers';

@Bundle({
  commandHandlers: [
    AddCapacityAuthorizationCommandHandler,
    UpdateCapacityAuthorizationCommandHandler,
    DeleteCapacityAuthorizationCommandHandler,
    AddInspectionReportCommandHandler,
    UpdateInspectionReportCommandHandler,
    DeleteInspectionReportCommandHandler,
  ],
  queryHandlers: [
    ListCapacityAuthorizationQueryHandler,
    GetCapacityAuthorizationQueryHandler,
    GetLatestCapacityAuthorizationQueryHandler,
    GetApplicableCapacityAuthorizationQueryHandler,
  ],
  eventSubscribers: [
    DeleteCapacityAuthorizationEventSubscriber,
    CapacityAuthorizationUpdateEventSubscriber,
    OnCapacityAuthorizationCreationEventsSubscriber,
  ],
})
export class CapacityAuthorizationBundle {}
