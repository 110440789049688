import { Component, OnDestroy, OnInit } from '@angular/core';
import { values, Year } from '@domain/lib';
import { QueryBus } from '@application/framework/command-query';
import { Subscription } from '@application/framework/reactive';
import { Facility } from '@domain/facility';
import { EPRDUrlsProvider, ERRDUrlsProvider } from '@easyhpad-ui/app/bundles/eprd-errd';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { DASHBOARD_SELECTORS } from '@easyhpad-ui/app/bundles/dashboard/store';
import { GetEprdErrdWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-eprd-errd-widget.query';
import { EprdErrdWidgetContent, WidgetAverage } from '@easyhpad-ui/app/bundles/dashboard/interfaces';

interface Column {
  type: 'eprd' | 'errd';
  name: string;
  data: null | {
    year: Year;
    value: number;
    caf: number;
    average?: WidgetAverage;
    url?: string;
  };
}

@Component({
  selector: 'ehp-widget-eprd-errd',
  templateUrl: './widget-eprd-errd.component.html',
  styleUrls: ['./widget-eprd-errd.component.scss'],
})
export class WidgetEPRDERRDComponent implements OnInit, OnDestroy {
  public data: { eprd: Column; errd: Column };

  public actions: { eprd?: string; errd?: string } = {};

  public readonly label = 'Résultats {{ type }} {{ year }}';

  public readonly empty = {
    single: 'Aucun {{ document }} renseigné pour cet établissement',
    plural: 'Aucun {{ document }} renseigné pour ces établissements',
  };

  public count: number = 0;

  private subscription: Subscription | undefined;

  public get isSingle(): boolean {
    return this.count <= 1;
  }

  public get columns(): Array<Column> {
    return values(this.data);
  }

  constructor(
    private readonly queryBus: QueryBus,
    private readonly eprdUrls: EPRDUrlsProvider,
    private readonly errdUrls: ERRDUrlsProvider,
    private readonly store: Store<AppState>,
  ) {
    this.data = this.createEmptyColumns();

    this.eprdUrls.getPublicCreate().then(url => (this.actions.eprd = url));
    this.errdUrls.getPublicCreate().then(url => (this.actions.errd = url));
  }

  public ngOnInit() {
    this.subscription = this.store.select(DASHBOARD_SELECTORS.selectDashboardFacilities).subscribe(facilities => {
      this.facilitiesChanges(facilities);
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public actionLink(type: any): string | undefined {
    if (type === 'eprd') {
      return this.actions.eprd;
    } else if (type === 'errd') {
      return this.actions.errd;
    }

    return;
  }

  private async facilitiesChanges(facilities: Array<Facility['id']>): Promise<void> {
    this.count = new Set(facilities).size;

    const content = await this.queryBus.request<EprdErrdWidgetContent>(new GetEprdErrdWidgetQuery(facilities));
    this.data = this.createEmptyColumns();

    if (content.eprdResult !== null) {
      this.data.eprd.data = {
        year: content.eprdYear,
        value: content.eprdResult,
        caf: content.eprdCaf ?? 0,
        average: content.eprdAverage,
        url: content.eprdId ? await this.eprdUrls.getPublicShow(content.eprdId) : undefined,
      };
    }

    if (content.errdResult !== null) {
      this.data.errd.data = {
        year: content.errdYear,
        value: content.errdResult,
        caf: content.errdCaf ?? 0,
        average: content.errdAverage,
        url: content.errdId ? await this.errdUrls.getPublicShow(content.errdId) : undefined,
      };
    }
  }

  private createEmptyColumns(): { eprd: Column; errd: Column } {
    return {
      eprd: {
        type: 'eprd',
        name: 'E.P.R.D.',
        data: null,
      },
      errd: {
        type: 'errd',
        name: 'E.R.R.D.',
        data: null,
      },
    };
  }
}
