import {SerializedActivityAnnexSearchParams} from "@application/bundles/activity-annex";
import {Query} from "@application/framework/command-query";
import {ActivityAnnex} from "@domain/activity-annex";

export class ListActivityAnnexQuery implements Query<ActivityAnnex[]> {

  constructor(public readonly params?: SerializedActivityAnnexSearchParams) {
  }

}
