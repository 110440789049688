import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {InspectionReport} from "@domain/capacity-authorization";

export class InspectionReportCreatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {
  
  constructor(public readonly report: InspectionReport) {
    super();
  }

}
