import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidEhpadDemainTypeValue} from "@domain/ehpad-demain";

@ValidatorConstraint({name: 'IsEhpadDemainType', async: false})
export class IsEhpadDemainTypeConstraint implements ValidatorConstraintInterface {

  public validate(type: any, args?: ValidationArguments): boolean {
    return isValidEhpadDemainTypeValue(type);
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'ID is not a valid EHPAD de Demain type.';
  }
}

export function IsEhpadDemainType(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsEhpadDemainTypeConstraint
    });
  };
}
