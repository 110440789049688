import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ehp-date-interval',
  templateUrl: './date-interval.component.html',
  styleUrls: ['./date-interval.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateIntervalComponent {

  @Input() public dates: { start?: Date, end?: Date } | undefined;

  @Input() public format: string = "dd/MM/yyyy";

  @Input() public endFormat: string | undefined;
}
