import {Sort, SORT_ORDER} from "@application/framework/search/sort";
import {isValidObject} from "@application/framework/lib";

export function isSort<K extends string = string>(sort: any): sort is Sort<K> {

  return isValidObject(sort)
    && typeof (sort as Sort<K>).key === "string"
    && (sort as Sort<K>).key !== ""
    && ((sort as Sort<K>).order === SORT_ORDER.ASC || (sort as Sort<K>).order === SORT_ORDER.DESC);

}
