import { DocumentListParamsImpl } from '@application/framework/search';
import { GMPSearchParams } from '@application/bundles/gmp';
import { SORT_ORDER } from '@application/framework/search/sort';

export class GMPSearchParamsImpl extends DocumentListParamsImpl implements GMPSearchParams {
  constructor() {
    super();
    this.setSort({ key: 'validationDate', order: SORT_ORDER.DESC });
  }
}
