import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FundingCollection } from '@domain/funding';
import { ExceptionalHealthFunding, HealthTaxonomyType } from '@domain/health-funding';

@Component({
  selector: 'ehp-exceptional-funding-table',
  templateUrl: './exceptional-funding-table.component.html',
  styleUrls: ['./exceptional-funding-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExceptionalFundingTableComponent {
  @Input() public funding!: FundingCollection<ExceptionalHealthFunding>;

  public taxonomy: HealthTaxonomyType = 'exceptional';
}
