import {createSelector} from '@ngrx/store';
import {EhpadDemainStoreState} from "./ehpad-demain.state";
import {EHPAD_DEMAIN_STORE_KEY} from "@easyhpad-ui/app/bundles/ehpad-demain/store/index";

export interface AppState {
  [EHPAD_DEMAIN_STORE_KEY]: EhpadDemainStoreState;
}

export const selectEhpadDemainStore = (state: AppState) => state[EHPAD_DEMAIN_STORE_KEY];

export const selectEhpadDemainList = createSelector(
  selectEhpadDemainStore,
  (state: EhpadDemainStoreState) => state.list
);


export const selectEhpadDemainLoading = createSelector(
  selectEhpadDemainList,
  (state: EhpadDemainStoreState['list']) => state.loading
);

export const selectEhpadDemainEntities = createSelector(
  selectEhpadDemainList,
  (state: EhpadDemainStoreState['list']) => state.entities
);


export const selectCurrentEhpadDemain = createSelector(
  selectEhpadDemainStore,
  (state: EhpadDemainStoreState) => state.current
);

