import {DOCUMENT} from "@angular/common";
import {Component, EventEmitter, HostBinding, HostListener, Inject, Input, Output} from '@angular/core';
import {POSITION} from "@application/framework/lib";
import {ResizableGrabberEvent} from "@easyhpad-ui/app/library/resizable/resizable-grabber.position";

@Component({
  selector: 'ehp-resizable-grabber',
  templateUrl: './resizable-grabber.component.html',
  styleUrls: ['./resizable-grabber.component.scss'],
  host: {
    '[class.is-grab]': 'isGrab'
  }
})
export class ResizableGrabberComponent {

  public originalMousePosition: { x: number, y: number } = {x: 0, y: 0}

  @Input() public position: POSITION = POSITION.LEFT;

  @Output() public onGrab: EventEmitter<ResizableGrabberEvent['initial']> = new EventEmitter();

  @Output() public onMove: EventEmitter<ResizableGrabberEvent> = new EventEmitter();

  public isGrab: boolean = false;

  @HostBinding('class')
  public get classes(): string {
    return this.position ? `position-${this.position}` : 'position-left';
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.watchMove = this.watchMove.bind(this);
    this.stopMoveWatch = this.stopMoveWatch.bind(this);
  }

  @HostListener('mousedown', ['$event'])
  public initializeGrab($event: MouseEvent) {
    $event.preventDefault();
    this.document.documentElement.classList.add('is-grab');
    this.isGrab = true;

    this.originalMousePosition.x = $event.pageX;
    this.originalMousePosition.y = $event.pageY;

    this.onGrab.emit({...this.originalMousePosition});

    window.addEventListener('mousemove', this.watchMove)
    window.addEventListener('mouseup', this.stopMoveWatch);
  }

  public watchMove(event: MouseEvent): void {

    const x = event.pageX - this.originalMousePosition.x;
    const y = event.pageY - this.originalMousePosition.y;

    this.onMove.emit({
      initial: {...this.originalMousePosition},
      move: {x, y},
      toDown: y >= 0,
      toRight: x >= 0
    })

  }

  public stopMoveWatch(): void {
    this.document.documentElement.classList.remove('is-grab');
    window.removeEventListener('mousemove', this.watchMove);
    this.isGrab = false;
  }
}
