import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {User} from "@domain/user";

export class UsersDeletionSuccessEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly users: User[]) {
    super();
  }

}
