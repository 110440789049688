import {AccommodationFundingRepository, GetAccommodationFundingQuery} from "@application/bundles/accommodation-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {AccommodationFunding} from "@domain/accommodation-funding";

@HandleQuery({
  query: GetAccommodationFundingQuery
})
export class GetAccommodationFundingQueryHandler implements QueryHandler<GetAccommodationFundingQuery, AccommodationFunding> {

  constructor(private readonly repository: AccommodationFundingRepository) {
  }

  public async handle(query: GetAccommodationFundingQuery): Promise<AccommodationFunding> {
    return this.repository.get(query.id);
  }
}
