import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  ActivityAnnexCreatedEvent,
  ActivityAnnexCreationFailEvent,
  ActivityAnnexRepository,
  CreateActivityAnnexCommand,
  CreateActivityAnnexDto,
} from '@application/bundles/activity-annex';
import { ACTIVITY_ANNEX_TRANSLATE_CONTEXT } from '@application/bundles/activity-annex/activity-annex.token';
import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { MediaBucket, TemporaryMedia } from '@domain/media';
import { ActivityAnnex } from '@domain/activity-annex';
import { ActivityAnnexAuthChecker } from '@application/bundles/activity-annex/activity-annex-authorization-checker';

@HandleCommand({
  command: CreateActivityAnnexCommand,
})
export class CreateActivityAnnexCommandHandler implements CommandHandler<CreateActivityAnnexCommand, ActivityAnnex> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: ActivityAnnexRepository,
    private readonly authorization: ActivityAnnexAuthChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
  ) {}

  public async handle(command: CreateActivityAnnexCommand): Promise<ActivityAnnex> {
    const { activityAnnex } = command;
    try {
      const created = await this.sanitize(activityAnnex)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.storePvAndCreateActivityAnnex(dto));

      this.eventDispatcher.dispatch(new ActivityAnnexCreatedEvent(created));

      return created;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: CreateActivityAnnexDto): Promise<CreateActivityAnnexDto> {
    if (!(await this.authorization.canCreate())) {
      this.logger.error('Activity annex creation : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: CreateActivityAnnexDto): Promise<CreateActivityAnnexDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('Activity annex creation : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: CreateActivityAnnexDto): Promise<CreateActivityAnnexDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('Activity annex creation : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à créer un nouveau Activity annex.",
        undefined,
        ACTIVITY_ANNEX_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new ActivityAnnexCreationFailEvent(message));

    return error;
  }

  private async storePvAndCreateActivityAnnex(dto: CreateActivityAnnexDto): Promise<ActivityAnnex> {
    let document: TemporaryMedia | undefined;

    try {
      if (dto.document) {
        document = await this.mediaBucket.add(dto.document);
        dto.documentId = document.id;
      }

      return this.repository.create(dto);
    } catch (e) {
      if (document !== undefined) {
        await document.markForDeletion();
      }

      throw e;
    }
  }
}
