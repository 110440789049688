import { Facility } from '@domain/facility';
import { isUUID } from '@domain/lib';

export function isValidFacilityIdType(id: any): id is Facility['id'] {
  return isUUID(id);
}

export function isMaybeAFacility(facility: any): facility is { id: Facility['id']; name: string } {
  return (
    facility &&
    typeof facility === 'object' &&
    'id' in facility &&
    isValidFacilityIdType(facility.id) &&
    'name' in facility &&
    typeof facility.name === 'string'
  );
}
