import {CreateTemporaryDependencyFundingDto} from "@application/bundles/dependency-funding";
import {isNegativeFundingAmount} from "@domain/funding";
import {
  DEPENDENCIES_FUNDING_MODES,
  isTemporaryDependencyFundingModeValue,
  TEMPORARY_DEPENDENCIES_FUNDING_MODES
} from "@domain/dependency-funding";
import {isValidObject} from "@application/framework/lib";

export class CreateTemporaryDependencyFundingDtoImpl<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = any> implements CreateTemporaryDependencyFundingDto<M, V> {


  public mode!: TEMPORARY_DEPENDENCIES_FUNDING_MODES;

  public value!: V;

  public activityAllowance: number | undefined;


  constructor(values?: Partial<CreateTemporaryDependencyFundingDto<M, V>>) {

    if (isValidObject(values)) {

      this.value = values?.value as V;
      if (isTemporaryDependencyFundingModeValue(values?.mode)) {
        this.mode = values?.mode;
      }

      if (isNegativeFundingAmount(values?.activityAllowance)) {
        this.activityAllowance = values?.activityAllowance;
      }
    }
  }


}
