import { Transformer } from '@application/framework/core';
import {
  CreateEasyDaycareDependencyFundingDto,
  CreateEasyDependencyFunding,
  CreateEasyPermanentDependencyFundingDto,
  CreateEasyTemporaryDependencyFundingDto,
  EasyDependencyFunding,
  UpdateEasyDaycareDependencyFundingDto,
  UpdateEasyDependencyFunding,
  UpdateEasyPermanentDependencyFundingDto,
  UpdateEasyTemporaryDependencyFundingDto,
} from '../../interfaces';
import { DependencyFunding } from '@domain/dependency-funding';
import { Provide, Service } from '@application/framework/di';
import { EasyPermanentDependencyFundingTransformer } from './permanent-dependency-funding.transformer';
import { EasyTemporaryDependencyFundingTransformer } from './easy-temporary-dependency-funding.transformer';
import { EasyDaycareDependencyFundingTransformer } from './easy-daycare-dependency-funding.transformer';
import { Common } from '@application/framework/lib';
import { CreateDependencyFundingDto, UpdateDependencyFundingDto } from '@application/bundles/dependency-funding';
import { DateParser } from '@application/framework/date/date.parser';
import { DateFormatter } from '@application/framework/date';
import { DependencyFundingImpl } from '@implementations/bundles/dependency-funding/implementations';
import { TemporaryDependencyFundingImpl } from '@implementations/bundles/dependency-funding/implementations/temporary-dependency-funding';
import { DaycareDependencyFundingImpl } from '@implementations/bundles/dependency-funding/implementations/daycare-dependecy-funding';

type CommonProperties = Omit<
  Common<DependencyFunding, Common<CreateDependencyFundingDto, UpdateDependencyFundingDto>>,
  'report' | 'document' | 'permanent' | 'temporary' | 'daycare'
>;

type EasyCommonProperties = Omit<
  Common<EasyDependencyFunding, Common<CreateEasyDependencyFunding, UpdateEasyDependencyFunding>>,
  'permanent' | 'temporary' | 'daycare'
>;

@Service()
export class EasyDependencyFundingTransformer implements Transformer<EasyDependencyFunding, DependencyFunding> {
  @Provide() private permanentTransformer!: EasyPermanentDependencyFundingTransformer;

  @Provide() private temporaryTransformer!: EasyTemporaryDependencyFundingTransformer;

  @Provide() private daycareTransformer!: EasyDaycareDependencyFundingTransformer;

  @Provide() private dateParser!: DateParser;

  @Provide() private dateFormatter!: DateFormatter;

  public transform(value: EasyDependencyFunding): DependencyFunding {
    const funding = new DependencyFundingImpl({
      ...this.transformCommon(value),
      id: value.id,
      reportId: value.reportId || undefined,
      documentId: value.arreteId,
      permanent: value.permanent ? this.permanentTransformer.transform(value.permanent) : undefined,
      temporary: undefined,
      daycare: undefined,
    });

    if (value.temporary) {
      funding.temporary = new TemporaryDependencyFundingImpl({
        ...this.temporaryTransformer.flatTransform(value.temporary),
        parent: funding,
      });
    }

    if (value.daycare) {
      funding.daycare = new DaycareDependencyFundingImpl(this.daycareTransformer.flatTransform(value.daycare));
    }

    return funding;
  }

  public reverseTransform(value: DependencyFunding): EasyDependencyFunding {
    return {
      ...this.reverseTransformCommon(value),
      id: value.id,
      permanent: value.permanent ? this.permanentTransformer.reverseTransform(value.permanent) : undefined,
      temporary: value.temporary ? this.temporaryTransformer.reverseTransform(value.temporary) : undefined,
      daycare: value.daycare ? this.daycareTransformer.reverseTransform(value.daycare) : undefined,
    };
  }

  public reverseTransformCreateDto(value: CreateDependencyFundingDto): CreateEasyDependencyFunding {
    return {
      ...this.reverseTransformCommon(value),
      permanent: value.permanent ? this.permanentTransformer.reverseTransformCreateDto(value.permanent) : undefined,
      temporary: value.temporary ? this.temporaryTransformer.reverseTransformCreateDto(value.temporary) : undefined,
      daycare: value.daycare ? this.daycareTransformer.reverseTransformCreateDto(value.daycare) : undefined,
    };
  }

  public reverseTransformUpdateDto(value: UpdateDependencyFundingDto): UpdateEasyDependencyFunding {
    let permanent: UpdateEasyPermanentDependencyFundingDto | CreateEasyPermanentDependencyFundingDto | undefined;
    let temporary: UpdateEasyTemporaryDependencyFundingDto | CreateEasyTemporaryDependencyFundingDto | undefined;
    let daycare: UpdateEasyDaycareDependencyFundingDto | CreateEasyDaycareDependencyFundingDto | undefined;

    if (value.permanent) {
      if ('id' in value.permanent) {
        permanent = this.permanentTransformer.reverseTransformUpdateDto(value.permanent);
      } else {
        permanent = this.permanentTransformer.reverseTransformCreateDto(value.permanent);
      }
    }

    if (value.temporary) {
      if ('id' in value.temporary) {
        temporary = this.temporaryTransformer.reverseTransformUpdateDto(value.temporary);
      } else {
        temporary = this.temporaryTransformer.reverseTransformCreateDto(value.temporary);
      }
    }

    if (value.daycare) {
      if ('id' in value.daycare) {
        daycare = this.daycareTransformer.reverseTransformUpdateDto(value.daycare);
      } else {
        daycare = this.daycareTransformer.reverseTransformCreateDto(value.daycare);
      }
    }

    return {
      ...this.reverseTransformCommon(value),
      id: value.id,
      permanent,
      temporary,
      daycare,
    };
  }

  private transformCommon(value: EasyCommonProperties): CommonProperties {
    return {
      date: this.dateParser.fromISOString(value.date),
      implementationDate: this.dateParser.fromISOString(value.dateStart),
      accountingYear: value.accountingYear,
      girValuation: typeof value.girValuation === 'number' ? value.girValuation : undefined,
      amount: typeof value.amount === 'number' ? value.amount : undefined,
      apa: typeof value.apa === 'number' ? value.apa : undefined,
      outsideDepartment: typeof value.outsideDepartment === 'number' ? value.outsideDepartment : undefined,
      moderatorTicket: typeof value.moderatorTicket === 'number' ? value.moderatorTicket : undefined,
      occupationRate: typeof value.occupationRate === 'number' ? value.occupationRate : undefined,
      facilityId: value.facilityId,
      documentId: value.arreteId,
      reportId: value.reportId || undefined,
    };
  }

  private reverseTransformCommon(value: CommonProperties): EasyCommonProperties {
    return {
      date: this.dateFormatter.toUTCIsoString(value.date),
      dateStart: this.dateFormatter.toUTCIsoString(value.implementationDate),
      girValuation: value.girValuation,
      amount: value.amount,
      apa: value.apa,
      outsideDepartment: value.outsideDepartment,
      moderatorTicket: value.moderatorTicket,
      occupationRate: value.occupationRate,
      accountingYear: value.accountingYear,
      facilityId: value.facilityId,
      arreteId: value.documentId || '',
      reportId: value.reportId || null,
    };
  }
}
