import { DynamicFieldGroup, DynamicHTMLGroup } from './dynamic-field-group';
import { DynamicInputField } from './dynamic-input-field';
import { DynamicFacilityField, DynamicMediaField } from '@easyhpad-ui/app/library/form/contracts/dynamic-custom-field';
import { Observable } from 'rxjs';
import { LocalMedia, Media } from '@domain/media';
import { FormGroup } from '@angular/forms';
import { TranslatableString } from '@application/framework/translation';
import { DynamicNoopField } from '@easyhpad-ui/app/library/form/contracts/dynamic-field';
import {
  WithChanges,
  WithControlValidators,
  WithCssClass,
  WithInitialValue,
} from '@easyhpad-ui/app/library/form/contracts/dynamic-field-attributes';
import { DynamicStepGroup, DynamicStepperGroup } from '@easyhpad-ui/app/library/form/contracts/dynamic-repeater-field';
import { DynamicSelectField } from '@easyhpad-ui/app/library/form/contracts/dynamic-select-field';
import { DynamicFormSummary } from '@easyhpad-ui/app/library/form/contracts/dynamic-summary';
import { DynamicPanelGroup, DynamicPanelsGroup } from '@easyhpad-ui/app/library/form/contracts/dynamic-form-panels';

export interface DynamicFormNode<T> {
  /**
   * Type of form element.
   * Used to define how the element must be rendered.
   */
  formElementType: T;
}

export type DynamicFormElementType<
  T extends 'input' | 'select' | 'group' | 'htmlGroup' | 'stepper' | 'step' | 'summary' | 'panels' | 'panel',
> = T extends 'input'
  ? DynamicNoopField | DynamicInputField<any> | DynamicFacilityField | DynamicMediaField
  : T extends 'select'
    ? DynamicSelectField
    : T extends 'group'
      ? DynamicFieldGroup<any>
      : T extends 'htmlGroup'
        ? DynamicHTMLGroup<any>
        : T extends 'stepper'
          ? DynamicStepperGroup
          : T extends 'step'
            ? DynamicStepGroup<any>
            : T extends 'panels'
              ? DynamicPanelsGroup
              : T extends 'panel'
                ? DynamicPanelGroup<any>
                : T extends 'summary'
                  ? DynamicFormSummary
                  : never;

/**
 * Define the model used to build the dynamic form
 */
export interface DynamicFormModel<
  C extends DynamicStepperGroup | DynamicPanelsGroup | { [k in keyof C]: DynamicFormElementType<any> },
  V = any,
> extends WithChanges,
    WithControlValidators,
    WithCssClass,
    WithInitialValue {
  /**
   * The recipe to build form controls
   */
  controls: C;

  medias?: {
    /**
     * Define to true if you when display medias preview
     */
    preview?: boolean;

    /**
     * An Observable to list media changes in form.
     * Can be uses to display the preview of media.
     */
    source: Observable<Array<LocalMedia | Media>>;
  };

  /**
   * Actions to render in the actions bar
   */
  actions?: Array<DynamicFormAction>;
}

export interface DynamicFormAction {
  /**
   * Text displayed in button
   */
  label: string | TranslatableString;

  /**
   * Action on button click.
   * @param form
   */
  onClick?: (form: FormGroup) => void;

  /**
   * Enable or disable the button based on form state.
   */
  enableSync?: boolean;

  /**
   * Class to apply on the native element
   */
  class?: string;

  /**
   * The button type
   */
  type?: 'submit' | 'reset' | 'button';
}
