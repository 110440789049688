import {CPOM} from "@domain/cpom/cpom";
import {TariffOption} from "@domain/cpom/tariff-option";
import {Facility} from "@domain/facility";
import {Media} from "@domain/media";
import {FundingCollection} from "@domain/funding";
import {CPOMFunding} from "@domain/cpom/cpom-funding.interface";

export interface LocalCPOM {

  readonly start: Date;

  readonly end: Date;

  readonly year: number;

  parent: CPOM | undefined;

  tariffOption: TariffOption;

  bedSocialCare: number | undefined;

  funding: FundingCollection<CPOMFunding>;

  facility(): Promise<Facility>;

  document(): Promise<Media>;
}
