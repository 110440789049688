import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, mergeMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {QueryBus} from "@application/framework/command-query";
import {ERRDActions} from "@easyhpad-ui/app/bundles/eprd-errd/store/errd.actions";
import {ERRD} from "@domain/eprd-errd";
import {ListERRDQuery} from "@application/bundles/eprd-errd";

@Injectable()
export class ErrdEffects {


  loadERRD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ERRDActions.loadPublicERRDDocuments),
      mergeMap((action) => from(this.queryBus.request<ERRD[]>(new ListERRDQuery(action.params)))),
      map((documents) => ERRDActions.setPublicERRDDocuments({documents}))
    )
  );

  constructor(
    private actions$: Actions,
    private queryBus: QueryBus
  ) {
  }
}

