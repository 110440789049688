import {
  AccommodationFundingSearchParams,
  CreateAccommodationFundingDto,
  UpdateAccommodationFundingDto,
} from '@application/bundles/accommodation-funding';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { DocumentRepository } from '@application/bundles/document/contracts';

export abstract class AccommodationFundingRepository
  implements
    DocumentRepository<
      AccommodationFunding,
      AccommodationFundingSearchParams,
      CreateAccommodationFundingDto,
      Partial<UpdateAccommodationFundingDto>
    >
{
  public abstract list(params?: AccommodationFundingSearchParams): Promise<AccommodationFunding[]>;

  public abstract get(id: AccommodationFunding['id']): Promise<AccommodationFunding>;

  public abstract create(id: CreateAccommodationFundingDto): Promise<AccommodationFunding>;

  public abstract update(
    id: AccommodationFunding['id'],
    update: Partial<UpdateAccommodationFundingDto>,
  ): Promise<AccommodationFunding>;

  public abstract delete(id: AccommodationFunding['id']): Promise<AccommodationFunding>;
}
