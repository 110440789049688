import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DynamicAbstractControlField,
  DynamicFacilityField,
  HasDynamicField,
} from '@easyhpad-ui/app/library/form/contracts';
import { isObservable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-dynamic-facility-field',
  templateUrl: './dynamic-facility-field.component.html',
  styleUrl: './dynamic-facility-field.component.scss',
})
export class DynamicFacilityFieldComponent implements HasDynamicField<DynamicFacilityField>, OnInit, OnDestroy {
  @Input() public readonly field!: DynamicAbstractControlField<DynamicFacilityField>;

  private destroy$ = new Subject<void>();

  public get control(): FormControl | null {
    return this.field && this.field.control ? (this.field.control as FormControl) : null;
  }

  /**
   * Facilities scope.
   */
  public get scope() {
    return this.field.scope ?? 'active';
  }

  constructor() {}

  public ngOnInit() {
    if (isObservable(this.field.initialValue)) {
      this.field.initialValue.pipe(takeUntil(this.destroy$)).subscribe(value => this.setInitialValue(value));
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setInitialValue(value: string | undefined) {
    if (!this.control || this.control?.touched || this.control?.dirty) {
      return;
    }

    if (!this.control.value && (value === null || value === undefined)) {
      return;
    }

    this.control.patchValue(value);
  }
}
