import { CAPACITY_AUTHORIZATION_FEATURE } from '@application/bundles/capacity-authorization';
import { CUSTOMER_FEATURE } from '@application/bundles/customer/customer.token';
import { FACILITY_FEATURE } from '@application/bundles/facility';
import { USER_FEATURE } from '@application/bundles/user/user.token';
import { Permission, PermissionCollection } from '@domain/authorization';
import { PERMISSION_ENTITIES } from '@implementations/bundles/backend/easyhpad/authorization/permission-entities';
import { MissingFeatureToEntityMappingError } from '@implementations/bundles/backend/easyhpad/errors';
import { DOCUMENT_FEATURE } from '@application/bundles/document';
import { FILE_DEPOSIT_FEATURE } from '@application/bundles/file-deposit';

export class EasyPermissionsCollection implements PermissionCollection {
  public readonly featureToEntityMap: Map<Symbol, PERMISSION_ENTITIES> = new Map([
    [USER_FEATURE, PERMISSION_ENTITIES.USER],
    [CUSTOMER_FEATURE, PERMISSION_ENTITIES.CUSTOMER],
    [FACILITY_FEATURE, PERMISSION_ENTITIES.FACILITY],
    [CAPACITY_AUTHORIZATION_FEATURE, PERMISSION_ENTITIES.DOCUMENT],
    [DOCUMENT_FEATURE, PERMISSION_ENTITIES.DOCUMENT],
    [FILE_DEPOSIT_FEATURE, PERMISSION_ENTITIES.FILE_DEPOSIT],
  ]);

  constructor(private readonly permissions: Permission[]) {}

  public has<F, E = string>(feature: F, type: E): boolean {
    const entity = this.featureToEntityMap.get(feature as Symbol);

    if (entity === undefined) {
      // @ts-ignore
      const name = feature.toString ? feature.toString() : '';
      throw new MissingFeatureToEntityMappingError(`Missing entity for feature ${name}`);
    }

    const permission = this.permissions.find(permission => permission.feature === entity && permission.type === type);
    return !!permission;
  }

  public all(): Array<Permission> {
    return this.permissions;
  }
}
