import {CrudPermissionVoter} from "@application/bundles/authorization/voters/crud-permission/crud-permission.voter";
import {
  CreateCustomerCommandHandler
} from "@application/bundles/customer/commands/handlers/create-customer/create-customer-command.handler";
import {
  DeleteCustomerCommandHandler
} from "@application/bundles/customer/commands/handlers/delete-customer/delete-customer.command.handler";
import {
  UpdateCustomerCommandHandler
} from "@application/bundles/customer/commands/handlers/update-customer/update-customer.command.handler";
import {CUSTOMER_FEATURE} from "@application/bundles/customer/customer.token";
import {
  OnCustomerCreationEventSubscriber
} from "@application/bundles/customer/events/subscribers/on-customer-creation/on-customer-creation.event.subscriber";
import {
  OnCustomerDeletionEventSubscriber
} from "@application/bundles/customer/events/subscribers/on-customer-deletion/on-customer-deletion.event.subscriber";
import {
  OnCustomerUpdateEventSubscriber
} from "@application/bundles/customer/events/subscribers/on-customer-update/on-customer-update.event.subscriber";
import {
  GetCustomerQueryHandler
} from "@application/bundles/customer/queries/handlers/get-customer/get-customer.query.handler";
import {
  ListCustomersQueryHandler
} from "@application/bundles/customer/queries/handlers/list-customers-query/list-customers-query.handler";
import {Bundle} from "@application/framework/bundle";
import {Customer} from "@domain/customer";
import {CustomerAuthorizationChecker} from "@application/bundles/customer/customer-authorization-checker";

@Bundle({
  commandHandlers: [
    CreateCustomerCommandHandler,
    UpdateCustomerCommandHandler,
    DeleteCustomerCommandHandler
  ],
  queryHandlers: [
    ListCustomersQueryHandler,
    GetCustomerQueryHandler
  ],
  eventSubscribers: [
    OnCustomerCreationEventSubscriber,
    OnCustomerUpdateEventSubscriber,
    OnCustomerDeletionEventSubscriber,
  ],
  providers: [
    {provide: CustomerAuthorizationChecker, useClass: CustomerAuthorizationChecker}
  ],
  voters: [
    new CrudPermissionVoter<Customer>(
      CUSTOMER_FEATURE,
      (subject, token) => Promise.resolve(false)
    )
  ]
})
export class CustomerBundle {
}
