import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {
  AddInspectionReportCommand,
  UpdateInspectionReportCommand
} from "@application/bundles/capacity-authorization/commands";
import {AddInspectionReportDto} from "@application/bundles/capacity-authorization/dtos";
import {UpdateInspectionReportDto} from "@application/bundles/capacity-authorization/dtos/update-inspection-report.dto";
import {
  InspectionReportDtoFactory
} from "@application/bundles/capacity-authorization/factories/inspection-report.dto.factory";
import {CommandBus} from "@application/framework/command-query";
import {DATE_FORMATS, DateFormatter} from "@application/framework/date";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {CapacityAuthorization, InspectionReport} from "@domain/capacity-authorization";
import {LocalMedia, Media, MEDIA_PERSISTANCE_STATE} from "@domain/media";
import {DateParser} from "@application/framework/date/date.parser";
import {FILE_TYPES} from "@application/bundles/media";

@Component({
  selector: 'ehp-inspection-report-edit-form',
  templateUrl: './inspection-report-edit-form.component.html',
  styleUrls: ['./inspection-report-edit-form.component.scss']
})
export class InspectionReportEditFormComponent implements OnInit {

  @Input() public authorization!: CapacityAuthorization;

  @Input() public report: InspectionReport | undefined;

  @Input() public mode: 'add' | 'edit' = 'edit';

  @Output('onSubmit') public submit$: EventEmitter<InspectionReport> = new EventEmitter();

  @Output('onCancel') public cancel$: EventEmitter<void> = new EventEmitter();

  public form: FormGroup<Record<keyof Omit<AddInspectionReportDto, 'mediaIds'>, AbstractControl>>;

  public buttonLabel: string = "Modifier le rapport d'inspection";

  public currentMedias: Array<Media> = [];

  public PDF = FILE_TYPES.PDF;


  @ProvideLogger() private readonly logger!: Logger;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly commandBus: CommandBus,
    private readonly dateFormatter: DateFormatter,
    private readonly dateParser: DateParser,
    private readonly factory: InspectionReportDtoFactory,
  ) {

    this.validateDate = this.validateDate.bind(this);
    this.form = this.formBuilder.group<Record<keyof Omit<AddInspectionReportDto, 'mediaIds'>, AbstractControl>>({
      date: new FormControl(undefined, [Validators.required, this.validateDate]),
      medias: new FormControl(),
    });
  }

  public async ngOnInit() {

    if (this.mode === 'add') {
      this.buttonLabel = "Ajouter un nouveau rapport";
    }

    if (this.report !== undefined) {

      if (this.report.date) {
        this.form.get('date')?.setValue(this.dateFormatter.format(this.report.date, DATE_FORMATS.SHORT_HTML));
      }

      const medias = await this.report.medias();
      this.currentMedias = medias;
      this.addMedia(medias);
    }
  }

  public async submit() {
    if (this.authorization === undefined || !this.form.valid || (this.mode !== 'add' && this.mode !== 'edit')) {
      return;
    }

    if (this.mode === 'add') {
      this.addReport();
    } else if (this.mode === 'edit') {
      this.editReport();
    }
  }

  public cancel() {
    this.form.reset();
    this.cancel$.emit();
  }

  public addMedia(medias: Array<LocalMedia | Media>) {
    this.form.get('medias')?.setValue(medias as LocalMedia[]);
  }

  private addReport(): void {

    const report = this.factory.createAddInspectionReportDtoFromUnSafeValues({
      date: this.form.value.date,
      medias: this.form.value.medias
    });

    this.commandBus.execute<InspectionReport>(new AddInspectionReportCommand(report, this.authorization.id)).then(newer => {
      this.submit$.emit(newer);
    });
  }

  private editReport(): void {
    if (!this.form.valid) {
      this.logger.warning('Form cannot be submit, form is invalid');
      return;
    }

    if (this.report === undefined || !this.report.id) {
      this.logger.warning('Update cancelled, report not found');
      return;
    }

    let toAdd: UpdateInspectionReportDto['mediaToAdd'] = [];
    let toRemove: UpdateInspectionReportDto['mediaToRemove'] = [];

    if (this.form.value.medias && Array.isArray(this.form.value.medias)) {

      const keep = this.form.value.medias.filter((media: LocalMedia & Media) => media.id !== undefined).map((media: Media) => media.id);
      toRemove = this.report.mediaIds.filter(id => !keep.includes(id));


      toAdd = this.form.value.medias.filter((media: LocalMedia & Media) => media.id === undefined && media.persistance === MEDIA_PERSISTANCE_STATE.TEMPORARY);
    }

    const report = this.factory.createUpdateInspectionReportDtoFromUnSafeValues({
      date: this.form.value.date,
      id: this.report?.id,
      mediaIds: this.report.mediaIds,
      mediaToAdd: toAdd,
      mediaToRemove: toRemove,
    });


    this.commandBus.execute<InspectionReport>(new UpdateInspectionReportCommand(report, this.authorization.id)).then(update => {
      this.submit$.emit(update);
    });
  }


  private validateDate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value && typeof value === "string" && this.authorization && this.authorization.dates) {
      const date = this.dateParser.parse(value);


      if (this.authorization.dates.start && +date < +this.authorization.dates.start) {
        return {inspectionDateIsLess: true};
      }

      if (this.authorization.dates.end && +date > +this.authorization.dates.end) {
        return {inspectionDateIsGreater: true};
      }

    }

    return null;
  }
}
