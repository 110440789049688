import {Request, REQUEST_METHOD, RequestParams} from "@application/framework/http/request.interface";

export class GetRequest implements Request<REQUEST_METHOD.GET> {


  public readonly method = REQUEST_METHOD.GET;

  public url: URL;

  public params?: RequestParams;

  constructor(url: URL) {
    this.url = url;
  }
}
