import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { HealthFunding } from '@domain/health-funding';
import { DateFormatter } from '@application/framework/date';

@Component({
  selector: 'ehp-health-funding-name',
  templateUrl: './health-funding-name.component.html',
  styleUrls: ['./health-funding-name.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HealthFundingNameComponent implements OnChanges {
  @Input() public healthFunding!: HealthFunding;

  public date: string | undefined;

  constructor(private readonly dateFormatter: DateFormatter) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['healthFunding']) {
      this.updateDate();
    }
  }

  private updateDate(): void {
    this.date = this.healthFunding?.date ? this.dateFormatter.format(this.healthFunding.date, 'dd/MM/yyyy') : undefined;
  }
}
