import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidEhpadDemainIdType} from "@application/bundles/ehpad-demain";

@ValidatorConstraint({name: 'IsValidEhpadDemainId', async: true})
export class IsValidEhpadDemainIdConstraint implements ValidatorConstraintInterface {

  public validate(id: any, args?: ValidationArguments): Promise<boolean> {
    return Promise.resolve(isValidEhpadDemainIdType(id));
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'ID is not a valid EHPAD de Demain ID.';
  }
}

export function IsValidEhpadDemainId(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidEhpadDemainIdConstraint
    });
  };
}
