import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AverageMessageComponent, DashboardComponent, DashboardWidgetComponent } from './components';
import { WidgetContentDirective } from '@easyhpad-ui/app/bundles/dashboard/directives/widget-content.directive';
import { WidgetContentResolverDirective } from '@easyhpad-ui/app/bundles/dashboard/directives/widget-content-resolver.directive';
import { WidgetResolver } from '@easyhpad-ui/app/bundles/dashboard/services/widget-resolver/widget.resolver';
import { GridsterComponent, GridsterItemComponent } from 'angular-gridster2';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DASHBOARD_STORE_KEY, DashboardEffects, dashboardReducer } from '@easyhpad-ui/app/bundles/dashboard/store';
import { Bundle } from '@application/framework/bundle';
import {
  GetAuthorizedCapacityWidgetQueryHandler,
  GetEprdErrdWidgetQueryHandler,
  GetFullDataTableWidgetQueryHandler,
  GetFundingItemQueryHandler,
  GetLatestGMPWidgetContentQueryHandler,
  GetOccupationRateWidgetQueryHandler,
  GetPmpWidgetContentQueryHandler,
} from '@easyhpad-ui/app/bundles/dashboard/queries/handlers';
import { DashboardTableComponent } from './components/dashboard-table/dashboard-table.component';
import { TableModule } from 'primeng/table';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { SidebarModule } from 'primeng/sidebar';
import { FilterModule } from '@easyhpad-ui/app/library/filter/filter.module';

@NgModule({
  imports: [
    CommonModule,
    GridsterComponent,
    GridsterItemComponent,
    TranslationModule,
    StoreModule.forFeature(DASHBOARD_STORE_KEY, dashboardReducer),
    EffectsModule.forFeature([DashboardEffects]),
    TableModule,
    ReusableComponentsModule,
    ReactiveFormsModule,
    FormModule,
    SidebarModule,
    FilterModule,
  ],
  providers: [WidgetResolver],
  declarations: [
    DashboardWidgetComponent,
    DashboardComponent,
    WidgetContentDirective,
    WidgetContentResolverDirective,
    AverageMessageComponent,
    DashboardTableComponent,
  ],
  exports: [
    DashboardWidgetComponent,
    WidgetContentDirective,
    DashboardComponent,
    AverageMessageComponent,
    DashboardTableComponent,
  ],
})
@Bundle({
  queryHandlers: [
    GetFullDataTableWidgetQueryHandler,
    GetFundingItemQueryHandler,
    GetAuthorizedCapacityWidgetQueryHandler,
    GetOccupationRateWidgetQueryHandler,
    GetPmpWidgetContentQueryHandler,
    GetLatestGMPWidgetContentQueryHandler,
    GetEprdErrdWidgetQueryHandler,
  ],
})
export class DashboardModule {}
