import { Common, isValidObject } from '@application/framework/lib';
import { CreateDependencyFundingDto, UpdateDependencyFundingDto } from '@application/bundles/dependency-funding';
import { IsDate, IsNumber, IsOptional, IsPositive, Max, Min } from 'class-validator';
import { IsValidFacilityId } from '@implementations/bundles/facility';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { isPositiveFundingAmount } from '@domain/funding';
import { IsMediaId } from '@implementations/bundles/media';
import { isValidMediaIdType } from '@application/bundles/media';
import { Media } from '@domain/media';
import { isValidYear, Year } from '@domain/lib';
import { IsValidYear } from '@implementations/framework/date';
import { Facility } from '@domain/facility';

type CommonProperties = Omit<
  Common<CreateDependencyFundingDto, UpdateDependencyFundingDto>,
  'permanent' | 'temporary' | 'daycare' | 'document' | 'report'
>;

export abstract class CommonDependencyFundingDtoAbstract implements CommonProperties {
  @IsDate()
  public date!: Date;

  @IsDate()
  public implementationDate!: Date;

  @IsValidYear()
  public accountingYear!: Year;

  @IsNumber()
  @IsPositive()
  @Min(0)
  @IsOptional()
  public girValuation: number | undefined;

  @IsNumber()
  @IsPositive()
  @Min(0)
  @IsOptional()
  public amount: number | undefined;

  @IsNumber()
  @IsPositive()
  @Min(0)
  @IsOptional()
  public apa: number | undefined;

  @IsNumber()
  @IsPositive()
  @Min(0)
  @IsOptional()
  public moderatorTicket: number | undefined;

  @IsNumber()
  @IsPositive()
  @Min(0)
  @IsOptional()
  public outsideDepartment: number | undefined;

  @IsNumber()
  @IsPositive()
  @Min(0)
  @Max(100)
  @IsOptional()
  public occupationRate: number | undefined;

  @IsMediaId()
  @IsOptional()
  public documentId?: Media['id'];

  @IsMediaId()
  @IsOptional()
  public reportId?: Media['id'];

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  protected constructor(values?: Partial<CommonProperties>) {
    if (isValidObject(values)) {
      if (values.date instanceof Date) {
        this.date = values.date;
      }

      if (values.implementationDate instanceof Date) {
        this.implementationDate = values.implementationDate;
      }

      if (isValidYear(values?.accountingYear)) {
        this.accountingYear = values?.accountingYear;
      }

      if (isPositiveFundingAmount(values?.amount)) {
        this.amount = values?.amount;
      }

      if (isPositiveFundingAmount(values?.apa)) {
        this.apa = values?.apa;
      }

      if (isPositiveFundingAmount(values?.moderatorTicket)) {
        this.moderatorTicket = values?.moderatorTicket;
      }

      if (isPositiveFundingAmount(values?.outsideDepartment)) {
        this.outsideDepartment = values?.outsideDepartment;
      }

      if (isValidFacilityIdType(values?.facilityId)) {
        this.facilityId = values.facilityId;
      }

      if (typeof values?.occupationRate === 'number' && values.occupationRate >= 0 && values.occupationRate <= 100) {
        this.occupationRate = values.occupationRate;
      }

      if (isValidMediaIdType(values?.documentId)) {
        this.documentId = values?.documentId;
      }

      if (isValidMediaIdType(values?.reportId)) {
        this.reportId = values?.reportId;
      }

      if (typeof values?.girValuation === 'number' && isFinite(values.girValuation)) {
        this.girValuation = values.girValuation;
      }
    }
  }
}
