import {StringParserReplacementToken} from "@application/framework/parser/string/replacement-token";

export abstract class StringMatcher {


  public abstract match(string: string): boolean;

  public abstract getReplacement(tokens: any, key?: string): any;

  public abstract replace(template: string, tokens: StringParserReplacementToken): string;
}
