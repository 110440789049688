import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, HostBinding, Inject } from '@angular/core';
import { ConfirmDeletionDialogData } from './confirm-deletion-dialog.interface';

@Component({
  selector: 'ehp-confirm-deletion-dialog',
  templateUrl: './confirm-deletion-dialog.component.html',
  styleUrls: ['./confirm-deletion-dialog.component.scss'],
})
export class ConfirmDeletionDialogComponent {
  @HostBinding('class.dialog') public has = true;

  public constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: ConfirmDeletionDialogData,
  ) {}

  public confirm() {
    this.dialogRef.close(true);
  }

  public cancel() {
    this.dialogRef.close(false);
  }
}
