import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, convertToParamMap, Router } from '@angular/router';
import { UpdateCustomerDto } from '@application/bundles/customer/dto/update-customer.dto';
import { CustomerDtoFactory } from '@application/bundles/customer/factories';
import { CommandBus } from '@application/framework/command-query';
import { Translator } from '@application/framework/translation';
import { Customer } from '@domain/customer';
import { Skeleton } from '@easyhpad-ui/app/bundles/reusable-components/components/skeleton';
import { RouteSailor } from '@easyhpad-ui/app/framework/routing';
import { filter, from, map, Observable, Subscription, switchMap } from 'rxjs';
import { CustomerRepository } from '@application/bundles/customer';

@Component({
  selector: 'ehp-admin-customer-update-page',
  templateUrl: './admin-customer-update-page.component.html',
  styleUrls: ['./admin-customer-update-page.component.scss'],
})
export class AdminCustomerUpdatePageComponent implements OnDestroy {
  public customer: Customer | undefined;

  public title: string = 'Modifier le client';

  public skeletonType = Skeleton.FORM;

  public loading$: Observable<Customer>;

  public form: FormGroup<Record<keyof UpdateCustomerDto, AbstractControl>> | undefined;

  public displayBillingAddress: boolean = false;
  private subscription: Subscription;

  constructor(
    private repository: CustomerRepository,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translator: Translator,
    private dtoFactory: CustomerDtoFactory,
    private commandBus: CommandBus,
    private routeSailor: RouteSailor,
  ) {
    this.loading$ = this.getIdFromUrlParams().pipe(switchMap(id => from(this.repository.get(id))));

    this.subscription = this.loading$.subscribe(customer => this.customerHasChange(customer));
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public async onUpdate(customer: Customer): Promise<void> {
    await this.routeSailor.catchAndRedirect(this.activatedRoute, 'admin/customer');
  }

  private customerHasChange(customer?: Customer) {
    this.customer = customer;
    this.updateTitle(customer);
  }

  private getIdFromUrlParams(): Observable<Customer['id']> {
    return this.activatedRoute.params.pipe(
      map(convertToParamMap),
      filter(params => params.has('id')),
      map(params => params.get('id') as Customer['id']),
    );
  }

  private async updateTitle(customer?: Customer) {
    if (customer && customer.name) {
      this.title = await this.translator.translate('Modifier <em>{{ name }}</em>', { name: customer.name });
    } else {
      this.title = "Modifier l'utilisateur";
    }
  }
}
