import {Service} from "@application/framework/di";
import {DispatchableEvent, EventDispatcher, EventSubscriber} from "@application/framework/event";
import {EventSubscribersRegistry} from "@application/framework/event/event-subscribers.registry";
import {Logger, LogLevel, ProvideLogger} from "@application/framework/logger";


@Service()
export class EventDispatcherImpl implements EventDispatcher {

  @ProvideLogger() private readonly logger!: Logger;

  private readonly _registry: EventSubscribersRegistry;

  public get registry(): EventSubscribersRegistry {
    return this._registry;
  }

  public constructor(registry: EventSubscribersRegistry, silent = false) {
    this._registry = registry;
    this.logger.level = silent ? LogLevel.SILENT : LogLevel.INFO;
  }

  /**
   * @inheritDoc
   * @param event
   * @param subscriber
   */
  public has<T extends DispatchableEvent>(event: T, subscriber: EventSubscriber<T>): boolean {

    if (!this.registry.has(event) || !this.registry.hasSubscribers(event)) {
      return false;
    }

    const subscribers = this.registry.get(event);

    return subscribers.includes(subscriber);
  }

  /**
   * @inheritDoc
   * @param event
   */
  public dispatch<T extends DispatchableEvent>(event: T) {

    if (this.registry.isEmpty() || !this.registry.has(event)) {
      this.logger.info(`Unknown event ${event.name} in event subscriber registry.`);
      return;
    }

    const subscribers = this.registry.get(event);

    this.logger.info(`Dispatch "${event.name}" event (${subscribers.length} subscribers)`);

    subscribers.forEach(subscriber => {
      subscriber(event);
    });
  }
}
