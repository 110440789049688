import { createAction, props } from '@ngrx/store';
import { InitialCalculatorFactoryProps } from '@easyhpad-ui/app/bundles/calculator';
import { CALCULATOR_TYPE } from '@easyhpad-ui/app/bundles/calculator/types';

export enum CALCULATOR_ACTION_TYPES {
  NEW = '[Calculator] Request new instance',
  SET_INSTANCE = '[Calculator] Store calculator instance',
  CLOSE = '[Calculator] close calculator',
}

const newInstance = createAction(
  CALCULATOR_ACTION_TYPES.NEW,
  props<{
    props: InitialCalculatorFactoryProps;
    mode?: CALCULATOR_TYPE;
  }>(),
);

const isOpen = createAction(CALCULATOR_ACTION_TYPES.SET_INSTANCE, props<{ isOpen: boolean }>());

const close = createAction(CALCULATOR_ACTION_TYPES.CLOSE);

export const CalculatorActions = {
  new: newInstance,
  isOpen,
  close,
};
