import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FILE_TYPES, MediaFactory } from '@application/bundles/media';
import { isLocalMedia, isMedia, isMediaRef, LocalMedia, Media, MediaBucket, MediaRef } from '@domain/media';
import { v4 } from 'uuid';
import { from, map, Observable, Subscription, tap } from 'rxjs';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';

@Component({
  selector: 'ehp-media-uploader',
  templateUrl: './media-uploader.component.html',
  styleUrls: ['./media-uploader.component.scss'],
  host: {
    '[class.drag-drop]': 'supportDragAndDrop',
    '[class.is-hover]': 'isHover',
  },
})
export class MediaUploaderComponent implements OnChanges, OnDestroy {
  @Input() public requiredFileType: string[] | FILE_TYPES[] | undefined;

  @Input() public min: number = 0;

  @Input() public max: number | undefined;

  @Input() public multiple: boolean = false;

  @Input('medias') public externalMedias:
    | LocalMedia
    | Media
    | MediaRef
    | Array<LocalMedia | Media | MediaRef>
    | undefined;

  @Input() public hideFileList: boolean = false;

  @Output('onChange') public change: EventEmitter<Array<LocalMedia | Media>> = new EventEmitter();

  @Output('onRemove') public remove: EventEmitter<LocalMedia | Media> = new EventEmitter();

  public internalMediaList: Array<Media | LocalMedia> = [];

  public inputId = v4();

  public isHover: boolean = false;

  private subscription: Subscription | undefined;

  public get size(): number {
    return this.internalMediaList.length;
  }

  constructor(
    private readonly factory: MediaFactory,
    private readonly bucket: MediaBucket,
    private readonly noticeStream: NoticeStream,
    private readonly elementRef: ElementRef,
  ) {}

  @HostListener('dragover', ['$event'])
  @HostListener('dragenter', ['$event'])
  public setIsHover($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.isHover = true;
  }

  @HostListener('dragleave', ['$event'])
  @HostListener('dragend', ['$event'])
  @HostListener('drop', ['$event'])
  public setIsNotHover($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.isHover = false;
  }

  @HostListener('drop', ['$event'])
  public dropFiles($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    const files = $event.dataTransfer?.files;

    if (files && files.length > 0) {
      this.filesChange(files);
    }
  }

  /**
   * @inheritDoc
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['externalMedias']) {
      this.mediasHasChange();
    }
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  public onFileSelection($event: Event) {
    if (!$event || !$event.target) {
      return;
    }

    const target = $event.target as HTMLInputElement;

    if (target.files instanceof FileList) {
      this.filesChange(target.files);
    }
  }

  public removeFile(file: Media | LocalMedia) {
    this.internalMediaList = this.internalMediaList.filter(f => f !== file);
    this.remove.emit(file);

    this.change.emit(this.internalMediaList);
  }

  public supportDragAndDrop(): boolean {
    return (
      'draggable' in this.elementRef.nativeElement ||
      ('ondragstart' in this.elementRef.nativeElement && 'ondrop' in this.elementRef.nativeElement)
    );
  }

  private filesChange(fileList: FileList): void {
    this.internalMediaList = Array.from(fileList).map(file => this.factory.createLocalMediaFromFile(file));
    this.change.emit(this.internalMediaList);
  }

  private isSameFile(a: Partial<LocalMedia & Media>, b: Partial<LocalMedia & Media>): boolean {
    if (isLocalMedia(a) && isLocalMedia(b)) {
      return a.file === b.file;
    } else if (isMedia(a) && isMedia(b)) {
      if (a.id === undefined) {
        if (b.id === undefined) {
          return a?.name === b.name;
        }
        return false;
      }

      return a.id === b.id;
    }

    return false;
  }

  private mediasHasChange() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    let medias: Array<Media | LocalMedia | MediaRef> = [];

    if (this.externalMedias === undefined) {
      return;
    }

    if (isMedia(this.externalMedias) || isLocalMedia(this.externalMedias)) {
      medias.push(this.externalMedias);
    } else if (Array.isArray(this.externalMedias) && this.externalMedias.length > 0) {
      medias = [...this.externalMedias];
    }

    if (medias.length === 0) {
      if (this.internalMediaList.length !== 0) {
        this.internalMediaList = [];
      }

      return;
    }

    if (!medias.every(isMediaRef)) {
      this.internalMediaList = medias as Array<LocalMedia | Media>;
      return;
    }

    this.subscription = this.loadMedias(medias).subscribe(loaded => (this.internalMediaList = loaded));
  }

  private loadMedias(references: Array<Media | LocalMedia | MediaRef>): Observable<Array<Media | LocalMedia>> {
    const currents = references.filter(r => !isMediaRef(r)) as Array<Media | LocalMedia>;

    const ids = (references.filter(r => isMediaRef(r)) as MediaRef[]).map(r => r.id);

    return from(Promise.all(ids.map(id => this.loadMediaFromBucket(id)))).pipe(
      map(medias => {
        let fail = 0;

        medias.forEach(media => (media === null ? ++fail : currents.push(media)));

        if (fail > 0) {
          this.displayMissingNotice(fail);
        }

        return currents;
      }),
      tap(medias => this.change.emit(medias)),
    );
  }

  private loadMediaFromBucket(id: Media['id']): Promise<Media | null> {
    return this.bucket.get(id).catch(() => null);
  }

  private displayMissingNotice(count: number): void {
    let message =
      "{{count}} fichier n'a pas pu être récupéré. Veuillez vérifier le(s) champ(s) de chargement de fichier.";

    if (count > 1) {
      message =
        "{{count}} fichiers n'a pas pu être récupérés. Veuillez vérifier le(s) champ(s) de chargement de fichier.";
    }

    this.noticeStream.push({
      type: NoticeType.WARNING,
      message: new TranslatableString(message, { count: count }),
    });
  }
}
