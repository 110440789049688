import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {AccommodationFunding} from "@domain/accommodation-funding";
import {DateFormatter} from "@application/framework/date";

@Component({
  selector: 'ehp-accommodation-funding-name',
  templateUrl: './accommodation-funding-name.component.html',
  styleUrls: ['./accommodation-funding-name.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccommodationFundingNameComponent implements OnChanges {

  @Input() public funding!: AccommodationFunding;

  public date: string | undefined;

  constructor(private readonly dateFormatter: DateFormatter) {
  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes['funding']) {
      this.updateDate();
    }
  }

  private updateDate(): void {
    this.date = this.funding?.date ? this.dateFormatter.format(this.funding.date, 'dd/MM/yyyy') : undefined;
  }
}
