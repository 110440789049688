import {
  CreateNonRecurringHealthFundingDto,
  NonRecurringHealthFundingDtoFactory,
  UpdateNonRecurringHealthFundingDto
} from "@application/bundles/health-funding";
import {
  CreateNonRecurringHealthFundingDtoImpl,
  UpdateNonRecurringHealthFundingDtoImpl
} from "@implementations/bundles/health-funding/dto";

export class NonRecurringHealthFundingDtoFactoryImpl implements NonRecurringHealthFundingDtoFactory {

  public buildCreateDtoFromUnsafeValues(values: Partial<CreateNonRecurringHealthFundingDto>): CreateNonRecurringHealthFundingDto {
    return new CreateNonRecurringHealthFundingDtoImpl(values);
  }

  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdateNonRecurringHealthFundingDto>): UpdateNonRecurringHealthFundingDto {
    return new UpdateNonRecurringHealthFundingDtoImpl(values);
  }


}
