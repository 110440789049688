import { Facility } from '@domain/facility';
import { keys } from '@domain/lib';
import { StoreSerializedFacility } from '@easyhpad-ui/app/bundles/facility/store/serializers/facility/facility-store.serializer';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FacilityActions } from './facility.actions';
import { FacilityStoreState } from './facility.state';

export const adapter: EntityAdapter<StoreSerializedFacility> = createEntityAdapter();

const initialState: FacilityStoreState = {
  names: {},
  account: {
    all: [],
    selected: [],
  },
  admin: {
    loading: false,
    entities: [],
  },
  public: adapter.getInitialState(),
};

const addNames = (
  state: FacilityStoreState['names'],
  newer: Array<{ id: Facility['id']; name: Facility['name'] }>,
): FacilityStoreState['names'] => {
  const currents = keys(state);

  const update: FacilityStoreState['names'] = newer
    .filter(n => currents.findIndex(id => id === n.id) === -1)
    .reduce((a, b) => ({ ...a, [b.id]: b.name }), {});

  return keys(update).length > 0 ? { ...state, ...update } : state;
};

export const facilityReducer = createReducer(
  initialState,
  on(FacilityActions.loadAdminFacilities, state => ({
    ...state,
    admin: { ...state.admin, loading: true },
  })),
  on(FacilityActions.setAdminFacilities, (state, { facilities }) => ({
    ...state,
    admin: { entities: facilities, loading: false },
  })),
  on(FacilityActions.setAccountFacilities, (state, { facilities }) => ({
    ...state,
    account: { ...state.account, all: [...facilities] },
    names: addNames(state.names, facilities),
  })),
  on(FacilityActions.selectAccountFacilities, (state, { ids }) => ({
    ...state,
    account: { ...state.account, selected: [...ids] },
  })),
  on(FacilityActions.setFacility, (state, { facility }) => ({
    ...state,
    public: adapter.addOne(facility, state.public),
    names: addNames(state.names, [facility]),
  })),
  on(FacilityActions.resetFacilityStore, () => ({
    ...initialState,
  })),
);
