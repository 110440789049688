import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  DynamicAbstractControlField,
  DynamicDateInputField,
  HasDynamicField,
} from '@easyhpad-ui/app/library/form/contracts';
import { AbstractControl, FormControl, NgModel, ValidationErrors } from '@angular/forms';
import { DynamicInputPropertySetter } from '@easyhpad-ui/app/library/form/services';
import { DATE_FORMATS, DateFormatter } from '@application/framework/date';
import { FormControlDirective } from '@easyhpad-ui/app/library/form/directives/form-control/form-control.directive';
import { Subject, takeUntil } from 'rxjs';
import { syncControlsDirty, syncControlsTouch } from '@easyhpad-ui/app/library/form/functions';

@Component({
  selector: 'ehp-dynamic-date-input-field',
  templateUrl: './dynamic-date-input-field.component.html',
  styleUrl: './dynamic-date-input-field.component.scss',
})
export class DynamicDateInputFieldComponent
  implements HasDynamicField<DynamicDateInputField>, OnInit, AfterViewInit, OnDestroy
{
  /**
   * Dynamic Field configuration
   */
  @Input() public field!: DynamicAbstractControlField<DynamicDateInputField>;

  /**
   * Input HTML element reference
   */
  @ViewChild('input', { read: ElementRef }) public input!: ElementRef;

  /**
   * Input HTML element reference
   */
  @ViewChild('input', { read: FormControlDirective }) public formControl!: FormControlDirective;

  /**
   * Angular model directive
   */
  @ViewChild('input', { read: NgModel }) public ngModel!: NgModel;

  /**
   * Date string
   */
  public value: string = '';

  private destroy$ = new Subject<void>();

  /**
   * Parent control
   * @private
   */
  public get errors(): ValidationErrors | null {
    const control = this.control;
    return control?.errors ?? null;
  }

  /**
   * Parent control
   * @private
   */
  private get control(): FormControl<Date> | null {
    if (!this.field || !this.field.control) {
      return null;
    }

    return this.field.control as FormControl;
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly dateFormatter: DateFormatter,
  ) {}

  public ngOnInit() {
    if (this.field.initialValue) {
      this.field.initialValue.pipe(takeUntil(this.destroy$)).subscribe(value => {
        if ((value as any) instanceof Date) {
          this.setInitialValue(value);
        }
      });
    }

    if (this.control) {
      this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(date => this.patchValue(date));
    }
  }

  public ngAfterViewInit(): void {
    if (this.input) {
      const setter = new DynamicInputPropertySetter(this.renderer);
      setter.defineProperties(this.input, this.field);
    }

    if (this.control && this.control.value) {
      this.patchValue(this.control.value);
    }

    if (!!(this.formControl && this.control)) {
      this.formControl.attachControl(this.control);
    }

    if (this.control && this.ngModel) {
      this.linkModelAndControl(this.ngModel, this.control);
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public updateControlValue(value: string | null) {
    if (!value) {
      return;
    }

    this.control?.setValue(new Date(value));
    this.control?.markAsDirty();
  }

  private setValue(date: Date) {
    this.value = this.dateFormatter.format(date, DATE_FORMATS.SHORT_HTML);
  }

  private patchValue(date: Date) {
    if ((date && !this.value) || date !== new Date(this.value)) {
      this.setValue(date);
    }
  }

  private linkModelAndControl(model: NgModel, control: AbstractControl): void {
    syncControlsDirty(model.control, control);
    syncControlsTouch(model.control, control);

    if (control.dirty) {
      model.control.markAsDirty();
    }

    if (control.touched) {
      model.control.markAsTouched();
    }
  }

  private setInitialValue(value: Date | undefined) {
    if (this.control?.touched || this.control?.dirty || value === undefined) {
      return;
    }

    this.patchValue(value);
    setTimeout(() => this.control?.patchValue(value), 0);
  }
}
