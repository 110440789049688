import { EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { DocumentStoreState } from './document.state';

export interface DocumentStoreSelectors<E extends { id: ID }, ID = string | number> {
  /**
   * Get the document root state
   */
  selectDocumentsRootState: MemoizedSelector<any, EntityState<E>>;

  /**
   * Select all document views
   */
  selectViews: MemoizedSelector<any, DocumentStoreState<E, ID>['views']>;

  /**
   * Select "list" view
   */
  selectListView: MemoizedSelector<any, DocumentStoreState<E, ID>['views']['list']>;

  /**
   * Select the "list" view loading state
   */
  selectListLoading: MemoizedSelector<any, boolean>;

  /**
   * select the current document's id to display in list view.
   */
  selectListDocumentsIds: MemoizedSelector<any, Array<E['id']>>;

  /**
   * select the current documents to display in list view.
   */
  selectListDocuments: MemoizedSelector<any, E[]>;

  /**
   * Select "single" view
   */
  selectSingleView: MemoizedSelector<any, DocumentStoreState<E, ID>['views']['single']>;

  /**
   * Get the single document's id
   */
  selectSingleDocumentId: MemoizedSelector<any, E['id'] | null>;

  /**
   * Get a single document entity
   */
  selectSingleDocument: MemoizedSelector<any, E | null>;
}

export const createDocumentsSelectors = <E extends { id: ID }, ID = string | number>(
  selector: (state: any) => DocumentStoreState<E, ID>,
  adapter: EntityAdapter<E>,
): DocumentStoreSelectors<E, ID> => {
  const selectDocumentsRootState = createSelector(selector, state => state.documents);

  const selectors = adapter.getSelectors();

  const selectAllDocuments = createSelector(selectDocumentsRootState, selectors.selectAll);

  const selectAllDocumentEntities = createSelector(selectDocumentsRootState, selectors.selectEntities);

  const selectViews = createSelector(selector, state => state.views);

  const selectListView = createSelector(selectViews, state => state.list);

  const selectListLoading = createSelector(selectListView, state => state.loading);

  const selectListDocumentsIds = createSelector(selectListView, state => state.ids);

  const selectListDocuments = createSelector(selectAllDocuments, selectListDocumentsIds, (state, ids) =>
    state.filter(d => ids.includes(d.id)),
  );

  const selectSingleView = createSelector(selectViews, state => state.single);

  const selectSingleDocumentId = createSelector(selectSingleView, state => state.id);

  const selectSingleDocument = createSelector(selectAllDocumentEntities, selectSingleDocumentId, (state, id) => {
    if (id) {
      const entity = state[id as string];
      return entity ? entity : null;
    }
    return null;
  });

  return {
    selectDocumentsRootState,
    selectViews,
    selectListView,
    selectListLoading,
    selectListDocumentsIds,
    selectListDocuments,
    selectSingleView,
    selectSingleDocument,
    selectSingleDocumentId,
  };
};
