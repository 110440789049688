import { CPOMRepository, CreateCPOMDto, StoredCPOM, UpdateCPOMDto } from '@application/bundles/cpom';
import { Provide, Service } from '@application/framework/di';
import { EasyCPOMTransformer } from '../../transformers';
import { DocumentRepositoryV2 } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { EasyCPOM } from '@implementations/bundles/backend/easyhpad';
import { Facility } from '@domain/facility';

@Service()
export class EasyCPOMRepository extends DocumentRepositoryV2<any, any, StoredCPOM, EasyCPOM> implements CPOMRepository {
  @Provide() private readonly entityTransformer!: EasyCPOMTransformer;

  constructor() {
    super('cpom/documents', {
      from: input => this.entityTransformer.reverseTransform(input),
      to: output => this.entityTransformer.transform(output),
      create: (input: CreateCPOMDto) => this.entityTransformer.reverseTransformCreate(input),
      update: (input: UpdateCPOMDto) => this.entityTransformer.reverseTransformUpdate(input),
    });
  }

  public current(facilityId: Facility['id']): Promise<StoredCPOM | undefined> {
    return this.backend
      .get<{ current: EasyCPOM | null }>(`facility/${facilityId}/cpom/current`)
      .then(response => this.parseResponse(response))
      .then(response => (response.current ? this.toEntity(response.current) : undefined));
  }
}
