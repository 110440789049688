import {Component, Input} from '@angular/core';

@Component({
  selector: 'ehp-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  host: {
    '[class.invalid]': '!valid'
  }
})
export class PanelComponent {


  @Input() public valid: boolean = true;
}
