import { AuthorizationChecker, CRUD_ACTIONS } from '@application/bundles/authorization';
import { FILE_DEPOSIT_FEATURE } from '@application/bundles/file-deposit/tokens';
import { Service } from '@application/framework/di';

@Service()
export class FileDepositAuthorizationChecker {
  constructor(private readonly authorizationChecker: AuthorizationChecker) {}

  public canManage() {
    return this.authorizationChecker.isGranted([{ feature: FILE_DEPOSIT_FEATURE, value: CRUD_ACTIONS.MANAGE }]);
  }

  public async canRead(): Promise<boolean> {
    if (await this.canManage()) {
      return true;
    }
    return this.authorizationChecker.isGranted([{ feature: FILE_DEPOSIT_FEATURE, value: CRUD_ACTIONS.READ }]);
  }

  public async canCreate(): Promise<boolean> {
    if (await this.canManage()) {
      return true;
    }
    return this.authorizationChecker.isGranted([{ feature: FILE_DEPOSIT_FEATURE, value: CRUD_ACTIONS.CREATE }]);
  }

  public async canEdit(): Promise<boolean> {
    if (await this.canManage()) {
      return true;
    }
    return this.authorizationChecker.isGranted([{ feature: FILE_DEPOSIT_FEATURE, value: CRUD_ACTIONS.UPDATE }]);
  }

  public async canDelete(): Promise<boolean> {
    if (await this.canManage()) {
      return true;
    }
    return this.authorizationChecker.isGranted([{ feature: FILE_DEPOSIT_FEATURE, value: CRUD_ACTIONS.DELETE }]);
  }
}
