import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from '@easyhpad-ui/app/bundles/dashboard/dashboard.module';
import { FacilityFormModule } from '@easyhpad-ui/app/bundles/facility/modules/form/facility-form.module';
import { FacilityUiModule } from '@easyhpad-ui/app/bundles/facility/modules/ui/facility-ui.module';
import { MediaModule } from '@easyhpad-ui/app/bundles/media/media.module';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { FilterModule } from '@easyhpad-ui/app/library/filter/filter.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { TooltipModule } from 'primeng/tooltip';
import { GmpCalculatorComponent, GmpLinkComponent, GmpListFiltersComponent, GmpListItemComponent } from './components';
import { GmpOtherListComponent } from './components/';
import { GmpAverageComponent } from './components/gmp-average/gmp-average.component';
import { GmpListComponent } from './components/gmp-list/gmp-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationModule,
    FacilityFormModule,
    FormModule,
    MediaModule,
    TooltipModule,
    ReusableComponentsModule,
    FacilityUiModule,
    FilterModule,
    DashboardModule,
  ],
  declarations: [
    GmpLinkComponent,
    GmpListItemComponent,
    GmpListComponent,
    GmpOtherListComponent,
    GmpCalculatorComponent,
    GmpListFiltersComponent,
    GmpAverageComponent,
  ],
  exports: [
    GmpLinkComponent,
    GmpListItemComponent,
    GmpListComponent,
    GmpOtherListComponent,
    GmpCalculatorComponent,
    GmpListFiltersComponent,
    GmpAverageComponent,
  ],
})
export class GmpUIModule {}
