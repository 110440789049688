<form (submit)="submit()" [formGroup]="form" novalidate>

  <div class="form-row">

    <div class="row-header">
      <h2 class="row-title title">{{"Informations sur l'utilisateur" | ehp_translate }}</h2>
    </div>

    <div class="row-content">

      <ehp-form-field class="form-field required">
        <label for="firstname">{{'Prénom'| ehp_translate}}</label>
        <input ehp-form-control formControlName="firstname" id="firstname" type="text">
      </ehp-form-field>

      <ehp-form-field class="form-field required">
        <label for="lastname">{{'Nom'| ehp_translate}}</label>
        <input ehp-form-control formControlName="lastname" id="lastname" type="text">
      </ehp-form-field>

    </div>

  </div>

  <div class="form-row">

    <div class="row-header">
      <h2 class="row-title title">{{"Mot de passe" | ehp_translate }}</h2>
      <p
        class="description">{{"Laissez vide si vous ne souhaitez pas modifier votre mot de passe actuel." | ehp_translate}}</p>
    </div>

    <div class="row-content">

      <ehp-form-field>
        <ehp-password-updater
          [allowEmpty]="true"
          [setDefault]="false"
          ehp-form-control
          formControlName="password"
          id="password"></ehp-password-updater>

      </ehp-form-field>
    </div>

  </div>


  <div class="actions">
    <button [disabled]="!form.valid" class="primary" type="submit">
      {{ 'Mettre à jour mon profil' | ehp_translate }}
    </button>
  </div>
</form>
