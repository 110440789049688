import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { FacilityGmpWidgetComponent } from './components';

@NgModule({
  imports: [CommonModule, RouterModule, TranslationModule],
  declarations: [FacilityGmpWidgetComponent],
  exports: [FacilityGmpWidgetComponent],
})
export class GmpDashboardModule {}
