import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {Component, Inject} from '@angular/core';
import {AbstractControl, FormControl, ValidationErrors} from "@angular/forms";
import {ConfirmCustomerDeletionDialog} from "./confirm-customer-deletion-dialog.interface";

@Component({
  selector: 'ehp-confirm-customer-deletion-dialog',
  templateUrl: './confirm-customer-deletion-dialog.component.html',
  styleUrls: ['./confirm-customer-deletion-dialog.component.scss']
})
export class ConfirmCustomerDeletionDialogComponent {

  public control: FormControl<string | null>;


  public constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: ConfirmCustomerDeletionDialog
  ) {

    this.validate = this.validate.bind(this);

    if (!this.data.customer || !this.data.customer.name) {
      this.cancel();
    }

    this.control = new FormControl<string>('', {
      validators: [this.validate],
      updateOn: "change"
    });

  }

  public confirm() {
    if (!this.control.valid) {
      return;
    }
    this.dialogRef.close(true);
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  private validate(control: AbstractControl): ValidationErrors | null {
    const isValid = this.data.customer && this.data.customer.name && control.value === this.data.customer.name;

    if (isValid) {
      return null;
    }
    return {customerNameMismatch: true};
  }
}
