import { CreateDemo, DemoDtoFactory } from '@application/bundles/demo';

import { CreateDemoDto } from '../dtos';
import { plainToClass } from 'class-transformer';

export class DemoDtoFactoryImpl extends DemoDtoFactory implements DemoDtoFactory {
  public newCreateDemoDtoFromUnsafeValues(values: Record<keyof CreateDemo, any>): CreateDemo {
    return plainToClass(CreateDemoDto, values);
  }
}
