<div *ngIf="pmp" class="amount-container">
  <p class="amount"><span *ngIf="pmp">{{pmp.value}}</span></p>

  <div *ngIf="national" class="national-container">
    <span class="value"> {{national.value}}</span>
    <span class="label">{{'National'|ehp_translate}}</span>
  </div>
</div>

<p *ngIf="difference" class="difference">
  <span [ngClass]="isPositive() ? 'up': 'down'" class="value">{{difference}} %</span>
  <ehp-translatable-string [value]="differenceMessage" class="label"></ehp-translatable-string>
</p>
