import { CreateCustomerDto } from '@application/bundles/customer/dto/create-customer.dto';
import { CustomerDtoFactory } from '@application/bundles/customer/factories';
import { Transformer } from '@application/framework/core';
import { EasyCreateCustomerDto } from '@implementations/bundles/backend/easyhpad/interfaces';
import { Provide, Service } from '@application/framework/di';
import { EasyCommonCustomerTransformer } from '@implementations/bundles/backend/easyhpad/transformers/customer/easy-common-customer/easy-common-customer.transformer';

@Service()
export class EasyCustomerCreateDtoTransformer implements Transformer<CreateCustomerDto, EasyCreateCustomerDto> {
  @Provide() private readonly commonTransformer!: EasyCommonCustomerTransformer;

  @Provide() private readonly factory!: CustomerDtoFactory;

  public transform(dto: CreateCustomerDto): EasyCreateCustomerDto {
    return {
      ...this.commonTransformer.transform(dto),
      address: this.commonTransformer.addressTransformer.transformCreateAddress(dto.address),
      billingAddress:
        dto.billingAddress !== undefined
          ? this.commonTransformer.addressTransformer.transformCreateAddress(dto.billingAddress)
          : undefined,
    };
  }

  /*
  address: value.address ? this.addressTransformer.reverseTransform(value.address) : undefined,
      billingAddress: value.billing_address
        ? this.addressTransformer.reverseTransform(value.billing_address)
        : undefined,
   */

  public reverseTransform(dto: EasyCreateCustomerDto): CreateCustomerDto {
    return this.factory.newCreateCustomerDtoFromUnsafeValues({
      ...this.commonTransformer.reverseTransform(dto),
      address: this.commonTransformer.addressTransformer.reverseTransformCreateAddress(dto.address),
    });
  }
}
