import {Command} from "@application/framework/command-query/command.interface";
import {
  InvalidCommandConstructorError,
  InvalidCommandHandlerFactoryError
} from "@application/framework/command-query/errors";
import {CommandHandler} from "@application/framework/command-query/handler.interface";

export type CommandHandlerFactory<C extends Command = Command, R = any> = (command?: C) => CommandHandler<C, R>;

export type CommandConstructor<C> = { name: string };

export abstract class CommandHandlerRegistry {

  private static readonly store: Map<string, CommandHandlerFactory<Command, any>> = new Map();

  protected get store(): Map<string, CommandHandlerFactory<Command, any>> {
    return CommandHandlerRegistry.store;
  }

  /**
   * Register new handler for command
   * @param command
   * @param handlerFactory
   */
  public static register<C extends Command, R = any>(command: CommandConstructor<C>, handlerFactory: CommandHandlerFactory<C, R>): void {

    if (!command || !command.name) {
      throw new InvalidCommandConstructorError();
    }

    if (typeof handlerFactory !== 'function') {
      throw new InvalidCommandHandlerFactoryError();
    }

    this.store.set(command.name, handlerFactory as CommandHandlerFactory<Command, R>);
  }

  /**
   * Register new handler for command
   * @param command
   * @param handlerFactory
   */
  public abstract register<C extends Command, R = any>(command: CommandConstructor<C>, handlerFactory: CommandHandlerFactory<C, R>): void;

  /**
   * Get handler for command
   * @param command
   */
  public abstract get<C extends Command, R = any>(command: C): CommandHandler<C, R> | undefined;


}
