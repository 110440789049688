import {isValidLocalCPOMIdType} from "@application/bundles/cpom";
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';

@ValidatorConstraint({name: 'IsValidCPOMId', async: true})
export class IsValidCPOMIdConstraint implements ValidatorConstraintInterface {

  public validate(id: any, args?: ValidationArguments): Promise<boolean> {
    return Promise.resolve(isValidLocalCPOMIdType(id));
  }

  public defaultMessage(args?: ValidationArguments) {
 
    if (args !== undefined) {
      return `id property value (${args.value}) is not a valid CPOM ID.`;
    }
    return `id property value is not a valid CPOM ID.`;
  }
}

export function IsValidCPOMId(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidCPOMIdConstraint
    });
  };
}
