import { DateString } from '@application/framework/date';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { EasyFacility } from '@implementations/bundles/backend/easyhpad';
import { UUID } from '@domain/lib';

export interface EasyPMP {
  id: UUID;

  facilityId: EasyFacility['id'];

  pvId: EasyMedia['id'];

  validationDate: DateString;

  value: number;

  createdAt?: DateString;

  updatedAt?: DateString;
}

export interface EasyPMPCreateDto extends Omit<EasyPMP, 'id'> {}

export interface EasyPMPUpdateDto extends Omit<EasyPMP, 'pvId'> {
  pvId?: EasyMedia['id'];
}
