import { Component, HostListener, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isLocalMedia, isMedia, LocalMedia, Media, MediaBucket } from '@domain/media';
import { isValidMediaIdType } from '@application/bundles/media';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ehp-media-download-button',
  templateUrl: './media-download-button.component.html',
  styleUrls: ['./media-download-button.component.scss'],
})
export class MediaDownloadButtonComponent implements OnChanges {
  @Input() public mediaId!: Media['id'];

  @Input() public media: LocalMedia | Media | undefined;

  public url: string | undefined;

  private valid: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly bucket: MediaBucket,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if ('media' in changes) {
      this.mediaHasChange();
    }

    if ('mediaId' in changes) {
      this.valid = isValidMediaIdType(changes['mediaId'].currentValue);
    }
  }

  @HostListener('click')
  public async download() {
    let url: string | undefined;

    if (this.url) {
      url = this.url;
    } else if (this.media) {
      url = await this.getUrl(this.media);
    } else if (isValidMediaIdType(this.mediaId)) {
      url = await this.loadMediaAndUrl(this.mediaId);
    }

    if (!url) {
      this.valid = false;
      return;
    }

    this.execute(url);
  }

  private async loadMediaAndUrl(id: Media['id']): Promise<string> {
    const media = await this.bucket.get(id);
    this.setMedia(media);

    return this.getUrl(media);
  }

  private async getUrl(media: LocalMedia | Media) {
    const url = await media.buildUrl().then(url => url.toString());
    this.url = url;
    return url;
  }

  private mediaHasChange(): void {
    this.url = undefined;

    if (!isLocalMedia(this.media) && !isMedia(this.media)) {
      return;
    }

    this.setMedia(this.media);
  }

  private setMedia(media: Media | LocalMedia) {
    this.url = undefined;
    this.media = media;

    if (isMedia(media) && media.id !== this.mediaId) {
      this.mediaId = media.id;
    }
  }

  private execute(link: string): void {
    const a = this.document.createElement('a');
    a.href = link;
    a.download = '';
    a.target = '_blank';
    a.click();
  }
}
