import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { authentificationActions } from '@easyhpad-ui/app/bundles/authentification/store/authentification.action';
import { AuthentificationTokenStore } from '@application/bundles/authentification';
import { QueryBus } from '@application/framework/command-query';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';

@Injectable()
export class AuthentificationEffects {
  constructor(
    private readonly store: Store<AppState>,
    private readonly actions$: Actions,
    private readonly queryBus: QueryBus,
    private readonly accountAccessor: AuthentificationTokenStore,
  ) {
    this.syncWithTheAuthenticationStore();
  }

  /**
   * When the account is set in the Authentification store, set the account in the redux store.
   * @private
   */
  private syncWithTheAuthenticationStore(): void {
    this.accountAccessor.onChange(change => {
      const account = change.current;
      this.store.dispatch(authentificationActions.setCurrentAccount({ account }));
    });
  }
}
