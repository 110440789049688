import { NgModule } from '@angular/core';
import { NationalPMPIndicatorRepository, PMPBundle, PMPDtoFactory, PMPRepository } from '@application/bundles/pmp';
import { Bundle } from '@application/framework/bundle';
import { extractDependencies } from '@application/framework/di';
import { OpenPMPDeletionDialogCommandHandler } from '@easyhpad-ui/app/bundles/pmp/commands/handlers';
import { PMPUrlsProvider } from '@easyhpad-ui/app/bundles/pmp/providers/url-provider/pmp-urls.provider';
import { PMPDashboardModule } from '@easyhpad-ui/app/bundles/pmp/modules/dashboard/pmp-dashboard.module';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator/validator.module';
import { EasyPMPRepository } from '@implementations/bundles/backend/easyhpad';
import {
  CreatePMPDtoImpl,
  createPMPDtoValidator,
  PMPDtoFactoryImpl,
  UpdatePMPDtoImpl,
  updatePMPDtoValidator,
} from '@implementations/bundles/pmp';
import { NationalPMPIndicatorRepositoryImpl } from '@implementations/bundles/pmp/repositories/national-pmp-indicator.repository';
import { StoreModule } from '@ngrx/store';
import { PMP_STORE_KEY, PMPEffect, pmpReducer } from '@easyhpad-ui/app/bundles/pmp/store';
import { EffectsModule } from '@ngrx/effects';

@Bundle({
  imports: [PMPBundle],
  commandHandlers: [OpenPMPDeletionDialogCommandHandler],
})
@NgModule({
  imports: [
    StoreModule.forFeature(PMP_STORE_KEY, pmpReducer),
    EffectsModule.forFeature([PMPEffect]),
    PMPDashboardModule,
    ValidatorModule.forChild({
      validators: [
        { objectType: CreatePMPDtoImpl, validator: createPMPDtoValidator },
        { objectType: UpdatePMPDtoImpl, validator: updatePMPDtoValidator },
      ],
    }),
  ],
  providers: [
    {
      provide: PMPUrlsProvider,
    },
    {
      provide: PMPRepository,
      useClass: EasyPMPRepository,
      deps: extractDependencies(EasyPMPRepository),
    },
    {
      provide: NationalPMPIndicatorRepository,
      useValue: new NationalPMPIndicatorRepositoryImpl(),
    },
    {
      provide: PMPDtoFactory,
      useClass: PMPDtoFactoryImpl,
    },
  ],
})
export class PMPModule {
  constructor() {
    new PMPBundle();
  }
}
