import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {DATE_FORMATS, DateFormatter} from "@application/framework/date";
import {Logger} from "@application/framework/logger";
import {NativeChangeObserver} from "@easyhpad-ui/app/framework/native-change-observer";
import {Subject, takeUntil} from "rxjs";

const selector = 'ehp-date-interval-input';

@Component({
  selector,
  templateUrl: './date-interval-input.component.html',
  styleUrls: ['./date-interval-input.component.scss'],
})
export class DateIntervalInputComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public id: string | undefined;

  @Input('startControl') public start: AbstractControl | null | undefined;

  @Input('endControl') public end: AbstractControl | null | undefined;

  /**
   * @deprecated use options instead
   */
  @Input() public endRequired: boolean = true;

  /**
   * @deprecated use options instead
   */
  @Input() public endSuperior: 'strict' | 'same' = 'strict';

  @Input() public options?: {
    endRequired?: boolean,
    endSuperior?: 'strict' | 'same',
    startReadonly?: boolean,
    endReadonly?: boolean,
  };

  public dates: FormGroup<{ start: FormControl, end: FormControl }>;

  private destroy$: Subject<void> = new Subject<void>();

  public get nodeName(): string {
    return selector;
  }

  public get value() {
    return this.dates.value;
  }

  public set value(value: any) {
    this.writeValue(value);
  }

  public get localStartControl(): FormControl | null {
    return this.dates.get('start') as FormControl || null;
  }

  public get localEndControl(): FormControl | null {
    return this.dates.get('end') as FormControl || null;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dateFormatter: DateFormatter,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly nativeChanges: NativeChangeObserver,
    protected readonly logger: Logger
  ) {
    this.logger = logger.channel(this.constructor.name);

    this.validateDateValues = this.validateDateValues.bind(this);

    this.nativeChanges.observe(this.elementRef, 'required').subscribe(change => {
      const isRequired = coerceBooleanProperty(change.currentValue);
      this.setRequiredState(isRequired);
    });

    this.dates = this.formBuilder.group({
      start: new FormControl(),
      end: new FormControl(),
    }, {validators: [this.validateDateValues]});

    this.localStartControl?.valueChanges.subscribe(value => this.dispatchChange({start: value}));
    this.localEndControl?.valueChanges.subscribe(value => this.dispatchChange({end: value}));
  }

  public ngOnInit(): void {
    this.setInitialEndRequiredValidator();

    this.start?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((start) => {
      this.writeValue({start});
      this.updateErrors(this.start, this.localStartControl);
    });
    this.end?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((end) => {
      this.writeValue({end});
      this.updateErrors(this.end, this.localEndControl);
    });

    
    this.writeValue({start: this.start?.value, end: this.end?.value});


    this.options = {
      endRequired: this.endRequired,
      endSuperior: this.endSuperior,
      ...this.options
    }

  }

  public ngAfterViewInit(): void {
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public writeValue(value: { start?: string | Date | null, end?: string | Date | null } | undefined): void {

    if (!value) {
      return;
    }

    if (value.start instanceof Date) {
      this.localStartControl?.setValue(this.dateFormatter.format(value.start, DATE_FORMATS.SHORT_HTML));
      this.logger.warning(`Please provide start date as string with format "${DATE_FORMATS.SHORT_HTML}", Date instance provided`);
    } else if (typeof value.start === 'string' || value.start === null) {
      this.localStartControl?.setValue(value.start);
    }

    if (value.end instanceof Date) {
      this.localEndControl?.setValue(this.dateFormatter.format(value.end, DATE_FORMATS.SHORT_HTML));
      this.logger.warning(`Please provide end date as string with format "${DATE_FORMATS.SHORT_HTML}", Date instance provided`);
    } else if (typeof value.end === 'string' || value.end === null) {
      this.localEndControl?.setValue(value.end);
    }

  }

  private dispatchChange(changes: Partial<{ start?: any, end?: any }>): void {

    if (changes.start && this.start && changes.start !== this.start.value) {
      this.start.setValue(changes.start);
    }

    if (changes.end && this.end && changes.end !== this.end.value) {
      this.end.setValue(changes.end);
    }

  }

  /**
   * Set Validator.required on end form control
   * @private
   */
  private setInitialEndRequiredValidator() {
    if (this.options?.endRequired) {
      this.localEndControl?.addValidators(Validators.required);
    } else if (this.localEndControl?.hasValidator(Validators.required)) {
      this.localEndControl?.removeValidators(Validators.required);
    }
  }


  private setRequiredState(isRequired: boolean): void {
    if (isRequired) {
      this.localStartControl?.setValidators(Validators.required);
      if (this.options?.endRequired) {
        this.localEndControl?.setValidators(Validators.required);
      }
    } else {
      this.localStartControl?.removeValidators(Validators.required);
      if (!this.options?.endRequired) {
        this.localEndControl?.removeValidators(Validators.required);
      }
    }
  }

  private validateDateValues(group: AbstractControl): ValidationErrors | null {

    const isRequired = group.get('end')?.hasValidator(Validators.required);

    if (group.value.start && group.value.end) {

      const start = new Date(group.value.start);
      const end = new Date(group.value.end);


      let error = null;
      if (this.options?.endSuperior === 'same') {
        error = end < start ? {endDateSameSuperior: true} : null;
      } else {
        error = end <= start ? {endDateStrictSuperior: true} : null;
      }


      if (error) {

        if (this.localEndControl) {
          this.localEndControl?.setErrors(error);
        } else {
          this.dates.setErrors(error);
        }

        return error;
      }

    }

    if (isRequired && !group.value.end) {
      return {missingEndDate: true}
    }

    return null;
  }


  private updateErrors(source: AbstractControl | null | undefined, control: AbstractControl | null | undefined) {

    if (source && control) {
      control.setErrors(source.errors);
    }

  }
}
