import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoUrlsProvider } from '@easyhpad-ui/app/bundles/demo/modules/providers';
import { DemoDtoFactory, DemoVoter } from '@application/bundles/demo';
import { DemoDtoFactoryImpl } from '@implementations/bundles/demo';
import { Bundle } from '@application/framework/bundle';

@NgModule({
  imports: [CommonModule],
  providers: [
    DemoUrlsProvider,
    {
      provide: DemoDtoFactory,
      useValue: new DemoDtoFactoryImpl(),
    },
  ],
})
@Bundle({
  voters: [new DemoVoter()],
})
export class DemoModule {}
