import {ActivityAnnexUpdatedEvent, ActivityAnnexUpdateFailEvent} from "@application/bundles/activity-annex";
import {ACTIVITY_ANNEX_TRANSLATE_CONTEXT} from "@application/bundles/activity-annex/activity-annex.token";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnActivityAnnexUpdateEventSubscriber {

  constructor(private readonly noticeStream: NoticeStream) {
  }

  @OnEvent({
    event: ActivityAnnexUpdatedEvent
  })
  private async pushNoticeOnActivityAnnexUpdateSuccess(event: ActivityAnnexUpdatedEvent): Promise<void> {

    const facility = await event.activityAnnex.facility();

    const message = new TranslatableString(
      "L'<strong>annexe activité {{ year }}</strong> pour l'établissement <strong>{{ name }}</strong> a été mis à jour avec succès.",
      {year: event.activityAnnex.year, name: facility.name},
      ACTIVITY_ANNEX_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  @OnEvent({
    event: ActivityAnnexUpdateFailEvent
  })
  private pushNoticeOnActivityAnnexUpdateFail(event: ActivityAnnexUpdateFailEvent): void {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        "Une erreur est survenue lors de la modification de annexe d'activité.",
        undefined,
        ACTIVITY_ANNEX_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }
}
