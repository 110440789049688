<form (ngSubmit)="submit()" *ngIf="form" [formGroup]="form">

  <ehp-form-field>
    <label for="email">{{'E-mail'| ehp_translate}}</label>
    <div class="description">
      {{ "Un e-mail contenant la procédure de récupération de mot de passe sera envoyé à cette adresse" | ehp_translate }}
    </div>
    <input ehp-form-control formControlName="email" id="email" type="email">
  </ehp-form-field>

  <div *ngIf="loginEnable" class="forgotten-container">
    <button (click)="moveToLogin()" class="link" type="button">
      {{'Se connecter' | ehp_translate}}
      <i aria-hidden="true" class="icon icon-arrow ehp-icons"></i>
    </button>
  </div>

  <div class="actions">
    <button [disabled]="!form.valid" class="primary" type="submit">
      {{ 'Demander la réinitialisation' | ehp_translate }}
    </button>
  </div>
</form>
