import {InjectionToken, ProviderToken} from "@angular/core";
import {ServiceIdentifier} from "@application/framework/di/types/service-identifier";

/**
 * Store all service identifier wrapped in Angular injection token
 *
 */
const wrappedServiceIdentifiers: Map<string | Symbol, ProviderToken<any>> = new Map();


export function wrapServiceIdentifier<T = unknown>(identifier: ServiceIdentifier<T>): ProviderToken<any> {

  if (typeof identifier === 'string' || typeof identifier === 'symbol') {
    let token = wrappedServiceIdentifiers.get(identifier);

    if (token === undefined) {
      token = new InjectionToken(identifier.toString());
      wrappedServiceIdentifiers.set(identifier, token);
    }
    return token;
  }

  return identifier as any;
}
