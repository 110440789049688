import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {SORT_ORDER} from "@application/framework/search/sort";

@Component({
  selector: 'ehp-sortable-label',
  templateUrl: './sortable-label.component.html',
  styleUrls: ['./sortable-label.component.scss'],
  host: {
    '[class.asc]': 'isAsc',
    '[class.desc]': '!isAsc',
    '[class.sortable]': 'sortable'
  }
})
export class SortableLabelComponent {

  @Input() public sortable: boolean = false;

  @Input('asc') public isAsc: boolean = false;

  @Output() public sort: EventEmitter<SORT_ORDER> = new EventEmitter();


  @HostListener('click')
  public toggle() {
    this.isAsc = !this.isAsc;
    this.sort.emit(this.isAsc ? SORT_ORDER.ASC : SORT_ORDER.DESC);
  }
}
