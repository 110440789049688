import { hasFacilityReference, isValidLocalCPOMIdType, StoredCPOM, StoredLocalCPOM } from '@application/bundles/cpom';
import { InvalidFacilityIdTypeError, isValidFacilityIdType } from '@application/bundles/facility';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { QueryBus } from '@application/framework/command-query';
import { Provide } from '@application/framework/di';
import { CPOMFunding, TariffOption } from '@domain/cpom';
import { Facility } from '@domain/facility';
import { Media } from '@domain/media';
import { FundingCollection } from '@domain/funding';

export class LocalCPOMImpl implements StoredLocalCPOM {
  public id!: StoredLocalCPOM['id'];

  public tariffOption!: TariffOption;

  public funding!: FundingCollection<CPOMFunding>;

  public bedSocialCare: number | undefined;

  public parent: StoredCPOM | undefined;

  public facilityId!: Facility['id'];

  @Provide()
  private readonly queryBus!: QueryBus;
  private facilityRequest: Promise<Facility> | undefined;

  public get start(): Date {
    if (this.parent === undefined) {
      throw new Error(`Cannot get start date, Parent CPOM is missing in Local CPOM ${this.id}`);
    }
    return this.parent.date;
  }

  public get end(): Date {
    if (this.parent === undefined) {
      throw new Error(`Cannot get end date, Parent CPOM is missing in Local CPOM ${this.id}`);
    }

    return this.parent.end;
  }

  public get year(): number {
    if (this.parent === undefined) {
      throw new Error(`Cannot get year, Parent CPOM is missing in Local CPOM ${this.id}`);
    }

    return this.parent.year;
  }

  constructor(values?: Partial<Omit<StoredLocalCPOM, 'start' | 'end' | 'year' | 'document' | 'facility'>>) {
    if (values) {
      if (isValidLocalCPOMIdType(values.id)) {
        this.id = values.id;
      }

      if (values.parent) {
        this.parent = values.parent;
      }

      if (values.tariffOption) {
        this.tariffOption = values.tariffOption;
      }

      if (values.bedSocialCare) {
        this.bedSocialCare = values.bedSocialCare;
      }

      if (values.funding) {
        this.funding = values.funding;
      }

      if (hasFacilityReference(values)) {
        this.facilityId = values.facilityId;
      }
    }
  }

  public async facility(): Promise<Facility> {
    if (!isValidFacilityIdType(this.facilityId)) {
      throw new InvalidFacilityIdTypeError(`${this.facilityId} cannot be used as a valid Facility id`);
    }

    if (!this.facilityRequest) {
      this.facilityRequest = this.queryBus.request<Facility>(new GetFacilityQuery(this.facilityId)).then(facility => {
        this.facilityRequest = undefined;
        return facility;
      });
    }

    return this.facilityRequest;
  }

  public async document(): Promise<Media> {
    if (this.parent === undefined) {
      throw new Error(`Cannot get document, Parent CPOM is missing in Local CPOM ${this.id}`);
    }
    return await this.parent.document();
  }
}
