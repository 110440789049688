import {TEMPORARY_DEPENDENCIES_FUNDING_MODES, TemporaryDependencyFunding} from "@domain/dependency-funding";

export interface CreateTemporaryDependencyFundingDto<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES, V> extends Omit<TemporaryDependencyFunding<M, V>, 'id' | 'mode' | 'value'> {

  mode: TEMPORARY_DEPENDENCIES_FUNDING_MODES;
  value: V;
}

export interface UpdateTemporaryDependencyFundingDto<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES, V> extends Omit<TemporaryDependencyFunding<M, V>, 'mode' | 'value'> {

  mode: TEMPORARY_DEPENDENCIES_FUNDING_MODES;
  value: V;
}
