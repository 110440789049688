import {createSelector} from '@ngrx/store';
import {ERRDStoreState} from "./eprd-errd.store.state";
import {ERRD_STORE_KEY} from "./eprd-errd-store.token";


export interface AppState {
  [ERRD_STORE_KEY]: ERRDStoreState;
}

export const selectERRDState = (state: AppState) => state[ERRD_STORE_KEY];

export const selectERRDPublic = createSelector(
  selectERRDState,
  (state: ERRDStoreState) => state.public
);

export const selectERRDPublicLoading = createSelector(
  selectERRDPublic,
  (state: ERRDStoreState['public']) => !!state?.loading || false
);

export const selectERRDPublicEntities = createSelector(
  selectERRDPublic,
  (state: ERRDStoreState['public']) => state?.entities || []
);
