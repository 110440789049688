import { MediaFactory } from '@application/bundles/media';
import { Transformer } from '@application/framework/core';
import { Media } from '@domain/media';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';

export class EasyMediaTransformer implements Transformer<Media, EasyMedia> {
  constructor(private readonly factory: MediaFactory) {}

  public transform(value: Media): EasyMedia {
    return {
      id: value.id,
      path: value.path,
      name: value.name,
      type: value.type,
    };
  }

  public reverseTransform(value: EasyMedia): Media {
    return this.factory.createMedia({
      id: value.id,
      type: value.type,
      name: value.name,
      path: value.path,
    });
  }
}
