import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginationComponent} from './components';
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";


@NgModule({
  declarations: [
    PaginationComponent
  ],
  imports: [
    CommonModule,
    TranslationModule
  ],
  exports: [
    PaginationComponent
  ]
})
export class PaginationModule {
}
