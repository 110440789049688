import { EhpadDemainCommonDtoAbstract } from '@implementations/bundles/ehpad-demain/dto/ehpad-demain-common.dto.abstract';
import { UpdateEhpadDemainDto } from '@application/bundles/ehpad-demain';
import { isLocalMedia, isMedia, LocalMedia, Media } from '@domain/media';
import { IsLocalMediaOrMedia, IsMediaId } from '@implementations/bundles/media';
import { IsArray, IsOptional } from 'class-validator';
import { isValidMediaIdType } from '@application/bundles/media';
import { IsValidEhpadDemainId } from '@implementations/bundles/ehpad-demain/validators';
import { UUID } from '@domain/lib';

export class UpdateEhpadDemainDtoImpl extends EhpadDemainCommonDtoAbstract implements UpdateEhpadDemainDto {
  @IsValidEhpadDemainId()
  public id!: UUID;

  @IsOptional()
  @IsMediaId()
  public conventionId?: Media['id'];

  @IsOptional()
  @IsMediaId()
  public otherConventionId?: Media['id'];

  @IsArray()
  public medias: Array<Media | LocalMedia> = [];

  @IsOptional()
  @IsLocalMediaOrMedia()
  public convention!: LocalMedia | Media;

  @IsOptional()
  @IsLocalMediaOrMedia()
  public otherConvention!: LocalMedia | Media;

  constructor(values?: Partial<UpdateEhpadDemainDto>) {
    super(values);

    if (values) {
      if (values.id) {
        this.id = values.id;
      }

      if (Array.isArray(values.mediaIds)) {
        this.mediaIds = values.mediaIds;
      }

      if (Array.isArray(values.medias)) {
        this.medias = values.medias;
      }

      if (isValidMediaIdType(values.conventionId)) {
        this.conventionId = values.conventionId;
      }

      if (isValidMediaIdType(values.otherConventionId)) {
        this.otherConventionId = values.otherConventionId;
      }

      if (isLocalMedia(values.convention) || isMedia(values.convention)) {
        this.convention = values.convention;
      }

      if (isLocalMedia(values.otherConvention) || isMedia(values.otherConvention)) {
        this.otherConvention = values.otherConvention;
      }
    }
  }
}
