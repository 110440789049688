import {AddInspectionReportDto} from "@application/bundles/capacity-authorization/dtos";
import {
  AddCapacityAuthorizationDto
} from "@application/bundles/capacity-authorization/dtos/add-capacity-authorization.dto";
import {LocalMedia} from "@domain/media";
import {AbstractPartialCapacityAuthorization} from './partial-capacity-authorization.abstract';
import {IsLocalMediaOrMedia} from "@implementations/bundles/media";
import {IsOptional} from "class-validator";

export class AddCapacityAuthorizationDtoImpl extends AbstractPartialCapacityAuthorization implements AddCapacityAuthorizationDto {


  @IsLocalMediaOrMedia()
  public authorization: LocalMedia;

  @IsOptional()
  @IsLocalMediaOrMedia()
  public pv: LocalMedia | undefined;

  public inspectionReports: Array<AddInspectionReportDto> = [];

  constructor(values: AddCapacityAuthorizationDto) {
    super(values);
    this.facilityId = values.facilityId;
    this.authorization = values.authorization;
    this.pv = values.pv;
    this.inspectionReports = values.inspectionReports;
  }


}
