import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { MediaUploader } from '@application/bundles/media';
import { LocalMedia, Media, TemporaryMedia } from '@domain/media';
import { MediaTransfert } from '@domain/media/media-transfert';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { CreateEasyMedia, EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { EasyTemporaryMediaTransformer } from '@implementations/bundles/backend/easyhpad/transformers/media/easy-temporary-media.transformer';
import { MediaTransfertImpl } from '@implementations/bundles/media/media-transfert';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';

export class AngularMediaUploaderAdapter implements MediaUploader {
  private readonly url = `${EasyBackendApi.url.toString()}media`;

  constructor(
    private readonly http: HttpClient,
    private readonly transformer: EasyTemporaryMediaTransformer,
  ) {}

  public upload(media: LocalMedia): MediaTransfert<TemporaryMedia> {
    const data = this.buildFormData([media]);
    return this.sendTransfert<TemporaryMedia>(data, true);
  }

  public uploadMany(...medias: LocalMedia[]): MediaTransfert<TemporaryMedia[]> {
    const data = this.buildFormData(medias);

    return this.sendTransfert<TemporaryMedia[]>(data, false);
  }

  private buildFormData(medias: LocalMedia[]): FormData {
    const body: CreateEasyMedia[] = [];

    const formData = new FormData();

    for (const media of medias) {
      body.push({
        name: media.name,
        path: media.path,
        customerId: media.customerId,
      });

      formData.append('files', media.file);
    }

    formData.append('medias', JSON.stringify(body));

    return formData;
  }

  private buildFromEvent<R = Media | Media[]>(
    event: HttpEvent<any>,
    singleResult: boolean,
  ): Observable<{
    progress: number;
    result?: R;
  }> {
    if (event.type == HttpEventType.UploadProgress && event.total) {
      return of({ progress: Math.round(100 * (event.loaded / event.total)) });
    }

    if (event.type === HttpEventType.Response) {
      const entities: Array<EasyMedia> = event.body.entities;

      if (singleResult) {
        return this.http
          .get<{ entity: EasyMedia }>(`${this.url}/${entities[0].id}`)
          .pipe(
            map(content => ({ progress: 100, result: this.transformer.reverseTransform(content.entity) })),
          ) as Observable<{
          progress: number;
          result?: R;
        }>;
      } else {
        const observable = combineLatest(
          entities.map(entity => this.http.get<{ entity: EasyMedia }>(`${this.url}/${entity.id}`)),
        );

        return observable.pipe(
          map(entities => ({
            progress: 100,
            result: entities.map(entity => this.transformer.reverseTransform(entity.entity)),
          })),
        ) as Observable<{ progress: number; result?: R }>;
      }
    }

    return of({ progress: 0 });
  }

  private sendTransfert<R>(data: FormData, singleResult: boolean): MediaTransfert<R> {
    const upload$ = this.http
      .post(this.url, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(switchMap(event => this.buildFromEvent<R>(event, singleResult)));

    return new MediaTransfertImpl<R>(upload$);
  }
}
