import { CreatePermanentDependencyFundingDto } from '@application/bundles/dependency-funding';
import { isGirAmountAndResidents, isValidPointGIR } from '@domain/gir';
import { isValidObject } from '@application/framework/lib';
import { isNegativeFundingAmount } from '@domain/funding';

export class CreatePermanentDependencyFundingDtoImpl implements CreatePermanentDependencyFundingDto {
  public firstGIRGroup!: { amount: number; residents: number | undefined };

  public secondGIRGroup!: { amount: number; residents: number | undefined };

  public thirdGIRGroup!: { amount: number; residents: number | undefined };

  public pointGIR: number | undefined;

  public activityAllowance: number | undefined;

  constructor(values?: Partial<CreatePermanentDependencyFundingDto>) {
    if (isValidObject(values)) {
      if (isGirAmountAndResidents(values?.firstGIRGroup)) {
        this.firstGIRGroup = values?.firstGIRGroup;
      }

      if (isGirAmountAndResidents(values?.secondGIRGroup)) {
        this.secondGIRGroup = values?.secondGIRGroup;
      }

      if (isGirAmountAndResidents(values?.thirdGIRGroup)) {
        this.thirdGIRGroup = values?.thirdGIRGroup;
      }

      if (typeof values?.pointGIR === 'number' && isValidPointGIR(values?.pointGIR)) {
        this.pointGIR = values?.pointGIR;
      }

      if (isNegativeFundingAmount(values?.activityAllowance)) {
        this.activityAllowance = values?.activityAllowance;
      }
    }
  }
}
