import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export interface PMPDocumentParams {
}


export interface SerializedPMPSearchParams extends SerializedDocumentParams<PMPDocumentParams> {
}


export abstract class PMPListParams extends DocumentListParams implements Serializable<SerializedPMPSearchParams> {

}
