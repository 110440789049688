import { OnChanges } from '@angular/core';
import { LocalMedia, Media } from '@domain/media';
import { MediaViewerChanges } from '@easyhpad-ui/app/bundles/media/interfaces/media-viewer-changes';

export type MEDIA_PREVIEW_FEATURE = 'zoom' | 'rotate';

export interface PreviewComponentInterface extends Partial<OnChanges> {
  supports: Set<MEDIA_PREVIEW_FEATURE>;

  media?: Media | LocalMedia;

  zoomIn?: () => number;

  zoomOut?: () => number;

  rotateLeft?: () => number;

  rotateRight?: () => number;

  parentChange?(changes: MediaViewerChanges): void;
}
