import { DispatchableEvent, EventSubscriber, EventSubscriberMetadata } from '@application/framework/event';
import { InvalidEventSubscriberTypeError } from '@application/framework/event/errors/invalid-event-subscriber-type.error';
import { EventSubscribersContainer } from '@application/framework/event/event-subscribers.container';
import { getEmptySubscriberMetadata } from '@application/framework/event/functions/get-empty-subscriber-metadata';

export class AgnosticEventSubscribersContainer<T extends DispatchableEvent> implements EventSubscribersContainer<T> {
  private readonly subscribers: Map<EventSubscriber<any>, EventSubscriberMetadata> = new Map();

  public has(subscriber: EventSubscriber<T>): boolean {
    return this.subscribers.has(subscriber);
  }

  public set(subscriber: EventSubscriber<T>, metadata?: EventSubscriberMetadata): void {
    if (typeof subscriber !== 'function') {
      throw new InvalidEventSubscriberTypeError(`Subscriber must be a valid function, ${typeof subscriber} receive.`);
    }

    this.subscribers.set(subscriber, metadata || getEmptySubscriberMetadata());
  }

  public metadata(subscriber: EventSubscriber<T>): EventSubscriberMetadata {
    const metadata = this.subscribers.get(subscriber);
    return metadata ? metadata : getEmptySubscriberMetadata();
  }

  public setMetadata(subscriber: EventSubscriber<T>, metadata: EventSubscriberMetadata): void {
    this.subscribers.set(subscriber, metadata);
  }

  public remove(subscriber: EventSubscriber<T>): void {
    this.subscribers.delete(subscriber);
  }

  public clear(): void {
    this.subscribers.clear();
  }

  public isEmpty(): boolean {
    return this.subscribers.size === 0;
  }

  public entries(): { subscriber: EventSubscriber<T>; metadata: EventSubscriberMetadata }[] {
    return Array.from(this.subscribers.entries()).map(([subscriber, metadata]) => ({ subscriber, metadata }));
  }

  public merge(...containers: EventSubscribersContainer<any>[]): EventSubscribersContainer<T> {
    containers.forEach((container) => {
      container.entries().forEach((entry) => {
        if (this.has(entry.subscriber)) {
          this.setMetadata(entry.subscriber, entry.metadata);
        } else {
          this.set(entry.subscriber, entry.metadata);
        }
      });
    });

    return this;
  }
}

/**
 * private static readonly subscribers: Map<EventSubscriber<any>, EventSubscriberMetadata> = new Map();
 *
 *   protected get subscribers(): Map<EventSubscriber<any>, EventSubscriberMetadata> {
 *     return EventSubscribersContainer.subscribers;
 *   }
 *
 *   public static metadata<T extends DispatchableEvent>(subscriber: EventSubscriber<T>): EventSubscriberMetadata {
 *     let metadata = this.subscribers.get(subscriber);
 *     return metadata ? metadata : getEmptySubscriberMetadata();
 *   }
 *
 *   public static set<T extends DispatchableEvent>(subscriber: EventSubscriber<T>): void;
 *   public static set<T extends DispatchableEvent>(subscriber: EventSubscriber<T>, metadata: EventSubscriberMetadata): void;
 *   public static set<T extends DispatchableEvent>(subscriber: EventSubscriber<T>, metadata?: EventSubscriberMetadata): void {
 *     const current = this.metadata(subscriber);
 *     metadata = metadata || getEmptySubscriberMetadata();
 *     this.subscribers.set(subscriber, {...current, ...metadata});
 *   }
 *
 *   public static merge(container: EventSubscribersContainer<any>, ...containers: EventSubscribersContainer<any>[]): EventSubscribersContainer<any> {
 *     return container.merge(...containers);
 *   }
 */
