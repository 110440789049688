import {ElementRef, Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class NativeChangeObserver {


  private observers: Map<any, Map<string, Subject<{ previousValue: any, currentValue: any }>>> = new Map();

  public observe<T = any>(el: ElementRef, attribute: string): Observable<{ previousValue: T, currentValue: T }> {

    const subject = this.getSubject(el, attribute);
    this.observeMutation(el, attribute, subject);
    return subject.asObservable();
  }

  public getSubject(el: ElementRef, attribute: string): Subject<{ previousValue: any, currentValue: any }> {
    let observers = this.observers.get(el.nativeElement);

    if (!(observers instanceof Map)) {
      observers = new Map();
    }

    let subject = observers.get(attribute);

    if (subject === undefined) {
      subject = new Subject();
      observers.set(attribute, subject);
    }

    this.observers.set(el.nativeElement, observers);
    return subject;
  }

  private observeMutation(el: ElementRef, attribute: string, subject: Subject<{ previousValue: any, currentValue: any }>) {
    const config = {attributeFilter: [attribute], attributeOldValue: true};

    const observer = new MutationObserver(mutations => {
      mutations.forEach((mutation) => {

        if (mutation.attributeName) {
          subject.next({
            previousValue: mutation.oldValue,
            currentValue: el.nativeElement.getAttribute(mutation.attributeName)
          });
        }
      });
    });

    observer.observe(el.nativeElement, config);
  }

}
