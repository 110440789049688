<header class="widget-header">

  <h3 class="widget-title title">{{"Taux d'occupation"|ehp_translate }}</h3>

</header>

<div class="widget-content">

  <div class="chart-container">
    <ngx-charts-gauge
      [angleSpan]="chartOptions.angleSpan"
      [max]="100"
      [min]="0"
      [results]="data"
      [scheme]="colors"
      [showAxis]="false"
      [showText]="chartOptions.showText"
      [startAngle]="chartOptions.startAngle"
      [tooltipDisabled]="chartOptions.tooltipDisabled"
    >
    </ngx-charts-gauge>

    <span class="value">

      <ng-container *ngIf="value; else empty">
        {{value| number:'1.0-2'}}%
      </ng-container>
      <ng-template #empty> - </ng-template>
    </span>
  </div>

  <span class="description">
    {{'* servant au calcul des tarifs hébergements' | ehp_translate}}
  </span>

</div>

<div class="widget-footer">
</div>
