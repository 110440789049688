import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export interface WithMinAndMax<T = number> {
  min?: T;

  max?: T;
}

export interface WithStep {
  step?: number;
}

export interface WithChanges<V = any> {
  /**
   * Method called when the control value changes.
   * @param value
   */
  onChanges?: (value: V) => void;
}

export interface WithControlValidators {
  /**
   * Abstract control validators
   */
  validators?: Observable<ValidatorFn | ValidatorFn[]>;

  /**
   * Async abstract control validators
   */
  asyncValidators?: Observable<AsyncValidatorFn | AsyncValidatorFn[]>;
}

export interface WithCssClass {
  /**
   * Class to apply on the native element
   */
  cssClass?: string;
}

export interface WithActivation {
  activation?: Observable<boolean>;
}

export interface WithInitialValue<V = any> {
  /**
   * The default value to pass in control
   */
  initialValue?: Observable<V | undefined>;
}
