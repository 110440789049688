import { extractExcludedPropertiesMetadata, extractPropertySerializerMetadata, } from '@application/framework/backend/helpers';
import { Constructable } from '@application/framework/lib';


/**
 * Serializes an object to be sent to the backend by applying transformations arising from decorators.
 * @param entity - The object to serialize.
 */
export const serialize = <T extends Record<string | symbol, any> = Record<string | symbol, any>>(entity: any): T => {
  const excluded = extractExcludedPropertiesMetadata(entity);
  const options = extractPropertySerializerMetadata(entity);

  const serialized: Record<string | symbol, any> = {};

  for (const key of Object.keys(entity)) {
    if (excluded.has(key)) {
      continue;
    }

    const option = options.get(key);

    if (option === undefined) {
      serialized[key] = (entity as any)[key];
      continue;
    }

    let value: any = (entity as any)[key];

    if (typeof option.transformers?.to === 'function') {
      value = option.transformers.to(value);
    }

    serialized[option.backendName] = value;
  }

  return serialized as T;
};

/**
 * Creates a new instance of the target and injects the values from the object, mapping the properties.
 * @param target - The object to class constructor.
 * @param values - The values to define in instance.
 */
export const unserialize = (target: Constructable<any>, values: Record<string | symbol, any>) => {
  const options = extractPropertySerializerMetadata(target.prototype);

  const instance = new target();

  for (const key of Object.keys(values)) {
    const option = Array.from(options.values()).find(opt => opt.backendName === key);

    if (option === undefined) {
      (instance as any)[key] = values[key];
      continue;
    }

    let value: any = values[key];

    if (typeof option.transformers?.from === 'function') {
      value = option.transformers.from(value);
    }

    (instance as any)[option.propertyName] = value;
  }

  return instance;
};
