import {DependencyFundingRepository} from "@application/bundles/dependency-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {DependencyFunding} from "@domain/dependency-funding";
import {ListDependencyFundingQuery} from "../../list-dependency-funding.query";
import {
  AgnosticDependencyFundingSearchParams
} from "@application/bundles/dependency-funding/implementations/agnostic-dependency-funding-search-params/agnostic-dependency-funding-search-params";

@HandleQuery({
  query: ListDependencyFundingQuery
})
export class ListDependencyFundingQueryHandler implements QueryHandler<ListDependencyFundingQuery, DependencyFunding[]> {

  constructor(private readonly repository: DependencyFundingRepository) {
  }

  public async handle(query: ListDependencyFundingQuery): Promise<DependencyFunding[]> {
    return this.repository.list(new AgnosticDependencyFundingSearchParams().deserialize(query.params));
  }
}
