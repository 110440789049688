<ehp-page-header>

  <h1 class="title page-title" pageTitle>
    <span class="trail">{{ 'Mon compte' | ehp_translate }}</span>
  </h1>

</ehp-page-header>

<ehp-page-content>
  <ehp-current-user-form (onSave)="redirectToHome()" class="form"></ehp-current-user-form>
</ehp-page-content>
