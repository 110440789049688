<p-dropdown
  (onChange)="dispatchChange($event)"
  [(ngModel)]="value"
  [disabled]="disabled"
  [id]="id"
  [optionLabel]="keyword"
  [options]="facilities"
  [showClear]="true"
  optionValue="id"
  placeholder="Rechercher un établissement"
>
    <ng-template let-item pTemplate="item">
        <span [innerHTML]="item.name"></span>
    </ng-template>

    <ng-template pTemplate="selectedItem">
        <span *ngIf="selectedFacility" [innerHTML]="selectedFacility.name"></span>
    </ng-template>

</p-dropdown>



