import { Component, Input } from '@angular/core';
import { DocumentActionButton, DocumentActionLink } from '@easyhpad-ui/app/bundles/documents/contracts/document-action';

@Component({
  selector: 'ehp-document-action',
  templateUrl: './document-action.component.html',
  styleUrls: ['./document-action.component.scss'],
})
export class DocumentActionComponent {
  @Input() public action: DocumentActionLink | DocumentActionButton | undefined;
}
