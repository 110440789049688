import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { CPOMFundingFormBuilder } from '@easyhpad-ui/app/bundles/cpom/services/cpom-funding-form-builder/cpom-funding-form-builder';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ehp-form-cpom-funding-table',
  templateUrl: './form-cpom-funding-table.component.html',
  styleUrls: ['./form-cpom-funding-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormCpomFundingTableComponent implements OnChanges, OnDestroy {
  @Input() public formArray!: FormArray<FormGroup> | null;

  @Input() public mode: 'create' | 'edit' = 'edit';

  public labels: Map<string, string> = new Map([
    ['name', 'Nom'],
    ['amount', 'Montant'],
    ['comment', 'Commentaire'],
  ]);

  /**
   * Locale used to format decimal input
   */
  public readonly locale = 'fr-FR';

  public amount: number = 0;

  private subscription: Subscription | undefined;

  public get rows(): FormGroup[] | null {
    if (this.formArray) {
      return this.formArray.controls as FormGroup[];
    }

    return null;
  }

  constructor(private readonly formBuilder: CPOMFundingFormBuilder) {}

  public ngOnChanges(changes: SimpleChanges) {
    this.unsubscribe();

    if (changes['formArray'] && changes['formArray'].currentValue) {
      this.subscription = this.formArray?.valueChanges.subscribe(values => {
        this.amount = values
          .map(row => row.amount)
          .filter(amount => !!amount)
          .reduce((a, b) => a + b, 0);
      });
    }
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

  public addRow(): void {
    if (!this.formArray) {
      return;
    }

    if (this.mode === 'create') {
      this.formBuilder.addCreateFunding(this.formArray);
    } else {
      this.formBuilder.addUpdateFunding(this.formArray);
    }
  }

  public removeRow(index: number): void {
    if (!this.formArray) {
      return;
    }

    this.formArray.removeAt(index);
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
