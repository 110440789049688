import {IsUniqueEmailQuery} from "@application/bundles/user/queries";
import {QueryBus} from "@application/framework/command-query";
import {isEmail, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface} from "class-validator";

@ValidatorConstraint({name: 'isUniqueEmail', async: true})
export class IsUniqueEmail implements ValidatorConstraintInterface {

  constructor(private readonly queryBus: QueryBus) {
  }

  public async validate(value: string, validationArguments?: ValidationArguments): Promise<boolean> {

    if (!isEmail(value)) {
      return false;
    }

    return await this.queryBus.request(new IsUniqueEmailQuery(value));
  }

  public defaultMessage(args: ValidationArguments) {
    return 'Email ($value) is not unique!';
  }

}
