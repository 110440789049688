import {Injectable} from "@angular/core";
import {joinPath} from "@application/framework/lib";
import {configuration} from "@easyhpad-ui/environments/configuration";

@Injectable({
  providedIn: 'root'
})
export class AuthentificationUrlsProvider {


  public static readonly publicSchema = {
    login: {path: configuration.loginPath.replace(/^\/|\/$/g, ''), title: 'Se connecter'},
    logout: {path: configuration.logoutPath.replace(/^\/|\/$/g, ''), title: 'Se déconnecter'},
    onboarding: {path: 'onboarding', title: 'Finaliser la création de son compte'},
    forgottenPassword: {
      path: configuration.forgottenPasswordPath.replace(/^\/|\/$/g, ''),
      title: 'Mot de passe oublié'
    },
    resetPassword: {path: 'password-reset', title: 'Réinitialisation du mot de passe oublié'},
  }

  public getLogin(): string {
    return joinPath('', AuthentificationUrlsProvider.publicSchema.login.path);
  }

  public getLogout(): string {
    return joinPath('', AuthentificationUrlsProvider.publicSchema.logout.path);
  }

  public getForgottenPassword(): string {
    return joinPath('', configuration.forgottenPasswordPath);
  }
}
