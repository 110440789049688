import {Transformer} from "@application/framework/core";
import {CRPP} from "@domain/eprd-errd";
import {EasyCRPP} from "@implementations/bundles/backend/easyhpad";
import {Service} from "@application/framework/di";

@Service()
export class CRPPTransformer implements Transformer<CRPP, EasyCRPP> {


  public transform(value: CRPP): EasyCRPP {
    return {
      account: value.account,
      value: value.value
    }
  }

  public reverseTransform(value: EasyCRPP): CRPP {
    return {
      account: value.account,
      value: value.value
    };
  }


}
