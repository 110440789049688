<ng-container *ngIf="healthFunding">

  <h3 class="title item-title m0">
    <span class="icon ehp-icon icon-file"></span>

    <ehp-health-funding-link [healthFunding]="healthFunding"></ehp-health-funding-link>
  </h3>


  <span class="accounting-year">
    {{ healthFunding.accountingYear }}
  </span>

  <span class="total">
    {{ healthFunding.total |currency}}
  </span>


  <span class="recurring funding">
       <ng-container *ngIf=" healthFunding.recurringFunding.sum() as amount">
      {{amount|currency}}
    </ng-container>
  </span>


  <span class="non-recurring funding">
    <ng-container *ngIf=" healthFunding.nonRecurringFunding.sum() as amount">
      {{amount|currency}}
    </ng-container>
  </span>


  <span class="facility">
    <ehp-facility-link [fid]="healthFunding.facilityId"></ehp-facility-link>
  </span>
  
  <div class="actions-list actions">

    <ehp-health-funding-link
      [healthFunding]="healthFunding"
      class="is-icon icon-arrow ghost button"
      pTooltip="{{'Voir' | ehp_translate}}"
      tooltipPosition="bottom">

      <span class="label">{{'Consulter le financement soin' | ehp_translate }}</span>

    </ehp-health-funding-link>

  </div>


</ng-container>
