import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {CapacityAuthorization, CapacityAuthorizationRepository} from "@domain/capacity-authorization";
import {GetLatestCapacityAuthorizationQuery} from "../../get-latest-capacity-authorization.query";
import {
  ListCapacityAuthorizationParamsImpl
} from "@application/bundles/capacity-authorization/implementations/list-capacity-authorization-params";

@HandleQuery({
  query: GetLatestCapacityAuthorizationQuery
})
export class GetLatestCapacityAuthorizationQueryHandler implements QueryHandler<GetLatestCapacityAuthorizationQuery, CapacityAuthorization | undefined> {

  constructor(private readonly repository: CapacityAuthorizationRepository) {
  }

  public async handle(query: GetLatestCapacityAuthorizationQuery): Promise<CapacityAuthorization | undefined> {
    
    const params = new ListCapacityAuthorizationParamsImpl();
    params.setFacilities(query.facility);

    const authorizations = await this.repository.list(params);
    return authorizations[0];
  }

}
