import {StartApplicationCommand} from "@application/bundles/application/commands/start-application.command";
import {ApplicationPreStartEvent} from "@application/bundles/application/events";
import {HandleCommand} from "@application/framework/command-query";
import {CommandHandler} from "@application/framework/command-query/handler.interface";
import {EventDispatcher} from "@application/framework/event";

@HandleCommand({
  command: StartApplicationCommand
})
export class StartApplicationCommandHandler implements CommandHandler<StartApplicationCommand> {


  public constructor(private readonly eventDispatcher: EventDispatcher) {
  }

  public async handle(command: StartApplicationCommand): Promise<void> {
    this.eventDispatcher.dispatch(new ApplicationPreStartEvent());
  }
}
