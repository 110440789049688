import { createAction, props } from '@ngrx/store';
import { AuthentificationToken } from '@application/bundles/authentification';

export const authentificationActions = Object.freeze({
  /**
   * Set authentification has checked by the system.
   */
  setAuthenticationChecked: createAction('[Authentication] auth is checked'),

  /**
   * Store the logged account
   */
  setCurrentAccount: createAction(
    '[Authentification] Set current account',
    props<{ account: AuthentificationToken | null }>(),
  ),

  /**
   * Log out the current account
   */
  logout: createAction(`[Authentification] Logout`),
});
