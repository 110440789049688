import {HealthFundingUpdatedEvent, HealthFundingUpdateFailEvent} from "@application/bundles/health-funding";
import {HEALTH_FUNDING_TRANSLATE_CONTEXT} from "@application/bundles/health-funding/health-funding.token";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnHealthFundingUpdateEventSubscriber {

  constructor(private readonly noticeStream: NoticeStream) {
  }

  @OnEvent({
    event: HealthFundingUpdatedEvent
  })
  private async pushNoticeOnHealthFundingUpdateSuccess(event: HealthFundingUpdatedEvent): Promise<void> {


    const message = new TranslatableString(
      "Le <strong>financement soin {{ year }}</strong> a été mis à jour avec succès.",
      {year: event.healthFunding.year},
      HEALTH_FUNDING_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  @OnEvent({
    event: HealthFundingUpdateFailEvent
  })
  private pushNoticeOnHealthFundingUpdateFail(event: HealthFundingUpdateFailEvent): void {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la modification du financement soin.',
        undefined,
        HEALTH_FUNDING_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }
}
