import { Facility } from '@domain/facility';
import { Media } from '@domain/media';
import { PMP } from '@domain/pmp';
import { BackendProperty } from '@application/framework/backend';
import { BackendDateTransformer } from '@implementations/bundles/backend/easyhpad/transformers';

export class PMPImpl implements PMP {
  public id!: PMP['id'];

  @BackendProperty('validationDate', {
    transformers: new BackendDateTransformer(),
  })
  public date!: Date;

  public value!: number;

  public facilityId!: Facility['id'];

  public pvId?: Media['id'];

  @BackendProperty('createdAt', {
    transformers: new BackendDateTransformer(),
  })
  public readonly createdAt?: Date;

  @BackendProperty('updatedAt', {
    transformers: new BackendDateTransformer(),
  })
  public readonly updatedAt?: Date;
}
