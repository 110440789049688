import {DependenciesInjectionConfiguration} from "@application/framework/di";
import {ServiceIdentifier} from "@application/framework/di/types/service-identifier";
import {AbstractConstructable, Constructable} from "@application/framework/lib";

/**
 *
 * Register a service in dependencies container.
 *
 * Exemple :
 * ```
 * class MyService { ... }
 *
 * registerService(MyService, MyService);
 *
 * ```
 * @param identifier
 * @param service
 */
export function registerService<T>(identifier: ServiceIdentifier, service: Constructable<T> | CallableFunction | any) {
  DependenciesInjectionConfiguration.serviceDecorator(service, {id: identifier, useClass: service})
}

/**
 *
 * Register in container an implementation of service.
 *
 * Exemple :
 * ```
 * abstract class Logger { ... }
 * class MyLoggerImplementation implements Logger { ... }
 *
 * bindService(Logger, MyLoggerImplementation);
 *
 * ```
 * @param service
 * @param bind
 */
export function bindService<T>(service: Constructable<T> | AbstractConstructable<T>, bind: Constructable<T>) {
  DependenciesInjectionConfiguration.serviceDecorator(service, {id: service, useClass: bind});
}
