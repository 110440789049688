import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {BILLING_MODE, Customer, isValidBillingMode} from "@domain/customer";
import {AbstractControl, FormGroup, Validators} from "@angular/forms";
import {CreateCustomerDto} from "@application/bundles/customer/dto/create-customer.dto";
import {UpdateCustomerDto} from "@application/bundles/customer/dto/update-customer.dto";
import {
  CustomerFormBuilder
} from "@easyhpad-ui/app/bundles/customer/modules/form/services/customer-form-builder/customer-form-builder";
import {CommandBus} from "@application/framework/command-query";
import {CustomerDtoFactory} from "@application/bundles/customer";
import {CreateNewCustomerCommand} from "@application/bundles/customer/commands/create-new-customer.command";
import {UpdateCustomerCommand} from "@application/bundles/customer/commands/update-customer.command";
import {BillingModeCollection} from "@implementations/bundles/customer/implementations";
import {isSelectOption, SelectOption} from "@implementations/forms";

@Component({
  selector: 'ehp-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerFormComponent implements OnChanges {

  @Input() public customer: Customer | undefined;

  @Input() public mode: 'create' | 'update' = 'update';

  @Output() public submitted: EventEmitter<Customer> = new EventEmitter<Customer>();

  public form!: FormGroup<Record<keyof CreateCustomerDto | keyof UpdateCustomerDto, AbstractControl>>;

  public billingModeOptions: SelectOption<BILLING_MODE>[];

  public displayBillingAddress: boolean = false;

  public buttonLabel = 'Modifier le client';

  constructor(
    private readonly formBuilder: CustomerFormBuilder,
    private readonly commandBus: CommandBus,
    private readonly dtoFactory: CustomerDtoFactory,
    private readonly billingModeCollection: BillingModeCollection,
  ) {

    this.billingModeOptions = this.billingModeCollection.list().map(v => ({value: v.mode, label: v.label}));
  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes['mode']) {
      this.rebuildForm();
    }

    if (changes['customer']) {
      this.rebuildFormValues();
    }
  }

  public submit(): void {

    if (!this.form || !this.form.valid) {
      return;
    }

    const deserialized = this.formBuilder.deserialize(this.form.value as any);

    let command: CreateNewCustomerCommand | UpdateCustomerCommand;
    let dto: CreateCustomerDto | UpdateCustomerDto;

    if (this.mode === 'create') {
      dto = this.dtoFactory.newCreateCustomerDtoFromUnsafeValues(deserialized);
      command = new CreateNewCustomerCommand(dto);
    } else {

      if (!this.customer || !this.customer.id) {
        return;
      }

      dto = this.dtoFactory.newUpdateCustomerDtoFromUnsafeValues({...deserialized, id: this.customer?.id});
      command = new UpdateCustomerCommand(this.customer.id, dto as UpdateCustomerDto);
    }

    this.commandBus.execute<Customer>(command).then((customer: Customer) => this.submitted.emit(customer));
  }

  private rebuildFormValues(): void {
    this.formBuilder.rebuildValues(this.form, this.customer);
  }

  private rebuildForm(): void {
    if (this.mode === 'create') {
      this.form = this.formBuilder.getCreationForm(this.customer) as any;
      this.buttonLabel = 'Créer le nouveau client';
    } else {
      this.form = this.formBuilder.getUpdateForm(this.customer);
      this.buttonLabel = 'Modifier le client';
    }

    this.form.get(CustomerFormBuilder.BILLING_MODE_CONTROL_NAME)?.valueChanges.subscribe(value => {

      if (isSelectOption<BILLING_MODE>(value)) {
        value = value.value;
      } else if (isValidBillingMode(value)) {
        value = value.mode;
      }

      const billingAddressControl = this.form.get(CustomerFormBuilder.BILLING_ADDRESS_CONTROL_NAME);
      if (value === BILLING_MODE.BILLING_ADDRESS) {
        this.displayBillingAddress = true;

        if (!billingAddressControl?.hasValidator(Validators.required)) {
          // this.form.get(CustomerFormBuilder.BILLING_ADDRESS_CONTROL_NAME)?.setValidators(Validators.required);
        }

      } else {
        this.displayBillingAddress = false;

        if (billingAddressControl?.hasValidator(Validators.required)) {
          // this.form.get(CustomerFormBuilder.BILLING_ADDRESS_CONTROL_NAME)?.removeValidators(Validators.required);
        }
      }

    });

  }
}
