import {Query} from "@application/framework/command-query/query.interface";

export abstract class QueryBus {

  /**
   * Send query request
   * @param query
   */
  public abstract request<R = any>(query: Query<R>): Promise<R>;
}
