import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TranslatableString} from "@application/framework/translation";

@Component({
  selector: 'ehp-average-message',
  templateUrl: './average-message.component.html',
  styleUrls: ['./average-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AverageMessageComponent implements OnChanges {

  @Input() public value: number | undefined;

  @Input() public total: number | undefined;

  public message: TranslatableString | undefined;

  public ngOnChanges(changes: SimpleChanges) {
    if (typeof this.value === "number" && !isNaN(this.value) && typeof this.total === 'number' && !isNaN(this.total)) {
      this.message = new TranslatableString('Moyenne de <strong>{{value}}</strong>/{{total}} établissements', {
        value: this.value,
        total: this.total
      });
    }
  }

}
