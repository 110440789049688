import { Year } from '@domain/lib';
import { DepartmentCode } from '@domain/geo';

export interface DepartmentGMPIndicator {
  year: Year;

  departmentCode: DepartmentCode;

  value: number;
}
