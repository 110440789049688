<span *ngIf="!reference.childNodes.length" class="default">
  <span *ngIf="facility; else facilityName" [innerHTML]="facility.name"></span>
  <ng-template #facilityName>
      <ehp-facility-name [fid]="fid"></ehp-facility-name>
  </ng-template>

</span>

<span #reference class="provided">
  <ng-content></ng-content>
</span>
