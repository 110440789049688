import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export interface EPRDDocumentParams {
}


export interface SerializedEPRDSearchParams extends SerializedDocumentParams<EPRDDocumentParams> {

}

export abstract class EPRDSearchParams extends DocumentListParams implements Serializable<SerializedEPRDSearchParams> {


  public abstract override serialize(): SerializedEPRDSearchParams;

  public abstract override deserialize(params?: SerializedEPRDSearchParams): this;

}



