export interface Address {
  id?: number;

  addressLine1: string;

  locality: string;

  postalCode: string;

  countryCode?: string;
}
