import {CPOMCreatedEvent, CPOMCreationFailEvent} from "@application/bundles/cpom";
import {CPOM_TRANSLATE_CONTEXT} from "@application/bundles/cpom/cpom.token";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnCPOMCreationEventSubscriber {

  constructor(private readonly noticeStream: NoticeStream) {
  }

  @OnEvent({
    event: CPOMCreatedEvent
  })
  private async pushNoticeOnCPOMCreationSuccess(event: CPOMCreatedEvent): Promise<void> {

    const message = new TranslatableString(
      "Le <strong>C.P.O.M. {{year}}</strong> a été créé avec succès.",
      {year: event.cpom.year},
      CPOM_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  @OnEvent({
    event: CPOMCreationFailEvent
  })
  private pushNoticeOnCPOMCreationFail(event: CPOMCreationFailEvent): void {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la création du nouveau C.P.O.M..',
        undefined,
        CPOM_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }
}
