import { isValidCPOMIdType, StoredCPOM, StoredLocalCPOM } from '@application/bundles/cpom';
import { InvalidMediaId, isValidMediaIdType } from '@application/bundles/media';
import { Provide } from '@application/framework/di';
import { Media, MediaBucket } from '@domain/media';
import { CPOMService } from '@domain/cpom/services';
import { configuration } from '@easyhpad-ui/environments/configuration';
import { CPOMAnnex } from '@domain/cpom/cpom-annex';

export class CPOMImpl implements StoredCPOM {
  public id!: StoredCPOM['id'];

  public date!: Date;

  public children: StoredLocalCPOM[] = [];

  public documentId!: Media['id'];

  public annexes: CPOMAnnex[] = [];

  public otherServices: CPOMService[] = [];

  @Provide()
  private readonly mediaBucket!: MediaBucket;

  public get year() {
    return this.date.getFullYear();
  }

  public get end(): Date {
    const end = new Date(this.date);
    end.setMonth(end.getMonth() + configuration.cpom.defaultCPOMDurationInMonth);
    return end;
  }

  public get isSingle(): boolean {
    return this.children && this.children.length === 1;
  }

  public get singleChild(): StoredLocalCPOM | undefined {
    return this.isSingle ? this.children[0] : undefined;
  }

  constructor(values?: Partial<Omit<StoredCPOM, 'document' | 'end'>>) {
    if (values) {
      if (isValidCPOMIdType(values.id)) {
        this.id = values.id;
      }

      if (values.date instanceof Date) {
        this.date = values.date;
      }

      if (Array.isArray(values.children)) {
        this.children = values.children;
      }

      if (isValidMediaIdType(values.documentId)) {
        this.documentId = values.documentId;
      }

      if (Array.isArray(values.annexes)) {
        this.annexes = values.annexes;
      }

      if (Array.isArray(values.otherServices)) {
        this.otherServices = values.otherServices;
      }
    }
  }

  public async document(): Promise<Media> {
    if (!isValidMediaIdType(this.documentId)) {
      throw new InvalidMediaId(`${this.documentId} cannot be used as a valid Media id`);
    }
    return await this.mediaBucket.get(this.documentId);
  }
}
