import { Directive } from '@angular/core';

@Directive({
  selector: '[ehpResizableGrabber]'
})
export class ResizableGrabberDirective {

  constructor() { }

}
