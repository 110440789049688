import { NgModule } from '@angular/core';
import { extractDependencies } from '@application/framework/di';
import { PointGirValuationRepository } from '@application/bundles/gir';
import { EasyPointGirValuationRepository } from '@implementations/bundles/backend/easyhpad/repositories';
import { Bundle } from '@application/framework/bundle';
import { GirBundle } from '@application/bundles/gir/gir.bundle';

@NgModule({
  providers: [
    {
      provide: PointGirValuationRepository,
      useClass: EasyPointGirValuationRepository,
      deps: extractDependencies(EasyPointGirValuationRepository),
    },
  ],
})
@Bundle({ imports: [GirBundle] })
export class GirModule {}
