import {validateOrReject} from "class-validator";
import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {
  CreateEhpadDemainHealthFundingDto,
  CreateExceptionalHealthFundingDto,
  CreateHealthFundingDto,
  CreateNonRecurringHealthFundingDto,
  CreateRecurringHealthFundingDto,
  UpdateEhpadDemainHealthFundingDto,
  UpdateExceptionalHealthFundingDto,
  UpdateHealthFundingDto,
  UpdateNonRecurringHealthFundingDto,
  UpdateRecurringHealthFundingDto
} from "@application/bundles/health-funding";


type DTO =
  CreateHealthFundingDto
  | UpdateHealthFundingDto
  | CreateRecurringHealthFundingDto
  | UpdateRecurringHealthFundingDto
  | CreateNonRecurringHealthFundingDto
  | UpdateNonRecurringHealthFundingDto
  | CreateExceptionalHealthFundingDto
  | UpdateExceptionalHealthFundingDto
  | CreateEhpadDemainHealthFundingDto
  | UpdateEhpadDemainHealthFundingDto;


export const validateHealthFundingDto: ValidateFunction<DTO> = async <T extends DTO>(dto: T): Promise<T> => {

  await validateOrReject(dto);

  return dto;
}
