import { UpdatePMPDto } from '@application/bundles/pmp';
import { BackendExclude, BackendProperty } from '@application/framework/backend';
import { Facility } from '@domain/facility';
import { LocalMedia, Media } from '@domain/media';
import { PMP } from '@domain/pmp';
import { BackendDateTransformer } from '@implementations/bundles/backend/easyhpad/transformers';
import { IsValidFacilityId } from '@implementations/bundles/facility';
import { IsDate, IsNumber, IsOptional, IsPositive, IsUUID } from 'class-validator';

export class UpdatePMPDtoImpl implements UpdatePMPDto {
  @IsUUID()
  @BackendExclude()
  public id!: PMP['id'];

  @IsDate()
  @BackendProperty({
    backendName: 'validationDate',
    transformers: new BackendDateTransformer(),
  })
  public date!: Date;

  @IsNumber()
  @IsPositive()
  public value!: number;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  @IsOptional()
  public pvId?: Media['id'];

  @IsOptional()
  @BackendExclude()
  public pv!: LocalMedia | Media;

  constructor(values?: Partial<UpdatePMPDto>) {
    if (values?.id) {
      this.id = values.id;
    }

    if (values?.date) {
      this.date = values.date;
    }
    if (values?.value) {
      this.value = values.value;
    }

    if (values?.facilityId) {
      this.facilityId = values.facilityId;
    }

    if (values?.pvId) {
      this.pvId = values.pvId;
    }

    if (values?.pv) {
      this.pv = values.pv;
    }
  }
}
