import {Injectable} from "@angular/core";
import {joinPath} from "@application/framework/lib";
import {AccommodationFundingRepository} from "@application/bundles/accommodation-funding";
import {
  AccommodationFundingAuthorizationChecker
} from "@application/bundles/accommodation-funding/accommodation-funding-authorization-checker";
import {AccommodationFunding} from "@domain/accommodation-funding";

@Injectable()
export class AccommodationFundingUrlsProvider {

  public static readonly base = 'accommodation-funding';

  public static readonly publicSchema = {
    list: {path: '', title: "Financements hébergement"},
    create: {path: 'create', title: "Créer un nouvel financement hébergement"},
    show: {path: ':id', title: "Financement hébergement"},
    edit: {path: ':id/edit', title: "Modifier le financement hébergement"},
  }

  constructor(
    public readonly authorization: AccommodationFundingAuthorizationChecker,
    private readonly repository: AccommodationFundingRepository
  ) {
  }

  public getPublicList(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList(): string {
    return joinPath('/', AccommodationFundingUrlsProvider.base);
  }

  public getPublicCreate(): Promise<string | undefined> {
    return this.authorization.canCreate()
      .then(can => can ? this.getUnsafePublicCreate() : undefined);
  }

  public getUnsafePublicCreate(): string {
    return joinPath('/', AccommodationFundingUrlsProvider.base, AccommodationFundingUrlsProvider.publicSchema.create.path);
  }

  public getPublicShow(id: AccommodationFunding['id']): Promise<string | undefined> {
    return this.authorization.canRead()
      .then(can => can ? this.getUnsafePublicShow(id) : undefined);
  }

  public getUnsafePublicShow(id: AccommodationFunding['id']): string {
    return joinPath('/', AccommodationFundingUrlsProvider.base, id.toString());
  }

  public async getPublicUpdate(id: AccommodationFunding['id']): Promise<string | undefined> {
    const gmp = await this.repository.get(id);

    return this.authorization.canUpdate(gmp)
      .then(can => can ? this.getUnsafePublicUpdate(id) : undefined);
  }

  public getUnsafePublicUpdate(id: AccommodationFunding['id']): string {
    return joinPath('/', AccommodationFundingUrlsProvider.base, id.toString(), 'edit');
  }

}
