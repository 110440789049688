import {ChangeableAccessor} from "@application/framework/core";
import {Subscription} from "@application/framework/reactive";
import {User} from "@domain/user";

export class CurrentUserAccessor implements ChangeableAccessor<User | undefined> {

  private user: User | undefined;

  private readonly subscribers: Map<Function, Subscription> = new Map();

  public get(): User | undefined {
    return this.user;
  }

  public set(item: User | undefined): void {
    this.user = item;
    for (const callable of this.subscribers.keys()) {
      callable(item);
    }
  }

  public onChange(callable: (value: (User | undefined)) => void): Subscription {
    let subscription = this.subscribers.get(callable);

    if (subscription === undefined) {
      subscription = {
        unsubscribe: () => this.subscribers.delete(callable)
      };

      this.subscribers.set(callable, subscription);
    }

    return subscription;
  }

}
