import { LocalEPRD, LocalERRD, PartialActivityAnnex } from '@domain/eprd-errd';
import { Common } from '@application/framework/lib';
import { EasyLocalEPRD, EasyLocalERRD } from '@implementations/bundles/backend/easyhpad';
import { Transformer } from '@application/framework/core';
import { Provide } from '@application/framework/di';
import { EasyFinancialAnnexTransformer } from '@implementations/bundles/backend/easyhpad/transformers/errd-errd/easy-financial-annex.transformer';
import { PartialActivityAnnexImpl } from '@implementations/bundles/eprd-errd/implementations';
import { isPercentage } from '@domain/lib/math/percentage/percentage';

type CommonLocal = Omit<Common<LocalEPRD, LocalERRD>, 'id' | 'parent' | 'facility' | 'refreshParent' | 'parentId'>;

type EasyCommonLocal = Omit<Common<EasyLocalEPRD, EasyLocalERRD>, 'id' | 'parentId'>;

export abstract class EasyLocalCommonTransformer implements Transformer<CommonLocal, EasyCommonLocal> {
  @Provide()
  private readonly financialAnnexTransformer!: EasyFinancialAnnexTransformer;

  public transform(value: CommonLocal): EasyCommonLocal {
    return {
      ...this.financialAnnexTransformer.transform(value.financialAnnex),
      facilityId: value.facilityId,
      chargeAmount: value.expense,
      revenueAmount: value.revenue,
      cafCapacity: value.CAF,
      actualDaysRealized: value.activityAnnex.days,
      occupationRate: value.activityAnnex.occupationRate,
    };
  }

  public reverseTransform(value: EasyCommonLocal): CommonLocal {
    return {
      CAF: value.cafCapacity || 0,
      activityAnnex: this.reverseTransformActivityAnnex(value),
      expense: value.chargeAmount || 0,
      revenue: value.revenueAmount || 0,
      facilityId: value.facilityId,
      financialAnnex: this.financialAnnexTransformer.reverseTransform(value),
    };
  }

  private reverseTransformActivityAnnex(value: EasyCommonLocal): PartialActivityAnnex {
    return new PartialActivityAnnexImpl({
      days: typeof value.actualDaysRealized === 'number' ? value.actualDaysRealized : undefined,
      occupationRate: isPercentage(value.occupationRate) ? value.occupationRate : undefined,
    });
  }
}
