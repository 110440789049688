import { DynamicField } from '@easyhpad-ui/app/library/form/contracts/dynamic-field';
import { TranslatableString } from '@application/framework/translation';
import { Observable } from 'rxjs';

export interface DynamicSelectOption<V extends string | number = string> {
  value: V;

  label: string | TranslatableString;
}

export interface DynamicSelectField<V = any> extends DynamicField<V> {
  options: Observable<Array<DynamicSelectOption>>;
}
