import {DependencyFundingRepository, GetDependencyFundingQuery} from "@application/bundles/dependency-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {DependencyFunding} from "@domain/dependency-funding";

@HandleQuery({
  query: GetDependencyFundingQuery
})
export class GetDependencyFundingQueryHandler implements QueryHandler<GetDependencyFundingQuery, DependencyFunding> {

  constructor(private readonly repository: DependencyFundingRepository) {
  }

  public async handle(query: GetDependencyFundingQuery): Promise<DependencyFunding> {
    return this.repository.get(query.id);
  }
}
