import {createEntityAdapter} from '@ngrx/entity';
import {
  createDocumentActions,
  createDocumentReducer,
  createDocumentsSelectors,
  createInitialDocumentState,
  DocumentStoreState,
} from '@easyhpad-ui/app/bundles/documents';
import {DependencyFunding} from '@domain/dependency-funding';

/**
 * Key used to define the DependencyFunding substore.
 */
export const DEPENDENCY_FUNDING_STORE_KEY = '@app/funding/dependency';

/**
 * Store state
 */
export type DependencyFundingStoreState = DocumentStoreState<DependencyFunding>;

const dependencyFundingStoreEntityAdapter = createEntityAdapter<DependencyFunding>();

export const dependencyFundingActions = createDocumentActions<DependencyFunding>('DependencyFunding');

const initialState: DependencyFundingStoreState = createInitialDocumentState(dependencyFundingStoreEntityAdapter);

export const dependencyFundingReducer = createDocumentReducer<DependencyFunding>(
  dependencyFundingStoreEntityAdapter,
  initialState,
  dependencyFundingActions,
);

export const dependencyFundingSelectors = createDocumentsSelectors(
  state => state[DEPENDENCY_FUNDING_STORE_KEY],
  dependencyFundingStoreEntityAdapter,
);
