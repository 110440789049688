import {AbstractControl, ValidationErrors, Validator} from "@angular/forms";
import {pasaUhrValidator} from "@application/bundles/capacity-authorization/validators";


export class PASAURHValidator implements Validator {


  constructor(
    private readonly permanentCapacityControlName: string,
    private readonly pasaCapacityControlName: string,
    private readonly uhrCapacityControlName: string
  ) {
    this.validate = this.validate.bind(this);

    if (!this.permanentCapacityControlName) {
      throw new Error(`permanentCapacityControlName cannot be empty in ${this.constructor.name}`);
    }

    if (!this.pasaCapacityControlName) {
      throw new Error(`pasaCapacityControlName cannot be empty in ${this.constructor.name}`);
    }

    if (!this.uhrCapacityControlName) {
      throw new Error(`pasaCapacityControlName cannot be empty in ${this.constructor.name}`);
    }


  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const capacity = control.get(this.permanentCapacityControlName);
    const pasa = control.get(this.pasaCapacityControlName);
    const uhr = control.get(this.uhrCapacityControlName);


    if (capacity && typeof capacity.value === "number") {

      const values: { pasa?: number, uhr?: number } = {pasa: undefined, uhr: undefined};

      if (pasa && typeof pasa.value === "number") {
        values.pasa = pasa.value;
      }

      if (uhr && typeof uhr.value === "number") {
        values.uhr = uhr.value;
      }
      
      if (!pasaUhrValidator.validate(capacity.value, values.pasa, values.uhr)) {
        const error = {pasaUhr: {max: capacity.value, actual: ((values.pasa || 0) + (values.uhr || 0))}}

        pasa?.setErrors(error);
        uhr?.setErrors(error);
        return error;
      }
    }

    return null;
  }

}
