import { CPOMFunding } from '@domain/cpom';
import { StoredCPOMFunding } from '@application/bundles/cpom';
import { isCPOMFunding } from '@domain/cpom/validators/is-cpom-funding/is-cpom-funding.validator';
import { isUUID } from '@domain/lib';

export function isValidCPOMFundingIdType(id: any): id is StoredCPOMFunding['id'] {
  return isUUID(id);
}

export function isStoredCPOMFunding(funding: CPOMFunding): funding is StoredCPOMFunding {
  return isCPOMFunding(funding) && 'id' in funding && isValidCPOMFundingIdType((funding as StoredCPOMFunding).id);
}
