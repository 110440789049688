import {Component, EventEmitter, Output} from '@angular/core';
import {User} from "@domain/user";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CurrentUserAccessor} from "@application/bundles/user/implementations";
import {UserDtoFactory} from "@application/bundles/user/factories/user-dto.factory";
import {CommandBus} from "@application/framework/command-query";
import {UpdateCurrentUserCommand} from "@application/bundles/user/commands/update-current-user.command";

@Component({
  selector: 'ehp-current-user-form',
  templateUrl: './current-user-form.component.html',
  styleUrls: ['./current-user-form.component.scss']
})
export class CurrentUserFormComponent {

  public user: User | undefined;

  public form: FormGroup<Record<keyof Pick<User, 'firstname' | 'lastname'> | 'password', FormControl>>;

  @Output() public onSave: EventEmitter<User> = new EventEmitter<User>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly accessor: CurrentUserAccessor,
    private readonly factory: UserDtoFactory,
    private readonly commandBus: CommandBus
  ) {
    this.user = this.accessor.get();

    this.form = this.formBuilder.group({
      firstname: new FormControl(this.user?.firstname, [Validators.required]),
      lastname: new FormControl(this.user?.lastname, [Validators.required]),
      password: new FormControl()
    });

  }


  public submit() {

    const values = this.form.value


    if ('password' in values) {
      if (typeof values.password !== 'string' || values.password === '') {
        values.password = undefined;
      }
    }

    const dto = this.factory.buildUpdateCurrentUserDto(values);
    this.commandBus.execute<User>(new UpdateCurrentUserCommand(dto)).then(user => this.onSave.emit(user));
  }
}
