import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { SelectOption } from '@implementations/forms/select-option.interface';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ehp-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.scss'],
})
export class ListBoxComponent implements OnChanges, OnDestroy {
  @Input() public options: SelectOption[] = [];

  @Input() public placeholder: string = '';

  @Input() public selectedValues: any[] = [];

  @Input() public disabled: boolean = false;

  @Output() public onChange: EventEmitter<Array<SelectOption>> = new EventEmitter();

  public internalSelectedOptions: SelectOption[] = [];

  private selection: SelectionModel<any> = new SelectionModel(true);

  private subscription: Subscription;

  constructor() {
    this.subscription = this.selection.changed.subscribe(() => this.dispatchChanges());
  }

  public clear(): void {
    this.internalSelectedOptions = [];
  }

  public changed(changes: { originalEvent: Event; value: Array<SelectOption> }) {
    if (changes.value.length === 0) {
      this.selection.clear();
    } else {
      this.selection.setSelection(...changes.value.map(option => option.value));
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedValues']) {
      this.selectValuesHasChange();
    }
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private selectValuesHasChange(): void {
    if (!Array.isArray(this.selectedValues) || this.selectedValues.length === 0) {
      this.internalSelectedOptions = [];
      this.selection.clear();
      return;
    }

    this.selectedValues.map(value => {
      if (!this.selection.isSelected(value)) {
        const found = this.options.find(option => option.value === value);

        if (found !== undefined) {
          this.internalSelectedOptions.push(found);
        }
      }
    });

    this.selection.setSelection(...this.internalSelectedOptions.map(o => o.value));
    this.internalSelectedOptions = [...this.internalSelectedOptions];
  }

  private dispatchChanges() {
    const options = this.options.filter(option => this.selection.selected.includes(option.value));
    this.onChange.emit(options);
  }
}
