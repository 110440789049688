import { Bundle } from '@application/framework/bundle';
import { GetFileDepositQueryHandler, ListFileDepositQueryHandler } from './queries/handlers';
import { CrudPermissionVoter } from '@application/bundles/authorization/voters';
import { FILE_DEPOSIT_FEATURE } from '@application/bundles/file-deposit/tokens';
import { CreateFileDepositCommandHandler } from '@application/bundles/file-deposit/commands/handlers/create-file-deposit/create-file-deposit.command.handler';

@Bundle({
  queryHandlers: [ListFileDepositQueryHandler, GetFileDepositQueryHandler],
  commandHandlers: [CreateFileDepositCommandHandler],
  voters: [new CrudPermissionVoter<any>(FILE_DEPOSIT_FEATURE, (subject, token) => Promise.resolve(false))],
})
export class FileDepositBundle {}
