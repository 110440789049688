import {FILE_DEPOT_STATES} from './file-deposit-states';
import {FileDepositItem} from './file-deposit-item';
import {FileDepositList} from './file-deposit-list';
import {UUID} from '@domain/lib';
import {Facility} from '@domain/facility';
import {User} from '@domain/user';

/**
 * A FileDeposit represents a space in which a user from a facility must upload files
 * that will be processed by the staff.
 * This deposit is accompanied by a list of expected documents.
 *
 * @since 1.1.0
 */
export interface FileDeposit {
  id: UUID;

  /**
   * Current depot state
   */
  state: FILE_DEPOT_STATES;

  /**
   * The deposit date.
   */
  date: Date;

  /**
   * Unique number
   */
  reference: number;

  /**
   * The list of expected files excepted for this deposit.
   *
   */
  list: FileDepositList;

  /**
   * The attached facility's id.
   */
  facilityId: Facility['id'];

  /**
   * Items attached to this Deposit
   */
  items: Array<FileDepositItem>;

  /**
   * Person handling the deposit. User must be a member of staff.
   */
  responsibleUserId?: User['id'] | null;
}
