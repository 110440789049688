import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidFundingAmount} from "@domain/funding";


@ValidatorConstraint({name: 'IsValidFundingAmount', async: false})
export class IsFundingAmountConstraint implements ValidatorConstraintInterface {

  public validate(amount: any, args?: ValidationArguments): boolean {
    return isValidFundingAmount(amount);
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'amount is not a valid funding amount.';
  }
}

export function IsFundingAmount(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsFundingAmountConstraint
    });
  };
}
