import '@angular/compiler';
import {DependenciesInjectionConfiguration} from "@application/framework/di";
import {ServiceOptions} from "@application/framework/di/interfaces/service-metadata.interface";
import {Constructable} from "@application/framework/lib";

export function Service<T = unknown>(): Function;
export function Service<T = unknown>(options: Partial<ServiceOptions<T>>): Function;
export function Service<T>(options: Partial<ServiceOptions<T>> = {}): ClassDecorator {

  return target => {

    DependenciesInjectionConfiguration.serviceDecorator(target, {
      ...options,
      id: options.id || target,
      useClass: target as unknown as Constructable<unknown>,
    });

    return target;

  }
}
