import {Injectable, ViewContainerRef} from '@angular/core';
import {Constructable} from "@application/framework/lib";
import {LocalMedia, Media} from "@domain/media";
import {MEDIA_FILE_TYPES} from "@domain/media/types";
import {PreviewPdfComponent} from "@easyhpad-ui/app/bundles/media/components/preview-pdf/preview-pdf.component";
import {
  PreviewUnknownMediaComponent
} from "@easyhpad-ui/app/bundles/media/components/preview-unknown-media/preview-unknown-media.component";
import {PreviewComponentInterface} from "@easyhpad-ui/app/bundles/media/interfaces/preview-component.interface";
import {
  PreviewSpreadsheetComponent
} from "@easyhpad-ui/app/bundles/media/components/preview-spreadsheet/preview-spreadsheet.component";


@Injectable({
  providedIn: 'root'
})
export class PreviewComponentResolver {

  /**
   * Inject preview instance in view container
   * @param media
   * @param container
   */
  public inject(media: Media | LocalMedia, container: ViewContainerRef) {

    container.clear();

    const reference = container.createComponent<PreviewComponentInterface>(this.resolve(media));
    //reference.instance.media = media;
    reference.setInput('media', media);

    if (typeof reference.instance.ngOnChanges === 'function') {
      // reference.instance.ngOnChanges({media: new SimpleChange(undefined, media, true)});
    }

    reference.changeDetectorRef.detectChanges();

    return reference;
  }

  /**
   * Get preview component constructor
   * @param media
   */
  public resolve(media: Media | LocalMedia): Constructable<PreviewComponentInterface> {


    switch (media.type) {
      case MEDIA_FILE_TYPES.PDF:
        return PreviewPdfComponent;
      case MEDIA_FILE_TYPES.EXCEL:
        return PreviewSpreadsheetComponent;
      default:
        return PreviewUnknownMediaComponent;

    }
  }
}
