import {Customer} from "@domain/customer";
import {PaginationParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export type UserPopulate = 'customer';

export interface SerializedUserSearchParams {

  customerId?: Customer['id'];

  pagination?: PaginationParams;

  populate?: UserPopulate;

}

export abstract class UserSearchParams implements Serializable<SerializedUserSearchParams> {


  public abstract setCustomer(customer: Customer | Customer['id'] | null): this;

  public abstract setPagination(pagination: PaginationParams | null): this;

  public abstract setPopulate(populate: UserPopulate | null): this;

  public abstract serialize(): SerializedUserSearchParams;

  public abstract deserialize(params?: SerializedUserSearchParams): UserSearchParams;

}
