import { SubFunding } from '@domain/funding';
import { EhpadDemain } from '@domain/ehpad-demain';
import { UUID } from '@domain/lib';

export interface EhpadDemainHealthFunding extends SubFunding {
  id: UUID;

  name: string;

  ehpadDemainId: EhpadDemain['id'] | undefined;

  hasEhpadDemain(): boolean;

  ehpadDemain(): Promise<EhpadDemain | undefined>;
}
