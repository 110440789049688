<table *ngIf="funding" class="is-table funding-table">
  <thead>
  <tr>
    <th></th>
    <th class="left">{{'Nom' | ehp_translate}}</th>
    <th>{{'Catégorie' | ehp_translate}}</th>
    <th>{{'Montant' | ehp_translate}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of funding; let index = index">
    <td class="index">{{index + 1}}</td>
    <td class="left"><span [innerHTML]="row.comment"></span></td>
    <td>
      <ehp-health-taxonomy-label *ngIf="row.categoryId" [taxonomy]="taxonomy"
                                 [tid]="row.categoryId"></ehp-health-taxonomy-label>
    </td>
    <td class="amount">{{row.amount | currency}}</td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td></td>
    <td></td>
    <td class="total-label"></td>
    <td class="total-cell">
      <span class="label">{{'Total' | ehp_translate}}:</span>
      <span class="total">{{funding.sum() | currency}}</span>
    </td>
  </tr>
  </tfoot>
</table>
