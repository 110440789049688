import { Type } from '@angular/core';
import { NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';
import { DynamicFormElementTypeComponent } from '@easyhpad-ui/app/library/form/contracts/dynamic-component';
import { WithActivation, WithCssClass, WithInitialValue, } from '@easyhpad-ui/app/library/form/contracts/dynamic-field-attributes';
import { Observable } from 'rxjs';
import { DynamicFormElementType, DynamicFormNode } from './dynamic-form';


export interface DynamicFieldGroup<
  F extends { [k in keyof F]: DynamicFormElementType<any> },
  C = Type<DynamicFormElementTypeComponent<any>>,
> extends DynamicFormNode<'group'>,
  WithActivation {
  /**
   * Title displayed before fields
   */
  title?: string | TranslatableString;

  /**
   *  List of notice to display in the group.
   */
  notices?: Observable<Array<{ message: string | TranslatableString; type: NoticeType }>>;

  /**
   * The fields contain in the group
   */
  fields: Record<keyof F, DynamicFormElementType<any>>;

  /**
   * List of class added on field container
   */
  containerClass?: Set<string>;

  /**
   * Component used to render the group
   */
  component(): C | undefined;
}

export interface DynamicHTMLGroup<C extends { [k in keyof C]: DynamicFormElementType<any> }>
  extends DynamicFormNode<'htmlGroup'>,
    WithCssClass,
    WithActivation,
    WithInitialValue {
  title?: string | TranslatableString;

  fields: Record<keyof C, DynamicFormElementType<any>>;
}
