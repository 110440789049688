import {FACILITY_TRANSLATE_CONTEXT} from "@application/bundles/facility";
import {FacilityCreatedEvent} from "@application/bundles/facility/events/facility-created.event";
import {FacilityCreationFailEvent} from "@application/bundles/facility/events/facility-creation-fail.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";

@HasEventSubscribers()
export class OnFacilityCreationEventSubscriber {

  constructor(
    private readonly noticeStream: NoticeStream,
    private readonly translator: Translator
  ) {
  }


  @OnEvent({
    event: FacilityCreatedEvent,
  })
  public async pushNoticeOnFacilityCreationSuccess(event: FacilityCreatedEvent): Promise<void> {

    const message = await this.translator.translate(
      "Le nouvel établissement <strong>{{name}}</strong> a été créé avec succès.",
      {name: event.facility.name},
      FACILITY_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({message, type: NoticeType.SUCCESS});
  }

  @OnEvent({
    event: FacilityCreationFailEvent,
  })
  public async pushNoticeOnFacilityCreationFail(event: FacilityCreationFailEvent): Promise<void> {

    let message = event.reason;

    if (!message) {
      message = await this.translator.translate(
        "Une erreur est survenue lors de la création du nouvel établissement.",
        undefined,
        FACILITY_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({message, type: NoticeType.ERROR});
  }
}
