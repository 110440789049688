import {AuthorizationTokenType, HttpAuthorizationToken} from "@application/framework/http";
import {EASYHPAD_PROVIDER} from "@implementations/bundles/backend/easyhpad/backend.tokens";
import {EasyHpadBearerHttpValidator} from "@implementations/bundles/backend/easyhpad/validators/bearer-http.validator";

export class EasyHpadBarerAuthorizationToken implements HttpAuthorizationToken<string> {

  public readonly type = AuthorizationTokenType.BEARER;

  public readonly provider = EASYHPAD_PROVIDER;

  private token?: string;

  public get value(): string {
    return this.token && EasyHpadBearerHttpValidator.isMaybeValid(this.token) ? this.token.toString() : '';
  }

  public set value(token: string) {
    this.token = EasyHpadBearerHttpValidator.isMaybeValid(token) ? token : undefined;
  }

  constructor(token?: string) {
    if (EasyHpadBearerHttpValidator.isMaybeValid(token)) {
      this.token = token;
    }
  }


  public toString(): string {
    return `Bearer ${this.value}`;
  }


}
