import {
  DEPENDENCIES_FUNDING_MODES,
  dependencyAmountFunding,
  dependencyEmptyFunding,
  DependencyFundingItem,
  DependencyFundingMode,
  dependencyGIRGroupFunding,
  dependencyUnknownFunding
} from "@domain/dependency-funding";

export function isDependencyFundingMode<M extends string>(value: any): value is DependencyFundingMode<M> {
  return (
    typeof value === 'object'
    && value !== null
    && 'key' in (value as DependencyFundingMode<any>)
    && typeof (value as DependencyFundingMode<any>).key === 'string'
    && typeof value.label === 'string'
  );
}

export function dependencyFundingItemHasMode<M extends DependencyFundingMode<T> = any, V = unknown, T extends string = any>(funding: any): funding is { mode: M, value(): V } {

  return typeof funding === 'object'
    && funding !== null
    && isDependencyFundingMode(funding.mode)
    && typeof funding.value === 'function';
}

export function useDependencyFundingUnknownMode(funding: DependencyFundingItem<any, unknown>): funding is dependencyUnknownFunding {
  return dependencyFundingItemHasMode(funding)
    && funding.mode.key === DEPENDENCIES_FUNDING_MODES.UNKNOWN;
}

export function useDependencyFundingEmptyMode(funding: DependencyFundingItem<any, unknown>): funding is dependencyEmptyFunding {
  return dependencyFundingItemHasMode(funding)
    && funding.mode.key === DEPENDENCIES_FUNDING_MODES.EMPTY;
}

export function useDependencyFundingGirGroupMode(funding: DependencyFundingItem<any, unknown>): funding is dependencyGIRGroupFunding {
  return dependencyFundingItemHasMode(funding)
    && funding.mode.key === DEPENDENCIES_FUNDING_MODES.GIR_GROUP;
}


export function useDependencyFundingAmountMode(funding: DependencyFundingItem<any, unknown>): funding is dependencyAmountFunding {
  return dependencyFundingItemHasMode(funding)
    && funding.mode.key === DEPENDENCIES_FUNDING_MODES.AMOUNT;
}

