import {EhpadDemainCreatedEvent, EhpadDemainCreationFailEvent} from "@application/bundles/ehpad-demain";
import {EHPAD_DEMAIN_TRANSLATE_CONTEXT} from "@application/bundles/ehpad-demain/ehpad-demain.token";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnEhpadDemainCreationEventSubscriber {

  constructor(private readonly noticeStream: NoticeStream) {
  }

  @OnEvent({
    event: EhpadDemainCreatedEvent
  })
  private async pushNoticeOnEhpadDemainCreationSuccess(event: EhpadDemainCreatedEvent): Promise<void> {


    const message = new TranslatableString(
      "L'expérimentation <em>EHPAD de demain</em> <strong>{{ name }}</strong> a été créé avec succès.",
      {name: event.ehpadDemain.name},
      EHPAD_DEMAIN_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  @OnEvent({
    event: EhpadDemainCreationFailEvent
  })
  private pushNoticeOnEhpadDemainCreationFail(event: EhpadDemainCreationFailEvent): void {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        "Une erreur est survenue lors de la création de l'expérimentation <em>EHPAD de demain</em>",
        undefined,
        EHPAD_DEMAIN_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }
}
