import { EasyFinancialDetail } from './easy-financial-detail';
import { EasyFacility, EasyTPER } from '@implementations/bundles/backend/easyhpad';
import { EasyERRD } from './easy-errd';
import { UUID } from '@domain/lib';

export interface EasyLocalERRD extends EasyFinancialDetail {
  id: UUID;

  facilityId: EasyFacility['id'];

  parentId: EasyERRD['id'];

  revenueAmount: number;

  chargeAmount: number;

  realEstateDegradation: { comment: string; rate: number } | null | undefined;

  usedTreasury: number | null | undefined;

  financialIndependenceRate: number | null | undefined;

  cafCapacity: number | null | undefined;

  tper: Array<EasyTPER>;

  occupationRate: number | null | undefined;

  actualDaysRealized: number | null | undefined;
}

export interface CreateEasyLocalERRD extends Omit<EasyLocalERRD, 'id' | 'parentId'> {}

export interface UpdateEasyLocalERRD extends Omit<EasyLocalERRD, 'parentId'> {}
