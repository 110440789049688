import {
  EhpadDemainHealthFunding,
  ExceptionalHealthFunding,
  HealthFunding,
  NonRecurringHealthFunding,
  RecurringHealthFunding,
} from '@domain/health-funding';
import { Provide } from '@application/framework/di';
import { QueryBus } from '@application/framework/command-query';
import { Facility } from '@domain/facility';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { isValidPMPValue, PMPValue } from '@domain/pmp';
import { FundingCollection, isFundingCollection, isValidFundingAmount } from '@domain/funding';
import { isValidYear, Year } from '@domain/lib';
import { GMPValue, isValidGMPValue } from '@domain/gmp';
import { isValidObject } from '@application/framework/lib';
import { isValidHealthFundingIdType } from '@application/bundles/health-funding';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { Media, MediaBucket } from '@domain/media';
import { isValidMediaIdType } from '@application/bundles/media';

type AmountsProperties = Pick<
  HealthFunding,
  | 'total'
  | 'nonRecurringTotal'
  | 'permanentRecurringAmount'
  | 'temporaryRecurringAmount'
  | 'dayCareRecurringAmount'
  | 'supportRecurringAmount'
>;

const amounts: Array<keyof AmountsProperties> = [
  'total',
  'nonRecurringTotal',
  'permanentRecurringAmount',
  'temporaryRecurringAmount',
  'dayCareRecurringAmount',
  'supportRecurringAmount',
];

export class HealthFundingImpl implements HealthFunding {
  public id!: HealthFunding['id'];

  public date!: Date;

  public accountingYear!: Year;

  public gmp!: GMPValue;

  public pmp!: PMPValue;

  public total!: number;

  public nonRecurringTotal!: number;

  public permanentRecurringAmount: number | undefined;

  public temporaryRecurringAmount: number | undefined;

  public dayCareRecurringAmount: number | undefined;

  public supportRecurringAmount: number | undefined;

  public recurringFunding!: FundingCollection<RecurringHealthFunding>;

  public nonRecurringFunding!: FundingCollection<NonRecurringHealthFunding>;

  public exceptionalFunding!: FundingCollection<ExceptionalHealthFunding>;

  public ehpadDemainFunding!: FundingCollection<EhpadDemainHealthFunding>;

  public facilityId!: Facility['id'];

  public documentId!: Media['id'];

  public reportId: Media['id'] | undefined;

  @Provide() private readonly queryBus!: QueryBus;

  @Provide() private readonly mediaBucket!: MediaBucket;

  public get year(): Year {
    return this.date.getFullYear();
  }

  constructor(defaults?: Partial<HealthFunding>) {
    if (isValidObject(defaults)) {
      if (isValidHealthFundingIdType(defaults?.id)) {
        this.id = defaults.id;
      }

      if (defaults?.date instanceof Date) {
        this.date = defaults.date;
      }

      if (isValidYear(defaults?.accountingYear)) {
        this.accountingYear = defaults?.accountingYear;
      }

      if (isValidGMPValue(defaults?.gmp)) {
        this.gmp = defaults?.gmp;
      }

      if (isValidPMPValue(defaults?.pmp)) {
        this.pmp = defaults?.pmp;
      }

      if (isValidFacilityIdType(defaults?.facilityId)) {
        this.facilityId = defaults.facilityId;
      }

      for (const key of amounts) {
        const amount = defaults[key];
        if (isValidFundingAmount(amount)) {
          this[key] = amount;
        }
      }

      if (isFundingCollection<RecurringHealthFunding>(defaults?.recurringFunding)) {
        this.recurringFunding = defaults?.recurringFunding;
      }

      if (isFundingCollection<NonRecurringHealthFunding>(defaults?.nonRecurringFunding)) {
        this.nonRecurringFunding = defaults?.nonRecurringFunding;
      }

      if (isFundingCollection<ExceptionalHealthFunding>(defaults?.exceptionalFunding)) {
        this.exceptionalFunding = defaults?.exceptionalFunding;
      }

      if (isFundingCollection<EhpadDemainHealthFunding>(defaults?.ehpadDemainFunding)) {
        this.ehpadDemainFunding = defaults?.ehpadDemainFunding;
      }

      if (isValidMediaIdType(defaults?.documentId)) {
        this.documentId = defaults?.documentId;
      }

      if (isValidMediaIdType(defaults?.reportId)) {
        this.reportId = defaults?.reportId;
      }
    }
  }

  public facility(): Promise<Facility> {
    return this.queryBus.request<Facility>(new GetFacilityQuery(this.facilityId));
  }

  public document(): Promise<Media> {
    return this.mediaBucket.get(this.documentId);
  }

  public report(): Promise<Media | undefined> {
    if (!isValidMediaIdType(this.reportId)) {
      return Promise.resolve(undefined);
    }

    return this.mediaBucket.get(this.reportId);
  }
}
