import {UserDtoFactory} from "@application/bundles/user/factories/user-dto.factory";
import {CreateUser, UpdateCurrentUser, UpdateUser} from "@domain/user";
import {CreateUserDtoImpl} from "@implementations/bundles/user/dto/create-user.dto";
import {UpdateUserDtoImpl} from "@implementations/bundles/user/dto/update-user.dto";
import {UpdateCurrentUserDtoImpl} from "@implementations/bundles/user/dto/update-current-user.dto";
import {plainToClass} from "class-transformer";


export class UserDtoFactoryImpl implements UserDtoFactory {

  public newCreateUserDtoFromUnsafeValues(values: Partial<Record<keyof CreateUser, any>>): CreateUser {
    return plainToClass(CreateUserDtoImpl, values);
  }

  public newUpdateUserDtoFromUnsafeValues(values: Partial<Record<keyof UpdateUser, any>>): UpdateUser {
    return plainToClass(UpdateUserDtoImpl, values);
  }

  public buildUpdateCurrentUserDto(values: Partial<Record<keyof UpdateCurrentUser, any>>): UpdateCurrentUser {
    return new UpdateCurrentUserDtoImpl(values);
  }
}
