import {NgModule} from "@angular/core";
import {Bundle} from "@application/framework/bundle";
import {EhpadDemainBundle, EhpadDemainDtoFactory, EhpadDemainRepository} from "@application/bundles/ehpad-demain";
import {ValidatorModule} from "@easyhpad-ui/app/framework/validator/validator.module";
import {
  CreateEhpadDemainDtoImpl,
  EhpadDemainDtoFactoryImpl,
  UpdateEhpadDemainDtoImpl,
} from "@implementations/bundles/ehpad-demain";
import {
  validateCreateEhpadDemainDtoImpl,
  validateUpdateEhpadDemainDtoImpl
} from "@implementations/bundles/ehpad-demain/validators";
import {EasyEhpadDemainRepository} from "@implementations/bundles/backend/easyhpad";
import {EhpadDemainUrlsProvider} from "@easyhpad-ui/app/bundles/ehpad-demain/provider/urls-provider/urls-provider";
import {
  OpenGMPDeletionDialogCommandHandler
} from "@easyhpad-ui/app/bundles/ehpad-demain/commands/handlers/open-gmp-deletion-dialog/open-gmp-deletion-dialog.command.handler";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {
  EHPAD_DEMAIN_STORE_KEY,
  EhpadDemainEffects,
  ehpadDemainReducer
} from "@easyhpad-ui/app/bundles/ehpad-demain/store";

@NgModule({
  imports: [
    StoreModule.forFeature(EHPAD_DEMAIN_STORE_KEY, ehpadDemainReducer),
    EffectsModule.forFeature([EhpadDemainEffects]),
    ValidatorModule.forChild({
      validators: [
        {objectType: CreateEhpadDemainDtoImpl, validator: validateCreateEhpadDemainDtoImpl},
        {objectType: UpdateEhpadDemainDtoImpl, validator: validateUpdateEhpadDemainDtoImpl}
      ]
    })
  ],
  providers: [
    EhpadDemainUrlsProvider,
    {
      provide: EhpadDemainRepository,
      useClass: EasyEhpadDemainRepository
    },
    {
      provide: EhpadDemainDtoFactory,
      useClass: EhpadDemainDtoFactoryImpl
    }
  ]
})
@Bundle({
  imports: [EhpadDemainBundle],
  commandHandlers: [
    OpenGMPDeletionDialogCommandHandler
  ]
})
export class EhpadDemainModule {

}
