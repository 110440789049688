import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {CreateCPOMDtoImpl, UpdateCPOMDtoImpl} from "@implementations/bundles/cpom";
import {validateOrReject} from "class-validator";

export const createCPOMDtoValidator: ValidateFunction<CreateCPOMDtoImpl> = async (dto: CreateCPOMDtoImpl): Promise<CreateCPOMDtoImpl> => {
  await validateOrReject(dto);
  return dto;
}

export const updateCPOMDtoValidator: ValidateFunction<UpdateCPOMDtoImpl> = async (dto: UpdateCPOMDtoImpl): Promise<UpdateCPOMDtoImpl> => {
  await validateOrReject(dto);
  return dto;
}
