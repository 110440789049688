import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CommandBus} from "@application/framework/command-query";
import {User} from "@domain/user";
import {CheckResetPasswordTokenValidityCommand} from "@application/bundles/user";
import {ResetPasswordCommand} from "@application/bundles/user/commands/reset-password.command";
import {TranslatableString} from "@application/framework/translation";

@Component({
  selector: 'ehp-user-reset-password-form',
  templateUrl: './user-reset-password-form.component.html',
  styleUrls: ['./user-reset-password-form.component.scss']
})
export class UserResetPasswordFormComponent implements OnChanges {

  @Input() public token: string | undefined;

  @Input() public loginEnable = false;

  @Output() public requestLoginMove = new EventEmitter();

  @Output() public onLogin = new EventEmitter();

  public readonly form: FormGroup<Record<'password', FormControl<string | null>>>;

  public state: 'login' | 'forgotten' | 'reset' | 'fail' = 'fail';

  public loading = false;

  public message: string | TranslatableString | undefined;

  public partialUser: Pick<User, 'displayName'> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private commandBus: CommandBus,
  ) {

    this.form = this.formBuilder.group({
      password: new FormControl('', [Validators.required])
    });

  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes['token'] && changes['token'].currentValue !== changes['token'].previousValue && changes['token'].currentValue) {
      this.loading = true;
      this.commandBus.execute<Pick<User, 'displayName'> | undefined>(new CheckResetPasswordTokenValidityCommand(changes['token'].currentValue))
        .then(partial => {
          this.loading = false;
          this.partialUser = partial;

          if (partial === undefined) {
            this.state = 'forgotten';
            this.setForgottenMessage();
          } else {
            this.state = 'reset';
          }
        });
    }

  }

  public submit() {

    if (!this.form.valid || this.token === undefined) {
      return;
    }

    const password = this.form.value.password as string;

    this.commandBus.execute<{ changed: boolean, tokenValid: boolean }>(new ResetPasswordCommand(password, this.token))
      .then(result => {
        if (result.changed) {
          this.state = 'login';
          this.message = 'Votre mot de passe a bien été modifié, vous pouvez dès à présent vous reconnecter avec votre adresse e-mail et votre nouveau mot de passe.'
        } else if (!result.tokenValid) {
          this.state = 'forgotten';
          this.setForgottenMessage();
        } else {
          this.state = 'fail';
          this.message = 'Il semblerait qu\'une erreur soit survenue lors de la mise à jour de votre mot de passe. Nous vous invitons à réessayer ou à contacter notre support si le problème persiste.'
        }
      });

  }


  public dispatchLogin() {
    this.onLogin.emit();
  }

  public moveToLogin() {
    this.requestLoginMove.emit();
  }

  private setForgottenMessage() {
    this.message = 'Il semblerait que le lien de réinitialisation est expiré. Nous vous invitons à renouveler votre demande.'
  }


}
