<header class="widget-header">
  <h3 class="widget-title title">{{"C.P.O.M." | ehp_translate }}</h3>
  <span *ngIf="single && cpom" class="date">
    {{"Valide jusqu'au" | ehp_translate}} {{ cpom.end | date }}
  </span>
</header>

<div class="widget-content">


  <ng-container *ngIf="single; else multiple">

    <ng-container *ngIf="cpom; else empty">
      <ehp-tariff-option-value
        *ngIf="cpom && cpom.tariffOption"
        [option]="cpom.tariffOption"
        [year]="cpom.year"
        class="tariff-option">
      </ehp-tariff-option-value>
    </ng-container>

  </ng-container>


  <ng-template #multiple>
    <div *ngIf="average; else empty" class="average">

      <span class="value">{{average.count}}</span>

      <ehp-plural [count]="average.count" class="label">

        <ng-container plural>
          {{'établissements sans C.P.O.M. saisi ou arrivé à échéance.' | ehp_translate}}
        </ng-container>
        
        <ng-container single>
          {{'établissement sans C.P.O.M. saisi ou arrivé à échéance.' | ehp_translate}}
        </ng-container>
      </ehp-plural>
    </div>

  </ng-template>


</div>

<div class="widget-footer">
  <div></div>
  <a *ngIf="single && url" [routerLink]="url" class="target-link">{{ "Voir le C.P.O.M." }}</a>
</div>

<ng-template #empty>

  <div class="empty">
    <ehp-plural [count]="single ? 1 : 2" class="label">
      <ng-container plural>{{'Aucun C.P.O.M. pour ces établissements' | ehp_translate}}</ng-container>
      <ng-container single>{{'Aucun C.P.O.M. pour cet établissement' | ehp_translate}}</ng-container>
    </ehp-plural>

    <a *ngIf="createUrl" [routerLink]="createUrl" class="target-link">{{'Nouvel archivage' | ehp_translate}}</a>
  </div>

</ng-template>

