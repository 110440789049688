import { DateString } from '@application/framework/date';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { EasyFacility } from '@implementations/bundles/backend/easyhpad';
import { EasyAccommodationDailyRate } from './easy-accommodation-daily-rate';
import { UUID, Year } from '@domain/lib';

export interface EasyAccommodationFunding {
  id?: UUID;

  date: DateString;

  accountingYear: Year;

  dailyRates: Array<EasyAccommodationDailyRate>;

  facilityId: EasyFacility['id'];

  documentId: EasyMedia['id'];

  reportId?: EasyMedia['id'] | null;

  amount?: number | null;

  /**
   * The total amount notified for permanent accommodation
   */
  permanentAmount?: number | null;

  /**
   * The total amount notified for temporary accommodation
   */
  temporaryAmount?: number | null;

  /**
   * The total amount notified for daycare accommodation
   */
  daycareAmount?: number | null;

  occupationRate: number | null | undefined;

  createdAt?: DateString | null | undefined;

  updatedAt?: Date | null | undefined;
}

export interface EasyCreateAccommodationFundingDto
  extends Omit<EasyAccommodationFunding, 'id' | 'createdAt' | 'updatedAt'> {}

export interface EasyUpdateAccommodationFundingDto extends Omit<EasyAccommodationFunding, 'createdAt' | 'updatedAt'> {}
