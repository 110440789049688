import { CommonAbstractDto } from '@implementations/bundles/eprd-errd/dto/common.abstract.dto';
import { isValidERRDIdType, UpdateEPRDDto, UpdateLocalEPRDDto } from '@application/bundles/eprd-errd';
import { isLocalMedia, isMedia, LocalMedia, Media } from '@domain/media';
import { isValidObject } from '@application/framework/lib';
import { IsLocalMediaOrMedia } from '@implementations/bundles/media';
import { IsArray, IsOptional, IsUUID } from 'class-validator';
import { EPRD } from '@domain/eprd-errd';

export class UpdateEPRDDtoImpl extends CommonAbstractDto implements UpdateEPRDDto {
  @IsUUID()
  public id!: EPRD['id'];

  @IsLocalMediaOrMedia()
  @IsOptional()
  public completeDocument!: LocalMedia | Media;

  @IsLocalMediaOrMedia()
  @IsOptional()
  public payrollDocument!: LocalMedia | Media;

  @IsLocalMediaOrMedia()
  @IsOptional()
  public financialAnnexDocument!: LocalMedia | Media;

  @IsLocalMediaOrMedia()
  @IsOptional()
  public activityAnnexDocument!: LocalMedia | Media;

  @IsLocalMediaOrMedia()
  @IsOptional()
  public reportDocument?: LocalMedia | Media;

  @IsArray()
  public details: UpdateLocalEPRDDto[] = [];

  constructor(defaults?: Partial<UpdateEPRDDto>) {
    super(defaults);

    if (isValidObject(defaults)) {
      if (isValidERRDIdType(defaults.id)) {
        this.id = defaults.id;
      }

      if (Array.isArray(defaults?.details)) {
        this.details = defaults?.details;
      }

      const keys = [
        'completeDocument',
        'payrollDocument',
        'financialAnnexDocument',
        'activityAnnexDocument',
        'reportDocument',
      ];

      keys.forEach(key => {
        const media = defaults[key as 'completeDocument'] as LocalMedia | Media | undefined;

        if (isLocalMedia(media) || isMedia(media)) {
          this[key as 'completeDocument'] = media;
        }
      });
    }
  }
}
