import { DateString } from '@application/framework/date';
import {
  CreateEasyRecurringHealthFundingDto,
  EasyRecurringHealthFunding,
  UpdateEasyRecurringHealthFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/health-funding/easy-recurring-health-funding';
import {
  CreateEasyExceptionalHealthFundingDto,
  EasyExceptionalHealthFunding,
  UpdateEasyExceptionalHealthFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/health-funding/easy-exceptional-health-funding';
import { EasyFacility } from '@implementations/bundles/backend/easyhpad';
import {
  CreateEasyNonRecurringHealthFundingDto,
  EasyNonRecurringHealthFunding,
  UpdateEasyNonRecurringHealthFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/health-funding/easy-non-recurring-funding';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { UUID } from '@domain/lib';

export interface EasyHealthFunding {
  id: UUID;

  date: DateString;

  accountingYear: number;

  gmpValue: number;

  pmpValue: number;

  amount: number;

  nonRecurringAmount: number;

  permanentAmount?: number | null;

  temporaryAmount?: number | null;

  daycareAmount?: number | null;

  supportAmount?: number | null;

  facilityId: EasyFacility['id'];

  arreteId: EasyMedia['id'];

  reportId: EasyMedia['id'] | null | undefined;

  recurringFunding: EasyRecurringHealthFunding[];

  uniqueFunding: EasyNonRecurringHealthFunding[];

  exceptionalFunding: EasyExceptionalHealthFunding[];

  ehpadDemainFunding: any[];

  createdAt?: DateString;
  updatedAt?: DateString;
}

export interface CreateEasyHealthFundingDto
  extends Omit<
    EasyHealthFunding,
    'id' | 'recurringFunding' | 'exceptionalFunding' | 'uniqueFunding' | 'ehpadDemainFunding'
  > {
  recurringFunding: CreateEasyRecurringHealthFundingDto[];

  uniqueFunding: CreateEasyNonRecurringHealthFundingDto[];

  exceptionalFunding: CreateEasyExceptionalHealthFundingDto[];

  ehpadDemainFunding: any[];
}

export interface UpdateEasyHealthFundingDto
  extends Omit<EasyHealthFunding, 'recurringFunding' | 'exceptionalFunding' | 'uniqueFunding' | 'ehpadDemainFunding'> {
  recurringFunding: Array<CreateEasyRecurringHealthFundingDto | UpdateEasyRecurringHealthFundingDto>;

  uniqueFunding: Array<CreateEasyNonRecurringHealthFundingDto | UpdateEasyNonRecurringHealthFundingDto>;

  exceptionalFunding: Array<CreateEasyExceptionalHealthFundingDto | UpdateEasyExceptionalHealthFundingDto>;

  ehpadDemainFunding: any[];
}
