<p-tabView *ngIf="healthFunding && fundingTabsDisplay" [(activeIndex)]="fundingTabIndex" class="table-container">

  <p-tabPanel *ngIf="healthFunding.recurringFunding && healthFunding.recurringFunding.size > 0">
    <ng-template pTemplate="header">
      <span class="title">{{'Crédits reconductibles' | ehp_translate}}</span>
    </ng-template>

    <ehp-recurring-funding-table [funding]="healthFunding.recurringFunding"></ehp-recurring-funding-table>
  </p-tabPanel>

  <p-tabPanel *ngIf="healthFunding.nonRecurringFunding && healthFunding.nonRecurringFunding.size > 0;">
    <ng-template pTemplate="header">
      <span class="title">{{'Crédits non reconductibles' | ehp_translate}}</span>
    </ng-template>

    <ehp-non-recurring-funding-table [funding]="healthFunding.nonRecurringFunding"></ehp-non-recurring-funding-table>
  </p-tabPanel>

  <p-tabPanel *ngIf="healthFunding.ehpadDemainFunding && healthFunding.ehpadDemainFunding.size > 0;">
    <ng-template pTemplate="header">
      <span class="title">{{'EHPAD de demain' | ehp_translate}}</span>
    </ng-template>

    <ehp-ehpad-demain-funding-table [funding]="healthFunding.ehpadDemainFunding"></ehp-ehpad-demain-funding-table>

  </p-tabPanel>

  <p-tabPanel *ngIf="healthFunding.exceptionalFunding && healthFunding.exceptionalFunding.size > 0;">
    <ng-template pTemplate="header">
      <span class="title">{{'Autres dotations exceptionnelles' | ehp_translate}}</span>
    </ng-template>

    <ehp-exceptional-funding-table [funding]="healthFunding.exceptionalFunding"></ehp-exceptional-funding-table>
  </p-tabPanel>
</p-tabView>

<div *ngIf="!fundingTabsDisplay" class="empty">
  <ehp-empty-block>{{'Aucun détail sur la répartition du financement soin disponible.' | ehp_translate}}</ehp-empty-block>
</div>
