import {CapacityAuthorization} from "@domain/capacity-authorization";

/**
 * Check if end date is always greater than start date
 */
export const capacityAuthorizationDatesValidator = Object.freeze({
  validate(start: Date, end: Date) {
    return start < end;
  }
});

/**
 * Check if begin date is included between start and end date
 */
export const capacityAuthorizationBeginDateValidator = Object.freeze({
  validate(begin: Date, dates: CapacityAuthorization['dates']) {
    return capacityAuthorizationBeginDateValidator.compareToStart(begin, dates.start)
      && capacityAuthorizationBeginDateValidator.compareToEnd(begin, dates.end);
  },

  compareToStart(begin: Date, start: Date) {
    return begin >= start;
  },

  compareToEnd(begin: Date, end: Date) {
    return begin <= end;
  }
});


/**
 * Check if bed care is always less than authorized capacity
 */
export const bedCareValidator = Object.freeze({
  validate(bedCare: number | null | undefined, authorizedCapacity: number): boolean {

    if (bedCare === undefined || bedCare === null) {
      return true;
    }

    return !(isNaN(bedCare) || !isFinite(bedCare) || bedCare < 0 || bedCare > authorizedCapacity);

  }
});


export const pasaUhrValidator = Object.freeze({
  validate(total: number, pasa: number | null | undefined, uhr: number | null | undefined): boolean {
    
    const value = ((pasa || 0) + (uhr || 0));

    return !(
      isNaN(total)
      || !isFinite(total)
      || isNaN(value)
      || !isFinite(value)
      || value < 0
      || value > total
    );

  }
});
