import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidPermanentDependencyFundingIdType} from "@application/bundles/dependency-funding";

@ValidatorConstraint({name: ' IsValidPermanentDependencyFundingId', async: true})
export class IsValidPermanentDependencyFundingIdConstraint implements ValidatorConstraintInterface {

  public validate(id: any, args?: ValidationArguments): Promise<boolean> {
    return Promise.resolve(isValidPermanentDependencyFundingIdType(id));
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'ID is not a valid permanent dependency funding ID.';
  }
}

export function IsValidPermanentDependencyFundingId(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidPermanentDependencyFundingIdConstraint
    });
  };
}
