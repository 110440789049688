<div class="chart-container">
  <ngx-charts-pie-chart
    [arcWidth]="0.55"
    [doughnut]="false"
    [labels]="false"
    [legendPosition]="legendOptions.position"
    [legendTitle]="legendOptions.title"
    [legend]="legendOptions.display"
    [results]="data"
    [scheme]="colors"
    [tooltipDisabled]="true"
  >
  </ngx-charts-pie-chart>
</div>
