import {
  MissingAuthentificationTokenError
} from "@application/bundles/authentification/errors/missing-authentification-token.error";
import {
  AuthentificationToken,
  AuthentificationTokenChange,
  AuthentificationTokenStore
} from "@application/bundles/authentification";
import {Subscription} from "@application/framework/reactive";

export class AgnosticAuthentificationTokenStore implements AuthentificationTokenStore {

  private token: AuthentificationToken | undefined;
  private observers: Set<(token: AuthentificationTokenChange) => void> = new Set();

  public getToken(): AuthentificationToken {

    if (this.token === undefined || !this.token) {
      throw new MissingAuthentificationTokenError('The token storage contains no authentication token.');
    }

    return this.token;
  }

  public setToken(token: AuthentificationToken): void {
    const change: AuthentificationTokenChange = {current: token, previous: this.token};
    this.token = token;
    this.dispatchChange(change);

  }

  public onChange(observer: (token: AuthentificationTokenChange) => void): Subscription {
    this.observers.add(observer);
    return {unsubscribe: () => this.observers.delete(observer)};
  }

  private dispatchChange(change: AuthentificationTokenChange) {
    this.observers.forEach(observer => observer(change));
  }
}
