import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { CurrentFacilitiesStore } from '@application/bundles/facility/store';
import { DisconnectSuccessEvent } from '@application/bundles/authentification/events/disconnect-success.event';
import { ApplicationStartEvent } from '@application/bundles/application/events';

@HasEventSubscribers()
export class SetAuthorizedFacilitiesEventSubscriber {
  @ProvideLogger() public logger!: Logger;

  constructor(private readonly currentFacilitiesStore: CurrentFacilitiesStore) {}

  @OnEvent({
    event: ApplicationStartEvent,
  })
  public hydrateFromSettings() {
    this.currentFacilitiesStore.hydrate();
  }

  @OnEvent({
    event: [DisconnectSuccessEvent],
  })
  public async clearCurrentFacilities(): Promise<void> {
    await this.currentFacilitiesStore.clear();
  }
}
