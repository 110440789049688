import {NgModule} from "@angular/core";
import {DecisionManager} from "@application/bundles/authorization";
import {AuthorizationChecker} from "@application/bundles/authorization/abstractions/authorization.checker.abstract";
import {VoterRegistry} from "@application/bundles/authorization/abstractions/voter.registry.abstract";
import {AuthorizationBundle} from "@application/bundles/authorization/authorization.bundle";
import {
  AgnosticAuthorizationChecker
} from "@application/bundles/authorization/implementations/agnostic-authorization-checker/agnostic-authorization.checker";
import {
  AgnosticDecisionManager
} from "@application/bundles/authorization/implementations/agnostic-decision-manager/agnostic-decision.manager";
import {
  AgnosticVoterRegistry
} from "@application/bundles/authorization/implementations/agnostic-voter-registry/agnostic-voter.registry";
import {RoleRepository} from "@application/bundles/authorization/role.repository";
import {Container} from "@application/framework/di/container";
import {
  EasyRoleRepository
} from "@implementations/bundles/backend/easyhpad/repositories/authorization/easy-role.repository";
import {AuthentificationTokenStore} from "@application/bundles/authentification";

@NgModule({
  providers: [
    {
      provide: VoterRegistry,
      useValue: new AgnosticVoterRegistry()
    },
    {
      provide: DecisionManager,
      useClass: AgnosticDecisionManager,
      deps: [VoterRegistry],
    },
    {
      provide: AuthorizationChecker,
      useClass: AgnosticAuthorizationChecker,
      deps: [AuthentificationTokenStore, DecisionManager]
    },
    {
      provide: RoleRepository,
      useClass: EasyRoleRepository,
    }
  ]
})
export class AuthorizationModule {


  constructor(container: Container) {
    AuthorizationBundle.build(container);
  }

}
