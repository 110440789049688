import { ActionCreator, createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

export interface DocumentStoreActions<E extends { id: ID }, ID = string | number> {
  loadDocumentList: ActionCreator<
    string,
    (props: { params?: any | undefined }) => TypedAction<string> & { params?: any | undefined }
  >;

  documentListLoaded: ActionCreator<string, (props: { documents: E[] }) => TypedAction<string> & { documents: E[] }>;

  openDocument: ActionCreator<string, (props: { id: E['id'] }) => TypedAction<string> & { id: E['id'] }>;

  documentOpened: ActionCreator<string, (props: { document: E }) => TypedAction<string> & { document: E }>;

  updateDocument: ActionCreator<
    string,
    (props: { id: E['id']; update: any }) => TypedAction<string> & { id: E['id']; update: any }
  >;

  documentUpdated: ActionCreator<string, (props: { document: E }) => TypedAction<string> & { document: E }>;

  resetState: ActionCreator<string, () => TypedAction<string>>;
}

export const createDocumentActions = <E extends { id: ID }, ID = string | number>(
  name: string,
): DocumentStoreActions<E, ID> => ({
  loadDocumentList: createAction(`[${name}] Loading documents`, props<{ params?: any }>()),
  documentListLoaded: createAction(`[${name}] Document loaded`, props<{ documents: E[] }>()),
  openDocument: createAction(`[${name}] Open document`, props<{ id: E['id'] }>()),
  documentOpened: createAction(`[${name}] Document opened`, props<{ document: E }>()),
  updateDocument: createAction(`[${name}] Update document`, props<{ id: E['id']; update: any }>()),
  documentUpdated: createAction(`[${name}] Document updated`, props<{ document: E }>()),
  resetState: createAction(`[${name}] Reset state`),
});
