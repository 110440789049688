import { FACILITY_STATE } from '@domain/facility';
import { FACILITY_TYPE, SOCIAL_CLEARANCE } from '@domain/facility/types';
import { UUID } from '@domain/lib';
import {
  EasyAddress,
  EasyCreateAddress,
} from '@implementations/bundles/backend/easyhpad/interfaces/easy-address.interface';
import { EasyCustomer } from '@implementations/bundles/backend/easyhpad/interfaces/easy-customer.interface';
import { EasyFacilityContact } from '@implementations/bundles/backend/easyhpad/interfaces/easy-facility-contact.interface';

export interface EasyFacility {
  id: UUID;

  name: string;

  address: EasyAddress;

  customerId: EasyCustomer['id'] | null;

  type: FACILITY_TYPE;

  geoFiness: string;

  legalFiness: string;

  socialClearance: SOCIAL_CLEARANCE;

  phone: string | null;

  pole: boolean | null;

  managingOrganization?: string | null | undefined;

  contact?: EasyFacilityContact;

  state: FACILITY_STATE;

  created_at?: Date;

  updated_at?: Date;
}

export interface EasyFacilityCreateDto
  extends Omit<EasyFacility, 'id' | 'created_at' | 'updated_at' | 'state' | 'address'> {
  state?: EasyFacility['state'];

  address: EasyCreateAddress;
}

export interface EasyFacilityUpdateDto extends Omit<EasyFacility, 'id' | 'created_at' | 'updated_at' | 'state'> {
  state?: EasyFacility['state'];
}
