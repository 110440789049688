import { Transformer } from '@application/framework/core';
import {
  CreateEasyDaycareDependencyFundingDto,
  EasyDaycareDependencyFunding,
  UpdateEasyDaycareDependencyFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/dependency-funding/easy-daycare-dependency-funding';
import {
  DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  DAYCARE_DEPENDENCIES_FUNDING_MODES,
  DaycareDependencyFunding,
  DaycareDependencyFundingMode,
  DEPENDENCIES_FUNDING_MODES,
} from '@domain/dependency-funding';
import {
  CreateDaycareDependencyFundingDto,
  DaycareDependencyFundingModeCollection,
  UpdateDaycareDependencyFundingDto,
} from '@application/bundles/dependency-funding';
import { Common } from '@application/framework/lib';
import { GroupedGIRAmount, isGroupedGIR } from '@domain/gir';
import { Provide, Service } from '@application/framework/di';
import { isValidFundingAmount } from '@domain/funding';

type CommonProperties = Omit<
  Common<
    DaycareDependencyFunding<any, unknown>,
    Common<CreateDaycareDependencyFundingDto<any, unknown>, UpdateDaycareDependencyFundingDto<any, unknown>>
  >,
  'mode' | 'value'
>;

type EasyCommonProperties = Omit<
  Common<
    EasyDaycareDependencyFunding,
    Common<CreateEasyDaycareDependencyFundingDto, UpdateEasyDaycareDependencyFundingDto>
  >,
  'funding_mode'
>;

@Service()
export class EasyDaycareDependencyFundingTransformer
  implements Transformer<EasyDaycareDependencyFunding, DaycareDependencyFunding<any, unknown>>
{
  @Provide() private readonly modeCollection!: DaycareDependencyFundingModeCollection;

  public transform(value: EasyDaycareDependencyFunding): DaycareDependencyFunding<any, unknown> {
    return {
      id: value.id,
      ...this.transformCommon(value),
      mode: this.modeCollection.get(
        value.funding_mode,
      ) as DaycareDependencyFundingMode<DAYCARE_DEPENDENCIES_FUNDING_MODES>,
      value: () => this.getValue(value.funding_mode, value),
    };
  }

  public flatTransform(
    value: EasyDaycareDependencyFunding,
  ): Omit<DaycareDependencyFunding<any, unknown>, 'value'> & { value: unknown } {
    return {
      id: value.id,
      ...this.transformCommon(value),
      mode: this.modeCollection.get(
        value.funding_mode,
      ) as DaycareDependencyFundingMode<DAYCARE_DEPENDENCIES_FUNDING_MODES>,
      value: this.getValue(value.funding_mode, value),
    };
  }

  public transformCreateDto(
    value: CreateEasyDaycareDependencyFundingDto,
  ): CreateDaycareDependencyFundingDto<any, unknown> {
    return {
      ...this.transformCommon(value),
      mode: value.funding_mode,
      value: this.getValue(value.funding_mode, value),
    };
  }

  public transformUpdateDto(
    value: UpdateEasyDaycareDependencyFundingDto,
  ): UpdateDaycareDependencyFundingDto<any, unknown> {
    return {
      ...this.transformCommon(value),
      id: value.id,
      mode: value.funding_mode,
      value: this.getValue(value.funding_mode, value),
    };
  }

  public reverseTransform(value: DaycareDependencyFunding<any, unknown>): EasyDaycareDependencyFunding {
    const funding: EasyDaycareDependencyFunding = {
      ...this.reverseTransformCommon(value),
      id: value.id,
      funding_mode: value.mode.key,
    };

    this.setEasyValue(value.mode.key, value.value() as any, funding);

    return funding;
  }

  public reverseTransformCreateDto(
    value: CreateDaycareDependencyFundingDto<any, unknown>,
  ): CreateEasyDaycareDependencyFundingDto {
    const dto = {
      ...this.reverseTransformCommon(value),
      funding_mode: value.mode,
    };

    this.setEasyValue(value.mode, value.value as any, dto);

    return dto;
  }

  public reverseTransformUpdateDto(
    value: UpdateDaycareDependencyFundingDto<any, unknown>,
  ): UpdateEasyDaycareDependencyFundingDto {
    const dto = {
      ...this.reverseTransformCommon(value),
      id: value.id,
      funding_mode: value.mode,
    };

    this.setEasyValue(value.mode, value.value as any, dto);

    return dto;
  }

  private transformCommon(value: EasyCommonProperties): CommonProperties {
    return {
      activityAllowance: value.activity_abattement || undefined,
      comment: value.comment_other || undefined,
    };
  }

  private reverseTransformCommon(value: CommonProperties): EasyCommonProperties {
    return {
      activity_abattement: value.activityAllowance,
      comment_other: value.comment,
      price_gir1_2: undefined,
      price_gir3_4: undefined,
      price_gir5_6: undefined,
      unique_price: undefined,
      amount_other: undefined,
      amount_package: 0,
    };
  }

  private getValue(
    mode: DAYCARE_DEPENDENCIES_FUNDING_MODES,
    properties: EasyCommonProperties,
  ): GroupedGIRAmount | number | undefined {
    let value: GroupedGIRAmount | number | undefined;

    if (mode === DEPENDENCIES_FUNDING_MODES.GIR_GROUP) {
      value = {
        firstGIRGroup: properties.price_gir1_2 || 0,
        secondGIRGroup: properties.price_gir3_4 || 0,
        thirdGIRGroup: properties.price_gir5_6 || 0,
      };
    } else if (mode === DEPENDENCIES_FUNDING_MODES.AMOUNT) {
      value = properties.amount_package || 0;
    } else if (mode === DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.SINGLE_GIR_AMOUNT) {
      value = properties.unique_price || 0;
    } else if (mode === DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.OTHER) {
      value = properties.amount_other || 0;
    }

    return value;
  }

  private setEasyValue(
    mode: DAYCARE_DEPENDENCIES_FUNDING_MODES,
    value: GroupedGIRAmount | number | undefined,
    funding: EasyCommonProperties,
  ) {
    if (mode === DEPENDENCIES_FUNDING_MODES.GIR_GROUP && isGroupedGIR(value)) {
      funding.price_gir1_2 = value.firstGIRGroup;
      funding.price_gir3_4 = value.secondGIRGroup;
      funding.price_gir5_6 = value.thirdGIRGroup;
    } else if (mode === DEPENDENCIES_FUNDING_MODES.AMOUNT && isValidFundingAmount(value)) {
      funding.amount_package = value;
    } else if (mode === DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.SINGLE_GIR_AMOUNT && isValidFundingAmount(value)) {
      funding.unique_price = value;
    } else if (mode === DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.OTHER && isValidFundingAmount(value)) {
      funding.amount_other = value;
    }
  }
}
