import { isValidObject } from '@application/framework/lib';
import { HealthFunding } from '@domain/health-funding';
import { isUUID } from '@domain/lib';

export function isValidHealthFundingIdType(id: any): id is HealthFunding['id'] {
  return isUUID(id);
}

export function isMaybeAHealthFunding(healthFunding: any): healthFunding is { id: HealthFunding['id'] } {
  return (
    isValidObject(healthFunding) &&
    'id' in healthFunding &&
    isValidHealthFundingIdType((healthFunding as HealthFunding).id)
  );
}
