import {
  GetApplicableCapacityAuthorizationQuery
} from "@application/bundles/capacity-authorization/queries/get-applicable-capacity-authorization.query";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {CapacityAuthorization, CapacityAuthorizationRepository} from "@domain/capacity-authorization";

@HandleQuery({
  query: GetApplicableCapacityAuthorizationQuery
})
export class GetApplicableCapacityAuthorizationQueryHandler implements QueryHandler<GetApplicableCapacityAuthorizationQuery, CapacityAuthorization | undefined> {

  constructor(private readonly repository: CapacityAuthorizationRepository) {
  }

  public async handle(query: GetApplicableCapacityAuthorizationQuery): Promise<CapacityAuthorization | undefined> {
    const authorizations = await this.repository.list();
    return authorizations[0];
  }

}
