import { TranslationReplacements } from '@application/framework/translation/translation-replacement.interface';
import { Stringifiable } from '@domain/lib';

export class TranslatableString implements Stringifiable, Pick<String, 'toLowerCase'> {
  constructor(
    public readonly value: string,
    public readonly replacement?: TranslationReplacements,
    public readonly context?: string,
  ) {}

  public toString(): string {
    return this.value;
  }

  public toLowerCase(): string {
    return this.value.toLowerCase();
  }
}
