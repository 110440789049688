import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {GMP} from "@domain/gmp";

export class GMPUpdatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly gmp: GMP) {
    super();
  }
}
