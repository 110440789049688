import {Cache, CacheManager} from "@application/framework/cache";
import {InvalidCacheIdentifierError, MissingCacheError} from "@application/framework/cache/errors";

export class MemoryCacheManager implements CacheManager {

  private static readonly caches: Map<string | Symbol, Cache> = new Map();

  constructor(private readonly config?: { missingCacheStrategy?: <T>() => Promise<Cache<T>> }) {
  }

  public static set(key: string | Symbol, cache: Cache): void {
    MemoryCacheManager.caches.set(key, cache);
  }

  public async get<T = unknown>(key: string | Symbol | { key: string | Symbol, factory: () => Promise<Cache<T>> }): Promise<Cache<T>> {

    const k = this.extractKey(key);

    if (k === undefined) {
      throw new InvalidCacheIdentifierError(`key ${key.toString()} is not a valid cache identifier. Please use string or symbol`);
    }

    let cache = MemoryCacheManager.caches.get(k);

    if (cache !== undefined) {
      return cache as Cache<T>;
    }

    let factory = this.extractFactory<T>(key);


    if (!factory) {

      if (this.config?.missingCacheStrategy) {
        factory = this.config.missingCacheStrategy;
      } else {
        throw new MissingCacheError(`Unknown cache for key : ${key.toString()}`);
      }

    }

    cache = await factory();
    MemoryCacheManager.set(k, cache);
    return cache as Cache<T>;
  }

  public async reset(key: string | Symbol): Promise<void> {
    const cache = MemoryCacheManager.caches.get(key);

    if (cache !== undefined && typeof cache.reset === 'function') {
      await cache.reset();
    }
  }

  private extractKey<T = unknown>(key: string | Symbol | { key: string | Symbol, factory: () => Promise<Cache<T>> }): string | Symbol | undefined {

    if (typeof typeof key === "string" || typeof key === "symbol") {
      return key ? key as any : undefined;

    } else if (typeof key === 'object' && key.hasOwnProperty('key')) {
      // @ts-ignore
      const k = key['key'];
      return (typeof k === "string" && k !== '') || typeof k === "symbol" ? k : undefined;

    }

    return undefined;
  }

  private extractFactory<T = unknown>(key: string | Symbol | { key: string | Symbol, factory: () => Promise<Cache<T>> }): (() => Promise<Cache<T>>) | undefined {

    if (typeof typeof key === "string" || typeof key === "symbol") {
      return undefined;
    }

    // @ts-ignore
    return typeof key.factory === "function" ? key.factory : undefined;


  }

}
