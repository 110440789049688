import {
  DocumentShowViewContainer,
  DocumentShowViewProcessorInstance,
  ShowDocumentActions,
} from '@easyhpad-ui/app/bundles/documents';
import { Observable, Subject } from 'rxjs';
import { Document } from '@domain/document';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { DocumentFromStoreSingleViewProcessorAbstract } from '@easyhpad-ui/app/bundles/documents/abstracts/document-from-store-show-view-processor/document-from-store-single-view-processor.abstract';
import { DocumentViewMediaReference } from '@easyhpad-ui/app/bundles/documents/contracts/document-view';

export abstract class DocumentFromStoreShowViewProcessorAbstract<D extends Document>
  extends DocumentFromStoreSingleViewProcessorAbstract<D>
  implements DocumentShowViewProcessorInstance
{
  /**
   * @inheritDoc
   */
  public readonly actions: Observable<ShowDocumentActions>;

  /**
   * Document actions emitter
   * @protected
   */
  protected readonly actions$ = new Subject<ShowDocumentActions>();

  protected constructor(
    protected readonly parent: DocumentShowViewContainer,
    store: Store<AppState>,
  ) {
    super(store);
    this.actions = this.actions$.asObservable();
  }

  /**
   * Get the media list for the document.
   * @param document
   */
  public abstract getDocumentMedias(document?: D): DocumentViewMediaReference[];

  /**
   * Dispatch all changes (title, medias, ...) based on the provided document.
   * @param document
   * @protected
   */
  protected override updateDocument(document: D | null): void {
    this.medias.next(this.getDocumentMedias(document ?? undefined));
    super.updateDocument(document);
  }
}
