import { LocalMedia, Media } from '@domain/media';
import { PMP } from '@domain/pmp';

export interface CreatePMPDto extends Omit<PMP, 'id' | 'pvId' | 'createdAt' | 'updatedAt'> {
  pvId?: Media['id'];

  pv: LocalMedia;
}

export interface UpdatePMPDto extends Omit<PMP, 'pvId' | 'createdAt' | 'updatedAt'> {
  pvId?: Media['id'];

  pv?: LocalMedia | Media;
}
