import {Command} from "@application/framework/command-query/command.interface";
import {HttpAuthorizationToken} from "@application/framework/http";

export class RefreshHttpAuthorizationTokenCommand<T extends HttpAuthorizationToken<any>> implements Command {


  public constructor(public readonly token: T) {
  }

}
