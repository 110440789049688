import { Component, HostListener, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GetPMPQuery } from '@application/bundles/pmp';
import { QueryBus } from '@application/framework/command-query';
import { getPMPYear, PMP } from '@domain/pmp';
import { PMPUrlsProvider } from '@easyhpad-ui/app/bundles/pmp';

@Component({
  selector: 'ehp-pmp-link',
  templateUrl: './pmp-link.component.html',
  styleUrls: ['./pmp-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.link]': 'isLink',
    '[attr.role]': 'role',
  },
})
export class PMPLinkComponent implements OnChanges {
  @Input() public pmp!: PMP;

  @Input() public refId!: PMP['id'];

  private url: string | null | undefined;

  public get isLink(): boolean {
    return !!this.url;
  }

  public get role(): string | undefined {
    return this.isLink ? 'link' : undefined;
  }

  public get year() {
    return getPMPYear(this.pmp);
  }

  constructor(
    private readonly queryBus: QueryBus,
    private readonly urls: PMPUrlsProvider,
    private readonly router: Router,
  ) {}

  @HostListener('click', ['$event'])
  public async click(event: MouseEvent) {
    if (!this.isLink) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.url !== undefined && this.url !== null) {
      return this.router.navigateByUrl(this.url);
    }

    return false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['refId']) {
      if (changes['refId'].previousValue !== changes['refId'].currentValue) {
        this.loadPMP();
      }
    }

    if (changes['pmp']) {
      if (changes['pmp'].previousValue !== changes['pmp'].currentValue) {
        this.pmpHasChange();
      }
    }
  }

  private loadPMP(): void {
    if (this.refId) {
      this.queryBus.request<PMP>(new GetPMPQuery(this.refId)).then(pmp => {
        this.pmp = pmp;
        this.pmpHasChange();
      });
    }
  }

  private setUrl(): void {
    this.url = null;

    if (this.pmp) {
      this.url = this.urls.getUnsafePublicShow(this.pmp.id);
    }
  }

  private pmpHasChange(): void {
    this.setUrl();
  }
}
