import { Response } from '@application/framework/http';
import { HttpStatusCode } from '@application/framework/http/http-code';
import { NotFoundDocumentError, UnauthorizedAccessError } from '@application/framework/lib';

function throwErrorFromStatus(status: HttpStatusCode) {
  switch (status) {
    case HttpStatusCode.Forbidden:
      throw new UnauthorizedAccessError('Forbidden');
    case HttpStatusCode.Unauthorized:
      throw new UnauthorizedAccessError('Unauthorized');
    case HttpStatusCode.NotFound:
      throw new NotFoundDocumentError('Not found');
  }
}

export function parseBackendResponse<Body = unknown>(response: Response<Body>): Body {
  throwErrorFromStatus(response.status);
  return response.body;
}
