import { CommonAbstractDto } from '@implementations/bundles/eprd-errd/dto/common.abstract.dto';
import { isLocalMedia, LocalMedia } from '@domain/media';
import { IsArray, IsOptional } from 'class-validator';
import { isValidObject } from '@application/framework/lib';
import { CreateEPRDDto, CreateLocalEPRDDto } from '@application/bundles/eprd-errd';
import { IsLocalMedia } from '@implementations/bundles/media';

export class CreateEPRDDtoImpl extends CommonAbstractDto implements CreateEPRDDto {
  @IsLocalMedia()
  public completeDocument!: LocalMedia;

  @IsLocalMedia()
  @IsOptional()
  public payrollDocument!: LocalMedia;

  @IsLocalMedia()
  @IsOptional()
  public financialAnnexDocument!: LocalMedia;

  @IsLocalMedia()
  @IsOptional()
  public activityAnnexDocument!: LocalMedia;

  @IsLocalMedia()
  @IsOptional()
  public reportDocument?: LocalMedia;

  @IsArray()
  public details: CreateLocalEPRDDto[] = [];

  constructor(defaults?: Partial<CreateEPRDDto>) {
    super(defaults);

    if (isValidObject(defaults)) {
      if (Array.isArray(defaults?.details)) {
        this.details = defaults?.details;
      }

      const keys = [
        'completeDocument',
        'payrollDocument',
        'financialAnnexDocument',
        'activityAnnexDocument',
        'reportDocument',
      ];

      keys.forEach(key => {
        const media = defaults[key as 'completeDocument'] as LocalMedia | undefined;

        if (isLocalMedia(media)) {
          this[key as 'completeDocument'] = media;
        }
      });
    }
  }
}
