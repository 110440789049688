<ng-container *ngIf="gmp">

  <h3 class="title item-title m0">

    <span class="icon ehp-icon icon-file"></span>

    <ehp-gmp-link [gmp]="gmp"></ehp-gmp-link>
  </h3>

  <div class="date">{{gmp.date | date:'dd/MM/YYYY'}}</div>

  <div class="value">{{gmp.value}}</div>

  <div class="facility">
    <ehp-facility-link *ngIf="gmp" [fid]="gmp.facilityId"></ehp-facility-link>
  </div>

  <div class="actions-list actions">

    <ehp-gmp-link
      [gmp]="gmp"
      class="is-icon icon-arrow ghost button"
      pTooltip="{{'Voir' | ehp_translate}}"
      tooltipPosition="bottom"
    >
      <span class="label">{{'Consulter le G.M.P.' | ehp_translate }}</span>
    </ehp-gmp-link>


  </div>

</ng-container>
