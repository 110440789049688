import { AbstractEasyFundingAmountRepository } from '@implementations/bundles/backend/easyhpad/repositories/funding/abstract-easy-funding-amount.repository';
import { DependencyFundingAmountRepository } from '@application/bundles/funding';
import { EasyDependencyFunding } from '@implementations/bundles/backend/easyhpad';
import { Provide } from '@application/framework/di';
import { DependencyFunding } from '@domain/dependency-funding';
import { EasyDependencyFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers/dependency-funding/easy-dependency-funding.transformer';
import { CreateDependencyFundingDto, UpdateDependencyFundingDto } from '@application/bundles/dependency-funding';

export class EasyDependencyFundingAmountRepository
  extends AbstractEasyFundingAmountRepository<DependencyFunding, EasyDependencyFunding>
  implements DependencyFundingAmountRepository
{
  @Provide() private readonly entityTransformer!: EasyDependencyFundingTransformer;

  constructor() {
    super('funding/dependency/amounts', {
      from: input => this.entityTransformer.reverseTransform(input),
      to: output => this.entityTransformer.transform(output),
      create: (input: CreateDependencyFundingDto) => this.entityTransformer.reverseTransformCreateDto(input),
      update: (input: UpdateDependencyFundingDto) => this.entityTransformer.reverseTransformUpdateDto(input),
    });
  }
}
