import {isLocalMedia} from "@domain/media";
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';

@ValidatorConstraint({name: 'IsLocalMedia', async: false})
export class IsLocalMediaConstraint implements ValidatorConstraintInterface {

  public validate(media: any, args: ValidationArguments) {
    return isLocalMedia(media);
  }

  public defaultMessage(args: ValidationArguments) {
    return 'This media must be a Local Media with a File reference.';
  }
}


export function IsLocalMedia(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsLocalMediaConstraint,
    });
  };
}
