import {
  CreateDependencyFundingDto,
  DependencyFundingSearchParams,
  UpdateDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {DependencyFunding} from "@domain/dependency-funding";

export abstract class DependencyFundingRepository {

  public abstract list(params?: DependencyFundingSearchParams): Promise<DependencyFunding[]>;

  public abstract get(id: DependencyFunding['id']): Promise<DependencyFunding>;

  public abstract create(dependencyFunding: CreateDependencyFundingDto): Promise<DependencyFunding>;

  public abstract update(id: DependencyFunding['id'], update: Partial<UpdateDependencyFundingDto>): Promise<DependencyFunding>;

  public abstract delete(id: DependencyFunding['id']): Promise<DependencyFunding>;
}
