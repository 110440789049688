<header #dragHandler class="header">
  <span class="title">{{'Simulateur' | ehp_translate}}</span>

  <div class="actions-list">

    <button (click)="toggleCollapse()" [ngClass]="{'icon-reduce-screen': !collapsed, 'icon-fullscreen': collapsed }"
            class="is-icon ghost secondary small" type="button">
      <span class="label">{{'Fermer le simulateur' | ehp_translate}}</span>
    </button>

    <button (click)="close()" class="is-icon icon-close ghost delete small" type="button">
      <span class="label">{{'Fermer le simulateur' | ehp_translate}}</span>
    </button>
  </div>
</header>


<div class="content">


  <ng-container [ngSwitch]="type">

    <ehp-health-allocation-calculator
      *ngSwitchCase="CALCULATOR_TYPES.HEALTH_ALLOCATION">
    </ehp-health-allocation-calculator>

    <ehp-gmp-calculator *ngSwitchDefault class="gmp-calculator"></ehp-gmp-calculator
    >
  </ng-container>
</div>
