import { FileDepositItem } from '@domain/file-deposit';
import { Year } from '@domain/lib';
import { DOCUMENT_TYPES } from '@domain/document';

export type UnsavedFileDepositItem<S extends string> = Omit<FileDepositItem, 'id' | 'parentId'> & {
  parentId?: FileDepositItem['parentId'];
};

export interface DocumentProjectItem<
  S extends string,
  T extends UnsavedFileDepositItem<S> = UnsavedFileDepositItem<S>,
> {
  type: DOCUMENT_TYPES;

  year: Year;

  items: T[];
}

export interface FileDepositItemsValidationResult {
  found: Array<{ type: DocumentProjectItem<any>['type']; year?: DocumentProjectItem<any>['year'] }>;
  missing: Array<{ type: DocumentProjectItem<any>['type']; year?: DocumentProjectItem<any>['year'] }>;
  documents: DocumentProjectItem<string>[];
}
