import {EPRDRepository, EPRDSearchParams, SerializedEPRDSearchParams} from "@application/bundles/eprd-errd";
import {DocumentRepository} from "@implementations/bundles/backend/easyhpad/repositories/base";
import {
  EasyEPRDTransformer
} from "@implementations/bundles/backend/easyhpad/transformers/errd-errd/easy-eprd.transformer";
import {Provide} from "@application/framework/di";
import {EPRD} from "@domain/eprd-errd";
import {EasyEPRD} from "@implementations/bundles/backend/easyhpad";

export class EasyEPRDRepository extends DocumentRepository<EPRDSearchParams, SerializedEPRDSearchParams, EPRD, EasyEPRD> implements EPRDRepository {

  @Provide() private readonly entityTransformer!: EasyEPRDTransformer;

  constructor() {
    super('eprd', {
      from: (input) => this.entityTransformer.transform(input),
      to: (output) => this.entityTransformer.reverseTransform(output),
      create: (input) => this.entityTransformer.createTransform(input),
      update: (input) => this.entityTransformer.updateTransform(input)
    });
  }


}
