import {
  ResetUserPasswordRequestFailEvent
} from "@application/bundles/user/events/reset-password/reset-user-password-request-fail.event";
import {
  ResetUserPasswordRequestSuccessEvent
} from "@application/bundles/user/events/reset-password/reset-user-password-request-success.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";

@HasEventSubscribers()
export class OnUserResetPasswordSubscriber {


  constructor(
    private readonly translator: Translator,
    private readonly noticeStream: NoticeStream
  ) {
  }

  @OnEvent({
    event: ResetUserPasswordRequestSuccessEvent
  })
  public async pushResetPasswordRequestSuccessNotice(event: ResetUserPasswordRequestSuccessEvent) {

    const message = await this.translator.translate(
      "Demande de récupération réussie. Un e-mail va être envoyé à l'adresse <strong>{{email}}</strong>.",
      {email: event.email}
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  @OnEvent({
    event: ResetUserPasswordRequestFailEvent
  })
  public async pushResetPasswordRequestFailNotice() {
    const message = await this.translator.translate(
      "Impossible de récupérer le mot de passe pour se compte. Veuillez vérifier l'adresse saisie puis réessayez.",
    );

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }


}
