import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentViewResolver } from './services';
import {
  DocumentCreateViewProvider,
  DocumentListViewProvider,
  DocumentShowViewProvider,
} from '@easyhpad-ui/app/bundles/documents';
import {
  DocumentActionComponent,
  DocumentCreateViewPageComponent,
  DocumentEditViewPageComponent,
  DocumentListViewPageComponent,
  DocumentShowViewPageComponent,
} from './components';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { TableModule } from '@easyhpad-ui/app/library/table/table.module';
import { RouterModule } from '@angular/router';
import { FacilityUiModule } from '@easyhpad-ui/app/bundles/facility/modules/ui/facility-ui.module';
import { AccommodationFundingUiModule } from '@easyhpad-ui/app/bundles/accommodation-funding/modules/ui/accommodation-funding-ui.module';
import { MediaModule } from '@easyhpad-ui/app/bundles/media/media.module';
import { DocumentEditViewProvider } from '@easyhpad-ui/app/bundles/documents/contracts/document-edit-view';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReusableComponentsModule,
    TranslationModule,
    TableModule,
    FacilityUiModule,
    AccommodationFundingUiModule,
    MediaModule,
  ],
  declarations: [
    DocumentListViewPageComponent,
    DocumentShowViewPageComponent,
    DocumentCreateViewPageComponent,
    DocumentEditViewPageComponent,
    DocumentActionComponent,
  ],
})
export class DocumentsModule {
  public static forRoot(): ModuleWithProviders<DocumentsModule> {
    return {
      ngModule: DocumentsModule,
      providers: [DocumentViewResolver],
    };
  }

  public static forFeature(options?: {
    providers?: Array<
      | DocumentListViewProvider<any>
      | DocumentShowViewProvider<any>
      | DocumentCreateViewProvider<any>
      | DocumentEditViewProvider<any>
    >;
  }): ModuleWithProviders<DocumentsModule> {
    if (options && Array.isArray(options.providers)) {
      options.providers.forEach(provider => DocumentViewResolver.register(provider));
    }

    return {
      ngModule: DocumentsModule,
      providers: [DocumentViewResolver],
    };
  }
}
