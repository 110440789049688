import {Component, HostListener, Input, OnChanges, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {GetCPOMQuery, StoredCPOM} from "@application/bundles/cpom";
import {QueryBus} from "@application/framework/command-query";
import {Facility} from "@domain/facility";
import {CPOMUrlsProvider} from "@easyhpad-ui/app/bundles/cpom";

@Component({
  selector: 'ehp-cpom-link',
  templateUrl: './cpom-link.component.html',
  styleUrls: ['./cpom-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.link]': 'isLink',
    '[attr.role]': 'role',
  }
})
export class CPOMLinkComponent implements OnChanges {

  @Input() public cpom!: StoredCPOM;

  @Input() public refId!: StoredCPOM['id'];

  public facility: Facility | undefined;

  private url: string | null | undefined;

  public get isLink(): boolean {
    return !!this.url;
  }

  public get role(): string | undefined {
    return this.isLink ? 'link' : undefined;
  }

  constructor(
    private readonly queryBus: QueryBus,
    private readonly urls: CPOMUrlsProvider,
    private readonly router: Router
  ) {
  }

  @HostListener('click', ['$event'])
  public async click(event: MouseEvent) {
    if (!this.isLink) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.url !== undefined && this.url !== null) {
      return this.router.navigateByUrl(this.url);
    }

    return false;

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['refId']) {

      if (changes['refId'].previousValue !== changes['refId'].currentValue) {
        this.loadCPOM();
      }
    }

    if (changes['cpom']) {
      if (changes['cpom'].previousValue !== changes['cpom'].currentValue) {
        this.cpomHasChange();
      }
    }
  }

  private loadCPOM(): void {

    if (this.refId) {
      this.queryBus.request<StoredCPOM>(new GetCPOMQuery(this.refId)).then(cpom => {
        this.cpom = cpom;
        this.cpomHasChange();
      });

    }
  }

  private setUrl(): void {
    this.url = null;

    if (this.cpom) {
      this.url = this.urls.getUnsafePublicShow(this.cpom.id);
    }

  }

  private cpomHasChange(): void {
    this.setUrl();
    if (this.cpom === undefined) {
      this.facility = undefined;
    } else {
    }
  }


}
