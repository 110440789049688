<ol class="stepper-progress-list">
  <ng-container *ngFor="let step of steps; let i = index;">
    <li
      [class.completed]="selectedIndex > i"
      [class.disabled]="isDisabled(i)"
      [ngClass]="(selectedIndex === i) ? activeClass : ''"
    >

      <button
        (click)="selectStep(i)"
        [disabled]="isDisabled(i)"
        class="progress-button"
      >

        <ng-container
          *ngIf="step.stepLabel; else showLabelText"
          [ngTemplateOutlet]="step.stepLabel.template">
        </ng-container>

        <ng-template #showLabelText>
          {{ step.label }}
        </ng-template>

      </button>
    </li>
  </ng-container>
</ol>


<ng-content select=".messages"></ng-content>

<div *ngIf="selected?.content" class="body">
  <ng-container [ngTemplateOutlet]="selected && selected.content ? selected.content : null"></ng-container>
</div>

