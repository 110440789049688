import {Media} from "@domain/media";
import {MediaCollection} from "@domain/media/media-collection";

export class MediaCollectionImpl<M = Media> implements MediaCollection<M> {

  constructor(private readonly medias: M[]) {
  }

  public first(): M | null {
    return this.medias[0] || null;
  }

  public last(): M | null {

    if (this.isEmpty()) {
      return null;
    }
    return this.medias[this.medias.length - 1] || null;
  }

  public all(): M[] {
    return this.medias;
  }

  public isEmpty(): boolean {
    return this.medias.length === 0;
  }
}
