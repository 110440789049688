import { StringMatcher } from '@application/framework/parser/string';
import { StringParserReplacementToken } from '@application/framework/parser/string/replacement-token';
import { STRING_TEMPLATE_MATCHER_EXP } from '@application/framework/parser/string/matcher-expression';

export class AgnosticStringMatcher implements StringMatcher {
  public match(string: string): boolean {
    return STRING_TEMPLATE_MATCHER_EXP.test(string);
  }

  public getReplacement(tokens: any, key?: string): string | undefined {
    if (!this.isDefined(tokens) || typeof key !== 'string') {
      return undefined;
    }

    const keys = key.split('.');

    let value = tokens;
    for (const key of keys) {
      value = value[key];
    }
    return this.stringyfiable(value) ? value.toString() : undefined;
  }

  public replace(template: string, tokens: StringParserReplacementToken): string {
    return template.replace(STRING_TEMPLATE_MATCHER_EXP, (match: string, p: string): string => {
      const r = this.getReplacement(tokens, p);
      return typeof r === 'string' ? r : match;
    });
  }

  private isDefined(value: any): boolean {
    return typeof value !== 'undefined' && value !== null;
  }

  private stringyfiable(value: any): value is { toString: Function } {
    return value !== undefined && value !== null;
  }
}
