import { isValidObject } from '@application/framework/lib';
import { DependencyFunding } from '@domain/dependency-funding';
import { isUUID } from '@domain/lib';

export function isValidDependencyFundingIdType(id: any): id is DependencyFunding['id'] {
  return isUUID(id);
}

export function isMaybeADependencyFunding(
  dependencyFunding: any,
): dependencyFunding is { id: DependencyFunding['id'] } {
  return (
    isValidObject(dependencyFunding) &&
    'id' in dependencyFunding &&
    isValidDependencyFundingIdType((dependencyFunding as DependencyFunding).id)
  );
}
