import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { CreateFileDepositCommand, CreateFileDepositCommandResult } from '../../create-file-deposit.command';
import { FileDepositRepository } from '@application/bundles/file-deposit';

@HandleCommand({
  command: CreateFileDepositCommand,
})
export class CreateFileDepositCommandHandler implements CommandHandler<CreateFileDepositCommand> {
  constructor(private readonly repository: FileDepositRepository) {}

  public async handle(command: CreateFileDepositCommand): Promise<CreateFileDepositCommandResult> {
    return this.repository.create(command.payload);
  }
}
