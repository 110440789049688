import { SubFunding } from '@domain/funding';
import { UUID } from '@domain/lib';
import { NonRecurringTaxonomyTerm } from '@domain/health-funding/taxonomies';

export interface NonRecurringHealthFunding extends SubFunding {
  id: UUID;

  comment?: string;

  categoryId?: NonRecurringTaxonomyTerm['id'];
}
