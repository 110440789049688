import {Job} from "@domain/user/job.interface";
import {JobRepository} from "@domain/user/job.repository";
import {NotFoundError} from "rxjs";

export class JobRepositoryImpl extends JobRepository {


  private jobs: Job[] = [
    {id: 'direction', name: 'Direction'},
    {id: 'cadre_administratif', name: 'Cadre administratif'},
    {id: 'gestionnaire_administratif', name: 'Gestionnaire administratif'},
    {id: 'cadre_soin', name: 'Cadre soin'},
    {id: 'gestionnaire_soin', name: 'Gestionnaire soin'},
  ];

  public async list(): Promise<Job[]> {
    return [...this.jobs];
  }

  public async get(id: Job['id']): Promise<Job> {
    const job = this.jobs.find(j => j.id === id);

    if (job === undefined) {
      throw new NotFoundError(`Missing job with id ${id}`);
    }

    return {id: job.id, name: job.name};
  }
}
