import {Sanitizable, SanitizeFunction, SanitizerRegistry} from "@application/framework/sanitizer/sanitizer";

export class AgnosticSanitizerRegistry implements SanitizerRegistry {

  private readonly registry: Map<string, Set<SanitizeFunction<any>>> = new Map();

  public register<E, R = E>(objectType: Sanitizable<E>, sanitize: SanitizeFunction<E, R>): void {
    let sanitizers = this.registry.get(objectType.name);

    if (sanitizers === undefined) {
      sanitizers = new Set();
    }
    sanitizers.add(sanitize);

    this.registry.set(objectType.name, sanitizers);
  }

  public get<E, R = E>(object: E): Set<SanitizeFunction<E, R>> {
    const sanitizers = this.registry.get(Object.getPrototypeOf(object).constructor.name);

    return sanitizers === undefined ? new Set() : sanitizers;
  }

}
