export class ErrorNormalizer {

  public normalize<T extends Error>(e: any): T {
    let error: T;

    if (e instanceof Error) {
      error = e as T;
    } else if (typeof e === 'string') {
      error = new Error(e) as T;
    } else {
      error = new Error() as T;
    }

    return error;
  }
}
