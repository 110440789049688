import { Role } from '@domain/authorization';
import { Customer } from '@domain/customer/customer.interface';
import { InitialTraining } from '@domain/user/initial-training.interface';
import { Job } from '@domain/user/job.interface';
import { StudyLevel } from '@domain/user/study-level.interface';
import { Facility } from '@domain/facility';
import { UUID } from '@domain/lib';

export interface User {
  id: UUID;

  displayName: string;

  firstname: string;

  lastname: string;

  email: string;

  job?: Job;

  initialTraining?: InitialTraining;

  studyLevel?: StudyLevel;

  roles: Role[];

  customerId?: Customer['id'];

  facilityIds?: Array<Facility['id']>;

  blocked: boolean;

  createdAt?: Date;

  updatedAt?: Date;
}

export interface CreateUser extends Omit<User, 'id' | 'createdAt' | 'updatedAt' | 'roles'> {
  roles?: Array<Role>;
}

export interface UpdateUser extends Omit<User, 'roles' | 'createdAt' | 'updatedAt' | 'facilityIds'> {
  roles?: Array<Role>;
  facilityIds?: Array<Facility['id']>;
}

export type UpdateCurrentUser = Pick<User, 'firstname' | 'lastname'> & { password?: string };
