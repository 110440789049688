import {Common} from "@application/framework/lib";
import {
  CreateEPRDDto,
  CreateERRDDto,
  CreateLocalEPRDDto,
  CreateLocalERRDDto,
  UpdateEPRDDto,
  UpdateERRDDto,
  UpdateLocalEPRDDto,
  UpdateLocalERRDDto
} from "@application/bundles/eprd-errd";

/**
 * EPRD - ERRD
 */

export type CommonAbstractCreateDtoType = Omit<Common<CreateEPRDDto, UpdateEPRDDto>, 'completeDocument' | 'payrollDocument' | 'activityAnnexDocument' | 'financialAnnexDocument' | 'reportDocument'>;

export type CommonAbstractUpdateDtoType = Common<CreateERRDDto, UpdateERRDDto>;

export type CommonAbstractDtoType = Omit<Common<CommonAbstractCreateDtoType, CommonAbstractUpdateDtoType>, 'details'>;

/**
 *  Local EPRD - ERRD
 */

export type LocalCommonAbstractCreateDtoType = Common<CreateLocalEPRDDto, UpdateLocalEPRDDto>;

export type LocalCommonAbstractUpdateDtoType = Common<CreateLocalERRDDto, UpdateLocalERRDDto>;

export type LocalCommonAbstractDtoType = Common<LocalCommonAbstractCreateDtoType, LocalCommonAbstractUpdateDtoType>;
