import {CommandConstructor, CommandHandlerRegistry} from "@application/framework/command-query";
import {ContainerRegistry, DependenciesInjectionConfiguration} from "@application/framework/di";


export function HandleCommand(provide: { command: CommandConstructor<unknown> }): Function;
export function HandleCommand(provide: { command: CommandConstructor<unknown>[] }): Function;
export function HandleCommand(provide: { command: CommandConstructor<unknown> | CommandConstructor<unknown>[] }): ClassDecorator {

  return target => {

    if (!Array.isArray(provide.command)) {
      provide.command = [provide.command];
    }

    provide.command.forEach(command => {
      CommandHandlerRegistry.register(command, () => ContainerRegistry.defaultContainer.get(target) as any);
    });

    DependenciesInjectionConfiguration.serviceDecorator(target, {
      id: target,
      useClass: target as any
    });
  };

}
