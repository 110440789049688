import { Observable } from 'rxjs';
import { Document } from '@domain/document';

export interface DocumentGlobalPublicUrl {
  list: string;

  create: string;
}

export interface DocumentPublicUrl {
  view: string;

  edit: string;
}

export interface DocumentUrlsBuilder {
  buildUrls(): Observable<Partial<DocumentGlobalPublicUrl & DocumentPublicUrl>>;

  buildGlobalPublicUrls(): Observable<Partial<DocumentGlobalPublicUrl>>;

  buildDocumentUrls(document: Document): Observable<Partial<DocumentPublicUrl>>;
}
