import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Facility } from '@domain/facility';
import { Year } from '@domain/lib';
import { LocalCPOMFormBuilder } from '@easyhpad-ui/app/bundles/cpom/services/local-cpom-form-builder/local-cpom-form-builder';
import { v4 } from 'uuid';

@Component({
  selector: 'ehp-form-local-cpom-inputs-row',
  templateUrl: './form-local-cpom-inputs-row.component.html',
  styleUrls: ['./form-local-cpom-inputs-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormLocalCpomInputsRowComponent {
  @Input() public form!: FormGroup;

  @Input() public year: Year | undefined;

  @Input() public mode: 'create' | 'edit' = 'edit';

  @Output() public facilityChange: EventEmitter<Facility | undefined> = new EventEmitter();

  public socialBedCareControlName = LocalCPOMFormBuilder.SOCIAL_BED_CARE_KEY;

  private readonly id = v4();

  private facility: Facility | undefined;

  public get fundingFormArray(): FormArray | null {
    if (this.form) {
      return this.form.get(LocalCPOMFormBuilder.FUNDING_FORM_ARRAY_NAME) as FormArray;
    }

    return null;
  }

  public getControlId(name: string): string {
    return `${this.id}-${name}`;
  }

  public updateFacility(facility: Facility | null) {
    this.facility = facility ?? undefined;
    this.facilityChange.emit(this.facility);
  }
}
