import { CdkVirtualScrollableElement } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, Injector, OnDestroy, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslatableString } from '@application/framework/translation';
import {
  DocumentListViewContainer,
  DocumentListViewProcessor,
  DocumentListViewProcessorInstance,
} from '@easyhpad-ui/app/bundles/documents';
import { DocumentActionLink, DocumentsActions } from '@easyhpad-ui/app/bundles/documents/contracts/document-action';
import { DocumentViewResolver } from '@easyhpad-ui/app/bundles/documents/services';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-document-list-view-page',
  templateUrl: './document-list-view-page.component.html',
  styleUrls: ['./document-list-view-page.component.scss'],
  hostDirectives: [
    {
      directive: CdkVirtualScrollableElement,
    },
  ],
  host: {
    class: 'document-list-view-page',
  },
})
export class DocumentListViewPageComponent implements AfterViewInit, OnDestroy, DocumentListViewContainer {
  @ViewChild('title', { read: ViewContainerRef })
  public readonly titleContainer!: ViewContainerRef;

  @ViewChild('quickFilters', { read: ViewContainerRef })
  public readonly quickFilterContainer!: ViewContainerRef;

  @ViewChild('documents', { read: ViewContainerRef })
  public readonly documentListContainer!: ViewContainerRef;

  public readonly title$ = new Subject<Array<string | TranslatableString>>();

  public readonly actions$ = new Subject<DocumentsActions<'create'>>();

  public loading$ = new BehaviorSubject(true);

  private viewIsInit = false;

  private processorName: Type<DocumentListViewProcessor> | undefined;

  private processorInstance: DocumentListViewProcessorInstance | undefined;

  private providerChange$ = new Subject<void>();

  private subscription: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly resolver: DocumentViewResolver,
    private readonly injector: Injector,
  ) {
    this.subscription = this.route.data.subscribe(data => {
      const provider = this.resolver.resolve(data['documentType'], 'list');

      if (!provider) {
        throw new Error(`Missing Document view processor provider for ${data['documentType']} (list)`);
      }

      this.processorName = provider.processor;

      if (this.viewIsInit) {
        this.processorChange();
      }
    });
  }

  public ngOnDestroy() {
    this.providerChange$.next();
    this.processorInstance?.destroy();
    this.subscription.unsubscribe();
  }

  public ngAfterViewInit() {
    this.viewIsInit = true;

    // Prevent error for change detection
    const timer = setTimeout(() => {
      this.render();
      clearTimeout(timer);
    }, 0);
  }

  private render() {
    if (!this.processorName) {
      return;
    }

    const processor = this.injector.get(this.processorName);
    this.processorInstance = processor.buildListView(this);

    this.processorInstance.title.pipe(takeUntil(this.providerChange$)).subscribe(trails => {
      this.title$.next(trails);
    });

    this.processorInstance.actions.pipe(takeUntil(this.providerChange$)).subscribe(actions => {
      let create: DocumentActionLink | undefined;

      if (actions.create) {
        create = {
          ...actions.create,
          label: new TranslatableString('Nouvel archivage'),
          classes: 'button primary',
        };
      }

      this.actions$.next({ ...actions, create });
    });
  }

  private processorChange(): void {
    this.providerChange$.next();
    this.render();
  }
}
