import {ForbiddenError} from "@application/bundles/authorization/error";
import {DeletePMPCommand, PMPDeletedEvent, PMPDeletionFailEvent, PMPRepository} from "@application/bundles/pmp";
import {PMPAuthorizationChecker} from "@application/bundles/pmp/pmp-authorization-checker";
import {PMP_TRANSLATE_CONTEXT} from "@application/bundles/pmp/pmp.token";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {TranslatableString} from "@application/framework/translation";
import {PMP} from "@domain/pmp";


@HandleCommand({
  command: DeletePMPCommand
})
export class DeletePMPCommandHandler implements CommandHandler<DeletePMPCommand, PMP> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private repository: PMPRepository,
    private authorization: PMPAuthorizationChecker,
    private eventDispatcher: EventDispatcher,
  ) {
  }

  public async handle(command: DeletePMPCommand): Promise<PMP> {

    const {pmp} = command;
    try {
      const deleted = await this.checkAccess(pmp)
        .then(() => this.repository.delete(pmp.id));

      this.eventDispatcher.dispatch(new PMPDeletedEvent(deleted));

      return deleted;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }


  private async checkAccess(pmp: PMP): Promise<PMP> {

    if (!await this.authorization.canDelete(pmp)) {
      this.logger.error('P.M.P. deletion : Forbidden');
      throw new ForbiddenError();
    }

    return pmp;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à supprimer ce P.M.P..",
        undefined,
        PMP_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new PMPDeletionFailEvent(message));

    return error;
  }

}
