import {UserUpdateFailEvent} from "@application/bundles/user/events/user-update/user-update-fail.event";
import {UserUpdateSuccessEvent} from "@application/bundles/user/events/user-update/user-update-success.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";
import {
  CurrentUserUpdateSuccessEvent
} from "@application/bundles/user/events/user-update/current-user-update.success.event";
import {CurrentUserUpdateFailEvent} from "@application/bundles/user/events/user-update/current-user-update-fail.event";
import {CommandBus} from "@application/framework/command-query";
import {StoreCurrentUserCommand} from "@application/bundles/user";

@HasEventSubscribers()
export class OnUserUpdateSubscriber {

  constructor(
    private readonly translator: Translator,
    private readonly noticeStream: NoticeStream,
    private readonly commandBus: CommandBus
  ) {
  }

  @OnEvent({
    event: [CurrentUserUpdateSuccessEvent, UserUpdateSuccessEvent]
  })
  public async pushUpdateSuccessNotice(event: CurrentUserUpdateSuccessEvent | UserUpdateSuccessEvent) {
    const message = await this.translator.translate(
      "L'utilisateur <strong>{{name}}</strong> a été mis à jour avec succès.",
      {name: event.user.displayName}
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }


  @OnEvent({
    event: [CurrentUserUpdateFailEvent, UserUpdateFailEvent]
  })
  public async pushUpdateFailNotice(event: CurrentUserUpdateFailEvent | UserUpdateFailEvent) {

    let message = event.reason;

    if (!message) {
      message = await this.translator.translate('Un erreur est survenue lors de la mise à jour de l\'utilisateur');
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }

  @OnEvent({
    event: CurrentUserUpdateSuccessEvent
  })
  public async updateCurrentUser(event: CurrentUserUpdateSuccessEvent) {
    this.commandBus.execute(new StoreCurrentUserCommand(event.user));
  }
}
