import {
  AddCapacityAuthorizationDto
} from "@application/bundles/capacity-authorization/dtos/add-capacity-authorization.dto";
import {BuilderAssembler, BuilderInterface, BuilderPiece} from "@application/framework/builder";

import {PropertyTypeOf} from "@application/framework/lib";
import {Collection} from "@application/framework/store/collection";


export class AddCapacityAuthorizationBuilder implements BuilderInterface<AddCapacityAuthorizationDto> {


  constructor(
    private readonly piecesStorage: Collection<BuilderPiece<PropertyTypeOf<AddCapacityAuthorizationDto>, AddCapacityAuthorizationDto>>,
    private readonly assembler: BuilderAssembler<AddCapacityAuthorizationDto>
  ) {
  }

  public addAuthorizationDates(dates: BuilderPiece<AddCapacityAuthorizationDto['dates'], AddCapacityAuthorizationDto>) {
    return this.addPieces(dates);
  }


  public async getResult(): Promise<AddCapacityAuthorizationDto> {
    return this.assembler(...this.piecesStorage.list());
  }

  public reset(): void {
    this.piecesStorage.clear();
  }

  public validate(): Promise<boolean> {
    return Promise.resolve(false);
  }

  public async addPieces(pieces: BuilderPiece<PropertyTypeOf<AddCapacityAuthorizationDto>, AddCapacityAuthorizationDto>): Promise<void> {

  }


}
