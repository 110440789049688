import {Injectable} from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateCPOMFundingDto, isValidCPOMFundingIdType, UpdateCPOMFundingDto} from "@application/bundles/cpom";
import {Common} from "@application/framework/lib";
import {CPOMFunding} from "@domain/cpom";

type CommonProperties = Common<CreateCPOMFundingDto, UpdateCPOMFundingDto>;

@Injectable({
  providedIn: 'root'
})
export class CPOMFundingFormBuilder {


  constructor(private readonly formBuilder: FormBuilder) {
  }


  public buildCreateFundingForm(funding?: CPOMFunding): FormGroup<Record<keyof CreateCPOMFundingDto, FormControl>> {
    return this.formBuilder.group(this.getCreateFundingControls(funding));
  }

  public buildUpdateFundingForm(funding?: CPOMFunding): FormGroup<Record<keyof UpdateCPOMFundingDto, FormControl>> {
    return this.formBuilder.group({
      ...this.getUpdateFundingControls(funding),
    });
  }

  public addCreateFunding(control: FormArray): void {
    control.push(this.formBuilder.group(this.getCreateFundingControls()));
  }

  public addUpdateFunding(control: FormArray): void {
    control.push(this.formBuilder.group(this.getUpdateFundingControls()));
  }

  public deleteFundingRow(control: FormArray, index: number): void {
    control.removeAt(index);
  }

  public getCreateFundingControls(funding?: any): Record<keyof CreateCPOMFundingDto, FormControl> {

    return {
      name: new FormControl(funding?.name, [Validators.required]),
      amount: new FormControl(funding?.amount, [Validators.required, Validators.min(0)]),
      comment: new FormControl(funding?.comment)
    }
  }

  public getUpdateFundingControls(funding?: any): Record<keyof UpdateCPOMFundingDto, FormControl> {

    return {
      ...this.getCreateFundingControls(funding),
      id: new FormControl(funding?.id)
    }
  }


  public serializeFormValue(funding?: Partial<CPOMFunding>): Record<keyof CommonProperties, any> {

    return {
      name: funding?.name || '',
      amount: funding?.amount || 0,
      comment: funding?.comment || undefined
    }

  }

  public deserializeFormValues<T extends CreateCPOMFundingDto | UpdateCPOMFundingDto>(values: Record<keyof CreateCPOMFundingDto | keyof UpdateCPOMFundingDto, any>): T {

    const deserialized: Partial<Record<keyof CreateCPOMFundingDto | keyof UpdateCPOMFundingDto, any>> = {
      name: values.name,
      amount: values.amount,
      comment: values?.comment,
    };

    if (('id' in values) && isValidCPOMFundingIdType(values.id)) {
      deserialized.id = values.id;
    }

    return deserialized as T;
  }
}
