import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ResizableGrabberComponent} from './components/resizable-grabber/resizable-grabber.component';
import {ResizableGrabberDirective} from './directives/resizable-grabber.directive';


@NgModule({
  declarations: [
    ResizableGrabberDirective,
    ResizableGrabberComponent
  ],
  exports: [
    ResizableGrabberDirective,
    ResizableGrabberComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class ResizableModule {
}
