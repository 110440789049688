import {DialogModule} from "@angular/cdk/dialog";
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormModule} from "@easyhpad-ui/app/library/form/form.module";
import {
  TableContainerComponent
} from "@easyhpad-ui/app/library/table/components/table-container/table-container.component";
import {TranslationModule} from "../../framework/translation/translation.module";
import {
  SelectableTableColumnsDialogComponent
} from './components/selectable-table-columns-dialog/selectable-table-columns-dialog.component';
import {
  SelectableTableColumnsComponent
} from './components/selectable-table-columns/selectable-table-columns.component';
import {SortableLabelComponent} from './sortable-label/sortable-label.component';


@NgModule({
  declarations: [
    SelectableTableColumnsDialogComponent,
    SelectableTableColumnsComponent,
    TableContainerComponent,
    SortableLabelComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    FormModule,
    DialogModule
  ],
  exports: [
    SelectableTableColumnsComponent,
    TableContainerComponent,
    SortableLabelComponent
  ]
})
export class TableModule {
}
