import {ViewContainerRef} from "@angular/core";
import {POSITION} from "@application/framework/lib";


export enum MediaViewerMode {
  DIALOG = 'dialog',
  PANEL = 'panel'
}

export interface MediaViewerOptions {

  container: ViewContainerRef;

  mode?: MediaViewerMode;

  position?: POSITION;

  acceptFullscreen?: boolean;

  acceptClosable?: boolean;
}
