import { Attribute, CRUD_ACTIONS } from '@application/bundles/authorization';
import { ForbiddenError } from '@application/bundles/authorization/error';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import {
  GetGMPQuery,
  GMPRepository,
  GMPUpdatedEvent,
  GMPUpdateFailEvent,
  UpdateGMPCommand,
  UpdateGMPDto,
} from '@application/bundles/gmp';
import { GMPAuthorizationChecker } from '@application/bundles/gmp/gmp-authorization-checker';
import { GMP_FEATURE, GMP_TRANSLATE_CONTEXT } from '@application/bundles/gmp/gmp.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { Facility } from '@domain/facility';
import { GMP } from '@domain/gmp';
import { isLocalMedia, isMedia, MediaBucket, TemporaryMedia } from '@domain/media';

@HandleCommand({
  command: UpdateGMPCommand,
})
export class UpdateGMPCommandHandler implements CommandHandler<UpdateGMPCommand, GMP> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: GMPRepository,
    private readonly authorization: GMPAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: UpdateGMPCommand): Promise<GMP> {
    const { id, gmp } = command;
    try {
      const updated = await this.sanitize(gmp)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(id, dto))
        .then(dto => this.updateGMP(id, dto));

      this.eventDispatcher.dispatch(new GMPUpdatedEvent(updated));

      return updated;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(id: GMP['id'], dto: UpdateGMPDto): Promise<UpdateGMPDto> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(dto.facilityId));
    const gmp = await this.queryBus.request<GMP>(new GetGMPQuery(id));

    const attributes: Attribute[] = [{ feature: GMP_FEATURE, value: CRUD_ACTIONS.UPDATE }];

    if (!(await this.authorization.canUpdate(gmp))) {
      this.logger.error('G.M.P. update : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: UpdateGMPDto): Promise<UpdateGMPDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('G.M.P. update : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdateGMPDto): Promise<UpdateGMPDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('G.M.P. update : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à mettre à jour ce G.M.P..",
        undefined,
        GMP_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new GMPUpdateFailEvent(message));

    return error;
  }

  private async updateGMP(id: GMP['id'], dto: UpdateGMPDto): Promise<GMP> {
    const current = await this.queryBus.request(new GetGMPQuery(id));
    let pv: TemporaryMedia | undefined;

    if (dto.pvId !== current.pvId || isLocalMedia(dto.pv)) {
      if (dto.pv && isLocalMedia(dto.pv)) {
        pv = await this.mediaBucket.add(dto.pv);
        dto.pvId = pv.id;
      } else if (dto.pv && isMedia(dto.pv) && dto.pv.id !== current.pvId) {
        dto.pvId = dto.pv.id;
      }
    }

    try {
      return await this.repository.update(id, dto);
    } catch (e) {
      if (pv !== undefined) {
        await pv.markForDeletion();
      }

      throw e;
    }
  }
}
