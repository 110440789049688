import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import {
  EhpadDemainFundingTableComponent,
  ExceptionalFundingTableComponent,
  HealthFundingLinkComponent,
  HealthFundingListComponent,
  HealthFundingListFiltersComponent,
  HealthFundingListItemComponent,
  HealthFundingNameComponent,
  HealthTaxonomyLabelComponent,
  HeathFundingFundingTabsComponent,
  NonRecurringFundingTableComponent,
  RecurringFundingTableComponent,
} from './components';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { EhpadDemainUiModule } from '@easyhpad-ui/app/bundles/ehpad-demain/modules/ui/ehpad-demain-ui.module';
import { FacilityUiModule } from '@easyhpad-ui/app/bundles/facility/modules/ui/facility-ui.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { FacilityFormModule } from '@easyhpad-ui/app/bundles/facility/modules/form/facility-form.module';
import { FilterModule } from '@easyhpad-ui/app/library/filter/filter.module';
import { HealthAllocationCalculatorComponent } from './components/health-allocation-calculator/health-allocation-calculator.component';

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    ReusableComponentsModule,
    TooltipModule,
    TabViewModule,
    EhpadDemainUiModule,
    FacilityUiModule,
    FormModule,
    FacilityFormModule,
    FilterModule,
  ],
  declarations: [
    HealthFundingNameComponent,
    HealthFundingLinkComponent,
    HealthFundingListComponent,
    HealthFundingListItemComponent,
    HeathFundingFundingTabsComponent,
    RecurringFundingTableComponent,
    NonRecurringFundingTableComponent,
    EhpadDemainFundingTableComponent,
    ExceptionalFundingTableComponent,
    HealthFundingListFiltersComponent,
    HealthAllocationCalculatorComponent,
    HealthTaxonomyLabelComponent,
  ],
  exports: [
    HealthFundingNameComponent,
    HealthFundingLinkComponent,
    HealthFundingListComponent,
    HeathFundingFundingTabsComponent,
    RecurringFundingTableComponent,
    NonRecurringFundingTableComponent,
    EhpadDemainFundingTableComponent,
    ExceptionalFundingTableComponent,
    HealthFundingListFiltersComponent,
    HealthAllocationCalculatorComponent,
    HealthTaxonomyLabelComponent,
  ],
})
export class HealthFundingUiModule {}
