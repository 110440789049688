import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {Component, Inject} from '@angular/core';
import {InspectionReport} from "@domain/capacity-authorization";
import {InspectionReportEditDialogData} from "./inspection-report-edit-dialog.interface";

@Component({
  selector: 'ehp-dialog-inspection-report-edit',
  templateUrl: './inspection-report-edit-dialog.component.html',
  styleUrls: ['./inspection-report-edit-dialog.component.scss']
})
export class InspectionReportEditDialogComponent {

  public title: string = '';

  constructor(
    @Inject(DIALOG_DATA) public readonly data: InspectionReportEditDialogData,
    private readonly dialogRef: DialogRef,
  ) {
    if (!this.data || !this.data.authorization) {
      this.dialogRef.close();
      return;
    }

    if (this.data.title) {
      this.title = this.data.title;
    } else {
      this.title = this.data.mode === 'add' ? "Ajouter un nouveau rapport d'inspection" : "Modifier le rapport d'inspection"
    }
  }

  public created(report: InspectionReport) {
    this.dialogRef.close(report);
  }

  public dismiss() {
    this.dialogRef.close();
  }
}
