import {Permission, PermissionCollection} from "@domain/authorization";

export class AgnosticPermissionCollection implements PermissionCollection {


  constructor(private readonly permissions: Permission[]) {
  }

  public has<F = string, E = string>(feature: F, type: E): boolean {
    const permission = this.permissions.find(permission => permission.feature === feature && permission.type === type);

    return !!permission;

  }

  public all(): Array<Permission> {
    return this.permissions;
  }


}
