import { Role } from '@domain/authorization';
import { Customer } from '@domain/customer';
import { Facility } from '@domain/facility';
import { StudyLevel, User } from '@domain/user';
import { InitialTraining } from '@domain/user/initial-training.interface';
import { Job } from '@domain/user/job.interface';

export class UserImpl implements User {
  public id!: User['id'];

  public firstname!: string;

  public lastname!: string;

  public email!: string;

  public roles!: Role[];

  public customerId?: Customer['id'];

  public facilityIds?: Array<Facility['id']>;

  public job?: Job;

  public initialTraining?: InitialTraining;

  public studyLevel?: StudyLevel;

  public createdAt?: Date;

  public updatedAt?: Date;

  public blocked: boolean = false;

  public get displayName(): string {
    return `${this.firstname} ${this.lastname}`.trim();
  }

  constructor(values?: Partial<User>) {
    if (values) {
      if (values.id !== undefined) {
        this.id = values.id;
      }
      if (values.firstname !== undefined) {
        this.firstname = values.firstname;
      }
      if (values.lastname !== undefined) {
        this.lastname = values.lastname;
      }

      if (values.email !== undefined) {
        this.email = values.email;
      }
      if (values.customerId !== undefined) {
        this.customerId = values.customerId;
      }
      if (values.facilityIds !== undefined) {
        this.facilityIds = values.facilityIds;
      }
      if (values.initialTraining !== undefined) {
        this.initialTraining = values.initialTraining;
      }
      if (values.job !== undefined) {
        this.job = values.job;
      }
      if (values.roles !== undefined) {
        this.roles = values.roles;
      }
      if (values.studyLevel !== undefined) {
        this.studyLevel = values.studyLevel;
      }

      if (values.blocked !== undefined) {
        this.blocked = values.blocked;
      }

      this.createdAt = values.createdAt;
      this.updatedAt = values.updatedAt;
    }
  }
}
