import { AbstractEasyFundingAmountRepository } from '@implementations/bundles/backend/easyhpad/repositories/funding/abstract-easy-funding-amount.repository';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { AccommodationFundingAmountRepository } from '@application/bundles/funding';
import { EasyAccommodationFunding } from '@implementations/bundles/backend/easyhpad';
import { Provide } from '@application/framework/di';
import { EasyAccommodationFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers/accommodation-funding/easy-accommodation-funding.transformer';

export class EasyAccommodationFundingStatsRepository
  extends AbstractEasyFundingAmountRepository<AccommodationFunding, EasyAccommodationFunding>
  implements AccommodationFundingAmountRepository
{
  @Provide() private readonly entityTransformer!: EasyAccommodationFundingTransformer;

  constructor() {
    super('funding/accommodations/amounts', {
      from: input => this.entityTransformer.transform(input),
      to: output => this.entityTransformer.reverseTransform(output),
      create: input => this.entityTransformer.transformCreate(input),
      update: input => this.entityTransformer.transformUpdate(input),
    });
  }
}
