import { PhoneNumber } from '@domain/phone/phone.interface';

export class PhonePropertySanitizer<Property extends string> {
  constructor(private readonly propertyName: Property) {
    this.sanitize = this.sanitize.bind(this);
  }

  public async sanitize<O = any>(object: O & Partial<Record<Property, PhoneNumber | null | undefined>>): Promise<O> {
    if (object.hasOwnProperty(this.propertyName)) {
      const property = object[this.propertyName];

      if (property === undefined || (property !== null && !property.number)) {
        // @ts-ignore
        object[this.propertyName] = undefined;
      }
    }

    return object;
  }
}
