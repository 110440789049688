import {
  CreateCPOMFundingDto,
  CreateLocalCPOMDto,
  StoredCPOM,
  StoredLocalCPOM,
  TariffOptionCollection,
  UpdateCPOMFundingDto,
  UpdateLocalCPOMDto,
} from '@application/bundles/cpom';
import { Service } from '@application/framework/di';
import { Common } from '@application/framework/lib';
import { Transformer } from '@application/framework/core';
import { LocalCPOM, TariffOption } from '@domain/cpom';
import { EasyCPOMDetail, EasyCreateCPOMDetail, EasyUpdateCPOMDetail } from '@implementations/bundles/backend/easyhpad';
import { EasyCPOMFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers/cpom/easy-cpom-funding.transformer';
import { LocalCPOMImpl } from '@implementations/bundles/cpom';
import { AgnosticFundingCollection } from '@application/bundles/funding';

type CommonProperties = Common<LocalCPOM, Common<CreateLocalCPOMDto, UpdateLocalCPOMDto>>;
type EasyCommonProperties = Omit<
  Common<EasyCPOMDetail, Common<EasyCreateCPOMDetail, EasyUpdateCPOMDetail>>,
  'fundings'
>;

@Service()
export class EasyCPOMDetailsTransformer implements Transformer<EasyCPOMDetail, LocalCPOM> {
  constructor(
    private readonly tariffOptions: TariffOptionCollection,
    private readonly fundingTransformer: EasyCPOMFundingTransformer,
  ) {}

  public transform(value: EasyCPOMDetail): StoredLocalCPOM {
    const defaults: Omit<StoredLocalCPOM, 'start' | 'end' | 'year' | 'document' | 'facility'> = {
      id: value.id,
      bedSocialCare: typeof value.bed_social_care === 'number' ? value.bed_social_care : undefined,
      facilityId: value.facilityId,
      funding: new AgnosticFundingCollection(
        value.fundings ? value.fundings.map(funding => this.fundingTransformer.transform(funding)) : [],
      ),
      parent: undefined,
      tariffOption: this.tariffOptions.get(value.option) as TariffOption,
    };

    return new LocalCPOMImpl(defaults);
  }

  public reverseTransform(value: StoredLocalCPOM): EasyCPOMDetail {
    return {
      id: value.id,
      facilityId: value.facilityId,
      cpom_id: value.parent && (value.parent as StoredCPOM).id ? (value.parent as StoredCPOM).id : '',
      fundings: Array.from(value.funding).map(funding => this.fundingTransformer.reverseTransform(funding)),
      option: value.tariffOption.type,
    };
  }

  public reverseTransformCreate(value: CreateLocalCPOMDto): EasyCreateCPOMDetail {
    return {
      ...this.reverseTransformCommonProperties(value),
      facilityId: value.facilityId,
      fundings: value.funding.map(funding => this.fundingTransformer.transformCreate(funding)),
    };
  }

  public reverseTransformUpdate(value: UpdateLocalCPOMDto): EasyUpdateCPOMDetail {
    const funding = value.funding.map((funding: CreateCPOMFundingDto | UpdateCPOMFundingDto) => {
      if ('id' in funding) {
        return this.fundingTransformer.reverseTransformUpdate(funding);
      }
      return this.fundingTransformer.reverseTransformCreate(funding);
    });

    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
      facilityId: value.facilityId,
      fundings: funding,
    };
  }

  private reverseTransformCommonProperties(value: CommonProperties): EasyCommonProperties {
    return {
      bed_social_care: value.bedSocialCare,
      facilityId: '',
      option: value.tariffOption.type,
    };
  }
}
