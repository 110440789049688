import {Query} from '@application/framework/command-query';
import {Customer} from '@domain/customer';

/**
 * List all the facilities that the user can view. If user is admin,
 * customer id can be used to restrict to a single user.
 */
export class ListAuthorizedFacilitiesQuery implements Query {
  constructor(
    public readonly customerId?: Customer['id'],
    public readonly includeInactive?: boolean,
  ) {}
}
