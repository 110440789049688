import {CUSTOMER_TRANSLATE_CONTEXT} from "@application/bundles/customer/customer.token";
import {CustomerDeletedEvent} from "@application/bundles/customer/events/delete-customer/customer-deleted.event";
import {
  CustomerDeletionFailEvent
} from "@application/bundles/customer/events/delete-customer/customer-deletion-fail.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";

@HasEventSubscribers()
export class OnCustomerDeletionEventSubscriber {

  constructor(
    private readonly noticeStream: NoticeStream,
    private readonly translator: Translator
  ) {
  }

  @OnEvent({
    event: CustomerDeletedEvent
  })
  public async pushNoticeOnCustomerDeletion(event: CustomerDeletedEvent): Promise<void> {

    const message = await this.translator.translate(
      "Le client <strong>{{name}}</strong> a été supprimé avec succès.",
      {name: event.customer.name},
      CUSTOMER_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({message, type: NoticeType.SUCCESS});
  }

  @OnEvent({
    event: CustomerDeletionFailEvent
  })
  public async pushNoticeOnCustomerDeletionFail(event: CustomerDeletionFailEvent): Promise<void> {
    let message = event.reason;

    if (!message) {
      message = await this.translator.translate(
        "Une erreur est survenue lors de la suppression du client.",
        undefined,
        CUSTOMER_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({message, type: NoticeType.ERROR});
  }

}
