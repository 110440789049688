import {PhoneValidator} from "@application/bundles/phone/validator/phone.validator";
import {Container} from "@application/framework/di/container";
import {
  IsValidPhoneNumber
} from "@implementations/bundles/phone/adapters/class-validator/is-valid-phone-number/is-valid-phone-number";
import {useContainer} from "class-validator";

export class PhoneBundle {

  private static instance: PhoneBundle

  private constructor(private readonly container: Container) {

    this.container.bind(IsValidPhoneNumber, IsValidPhoneNumber, PhoneValidator);
    useContainer(container, {fallbackOnErrors: true});
  }

  public static build(container: Container): PhoneBundle {
    if (!PhoneBundle.instance) {
      PhoneBundle.instance = new PhoneBundle(container);
    }
    return PhoneBundle.instance;
  }
}
