import { NonRecurringTaxonomyTerm } from '@domain/health-funding';
import { UUID } from '@domain/lib';

export interface EasyNonRecurringHealthFunding {
  id: UUID;

  categoryId: NonRecurringTaxonomyTerm['id'] | null | undefined;

  amount: number;

  comment: string | null | undefined;
}

export interface CreateEasyNonRecurringHealthFundingDto extends Omit<EasyNonRecurringHealthFunding, 'id'> {}

export interface UpdateEasyNonRecurringHealthFundingDto extends EasyNonRecurringHealthFunding {}
