import { Component, Input } from '@angular/core';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { DocumentList } from '@easyhpad-ui/app/library/document';
import { Skeleton } from '@easyhpad-ui/app/bundles/reusable-components/components/skeleton';

@Component({
  selector: 'ehp-accommodation-funding-list',
  templateUrl: './accommodation-funding-list.component.html',
  styleUrls: ['./accommodation-funding-list.component.scss'],
  host: {
    class: 'document-list',
  },
})
export class AccommodationFundingListComponent {
  @Input('documents') public list!: DocumentList<AccommodationFunding>;

  public SKELETON_TYPE = Skeleton.DOCUMENT_LIST;
}
