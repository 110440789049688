import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  BALANCE_SHEET_STORE_KEY,
  BalanceSheetEffects,
  balanceSheetReducer,
} from '@easyhpad-ui/app/bundles/balance-sheet/store';
import { BalanceSheetUrlsProvider } from '@easyhpad-ui/app/bundles/balance-sheet/providers';
import { BalanceSheetRepository } from '@application/bundles/balance-sheet';
import { EasyBalanceSheetRepository } from '@implementations/bundles/backend/easyhpad/repositories/balance-sheet/balance-sheet.repository';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(BALANCE_SHEET_STORE_KEY, balanceSheetReducer),
    EffectsModule.forFeature([BalanceSheetEffects]),
  ],
  providers: [
    BalanceSheetUrlsProvider,
    {
      provide: BalanceSheetRepository,
      useClass: EasyBalanceSheetRepository,
    },
  ],
})
export class BalanceSheetModule {}
