import {Command} from "@application/framework/command-query";
import {ActivityAnnex} from "@domain/activity-annex";

export class OpenActivityAnnexDeletionDialogCommand implements Command<void> {

  constructor(
    public readonly document: ActivityAnnex,
    public readonly onDeletion?: (document: ActivityAnnex) => void
  ) {
  }
}
