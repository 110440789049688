import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SecurityContext,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatableString, TranslationReplacements, Translator } from '@application/framework/translation';
import { Stringifiable } from '@domain/lib';

@Component({
  selector: 'ehp-translatable-string',
  templateUrl: './translatable-string.component.html',
  styleUrls: ['./translatable-string.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TranslatableStringComponent implements OnChanges {
  @Input() public value: string | TranslatableString | Stringifiable | undefined;

  @Input() public replacement: TranslationReplacements | undefined;

  @Input() public context: string | undefined;

  public original: string | undefined;

  public translation: string | undefined;

  public isTranslated: boolean = false;

  public constructor(
    private translator: Translator,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef,
    private _sanitizer: DomSanitizer,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && changes['value'].currentValue !== this.original) {
      this.original = changes['value'].currentValue;
      this.displayContent(this.original ?? '');
      this.markForTranslation();
    }
  }

  private markForTranslation(): void {
    if (this.original === undefined) {
      return;
    }

    this.isTranslated = false;

    this.translator.translate(this.original, this.replacement, this.context).then(translation => {
      this.translation = translation;
      this.isTranslated = true;
      this.displayContent(translation ?? '');
      this.ref.markForCheck();
    });
  }

  private displayContent(content: string) {
    this.elementRef.nativeElement.innerHTML = this._sanitizer.sanitize(SecurityContext.HTML, content);
  }
}
