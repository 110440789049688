import { Injectable } from '@angular/core';
import { SpreadsheetViewer } from '@easyhpad-ui/app/bundles/media/spreadsheet/spreadsheet.interface';
import { WindowRef } from '@easyhpad-ui/app/framework/window';
import { LuckySpreadsheetViewer } from '@easyhpad-ui/app/bundles/media/spreadsheet/lucky-spreadsheet.adapter';
import { LocalMedia, Media } from '@domain/media';
import { FILE_TYPES } from '@application/bundles/media';

@Injectable({
  providedIn: 'root',
})
export class SpreadsheetFactory {
  private external: Record<string, { type: 'script' | 'style'; src: string; loaded: boolean }> = {
    xspreadsheet: {
      src: '//cdn.sheetjs.com/xspreadsheet/xlsxspread.min.js',
      loaded: false,
      type: 'script',
    },
    luckysheet_plugin: {
      src: '//cdn.jsdelivr.net/npm/luckysheet/dist/plugins/js/plugin.js',
      loaded: false,
      type: 'script',
    },
    luckysheet: {
      src: '//cdn.jsdelivr.net/npm/luckysheet/dist/luckysheet.umd.js',
      loaded: false,
      type: 'script',
    },
    luckysheet_css_plugins_css: {
      src: '//cdn.jsdelivr.net/npm/luckysheet/dist/plugins/css/pluginsCss.css',
      loaded: false,
      type: 'style',
    },

    luckysheet_css_plugins: {
      src: '//cdn.jsdelivr.net/npm/luckysheet/dist/plugins/plugins.css',
      loaded: false,
      type: 'style',
    },

    luckysheet_css: {
      src: '//cdn.jsdelivr.net/npm/luckysheet/dist/css/luckysheet.css',
      loaded: false,
      type: 'style',
    },

    luckysheet_icons: {
      src: '//cdn.jsdelivr.net/npm/luckysheet/dist/assets/iconfont/iconfont.css',
      loaded: false,
      type: 'style',
    },
    luckysheet_excel: {
      src: '//cdn.jsdelivr.net/npm/luckyexcel/dist/luckyexcel.umd.js',
      loaded: false,
      type: 'script',
    },
  };

  constructor(private readonly windowRef: WindowRef) {}

  public support(media: LocalMedia | Media): boolean {
    return media.mimeType === FILE_TYPES.EXCEL_XLSM || media.mimeType === FILE_TYPES.EXCEL_XLSX;
  }

  public async createSpreadSheetViewer(container: HTMLElement): Promise<SpreadsheetViewer> {
    return this.createLuckySpreadsheetViewer(container);
  }

  private async createLuckySpreadsheetViewer(container: HTMLElement): Promise<SpreadsheetViewer> {
    await this.load(
      'luckysheet_css_plugins_css',
      'luckysheet_css_plugins',
      'luckysheet_css',
      'luckysheet_icons',
      'luckysheet_plugin',
      'luckysheet',
      'luckysheet_excel',
    );
    return new LuckySpreadsheetViewer(container, this.windowRef.nativeWindow as Window);
  }

  private load(...names: string[]) {
    const promises: any[] = [];
    names.forEach(name => promises.push(this.loadExternal(name)));
    return Promise.all(promises);
  }

  private loadExternal(name: string) {
    if (!this.external[name]) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const file = this.external[name];

      if (file.loaded) {
        resolve(file);
        return;
      }

      let tag: HTMLLinkElement | HTMLScriptElement;

      if (file.type === 'script') {
        tag = document.createElement('script');
        tag.type = 'text/javascript';
        tag.src = file.src;
      } else {
        tag = document.createElement('link');
        tag.rel = 'stylesheet';
        tag.href = file.src;
      }

      tag.onload = () => {
        file.loaded = true;
        resolve(file);
      };

      tag.onerror = (error: any) => {
        console.error(error);
        resolve(file);
      };

      document.getElementsByTagName('head')[0].appendChild(tag);
    });
  }
}
