import {
  CreateHealthFundingDto,
  HealthFundingDtoFactory,
  UpdateHealthFundingDto
} from "@application/bundles/health-funding";
import {CreateHealthFundingDtoImpl, UpdateHealthFundingDtoImpl} from "@implementations/bundles/health-funding/dto";

export class HealthFundingDtoFactoryImpl implements HealthFundingDtoFactory {


  public buildCreateDtoFromUnsafeValues(values: Partial<CreateHealthFundingDto>): CreateHealthFundingDto {
    return new CreateHealthFundingDtoImpl(values);
  }

  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdateHealthFundingDto>): UpdateHealthFundingDto {
    return new UpdateHealthFundingDtoImpl(values);
  }

}
