import {
  DependencyFunding,
  isTemporaryDependencyFundingMode,
  TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  TEMPORARY_DEPENDENCIES_FUNDING_MODES,
  TemporaryDependencyFunding,
  TemporaryDependencyFundingMode
} from "@domain/dependency-funding";
import {isValidObject} from "@application/framework/lib";
import {isValidTemporaryDependencyFundingIdType} from "@application/bundles/dependency-funding";
import {isNegativeFundingAmount} from "@domain/funding";

export class TemporaryDependencyFundingImpl<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES, V> implements TemporaryDependencyFunding<M, V> {

  public id!: number;

  public mode!: TemporaryDependencyFundingMode<M>;

  public activityAllowance: number | undefined;

  public parent!: DependencyFunding;

  private readonly _value!: V;

  constructor(defaults?: Partial<Omit<TemporaryDependencyFunding<M, V>, 'value'> & { value: V, parent: DependencyFunding }>) {

    if (isValidObject(defaults)) {

      if (isValidTemporaryDependencyFundingIdType(defaults?.id)) {
        this.id = defaults?.id;
      }

      if (isTemporaryDependencyFundingMode(defaults?.mode)) {
        this.mode = defaults?.mode;
      }

      if (defaults?.value !== undefined) {
        this._value = defaults?.value;
      }

      if (isNegativeFundingAmount(defaults?.activityAllowance)) {
        this.activityAllowance = defaults?.activityAllowance;
      }

      if (defaults?.parent) {
        this.parent = defaults.parent;
      }

    }
  }

  public value(): V {

    if (this.mode.key === TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES.SAME_PERMANENT) {
      if (!this.parent) {
        throw new Error(`Missing parent property in ${this.constructor.name} (${this.id})`);
      }

      return this.parent.permanent as V;

    }

    return this._value;
  }

}
