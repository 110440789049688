import {HasHealthFundingForYearQuery, HealthFundingRepository} from "@application/bundles/health-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";

@HandleQuery({
  query: HasHealthFundingForYearQuery
})
export class HasHealthFundingForYearQueryHandler implements QueryHandler<HasHealthFundingForYearQuery, boolean> {

  constructor(private readonly repository: HealthFundingRepository) {
  }

  public async handle(query: HasHealthFundingForYearQuery): Promise<boolean> {
    throw new Error(`handle method not implemented in ${this.constructor.name}`);
  }
}
