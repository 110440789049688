import {AuthentificationToken} from "./authentification-token";

export type AuthentificationStrategyCredentials<T = any> = {
  credentials: T;
}

export type StrategyResult<S = unknown> = {
  success: boolean;
  result: S;
}

export type AuthentificationStrategy<S = unknown, F = unknown> = {

  /**
   * Run login strategy
   */
  login(): Promise<StrategyResult<S>>;

  /**
   * Run login strategy
   * @param credentials
   */
  login(credentials: AuthentificationStrategyCredentials): Promise<StrategyResult<S>>;

  /**
   * Run logout strategy
   */
  logout(): Promise<StrategyResult<S>>;

  /**
   * Run logout strategy
   * @param credentials
   */
  logout(credentials: AuthentificationStrategyCredentials): Promise<StrategyResult<S>>;

  
  /**
   * Create authentification token from strategy result
   * @param result
   */
  buildToken(result: StrategyResult): Promise<AuthentificationToken>;
}
