import {Component, EventEmitter, Input, Output} from '@angular/core';
import {isValidYear, Year} from "@domain/lib";

@Component({
  selector: 'ehp-year-filter',
  templateUrl: './year-filter.component.html',
  styleUrls: ['./year-filter.component.scss']
})
export class YearFilterComponent {

  @Input() public start: number = new Date().getFullYear();

  @Input() public label: string = '';
  
  @Output() public onChange: EventEmitter<Year | null> = new EventEmitter();

  public year: Year | null | undefined;

  public yearHasChange(year: Year | null | undefined) {

    if (year === null || year === undefined) {
      year = null;
    }

    if (isValidYear(year) || year === null) {
      this.onChange.emit(year);
    }
  }
}
