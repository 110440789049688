import {DocumentListParams, DocumentSearchParamsFactory, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export interface ActivityAnnexDocumentParams {
}


export interface SerializedActivityAnnexSearchParams extends SerializedDocumentParams<ActivityAnnexDocumentParams> {
}


export abstract class ActivityAnnexSearchParams extends DocumentListParams implements Serializable<SerializedActivityAnnexSearchParams> {

}


export abstract class ActivityAnnexSearchParamsFactory extends DocumentSearchParamsFactory<ActivityAnnexSearchParams, SerializedActivityAnnexSearchParams> {

}
