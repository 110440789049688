import {BILLING_MODE, BillingMode} from "@domain/customer";
import {values} from "@domain/lib/object/object";
import {isValidObject} from "@application/framework/lib";

export function isValidBillingModeValue(value: any): value is BILLING_MODE {
  return values(BILLING_MODE).includes(value);
}


export function isValidBillingMode(value: any): value is BillingMode {
  return isValidObject(value)
    && isValidBillingModeValue((value as BillingMode).mode)
    && typeof (value as BillingMode).label === 'string';
}
