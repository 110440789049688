import { v4 } from 'uuid';
import { SpreadsheetPreview, SpreadsheetViewer } from './spreadsheet.interface';

interface LuckyJSONData {
  sheets: any[];

  info: {
    appversion: string;
    company: string;
    createdTime: string;
    creator: string;
    lastmodifiedby: string;
    modifiedTime: string;
    name: string;
  };
}

export interface LuckyWorkSheet {
  name: string;
  color: string; //Worksheet color
  config: any; //Table row height, column width, merged cells, borders, hidden rows and other settings
  index: string; //Worksheet index
  chart: []; //Chart configuration
  status: string; //Activation status
  order: string; //The order of the worksheet
  hide: number; //whether to hide
  column: number; //Number of columns
  row: number; //number of rows
  celldata: []; //Original cell data set
  visibledatarow: []; //The position of all rows
  visibledatacolumn: []; //The position of all columns
  ch_width: number;
  rh_height: number; //The height of the worksheet area
  scrollLeft: number; //Left and right scroll bar position
  scrollTop: number; //Up and down scroll bar position
  luckysheet_select_save: []; //selected area
  luckysheet_conditionformat_save: {}; //Conditional format
  calcChain: []; //Formula chain
  isPivotTable: boolean; //Whether to pivot table
  pivotTable: {}; //Pivot table settings
  filter_select: null; //Filter range
  filter: null; //Filter configuration
  luckysheet_alternateformat_save: []; //Alternate colors
  luckysheet_alternateformat_save_modelCustom: []; //Customize alternate colors
}

interface LuckySpreadsheetRightClickOptions {
  copy?: boolean; // copy
  copyAs?: boolean; // copy as
  paste?: boolean; // paste
  insertRow?: boolean; // insert row
  insertColumn?: boolean; // insert column
  deleteRow?: boolean; // delete the selected row
  deleteColumn?: boolean; // delete the selected column
  deleteCell?: boolean; // delete cell
  hideRow?: boolean; // hide the selected row and display the selected row
  hideColumn?: boolean; // hide the selected column and display the selected column
  rowHeight?: boolean; // row height
  columnWidth?: boolean; // column width
  clear?: boolean; // clear content
  matrix?: boolean; // matrix operation selection
  sort?: boolean; // sort selection
  filter?: boolean; // filter selection
  chart?: boolean; // chart generation
  image?: boolean; // insert picture
  link?: boolean; // insert link
  data?: boolean; // data verification
  cellFormat?: boolean; // Set cell format
}

interface LuckySpreadsheetOptions {
  container?: string;

  data?: any[];

  lang?: string;

  showinfobar?: boolean;

  showtoolbarConfig?: any;

  allowUpdate?: boolean;
  /**
   * Automatically format numbers with more than 4 digits into "billion format", for example: true or "true" or "TRUE"
   */
  autoFormatw?: boolean;

  showsheetbar?: boolean;

  showsheetbarConfig?: {
    add?: boolean; //Add worksheet
    menu?: boolean; //Worksheet management menu
    sheet?: boolean; //Worksheet display
  };

  enableAddRow?: boolean;
  cellRightClickConfig?: LuckySpreadsheetRightClickOptions;

  hook?: any;
}

interface Luckysheet {
  resize(): void;

  getCellValue(row: number, column: number, setting?: any): string | number | Object;

  setCellValue(row: number, column: number, value: any, setting?: any): void;

  /**
   * Switch to another worksheet
   * @param index
   */
  setSheetActive(index: number): void;
}

class LuckySpreadsheetPreview implements SpreadsheetPreview {
  private instance!: Luckysheet;
  private editMap: Map<string, any> = new Map();

  private get preview(): Luckysheet | null {
    if ('luckysheet' in window) {
      // @ts-ignore
      return window.luckysheet;
    }

    return null;
  }

  private get id(): string {
    return this.container.id;
  }

  constructor(
    public readonly container: HTMLElement,
    private readonly data: LuckyJSONData,
  ) {
    this.data.sheets.forEach((s) => (s.frozen = true));
    this.createLuckysheet();
  }

  public setHeight(height: number): this {
    return this;
  }

  public setWidth(width: number): this {
    return this;
  }

  public resize(): void {
    this.preview?.resize();
  }

  public render(): this {
    return this;
  }

  private createLuckysheet() {
    //@ts-ignore
    window.luckysheet.create({
      ...this.getBaseOptions(),
      container: this.id,
      data: this.data.sheets,
      title: this.data.info.name ?? 'New document',
      userInfo: this.data.info.creator ?? '',
    });

    //@ts-ignore
    this.instance = window.luckysheet;
  }

  private getBaseOptions(): Partial<LuckySpreadsheetOptions> {
    return {
      showinfobar: false,
      showtoolbarConfig: {
        undoRedo: true, //Undo redo
        paintFormat: true, //Format brush
        currencyFormat: false, //currency format
        percentageFormat: false, //Percentage format
        numberDecrease: true, //'Decrease the number of decimal places'
        numberIncrease: true, //'Increase the number of decimal places
        moreFormats: false, //'More Formats'
        font: false, //'font'
        fontSize: false, //'Font size'
        bold: false, //'Bold (Ctrl+B)'
        italic: false, //'Italic (Ctrl+I)'
        strikethrough: false, //'Strikethrough (Alt+Shift+5)'
        underline: false, // 'Underline (Alt+Shift+6)'
        textColor: false, //'Text color'
        fillColor: false, //'Cell color'
        border: false, //'border'
        mergeCell: false, //'Merge cells'
        horizontalAlignMode: false, //'Horizontal alignment'
        verticalAlignMode: false, //'Vertical alignment'
        textWrapMode: false, //'Wrap mode'
        textRotateMode: false, //'Text Rotation Mode'
        image: false, // 'Insert picture'
        link: false, // 'Insert link'
        chart: false, //'chart' (the icon is hidden, but if the chart plugin is configured, you can still create a new chart by right click)
        postil: false, //'comment'
        pivotTable: false, //'PivotTable'
        function: false, //'formula'
        frozenMode: false, //'freeze mode'
        sortAndFilter: false, //'Sort and filter'
        conditionalFormat: false, //'Conditional Format'
        dataVerification: false, // 'Data Verification'
        splitColumn: false, //'Split column'
        screenshot: false, //'screenshot'
        findAndReplace: false, //'Find and Replace'
        protection: false, // 'Worksheet protection'
        print: false, // 'Print'
      },
      showsheetbar: true,
      showsheetbarConfig: {
        add: false,
        menu: false,
      },
      enableAddRow: false,
      cellRightClickConfig: {
        copy: true,
        copyAs: false, // copy as
        paste: false, // paste
        insertRow: false, // insert row
        insertColumn: false, // insert column
        deleteRow: false, // delete the selected row
        deleteColumn: false, // delete the selected column
        deleteCell: false, // delete cell
        hideRow: false, // hide the selected row and display the selected row
        hideColumn: false, // hide the selected column and display the selected column
        rowHeight: false, // row height
        columnWidth: false, // column width
        clear: false, // clear content
        matrix: false, // matrix operation selection
        sort: false, // sort selection
        filter: false, // filter selection
        chart: false, // chart generation
        image: false, // insert picture
        link: false, // insert link
        data: false, // data verification
        cellFormat: false, // Set cell format
      },
      allowUpdate: false,
      hook: {
        cellEditBefore: this.beforeEdit.bind(this),
        cellUpdateBefore: this.beforeUpdate.bind(this),
      },
    };
  }

  private beforeEdit(range: Array<{ row_focus: number; column_focus: number }>) {
    range.forEach((editedCell) => {
      const value = this.instance.getCellValue(editedCell.row_focus, editedCell.column_focus);
      this.editMap.set(this.generateEditMapKey(editedCell.row_focus, editedCell.column_focus), value);
    });
  }

  private beforeUpdate(row: number, column: number, value: string | number | Object, isRefresh: boolean) {
    const previous = this.editMap.get(this.generateEditMapKey(row, column));

    if (previous !== value) {
      this.instance.setCellValue(row, column, previous);
    }

    return false;
  }

  private generateEditMapKey(row: number, column: number): string {
    return `${row}_${column}`;
  }
}

export class LuckySpreadsheetViewer implements SpreadsheetViewer {
  private wrapper: HTMLElement;

  constructor(
    private readonly container: HTMLElement,
    private readonly window: Window,
  ) {
    this.wrapper = window.document.createElement('div');

    this.wrapper.style.setProperty('position', 'absolute');
    this.wrapper.style.setProperty('width', '100%');
    this.wrapper.style.setProperty('height', '100%');
    this.wrapper.style.setProperty('left', '0');
    this.wrapper.style.setProperty('top', '0');
    this.wrapper.id = 'luckysheet-' + v4();
    this.container.innerHTML = '';
    this.container.appendChild(this.wrapper);
  }

  public async open(url: URL): Promise<SpreadsheetPreview> {
    /*this.workbook = await fetch(url)
      .then(response => response.blob())
      .then(buffer => read(buffer));*/
    // @ts-ignore

    return new Promise((resolve, reject) => {
      // @ts-ignore
      LuckyExcel.transformExcelToLuckyByUrl(url.toString(), name, (exportJson: LuckyJSONData) => {
        if (exportJson.sheets == null || exportJson.sheets.length == 0) {
          reject(new Error('Failed to read the content of the excel file, currently does not support xls files!'));
        }

        resolve(new LuckySpreadsheetPreview(this.wrapper, exportJson));
      });
    });
  }
}
