import {ProvideMetadata} from "@application/framework/di/interfaces/provide-options.interface";
import {DI_PROVIDE_METADATA} from "@application/framework/di/tokens";
import {ProvideReflectionMetadata} from "@application/framework/di/types/provide-reflection-metadata.type";
import {MetadataReflection} from "@application/framework/lib";

export const setProvideReflectionMetadata = <T extends Object>(target: T, paramIndex: number, metadata: ProvideMetadata): T => {
  let provideMetadata: ProvideReflectionMetadata = new Map();

  const reflection = new MetadataReflection();
  if (reflection.hasMetadata(DI_PROVIDE_METADATA, target)) {
    provideMetadata = reflection.getMetadata(DI_PROVIDE_METADATA, target);
  }

  provideMetadata.set(paramIndex, metadata);
  reflection.defineMetadata(DI_PROVIDE_METADATA, provideMetadata, target);

  return target;
}
