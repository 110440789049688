<a class="logo-wrapper" routerLink="/">
  <span class="visually-hidden">{{'Retour à l\'accueil' | ehp_translate}}</span>
  <ehp-logo aria-hidden="true"></ehp-logo>
</a>

<div *ngIf="(user$ | async) as user" class="user-container">

  <h3 class="username">
    <a [routerLink]="URLS.profile">
      {{user.displayName}}
    </a>
  </h3>

  <a class="button is-icon small icon-logout logout" routerLink="{{ URLS.logout }}">
    <span class="label">{{ 'Se déconnecter'|ehp_translate }}</span>
  </a>


</div>
