import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {EhpadDemain} from "@domain/ehpad-demain";

export class EhpadDemainUpdatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly ehpadDemain: EhpadDemain) {
    super();
  }
}
