import {ReadOnlyCollection} from "@application/framework/store/collection";
import {
  DEPENDENCIES_FUNDING_MODES,
  TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  TEMPORARY_DEPENDENCIES_FUNDING_MODES,
  TemporaryDependencyFundingMode,
  TemporaryDependencyFundingModeValues
} from "@domain/dependency-funding";
import {Service} from "@application/framework/di";
import {
  commonDependencyFundingModes
} from "@application/bundles/dependency-funding/collections/common-dependency-funding-modes";

@Service()
export class TemporaryDependencyFundingModeCollection implements ReadOnlyCollection<TemporaryDependencyFundingMode<any>> {

  // @ts-ignore
  private readonly collection: Map<TEMPORARY_DEPENDENCIES_FUNDING_MODES, string> = new Map([
    [DEPENDENCIES_FUNDING_MODES.UNKNOWN, commonDependencyFundingModes[DEPENDENCIES_FUNDING_MODES.UNKNOWN]],
    [DEPENDENCIES_FUNDING_MODES.EMPTY, commonDependencyFundingModes[DEPENDENCIES_FUNDING_MODES.EMPTY]],
    [TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES.SAME_PERMANENT, "Tarif identique à celui de l’hébergement permanent"],
    [DEPENDENCIES_FUNDING_MODES.GIR_GROUP, commonDependencyFundingModes[DEPENDENCIES_FUNDING_MODES.GIR_GROUP]],
    [DEPENDENCIES_FUNDING_MODES.AMOUNT, commonDependencyFundingModes[DEPENDENCIES_FUNDING_MODES.AMOUNT]],
  ]);

  public get<K extends TEMPORARY_DEPENDENCIES_FUNDING_MODES>(key: K): TemporaryDependencyFundingMode<K> | undefined {

    if (!TemporaryDependencyFundingModeValues.has(key)) {
      return undefined;
    }

    const label = this.collection.get(key) || key;
    return {key, label};
  }

  public has(key: TEMPORARY_DEPENDENCIES_FUNDING_MODES): boolean {
    return this.collection.has(key);
  }

  public list(): TemporaryDependencyFundingMode<any>[] {
    return Array.from(this.collection.entries()).map(([key, label]) => ({key, label}));
  }

}
