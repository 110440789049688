import {Transformer} from "@application/framework/core";
import {EhpadDemainHealthFunding} from "@domain/health-funding";
import {Common} from "@application/framework/lib";
import {
  CreateEhpadDemainHealthFundingDto,
  EhpadDemainHealthFundingDtoFactory,
  UpdateEhpadDemainHealthFundingDto
} from "@application/bundles/health-funding";
import {Service} from "@application/framework/di";

import {
  CreateEasyEhpadDemainHealthFundingDto,
  EasyEhpadDemainHealthFunding,
  UpdateEasyEhpadDemainHealthFundingDto
} from "@implementations/bundles/backend/easyhpad/interfaces/health-funding/easy-ehpad-demain-health-funding";
import {EhpadDemainHealthFundingImpl} from "@implementations/bundles/health-funding/implementations";


type CommonProperties = Common<EhpadDemainHealthFunding, Common<CreateEhpadDemainHealthFundingDto, UpdateEhpadDemainHealthFundingDto>>;

type EasyCommonProperties = Common<EasyEhpadDemainHealthFunding, Common<CreateEasyEhpadDemainHealthFundingDto, UpdateEasyEhpadDemainHealthFundingDto>>;

@Service()
export class EhpadDemainHealthFundingTransformer implements Transformer<EasyEhpadDemainHealthFunding, EhpadDemainHealthFunding> {

  constructor(
    private readonly dtoFactory: EhpadDemainHealthFundingDtoFactory
  ) {
  }


  public transform(value: EasyEhpadDemainHealthFunding): EhpadDemainHealthFunding {
    return new EhpadDemainHealthFundingImpl({
      ...this.transformCommonProperties(value),
      id: value.id,
    });
  }

  public transformCreateDto(value: CreateEasyEhpadDemainHealthFundingDto): CreateEhpadDemainHealthFundingDto {
    return this.dtoFactory.buildCreateDtoFromUnsafeValues({
      ...this.transformCommonProperties(value)
    });
  }

  public transformUpdateDto(value: UpdateEasyEhpadDemainHealthFundingDto): UpdateEhpadDemainHealthFundingDto {
    return this.dtoFactory.buildUpdateDtoFromUnsafeValues({
      ...this.transformCommonProperties(value),
      id: value.id
    });
  }


  public reverseTransform(value: EhpadDemainHealthFunding): EasyEhpadDemainHealthFunding {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id
    }
  }

  public reverseTransformCreateDto(value: CreateEhpadDemainHealthFundingDto): CreateEasyEhpadDemainHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value)
    }
  }

  public reverseTransformUpdateDto(value: UpdateEhpadDemainHealthFundingDto): UpdateEasyEhpadDemainHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id
    }
  }

  private transformCommonProperties(value: EasyCommonProperties): CommonProperties {
    return {
      name: value.name,
      amount: value.amount,
      ehpadDemainId: value.ehpad_demain_id || undefined,
    }
  }

  private reverseTransformCommonProperties(value: CommonProperties): EasyCommonProperties {
    return {
      name: value.name,
      amount: value.amount,
      ehpad_demain_id: value.ehpadDemainId,

    }
  }


}
