import {Command} from "@application/framework/command-query";
import {Role} from "@domain/authorization";
import {User} from "@domain/user";

export class AssignRolesToUserCommand implements Command<User> {

  constructor(public readonly user: User, public readonly roles: Role[]) {
  }

}
