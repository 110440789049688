import {
  CreateCPOMCommandHandler,
  DeleteCPOMCommandHandler,
  UpdateCPOMCommandHandler
} from "@application/bundles/cpom/commands/handlers";
import {
  OnCPOMCreationEventSubscriber,
  OnCPOMDeletionEventSubscriber,
  OnCPOMUpdateEventSubscriber
} from "@application/bundles/cpom/events/subscribers";
import {
  GetCPOMQueryHandler,
  GetCurrentCPOMQueryHandler,
  GetCurrentLocalCpomQueryHandler,
  HasCPOMForYearQueryHandler,
  ListCPOMQueryHandler,
  ListOtherLocalCpomQueryHandler
} from "@application/bundles/cpom/queries/handlers";
import {Bundle} from "@application/framework/bundle";
import {Service} from "@application/framework/di";

@Bundle({
  commandHandlers: [
    CreateCPOMCommandHandler,
    UpdateCPOMCommandHandler,
    DeleteCPOMCommandHandler
  ],
  queryHandlers: [
    ListCPOMQueryHandler,
    GetCPOMQueryHandler,
    GetCurrentCPOMQueryHandler,
    GetCurrentLocalCpomQueryHandler,
    HasCPOMForYearQueryHandler,
    ListOtherLocalCpomQueryHandler
  ],
  eventSubscribers: [
    OnCPOMCreationEventSubscriber,
    OnCPOMUpdateEventSubscriber,
    OnCPOMDeletionEventSubscriber
  ]
})
@Service()
export class CPOMBundle {

  constructor() {
  }
}
