/**
 * Type alias to define french department code.
 *
 * @since 1.0.0
 */
export type DepartmentCode = string;


/**
 * French department representation.
 *
 * @since 1.0.0
 */
export interface Department {
  /**
   * Human-readable name.
   */
  name: string;

  /**
   * Two or three digits code, except for Corse island (2A & 2B). Each department as uniq code.
   */
  code: DepartmentCode;
}