<ehp-page-header>

  <h1 class="title page-title" pageTitle>
    <span class="trail">{{ 'Administration' | ehp_translate }}</span>
    <span class="trail">{{ 'Clients' | ehp_translate }}</span>
  </h1>


  <ng-container actions>
    <a class="button primary has-count" routerLink="create">{{'Nouveau client' | ehp_translate}}</a>
    <button (click)="delete()" *ngIf="(selectionCount$ | async) as selectionCount"
            class="button delete outline has-count">
      Supprimer
      <span *ngIf="selectionCount > 1" class="count">{{selectionCount}}</span>
    </button>
  </ng-container>

</ehp-page-header>

<ehp-page-content>


  <ehp-table-container>
    <div class="table-header">
      <div [formGroup]="filters" class="filters">
        <div class="filter">
          <input ehp-form-control formControlName="name" id="name" placeholder="{{'Nom du client' | ehp_translate}}"
                 type="text">
        </div>
      </div>
      <ehp-selectable-table-columns [columns]="columns"></ehp-selectable-table-columns>
    </div>

    <table [dataSource]="customers" [ngClass]="{'loading': loading}" cdk-table class="is-table is-striped scroll-body">


      <ng-container cdkColumnDef="select">
        <th *cdkHeaderCellDef cdk-header-cell></th>
        <td *cdkCellDef="let user" cdk-cell class="center">
          <input (click)="toggleSelection(user.id)" [checked]="isSelected(user.id)" type="checkbox">
        </td>
      </ng-container>

      <ng-container cdkColumnDef="id">
        <th *cdkHeaderCellDef cdk-header-cell>
          <ehp-sortable-label (sort)="sort('id', $event)" [sortable]="this.columns.isSortable('id')">
            {{ this.columns.label('id') | ehp_translate}}
          </ehp-sortable-label>
        </th>
        <td *cdkCellDef="let customer" cdk-cell> {{ customer.id }} </td>
      </ng-container>

      <ng-container cdkColumnDef="name">
        <th *cdkHeaderCellDef cdk-header-cell>
          <ehp-sortable-label (sort)="sort('name', $event)" [sortable]="this.columns.isSortable('name')">
            {{ this.columns.label('name') | ehp_translate}}
          </ehp-sortable-label>
        </th>
        <td *cdkCellDef="let customer" cdk-cell> {{ customer.name }} </td>
      </ng-container>

      <ng-container cdkColumnDef="address">
        <th *cdkHeaderCellDef cdk-header-cell>
          <ehp-sortable-label [sortable]="this.columns.isSortable('address')">
            {{ this.columns.label('address') | ehp_translate}}
          </ehp-sortable-label>
        </th>
        <td *cdkCellDef="let customer" cdk-cell>
          <ehp-address-one-line [address]="customer.address"></ehp-address-one-line>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="phone">
        <th *cdkHeaderCellDef cdk-header-cell>
          <ehp-sortable-label (sort)="sort('phone', $event)" [sortable]="this.columns.isSortable('phone')">
            {{ this.columns.label('phone') | ehp_translate}}
          </ehp-sortable-label>
        </th>
        <td *cdkCellDef="let customer" cdk-cell>
          {{ customer.phone?.number }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="blocked">
        <th *cdkHeaderCellDef cdk-header-cell class="center">
          <ehp-sortable-label (sort)="sort('blocked', $event)" [sortable]="this.columns.isSortable('blocked')">
            {{ this.columns.label('blocked') | ehp_translate}}
          </ehp-sortable-label>
        </th>
        <td *cdkCellDef="let customer" cdk-cell class="center">
          <ehp-blocked-icon [active]="customer.blocked"></ehp-blocked-icon>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="createdAt">
        <th *cdkHeaderCellDef cdk-header-cell class="center">
          <ehp-sortable-label (sort)="sort('createdAt', $event)" [asc]="true"
                              [sortable]="this.columns.isSortable('createdAt')">

            {{ this.columns.label('createdAt') | ehp_translate}}
          </ehp-sortable-label>
        </th>
        <td *cdkCellDef="let customer" cdk-cell class="center">
          {{ customer.createdAt | date:'medium'}}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="actions">
        <th *cdkHeaderCellDef cdk-header-cell class="right">
          {{ this.columns.label('actions') | ehp_translate}}
        </th>
        <td *cdkCellDef="let customer" cdk-cell class="right">
          <a [routerLink]="customer.id">{{ 'Modifier' | ehp_translate }}</a>
        </td>
      </ng-container>


      <tr *cdkHeaderRowDef="this.columns.keys$ | async" cdk-header-row></tr>
      <tr *cdkRowDef="let row; columns: this.columns.keys$ | async;" [ngClass]="{'selected': isSelected(row.id)}"
          cdk-row></tr>
    </table>


    <div class="table-footer">
      <ehp-pagination (change)="changePagination($event)" [current]="paginationResults"></ehp-pagination>
    </div>

  </ehp-table-container>
</ehp-page-content>
