import {
  isValidDaycareDependencyFundingIdType,
  UpdateDaycareDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {CreateDaycareDependencyFundingDtoImpl} from "./create-daycare-dependency-funding.dto";
import {DAYCARE_DEPENDENCIES_FUNDING_MODES, DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding";
import {IsValidDaycareDependencyFundingId} from "@implementations/bundles/dependency-funding/validators";

export class UpdateDaycareDependencyFundingDtoImpl<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = any> extends CreateDaycareDependencyFundingDtoImpl<M, V> implements UpdateDaycareDependencyFundingDto<M, V> {

  @IsValidDaycareDependencyFundingId()
  public id!: number;

  constructor(values?: Partial<UpdateDaycareDependencyFundingDto<M, V>>) {
    super(values);

    if (values && isValidDaycareDependencyFundingIdType(values.id)) {
      this.id = values.id;
    }
  }

}
