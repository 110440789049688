import {SerializedPMPSearchParams} from "@application/bundles/pmp";
import {Query} from "@application/framework/command-query";
import {PMP} from "@domain/pmp";

export class ListPMPQuery implements Query<PMP[]> {

  constructor(public readonly params?: SerializedPMPSearchParams) {
  }

}
