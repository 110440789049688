import { Actions } from '@ngrx/effects';
import { CommandBus, QueryBus } from '@application/framework/command-query';
import { Injectable } from '@angular/core';
import { DocumentEffet } from '@easyhpad-ui/app/bundles/documents/store/document.effet';
import { DocumentStoreActions } from '@easyhpad-ui/app/bundles/documents';
import { from, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { dependencyFundingActions } from '@easyhpad-ui/app/bundles/dependency-funding/store/dependency-funding.store';
import { DependencyFunding } from '@domain/dependency-funding';
import {
  CreateDependencyFundingDto,
  DependencyFundingDtoFactory,
  GetDependencyFundingQuery,
  GetDependencyFundingQueryResult,
  ListDependencyFundingQuery,
  ListDependencyFundingQueryResult,
  SerializedDependencyFundingSearchParams,
  UpdateDependencyFundingCommand,
  UpdateDependencyFundingCommandResult,
  UpdateDependencyFundingDto,
} from '@application/bundles/dependency-funding';

@Injectable()
export class DependencyFundingEffects extends DocumentEffet<
  DependencyFunding,
  any,
  CreateDependencyFundingDto,
  UpdateDependencyFundingDto
> {
  /**
   * @inheritDoc
   * @protected
   */
  protected get actions(): DocumentStoreActions<DependencyFunding, DependencyFunding['id']> {
    return dependencyFundingActions;
  }

  constructor(
    private readonly commandBus: CommandBus,
    private readonly queryBus: QueryBus,
    private readonly factory: DependencyFundingDtoFactory,
    store: Store<AppState>,
    actions$: Actions,
  ) {
    super(actions$, store);
  }

  protected loadDocuments(params?: SerializedDependencyFundingSearchParams): Observable<DependencyFunding[]> {
    return from(this.queryBus.request<ListDependencyFundingQueryResult>(new ListDependencyFundingQuery(params)));
  }

  protected loadDocument(id: DependencyFunding['id']): Observable<DependencyFunding> {
    return from(this.queryBus.request<GetDependencyFundingQueryResult>(new GetDependencyFundingQuery(id)));
  }

  protected updateDocument(
    _: DependencyFunding['id'],
    update: UpdateDependencyFundingDto,
  ): Observable<DependencyFunding> {
    return from(
      this.commandBus.execute<UpdateDependencyFundingCommandResult>(
        new UpdateDependencyFundingCommand(this.factory.buildUpdateDtoFromUnsafeValues(update)),
      ),
    );
  }
}
