import {AuthentificationBundle as ApplicationAuthentificationBundle} from '@application/bundles/authentification/index';
import {Bundle} from "@application/framework/bundle";
import {
  OnUnauthorizedAccessSubscriber
} from "@easyhpad-ui/app/bundles/authentification/events/subscribers/on-authorized-access.subscriber";


@Bundle({
  imports: [ApplicationAuthentificationBundle],
  eventSubscribers: [
    OnUnauthorizedAccessSubscriber
  ]
})
export class AuthentificationBundle {

}
