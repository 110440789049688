import { TaxonomyRepository } from '@application/bundles/taxonomy';
import { TaxonomyTerm } from '@domain/taxonomy';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { joinPath } from '@application/framework/lib';

export abstract class EasyTaxonomyRepository implements TaxonomyRepository {
  constructor(
    public readonly path: string,
    private readonly backend: EasyBackendApi,
  ) {}

  public list(): Promise<TaxonomyTerm[]> {
    return this.backend.get<{ entities: TaxonomyTerm[] }>(this.path).then(response => response.body.entities);
  }

  public get(id: TaxonomyTerm['id']): Promise<TaxonomyTerm> {
    return this.backend.get<{ entity: TaxonomyTerm }>(joinPath(this.path, id)).then(response => response.body.entity);
  }

  public create(payload: Omit<TaxonomyTerm, 'id'>): Promise<TaxonomyTerm> {
    return this.backend.post<{ entity: TaxonomyTerm }>(this.path, payload).then(response => response.body.entity);
  }
}
