<div *ngIf="cpom" class="document-list-item root-item">

    <h3 class="title item-title m0">
        <span [class.icon-file]="cpom.isSingle" [class.icon-folder]="!cpom.isSingle" class="icon ehp-icon"></span>

        <ehp-cpom-link [refId]="cpom.id">
            {{"Contrat Pluriannuel d'Objectifs et de Moyens" | ehp_translate}}
        </ehp-cpom-link>
    </h3>

    <span class="year">
    {{cpom.year}}
  </span>

    <span class="date">
    {{cpom.date | date}}
  </span>

    <span class="tariff-option">
    <ehp-translatable-string *ngIf="cpom.singleChild" [value]="cpom.singleChild.tariffOption.label">
    </ehp-translatable-string>
  </span>
  
    <ehp-countable-label [count]="cpom.children.length || 0" [displaySingle]="false" class="facilities">

        <ng-container plural>{{'établissements' | ehp_translate}}</ng-container>

        <ng-container *ngIf="cpom.singleChild" single>
            <ehp-facility-name [fid]="cpom.singleChild.facilityId"></ehp-facility-name>
        </ng-container>
    </ehp-countable-label>


    <div class="actions-list actions">
        <!-- <button class="is-icon icon-eye ghost secondary" pTooltip="{{'Ouvrir le document' | ehp_translate}}"
                 tooltipPosition="bottom">

         </button>-->

        <ehp-cpom-link [refId]="cpom.id" class="is-icon icon-arrow ghost button" pTooltip="{{'Voir' | ehp_translate}}"
                       tooltipPosition="bottom">

            <span class="label">{{'Consulter le C.P.O.M.' | ehp_translate }}</span>
        </ehp-cpom-link>
    </div>

</div>

<div *ngIf="cpom && cpom.children && cpom.children.length > 1" class="children-items">

    <ng-container *ngFor="let local of cpom.children; let index = index">

        <div
          *ngIf="index <= 1 || open"
          [ngStyle]="{'--ehp-document-list-item--index': index + 1}"
          class="document-list-item child-item"
        >
            <h3 class="title item-title m0">
                <span class="icon ehp-icon icon-file"></span>

                <ehp-cpom-link [refId]="cpom.id">
                    <ehp-facility-name [fid]="local.facilityId"></ehp-facility-name>
                </ehp-cpom-link>
            </h3>

            <span class="year">
        {{cpom.year}}
      </span>

            <span class="date">
       {{cpom.date | date}}
      </span>

            <span class="tariff-option">
        <ehp-translatable-string [value]="local.tariffOption.label">
        </ehp-translatable-string>
      </span>

            <div class="facilities"></div>

            <div class="actions"></div>
        </div>

    </ng-container>


</div>

