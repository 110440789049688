import {TranslatableString} from "@application/framework/translation";

export enum NoticeType {
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export interface Notice {

  icon?: string;

  message: string | TranslatableString;

  type: NoticeType;

  dismiss?: number;
}
