import { UUID } from '@domain/lib';

export interface TaxonomyTerm {
  /**
   * Unique identifier.
   */
  id: UUID;

  /**
   * A human-readable name.
   */
  name: string;

  /**
   * If term is a sub-term, parent reference.
   */
  parentId?: TaxonomyTerm['id'] | null;
}
