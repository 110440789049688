import { Transformer } from '@application/framework/core';
import { ERRD } from '@domain/eprd-errd';
import { CreateEasyERRD, EasyERRD, UpdateEasyERRD } from '@implementations/bundles/backend/easyhpad';
import { Provide, Service } from '@application/framework/di';
import { ERRDImpl } from '@implementations/bundles/eprd-errd/implementations';
import { DateFormatter } from '@application/framework/date';
import { DateParser } from '@application/framework/date/date.parser';
import { EasyLocalErrdTransformer } from '@implementations/bundles/backend/easyhpad/transformers/errd-errd/easy-local-errd.transformer';
import { CreateERRDDto, UpdateERRDDto } from '@application/bundles/eprd-errd';
import { Common } from '@application/framework/lib';

type CommonDtoProperties = Common<CreateERRDDto, UpdateERRDDto>;

type CommonEasyDtoProperties = Common<CreateEasyERRD, UpdateEasyERRD>;

type CommonProperties = Omit<Common<ERRD, CommonDtoProperties>, 'details'>;

type CommonEasyProperties = Omit<Common<EasyERRD, CommonEasyDtoProperties>, 'details'>;

@Service()
export class EasyERRDTransformer implements Transformer<ERRD, EasyERRD> {
  @Provide()
  private readonly localTransformer!: EasyLocalErrdTransformer;

  @Provide()
  private readonly dateParser!: DateParser;

  @Provide()
  private readonly dateFormatter!: DateFormatter;

  public transform(value: ERRD): EasyERRD {
    return {
      id: value.id,
      accountingYear: value.accountingYear,
      depositDate: value.depositDate instanceof Date ? this.dateFormatter.toUTCIsoString(value.depositDate) : undefined,
      details: value.details.map(local => this.localTransformer.transform(local)),
      reportId: value.reportId,
      completeDocumentId: value.completeDocumentId,
      payrollId: value.payrollDocumentId,
      financialAnnexId: value.financialAnnexDocumentId,
      activityAnnexId: value.activityAnnexDocumentId,
    };
  }

  public reverseTransform(value: EasyERRD): ERRD {
    return new ERRDImpl({
      id: value.id,
      accountingYear: value.accountingYear,
      depositDate: value.depositDate ? this.dateParser.fromISOString(value.depositDate) : undefined,
      details: value.details.map(local => this.localTransformer.reverseTransform(local)),
      reportId: value.reportId || undefined,
      completeDocumentId: value.completeDocumentId,
      payrollDocumentId: value.payrollId,
      financialAnnexDocumentId: value.financialAnnexId,
      activityAnnexDocumentId: value.activityAnnexId,
    });
  }

  public createTransform(dto: CreateERRDDto): CreateEasyERRD {
    return {
      ...this.getCommonProperties(dto),
      details: dto.details.map(d => this.localTransformer.getLocalCreateDto(d)),
    };
  }

  public updateTransform(dto: UpdateERRDDto): UpdateEasyERRD {
    return {
      ...this.getCommonProperties(dto),
      id: dto.id,
      details: dto.details.map(d => this.localTransformer.getLocalUpdateDto(d)),
    };
  }

  private getCommonProperties(values: CommonProperties): CommonEasyProperties {
    return {
      accountingYear: values.accountingYear,
      depositDate:
        values.depositDate instanceof Date ? this.dateFormatter.toUTCIsoString(values.depositDate) : undefined,
      completeDocumentId: values.completeDocumentId || '',
      payrollId: values.payrollDocumentId || '',
      financialAnnexId: values.financialAnnexDocumentId || '',
      activityAnnexId: values.activityAnnexDocumentId || '',
      reportId: values.reportId,
    };
  }
}
