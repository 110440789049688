import {isTariffOption} from "@domain/cpom";
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';

@ValidatorConstraint({name: 'isTariffOption', async: false})
export class isTariffOptionConstraint implements ValidatorConstraintInterface {

  public validate(option: any, args?: ValidationArguments): boolean {
    return isTariffOption(option);
  }

  public defaultMessage(args?: ValidationArguments): string {
    return 'This option cannot be used as valid Tariff option.';
  }
}


export function IsTariffOption(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: isTariffOptionConstraint,
    });
  };
}
