import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutCommand } from '@application/bundles/authentification/commands/logout.command';
import { CommandBus } from '@application/framework/command-query/command-bus.abstract';
import { AuthentificationStrategy } from '@application/bundles/authentification/abstraction/authentication-strategy';
import { configuration } from '../../../../../environments/configuration';
import { EMAIL_PASSWORD_AUTH_STRATEGY } from '../../../user/user.tokens';

@Component({
  selector: 'ehp-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss'],
})
export class LogoutPageComponent implements OnInit {
  constructor(
    private router: Router,
    private commandBus: CommandBus,
    @Inject(EMAIL_PASSWORD_AUTH_STRATEGY) private strategy: AuthentificationStrategy,
  ) {}

  public ngOnInit(): void {
    this.commandBus
      .execute(new LogoutCommand(this.strategy))
      .then(() => this.onLogoutSuccess())
      .catch(error => this.onLogoutFail(error));
  }

  private onLogoutSuccess() {
    this.router.navigate([configuration.loginPath]);
  }

  private onLogoutFail(error: any) {
    console.error('error =>', error);
  }
}
