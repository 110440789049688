import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {PaginationParams} from "@application/framework/search";
import {FormControl} from "@angular/forms";
import {debounceTime, Subscription} from "rxjs";

@Component({
  selector: 'ehp-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  host: {
    '[class.is-end]': 'isEnd'
  }
})
export class PaginationComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {


  @Input("current") public inputs: { result: number, page: number } | undefined;

  @Output('change') public pagination: EventEmitter<PaginationParams> = new EventEmitter();

  public current: FormControl<number> = new FormControl<any>(1);

  public limit: number = 50;

  public count: number = 0;

  public isEnd: boolean = false;

  @ViewChild('prevButton') public previousButton: ElementRef<HTMLButtonElement> | undefined;

  @ViewChild('nextButton') public nextButton: ElementRef<HTMLButtonElement> | undefined;

  private subscription: Subscription | undefined;

  public ngOnInit() {
    this.subscription = this.current.valueChanges.pipe(debounceTime(200)).subscribe(current => {
      this.pagination.next({limit: this.limit, start: current});
      this.updateButtonState();
    })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.inputs) {
    }
  }

  public ngAfterViewInit(): void {
    this.updateButtonState();
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.pagination.complete();
  }

  public prev() {

    const value = this.current.value - 1;
    this.current.setValue(value >= 1 ? value : 1, {emitEvent: true});
  }

  public next(): void {
    this.current.setValue(this.current.value + 1);
    this.updateButtonState();
  }

  private updateButtonState() {
    if (this.previousButton?.nativeElement) {
      this.previousButton.nativeElement.disabled = this.current.value === 1;
    }

    if (this.nextButton?.nativeElement) {
      this.nextButton.nativeElement.disabled = this.isEnd;
    }
  }

}
