import {StoreCurrentUserCommand} from "@application/bundles/user/commands/store-current-user.command";
import {
  CurrentUserAccessor
} from "@application/bundles/user/implementations/current-user-accessor/current-user.accessor";
import {HandleCommand} from "@application/framework/command-query";
import {CommandHandler} from "@application/framework/command-query/handler.interface";

@HandleCommand({
  command: StoreCurrentUserCommand
})
export class StoreCurrentUserCommandHandler implements CommandHandler<StoreCurrentUserCommand, void> {


  constructor(
    private readonly accessor: CurrentUserAccessor,
  ) {
  }

  public async handle(command: StoreCurrentUserCommand): Promise<void> {
    await this.accessor.set(command.user);
  }


}
