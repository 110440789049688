import {
  CreateDaycareDependencyFundingDto,
  CreateDependencyFundingDto,
  CreatePermanentDependencyFundingDto,
  CreateTemporaryDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {CommonDependencyFundingDtoAbstract} from "./common-dependency-funding.dto.abstract";
import {isValidObject} from "@application/framework/lib";
import {isLocalMedia, LocalMedia} from "@domain/media";
import {IsLocalMedia} from "@implementations/bundles/media";
import {IsOptional} from "class-validator";

export class CreateDependencyFundingDtoImpl extends CommonDependencyFundingDtoAbstract implements CreateDependencyFundingDto {


  public permanent!: CreatePermanentDependencyFundingDto;

  public temporary!: CreateTemporaryDependencyFundingDto<any, unknown>;

  public daycare!: CreateDaycareDependencyFundingDto<any, unknown>;

  @IsLocalMedia()
  public document!: LocalMedia;


  @IsLocalMedia()
  @IsOptional()
  public report?: LocalMedia;


  constructor(values?: Partial<CreateDependencyFundingDto>) {
    super(values);

    if (isValidObject(values)) {

      if (values.permanent) {
        this.permanent = values.permanent;
      }

      if (values.temporary) {
        this.temporary = values.temporary;
      }

      if (values.daycare) {
        this.daycare = values.daycare;
      }

      if (isLocalMedia(values?.document)) {
        this.document = values?.document;
      }

      if (isLocalMedia(values?.report)) {
        this.report = values?.report;
      }
    }
  }

}
