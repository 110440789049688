import {CreateUser, UpdateCurrentUser, UpdateUser} from "@domain/user";

export abstract class UserDtoFactory {

  public abstract newCreateUserDtoFromUnsafeValues(values: Partial<Record<keyof CreateUser, any>>): CreateUser;

  public abstract newUpdateUserDtoFromUnsafeValues(values: Partial<Record<keyof UpdateUser, any>>): UpdateUser;

  public abstract buildUpdateCurrentUserDto(values: Partial<Record<keyof UpdateCurrentUser, any>>): UpdateCurrentUser;

}
