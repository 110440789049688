import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {User} from "@domain/user";

export class AutoAuthentificationSuccessEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  public constructor(public readonly user: User) {
    super();
  }
}
