import { Media } from '@domain/media';
import { Facility } from '@domain/facility';
import { DaycareDependencyFunding } from '@domain/dependency-funding/daycare-dependency-funding';
import { PermanentDependencyFunding } from '@domain/dependency-funding/permanent-dependency-funding';
import { TemporaryDependencyFunding } from '@domain/dependency-funding/temporary-dependency-funding';
import { Year } from '@domain/lib';
import { Funding } from '@domain/funding';

export interface DependencyFunding extends Funding {
  implementationDate: Date;

  readonly year: Year;

  girValuation: number | undefined;

  occupationRate: number | undefined;

  amount: number | undefined;

  apa: number | undefined;

  outsideDepartment: number | undefined;

  moderatorTicket: number | undefined;

  documentId: Media['id'];

  reportId: Media['id'] | undefined;

  permanent: PermanentDependencyFunding | undefined;

  temporary: TemporaryDependencyFunding<any, unknown> | undefined;

  daycare: DaycareDependencyFunding<any, unknown> | undefined;

  hasReport(): boolean;

  facility(): Promise<Facility>;

  document(): Promise<Media>;

  report(): Promise<Media | undefined>;
}
