import {Injectable} from "@angular/core";
import {AbstractControl, AsyncValidator, ValidationErrors} from "@angular/forms";
import {IsUniqueEmailQuery} from "@application/bundles/user/queries";
import {QueryBus} from "@application/framework/command-query";
import {from, map, Observable, of, tap} from "rxjs";

@Injectable({providedIn: 'root'})
export class UniqueUserEmailValidator implements AsyncValidator {

  private pending: boolean = false;

  private previousValue: string | undefined;

  private isUnique: boolean | undefined;

  private currentRequest: Observable<ValidationErrors | null> | undefined;

  public constructor(private queryBus: QueryBus) {
    this.validate = this.validate.bind(this);
  }


  public validate(control: AbstractControl): Observable<ValidationErrors | null> {

    const value = control.value;

    if (this.previousValue === value) {
      return this.isUnique ? of(null) : of(this.getValidationError());
    }

    if (this.currentRequest === undefined) {
      this.currentRequest = this.getNewRequest(value);
    }

    return this.currentRequest;

  }

  public clone(): UniqueUserEmailValidator {
    return new UniqueUserEmailValidator(this.queryBus);
  }


  private getNewRequest(value: string): Observable<ValidationErrors | null> {

    return from(this.queryBus.request(new IsUniqueEmailQuery(value))).pipe(
      map(isUnique => isUnique ? null : this.getValidationError()),
      tap(() => this.currentRequest = undefined),
    )
  }

  private getValidationError(): ValidationErrors {
    return {uniqueUserEmail: true}
  }
}
