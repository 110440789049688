import { Attribute, CRUD_ACTIONS } from '@application/bundles/authorization';
import { ForbiddenError } from '@application/bundles/authorization/error';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import {
  ActivityAnnexRepository,
  ActivityAnnexUpdatedEvent,
  ActivityAnnexUpdateFailEvent,
  GetActivityAnnexQuery,
  UpdateActivityAnnexCommand,
  UpdateActivityAnnexDto,
} from '@application/bundles/activity-annex';
import {
  ACTIVITY_ANNEX_FEATURE,
  ACTIVITY_ANNEX_TRANSLATE_CONTEXT,
} from '@application/bundles/activity-annex/activity-annex.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { Facility } from '@domain/facility';
import { isLocalMedia, isMedia, MediaBucket, TemporaryMedia } from '@domain/media';
import { ActivityAnnex } from '@domain/activity-annex';
import { ActivityAnnexAuthChecker } from '@application/bundles/activity-annex/activity-annex-authorization-checker';

@HandleCommand({
  command: UpdateActivityAnnexCommand,
})
export class UpdateActivityAnnexCommandHandler implements CommandHandler<UpdateActivityAnnexCommand, ActivityAnnex> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: ActivityAnnexRepository,
    private readonly authorization: ActivityAnnexAuthChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: UpdateActivityAnnexCommand): Promise<ActivityAnnex> {
    const { activityAnnex } = command;
    try {
      const updated = await this.sanitize(activityAnnex)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.updateActivityAnnex(dto));

      this.eventDispatcher.dispatch(new ActivityAnnexUpdatedEvent(updated));

      return updated;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  public async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à mettre à jour cette annexe activité.",
        undefined,
        ACTIVITY_ANNEX_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new ActivityAnnexUpdateFailEvent(message));

    return error;
  }

  private async checkAccess(dto: UpdateActivityAnnexDto): Promise<UpdateActivityAnnexDto> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(dto.facilityId));
    const activityAnnex = await this.queryBus.request<ActivityAnnex>(new GetActivityAnnexQuery(dto.id));

    const a: Attribute[] = [{ feature: ACTIVITY_ANNEX_FEATURE, value: CRUD_ACTIONS.UPDATE }];

    if (!(await this.authorization.canUpdate(activityAnnex))) {
      this.logger.error('Activity annex update : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: UpdateActivityAnnexDto): Promise<UpdateActivityAnnexDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('Activity annex update : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdateActivityAnnexDto): Promise<UpdateActivityAnnexDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('Activity annex update : Validator fail');
      throw e;
    }

    return dto;
  }

  private async updateActivityAnnex(dto: UpdateActivityAnnexDto): Promise<ActivityAnnex> {
    const current = await this.queryBus.request(new GetActivityAnnexQuery(dto.id));

    let document: TemporaryMedia | undefined;

    if (dto.documentId !== current.documentId || isLocalMedia(dto.document)) {
      if (dto.document && isLocalMedia(dto.document)) {
        document = await this.mediaBucket.add(dto.document);
        dto.documentId = document.id;
      } else if (dto.document && isMedia(dto.document)) {
        dto.documentId = dto.document.id;
      }
    }

    try {
      return await this.repository.update(dto.id, dto);
    } catch (e) {
      if (document !== undefined) {
        await document.markForDeletion();
      }

      throw e;
    }
  }
}
