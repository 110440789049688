import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ListCustomersQuery } from '@application/bundles/customer/queries/list-customers.query';
import { QueryBus } from '@application/framework/command-query';
import { Customer } from '@domain/customer';
import { Facility, FACILITY_STATE } from '@domain/facility';
import { FacilityTypeRepository } from '@domain/facility/facility-type.repository';
import { SocialClearanceRepository } from '@domain/facility/social-clearance.repository';
import { selectIsAdministratorAccount } from '@easyhpad-ui/app/bundles/authentification/store';
import { FacilityFormBuilder } from '@easyhpad-ui/app/bundles/facility/services/form-builder/form-builder.service';
import { AppState } from '@easyhpad-ui/app/store';

import {
  FacilityTypeSelectOptionTransformer,
  SocialClearanceSelectOptionTransformer,
} from '@implementations/bundles/facility';
import { SelectOption } from '@implementations/forms/select-option.interface';
import { Store } from '@ngrx/store';
import { from, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-facility-edit-form',
  templateUrl: './facility-edit-form.component.html',
  styleUrls: ['./facility-edit-form.component.scss'],
})
export class FacilityEditFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public mode: 'create' | 'update' | undefined;

  @Input() public facility: Facility | undefined;

  @Input() public submitLabel = "Modifier l'établissement";

  @Output('onSubmit') public submit$: EventEmitter<Partial<Facility>> = new EventEmitter();

  public form?: FormGroup;

  public facilityStateOptions: SelectOption[] = [];

  public customer$: Observable<Customer[]>;

  public socialClearance$: Observable<SelectOption[]>;

  public type$: Observable<SelectOption[]>;

  private transformers = {
    facilityType: new FacilityTypeSelectOptionTransformer(),
    socialClearance: new SocialClearanceSelectOptionTransformer(),
  };

  private isAdmin: boolean = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly formBuilder: FacilityFormBuilder,
    private queryBus: QueryBus,
    private typeRepository: FacilityTypeRepository,
    private socialClearanceRepository: SocialClearanceRepository,
    private readonly store: Store<AppState>,
  ) {
    this.customer$ = from(this.queryBus.request(new ListCustomersQuery()));

    this.socialClearance$ = from(this.socialClearanceRepository.list()).pipe(
      map(socialClearances => socialClearances.map(s => this.transformers.socialClearance.transform(s))),
    );

    this.type$ = from(this.typeRepository.list()).pipe(
      map(types => types.map(type => this.transformers.facilityType.transform(type))),
    );

    this.facilityStateOptions = Object.values(FACILITY_STATE).map(state => {
      const option: SelectOption = { value: state, label: state };

      switch (state) {
        case FACILITY_STATE.DRAFT:
          option.label = 'Brouillon';
          break;
        case FACILITY_STATE.ONBOARDING:
          option.label = 'En création';
          break;
        case FACILITY_STATE.ACTIVE:
          option.label = 'Actif';
          break;
      }
      return option;
    });
  }

  public ngOnInit() {
    this.store
      .select(selectIsAdministratorAccount)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isAdmin => (this.isAdmin = isAdmin));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode']) {
      this.rebuildForm();
    }

    if (changes['facility'] && changes['facility'].currentValue) {
      this.rebuildFormValues();
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public canUpdateProperty(property: keyof Facility): boolean {
    switch (property) {
      case 'customerId':
      case 'state':
        return this.isAdmin;

      default:
        return true;
    }
  }

  public submit() {
    if (this.form === undefined || !this.form?.valid) {
      return;
    }

    this.submit$.next(this.formBuilder.getFacilityValuesFromForm(this.form.value));
  }

  private async rebuildForm() {
    switch (this.mode) {
      case 'create':
        this.form = await this.formBuilder.getCreationForm();
        break;
      case 'update':
        this.form = await this.formBuilder.getUpdateForm(this.facility);
        break;
      default:
        this.form = undefined;
    }
  }

  private rebuildFormValues(): void {
    if (!this.form || !this.facility) {
      return;
    }

    const values = this.formBuilder.getFormValuesFromFacility(this.facility);
    this.formBuilder.addValuesInForm(this.form, values);
  }
}
