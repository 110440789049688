import { AgnosticRoleCollection } from '@application/bundles/authorization/implementations/agnostic-role-collection/agnostic-role-collection';
import { GetUserRolesQuery, GetUserRolesQueryResult } from '@application/bundles/user/queries/get-user-roles.query';
import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { Role } from '@domain/authorization';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';

@HandleQuery({
  query: GetUserRolesQuery,
})
export class GetUserRolesQueryHandler implements QueryHandler<GetUserRolesQuery, GetUserRolesQueryResult> {
  constructor(private readonly backend: EasyBackendApi) {}

  public handle(query: GetUserRolesQuery): Promise<GetUserRolesQueryResult> {
    return this.backend
      .get<{ roles: Role[] }>(`user/${query.id}/roles`)
      .then(response => new AgnosticRoleCollection(response.body.roles));
  }
}
