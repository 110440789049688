import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Bundle } from '@application/framework/bundle';
import { TaxonomyBundle } from '@application/bundles/taxonomy/taxonomy.bundle';

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
@Bundle({ imports: [TaxonomyBundle] })
export class TaxonomyModule {}
