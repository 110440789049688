import { Attribute, VoteResult } from '@application/bundles/authorization';
import { DecisionManager } from '@application/bundles/authorization/abstractions/decision.manager.abstract';
import { VoterRegistry } from '@application/bundles/authorization/abstractions/voter.registry.abstract';
import { AuthentificationToken } from '@application/bundles/authentification';

export class AgnosticDecisionManager implements DecisionManager {
  public allowIfAllAbstainDecisions: boolean = true;

  public constructor(
    private registry: VoterRegistry,
    allowIfAllAbstainDecisions = true,
  ) {
    this.allowIfAllAbstainDecisions = allowIfAllAbstainDecisions;
  }

  public async decide(token: AuthentificationToken, attributes: Attribute[], object: any = null): Promise<boolean> {
    let deny = 0;

    for (const voter of this.registry.all()) {
      const result = await voter.vote(token, object, attributes);

      if (VoteResult.GRANTED === result) {
        return true;
      }

      if (VoteResult.DENIED === result) {
        ++deny;
      }
    }

    if (deny > 0) {
      return false;
    }

    return this.allowIfAllAbstainDecisions;
  }
}
