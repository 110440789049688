import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '@implementations/forms/select-option.interface';
import { Observable } from 'rxjs';
import { isSelectOption } from '@implementations/forms';

@Component({
  selector: 'ehp-async-select',
  templateUrl: './async-select.component.html',
  styleUrls: ['./async-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectComponent),
      multi: true,
    },
  ],
})
export class AsyncSelectComponent implements OnInit, ControlValueAccessor {
  @Input() public options: Promise<SelectOption[]> | Observable<SelectOption[]> | undefined;

  @Input() public required = false;

  @Input() public emptyOption: boolean = false;

  @Input() public disabled: boolean = false;

  @Input() public filter = false;

  public readonly select = new FormControl<SelectOption[] | SelectOption | null | undefined>(undefined);

  public loaded = false;

  public loading = true;

  public opts: SelectOption[] = [];

  public selected: SelectOption['value'] | undefined;

  public placeholder = '- Sélectionner une option -';

  public get nodeName(): string {
    return 'ehp-async-select';
  }

  public get value() {
    return this.selected;
  }

  public set value(value: any) {
    this.writeValue(value);
  }

  public get selectedOption(): SelectOption | undefined {
    if (this.selected === undefined) {
      return undefined;
    }
    return this.opts.find(option => option.value === this.selected);
  }

  public compareFn = (a?: SelectOption, b?: SelectOption) => {
    return a?.value === b?.value;
  };

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    if (this.options instanceof Observable) {
      this.options.subscribe(
        options => {
          this.opts = options;
          this.loaded = true;
        },
        undefined,
        () => (this.loading = false),
      );
    } else if (this.options instanceof Promise) {
      this.options
        .then(options => {
          this.loaded = true;
        })
        .catch(e => {
          this.loaded = false;
          throw e;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  public onTouched = (): void => undefined;

  /**
   * @inheritDoc
   * @param fn
   */
  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * @inheritDoc
   * @param fn
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * @inheritDoc
   * @param isDisabled
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * @inheritDoc
   * @param value
   */
  public writeValue(value: SelectOption | string | number | null | undefined): void {
    if (value === null || value === undefined) {
      this.selected = undefined;
      return;
    }

    if (isSelectOption(value)) {
      this.selected = value.value;
    } else if (typeof value === 'string' || typeof value === 'number') {
      this.selected = value;
    }
  }

  public dispatchChange(selected: SelectOption[] | SelectOption | undefined) {
    this._onChange(selected);
  }

  public dropdownChange() {
    this.dispatchChange(this.selectedOption);
  }

  private _onChange = (_value: SelectOption[] | SelectOption | undefined): void => undefined;
}
