import { AppState } from '@easyhpad-ui/app/store';
import { HEALTH_FUNDING_STORE_KEY } from '@easyhpad-ui/app/bundles/health-funding/store/health-funding-store.key';
import { createSelector } from '@ngrx/store';
import { healthFundingTaxonomyAdapter } from '@easyhpad-ui/app/bundles/health-funding/store/health-funding.reducer';
import { TaxonomyTerm } from '@domain/taxonomy';
import { HealthTaxonomyType } from '@domain/health-funding';

const selectHealthFundingState = (state: AppState) => state[HEALTH_FUNDING_STORE_KEY];
const taxonomySelectors = healthFundingTaxonomyAdapter.getSelectors();

const selectTaxonomiesState = createSelector(selectHealthFundingState, state => state.taxonomies);

const selectTaxonomyState = (taxonomy: HealthTaxonomyType) =>
  createSelector(selectTaxonomiesState, state => state[taxonomy]);

const selectTaxonomyEntities = (taxonomy: HealthTaxonomyType) =>
  createSelector(selectTaxonomyState(taxonomy), state => taxonomySelectors.selectEntities(state));

const selectAllTaxonomyEntities = (taxonomy: HealthTaxonomyType) =>
  createSelector(selectTaxonomyState(taxonomy), state => taxonomySelectors.selectAll(state));

const selectTaxonomyTerm = (taxonomy: HealthTaxonomyType, id: TaxonomyTerm['id']) =>
  createSelector(selectTaxonomyEntities(taxonomy), state => state[id]);

export const healthFundingSelectors = {
  selectTaxonomyState,
  selectTaxonomyEntities,
  selectAllTaxonomyEntities,
  selectTaxonomyTerm,
};
