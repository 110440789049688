export type EventName = string;

export type DispatchableEvent = {
  name: EventName;
}

export type OnDispatchFunction<T extends DispatchableEvent> = (event: T) => void;

export interface EventSubscriber<T extends DispatchableEvent> extends OnDispatchFunction<T> {
}


export interface EventSubscriberMetadata {
  priority?: number;
}

export interface EventSubscriberOptions {
  sort?: PrioritySortOption;
}

export enum PrioritySortOption {
  DESC,
  ASC
}
