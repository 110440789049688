import { Transformer } from '@application/framework/core';
import { AVAILABLE_ROLES, Role } from '@domain/authorization';
import { roleLabelDictionary } from '@implementations/bundles/authorization/role-label.dictionary';
import { EasyRole } from '@implementations/bundles/backend/easyhpad/interfaces/easy-role.interface';
import { Service } from '@application/framework/di';

@Service()
export class EasyRoleTransformer implements Transformer<Role, EasyRole> {
  public transform(value: Role): EasyRole {
    return {
      id: value.id,
      name: value.name,
    };
  }

  public reverseTransform(value: EasyRole): Role {
    const label = roleLabelDictionary.get(value.name);
    return {
      id: value.id,
      name: value.name as AVAILABLE_ROLES,
      label: label || value.name,
    };
  }
}
