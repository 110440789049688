import { BILLING_MODE } from '@domain/customer';
import { Facility } from '@domain/facility';
import { UUID } from '@domain/lib';
import { EasyAddress, EasyCreateAddress } from './easy-address.interface';

export interface EasyCustomer {
  id: UUID;

  name: string;

  billingMode: BILLING_MODE;

  address: EasyAddress | undefined;

  billingAddress: EasyAddress | null | undefined;

  phone: string | null | undefined;

  blocked: boolean | null | undefined;

  createdAt: Date;

  updatedAt: Date;

  facilities: Facility[];
}

export interface EasyCreateCustomerDto
  extends Omit<EasyCustomer, 'id' | 'createdAt' | 'updatedAt' | 'facilities' | 'address' | 'billingAddress'> {
  address: EasyCreateAddress;

  billingAddress: EasyCreateAddress | undefined;
}

export interface EasyUpdateCustomerDto
  extends Omit<EasyCustomer, 'id' | 'createdAt' | 'updatedAt' | 'facilities' | 'address' | 'billingAddress'> {
  address?: EasyCreateAddress | EasyAddress;

  billingAddress: EasyCreateAddress | EasyAddress | undefined;
}
