import { PermanentDependencyFunding } from '@domain/dependency-funding';
import { isGroupedGirAmountAndResidents, isValidPointGIR } from '@domain/gir';
import { isNegativeFundingAmount } from '@domain/funding';
import { isUUID } from '@domain/lib';

export function isValidPermanentDependencyFundingIdType(id: any): id is PermanentDependencyFunding['id'] {
  return isUUID(id);
}

export function isMayBeAPermanentDependencyFunding(funding: any): funding is Omit<PermanentDependencyFunding, 'id'> {
  return (
    isGroupedGirAmountAndResidents(funding) &&
    ((funding as PermanentDependencyFunding).pointGIR === undefined ||
      isValidPointGIR((funding as PermanentDependencyFunding).pointGIR as number)) &&
    ((funding as PermanentDependencyFunding).activityAllowance === undefined ||
      isNegativeFundingAmount((funding as PermanentDependencyFunding).activityAllowance))
  );
}

export function isPermanentDependencyFunding(funding: any): funding is PermanentDependencyFunding {
  return (
    isMayBeAPermanentDependencyFunding(funding) &&
    isValidPermanentDependencyFundingIdType((funding as PermanentDependencyFunding).id)
  );
}
