import {
  CreateNonRecurringHealthFundingDto,
  UpdateNonRecurringHealthFundingDto
} from "@application/bundles/health-funding";

export abstract class NonRecurringHealthFundingDtoFactory {

  public abstract buildCreateDtoFromUnsafeValues(values: Partial<CreateNonRecurringHealthFundingDto>): CreateNonRecurringHealthFundingDto;

  public abstract buildUpdateDtoFromUnsafeValues(values: Partial<UpdateNonRecurringHealthFundingDto>): UpdateNonRecurringHealthFundingDto;

}
