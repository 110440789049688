import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Facility } from '@domain/facility';
import { SelectionModel } from '@angular/cdk/collections';
import { isValidFacilityIdType } from '@application/bundles/facility';

export class UniqueFacilitySelectorSource {
  private static readonly sources: Map<
    string,
    {
      s: UniqueFacilitySelectorSource;
      count: number;
    }
  > = new Map();

  /**
   * Facilities provided by parent.
   * @private
   */
  private input: Observable<Facility[]> | undefined;

  /**
   * Current facilities
   * @private
   */
  private source: Facility[] = [];

  /**
   * Provide Facilities to parent
   * @private
   */
  private readonly output: BehaviorSubject<Facility[]> = new BehaviorSubject<Facility[]>(this.source);

  /**
   * Current selection
   * @private
   */
  private readonly selection: SelectionModel<Facility['id']> = new SelectionModel(true);

  /**
   * Input subscription
   * @private
   */
  private subscription: Subscription | undefined;

  protected constructor(public readonly name: string) {}

  public static create(name: string): UniqueFacilitySelectorSource {
    let source = UniqueFacilitySelectorSource.sources.get(name);

    if (source === undefined) {
      source = { s: new UniqueFacilitySelectorSource(name), count: 0 };
    }

    source.count++;
    UniqueFacilitySelectorSource.sources.set(name, source);

    return source.s;
  }

  protected static destroy(name: string): void {
    const source = UniqueFacilitySelectorSource.sources.get(name);

    if (source === undefined) {
      return;
    }

    --source.count;

    if (source.count <= 0) {
      UniqueFacilitySelectorSource.sources.delete(name);
    }
  }

  public destroy() {
    this.subscription?.unsubscribe();
    this.output.complete();
    UniqueFacilitySelectorSource.destroy(this.name);
  }

  public attachSource(source: Observable<Facility[]>): this {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.input = source;
    this.subscription = this.input.subscribe(facilities => this.facilitiesHasChange(facilities));
    return this;
  }

  public facilities(): Observable<Facility[]> {
    return this.output.asObservable();
  }

  public select(facility: Facility | Facility['id']): void {
    if (isValidFacilityIdType(facility)) {
      this.selection.select(facility);
    } else {
      this.selection.select(facility.id);
    }

    this.dispatchOutput(this.source);
  }

  public deselect(facility: Facility | Facility['id']): void {
    if (isValidFacilityIdType(facility)) {
      this.selection.deselect(facility);
    } else {
      this.selection.deselect(facility.id);
    }

    this.dispatchOutput(this.source);
  }

  public toggle(facility: Facility | Facility['id']): void {
    if (isValidFacilityIdType(facility)) {
      this.selection.toggle(facility);
    } else {
      this.selection.toggle(facility.id);
    }
    this.dispatchOutput(this.source);
  }

  private dispatchOutput(facilities: Facility[]) {
    facilities = facilities.filter(facility => !this.selection.isSelected(facility.id));
    this.output.next(facilities);
  }

  private facilitiesHasChange(facilities: Facility[]) {
    this.source = facilities;

    const remove: Array<Facility['id']> = [];

    this.selection.selected.forEach(selected => {
      if (!facilities.find(facility => facility.id === selected)) {
        remove.push(selected);
      }
    });

    if (remove.length > 0) {
      this.selection.deselect(...remove);
    }

    this.dispatchOutput(facilities);
  }
}
