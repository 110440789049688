import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SourceComponent } from './components';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';

@NgModule({
  declarations: [SourceComponent],
  imports: [CommonModule, TranslationModule],
  exports: [SourceComponent],
})
export class SourcedDataModule {}
