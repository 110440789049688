import { UUID } from '@domain/lib';

export interface EasyCPOMDetailFunding {
  id: UUID;

  amount: number;

  name: string;

  comment?: string | null;
}

export interface EasyCreateCPOMDetailFunding extends Omit<EasyCPOMDetailFunding, 'id'> {}

export interface EasyUpdateCPOMDetailFunding extends EasyCPOMDetailFunding {}
