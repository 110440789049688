import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {validateOrReject} from "class-validator";
import {
  UpdateCapacityAuthorizationDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/update-capacity-authorization.dto";

export const validateUpdateCapacityAuthorizationDtoImpl: ValidateFunction<UpdateCapacityAuthorizationDtoImpl> = async (dto: UpdateCapacityAuthorizationDtoImpl) => {
  await validateOrReject(dto);

  return dto;
}
