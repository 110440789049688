import { AddCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos';
import { UpdateCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto';
import { CapacityAuthorizationRepository } from '@domain/capacity-authorization/capacity-authorization.repository';
import { EasyAddCapacityAuthorizationTransformer } from '@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-add-capacity-authorization.transformer';
import { EasyCapacityAuthorizationTransformer } from '@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-capacity-authorization.transformer';
import { EasyUpdateCapacityAuthorizationTransformer } from '@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-update-capacity-authorization.transformer';
import { Provide, Service } from '@application/framework/di';
import { DocumentRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { CapacityAuthorization } from '@domain/capacity-authorization';
import { EasyCapacityAuthorization } from '@implementations/bundles/backend/easyhpad/interfaces/easy-capacity-authorization.interface';

@Service()
export class EasyCapacityAuthorizationRepository
  extends DocumentRepository<any, any, CapacityAuthorization, EasyCapacityAuthorization>
  implements CapacityAuthorizationRepository
{
  @Provide() private readonly entityTransformer!: EasyCapacityAuthorizationTransformer;

  @Provide() private readonly createTransformer!: EasyAddCapacityAuthorizationTransformer;

  @Provide() private readonly updateTransformer!: EasyUpdateCapacityAuthorizationTransformer;

  constructor() {
    super('capacity-authorization', {
      from: input => this.entityTransformer.transform(input),
      to: output => this.entityTransformer.reverseTransform(output),
      create: (input: AddCapacityAuthorizationDto) => this.createTransformer.transform(input),
      update: (input: UpdateCapacityAuthorizationDto) => this.updateTransformer.transform(input),
    });
  }
}
