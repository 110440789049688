import {Component} from '@angular/core';
import {GetLatestGMPQuery} from "@application/bundles/gmp";
import {QueryBus} from "@application/framework/command-query";
import {Subscription} from "@application/framework/reactive";
import {Facility} from "@domain/facility";
import {GMP} from "@domain/gmp";
import {DashboardWidgetContent} from "@easyhpad-ui/app/bundles/dashboard/interfaces/dashboard-widget-content.reference";
import {GmpUrlsProvider} from "@easyhpad-ui/app/bundles/gmp";
import {DashboardFacilityAccessor} from "@implementations/bundles/facility/accessor/dashboard-facility-accessor";

@Component({
  selector: 'ehp-facility-gmp-widget',
  templateUrl: './facility-gmp-widget.component.html',
  styleUrls: ['./facility-gmp-widget.component.scss']
})
export class FacilityGmpWidgetComponent implements DashboardWidgetContent {

  public facility: Facility | undefined;

  public gmp: GMP | undefined;

  public url: string | undefined;

  private subscription: Subscription;

  constructor(
    private readonly accessor: DashboardFacilityAccessor,
    private readonly queryBus: QueryBus,
    private readonly urls: GmpUrlsProvider,
  ) {
    this.facility = this.accessor.get();
    this.subscription = this.accessor.onChange((facility) => this.facilityChange(facility));
  }

  private async facilityChange(facility: Facility | undefined) {

    this.facility = facility;
    let gmp: GMP | undefined;

    if (facility !== undefined) {
      gmp = await this.queryBus.request(new GetLatestGMPQuery(facility.id));
    }

    await this.gmpChange(gmp);
  }

  private async gmpChange(gmp: GMP | undefined) {

    this.gmp = gmp;
    this.url = undefined;

    if (this.gmp !== undefined) {
      this.urls.getPublicShow(this.gmp.id).then((url) => this.url = url);
    } else {
      this.urls.getPublicList().then(url => this.url = url);
    }
  }
}
