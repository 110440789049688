import { FileDepositItem } from '@domain/file-deposit';
import { isValidYear } from '@domain/lib';
import { isValidMediaIdType } from '@application/bundles/media';
import { isDocumentType } from '@domain/document';

export function isMaybeAFileDepositItem(item: any): item is Omit<FileDepositItem, 'id' | 'parentId'> {
  return (
    typeof item === 'object' &&
    item !== null &&
    'type' in item &&
    isDocumentType(item.type) &&
    'mediaId' in item &&
    isValidMediaIdType(item.mediaId) &&
    (item.year === null || isValidYear(item.year)) &&
    'lock' in item &&
    typeof item.lock === 'boolean'
  );
}
