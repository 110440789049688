import {Request, REQUEST_METHOD, Response} from "@application/framework/http/request.interface";


export abstract class HttpRequestClient {


  public abstract request<R>(request: Request): Promise<Response<R>>;


  public abstract get<R>(request: Request<REQUEST_METHOD.GET>): Promise<Response<R>>;


  public abstract post<R>(request: Request<REQUEST_METHOD.POST>): Promise<Response<R>>;


  public abstract patch<R>(request: Request<REQUEST_METHOD.PATCH>): Promise<Response<R>>;


  public abstract delete<R>(request: Request<REQUEST_METHOD.DELETE>): Promise<Response<R>>;

}
