import { CreatePMPDto, PMPListParams, UpdatePMPDto } from '@application/bundles/pmp';
import { PMP } from '@domain/pmp';
import { Facility } from '@domain/facility';

export abstract class PMPRepository {
  public abstract list(params?: PMPListParams): Promise<PMP[]>;

  public abstract get(id: PMP['id']): Promise<PMP>;

  public abstract create(id: CreatePMPDto): Promise<PMP>;

  public abstract update(id: PMP['id'], update: Partial<UpdatePMPDto>): Promise<PMP>;

  public abstract delete(id: PMP['id']): Promise<PMP>;

  public abstract latest(params?: PMPListParams): Promise<PMP[]>;

  public abstract latestForFacility(facilityId: Facility['id']): Promise<PMP | undefined>;
}
