import {Attribute, AuthorizationChecker} from "@application/bundles/authorization";
import {ForbiddenError} from "@application/bundles/authorization/error";
import {DeleteCustomerCommand} from "@application/bundles/customer/commands/delete-customer.command";
import {CUSTOMER_FEATURE, CUSTOMER_TRANSLATE_CONTEXT} from "@application/bundles/customer/customer.token";
import {CustomerDeletedEvent} from "@application/bundles/customer/events/delete-customer/customer-deleted.event";
import {
  CustomerDeletionFailEvent
} from "@application/bundles/customer/events/delete-customer/customer-deletion-fail.event";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {Translator} from "@application/framework/translation";
import {Customer} from "@domain/customer";
import {CustomerRepository} from "@application/bundles/customer";

@HandleCommand({
  command: DeleteCustomerCommand
})
export class DeleteCustomerCommandHandler implements CommandHandler<DeleteCustomerCommand, Customer> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private repository: CustomerRepository,
    private authorization: AuthorizationChecker,
    private eventDispatcher: EventDispatcher,
    private translator: Translator,
  ) {
  }

  public async handle(command: DeleteCustomerCommand): Promise<Customer> {

    const {customer} = command;
    try {
      await this.checkAccess(customer)
        .then(() => this.repository.delete(customer.id));

      this.eventDispatcher.dispatch(new CustomerDeletedEvent(customer));

      return customer;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }

  }

  private async checkAccess(customer: Customer): Promise<void> {
    const attributes: Attribute[] = [
      {feature: CUSTOMER_FEATURE, value: 'delete'}
    ];

    if (!await this.authorization.isGranted(attributes, customer)) {
      this.logger.error('Customer deletion : Forbidden');
      throw new ForbiddenError();
    }
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message = '';

    if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à supprimer ce client.",
        undefined,
        CUSTOMER_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new CustomerDeletionFailEvent(message));

    return error;
  }

}
