<p-listbox (onChange)="changed($event)"
           [(ngModel)]="internalSelectedOptions"
           [checkbox]="true"
           [disabled]="disabled"
           [filterPlaceHolder]="placeholder"
           [filter]="true"
           [metaKeySelection]="false"
           [multiple]="true"
           [options]="options"
           emptyFilterMessage="{{'Aucun résultat trouvé.' | ehp_translate }}"
           emptyMessage="{{'Aucun résultat trouvé.' | ehp_translate}}"
           optionLabel="label">
</p-listbox>
