import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDepositsDashboardMessageComponent } from './components';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { RouterModule } from '@angular/router';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';

@NgModule({
  imports: [CommonModule, RouterModule, TranslationModule, ReusableComponentsModule],
  declarations: [FileDepositsDashboardMessageComponent],
  exports: [FileDepositsDashboardMessageComponent],
})
export class FileDepositDashboardModule {}
