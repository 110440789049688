import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {CreateLocalCPOMDtoImpl, UpdateLocalCPOMDtoImpl} from "@implementations/bundles/cpom";
import {validateOrReject} from "class-validator";

export const createLocalCPOMDtoValidator: ValidateFunction<CreateLocalCPOMDtoImpl> = async (dto: CreateLocalCPOMDtoImpl): Promise<CreateLocalCPOMDtoImpl> => {
  await validateOrReject(dto);
  return dto;
}

export const updateLocalCPOMDtoValidator: ValidateFunction<UpdateLocalCPOMDtoImpl> = async (dto: UpdateLocalCPOMDtoImpl): Promise<UpdateLocalCPOMDtoImpl> => {
  await validateOrReject(dto);
  return dto;
}
