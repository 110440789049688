<ng-container *ngIf="action" [ngSwitch]="action.type">

  <ng-container *ngSwitchCase="'link'">
    <a *ngIf="$any(action).url" [ngClass]="action.classes" [routerLink]="$any(action).url">
      <ehp-translatable-string [value]="action.label"></ehp-translatable-string>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'button'">

    <button (click)="$any(action).onClick({event: $event, action})" *ngIf="$any(action).onClick"
            [ngClass]="action.classes"
            type="button">
      <ehp-translatable-string [value]="action.label"></ehp-translatable-string>
    </button>

  </ng-container>

</ng-container>
