<ehp-form-field *ngIf="control">
  <label *ngIf="field.label" [for]="field.name">
    <ehp-translatable-string [value]="field.label"></ehp-translatable-string>
  </label>

  <p *ngIf="field.description" class="description">
    <ehp-translatable-string [value]="field.description"></ehp-translatable-string>
  </p>

  <p-dropdown
    #select
    (onChange)="selectChange($event)"
    [(ngModel)]="selected"
    [options]="options"
    [placeholder]="placeholder"
    ehp-form-control
    optionLabel="label"
  >
    <ng-template let-option pTemplate="item">
      <ehp-translatable-string [value]="option.label"></ehp-translatable-string>
    </ng-template>

  </p-dropdown>

</ehp-form-field>
