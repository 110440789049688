import {Selection, SelectionChange} from "@application/framework/core";
import {BehaviorSubject, map, Observable} from "rxjs";
import {TableColumn} from "../table-column";

export class TableColumnsList<Key = string> {

  public readonly selection: Selection<Key> = new Selection();

  public _keyChange: BehaviorSubject<Key[]>;

  public get selected() {
    return this.selection.selected;
  }

  public get keys(): Key[] {
    return this.columns.map(column => column.key);
  }

  public get keys$(): Observable<Key[]> {
    return this._keyChange;
  }

  public get columns$(): Observable<TableColumn<Key>[]> {
    return this._keyChange.asObservable().pipe(map(keys => this.columns.filter(c => keys.includes(c.key))));
  }

  public get labels() {
    return this.columns.map(column => column.label);
  }

  constructor(public readonly columns: TableColumn<Key>[], public readonly settingName: string) {
    this._keyChange = new BehaviorSubject<Key[]>(this.keys);
    this.select(...columns.map(column => column.key));
  }

  public label(key: Key): string | undefined {
    const column = this.columns.find(column => column.key === key);
    return column ? column.label : undefined;
  }

  public isSortable(key: Key): boolean {
    const column = this.columns.find(column => column.key === key);
    return !!(column && column.sortable);
  }

  public select(...keys: Key[]): void {
    const change = this.selection.select(...keys);
    this.dispatchChanges(change);
  }

  public deselect(...keys: Key[]): void {
    const change = this.selection.deselect(...keys);
    this.dispatchChanges(change);

  }

  public toggle(key: Key): void {
    const change = this.selection.toggle(key);
    this.dispatchChanges(change);
  }


  private dispatchChanges(change: SelectionChange<Key>) {
    this._keyChange.next(this.keys.filter(key => change.current.includes(key)));
  }


}
