import {AuthentificationStrategy} from "@application/bundles/authentification/abstraction/authentication-strategy";
import {AuthentificationToken} from "@application/bundles/authentification/abstraction/authentification-token";

export type ConnectCredential = Record<any, any>;

export type ConnectionResult<T = unknown> = {
  success: boolean;

  token: AuthentificationToken | undefined;

  extras: T
}

export type DisconnectCredential = Record<any, any>;

export type DisconnectionResult<T = unknown> = {
  success: boolean;
  token: AuthentificationToken | undefined;
  extras: T
}

export abstract class Authenticator {

  /**
   * Set authentification strategy to use
   * during the authentification phase.
   * @param strategy
   */

  public abstract withStrategy(strategy: Partial<AuthentificationStrategy>): Authenticator;

  /**
   * Run authentification strategy.
   */
  public abstract connect<T = any>(): Promise<ConnectionResult<T>>;

  /**
   * Run authentification strategy.
   * @param credentials
   */
  public abstract connect<T = any>(credentials: ConnectCredential): Promise<ConnectionResult<T>>;

  /**
   * Run strategy disconnect
   */
  public abstract disconnect<T = any>(): Promise<DisconnectionResult<T>>;

  /**
   * Run strategy disconnect
   * @param credentials
   */
  public abstract disconnect<T = any>(credentials: DisconnectCredential): Promise<DisconnectionResult<T>>;

}
