<header class="header document-list-header">
  <div class="title">{{'Nom du document' | ehp_translate}}</div>
  <div class="dates">{{'Dates' | ehp_translate}}</div>
  <div class="amount">{{"Montant" | ehp_translate}}</div>
  <div class="facility">{{'Établissements' | ehp_translate}}</div>
  <div class="actions">{{'Actions' | ehp_translate}}</div>
</header>

<ng-container *ngIf="documents && documents.length > 0; else emptyList">

  <ng-container *ngFor="let document of documents">
    <ehp-ehpad-demain-list-item [document]="document"></ehp-ehpad-demain-list-item>
  </ng-container>
</ng-container>

<ng-template #emptyList>
  <ehp-empty-block>{{'Aucun EHPAD de demain archivé pour le moment.' | ehp_translate}}</ehp-empty-block>
</ng-template>
