import {DocumentListParamsImpl} from "@application/framework/search";
import {ActivityAnnexSearchParams} from "@application/bundles/activity-annex";
import {SORT_ORDER} from "@application/framework/search/sort";

export class ListActivityAnnexParamsImpl extends DocumentListParamsImpl implements ActivityAnnexSearchParams {
  constructor() {
    super();
    this.setSort({key: 'date', order: SORT_ORDER.DESC});
  }
}
