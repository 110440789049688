<ng-container *ngIf="option">
  <ehp-translatable-string [value]="option.label" class="value"></ehp-translatable-string>

  <p *ngIf="amount" class="amount">
    <span class="value">{{amount.value | currency }}</span>
    <span *ngIf="amount.isOverseas" class="label">{{'Outre-mer' | ehp_translate}}</span>
  </p>


</ng-container>
