import {HandleQuery, QueryBus, QueryHandler} from "@application/framework/command-query";
import {Facility} from "@domain/facility";
import {FacilityRepository} from "@domain/facility/facility.repository";
import {ListUserFacilitiesQuery} from "@application/bundles/facility/query/list-user-facilities.query";
import {
  AgnosticFacilitySearchParams
} from "@application/bundles/facility/implementations/agnostic-facility-search-params";
import {isValidCustomerIdType} from "@application/bundles/customer";

@HandleQuery({
  query: ListUserFacilitiesQuery
})
export class ListUserFacilitiesQueryHandler implements QueryHandler<ListUserFacilitiesQuery, Facility[]> {

  constructor(
    private readonly repository: FacilityRepository,
    private readonly queryBus: QueryBus
  ) {
  }


  public async handle(query: ListUserFacilitiesQuery): Promise<Facility[]> {
    const {user} = query;

    const params = new AgnosticFacilitySearchParams();

    if (Array.isArray(user.facilityIds) && user.facilityIds.length > 0) {
      params.setPagination({limit: -1});

    } else if (isValidCustomerIdType(user.customerId)) {
      params.setCustomer(user.customerId);
    } else {
      throw new Error(`The user is not associated with a customer or one or more facilities.`);
    }

    return this.repository.list(params);
  }
}
