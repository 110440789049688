import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  DynamicAbstractControlField,
  DynamicTaxonomyField,
  HasDynamicField,
} from '@easyhpad-ui/app/library/form/contracts';
import { FormControlDirective } from '@easyhpad-ui/app/library/form/directives/form-control/form-control.directive';
import { AbstractControl, FormControl, NgModel } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { syncControlsDirty, syncControlsTouch } from '@easyhpad-ui/app/library/form/functions';
import { TaxonomyTerm } from '@domain/taxonomy';

@Component({
  selector: 'ehp-dynamic-taxonomy-tree-selector',
  templateUrl: './dynamic-taxonomy-tree-selector.component.html',
  styleUrl: './dynamic-taxonomy-tree-selector.component.scss',
})
export class DynamicTaxonomyTreeSelectorComponent
  implements HasDynamicField<DynamicTaxonomyField>, OnInit, AfterViewInit, OnDestroy
{
  /**
   * Field definition
   */
  @Input() public readonly field!: DynamicAbstractControlField<DynamicTaxonomyField>;

  /**
   * Input Form control reference
   */
  @ViewChild('input', { read: FormControlDirective }) public formControl!: FormControlDirective;

  /**
   * Angular model directive
   */
  @ViewChild('input', { read: NgModel }) public ngModel!: NgModel;

  public terms: TaxonomyTerm[] = [];

  private destroy$ = new Subject<void>();

  private value: any;

  public get control(): FormControl | null {
    return this.field.control ? (this.field.control as FormControl) : null;
  }

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  public ngOnInit() {
    if (this.field.initialValue) {
      this.field.initialValue.pipe(takeUntil(this.destroy$)).subscribe(value => this.setInitialValue(value));
    }

    this.field.termsChanges.pipe(takeUntil(this.destroy$)).subscribe(terms => (this.terms = terms));

    if (this.control) {
      this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => this.patchInternalValue(value));
    }
  }

  public ngAfterViewInit(): void {
    if (this.control) {
      if (this.formControl) {
        this.formControl.attachControl(this.control);
      }

      if (this.ngModel) {
        //  this.linkModelAndControl(this.ngModel, this.control);
      }
    }

    this.changeDetector.detectChanges();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Update field control value.
   * @param value
   */
  public updateValue(value: number | null): void {
    this.control?.setValue(value);
  }

  /**
   * Set value if field is untouched
   * @param value
   * @private
   */
  private setInitialValue(value: any) {
    if (this.control?.touched || this.control?.dirty) {
      return;
    }

    this.patchInternalValue(value);

    if (
      (value === null || value === undefined) &&
      (this.control?.value === null || this.control?.value === undefined)
    ) {
      return;
    }
    this.control?.patchValue(value);
  }

  /**
   * Update value only if the value is not the same as this.value
   * @param value
   * @private
   */
  private patchInternalValue(value: number | null | undefined): void {
    if (this.value !== this.transformControlValue(value)) {
      this.value = this.transformControlValue(value);
    }
  }

  private transformControlValue(value: any): number | null {
    if (value === null || value === undefined || isNaN(value)) {
      return null;
    }

    if (typeof value === 'number' && isFinite(value)) {
      return value;
    }

    if (typeof value === 'string') {
      return parseFloat(value);
    }

    return null;
  }

  private linkModelAndControl(model: NgModel, control: AbstractControl): void {
    syncControlsDirty(model.control, control);
    syncControlsTouch(model.control, control);

    if (control.dirty) {
      model.control.markAsDirty();
    }

    if (control.touched) {
      model.control.markAsTouched();
    }
  }
}
