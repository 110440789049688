import {DependencyFundingRepository, GetCurrentDependencyFundingQuery} from "@application/bundles/dependency-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {DependencyFunding} from "@domain/dependency-funding";

@HandleQuery({
  query: GetCurrentDependencyFundingQuery
})
export class GetCurrentDependencyFundingQueryHandler implements QueryHandler<GetCurrentDependencyFundingQuery, DependencyFunding | undefined> {


  constructor(private readonly repository: DependencyFundingRepository) {
  }

  public async handle(query: GetCurrentDependencyFundingQuery): Promise<DependencyFunding | undefined> {
    const results = await this.repository.list();

    return results[0];
  }
}
