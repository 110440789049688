import {createAction, props} from '@ngrx/store';
import {Customer} from "@domain/customer";
import {CustomerSearchParams} from "@application/bundles/customer";


export enum CUSTOMER_ACTION_TYPES {
  ADMIN_LOAD = '[Customer Admin] Load customers',
  ADMIN_LOADED = '[Customer Admin] Set customers',
  SET_CURRENT = '[Customer] Set current customer',
  LOAD_AVAILABLE = '[Customer] Load current available customers',
  SET_AVAILABLE = '[Customer] Set current available customers',
  RESET = '[Customer] Reset customer store',

}

const loadAdminCustomers = createAction(CUSTOMER_ACTION_TYPES.ADMIN_LOAD, props<{ params?: CustomerSearchParams }>());

const setAdminCustomers = createAction(CUSTOMER_ACTION_TYPES.ADMIN_LOADED, props<{ customers: Customer[] }>());

const setCurrentCustomer = createAction(CUSTOMER_ACTION_TYPES.SET_CURRENT, props<{ customer: Customer | undefined }>());

const loadAvailableCustomers = createAction(CUSTOMER_ACTION_TYPES.LOAD_AVAILABLE);

const setAvailableCustomers = createAction(CUSTOMER_ACTION_TYPES.SET_AVAILABLE, props<{ customers: Customer[] }>());

const resetCustomerStore = createAction(CUSTOMER_ACTION_TYPES.RESET);

export const CustomerActions = Object.freeze({
  loadAdminCustomers,
  setAdminCustomers,
  setCurrentCustomer,
  loadAvailableCustomers,
  setAvailableCustomers,
  resetCustomerStore
})
