import {SerializedAccommodationFundingSearchParams} from "@application/bundles/accommodation-funding";
import {Query} from "@application/framework/command-query";
import {AccommodationFunding} from "@domain/accommodation-funding";

export class ListAccommodationFundingQuery implements Query<AccommodationFunding[]> {

  constructor(public readonly params?: SerializedAccommodationFundingSearchParams) {
  }

}
