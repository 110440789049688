import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isValidYear, Year } from '@domain/lib';
import { QueryBus } from '@application/framework/command-query';
import { ConsolidatedPointGirValuation } from '@domain/gir';
import { ListGirValuationForYearQuery } from '@application/bundles/gir/queries';
import { Table } from 'primeng/table';

@Component({
  selector: 'ehp-point-gir-valuation-list',
  templateUrl: './point-gir-valuation-list.component.html',
  styleUrls: ['./point-gir-valuation-list.component.scss'],
})
export class PointGirValuationListComponent implements OnChanges {
  @Input() public year!: Year;

  public values: ConsolidatedPointGirValuation[] = [];

  constructor(private readonly queryBus: QueryBus) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('year' in changes) {
      this.loadValuations();
    }
  }

  public filter(table: Table, event: Event) {
    if (event.target && 'value' in event.target) {
      table.filterGlobal(event.target['value'], 'contains');
    }
  }

  public clear(table: Table) {
    table.clear();
  }

  private loadValuations(): void {
    if (!isValidYear(this.year)) {
      this.values = [];
      return;
    }

    this.queryBus.request<ConsolidatedPointGirValuation[]>(new ListGirValuationForYearQuery(this.year)).then(values => (this.values = values));
  }
}
