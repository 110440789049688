import { DocumentViewProcessorProvider } from '@easyhpad-ui/app/bundles/documents/contracts/document-view';
import { Observable } from 'rxjs';
import { TranslatableString } from '@application/framework/translation';
import { ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface DocumentCreateViewContainer {
  readonly formContainer: ViewContainerRef;

  /**
   * The current route
   */
  readonly route: ActivatedRoute;
}

export interface DocumentCreateViewProcessorInstance {
  /**
   * The title trails displayed in DocumentSingleViewContainer Component
   */
  title: Observable<Array<string | TranslatableString>>;

  /**
   * Call this method to destroy the component instance.
   */
  destroy: () => void;
}

export interface DocumentCreateViewProcessor {
  /**
   * Attach subcomponents in the parent view.
   * @param parent
   */
  buildCreateView: (parent: DocumentCreateViewContainer) => DocumentCreateViewProcessorInstance;
}

/**
 * Provide the processor to build a Document create page component for a type of document
 */
export type DocumentCreateViewProvider<C extends DocumentCreateViewProcessor> = DocumentViewProcessorProvider<
  'create',
  C
>;
