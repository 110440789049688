<ng-container *ngIf="panels && panels.length > 0">


    <ng-container *ngFor="let panel of panels">

        <ehp-panel [valid]="isValidPanel(panel)">

            <ng-template panelHeader>
                <ehp-translatable-string *ngIf="panel.label" [value]="panel.label"></ehp-translatable-string>
            </ng-template>


            <ehp-dynamic-form-element
              *ngFor="let field of asArray(panel.fields)"
              [field]="field">
            </ehp-dynamic-form-element>


        </ehp-panel>


    </ng-container>


</ng-container>
