import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {ListOtherLocalCpomQuery} from "@application/bundles/cpom/queries/list-other-local-cpom.query";
import {CPOMRepository, StoredLocalCPOM} from "@application/bundles/cpom";

@HandleQuery({
  query: ListOtherLocalCpomQuery
})
export class ListOtherLocalCpomQueryHandler implements QueryHandler<ListOtherLocalCpomQuery, StoredLocalCPOM[]> {


  public constructor(private readonly repository: CPOMRepository) {
  }

  public async handle(query: ListOtherLocalCpomQuery): Promise<StoredLocalCPOM[]> {
    const documents: StoredLocalCPOM[] = [];

    const roots = await this.repository.list();

    roots.forEach(cpom => {

      if (Array.isArray(cpom.children)) {
        cpom.children.forEach(child => {

          if (child.facilityId === query.localCPOM.facilityId && child.id !== query.localCPOM.id) {
            documents.push(child);
          }
        });
      }
    });

    return documents;
  }

}
