import { Command } from '@application/framework/command-query';
import { UpdateFileDepositItem } from '@application/bundles/file-deposit/contracts';
import { FileDepositItem } from '@domain/file-deposit';

export class UpdateFileDepositItemCommand implements Command {
  constructor(
    public readonly id: FileDepositItem['id'],
    public readonly update: UpdateFileDepositItem,
  ) {}
}
