import { ElementRef, Renderer2 } from '@angular/core';
import { DATE_FORMATS, DateFormatter } from '@application/framework/date';
import { Provide } from '@application/framework/di';
import { DynamicField, WithMinAndMax, WithStep } from '@easyhpad-ui/app/library/form/contracts';

export class DynamicInputPropertySetter {
  @Provide()
  private readonly dateFormatter!: DateFormatter;

  constructor(private readonly renderer: Renderer2) {}

  public defineProperties(reference: ElementRef, properties: DynamicField & WithMinAndMax<any> & WithStep) {
    const element: HTMLElement = reference.nativeElement;

    if ('min' in properties) {
      let value: string | null = null;

      if (typeof (properties as any).min === 'number') {
        value = (properties as any).min.toString();
      } else if ((properties as any).min instanceof Date) {
        value = this.dateFormatter.format(properties.min, DATE_FORMATS.SHORT_HTML);
      }

      if (value !== null) {
        this.renderer.setAttribute(element, 'min', value);
      }
    }

    if ('max' in properties) {
      let value: string | null = null;

      if (typeof (properties as any).max === 'number') {
        value = (properties as any).max.toString();
      } else if ((properties as any).max instanceof Date) {
        value = this.dateFormatter.format(properties.max, DATE_FORMATS.SHORT_HTML);
      }

      if (value !== null) {
        this.renderer.setAttribute(element, 'max', value);
      }
    }

    if ('step' in properties && typeof properties.step === 'number') {
      this.renderer.setAttribute(element, 'step', properties.step.toString());
    }

    if ('pattern' in properties && typeof properties.pattern === 'string' && properties.pattern !== '') {
      this.renderer.setAttribute(element, 'pattern', properties.pattern);
    }
  }
}
