import {Component, Input, ViewEncapsulation} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {v4} from 'uuid';

@Component({
  selector: 'ehp-form-cpom-funding-inputs-row',
  templateUrl: './form-cpom-funding-inputs-row.component.html',
  styleUrls: ['./form-cpom-funding-inputs-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormCpomFundingInputsRowComponent {

  @Input() public form!: FormGroup;

  private readonly id = v4();

  public getControlId(name: string): string {
    return `${this.id}-${name}`;
  }

}
