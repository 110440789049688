import {Query} from "@application/framework/command-query";
import {PMP} from "@domain/pmp";
import {Facility} from "@domain/facility";

export class GetPMPForYearQuery implements Query<PMP | undefined> {

  constructor(public readonly facilityId: Facility['id'], public readonly year: number) {
  }

}
