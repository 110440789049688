<ng-container *ngIf="form" [formGroup]="form">

    <ehp-form-field>
        <label [for]="getControlId('facility')">{{'Établissement concerné' | ehp_translate }}</label>

        <ehp-unique-facility-selector
          (facilityChange)="updateFacility($event)"
          [id]="getControlId('facility')"
          ehp-form-control
          formControlName="facilityId"
          name="cpom-edit-facility-id"
          scope="all"
        ></ehp-unique-facility-selector>

    </ehp-form-field>

    <ehp-form-field>
        <label [for]="getControlId('tariffOption')">{{'Option tarifaire retenue' | ehp_translate }}</label>

        <ehp-tariff-option-select ehp-form-control formControlName="tariffOption"></ehp-tariff-option-select>

    </ehp-form-field>

    <ehp-form-field>
        <label [for]="getControlId('bedSocialCare')">
            {{'Nombre de lits habilités à l’aide sociale' | ehp_translate }}
        </label>

        <input
          [formControlName]="socialBedCareControlName"
          [id]="getControlId('bedSocialCare')"
          ehp-form-control
          type="number">

    </ehp-form-field>

    <ehp-form-field formArrayName="funding">

        <label [for]="getControlId('funding')">{{'Financements complémentaires' | ehp_translate }}</label>

        <ehp-form-cpom-funding-table [formArray]="fundingFormArray" [id]="getControlId('funding')" ehp-form-control>
        </ehp-form-cpom-funding-table>
    </ehp-form-field>

</ng-container>
