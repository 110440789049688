import { UpdateCPOMDto, UpdateLocalCPOMDto } from '@application/bundles/cpom';
import { isValidMediaIdType } from '@application/bundles/media';
import { isLocalMedia, isMedia, LocalMedia, Media } from '@domain/media';
import { CPOMCommonDtoAbstract } from '@implementations/bundles/cpom/dto/cpom-common.dto.abstract';
import { IsLocalMediaOrMedia, IsMediaId } from '@implementations/bundles/media';
import { IsArray, IsOptional } from 'class-validator';
import { CreateCPOMAnnex, UpdateCPOMAnnex } from '@application/bundles/cpom/dto/cpom-annex.dto';

export class UpdateCPOMDtoImpl extends CPOMCommonDtoAbstract implements UpdateCPOMDto {
  @IsArray()
  public children!: UpdateLocalCPOMDto[];

  @IsOptional()
  @IsMediaId()
  public documentId?: Media['id'];

  @IsOptional()
  @IsLocalMediaOrMedia()
  public document?: LocalMedia | Media;

  @IsArray()
  public annexes: Array<CreateCPOMAnnex | UpdateCPOMAnnex> = [];

  constructor(values?: Partial<UpdateCPOMDto>) {
    super(values);

    if (values) {
      if (Array.isArray(values.children)) {
        this.children = values.children;
      }

      if (isValidMediaIdType(values.documentId)) {
        this.documentId = values.documentId;
      }

      if (isMedia(values.document) || isLocalMedia(values.document)) {
        this.document = values.document;
      }

      if (Array.isArray(values.annexes)) {
        this.annexes = values.annexes;
      }
    }
  }
}
