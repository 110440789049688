import { Component, Input, OnInit } from '@angular/core';
import { values } from '@domain/lib';
import {
  DynamicAbstractControlField,
  DynamicFieldGroup,
  DynamicFormElementType,
  DynamicFormElementTypeComponent,
} from '@easyhpad-ui/app/library/form/contracts';
import { isObservable } from 'rxjs';

@Component({
  selector: 'ehp-dynamic-form-group',
  templateUrl: './dynamic-form-group.component.html',
  styleUrl: './dynamic-form-group.component.scss',
})
export class DynamicFormGroupComponent implements DynamicFormElementTypeComponent<DynamicFieldGroup<any>>, OnInit {
  @Input() public readonly field!: DynamicAbstractControlField<DynamicFieldGroup<any>>;

  public fields: Array<DynamicFormElementType<any>> = [];

  public containerClass = new Set<string>(['fields-container']);

  public get notices() {
    return isObservable(this.field.notices) ? this.field.notices : undefined;
  }

  public ngOnInit() {
    this.fields = values(this.field.fields);

    if (this.field.containerClass instanceof Set) {
      this.containerClass.forEach(entry => this.field.containerClass?.add(entry));
      this.containerClass = this.field.containerClass;
    }
  }
}
