import { ConsolidatedDepartmentGMPIndicator } from '@domain/gmp';
import { DepartmentCode } from '@domain/geo';

export abstract class DepartmentGMPIndicatorRepository {
  public abstract listLatest(params?: {
    departmentCodes?: DepartmentCode[];
  }): Promise<ConsolidatedDepartmentGMPIndicator[]>;

  public abstract getLatestForDepartment(
    departmentCode: DepartmentCode,
  ): Promise<ConsolidatedDepartmentGMPIndicator | undefined>;
}
