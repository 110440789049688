<form (submit)="submit()" *ngIf="form" [formGroup]="form" novalidate>


  <div *ngIf="canUpdateProperty('customerId')" class="form-row">
    <div class="row-header">
      <h2 class="title row-title">{{ 'Client' | ehp_translate }}</h2>
      <p class="description">
        {{ "Le client propriétaire de cet établissement." | ehp_translate }}
      </p>
    </div>

    <div class="row-content">

      <ehp-form-field>
        <label for="customer">{{'Client' | ehp_translate }}</label>
        <ehp-customer-select
          [customers$]="customer$"
          ehp-form-control
          formControlName="customerId"
          id="customer">
        </ehp-customer-select>


      </ehp-form-field>
    </div>

  </div>


  <div class="form-row">
    <div class="row-header">
      <h2 class="title row-title">{{ 'Informations' | ehp_translate }}</h2>
      <p class="description">
        {{ "Carte d'identité de l'établissement reprenant les informations générales et les informations juridiques." | ehp_translate }}
      </p>
    </div>

    <div class="row-content">
      <ehp-form-field>
        <label for="name">{{'Nom de l\'établissement' | ehp_translate }}</label>
        <input ehp-form-control formControlName="name" id="name" type="text">
      </ehp-form-field>


      <ehp-form-field *ngIf="canUpdateProperty('state') && mode === 'update'">
        <label for="state">{{'État' | ehp_translate }}</label>
        <ehp-select [options]="facilityStateOptions" [required]="true" ehp-form-control formControlName="state"
                    id="state"></ehp-select>
      </ehp-form-field>

      <ehp-form-field>
        <label for="managingOrganization">{{'Organisme gestionnaire' | ehp_translate }}</label>
        <input ehp-form-control formControlName="managingOrganization" id="managingOrganization" type="text">
      </ehp-form-field>

      <ehp-form-field>
        <label>{{'Statut' | ehp_translate }}</label>
        <ehp-async-select [options]="type$" ehp-form-control
                          formControlName="type">
        </ehp-async-select>
      </ehp-form-field>

      <ehp-form-field>
        <label>{{'Habilitation à l’aide sociale' | ehp_translate }}</label>
        <ehp-async-select [options]="socialClearance$" ehp-form-control
                          formControlName="socialClearance">
        </ehp-async-select>
      </ehp-form-field>
    </div>

  </div>

  <div class="form-row">
    <div class="row-header">
      <h2 class="title row-title">{{ 'FINESS' | ehp_translate }}</h2>
      <p class="description">
        {{ "Numéros d'identification de l'établissement." | ehp_translate }}
      </p>
    </div>

    <div class="row-content">

      <div class="finess-container">
        <ehp-form-field>
          <label for="finess-geo">{{'FINESS Géographique' | ehp_translate }}</label>
          <input ehp-form-control formControlName="finessGeo" id="finess-geo" type="text">
        </ehp-form-field>

        <ehp-form-field>
          <label for="finess-legal">{{'FINESS Juridique' | ehp_translate }}</label>
          <input ehp-form-control formControlName="finessLegal" id="finess-legal" type="text">
        </ehp-form-field>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="row-header">
      <h2 class="title row-title">{{ 'Localisation' | ehp_translate }}</h2>

    </div>

    <div class="row-content">
      <ehp-form-field>
        <ehp-address-input ehp-form-control formControlName="address"></ehp-address-input>
      </ehp-form-field>
    </div>

  </div>

  <div class="form-row">
    <div class="row-header">
      <h2 class="title row-title">{{ 'Personne référente' | ehp_translate }}</h2>
      <p class="description">
        {{ "Personne présente au sein de l'établissement à contacter en cas de besoin." | ehp_translate }}
      </p>
    </div>

    <div class="row-content contact-container" formGroupName="contact">

      <ehp-form-field>
        <label for="contact-name">{{'Nom et prénom' | ehp_translate }}</label>
        <input ehp-form-control formControlName="name" id="contact-name" type="text">
      </ehp-form-field>

      <ehp-form-field>
        <label for="contact-job">{{'Poste occupé' | ehp_translate }}</label>
        <input ehp-form-control formControlName="job" id="contact-job" type="text">
      </ehp-form-field>

      <ehp-form-field>
        <label for="contact-email">{{'Adresse e-mail' | ehp_translate }}</label>
        <input ehp-form-control formControlName="email" id="contact-email" type="email">
      </ehp-form-field>

      <ehp-form-field>
        <label>{{'Numéro de téléphone' | ehp_translate }}</label>
        <ehp-phone-input ehp-form-control formControlName="phone"></ehp-phone-input>
      </ehp-form-field>
    </div>

  </div>


  <div class="actions">
    <button [disabled]="!form.valid" class="primary">
      <ehp-translatable-string [innerHTML]="submitLabel"></ehp-translatable-string>
    </button>
  </div>

</form>
