import { RequestTransitionToCloseFileDepositCommand } from '@application/bundles/file-deposit/commands';
import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { joinPath } from '@application/framework/lib';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { FILE_DEPOT_STATES, FileDeposit, FileDepositTransition, isFileDepositId } from '@domain/file-deposit';

import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { FILE_DEPOSIT_URI } from '@implementations/bundles/backend/easyhpad/config/file-deposit';
import { parseBackendResponse } from '@implementations/bundles/backend/functions';

@HandleCommand({
  command: RequestTransitionToCloseFileDepositCommand,
})
export class RequestTransitionToCloseFileDepositCommandHandler
  implements CommandHandler<RequestTransitionToCloseFileDepositCommand>
{
  constructor(
    private readonly backend: EasyBackendApi,
    private readonly noticeStream: NoticeStream,
  ) {}

  public async handle(command: RequestTransitionToCloseFileDepositCommand): Promise<FileDepositTransition | undefined> {
    const { deposit } = command;

    const loaded = await this.backend
      .get<{ entity: FileDeposit }>(this.buildFileDepositEndpoint(deposit.id))
      .then(response => parseBackendResponse(response))
      .then(body => body.entity);

    if (loaded.state === FILE_DEPOT_STATES.CLOSE) {
      this.noticeStream.push({
        type: NoticeType.ERROR,
        message: 'Ce dépôt de fichier est déjà fermé.',
      });
      return;
    } else if (loaded.state === FILE_DEPOT_STATES.ARCHIVED) {
      this.noticeStream.push({
        type: NoticeType.ERROR,
        message: "Le dépôt de fichier est archivé. Il n'est donc plus possible de le réactiver.",
      });
      return;
    }

    const request: { to: FILE_DEPOT_STATES; comment?: string } = {
      to: FILE_DEPOT_STATES.CLOSE,
      comment: undefined,
    };

    if (command.comment) {
      request.comment = command.comment;
    }

    return this.backend
      .post<{ entity: FileDepositTransition }>(this.buildEndpoint(deposit.id), request)
      .then(response => parseBackendResponse(response))
      .then(body => body.entity);
  }

  private buildEndpoint(id: FileDeposit['id']): string {
    if (!isFileDepositId(id)) {
      throw new Error(`${id} is not a valid FileDeposit ID`);
    }
    return joinPath(this.buildFileDepositEndpoint(id), 'transitions');
  }

  private buildFileDepositEndpoint(id: FileDeposit['id']): string {
    if (!isFileDepositId(id)) {
      throw new Error(`${id} is not a valid FileDeposit ID`);
    }

    return joinPath(FILE_DEPOSIT_URI, id);
  }
}
