import { MediaPreviewList, MediaPreviewSearchResult, MediaRef } from '@easyhpad-ui/app/bundles/media/interfaces';
import { isLocalMedia, isMedia, LocalMedia, Media } from '@domain/media';
import { BehaviorSubject, Observable } from 'rxjs';

export class MediaViewerPreviewList<M extends Media | LocalMedia> implements MediaPreviewList<M> {
  private readonly list: Set<M>;

  private readonly current$: BehaviorSubject<M | undefined> = new BehaviorSubject<M | undefined>(undefined);

  private obs: Observable<M | undefined> | undefined;

  public get size(): number {
    return this.list.size;
  }

  public get current(): Observable<M | undefined> {
    if (!this.obs) {
      this.obs = this.current$.asObservable();
    }
    return this.obs;
  }

  constructor(medias?: Iterable<M>) {
    this.list = new Set([...(medias ?? [])]);
  }

  public [Symbol.iterator](): Iterator<M> {
    return this.list[Symbol.iterator]();
  }

  /**
   * @inheritDoc
   */
  public has(media: M): boolean {
    return this.list.has(media);
  }

  /**
   * @inheritDoc
   */
  public add(...media: M[]) {
    for (const m of media) {
      this.list.add(m);
      if (this.size === 1) {
        this.dispatchOpen(m);
      }
    }
  }

  public first(): M | undefined {
    return this.list.values().next().value;
  }

  /**
   * @inheritDoc
   */
  public search(predicate: (media: M, index?: number) => boolean): MediaPreviewSearchResult<M> | undefined {
    const medias = Array.from(this.list);
    const index = medias.findIndex(predicate);

    if (index !== -1) {
      return { index, media: medias[index] };
    }
    return undefined;
  }

  /**
   * @inheritDoc
   */
  public replace(index: number, media: M) {
    this.dispatchClose(media);
    const medias = Array.from(this.list);
    index === -1 ? medias.push(media) : (medias[-1] = media);
    this.list.clear();
    medias.forEach(m => this.list.add(m));
  }

  /**
   * @inheritDoc
   */
  public change(iterable: Iterable<M>): void {
    this.clear();

    for (const media of iterable) {
      this.list.add(media);
    }

    const first = this.first();

    if (first) {
      this.open(first);
    }
  }

  /**
   * @inheritDoc
   */
  public clear() {
    this.list.clear();
    this.dispatchClose();
  }

  /**
   * @inheritDoc
   */
  public remove(media: M, openFirst?: boolean): M | null {
    if (this.list.delete(media)) {
      this.dispatchClose(media);

      if (openFirst) {
        this.dispatchOpen(this.first());
      }

      return media;
    }
    return null;
  }

  /**
   * @inheritDoc
   */
  public open(reference: M | MediaRef<M>): void {
    let media: M | null | undefined;

    if ((isMedia(reference) || isLocalMedia(reference)) && this.has(reference)) {
      media = reference;
    } else if ('search' in reference) {
      media = reference.search(this.list);
    }

    this.dispatchOpen(media || undefined);
  }

  /**
   * @inheritDoc
   */
  public close(reference?: MediaRef<M>) {
    const media: M | null | undefined = reference?.search(this.list);
    this.dispatchClose(media || undefined);
  }

  private dispatchOpen(media: M | undefined): void {
    if (media !== this.current$.value) {
      this.current$.next(media);
    }
  }

  private dispatchClose(media?: M | undefined) {
    if ((media && media === this.current$.value) || this.current$.value !== undefined) {
      this.current$.next(undefined);
    }
  }
}
