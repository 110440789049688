import {createReducer, on} from "@ngrx/store";
import {CustomerActions} from './customer.actions';
import {CustomerStoreState} from "./customer.state";

const initialState: CustomerStoreState = {
  current: {
    available: [],
    selected: undefined
  },
  admin: {
    loading: false,
    entities: []
  }
};

export const customerReducer = createReducer(
  initialState,
  on(CustomerActions.loadAdminCustomers, state => ({
    ...state,
    admin: {...state.admin, loading: true}
  })),
  on(CustomerActions.setAdminCustomers, (state, {customers}) => ({
    ...state,
    admin: {entities: customers, loading: false}
  })),
  on(CustomerActions.setAvailableCustomers, (state, {customers}) => ({
    ...state,
    current: {...state.current, available: customers}
  })),
  on(CustomerActions.setCurrentCustomer, (state, {customer}) => ({
    ...state,
    current: {...state.current, selected: customer}
  })),
  on(CustomerActions.resetCustomerStore, () => ({
    ...initialState
  }))
);
