import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginCommand} from "@application/bundles/authentification/commands/login.command";
import {CommandBus} from "@application/framework/command-query/command-bus.abstract";
import {AuthentificationStrategy} from "@application/bundles/authentification/abstraction/authentication-strategy";
import {EMAIL_PASSWORD_AUTH_STRATEGY} from "../../../../user.tokens";
import {ConnectionResult} from "@application/bundles/authentification";

@Component({
  selector: 'ehp-user-login-form',
  templateUrl: './user-login-form.component.html',
  styleUrls: ['./user-login-form.component.scss']
})
export class UserLoginFormComponent implements OnInit {

  @Input('defaults') public defaultValues: { email: string, password: string } | undefined;

  @Input() public forgottenEnable: boolean = false;

  @Output() public onForgotten: EventEmitter<void> = new EventEmitter();

  @Output() public onLogin: EventEmitter<void> = new EventEmitter();

  public form: FormGroup | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private commandBus: CommandBus,
    @Inject(EMAIL_PASSWORD_AUTH_STRATEGY) private authentificationStrategy: AuthentificationStrategy,
  ) {
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      'email': new FormControl(this.defaultValues?.email, {
        validators: [Validators.required, Validators.email],
        updateOn: "blur"
      }),
      'password': new FormControl(this.defaultValues?.password, {
        validators: [Validators.required],
      })
    });
  }

  public async submit(): Promise<void> {

    if (!this.form?.valid) {
      return;
    }

    const values = this.form?.value;

    await this.commandBus.execute<ConnectionResult>(new LoginCommand(this.authentificationStrategy, values))
      .then((result) => {
        if (result.success) {
          this.onLogin.emit();
        }
      })
  }

  public forgotten() {
    this.onForgotten.emit();
  }
}
