import {GetPMPForYearQuery, PMPRepository} from "@application/bundles/pmp";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {PMP} from "@domain/pmp";
import {getYearDateInterval} from "@application/framework/date";
import {ListPMPParamsImpl} from "@application/bundles/pmp/implementations";

@HandleQuery({
  query: GetPMPForYearQuery
})
export class HasPMPForYearQueryHandler implements QueryHandler<GetPMPForYearQuery, PMP | undefined> {

  constructor(private readonly repository: PMPRepository) {
  }

  public async handle(query: GetPMPForYearQuery): Promise<PMP | undefined> {
    
    const {start, end} = getYearDateInterval(query.year);

    return this.repository.list(new ListPMPParamsImpl().setDates(start, end).setFacilities(query.facilityId))
      .then(list => list[0]);
  }
}
