<ehp-page-header>

  <h1 class="title page-title" pageTitle>
    <span class="trail">{{ 'Administration' | ehp_translate }}</span>
    <span *ngIf="title" [innerHTML]="title" class="trail"></span>
  </h1>

</ehp-page-header>

<ehp-page-content [loading$]="loading$" [skeleton]="skeletonType">

  <ehp-customer-form (submitted)="onUpdate($event)" [customer]="customer" class="form"
                     mode="update"></ehp-customer-form>

</ehp-page-content>



