import {CreateCustomerDto} from "@application/bundles/customer/dto/create-customer.dto";
import {UpdateCustomerDto} from "@application/bundles/customer/dto/update-customer.dto";
import {CustomerSearchParams} from "@application/bundles/customer/repositories/customer-search-params";
import {Customer} from "@domain/customer/customer.interface";

export abstract class CustomerRepository {

  public abstract list(params?: CustomerSearchParams): Promise<Customer[]>;

  public abstract get(id: Customer['id']): Promise<Customer>;

  public abstract create(customer: CreateCustomerDto): Promise<Customer>;

  public abstract update(id: Customer['id'], update: UpdateCustomerDto): Promise<Customer>;

  public abstract delete(id: Customer['id']): Promise<Customer>;
}
