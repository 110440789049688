import {CapacityAuthorization} from "@domain/capacity-authorization";
import {IsNumber, IsOptional, Max, Min} from "class-validator";
import {Facility} from "@domain/facility";
import {IsValidFacilityId} from "@implementations/bundles/facility";

type PickedProperties = 'dates'
  | 'begin'
  | 'permanentCapacity'
  | 'uhrCapacity'
  | 'pasaCapacity'
  | 'temporaryCapacity'
  | 'supportCapacity'
  | 'daycareCapacity'
  | 'bedSocialCare'
  | 'facilityId';

export abstract class AbstractPartialCapacityAuthorization {

  public dates: { start: Date; end: Date };

  public begin: Date | undefined;

  @IsNumber()
  @Min(0)
  public permanentCapacity: number = 0;

  @IsOptional()
  @IsNumber()
  @Min(0)
  public uhrCapacity: number | undefined;

  @IsOptional()
  @IsNumber()
  @Min(0)
  public pasaCapacity: number | undefined;

  @IsNumber()
  @Min(0)
  public temporaryCapacity: number = 0;

  @IsNumber()
  @Min(0)
  @Max(1)
  public supportCapacity: number = 0;

  @IsNumber()
  @Min(0)
  public daycareCapacity: number = 0;

  @IsOptional()
  @IsNumber()
  @Min(0)
  public bedSocialCare: number | undefined;

  @IsValidFacilityId()
  public facilityId: Facility['id'];


  protected constructor(values: Pick<CapacityAuthorization, PickedProperties>) {
    this.dates = {
      start: values.dates.start,
      end: values.dates.end
    }
    this.begin = values.begin;
    this.permanentCapacity = values.permanentCapacity;
    this.uhrCapacity = values.uhrCapacity;
    this.pasaCapacity = values.pasaCapacity;
    this.temporaryCapacity = values.temporaryCapacity;
    this.supportCapacity = values.supportCapacity;
    this.daycareCapacity = values.daycareCapacity;
    this.bedSocialCare = values.bedSocialCare;
    this.facilityId = values.facilityId;
  }
}
