import { Facility, FinessNumber } from '@domain/facility';
import { Contact } from '@domain/facility/facility-contact.interface';
import { PhoneNumber } from '@domain/phone';
import { PhoneNumberImpl } from '@implementations/bundles/phone/implementations/phone-number';

export type StoreSerializedFacility = Record<keyof Facility, string | number | any>;

const phoneSerializer = Object.freeze({
  serialize: (phone: PhoneNumber): Pick<PhoneNumber, 'number' | 'region'> => {
    return {
      number: phone.number,
      region: phone.region,
    };
  },

  deserialize(phone: Pick<PhoneNumber, 'number' | 'region'>): PhoneNumber | undefined {
    if (phone.number !== '') {
      return new PhoneNumberImpl(phone.number, phone.region);
    }
    return undefined;
  },
});

const contactSerializer = Object.freeze({
  serialize: (contact: Contact): Record<keyof Contact, string | number | any> => {
    return {
      id: contact.id,
      name: contact.name,
      email: contact.email,
      job: contact.job,
      phone: contact.phone ? phoneSerializer.serialize(contact.phone) : undefined,
    };
  },
  deserialize(contact: Record<keyof Contact, string | number | any>): Contact {
    return {
      id: contact.id,
      name: contact.name,
      email: contact.email,
      job: contact.job,
      phone: contact.phone ? phoneSerializer.deserialize(contact.phone) : undefined,
    };
  },
});

export const facilityStoreSerializer = Object.freeze({
  serialize: (facility: Facility): StoreSerializedFacility => {
    return {
      id: facility.id,
      name: facility.name,
      type: facility.type,
      address: facility.address,
      finessGeo: facility.finessGeo?.toString(),
      finessLegal: facility.finessLegal?.toString(),
      socialClearance: facility.socialClearance,
      state: facility.state,
      createdAt: facility.createdAt?.toISOString(),
      updatedAt: facility.updatedAt?.toISOString(),
      customerId: facility.customerId,
      managingOrganization: facility.managingOrganization,
      phone: facility.phone ? phoneSerializer.serialize(facility.phone) : undefined,
      contact: facility.contact ? contactSerializer.serialize(facility.contact) : undefined,
    };
  },
  deserialize: (facility: StoreSerializedFacility): Facility => {
    return {
      id: facility.id,
      name: facility.name,
      type: facility.type,
      address: facility.address,
      finessGeo: facility.finessGeo ? new FinessNumber(facility.finessGeo) : undefined,
      finessLegal: facility.finessLegal ? new FinessNumber(facility.finessLegal) : undefined,
      socialClearance: facility.socialClearance,
      state: facility.state,
      customerId: facility.customerId,
      managingOrganization: facility.managingOrganization,
      phone: facility.phone ? phoneSerializer.deserialize(facility.phone) : undefined,
      contact: facility.contact ? contactSerializer.deserialize(facility.contact) : undefined,
      createdAt: typeof facility.createdAt === 'string' ? new Date(facility.createdAt) : (undefined as any),
      updatedAt: typeof facility.updatedAt === 'string' ? new Date(facility.updatedAt) : (undefined as any),
    };
  },
});
