import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {Customer} from "@domain/customer";

export class CreateCustomerSuccessEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  public constructor(public readonly customer: Customer) {
    super();
  }
}
