import {ActivityAnnexRepository, GetLatestActivityAnnexQuery} from "@application/bundles/activity-annex";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {ActivityAnnex} from "@domain/activity-annex";

@HandleQuery({
  query: GetLatestActivityAnnexQuery
})
export class GetLatestActivityAnnexQueryHandler implements QueryHandler<GetLatestActivityAnnexQuery, ActivityAnnex | undefined> {

  constructor(private readonly repository: ActivityAnnexRepository) {
  }

  public async handle(query: GetLatestActivityAnnexQuery): Promise<ActivityAnnex | undefined> {

    return undefined;
  }
}
