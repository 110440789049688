import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {
  ListOtherHealthFundingQuery
} from "@application/bundles/health-funding/queries/list-other-local-health-funding.query";
import {HealthFundingRepository} from "@application/bundles/health-funding";
import {HealthFunding} from "@domain/health-funding";

@HandleQuery({
  query: ListOtherHealthFundingQuery
})
export class ListOtherLocalCpomQueryHandler implements QueryHandler<ListOtherHealthFundingQuery, HealthFunding[]> {


  public constructor(private readonly repository: HealthFundingRepository) {
  }

  public async handle(query: ListOtherHealthFundingQuery): Promise<HealthFunding[]> {
    return [];
  }

}
