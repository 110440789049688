import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, NgModel } from '@angular/forms';
import { FormControlDirective } from '@easyhpad-ui/app/library/form/directives/form-control/form-control.directive';
import { Subject, takeUntil } from 'rxjs';
import {
  DynamicAbstractControlField,
  DynamicNumberInputField,
  HasDynamicField,
} from '@easyhpad-ui/app/library/form/contracts';
import { DynamicInputPropertySetter } from '@easyhpad-ui/app/library/form/services';
import { syncControlsDirty, syncControlsTouch } from '@easyhpad-ui/app/library/form/functions';

@Component({
  selector: 'ehp-dynamic-float-input-field',
  templateUrl: './dynamic-float-input-field.component.html',
  styleUrl: './dynamic-float-input-field.component.scss',
})
export class DynamicFloatInputFieldComponent
  implements HasDynamicField<DynamicNumberInputField>, OnInit, AfterViewInit, OnDestroy
{
  /**
   * Field definition
   */
  @Input() public readonly field!: DynamicAbstractControlField<DynamicNumberInputField>;

  /**
   * Input HTML element reference
   */
  @ViewChild('input', { read: ElementRef }) public input!: ElementRef;

  /**
   * Input Form control reference
   */
  @ViewChild('input', { read: FormControlDirective }) public formControl!: FormControlDirective;

  /**
   * Angular model directive
   */
  @ViewChild('input', { read: NgModel }) public ngModel!: NgModel;

  /**
   * Locale used to format input value
   */
  public locale = 'fr-FR';

  /**
   * Input value binding.
   */
  public value: number | null = null;

  private destroy$ = new Subject<void>();

  public get control(): FormControl | null {
    return this.field.control ? (this.field.control as FormControl) : null;
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    if (this.field.initialValue) {
      this.field.initialValue.pipe(takeUntil(this.destroy$)).subscribe(value => this.setInitialValue(value));
    }

    if (this.control) {
      this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => this.patchInternalValue(value));
    }
  }

  public ngAfterViewInit(): void {
    if (this.input) {
      this.renderer.setAttribute(this.input.nativeElement, 'type', this.field.type);

      const setter = new DynamicInputPropertySetter(this.renderer);
      setter.defineProperties(this.input, this.field);
    }

    if (this.control) {
      if (this.formControl) {
        this.formControl.attachControl(this.control);
      }

      if (this.ngModel) {
        this.linkModelAndControl(this.ngModel, this.control);
      }
    }

    this.changeDetector.detectChanges();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Update field control value.
   * @param value
   */
  public updateValue(value: number | null): void {
    this.control?.setValue(value);
  }

  /**
   * Set value if field is untouched
   * @param value
   * @private
   */
  private setInitialValue(value: any) {
    if (this.control?.touched || this.control?.dirty) {
      return;
    }

    this.patchInternalValue(value);

    if (
      (value === null || value === undefined) &&
      (this.control?.value === null || this.control?.value === undefined)
    ) {
      return;
    }
    this.control?.patchValue(value);
  }

  /**
   * Update value only if the value is not the same as this.value
   * @param value
   * @private
   */
  private patchInternalValue(value: number | null | undefined): void {
    if (this.value !== this.transformControlValue(value)) {
      this.value = this.transformControlValue(value);
    }
  }

  private transformControlValue(value: any): number | null {
    if (value === null || value === undefined || isNaN(value)) {
      return null;
    }

    if (typeof value === 'number' && isFinite(value)) {
      return value;
    }

    if (typeof value === 'string') {
      return parseFloat(value);
    }

    return null;
  }

  private linkModelAndControl(model: NgModel, control: AbstractControl): void {
    syncControlsDirty(model.control, control);
    syncControlsTouch(model.control, control);

    if (control.dirty) {
      model.control.markAsDirty();
    }

    if (control.touched) {
      model.control.markAsTouched();
    }
  }
}
