import {Component, Input} from '@angular/core';
import {LocalMedia, Media} from "@domain/media";
import {
  MEDIA_PREVIEW_FEATURE,
  PreviewComponentInterface
} from "@easyhpad-ui/app/bundles/media/interfaces/preview-component.interface";

@Component({
  selector: 'ehp-preview-unknown-media',
  templateUrl: './preview-unknown-media.component.html',
  styleUrls: ['./preview-unknown-media.component.scss']
})
export class PreviewUnknownMediaComponent implements PreviewComponentInterface {

  @Input() public media: Media | LocalMedia | undefined;

  public readonly supports: Set<MEDIA_PREVIEW_FEATURE> = new Set();

  constructor() {
  }

}
