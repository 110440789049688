import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { FileDepositActions, selectFileDepositEntities } from '@easyhpad-ui/app/bundles/file-deposit/store';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { FileDepositUrlProvider } from '@easyhpad-ui/app/bundles/file-deposit/providers';
import { FileDeposit } from '@domain/file-deposit';

@Component({
  selector: 'ehp-file-deposits-button',
  templateUrl: './file-deposits-button.component.html',
  styleUrls: ['./file-deposits-button.component.scss'],
})
export class FileDepositsButtonComponent implements OnDestroy {
  public path: string | null | undefined;

  public hasDeposit$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly fileDepositUrls: FileDepositUrlProvider,
    private readonly store: Store<AppState>,
  ) {
    this.hasDeposit$ = this.store.select(selectFileDepositEntities).pipe(
      tap(deposits => this.setUrl(deposits)),
      map(deposits => deposits && deposits.length > 0),
      takeUntil(this.destroy$),
    );

    this.store.dispatch(FileDepositActions.loadFileDeposits({}));
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private setUrl(deposits: FileDeposit[]): void {
    if (deposits.length === 1) {
      this.fileDepositUrls.getPublicShow(deposits[0].id).then(url => (this.path = url));
    } else {
      this.fileDepositUrls.getPublicList().then(url => (this.path = url));
    }
  }
}
