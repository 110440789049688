import { CommandBus } from '@application/framework/command-query/command-bus.abstract';
import {
  CommandConstructor,
  CommandHandlerRegistry,
} from '@application/framework/command-query/command-handler.registry';
import { Command } from '@application/framework/command-query/command.interface';
import { InvalidHandlerTypeError } from '@application/framework/command-query/errors/invalid-handler-type.error';
import { MissingCommandHandlerError } from '@application/framework/command-query/errors/missing-command-handler.error';
import { MissingQueryHandlerError } from '@application/framework/command-query/errors/missing-query-handler.error';
import { CommandHandler, QueryHandler } from '@application/framework/command-query/handler.interface';
import { QueryBus } from '@application/framework/command-query/query-bus.abstract';
import { QueryConstructor, QueryHandlerRegistry } from '@application/framework/command-query/query-handler.registry';
import { Query } from '@application/framework/command-query/query.interface';
import { Logger, LogLevel } from '@application/framework/logger';
import { BusBuildOptions } from '@implementations/framework/command-query/bus-build-options.interface';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';

export class CommandQueryBus implements QueryBus, CommandBus {
  private readonly logger: Logger;

  private readonly queueActivate = new BehaviorSubject(true);

  /**
   *
   * @private
   */
  private bypassEnqueue: Array<CommandConstructor<any> | QueryConstructor<any>> = [];

  public constructor(
    private commandRegistry: CommandHandlerRegistry,
    private queryRegistry: QueryHandlerRegistry,
    logger: Logger,
    silent = false,
  ) {
    this.logger = logger.channel('Command / Query bus');
    this.logger.level = silent ? LogLevel.WARNING : LogLevel.INFO;
  }

  public static build(options: BusBuildOptions): CommandQueryBus {
    const bus = new CommandQueryBus(
      options.commandRegistry,
      options.queryRegistry,
      options.logger.use,
      options.logger.silent,
    );

    bus.bypassEnqueue = options.bypassEnqueue ?? [];

    if (options.authentificationState !== undefined) {
      options.authentificationState.changes.subscribe(change => {
        const isAuthenticated = change.logged;

        if (isAuthenticated && bus.queueIsActivated()) {
          bus.deactivateQueue();
        } else if (!isAuthenticated && !bus.queueIsActivated()) {
          bus.activateQueue();
        }
      });
    }
    return bus;
  }

  /**
   * Get command handler from registry, and execute command
   * @param command
   */
  public async execute<R = any>(command: Command<R>): Promise<R> {
    const handler = this.commandRegistry.get(command);

    if (!handler) {
      throw new MissingCommandHandlerError(
        `No command handler found for "${Object.getPrototypeOf(command).constructor.name}"`,
      );
    }

    this.logger.info(`Execute command "${Object.getPrototypeOf(command).constructor.name}"`);
    this.checkHandler(handler, command);

    return this.wrapHandleFunction(handler, command);
  }

  /**
   * Get query handler from registry and execute query
   * @param query
   */
  public async request<R = any>(query: Query<R>): Promise<R> {
    const handler = this.queryRegistry.get(query);

    if (!handler) {
      throw new MissingQueryHandlerError(
        `No query handler found for "${Object.getPrototypeOf(query).constructor.name}"`,
      );
    }

    this.logger.info(`Execute query "${Object.getPrototypeOf(query).constructor.name}"`);
    this.checkHandler(handler, query);

    return this.wrapHandleFunction(handler, query);
  }

  public activateQueue(): void {
    this.logger.info('Activate Command & Query queuing');
    this.queueActivate.next(true);
  }

  public deactivateQueue(): void {
    this.logger.info('Deactivate Command & Query queuing');
    this.queueActivate.next(false);
  }

  /**
   * Throw InvalidHandlerTypeError if Handler.handle() is not a function
   * @param handler
   * @param handled
   * @private
   */
  private checkHandler(handler: CommandHandler<any> | QueryHandler<any>, handled: Command | Query): void {
    if (typeof handler.handle !== 'function') {
      throw new InvalidHandlerTypeError(
        `"${
          Object.getPrototypeOf(handled).constructor.name
        }" Handler : handler.handle must be a function, ${typeof handler.handle} provided`,
      );
    }
  }

  /**
   * If enqueue is activated, wrap handler in another promise
   * @param handler
   * @param handled
   * @private
   */
  private wrapHandleFunction<R = any>(
    handler: CommandHandler<any> | QueryHandler<any>,
    handled: Command | Query,
  ): Promise<R> {
    if (!this.queueIsActivated() || this.canBypassEnqueue(handled)) {
      return handler.handle(handled);
    }

    return firstValueFrom(this.queueActivate.pipe(filter(activate => !activate))).then(() => {
      return handler.handle(handled);
    });
  }

  /**
   * Check if queue is currently activate
   * @private
   */
  private queueIsActivated() {
    return this.queueActivate.value;
  }

  /**
   * Check if handled constructor is in byPass array.
   * @param handled
   * @private
   */
  private canBypassEnqueue(handled: Command | Query) {
    return this.bypassEnqueue.filter((constructor: any) => handled instanceof constructor).length;
  }
}
