import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Facility } from '@domain/facility';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { FacilityActions, selectFacility } from '@easyhpad-ui/app/bundles/facility/store';

@Component({
  selector: 'ehp-facility-id-card',
  templateUrl: './facility-id-card.component.html',
  styleUrls: ['./facility-id-card.component.scss'],
})
export class FacilityIdCardComponent implements OnChanges, OnDestroy {
  @Input() public fid: Facility['id'] | undefined;

  @Input() public facility!: Facility | undefined;

  public facility$ = new BehaviorSubject<Facility | undefined>(undefined);

  private subscription: Subscription | undefined;

  constructor(private readonly store: Store<AppState>) {}

  public ngOnChanges(changes: SimpleChanges) {
    if ('fid' in changes) {
      this.loadFacility(changes['fid'].currentValue);
    }

    if ('facility' in changes) {
      this.dispatchFacility(changes['facility'].currentValue);
    }
  }

  public ngOnDestroy() {
    if (this.facility$) {
      this.facility$.complete();
    }

    this.unsubscribe();
  }

  private loadFacility(id: Facility['id'] | undefined) {
    this.unsubscribe();

    if (id === undefined) {
      this.setFacility(undefined);
      return;
    }

    if (this.facility && this.facility.id === id) {
      return;
    }

    this.subscription = this.store.select(selectFacility(id)).subscribe(facility => {
      this.setFacility(facility);
    });

    this.store.dispatch(FacilityActions.loadFacility({ id, useCache: true }));
  }

  private setFacility(facility: Facility | undefined) {
    if (
      facility === undefined ||
      (this.facility$.value === undefined && this.facility$.value !== facility) ||
      (facility && this.facility$.value && this.facility$?.value.id !== facility.id)
    ) {
      this.dispatchFacility(facility);
    }

    this.facility = facility;
  }

  private dispatchFacility(facility: Facility | undefined) {
    if (
      facility === undefined ||
      (this.facility$.value === undefined && this.facility$.value !== facility) ||
      (facility && this.facility$.value && this.facility$?.value.id !== facility.id)
    ) {
      this.facility$.next(facility);
    }
  }

  private unsubscribe(): void {
    this.subscription?.unsubscribe();
  }
}
