<div class="filter-container">
  <ehp-multi-current-facility-filter
    (onChange)="setFacilities($event)"
    label="{{'Établissement' | ehp_translate}}">
  </ehp-multi-current-facility-filter>
</div>

<div class="filter-container">
  <ehp-year-filter
    (onChange)="setYear($event)"
    label="{{'Année' | ehp_translate}}">
  </ehp-year-filter>
</div>
