<div [class.tooltip--visible]="visible"
     [ngClass]="['tooltip--'+position]"
     [style.left]="left + 'px'"
     [style.top]="top + 'px'" class="tooltip">

  <div *ngIf="template; else contentContainer" [ngTemplateOutlet]="template"></div>

  <ng-template #contentContainer>
    <span [innerHTML]="content"></span>
  </ng-template>

</div>



