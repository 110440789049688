import { Component, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { LocalMedia, Media } from '@domain/media';
import { MediaViewerOptions } from '@easyhpad-ui/app/bundles/media/interfaces/media-viewer-options.interface';
import { MediaPreview } from '@easyhpad-ui/app/bundles/media/services/media-preview/media-preview';

@Component({
  selector: 'ehp-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss'],
})
export class MediaItemComponent implements OnChanges {
  @Input() public media: LocalMedia | Media | undefined;

  @Input() public download: boolean = true;

  @Input() public preview: boolean = true;

  public downloadUrl: string | undefined;

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly previewer: MediaPreview,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['media']) {
      this.mediaHasChange();
    }
  }

  public openPreview() {
    if (!this.preview || !this.media) {
      return;
    }

    const options: MediaViewerOptions = {
      container: this.viewContainer,
      acceptClosable: true,
      acceptFullscreen: true,
    };

    this.previewer.open([this.media], options);
  }

  private mediaHasChange(): void {
    if (this.media === undefined) {
      this.downloadUrl = undefined;
    } else {
      this.media.buildUrl().then(url => {
        this.downloadUrl = url.toString();
      });
    }
  }
}
