import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {CapacityAuthorization, CapacityAuthorizationRepository} from "@domain/capacity-authorization";
import {GetCapacityAuthorizationQuery} from "../../get-capacity-authorization.query";

@HandleQuery({
  query: GetCapacityAuthorizationQuery
})
export class GetCapacityAuthorizationQueryHandler implements QueryHandler<GetCapacityAuthorizationQuery, CapacityAuthorization> {

  constructor(private readonly repository: CapacityAuthorizationRepository) {
  }

  public handle(query: GetCapacityAuthorizationQuery): Promise<CapacityAuthorization> {
    return this.repository.get(query.id);
  }


}
