import {NgModule} from '@angular/core';
import {Route, RouterModule} from "@angular/router";
import {AuthGuard} from "@easyhpad-ui/app/bundles/authentification";
import {DashboardPageComponent} from "./pages/dashboard-page/dashboard-page.component";

const routes: Route[] = [
  {path: '', component: DashboardPageComponent, canActivate: [AuthGuard]}
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class DashboardPublicRoutingModule {
}
