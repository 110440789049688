import {CreateEhpadDemainDto, EhpadDemainListParams, UpdateEhpadDemainDto} from "@application/bundles/ehpad-demain";
import {EhpadDemain} from "@domain/ehpad-demain";

export abstract class EhpadDemainRepository {

  public abstract list(params?: EhpadDemainListParams): Promise<EhpadDemain[]>;

  public abstract get(id: EhpadDemain['id']): Promise<EhpadDemain>;

  public abstract create(create: CreateEhpadDemainDto): Promise<EhpadDemain>;

  public abstract update(id: EhpadDemain['id'], update: UpdateEhpadDemainDto): Promise<EhpadDemain>;

  public abstract delete(id: EhpadDemain['id']): Promise<EhpadDemain>;
}
