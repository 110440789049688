import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { QueryBus } from '@application/framework/command-query';
import {
  CapacityAuthorization,
  CapacityAuthorizationProperties,
  computeAuthorizedCapacity,
  InspectionReport,
} from '@domain/capacity-authorization';
import { Facility } from '@domain/facility';
import { Media, MediaBucket } from '@domain/media';
import { UUID } from '@domain/lib';

export class CapacityAuthorizationImpl implements CapacityAuthorization {
  public id: UUID = '';

  public authorizationId: Media['id'] | undefined;

  public pvId: Media['id'] | undefined;

  public dates: { start: Date; end: Date } = { start: new Date(), end: new Date() };

  public begin: Date = new Date();

  public permanentCapacity: number = 0;

  public daycareCapacity: number = 0;

  public pasaCapacity: number = 0;

  public supportCapacity: number = 0;

  public temporaryCapacity: number = 0;

  public uhrCapacity: number = 0;

  public bedSocialCare: number | undefined = 0;

  public createdAt: Date = new Date();

  public updatedAt: Date = new Date();

  public facilityId: Facility['id'];

  public reportsIds: Array<Media['id']> = [];

  private reports: InspectionReport[] = [];

  constructor(
    private readonly queryBus: QueryBus,
    private readonly mediaBucket: MediaBucket,
    values: CapacityAuthorizationProperties,
    inspectionReports?: InspectionReport[],
  ) {
    const properties: Array<keyof CapacityAuthorizationProperties> = [
      'id',
      'permanentCapacity',
      'begin',
      'daycareCapacity',
      'pasaCapacity',
      'supportCapacity',
      'temporaryCapacity',
      'uhrCapacity',
      'bedSocialCare',
      'createdAt',
      'updatedAt',
      'pvId',
      'facilityId',
    ];

    this.facilityId = values.facilityId;

    for (const property of properties) {
      if (values[property] && property in this) {
        // @ts-ignore
        this[property] = values[property] as any;
      }
    }

    if (values.dates?.start) {
      this.dates.start = values.dates?.start;
    }

    if (values.dates?.end) {
      this.dates.end = values.dates?.end;
    }

    if (values.authorizationId) {
      this.authorizationId = values.authorizationId;
    }

    if (inspectionReports) {
      this.reports = inspectionReports;
    }
  }

  public authorizedCapacity(): number {
    return computeAuthorizedCapacity(this);
  }

  public async inspectionReports(): Promise<any[]> {
    return Array.isArray(this.reports) ? this.reports : [];
  }

  public async facility(): Promise<Facility> {
    if (!this.facilityId) {
      return Promise.reject(`Missing facility for Capacity Authorization ${this.id}`);
    }
    return this.queryBus.request(new GetFacilityQuery(this.facilityId));
  }

  public authorization(): Promise<Media | undefined> {
    if (!this.authorizationId) {
      return Promise.resolve(undefined);
    }
    return this.mediaBucket.get(this.authorizationId);
  }

  public pv(): Promise<Media | undefined> {
    if (!this.pvId) {
      return Promise.resolve(undefined);
    }
    return this.mediaBucket.get(this.pvId);
  }
}
