import { PMPValue } from '@domain/pmp/types';
import { GMPValue } from '@domain/gmp';
import { Facility } from '@domain/facility';
import { Funding, FundingCollection } from '@domain/funding';
import { ExceptionalHealthFunding, NonRecurringHealthFunding, RecurringHealthFunding } from '@domain/health-funding';
import { Media } from '@domain/media';
import { EhpadDemainHealthFunding } from '@domain/health-funding/ehpad-demain-health-funding';

export interface HealthFunding extends Funding {
  gmp: GMPValue;

  pmp: PMPValue;

  total: number;

  nonRecurringTotal: number;

  permanentRecurringAmount: number | undefined;

  temporaryRecurringAmount: number | undefined;

  dayCareRecurringAmount: number | undefined;

  supportRecurringAmount: number | undefined;

  documentId: Media['id'];

  reportId: Media['id'] | undefined;

  recurringFunding: FundingCollection<RecurringHealthFunding>;

  nonRecurringFunding: FundingCollection<NonRecurringHealthFunding>;

  exceptionalFunding: FundingCollection<ExceptionalHealthFunding>;

  ehpadDemainFunding: FundingCollection<EhpadDemainHealthFunding>;

  createdAt?: Date;

  updatedAt?: Date;

  facility(): Promise<Facility>;

  document(): Promise<Media>;

  report(): Promise<Media | undefined>;
}
