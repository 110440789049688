import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ehp-empty-value',
  templateUrl: './empty-value.component.html',
  styleUrls: ['./empty-value.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmptyValueComponent {

}
