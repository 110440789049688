import {values} from "@domain/lib/object/object";

export enum BILLING_MODE {

  CURRENT_ADDRESS = 'current_address',

  EACH_FACILITY = 'each_facility',

  BILLING_ADDRESS = 'billing_address'
}

export const billingModeValues = new Set(values(BILLING_MODE));

export interface BillingMode {

  mode: BILLING_MODE;

  label: string;
}
