import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { selectCurrentFacilitiesForAccount } from '@easyhpad-ui/app/bundles/facility/store';
import { Facility } from '@domain/facility';
import { Observable } from 'rxjs';

@Component({
  selector: 'ehp-multi-current-facility-selector',
  templateUrl: './current-multi-facility-selector.component.html',
  styleUrls: ['./current-multi-facility-selector.component.scss'],
})
export class CurrentMultiFacilitySelectorComponent {
  public facilities$: Observable<Facility[]>;

  @Output() public onChange: EventEmitter<Facility[]> = new EventEmitter();

  constructor(private readonly store: Store<AppState>) {
    this.facilities$ = this.store.select(selectCurrentFacilitiesForAccount);
  }

  public selectionChange(selection: Facility[]) {
    this.onChange.emit(selection);
  }
}
