import { EasyEntityRepository } from './easy-entity.repository.abstract';
import { SerializedDocumentParams } from '@application/framework/search';
import { RequestParams } from '@application/framework/http/request.interface';
import { EasyEntityRepositoryV2 } from '@implementations/bundles/backend/easyhpad/repositories/base/easy-entity-repository-v2.abstract';

export abstract class DocumentRepository<
  P extends { serialize: () => S } = any,
  S extends SerializedDocumentParams = SerializedDocumentParams,
  Entity = any,
  EasyEntity = Entity,
  ID = string | number,
  C = any,
  U = any,
> extends EasyEntityRepository<Entity, EasyEntity, ID, P, C, U> {
  protected override convertParams(params?: P): RequestParams {
    if (params === undefined) {
      return {};
    }

    return this.searchTransformer.toRequestParams(
      this.searchTransformer.fromSerializeDocumentParams(params.serialize()),
    );
  }
}

export abstract class DocumentRepositoryV2<
  P extends { serialize: () => S } = any,
  S extends SerializedDocumentParams = SerializedDocumentParams,
  Entity = any,
  EasyEntity = Entity,
  ID = string | number,
  C = any,
  U = any,
> extends EasyEntityRepositoryV2<Entity, EasyEntity, ID, P, C, U> {
  protected override convertParams(params?: P): RequestParams {
    if (params === undefined) {
      return {};
    }

    return this.searchTransformer.toRequestParams(
      this.searchTransformer.fromSerializeDocumentParams(params.serialize()),
    );
  }
}
