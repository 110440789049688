import {TransformationFailedError, Transformer} from "@application/framework/core";
import {StudyLevel} from "@domain/user/study-level.interface";
import {SelectOption} from "@implementations/forms/select-option.interface";

export class StudyLevelSelectOptionTransformer implements Transformer<StudyLevel, SelectOption> {


  public transform(level: StudyLevel): SelectOption {

    if (!level) {
      throw new TransformationFailedError('Missing level');
    }

    if (!level.id) {
      throw new TransformationFailedError('Missing property "id" in Study level');
    }

    if (!level.level) {
      throw new TransformationFailedError('Missing property "level" in Study level');
    }

    return {value: level.id, label: level.level};
  }

  /**
   * @inheritDoc
   * @param option
   */
  public reverseTransform(option: SelectOption<string>): StudyLevel {

    if (!option) {
      throw new TransformationFailedError('Missing option');
    }

    if (!option.value) {
      throw new TransformationFailedError('Missing property "value" in SelectOption');
    }

    if (!option.label) {
      throw new TransformationFailedError('Missing property "label" in SelectOption');
    }

    return {id: option.value, level: option.label};
  }

}
