import {
  CreateExceptionalHealthFundingDto,
  ExceptionalHealthFundingDtoFactory,
  UpdateExceptionalHealthFundingDto
} from "@application/bundles/health-funding";
import {
  CreateExceptionalHealthFundingDtoImpl,
  UpdateExceptionalHealthFundingDtoImpl
} from "@implementations/bundles/health-funding/dto";

export class ExceptionalHealthFundingDtoFactoryImpl implements ExceptionalHealthFundingDtoFactory {

  public buildCreateDtoFromUnsafeValues(values: Partial<CreateExceptionalHealthFundingDto>): CreateExceptionalHealthFundingDto {
    return new CreateExceptionalHealthFundingDtoImpl(values);
  }

  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdateExceptionalHealthFundingDto>): UpdateExceptionalHealthFundingDto {
    return new UpdateExceptionalHealthFundingDtoImpl(values);
  }


}
