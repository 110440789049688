import { Component, HostListener, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Facility } from '@domain/facility';
import { FacilityUrls } from '@easyhpad-ui/app/bundles/facility/services/facility-urls/facility-urls';
import { Store } from '@ngrx/store';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { AppState } from '@easyhpad-ui/app/store';

@Component({
  selector: 'ehp-facility-link',
  templateUrl: './facility-link.component.html',
  styleUrls: ['./facility-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.link]': 'isLink',
    '[attr.role]': 'role',
  },
})
export class FacilityLinkComponent implements OnChanges {
  @Input() public fid: Facility['id'] | undefined;

  @Input() public facility!: Facility;

  private url: string | null | undefined;

  public get isLink(): boolean {
    return !!this.url;
  }

  public get role(): string | undefined {
    return this.isLink ? 'link' : undefined;
  }

  constructor(
    private readonly urls: FacilityUrls,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}

  @HostListener('click', ['$event'])
  public async click(event: MouseEvent) {
    if (!this.isLink) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.url !== undefined && this.url !== null) {
      return this.router.navigateByUrl(this.url);
    }

    return false;
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes['facility']) {
      this.defineUrl(this.facility.id);
    }

    if (changes['fid']) {
      this.defineUrl(changes['fid'].currentValue);
    }
  }

  private async defineUrl(id?: Facility['id']): Promise<void> {
    this.url = isValidFacilityIdType(id) ? await this.urls.getPublicShow(id) : null;
  }
}
