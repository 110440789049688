import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  DynamicAbstractControlField,
  DynamicField,
  DynamicFieldGroup,
  DynamicFormAction,
  DynamicFormElementType,
  DynamicFormModel,
  DynamicFormNode,
  DynamicHTMLGroup,
  DynamicOptionalAbstractControlField,
  DynamicPanelGroup,
  DynamicPanelsGroup,
  WithActivation,
  WithChanges,
  WithControlValidators,
  WithInitialValue,
} from '@easyhpad-ui/app/library/form/contracts';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { keys, ObjectKey, pick, values } from '@domain/lib';
import { filter, isObservable, map, merge, of, Subject, takeUntil } from 'rxjs';
import { MediaViewerPreviewList } from '@easyhpad-ui/app/bundles/media';
import { DynamicStepGroup, DynamicStepperGroup } from '@easyhpad-ui/app/library/form/contracts/dynamic-repeater-field';
import { createHtmlGroupActivationObservable } from '@easyhpad-ui/app/library/form/functions';
import { DynamicFormSummary } from '@easyhpad-ui/app/library/form/contracts/dynamic-summary';
import { DynamicValueExtractor } from '@easyhpad-ui/app/library/form/functions/create-html-group-initial-value';
import { isDynamicPanelsGroup, isDynamicStepperGroup } from '@easyhpad-ui/app/library/form/validators';

type ControlTree<T = any> = Record<keyof T, AbstractControl>;

type FormTreeElement<D extends DynamicFormNode<any>> = {
  control?: AbstractControl;
  tree: ControlTree;
  field: DynamicOptionalAbstractControlField<D>;
};

@Component({
  selector: 'ehp-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrl: './dynamic-form.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormComponent<C extends { [k in keyof C]: DynamicFormElementType<any> }>
  implements OnInit, OnDestroy
{
  @Input() public model!: DynamicFormModel<C>;

  /**
   * The Primary form group
   */
  public form!: FormGroup;

  /**
   * Fields to pass in form element compo
   */
  public fields: Array<DynamicOptionalAbstractControlField<DynamicField>> = [];

  /**
   * Form buttons
   */
  public actions: Array<
    Required<DynamicFormAction> & {
      disabled: boolean;
    }
  > = [];

  /**
   * Set true to display the media preview window
   */
  public hasMediaPreview = false;

  /**
   * List of media to display in preview
   */
  public medias = new MediaViewerPreviewList();

  @HostBinding('role') public readonly role = 'form';

  /**
   * List of observers
   * @private
   */
  private formChangesObservers = new Set<Required<WithChanges>>();

  /**
   * Component destruction emitter
   * @private
   */
  private destroy$ = new Subject<void>();

  @HostBinding('class')
  public get classes(): string {
    const classes = ['form', 'dynamic-form'];

    if (this.model && typeof this.model.cssClass === 'string' && this.model.cssClass !== '') {
      classes.push(this.model.cssClass);
    }

    if (this.model && this.model?.medias?.preview === true) {
      classes.push('has-media-preview');
    }

    return classes.join(' ');
  }

  constructor(private readonly fb: FormBuilder) {}

  public ngOnInit(): void {
    this.buildForm();

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.formChangesObservers.forEach(obs => obs.onChanges(changes));
    });

    if (this.model.medias && this.model.medias.preview && this.model.medias.source) {
      this.hasMediaPreview = true;

      this.model.medias.source.pipe(takeUntil(this.destroy$)).subscribe(medias => {
        this.medias.change(medias);
      });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private buildForm() {
    const { fields, controls } = this.getFormControlsFields();
    this.form = this.fb.group(controls);
    this.fields = fields;
    this.attachValidators(this.form, this.model);

    if (Array.isArray(this.model.actions)) {
      this.actions = this.model.actions.map(action => {
        return {
          ...action,
          type: action.type ? action.type : 'button',
          class: action.class ?? '',
          enableSync: !!action.enableSync,
          onClick: action.onClick && typeof (action as any).onClick === 'function' ? action.onClick : (form: any) => {},
          disabled: action.enableSync ? !this.form.valid : false,
        };
      });
    }

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(state => {
      const synced = this.actions.filter(action => action.enableSync);
      synced.forEach(action => (action.disabled = state === 'INVALID'));
    });

    this.attachOnChanges(this.form, this.model);
  }

  private getFormControlsFields(): {
    fields: Array<DynamicAbstractControlField<DynamicField>>;
    controls: ControlTree<C>;
  } {
    let controlTree: ControlTree<C> = {} as any;
    const fields: Array<DynamicAbstractControlField<DynamicField>> = [];

    if (isDynamicStepperGroup(this.model.controls) || isDynamicPanelsGroup(this.model.controls)) {
      const key = '$';

      const { field: field, tree: partialTree } = this.buildControlFromDefinition(key, this.model.controls);
      controlTree = (partialTree[key] as FormGroup).controls as ControlTree<C>;

      if (this.model.initialValue) {
        this.model.initialValue = this.model.initialValue.pipe(map(v => ({ [key]: v })));
      }
      fields.push(field);
    } else {
      for (const name of keys(this.model.controls)) {
        const { tree, field } = this.buildControlFromDefinition(name, this.model.controls[name]);
        controlTree = { ...controlTree, ...tree };
        fields.push(field);
      }
    }

    if (this.model.initialValue) {
      this.attachParentInitialValue(this.model, fields);
    }

    return { controls: controlTree, fields };
  }

  private buildControlFromDefinition<N extends ObjectKey = string>(
    name: N,
    definition: DynamicFormElementType<any>,
  ): FormTreeElement<any> {
    let element: FormTreeElement<any>;

    switch (definition.formElementType) {
      case 'noop':
      case 'field':
        element = this.buildFormControl(name, definition);
        break;

      case 'group':
        element = this.buildFormGroup(name, definition);
        break;

      case 'htmlGroup':
        element = this.buildHTMLFormGroup(name, definition);
        break;

      case 'stepper':
        element = this.buildStepperGroup(name, definition);
        break;

      case 'step':
        element = this.buildFormStep(name, definition);
        break;

      case 'panels':
        element = this.buildPanelsGroup(name, definition);
        break;

      case 'panel':
        element = this.buildFormPanel(name, definition);
        break;

      case 'summary':
        element = {
          ...this.buildSummary(definition),
          tree: {},
        };

        break;

      default:
        throw this.getUnknownElementError((definition as any).formElementType);
    }

    if (!element.field.control && element.control) {
      element.field.control = element.control;
    }

    return element;
  }

  /**
   * Build FormControl based on DynamicField definition.
   * @param name
   * @param definition
   * @private
   */
  private buildFormControl<T extends DynamicFormNode<any>>(name: ObjectKey, definition: T): FormTreeElement<T> {
    const control = new FormControl();

    this.attachValidators(control, definition as WithControlValidators);
    this.attachOnChanges(control, definition as WithChanges);
    this.attachActivation(control, definition as WithActivation);

    return {
      control,
      tree: { [name]: control },
      field: this.setName(name, definition),
    };
  }

  /**
   * Build FormGroup based on DynamicFieldGroup definition.
   * @param name
   * @param definition
   * @private
   */
  private buildFormGroup(name: ObjectKey, definition: DynamicFieldGroup<any>): FormTreeElement<DynamicFieldGroup<any>> {
    const elements = this.buildFieldGroup(name, definition, 'fields');
    const control = this.fb.group(elements.tree);

    return {
      control,
      tree: { [name]: control },
      field: elements.field,
    };
  }

  private buildStepperGroup(name: ObjectKey, definition: DynamicStepperGroup): FormTreeElement<DynamicStepperGroup> {
    const elements = this.buildFieldGroup(name, definition, 'steps');
    const control = this.fb.group(elements.tree);
    return {
      control,
      tree: { [name]: control },
      field: elements.field,
    };
  }

  /**
   * Build FormGroup based on DynamicFieldGroup definition.
   * @param name
   * @param definition
   * @private
   */
  private buildFormStep(name: ObjectKey, definition: DynamicStepGroup<any>): FormTreeElement<DynamicStepGroup<any>> {
    const elements = this.buildFieldGroup(name, definition, 'fields');

    const control = this.fb.group(elements.tree);
    this.attachValidators(control, definition);

    return {
      control,
      tree: { [name]: control },
      field: elements.field,
    };
  }

  private buildPanelsGroup(
    name: string | number | symbol,
    definition: DynamicPanelsGroup,
  ): FormTreeElement<DynamicPanelsGroup> {
    const elements = this.buildFieldGroup(name, definition, 'panels');
    const control = this.fb.group(elements.tree);
    return {
      control,
      tree: { [name]: control },
      field: elements.field,
    };
  }

  /**
   * Build FormGroup based on DynamicPanelGroup definition.
   * @param name
   * @param definition
   * @private
   */
  private buildFormPanel(
    name: string | number | symbol,
    definition: DynamicPanelGroup<any>,
  ): FormTreeElement<DynamicPanelGroup<any>> {
    const elements = this.buildFieldGroup(name, definition, 'fields');

    const control = this.fb.group(elements.tree);
    this.attachValidators(control, definition);

    return {
      control,
      tree: { [name]: control },
      field: elements.field,
    };
  }

  private buildSummary(definition: DynamicFormSummary): Omit<FormTreeElement<DynamicFormSummary>, 'control' | 'tree'> {
    this.formChangesObservers.add({ onChanges: (changes: any) => definition.setValue(changes) });
    return { field: { ...definition, name: 'summary' } };
  }

  /**
   * Build FormGroup based on DynamicHTMLGroup definition.
   * @param name
   * @param definition
   * @private
   */
  private buildHTMLFormGroup(
    name: ObjectKey,
    definition: DynamicHTMLGroup<any>,
  ): FormTreeElement<DynamicHTMLGroup<any>> {
    const elements = this.buildFieldGroup(name, definition, 'fields');

    elements.field.activation = createHtmlGroupActivationObservable(definition);

    return { tree: elements.tree, field: elements.field };
  }

  private buildFieldGroup<D extends DynamicFormElementType<any> & WithInitialValue>(
    name: string | number | symbol,
    definition: D,
    property: keyof D,
  ): Omit<FormTreeElement<D>, 'control'> {
    const fields = definition[property] as Record<string | number | symbol, DynamicFormElementType<any>>;

    const elements: Map<ObjectKey, FormTreeElement<any>> = new Map(
      keys(fields).map(k => [k, this.buildControlFromDefinition(k, fields[k])]),
    );

    let tree: Record<string, AbstractControl> = {};

    Array.from(elements.entries()).forEach(([key, element]) => {
      tree = { ...tree, ...element.tree };

      fields[key] = element.field;
    });

    return { tree, field: this.setName(name, definition) };
  }

  /**
   * Assign a default name. The name can be used to define `name` attribute of `HTMLInputElement`.
   * @param fieldName
   * @param definition
   * @private
   */
  private setName<D extends DynamicFormNode<any>>(fieldName: ObjectKey, definition: D): D & { name: ObjectKey } {
    (definition as any).name = fieldName;
    return definition as any;
  }

  /**
   * Subscribe to control value changes and emit to the model.
   * @param control
   * @param model
   * @private
   */
  private attachOnChanges(control: AbstractControl, model: WithChanges) {
    if (typeof model.onChanges === 'function') {
      const emit = model.onChanges;
      control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => emit(value));
    }
  }

  private attachValidators(control: AbstractControl, definition: WithControlValidators): void {
    if (isObservable(definition.validators)) {
      definition.validators.pipe(takeUntil(this.destroy$)).subscribe(validators => {
        control.setValidators(validators);
        control.updateValueAndValidity();
      });
    }

    if (isObservable(definition.asyncValidators)) {
      definition.asyncValidators.pipe(takeUntil(this.destroy$)).subscribe(validators => {
        control.setAsyncValidators(validators);
        control.updateValueAndValidity();
      });
    }
  }

  private attachActivation(control: AbstractControl, definition: WithActivation): void {
    if (!isObservable(definition.activation)) {
      return;
    }

    definition.activation.pipe(takeUntil(this.destroy$)).subscribe(isActivate => {
      if (isActivate && !control.enabled) {
        control.enable();
      } else if (!isActivate && control.enabled) {
        control.disable();
      }
    });
  }

  private isStepperModel(controls: DynamicFormModel<any>['controls']): controls is DynamicStepperGroup {
    return (
      typeof controls === 'object' &&
      controls !== null &&
      (controls as DynamicFormElementType<any>).formElementType === 'stepper'
    );
  }

  private isPanelsModel(controls: DynamicFormModel<any>['controls']): controls is DynamicPanelsGroup {
    return (
      typeof controls === 'object' && controls !== null && (controls as DynamicPanelsGroup).formElementType === 'panels'
    );
  }

  private attachParentInitialValue(
    parent: WithInitialValue,
    children: Array<DynamicOptionalAbstractControlField<DynamicFormElementType<any>>>,
  ): void {
    if (!isObservable(parent.initialValue)) {
      return;
    }

    children.forEach(child => {
      if (!isObservable(parent.initialValue)) {
        return;
      }

      let initialValue = (child as WithInitialValue).initialValue;

      if (!isObservable(initialValue)) {
        initialValue = of();
      }

      const extractor = this.getExtractor(child.name, child);

      const source = parent.initialValue.pipe(
        map((value: any) => extractor(value)),
        filter(predicate => predicate.found),
        map(project => project.value),
      );

      let next: Array<DynamicOptionalAbstractControlField<DynamicFormElementType<any>>> = [];

      switch (child.formElementType) {
        case 'group':
        case 'step':
        case 'panel':
        case 'htmlGroup':
          next = values((child as DynamicFormElementType<'htmlGroup' | 'group' | 'step' | 'panel'>).fields) as any;
          break;
        case 'stepper':
          next = values((child as DynamicFormElementType<'stepper'>).steps) as any;
          break;
        case 'panels':
          next = values((child as DynamicFormElementType<'panels'>).panels) as any;
          break;
      }

      (child as WithInitialValue).initialValue = merge(initialValue, source);

      if (Array.isArray(next) && next.length > 0) {
        this.attachParentInitialValue(child as WithInitialValue, next);
      }
    });

    parent.initialValue;
  }

  private getExtractor(name: ObjectKey, definition: DynamicFormElementType<any>) {
    switch (definition.formElementType) {
      case 'htmlGroup':
        return this.createHtmlGroupExtractor(definition.fields);
      default:
        return this.createValueFromParentExtractor<ObjectKey, any>(name);
    }
  }

  private createValueFromParentExtractor<T, K extends keyof T>(k: K): DynamicValueExtractor<T, K> {
    return (
      obj: T,
    ): {
      found: boolean;
      value: T[K];
    } => {
      if (typeof obj == 'object' && obj !== null && k in obj) {
        return { found: true, value: obj[k] };
      }
      return { found: false, value: undefined } as any;
    };
  }

  private createHtmlGroupExtractor<T, K extends keyof T>(
    fields: Record<keyof C, DynamicFormElementType<any>>,
  ): DynamicValueExtractor<T, K> {
    return (
      value: any,
    ): {
      found: boolean;
      value: T[K];
    } => {
      const properties = keys(fields);

      if (typeof value == 'object' && value !== null) {
        const fragment = pick(value, properties);

        return { found: keys(fragment).length > 0, value: fragment } as any;
      }

      return { found: false, value: {} } as any;
    };
  }

  /**
   * Generate random string.
   * @private
   */
  private randomString() {
    let result = '';
    length = 8;
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  /**
   *
   * @param type
   * @private
   */
  private getUnknownElementError(type: string): Error {
    return new Error(`DynamicFormElement with type "${type}" is not supported in ${this.constructor.name}`);
  }
}
