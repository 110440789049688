import {Query} from "@application/framework/command-query";
import {Facility} from "@domain/facility";
import {ActivityAnnex} from "@domain/activity-annex";

export class GetActivityAnnexForYearQuery implements Query<ActivityAnnex | undefined> {

  constructor(public readonly facilityId: Facility['id'], public readonly year: number) {
  }

}
