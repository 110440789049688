import {TEMPORARY_DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding";

export interface EasyTemporaryDependencyFunding {

  id: number;

  funding_mode: TEMPORARY_DEPENDENCIES_FUNDING_MODES;

  price_gir1_2: number | null | undefined;

  price_gir3_4: number | null | undefined;

  price_gir5_6: number | null | undefined;

  amount_package: number | null | undefined;

  activity_abattement: number | null | undefined;

}

export interface CreateEasyTemporaryDependencyFundingDto extends Omit<EasyTemporaryDependencyFunding, 'id'> {
}

export interface UpdateEasyTemporaryDependencyFundingDto extends EasyTemporaryDependencyFunding {
}
