import {CUSTOMER_TRANSLATE_CONTEXT} from "@application/bundles/customer/customer.token";
import {CreateCustomerFailEvent} from "@application/bundles/customer/events/create-customer/create-customer-fail.event";
import {
  CreateCustomerSuccessEvent
} from "@application/bundles/customer/events/create-customer/create-customer-success.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";

@HasEventSubscribers()
export class OnCustomerCreationEventSubscriber {

  constructor(
    private readonly noticeStream: NoticeStream,
    private readonly translator: Translator
  ) {
  }

  @OnEvent({
    event: CreateCustomerSuccessEvent
  })
  public async pushNoticeOnCreateCustomerSuccess(event: CreateCustomerSuccessEvent): Promise<void> {

    const message = await this.translator.translate(
      "Le nouveau client <strong>{{name}}</strong> a été créé avec succès.",
      {name: event.customer.name},
      CUSTOMER_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({message, type: NoticeType.SUCCESS});
  }

  @OnEvent({
    event: CreateCustomerFailEvent
  })
  public async pushNoticeOnCreateCustomerFail(): Promise<void> {

    const message = await this.translator.translate(
      "Une erreur est survenue lors de la création du nouveau client.",
      undefined,
      CUSTOMER_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({message, type: NoticeType.ERROR});
  }
}
