<div class="form-container">

    <ng-container *ngIf="deposit">

        <ng-container [ngSwitch]="deposit.state">

            <ng-container *ngSwitchCase="STATES.WAITING_CUSTOMER">
                <div class="form">

                    <ehp-form-field class="form-row type-select-container">

                        <label for="type-selector">
                            {{'Sélectionner le type de fichier à déposer' | ehp_translate }} :
                        </label>

                        <p-dropdown
                          [(ngModel)]="current"
                          [options]="availableTypes"
                          ehp-form-control
                          id="type-selector">

                            <ng-template pTemplate="selectedItem">

                                <ehp-document-type-label
                                  *ngIf="current"
                                  [type]="current">
                                </ehp-document-type-label>

                            </ng-template>

                            <ng-template let-type pTemplate="item">
                                <ehp-document-type-label [type]="type"></ehp-document-type-label>
                            </ng-template>
                        </p-dropdown>

                    </ehp-form-field>

                    <ehp-form-field *ngIf="current" class="form-row">

                        <label for="media-uploader">
                            <ehp-document-type-label [type]="current"></ehp-document-type-label>
                        </label>
                        <ehp-media-uploader
                          (onChange)="addMedias({type: current, medias: $event})"
                          [hideFileList]="true"
                          ehp-form-control
                          id="media-uploader"
                        ></ehp-media-uploader>
                    </ehp-form-field>

                    <ul *ngIf="currentItems && currentItems.length > 0" class="items">
                        <h3 class="title">{{ 'Fichiers déposés' | ehp_translate }}</h3>

                        <li *ngFor="let item of currentItems" class="item">
                            <ng-template #itemLabel>
                                <p class="media-label">
                                    <span *ngIf="item.year" [innerHTML]="item.year"></span>
                                </p>
                            </ng-template>

                            <div class="media-wrapper">
                                <div *ngIf="item.mediaId | mediaLoader | async as media; else noMedia"
                                     class="file-deposit-item">

                                    <div class="name">
                                        <ehp-media-icon [type]="media.type"></ehp-media-icon>
                                        {{ media.name }}
                                    </div>

                                    <div class="type">
                                        <ehp-document-type-label
                                          *ngIf="item.type"
                                          [type]="item.type"
                                          class="label is-badge secondary-light"
                                        ></ehp-document-type-label>
                                    </div>

                                    <div class="sub-type">
                                        <ehp-file-deposit-media-type-label *ngIf="item.subType" [key]="item.subType"
                                                                           [type]="item.type"></ehp-file-deposit-media-type-label>
                                    </div>

                                    <div class="year">
                                        <ng-container></ng-container>
                                    </div>

                                    <div class="actions">
                                        <ehp-media-download-button [media]="media"></ehp-media-download-button>
                                        <ehp-media-preview-button [media]="media"></ehp-media-preview-button>
                                    </div>
                                </div>

                                <ng-template #noMedia>
                                    <ng-container [ngTemplateOutlet]="itemLabel"></ng-container>
                                </ng-template>

                            </div>

                            <button (click)="deleteItem(item)" class="is-icon icon-delete delete outline">
                                <span class="label">{{ 'Supprimer' | ehp_translate }}</span>
                            </button>

                        </li>
                    </ul>

                    <ul *ngIf="lockedItems && lockedItems.length > 0" class="items">
                        <h3 class="title">{{ 'Fichiers validés' | ehp_translate }}</h3>

                        <li *ngFor="let item of lockedItems" class="item file-deposit-item validated">

                            <ng-container *ngIf="item.mediaId | mediaLoader | async as media">

                                <i [tooltip]="lockIcon" aria-hidden="true" class="icon icon-lock ehp-icon"
                                   ehp-tooltip></i>

                                <div class="name">
                                    <ehp-media-icon [type]="media.type"></ehp-media-icon>
                                    {{ media.name }}
                                </div>

                                <div class="type">
                                    <ehp-document-type-label *ngIf="item.type"
                                                             [type]="item.type"></ehp-document-type-label>
                                </div>

                                <div class="sub-type">
                                    <ehp-file-deposit-media-type-label *ngIf="item.subType" [key]="item.subType"
                                                                       [type]="item.type"></ehp-file-deposit-media-type-label>
                                </div>

                                <div class="year">
                                    <ng-container></ng-container>
                                </div>

                                <div class="actions">
                                    <ehp-media-download-button [media]="media"></ehp-media-download-button>
                                    <ehp-media-preview-button [media]="media"></ehp-media-preview-button>
                                </div>

                            </ng-container>
                        </li>

                    </ul>
                </div>

                <div class="actions-bar">
                    <p [ngClass]="{'saved': !saving}" class="saving">
                        <ng-container *ngIf="saving; else saved">
                            <span class="loader"></span>
                            <span class="label">{{'Sauvegarde en cours' | ehp_translate}}</span>
                        </ng-container>

                        <ng-template #saved>
                            <i class="icon ehp-icon icon-check-valid"></i>
                            <span class="label">{{ 'Sauvegardé' | ehp_translate }}</span>
                        </ng-template>
                    </p>

                    <div class="actions-list">
                        <button (click)="requestReview()" class="primary" type="button">
                            {{'Valider le dépôt' | ehp_translate }}
                        </button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="STATES.REVIEW">
                <p class="state-message">
                    <span class="loader"></span>

                    {{'Ce dépôt est en cours de validation par nos équipes.' | ehp_translate}}<br/>
                    {{'Si aucun fichier n\'est manquant, il sera alors traité et vous retrouverez vos documents dans votre bibliothèque.' | ehp_translate}}
                </p>
            </ng-container>

            <ng-container *ngSwitchCase="STATES.ARCHIVED">
                <p class="state-message">
                    {{'Ce dépôt est en actuellement archivé et plus aucun fichier ne peut être ajouté. Retrouvez l\'ensemble de vos documents directement depuis votre bibliothèque.' | ehp_translate}}
                </p>
            </ng-container>

            <ng-container *ngSwitchCase="STATES.CLOSE">
                <p class="state-message">
                    {{'Ce dépôt est en actuellement fermé et plus aucun fichier ne peut être ajouté. Retrouvez l\'ensemble de vos documents directement depuis votre bibliothèque.' | ehp_translate}}
                </p>
            </ng-container>

            <ng-container *ngSwitchCase="STATES.DRAFT">
                <p class="state-message">
                    {{'Ce dépôt est en brouillon. Un peu de patience, vous pourrez prochainement nous déposer vos fichiers.' | ehp_translate}}
                </p>
            </ng-container>

        </ng-container>
    </ng-container>
</div>


<div class="list-container">

    <div class="title-wrapper">
        <h2 class="title">{{ 'Documents attendus' | ehp_translate }}</h2>
    </div>

    <div *ngIf="deposit && deposit.id" class="list-wrapper">

        <ehp-file-deposit-expected-media-list
          [fileDepositId]="deposit.id"
          [items]="deposit.items">
        </ehp-file-deposit-expected-media-list>

    </div>
</div>

<ng-template #lockIcon>
    {{'Ce fichier à été validé,' | ehp_translate}}<br/> {{'il n\'est donc plus modifiable.' | ehp_translate }}
</ng-template>

