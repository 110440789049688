import {TariffOptionAmount} from "@domain/cpom";
import {Year} from "@domain/lib";
import {isValidFrenchOverseasPostalCode} from "@domain/address";

export class AgnosticTariffOptionAmount implements TariffOptionAmount {


  constructor(public year: Year, public mainland: number, public overseas: number) {
  }


  public value(postalCode: string): { value: number; type: "mainland" | "overseas" } {
    if (isValidFrenchOverseasPostalCode(postalCode)) {
      return {value: this.overseas, type: 'overseas'}
    }

    return {value: this.mainland, type: 'mainland'}

  }
}
