import { RecurringHealthFunding } from '@domain/health-funding';
import { isUUID } from '@domain/lib';
import { isValidTaxonomyTermIdType } from '@domain/taxonomy';

export function isValidRecurringHealthFundingIdType(id: any): id is RecurringHealthFunding['id'] {
  return isUUID(id);
}

export function isMaybeRecurringHealthFunding(funding: any): funding is Omit<RecurringHealthFunding, 'id'> {
  return (
    typeof funding === 'object' &&
    funding !== null &&
    (funding.name === undefined || (typeof funding.name === 'string' && funding.name)) &&
    (funding.categoryId === undefined || isValidTaxonomyTermIdType((funding as RecurringHealthFunding).categoryId))
  );
}

export function isRecurringHealthFunding(funding: any): funding is RecurringHealthFunding {
  return (
    isMaybeRecurringHealthFunding(funding) &&
    isValidRecurringHealthFundingIdType((funding as RecurringHealthFunding).id)
  );
}
