import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[ehpPreviewHost]'
})
export class PreviewHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
