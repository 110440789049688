import { Component, HostListener, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { QueryBus } from '@application/framework/command-query';
import { Router } from '@angular/router';
import { EhpadDemain } from '@domain/ehpad-demain';
import { EhpadDemainUrlsProvider } from '@easyhpad-ui/app/bundles/ehpad-demain/provider/urls-provider/urls-provider';
import { GetEhpadDemainQuery } from '@application/bundles/ehpad-demain';

@Component({
  selector: 'ehp-ehpad-demain-link',
  templateUrl: './ehpad-demain-link.component.html',
  styleUrls: ['./ehpad-demain-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.link]': 'isLink',
    '[attr.role]': 'role',
  },
})
export class EhpadDemainLinkComponent implements OnChanges {
  @Input() public document!: EhpadDemain;

  @Input() public refId!: EhpadDemain['id'];

  private url: string | null | undefined;

  public get isLink(): boolean {
    return !!this.url;
  }

  public get role(): string | undefined {
    return this.isLink ? 'link' : undefined;
  }

  constructor(
    private readonly queryBus: QueryBus,
    private readonly urls: EhpadDemainUrlsProvider,
    private readonly router: Router,
  ) {}

  @HostListener('click', ['$event'])
  public async click(event: MouseEvent) {
    if (!this.isLink) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.url !== undefined && this.url !== null) {
      return this.router.navigateByUrl(this.url);
    }

    return false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['refId']) {
      if (changes['refId'].previousValue !== changes['refId'].currentValue) {
        this.loadDocument();
      }
    }

    if (changes['document']) {
      if (changes['document'].previousValue !== changes['document'].currentValue) {
        this.documentHasChange();
      }
    }
  }

  private loadDocument(): void {
    if (this.refId) {
      this.queryBus.request<EhpadDemain>(new GetEhpadDemainQuery(this.refId)).then((document) => {
        this.document = document;
        this.documentHasChange();
      });
    }
  }

  private setUrl(): void {
    this.url = null;

    if (this.document) {
      this.url = this.urls.getUnsafePublicShow(this.document.id);
    }
  }

  private documentHasChange(): void {
    this.setUrl();
  }
}
