import {Injectable} from "@angular/core";
import {joinPath} from "@application/framework/lib";
import {Customer} from "@domain/customer";
import {CustomerAuthorizationChecker} from "@application/bundles/customer";

@Injectable()
export class CustomerUrlsProvider {

  public static readonly base = 'customer';

  public static readonly adminBase = 'admin/customer';

  public static readonly adminSchema = {
    list: {path: '', title: 'Administration : Clients'},
    create: {path: 'create', title: 'Nouveau client'},
    update: {path: ':id', title: 'Modifier le client'},
  }


  constructor(private readonly authorizationChecker: CustomerAuthorizationChecker) {
  }

  public getAdminList(): Promise<string | null> {
    return this.authorizationChecker.canManage().then(can => can ? this.getUnsafeAdminList() : null);
  }

  public getUnsafeAdminList(): string {
    return joinPath('/', CustomerUrlsProvider.adminBase, CustomerUrlsProvider.adminSchema.list.path);
  }


  public getUnsafeAdminShow(id: Customer['id']): string {
    return joinPath('/', CustomerUrlsProvider.adminBase, id.toString());
  }


  public getUnsafeAdminUpdate(id: Customer['id']): string {
    return joinPath('/', CustomerUrlsProvider.adminBase, id.toString());
  }

}
