import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalActions } from '@easyhpad-ui/app/store/global.action';
import { SettingsStore } from '@application/bundles/application/store/settings-store';
import { tap } from 'rxjs';
import { Facility } from '@domain/facility';

@Injectable()
export class GlobalEffects {
  private static readonly ONBOARDING_SETTING = 'facility_onboarding_accepted';
  /*private loadCurrentAccountRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.setAccount),
      switchMap(() => of([])),
      //switchMap(action => (action.account ? from(action.account.getRoles().then(c => c.all())) : of([]))),
      map(roles => GlobalActions.setRoles({ roles })),
    ),

  );*/

  public loadCurrentAccountRoles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.validateFacilityOnboarding),
        tap(action => {
          let validated: Array<Facility['id']> = this.settings.get(GlobalEffects.ONBOARDING_SETTING);
          if (!Array.isArray(validated)) {
            validated = [];
          }

          validated = Array.from(new Set([...validated, action.id]));
          this.settings.set(GlobalEffects.ONBOARDING_SETTING, validated);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly settings: SettingsStore,
  ) {
    this.loadInitialFacilitiesOnboarding();
  }

  /**
   * Get facility ids for validated onboarding from the settings.
   * @private
   */
  private loadInitialFacilitiesOnboarding() {
    const validated: Array<any> = this.settings.get(GlobalEffects.ONBOARDING_SETTING);

    if (Array.isArray(validated)) {
      this.store.dispatch(GlobalActions.setFacilitiesOnboarding({ ids: validated }));
    }
  }
}
