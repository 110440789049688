import {Location} from '@angular/common'
import {Injectable} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RouteSailor {

  public static readonly URL_PARAMS = 'redirect_to';


  private readonly history: string[] = [];

  public get router(): Router {
    return this._router;
  }

  constructor(
    private readonly _router: Router,
    private readonly location: Location
  ) {
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }

    })
  }

  public async catchAndRedirect(route: ActivatedRoute, defaultUrl: string) {
    let redirect: string = await firstValueFrom(route.queryParams).then(params => params[RouteSailor.URL_PARAMS]);

    if (!redirect) {
      redirect = defaultUrl;
    }

    await this.router.navigate([redirect]);
  }

  public async back() {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back();
    } else {
      await this.router.navigateByUrl('/')
    }
  }
}
