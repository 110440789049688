import {Injectable, Injector, Type} from "@angular/core";
import {Container} from "@application/framework/di";
import {ServiceMetadata} from "@application/framework/di/interfaces/service-metadata.interface";
import {ServiceIdentifier} from "@application/framework/di/types/service-identifier";
import {Logger} from "@application/framework/logger";
import {createStaticProviderFromServiceMetadata} from "@easyhpad-ui/app/framework/di/functions/create-static-provider";
import {wrapServiceIdentifier} from "@easyhpad-ui/app/framework/di/functions/wrap-service-identifier.function";

@Injectable({
  providedIn: 'root'
})
export class AngularContainer extends Container implements Container {

  private static readonly ID = Symbol('ANGULAR_DI_CONTAINER');

  public constructor(private injector: Injector, private logger: Logger) {
    super(AngularContainer.ID);
  }

  /**
   * @inheritDoc
   * @param identifier
   */
  public override has<T = unknown>(identifier: ServiceIdentifier<T>): boolean {
    return this.injector.get(wrapServiceIdentifier(identifier), null, {optional: true}) === null;
  }

  public override get<T = unknown>(identifier: ServiceIdentifier<T>): T {
    return this.injector.get(wrapServiceIdentifier(identifier));
  }

  public override set<T = unknown>(metadata: ServiceMetadata<T>): boolean {
    this.metadataMaps.set(metadata.id, metadata);

    const token = createStaticProviderFromServiceMetadata(metadata);
    this.injector = Injector.create({providers: [token], parent: this.injector});

    return true;
  }

  /**
   * @inheritDoc
   * @deprecated
   * @param token
   * @param value
   * @param dependencies
   */
  public override bind(token: any, value: Type<any>, ...dependencies: any[]): void {
    this.logger.warning(`${this.constructor.name}.bind() is deprecated. Please replace implementation by ${this.constructor.name}.set() or use @Service() decorator`);
    this.injector = Injector.create({
      providers: [{provide: token, useClass: value, deps: dependencies}],
      parent: this.injector
    });
  }

  /**
   * @inheritDoc
   * @deprecated
   * @param token
   * @param dependencies
   */
  public override make<T>(token: T, ...dependencies: any[]): T {
    this.logger.warning(`${this.constructor.name}.make() is deprecated. Please replace implementation by ${this.constructor.name}.set() or use @Service() decorator`);

    this.bind(token, token as any, ...dependencies);
    return this.get(token as any);
  }

  /**
   * @inheritDoc
   * @deprecated
   * @param token
   * @param dependencies
   */
  public override register<T extends { new(...args: any): any }>(token: T, dependencies: ConstructorParameters<T>): void {
    this.logger.warning(`${this.constructor.name}.register() is deprecated. Please replace implementation by ${this.constructor.name}.set() or use @Service() decorator`);

    this.injector = Injector.create({
      providers: [{provide: token, useClass: token, deps: dependencies}],
      parent: this.injector
    });
  }

}
