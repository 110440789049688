import { NgModule } from '@angular/core';
import { CPOMBundle, CPOMDtoFactory, CPOMRepository, TariffOptionCollection } from '@application/bundles/cpom';
import { Bundle } from '@application/framework/bundle';
import { extractDependencies } from '@application/framework/di';
import { OpenCPOMDeletionDialogCommandHandler } from '@easyhpad-ui/app/bundles/cpom/commands/handlers';
import { CPOMUrlsProvider } from '@easyhpad-ui/app/bundles/cpom/providers/url-provider/cpom-urls.provider';
import { CPOMDashboardModule } from '@easyhpad-ui/app/bundles/cpom/modules/dashboard/cpom-dashboard.module';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator/validator.module';
import { EasyCPOMRepository } from '@implementations/bundles/backend/easyhpad';
import {
  CpomItemsDtoFactory,
  CreateCPOMDtoImpl,
  CreateCPOMFundingDtoImpl,
  CreateLocalCPOMDtoImpl,
  UpdateCPOMDtoImpl,
  UpdateCPOMFundingDtoImpl,
  UpdateLocalCPOMDtoImpl,
} from '@implementations/bundles/cpom';

import {
  createCPOMDtoValidator,
  createCPOMFundingDtoValidator,
  createLocalCPOMDtoValidator,
  updateCPOMDtoValidator,
  updateCPOMFundingDtoValidator,
  updateLocalCPOMDtoValidator,
} from '@implementations/bundles/cpom/validators';
import { AgnosticTariffOptionCollection } from '@application/bundles/cpom/implementations/agnostic-tariff-option-collection/agnostic-tariff-option.collection';
import { TariffOptionAmountCollection } from '@domain/cpom';
import { AgnosticTariffOptionAmountCollection } from '@implementations/bundles/cpom/implementations/agnostic-tariff-option-amount-collection/agnostic-tariff-option-amount.collection';
import { MediaModule } from '@easyhpad-ui/app/bundles/media/media.module';
import { GetCPOMWidgetQueryHandler } from '@easyhpad-ui/app/bundles/cpom/queries/handlers';

@Bundle({
  imports: [CPOMBundle],
  commandHandlers: [OpenCPOMDeletionDialogCommandHandler],
  queryHandlers: [GetCPOMWidgetQueryHandler],
})
@NgModule({
  imports: [
    CPOMDashboardModule,
    ValidatorModule.forChild({
      validators: [
        { objectType: CreateCPOMDtoImpl, validator: createCPOMDtoValidator },
        { objectType: UpdateCPOMDtoImpl, validator: updateCPOMDtoValidator },
        { objectType: CreateLocalCPOMDtoImpl, validator: createLocalCPOMDtoValidator },
        { objectType: UpdateLocalCPOMDtoImpl, validator: updateLocalCPOMDtoValidator },
        { objectType: CreateCPOMFundingDtoImpl, validator: createCPOMFundingDtoValidator },
        { objectType: UpdateCPOMFundingDtoImpl, validator: updateCPOMFundingDtoValidator },
      ],
    }),
    MediaModule,
  ],
  providers: [
    {
      provide: CPOMUrlsProvider,
    },
    {
      provide: CPOMRepository,
      useClass: EasyCPOMRepository,
      deps: extractDependencies(EasyCPOMRepository),
    },
    {
      provide: CPOMDtoFactory,
      useClass: CpomItemsDtoFactory,
    },
    {
      provide: TariffOptionCollection,
      useValue: new AgnosticTariffOptionCollection(),
    },
    {
      provide: TariffOptionAmountCollection,
      useValue: new AgnosticTariffOptionAmountCollection(),
    },
  ],
})
export class CPOMModule {
  constructor() {
    new CPOMBundle();
  }
}
