import { Injectable } from '@angular/core';
import { joinPath } from '@application/framework/lib';
import { AuthorizationChecker } from '@application/bundles/authorization';
import { DEMO_FEATURE } from '@application/bundles/demo';

@Injectable()
export class DemoUrlsProvider {
  public static readonly adminBase = 'admin/demo';

  public static readonly adminSchema = {
    list: { path: '', title: 'Administration : Démo' },
  };

  constructor(private readonly authorizationChecker: AuthorizationChecker) {}

  public async getAdminPage(): Promise<string | null> {
    return await this.authorizationChecker
      .isGranted([{ feature: DEMO_FEATURE, value: 'create demo' }])
      .then(can => (can ? this.getUnsafeAdminPage() : null));
  }

  public getUnsafeAdminPage(): string {
    return joinPath('/', DemoUrlsProvider.adminBase, DemoUrlsProvider.adminSchema.list.path);
  }
}
