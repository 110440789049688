import {
  CreateDaycareDependencyFundingDto,
  UpdateDaycareDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {DAYCARE_DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding";


export abstract class DaycareDependencyFundingDtoFactory {

  public abstract buildCreateDtoFromUnsafeValues<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES, V>(values: Partial<CreateDaycareDependencyFundingDto<M, V>>): CreateDaycareDependencyFundingDto<M, V>;

  public abstract buildUpdateDtoFromUnsafeValues<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES, V>(values: Partial<UpdateDaycareDependencyFundingDto<M, V>>): UpdateDaycareDependencyFundingDto<M, V>;

}
