import { PhoneRegionCode } from '@domain/phone/phone.interface';
import { ApplicationConfiguration } from '@implementations/configuration/configuration';
import { TARIFF_OPTIONS } from '@domain/cpom';
import { Year } from '@domain/lib';
import { PointsGirListImpl } from '@application/bundles/gir';
import { GIR } from '@domain/gir';

const now = new Date();

export const configuration: ApplicationConfiguration = Object.freeze({
  appName: 'EasyHPAD',
  appSlogan: 'Gérer vos documents',

  homePath: '/',
  loginPath: '/login',
  logoutPath: '/logout',
  forgottenPasswordPath: '/password',
  defaultPhoneNumberRegion: PhoneRegionCode.FR,
  defaultLocalCode: 'fr-FR',
  nationalPMPIndicators: new Map<Year, number>([[2022, 222]]),
  cpom: {
    defaultCPOMDurationInMonth: 5 * 12,
    tariffOptionAmountValues: new Map([
      [TARIFF_OPTIONS.GLOBAL_WI_PUI, new Map([[2022, { mainland: 13.3, overseas: 15.96 }]])],
      [TARIFF_OPTIONS.GLOBAL_WO_PUI, new Map([[2022, { mainland: 12.63, overseas: 15.15 }]])],
      [TARIFF_OPTIONS.PARTIAL_WI_PUI, new Map([[2022, { mainland: 11.33, overseas: 13.59 }]])],
      [TARIFF_OPTIONS.PARTIAL_WO_PUI, new Map([[2022, { mainland: 10.69, overseas: 12.83 }]])],
    ]),
  },
  pointsGirList: new PointsGirListImpl(
    undefined,
    new Map([
      [GIR.GIR1, 1000],
      [GIR.GIR2, 840],
      [GIR.GIR3, 660],
      [GIR.GIR4, 420],
      [GIR.GIR5, 250],
      [GIR.GIR6, 70],
    ]),
  ),
});
