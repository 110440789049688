import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";


export interface AccommodationFundingDocumentParams {
}


export interface SerializedAccommodationFundingSearchParams extends SerializedDocumentParams<AccommodationFundingDocumentParams> {
}


export abstract class AccommodationFundingSearchParams extends DocumentListParams implements Serializable<SerializedAccommodationFundingSearchParams> {
}
