import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  DEPENDENCY_FUNDING_TRANSLATE_CONTEXT,
  DependencyFundingRepository,
  DependencyFundingUpdatedEvent,
  DependencyFundingUpdateFailEvent,
  GetDependencyFundingQuery,
  UpdateDependencyFundingCommand,
  UpdateDependencyFundingDto,
} from '@application/bundles/dependency-funding';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { DependencyFunding } from '@domain/dependency-funding';
import { isLocalMedia, isMedia, MediaBucket, TemporaryMedia } from '@domain/media';

@HandleCommand({
  command: UpdateDependencyFundingCommand,
})
export class UpdateDependencyFundingCommandHandler
  implements CommandHandler<UpdateDependencyFundingCommand, DependencyFunding>
{
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: DependencyFundingRepository,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: UpdateDependencyFundingCommand): Promise<DependencyFunding> {
    const { dependencyFunding } = command;
    try {
      const updated = await this.sanitize(dependencyFunding)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.updateDependencyFunding(dto));

      this.eventDispatcher.dispatch(new DependencyFundingUpdatedEvent(updated));

      return updated;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: UpdateDependencyFundingDto): Promise<UpdateDependencyFundingDto> {
    return dto;
  }

  private async sanitize(dto: UpdateDependencyFundingDto): Promise<UpdateDependencyFundingDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('DependencyFunding update : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdateDependencyFundingDto): Promise<UpdateDependencyFundingDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('DependencyFunding update : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à mettre à jour ce financement dépendance.",
        undefined,
        DEPENDENCY_FUNDING_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new DependencyFundingUpdateFailEvent(message));

    return error;
  }

  private async updateDependencyFunding(dto: UpdateDependencyFundingDto): Promise<DependencyFunding> {
    const current = await this.queryBus.request(new GetDependencyFundingQuery(dto.id));

    const documents: TemporaryMedia[] = [];

    if (dto.documentId !== current.documentId || isLocalMedia(dto.document)) {
      if (dto.document && isLocalMedia(dto.document)) {
        const document = await this.mediaBucket.add(dto.document);
        dto.documentId = document.id;
        documents.push(document);
      } else if (dto.document && isMedia(dto.document)) {
        dto.documentId = dto.document.id;
      }
    }

    if (dto.reportId !== current.reportId || isLocalMedia(dto.report)) {
      if (dto.report && isLocalMedia(dto.report)) {
        const report = await this.mediaBucket.add(dto.report);
        dto.reportId = report.id;
        documents.push(report);
      } else if (dto.report && isMedia(dto.report)) {
        dto.reportId = dto.report.id;
      }
    }

    try {
      return await this.repository.update(dto.id, dto);
    } catch (e) {
      for (const document of documents) {
        await document.markForDeletion();
      }

      throw e;
    }
  }
}
