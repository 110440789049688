import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { SupportRequestSendFail, SupportRequestSentEvent } from '@application/bundles/support';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';

@HasEventSubscribers()
export class SupportRequestSendingSubscriber {
  constructor(private readonly noticeStream: NoticeStream) {}

  /**
   * Display notice when support request is sent.
   * @param event
   */
  @OnEvent({
    event: [SupportRequestSentEvent],
  })
  public dispatchNoticeOnSuccess(event: SupportRequestSentEvent) {
    const message = new TranslatableString("Votre demande d'assistance à bien été envoyée.");
    this.noticeStream.push({ message, type: NoticeType.SUCCESS });
  }

  /**
   * Display notice when support request cannot be sent.
   * @param event
   */
  @OnEvent({
    event: [SupportRequestSendFail],
  })
  public dispatchNoticeOnError(event: SupportRequestSendFail) {
    const message = new TranslatableString(
      "Votre demande d'assistance m'a pu aboutir. Nous vous invitons à réessayer plus tard.",
    );
    this.noticeStream.push({ message, type: NoticeType.ERROR });
  }
}
