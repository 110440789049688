import {CreateLocalEPRDDto} from "@application/bundles/eprd-errd";
import {LocalCommonAbstractDto} from "./local-common.abstract.dto";
import {CRPP} from "@domain/eprd-errd";
import {IsArray} from "class-validator";

export class CreateLocalEPRDDtoImpl extends LocalCommonAbstractDto implements CreateLocalEPRDDto {

  @IsArray()
  public CRPP: CRPP[] = [];


  constructor(defaults?: Partial<CreateLocalEPRDDto>) {
    super(defaults);

    if (defaults && Array.isArray(defaults.CRPP)) {
      this.CRPP = defaults.CRPP;
    }

  }
}
