import { isValidObject } from '@application/framework/lib';
import { LocalERRD } from '@domain/eprd-errd';
import { isUUID } from '@domain/lib';

export function isValidLocalERRDIdType(id: any): id is LocalERRD['id'] {
  return isUUID(id);
}

export function isMaybeALocalERRD(eprd: any): eprd is { id: LocalERRD['id'] } {
  return isValidObject(eprd) && 'id' in eprd && isValidLocalERRDIdType((eprd as LocalERRD).id);
}
