import { CreateGMPDto, UpdateGMPDto } from '@application/bundles/gmp';
import { Transformer } from '@application/framework/core';
import { DateFormatter } from '@application/framework/date';
import { DateParser } from '@application/framework/date/date.parser';
import { Service } from '@application/framework/di';
import { Common } from '@application/framework/lib';
import { GIR, isValidResidentTotal, ResidentGIRList } from '@domain/gir';
import { GMP } from '@domain/gmp';
import { entries } from '@domain/lib';
import { EasyGMP, EasyGMPCreateDto, EasyGMPUpdateDto } from '@implementations/bundles/backend/easyhpad';

type GMPCommon = Omit<Common<GMP, Common<CreateGMPDto, UpdateGMPDto>>, 'pv'>;

type EasyGMPCommon = Omit<Common<EasyGMP, Common<EasyGMPCreateDto, EasyGMPUpdateDto>>, 'pvId'>;

type EasyGMPGIRList = Pick<EasyGMPCommon, 'gir1' | 'gir2' | 'gir3' | 'gir4' | 'gir5' | 'gir6'>;

@Service()
export class EasyGMPCommonTransformer implements Transformer<GMPCommon, EasyGMPCommon> {
  private readonly EasyGIRKeys: Record<GIR, keyof EasyGMPGIRList> = {
    [GIR.GIR1]: 'gir1',
    [GIR.GIR2]: 'gir2',
    [GIR.GIR3]: 'gir3',
    [GIR.GIR4]: 'gir4',
    [GIR.GIR5]: 'gir5',
    [GIR.GIR6]: 'gir6',
  };

  constructor(
    private readonly dateParser: DateParser,
    private readonly dateFormatter: DateFormatter,
  ) {}

  public transform(value: GMPCommon): EasyGMPCommon {
    return {
      value: value.value,
      validationDate: this.dateFormatter.toUTCIsoString(value.date),
      facilityId: value.facilityId,
      ...this.serializeResidentList(value.residents),
    };
  }

  reverseTransform(value: EasyGMPCommon): GMPCommon {
    return {
      facilityId: value.facilityId,
      value: value.value ? parseFloat(value.value.toString()) : 0,
      date: this.dateParser.fromISOString(value.validationDate),
      residents: this.deserializeResidentList(value),
    };
  }

  private deserializeResidentList(value: EasyGMPCommon): ResidentGIRList {
    const residents = {} as ResidentGIRList;

    for (const [gir, key] of entries(this.EasyGIRKeys)) {
      const total = value[key];
      residents[gir] = isValidResidentTotal(total) ? total : 0;
    }

    return residents;
  }

  private serializeResidentList(list: ResidentGIRList): EasyGMPGIRList {
    const values: EasyGMPGIRList = {};

    for (const [gir, key] of entries(this.EasyGIRKeys)) {
      values[key] = list[gir] ?? 0;
    }

    return values;
  }
}
