import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RequestParams } from '@application/framework/http/request.interface';
import { GetCPOMWidgetQuery } from '@easyhpad-ui/app/bundles/cpom/queries/get-cpom-widget.query';
import { StoredCPOM } from '@application/bundles/cpom';
import { EasyCPOM } from '@implementations/bundles/backend/easyhpad';
import { EasyCPOMTransformer } from '@implementations/bundles/backend/easyhpad/transformers';
import { CurrentCpomWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces/widgets/current-cpom-widget-content';

type BackendWidgetContent = Omit<CurrentCpomWidgetContent, 'cpom'> & { cpom?: EasyCPOM };

@HandleQuery({
  query: GetCPOMWidgetQuery,
})
export class GetCPOMWidgetQueryHandler implements QueryHandler<GetCPOMWidgetQuery, CurrentCpomWidgetContent> {
  constructor(
    private readonly backend: EasyBackendApi,
    private readonly transformer: EasyCPOMTransformer,
  ) {}

  public async handle(query: GetCPOMWidgetQuery): Promise<CurrentCpomWidgetContent> {
    const params: RequestParams = {};

    if (Array.isArray(query.ids) && query.ids.length > 0) {
      params['facility_ids'] = query.ids.join(', ');
    }

    const response = await this.backend.get<BackendWidgetContent>('/widgets/cpom', params);
    return this.buildContent(response.body);
  }

  private buildContent(content: BackendWidgetContent): CurrentCpomWidgetContent {
    let cpom: StoredCPOM | undefined;

    if (content.cpom) {
      cpom = this.transformer.transform(content.cpom);
    }

    return { ...content, cpom };
  }
}
