import { extractDependencies } from '@application/framework/di';
import { ServiceMetadata, ServiceOptions } from '@application/framework/di/interfaces/service-metadata.interface';
import { isClassProvider, isFactoryProvider, isValueProvider } from '@application/framework/di/providers';
import { ServiceIdentifier } from '@application/framework/di/types/service-identifier';

export const createServiceMetadata = (options: ServiceOptions): ServiceMetadata => {
  const metadata = {
    id: ((options as any).id || (options as any).lazyType) as ServiceIdentifier,
  };

  if (isFactoryProvider(options)) {
    return { ...metadata, useFactory: options.useFactory };
  }

  if (isValueProvider(options)) {
    return { ...metadata, useValue: options.useValue };
  }

  if (isClassProvider(options)) {
    const dependencies = extractDependencies(options.useClass);
    return { ...metadata, useClass: options.useClass, dependencies };
  }

  throw new Error('Unknown provider type');
};
