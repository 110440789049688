import { createEntityAdapter } from '@ngrx/entity';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { AccommodationFundingStoreState } from '@easyhpad-ui/app/bundles/accommodation-funding/store/accommodation-funding.state';
import { createDocumentReducer, createInitialDocumentState } from '@easyhpad-ui/app/bundles/documents';
import { accommodationActions } from './accommodation-funding.action';

export const accommodationFundingStoreEntityAdapter = createEntityAdapter<AccommodationFunding>();

const initialState: AccommodationFundingStoreState = createInitialDocumentState(accommodationFundingStoreEntityAdapter);

export const accommodationFundingReducer = createDocumentReducer<AccommodationFunding>(
  accommodationFundingStoreEntityAdapter,
  initialState,
  accommodationActions,
);
