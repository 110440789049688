import { ETP_CATEGORIES } from '@domain/etp/etp-categories';
import { ETPCategory } from '@domain/etp';

export abstract class ETPCategoryCollection {
  public abstract has(key: ETP_CATEGORIES): boolean;

  public abstract get(key: ETP_CATEGORIES): ETPCategory;

  public abstract list(): ETPCategory[];
}
