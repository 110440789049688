import { NgModule } from '@angular/core';
import { DashboardPublicRoutingModule } from './dashboard-public-routing.module';
import { DashboardPageComponent } from '@easyhpad-ui/app/bundles/dashboard/modules/public/pages/dashboard-page/dashboard-page.component';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { MainDashboardWidgetCollection } from '@easyhpad-ui/app/bundles/dashboard/modules/public/collections/dashboard-widgets-collection';
import { DashboardWidgetsModule } from '@easyhpad-ui/app/bundles/dashboard/modules/widgets/dashboard-widgets.module';
import { DashboardModule } from '@easyhpad-ui/app/bundles/dashboard/dashboard.module';
import { CommonModule } from '@angular/common';
import { FileDepositDashboardModule } from '@easyhpad-ui/app/bundles/file-deposit/modules/dashboard/file-deposit-dashboard.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardPublicRoutingModule,
    ReusableComponentsModule,
    TranslationModule,
    DashboardWidgetsModule,
    DashboardModule,
    FileDepositDashboardModule,
  ],
  declarations: [DashboardPageComponent],
  providers: [MainDashboardWidgetCollection],
})
export class DashboardPublicModule {}
