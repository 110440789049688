import {ERRDSearchParams} from "../errd-search-params";
import {ERRD} from "@domain/eprd-errd";
import {CreateERRDDto, UpdateERRDDto} from "../dto";

export abstract class ERRDRepository {

  public abstract list(params?: ERRDSearchParams): Promise<ERRD[]>;

  public abstract get(id: ERRD['id']): Promise<ERRD>;

  public abstract create(errd: CreateERRDDto): Promise<ERRD>;

  public abstract update(id: ERRD['id'], update: Partial<UpdateERRDDto>): Promise<ERRD>;

  public abstract delete(id: ERRD['id']): Promise<ERRD>;
}
