import {Component} from '@angular/core';

@Component({
  selector: 'ehp-overview-item-list',
  templateUrl: './overview-item-list.component.html',
  styleUrls: ['./overview-item-list.component.scss']
})
export class OverviewItemListComponent {

}
