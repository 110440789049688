import {AddCapacityAuthorizationDto} from "@application/bundles/capacity-authorization/dtos";
import {Transformer} from "@application/framework/core";
import {
  EasyAddCapacityAuthorization
} from "@implementations/bundles/backend/easyhpad/interfaces/easy-capacity-authorization.interface";
import {
  EasyCapacityAuthorizationAbstractTransformer
} from "@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-capacity-authorization.abstract.transformer";

export class EasyAddCapacityAuthorizationTransformer extends EasyCapacityAuthorizationAbstractTransformer implements Transformer<AddCapacityAuthorizationDto, EasyAddCapacityAuthorization> {


  public transform(value: AddCapacityAuthorizationDto): EasyAddCapacityAuthorization {

    return {
      ...this.transformCommonProperties(value),
    }
  }

  public reverseTransform(value: EasyAddCapacityAuthorization): AddCapacityAuthorizationDto {
    throw new Error(`Missing reverseTransform method implementation in ${this.constructor.name}`);
  }

}
