import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {CPOM} from "@domain/cpom";

export class CPOMUpdatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly cpom: CPOM) {
    super();
  }
}
