import {Attribute, ATTRIBUTE_SCOPE} from "@application/bundles/authorization";


export function isAttribute<A = any>(attribute: any): attribute is Attribute<A> {
  return attribute !== null
    && typeof attribute === 'object'
    && (typeof attribute.feature === 'string' || typeof attribute.feature === 'symbol')
    && typeof attribute.value !== 'undefined'
    && (typeof attribute.scope === 'undefined' || attribute.scope === ATTRIBUTE_SCOPE.OWNED)
}
