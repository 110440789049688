import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidDaycareDependencyFundingIdType} from "@application/bundles/dependency-funding";

@ValidatorConstraint({name: 'IsValidDaycareDependencyFundingId', async: true})
export class IsValidDaycareDependencyFundingIdConstraint implements ValidatorConstraintInterface {

  public validate(id: any, args?: ValidationArguments): Promise<boolean> {
    return Promise.resolve(isValidDaycareDependencyFundingIdType(id));
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'ID is not a valid daycare dependency funding ID.';
  }
}

export function IsValidDaycareDependencyFundingId(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidDaycareDependencyFundingIdConstraint
    });
  };
}
