import { Document } from '@domain/document';
import { DocumentListViewContainer, DocumentListViewProcessorInstance } from '@easyhpad-ui/app/bundles/documents';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DocumentActionLink } from '@easyhpad-ui/app/bundles/documents/contracts/document-action';
import { TranslatableString } from '@application/framework/translation';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';

export abstract class DocumentFromStoreListViewProcessorAbstract<D extends Document>
  implements DocumentListViewProcessorInstance
{
  /**
   * @inheritDoc
   */
  public readonly title: BehaviorSubject<Array<string | TranslatableString>>;

  /**
   * @inheritDoc
   */
  public readonly actions: Observable<{ create?: Omit<DocumentActionLink, 'classes' | 'label'> }>;

  /**
   * Document actions emitter
   * @protected
   */
  protected readonly actions$ = new Subject<{ create?: Omit<DocumentActionLink, 'classes' | 'label'> }>();

  /**
   * Use this subject in `takeUntil` pipe to unsubscribe a subscription
   * when `this.destroy()` is called.
   */
  protected readonly destroy$ = new Subject<void>();

  protected constructor(
    protected readonly parent: DocumentListViewContainer,
    protected readonly store: Store<AppState>,
  ) {
    this.actions = this.actions$.asObservable();
    this.title = new BehaviorSubject<Array<string | TranslatableString>>(this.getDocumentTitle());
  }

  /**
   * Get the document title trails.
   */
  public abstract getDocumentTitle(): Array<string | TranslatableString>;

  /**
   * @inheritDoc
   */
  public destroy(): void {}
}
