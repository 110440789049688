<div class="toolbar">

    <button (click)="openFilters()" class="secondary has-count" type="button">
        {{'Filtres' | ehp_translate }}
        <span *ngIf="filters && filters.length" class="count">{{filters.length}}</span>
    </button>

</div>

<ng-container *ngIf="rows && rows.length">
    <p-table
      [columns]="columns"
      [rowHover]="true"
      [scrollable]="true"
      [tableStyle]="{ 'min-width': '50rem', 'min-height':'100%' }"
      [value]="rows"
      [virtualScrollItemSize]="displayableRows"
      [virtualScroll]="true"
      class="p-datatable-striped" scrollHeight="flex">

        <ng-template let-columns pTemplate="header">
            <tr>

                <ng-container *ngFor="let column of columns">
                    <th *ngIf="column.property === 'name'" [ngClass]="'property-' + column.property" pFrozenColumn>
                        {{ column.label }}
                    </th>

                    <th *ngIf="column.property !== 'name'" [ngClass]="'property-' + column.property">
                        {{ column.label }}
                    </th>
                </ng-container>

            </tr>
        </ng-template>
        <ng-template let-columns="columns" let-row pTemplate="body">

            <tr [ngClass]="{'empty': row.id === 0}">

                <ng-container *ngFor="let column of columns">

                    <td *ngIf="column.property === 'name'" [ngClass]="'property-' + column.property" pFrozenColumn
                        style="min-width: 20rem">
                        <ng-container [ngTemplateOutletContext]="{column, row}"
                                      [ngTemplateOutlet]="rowContentTpl"></ng-container>
                    </td>

                    <td *ngIf="column.property !== 'name'" [ngClass]="'property-' + column.property"
                        style="min-width: 10rem">
                        <ng-container [ngTemplateOutletContext]="{column, row}"
                                      [ngTemplateOutlet]="rowContentTpl"></ng-container>
                    </td>

                </ng-container>

            </tr>
        </ng-template>
    </p-table>

    <ng-template #rowContentTpl let-column="column" let-row="row">
        <ng-container [ngSwitch]="column.property">
            <ng-container
              *ngSwitchCase="'name'"
              [ngTemplateOutletContext]="{row: row}"
              [ngTemplateOutlet]="nameTpl"
            ></ng-container>

            <ng-container *ngSwitchCase="'permanentCapacity'">
            <span *ngIf="isNumber(row.permanentCapacity); else noValueTpl">
              {{ row.permanentCapacity }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'temporaryCapacity'">
            <span *ngIf="isNumber(row.temporaryCapacity); else noValueTpl">
              {{ row.temporaryCapacity }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'daycareCapacity'">
            <span *ngIf="isNumber(row.daycareCapacity); else noValueTpl">
              {{ row.daycareCapacity }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'occupationRate'">
            <span *ngIf="isNumber(row.occupationRate); else noValueTpl">
              {{ row.occupationRate }}%
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'gmpValue'">
            <span *ngIf="isNumber(row.gmpValue); else noValueTpl">
              {{ row.gmpValue }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'pmpValue'">
            <span *ngIf="isNumber(row.pmpValue); else noValueTpl">
              {{ row.pmpValue }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'cpomOption'">
                <ehp-translatable-string
                  *ngIf="row.cpomOption?.label; else noValueTpl"
                  [value]="row.cpomOption?.label"
                ></ehp-translatable-string>
            </ng-container>

            <ng-container *ngSwitchCase="'cpomEndDate'">

          <span *ngIf="row.cpomEndDate; else noValueTpl">
              {{ row.cpomEndDate | date }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'healthFundingAmount'">
            <span *ngIf="isNumber(row.healthFundingAmount); else noValueTpl">
              {{ row.healthFundingAmount | currency }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'dependencyFundingAmount'">
            <span *ngIf="isNumber(row.dependencyFundingAmount); else noValueTpl">
              {{ row.dependencyFundingAmount | currency }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'accommodationFundingAmount'">
            <span *ngIf="isNumber(row.accommodationFundingAmount); else noValueTpl">
              {{ row.accommodationFundingAmount | currency }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'eprdAmount'">
            <span *ngIf="isNumber(row.eprdAmount); else noValueTpl"
                  [ngClass]="{'negative': isNegative(row.eprdAmount)}">
              {{ row.eprdAmount | currency }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'errdAmount'">
            <span *ngIf="isNumber(row.errdAmount); else noValueTpl"
                  [ngClass]="{'negative': isNegative(row.errdAmount)}">
              {{ row.errdAmount | currency }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'eprdCAFCapacity'">
            <span *ngIf="isNumber(row.eprdCAFCapacity); else noValueTpl"
                  [ngClass]="{'negative': isNegative(row.eprdCAFCapacity)}">
              {{ row.eprdCAFCapacity | currency }}
            </span>
            </ng-container>

            <ng-container *ngSwitchCase="'errdCAFCapacity'">
            <span *ngIf="isNumber(row.errdCAFCapacity); else noValueTpl"
                  [ngClass]="{'negative': isNegative(row.errdCAFCapacity)}">
              {{ row.errdCAFCapacity | currency }}
            </span>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <span *ngIf="row[column.property]" [innerHTML]="row[column.property]"></span>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #noValueTpl>
        <span class="no-value">-</span>
    </ng-template>

    <ng-template #nameTpl let-row="row">
        <strong class="name">{{row.name}}</strong>

        <span *ngIf="row.authorizedCapacity" class="authorized-capacity">
    <span class="label">{{'Autorisation de capacité'| ehp_translate}} :</span>

    <ehp-plural [count]="row.authorizedCapacity">
      <span class="value" plural>{{row.authorizedCapacity}} {{'lits' | ehp_translate }}</span>
      <span class="value" single>{{row.authorizedCapacity}} {{'lit' | ehp_translate }}</span>
    </ehp-plural>

  </span>
    </ng-template>

</ng-container>

<p-sidebar [(visible)]="sidebarVisible" position="right" styleClass="filter-sidebar">

    <ng-template pTemplate="header">
        {{ 'Filtres' | ehp_translate }}
    </ng-template>

    <ng-template pTemplate="content">

        <ehp-advanced-filters-form
          (validityChanges)="updateFiltersValidity($event)"
          (valueChanges)="filtersChange($event)"
          [definitions]="filterDefinitions"
          [initialValues]="filters">
        </ehp-advanced-filters-form>

    </ng-template>

    <ng-template pTemplate="footer">

        <button (click)="applyFilters()" [disabled]="!filtersValidity" class="primary apply" type="button">
            {{'Appliquer les filtres' | ehp_translate}}
        </button>

    </ng-template>
</p-sidebar>
