import {CustomerCacheKey} from "@application/bundles/customer/customer-cache-key";
import {CUSTOMER_CACHE_ID} from "@application/bundles/customer/customer.token";
import {GetCustomerQuery} from "@application/bundles/customer/queries/get-customer.query";
import {CacheManager} from "@application/framework/cache";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {Customer} from "@domain/customer";
import {CustomerRepository} from "@application/bundles/customer";

@HandleQuery({
  query: GetCustomerQuery
})
export class GetCustomerQueryHandler implements QueryHandler<GetCustomerQuery, Customer> {

  public constructor(
    private readonly repository: CustomerRepository,
    private readonly cacheManager: CacheManager
  ) {
  }

  public async handle(query: GetCustomerQuery): Promise<Customer> {
    const cache = await this.cacheManager.get<Customer>(CUSTOMER_CACHE_ID);
    return await cache.wrap(new CustomerCacheKey(query.id), () => this.repository.get(query.id));
  }

}
