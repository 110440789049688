import {Query} from "@application/framework/command-query";
import {Year} from "@domain/lib";
import {Facility} from "@domain/facility";

export class GetGMPForYearQuery implements Query<boolean> {

  constructor(public readonly facilityId: Facility['id'], public readonly year: Year) {
  }

}
