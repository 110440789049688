import { UpdateCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto';
import { Command } from '@application/framework/command-query';
import { CapacityAuthorization } from '@domain/capacity-authorization';

export class UpdateCapacityAuthorizationCommand implements Command<CapacityAuthorization> {
  public constructor(
    public id: CapacityAuthorization['id'],
    public readonly dto: UpdateCapacityAuthorizationDto,
  ) {}
}
