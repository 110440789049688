import { Transformer } from '@application/framework/core';
import {
  EasyCreateEhpadDemainDto,
  EasyEhpadDemain,
  EasyUpdateEhpadDemainDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/ehpad-demain/easy-ehpad-demain.interface';
import { EhpadDemain } from '@domain/ehpad-demain';
import { Service } from '@application/framework/di';
import { EhpadDemainImpl } from '@implementations/bundles/ehpad-demain';
import { DateParser } from '@application/framework/date/date.parser';
import { Common } from '@application/framework/lib';
import { CreateEhpadDemainDto, UpdateEhpadDemainDto } from '@application/bundles/ehpad-demain';

type EhpadDemainBaseProperties = Omit<
  EhpadDemain,
  'medias' | 'type' | 'hasConvention' | 'convention' | 'otherAuthorityConvention' | 'otherConvention' | 'facilities'
>;

type EhpadDemainCommonProperties = Common<
  EhpadDemainBaseProperties,
  Common<CreateEhpadDemainDto, UpdateEhpadDemainDto>
>;

type EasyEhpadDemainCommonProperties = Omit<
  Common<EasyEhpadDemain, Common<EasyCreateEhpadDemainDto, EasyUpdateEhpadDemainDto>>,
  'created_at' | 'updated_at' | 'media_ids'
>;

@Service()
export class EasyEhpadDemainTransformer implements Transformer<EasyEhpadDemain, EhpadDemain> {
  constructor(private readonly dateParser: DateParser) {}

  public transform(value: EasyEhpadDemain): EhpadDemain {
    const values: EhpadDemainBaseProperties = {
      ...this.commonEhpadDemainProperties(value),
      id: value.id,

      conventionId: value.convention_id || undefined,
      otherConventionId: value.convention_others_id || undefined,

      mediasIds: Array.isArray(value.media_ids) ? value.media_ids : [],

      updatedAt: typeof value.updated_at === 'string' ? this.dateParser.fromISOString(value.updated_at) : undefined,
      createdAt: typeof value.created_at === 'string' ? this.dateParser.fromISOString(value.created_at) : undefined,
    };

    return new EhpadDemainImpl(values);
  }

  public reverseTransform(value: EhpadDemain): EasyEhpadDemain {
    return {
      ...this.easyCommonEhpadDemainProperties(value),
      id: value.id,
      media_ids: value.mediasIds,
      created_at: value.createdAt ? value.createdAt.toDateString() : undefined,
      updated_at: value.updatedAt ? value.updatedAt.toDateString() : undefined,
    };
  }

  public reverseCreateTransform(value: CreateEhpadDemainDto): EasyCreateEhpadDemainDto {
    return {
      ...this.easyCommonEhpadDemainProperties(value),
      media_ids: value.mediaIds || [],
    };
  }

  public reverseUpdateTransform(value: UpdateEhpadDemainDto): EasyUpdateEhpadDemainDto {
    return {
      ...this.easyCommonEhpadDemainProperties(value),
      id: value.id,
      media_ids: value.mediaIds || [],
    };
  }

  private commonEhpadDemainProperties(value: EasyEhpadDemainCommonProperties): EhpadDemainCommonProperties {
    return {
      name: value.name,
      comment: value.description || undefined,
      start: this.dateParser.fromISOString(value.start),
      end: this.dateParser.fromISOString(value.end),
      facilitiesIds: Array.isArray(value.facility_ids) ? value.facility_ids : [],
      conventionId: value.convention_id || undefined,
      otherConventionId: value.convention_others_id || undefined,
      amount: value.amount,
      typeId: value.type,
    };
  }

  private easyCommonEhpadDemainProperties(value: EhpadDemainCommonProperties): EasyEhpadDemainCommonProperties {
    return {
      name: value.name,
      description: value.comment,
      start: value.start.toDateString(),
      end: value.end.toDateString(),
      type: value.typeId,
      ars_convention: !!value.conventionId,
      convention_id: value.conventionId,
      convention_others_id: value.otherConventionId,
      amount: value.amount,
      facility_ids: value.facilitiesIds,
    };
  }
}
