import { Component, HostBinding, Input } from '@angular/core';
import { FACILITY_STATE } from '@domain/facility';

@Component({
  selector: 'ehp-facility-state',
  templateUrl: './facility-state.component.html',
  styleUrls: ['./facility-state.component.scss'],
})
export class FacilityStateComponent {
  @Input() public state!: FACILITY_STATE;

  public states = FACILITY_STATE;

  @HostBinding('class')
  public get classes(): string {
    return this.state ? `state-${this.state}` : '';
  }
}
