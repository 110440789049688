import {NgModule} from '@angular/core';
import {ValidatorModule} from "@easyhpad-ui/app/framework/validator/validator.module";
import {
  ActivityAnnexDtoFactoryImpl,
  CreateActivityAnnexDtoImpl,
  UpdateActivityAnnexDtoImpl
} from "@implementations/bundles/activity-annex";
import {
  ActivityAnnexBundle,
  ActivityAnnexDtoFactory,
  ActivityAnnexRepository
} from "@application/bundles/activity-annex";
import {Bundle} from "@application/framework/bundle";
import {
  ActivityAnnexUrlsProvider
} from "@easyhpad-ui/app/bundles/activity-annex/providers/url-provider/activity-annex-urls.provider";
import {OpenActivityAnnexDeletionDialogCommandHandler} from "@easyhpad-ui/app/bundles/activity-annex/commands/handlers";
import {validateClassValidatorObject} from "@implementations/framework/validator";
import {
  EasyActivityAnnexRepository
} from "@implementations/bundles/backend/easyhpad/repositories/activity-annex/activity-annex.repository";


@NgModule({
  imports: [
    ValidatorModule.forChild({
      validators: [
        {objectType: CreateActivityAnnexDtoImpl, validator: validateClassValidatorObject},
        {objectType: UpdateActivityAnnexDtoImpl, validator: validateClassValidatorObject},
      ]
    })
  ],
  providers: [
    ActivityAnnexUrlsProvider,
    {
      provide: ActivityAnnexDtoFactory,
      useClass: ActivityAnnexDtoFactoryImpl,
    },
    {
      provide: ActivityAnnexRepository,
      useClass: EasyActivityAnnexRepository
    }
  ]
})
@Bundle({
  imports: [ActivityAnnexBundle],
  commandHandlers: [
    OpenActivityAnnexDeletionDialogCommandHandler
  ]
})
export class ActivityAnnexModule {
}
