import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CUSTOMER_TRANSLATE_CONTEXT } from '@application/bundles/customer/customer.token';
import { GetCustomerQuery } from '@application/bundles/customer/queries/get-customer.query';
import { QueryBus } from '@application/framework/command-query';
import { Translator } from '@application/framework/translation';
import { Customer } from '@domain/customer';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { Observable, Subscription } from 'rxjs';
import { isMaybeACustomer, isValidCustomerIdType } from '@application/bundles/customer';

const selector = 'ehp-customer-select';

@Component({
  selector: selector,
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSelectComponent),
      multi: true,
    },
  ],
})
export class CustomerSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input() public customers$: Observable<Customer[]> | undefined;

  @ViewChild('autocomplete') public autocomplete: AutocompleteComponent | undefined;

  public isLoading = true;

  public disabled: boolean = false;

  public customers: Customer[] = [];

  public data: Customer[] = [];

  public placeholder = '';

  public keyword = 'name';

  private subscription: Subscription | undefined;

  public get nodeName(): string {
    return selector;
  }

  public get value() {
    return this.autocomplete?.data;
  }

  public set value(value: any) {
    this.writeValue(value);
  }

  constructor(
    private readonly translator: Translator,
    private queryBus: QueryBus,
  ) {
    this.translator
      .translate('Sélectionner un client', undefined, CUSTOMER_TRANSLATE_CONTEXT)
      .then(translation => (this.placeholder = translation));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['customers$']) {
      this.unsubscribe();

      if (changes['customers$'].currentValue instanceof Observable) {
        this.customers$?.subscribe((customers: Customer[]) => (this.customers = customers));
      }
    }
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

  public onChangeSearch(search: string) {
    //this.data = this.customers;
  }

  public selected(customer: Customer | undefined) {
    this.onChange(customer);
  }

  public cleared($event: any) {
    this.onChange(undefined);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: Customer | Customer['id'] | null | undefined): void {
    if (!value) {
      this.deselectCustomer();
      return;
    }

    let id: Customer['id'] | undefined = undefined;

    if (isValidCustomerIdType(value)) {
      id = value;
    } else if (isMaybeACustomer(value)) {
      id = value.id;
    }

    if (id !== undefined) {
      if (!this.customers.find(customer => customer.id === id)) {
        this.queryBus.request(new GetCustomerQuery(id)).then(customer => {
          this.pushCustomer(customer);
          this.selectCustomer(customer.id);
        });
      }

      this.selectCustomer(id);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChange = (value: Customer | undefined): void => undefined;

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private pushCustomer(customer: Customer): void {
    if (!this.customers.find(existant => existant.id === customer.id)) {
      this.customers.push(customer);
    }
  }

  private selectCustomer(id: Customer['id']): void {
    const customer = this.customers.find(existant => existant.id === id);

    if (customer) {
      this.autocomplete?.writeValue(customer);
    }
  }

  private deselectCustomer(): void {
    this.autocomplete?.writeValue(undefined);
  }
}
