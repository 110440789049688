import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RouteSailor } from '@easyhpad-ui/app/framework/routing';
import { Observable, tap } from 'rxjs';
import { AuthentificationUrlsProvider } from '@easyhpad-ui/app/bundles/authentification';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { selectIsAuthenticated } from '@easyhpad-ui/app/bundles/authentification/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  public constructor(
    private readonly routeSailor: RouteSailor,
    private readonly store: Store<AppState>,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store
      .select(selectIsAuthenticated)
      .pipe(tap(isAuthenticated => (isAuthenticated ? null : this.redirectToLogin(state))));
  }

  public async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    return true;
  }

  public async redirectToLogin(state: RouterStateSnapshot) {
    return this.routeSailor.router.navigate([AuthentificationUrlsProvider.publicSchema.login.path], {
      skipLocationChange: true,
      queryParams: { [RouteSailor.URL_PARAMS]: state.url },
    });
  }
}
