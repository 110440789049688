import {
  SerializedCapacityAuthorizationSearchParams
} from "@application/bundles/capacity-authorization/list-capacity-authorization-params";
import {Query} from "@application/framework/command-query";
import {CapacityAuthorization} from "@domain/capacity-authorization";

export class ListCapacityAuthorizationsQuery implements Query<CapacityAuthorization[]> {

  public constructor(public params?: SerializedCapacityAuthorizationSearchParams) {
  }
}
