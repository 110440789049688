import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {ActivityAnnex} from "@domain/activity-annex";

export class ActivityAnnexUpdatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly activityAnnex: ActivityAnnex) {
    super();
  }
}
