import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class StepperComponent extends CdkStepper implements OnInit, AfterViewInit, OnDestroy {
  @Input() public readonly activeClass = 'active';

  @Output() public stepChange: EventEmitter<{ step: CdkStep; index: number; total: number }> = new EventEmitter();

  private destroy$ = new Subject<void>();

  public ngOnInit() {
    this.selectionChange.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.dispatchStepChange(changes.selectedIndex, changes.selectedStep);
    });
  }

  public override ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.selected) {
      this.dispatchStepChange(this.selectedIndex, this.selected);
    }
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next();
  }

  public selectStep(index: number) {
    // Prevent undefined step
    if (!this.steps.get(index) || index === this.selectedIndex) {
      return;
    }

    const previous = this.steps.get(index - 1);

    if (index < this.selectedIndex || (previous && previous.completed)) {
      this.selectedIndex = index;
    }
  }

  public isDisabled(index: number): boolean {
    const step = this.steps.get(index);

    if (!step) {
      return false;
    }
    if (!step.completed) {
      return true;
    }

    return this.isDisabled(index - 1);
  }

  private dispatchStepChange(index: number, step: CdkStep): void {
    this.stepChange.emit({
      index: index,
      total: this.steps.length,
      step: step,
    });
  }
}
