<div class="title-container">
  <h2 class="title">{{ 'G.M.P' | ehp_translate}}</h2>
  <span *ngIf="date" class="date">{{'Validé le' | ehp_translate}} {{ date }}</span>
</div>

<div class="content">

  <div class="indicator">
    <span class="value current-value">{{value | number:'1.0-2'}}</span>

    <div class="department-container">
      <span class="value">740</span>
      <span class="label">{{'National'|ehp_translate}}</span>
      <span class="year">2023</span>
    </div>
    <div *ngIf="indicator" class="department-container">
      <span class="value">{{indicator | number:'1.0-2'}}</span>

      <ehp-translatable-string *ngIf="indicatorLocation" [value]="indicatorLocation"
        class="label"></ehp-translatable-string>

      <span [innerHTML]="indicatorYear" class="year">&nbsp;</span>
    </div>
  </div>

  <p *ngIf="difference" class="difference">
    <span [ngClass]="isPositiveDifference ? 'up': 'down'" class="value">{{difference}} %</span>
    <ehp-translatable-string [value]="differenceMessage" class="label"></ehp-translatable-string>
  </p>
</div>

<ehp-average-message *ngIf="average" [total]="average.total" [value]="average.value"
  class="average-message"></ehp-average-message>