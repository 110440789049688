import { Component, Inject, OnDestroy } from '@angular/core';
import { CAPACITY_AUTHORIZATION_FEATURE } from '@application/bundles/capacity-authorization';
import { CPOM_FEATURE } from '@application/bundles/cpom';
import { CUSTOMER_FEATURE } from '@application/bundles/customer/customer.token';
import { FACILITY_FEATURE } from '@application/bundles/facility';
import { GMP_FEATURE } from '@application/bundles/gmp/gmp.token';
import { PMP_FEATURE } from '@application/bundles/pmp';
import { USER_FEATURE } from '@application/bundles/user/user.token';
import { User } from '@domain/user';
import { CPOMUrlsProvider } from '@easyhpad-ui/app/bundles/cpom';
import { GmpUrlsProvider } from '@easyhpad-ui/app/bundles/gmp';
import { PMPUrlsProvider } from '@easyhpad-ui/app/bundles/pmp';
import { ReactiveAccessor } from '@implementations/framework/accessor/reactive-accessor.interface';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { configuration } from '../../../environments/configuration';
import { CURRENT_USER_REACTIVE_ACCESSOR } from '../../bundles/user/user.tokens';
import { EHPAD_DEMAIN_FEATURE } from '@application/bundles/ehpad-demain';
import { EhpadDemainUrlsProvider } from '@easyhpad-ui/app/bundles/ehpad-demain/provider/urls-provider/urls-provider';
import { CapacityAuthorizationUrlsProvider } from '@easyhpad-ui/app/bundles/capacity-authorization/providers/capacity-authorization-urls/capacity-authorization-urls.provider';
import { HealthFundingUrlsProvider } from '@easyhpad-ui/app/bundles/health-funding/providers/url-provider/health-funding-urls.provider';
import { HEALTH_FUNDING_FEATURE } from '@application/bundles/health-funding';
import { DEPENDENCY_FUNDING_FEATURE } from '@application/bundles/dependency-funding';
import { DependencyFundingUrlsProvider } from '@easyhpad-ui/app/bundles/dependency-funding/providers/url-provider/dependency-funding-urls.provider';
import { EPRD_FEATURE, ERRD_FEATURE } from '@application/bundles/eprd-errd';
import { EPRDUrlsProvider } from '@easyhpad-ui/app/bundles/eprd-errd/providers/eprd-urls-provider/eprd-urls-provider';
import { ERRDUrlsProvider } from '@easyhpad-ui/app/bundles/eprd-errd/providers/errd-urls-provider/errd-urls-provider';
import { AppState } from '@easyhpad-ui/app/store';
import { Store } from '@ngrx/store';
import { CalculatorActions } from '@easyhpad-ui/app/bundles/calculator/store';
import { ACTIVITY_ANNEX_FEATURE } from '@application/bundles/activity-annex';
import { ActivityAnnexUrlsProvider } from '@easyhpad-ui/app/bundles/activity-annex/providers/url-provider/activity-annex-urls.provider';
import { ACCOMMODATION_FUNDING_FEATURE } from '@application/bundles/accommodation-funding';
import { AccommodationFundingUrlsProvider } from '@easyhpad-ui/app/bundles/accommodation-funding/providers';
import { UserUrlsProvider } from '@easyhpad-ui/app/bundles/user/providers';
import { FacilityUrls } from '@easyhpad-ui/app/bundles/facility/services/facility-urls/facility-urls';
import { CustomerUrlsProvider } from '@easyhpad-ui/app/bundles/customer';
import { SupportUrlsProvider } from '@easyhpad-ui/app/bundles/support/providers';
import { BenchmarkingUrlProvider } from '@easyhpad-ui/app/bundles/benchmarking/providers';
import { SimulationUrlProvider } from '@easyhpad-ui/app/bundles/simulation/providers';
import { FILE_DEPOSIT_FEATURE } from '@application/bundles/file-deposit/tokens';
import { FileDepositUrlProvider } from '@easyhpad-ui/app/bundles/file-deposit/providers/file-deposit-url/file-deposit-url.provider';
import { LibraryUrlsProvider } from '@easyhpad-ui/app/bundles/library/providers';
import { selectIsFacilityOnboarding } from '@easyhpad-ui/app/store/global.selector';
import { DemoUrlsProvider } from '@easyhpad-ui/app/bundles/demo/modules/providers';
import { DEMO_FEATURE } from '@application/bundles/demo';
import { BalanceSheetUrlsProvider } from '@easyhpad-ui/app/bundles/balance-sheet/providers';
import { BALANCE_SHEET_FEATURE } from '@application/bundles/balance-sheet';
import { selectCurrentAccount } from '@easyhpad-ui/app/bundles/authentification/store';

@Component({
  selector: 'ehp-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnDestroy {
  public customerSelectorEnabled = false;

  public user$: Observable<User | undefined>;

  public isOnboarded$: Observable<boolean>;

  public logoutPath: string;

  public readonly FEATURE = {
    customer: CUSTOMER_FEATURE,
    user: USER_FEATURE,
    facility: FACILITY_FEATURE,
    capacity_authorization: CAPACITY_AUTHORIZATION_FEATURE,
    gmp: GMP_FEATURE,
    pmp: PMP_FEATURE,
    cpom: CPOM_FEATURE,
    ehpad_demain: EHPAD_DEMAIN_FEATURE,
    health_funding: HEALTH_FUNDING_FEATURE,
    dependency_funding: DEPENDENCY_FUNDING_FEATURE,
    accommodation_funding: ACCOMMODATION_FUNDING_FEATURE,
    eprd: EPRD_FEATURE,
    errd: ERRD_FEATURE,
    activity_annex: ACTIVITY_ANNEX_FEATURE,
    file_deposit: FILE_DEPOSIT_FEATURE,
    balance_sheet: BALANCE_SHEET_FEATURE,
    demo: DEMO_FEATURE,
  };

  public readonly URLS = {
    capacity_authorization: '',
    gmp: '',
    pmp: '',
    cpom: '',
    ehpad_demain: '',
    health_funding: '',
    dependency_funding: '',
    accommodation_funding: '',
    eprd: '',
    errd: '',
    activity_annex: '',
    user_profile: '',
    facility_admin: '',
    customer_admin: '',
    user_admin: '',
    support: '',
    benchmarking: '',
    simulation: '',
    file_deposit: '',
    balance_sheet: '',
    library: '',
    demo: '',
  };

  private readonly access: Map<string | Symbol, boolean> = new Map<string | symbol, boolean>();

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(CURRENT_USER_REACTIVE_ACCESSOR) private userAccessor: ReactiveAccessor<User>,
    private readonly authorizationUrls: CapacityAuthorizationUrlsProvider,
    private readonly gmpUrls: GmpUrlsProvider,
    private readonly pmpUrls: PMPUrlsProvider,
    private readonly cpomUrls: CPOMUrlsProvider,
    private readonly ehpadDemainUrls: EhpadDemainUrlsProvider,
    private readonly healthFundingUrls: HealthFundingUrlsProvider,
    private readonly dependencyFundingUrls: DependencyFundingUrlsProvider,
    private readonly accommodationFundingUrls: AccommodationFundingUrlsProvider,
    private readonly eprdUrls: EPRDUrlsProvider,
    private readonly errdUrls: ERRDUrlsProvider,
    private readonly activityAnnexUrls: ActivityAnnexUrlsProvider,
    private readonly userUrls: UserUrlsProvider,
    private readonly facilityUrls: FacilityUrls,
    private readonly customerUrls: CustomerUrlsProvider,
    private readonly supportUrls: SupportUrlsProvider,
    private readonly benchmarkingUrls: BenchmarkingUrlProvider,
    private readonly simulationUrls: SimulationUrlProvider,
    private readonly fileDepositUrls: FileDepositUrlProvider,
    private readonly balanceSheetUrls: BalanceSheetUrlsProvider,
    private readonly libraryUrlProvider: LibraryUrlsProvider,
    private readonly demoUrlsProvider: DemoUrlsProvider,
    private readonly store: Store<AppState>,
  ) {
    this.logoutPath = configuration.logoutPath;

    this.user$ = this.userAccessor.get();

    this.isOnboarded$ = this.store.select(selectIsFacilityOnboarding).pipe(
      takeUntil(this.destroy$),
      map(v => !v),
    );

    this.store.select(selectCurrentAccount).subscribe(account => {
      if (account && account.isAuthenticated && !account.customerReference) {
        this.customerSelectorEnabled = true;
      }
    });

    this.setUrls();
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public canAccess(feature: string | Symbol): boolean {
    if (feature === 'administration') {
      return this.canAccessToFeature(feature);
    }

    const access = this.access.get(feature);

    if (access !== undefined) {
      return access;
    }
    return true;
  }

  public openCalculator() {
    this.store.dispatch(CalculatorActions.new({ props: { initialPosition: { x: 'right', y: 'bottom' } } }));
  }

  private canAccessToFeature(feature: string): boolean {
    if (feature === 'administration') {
      const search = [this.FEATURE.user, this.FEATURE.customer, this.FEATURE.facility];
      return search.map(f => this.access.get(f)).filter(f => f !== false).length !== 0;
    }

    return true;
  }

  private setUrls(): void {
    this.URLS.user_profile = this.userUrls.getUserProfileUrl();

    this.facilityUrls.getAdminList().then(url => {
      this.URLS.facility_admin = url ?? '';
      this.access.set(this.FEATURE.facility, !!url);
    });

    this.customerUrls.getAdminList().then(url => {
      this.URLS.customer_admin = url ?? '';
      this.access.set(this.FEATURE.customer, !!url);
    });

    this.userUrls.getAdminList().then(url => {
      this.URLS.user_admin = url ?? '';
      this.access.set(this.FEATURE.user, !!url);
    });

    this.authorizationUrls.getPublicList().then(url => {
      this.URLS.capacity_authorization = url ?? '';
      this.access.set(this.FEATURE.capacity_authorization, !!url);
    });

    this.gmpUrls.getPublicList().then(url => {
      this.URLS.gmp = url ?? '';
      this.access.set(this.FEATURE.gmp, !!url);
    });

    this.pmpUrls.getPublicList().then(url => {
      this.URLS.pmp = url ?? '';
      this.access.set(this.FEATURE.pmp, !!url);
    });

    this.cpomUrls.getPublicList().then(url => {
      this.URLS.cpom = url ?? '';
      this.access.set(this.FEATURE.cpom, !!url);
    });

    this.ehpadDemainUrls.getPublicList().then(url => {
      this.URLS.ehpad_demain = url ?? '';
      this.access.set(this.FEATURE.ehpad_demain, !!url);
    });

    this.healthFundingUrls.getPublicList().then(url => {
      this.URLS.health_funding = url ?? '';
      this.access.set(this.FEATURE.health_funding, !!url);
    });

    this.dependencyFundingUrls.getPublicList().then(url => {
      this.URLS.dependency_funding = url ?? '';
      this.access.set(this.FEATURE.dependency_funding, !!url);
    });

    this.accommodationFundingUrls.getPublicList().then(url => {
      this.URLS.accommodation_funding = url ?? '';
      this.access.set(this.FEATURE.accommodation_funding, !!url);
    });

    this.eprdUrls.getPublicList().then(url => {
      this.URLS.eprd = url ?? '';
      this.access.set(this.FEATURE.eprd, !!url);
    });

    this.errdUrls.getPublicList().then(url => {
      this.URLS.errd = url ?? '';
      this.access.set(this.FEATURE.errd, !!url);
    });

    this.activityAnnexUrls.getPublicList().then(url => {
      this.URLS.activity_annex = url ?? '';
      this.access.set(this.FEATURE.activity_annex, !!url);
    });

    this.supportUrls.getPublicFAQ().then(url => {
      this.URLS.support = url ?? '';
    });

    this.benchmarkingUrls.getPublicBenchmarkingUrl().then(url => {
      this.URLS.benchmarking = url ?? '';
    });

    this.simulationUrls.getPublicSimulationDashboardUrl().then(url => {
      this.URLS.simulation = url ?? '';
    });

    this.fileDepositUrls.getAdminList().then(url => {
      this.URLS.file_deposit = url ?? '';
      this.access.set(this.FEATURE.file_deposit, !!url);
    });

    this.balanceSheetUrls.getPublicList().then(url => {
      this.URLS.balance_sheet = url ?? '';
      this.access.set(this.FEATURE.file_deposit, !!url);
    });

    this.libraryUrlProvider.getPublicList().then(url => {
      this.URLS.library = url ?? '';
      this.access.set(this.FEATURE.balance_sheet, !!url);
    });

    this.demoUrlsProvider.getAdminPage().then(url => {
      this.URLS.demo = url ?? '';
      this.access.set(this.FEATURE.demo, !!url);
    });
  }
}
