import { RequestParams, Response } from '@application/framework/http/request.interface';
import { Provide } from '@application/framework/di';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { EasyRepositoryTransformer } from './easy-repository-transform';
import { EasyDocumentQueryParamsTransformer } from '@implementations/bundles/backend/easyhpad/transformers/document/easy-document-query-params.transformer';
import { parseBackendResponse } from '@implementations/bundles/backend/functions';

export abstract class EasyRepositoryAbstract<Entity = any, EasyEntity = Entity, P = any> {
  @Provide() protected readonly backend!: EasyBackendApi;

  @Provide() protected readonly searchTransformer!: EasyDocumentQueryParamsTransformer;

  constructor(
    protected readonly basePath: string,
    protected transformer?: EasyRepositoryTransformer<Entity, EasyEntity>,
  ) {}

  protected convertParams(params?: P): RequestParams {
    return {};
  }

  protected parseResponse<Body = unknown>(response: Response<Body>): Body {
    return parseBackendResponse(response);
  }
}
