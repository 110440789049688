import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import {
  GMPCreatedEvent,
  GMPCreationFailEvent,
  GMPDeletedEvent,
  GMPDeletionFailEvent,
  GMPUpdatedEvent,
  GMPUpdateFailEvent,
} from '@application/bundles/gmp';
import { GMP_TRANSLATE_CONTEXT } from '@application/bundles/gmp/gmp.token';
import { QueryBus } from '@application/framework/command-query';
import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';
import { Facility } from '@domain/facility';

@HasEventSubscribers()
export class OnGmpEventsSubscriber {
  constructor(
    private readonly noticeStream: NoticeStream,
    private readonly queryBus: QueryBus,
  ) {}

  @OnEvent({
    event: GMPCreatedEvent,
  })
  private async pushNoticeOnGMPCreationSuccess(event: GMPCreatedEvent): Promise<void> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(event.gmp.facilityId));

    const message = new TranslatableString(
      "Le <strong>G.M.P. {{ year }}</strong> pour l'établissement <strong>{{ name }}</strong> a été créé avec succès.",
      { year: event.gmp.year, name: facility.name },
      GMP_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: GMPCreationFailEvent,
  })
  private pushNoticeOnGMPCreationFail(event: GMPCreationFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la création du nouveau G.M.P..',
        undefined,
        GMP_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }

  @OnEvent({
    event: GMPUpdatedEvent,
  })
  private async pushNoticeOnGMPUpdateSuccess(event: GMPUpdatedEvent): Promise<void> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(event.gmp.facilityId));

    const message = new TranslatableString(
      "Le <strong>G.M.P. {{ year }}</strong> pour l'établissement <strong>{{ name }}</strong> a été mis à jour avec succès.",
      { year: event.gmp.year, name: facility.name },
      GMP_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: GMPUpdateFailEvent,
  })
  private pushNoticeOnGMPUpdateFail(event: GMPUpdateFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la modification du G.M.P..',
        undefined,
        GMP_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }

  @OnEvent({
    event: GMPDeletedEvent,
  })
  private async pushNoticeOnGMPDeletionSuccess(event: GMPDeletedEvent): Promise<void> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(event.gmp.facilityId));

    const message = new TranslatableString(
      "Le <strong>G.M.P. {{ year }}</strong> pour l'établissement <strong>{{ name }}</strong> a été supprimé avec succès.",
      { year: event.gmp.year, name: facility.name },
      GMP_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: GMPDeletionFailEvent,
  })
  private pushNoticeOnGMPDeletionFail(event: GMPDeletionFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la suppression du G.M.P..',
        undefined,
        GMP_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }
}
