import { isValidObject } from '@application/framework/lib';
import { Media } from '@domain/media';
import { IsDate, IsOptional } from 'class-validator';
import { CommonAbstractDtoType } from '@implementations/bundles/eprd-errd';
import { IsMediaId } from '@implementations/bundles/media';
import { isValidMediaIdType } from '@application/bundles/media';
import { IsValidYear } from '@implementations/framework/date';
import { isValidYear, Year } from '@domain/lib';

export abstract class CommonAbstractDto implements CommonAbstractDtoType {
  @IsValidYear()
  public accountingYear!: Year;

  @IsDate()
  @IsOptional()
  public depositDate: Date | undefined;

  @IsMediaId()
  @IsOptional()
  public completeDocumentId!: Media['id'] | undefined;

  @IsMediaId()
  @IsOptional()
  public payrollDocumentId!: Media['id'] | undefined;

  @IsMediaId()
  @IsOptional()
  public financialAnnexDocumentId!: Media['id'] | undefined;

  @IsMediaId()
  @IsOptional()
  public activityAnnexDocumentId: Media['id'] | undefined;

  @IsMediaId()
  @IsOptional()
  public reportId: Media['id'] | undefined;

  constructor(defaults?: Partial<CommonAbstractDtoType>) {
    if (isValidObject(defaults)) {
      if (isValidYear(defaults?.accountingYear)) {
        this.accountingYear = defaults?.accountingYear;
      }

      if (defaults.depositDate instanceof Date) {
        this.depositDate = defaults.depositDate;
      }

      const keys: Array<keyof Omit<CommonAbstractDtoType, 'accountingYear' | 'depositDate' | 'details'>> = [
        'completeDocumentId',
        'payrollDocumentId',
        'financialAnnexDocumentId',
        'activityAnnexDocumentId',
        'reportId',
      ];

      keys.forEach(key => {
        const id = defaults[key];

        if (isValidMediaIdType(defaults[key])) {
          this[key] = defaults[key];
        }
      });
    }
  }
}
