import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ehp-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': 'classes',
    '[class.is-empty]': 'isEmpty'
  }
})
export class BadgeComponent {

  @Input() public value: string | number | undefined;

  @Input() public mode: 'warning' | 'error' | undefined;

  public get isEmpty() {
    return (typeof this.value !== 'string' && typeof this.value !== 'number')
      || this.value === ''
      || Number.isNaN(this.value)
      || !Number.isFinite(this.value);
  }

  public get classes() {
    return this.mode ? this.mode : 'default';
  }
}
