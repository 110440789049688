import {AccommodationFundingRepository} from "@application/bundles/accommodation-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {AccommodationFunding} from "@domain/accommodation-funding";
import {ListAccommodationFundingQuery} from "../../list-accommodation-funding.query";
import {ListAccommodationFundingParamsImpl} from "@application/bundles/accommodation-funding/implementations";

@HandleQuery({
  query: ListAccommodationFundingQuery
})
export class ListAccommodationFundingQueryHandler implements QueryHandler<ListAccommodationFundingQuery, AccommodationFunding[]> {

  constructor(private readonly repository: AccommodationFundingRepository) {
  }

  public async handle(query: ListAccommodationFundingQuery): Promise<AccommodationFunding[]> {
    return this.repository.list(new ListAccommodationFundingParamsImpl().deserialize(query.params));
  }
}
