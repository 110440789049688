<ng-container *ngIf="facilities">

  <ng-container [ngSwitch]="mode">

    <p-listbox
      (onChange)="selectionChange($event)"
      *ngSwitchCase="'list'"
      [(ngModel)]="facilities"
      [checkbox]="true"
      [disabled]="disabled"
      [filter]="true"
      [metaKeySelection]="false"
      [multiple]="true"
      [optionLabel]="optionLabel"
      [options]="facilities"
      emptyFilterMessage="{{'Aucun établissement trouvé.' | ehp_translate }}"
      emptyMessage="{{'Aucun établissement trouvé.' | ehp_translate}}"
      filterPlaceHolder="{{'Rechercher' | ehp_translate }}"
    ></p-listbox>

    <p-multiSelect
      (onChange)="selectionChange($event)"
      *ngSwitchDefault
      [(ngModel)]="selection"
      [disabled]="disabled"
      [optionLabel]="optionLabel"
      [options]="facilities"
      display="chip"
      optionValue="id"
    ></p-multiSelect>

  </ng-container>

</ng-container>
