import { UpdateRate } from '@domain/funding/entities/update-rate';
import { EasyEntityRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { DateParser } from '@application/framework/date';
import { EasyUpdateRate } from '@implementations/bundles/backend/easyhpad/interfaces/easy-update-rate.interface';
import { SourcedData } from '@application/bundles/sourced-data';
import { FundingUpdateRateRepository } from '@application/bundles/funding/repositories/funding-update-rate.repository';

export abstract class EasyFundingUpdateRateAbstractRepository
  extends EasyEntityRepository
  implements FundingUpdateRateRepository
{
  protected constructor(
    basePath: string,
    private readonly dateParser: DateParser,
  ) {
    super(basePath);
  }

  public async find(): Promise<UpdateRate[]> {
    const response = await this.backend.get<{ entities: EasyUpdateRate[] }>(this.basePath);
    return this.parseResponse(response).entities.map(entity => this.transform(entity));
  }

  private transform(value: EasyUpdateRate): SourcedData<UpdateRate> {
    return {
      ...value,
      date: this.dateParser.fromISOString(value.date),
      latestUpdate: this.dateParser.fromISOString(value.latestUpdate),
    };
  }
}
