import { createReducer, on } from '@ngrx/store';
import { EntityAdapter } from '@ngrx/entity';
import { DocumentStoreState } from './document.state';
import { DocumentStoreActions } from './document.action';

export const createDocumentReducer = <E extends { id: ID }, ID = string | number>(
  adapter: EntityAdapter<E>,
  initialState: DocumentStoreState<E, ID>,
  actions: DocumentStoreActions<E, ID>,
) =>
  createReducer(
    initialState,

    on(actions.loadDocumentList, state => ({
      ...state,
      views: {
        ...state.views,
        list: {
          ...state.views.list,
          loading: true,
        },
      },
    })),
    on(actions.documentListLoaded, (state, { documents }) => ({
      ...state,
      views: {
        ...state.views,
        list: {
          ...state.views.list,
          ids: documents.map(d => d.id),
          count: documents.length,
          total: documents.length,
          loading: false,
        },
      },
      documents: {
        ...adapter.addMany(documents, state.documents),
      },
    })),
    on(actions.openDocument, state => ({
      ...state,
      views: {
        ...state.views,
        single: {
          ...state.views.single,
        },
      },
    })),
    on(actions.documentOpened, (state, { document }) => ({
      ...state,
      views: {
        ...state.views,
        single: {
          ...state.views.single,
          id: document.id,
        },
      },
      documents: {
        ...adapter.addOne(document, state.documents),
      },
    })),
    on(actions.documentUpdated, (state, { document }) => ({
      ...state,
      documents: {
        ...adapter.upsertOne(document, state.documents),
      },
    })),
  );
