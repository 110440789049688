import {RefreshCurrentUserCommand} from "@application/bundles/user/commands/refresh-current-user.command";
import {CommandBus} from "@application/framework/command-query";
import {HttpAuthorizationToken, HttpAuthorizationTokenStore} from "@application/framework/http";
import {User} from "@domain/user";
import {EasyBackendApi} from "@implementations/bundles/backend/easyhpad/api";
import {EASYHPAD_BEARER_AUTH_TOKEN} from "@implementations/bundles/backend/easyhpad/backend.tokens";
import {
  RefreshHttpAuthorizationTokenCommand
} from "@implementations/bundles/backend/easyhpad/commands/refresh-http-authorization-token.command";

export class EasyHpadAutoReconnectUseCase {

  constructor(
    private readonly backend: EasyBackendApi,
    private readonly tokenStore: HttpAuthorizationTokenStore,
    private readonly commandBus: CommandBus
  ) {
  }

  public async execute(): Promise<User> {

    const current = await this.tokenStore.get(EASYHPAD_BEARER_AUTH_TOKEN);

    await this.commandBus.execute(new RefreshHttpAuthorizationTokenCommand(current as HttpAuthorizationToken<any>));
    return this.commandBus.execute<User>(new RefreshCurrentUserCommand());
  }
}
