import {CPOMRepository} from "@application/bundles/cpom";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {CPOM} from "@domain/cpom";
import {ListCPOMQuery} from "../../list-cpom.query";
import {ListCPOMParamsImpl} from "@application/bundles/cpom/implementations";

@HandleQuery({
  query: ListCPOMQuery
})
export class ListCPOMQueryHandler implements QueryHandler<ListCPOMQuery, CPOM[]> {

  constructor(private readonly repository: CPOMRepository) {
  }

  public async handle(query: ListCPOMQuery): Promise<CPOM[]> {
    return this.repository.list(new ListCPOMParamsImpl().deserialize(query.params));
  }
}
