import {AsyncCollection, Collection, ListCollectionParams} from "@application/framework/store/collection";

export class SynchroneCollectionAsynchroneAdapter<T = any> implements AsyncCollection<T> {


  public constructor(private readonly collection: Collection<T>) {
  }

  public has(reference: any): Promise<boolean> {
    return Promise.resolve(this.collection.has(reference));
  }

  public list(params?: ListCollectionParams): Promise<T[]> {
    return Promise.resolve(this.collection.list(params));
  }

  public get(reference: any): Promise<T | undefined> {
    return Promise.resolve(this.collection.get(reference));
  }

  public set(reference: any, item: T | undefined): Promise<void> {
    this.collection.set(reference, item);
    return Promise.resolve();
  }

  public remove(reference: any): Promise<void> {
    this.collection.remove(reference);
    return Promise.resolve();
  }

  public async clear(): Promise<void> {
    return this.collection.clear();
  }

}
