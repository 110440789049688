import {TransformationFailedError, Transformer} from "@application/framework/core";
import {InitialTraining} from "@domain/user/initial-training.interface";
import {SelectOption} from "@implementations/forms/select-option.interface";

export class InitialTrainingSelectOptionTransformer implements Transformer<InitialTraining, SelectOption> {


  public transform(training: InitialTraining): SelectOption {
    if (!training) {
      throw new TransformationFailedError('Missing training');
    }

    if (!training.id) {
      throw new TransformationFailedError('Missing property "id" in InitialTraining');
    }

    if (!training.level) {
      throw new TransformationFailedError('Missing property "level" in InitialTraining');
    }

    return {value: training.id, label: training.level}
  }

  public reverseTransform(option: SelectOption<string>): InitialTraining {
    if (!option) {
      throw new TransformationFailedError('Missing option');
    }

    if (!option.value) {
      throw new TransformationFailedError('Missing property "value" in SelectOption');
    }

    if (!option.label) {
      throw new TransformationFailedError('Missing property "label" in SelectOption');
    }

    return {id: option.value, level: option.label}
  }
}
