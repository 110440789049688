export enum GIR {
  GIR1 = "GIR1",
  GIR2 = "GIR2",
  GIR3 = "GIR3",
  GIR4 = "GIR4",
  GIR5 = "GIR5",
  GIR6 = "GIR6"
}

export const GIRValues = new Set<GIR>(Object.values(GIR));

export const GIRLabels: Map<GIR, string> = new Map([
  [GIR.GIR1, 'GIR 1'],
  [GIR.GIR2, 'GIR 2'],
  [GIR.GIR3, 'GIR 3'],
  [GIR.GIR4, 'GIR 4'],
  [GIR.GIR5, 'GIR 5'],
  [GIR.GIR6, 'GIR 6'],
]);
