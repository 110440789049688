<ehp-form-field>
  <label *ngIf="field.label" [for]="field.name">
    <ehp-translatable-string [value]="field.label"></ehp-translatable-string>
  </label>

  <p *ngIf="field.description" class="description">
    <ehp-translatable-string [value]="field.description"></ehp-translatable-string>
  </p>

  <input
    #input
    (ngModelChange)="updateControlValue($event)"
    [(ngModel)]="value"
    [id]="field.name"
    ehp-form-control
    type="date"
  >

</ehp-form-field>
