<ng-container *ngFor="let facility of facilities">

  <article *ngIf="facility" class="row">
    <div class="name-container">
      <h3 class="title facility-name">{{facility.name}}</h3>
      <ehp-address-one-line [address]="facility.address"></ehp-address-one-line>
    </div>

    <ehp-facility-link *ngIf="facility" [facility]="facility" class="button small is-icon icon-arrow secondary">
      <span class="label">{{"Voir la fiche d'identité de l'établissement" | ehp_translate}}</span>
    </ehp-facility-link>
    
  </article>

</ng-container>
