import { CostByETP } from './costs-by-etp';
import { Facility } from '@domain/facility';

export interface ForecastCostByETP extends CostByETP {
  etp: number;

  total: number;

  facilityId: Facility['id'];

  facilityName: Facility['name'];
}
