import {Command} from "@application/framework/command-query";
import {PMP} from "@domain/pmp";

export class OpenPMPDeletionDialogCommand implements Command<PMP> {


  constructor(
    public readonly pmp: PMP,
    public readonly onDeletion?: (pmp: PMP) => void
  ) {
  }
}
