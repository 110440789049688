import { Service } from '@application/framework/di';
import { PMP } from '@domain/pmp';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Service()
export class PMPAuthorizationChecker {
  constructor(private readonly authorization: DocumentAuthorization) {}

  public canRead(pmp?: PMP) {
    return this.authorization.canReadDocument(pmp);
  }

  public canCreate() {
    return this.authorization.canCreateDocument();
  }

  public canUpdate(pmp?: PMP) {
    return this.authorization.canEditDocument(pmp);
  }

  public canDelete(pmp?: PMP) {
    return this.authorization.canDeleteDocument(pmp);
  }
}
