/**
 * @since 1.1.0
 */
export interface UpdateRate {
  /**
   * Number between -100% +100%
   */
  rate: number;

  /**
   * Application date
   */
  date: Date;
}
