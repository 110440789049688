import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CPOMAnnex } from '@domain/cpom/cpom-annex';
import { Media, MediaBucket } from '@domain/media';
import { isValidMediaIdType } from '@application/bundles/media';

@Component({
  selector: 'ehp-cpom-annex-list-item',
  templateUrl: './cpom-annex-list-item.component.html',
  styleUrls: ['./cpom-annex-list-item.component.scss'],
})
export class CpomAnnexListItemComponent implements OnChanges {
  @Input() public annex!: CPOMAnnex;

  @HostBinding('class') public classes = 'is-card';

  public document: Media | undefined;

  constructor(public readonly bucket: MediaBucket) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('annex' in changes) {
      this.loadMedia();
    }
  }

  public loadMedia(): void {
    const id = this.annex.mediaId;

    if (isValidMediaIdType(id)) {
      this.bucket.get(id).then(media => (this.document = media));
    }
  }
}
