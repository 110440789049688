import { isValidLocalCPOMIdType, UpdateLocalCPOMDto } from '@application/bundles/cpom';
import { CreateLocalCPOMDtoImpl } from '@implementations/bundles/cpom';
import { IsValidLocalCPOMId } from '@implementations/bundles/cpom/validators';

export class UpdateLocalCPOMDtoImpl extends CreateLocalCPOMDtoImpl implements UpdateLocalCPOMDto {
  @IsValidLocalCPOMId()
  public id!: UpdateLocalCPOMDto['id'];

  constructor(values?: Partial<UpdateLocalCPOMDto>) {
    super(values);

    if (values && isValidLocalCPOMIdType(values.id)) {
      this.id = values.id;
    }
  }
}
