import {SerializedHealthFundingSearchParams,} from "@application/bundles/health-funding";
import {Query} from "@application/framework/command-query";
import {HealthFunding} from "@domain/health-funding";

export class ListHealthFundingQuery implements Query<HealthFunding[]> {

  constructor(public readonly params?: Partial<SerializedHealthFundingSearchParams>) {
  }

}
