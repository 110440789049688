export class InvalidCommandConstructorError extends Error {
}


export class InvalidCommandHandlerFactoryError extends Error {
}


export class MissingCommandConstructorError extends Error {
}
