import { NgModule } from '@angular/core';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator/validator.module';
import {
  CreateEhpadDemainHealthFundingDtoImpl,
  CreateExceptionalHealthFundingDtoImpl,
  CreateHealthFundingDtoImpl,
  CreateNonRecurringHealthFundingDtoImpl,
  CreateRecurringHealthFundingDtoImpl,
  UpdateEhpadDemainHealthFundingDtoImpl,
  UpdateExceptionalHealthFundingDtoImpl,
  UpdateHealthFundingDtoImpl,
  UpdateNonRecurringHealthFundingDtoImpl,
  UpdateRecurringHealthFundingDtoImpl,
} from '@implementations/bundles/health-funding/dto';
import { validateHealthFundingDto } from '@implementations/bundles/health-funding/validators';
import {
  EhpadDemainHealthFundingDtoFactory,
  ExceptionalHealthFundingDtoFactory,
  HealthFundingBundle,
  HealthFundingDtoFactory,
  HealthFundingRepository,
  HealthTaxonomyRepositoryProvider,
  NonRecurringHealthFundingDtoFactory,
  RecurringHealthFundingDtoFactory,
} from '@application/bundles/health-funding';
import {
  EhpadDemainHealthFundingDtoFactoryImpl,
  ExceptionalHealthFundingDtoFactoryImpl,
  HealthFundingDtoFactoryImpl,
  NonRecurringHealthFundingDtoFactoryImpl,
  RecurringHealthFundingDtoFactoryImpl,
} from '@implementations/bundles/health-funding/factories';
import { Bundle } from '@application/framework/bundle';
import { EasyHealthFundingRepository } from '@implementations/bundles/backend/easyhpad';
import { HealthFundingUrlsProvider } from '@easyhpad-ui/app/bundles/health-funding/providers/url-provider/health-funding-urls.provider';
import { OpenHealthFundingDeletionDialogCommandHandler } from '@easyhpad-ui/app/bundles/health-funding/commands/handlers';
import { StoreModule } from '@ngrx/store';
import { HEALTH_FUNDING_STORE_KEY, HealthFundingEffects, healthFundingReducer } from './store';
import { EffectsModule } from '@ngrx/effects';
import { EasyHealthTaxonomyRepositoryProvider } from '@implementations/bundles/dependency-funding';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';

@NgModule({
  imports: [
    StoreModule.forFeature(HEALTH_FUNDING_STORE_KEY, healthFundingReducer),
    EffectsModule.forFeature([HealthFundingEffects]),
    ValidatorModule.forChild({
      validators: [
        { objectType: CreateHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: UpdateHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: CreateRecurringHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: UpdateRecurringHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: CreateNonRecurringHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: UpdateNonRecurringHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: CreateExceptionalHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: UpdateExceptionalHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: CreateEhpadDemainHealthFundingDtoImpl, validator: validateHealthFundingDto },
        { objectType: UpdateEhpadDemainHealthFundingDtoImpl, validator: validateHealthFundingDto },
      ],
    }),
  ],
  providers: [
    HealthFundingEffects,
    HealthFundingUrlsProvider,
    {
      provide: HealthFundingDtoFactory,
      useClass: HealthFundingDtoFactoryImpl,
    },
    {
      provide: RecurringHealthFundingDtoFactory,
      useClass: RecurringHealthFundingDtoFactoryImpl,
    },
    {
      provide: NonRecurringHealthFundingDtoFactory,
      useClass: NonRecurringHealthFundingDtoFactoryImpl,
    },
    {
      provide: ExceptionalHealthFundingDtoFactory,
      useClass: ExceptionalHealthFundingDtoFactoryImpl,
    },
    {
      provide: EhpadDemainHealthFundingDtoFactory,
      useClass: EhpadDemainHealthFundingDtoFactoryImpl,
    },
    {
      provide: HealthFundingRepository,
      useClass: EasyHealthFundingRepository,
    },
    {
      provide: HealthTaxonomyRepositoryProvider,
      useFactory: (backend: EasyBackendApi) => new EasyHealthTaxonomyRepositoryProvider(backend),
      deps: [EasyBackendApi],
    },
  ],
})
@Bundle({
  imports: [HealthFundingBundle],
  commandHandlers: [OpenHealthFundingDeletionDialogCommandHandler],
})
export class HealthFundingModule {}
