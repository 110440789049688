import { PropertySerializerMetadata, PropertySerializerOptions } from '@application/framework/backend/contracts';
import {
  extractExcludedPropertiesMetadata,
  extractPropertySerializerMetadata,
} from '@application/framework/backend/helpers';
import { BACKEND_SERIALIZER_METADATA } from '@application/framework/backend/metadata';
import { MetadataReflection } from '@application/framework/lib';

const reflection = new MetadataReflection();

/**
 *  Uses this decorator to change the name of the property and/or transforms the property
 *  when the object is serialized to be sent to the backend.
 * @param name
 * @constructor
 */
export function BackendProperty(name: string): PropertyDecorator;
export function BackendProperty(options: Omit<PropertySerializerMetadata, 'propertyName'>): PropertyDecorator;
export function BackendProperty(name: string, options?: PropertySerializerOptions): PropertyDecorator;
export function BackendProperty(
  name: string | Omit<PropertySerializerMetadata, 'propertyName'>,
  options?: PropertySerializerOptions,
): PropertyDecorator {
  return (target: any, propertyKey: string | symbol) => {
    let metadata: PropertySerializerMetadata;

    if (typeof name === 'string') {
      metadata = {
        ...(options ?? {}),
        propertyName: propertyKey,
        backendName: name,
      };
    } else {
      metadata = {
        ...name,
        propertyName: propertyKey,
      };
    }

    const values = extractPropertySerializerMetadata(target);
    values.set(propertyKey, metadata);

    reflection.defineMetadata(BACKEND_SERIALIZER_METADATA.PROPERTY_NAME, values, target);
  };
}

/**
 * Uses this decorator to remove the property when the entity is serialized
 * into an object to be sent to the backend.
 */
export function BackendExclude(): PropertyDecorator {
  return (target: any, propertyKey: string | symbol) => {
    const metadata = extractExcludedPropertiesMetadata(target);
    metadata.add(propertyKey);

    reflection.defineMetadata(BACKEND_SERIALIZER_METADATA.EXCLUDE, metadata, target);
  };
}
