import {Query} from "@application/framework/command-query";
import {SerializedEPRDSearchParams} from "@application/bundles/eprd-errd";
import {EPRD} from "@domain/eprd-errd";

export class ListEPRDQuery implements Query<EPRD[]> {

  constructor(public readonly params?: Partial<SerializedEPRDSearchParams>) {
  }

}
