import { Injectable } from '@angular/core';
import { isMaybeAFacility } from '@application/bundles/facility';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { ListAuthorizedFacilitiesQuery } from '@application/bundles/facility/query/list-authorized-facilities.query';
import { ListFacilitiesQuery } from '@application/bundles/facility/query/list-facilities.query';
import { CurrentFacilitiesStore } from '@application/bundles/facility/store';
import { QueryBus } from '@application/framework/command-query';
import { Customer } from '@domain/customer';
import { Facility, isActiveFacility } from '@domain/facility';
import { CustomerActions } from '@easyhpad-ui/app/bundles/customer';
import { ErrorActions } from '@easyhpad-ui/app/bundles/errors/store';
import {
  selectFacility,
  selectFacilityName,
  selectSelectedFacilitiesForAccount,
} from '@easyhpad-ui/app/bundles/facility/store/facility.selectors';
import {
  facilityStoreSerializer,
  StoreSerializedFacility,
} from '@easyhpad-ui/app/bundles/facility/store/serializers/facility/facility-store.serializer';
import { AppState } from '@easyhpad-ui/app/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, from, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { FacilityActions } from './facility.actions';

@Injectable()
export class FacilityEffects {
  private loadAccountFacilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityActions.loadAccountFacilities),
      mergeMap(() => this.loadAccountFacilities()),
      map(facilities => FacilityActions.setAccountFacilities({ facilities: this.serializeFacilities(facilities) })),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  private loadAvailableOnCustomerChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.setCurrentCustomer),
      mergeMap(action => this.loadAccountFacilities(action.customer?.id)),
      concatMap(facilities => {
        const actives = facilities.filter(isActiveFacility);
        return [
          FacilityActions.setAccountFacilities({ facilities: this.serializeFacilities(facilities) }),
          FacilityActions.selectAccountFacilities({ ids: actives.length === 1 ? actives.map(f => f.id) : [] }),
        ];
      }),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  private loadFacilityForAdminView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityActions.loadAdminFacilities),
      mergeMap(action => from(this.queryBus.request<Facility[]>(new ListFacilitiesQuery(action.params?.serialize())))),
      map(facilities => FacilityActions.setAdminFacilities({ facilities: this.serializeFacilities(facilities) })),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  private setCurrentFacilities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FacilityActions.selectAccountFacilities),
        switchMap(action => this.store.select(selectSelectedFacilitiesForAccount).pipe(take(1))),
        tap(facilities => {
          this.currentFacilities.set(...facilities);
        }),
      ),
    { dispatch: false },
  );

  private loadSingleFacility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityActions.loadFacility),
      mergeMap(action => {
        if (action.useCache) {
          return this.store.select(selectFacility(action.id)).pipe(
            take(1),
            switchMap(facility => {
              return isMaybeAFacility(facility)
                ? of(facility)
                : from(this.queryBus.request<Facility>(new GetFacilityQuery(action.id)));
            }),
          );
        }

        return from(this.queryBus.request<Facility>(new GetFacilityQuery(action.id)));
      }),
      map(facility => FacilityActions.setFacility({ facility: facilityStoreSerializer.serialize(facility) })),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  private loadFacilityName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityActions.loadFacilityName),
      mergeMap(action => {
        const resolver = (name: string | undefined) => {
          if (name) {
            return of({ id: action.id, name });
          }
          return from(this.queryBus.request<Facility>(new GetFacilityQuery(action.id)));
        };

        return this.store.select(selectFacilityName(action.id)).pipe(take(1), switchMap(resolver));
      }),
      map(name => FacilityActions.setFacilityForName(name)),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private queryBus: QueryBus,
    private readonly currentFacilities: CurrentFacilitiesStore,
  ) {
    this.currentFacilities.onChange(facilities => {
      this.updateFacilitiesInStore(facilities);
    });
  }

  private loadAccountFacilities(customer?: Customer['id']): Observable<Facility[]> {
    return from(this.queryBus.request<Facility[]>(new ListAuthorizedFacilitiesQuery(customer, true)));
  }

  private updateFacilitiesInStore(facilities: Facility[]) {
    this.store
      .select(selectSelectedFacilitiesForAccount)
      .pipe(take(1))
      .subscribe(currents => {
        if (!this.isTheSameSelection(currents, facilities)) {
          this.store.dispatch(FacilityActions.selectAccountFacilities({ ids: facilities.map(f => f.id) }));
        }
      });
  }

  private isTheSameSelection(a: Facility[], b: Facility[]): boolean {
    const ids = new Set(a.map(facility => facility.id));
    const selected = new Set(b.map(facility => facility.id));

    return ids.size === selected.size && [...ids].every(id => selected.has(id));
  }

  private serializeFacilities(facilities: Facility[]): StoreSerializedFacility[] {
    return facilities.map(f => facilityStoreSerializer.serialize(f));
  }
}
