<ng-container *ngIf="field">

    <ng-container [ngSwitch]="field.formElementType">

        <ng-container *ngSwitchCase="'htmlGroup'">

            <header *ngIf="$htmlGroup(field).title as groupTitle" class="form-group-header">
                <h2 class="title form-row-title">
                    <ehp-translatable-string [value]="groupTitle"></ehp-translatable-string>
                </h2>
            </header>

            <div class="form-group-content">
                <ng-container #groupControlContainer></ng-container>
            </div>

        </ng-container>

        <ng-container #controlContainer *ngSwitchDefault></ng-container>

    </ng-container>

</ng-container>
