import {Dialog} from "@angular/cdk/dialog";
import {
  DeleteCapacityAuthorizationCommand
} from "@application/bundles/capacity-authorization/commands/delete-capacity-authorization.command";
import {CommandBus, CommandHandler, HandleCommand} from "@application/framework/command-query";
import {CapacityAuthorization} from "@domain/capacity-authorization";
import {
  ConfirmDeletionDialogComponent
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component";
import {
  ConfirmDeletionDialogData
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface";
import {DialogConfiguration} from "@easyhpad-ui/app/library/dialog";
import {OpenDeleteCapacityAuthorizationDialogCommand} from "../../open-delete-capacity-authorization-dialog.command";

@HandleCommand({
  command: OpenDeleteCapacityAuthorizationDialogCommand
})
export class OpenDeleteCapacityAuthorizationDialogCommandHandler implements CommandHandler<OpenDeleteCapacityAuthorizationDialogCommand> {


  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus
  ) {
  }

  public async handle(command: OpenDeleteCapacityAuthorizationDialogCommand): Promise<void> {
    const {authorization, onDeletion} = command;


    const facility = await authorization.facility();

    const data: ConfirmDeletionDialogData = {
      options: [{key: authorization.id, label: facility.name}]
    }

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<CapacityAuthorization>(new DeleteCapacityAuthorizationCommand(authorization));

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    }

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, {...DialogConfiguration, data});
    dialog.closed.subscribe(subscriber);
  }
}
