import {Command} from "@application/framework/command-query";
import {GMP} from "@domain/gmp";

export class OpenGMPDeletionDialogCommand implements Command<GMP> {


  constructor(
    public readonly gmp: GMP,
    public readonly onDeletion?: (gmp: GMP) => void
  ) {
  }
}
