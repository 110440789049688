import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { getPMPYear, NationalPMPIndicator, PMP } from '@domain/pmp';
import { differenceInPercentage, isPositive } from '@application/framework/lib';
import { TranslatableString } from '@application/framework/translation';
import { NationalPMPIndicatorRepository, PMP_TRANSLATE_CONTEXT } from '@application/bundles/pmp';

@Component({
  selector: 'ehp-pmp-value',
  templateUrl: './pmp-value.component.html',
  styleUrls: ['./pmp-value.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PmpValueComponent implements OnChanges {
  @Input() public pmp: PMP | undefined;

  public national: NationalPMPIndicator | undefined;

  public difference: number | undefined;

  public differenceMessage: TranslatableString | undefined;

  constructor(private readonly nationalRepository: NationalPMPIndicatorRepository) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['pmp']) {
      this.pmpHasChanges();
    }
  }

  public isPositive() {
    return !!(this.difference && isPositive(this.difference));
  }

  private async pmpHasChanges() {
    this.difference = undefined;
    this.differenceMessage = undefined;

    if (this.pmp !== undefined) {
      this.national = await this.nationalRepository.getLatest(getPMPYear(this.pmp));

      if (this.national !== undefined) {
        this.difference = differenceInPercentage(this.pmp.value, this.national.value);
        let message = 'Plus haut que la moyenne nationale en {{year}}';

        if (this.difference <= 0) {
          message = 'Plus bas que la moyenne nationale en {{year}}';
        }

        this.differenceMessage = new TranslatableString(message, { year: getPMPYear(this.pmp) }, PMP_TRANSLATE_CONTEXT);
      }
    }
  }
}
