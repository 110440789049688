import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isMaybeAFacility, isValidFacilityIdType } from '@application/bundles/facility';
import { Facility } from '@domain/facility';
import {
  selectActiveFacilitiesForAccount,
  selectAllFacilitiesForAccount,
} from '@easyhpad-ui/app/bundles/facility/store';
import { AppState } from '@easyhpad-ui/app/store';
import { Store } from '@ngrx/store';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-account-multi-facility-selector',
  templateUrl: './account-multi-facility-selector.component.html',
  styleUrls: ['./account-multi-facility-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountMultiFacilitySelectorComponent),
      multi: true,
    },
  ],
})
export class AccountMultiFacilitySelectorComponent implements ControlValueAccessor, OnInit {
  @Input() public id: string | undefined;

  @Input() public scope: 'active' | 'all' = 'active';

  @Output() public facilityChange: EventEmitter<Facility[]> = new EventEmitter();

  public readonly optionLabel = 'name';

  public disabled: boolean = false;

  public facilities: Facility[] = [];

  public selection: Array<Facility['id']> = [];

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly store: Store<AppState>) {}

  public ngOnInit() {
    const selector = this.scope === 'all' ? selectAllFacilitiesForAccount : selectActiveFacilitiesForAccount;
    this.store
      .select(selector)
      .pipe(takeUntil(this.destroy$))
      .subscribe(facilities => {
        this.sourceChanges(facilities);
      });
  }

  public onChange = (value: Array<Facility['id']>): void => undefined;

  public onTouch = (value: any): void => undefined;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(value: Array<Facility> | Array<Facility['id']> | null | undefined): void {
    if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
      this.selection = [];
      return;
    }

    const selection: Array<Facility['id']> = value
      .map(facility => {
        if (isValidFacilityIdType(facility)) {
          return facility;
        } else if (isMaybeAFacility(facility)) {
          return facility.id;
        }
        return '';
      })
      .filter(id => id !== '');

    if (this.facilities.length > 0) {
      const currents = this.facilities.map(f => f.id);
      selection.filter(id => !currents.includes(id));
    }

    this.selection = selection;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public selectionChange(changes: MultiSelectChangeEvent) {
    this.onChange(changes.value);
  }

  private sourceChanges(facilities: Facility[]) {
    this.facilities = facilities;

    if (this.selection.length > 0) {
      const ids = this.facilities.map(f => f.id);

      this.selection = [...this.selection.filter(id => ids.includes(id))];
      this.onChange(this.selection);
    }
  }
}
