import { Common } from '@application/framework/lib';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { AsyncTransformer } from '@application/framework/core';
import { CreateUser, StudyLevel, UpdateUser, User } from '@domain/user';
import { InitialTraining } from '@domain/user/initial-training.interface';
import { InitialTrainingRepository } from '@domain/user/initial-training.repository';
import { Job } from '@domain/user/job.interface';
import { JobRepository } from '@domain/user/job.repository';
import { StudyLevelRepository } from '@domain/user/study-level.repository';
import { EasyCreateUser, EasyUpdateUser, EasyUser } from '@implementations/bundles/backend/easyhpad/interfaces';
import { Provide, Service } from '@application/framework/di';
import { EasyRoleTransformer } from '@implementations/bundles/backend/easyhpad/transformers/role/easy-role-transformer';

type EasyValue = Common<EasyUser, Common<EasyCreateUser, EasyUpdateUser>>;

type Value = Common<User, Common<CreateUser, UpdateUser>>;

@Service()
export class EasyUserCommonTransformer implements AsyncTransformer<Value, EasyValue> {
  @ProvideLogger() private readonly logger!: Logger;

  @Provide() private readonly jobRepository!: JobRepository;
  @Provide() private readonly initialTrainingRepository!: InitialTrainingRepository;
  @Provide() private readonly studyLevelRepository!: StudyLevelRepository;

  private readonly roleTransformer: EasyRoleTransformer = new EasyRoleTransformer();

  public async transform(value: Value): Promise<EasyValue> {
    return {
      firstname: value.firstname,
      lastname: value.lastname,
      email: value.email,
      blocked: value.blocked,
      customerId: value.customerId || undefined,
      facilityIds: value.facilityIds,
      jobType: value.job?.id || undefined,
      educationLevel: value.studyLevel?.id || undefined,
      initialTraining: value.initialTraining?.id || undefined,
    };
  }

  public async reverseTransform(value: EasyValue): Promise<Value> {
    return {
      firstname: value.firstname,
      lastname: value.lastname,
      displayName: `${value.firstname} ${value.lastname}`.trim(),
      email: value.email,
      job: await this.getJob(value.jobType),
      studyLevel: await this.getStudyLevel(value.educationLevel),
      initialTraining: await this.getInitialTraining(value.initialTraining),
      customerId: value.customerId || undefined,
      blocked: value.blocked,
    };
  }

  private async getJob(id: EasyUser['jobType']): Promise<Job | undefined> {
    if (!id) {
      return undefined;
    }
    try {
      return await this.jobRepository.get(id);
    } catch (e: any) {
      this.logger.error(e);
      return undefined;
    }
  }

  private async getInitialTraining(id: EasyUser['initialTraining']): Promise<InitialTraining | undefined> {
    if (!id) {
      return undefined;
    }
    try {
      return await this.initialTrainingRepository.get(id);
    } catch (e: any) {
      this.logger.error(e);
      return undefined;
    }
  }

  private async getStudyLevel(id: EasyUser['educationLevel']): Promise<StudyLevel | undefined> {
    if (!id) {
      return undefined;
    }
    try {
      return await this.studyLevelRepository.get(id);
    } catch (e: any) {
      this.logger.error(e);
      return undefined;
    }
  }
}
