<div *ngIf="max !== size" class="zone">
  <label [for]="inputId" class="label">

    <ng-container *ngIf="multiple; else singleFile">{{"Choisir des fichiers" | ehp_translate}}</ng-container>
    <ng-template #singleFile>{{"Choisir un fichier" | ehp_translate}}</ng-template>
    <span class="drag-drop-label"> {{ "ou effectuer un glisser/déposer" | ehp_translate }}</span>

  </label>

  <input #fileUpload (change)="onFileSelection($event)"
         [accept]="requiredFileType"
         [id]="inputId"
         [max]="max"
         [min]="min"
         [multiple]="multiple"
         class="file-input"
         data-multiple-caption="{count} files selected"
         type="file"
  >
</div>

<ul *ngIf="!hideFileList && internalMediaList && internalMediaList.length > 0" class="files-list">
  <li *ngFor="let file of internalMediaList">
    <span class="name">{{ file.name }}</span>

    <button (click)="removeFile(file)" class="is-icon icon-delete delete" type="button">
      <span class="label">{{'Supprimer le fichier' | ehp_translate }}</span>
    </button>
  </li>

</ul>


