<div *ngIf="others && others.length > 0; else emptyPreviousList" class="previous-list">
  <ng-container *ngFor="let local of others">
    <ehp-cpom-list-item *ngIf="local.parent" [cpom]="local.parent"></ehp-cpom-list-item>
  </ng-container>

</div>


<ng-template #emptyPreviousList>
  <ehp-empty-block>{{'Aucun autre C.P.O.M. archivé pour le moment.' | ehp_translate}}</ehp-empty-block>
</ng-template>
