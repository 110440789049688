import {HealthFundingCreatedEvent, HealthFundingCreationFailEvent} from "@application/bundles/health-funding";
import {HEALTH_FUNDING_TRANSLATE_CONTEXT} from "@application/bundles/health-funding/health-funding.token";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnHealthFundingCreationEventSubscriber {

  constructor(private readonly noticeStream: NoticeStream) {
  }

  @OnEvent({
    event: HealthFundingCreatedEvent
  })
  private async pushNoticeOnHealthFundingCreationSuccess(event: HealthFundingCreatedEvent): Promise<void> {

    const message = new TranslatableString(
      "Le <strong>financement soin {{year}}</strong> a été créé avec succès.",
      {year: event.healthFunding.year},
      HEALTH_FUNDING_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  @OnEvent({
    event: HealthFundingCreationFailEvent
  })
  private pushNoticeOnHealthFundingCreationFail(event: HealthFundingCreationFailEvent): void {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la création du nouveau financement soin.',
        undefined,
        HEALTH_FUNDING_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({type: NoticeType.ERROR, message});
  }
}
