import { FileDeposit } from '@domain/file-deposit';
import { FileDepositSearchParams } from '@application/bundles/file-deposit';
import { CreateFileDeposit } from '../contracts/create-file-deposit';

export abstract class FileDepositRepository {
  public abstract list(params?: FileDepositSearchParams): Promise<FileDeposit[]>;

  public abstract get(id: FileDeposit['id']): Promise<FileDeposit | undefined>;

  public abstract create(create: CreateFileDeposit): Promise<FileDeposit>;
}
