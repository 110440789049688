import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { CURRENT_USER_REACTIVE_ACCESSOR } from '@easyhpad-ui/app/bundles/user/user.tokens';
import { ReactiveAccessor } from '@implementations/framework/accessor/reactive-accessor.interface';
import { User } from '@domain/user';
import { Observable } from 'rxjs';
import { configuration } from '@easyhpad-ui/environments/configuration';
import { UserUrlsProvider } from '@easyhpad-ui/app/bundles/user/providers';

@Component({
  selector: 'ehp-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopBarComponent {
  public user$: Observable<User>;

  public URLS: { profile: string; logout: string };

  constructor(
    @Inject(CURRENT_USER_REACTIVE_ACCESSOR) private readonly userAccessor: ReactiveAccessor<User>,
    private readonly userUrls: UserUrlsProvider,
  ) {
    this.user$ = this.userAccessor.get();
    this.URLS = {
      profile: this.userUrls.getUserProfileUrl(),
      logout: configuration.logoutPath,
    };
  }
}
