import {EPRDRepository, GetEPRDQuery} from "@application/bundles/eprd-errd";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {EPRD} from "@domain/eprd-errd";

@HandleQuery({
  query: GetEPRDQuery
})
export class GetEPRDQueryHandler implements QueryHandler<GetEPRDQuery, EPRD> {

  constructor(private readonly repository: EPRDRepository) {
  }

  public async handle(query: GetEPRDQuery): Promise<EPRD> {
    return this.repository.get(query.id);
  }
}
