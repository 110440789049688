import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {registerApplicationComponents} from "@application/bundles/application";
import {
  angularInjectProvideDecoratorAdapter
} from "@easyhpad-ui/app/framework/di/adapters/angular-inject-provide-decorator.adapter";
import {
  angularInjectableServiceDecoratorAdapter
} from "@easyhpad-ui/app/framework/di/adapters/angular-injectable-service-decorator.adapter";
import {LazyAngularContainer} from "@easyhpad-ui/app/framework/di/containers/lazy-angular.container";
import {configuration} from "@easyhpad-ui/environments/configuration";
import {ConsoleLogger} from "@implementations/framework/logger";

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

export const boostrap = () => {
  if (environment.production) {
    enableProdMode();
  }

  registerApplicationComponents({
    defaultContainer: new LazyAngularContainer('LazyAngular'),
    serviceDecorator: angularInjectableServiceDecoratorAdapter,
    provideDecorator: angularInjectProvideDecoratorAdapter,
    logger: {useValue: new ConsoleLogger(configuration.appName)}
  });


  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}


