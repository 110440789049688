import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateCPOMDto, StoredCPOM, UpdateCPOMDto } from '@application/bundles/cpom';
import { DATE_FORMATS, DateFormatter } from '@application/framework/date';
import { DateParser } from '@application/framework/date/date.parser';
import { Common } from '@application/framework/lib';
import { CPOM, LocalCPOM } from '@domain/cpom';
import { LocalCPOMFormBuilder } from '@easyhpad-ui/app/bundles/cpom/services/local-cpom-form-builder/local-cpom-form-builder';
import { isLocalMedia, isMedia, LocalMedia, Media } from '@domain/media';
import { resizeFormArray } from '@easyhpad-ui/app/library/form';
import { CPOMAnnex } from '@domain/cpom/cpom-annex';
import { CreateCPOMAnnex, UpdateCPOMAnnex } from '@application/bundles/cpom/dto/cpom-annex.dto';
import { isValidMediaIdType } from '@application/bundles/media';

type CommonProperties = Omit<Common<CreateCPOMDto, UpdateCPOMDto>, 'children' | 'documentId'>;

@Injectable()
export class CPOMFormBuilder {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dateParser: DateParser,
    private readonly dateFormatter: DateFormatter,
    private readonly localCPOMFormBuilder: LocalCPOMFormBuilder,
  ) {}

  public buildCreationForm(
    cpom?: Partial<StoredCPOM | CPOM>,
  ): FormGroup<Record<keyof Omit<CreateCPOMDto, 'documentId'>, any>> {
    let childrenControls: AbstractControl[] = [];

    if (cpom && Array.isArray(cpom.children)) {
      childrenControls = cpom.children.map(child => this.localCPOMFormBuilder.buildCreationForm(child));
    } else {
      childrenControls.push(this.localCPOMFormBuilder.buildCreationForm());
    }

    return this.formBuilder.group({
      ...this.buildCommonForm(this.serializeFormValues(cpom)),
      children: this.formBuilder.array(childrenControls, [Validators.required]),
    });
  }

  public buildUpdateForm(
    cpom?: Partial<StoredCPOM | CPOM>,
  ): FormGroup<Record<keyof Omit<UpdateCPOMDto, 'documentId' | 'id'>, any>> {
    let childrenControls: AbstractControl[] = [];

    if (cpom && Array.isArray(cpom.children)) {
      childrenControls = cpom.children.map(child => this.localCPOMFormBuilder.buildUpdateForm(child));
    }

    return this.formBuilder.group({
      ...this.buildCommonForm(this.serializeFormValues(cpom)),
      children: this.formBuilder.array(childrenControls, [Validators.required]),
    });
  }

  public addCreateLocalCPOMFormRow(control: FormArray): void {
    control.push(this.localCPOMFormBuilder.buildCreationForm());
  }

  public addUpdateLocalCPOMFormRow(control: FormArray): void {
    control.push(this.localCPOMFormBuilder.buildUpdateForm());
  }

  public deleteLocalCPOMRow(control: FormArray, index: number): void {
    control.removeAt(index);
  }

  public addAnnexRow(
    control: FormArray<FormGroup>,
    values: { id?: CPOMAnnex['id']; name?: CPOMAnnex['name']; media: LocalMedia | Media },
  ): void {
    control.push(this.formBuilder.group(this.buildAnnexForm({ ...values, mediaId: values.media })));
  }

  public removeAnnexRow(control: FormArray, index: number): void {
    control.removeAt(index);
  }

  public serializeFormValues(cpom?: Partial<StoredCPOM | CPOM>): Record<keyof CommonProperties, any> {
    const values: Record<keyof CommonProperties, any> = {
      date: cpom?.date ? this.dateFormatter.format(cpom?.date, DATE_FORMATS.SHORT_HTML) : undefined,
      document: undefined,
      otherServices: cpom?.otherServices,
      annexes: cpom?.annexes,
    };

    if (cpom && 'documentId' in cpom) {
      values.document = cpom.documentId;
    }

    return values;
  }

  public deserializeFormValues<T extends CreateCPOMDto | UpdateCPOMDto>(
    values: Record<keyof CreateCPOMDto | keyof UpdateCPOMDto, any>,
  ): T {
    const deserialized: Partial<Record<keyof CreateCPOMDto | keyof UpdateCPOMDto, any>> = {
      date: values.date ? this.dateParser.fromISOString(values.date) : undefined,
      otherServices: values.otherServices || undefined,
      annexes: [],
      children: Array.isArray(values.children)
        ? values.children.map(child => this.localCPOMFormBuilder.deserializeFormValues(child))
        : [],
    };

    if (isLocalMedia(values.document) || isMedia(values.document)) {
      deserialized.document = values.document;
    }

    if (Array.isArray(values.annexes)) {
      deserialized.annexes = values.annexes.map(annex => {
        const deserializedAnnex: Partial<Record<keyof CreateCPOMAnnex | keyof UpdateCPOMAnnex, any>> = {
          id: undefined,
          name: typeof annex.name === 'string' ? annex.name : '',
          mediaId: undefined,
          media: undefined,
        };

        if ('id' in annex && annex.id) {
          deserializedAnnex.id = annex.id;
        }

        if (isLocalMedia(annex.mediaId) || isMedia(annex.mediaId)) {
          deserializedAnnex.media = annex.mediaId;

          if (isMedia(annex.mediaId)) {
            deserializedAnnex.mediaId = annex.mediaId.id;
          }
        } else if (isValidMediaIdType(annex.mediaId)) {
          deserializedAnnex.mediaId = annex.mediaId;
        }

        return deserializedAnnex;
      });
    }

    return deserialized as T;
  }

  public rebuildFormValue(form: FormGroup, cpom?: Partial<CPOM | StoredCPOM>, isUpdate = true) {
    const values: Partial<Record<keyof CPOM | keyof StoredCPOM, any>> = this.serializeFormValues(cpom);

    values.children = [];
    if (cpom && Array.isArray(cpom?.children)) {
      values.children = cpom?.children.map(child => this.localCPOMFormBuilder.serializeFormValues(child));

      const childGenerator = (child?: LocalCPOM) =>
        isUpdate
          ? this.localCPOMFormBuilder.buildUpdateForm(child)
          : this.localCPOMFormBuilder.buildCreationForm(child);

      resizeFormArray(form.get('children') as FormArray, values.children, childGenerator);
    }

    values.annexes = [];

    if (cpom && Array.isArray(cpom.annexes)) {
      values.annexes = cpom.annexes;
      const childGenerator = (annex?: CPOMAnnex) => this.formBuilder.group(this.buildAnnexForm(annex));
      resizeFormArray(form.get('annexes') as FormArray, values.annexes, childGenerator);
    }

    form.patchValue(values);
  }

  private buildCommonForm(
    values?: Record<keyof CommonProperties, any>,
  ): Record<keyof CommonProperties, AbstractControl> {
    const controls = {
      date: new FormControl(values?.date, [Validators.required]),
      document: new FormControl(values?.document, [Validators.required]),
      otherServices: new FormControl(values?.otherServices),
      annexes: this.formBuilder.array<FormGroup>([]),
    };

    if (values && Array.isArray(values.annexes) && values.annexes.length > 0) {
      controls.annexes = this.formBuilder.array(
        values.annexes.map(v => this.formBuilder.group(this.buildAnnexForm(v))),
      );
    }

    return controls;
  }

  private buildAnnexForm(values?: Partial<Record<keyof CPOMAnnex, any>>): Record<keyof CPOMAnnex, FormControl> {
    return {
      id: new FormControl(values?.id),
      name: new FormControl(values?.name),
      mediaId: new FormControl(values?.mediaId, [Validators.required]),
    };
  }
}
