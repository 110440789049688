import {DocumentListParamsImpl} from "@application/framework/search";
import {AccommodationFundingSearchParams} from "@application/bundles/accommodation-funding";
import {SORT_ORDER} from "@application/framework/search/sort";

export class ListAccommodationFundingParamsImpl extends DocumentListParamsImpl implements AccommodationFundingSearchParams {
  constructor() {
    super();
    this.setSort({key: 'date', order: SORT_ORDER.DESC});
  }
}
