import { AddCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos';
import { UpdateCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto';
import { DateParser } from '@application/framework/date/date.parser';
import { Common } from '@application/framework/lib';
import { CapacityAuthorization } from '@domain/capacity-authorization';
import {
  EasyAddCapacityAuthorization,
  EasyCapacityAuthorization,
  EasyUpdateCapacityAuthorization,
} from '@implementations/bundles/backend/easyhpad/interfaces/easy-capacity-authorization.interface';
import { DateFormatter } from '@application/framework/date';

type EasyValue = Common<
  EasyUpdateCapacityAuthorization,
  Common<EasyAddCapacityAuthorization, EasyCapacityAuthorization>
>;

type Value = Omit<
  Common<UpdateCapacityAuthorizationDto, Common<AddCapacityAuthorizationDto, CapacityAuthorization>>,
  'authorization' | 'pv' | 'inspectionReports'
>;

export class EasyCapacityAuthorizationAbstractTransformer {
  constructor(
    protected readonly dateParser: DateParser,
    protected readonly dateFormatter: DateFormatter,
  ) {}

  protected transformCommonProperties(value: Value): EasyValue {
    return {
      permanentCapacity: value.permanentCapacity,
      bedSocialCareCapacity: value.bedSocialCare,
      start: value.dates?.start ? this.dateFormatter.toUTCIsoString(value.dates.start) : (undefined as any),
      end: value.dates?.end ? this.dateFormatter.toUTCIsoString(value.dates.end) : (undefined as any),
      implementationLimitDate: value.begin ? this.dateFormatter.toUTCIsoString(value.begin) : undefined,
      daycareCapacity: value.daycareCapacity,
      facilityId: value.facilityId,
      inspections: [],
      pasaCapacity: value.pasaCapacity,
      pvId: value.pvId,
      supportCapacity: value.supportCapacity,
      temporaryCapacity: value.temporaryCapacity,
      uhrCapacity: value.uhrCapacity,
      authorizationId: value.authorizationId || (undefined as any),
    };
  }

  protected reverseTransformCommonProperties(value: EasyValue): Value {
    return {
      authorizationId: value.authorizationId,
      permanentCapacity: value.permanentCapacity,
      bedSocialCare:
        value.bedSocialCareCapacity || value.bedSocialCareCapacity === 0 ? value.bedSocialCareCapacity : undefined,
      begin: value.implementationLimitDate ? this.dateParser.fromISOString(value.implementationLimitDate) : undefined,
      dates: {
        start: this.dateParser.fromISOString(value.start),
        end: this.dateParser.fromISOString(value.end),
      },
      daycareCapacity: value.daycareCapacity,
      facilityId: value.facilityId,
      pasaCapacity: value.pasaCapacity || value.pasaCapacity === 0 ? value.pasaCapacity : undefined,
      pvId: value.pvId ? value.pvId : undefined,
      supportCapacity: value.supportCapacity,
      temporaryCapacity: value.temporaryCapacity,
      uhrCapacity: value.uhrCapacity || value.uhrCapacity === 0 ? value.uhrCapacity : undefined,
    };
  }
}
