import {StringMatcher, StringParser} from "@application/framework/parser/string";
import {StringParserReplacementToken} from "@application/framework/parser/string/replacement-token";

export class AgnosticStringParser implements StringParser {


  public constructor(public readonly matcher: StringMatcher) {
  }

  public parse(template: string, replacement?: StringParserReplacementToken): string {
    
    if (!replacement) {
      return template;
    }

    return this.matcher.replace(template, replacement);
  }

}
