<ehp-page-header>

  <ng-container pageTitle>

    <h1 class="title page-title">

      <ehp-facility-name *ngIf="facilityId$ | async as id" [fid]="id" class="trail"></ehp-facility-name>

      <ng-container *ngIf="title && title.length > 0">

        <ehp-translatable-string
          *ngFor="let trail of title"
          [value]="trail"
          class="trail">
        </ehp-translatable-string>

      </ng-container>
    </h1>

    <ng-container #afterTitle></ng-container>
  </ng-container>


  <div actions class="actions-list actions">
    <ng-container *ngIf="actions$ | async as actionList">
      <ehp-document-action *ngIf="actionList.edit" [action]="actionList.edit"></ehp-document-action>
      <ehp-document-action *ngIf="actionList.delete" [action]="actionList.delete"></ehp-document-action>
      <ehp-document-action *ngIf="actionList.create" [action]="actionList.create"></ehp-document-action>
    </ng-container>
  </div>

  <ng-container actions>
  </ng-container>

</ehp-page-header>

<ehp-page-content class="page-layout-default">
  <div class="content-container">
    <ng-container #content></ng-container>
  </div>

  <div class="sidebar">

    <div *ngIf="medias$ | async as medias" class="documents-container">

      <ng-container *ngFor="let ref of medias">
        <div *ngIf=" ref.id | mediaLoader | async as media" class="row">

          <h2 *ngIf="ref.title" class="title section-title">
            <ehp-translatable-string [value]="ref.title"></ehp-translatable-string>
          </h2>

          <ehp-media-item [media]="media" class="is-card"></ehp-media-item>

        </div>
      </ng-container>

    </div>

    <div *ngIf="facilityId$ | async as id" class="facility-container">
      <h2 class="title section-title">{{ 'Établissement lié' | ehp_translate }}</h2>
      <ehp-facility-id-card [fid]="id"></ehp-facility-id-card>
    </div>


    <ng-container *ngIf="facilityIds$ | async as ids">
      <div *ngIf=" ids.length > 0" class="facility-container">
        <h2 class="title section-title">{{ 'Établissements liés' | ehp_translate }}</h2>
        <!-- todo: changer le système d'acquisition des données des facilities dans le cmp ehp-facilities-id-card -->
      </div>
    </ng-container>
  </div>


</ehp-page-content>
