import { DocumentListParamsImpl } from '@application/framework/search';
import { PMPListParams } from '@application/bundles/pmp';
import { SORT_ORDER } from '@application/framework/search/sort';

export class ListPMPParamsImpl extends DocumentListParamsImpl implements PMPListParams {
  constructor() {
    super();
    this.setSort({ key: 'validationDate', order: SORT_ORDER.DESC });
  }
}
