import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FacilityFormModule } from '@easyhpad-ui/app/bundles/facility/modules/form/facility-form.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import {
  AdvancedFiltersFormComponent,
  FilterComponent,
  MultiCurrentFacilityFilterComponent,
  YearFilterComponent,
} from './components';
import { FiltersFormBuilder } from './services/filters-form/filters-form.builder';

@NgModule({
  imports: [
    CommonModule,
    ListboxModule,
    FormModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    FacilityFormModule,
    MultiSelectModule,
  ],
  declarations: [
    YearFilterComponent,
    FilterComponent,
    AdvancedFiltersFormComponent,
    MultiCurrentFacilityFilterComponent,
  ],
  providers: [FiltersFormBuilder],
  exports: [YearFilterComponent, FilterComponent, AdvancedFiltersFormComponent, MultiCurrentFacilityFilterComponent],
})
export class FilterModule {}
