import {Command} from "@application/framework/command-query";
import {CPOM} from "@domain/cpom";
import {EPRD} from "@domain/eprd-errd";

export class OpenEPRDDeletionDialogCommand implements Command<CPOM> {


  constructor(
    public readonly eprd: EPRD,
    public readonly onDeletion?: (eprd: EPRD) => void
  ) {
  }
}
