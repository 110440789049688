import {isValidLocalCPOMIdType} from "@application/bundles/cpom";
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';

@ValidatorConstraint({name: 'IsValidLocalCPOM', async: true})
export class IsValidLocalCPOMIdConstraint implements ValidatorConstraintInterface {


  public validate(id: any, args?: ValidationArguments): Promise<boolean> {
    return Promise.resolve(isValidLocalCPOMIdType(id));
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'ID is not a valid Local CPOM ID.';
  }
}

export function IsValidLocalCPOMId(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidLocalCPOMIdConstraint
    });
  };
}
