<ng-container *ngIf="displayable">
  <span *ngIf="count > 1  || (count <= 1 && displaySingle)" [innerHTML]="count" class="count"></span>
</ng-container>

<ng-container *ngIf="count && count > 1; else singleItem">
  <ng-content select="[plural]"></ng-content>
</ng-container>

<ng-template #singleItem>
  <ng-content select="[single]"></ng-content>
</ng-template>
