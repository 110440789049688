import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Facility } from '@domain/facility';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FacilityUrls } from '@easyhpad-ui/app/bundles/facility/services/facility-urls/facility-urls';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  FacilityActions,
  selectActiveFacilitiesForAccount,
  selectSelectedFacilitiesForAccount,
} from '@easyhpad-ui/app/bundles/facility/store';
import { Subject, take, takeUntil } from 'rxjs';
import { AppState } from '@easyhpad-ui/app/store';

@Component({
  selector: 'ehp-current-facilities-selector',
  templateUrl: './current-facilities-selector.component.html',
  styleUrls: ['./current-facilities-selector.component.scss'],
})
export class CurrentFacilitiesSelectorComponent implements OnInit, OnDestroy {
  public facilities: Facility[] = [];

  public selection: Facility[] = [];

  public url: string = '';

  @ViewChild(OverlayPanel) private panel!: OverlayPanel;

  private selectionIsDisable = false;

  private isOpen = false;

  private destroy$: Subject<void> = new Subject();

  public get size(): number {
    return this.selection.length;
  }

  public get single(): Facility | undefined {
    return this.size === 1 ? this.selection[0] : undefined;
  }

  constructor(
    private readonly router: Router,
    private readonly urls: FacilityUrls,
    private readonly store: Store<AppState>,
  ) {
    this.store
      .select(selectSelectedFacilitiesForAccount)
      .pipe(takeUntil(this.destroy$))
      .subscribe(facilities => this.setSelectedFacilities(facilities));

    this.store
      .select(selectActiveFacilitiesForAccount)
      .pipe(takeUntil(this.destroy$))
      .subscribe(facilities => this.setFacilities(facilities));
  }

  public ngOnInit() {
    this.store.dispatch(FacilityActions.loadAccountFacilities());
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public open(): void {
    if (this.selectionIsDisable) {
      if (this.url) {
        this.router.navigateByUrl(this.url);
      }
      return;
    }

    if (this.panel) {
      this.panel.toggle(new CustomEvent(''));

      if (this.isOpen) {
        this.validateSelection();
      }
    }
  }

  public validateSelection(): void {
    if (this.panel) {
      this.panel.hide();
    }

    this.store
      .select(selectSelectedFacilitiesForAccount)
      .pipe(take(1))
      .subscribe(currents => {
        if (!this.isTheSameSelection(currents)) {
          this.store.dispatch(FacilityActions.selectAccountFacilities({ ids: this.selection.map(f => f.id) }));
          this.updateUrl();
        }
      });
  }

  public updateSelection(selection: { originalEvent: Event; value: Facility[] }) {
    this.selection = selection.value;
  }

  private setSelectedFacilities(facilities: Facility[]): void {
    this.selection = facilities;
    this.updateUrl();
  }

  private updateUrl(): void {
    this.url = this.single ? this.urls.getPublicShowWithoutAuthorizationCheck(this.single.id) : '';
  }

  private setFacilities(facilities: Facility[]): void {
    this.facilities = facilities;

    if (this.facilities.length <= 1) {
      this.selectionIsDisable = true;
      this.setSelectedFacilities(this.facilities);
    } else {
      this.selectionIsDisable = false;
    }
  }

  private clearSelection(): void {
    this.selection = [];
    this.validateSelection();
  }

  private isTheSameSelection(facilities: Facility[]): boolean {
    const expected = new Set(facilities.map(facility => facility.id));
    const selected = new Set(this.selection.map(facility => facility.id));

    return expected.size === selected.size && [...expected].every(id => selected.has(id));
  }
}
