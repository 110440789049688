import {UpdateInspectionReportDto} from "@application/bundles/capacity-authorization/dtos/update-inspection-report.dto";
import {Command} from "@application/framework/command-query";
import {CapacityAuthorization, InspectionReport} from "@domain/capacity-authorization";

export class UpdateInspectionReportCommand implements Command<InspectionReport> {


  public constructor(public readonly update: UpdateInspectionReportDto, public readonly authorizationId: CapacityAuthorization['id']) {
  }

}
