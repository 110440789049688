export enum SORT_ORDER {

  ASC = 'ASC',

  DESC = 'DESC'
}

export interface Sort<K extends string> {

  key: K;

  order: SORT_ORDER
}
