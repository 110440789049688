import { Customer } from '@domain/customer';
import { Address } from '@domain/address';
import { Facility } from '@domain/facility';
import { isUUID } from '@domain/lib';

export function isValidCustomerIdType(id: any): id is Customer['id'] {
  return isUUID(id);
}

export function isMaybeACustomer(
  customer: any,
): customer is { id: number; name?: string; address?: Address; facilities?: Array<Facility> } {
  if (typeof customer !== 'object' || customer === null) {
    return false;
  }

  return (
    (isValidCustomerIdType(customer.id) && typeof customer.name === 'string') ||
    (isValidCustomerIdType(customer.id) && typeof customer.address === 'object' && customer.address !== null) ||
    (isValidCustomerIdType(customer.id) && Array.isArray((customer as Customer).facilities))
  );
}

export function isValidCustomer(customer: any): customer is Customer {
  return (
    isValidCustomerIdType(customer.id) &&
    typeof customer.name === 'string' &&
    typeof customer.address === 'object' &&
    customer.address !== null &&
    Array.isArray((customer as Customer).facilities)
  );
}
