import { isValidObject } from '@application/framework/lib';
import { ERRD } from '@domain/eprd-errd';
import { isUUID } from '@domain/lib';

export function isValidERRDIdType(id: any): id is ERRD['id'] {
  return isUUID(id);
}

export function isMaybeAnERRD(errd: any): errd is { id: ERRD['id'] } {
  return isValidObject(errd) && 'id' in errd && isValidERRDIdType((errd as ERRD).id);
}
