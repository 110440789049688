import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GetFullDataTableWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-full-data-table-widget.query';
import {
  FullDataTableWidgetContent,
  FullDataTableWidgetContentRow,
} from '@easyhpad-ui/app/bundles/dashboard/interfaces/widgets/full-data-table-widget';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RequestParams } from '@application/framework/http/request.interface';
import {
  EasyFullDataTableWidget,
  EasyFullDataTableWidgetRow,
} from '@implementations/bundles/backend/easyhpad/interfaces/widgets';
import { TariffOptionCollection } from '@application/bundles/cpom';
import { TariffOption } from '@domain/cpom';
import { computeAuthorizedCapacity } from '@domain/capacity-authorization';

@HandleQuery({
  query: GetFullDataTableWidgetQuery,
})
export class GetFullDataTableWidgetQueryHandler
  implements QueryHandler<GetFullDataTableWidgetQuery, FullDataTableWidgetContent>
{
  constructor(
    private readonly backend: EasyBackendApi,
    private readonly tariffOptions: TariffOptionCollection,
  ) {}

  public async handle(query: GetFullDataTableWidgetQuery): Promise<FullDataTableWidgetContent> {
    const params = this.serializeParams(query);

    const response = await this.backend.get<EasyFullDataTableWidget>('/widgets/table', params);
    return this.getWidgetContent(response.body);
  }

  private async getWidgetContent(value: EasyFullDataTableWidget): Promise<FullDataTableWidgetContent> {
    return {
      ...value,
      results: value.results.map(row => this.createWidgetRow(row)),
    };
  }

  private createWidgetRow(row: EasyFullDataTableWidgetRow): FullDataTableWidgetContentRow {
    let option: TariffOption | null = null;

    if (row.cpomOption) {
      option = this.tariffOptions.get(row.cpomOption) ?? null;
    }

    const authorizedCapacity = computeAuthorizedCapacity({
      permanentCapacity: row.permanentCapacity ?? undefined,
      temporaryCapacity: row.temporaryCapacity ?? undefined,
    });

    return {
      ...row,
      authorizedCapacity,
      cpomOption: option,
      accommodationFundingAmount: row.accommodationFundingAmount ?? null,
    };
  }

  private serializeParams(query: GetFullDataTableWidgetQuery): RequestParams {
    const params: RequestParams = {};

    if (Array.isArray(query.facilities) && query.facilities.length > 0) {
      params['facility_ids'] = query.facilities.join(', ');
    }

    if (Array.isArray(query.filters) && query.filters.length > 0) {
      for (const [index, filter] of query.filters.entries()) {
        params[`filters[${filter.key}][${index}][op]`] = filter.op;

        if (filter.value instanceof Date) {
          params[`filters[${filter.key}][${index}][value]`] = filter.value.toISOString();
        } else if (typeof filter.value === 'boolean') {
          params[`filters[${filter.key}][${index}][value]`] = filter.value ? 'true' : 'false';
        } else {
          params[`filters[${filter.key}][${index}][value]`] = filter.value;
        }
      }
    }

    return params;
  }
}
