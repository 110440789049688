import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, NgModel } from '@angular/forms';
import { DATE_FORMATS, DateFormatter } from '@application/framework/date';
import {
  DynamicAbstractControlField,
  DynamicInputField,
  DynamicTextInputField,
  HasDynamicField,
} from '@easyhpad-ui/app/library/form/contracts';
import { FormControlDirective } from '@easyhpad-ui/app/library/form/directives/form-control/form-control.directive';
import { syncControlsDirty, syncControlsTouch } from '@easyhpad-ui/app/library/form/functions';
import { DynamicInputPropertySetter } from '@easyhpad-ui/app/library/form/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-dynamic-input-field',
  templateUrl: './dynamic-input-field.component.html',
})
export class DynamicInputFieldComponent
  implements HasDynamicField<DynamicTextInputField>, OnInit, AfterViewInit, OnDestroy
{
  /**
   * Field definition
   */
  @Input() public readonly field!: DynamicAbstractControlField<DynamicInputField<any>>;

  /**
   * Input HTML element reference
   */
  @ViewChild('input', { read: ElementRef }) public input!: ElementRef;

  /**
   * Input Form control reference
   */
  @ViewChild('input', { read: FormControlDirective }) public formControl!: FormControlDirective;

  /**
   * Angular model directive
   */
  @ViewChild('input', { read: NgModel }) public ngModel!: NgModel;

  /**
   * Input value binding.
   */
  public value: any = '';

  private destroy$ = new Subject<void>();

  public get type(): string {
    return this.field.type;
  }

  public get control(): FormControl | null {
    return this.field.control ? (this.field.control as FormControl) : null;
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dateFormatter: DateFormatter,
  ) {}

  public ngOnInit() {
    if (this.field.initialValue) {
      this.field.initialValue.pipe(takeUntil(this.destroy$)).subscribe(value => this.setInitialValue(value));
    }

    if (this.control) {
      this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => this.patchInternalValue(value));
    }
  }

  public ngAfterViewInit(): void {
    if (this.input) {
      this.renderer.setAttribute(this.input.nativeElement, 'type', this.field.type);

      const setter = new DynamicInputPropertySetter(this.renderer);
      setter.defineProperties(this.input, this.field);
    }

    if (this.control) {
      if (this.formControl) {
        this.formControl.attachControl(this.control);
      }

      if (this.ngModel) {
        this.linkModelAndControl(this.ngModel, this.control);
      }
    }

    this.changeDetector.detectChanges();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Update field control value.
   * @param value
   */
  public updateValue(value: string): void {
    this.control?.setValue(this.transformInputValue(value));
  }

  /**
   * Set value if field is untouched
   * @param value
   * @private
   */
  private setInitialValue(value: any) {
    if (this.control?.touched || this.control?.dirty) {
      return;
    }

    this.patchInternalValue(value);

    if (
      (value === null || value === undefined) &&
      (this.control?.value === null || this.control?.value === undefined)
    ) {
      return;
    }
    this.control?.patchValue(value, { emitEvent: true });
  }

  /**
   * Update value only if the value is different from this.value
   * @param value
   * @private
   */
  private patchInternalValue(value: any): void {
    if (this.value !== this.transformControlValue(value)) {
      this.value = this.transformControlValue(value);
    }
  }

  private transformControlValue(value: any): string {
    if (value === null || value === undefined) {
      return '';
    }
    if (this.type === 'date' && value instanceof Date) {
      return this.dateFormatter.format(value, DATE_FORMATS.SHORT_HTML);
    }

    if (typeof value === 'number' || typeof value === 'string') {
      return value.toString();
    }

    if ('toString' in value && typeof value.toString === 'function') {
      return value.toString();
    }

    return '';
  }

  private transformInputValue(value: string): any {
    if (this.type === 'number') {
      return value ? parseFloat(value) : null;
    } else if (this.type === 'date') {
      return value ? new Date(value) : null;
    }

    return value;
  }

  private linkModelAndControl(model: NgModel, control: AbstractControl): void {
    syncControlsDirty(model.control, control);
    syncControlsTouch(model.control, control);

    if (control.dirty) {
      model.control.markAsDirty();
    }

    if (control.touched) {
      model.control.markAsTouched();
    }
  }
}
