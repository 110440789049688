export enum FILE_DEPOT_STATES {
  DRAFT = 'draft',

  WAITING_CUSTOMER = 'waiting_customer',

  REVIEW = 'review',

  CLOSE = 'closed',

  ARCHIVED = 'archived',
}
