export class NamedList<K, V> implements Omit<Map<K, V>, 'set' | 'delete' | 'clear'> {


  private readonly list: Map<K, V>;


  public get size(): number {
    return this.list.size;
  }

  public get [Symbol.toStringTag](): string {
    return this.constructor.name;
  }


  constructor(entries?: readonly (readonly [K, V])[] | null) {
    this.list = new Map(entries);
  }


  public has(key: K): boolean {
    return this.list.has(key);
  }

  public get(key: K): V | undefined {
    return this.list.get(key);
  }


  public keys(): IterableIterator<K> {
    return this.list.keys();
  }


  public values(): IterableIterator<V> {
    return this.list.values();
  }

  public entries(): IterableIterator<[K, V]> {
    return this.list.entries();
  }

  public [Symbol.iterator](): IterableIterator<[K, V]> {
    return this.list[Symbol.iterator]();
  }

  public forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
    this.list.forEach(callbackfn, thisArg);
  }


}


