import {
  ApplicationPreStartEvent,
  ApplicationStartedEvent,
  ApplicationStartEvent,
} from '@application/bundles/application/events';
import { TryAutoReconnectCommand } from '@application/bundles/authentification/commands/try-autoreconnect.command';
import { CommandBus } from '@application/framework/command-query';
import { EventDispatcher, HasEventSubscribers, OnEvent } from '@application/framework/event';

@HasEventSubscribers()
export class ApplicationStartEventSubscriber {
  public constructor(
    private readonly eventDispatcher: EventDispatcher,
    private readonly commandBus: CommandBus,
  ) {}

  @OnEvent({
    event: ApplicationPreStartEvent,
    priority: Number.MAX_VALUE,
  })
  private dispatchApplicationStart() {
    this.eventDispatcher.dispatch(new ApplicationStartEvent());
  }

  @OnEvent({
    event: ApplicationStartEvent,
    priority: Number.MAX_VALUE,
  })
  private dispatchApplicationStarted() {
    this.eventDispatcher.dispatch(new ApplicationStartedEvent());
  }

  @OnEvent({
    event: ApplicationStartEvent,
    //priority: Number.MAX_VALUE
  })
  private reconnect() {
    this.commandBus.execute(new TryAutoReconnectCommand());
  }
}
