import {createAction, props} from '@ngrx/store';
import {SerializedERRDSearchParams} from "@application/bundles/eprd-errd";
import {ERRD} from "@domain/eprd-errd";


export enum ERRD_ACTION_TYPES {
  PUBLIC_LOAD = '[ERRD Admin] Load documents',
  PUBLIC_LOADED = '[ERRD Admin] Set documents',
  SET_CURRENT = '[ERRD] Set current errd',
  RESET = '[ERRD] Reset customer store',


}

const loadPublicERRDDocuments = createAction(ERRD_ACTION_TYPES.PUBLIC_LOAD, props<{ params?: SerializedERRDSearchParams }>());

const setPublicERRDDocuments = createAction(ERRD_ACTION_TYPES.PUBLIC_LOADED, props<{ documents: ERRD[] }>());

const setCurrentERRD = createAction(ERRD_ACTION_TYPES.SET_CURRENT, props<{ errd: ERRD | undefined }>());

const resetERRDStore = createAction(ERRD_ACTION_TYPES.RESET);

export const ERRDActions = Object.freeze({
  loadPublicERRDDocuments,
  setPublicERRDDocuments,
  setCurrentERRD,
  resetERRDStore
});
