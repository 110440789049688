import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {UnauthorizedAccessEvent} from "@application/bundles/authorization/events/unauthorized-access.event";
import {EventDispatcher} from "@application/framework/event";
import {
  FacilityAccessChecker
} from "@easyhpad-ui/app/bundles/facility/services/authorization-checker/facility-access-checker.service";
import {from, Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacilityManageGuard  {

  constructor(
    private readonly facilityAccessChecker: FacilityAccessChecker,
    private readonly eventDispatcher: EventDispatcher
  ) {
    this.dispatchEventIfUnauthorized = this.dispatchEventIfUnauthorized.bind(this);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.facilityAccessChecker.canAccessToAdminList()).pipe(
      tap(this.dispatchEventIfUnauthorized)
    );
  }

  private dispatchEventIfUnauthorized(authorized: boolean): void {
    if (!authorized) {
      this.eventDispatcher.dispatch(new UnauthorizedAccessEvent());
    }
  }

}
