import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {validateOrReject} from "class-validator";
import {Class} from "@application/framework/lib";

type O = Class<any>;

export const validateClassValidatorObject: ValidateFunction<O> = async (instance: O): Promise<O> => {

  await validateOrReject(instance);
  return instance;
}
