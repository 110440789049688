import {joinPath} from "@application/framework/lib";
import {Injectable} from "@angular/core";

@Injectable()
export class SupportUrlsProvider {
  public static readonly base = 'support';

  public static readonly publicSchema = {
    faq: {path: '', title: 'F.A.Q'},
  }

  constructor() {
  }

  public getPublicFAQ(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicFAQ());
  }

  public getUnsafePublicFAQ(): string {
    return joinPath('/', SupportUrlsProvider.base);
  }
}
