import {Year} from "@domain/lib";

export function getYearDateInterval(year: Year): { start: Date, end: Date } {
  const start = new Date(year, 0, 1);
  const end = new Date(year, 11, 31, 23, 59, 59, 999);

  return {start, end};
}


export function getYearDateIntervalFromDate(date: Date): { start: Date, end: Date } {
  return getYearDateInterval(date.getFullYear());
}
