import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {isSelectOption, SelectOption} from "@implementations/forms";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'ehp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor {

  @Input() public options: SelectOption[] | undefined;

  @Input() public required = false;

  @Input() public multiple: boolean = false;

  @Input() public emptyOption: boolean = false;

  @Input() public disabled: boolean = false;

  public readonly select = new FormControl<SelectOption[] | SelectOption | null | undefined>(undefined);

  public selected: any;

  public get nodeName(): string {
    return 'ehp-async-select';
  }

  public get value() {
    return this.selected;
  }

  public set value(value: any) {
    this.writeValue(value);
  }

  public compareFn = (a?: SelectOption, b?: SelectOption) => {
    return a?.value === b?.value;
  }


  /**
   * @inheritDoc
   */
  public onTouched = (): void => undefined;

  /**
   * @inheritDoc
   * @param fn
   */
  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * @inheritDoc
   * @param fn
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * @inheritDoc
   * @param isDisabled
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * @inheritDoc
   * @param value
   */
  public writeValue(value: SelectOption[] | SelectOption | string | number | null | undefined): void {

    if (value === null || value === undefined) {
      this.selected = [];
      return;
    }


    let update: SelectOption[] | SelectOption | undefined;


    if (isSelectOption(value)) {
      update = [value];
    } else if (typeof value === 'string' || typeof value === 'number') {

      if (Array.isArray(this.options)) {
        const option = this.options.find(option => option.value === value);

        if (option) {
          update = [option];
        }
      }

    } else {
      update = value;
    }


    this.selected = update;
  }

  public dispatchChange(selected: SelectOption[] | SelectOption | undefined) {

    let option: SelectOption[] | SelectOption | undefined;

    if (selected) {
      option = this.multiple ? selected : Array.isArray(selected) ? selected[0] : selected;
    }

    //this._value = option;
    this._onChange(option);
  }

  private _onChange = (_value: SelectOption[] | SelectOption | undefined): void => undefined;
}
