import { Component, Input, ViewEncapsulation } from '@angular/core';
import { HealthTaxonomyType, RecurringHealthFunding } from '@domain/health-funding';
import { FundingCollection } from '@domain/funding';

@Component({
  selector: 'ehp-recurring-funding-table',
  templateUrl: './recurring-funding-table.component.html',
  styleUrls: ['./recurring-funding-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecurringFundingTableComponent {
  @Input() public funding!: FundingCollection<RecurringHealthFunding>;

  public taxonomy: HealthTaxonomyType = 'recurring';
}
