import { LocalCPOM } from '@domain/cpom/local-cpom.interface';
import { Media } from '@domain/media';
import { CPOMService } from '@domain/cpom/services';
import { UUID } from '@domain/lib';
import { CPOMAnnex } from '@domain/cpom/cpom-annex';

export interface CPOM {
  id: UUID;

  date: Date;

  readonly year: number;

  readonly end: Date;

  children: LocalCPOM[];

  annexes: CPOMAnnex[];

  otherServices: CPOMService[];

  readonly isSingle: boolean;

  readonly singleChild: LocalCPOM | undefined;

  document(): Promise<Media>;
}
