import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { FileDepositActions, selectFileDepositEntities } from '@easyhpad-ui/app/bundles/file-deposit/store';
import { Subject, takeUntil } from 'rxjs';
import { FileDeposit } from '@domain/file-deposit';
import { FileDepositUrlProvider } from '@easyhpad-ui/app/bundles/file-deposit/providers';

@Component({
  selector: 'ehp-file-deposits-dashboard-message',
  templateUrl: './file-deposits-dashboard-message.component.html',
  styleUrls: ['./file-deposits-dashboard-message.component.scss'],
})
export class FileDepositsDashboardMessageComponent implements OnDestroy {
  public path: string | null | undefined;

  public deposits: FileDeposit[] = [];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<AppState>,
    private readonly fileDepositUrls: FileDepositUrlProvider,
  ) {
    this.fileDepositUrls.getPublicList().then(url => {
      if (!this.path && url) {
        this.path = url;
      }
    });

    this.store
      .select(selectFileDepositEntities)
      .pipe(takeUntil(this.destroy$))
      .subscribe(deposits => {
        this.setUrl(deposits);
        this.deposits = deposits;
      });

    this.store.dispatch(FileDepositActions.loadFileDeposits({}));
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private setUrl(deposits: FileDeposit[]): void {
    if (deposits.length === 1) {
      this.fileDepositUrls.getPublicShow(deposits[0].id).then(url => (this.path = url));
    } else {
      this.fileDepositUrls.getPublicList().then(url => (this.path = url));
    }
  }
}
