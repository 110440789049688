export class FinessNumber {

  private static pattern = '## ### ### #';

  public get raw() {
    return FinessNumber.asRaw(this.finess);
  }

  public get formatted() {
    return FinessNumber.format(this.finess);
  }

  constructor(private readonly finess: string) {
  }

  public static format(finess: string): string {
    const chars = FinessNumber.asRaw(finess).split('');
    let formatted = '';
    let nextChar = 0;

    for (let i = 0; i < FinessNumber.pattern.length; i++) {
      if (FinessNumber.pattern.charAt(i) === '#') {
        formatted += chars[nextChar] !== undefined ? chars[nextChar].toString() : '';
        ++nextChar;
      } else {
        formatted += FinessNumber.pattern.charAt(i);
      }
    }

    return formatted;
  }

  public static validate(finess: string): boolean {
    const test = FinessNumber.asRaw(finess);
    return new RegExp('^[0-9]*$').test(test) && test.length === 9;
  }

  private static asRaw(finess: string): string {
    return finess.replace(/\s/g, '').trim();
  }

  public toString(): string {
    return this.formatted;
  }
}
