import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GetLatestPMPCollectionQuery } from '@application/bundles/pmp/queries/get-latest-pmp-collection.query';
import { PMP } from '@domain/pmp';
import { PMPListParams, PMPRepository } from '@application/bundles/pmp';
import { ListPMPParamsImpl } from '@application/bundles/pmp/implementations';

@HandleQuery({
  query: GetLatestPMPCollectionQuery,
})
export class GetLatestPMPCollectionQueryHandler implements QueryHandler<GetLatestPMPCollectionQuery, PMP[]> {
  constructor(private readonly repository: PMPRepository) {}

  public handle(query: GetLatestPMPCollectionQuery): Promise<PMP[]> {
    let params: PMPListParams | undefined;

    if (query.facilityIds && query.facilityIds.length > 0) {
      params = new ListPMPParamsImpl().setFacilities(...query.facilityIds);
    }

    return this.repository.latest(params);
  }
}
