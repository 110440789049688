import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicStepGroup, DynamicStepperGroup } from '@easyhpad-ui/app/library/form/contracts/dynamic-repeater-field';
import { DynamicAbstractControlField, DynamicFormElementType } from '@easyhpad-ui/app/library/form/contracts';
import { values } from '@domain/lib';
import { CdkStep } from '@angular/cdk/stepper';
import { TranslatableString } from '@application/framework/translation';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ehp-dynamic-stepper',
  templateUrl: './dynamic-stepper.component.html',
  styleUrl: './dynamic-stepper.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dynamic-stepper stepper',
  },
})
export class DynamicStepperComponent implements OnInit {
  @Input() public field!: DynamicAbstractControlField<DynamicStepperGroup>;

  public steps: Array<DynamicAbstractControlField<DynamicStepGroup<any>>> = [];

  public submitLabel: string | TranslatableString = '';

  private selectedStepIndex: number = 0;

  private stepsCount: number = 0;

  private selectedCdkStep: CdkStep | undefined;

  private stepFields: Map<Record<keyof any, DynamicFormElementType<any>>, DynamicFormElementType<any>[]> = new Map();

  public get stepperControl(): FormGroup | null {
    return (this.field.control as FormGroup) ?? null;
  }

  public get isFirstStep(): boolean {
    return this.selectedStepIndex === 0;
  }

  public get isLastStep(): boolean {
    return this.selectedStepIndex + 1 >= this.stepsCount;
  }

  public get stepIsValid(): boolean {
    return !!this.selectedCdkStep?.stepControl.valid;
  }

  public ngOnInit(): void {
    this.submitLabel =
      this.field && this.field.submitLabel ? this.field.submitLabel : new TranslatableString('Soumettre');

    this.steps = values(this.field.steps) as any;
    this.stepsCount = this.steps.length;

    // Update the parent form when step subform value changes
    this.steps.forEach(step => {
      step.control.valueChanges.subscribe(() => this.stepperControl?.updateValueAndValidity());
    });
  }

  /**
   * Get the array version of fields
   * @param fields
   */
  public asArray(fields: Record<keyof any, DynamicFormElementType<any>>) {
    let array = this.stepFields.get(fields);

    if (array === undefined) {
      array = values(fields);
      this.stepFields.set(fields, array);
    }
    return array;
  }

  /**
   * Update selected step
   * @param changes
   */
  public stepChanges(changes: { step: CdkStep; index: number; total: number }) {
    this.selectedCdkStep = changes.step;
    this.selectedStepIndex = changes.index;
    this.stepsCount = changes.total;
  }

  public submit() {
    if (!this.stepperControl?.valid) {
      return;
    }

    this.field.onSubmit(this.stepperControl.value);
  }
}
