import {Component, ElementRef, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {PasswordStrengthChecker, PasswordStrengthRequirements} from "@application/bundles/user";
import {NativeChangeObserver} from "@easyhpad-ui/app/framework/native-change-observer";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {TranslatableString} from "@application/framework/translation";

@Component({
  selector: 'ehp-password-updater',
  templateUrl: './password-updater.component.html',
  styleUrls: ['./password-updater.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordUpdaterComponent),
      multi: true
    }
  ]
})
export class PasswordUpdaterComponent implements ControlValueAccessor, OnInit {

  @Input() public setDefault = true;

  @Input() public allowEmpty = false;

  public required: boolean = false;

  public hidden: boolean = false;

  public form: FormGroup<Record<'password' | 'confirm', FormControl<string | null>>>;

  public strength: number = 0;

  private _requirements: Record<PasswordStrengthRequirements, {
    label: string | TranslatableString,
    validate: boolean
  }> = {
    length: {
      label: 'Le mot de passe respect la longueur minimal.',
      validate: false
    },
    lowUpper: {
      label: "Le mot de passe contient au moins une lettre en majuscule et une en minuscule.",
      validate: false
    },
    number: {
      label: "Le mot de passe contient au moins un chiffre.",
      validate: false
    },
    specialChar: {
      label: "Le mot de passe contient un caractère spécial.",
      validate: false
    }
  }


  public get requirements() {
    return Object.values(this._requirements);
  }

  constructor(
    private readonly checker: PasswordStrengthChecker,
    private readonly formBuilder: FormBuilder,
    private readonly elementRef: ElementRef,
    private readonly nativeChanges: NativeChangeObserver,
  ) {

    this.nativeChanges.observe(this.elementRef, 'required').subscribe(change => {
      this.setRequired(coerceBooleanProperty(change.currentValue));
    });

    this.form = this.formBuilder.group({
      password: new FormControl(''),
      confirm: new FormControl('')
    }, {
      validators: [this.confirmMatchValidator.bind(this)],
    });

    this.form.valueChanges.subscribe(values => this.valuesHasChange(values));
    this.replaceLabels();
  }

  public ngOnInit() {
    if (this.setDefault) {
      const proposal = this.checker.getProposal();
      this.form.patchValue({password: proposal, confirm: proposal});
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;

    if (this.form.value.password) {
      this._onChange(this.form.value.password);
    }
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  public setRequired(isRequired: boolean) {
    this.required = isRequired;

    if (isRequired) {
      Object.values(this.form.controls).forEach(control => {
        if (!control.hasValidator(Validators.required)) {
          control.addValidators(Validators.required);
        }
      });
    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.hasValidator(Validators.required)) {
          control.removeValidators(Validators.required);
        }
      });
    }
  }

  public visibilityChange(passwordHidden: boolean) {
    this.hidden = passwordHidden;
  }

  public writeValue(password: string | undefined): void {

  }

  private valuesHasChange(values: Partial<{ password: string | null; confirm: string | null; }>) {
    
    const check = this.checker.check(values.password || '');

    for (const [type, valid] of Object.entries(check.requirements)) {
      this._requirements[type as PasswordStrengthRequirements].validate = valid;
    }

    this.strength = check.strength;

    this._onChange(this.form.valid ? values.password : null);
  }

  private _onChange = (value: string | null | undefined): void => undefined;

  private confirmMatchValidator(control: AbstractControl): ValidationErrors | null {

    const values: Partial<{ password: string | null; confirm: string | null; }> = control.value;

    if ('password' in values) {

      if (this.allowEmpty && values.password === '') {

        if ('confirm' in values) {
          return values.confirm === '' ? null : {passwordRequirement: true};
        }
        return null;
      }

      const check = this.checker.check(values.password || '');

      if (!check.valid) {
        return {passwordRequirement: true}
      }

      if ('confirm' in values && values.password !== values.confirm) {
        return {passwordConfirmationMismatch: true};
      }

    }


    return null;
  }

  private replaceLabels() {
    this._requirements.length.label = new TranslatableString(
      "Le mot de passe a une longueur minimale de {{count}} caractères.",
      {count: this.checker.minLength}
    );

    this._requirements.specialChar.label = new TranslatableString(
      `Le mot de passe contient un caractère spécial suivant : {{list}}.`,
      {list: `<span class="special-chars-list">${Array.from(this.checker.specialChar).map(c => `<span class="special-char">${c}</span>`).join('')}</span>`}
    );

  }

}
