import { Injectable } from '@angular/core';
import { joinPath } from '@application/framework/lib';

@Injectable()
export class BenchmarkingUrlProvider {
  public static readonly base = 'benchmarking';

  public static readonly publicSchema = {
    overview: { path: '', title: 'Benchmarking' },
  };

  public getPublicBenchmarkingUrl(): Promise<string> {
    return Promise.resolve(joinPath('/', BenchmarkingUrlProvider.base, BenchmarkingUrlProvider.publicSchema.overview.path));
  }
}
