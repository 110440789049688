import {Transformer} from "@application/framework/core";
import {PhoneNumber} from "@domain/phone/phone.interface";

export abstract class PhoneFromStringTransformer implements Transformer<string, PhoneNumber> {

  public abstract reverseTransform(phone: PhoneNumber): string;

  public abstract transform(value: string, region?: string): PhoneNumber;

}
