import { Transformer } from '@application/framework/core';
import {
  EasyAccommodationFunding,
  EasyCreateAccommodationFundingDto,
  EasyUpdateAccommodationFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces';
import { Common } from '@application/framework/lib';
import {
  CreateAccommodationFundingDto,
  UpdateAccommodationFundingDto,
} from '@application/bundles/accommodation-funding';
import { Service } from '@application/framework/di';
import { DateFormatter, DateParser } from '@application/framework/date';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { AccommodationFundingImpl } from '@implementations/bundles/accommodation-funding';
import { isPercentage } from '@domain/lib/math/percentage/percentage';

type CommonProperties = Omit<
  Common<AccommodationFunding, Common<CreateAccommodationFundingDto, UpdateAccommodationFundingDto>>,
  'document'
>;

type EasyCommonProperties = Common<
  EasyAccommodationFunding,
  Common<EasyCreateAccommodationFundingDto, EasyUpdateAccommodationFundingDto>
>;

@Service()
export class EasyAccommodationFundingTransformer
  implements Transformer<AccommodationFunding, EasyAccommodationFunding>
{
  constructor(
    private readonly dateFormatter: DateFormatter,
    private readonly dateParser: DateParser,
  ) {}

  public transform(value: AccommodationFunding): EasyAccommodationFunding {
    return {
      ...this.transformCommon(value),
      id: value.id,
    };
  }

  public reverseTransform(value: EasyAccommodationFunding): AccommodationFunding {
    return new AccommodationFundingImpl({
      ...this.reverseTransformCommon(value),
      pvId: value.documentId,
      reportId: value.reportId,
      id: value.id ?? '',
    });
  }

  public transformCreate(value: CreateAccommodationFundingDto): EasyCreateAccommodationFundingDto {
    return {
      ...this.transformCommon(value),
    };
  }

  public transformUpdate(value: UpdateAccommodationFundingDto): EasyUpdateAccommodationFundingDto {
    return {
      ...this.transformCommon(value),
    };
  }

  private transformCommon(value: CommonProperties): EasyCommonProperties {
    return {
      date: this.dateFormatter.toUTCIsoString(value.date),
      accountingYear: value.date.getFullYear(),
      dailyRates: value.dailyRates,
      occupationRate: value.occupationRate,
      documentId: value.pvId || '',
      reportId: value.reportId || null,
      facilityId: value.facilityId,
      permanentAmount: value.permanentAmount,
      temporaryAmount: value.temporaryAmount,
      daycareAmount: value.daycareAmount,
    };
  }

  private reverseTransformCommon(value: EasyCommonProperties): CommonProperties {
    return {
      date: this.dateParser.fromISOString(value.date),
      pvId: value.documentId,
      facilityId: value.facilityId,
      dailyRates: value.dailyRates,
      occupationRate: isPercentage(value.occupationRate) ? value.occupationRate : undefined,
      permanentAmount: value.permanentAmount,
      temporaryAmount: value.temporaryAmount,
      daycareAmount: value.daycareAmount,
    };
  }
}
