import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { ListPMPQuery, SerializedPMPSearchParams } from '@application/bundles/pmp';
import { QueryBus } from '@application/framework/command-query';
import { PMP } from '@domain/pmp';
import { DocumentListImpl } from '@easyhpad-ui/app/library/document';

@Component({
  selector: 'ehp-pmp-other-list',
  templateUrl: './pmp-other-list.component.html',
  styleUrls: ['./pmp-other-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PmpOtherListComponent implements OnChanges {
  @Input() public pmp!: PMP;

  public documents = new DocumentListImpl<PMP>();

  constructor(private readonly queryBus: QueryBus) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('pmp' in changes) {
      this.loadDocuments();
    }
  }

  private loadDocuments(): void {
    const params: SerializedPMPSearchParams = {};

    if (this.pmp && isValidFacilityIdType(this.pmp.facilityId)) {
      params.facilities = [this.pmp.facilityId];
    }

    this.queryBus.request<PMP[]>(new ListPMPQuery(params)).then(documents => {
      const id = this.pmp ? this.pmp.id : undefined;

      if (id) {
        const index = documents.findIndex(document => document.id === id);
        if (index !== -1) {
          documents.splice(index, 1);
        }

        this.documents.set(documents);
      }
    });
  }
}
