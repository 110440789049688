import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TariffOption} from "@domain/cpom";
import {Year} from "@domain/lib";
import {isValidYear} from "@application/framework/lib/functions/validators/year/year.validator";

@Component({
  selector: 'ehp-tariff-option-value',
  templateUrl: './tariff-option-value.component.html',
  styleUrls: ['./tariff-option-value.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TariffOptionValueComponent implements OnChanges {


  @Input() public option!: TariffOption;

  @Input() public year!: Year;

  @Input() public postalCode?: string;

  public amount: { value: number, isOverseas: boolean } | undefined;

  public ngOnChanges(changes: SimpleChanges): void {
    this.optionHasChange();
  }

  private optionHasChange(): void {
    this.amount = undefined;

    if (this.option && isValidYear(this.year)) {

      this.option.amounts.getForYear(this.year).then(amount => {

        if (amount !== undefined) {
          const value = this.postalCode ? amount.value(this.postalCode) : {value: amount.mainland, type: "mainland"};
          this.amount = {value: value.value, isOverseas: value.type === "overseas"}
        }

      });
    }
  }
}
