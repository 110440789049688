import {
  CreateEhpadDemainCommandHandler,
  DeleteEhpadDemainCommandHandler,
  UpdateEhpadDemainCommandHandler
} from "@application/bundles/ehpad-demain/commands/handlers";
import {
  OnEhpadDemainCreationEventSubscriber,
  OnEhpadDemainDeletionEventSubscriber,
  OnEhpadDemainUpdateEventSubscriber
} from "@application/bundles/ehpad-demain/events/subscribers";
import {
  GetEhpadDemainQueryHandler,
  ListEhpadDemainQueryHandler
} from "@application/bundles/ehpad-demain/queries/handlers";
import {Bundle} from "@application/framework/bundle";

@Bundle({

  commandHandlers: [
    CreateEhpadDemainCommandHandler,
    UpdateEhpadDemainCommandHandler,
    DeleteEhpadDemainCommandHandler
  ],
  queryHandlers: [
    ListEhpadDemainQueryHandler,
    GetEhpadDemainQueryHandler,
  ],
  eventSubscribers: [
    OnEhpadDemainCreationEventSubscriber,
    OnEhpadDemainUpdateEventSubscriber,
    OnEhpadDemainDeletionEventSubscriber
  ]
})
export class EhpadDemainBundle {

  constructor() {
  }
}
