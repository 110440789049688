import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Common, EMPTY_VALUE } from '@application/framework/lib';
import { CreateUser, UpdateUser, User } from '@domain/user';
import { UniqueUserEmailValidator } from '@easyhpad-ui/app/bundles/user/validators/unique-email/unique-email.validator';
import { InitialTrainingSelectOptionTransformer } from '@implementations/bundles/user/transformers/initial-training-select-option/initial-training-select-option.transformer';
import { JobSelectOptionTransformer } from '@implementations/bundles/user/transformers/job-select-option/job-select-option.transformer';
import { StudyLevelSelectOptionTransformer } from '@implementations/bundles/user/transformers/study-level-select-option/study-level-select-option.transformer';
import { includeAdminRole, Role } from '@domain/authorization';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { isMaybeACustomer, isValidCustomerIdType } from '@application/bundles/customer';
import { isValidFacilityIdType } from '@application/bundles/facility';

type CommonDtoPropertiesKeys = keyof Omit<Common<CreateUser, UpdateUser>, 'displayName'>;

@Injectable({
  providedIn: 'root',
})
export class UserFormBuilder {
  public static readonly CUSTOMER_CONTROL_NAME: keyof Pick<User, 'customerId'> = 'customerId';

  public static readonly FACILITIES_CONTROL_NAME: keyof Pick<User, 'facilityIds'> = 'facilityIds';

  private transformers = {
    job: new JobSelectOptionTransformer(),
    training: new InitialTrainingSelectOptionTransformer(),
    studyLevel: new StudyLevelSelectOptionTransformer(),
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly noticeStream: NoticeStream,
  ) {}

  public getCreationForm(
    user: User | undefined,
    options: {
      uniqueEmailValidator: UniqueUserEmailValidator;
    },
  ): FormGroup<Record<keyof Omit<CreateUser, 'displayName'>, AbstractControl>> {
    return this.formBuilder.group(this.getCommonControls(user, options));
  }

  public getUpdateForm(
    user: User | undefined,
  ): FormGroup<Record<keyof Omit<UpdateUser, 'id' | 'displayName'>, AbstractControl>> {
    const controls: Record<keyof Omit<UpdateUser, 'id' | 'displayName'>, AbstractControl> = {
      ...this.getCommonControls(user),
    };

    return this.formBuilder.group(controls);
  }

  public serializeUser(user: User): Record<keyof User, any> {
    const userValues = {} as Record<keyof User, any>;

    Object.keys(user).map((p) => {
      userValues[p as keyof User] = user[p as keyof User];

      if (userValues.job && userValues.job.id) {
        userValues.job = this.transformers.job.transform(userValues.job);
      }

      if (userValues.initialTraining && userValues.initialTraining.id) {
        userValues.initialTraining = this.transformers.training.transform(userValues.initialTraining);
      }

      if (userValues.studyLevel && userValues.studyLevel.id) {
        userValues.studyLevel = this.transformers.studyLevel.transform(userValues.studyLevel);
      }
    });

    return userValues;
  }

  public deserializeValues<T extends CreateUser | UpdateUser>(
    values: Partial<Record<CommonDtoPropertiesKeys, any>>,
  ): T {
    const result = { facilityIds: [] } as Record<CommonDtoPropertiesKeys, any>;

    Object.keys(values).map((key) => {
      switch (key as CommonDtoPropertiesKeys) {
        case 'job':
          if (values['job']) {
            result.job = this.transformers.job.reverseTransform(values['job']);
          }
          break;

        case 'initialTraining':
          if (values['initialTraining']) {
            result.initialTraining = this.transformers.training.reverseTransform(values['initialTraining']);
          }
          break;

        case 'studyLevel':
          if (values['studyLevel']) {
            result.studyLevel = this.transformers.job.reverseTransform(values['studyLevel']);
          }
          break;

        case 'customerId':
          if (isValidCustomerIdType(values['customerId'])) {
            result.customerId = values['customerId'];
          } else if (isMaybeACustomer(values['customerId'])) {
            result.customerId = values['customerId'].id;
          }
          break;

        case 'facilityIds':
          if (!Array.isArray(values['facilityIds'])) {
            result.facilityIds = [];
          } else {
            result.facilityIds = values.facilityIds.map((facility) =>
              isValidFacilityIdType(facility) ? facility : facility.id,
            );
          }
          break;

        default:
          result[key as 'firstname'] = values[key as 'firstname'];
          break;
      }
    });

    return result as T;
  }

  public rebuildFormValues(form: FormGroup, user: User) {
    const values = this.serializeUser(user);
    form.patchValue(values);
  }

  private getCommonControls(
    user: User | undefined,
    options?: {
      uniqueEmailValidator: UniqueUserEmailValidator;
    },
  ): Record<CommonDtoPropertiesKeys, AbstractControl> {
    const controls = {
      firstname: new FormControl(user?.firstname, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),

      lastname: new FormControl(user?.lastname, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),

      email: new FormControl(user?.email, {
        validators: [Validators.email, Validators.required],
        asyncValidators: options?.uniqueEmailValidator ? [options.uniqueEmailValidator.validate] : [],
        updateOn: 'blur',
      }),

      roles: new FormControl(user?.roles, [Validators.required]),

      job: new FormControl(user?.job ? this.transformers.job.transform(user.job) : null, []),

      initialTraining: new FormControl(
        user?.initialTraining ? this.transformers.training.transform(user.initialTraining) : null,
        [],
      ),

      studyLevel: new FormControl(
        user?.studyLevel ? this.transformers.studyLevel.transform(user.studyLevel) : null,
        [],
      ),

      blocked: new FormControl(!!(user && user.blocked)),

      customerId: new FormControl(user?.customerId, [Validators.required]),
      facilityIds: new FormControl(user?.facilityIds),
    };

    this.setWatchers(controls);
    return controls;
  }

  private setWatchers(controls: Record<CommonDtoPropertiesKeys, AbstractControl>): void {
    let customer: any = EMPTY_VALUE;

    let hasAdminRole = false;

    controls.roles.valueChanges.subscribe((roles: Role[] | undefined) => {
      if (!roles) {
        return;
      }

      if (includeAdminRole(roles)) {
        if (hasAdminRole) {
          return;
        }

        const message =
          "Vous êtes sur le point de créer un nouvel administrateur. N'attribuez ce rôle qu'à des utilisateurs de confiance.";
        this.noticeStream.push({ message, type: NoticeType.WARNING, dismiss: 0 });

        customer = controls.customerId.value;

        if (controls.customerId) {
          controls.customerId?.setValue(undefined);
          controls.customerId?.disable();

          if (controls.customerId.hasValidator(Validators.required)) {
            controls.customerId.removeValidators(Validators.required);
          }
        }

        controls.facilityIds?.disable({ emitEvent: true });

        hasAdminRole = true;
      } else if (hasAdminRole) {
        if (controls.customerId) {
          if (customer !== EMPTY_VALUE) {
            controls.customerId?.setValue(customer);
          }

          if (!controls.customerId.hasValidator(Validators.required)) {
            controls.customerId.addValidators(Validators.required);
          }

          controls.customerId?.enable({ emitEvent: true });
        }

        controls.facilityIds?.enable();
        hasAdminRole = false;
      }
    });
  }
}
