import { Facility } from '@domain/facility';
import { UUID } from '@domain/lib';
import { Media } from '@domain/media';

export interface PMP {
  id: UUID;

  date: Date;

  value: number;

  pvId?: Media['id'];

  facilityId: Facility['id'];

  createdAt?: Date;

  updatedAt?: Date;
}
