<ng-container *ngIf="pmp">

  <h3 class="title item-title m0">

    <span class="icon ehp-icon icon-file"></span>

    <ehp-pmp-link [pmp]="pmp"></ehp-pmp-link>
  </h3>

  <div class="date">{{pmp.date | date:'dd/MM/YYYY'}}</div>

  <div class="amount">
    {{pmp.value}}
  </div>

  <div class="national">
    <ng-container *ngIf="national">{{national.value}}</ng-container>
  </div>


  <div class="facility">
    <ehp-facility-link *ngIf="pmp" [fid]="pmp.facilityId"></ehp-facility-link>
  </div>

  <div class="actions-list actions">

    <ehp-pmp-link
      [pmp]="pmp"
      class="is-icon icon-arrow ghost button"
      pTooltip="{{'Voir' | ehp_translate}}"
      tooltipPosition="bottom"
    >
      <span class="label">{{'Consulter le G.M.P.' | ehp_translate }}</span>
    </ehp-pmp-link>


  </div>

</ng-container>
