import {FACILITY_TRANSLATE_CONTEXT} from "@application/bundles/facility";
import {FacilityDeletedEvent} from "@application/bundles/facility/events/delete-facility/facility-deleted.event";
import {
  FacilityDeletionFailEvent
} from "@application/bundles/facility/events/delete-facility/facility-deletion-fail.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";

@HasEventSubscribers()
export class OnFacilityDeletionSubscriber {

  constructor(
    private readonly noticeStream: NoticeStream,
    private readonly translator: Translator
  ) {
  }


  @OnEvent({
    event: FacilityDeletedEvent
  })
  public async pushNoticeOnFacilityDeletionSuccess(event: FacilityDeletedEvent): Promise<void> {

    const message = await this.translator.translate(
      "L'établissement <strong>{{name}}</strong> a été supprimé avec succès.",
      {name: event.facility.name},
      FACILITY_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({message, type: NoticeType.SUCCESS});
  }

  @OnEvent({
    event: FacilityDeletionFailEvent
  })
  public async pushNoticeOnFacilityDeletionFail(event: FacilityDeletionFailEvent): Promise<void> {

    let message = event.reason;

    if (!message) {
      message = await this.translator.translate(
        "Une erreur est survenue lors de la suppression de l'établissement.",
        undefined,
        FACILITY_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({message, type: NoticeType.ERROR});
  }
}
