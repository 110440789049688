import { CreateCPOMDto, UpdateCPOMDto } from '@application/bundles/cpom';
import { Common } from '@application/framework/lib';
import { IsArray, IsDate } from 'class-validator';
import { CPOMService } from '@domain/cpom/services';

type CommonProperties = Omit<Common<CreateCPOMDto, UpdateCPOMDto>, 'children' | 'document'>;

export abstract class CPOMCommonDtoAbstract implements CommonProperties {
  @IsDate()
  public date!: Date;

  @IsArray()
  public otherServices: CPOMService[] = [];

  public get year(): number {
    return this.date.getFullYear();
  }

  constructor(values?: Partial<CommonProperties>) {
    if (values) {
      if (values.date instanceof Date) {
        this.date = values.date;
      }

      if (Array.isArray(values.otherServices)) {
        this.otherServices = values.otherServices;
      }
    }
  }
}
