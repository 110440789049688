import { AbstractControl } from '@angular/forms';
import { ArgumentsType } from '@domain/lib';

export function syncControlsDirty(source: AbstractControl, destination: AbstractControl): void {
  const initial = {
    markSourceAsPristine: source.markAsPristine.bind(source),
    markSourceAsDirty: source.markAsDirty.bind(source),
    markDestinationAsPristine: destination.markAsPristine.bind(destination),
    markDestinationAsDirty: destination.markAsDirty.bind(destination),
  };

  function markAsPristine(...args: ArgumentsType<AbstractControl['markAsPristine']>) {
    initial.markSourceAsPristine(...args);
    initial.markDestinationAsPristine(...args);
  }

  function markAsDirty(...args: ArgumentsType<AbstractControl['markAsDirty']>) {
    initial.markSourceAsDirty(...args);
    initial.markDestinationAsDirty(...args);
  }

  source.markAsPristine = markAsPristine;
  source.markAsDirty = markAsDirty;

  destination.markAsPristine = markAsPristine;
  destination.markAsDirty = markAsDirty;
}
