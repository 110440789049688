import {
  CreateCPOMDto,
  CreateCPOMFundingDto,
  CreateLocalCPOMDto,
  UpdateCPOMDto,
  UpdateCPOMFundingDto,
  UpdateLocalCPOMDto
} from "@application/bundles/cpom";

export abstract class CPOMDtoFactory {

  public abstract buildCreateCPOMDtoFromUnsafeValues(values: Partial<CreateCPOMDto>): CreateCPOMDto;

  public abstract buildUpdateCPOMDtoFromUnsafeValues(values: Partial<UpdateCPOMDto>): UpdateCPOMDto;

  public abstract buildCreateLocalCPOMDtoFromUnsafeValues(values: Partial<CreateLocalCPOMDto>): CreateLocalCPOMDto;

  public abstract buildUpdateLocalCPOMDtoFromUnsafeValues(values: Partial<UpdateLocalCPOMDto>): UpdateLocalCPOMDto;

  public abstract buildCreateCPOMFundingDtoFromUnsafeValues(values: Partial<CreateCPOMFundingDto>): CreateCPOMFundingDto;

  public abstract buildUpdateCPOMFundingDtoFromUnsafeValues(values: Partial<UpdateCPOMFundingDto>): UpdateCPOMFundingDto;
}
