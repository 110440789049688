import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CssUnitConverter } from '@application/framework/converter/css-unit.converter';
import { POSITION } from '@application/framework/lib';
import { LocalMedia, Media } from '@domain/media';
import { PreviewHostDirective } from '@easyhpad-ui/app/bundles/media/directives/preview-host/preview-host.directive';
import { MediaPreviewCommand, MediaPreviewList, MediaViewerChanges } from '@easyhpad-ui/app/bundles/media/interfaces';
import { MediaViewerMode } from '@easyhpad-ui/app/bundles/media/interfaces/media-viewer-options.interface';
import {
  MEDIA_PREVIEW_FEATURE,
  PreviewComponentInterface,
} from '@easyhpad-ui/app/bundles/media/interfaces/preview-component.interface';
import { PreviewComponentResolver } from '@easyhpad-ui/app/bundles/media/services/preview-component-resolver/preview-component.resolver';
import { WINDOW } from '@easyhpad-ui/app/framework/window';
import { ResizableGrabberComponent } from '@easyhpad-ui/app/library/resizable/components/resizable-grabber/resizable-grabber.component';
import { ResizableGrabberEvent } from '@easyhpad-ui/app/library/resizable/resizable-grabber.position';
import { Observable, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-medias-viewer',
  templateUrl: './medias-viewer.component.html',
  styleUrls: ['./medias-viewer.component.scss'],
  host: {
    '[class]': 'classes',
    '[class.is-fullscreen]': 'fullscreenState.isFullscreen',
  },
})
export class MediasViewerComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() public medias!: MediaPreviewList<Media | LocalMedia>;

  @Input() public mode: MediaViewerMode = MediaViewerMode.PANEL;

  @Input() public position: POSITION = POSITION.RIGHT;

  @Input() public supportPositions: POSITION[] | undefined;

  @Input() public closable: boolean = false;

  @Input() public fullscreen: boolean = false;

  @Output() public modeChange: EventEmitter<MediaViewerMode> = new EventEmitter();

  @HostBinding('style.width.px') public width!: number;

  @ViewChild(PreviewHostDirective, { static: true }) previewHost!: PreviewHostDirective;

  @ViewChild(ResizableGrabberComponent, { static: true }) public grabber!: ResizableGrabberComponent;

  public title: string = '';

  public POSITION = POSITION;

  public isHorizontalPosition: boolean = false;

  public mediaListIsOpen: boolean = false;

  public fullscreenState: {
    width: number | undefined;
    height: number | undefined;
    isFullscreen: boolean;
    tooltip: string;
    tooltipPositon: POSITION;
  } = {
    width: undefined,
    height: undefined,
    isFullscreen: false,
    tooltipPositon: POSITION.LEFT,
    tooltip: 'Ouvrir le mode plein écran',
  };

  private previewRef?: ComponentRef<PreviewComponentInterface>;

  private grabberPositions: Map<POSITION, POSITION> = new Map([
    [POSITION.LEFT, POSITION.RIGHT],
    [POSITION.RIGHT, POSITION.LEFT],
    [POSITION.BOTTOM, POSITION.TOP],
    [POSITION.TOP, POSITION.BOTTOM],
  ]);

  private originalSize: { width: number; height: number } = { width: 0, height: 0 };

  private minimalSize: { width: number; height: number } = { width: 0, height: 0 };

  private originalPosition: { x: number; y: number } = { x: 0, y: 0 };

  private subscription: Subscription | undefined;

  private needToBeDestroy$: EventEmitter<void> = new EventEmitter();

  public get classes(): string {
    const c = [
      this.mode ? `mode-${this.mode}` : 'mode-panel',
      this.position ? `position-${this.position}` : 'position-right',
    ];

    if (!this.medias || this.medias.size === 0) {
      c.push('is-empty');
    }

    return c.join(' ');
  }

  public get count(): number {
    return this.medias ? this.medias.size : 0;
  }

  public get grabberPosition(): POSITION {
    const position = this.grabberPositions.get(this.position);
    return position ? position : POSITION.LEFT;
  }

  public get destroyed(): Observable<void> {
    return this.needToBeDestroy$.asObservable();
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly window: Window,
    private readonly resolver: PreviewComponentResolver,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly converter: CssUnitConverter,
  ) {}

  public ngOnInit() {
    this.setSizesAndPositions();
    this.setMinimalSize();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['medias']) {
      this.unsubscribe();

      if (changes['medias'].currentValue) {
        this.subscription = this.medias.current.pipe(takeUntil(this.needToBeDestroy$)).subscribe(media => {
          media ? this.open(media) : this.close();
        });
      }
    }

    if (changes['position']) {
      this.setSizesAndPositions();
      this.isHorizontalPosition = this.position === POSITION.TOP || this.position === POSITION.BOTTOM;
    }
  }

  public ngAfterViewInit() {
    this.setSizesAndPositions();
    this.setMinimalSize();
  }

  public ngOnDestroy() {
    this.document.documentElement.style.removeProperty('--ehp--media-viewer--width');
    this.document.documentElement.style.removeProperty('--ehp--media-viewer--height');
    this.unsubscribe();
  }

  public selfDestroy() {
    this.needToBeDestroy$.emit();
  }

  public open(media: Media | LocalMedia) {
    this.close();
    this.title = media.name;
    this.previewRef = this.resolver.inject(media, this.previewHost.viewContainerRef);
  }

  public close(): void {
    this.previewRef = undefined;

    if (this.previewHost) {
      this.previewHost.viewContainerRef.clear();
    }
  }

  public execute(command: MediaPreviewCommand<Media | LocalMedia>): void {
    if (command.action === 'open' && command.media) {
      this.open(command.media);
    } else if (command.action === 'close') {
      if (command.media) {
        if (command.media === this.previewRef?.instance.media) {
          this.close();
        }
      } else {
        this.close();
      }
    }
  }

  /**
   * Check if instance support feature
   * @param feature
   */
  public support(feature: MEDIA_PREVIEW_FEATURE): boolean {
    if (!this.previewRef?.instance) {
      return false;
    }

    return this.previewRef.instance.supports.has(feature);
  }

  public zoomIn(): void {
    if (!this.previewRef || !this.previewRef.instance.supports.has('zoom')) {
      return;
    }

    if (this.previewRef.instance.zoomIn) {
      this.previewRef.instance.zoomIn();
    }
  }

  public zoomOut(): void {
    if (!this.previewRef || !this.previewRef.instance.supports.has('zoom')) {
      return;
    }

    if (this.previewRef.instance.zoomOut) {
      this.previewRef.instance.zoomOut();
    }
  }

  public rotateLeft(): void {
    if (!this.previewRef || !this.previewRef.instance.supports.has('rotate')) {
      return;
    }

    if (this.previewRef.instance.rotateLeft) {
      this.previewRef.instance.rotateLeft();
    }
  }

  public rotateRight(): void {
    if (!this.previewRef || !this.previewRef.instance.supports.has('rotate')) {
      return;
    }

    if (this.previewRef.instance.rotateRight) {
      this.previewRef.instance.rotateRight();
    }
  }

  public switchMode(): void {
    this.mode = this.mode !== MediaViewerMode.PANEL ? MediaViewerMode.PANEL : MediaViewerMode.DIALOG;
    this.modeChange.emit(this.mode);
  }

  public toggleFullscreen() {
    if (!this.fullscreen) {
      return;
    }

    const fullscreen = !this.fullscreenState.isFullscreen;

    if (fullscreen) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.elementRef.nativeElement, 'inset', '0');
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.elementRef.nativeElement, 'height', '100%');
    } else {
      this.renderer.removeStyle(this.elementRef.nativeElement, 'position');
      this.renderer.removeStyle(this.elementRef.nativeElement, 'inset');
      this.renderer.removeStyle(this.elementRef.nativeElement, 'width');
      this.renderer.removeStyle(this.elementRef.nativeElement, 'height');
    }

    this.dispatchChange({
      resize: {
        width: this.elementRef.nativeElement.width,
        height: this.elementRef.nativeElement.height,
      },
    });

    this.fullscreenState = {
      ...this.fullscreenState,
      isFullscreen: fullscreen,
      tooltip: fullscreen ? 'Quitter le mode plein écran' : 'Ouvrir le mode plein écran',
    };
  }

  public onGrab(): void {
    this.setSizesAndPositions();
  }

  public onMove(change: ResizableGrabberEvent) {
    if (this.position === POSITION.LEFT || this.position === POSITION.RIGHT) {
      const mw = this.position === POSITION.RIGHT ? change.move.x : change.move.x * -1;
      const width = this.originalSize.width - mw;

      if (width >= this.minimalSize.width && width <= this.window.innerWidth) {
        this.setWidth(width);
      }
    }

    if (this.position === POSITION.TOP || this.position === POSITION.BOTTOM) {
      const mh = this.position === POSITION.BOTTOM ? change.move.y : change.move.y * -1;
      const height = this.originalSize.height - mh;

      if (height >= this.minimalSize.height && height <= this.window.innerHeight) {
        this.setHeight(height);
      }
    }
  }

  public toggleMediaList(): void {
    if (!this.medias || this.medias.size <= 1) {
      this.mediaListIsOpen = false;
    }

    this.mediaListIsOpen = !this.mediaListIsOpen;
  }

  private setSizesAndPositions(): void {
    if (this.elementRef.nativeElement.getBoundingClientRect()) {
      this.originalPosition = {
        x: this.elementRef.nativeElement.getBoundingClientRect().left,
        y: this.elementRef.nativeElement.getBoundingClientRect().top,
      };

      this.originalSize = {
        width: this.elementRef.nativeElement.getBoundingClientRect().width,
        height: this.elementRef.nativeElement.getBoundingClientRect().height,
      };

      document.documentElement.style.setProperty(
        '--ehp--media-viewer--width',
        this.getWidthRelativeToViewport(this.originalSize.width),
      );
      document.documentElement.style.setProperty(
        '--ehp--media-viewer--height',
        this.getHeightRelativeToViewport(this.originalSize.height),
      );
    }
  }

  private setMinimalSize() {
    const width = this.converter.toPx(this.getHostStylePropertyValue('min-width')) || 0;
    const height = this.converter.toPx(this.getHostStylePropertyValue('min-height')) || 0;

    this.minimalSize = { width, height };
  }

  private setWidth(width: number): void {
    const w = this.getWidthRelativeToViewport(width);
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', w);
    this.document.documentElement.style.setProperty('--ehp--media-viewer--width', w);
    this.dispatchChange({ resize: { width, height: this.elementRef.nativeElement.height } });
  }

  private setHeight(height: number): void {
    const h = this.getHeightRelativeToViewport(height);
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', h);
    this.document.documentElement.style.setProperty('--ehp--media-viewer--height', h);
    this.dispatchChange({ resize: { width: this.elementRef.nativeElement.width, height } });
  }

  private getWidthRelativeToViewport(width: number): string {
    return this.converter.toVw(`${width}px`) + 'vw';
  }

  private getHeightRelativeToViewport(height: number): string {
    return this.converter.toVh(`${height}px`) + 'vh';
  }

  private getHostStylePropertyValue(property: 'min-width' | 'min-height'): string {
    return this.window.getComputedStyle(this.elementRef.nativeElement, null).getPropertyValue(property);
  }

  private dispatchChange(changes: MediaViewerChanges) {
    if (this.previewRef?.instance.parentChange) {
      this.previewRef.instance.parentChange(changes);
    }
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
