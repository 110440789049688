import { isValidCPOMFundingIdType, StoredCPOMFunding } from '@application/bundles/cpom';

export class CPOMFundingImpl implements StoredCPOMFunding {
  id!: StoredCPOMFunding['id'];

  name!: string;

  amount!: number;

  comment: string | undefined;

  constructor(values: Partial<StoredCPOMFunding>) {
    if (values) {
      if (isValidCPOMFundingIdType(values.id)) {
        this.id = values.id;
      }

      if (values.name) {
        this.name = values.name;
      }

      if (typeof values.amount === 'number') {
        this.amount = values.amount;
      }

      if (values.comment) {
        this.comment = values.comment;
      }
    }
  }
}
