import {Logger, LogLevel} from "@application/framework/logger";

export class NullLogger implements Logger {

  public readonly level = LogLevel.SILENT;

  public channel(name: string): Logger {
    return this;
  }

  public info(message: string, ...args: any[]): void {
  }

  public debug(message: string, ...args: any[]): void {
  }

  public warning(message: string, ...args: any[]): void {
  }

  public error(message: string, ...args: any[]): void {
  }
}
