import {validateOrReject} from "class-validator";
import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {
  CreateDaycareDependencyFundingDto,
  CreateDependencyFundingDto,
  CreatePermanentDependencyFundingDto,
  CreateTemporaryDependencyFundingDto,
  UpdateDaycareDependencyFundingDto,
  UpdateDependencyFundingDto,
  UpdatePermanentDependencyFundingDto,
  UpdateTemporaryDependencyFundingDto
} from "@application/bundles/dependency-funding";


type DTO =
  CreateDependencyFundingDto
  | UpdateDependencyFundingDto
  | CreatePermanentDependencyFundingDto
  | UpdatePermanentDependencyFundingDto
  | CreateTemporaryDependencyFundingDto<any, unknown>
  | UpdateTemporaryDependencyFundingDto<any, unknown>
  | CreateDaycareDependencyFundingDto<any, unknown>
  | UpdateDaycareDependencyFundingDto<any, unknown>;


export const validateDependencyFundingDto: ValidateFunction<DTO> = async <T extends DTO>(dto: T): Promise<T> => {

  await validateOrReject(dto);

  return dto;
}
