import { FinancialAnnex } from '@domain/eprd-errd';
import { isValidFundingAmount } from '@domain/funding';
import { roundFunding } from '@domain/funding/round-funding/round-funding';

export class FinancialAnnexImpl implements FinancialAnnex {
  public permanentRevenue: number = 0;

  public dependencyExpense: number = 0;

  public healthRevenue: number = 0;

  public permanentExpense: number = 0;

  public dependencyRevenue: number = 0;

  public healthExpense: number = 0;

  public accommodationEmployeesExpense?: number;

  public healthEmployeesExpense?: number;

  public dependencyEmployeesExpense?: number;

  constructor(
    defaults?: Partial<
      Omit<FinancialAnnex, 'permanentSubTotal' | 'dependencySubTotal' | 'healthSubTotal' | 'revenue' | 'expense'>
    >,
  ) {
    if (defaults) {
      const keys: Array<
        keyof Omit<
          FinancialAnnex,
          'permanentSubTotal' | 'dependencySubTotal' | 'healthSubTotal' | 'revenue' | 'expense'
        >
      > = [
        'permanentRevenue',
        'healthRevenue',
        'dependencyRevenue',
        'permanentExpense',
        'healthExpense',
        'dependencyExpense',
        'accommodationEmployeesExpense',
        'healthEmployeesExpense',
        'dependencyEmployeesExpense',
      ];

      for (const key of keys) {
        if (isValidFundingAmount(defaults[key])) {
          this[key] = defaults[key] as number;
        }
      }
    }
  }

  public permanentSubTotal(): number {
    return (this.permanentRevenue ?? 0) - (this.permanentExpense ?? 0);
  }

  public dependencySubTotal(): number {
    return (this.dependencyRevenue ?? 0) - (this.dependencyExpense ?? 0);
  }

  public healthSubTotal(): number {
    return (this.healthRevenue ?? 0) - (this.healthExpense ?? 0);
  }

  public revenue(): number {
    return roundFunding((this.permanentRevenue ?? 0) + (this.dependencyRevenue ?? 0) + (this.healthRevenue ?? 0));
  }

  public expense(): number {
    return roundFunding((this.permanentExpense ?? 0) + (this.dependencyExpense ?? 0) + (this.healthExpense ?? 0));
  }

  private employeesExpense(): number {
    return roundFunding(
      (this.accommodationEmployeesExpense ?? 0) +
        (this.dependencyEmployeesExpense ?? 0) +
        (this.healthEmployeesExpense ?? 0),
    );
  }
}
