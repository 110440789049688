import {DocumentListParams, SerializedDocumentParams} from "@application/framework/search";
import {Serializable} from "@application/framework/lib";
import {Year} from "@domain/lib";


export interface DependencyFundingDocumentParams {
  accountingYear?: Year;
}

export interface SerializedDependencyFundingSearchParams extends SerializedDocumentParams<DependencyFundingDocumentParams> {
  
}

export abstract class DependencyFundingSearchParams extends DocumentListParams implements Serializable<SerializedDependencyFundingSearchParams> {

  public abstract setAccountingYear(year: Year | undefined): this;

  public abstract override serialize(): SerializedDependencyFundingSearchParams;

  public abstract override deserialize(params?: SerializedDependencyFundingSearchParams): this;

}



