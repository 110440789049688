import { EhpadDemainListParams } from '@application/bundles/ehpad-demain';
import { DocumentListParamsImpl } from '@application/framework/search';
import { SORT_ORDER } from '@application/framework/search/sort';

export class AgnosticEhpadDemainSearchParams extends DocumentListParamsImpl implements EhpadDemainListParams {
  constructor() {
    super();
    this.setSort({ key: 'start', order: SORT_ORDER.DESC });
  }
}
