import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RequestParams } from '@application/framework/http/request.interface';
import { OccupationRateWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';
import { GetOccupationRateWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-occupation-rate-widget.query';
import { EasyOccupationRateWidget } from '@implementations/bundles/backend/easyhpad/interfaces/widgets';

@HandleQuery({
  query: GetOccupationRateWidgetQuery,
})
export class GetOccupationRateWidgetQueryHandler
  implements QueryHandler<GetOccupationRateWidgetQuery, OccupationRateWidgetContent>
{
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: GetOccupationRateWidgetQuery): Promise<OccupationRateWidgetContent> {
    const params: RequestParams = {};

    if (Array.isArray(query.facilities) && query.facilities.length > 0) {
      params['facility_ids'] = query.facilities.join(', ');
    }

    const response = await this.backend.get<EasyOccupationRateWidget>('/widgets/occupation-rate', params);
    return this.transform(response.body);
  }

  private transform(value: EasyOccupationRateWidget): OccupationRateWidgetContent {
    return {
      value: value.value || 0,
      facilityId: value.facility_id,
      id: value.id,
      average: value.average,
    };
  }
}
