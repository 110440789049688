import {EhpadDemain} from "@domain/ehpad-demain";
import {EntityState} from "@ngrx/entity";

export interface EhpadDemainStoreState {
  current?: EhpadDemain | undefined,
  list: EntityState<EhpadDemain> & {
    selected?: EhpadDemain['id'] | null;
    loading: boolean,
    loaded: boolean
  }
}

