import { Component, Input, OnInit } from '@angular/core';
import { isValidTaxonomyTermIdType, TaxonomyTerm } from '@domain/taxonomy';
import { HealthTaxonomyType, isHealthTaxonomyType } from '@domain/health-funding';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { Observable } from 'rxjs';
import { healthFundingActions, healthFundingSelectors } from '@easyhpad-ui/app/bundles/health-funding/store';

@Component({
  selector: 'ehp-health-taxonomy-label',
  templateUrl: './health-taxonomy-label.component.html',
})
export class HealthTaxonomyLabelComponent implements OnInit {
  @Input() public tid!: TaxonomyTerm['id'];

  @Input() public taxonomy!: HealthTaxonomyType;

  public term$: Observable<TaxonomyTerm | undefined> | undefined;

  constructor(private readonly store: Store<AppState>) {}

  public ngOnInit() {
    if (isValidTaxonomyTermIdType(this.tid) && isHealthTaxonomyType(this.taxonomy)) {
      this.term$ = this.store.select(healthFundingSelectors.selectTaxonomyTerm(this.taxonomy, this.tid));
      this.store.dispatch(healthFundingActions.loadTerm({ taxonomy: this.taxonomy, id: this.tid }));
    }
  }
}
