import {CreateFacilityDto, UpdateFacilityDto} from "@application/bundles/facility/dto";
import {FacilityRepository} from "@domain/facility/facility.repository";
import {
  EasyFacilityCreateDtoTransformer
} from "@implementations/bundles/backend/easyhpad/transformers/facility/easy-facility-create-dto/easy-facility-create-dto.transformer";
import {
  EasyFacilityUpdateDtoTransformer
} from "@implementations/bundles/backend/easyhpad/transformers/facility/easy-facility-update-dto/easy-facility-update-dto.transformer";
import {
  EasyFacilityTransformer
} from "@implementations/bundles/backend/easyhpad/transformers/facility/easy-facility.transformer";
import {Provide} from "@application/framework/di";
import {FacilitySearchParams} from "@application/bundles/facility/facility-search-params";
import {RequestParams} from "@application/framework/http/request.interface";
import {EasyEntityRepository} from "@implementations/bundles/backend/easyhpad/repositories/base";
import {Facility} from "@domain/facility";
import {EasyFacility} from "@implementations/bundles/backend/easyhpad";

export class EasyFacilityRepository extends EasyEntityRepository<Facility, EasyFacility, Facility['id'], FacilitySearchParams> implements FacilityRepository {

  @Provide() private readonly entityTransformer!: EasyFacilityTransformer;
  @Provide() private readonly createTransformer!: EasyFacilityCreateDtoTransformer;
  @Provide() private readonly updateTransformer!: EasyFacilityUpdateDtoTransformer;


  constructor() {
    super('facility', {
      from: (input) => this.entityTransformer.transform(input),
      to: (output) => this.entityTransformer.reverseTransform(output),
      create: (input: CreateFacilityDto) => this.createTransformer.transform(input),
      update: (input: UpdateFacilityDto) => this.updateTransformer.transform(input),
    });
  }

  protected override convertParams(params?: FacilitySearchParams): RequestParams {
    if (params === undefined) {
      return {};
    }

    return this.searchTransformer.toRequestParams(
      this.searchTransformer.fromSerializedFacilitesSearchParams(params.serialize())
    );


  }

}
