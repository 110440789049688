import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CPOMRepository,
  CPOMUpdatedEvent,
  CPOMUpdateFailEvent,
  GetCPOMQuery,
  isValidCPOMIdType,
  UpdateCPOMCommand,
  UpdateCPOMDto,
} from '@application/bundles/cpom';
import { CPOMAuthorizationChecker } from '@application/bundles/cpom/cpom-authorization-checker';
import { CPOM_TRANSLATE_CONTEXT } from '@application/bundles/cpom/cpom.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { CPOM } from '@domain/cpom';
import { isLocalMedia, isMedia, MediaBucket, TemporaryMedia } from '@domain/media';
import { CPOMAnnex } from '@domain/cpom/cpom-annex';
import { CreateCPOMAnnex, UpdateCPOMAnnex } from '@application/bundles/cpom/dto/cpom-annex.dto';

@HandleCommand({
  command: UpdateCPOMCommand,
})
export class UpdateCPOMCommandHandler implements CommandHandler<UpdateCPOMCommand, CPOM> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: CPOMRepository,
    private readonly authorization: CPOMAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: UpdateCPOMCommand): Promise<CPOM> {
    const { id, cpom } = command;

    if (!isValidCPOMIdType(id)) {
      throw new Error(`${id} is not a valid CPOM ID`);
    }

    try {
      const updated = await this.sanitize(cpom)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(id, dto))
        .then(dto => this.updateCPOM(id, dto));

      this.eventDispatcher.dispatch(new CPOMUpdatedEvent(updated));

      return updated;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(id: CPOM['id'], dto: UpdateCPOMDto): Promise<UpdateCPOMDto> {
    const cpom = await this.queryBus.request<CPOM>(new GetCPOMQuery(id));

    if (!(await this.authorization.canUpdate(cpom))) {
      this.logger.error('C.P.O.M. update : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: UpdateCPOMDto): Promise<UpdateCPOMDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('C.P.O.M. update : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdateCPOMDto): Promise<UpdateCPOMDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('C.P.O.M. update : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à mettre à jour ce C.P.O.M..",
        undefined,
        CPOM_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new CPOMUpdateFailEvent(message));

    return error;
  }

  private async updateCPOM(id: CPOM['id'], dto: UpdateCPOMDto): Promise<CPOM> {
    const current = await this.queryBus.request(new GetCPOMQuery(id));

    const medias: Array<TemporaryMedia> = [];

    if (dto.documentId !== current.documentId || isLocalMedia(dto.document)) {
      if (dto.document && isLocalMedia(dto.document)) {
        const document = await this.mediaBucket.add(dto.document);
        dto.documentId = document.id;
      } else if (dto.document && isMedia(dto.document) && dto.document.id !== current.documentId) {
        dto.documentId = dto.document.id;
      }
    }

    const updateAnnexIds: Array<CPOMAnnex['id']> = [];
    const updateAnnex: Array<CreateCPOMAnnex | UpdateCPOMAnnex> = [];

    if (Array.isArray(dto.annexes)) {
      for (const [index, annex] of dto.annexes.entries()) {
        if (isLocalMedia(annex.media)) {
          const media = await this.mediaBucket.add(annex.media);
          medias.push(media);
          annex.mediaId = media.id;
        } else if (isMedia(annex.media) && annex.media.id !== annex.mediaId) {
          annex.mediaId = annex.media.id;
        }

        updateAnnex.push(annex);
      }
    }

    dto.annexes = updateAnnex;

    try {
      return await this.repository.update(id, dto);
    } catch (e) {
      await Promise.all(medias.map(media => media.markForDeletion()));
      throw e;
    }
  }
}
