import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { ListGirValuationForYearQuery } from '@application/bundles/gir/queries';
import { ConsolidatedPointGirValuation } from '@domain/gir';
import { PointGirValuationRepository } from '@application/bundles/gir';

@HandleQuery({
  query: ListGirValuationForYearQuery,
})
export class ListGirValuationForYearQueryHandler implements QueryHandler<ListGirValuationForYearQuery, ConsolidatedPointGirValuation[]> {
  constructor(private readonly repository: PointGirValuationRepository) {}

  public handle(query: ListGirValuationForYearQuery): Promise<ConsolidatedPointGirValuation[]> {
    return this.repository.findByYear(query.year);
  }
}
