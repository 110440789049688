import {HealthFunding} from "@domain/health-funding";
import {
  CreateEhpadDemainHealthFundingDto,
  CreateExceptionalHealthFundingDto,
  CreateNonRecurringHealthFundingDto,
  CreateRecurringHealthFundingDto,
  UpdateEhpadDemainHealthFundingDto,
  UpdateExceptionalHealthFundingDto,
  UpdateNonRecurringHealthFundingDto,
  UpdateRecurringHealthFundingDto
} from "@application/bundles/health-funding";
import {LocalMedia, Media} from "@domain/media";

export interface CreateHealthFundingDto extends Omit<HealthFunding, 'id' | 'year' | 'recurringFunding' | 'nonRecurringFunding' | 'exceptionalFunding' | 'ehpadDemainFunding' | 'facility' | 'documentId' | 'reportId' | 'document' | 'report' | 'createdAt' | 'updatedAt'> {

  recurringFunding: CreateRecurringHealthFundingDto[];

  nonRecurringFunding: CreateNonRecurringHealthFundingDto[];

  exceptionalFunding: CreateExceptionalHealthFundingDto[];

  ehpadDemainFunding: CreateEhpadDemainHealthFundingDto[];

  documentId?: Media['id'];
  reportId?: Media['id'];

  document: LocalMedia;

  report?: LocalMedia;

}


export interface UpdateHealthFundingDto extends Omit<HealthFunding, 'year' | 'facility' | 'createdAt' | 'updatedAt' | 'recurringFunding' | 'nonRecurringFunding' | 'exceptionalFunding' | 'ehpadDemainFunding' | 'documentId' | 'reportId' | 'document' | 'report'> {

  recurringFunding: Array<CreateRecurringHealthFundingDto | UpdateRecurringHealthFundingDto>;

  nonRecurringFunding: Array<CreateNonRecurringHealthFundingDto | UpdateNonRecurringHealthFundingDto>;

  exceptionalFunding: Array<CreateExceptionalHealthFundingDto | UpdateExceptionalHealthFundingDto>;

  ehpadDemainFunding: Array<CreateEhpadDemainHealthFundingDto | UpdateEhpadDemainHealthFundingDto>;

  documentId?: Media['id'];

  reportId?: Media['id'];

  document: LocalMedia | Media;

  report?: LocalMedia | Media;
}
