import { Bundle } from '@application/framework/bundle';
import {
  GetLatestAccommodationFundingAmountsQueryHandler,
  GetLatestDependencyFundingAmountsQueryHandler,
  GetLatestHealthFundingAmountsQueryHandler,
  ListDependencyFundingUpdateRateQueryHandler,
  ListHealthUpdateRatesQueryHandler,
} from './queries/handlers';

@Bundle({
  queryHandlers: [
    GetLatestAccommodationFundingAmountsQueryHandler,
    GetLatestDependencyFundingAmountsQueryHandler,
    GetLatestHealthFundingAmountsQueryHandler,
    ListDependencyFundingUpdateRateQueryHandler,
    ListHealthUpdateRatesQueryHandler,
  ],
})
export class FundingBundle {}
