import {Injectable} from "@angular/core";
import {Container} from "@application/framework/di/container";
import {CustomerBundle as ImplementationCustomerBundle} from '@implementations/bundles/customer/customer.bundle';

@Injectable({
  providedIn: 'root'
})
export class CustomerBundle {

  private static build = false;

  public constructor(private container: Container) {
  }

  public build() {

    if (CustomerBundle.build) {
      return;
    }
    ImplementationCustomerBundle.build(this.container);
    CustomerBundle.build = true;
  }
}
