import { Command } from '@application/framework/command-query';
import { GMP } from '@domain/gmp';
import { UpdateGMPDto } from './../gmp.dto';

export class UpdateGMPCommand implements Command<GMP> {
  constructor(
    public readonly id: GMP['id'],
    public readonly gmp: UpdateGMPDto,
  ) {}
}
