import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { NationalPMPIndicator } from '@domain/pmp';
import { NationalPMPIndicatorRepository } from '@application/bundles/pmp';
import { GetLatestNationalPMPIndicatorQuery } from '@application/bundles/pmp/queries/get-latest-national-pmp-indicator.query';

@HandleQuery({
  query: GetLatestNationalPMPIndicatorQuery,
})
export class GetLatestNationalPMPIndicatorQueryHandler implements QueryHandler<GetLatestNationalPMPIndicatorQuery> {
  constructor(private readonly repository: NationalPMPIndicatorRepository) {}

  public handle(query: GetLatestNationalPMPIndicatorQuery): Promise<NationalPMPIndicator | undefined> {
    return this.repository.getLatest();
  }
}
