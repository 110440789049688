import {
  CommandConstructor,
  CommandHandlerFactory,
  CommandHandlerRegistry
} from "@application/framework/command-query/command-handler.registry";
import {Command} from "@application/framework/command-query/command.interface";
import {MissingCommandConstructorError} from "@application/framework/command-query/errors";
import {CommandHandler} from "@application/framework/command-query/handler.interface";
import {Service} from "@application/framework/di";
import {Logger, ProvideLogger} from "@application/framework/logger";

@Service()
export class CommandHandlersAgnosticRegistry extends CommandHandlerRegistry implements CommandHandlerRegistry {

  @ProvideLogger() private readonly logger!: Logger;

  /**
   * @inheritDoc
   * @param command
   */
  public get<C extends Command, R = any>(command: C): CommandHandler<C, R> | undefined {

    if (!command) {
      throw new MissingCommandConstructorError(`Constructor is missing on command "${command}".`);
    }

    const factory = this.store.get(Object.getPrototypeOf(command).constructor.name);
    
    return factory !== undefined ? factory(command) : undefined;
  }

  /**
   * @inheritDoc
   * @param command
   * @param handlerFactory
   */
  public register<C extends Command, R = any>(command: CommandConstructor<C>, handlerFactory: CommandHandlerFactory<C, R>): void {

    CommandHandlersAgnosticRegistry.register(command, handlerFactory);
    this.logger.info(`Register handler for command "${command.name}"`, handlerFactory.name);
  }

}
