import {Component, HostListener, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {QueryBus} from "@application/framework/command-query";
import {Router} from "@angular/router";
import {GetEPRDQuery} from "@application/bundles/eprd-errd";
import {EPRD} from "@domain/eprd-errd";
import {EPRDUrlsProvider} from "@easyhpad-ui/app/bundles/eprd-errd";

@Component({
  selector: 'ehp-eprd-link',
  templateUrl: './eprd-link.component.html',
  styleUrls: ['./eprd-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.link]': 'isLink',
    '[attr.role]': 'role',
  }
})
export class EprdLinkComponent implements OnChanges {


  @Input() public eprd!: EPRD;

  @Input() public refId!: EPRD['id'];

  private url: string | null | undefined;

  public get isLink(): boolean {
    return !!this.url;
  }

  public get role(): string | undefined {
    return this.isLink ? 'link' : undefined;
  }

  constructor(
    private readonly queryBus: QueryBus,
    private readonly urls: EPRDUrlsProvider,
    private readonly router: Router
  ) {
  }

  @HostListener('click', ['$event'])
  public async click(event: MouseEvent) {
    if (!this.isLink) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.url !== undefined && this.url !== null) {
      return this.router.navigateByUrl(this.url);
    }

    return false;

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['refId']) {

      if (changes['refId'].previousValue !== changes['refId'].currentValue) {
        this.loadEPRD();
      }
    }

    if (changes['eprd']) {
      if (changes['eprd'].previousValue !== changes['eprd'].currentValue) {
        this.eprdHasChange();
      }
    }
  }

  private loadEPRD(): void {

    if (this.refId) {
      this.queryBus.request(new GetEPRDQuery(this.refId)).then(eprd => {
        this.eprd = eprd;
        this.eprdHasChange();
      });

    }
  }

  private eprdHasChange(): void {
    this.url = null;

    if (this.eprd) {
      this.url = this.urls.getUnsafePublicShow(this.eprd.id);
    }
  }
}
