<ng-container [formGroup]="form">


  <ehp-form-field class="field-address-line">
    <label> {{ 'Numéro et nom de la rue' | ehp_translate }}</label>
    <input ehp-form-control formControlName="addressLine1" type="text">
  </ehp-form-field>

  <ehp-form-field>
    <label> {{ 'Code Postal' | ehp_translate }}</label>
    <input ehp-form-control formControlName="postalCode" type="text">
  </ehp-form-field>

  <ehp-form-field>
    <label> {{ 'Ville' | ehp_translate }}</label>
    <input ehp-form-control formControlName="locality" type="text">
  </ehp-form-field>


</ng-container>
