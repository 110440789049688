import { createReducer, on } from '@ngrx/store';
import { GlobalStoreState } from '@easyhpad-ui/app/store/global.state';
import { GlobalActions } from '@easyhpad-ui/app/store/global.action';

const initialState: GlobalStoreState = {
  isInitProcess: false,
  facilityOnboarding: {
    validated: [],
  },
};

export const globalReducer = createReducer(
  initialState,

  on(GlobalActions.setFacilitiesOnboarding, (state, { ids }) => ({
    ...state,
    facilityOnboarding: {
      ...state.facilityOnboarding,
      validated: [...ids],
    },
  })),

  on(GlobalActions.validateFacilityOnboarding, (state, { id }) => ({
    ...state,
    facilityOnboarding: {
      ...state.facilityOnboarding,
      validated: Array.from(new Set([...state.facilityOnboarding.validated, id])),
    },
  })),
);
