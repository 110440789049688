import {CreateCustomerDto} from "@application/bundles/customer/dto/create-customer.dto";
import {UpdateCustomerDto} from "@application/bundles/customer/dto/update-customer.dto";
import {CustomerDtoFactory} from "@application/bundles/customer/factories";
import {CreateCustomerDtoImpl} from "@implementations/bundles/customer/dto/create-customer.dto";
import {UpdateCustomerDtoImpl} from "@implementations/bundles/customer/dto/update-customer.dto";

export class CustomerDtoFactoryImpl extends CustomerDtoFactory {


  public newCreateCustomerDtoFromUnsafeValues(values: Partial<Record<keyof CreateCustomerDto, any>>): CreateCustomerDto {
    return new CreateCustomerDtoImpl(values as CreateCustomerDto);
  }

  public newUpdateCustomerDtoFromUnsafeValues(values: Partial<Record<keyof UpdateCustomerDto, any>>): UpdateCustomerDto {
    return new UpdateCustomerDtoImpl(values as UpdateCustomerDto);
  }


}
