import { Bundle } from '@application/framework/bundle';
import {
  CheckResetPasswordTokenValidityCommandHandler,
  CreateFileDepositItemCommandHandler,
  RefreshCurrentUserCommandHandler,
  RefreshHttpBearerAuthorizationTokenCommandHandler,
  RequestPasswordResetCommandHandler,
  RequestTransitionToCustomerFileDepositCommandHandler,
  RequestTransitionToReviewFileDepositCommandHandler,
  ResetPasswordCommandHandler,
  SendSupportRequestCommandHandler,
  SendWelcomeEmailCommandHandler,
  TryAutoReconnectCommandHandler,
  UpdateFileDepositItemCommandHandler,
} from '@implementations/bundles/backend/easyhpad/commands/handlers';
import {
  BuildMediaPublicUrlQueryHandler,
  GetExpectedMediaListQueryHandler,
  GetFirstJanuaryTariffsQueryHandler,
  GetHealthTaxonomyTermQueryHandler,
  GetUserPermissionsQueryHandler,
  GetUserRolesQueryHandler,
  IsUniqueEmailQueryHandler,
  ListLibraryQueryHandler,
  LoadFileDepositHistoryQueryHandler,
} from '@implementations/bundles/backend/easyhpad/queries/handlers';

@Bundle({
  commandHandlers: [
    RefreshCurrentUserCommandHandler,
    TryAutoReconnectCommandHandler,
    RefreshHttpBearerAuthorizationTokenCommandHandler,
    CheckResetPasswordTokenValidityCommandHandler,
    RequestPasswordResetCommandHandler,
    ResetPasswordCommandHandler,
    SendSupportRequestCommandHandler,
    CreateFileDepositItemCommandHandler,
    RequestTransitionToCustomerFileDepositCommandHandler,
    RequestTransitionToReviewFileDepositCommandHandler,
    UpdateFileDepositItemCommandHandler,
    SendWelcomeEmailCommandHandler,
  ],
  queryHandlers: [
    IsUniqueEmailQueryHandler,
    GetUserRolesQueryHandler,
    GetUserPermissionsQueryHandler,
    BuildMediaPublicUrlQueryHandler,
    GetExpectedMediaListQueryHandler,
    LoadFileDepositHistoryQueryHandler,
    ListLibraryQueryHandler,
    GetFirstJanuaryTariffsQueryHandler,
    GetHealthTaxonomyTermQueryHandler,
  ],
})
export class EasyHpadBackendBundle {}
