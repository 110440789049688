import { Component, Input, OnInit } from '@angular/core';
import {
  DynamicAbstractControlField,
  DynamicFormElementType,
  DynamicFormElementTypeComponent,
  DynamicPanelGroup,
  DynamicPanelsGroup,
} from '@easyhpad-ui/app/library/form/contracts';
import { values } from '@domain/lib';

@Component({
  selector: 'ehp-dynamic-form-panels',
  templateUrl: './dynamic-form-panels.component.html',
  styleUrl: './dynamic-form-panels.component.scss',
})
export class DynamicFormPanelsComponent implements DynamicFormElementTypeComponent<DynamicPanelsGroup>, OnInit {
  @Input() public field!: DynamicAbstractControlField<DynamicPanelsGroup>;

  public panels: Array<DynamicPanelGroup<any>> = [];

  private panelsArray: Map<Record<keyof any, DynamicFormElementType<any>>, DynamicFormElementType<any>[]> = new Map();

  public ngOnInit(): void {
    this.panels = values(this.field.panels);
  }

  public isValidPanel(panel: DynamicPanelGroup<any> | DynamicAbstractControlField<DynamicPanelGroup<any>>) {
    if ('control' in panel) {
      return panel.control.valid;
    }

    return true;
  }

  /**
   * Get the array version of fields
   * @param fields
   */
  public asArray(fields: Record<keyof any, DynamicFormElementType<any>>) {
    let array = this.panelsArray.get(fields);

    if (array === undefined) {
      array = values(fields);
      this.panelsArray.set(fields, array);
    }
    return array;
  }
}
