export enum MEDIA_FILE_TYPES {
  PDF = 'pdf',
  EXCEL = 'excel',
  IMAGE = 'image',
  OTHER = 'other',
}

export enum MEDIA_TYPES {
  LOCAL,
  TEMPORARY,
  MEDIA,
}
