import { ListCapacityAuthorizationParams } from '@application/bundles/capacity-authorization/list-capacity-authorization-params';
import { DocumentListParamsImpl } from '@application/framework/search';
import { SORT_ORDER } from '@application/framework/search/sort';

export class ListCapacityAuthorizationParamsImpl
  extends DocumentListParamsImpl
  implements ListCapacityAuthorizationParams
{
  constructor() {
    super();
    this.setSort({ key: 'start', order: SORT_ORDER.DESC });
  }
}
