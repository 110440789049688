import { EntityAdapter, EntityState } from '@ngrx/entity';

export interface DocumentStoreState<E extends { id: ID }, ID = string | number> {
  views: {
    list: {
      loading: boolean;
      ids: Array<E['id']>;
      total: number;
      count: number;
    };

    single: {
      id: E['id'] | null;
    };
  };

  documents: EntityState<E>;
}

export const createInitialDocumentState = <E extends { id: ID }, ID = string | number>(
  adapter: EntityAdapter<E>,
): DocumentStoreState<E, ID> => {
  return {
    views: {
      single: {
        id: null,
      },
      list: {
        loading: false,
        ids: [],
        total: 0,
        count: 0,
      },
    },
    documents: adapter.getInitialState(),
  };
};
