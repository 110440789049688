import { Injectable } from '@angular/core';
import { joinPath } from '@application/framework/lib';
import { FacilityAccessChecker } from '@easyhpad-ui/app/bundles/facility/services/authorization-checker/facility-access-checker.service';
import { FileDepositAuthorizationChecker } from '@application/bundles/file-deposit';
import { FileDeposit } from '@domain/file-deposit';

@Injectable({
  providedIn: 'root',
})
export class FileDepositUrlProvider {
  public static readonly base = 'file-deposits';

  public static readonly public = {
    list: '',
    add: ':id',
  };
  public static readonly admin = {
    list: { path: '', title: 'Administration : Dépôts de fichier' },
    create: { path: 'create', title: 'Administration : Nouveau dépôt de fichier' },
    update: { path: ':id', title: 'Administration: Modifier le dépôt' },
  };

  constructor(
    public readonly authorization: FileDepositAuthorizationChecker,
    public readonly access: FacilityAccessChecker,
  ) {}

  public async getAdminList(): Promise<string | null> {
    return this.authorization.canManage().then(can => (can ? this.getAdminListWithoutAuthorizationCheck() : null));
  }

  public getAdminListWithoutAuthorizationCheck(): string {
    return '/' + joinPath('admin', FileDepositUrlProvider.base, FileDepositUrlProvider.admin.list.path);
  }

  public async getAdminCreate(): Promise<string | null> {
    return this.authorization.canManage().then(can => (can ? this.getAdminCreateWithoutAuthorizationCheck() : null));
  }

  public async getAdminCreateWithoutAuthorizationCheck(): Promise<string | null> {
    return '/' + joinPath('admin', FileDepositUrlProvider.base, FileDepositUrlProvider.admin.create.path);
  }

  public async getAdminUpdate(id: FileDeposit['id']): Promise<string | null> {
    return this.authorization.canManage().then(can => (can ? this.getAdminUpdateWithoutAuthorizationCheck(id) : null));
  }

  public async getAdminUpdateWithoutAuthorizationCheck(id: FileDeposit['id']): Promise<string | null> {
    return (
      '/' + joinPath('admin', FileDepositUrlProvider.base, FileDepositUrlProvider.admin.update.path, id.toString())
    );
  }

  public async getPublicList(): Promise<string | null> {
    return this.authorization.canRead().then(can => (can ? this.getPublicListWithoutAuthorizationCheck() : null));
  }

  public getPublicListWithoutAuthorizationCheck(): string {
    return '/' + joinPath(FileDepositUrlProvider.base, FileDepositUrlProvider.public.list);
  }

  public async getPublicShow(id: FileDeposit['id']): Promise<string | undefined> {
    const can = await this.authorization.canRead();
    return can ? this.getPublicShowWithoutAuthorizationCheck(id) : undefined;
  }

  public getPublicShowWithoutAuthorizationCheck(id: FileDeposit['id']): string {
    return joinPath('/', FileDepositUrlProvider.base, id.toString());
  }
}
