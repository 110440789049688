import {Voter} from "@application/bundles/authorization";
import {VoterRegistry} from "@application/bundles/authorization/abstractions/voter.registry.abstract";

export class AgnosticVoterRegistry implements VoterRegistry {


  public register(...voter: Voter[]): void {
    voter.forEach(v => VoterRegistry.set(v));
  }

  public all(): Voter[] {
    return VoterRegistry.all();
  }

}
