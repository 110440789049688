import { Component, OnInit, TemplateRef } from '@angular/core';
import { TooltipPosition } from '../../tooltip.enums';

@Component({
  selector: 'ehp-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  public tooltip: string | TemplateRef<any> = '';

  public position: TooltipPosition = TooltipPosition.ABOVE;

  public content: string | undefined;

  public template: TemplateRef<any> | undefined;

  public left = 0;

  public top = 0;

  public visible = false;

  public ngOnInit(): void {
    if (this.tooltip instanceof TemplateRef) {
      this.template = this.tooltip;
    } else {
      this.content = this.tooltip;
    }
  }
}
