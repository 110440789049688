import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GetFundingWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-funding-widget.query';
import { FundingWidget } from '@easyhpad-ui/app/bundles/dashboard/interfaces/widgets/funding-widget';
import { RequestParams } from '@application/framework/http/request.interface';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';

@HandleQuery({
  query: GetFundingWidgetQuery,
})
export class GetFundingItemQueryHandler implements QueryHandler<GetFundingWidgetQuery, FundingWidget> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: GetFundingWidgetQuery): Promise<FundingWidget> {
    const params: RequestParams = {};

    if (Array.isArray(query.facilities) && query.facilities.length > 0) {
      params['facility_ids'] = query.facilities.join(', ');
    }

    const response = await this.backend.get<FundingWidget>('/widgets/funding', params);
    return response.body;
  }
}
