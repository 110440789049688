<div *ngIf="facilities && facilities.length > 0" class="launcher button primary">
  <div (click)="open()" class="label-container">
    <span class="count-container">
    <ng-container *ngIf="size > 1; else countIcon">{{size}}</ng-container>

    <ng-template #countIcon>
        <i aria-hidden="true" class="icon icon-home ehp-icon"></i>
     </ng-template>
  </span>

    <span class="label">

    <ng-container *ngIf="size > 1; else hasSingleSelection">
      {{ 'Établissements' | ehp_translate }}
    </ng-container>

    <ng-template #hasSingleSelection>
      <ng-container *ngIf="single; else emptySelection">
        {{single.name}}
      </ng-container>
    </ng-template>

    <ng-template #emptySelection>
        {{ 'Mes établissements' | ehp_translate }}
    </ng-template>

  </span>
  </div>

  <div *ngIf="url" class="link-container">
    <a [routerLink]="url" class="button is-icon icon-arrow small single-link">
      <span class="label">{{"Accéder à la page de l'établissement" | ehp_translate}}</span>
    </a>
  </div>

</div>

<div #selector>
</div>

<p-overlayPanel #op [showCloseIcon]="false" appendTo="selector" styleClass="current-facilities-selector-panel">
  <ng-template pTemplate="">
    <p-listbox
      (onChange)="updateSelection($event)"
      [(ngModel)]="selection"
      [checkbox]="true"
      [filter]="true"
      [listStyle]="{ 'max-height': '250px' }"
      [multiple]="true"
      [options]="facilities"
      optionLabel="name"
    ></p-listbox>

    <button (click)="validateSelection()" class="secondary validate" type="button">
      {{'Valider la selection' | ehp_translate}} <span class="count">{{size}}</span>
    </button>

  </ng-template>
</p-overlayPanel>
