import { Facility } from '@domain/facility';
import { StoreSerializedFacility } from '@easyhpad-ui/app/bundles/facility/store/serializers/facility/facility-store.serializer';
import { EntityState } from '@ngrx/entity';

export interface FacilityStoreState {
  names: Record<Facility['id'], Facility['name']>;

  account: {
    all: StoreSerializedFacility[];
    selected: Array<Facility['id']>;
  };

  admin: {
    loading: boolean;
    entities: StoreSerializedFacility[];
  };

  public: EntityState<StoreSerializedFacility> & unknown;
}
