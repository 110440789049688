import {SerializedGMPSearchParams} from "@application/bundles/gmp";
import {Query} from "@application/framework/command-query";
import {GMP} from "@domain/gmp";

export class ListGMPQuery implements Query<GMP[]> {

  constructor(public readonly params?: Partial<SerializedGMPSearchParams>) {
  }

}
