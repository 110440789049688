import { CreateRecurringHealthFundingDto } from '@application/bundles/health-funding';
import { IsOptional, IsString } from 'class-validator';
import { isValidFundingAmount } from '@domain/funding';
import { IsFundingAmount } from '@implementations/bundles/funding/validators';
import { RecurringTaxonomyTerm } from '@domain/health-funding';
import { isUUID } from '@domain/lib';
import { IsHealthTaxonomyTermId } from '@implementations/bundles/health-funding/validators';

export class CreateRecurringHealthFundingDtoImpl implements CreateRecurringHealthFundingDto {
  @IsOptional()
  @IsString()
  public name: string | undefined;

  @IsFundingAmount()
  public amount!: number;

  @IsOptional()
  @IsHealthTaxonomyTermId('recurring')
  public categoryId: RecurringTaxonomyTerm['id'] | undefined;

  constructor(values?: Partial<CreateRecurringHealthFundingDto>) {
    if (values) {
      if (typeof values.name === 'string' || values.name === undefined) {
        this.name = values.name;
      }

      if (isValidFundingAmount(values.amount)) {
        this.amount = values.amount;
      }

      if (isUUID(values.categoryId)) {
        this.categoryId = values.categoryId;
      }
    }
  }
}
