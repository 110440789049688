import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { SendSupportRequestCommand } from '@application/bundles/support/command/send-support-request.command';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { EventDispatcher } from '@application/framework/event';
import { SupportRequestSendFail, SupportRequestSentEvent } from '@application/bundles/support';

@HandleCommand({
  command: SendSupportRequestCommand,
})
export class SendSupportRequestCommandHandler implements CommandHandler<SendSupportRequestCommand, boolean> {
  constructor(
    private readonly backend: EasyBackendApi,
    private readonly dispatcher: EventDispatcher,
  ) {}

  public async handle(command: SendSupportRequestCommand): Promise<boolean> {
    const response = await this.backend.post<{ success: boolean }>('/support/request', command.request);

    const sent = response.body?.success ?? false;

    if (sent) {
      this.dispatcher.dispatch(new SupportRequestSentEvent());
    } else {
      this.dispatcher.dispatch(new SupportRequestSendFail());
    }

    return sent;
  }
}
