import {ReadOnlyCollection} from "@application/framework/store/collection";
import {BILLING_MODE, BillingMode} from "@domain/customer";
import {Service} from "@application/framework/di";

@Service()
export class BillingModeCollection implements ReadOnlyCollection<BillingMode> {


  private readonly collection: Map<BILLING_MODE, BillingMode> = new Map([
    [BILLING_MODE.CURRENT_ADDRESS, {mode: BILLING_MODE.CURRENT_ADDRESS, label: 'Same address'}],
    [BILLING_MODE.BILLING_ADDRESS, {mode: BILLING_MODE.BILLING_ADDRESS, label: 'Billing address'}],
    [BILLING_MODE.EACH_FACILITY, {mode: BILLING_MODE.EACH_FACILITY, label: 'Each facility'}],
  ]);

  public get(mode: BILLING_MODE): BillingMode | undefined {
    return this.collection.get(mode);
  }

  public has(mode: BILLING_MODE): boolean {
    return this.collection.has(mode);
  }

  public list(): BillingMode[] {
    return Array.from(this.collection.values());
  }

}
