import {AuthentificationToken} from "./authentification-token";
import {Subscription} from "@application/framework/reactive";


export interface AuthentificationTokenChange {

  current: AuthentificationToken;

  previous: AuthentificationToken | undefined;

}


export abstract class AuthentificationTokenStore {

  /**
   * Get current token
   */
  public abstract getToken(): AuthentificationToken;

  /**
   * Set current token
   */
  public abstract setToken(token: AuthentificationToken): void;

  /**
   * Observe token change.
   * @param observer
   */
  public abstract onChange(observer: (token: AuthentificationTokenChange) => void): Subscription;
}
