import {CRPP, CRPPAccountNumber, CRRPAccountNumberValues} from "@domain/eprd-errd";
import {isPositive} from "@application/framework/lib";

export function isValidCRPPAccountNumber(value: any): value is CRPPAccountNumber {
  return CRRPAccountNumberValues.has(value);
}


export function isCRPP(obj: any): obj is CRPP {
  return typeof obj === 'object' &&
    obj !== null
    && isValidCRPPAccountNumber(obj.account)
    && isPositive(obj.value, true);
}
