import {
  CreateHealthFundingCommandHandler,
  DeleteHealthFundingCommandHandler,
  UpdateHealthFundingCommandHandler,
} from '@application/bundles/health-funding/commands/handlers';
import {
  OnHealthFundingCreationEventSubscriber,
  OnHealthFundingDeletionEventSubscriber,
  OnHealthFundingUpdateEventSubscriber,
} from '@application/bundles/health-funding/events/subscribers';
import {
  GetCurrentHealthFundingQueryHandler,
  GetHealthFundingQueryHandler,
  HasHealthFundingForYearQueryHandler,
  ListHealthFundingQueryHandler,
  ListHealthTaxonomyTermsQueryHandler,
  ListOtherLocalCpomQueryHandler,
} from '@application/bundles/health-funding/queries/handlers';
import { Bundle } from '@application/framework/bundle';
import { Service } from '@application/framework/di';

@Bundle({
  commandHandlers: [
    CreateHealthFundingCommandHandler,
    UpdateHealthFundingCommandHandler,
    DeleteHealthFundingCommandHandler,
  ],
  queryHandlers: [
    ListHealthFundingQueryHandler,
    GetHealthFundingQueryHandler,
    GetCurrentHealthFundingQueryHandler,
    HasHealthFundingForYearQueryHandler,
    ListOtherLocalCpomQueryHandler,
    ListHealthTaxonomyTermsQueryHandler,
  ],
  eventSubscribers: [
    OnHealthFundingCreationEventSubscriber,
    OnHealthFundingUpdateEventSubscriber,
    OnHealthFundingDeletionEventSubscriber,
  ],
})
@Service()
export class HealthFundingBundle {}
