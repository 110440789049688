import {Router} from "@angular/router";
import {UnauthorizedAccessEvent} from "@application/bundles/authorization/events/unauthorized-access.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";

@HasEventSubscribers()
export class OnUnauthorizedAccessSubscriber {

  public constructor(private readonly router: Router) {
  }


  @OnEvent({
    event: UnauthorizedAccessEvent
  })
  private async redirectToHome() {
    await this.router.navigate(['/']);
  }
}
