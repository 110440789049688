import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EPRDUrlsProvider } from './providers/eprd-urls-provider/eprd-urls-provider';
import { ERRDUrlsProvider } from './providers/errd-urls-provider/errd-urls-provider';
import { Bundle } from '@application/framework/bundle';
import { EPRDERRDBundle } from '@application/bundles/eprd-errd/eprrd-errd.bundle';
import { EPRDRepository, ERRDRepository } from '@application/bundles/eprd-errd';
import { EasyEPRDRepository, EasyERRDRepository } from '@implementations/bundles/backend/easyhpad';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator';
import {
  CreateEPRDDtoImpl,
  CreateERRDDtoImpl,
  CreateLocalEPRDDtoImpl,
  CreateLocalERRDDtoImpl,
  UpdateEPRDDtoImpl,
  UpdateERRDDtoImpl,
  UpdateLocalEPRDDtoImpl,
  UpdateLocalERRDDtoImpl,
} from '@implementations/bundles/eprd-errd/dto';
import { validateClassValidatorObject } from '@implementations/framework/validator';
import { StoreModule } from '@ngrx/store';
import { ERRD_STORE_KEY, errdReducer } from '@easyhpad-ui/app/bundles/eprd-errd/store';
import { EffectsModule } from '@ngrx/effects';
import { ErrdEffects } from '@easyhpad-ui/app/bundles/eprd-errd/store/errd.effect';
import { EPRDDtoFactory, ERRDDtoFactory } from '@application/bundles/eprd-errd/factories';
import { EprdDtoFactoryImpl, ErrdDtoFactoryImpl } from '@implementations/bundles/eprd-errd/factories';
import { ETPCategoryCollection } from '@application/bundles/etp/etp-category-collection';
import { AgnosticTPERCollection } from '@application/bundles/eprd-errd/implementations';
import {
  OpenEPRDDeletionDialogCommandHandler,
  OpenERRDDeletionDialogCommandHandler,
} from '@easyhpad-ui/app/bundles/eprd-errd/commands/handlers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(ERRD_STORE_KEY, errdReducer),
    EffectsModule.forFeature([ErrdEffects]),
    ValidatorModule.forChild({
      validators: [
        { objectType: CreateEPRDDtoImpl, validator: validateClassValidatorObject },
        { objectType: CreateERRDDtoImpl, validator: validateClassValidatorObject },
        { objectType: UpdateEPRDDtoImpl, validator: validateClassValidatorObject },
        { objectType: UpdateERRDDtoImpl, validator: validateClassValidatorObject },
        { objectType: CreateLocalEPRDDtoImpl, validator: validateClassValidatorObject },
        { objectType: CreateLocalERRDDtoImpl, validator: validateClassValidatorObject },
        { objectType: UpdateLocalEPRDDtoImpl, validator: validateClassValidatorObject },
        { objectType: UpdateLocalERRDDtoImpl, validator: validateClassValidatorObject },
      ],
    }),
  ],
  providers: [
    EPRDUrlsProvider,
    ERRDUrlsProvider,
    {
      provide: EPRDRepository,
      useClass: EasyEPRDRepository,
    },
    {
      provide: ERRDRepository,
      useClass: EasyERRDRepository,
    },
    {
      provide: EPRDDtoFactory,
      useClass: EprdDtoFactoryImpl,
    },
    {
      provide: ERRDDtoFactory,
      useClass: ErrdDtoFactoryImpl,
    },
    {
      provide: ETPCategoryCollection,
      useClass: AgnosticTPERCollection,
    },
  ],
})
@Bundle({
  imports: [EPRDERRDBundle],
  commandHandlers: [OpenEPRDDeletionDialogCommandHandler, OpenERRDDeletionDialogCommandHandler],
})
export class EPRDERRDModule {}
