import {
  DateRange,
  EasySearchOptions,
  EasySearchQueryParams,
  EasySort,
  PagingNum,
} from '@implementations/bundles/backend/easyhpad/interfaces/easy-search-options';
import { PaginationParams, SerializedDocumentParams } from '@application/framework/search';
import { Service } from '@application/framework/di';
import { RequestParams } from '@application/framework/http/request.interface';
import { SerializedFacilitySearchParams } from '@application/bundles/facility/facility-search-params';
import { SerializedCustomerSearchParams } from '@application/bundles/customer';
import { DateFormatter } from '@application/framework/date';
import { isValidYear } from '@domain/lib/date/year';
import { SerializedUserSearchParams } from '@application/bundles/user';
import { SerializedFileDepositSearchParams } from '@application/bundles/file-deposit';
import { EasyCustomer } from '@implementations/bundles/backend/easyhpad';

@Service()
export class EasyDocumentQueryParamsTransformer {
  constructor(private readonly dateFormatter: DateFormatter) {}

  public fromSerializeDocumentParams(values: SerializedDocumentParams): EasySearchQueryParams<any> {
    const params: EasySearchQueryParams<any> = {};

    if (values.facilities) {
      params.facility_ids = values.facilities;
    }

    const search: EasySearchQueryParams<any>['search'] = {};

    if (values.dates) {
      search.date_range = {
        start: this.dateFormatter.toUTCIsoString(values.dates.start),
        end: values.dates.end instanceof Date ? this.dateFormatter.toUTCIsoString(values.dates.end) : undefined,
      };
    }

    if (values.sort) {
      search.sort = { [values.sort.key]: values.sort.order };
    }

    const parsed = this.parseSerializedDocumentParams(values.params);

    if (parsed) {
      search.search = parsed;
    }

    if (Object.keys(search).length > 0) {
      params.search = search;
    }

    return params;
  }

  public fromSerializedFacilitesSearchParams(values: SerializedFacilitySearchParams): EasySearchQueryParams<any> {
    const params: EasySearchQueryParams<any, EasySearchOptions<any> & { includeInactive?: boolean }> = {
      ...this.setCustomerId(values),
    };

    if (values.pagination !== undefined) {
      params.search = {
        paging: this.serializePagination(values.pagination),
      };
    }

    if (values.populate) {
      params.search = {
        ...params.search,
        populate: values.populate,
      };
    }

    if (values.includeInactive) {
      params.search = {
        ...params.search,
        includeInactive: values.includeInactive,
      };
    }

    if (values.search && Array.isArray(values.search.id) && values.search.id.length > 0) {
      params.facility_ids = values.search.id;
    }

    return params;
  }

  public fromSerializedFileDepositSearchParams(values: SerializedFileDepositSearchParams): EasySearchQueryParams<any> {
    const params: EasySearchQueryParams<any> = {
      ...this.setCustomerId(values),
    };

    if (values.facilities) {
      params.facility_ids = values.facilities;
    }

    const search: EasySearchQueryParams<any>['search'] = {};

    if (values.search && values.search.states) {
      search.states = Array.isArray(values.search.states) ? values.search.states.join(',') : values.search.states;
    }

    if (Object.keys(search).length > 0) {
      params.search = {
        ...(params.search ?? {}),
        search: search,
      };
    }

    return params;
  }

  public fromSerializedCustomerSearchParams(
    values: SerializedCustomerSearchParams,
  ): EasySearchQueryParams<EasySort<string>> {
    const params: EasySearchQueryParams<EasySort<string>> = {
      search: {
        search: values.search,
      },
    };

    if (values.sort) {
      const key: string = values.sort.key;

      params.search = {
        ...params.search,
        sort: {
          [key]: values.sort.order,
        },
      };
    }

    if (values.pagination) {
      params.search = {
        ...params.search,
        paging: this.serializePagination(values.pagination),
      };
    }

    return params;
  }

  public fromSerializedUserSearchParams(values: SerializedUserSearchParams): EasySearchQueryParams<any> {
    const params: EasySearchQueryParams<any> = {
      ...this.setCustomerId(values),
    };

    if (values.pagination !== undefined) {
      params.search = {
        paging: this.serializePagination(values.pagination),
      };
    }

    if (values.populate) {
      params.search = {
        ...params.search,
        populate: values.populate,
      };
    }

    return params;
  }

  public toRequestParams(values: EasySearchQueryParams<any>): RequestParams {
    const p: RequestParams = {};

    for (const [key, value] of Object.entries(values)) {
      if (key === 'search') {
        const searchRequestParams: Record<string, any> = value.search;

        if (searchRequestParams) {
          for (const [property, s] of Object.entries(searchRequestParams)) {
            p[`search[search][${property}]`] = s;
          }
        }

        const sortRequestParams: EasySort<string> = value.sort;

        if (sortRequestParams) {
          for (const [property, order] of Object.entries(sortRequestParams)) {
            p[`search[sort][${property}]`] = order;
          }
        }

        const datesRequestParams: DateRange | undefined = value.date_range;

        if (datesRequestParams) {
          if (datesRequestParams.start) {
            p['search[date_range][start]'] = datesRequestParams.start;
          }

          if (datesRequestParams.end) {
            p['search[date_range][end]'] = datesRequestParams.end;
          }
        }

        const pagingRequestParams: PagingNum = value.paging;

        if (pagingRequestParams) {
          for (const [property, value] of Object.entries(pagingRequestParams)) {
            p[`search[paging][${property}]`] = value;
          }
        }

        const populateRequestParams: EasySearchOptions<any>['populate'] = value.populate;

        if (populateRequestParams) {
          p[`search[populate]`] = populateRequestParams;
        }

        if ('includeInactive' in value && value.includeInactive === true) {
          p[`search[includeInactive]`] = 1;
        }
      } else if (key === 'facility_ids') {
        if (Array.isArray(value) && value.length > 0) {
          p['facility_ids'] = value.join(', ');
        }
      } else {
        p[key] = value as any;
      }
    }
    return p;
  }

  private setCustomerId<P extends { customerId?: EasyCustomer['id'] }>(params: P): { client_id?: P['customerId'] } {
    return params.customerId ? { client_id: params.customerId } : {};
  }

  private parseSerializedDocumentParams(params?: Record<string, any>): Record<string, any> | undefined {
    if (!params) {
      return undefined;
    }

    const search: Record<string, any> = {};

    if (isValidYear(params['accountingYear'])) {
      search['accountingYear'] = params['accountingYear'];
    }

    return search;
  }

  private serializePagination(pagination: PaginationParams): PagingNum {
    const start = pagination.start ?? 1;

    return {
      start_page: start,
      end_page: start,
      per_page: pagination.limit,
    };
  }
}
