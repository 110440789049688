import {Command} from "@application/framework/command-query";
import {DependencyFunding} from "@domain/dependency-funding";

export class OpenDependencyFundingDeletionDialogCommand implements Command<void> {

  constructor(
    public readonly document: DependencyFunding,
    public readonly onDeletion?: (document: DependencyFunding) => void
  ) {
  }
}
