import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {Skeleton} from "@easyhpad-ui/app/bundles/reusable-components/components/skeleton/index";

@Component({
  selector: 'ehp-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonComponent {

  @Input()
  @HostBinding('attr.type')
  public type: Skeleton = Skeleton.DEFAULT;

  public types = Skeleton;

  public randomWidth(min: number = 30, max: number = 100): number {
    return this.randomIntFromInterval(min, max);
  }

  private randomIntFromInterval(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
