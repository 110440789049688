import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {Facility} from "@domain/facility";

export class FacilityUpdatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly facility: Facility) {
    super();
  }

}
