import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { QueryBus } from '@application/framework/command-query';
import { Provide } from '@application/framework/di';
import { Facility } from '@domain/facility';
import { Media, MediaBucket } from '@domain/media';
import { AccommodationDailyRate, AccommodationFunding } from '@domain/accommodation-funding';
import { roundFunding } from '@domain/funding/round-funding/round-funding';

export class AccommodationFundingImpl implements AccommodationFunding {
  public id!: AccommodationFunding['id'];

  public date!: Date;

  public dailyRates!: Array<AccommodationDailyRate>;

  public occupationRate: number | undefined;

  public facilityId!: Facility['id'];

  public pvId!: Media['id'];

  public reportId?: Media['id'] | null;

  public permanentAmount: number | null | undefined;

  public temporaryAmount: number | null | undefined;

  public daycareAmount: number | null | undefined;

  public readonly createdAt?: Date;

  public readonly updatedAt?: Date;
  @Provide()
  private readonly queryBus!: QueryBus;
  @Provide()
  private readonly mediaBucket!: MediaBucket;

  public get amount(): number {
    const amounts = [this.permanentAmount ?? 0, this.temporaryAmount ?? 0, this.daycareAmount ?? 0];
    return roundFunding(amounts.reduce((a, b) => a + b, 0));
  }

  public get year() {
    return this.date.getFullYear();
  }

  public get accountingYear() {
    return this.date.getFullYear();
  }

  constructor(values?: Omit<AccommodationFunding, 'year' | 'accountingYear' | 'amount'>) {
    if (values) {
      this.id = values.id;
      this.date = values.date;
      this.dailyRates = values.dailyRates;
      this.occupationRate = values.occupationRate;
      this.pvId = values.pvId;
      this.reportId = values.reportId;
      this.facilityId = values.facilityId;
      this.createdAt = values.createdAt;
      this.updatedAt = values.updatedAt;
      this.permanentAmount = values.permanentAmount;
      this.temporaryAmount = values.temporaryAmount;
      this.daycareAmount = values.daycareAmount;
    }
  }

  public facility(): Promise<Facility> {
    return this.queryBus.request(new GetFacilityQuery(this.facilityId));
  }
}
