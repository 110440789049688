import {NgModule} from "@angular/core";
import {Container} from "@application/framework/di/container";
import {AngularContainer} from "@easyhpad-ui/app/framework/di/containers/angular-container";

@NgModule({
  providers: [{
    provide: Container,
    useClass: AngularContainer
  },]
})
export class DiModule {

}
