import {ActivityAnnexRepository, ListActivityAnnexQuery} from "@application/bundles/activity-annex";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {ActivityAnnex} from "@domain/activity-annex";
import {ListActivityAnnexParamsImpl} from "@application/bundles/activity-annex/implementations";

@HandleQuery({
  query: ListActivityAnnexQuery
})
export class ListActivityAnnexQueryHandler implements QueryHandler<ListActivityAnnexQuery, ActivityAnnex[]> {

  constructor(private readonly repository: ActivityAnnexRepository) {
  }

  public async handle(query: ListActivityAnnexQuery): Promise<ActivityAnnex[]> {
    return this.repository.list(new ListActivityAnnexParamsImpl().deserialize(query.params));
  }
}
