import {MediaFactory} from "@application/bundles/media";
import {Transformer} from "@application/framework/core";
import {Media, TemporaryMedia} from "@domain/media";
import {EasyMedia} from "@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface";
import {
  EasyMediaTransformer
} from "@implementations/bundles/backend/easyhpad/transformers/media/easy-media.transformer";


export class EasyTemporaryMediaTransformer implements Transformer<TemporaryMedia, EasyMedia> {

  constructor(
    private readonly factory: MediaFactory,
    private readonly mediaTransformer: EasyMediaTransformer
  ) {
  }

  public transform(value: Media): EasyMedia {
    return {
      id: value.id,
      path: value.path,
      name: value.name,
      type: value.type
    }
  }

  public reverseTransform(value: EasyMedia): TemporaryMedia {
    return this.factory.createTemporaryMedia(this.mediaTransformer.reverseTransform(value));
  }


}
