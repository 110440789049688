import { AuthorizationChecker, CRUD_ACTIONS } from '@application/bundles/authorization';
import { ForbiddenError } from '@application/bundles/authorization/error';
import { UpdateUserCommand } from '@application/bundles/user/commands/update-user.command';
import { UserUpdateFailEvent } from '@application/bundles/user/events/user-update/user-update-fail.event';
import { UserUpdateSuccessEvent } from '@application/bundles/user/events/user-update/user-update-success.event';
import { USER_FEATURE } from '@application/bundles/user/user.token';
import { CommandBus, HandleCommand } from '@application/framework/command-query';
import { CommandHandler } from '@application/framework/command-query/handler.interface';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { Translator } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { UpdateUser, User, UserRepository } from '@domain/user';

@HandleCommand({
  command: UpdateUserCommand,
})
export class UpdateUserCommandHandler implements CommandHandler<UpdateUserCommand, User> {
  @ProvideLogger() private readonly logger!: Logger;

  constructor(
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly repository: UserRepository,
    private readonly eventDispatcher: EventDispatcher,
    private readonly commandBus: CommandBus,
    private readonly authorization: AuthorizationChecker,
    private readonly translator: Translator,
  ) {}

  public async handle(command: UpdateUserCommand): Promise<User> {
    const { id, update } = command;

    try {
      const user = await this.repository.get(id);

      await this.preventUnauthorizedUserUpdate(user);

      const updated = await this.sanitize(update)
        .then(dto => this.validate(dto))
        .then(dto => this.repository.update(id, dto));

      this.eventDispatcher.dispatch(new UserUpdateSuccessEvent(updated));
      return updated;
    } catch (e) {
      const error = await this.catchError(e, update);
      return Promise.reject(error);
    }
  }

  private async preventUnauthorizedUserUpdate(user: User): Promise<void> {
    if (!(await this.authorization.isGranted([{ feature: USER_FEATURE, value: CRUD_ACTIONS.UPDATE }], user))) {
      this.logger.error('User update : unauthorized');
      throw new ForbiddenError();
    }
  }

  private async sanitize(dto: UpdateUser): Promise<UpdateUser> {
    try {
      return await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.error('User update : sanitizer fail', e);
      throw new SanitizationFailError();
    }
  }

  private async validate(dto: UpdateUser): Promise<UpdateUser> {
    try {
      return await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.error('User update : validator fail', e);
      throw new ValidationError();
    }
  }

  private async catchError(e: any, dto: UpdateUser): Promise<Error> {
    const error = new ErrorNormalizer().normalize(e);

    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = await this.translator.translate('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à mettre à jour l'utilisateur <strong>{{name}}</strong>.",
        { name: dto.firstname },
      );
    }

    this.eventDispatcher.dispatch(new UserUpdateFailEvent(message, error.stack, error));
    return error;
  }
}
