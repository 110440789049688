import { CreateCPOMDto, CreateLocalCPOMDto } from '@application/bundles/cpom';
import { isLocalMedia, LocalMedia } from '@domain/media';
import { CPOMCommonDtoAbstract } from '@implementations/bundles/cpom/dto/cpom-common.dto.abstract';
import { IsLocalMedia } from '@implementations/bundles/media';
import { IsArray } from 'class-validator';
import { CreateCPOMAnnex } from '@application/bundles/cpom/dto/cpom-annex.dto';

export class CreateCPOMDtoImpl extends CPOMCommonDtoAbstract implements CreateCPOMDto {
  @IsArray()
  public children!: CreateLocalCPOMDto[];

  @IsLocalMedia()
  public document!: LocalMedia;

  @IsArray()
  public annexes: Array<CreateCPOMAnnex> = [];

  constructor(values?: Partial<CreateCPOMDtoImpl>) {
    super(values);

    if (values) {
      if (isLocalMedia(values.document)) {
        this.document = values.document;
      }

      if (Array.isArray(values.children)) {
        this.children = values.children;
      }

      if (Array.isArray(values.annexes)) {
        this.annexes = values.annexes;
      }
    }
  }
}
