import { Injectable } from '@angular/core';
import { joinPath } from '@application/framework/lib';

@Injectable()
export class LibraryUrlsProvider {
  public static readonly base = 'library';

  public static readonly publicSchema = {
    list: { path: '', title: 'Bibliothèque' },
  };

  public getPublicList(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList(): string {
    return joinPath('/', LibraryUrlsProvider.base);
  }
}
