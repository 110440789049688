import { NgModule } from '@angular/core';
import { GeoService } from '@application/bundles/geo/services/geo.service';
import { EasyGeoService } from '@implementations/bundles/backend/easyhpad/services/geo/easy-geo.service';
import { extractDependencies } from '@application/framework/di';

@NgModule({
  providers: [
    {
      provide: GeoService,
      useClass: EasyGeoService,
      deps: extractDependencies(EasyGeoService),
    },
  ],
})
export class GeoModule {}
