<ng-container *ngIf="facility$ | async as facility">

  <div class="name-container">
    <h3 class="title facility-name">{{facility.name}}</h3>
    <ehp-address-one-line [address]="facility.address"></ehp-address-one-line>
  </div>

  <ehp-facility-link [facility]="facility" class="button is-icon small icon-arrow secondary">
    <span class="label">{{"Voir la fiche d'identité de l'établissement" | ehp_translate}}</span>
  </ehp-facility-link>

</ng-container>
