import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CPOMFunding } from '@domain/cpom';
import { FundingCollection } from '@domain/funding';

@Component({
  selector: 'ehp-cpom-funding-table',
  templateUrl: './cpom-funding-table.component.html',
  styleUrls: ['./cpom-funding-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    role: 'table',
  },
})
export class CpomFundingTableComponent {
  @Input('funding') public collection!: FundingCollection<CPOMFunding>;
}
