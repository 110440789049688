import {Injectable} from "@angular/core";
import {joinPath} from "@application/framework/lib";
import {UserAuthorizationChecker} from "@application/bundles/user/user-authorization-checker";

@Injectable({
  providedIn: 'root'
})
export class UserUrlsProvider {

  public static basePath = 'user';

  public static readonly adminSchema = {
    list: {path: '', title: 'Administration : utilisateurs'},
    create: {path: 'create', title: 'Nouvel utilisateur'},
    update: {path: ':id', title: "Modifier l'utilisateur"},
  }

  public static readonly publicSchema = {
    current: {path: '@me', title: 'Mon profile'}
  }


  constructor(private readonly authorizationChecker: UserAuthorizationChecker) {
  }


  public async getAdminList(): Promise<string | null> {

    const checks = await Promise.all([
      this.authorizationChecker.canRead(),
      this.authorizationChecker.canUpdate(),
      this.authorizationChecker.canDelete(),
    ]).then(values => values.filter(v => !v));

    return checks.length === 0 ? this.getUnsafeAdminList() : null;
  }

  public getUnsafeAdminList(): string {
    return joinPath('/', 'admin', UserUrlsProvider.basePath, UserUrlsProvider.adminSchema.list.path);
  }


  public getUserProfileUrl(): string {
    return joinPath('/', UserUrlsProvider.publicSchema.current.path);
  }
}
