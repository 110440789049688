import {EhpadDemainRepository} from "@application/bundles/ehpad-demain";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {EhpadDemain} from "@domain/ehpad-demain";
import {ListEhpadDemainQuery} from "../../list-ehpad-demain.query";
import {
  AgnosticEhpadDemainSearchParams
} from "@application/bundles/ehpad-demain/implementations/agnostic-ehpad-demain-search-params";

@HandleQuery({
  query: ListEhpadDemainQuery
})
export class ListEhpadDemainQueryHandler implements QueryHandler<ListEhpadDemainQuery, EhpadDemain[]> {

  constructor(private readonly repository: EhpadDemainRepository) {
  }

  public async handle(query: ListEhpadDemainQuery): Promise<EhpadDemain[]> {
    return this.repository.list(new AgnosticEhpadDemainSearchParams().deserialize(query.params));
  }
}
