import {ForbiddenError} from "@application/bundles/authorization/error";
import {
  CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
  CapacityAuthorizationAuthorizationChecker,
  CapacityAuthorizationDeletedEvent,
  CapacityAuthorizationDeletionFailEvent
} from "@application/bundles/capacity-authorization";
import {
  DeleteCapacityAuthorizationCommand
} from "@application/bundles/capacity-authorization/commands/delete-capacity-authorization.command";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {SanitizerLibrary} from "@application/framework/sanitizer/sanitizer";
import {Translator} from "@application/framework/translation";
import {ObjectValidator} from "@application/framework/validator/object-validator";
import {CapacityAuthorization, CapacityAuthorizationRepository} from "@domain/capacity-authorization";
import {MediaBucket} from "@domain/media";


@HandleCommand({
  command: DeleteCapacityAuthorizationCommand
})
export class DeleteCapacityAuthorizationCommandHandler implements CommandHandler<DeleteCapacityAuthorizationCommand, CapacityAuthorization> {
  
  @ProvideLogger() private readonly logger!: Logger;

  private readonly errorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly authorization: CapacityAuthorizationAuthorizationChecker,
    private readonly sanitizer: SanitizerLibrary,
    private readonly validator: ObjectValidator,
    private readonly repository: CapacityAuthorizationRepository,
    private readonly mediaBucket: MediaBucket,
    private readonly eventDispatcher: EventDispatcher,
    private readonly translator: Translator,
  ) {
  }

  public async handle(command: DeleteCapacityAuthorizationCommand): Promise<CapacityAuthorization> {

    const {authorization} = command;
    try {

      await this.checkAccess(authorization);

      const deleted = await this.repository.delete(authorization.id);

      this.eventDispatcher.dispatch(new CapacityAuthorizationDeletedEvent(authorization));

      return authorization;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(authorization: CapacityAuthorization): Promise<void> {

    if (!await this.authorization.canDelete(authorization)) {
      this.logger.error('Capacity authorization deletion : Forbidden');
      throw new ForbiddenError();
    }
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message = '';

    if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à supprimer cette autorisation de capacité.",
        undefined,
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new CapacityAuthorizationDeletionFailEvent(message));

    return error;
  }


}
