import {Attribute, AuthorizationChecker, CRUD_ACTIONS} from "@application/bundles/authorization";
import {ForbiddenError} from "@application/bundles/authorization/error";
import {FACILITY_FEATURE, FACILITY_TRANSLATE_CONTEXT} from "@application/bundles/facility";
import {DeleteFacilityCommand} from "@application/bundles/facility/commands/delete-facility.command";
import {FacilityDeletedEvent} from "@application/bundles/facility/events/delete-facility/facility-deleted.event";
import {FacilityCreationFailEvent} from "@application/bundles/facility/events/facility-creation-fail.event";
import {HandleCommand} from "@application/framework/command-query";
import {CommandHandler} from "@application/framework/command-query/handler.interface";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {Translator} from "@application/framework/translation";
import {Facility} from "@domain/facility";
import {FacilityRepository} from "@domain/facility/facility.repository";

@HandleCommand({
  command: DeleteFacilityCommand
})
export class DeleteFacilityCommandHandler implements CommandHandler<DeleteFacilityCommand, Facility> {


  @ProvideLogger()
  private readonly logger!: Logger;

  private readonly errorNormalizer = new ErrorNormalizer();

  public constructor(
    private readonly repository: FacilityRepository,
    private readonly authorization: AuthorizationChecker,
    private readonly eventDispatcher: EventDispatcher,
    private readonly translator: Translator,
  ) {
  }

  public async handle(command: DeleteFacilityCommand): Promise<Facility> {
    const {facility} = command;
    try {
      await this.checkAccess(facility);
      await this.repository.delete(facility.id);

      this.eventDispatcher.dispatch(new FacilityDeletedEvent(facility));

      return facility;

    } catch (e) {
      const error = this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(facility: Facility): Promise<void> {

    const attributes: Attribute[] = [
      {feature: FACILITY_FEATURE, value: CRUD_ACTIONS.DELETE}
    ];

    if (!await this.authorization.isGranted(attributes, facility)) {
      this.logger.error('Facility deletion : Forbidden');
      throw new ForbiddenError();
    }
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message = '';

    if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à supprimer cet établissement.",
        undefined,
        FACILITY_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new FacilityCreationFailEvent(message));

    return error;
  }

}
