import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';

import { isSelectOption, SelectOption } from '@implementations/forms';
import { FiltersFormBuilder } from '@easyhpad-ui/app/library/filter/services/filters-form/filters-form.builder';
import { Filter, FilterDefinition, FilterFormItem } from '@easyhpad-ui/app/library/filter/interfaces';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ehp-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterComponent),
      multi: true,
    },
  ],
})
export class FilterComponent implements OnInit, ControlValueAccessor {
  @Input() public definitions: FilterDefinition[] = [];

  @Input() public initialValue: Filter | undefined;

  @Output() public keyChange = new EventEmitter();

  @Output('onChange') public change = new EventEmitter();

  public propertyOptions: SelectOption[] = [];

  public operatorOptions: SelectOption[] = [];

  public inputType: 'text' | 'number' | 'date' = 'text';

  public form: FilterFormItem;

  public get key(): any {
    return this.form.get('key')?.value;
  }

  public get op(): any {
    return this.form.get('op')?.value;
  }

  public get value(): any {
    return this.form.get('value')?.value;
  }

  constructor(private readonly fb: FiltersFormBuilder) {
    this.form = this.fb.buildFilterItemForm();

    this.form.get('key')?.valueChanges.subscribe((change: SelectOption<string> | null) => {
      const key = this.getKeyFromOptions(change);
      this.operatorOptions = this.getOperatorListForKey(key);
      this.inputType = this.getInputTypeForKey(key) ?? 'text';
      this.keyChange.emit(change ? change?.value : null);
    });

    this.form.valueChanges.subscribe(change => this.hasChange(change));
  }

  public ngOnInit(): void {
    if (typeof this.initialValue === 'object' && this.initialValue) {
      this.form.patchValue(this.initialValue);
    }

    this.propertyOptions = this.definitions.map((property: any) => ({ value: property.key, label: property.label }));
  }

  public registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public writeValue(obj: any): void {
    this.form.setValue(obj);
  }

  private getOperatorListForKey(key: string | undefined): SelectOption[] {
    const definition = this.getFilterDefinitionFromKey(key);
    if (!definition) {
      return [];
    }

    return definition.operators.map(operator => ({ value: operator.op, label: operator.label }));
  }

  private getInputTypeForKey(key: string | undefined): 'text' | 'number' | 'date' | undefined {
    const definition = this.getFilterDefinitionFromKey(key);

    if (definition) {
      return definition.inputType;
    }

    return;
  }

  private hasChange(change: any) {
    if (change.key === null || change.op === null) {
      this.change.emit(null);
      return;
    }

    const key = isSelectOption(change.key) ? change.key.value : change.key;
    const op = isSelectOption(change.op) ? change.op.value : change.op;
    const value = change.value;

    this.change.emit({ key, op, value });
  }

  private getKeyFromOptions(option: SelectOption | string | null | undefined): string | undefined {
    if (isSelectOption<string>(option)) {
      return option.value;
    }

    return typeof option === 'string' ? option : undefined;
  }

  private getFilterDefinitionFromKey(key: string | undefined): FilterDefinition | undefined {
    if (key === undefined) {
      return;
    }

    return this.definitions.find(definition => definition.key === key);
  }
}
