import { UpdateGMPDto } from '@application/bundles/gmp';
import { Facility } from '@domain/facility';
import { ResidentGIRList } from '@domain/gir';
import { LocalMedia, Media } from '@domain/media';
import { IsValidFacilityId } from '@implementations/bundles/facility';
import { IsDate, IsDefined, IsNumber, IsOptional, IsPositive } from 'class-validator';

export class UpdateGMPDtoImpl implements UpdateGMPDto {
  @IsNumber()
  @IsPositive()
  public value!: number;

  @IsDate()
  public date!: Date;

  @IsDefined()
  public residents!: ResidentGIRList;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  @IsOptional()
  public pvId?: Media['id'];

  @IsOptional()
  public pv!: LocalMedia | Media;

  constructor(values?: Partial<UpdateGMPDto>) {
    if (values?.date) {
      this.date = values.date;
    }
    if (values?.value) {
      this.value = values.value;
    }

    if (values?.facilityId) {
      this.facilityId = values.facilityId;
    }

    if (values?.pvId) {
      this.pvId = values.pvId;
    }

    if (values?.pv) {
      this.pv = values.pv;
    }
    if (values?.residents) {
      this.residents = values.residents;
    }
  }
}
