import {
  CurrentUserAccessor
} from "@application/bundles/user/implementations/current-user-accessor/current-user.accessor";
import {User} from "@domain/user";
import {ReactiveAccessor} from "@implementations/framework/accessor/reactive-accessor.interface";
import {merge, Observable, of, Subject} from "rxjs";

export class CurrentUserReactiveAccessor implements ReactiveAccessor<User | undefined> {

  private subject: Subject<User | undefined> = new Subject();

  public constructor(private accessor: CurrentUserAccessor) {

    this.accessor.onChange((user) => this.subject.next(user));
  }

  public get(): Observable<User | undefined> {
    return merge(of(this.accessor.get()), this.subject.asObservable());
  }

  public set(item: User | undefined): Observable<void> {
    this.accessor.set(item)
    return of(undefined);
  }

}
