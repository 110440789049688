import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  AuthentificationUrlsProvider
} from "@easyhpad-ui/app/bundles/authentification/providers/urls/authentification-urls.provider";

@Component({
  selector: 'ehp-forgotten-password-page',
  templateUrl: './forgotten-password-page.component.html',
  styleUrls: ['./forgotten-password-page.component.scss'],
  host: {
    'class': 'authentification-page'
  }
})
export class ForgottenPasswordPageComponent {

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly urls: AuthentificationUrlsProvider
  ) {
  }


  public moveToLogin() {
    this.router.navigateByUrl(this.urls.getLogin());
  }
}
