import {
  DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  DAYCARE_DEPENDENCIES_FUNDING_MODES,
  DaycareDependencyFunding,
  DaycareDependencyFundingMode,
  isDaycareDependencyFundingMode
} from "@domain/dependency-funding";
import {isValidObject} from "@application/framework/lib";
import {isValidDaycareDependencyFundingIdType} from "@application/bundles/dependency-funding";
import {isNegativeFundingAmount} from "@domain/funding";

export class DaycareDependencyFundingImpl<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES, V> implements DaycareDependencyFunding<M, V> {

  public id!: number;

  public mode!: DaycareDependencyFundingMode<M>;

  public activityAllowance: number | undefined;

  private readonly _value!: V;

  private _comment: string | undefined;

  public get comment(): string | undefined {
    return this.mode.key === DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.OTHER ? this._comment : undefined;
  }

  public set comment(comment: string | undefined) {

    if (this.mode.key === DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.OTHER) {
      this._comment = comment;
    }

  }

  constructor(defaults?: Partial<Omit<DaycareDependencyFunding<M, V>, 'value'> & { value: V }>) {

    if (isValidObject(defaults)) {

      if (isValidDaycareDependencyFundingIdType(defaults?.id)) {
        this.id = defaults?.id;
      }

      if (isDaycareDependencyFundingMode(defaults?.mode)) {
        this.mode = defaults?.mode;
      }

      if (defaults?.value !== undefined) {
        this._value = defaults?.value;
      }

      if (isNegativeFundingAmount(defaults?.activityAllowance)) {
        this.activityAllowance = defaults?.activityAllowance;
      }

      if (typeof defaults?.comment === 'string' || defaults?.comment === undefined) {
        this._comment = defaults?.comment;
      }
    }
  }

  public value(): V {
    return this._value;
  }

}
