import { Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EPRD } from '@domain/eprd-errd';

@Component({
  selector: 'ehp-eprd-list-item',
  templateUrl: './eprd-list-item.component.html',
  styleUrls: ['./eprd-list-item.component.scss'],
  host: {
    '[class.collapsable]': 'collapsable',
    '[attr.aria-expanded]': 'open.toString()',
  },
})
export class EprdListItemComponent implements OnChanges {
  @Input() public eprd!: EPRD;

  public open: boolean = false;

  public collapsable: boolean = false;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['eprd']) {
      this.eprdHasChange();
    }
  }

  @HostListener('click')
  private clicked() {
    if (this.collapsable) {
      this.open = !this.open;
    }
  }

  private eprdHasChange() {
    if (!this.eprd) {
      return;
    }

    if (this.eprd.details && this.eprd.details.length > 1) {
      this.collapsable = true;
    }
  }
}
