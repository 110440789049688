import {GMPRepository} from "@application/bundles/gmp";
import {GetGMPQuery} from "@application/bundles/gmp/queries/get-gmp.query";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {GMP} from "@domain/gmp";

@HandleQuery({
  query: GetGMPQuery
})
export class GetGMPQueryHandler implements QueryHandler<GetGMPQuery, GMP> {

  constructor(private readonly repository: GMPRepository) {
  }

  public async handle(query: GetGMPQuery): Promise<GMP> {
    return this.repository.get(query.id);
  }
}
