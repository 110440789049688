import {AuthorizationChecker, CRUD_ACTIONS} from "@application/bundles/authorization";
import {Service} from "@application/framework/di";
import {CUSTOMER_FEATURE} from "@application/bundles/customer/customer.token";

@Service()
export class CustomerAuthorizationChecker {


  constructor(public readonly authorizationChecker: AuthorizationChecker) {
  }


  public canManage(): Promise<boolean> {
    return this.authorizationChecker.isGranted([{feature: CUSTOMER_FEATURE, value: CRUD_ACTIONS.MANAGE}])
  }

  public canRead(): Promise<boolean> {
    return this.authorizationChecker.isGranted([{feature: CUSTOMER_FEATURE, value: CRUD_ACTIONS.READ}])
  }

  public canCreate(): Promise<boolean> {
    return this.authorizationChecker.isGranted([{feature: CUSTOMER_FEATURE, value: CRUD_ACTIONS.CREATE}])
  }

  public canUpdate(): Promise<boolean> {
    return this.authorizationChecker.isGranted([{feature: CUSTOMER_FEATURE, value: CRUD_ACTIONS.UPDATE}])
  }

  public canDelete(): Promise<boolean> {
    return this.authorizationChecker.isGranted([{feature: CUSTOMER_FEATURE, value: CRUD_ACTIONS.DELETE}])
  }

}
