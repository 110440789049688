import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isTaxonomyTerm, isValidTaxonomyTermIdType, TaxonomyTerm } from '@domain/taxonomy';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { TreeNodeSelectEvent } from 'primeng/tree';

@Component({
  selector: 'ehp-taxonomy-tree-selector',
  templateUrl: './taxonomy-tree-selector.component.html',
  styleUrl: './taxonomy-tree-selector.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaxonomyTreeSelectorComponent),
      multi: true,
    },
  ],
})
export class TaxonomyTreeSelectorComponent implements OnChanges, ControlValueAccessor {
  @Input() terms: TaxonomyTerm[] = [];

  @Input() placeholder = 'Sélectionner une catégorie';

  public nodes: TreeNode<TaxonomyTerm['id']>[] = [];

  public selected: TaxonomyTerm['id'] | undefined;

  public selectedNode: TreeNode<TaxonomyTerm['id']> | undefined;

  public disabled = false;

  private get flattenNodes(): Array<TreeNode<TaxonomyTerm['id']>> {
    let nodes: Array<TreeNode<TaxonomyTerm['id']>> = [];
    this.nodes.forEach(node => {
      nodes.push(node);

      if (Array.isArray(node.children) && node.children.length > 0) {
        nodes = [...nodes, ...node.children];
      }
    });

    return nodes;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('terms' in changes) {
      this.termsChanges();
    }
  }

  public onChange = (value: TaxonomyTerm['id'] | null | undefined): void => undefined;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: TaxonomyTerm | TaxonomyTerm['id'] | null | undefined): void {
    if (isTaxonomyTerm(value)) {
      this.selected = value.id;
    } else if (isValidTaxonomyTermIdType(value)) {
      this.selected = value;
    } else {
      this.selected = undefined;
    }
    this.updateTreeSelection();
  }

  public onTreeNodeUnselect() {
    this.selected = undefined;
    this.dispatchChange();
  }

  public onTreeNodeSelect(event: TreeNodeSelectEvent) {
    this.selected = event.node.data;
    this.dispatchChange();
  }

  private termsChanges() {
    this.nodes = [];
    const isChildren: Set<TaxonomyTerm['id']> = new Set();

    this.terms.forEach(term => {
      if (isChildren.has(term.id)) {
        return;
      }

      const node: TreeNode<TaxonomyTerm['id']> = {
        data: term.id,
        label: term.name,
      };

      const children = this.terms.filter(t => t.parentId === term.id);

      if (children.length > 0) {
        node.children = children.map(child => ({
          data: child.id,
          label: child.name,
          parent: node,
        }));

        children.forEach(child => isChildren.add(child.id));
      }
      this.nodes.push(node);
    });

    if (this.selected && !this.terms.map(t => t.id).includes(this.selected)) {
      this.selected = undefined;
      this.dispatchChange();
    }

    this.updateTreeSelection();
  }

  private dispatchChange() {
    this.onChange(this.selected);
  }

  private updateTreeSelection() {
    if (this.selected === undefined) {
      this.selectedNode = undefined;
    } else {
      this.selectedNode = this.flattenNodes.find(node => node.data === this.selected);
    }
  }
}
