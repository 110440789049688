import { Pipe, PipeTransform } from '@angular/core';
import { Media, MediaBucket } from '@domain/media';
import { Logger } from '@application/framework/logger';
import { BehaviorSubject, Observable } from 'rxjs';

@Pipe({
  name: 'mediaLoader',
})
export class MediaLoaderPipe implements PipeTransform {
  private pipe = new BehaviorSubject<Media | undefined>(undefined);

  private media: Media | undefined;

  constructor(
    private readonly logger: Logger,
    private readonly bucket: MediaBucket,
  ) {}

  public transform(id: Media['id']): Observable<Media | undefined> {
    if (id === undefined) {
      this.pipe.next(undefined);
    } else if (!this.media || id !== this.media.id) {
      this.loadMedia(id);
    } else {
      this.pipe.next(this.media);
    }

    return this.pipe.asObservable();
  }

  private loadMedia(id: Media['id']): void {
    this.bucket
      .get(id)
      .then(media => {
        this.media = media;
        this.pipe.next(media);
      })
      .catch(e => {
        this.logger.error(e);
        return undefined;
      });
  }
}
