import { FILE_TYPES } from '@application/bundles/media';
import { Facility } from '@domain/facility';
import { LocalMedia, Media } from '@domain/media';
import { TaxonomyTerm } from '@domain/taxonomy';
import { Observable } from 'rxjs';

import { DynamicField } from './dynamic-field';

export interface DynamicMediaField extends DynamicField<Media['id']> {
  /**
   * Allowed file types
   */
  fileTypes?: FILE_TYPES[];

  /**
   * The minimal file count. must be zero or 1.
   */
  min?: number;

  /**
   * Show or hide the uploaded medias in field.
   */
  hideFileList?: boolean;

  /**
   * Used to emit the list changes
   * @param medias
   */
  onChange?: (medias: Array<Media | LocalMedia>) => void;

  /**
   * Used to emit when a media is removed in the component
   * @param medias
   */
  onRemove?: (removed: Media | LocalMedia) => void;
}

export interface DynamicFacilityField extends DynamicField<Facility['id']> {
  scope?: 'active' | 'all';
}

export interface DynamicTaxonomyField extends DynamicField<TaxonomyTerm['id']> {
  /**
   * Update term list
   * @param terms
   */
  setTerms: (terms: TaxonomyTerm[]) => void;

  /**
   * Term list changes
   */
  termsChanges: Observable<TaxonomyTerm[]>;
}
