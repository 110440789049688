import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  DraggableHandleDirective
} from "@easyhpad-ui/app/library/draggable/directives/draggable-handle/draggable-handle.directive";


@NgModule({
  declarations: [
    DraggableHandleDirective
  ],
  exports: [
    DraggableHandleDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DraggableModule {
}
