import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  CPOMDtoFactory,
  CreateCPOMCommand,
  CreateCPOMDto,
  isMaybeAStoredCPOM,
  StoredCPOM,
  UpdateCPOMCommand,
  UpdateCPOMDto,
} from '@application/bundles/cpom';
import { CommandBus } from '@application/framework/command-query';
import { DateParser } from '@application/framework/date/date.parser';
import { CPOM } from '@domain/cpom';
import { Year } from '@domain/lib';
import { isMedia, LocalMedia, Media, MediaBucket } from '@domain/media';
import { CPOMFormBuilder } from '@easyhpad-ui/app/bundles/cpom/services/cpom-edit-form-builder/form-builder';
import { Facility } from '@domain/facility';
import { MediaViewerPreviewList } from '@easyhpad-ui/app/bundles/media';
import { FILE_TYPES, isValidMediaIdType } from '@application/bundles/media';

@Component({
  selector: 'ehp-form-cpom-edit',
  templateUrl: './form-cpom-edit.component.html',
  styleUrls: ['./form-cpom-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormCPOMEditComponent implements OnChanges {
  @Input() public mode: 'create' | 'edit' = 'edit';

  @Input() public cpom: Partial<StoredCPOM | CPOM> | undefined;

  @Output() public onCreation: EventEmitter<StoredCPOM> = new EventEmitter();

  @Output() public onCancel: EventEmitter<void> = new EventEmitter();

  public form!: FormGroup;

  public submitLabel!: string;

  public previewList = new MediaViewerPreviewList();

  public currentMedias: Array<Media> = [];

  public year!: Year | undefined;

  public mimeType = FILE_TYPES.PDF;

  public annexMedias = new Map<Media['id'], Media>();

  protected readonly FormGroup = FormGroup;

  private document: Media | LocalMedia | undefined;

  private localCPOMFacilities: Map<number, Facility | undefined> = new Map();

  public get localCPOMRows(): FormArray<FormGroup> | null {
    return this.form.get('children') as FormArray<FormGroup>;
  }

  public get annexesRows(): FormArray<FormGroup> | null {
    return this.form.get('annexes') as FormArray<FormGroup>;
  }

  constructor(
    private readonly formBuilder: CPOMFormBuilder,
    private readonly commandBus: CommandBus,
    private readonly factory: CPOMDtoFactory,
    private readonly dateParse: DateParser,
    private readonly mediaBucket: MediaBucket,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode'] && changes['mode'].currentValue !== changes['mode'].previousValue) {
      this.rebuildForm();
    }

    if (changes['cpom']) {
      this.cpomHasChange();
    }
  }

  public submit(): void {
    if (!this.form.valid) {
      return;
    }

    if (this.mode === 'create') {
      const dto = this.factory.buildCreateCPOMDtoFromUnsafeValues(
        this.formBuilder.deserializeFormValues<CreateCPOMDto>(this.form.value),
      );

      this.commandBus.execute<StoredCPOM>(new CreateCPOMCommand(dto)).then(cpom => this.onCreation.emit(cpom));
    } else if (this.cpom !== undefined && isMaybeAStoredCPOM(this.cpom)) {
      const dto = this.factory.buildUpdateCPOMDtoFromUnsafeValues({
        ...this.formBuilder.deserializeFormValues<UpdateCPOMDto>(this.form.value),
        documentId: isMedia(this.document) ? this.document.id : undefined,
      });

      this.commandBus
        .execute<StoredCPOM>(new UpdateCPOMCommand(this.cpom?.id ?? '', dto))
        .then(cpom => this.onCreation.emit(cpom));
    }
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public addLocalCPOM() {
    if (this.localCPOMRows) {
      if (this.mode === 'create') {
        this.formBuilder.addCreateLocalCPOMFormRow(this.localCPOMRows);
      } else {
        this.formBuilder.addUpdateLocalCPOMFormRow(this.localCPOMRows);
      }
    }
  }

  public deleteLocalCPOM(index: number) {
    if (this.localCPOMRows) {
      this.formBuilder.deleteLocalCPOMRow(this.localCPOMRows, index);
    }
  }

  public localFacilityHasChange(index: number, facility: Facility | undefined) {
    this.localCPOMFacilities.set(index, facility);
  }

  public getLocalFacility(index: number): Facility | undefined {
    return this.localCPOMFacilities.get(index);
  }

  public updateDocument(medias: Array<LocalMedia | Media>): void {
    this.document = medias[0];
    this.form.get('document')?.setValue(this.document);
    if (this.document) {
      this.pushToMediaPreviewList(this.document);
    }
  }

  public updateAnnexesMedias(medias: Array<LocalMedia | Media>): void {
    const annexes = this.annexesRows;

    if (!annexes) {
      return;
    }

    medias.forEach(media => this.formBuilder.addAnnexRow(annexes, { media }));
  }

  public removeAnnexRow(index: number) {
    const controls = this.annexesRows;

    if (controls) {
      const row = controls.at(index);
      const control = row.get('mediaId');

      if (control) {
        if (control.value) {
          this.removeFromMediaPreviewList(control.value);
        }
      }

      this.formBuilder.removeAnnexRow(controls, index);
    }
  }

  public removeDocument(): void {
    this.form.get('document')?.setValue(undefined);
    if (this.document) {
      this.removeFromMediaPreviewList(this.document);
    }
  }

  private rebuildForm(): void {
    if (this.mode === 'create') {
      this.form = this.formBuilder.buildCreationForm(this.cpom);
      this.submitLabel = 'Enregistrer le C.P.O.M.';
    } else {
      this.form = this.formBuilder.buildUpdateForm(this.cpom);
      this.submitLabel = 'Enregistrer les modifications';
    }

    if (this.form) {
      const dateControl = this.form.get('date');

      if (dateControl) {
        dateControl.valueChanges.subscribe(value => {
          if (value) {
            const date = this.dateParse.fromISOString(value);
            this.year = date.getFullYear();
          } else {
            this.year = undefined;
          }
        });
      }
    }
  }

  private async cpomHasChange(): Promise<void> {
    this.formBuilder.rebuildFormValue(this.form, this.cpom, this.mode !== 'create');

    if (this.cpom !== undefined) {
      if (typeof this.cpom.document === 'function') {
        this.defineMediaDocument(await this.cpom.document());
      }

      this.cpom.annexes?.map(annex => {
        this.mediaBucket.get(annex.mediaId).then(media => {
          this.annexMedias.set(media.id, media);
          this.pushToMediaPreviewList(media);
        });
      });
    }
  }

  private defineMediaDocument(document: Media | undefined): void {
    this.document = document;

    if (document !== undefined) {
      const index = this.currentMedias.filter(isMedia).findIndex(current => current.id === document.id);
      index !== -1 ? (this.currentMedias[index] = document) : this.currentMedias.push(document);
      this.currentMedias = [...this.currentMedias];

      this.pushToMediaPreviewList(document);
    }
  }

  private pushToMediaPreviewList(media: Media | LocalMedia): void {
    const result = this.previewList.search(current => {
      if ('id' in current && 'id' in media && 'id' in current && current.id === media.id) {
        return true;
      }

      return current === media;
    });

    if (result) {
      this.previewList.replace(result.index, media);
    } else {
      this.previewList.add(media);
    }
  }

  private removeFromMediaPreviewList(media: Media | LocalMedia | Media['id']): void {
    const result = this.previewList.search(current => {
      if (isValidMediaIdType(media) && 'id' in current) {
        return current.id === media;
      } else if (isMedia(media) && isMedia(current)) {
        return media.id === current.id;
      }
      return current === media;
    });

    if (result) {
      this.previewList.remove(result.media, true);
    }
  }
}
