<!--<svg id="Calque_2" viewBox="0 0 3240 4850.08"
     xmlns="http://www.w3.org/2000/svg">
  <defs>
    <style>.cls-1 {
      fill: none;
    }

    .cls-2 {
      clip-path: url(#clippath);
    }

    .cls-3 {
      fill: #eaeeee;
    }

    .cls-4 {
      fill: #fff;
    }

    .cls-5 {
      fill: #699e9e;
    }

    .cls-6 {
      fill: #a1874d;
    }</style>
    <clipPath id="clippath">
      <rect class="cls-1" height="4850.08" width="3240" x="0" y="0"/>
    </clipPath>
  </defs>
  <g id="Calque_1-2">
    <g class="cls-2">
      <g>
        <rect class="cls-4" height="4850.08" width="3240" x="0"/>
        <g>
          <g>
            <path class="cls-3" d="M1620,1210.04h-405v405c223.68,0,405-181.32,405-405Z"/>
            <path class="cls-3" d="M2025,1210.04h405v-405c-223.68,0-405,181.32-405,405Z"/>
            <path class="cls-3" d="M2025,4045.04v405s405,0,405,0c0-223.68-181.32-405-405-405Z"/>
            <circle class="cls-3" cx="1012.5" cy="1412.54" r="202.5"/>
          </g>
          <g>
            <path class="cls-5" d="M0-4.96H405V400.04C181.32,400.04,0,218.72,0-4.96Z"/>
            <path class="cls-5" d="M405,2020.04H0v405c223.68,0,405-181.32,405-405Z"/>
            <path class="cls-5" d="M405,1210.04v405h405c0-223.68-181.32-405-405-405Z"/>
            <path class="cls-5" d="M405,2425.04h405v-405c-223.68,0-405,181.32-405,405Z"/>
            <path class="cls-5" d="M810,1615.04v405s405,0,405,0c0-223.68-181.32-405-405-405Z"/>
            <path class="cls-5" d="M810,3640.04h405v-405c-223.68,0-405,181.32-405,405Z"/>
            <path class="cls-5" d="M810,3640.04H405v405c223.68,0,405-181.32,405-405Z"/>
            <path class="cls-5" d="M1620,4450.04v405h-405c0-223.68,181.32-405,405-405Z"/>
            <path class="cls-5" d="M1620,1210.04h405v405c-223.68,0-405-181.32-405-405Z"/>
            <path class="cls-5" d="M2025-4.96h405V400.04c-223.68,0-405-181.32-405-405Z"/>
            <path class="cls-5" d="M2430-4.96h405V400.04c-223.68,0-405-181.32-405-405Z"/>
            <path class="cls-5" d="M2430,3640.04h405v-405c-223.68,0-405,181.32-405,405Z"/>
            <path class="cls-5" d="M2835,2425.04h-405v-405c223.68,0,405,181.32,405,405Z"/>
            <path class="cls-5" d="M2835,2830.04h405v-405c-223.68,0-405,181.32-405,405Z"/>
            <path class="cls-5" d="M2835,3235.04h405v-405c-223.68,0-405,181.32-405,405Z"/>
            <path class="cls-5" d="M3240,4855.04h-405v-405c223.68,0,405,181.32,405,405Z"/>
            <circle class="cls-5" cx="607.5" cy="602.54" r="202.5"/>
            <circle class="cls-5" cx="1417.5" cy="3032.54" r="202.5"/>
          </g>
          <g>
            <path class="cls-6" d="M405,1210.04H0v405c223.68,0,405-181.32,405-405Z"/>
            <path class="cls-6" d="M0,3640.04H405v-405C181.32,3235.04,0,3416.36,0,3640.04Z"/>
            <path class="cls-6" d="M0,4045.04v405H405C405,4226.36,223.68,4045.04,0,4045.04Z"/>
            <path class="cls-6" d="M1215,3640.04v405h-405c0-223.68,181.32-405,405-405Z"/>
            <path class="cls-6" d="M1215,400.04h-405v405c223.68,0,405-181.32,405-405Z"/>
            <path class="cls-6" d="M810,2425.04v810c223.68,0,405-181.32,405-405s-181.32-405-405-405Z"/>
            <path class="cls-6" d="M1215,4450.04h-405v405c223.68,0,405-181.32,405-405Z"/>
            <path class="cls-6" d="M2430,805.04h-405V400.04c223.68,0,405,181.32,405,405Z"/>
            <path class="cls-6" d="M3240,2020.04h-405v405c223.68,0,405-181.32,405-405Z"/>
            <path class="cls-6" d="M2430,2425.04h405v405c-223.68,0-405-181.32-405-405Z"/>
            <path class="cls-6" d="M2430,4045.04h405v-405c-223.68,0-405,181.32-405,405Z"/>
            <circle class="cls-6" cx="3037.5" cy="3842.54" r="202.5"/>
            <circle class="cls-6" cx="607.5" cy="4247.54" r="202.5"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>-->

<ehp-logo></ehp-logo>
