import {GetInitialTrainingValuesQuery} from "@application/bundles/user/queries/get-initial-training-values.query";
import {HandleQuery} from "@application/framework/command-query";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {Provide} from "@application/framework/di";
import {InitialTraining} from "@domain/user/initial-training.interface";
import {InitialTrainingRepository} from "@domain/user/initial-training.repository";

@HandleQuery({
  query: GetInitialTrainingValuesQuery
})
export class GetInitialTrainingValuesHandler implements QueryHandler<GetInitialTrainingValuesQuery, InitialTraining[]> {

  @Provide()
  private readonly repository!: InitialTrainingRepository;

  public async handle(query: GetInitialTrainingValuesQuery): Promise<InitialTraining[]> {
    return this.repository.list();
  }

}
