import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {
  AddInspectionReportDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/add-inspection-report.dto";
import {
  UpdateInspectionReportDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/update-inspection-report.dto";
import {validateOrReject} from "class-validator";

export const validateAddInspectionReportDtoImpl: ValidateFunction<AddInspectionReportDtoImpl> = async (dto) => {
  await validateOrReject(dto);
  return dto;
}

export const validateUpdateInspectionReportDtoImpl: ValidateFunction<UpdateInspectionReportDtoImpl> = async (dto) => {
  await validateOrReject(dto);
  return dto;
}
