import { EasyFacility } from '@implementations/bundles/backend/easyhpad';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { DateString } from '@application/framework/date';
import { UUID } from '@domain/lib';

export interface EasyActivityAnnex {
  id: UUID;

  facilityId: EasyFacility['id'];

  document_id: EasyMedia['id'];

  occupation_rate: number;

  days_realized: number;

  date: DateString;

  date_depot: DateString | null | undefined;
}

export interface EasyCreateActivityAnnexDto extends Omit<EasyActivityAnnex, 'id'> {}

export interface EasyUpdateActivityAnnexDto extends EasyActivityAnnex {}
