import {Store, StoreKey} from "@application/framework/store/store";

export abstract class WindowStorageStoreAdapter<T = string | object> implements Store<T> {


  protected abstract store: Storage;
  private serializedTypeToken = '___storage__object_type__';

  protected constructor(private readonly prefix = '') {
  }

  public get(key: StoreKey): T | undefined {
    return this.unserialize(this.store.getItem(this.prefixedKey(key)));
  }

  public has(key: StoreKey): boolean {
    return !!this.store.getItem(this.prefixedKey(key));
  }

  public remove(key: StoreKey): void {
    this.store.removeItem(this.prefixedKey(key));
  }

  public set(key: StoreKey, value: T): void {
    this.store.setItem(this.prefixedKey(key), this.serialize(value));
  }

  public clear(): void {
    this.store.clear();
  }

  protected storageAvailable(type: 'localStorage' | 'sessionStorage') {
    const storage = window[type];

    try {
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0;
    }
  }

  private serialize(value: any): string {

    if (value === null) {
      value = {[this.serializedTypeToken]: 'null'};
    } else if (value === undefined) {
      value = {[this.serializedTypeToken]: 'undefined'};
    }

    return JSON.stringify(value);
  }

  private unserialize(value: string | null): any | undefined {
    if (value === null) {
      return undefined;
    }

    let unserialized = JSON.parse(value);

    if (unserialized.hasOwnProperty(this.serializedTypeToken)) {
      switch (unserialized[this.serializedTypeToken]) {
        case 'null':
          unserialized = null;
          break;
        case 'undefined':
          unserialized = undefined;
          break;
        default:
          throw new Error('unknown serialized type ' + unserialized[this.serializedTypeToken].toString());
      }
    }

    return unserialized;
  }


  private prefixedKey(key: StoreKey): string {
    return this.prefix ? `${this.prefix}_${key.toString()}` : key.toString();
  }
}
