import {Command} from "@application/framework/command-query";
import {EhpadDemain} from "@domain/ehpad-demain";

export class OpenEhpadDemainDeletionDialogCommand implements Command<void> {

  constructor(
    public readonly document: EhpadDemain,
    public readonly onDeletion?: (document: EhpadDemain) => void
  ) {
  }
}
