import {TransformationFailedError, Transformer} from "@application/framework/core";
import {Job} from "@domain/user/job.interface";
import {SelectOption} from "@implementations/forms/select-option.interface";

export class JobSelectOptionTransformer implements Transformer<Job, SelectOption> {


  /**
   * @inheritDoc
   * @param job
   */
  public transform(job: Job): SelectOption {

    if (!job) {
      throw new TransformationFailedError('Missing job');
    }

    if (!job.id) {
      throw new TransformationFailedError('Missing property "id" in Job');
    }

    if (!job.name) {
      throw new TransformationFailedError('Missing property "name" in Job');
    }

    return {value: job.id, label: job.name};
  }

  /**
   * @inheritDoc
   * @param option
   */
  public reverseTransform(option: SelectOption<string>): Job {
    if (!option) {
      throw new TransformationFailedError('Missing option');
    }

    if (!option.value) {
      throw new TransformationFailedError('Missing property "value" in SelectOption');
    }

    if (!option.label) {
      throw new TransformationFailedError('Missing property "label" in SelectOption');
    }

    return {id: option.value, name: option.label};
  }

}
