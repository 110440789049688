import {TARIFF_OPTIONS, TariffOption, TariffOptionAmountCollection} from "@domain/cpom";
import {Provide} from "@application/framework/di";

export class AgnosticTariffOption implements TariffOption {


  @Provide() private readonly collection!: TariffOptionAmountCollection;

  public get amounts(): TariffOptionAmountCollection {
    return this.collection.getCollection(this.type);
  }

  public constructor(public readonly type: TARIFF_OPTIONS, public readonly label: string) {
  }

}
