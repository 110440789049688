import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {StrategyResult} from "@application/bundles/authentification/abstraction/authentication-strategy";

export class AuthentificationFailEvent extends AbstractDispatchableEvent implements DispatchableEvent {


  public constructor(public readonly reason: string = '', public readonly error?: Error, public readonly result?: StrategyResult) {
    super();
  }
}
