import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ehp-empty-block',
  templateUrl: './empty-block.component.html',
  styleUrls: ['./empty-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmptyBlockComponent {

}
