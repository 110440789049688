import {SocialClearanceRepository} from "@domain/facility/social-clearance.repository";
import {SOCIAL_CLEARANCE, SocialClearance} from "@domain/facility/types";

export class EasySocialClearanceRepository implements SocialClearanceRepository {

  private readonly repository: Map<SOCIAL_CLEARANCE, SocialClearance> = new Map([
    [SOCIAL_CLEARANCE.NONE, {type: SOCIAL_CLEARANCE.NONE, label: 'Aucune'}],
    [SOCIAL_CLEARANCE.PARTIAL_LOW, {type: SOCIAL_CLEARANCE.PARTIAL_LOW, label: 'Partielle, inférieur à 50%'}],
    [SOCIAL_CLEARANCE.PARTIAL_HIGH, {type: SOCIAL_CLEARANCE.PARTIAL_HIGH, label: 'Partielle, supérieur à 50%'}],
    [SOCIAL_CLEARANCE.TOTAL, {type: SOCIAL_CLEARANCE.TOTAL, label: 'Totale'}]
  ]);

  public async list(): Promise<SocialClearance[]> {
    return Array.from(this.repository.values());
  }

  public async get(type: SOCIAL_CLEARANCE): Promise<SocialClearance> {
    const socialClearance = this.repository.get(type);

    if (socialClearance === undefined) {
      throw new Error('Unknown social clearance type');
    }
    return socialClearance;
  }

}
