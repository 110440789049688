import {CssUnitConverter} from "@application/framework/converter/css-unit.converter";
import convert, {CSSUnits} from 'css-unit-converter';


export class CssUnitConverterImpl implements CssUnitConverter {

  constructor(private readonly window: Window) {
  }

  public getUnit(value: string): string | null {
    const len = value.length;

    if (!value || !len) {
      return null;
    }


    let i = len
    while (i--) {
      if (!isNaN(Number(value[i]))) {
        return value.slice(i + 1, len) || null;
      }
    }

    return null;
  }

  public toPx(value: string): number | null {
    const unit = this.getUnit(value);

    if (!unit) {
      return null;
    }

    return convert(parseFloat(value), unit as CSSUnits, 'px', 10);
  }

  public toVw(value: string): number | null {
    const unit = this.getUnit(value);

    if (!unit) {
      return null;
    }

    const px = convert(parseFloat(value), unit as CSSUnits, 'px', 10);

    return (px * 100 / this.getWindowWidth());
  }

  public toVh(value: string): number | null {
    const unit = this.getUnit(value);

    if (!unit) {
      return null;
    }

    const px = convert(parseFloat(value), unit as CSSUnits, 'px', 10);
    return (px * 100 / this.getWindowHeight());
  }

  private getWindowWidth(): number {
    return this.window.innerWidth;
  }

  private getWindowHeight(): number {
    return this.window.innerHeight;
  }
}
