import {Attribute, AuthorizationChecker} from "@application/bundles/authorization";
import {ForbiddenError} from "@application/bundles/authorization/error";
import {UpdateCustomerCommand} from "@application/bundles/customer/commands/update-customer.command";
import {CUSTOMER_FEATURE, CUSTOMER_TRANSLATE_CONTEXT} from "@application/bundles/customer/customer.token";
import {UpdateCustomerDto} from "@application/bundles/customer/dto/update-customer.dto";
import {MissingCustomerIdError} from "@application/bundles/customer/errors";
import {
  CustomerUpdateFailEvent
} from "@application/bundles/customer/events/customer-updated/customer-update-fail.event";
import {
  CustomerUpdatedSuccessEvent
} from "@application/bundles/customer/events/customer-updated/customer-updated-success.event";
import {HandleCommand} from "@application/framework/command-query";
import {CommandHandler} from "@application/framework/command-query/handler.interface";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {SanitizationFailError} from "@application/framework/sanitizer/errors";
import {SanitizerLibrary} from "@application/framework/sanitizer/sanitizer";
import {Translator} from "@application/framework/translation";
import {ValidationError} from "@application/framework/validator/errors/validation.error";
import {ObjectValidator} from "@application/framework/validator/object-validator";
import {Customer} from "@domain/customer";
import {CustomerRepository} from "@application/bundles/customer";

@HandleCommand({
  command: UpdateCustomerCommand
})
export class UpdateCustomerCommandHandler implements CommandHandler<UpdateCustomerCommand, Customer> {


  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  public constructor(
    private readonly repository: CustomerRepository,
    private readonly authorization: AuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly translator: Translator,
  ) {
  }

  public async handle(command: UpdateCustomerCommand): Promise<Customer> {

    const {id, update} = command;

    try {
      this.checkId(id);
      await this.checkAccess(update);

      const updated = await this.sanitize(update)
        .then(update => this.validate(update))
        .then(update => this.repository.update(id, update));

      this.eventDispatcher.dispatch(new CustomerUpdatedSuccessEvent(updated));

      return updated;
    } catch (e) {
      const error = this.catchError(e);
      return Promise.reject(error);
    }
  }


  private checkId(id: Customer['id']) {
    if (!id) {
      throw new MissingCustomerIdError();
    }
  }

  private async checkAccess(dto: UpdateCustomerDto): Promise<void> {
    const attributes: Attribute[] = [
      {feature: CUSTOMER_FEATURE, value: 'update'}
    ];

    if (!await this.authorization.isGranted(attributes, dto)) {
      this.logger.error('Customer update : Forbidden');
      throw new ForbiddenError();
    }
  }

  private async sanitize(dto: UpdateCustomerDto): Promise<UpdateCustomerDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('Customer update : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdateCustomerDto): Promise<UpdateCustomerDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('Customer update : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {

      message = await this.translator.translate(
        'Une erreur est survenue lors de la vérification des données.',
        undefined,
        CUSTOMER_TRANSLATE_CONTEXT
      );

    } else if (error instanceof ForbiddenError) {

      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à créer un nouveau client.",
        undefined,
        CUSTOMER_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new CustomerUpdateFailEvent(message));

    return error;
  }

}
