import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {EPRD} from "@domain/eprd-errd";

export class EPRDUpdatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly eprd: EPRD) {
    super();
  }
}
