import {ErrorHandler} from "@application/framework/error/error.handler";
import {Logger} from "@application/framework/logger";

export class AgnosticErrorHandler implements ErrorHandler {


  private readonly logger: Logger;

  public constructor(logger: Logger) {
    this.logger = logger.channel(this.constructor.name);
  }

  public handle(error: any): void {
    this.logger.error(error);
  }

}
