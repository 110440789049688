import { Media } from '@domain/media';
import { Facility } from '@domain/facility';
import { UUID, Year } from '@domain/lib';

export interface ActivityAnnex {
  id: UUID;

  date: Date;

  depositDate: Date | undefined;

  readonly year: Year;

  days: number;

  occupationRate: number;

  documentId: Media['id'];

  facilityId: Facility['id'];

  document(): Promise<Media>;

  facility(): Promise<Facility>;
}
