import {
  CreateGMPCommandHandler,
  DeleteGMPCommandHandler,
  UpdateGMPCommandHandler,
} from '@application/bundles/gmp/commands/handlers';
import { OnGmpEventsSubscriber } from '@application/bundles/gmp/events/subscribers';
import {
  GetCurrentGMPQueryHandler,
  GetGMPQueryHandler,
  GetLatestGmpCollectionQueryHandler,
  HasGMPForYearQueryHandler,
  ListGMPQueryHandler,
  ListLatestDepartmentGMPIndicatorForFacilityQueryHandler,
} from '@application/bundles/gmp/queries/handlers';
import { Bundle } from '@application/framework/bundle';
import { Service } from '@application/framework/di';

@Bundle({
  commandHandlers: [CreateGMPCommandHandler, UpdateGMPCommandHandler, DeleteGMPCommandHandler],
  queryHandlers: [
    ListGMPQueryHandler,
    GetGMPQueryHandler,
    GetCurrentGMPQueryHandler,
    HasGMPForYearQueryHandler,
    GetLatestGmpCollectionQueryHandler,
    ListLatestDepartmentGMPIndicatorForFacilityQueryHandler,
  ],
  eventSubscribers: [OnGmpEventsSubscriber],
})
@Service()
export class GmpBundle {
  constructor() {}
}
