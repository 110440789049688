import {CRUD_ACTIONS} from "@application/bundles/authorization";

export interface Permission<F = string, T = string> {

  feature: F;

  type: T & CRUD_ACTIONS;

  owned: boolean;

}


export abstract class PermissionCollection {

  public abstract has<F, E = string>(feature: F, type: E): boolean ;

  public abstract all(): Array<Permission>;
}
