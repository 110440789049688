import {NullContainer} from "@application/framework/di/containers/null-container";
import {isLazyContainer} from "@application/framework/di/functions/is-lazy-container.function";
import {Container} from './container';
import {ContainerDefaultAlreadyRegisteredError, InvalidContainerError, SameContainerIdError} from "./errors";
import {ContainerIdentifier} from "./types/container-identifier.type";

const DEFAULT_CONTAINER_ID = Symbol('DEFAULT_CONTAINER_ID');

export class ContainerRegistry {

  public static readonly containerMap: Map<ContainerIdentifier, Container> = new Map();

  private static _defaultContainer: Container = new NullContainer(DEFAULT_CONTAINER_ID);

  public static get defaultContainer(): Container {

    if (!ContainerRegistry._defaultContainer) {
      throw new InvalidContainerError('Default container is not provided');
    }

    return ContainerRegistry._defaultContainer;
  }

  public static set defaultContainer(container: Container) {

    if (!!ContainerRegistry._defaultContainer) {
      if (isLazyContainer(ContainerRegistry._defaultContainer) && !ContainerRegistry._defaultContainer.containerIsAlreadyMounted()) {
        ContainerRegistry._defaultContainer.mountContainer(container);
        ContainerRegistry._defaultContainer = ContainerRegistry._defaultContainer.getContainer();
        return;
      }

      throw new ContainerDefaultAlreadyRegisteredError('Default container is already registered');
    }

    ContainerRegistry._defaultContainer = container;

  }

  public static registerContainer(container: Container): void {

    if (!(container instanceof Container)) {
      throw new InvalidContainerError('Only Container instances can be registered.');
    }

    if (!!ContainerRegistry.defaultContainer && container.id === ContainerRegistry.defaultContainer.id) {
      throw new ContainerDefaultAlreadyRegisteredError(`You cannot register a container with the "${ContainerRegistry.defaultContainer.id.toString()}" ID`)
    }

    if (ContainerRegistry.containerMap.has(container.id)) {
      throw new SameContainerIdError(`A container with ID ${container.id.toString()} is already registered`);
    }

    ContainerRegistry.containerMap.set(container.id, container);
  }

  public static hasContainer(id: ContainerIdentifier): boolean {
    return ContainerRegistry.containerMap.has(id);
  }

  public static getContainer(id: ContainerIdentifier): Container {

    if (id === ContainerRegistry.defaultContainer.id) {
      return ContainerRegistry.defaultContainer;
    }

    const registeredContainer = this.containerMap.get(id);

    if (registeredContainer === undefined) {
      throw new InvalidContainerError('No container is registered with the given ID.');
    }

    return registeredContainer;
  }

  public static async removeContainer(container: Container): Promise<void> {
    const registeredContainer = ContainerRegistry.containerMap.get(container.id);

    if (registeredContainer === undefined) {
      throw new InvalidContainerError('No container is registered with the given ID.');
    }

    /** We remove the container first. */
    ContainerRegistry.containerMap.delete(container.id);

    /** We dispose all registered classes in the container. */
    await registeredContainer.dispose();
  }
}
