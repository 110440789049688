import { LocalMedia, Media } from '@domain/media';
import { AccommodationFunding } from '@domain/accommodation-funding';

export interface CreateAccommodationFundingDto
  extends Omit<
    AccommodationFunding,
    'id' | 'pvId' | 'reportId' | 'amount' | 'year' | 'facility' | 'accountingYear' | 'createdAt' | 'updatedAt'
  > {
  pvId?: Media['id'];

  pv: LocalMedia;

  reportId?: Media['id'];

  report?: LocalMedia;
}

export interface UpdateAccommodationFundingDto
  extends Omit<
    AccommodationFunding,
    'pvId' | 'reportId' | 'accountingYear' | 'amount' | 'year' | 'createdAt' | 'updatedAt'
  > {
  pvId?: Media['id'];

  pv?: LocalMedia | Media;

  reportId?: Media['id'];

  report?: LocalMedia | Media;
}
