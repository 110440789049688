import {
  CRPP,
  EPRD,
  FinancialAnnex,
  isFinancialAnnex,
  isFinancialAnnexData,
  LocalEPRD,
  PartialActivityAnnex,
} from '@domain/eprd-errd';
import { GetEPRDQuery, isValidLocalEPRDIdType } from '@application/bundles/eprd-errd';
import { isPositiveFundingAmount, isValidFundingAmount } from '@domain/funding';
import { Facility } from '@domain/facility';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { Provide } from '@application/framework/di';
import { QueryBus } from '@application/framework/command-query';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { FinancialAnnexImpl } from '@implementations/bundles/eprd-errd/implementations/financial-annex.implementation';
import { isPartialActivityAnnex } from '@domain/activity-annex';
import { UUID } from '@domain/lib';

export class LocalEPRDImpl implements LocalEPRD {
  public id!: UUID;

  public expense: number = 0;

  public revenue: number = 0;

  public CAF: number = 0;

  public activityAnnex!: PartialActivityAnnex;

  public financialAnnex!: FinancialAnnex;

  public CRPP: CRPP[] = [];

  public facilityId!: Facility['id'];

  public parentId!: EPRD['id'];

  @Provide()
  private readonly queryBus!: QueryBus;

  private _parent: EPRD | undefined;

  public get parent(): EPRD {
    if (!this._parent) {
      throw new Error(`parent is missing in ${this.constructor.name} (${this.id})`);
    }
    return this._parent;
  }

  constructor(defaults?: Partial<Omit<LocalEPRD, 'facility' | 'parent' | 'refreshParent' | 'occupationRate'>>) {
    if (defaults) {
      if (isValidLocalEPRDIdType(defaults.id)) {
        this.id = defaults.id;
      }

      if (isValidFacilityIdType(defaults.facilityId)) {
        this.facilityId = defaults.facilityId;
      }

      if (isPositiveFundingAmount(defaults.expense)) {
        this.expense = defaults.expense;
      }

      if (isPositiveFundingAmount(defaults.revenue)) {
        this.revenue = defaults.revenue;
      }

      if (isValidFundingAmount(defaults.CAF)) {
        this.CAF = defaults.CAF;
      }

      if (isPartialActivityAnnex(defaults.activityAnnex)) {
        this.activityAnnex = defaults.activityAnnex;
      }

      if (isFinancialAnnex(defaults.financialAnnex)) {
        this.financialAnnex = defaults.financialAnnex;
      } else if (isFinancialAnnexData(defaults.financialAnnex)) {
        this.financialAnnex = new FinancialAnnexImpl(defaults.financialAnnex);
      }

      if (Array.isArray(defaults.CRPP)) {
        this.CRPP = defaults.CRPP;
      }
    }
  }

  public facility(): Promise<Facility> {
    return this.queryBus.request(new GetFacilityQuery(this.facilityId));
  }

  public occupationRate(): number | undefined {
    return undefined;
  }

  public refreshParent(): Promise<EPRD> {
    if (this._parent) {
      return Promise.resolve(this._parent);
    }

    return this.queryBus.request<EPRD>(new GetEPRDQuery(this.parentId)).then(parent => {
      this._parent = parent;
      return parent;
    });
  }
}
