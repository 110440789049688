import {InitialTraining} from "@domain/user/initial-training.interface";
import {InitialTrainingRepository} from "@domain/user/initial-training.repository";
import {NotFoundError} from "rxjs";

export class InitialTrainingRepositoryImpl extends InitialTrainingRepository {

  private readonly initialTrainings: InitialTraining[] = [
    {id: "management", level: 'Management'},
    {id: "cares", level: "Santé"},
    {id: "legal", level: "Juridique"},
    {id: "other", level: "Autres"}
  ];

  public async list(): Promise<InitialTraining[]> {
    return [...this.initialTrainings];
  }

  public async get(id: InitialTraining["id"]): Promise<InitialTraining> {
    const level = this.initialTrainings.find(level => level.id === id);

    if (!level) {
      throw new NotFoundError(`Initial training with id ${id} is not found`);
    }

    return {id: level.id, level: level.level};
  }

}
