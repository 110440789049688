import { TranslatableString } from '@application/framework/translation';
import { BehaviorSubject } from 'rxjs';
import { DocumentEditViewContainer } from '@easyhpad-ui/app/bundles/documents/contracts/document-edit-view';
import { Document } from '@domain/document';

export abstract class DocumentEditViewProcessorAbstract<D extends Document, S = any> {
  /**
   * @inheritDoc
   */
  public readonly title = new BehaviorSubject<Array<string | TranslatableString>>([]);

  protected constructor(protected readonly parent: DocumentEditViewContainer) {
    this.mountComponents();
  }

  /**
   * Get the document title trails.
   * @param document
   */
  public abstract getDocumentTitle(document?: D): Array<string | TranslatableString>;

  /**
   * Action called when submit button is clicked
   */
  public abstract mountComponents(): void;
}
