import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  DynamicAbstractControlField,
  DynamicField,
  DynamicFieldGroup,
  DynamicFormElementType,
  DynamicFormElementTypeComponent,
  DynamicHTMLGroup,
  DynamicNoopField,
  WithActivation,
  WithCssClass,
} from '@easyhpad-ui/app/library/form/contracts';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { camelToDashCase, values } from '@domain/lib';
import { BehaviorSubject, isObservable, Subject, takeUntil } from 'rxjs';
import { DynamicFormSummary } from '@easyhpad-ui/app/library/form/contracts/dynamic-summary';

@Component({
  selector: 'ehp-dynamic-form-element',
  templateUrl: './dynamic-form-element.component.html',
  styleUrl: './dynamic-form-element.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormElementComponent implements OnInit, AfterViewInit, OnDestroy {
  @ProvideLogger() public logger!: Logger;

  @Input() public field!: DynamicFormElementType<any>;

  /**
   * Single field container
   */
  @ViewChild('controlContainer', { read: ViewContainerRef })
  public controlContainer: ViewContainerRef | undefined;

  /**
   * HTML group controls container
   */
  @ViewChild('groupControlContainer', { read: ViewContainerRef })
  public groupControlContainer: ViewContainerRef | undefined;

  /**
   * Define subfields if field is an htmlGroup
   */
  public fields: Array<DynamicFormElementType<any>> | undefined;
  public visible = true;
  /**
   * Child view instances
   * @private
   */
  private instances = new Set<{ destroy: () => void }>();
  /**
   *
   * @private
   */
  private activationChanges = new BehaviorSubject<boolean>(true);
  /**
   * Component destruction emitter
   * @private
   */
  private destroy$ = new Subject<void>();
  private isRender = false;

  /**
   * Current activation state
   */
  public get isActivate(): boolean {
    return this.activationChanges.value;
  }

  @HostBinding('class')
  public get classes(): string {
    const classes: string[] = ['form-element', 'dynamic-form-element'];

    if (this.field) {
      classes.push(`type-${camelToDashCase(this.field.formElementType)}`);
    }

    if (!this.isActivate) {
      classes.push(`deactivate`);
    }

    if (typeof (this.field as any).cssClass === 'string' && (this.field as WithCssClass).cssClass !== '') {
      classes.push((this.field as WithCssClass).cssClass as string);
    }

    return classes.join(' ');
  }

  constructor(private readonly cd: ChangeDetectorRef) {}

  /**
   * Use to get the correct type in template.
   * @param field
   */
  public $htmlGroup(field: DynamicFormElementType<any>): DynamicFormElementType<'htmlGroup'> {
    return field as DynamicFormElementType<'htmlGroup'>;
  }

  public ngOnInit() {
    this.observeActivation();
  }

  public ngAfterViewInit() {
    this.renderFieldComponent();
    this.cd.detectChanges();
  }

  public ngOnDestroy() {
    this.destroyFieldComponent();
    this.destroy$.next();
  }

  private renderFieldComponent() {
    if (this.isRender || !this.isActivate) {
      return;
    }
    switch (this.field.formElementType) {
      case 'field':
        this.createFieldComponent();
        break;
      case 'htmlGroup':
        this.createHtmlGroup();
        break;
      case 'group':
        this.createGroupComponent();
        break;
      case 'summary':
        this.createSummaryComponent();
        break;
      case 'noop':
        this.createNoopComponent();
        break;
      default:
        this.logger.warning(
          `Unknown formElementType "${this.field.formElementType}" to render in ${this.constructor.name}`,
        );
    }
    this.isRender = true;
  }

  private destroyFieldComponent(): void {
    this.instances.forEach(instance => instance.destroy());
    this.isRender = false;
  }

  private createFieldComponent() {
    const resolver = (this.field as DynamicField<any>).component;

    if (typeof resolver !== 'function' || !resolver()) {
      throw new Error(`Missing component resolver for field`);
    }

    this.createSingleComponentInstance(resolver(), this.field);
  }

  private createGroupComponent() {
    const field = this.field as DynamicFieldGroup<any>;

    if (typeof field.component !== 'function') {
      throw new Error(`Missing component resolver for DynamicFieldGroup`);
    }

    const component = field.component();

    if (component === undefined) {
      values(field.fields).forEach(f => {
        this.createSingleComponentInstance(DynamicFormElementComponent, f);
      });
    } else {
      this.createSingleComponentInstance(component, field);
    }
  }

  private createHtmlGroup() {
    const field = this.field as DynamicHTMLGroup<any>;
    this.fields = values(field.fields);

    this.fields.forEach(f => {
      if (!this.groupControlContainer) {
        this.logger.warning(`Missing ViewContainerRef "groupControlContainer" in ${this.constructor.name}`, this);
        return;
      }

      if (!this.isActivate) {
        return;
      }

      this.createComponentInstance(DynamicFormElementComponent, this.groupControlContainer, f);
    });
  }

  private createSummaryComponent(): void {
    const field = this.field as DynamicFormSummary;

    if (typeof field.component !== 'function') {
      throw new Error(`Missing component resolver for DynamicFormSummary`);
    }

    this.createSingleComponentInstance(field.component(), field);
  }

  private createSingleComponentInstance(
    component: Type<DynamicFormElementTypeComponent<any>>,
    field: DynamicFormElementType<any>,
  ) {
    if (!this.controlContainer) {
      this.logger.warning(`Missing ViewContainerRef "controlContainer" in ${this.constructor.name}`, this);
      return;
    }

    this.createComponentInstance(component, this.controlContainer, field);
  }

  private createComponentInstance(
    component: Type<DynamicFormElementTypeComponent<any>>,
    container: ViewContainerRef,
    field: DynamicFormElementType<any>,
  ): void {
    if (!container) {
      this.logger.warning(`Missing ViewContainerRef in ${this.constructor.name}`, this);
      return;
    }

    const instance = container.createComponent(component);
    instance.setInput('field', field);
    this.instances.add(instance);
  }

  /**
   * If activation observable provided in field,
   * subscribe and update component activation state
   * @private
   */
  private observeActivation() {
    const activation = (this.field as WithActivation).activation;

    if (!isObservable(activation)) {
      return;
    }

    activation.pipe(takeUntil(this.destroy$)).subscribe(isActivate => this.updateActivationState(isActivate));
  }

  private updateActivationState(isActivate: boolean): void {
    if (this.isActivate === isActivate) {
      return;
    }

    const previous = this.isActivate;
    this.activationChanges.next(isActivate);

    if (isActivate && !previous) {
      this.renderFieldComponent();
    } else if (!isActivate && previous) {
      this.destroyFieldComponent();
    }

    this.cd.detectChanges();
  }

  private createNoopComponent() {
    this.instances.add(new NoopField(this.field as any));
  }
}

class NoopField {
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly field: DynamicAbstractControlField<DynamicNoopField>) {
    if (isObservable(this.field.initialValue)) {
      this.field.initialValue.pipe(takeUntil(this.destroy$)).subscribe(value => this.setInitialValue(value));
    }
  }

  public destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setInitialValue(value: any) {
    if (!this.field.control.value && (value === undefined || value === null)) {
      return;
    }

    this.field.control.setValue(value);
  }
}
