import { CreateUser } from '@domain/user';
import { IsUniqueEmail } from '@implementations/bundles/user/validators/unique-email/class-validator-unique-email.validator';
import { IsArray, IsNotEmpty, IsOptional, Validate } from 'class-validator';
import { UserCommonDtoAbstract } from './user-common.dto.abstract';
import { Role } from '@domain/authorization';

export class CreateUserDtoImpl extends UserCommonDtoAbstract implements CreateUser {
  @Validate(IsUniqueEmail)
  public override email: string = '';

  @IsOptional()
  @IsArray()
  @IsNotEmpty()
  public roles?: Array<Role>;
}
