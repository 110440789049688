import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Facility } from '@domain/facility';
import { selectCurrentFacilitiesForAccount } from '@easyhpad-ui/app/bundles/facility/store';
import { AppState } from '@easyhpad-ui/app/store';
import { Store } from '@ngrx/store';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ehp-multi-current-facility-filter',
  templateUrl: './multi-current-facility-filter.component.html',
  styleUrl: './multi-current-facility-filter.component.scss',
})
export class MultiCurrentFacilityFilterComponent implements OnDestroy {
  @Input() public label: string = '';

  @Output() public readonly onChange: EventEmitter<Facility[]> = new EventEmitter();

  public facilities: Array<Facility> = [];

  public selection: Array<Facility['id']> = [];

  public disabled = false;

  public readonly optionLabel = 'name';

  private readonly subscription: Subscription;

  constructor(private readonly store: Store<AppState>) {
    this.subscription = this.store
      .select(selectCurrentFacilitiesForAccount)
      .subscribe(facilities => this.updateFacilities(facilities));
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Emit the selection after change in multi-select.
   * @param change
   */
  public selectChange(change: MultiSelectChangeEvent) {
    this.emit(change.value);
  }

  /**
   * Set current facilities and emit the new selection
   * @param facilities - The facilities.
   * @private
   */
  private updateFacilities(facilities: Facility[]) {
    this.facilities = facilities;
    const ids = facilities.map(f => f.id);

    if (this.selection.length > 0) {
      this.select(ids);
    } else {
      this.emit(ids);
    }
  }

  /**
   * Filter the facility list.
   * @param ids
   * @private
   */
  private find(ids: Array<Facility['id']>): Facility[] {
    return this.facilities.filter(facility => ids.includes(facility.id));
  }

  /**
   * Set a new selection and emit the selected facilities.
   * @param ids - The new selection.
   * @private
   */
  private select(ids: Array<Facility['id']>): void {
    this.selection = [...ids];
    this.emit(this.selection);
  }

  /**
   * Emit ths facilities or a list of filtered facilities.
   * @param ids - Ids of facility to emit.
   * @private
   */
  private emit(ids?: Array<Facility['id']>): void {
    const facilities = Array.isArray(ids) && ids.length > 0 ? this.find(ids) : [...this.facilities];
    this.onChange.emit(facilities);
  }
}
