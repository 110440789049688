import { Transformer } from '@application/framework/core';
import {
  CreateEasyRecurringHealthFundingDto,
  EasyRecurringHealthFunding,
  UpdateEasyRecurringHealthFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/health-funding/easy-recurring-health-funding';
import { RecurringHealthFunding } from '@domain/health-funding';
import { Common } from '@application/framework/lib';
import {
  CreateRecurringHealthFundingDto,
  RecurringHealthFundingDtoFactory,
  UpdateRecurringHealthFundingDto,
} from '@application/bundles/health-funding';
import { Service } from '@application/framework/di';

type CommonProperties = Common<
  RecurringHealthFunding,
  Common<CreateRecurringHealthFundingDto, UpdateRecurringHealthFundingDto>
>;

type EasyCommonProperties = Common<
  EasyRecurringHealthFunding,
  Common<CreateEasyRecurringHealthFundingDto, UpdateEasyRecurringHealthFundingDto>
>;

@Service()
export class RecurringHealthFundingTransformer
  implements Transformer<EasyRecurringHealthFunding, RecurringHealthFunding>
{
  constructor(private readonly dtoFactory: RecurringHealthFundingDtoFactory) {}

  public transform(value: EasyRecurringHealthFunding): RecurringHealthFunding {
    return {
      ...this.transformCommonProperties(value),
      id: value.id,
    };
  }

  public transformCreateDto(value: CreateEasyRecurringHealthFundingDto): CreateRecurringHealthFundingDto {
    return this.dtoFactory.buildCreateDtoFromUnsafeValues({
      ...this.transformCommonProperties(value),
    });
  }

  public transformUpdateDto(value: UpdateEasyRecurringHealthFundingDto): UpdateRecurringHealthFundingDto {
    return this.dtoFactory.buildUpdateDtoFromUnsafeValues({
      ...this.transformCommonProperties(value),
      id: value.id,
    });
  }

  public reverseTransform(value: RecurringHealthFunding): EasyRecurringHealthFunding {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
    };
  }

  public reverseTransformCreateDto(value: CreateRecurringHealthFundingDto): CreateEasyRecurringHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value),
    };
  }

  public reverseTransformUpdateDto(value: UpdateRecurringHealthFundingDto): UpdateEasyRecurringHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
    };
  }

  private transformCommonProperties(value: EasyCommonProperties): CommonProperties {
    return {
      amount: value.amount,
      name: value.name || undefined,
      categoryId: value.categoryId ?? undefined,
    };
  }

  private reverseTransformCommonProperties(value: CommonProperties): EasyCommonProperties {
    return {
      amount: value.amount,
      name: value.name,
      categoryId: value.categoryId,
    };
  }
}
