import { isValidObject } from '@application/framework/lib';
import { LocalEPRD } from '@domain/eprd-errd';
import { isUUID } from '@domain/lib';

export function isValidLocalEPRDIdType(id: any): id is LocalEPRD['id'] {
  return isUUID(id);
}

export function isMaybeALocalEPRD(eprd: any): eprd is { id: LocalEPRD['id'] } {
  return isValidObject(eprd) && 'id' in eprd && isValidLocalEPRDIdType((eprd as LocalEPRD).id);
}
