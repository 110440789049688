import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ContentChild, Input, ViewEncapsulation } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FormControlDirective } from '@easyhpad-ui/app/library/form/directives/form-control/form-control.directive';

@Component({
  selector: 'ehp-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'form-field',
    '[class.has-errors]': 'hasErrors',
    '[class.required]': 'required',
    '[class.pending-validation]': 'pending',
    '[class.disabled]': 'disabled',
  },
})
export class FormFieldComponent {
  @ContentChild(FormControlDirective) public field!: FormControlDirective;

  @Input()
  public prefix: string | undefined = '';

  @Input()
  public suffix: string | undefined = '';

  public get hasErrors() {
    return this.field && this.field.invalid && (this.field.dirty || this.field.touched);
  }

  public get required() {
    return this.field?.required;
  }

  public get pending() {
    return this.field?.pending;
  }

  public get disabled() {
    return this.field?.disabled;
  }

  public get async() {
    return this.field?.async;
  }

  public get errors(): ValidationErrors | null {
    return this.field && this.field.errors;
  }

  private _displayErrors: boolean = true;

  @Input()
  public get displayErrors(): boolean {
    return this._displayErrors;
  }

  public set displayErrors(display: any) {
    this._displayErrors = coerceBooleanProperty(display);
  }
}
