import { Query } from '@application/framework/command-query';
import { Customer } from '@domain/customer';
import { Facility } from '@domain/facility';
import { DOCUMENT_TYPES } from '@domain/document';

export class ListLibraryQuery implements Query {
  constructor(
    public readonly search?: {
      customer?: Customer['id'];
      facilities?: Array<Facility['id']>;
      scopes?: Array<DOCUMENT_TYPES>;
    },
  ) {}
}
