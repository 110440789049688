import { createSelector } from '@ngrx/store';
import { CustomerStoreState } from './customer.state';
import { CUSTOMER_STORE_KEY } from './customer.key';
import { AppState } from '@easyhpad-ui/app/store';

export const selectCustomerState = (state: AppState) => state[CUSTOMER_STORE_KEY];

export const selectCustomerAdmin = createSelector(selectCustomerState, (state: CustomerStoreState) => state.admin);

export const selectCustomerAdminLoading = createSelector(
  selectCustomerAdmin,
  (state: CustomerStoreState['admin']) => state.loading,
);

export const selectCustomerAdminEntities = createSelector(
  selectCustomerAdmin,
  (state: CustomerStoreState['admin']) => state.entities,
);

export const selectCustomerCurrent = createSelector(selectCustomerState, (state: CustomerStoreState) => state.current);

export const selectCustomerCurrentSelected = createSelector(
  selectCustomerCurrent,
  (state: CustomerStoreState['current']) => state.selected,
);

export const selectCustomerCurrentAvailable = createSelector(
  selectCustomerCurrent,
  (state: CustomerStoreState['current']) => state.available,
);
