import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FacilityTypeRepository } from '@domain/facility/facility-type.repository';
import { FACILITY_TYPE } from '@domain/facility/types';

@Component({
  selector: 'ehp-facility-type-label',
  templateUrl: './facility-type-label.component.html',
  styleUrl: './facility-type-label.component.scss',
})
export class FacilityTypeLabelComponent implements OnChanges {
  @Input() public value: FACILITY_TYPE | undefined;

  public label: string | undefined;

  constructor(private readonly repository: FacilityTypeRepository) {}

  public ngOnChanges(changes: SimpleChanges) {
    if ('value' in changes) {
      this.updateLabel();
    }
  }

  private updateLabel() {
    if (!this.value) {
      this.label = undefined;
      return;
    }

    this.repository.get(this.value).then(type => (this.label = type.label));
  }
}
