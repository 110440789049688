import {DependencyFundingRepository, HasDependencyFundingForYearQuery} from "@application/bundles/dependency-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";

@HandleQuery({
  query: HasDependencyFundingForYearQuery
})
export class HasDependencyFundingForYearQueryHandler implements QueryHandler<HasDependencyFundingForYearQuery, boolean> {

  constructor(private readonly repository: DependencyFundingRepository) {
  }

  public async handle(query: HasDependencyFundingForYearQuery): Promise<boolean> {
    throw new Error(`handle method not implemented in ${this.constructor.name}`);
  }
}
