import { HandleQuery, QueryBus, QueryHandler } from '@application/framework/command-query';
import {
  DepartmentGMPIndicatorRepository,
  ListLatestDepartmentGMPIndicatorForFacilityQuery,
} from '@application/bundles/gmp';
import { ListFacilitiesQuery } from '@application/bundles/facility/query/list-facilities.query';
import { GeoService } from '@application/bundles/geo/services/geo.service';
import { Facility } from '@domain/facility';
import { Department, DepartmentCode, isMaybeADepartment } from '@domain/geo';
import { ConsolidatedDepartmentGMPIndicator } from '@domain/gmp';

@HandleQuery({
  query: ListLatestDepartmentGMPIndicatorForFacilityQuery,
})
export class ListLatestDepartmentGMPIndicatorForFacilityQueryHandler
  implements QueryHandler<ListLatestDepartmentGMPIndicatorForFacilityQuery>
{
  constructor(
    private readonly queryBus: QueryBus,
    private readonly geoService: GeoService,
    private readonly repository: DepartmentGMPIndicatorRepository,
  ) {}

  public async handle(
    query: ListLatestDepartmentGMPIndicatorForFacilityQuery,
  ): Promise<ConsolidatedDepartmentGMPIndicator[]> {
    const facilities = await this.queryBus.request<Facility[]>(
      new ListFacilitiesQuery({ search: { id: query.facilityIds } }),
    );

    const codes: DepartmentCode[] = await Promise.all(facilities.map(f => this.geoService.getDepartmentOfFacility(f)))
      .then(departments => departments.filter(d => isMaybeADepartment(d)))
      .then(departments => departments.map(d => (d as Department).code));

    if (codes.length === 0) {
      return [];
    }

    return this.repository.listLatest({ departmentCodes: codes });
  }
}
