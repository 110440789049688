import {
  PMPCreatedEvent,
  PMPCreationFailEvent,
  PMPDeletedEvent,
  PMPDeletionFailEvent,
  PMPUpdatedEvent,
  PMPUpdateFailEvent,
} from '@application/bundles/pmp';
import { PMP_TRANSLATE_CONTEXT } from '@application/bundles/pmp/pmp.token';
import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';
import { Facility } from '@domain/facility';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { QueryBus } from '@application/framework/command-query';
import { getPMPYear } from '@domain/pmp';

@HasEventSubscribers()
export class OnPMPEventsSubscriber {
  constructor(
    private readonly noticeStream: NoticeStream,
    private readonly queryBus: QueryBus,
  ) {}

  @OnEvent({
    event: PMPCreatedEvent,
  })
  private async pushNoticeOnPMPCreationSuccess(event: PMPCreatedEvent): Promise<void> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(event.pmp.facilityId));

    const message = new TranslatableString(
      "Le <strong>P.M.P. {{ year }}</strong> pour l'établissement <strong>{{ name }}</strong> a été créé avec succès.",
      { year: getPMPYear(event.pmp), name: facility.name },
      PMP_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: PMPCreationFailEvent,
  })
  private pushNoticeOnPMPCreationFail(event: PMPCreationFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la création du nouveau P.M.P..',
        undefined,
        PMP_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }

  @OnEvent({
    event: PMPUpdatedEvent,
  })
  private async pushNoticeOnPMPUpdateSuccess(event: PMPUpdatedEvent): Promise<void> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(event.pmp.facilityId));

    const message = new TranslatableString(
      "Le <strong>P.M.P. {{ year }}</strong> pour l'établissement <strong>{{ name }}</strong> a été mis à jour avec succès.",
      { year: getPMPYear(event.pmp), name: facility.name },
      PMP_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: PMPUpdateFailEvent,
  })
  private pushNoticeOnPMPUpdateFail(event: PMPUpdateFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la modification du P.M.P..',
        undefined,
        PMP_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }

  @OnEvent({
    event: PMPDeletedEvent,
  })
  private async pushNoticeOnPMPDeletionSuccess(event: PMPDeletedEvent): Promise<void> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(event.pmp.facilityId));

    const message = new TranslatableString(
      "Le <strong>P.M.P. {{ year }}</strong> pour l'établissement <strong>{{ name }}</strong> a été supprimé avec succès.",
      { year: getPMPYear(event.pmp), name: facility.name },
      PMP_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: PMPDeletionFailEvent,
  })
  private pushNoticeOnPMPDeletionFail(event: PMPDeletionFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la suppression du P.M.P..',
        undefined,
        PMP_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }
}
