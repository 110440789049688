import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { DEFAULT_CURRENCY_CODE, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { ApplicationBundle } from '@application/bundles/application/application.bundle';
import { StartApplicationCommand } from '@application/bundles/application/commands/start-application.command';
import { SettingsStore } from '@application/bundles/application/store/settings-store';
import { AuthorizationChecker } from '@application/bundles/authorization';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';
import { CssUnitConverter } from '@application/framework/converter/css-unit.converter';
import { DateFormatter } from '@application/framework/date';
import { DateParser } from '@application/framework/date/date.parser';
import { Container, ContainerRegistry } from '@application/framework/di';
import { ErrorHandler } from '@application/framework/error';
import { AgnosticErrorHandler } from '@application/framework/error/implementations/agnostic-error.handler';
import { HttpRequestClient } from '@application/framework/http';
import { Logger, LogLevel } from '@application/framework/logger';
import { StringMatcher, StringParser } from '@application/framework/parser/string';
import { AgnosticStringMatcher } from '@application/framework/parser/string/agnostic-matcher/agnostic-matcher';
import { AgnosticStringParser } from '@application/framework/parser/string/agnostic-parser/agnostic-parser';
import { AccommodationFundingModule } from '@easyhpad-ui/app/bundles/accommodation-funding/accommodation-funding.module';
import { ActivityAnnexModule } from '@easyhpad-ui/app/bundles/activity-annex/activity-annex.module';
import { AuthorizationModule } from '@easyhpad-ui/app/bundles/authorization/authorization.module';
import { EasyhpadBackendModule } from '@easyhpad-ui/app/bundles/backend/easyhpad/easyhpad.module';
import { BalanceSheetModule } from '@easyhpad-ui/app/bundles/balance-sheet/balance-sheet.module';
import { BenchmarkingModule } from '@easyhpad-ui/app/bundles/benchmarking/benchmarking.module';
import { CalculatorModule } from '@easyhpad-ui/app/bundles/calculator/calculator.module';
import { CapacityAuthorizationModule } from '@easyhpad-ui/app/bundles/capacity-authorization/capacity-authorization.module';
import { CPOMModule } from '@easyhpad-ui/app/bundles/cpom/cpom.module';
import { DashboardPublicModule } from '@easyhpad-ui/app/bundles/dashboard/modules/public/dashboard-public.module';
import { DemoModule } from '@easyhpad-ui/app/bundles/demo/demo.module';
import { DependencyFundingModule } from '@easyhpad-ui/app/bundles/dependency-funding/dependency-funding.module';
import { DocumentsModule } from '@easyhpad-ui/app/bundles/documents/documents.module';
import { EhpadDemainModule } from '@easyhpad-ui/app/bundles/ehpad-demain/ehpad-demain.module';
import { EPRDERRDModule } from '@easyhpad-ui/app/bundles/eprd-errd';
import { ErrorsModule } from '@easyhpad-ui/app/bundles/errors/errors.module';
import { EtpModule } from '@easyhpad-ui/app/bundles/etp/etp.module';
import { FacilityModule } from '@easyhpad-ui/app/bundles/facility/facility.module';
import { FileDepositModule } from '@easyhpad-ui/app/bundles/file-deposit/file-deposit.module';
import { FundingModule } from '@easyhpad-ui/app/bundles/funding/funding.module';
import { GeoModule } from '@easyhpad-ui/app/bundles/geo/geo.module';
import { GirModule } from '@easyhpad-ui/app/bundles/gir/gir.module';
import { GMPModule } from '@easyhpad-ui/app/bundles/gmp/gmp.module';
import { HealthFundingModule } from '@easyhpad-ui/app/bundles/health-funding/health-funding.module';
import { LibraryModule } from '@easyhpad-ui/app/bundles/library/library.module';
import { MediaModule } from '@easyhpad-ui/app/bundles/media/media.module';
import { PMPModule } from '@easyhpad-ui/app/bundles/pmp/pmp.module';
import { SimulationModule } from '@easyhpad-ui/app/bundles/simulation/simulation.module';
import { SupportModule } from '@easyhpad-ui/app/bundles/support/support.module';
import { TaxonomyModule } from '@easyhpad-ui/app/bundles/taxonomy/taxonomy.module';
import { UserPublicModule } from '@easyhpad-ui/app/bundles/user/modules/public/user-public.module';
import { CacheModule } from '@easyhpad-ui/app/framework/cache/cache.module';
import { DiModule } from '@easyhpad-ui/app/framework/di/di-module';
import { EventModule } from '@easyhpad-ui/app/framework/events/event.module';
import { NativeChangeObserver } from '@easyhpad-ui/app/framework/native-change-observer';
import { RouteSailor } from '@easyhpad-ui/app/framework/routing';
import { SanitizerModule } from '@easyhpad-ui/app/framework/sanitizer/sanitizer.module';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator/validator.module';
import { WINDOW, WINDOW_PROVIDERS } from '@easyhpad-ui/app/framework/window';
import { HomePageComponent } from '@easyhpad-ui/app/pages';
import { GlobalEffects } from '@easyhpad-ui/app/store/global.effect';
import { GLOBAL_STORE_KEY } from '@easyhpad-ui/app/store/global.keys';
import { globalReducer } from '@easyhpad-ui/app/store/global.reducer';
import { configuration } from '@easyhpad-ui/environments/configuration';
import { CssUnitConverterImpl } from '@implementations/framework/converters/css-unit/css-unit.converter';
import { DateFnsParser } from '@implementations/framework/date';
import { DateFnsFormatter } from '@implementations/framework/date/date-fns.formatter';
import { AngularHttpClientWrapper } from '@implementations/framework/http/client';
import { ConsoleLogger } from '@implementations/framework/logger/console.logger';
import { LocalStorageStoreAdapter } from '@implementations/framework/store/adapters/localstorage.store.adapter';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminModule } from './bundles/admin/admin.module';
import { AuthentificationModule } from './bundles/authentification/authentification.module';
import { NoticeModule } from './bundles/notice/notice.module';
import { ReusableComponentsModule } from './bundles/reusable-components/reusable-components.module';
import { UserModule } from './bundles/user/user.module';
import {
  CurrentCustomerSelectorComponent,
  CurrentFacilitiesSelectorComponent,
  FileDepositsButtonComponent,
  MenuSidebarComponent,
  TopBarComponent,
} from './components';
import { CommandQueryModule } from './framework/command-query/command-query.module';
import { EasyhpadTitleStrategy } from './framework/title/strategies/title-strategy/title.strategy';
import { TranslationModule } from './framework/translation/translation.module';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    MenuSidebarComponent,
    CurrentFacilitiesSelectorComponent,
    CurrentCustomerSelectorComponent,
    FileDepositsButtonComponent,
    TopBarComponent,
    HomePageComponent,
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    DiModule,
    HttpClientModule,
    StoreModule.forRoot({
      router: routerReducer,
      [GLOBAL_STORE_KEY]: globalReducer,
    }),
    EffectsModule.forRoot([GlobalEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot(),
    AuthorizationModule,
    CommandQueryModule.forRoot({
      bypass: [StartApplicationCommand],
    }),
    ErrorsModule,
    SanitizerModule,
    ValidatorModule.forRoot(),
    CacheModule.forRoot(),
    TranslationModule,
    AppRoutingModule,
    DashboardPublicModule,
    ReusableComponentsModule,
    NoticeModule,
    AuthentificationModule,
    UserModule,
    UserPublicModule,
    AdminModule,
    FacilityModule,
    EasyhpadBackendModule,
    CapacityAuthorizationModule,
    MediaModule,
    EventModule,
    GMPModule,
    PMPModule,
    CPOMModule,
    OverlayPanelModule,
    ListboxModule,
    FormsModule,
    EhpadDemainModule,
    HealthFundingModule,
    DependencyFundingModule,
    AccommodationFundingModule,
    EPRDERRDModule,
    ActivityAnnexModule,
    CalculatorModule,
    SupportModule,
    GirModule,
    BenchmarkingModule,
    GeoModule,
    EtpModule,
    SimulationModule,
    FundingModule,
    FileDepositModule,
    LibraryModule,
    DemoModule,
    BalanceSheetModule,
    DocumentsModule.forRoot(),
    TaxonomyModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    WINDOW_PROVIDERS,
    RouteSailor,
    NativeChangeObserver,
    {
      provide: CssUnitConverter,
      useClass: CssUnitConverterImpl,
      deps: [WINDOW],
    },
    {
      provide: Logger,
      useValue: new ConsoleLogger('EasyHPAD', LogLevel.INFO),
    },
    {
      provide: TitleStrategy,
      useClass: EasyhpadTitleStrategy,
    },
    {
      provide: StringMatcher,
      useValue: new AgnosticStringMatcher(),
    },
    {
      provide: StringParser,
      useClass: AgnosticStringParser,
      deps: [StringMatcher],
    },
    {
      provide: DateFormatter,
      useFactory: () => new DateFnsFormatter(configuration),
    },
    {
      provide: DateParser,
      useClass: DateFnsParser,
    },
    {
      provide: HttpRequestClient,
      useClass: AngularHttpClientWrapper,
      deps: [HttpClient],
    },
    {
      provide: ErrorHandler,
      useClass: AgnosticErrorHandler,
      deps: [Logger],
    },
    {
      provide: DocumentAuthorization,
      useFactory: (a: AuthorizationChecker) => new DocumentAuthorization(a),
      deps: [AuthorizationChecker],
    },
    /* {
       provide: AngularErrorHandler,
      useClass: AngularErrorHandlerAdapter
     },*/
    {
      provide: SettingsStore,
      useValue: new LocalStorageStoreAdapter('easyhpad_settings'),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(container: Container) {
    ContainerRegistry.defaultContainer = container;
    ApplicationBundle.build(container);
  }
}
