import { Common } from '@application/framework/lib';
import { Customer } from '@domain/customer';
import { CreateUser, StudyLevel, UpdateUser } from '@domain/user';
import { InitialTraining } from '@domain/user/initial-training.interface';
import { Job } from '@domain/user/job.interface';
import { IsArray, IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { Facility } from '@domain/facility';
import { IsValidCustomerId } from '@implementations/bundles/customer/validators/class-validator/is-valid-customer-id/is-valid-customer-id.validator';

export abstract class UserCommonDtoAbstract implements Common<CreateUser, UpdateUser> {
  @IsString()
  @IsNotEmpty()
  public firstname: string = '';

  @IsString()
  @IsNotEmpty()
  public lastname: string = '';

  @IsEmail()
  @IsNotEmpty()
  public email: string = '';

  @IsOptional()
  @IsValidCustomerId()
  public customerId?: Customer['id'];

  @IsBoolean()
  @IsOptional()
  public blocked: boolean = false;

  @IsOptional()
  public initialTraining?: InitialTraining;

  @IsOptional()
  public job?: Job;

  @IsOptional()
  public studyLevel?: StudyLevel;

  @IsArray()
  public facilityIds!: Array<Facility['id']>;

  public get displayName() {
    return `${this.firstname} ${this.lastname}`.trim();
  }

  public constructor(values?: Partial<Common<CreateUser, UpdateUser>>) {
    Object.assign(this, values);
  }
}
