import { AbstractControl } from '@angular/forms';
import { ArgumentsType } from '@domain/lib';

export function syncControlsTouch(source: AbstractControl, destination: AbstractControl): void {
  const initial = {
    markAsTouched: source.markAsTouched.bind(source),
    markAsUntouched: source.markAsUntouched.bind(source),
    markAllAsTouched: source.markAllAsTouched.bind(source),
  };

  function markAsTouched(...args: ArgumentsType<AbstractControl['markAsTouched']>) {
    initial.markAsTouched(...args);
    destination.markAsTouched(...args);
  }

  function markAsUntouched(...args: ArgumentsType<AbstractControl['markAsUntouched']>) {
    initial.markAsUntouched(...args);
    destination.markAsUntouched(...args);
  }

  function markAllAsTouched(...args: ArgumentsType<AbstractControl['markAllAsTouched']>) {
    initial.markAllAsTouched(...args);
    destination.markAllAsTouched(...args);
  }

  source.markAsTouched = markAsTouched;
  source.markAsUntouched = markAsUntouched;
  source.markAllAsTouched = markAllAsTouched;
}
