import { GMPRepository } from '@application/bundles/gmp';
import { GetGMPForYearQuery } from '@application/bundles/gmp/queries/get-gmp-for-year.query';
import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GMPSearchParamsImpl } from '@application/bundles/gmp/implementations';
import { getYearDateInterval } from '@application/framework/date';
import { GMP } from '@domain/gmp';

@HandleQuery({
  query: GetGMPForYearQuery,
})
export class HasGMPForYearQueryHandler implements QueryHandler<GetGMPForYearQuery, GMP | undefined> {
  constructor(private readonly repository: GMPRepository) {}

  public async handle(query: GetGMPForYearQuery): Promise<GMP | undefined> {
    const { start, end } = getYearDateInterval(query.year);

    return this.repository
      .list(new GMPSearchParamsImpl().setDates(start, end).setFacilities(query.facilityId))
      .then(list => list[0]);
  }
}
