import {EPRD, ERRD} from "@domain/eprd-errd";
import {SORT_ORDER} from "@application/framework/search/sort";


interface DocumentList<Document> {

  loading: boolean;

  loaded: boolean;

  entities: Document[];

  page: number;

  sort?: { property: keyof Document, order: SORT_ORDER } | undefined;

}

export interface EPRDERRDDocumentStoreState<Entity extends EPRD | ERRD> {

  current?: {
    entity: Entity
  }

  public: DocumentList<Entity> | undefined
}

export interface EPRDStoreState extends EPRDERRDDocumentStoreState<EPRD> {

}


export interface ERRDStoreState extends EPRDERRDDocumentStoreState<ERRD> {

}
