import { CreateLocalERRDDtoImpl } from './create-local-errd.dto';
import { isValidERRDIdType, isValidLocalERRDIdType, UpdateLocalERRDDto } from '@application/bundles/eprd-errd';
import { ERRD } from '@domain/eprd-errd';
import { isValidObject } from '@application/framework/lib';
import { IsUUID } from 'class-validator';

export class UpdateLocalERRDDtoImpl extends CreateLocalERRDDtoImpl implements UpdateLocalERRDDto {
  @IsUUID()
  public id!: UpdateLocalERRDDto['id'];

  public parentId!: ERRD['id'];

  constructor(defaults?: Partial<UpdateLocalERRDDto>) {
    super(defaults);

    if (isValidObject(defaults)) {
      if (isValidLocalERRDIdType(defaults.id)) {
        this.id = defaults.id;
      }

      if (isValidERRDIdType(defaults.parentId)) {
        this.parentId = defaults.parentId;
      }
    }
  }
}
