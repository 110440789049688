import {
  AgnosticRoleCollection
} from "@application/bundles/authorization/implementations/agnostic-role-collection/agnostic-role-collection";
import {RoleRepository} from "@application/bundles/authorization/role.repository";
import {Role, RoleCollection} from "@domain/authorization";
import {User} from "@domain/user";
import {EasyRole} from "@implementations/bundles/backend/easyhpad/interfaces/easy-role.interface";
import {EasyRoleTransformer} from "@implementations/bundles/backend/easyhpad/transformers/role/easy-role-transformer";
import {
  EasyRepositoryAbstract
} from "@implementations/bundles/backend/easyhpad/repositories/base/easy.repository.abstract";
import {Provide} from "@application/framework/di";

export class EasyRoleRepository extends EasyRepositoryAbstract implements RoleRepository {

  @Provide() private readonly entityTransformer!: EasyRoleTransformer;

  constructor() {
    super('user');
  }

  public getAssignableRolesByUser(id: User["id"]): Promise<RoleCollection> {
    return this.backend.get<{ roles: EasyRole[] }>(`user/${id}/roles`)
      .then(response => this.parseResponse(response))
      .then((response) => response.roles.map(r => this.entityTransformer.reverseTransform(r)))
      .then((roles) => new AgnosticRoleCollection(roles));
  }

  public assignRolesToUser(id: User["id"], roles: Role[]): Promise<RoleCollection> {
    return this.backend.patch<{ roles: EasyRole[] }>(`user/${id}/roles`, {roles: roles.map(r => this.entityTransformer.transform(r))})
      .then(response => this.parseResponse(response))
      .then((response) => response.roles.map(r => this.entityTransformer.reverseTransform(r)))
      .then((roles) => new AgnosticRoleCollection(roles));

  }

}
