import { isValidObject } from '@application/framework/lib';
import { EhpadDemain } from '@domain/ehpad-demain';
import { isUUID, UUID } from '@domain/lib';

export function isValidEhpadDemainIdType(id: any): id is UUID {
  return isUUID(id);
}

export function isMaybeAnEhpadDemain(document: any): document is { id: EhpadDemain['id'] } {
  return isValidObject(document) && 'id' in document && isValidEhpadDemainIdType(document.id);
}
