<header class="widget-header">

  <h3 class="widget-title title">
    <ng-container
      *ngIf="average.total > 1; else singleTitle">{{'Mes Autorisations' | ehp_translate }}</ng-container>
    <ng-template #singleTitle>{{'Autorisation de capacité' | ehp_translate }}</ng-template>
  </h3>

</header>

<div class="widget-content">

  <ng-container *ngIf="!isEmpty; else noData">
    <div *ngIf="isDisplayableValue(total)" class="block total">
      <span class="label">{{'Lits autorisés' | ehp_translate }}</span>
      <span class="value">{{total | number:'1.0-0'}}</span>

      <ehp-translatable-string *ngIf="bedSocialCare" [value]="bedSocialCare"
                               class="social-care-message"></ehp-translatable-string>
    </div>

    <div *ngIf="isDisplayableValue(permanent)" class="block">
      <span class="label">{{'Hébergement permanent' | ehp_translate }}</span>
      <span class="value">{{permanent | number:'1.0-0'}}</span>
    </div>

    <div *ngIf="isDisplayableValue(temporary)" class="block">
      <span class="label">{{'Hébergement temporaire' | ehp_translate }}</span>
      <span class="value">{{temporary | number:'1.0-0'}}</span>
    </div>

    <div *ngIf="isDisplayableValue(dayCare)" class="block">
      <span class="label">{{'Accueil de jour' | ehp_translate }}</span>
      <span class="value">{{dayCare | number:'1.0-0'}}</span>
    </div>
  </ng-container>

</div>

<div class="widget-footer">

  <ehp-average-message
    *ngIf="average && average.total > 1"
    [total]="average.total"
    [value]="average.value"
    class="average-message"
  ></ehp-average-message>

</div>


<ehp-chart-resident-gir-list-line
  *ngIf="girList"
  [displayLabel]="false"
  [displayTooltip]="true"
  [residents]="girList"
  class="gir-bar">
</ehp-chart-resident-gir-list-line>


<ng-template #noData>
  <div class="empty">
    <p>{{'Aucune autorisation de capacité renseignée pour cet établissement' | ehp_translate}}</p>
    <a *ngIf="createUrl" [routerLink]="createUrl" class="target-link">{{'Nouvel archivage' | ehp_translate}}</a>
  </div>

</ng-template>
