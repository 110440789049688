import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PMP } from '@domain/pmp';
import { Skeleton } from '@easyhpad-ui/app/bundles/reusable-components/components/skeleton';
import { DocumentList } from '@easyhpad-ui/app/library/document';

@Component({
  selector: 'ehp-pmp-list',
  templateUrl: './pmp-list.component.html',
  styleUrls: ['./pmp-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'document-list',
  },
})
export class PmpListComponent {
  @Input('documents') list!: DocumentList<PMP>;

  public SKELETON_TYPE = Skeleton.DOCUMENT_LIST;

  protected readonly document = document;
}
