import { BuildMediaPublicUrlQuery } from '@application/bundles/media/queries/build-media-dowload-url.query';
import { QueryBus } from '@application/framework/command-query';
import { Provide } from '@application/framework/di';
import { MEDIA_PERSISTANCE_STATE } from '@domain/media';
import { Media } from '@domain/media/media.interface';
import { MEDIA_FILE_TYPES } from '@domain/media/types';
import { Facility } from '@domain/facility';
import { Customer } from '@domain/customer';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { isValidCustomerIdType } from '@application/bundles/customer';
import { isValidMediaIdType } from '@application/bundles/media';

export class MediaImpl implements Media {
  public type!: MEDIA_FILE_TYPES;

  public id!: Media['id'];

  public name: string = '';

  public path: string = '';

  public mimeType: string = '';

  public facilityId: Facility['id'] | undefined;

  public customerId: Customer['id'] | undefined;

  @Provide()
  private readonly queryBus!: QueryBus;

  public get persistance(): MEDIA_PERSISTANCE_STATE {
    return this.id ? MEDIA_PERSISTANCE_STATE.STORED : MEDIA_PERSISTANCE_STATE.TEMPORARY;
  }

  constructor(defaults: Partial<Media>) {
    if (defaults.id) {
      this.id = defaults.id;
    }

    if (defaults.name) {
      this.name = defaults.name;
    }

    if (defaults.path) {
      this.path = defaults.path;
    }

    if (defaults.type) {
      this.type = defaults.type;
    }

    if (defaults.mimeType) {
      this.mimeType = defaults.mimeType;
    }

    if (isValidFacilityIdType(defaults.facilityId)) {
      this.facilityId = defaults.facilityId;
    }

    if (isValidCustomerIdType(defaults.facilityId)) {
      this.facilityId = defaults.facilityId;
    }
  }

  public async buildUrl(): Promise<URL> {
    if (this.queryBus === undefined) {
      return Promise.reject(new Error('QueryBus is not defined'));
    }

    if (!isValidMediaIdType(this.id)) {
      return Promise.reject(new Error('Media id is invalid'));
    }

    return this.queryBus.request(new BuildMediaPublicUrlQuery(this.id));
  }
}
