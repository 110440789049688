import {CreateCPOMFundingDto} from "@application/bundles/cpom";
import {IsNumber, IsOptional, IsPositive, IsString} from "class-validator";

export class CreateCPOMFundingDtoImpl implements CreateCPOMFundingDto {


  @IsString()
  public name!: string;

  @IsNumber()
  @IsPositive()
  public amount!: number;

  @IsOptional()
  @IsString()
  public comment: string | undefined;


  constructor(values?: Partial<CreateCPOMFundingDto>) {

    if (values) {
      if (values.name) {
        this.name = values.name;
      }

      if (values.amount) {
        this.amount = values.amount;
      }

      this.comment = values.comment;
    }
  }

}
