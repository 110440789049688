import {LogLevel} from "@application/framework/logger/log-level";

export abstract class Logger {

  abstract level: LogLevel;

  /**
   * Open new logger channel
   * @param name
   */
  public abstract channel(name: string): Logger;

  /**
   * Send info message
   * @param message
   * @param args
   */
  public abstract info(message: string, ...args: any[]): void;

  /**
   * Send debug message
   * @param message
   * @param args
   */
  public abstract debug(message: string, ...args: any[]): void;

  /**
   * Send warning message
   * @param message
   * @param args
   */
  public abstract warning(message: string, ...args: any[]): void;

  /**
   * Send error message
   * @param message
   * @param args
   */
  public abstract error(message: string, ...args: any[]): void;
}
