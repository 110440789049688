import {LocalMedia, Media} from "@domain/media";
import {ActivityAnnex} from "@domain/activity-annex";

export interface CreateActivityAnnexDto extends Omit<ActivityAnnex, 'id' | 'year' | 'document' | 'documentId' | 'facility'> {

  documentId?: Media['id'];

  document: LocalMedia;

}


export interface UpdateActivityAnnexDto extends Omit<ActivityAnnex, 'year' | 'document' | 'documentId' | 'facility'> {

  documentId?: Media['id'];

  document?: LocalMedia | Media;

}
