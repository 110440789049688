import { Component } from '@angular/core';

@Component({
  selector: 'ehp-health-allocation-calculator',
  templateUrl: './health-allocation-calculator.component.html',
  styleUrls: ['./health-allocation-calculator.component.scss']
})
export class HealthAllocationCalculatorComponent {

}
