import { Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { isMaybeACustomer, isValidCustomerIdType } from '@application/bundles/customer';
import { ListCustomersQuery } from '@application/bundles/customer/queries/list-customers.query';
import { ListAuthorizedFacilitiesQuery } from '@application/bundles/facility/query/list-authorized-facilities.query';
import { CreateNewUserCommand, UpdateUserCommand } from '@application/bundles/user';
import { UserDtoFactory } from '@application/bundles/user/factories/user-dto.factory';
import { GetInitialTrainingValuesQuery } from '@application/bundles/user/queries/get-initial-training-values.query';
import { GetJobValuesQuery } from '@application/bundles/user/queries/get-job-values.query';
import { GetStudyLevelValuesQuery } from '@application/bundles/user/queries/get-study-level-values.query';
import { Command, CommandBus, QueryBus } from '@application/framework/command-query';
import { Common } from '@application/framework/lib';
import { includeAdminRole } from '@domain/authorization';
import { Customer } from '@domain/customer';
import { Facility } from '@domain/facility';
import { CreateUser, UpdateUser, User } from '@domain/user';
import { InitialTraining } from '@domain/user/initial-training.interface';
import { Job } from '@domain/user/job.interface';
import { StudyLevel } from '@domain/user/study-level.interface';
import { ConfirmAdministrationCreationDialogData } from '@easyhpad-ui/app/bundles/user/modules/form/components/dialog-confirm-administrator-creation/confirm-administration-creation-dialog.interface';
import { ConfirmAdministratorCreationDialogComponent } from '@easyhpad-ui/app/bundles/user/modules/form/components/dialog-confirm-administrator-creation/dialog-confirm-administrator-creation.component';
import { UserFormBuilder } from '@easyhpad-ui/app/bundles/user/services/form-builder/user-form-builder';
import { UniqueUserEmailValidator } from '@easyhpad-ui/app/bundles/user/validators/unique-email/unique-email.validator';
import { DialogConfiguration } from '@easyhpad-ui/app/library/dialog';
import { InitialTrainingSelectOptionTransformer } from '@implementations/bundles/user/transformers/initial-training-select-option/initial-training-select-option.transformer';
import { JobSelectOptionTransformer } from '@implementations/bundles/user/transformers/job-select-option/job-select-option.transformer';
import { StudyLevelSelectOptionTransformer } from '@implementations/bundles/user/transformers/study-level-select-option/study-level-select-option.transformer';
import { SelectOption } from '@implementations/forms/select-option.interface';
import { BehaviorSubject, from, map, Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'ehp-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  styleUrls: ['./user-edit-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserEditFormComponent implements OnChanges {
  @Input() public mode: 'create' | 'update' = 'update';

  @Input() public user: User | undefined;

  @Input() public submitLabel = "Mettre à jour l'utilisateur";

  @Output() public onSave: EventEmitter<User> = new EventEmitter();

  @Output('statusChanges') public valid$ = new EventEmitter();

  public form?: FormGroup<Record<keyof Omit<Common<CreateUser, UpdateUser>, 'displayName'>, AbstractControl>>;

  public jobOptions$: Observable<SelectOption[]> | undefined;

  public initialTrainingOption$: Observable<SelectOption[]> | undefined;

  public studyLevelOptions$: Observable<SelectOption[]> | undefined;

  public customers$: Observable<Customer[]> | undefined;

  public facilities$: Subject<Facility[]> = new BehaviorSubject([] as Facility[]);

  private subscriptions: Set<Subscription> = new Set();

  private transformers = {
    job: new JobSelectOptionTransformer(),
    training: new InitialTrainingSelectOptionTransformer(),
    studyLevel: new StudyLevelSelectOptionTransformer(),
  };

  constructor(
    private readonly formBuilder: UserFormBuilder,
    private readonly queryBus: QueryBus,
    private readonly uniqueEmailValidator: UniqueUserEmailValidator,
    private readonly factory: UserDtoFactory,
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode']) {
      this.rebuildForm();
    }

    if (changes['user'] && changes['user'].currentValue) {
      this.rebuildFormValues();
    }
  }

  public submit() {
    if (this.form === undefined || !this.form?.valid) {
      return;
    }

    let dto: CreateUser | UpdateUser | undefined;
    let commandFactory: (<T extends CreateUser | UpdateUser>() => Command) | undefined;

    if (this.mode === 'create') {
      const createUserDto = this.factory.newCreateUserDtoFromUnsafeValues(
        this.formBuilder.deserializeValues<CreateUser>(this.form.value),
      );

      commandFactory = () => new CreateNewUserCommand(createUserDto);
      dto = createUserDto;
    } else if (this.user && this.user.id) {
      const id = this.user.id;

      const updateUserDto = this.factory.newUpdateUserDtoFromUnsafeValues({
        ...this.formBuilder.deserializeValues<UpdateUser>(this.form.value),
        id,
      });

      commandFactory = () => new UpdateUserCommand(id, updateUserDto);
      dto = updateUserDto;
    }

    if (dto && commandFactory) {
      if (dto.roles && includeAdminRole(dto.roles)) {
        const data: ConfirmAdministrationCreationDialogData = {
          user: dto,
        };
        const dialog = this.dialog.open<boolean>(ConfirmAdministratorCreationDialogComponent, {
          ...DialogConfiguration,
          data,
        });

        dialog.closed.subscribe(confirm => {
          if (confirm && dto && commandFactory) {
            this.commandBus.execute<User>(commandFactory()).then(user => {
              this.onSave.emit(user);
            });
          }
        });
      } else {
        this.commandBus.execute<User>(commandFactory()).then(user => {
          this.onSave.emit(user);
        });
      }
    }
  }

  private rebuildForm(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions.clear();

    if (this.mode) {
      this.initialTrainingOption$ = from(
        this.queryBus.request<InitialTraining[]>(new GetInitialTrainingValuesQuery()),
      ).pipe(map(trainings => trainings.map(this.transformers.training.transform)));

      this.jobOptions$ = from(this.queryBus.request<Job[]>(new GetJobValuesQuery())).pipe(
        map(jobs => jobs.map(this.transformers.job.transform)),
      );

      this.studyLevelOptions$ = from(this.queryBus.request<StudyLevel[]>(new GetStudyLevelValuesQuery())).pipe(
        map(levels => levels.map(this.transformers.studyLevel.transform)),
      );

      this.customers$ = from(this.queryBus.request<Customer[]>(new ListCustomersQuery()));
    }

    switch (this.mode) {
      case 'create':
        this.form = this.formBuilder.getCreationForm(this.user, {
          uniqueEmailValidator: this.uniqueEmailValidator.clone(),
        });
        break;
      case 'update':
        this.form = this.formBuilder.getUpdateForm(this.user);
        break;
      default:
        this.form = undefined;
    }

    if (this.form) {
      this.subscriptions.add(
        this.form.statusChanges.subscribe(change => {
          this.valid$.emit(change);
        }),
      );

      const customerSubscription = this.form
        .get(UserFormBuilder.CUSTOMER_CONTROL_NAME)
        ?.valueChanges.subscribe(change => {
          this.rebuildFacilitiesFromCustomer(change);
        });

      if (customerSubscription) {
        this.subscriptions.add(customerSubscription);
      }
    }
  }

  private rebuildFormValues(): void {
    if (!this.form || !this.user) {
      return;
    }

    this.formBuilder.rebuildFormValues(this.form, this.user);
  }

  private rebuildFacilitiesFromCustomer(customer: Customer | Customer['id'] | null | undefined) {
    let id: Customer['id'] | undefined = undefined;

    if (isValidCustomerIdType(customer)) {
      id = customer;
    } else if (isMaybeACustomer(customer)) {
      id = customer.id;
    }

    if (id !== undefined) {
      this.queryBus.request(new ListAuthorizedFacilitiesQuery(id, true)).then(facilities => {
        this.facilities$.next(facilities);
      });
    } else {
      this.facilities$.next([]);
    }
  }
}
