import {Constructable} from "@application/framework/lib";
import {ObjectValidatorRegistry, ValidateFunction,} from "@application/framework/validator/object-validator.registry";

export class AgnosticValidatorRegistry implements ObjectValidatorRegistry {

  private static registry: Map<string, Set<ValidateFunction<any>>> = new Map();


  public static fromProviders(providers?: Array<{ objectType: Constructable<any>, validator: ValidateFunction<any> }>): AgnosticValidatorRegistry {
    const registry = new AgnosticValidatorRegistry();

    if (providers instanceof Array) {
      providers.forEach(value => {
        registry.register(value.objectType, value.validator);
      });
    }

    return registry;
  }


  public register<T>(objectType: Constructable<T>, validator: ValidateFunction<T>): void {

    let validators = AgnosticValidatorRegistry.registry.get(objectType.name);

    if (validators === undefined) {
      validators = new Set();
    }

    validators.add(validator as ValidateFunction<any>);
    AgnosticValidatorRegistry.registry.set(objectType.name, validators);

  }

  public get<T>(object: T): Set<ValidateFunction<T>> {

    let validators = AgnosticValidatorRegistry.registry.get(Object.getPrototypeOf(object).constructor.name);

    if (validators === undefined || validators.size === 0) {
      validators = new Set();
    }

    return validators as unknown as Set<ValidateFunction<T>>;
  }

}
