import {
  AccommodationFundingDtoFactory,
  CreateAccommodationFundingDto,
  UpdateAccommodationFundingDto
} from "@application/bundles/accommodation-funding";
import {
  CreateAccommodationFundingDtoImpl,
  UpdateAccommodationFundingDtoImpl
} from "@implementations/bundles/accommodation-funding";

export class AccommodationFundingDtoFactoryImpl implements AccommodationFundingDtoFactory {

  public buildCreateAccommodationFundingDtoFromUnsafeValues(values: Partial<CreateAccommodationFundingDto>): CreateAccommodationFundingDto {
    return new CreateAccommodationFundingDtoImpl(values);
  }

  public buildUpdateAccommodationFundingDtoFromUnsafeValues(values: Partial<UpdateAccommodationFundingDto>): UpdateAccommodationFundingDto {
    return new UpdateAccommodationFundingDtoImpl(values);
  }

}
