import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Accessor } from '@application/framework/core';
import { Translator } from '@application/framework/translation';
import { configuration } from '../../../environments/configuration';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PageTitleAccessor implements Accessor<string | undefined> {
  private latest: string | undefined;

  constructor(
    private angularTitle: Title,
    private translator: Translator,
  ) {}

  public async set(title: string, replacement?: Record<string, string | number>): Promise<void> {
    title = await this.translator.translate(title, replacement, 'page-title');
    this.latest = title;

    if (environment['name']) {
      title = `[${environment['name']}] ${title}`.trim();
    }

    if (configuration.appName) {
      title = title ? (title += ' | ') : title;
      title = `${title} ${configuration.appName}`.trim();
    }

    if (configuration.appSlogan) {
      title = title ? (title += ' - ') : title;
      title = `${title} ${configuration.appSlogan}`.trim();
    }

    this.angularTitle.setTitle(title);
  }

  public get(): string | undefined {
    return this.latest;
  }

  public full() {
    return this.angularTitle.getTitle();
  }
}
