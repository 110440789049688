import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { DashboardWidgetComponent } from '@easyhpad-ui/app/bundles/dashboard/components/dashboard-widget/dashboard-widget.component';
import {
  DashboardWidget,
  DashboardWidgetContent,
} from '@easyhpad-ui/app/bundles/dashboard/interfaces/dashboard-widget-content.reference';

@Injectable({
  providedIn: 'root',
})
export class WidgetResolver {
  public compose<T extends DashboardWidgetContent = any>(
    container: ViewContainerRef,
    ...widgets: DashboardWidget<T>[]
  ) {
    widgets.forEach((widget) => this.inject(container, widget));
  }

  public inject<T extends DashboardWidgetContent>(
    container: ViewContainerRef,
    widget: DashboardWidget<T>,
  ): ComponentRef<DashboardWidgetComponent> {
    const reference = container.createComponent(DashboardWidgetComponent);
    reference.changeDetectorRef.detectChanges();
    const content = reference.instance.content;

    if (content) {
      this.injectContent(content.viewContainerRef, widget);
    }
    reference.changeDetectorRef.detectChanges();

    return reference;
  }

  public injectContent<T extends DashboardWidgetContent>(
    container: ViewContainerRef,
    widget: DashboardWidget<T>,
  ): ComponentRef<T> {
    const reference = container.createComponent<T>(widget.content);
    reference.location.nativeElement.classList.add('widget-content-container');

    for (const name in widget.inputs) {
      if (name in widget.inputs) {
        reference.setInput(name, widget.inputs[name]);
      }
    }

    reference.changeDetectorRef.detectChanges();

    return reference;
  }
}
