import { CreateERRDDto, CreateLocalERRDDto } from '@application/bundles/eprd-errd';
import { CommonAbstractDto } from '@implementations/bundles/eprd-errd/dto/common.abstract.dto';
import { isLocalMedia, LocalMedia } from '@domain/media';
import { isValidObject } from '@application/framework/lib';
import { IsLocalMedia } from '@implementations/bundles/media';
import { IsArray, IsOptional } from 'class-validator';
import { isValidMediaIdType } from '@application/bundles/media';

export class CreateERRDDtoImpl extends CommonAbstractDto implements CreateERRDDto {
  @IsLocalMedia()
  public completeDocument!: LocalMedia;

  @IsLocalMedia()
  public payrollDocument!: LocalMedia;

  @IsLocalMedia()
  public financialAnnexDocument!: LocalMedia;

  @IsLocalMedia()
  public activityAnnexDocument!: LocalMedia;

  @IsLocalMedia()
  @IsOptional()
  public reportDocument?: LocalMedia;

  @IsArray()
  public details: CreateLocalERRDDto[] = [];

  constructor(defaults?: Partial<CreateERRDDto>) {
    super(defaults);

    if (isValidObject(defaults)) {
      if (defaults.depositDate instanceof Date) {
        this.depositDate = defaults.depositDate;
      }

      if (isValidMediaIdType(defaults.reportId)) {
        this.reportId = defaults.reportId;
      }
      if (Array.isArray(defaults?.details)) {
        this.details = defaults?.details;
      }

      const keys = [
        'completeDocument',
        'payrollDocument',
        'financialAnnexDocument',
        'activityAnnexDocument',
        'reportDocument',
      ];

      keys.forEach(key => {
        const media = defaults[key as 'completeDocument'] as LocalMedia | undefined;

        if (isLocalMedia(media)) {
          this[key as 'completeDocument'] = media;
        }
      });
    }
  }
}
