<div class="title-container">
  <h2 class="title">{{ 'P.M.P' | ehp_translate}}</h2>
  <span *ngIf="date" class="date">{{'Validé le' | ehp_translate}} {{ date }}</span>
</div>

<div class="content">

  <div class="indicator">
    <span class="value current-value">{{value | number:'1.0-2'}}</span>

    <div *ngIf="national" class="national-container">
      <span class="value">{{national.value | number:'1.0-2'}}</span>
      <span class="label">{{'National'|ehp_translate}}</span>
    </div>
  </div>

  <p *ngIf="difference" class="difference">
    <span [ngClass]="isPositiveDifference ? 'up': 'down'" class="value">{{difference}} %</span>
    <ehp-translatable-string [value]="differenceMessage" class="label"></ehp-translatable-string>
  </p>

  <!--<a *ngIf="url" [routerLink]="url" class="show-link target-link">
    {{'Voir le P.M.P.' | ehp_translate}}
  </a>-->
</div>

<ehp-average-message *ngIf="average" [total]="average.total" [value]="average.value"
                     class="average-message"></ehp-average-message>
