import { AfterViewInit, Component, Injector, OnDestroy, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { TranslatableString } from '@application/framework/translation';
import { DocumentCreateViewProcessor, DocumentCreateViewProcessorInstance } from '@easyhpad-ui/app/bundles/documents';
import { ActivatedRoute } from '@angular/router';
import { DocumentViewResolver } from '@easyhpad-ui/app/bundles/documents/services';
import { DocumentCreateViewContainer } from '@easyhpad-ui/app/bundles/documents/contracts';

@Component({
  selector: 'ehp-document-create-view-page',
  templateUrl: './document-create-view-page.component.html',
  styleUrl: './document-create-view-page.component.scss',
  host: {
    class: 'document-create-view-page',
  },
})
export class DocumentCreateViewPageComponent implements AfterViewInit, OnDestroy, DocumentCreateViewContainer {
  @ViewChild('title', { read: ViewContainerRef })
  public readonly titleContainer!: ViewContainerRef;

  @ViewChild('form', { read: ViewContainerRef })
  public readonly formContainer!: ViewContainerRef;

  public readonly title$ = new Subject<Array<string | TranslatableString>>();

  private processorName: Type<DocumentCreateViewProcessor> | undefined;

  private processorInstance: DocumentCreateViewProcessorInstance | undefined;

  private providerChange$ = new Subject<void>();

  private viewIsInit = false;

  private subscription: Subscription;

  constructor(
    public readonly route: ActivatedRoute,
    private readonly resolver: DocumentViewResolver,
    private readonly injector: Injector,
  ) {
    this.subscription = this.route.data.subscribe(data => {
      const provider = this.resolver.resolve(data['documentType'], 'create');

      if (!provider) {
        throw new Error(`Missing Document view processor provider for ${data['documentType']} (create)`);
      }

      this.processorName = provider.processor;

      if (this.viewIsInit) {
        this.processorChange();
      }
    });
  }

  public ngOnDestroy() {
    this.providerChange$.next();
    this.processorInstance?.destroy();
    this.subscription.unsubscribe();
  }

  public ngAfterViewInit() {
    this.viewIsInit = true;

    // Prevent error for change detection
    const timer = setTimeout(() => {
      this.render();
      clearTimeout(timer);
    }, 0);
  }

  private render() {
    if (!this.processorName) {
      return;
    }

    const processor = this.injector.get(this.processorName);
    this.processorInstance = processor.buildCreateView(this);

    this.processorInstance.title.pipe(takeUntil(this.providerChange$)).subscribe(trails => {
      this.title$.next(trails);
    });
  }

  private processorChange(): void {
    this.providerChange$.next();
    this.render();
  }
}
