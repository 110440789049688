import { values } from '@domain/lib/object/object';

export enum ETP_CATEGORIES {
  DIRECTION = 'direction',
  COOKING = 'cooking',
  ANIMATION = 'animation',
  ASH = 'ASH',
  SOCIAL_WORKER = 'social_worker',
  PSYCHOLOGIST = 'psychologist',
  NURSE = 'nurse',
  MEDICAL_ASSISTANT = 'medical_assistant',
  MEDICAL_COORDINATOR = 'medical_coordinator',
  OTHER = 'other',
}

export const ETPCategoriesValues = new Set<ETP_CATEGORIES>(values(ETP_CATEGORIES));
