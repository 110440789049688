import {Injectable} from '@angular/core';
import {AuthorizationChecker} from "@application/bundles/authorization";
import {FacilityAuthorizationChecker} from "@application/bundles/facility/authorization-checker";
import {Facility} from "@domain/facility";

@Injectable({
  providedIn: 'root'
})
export class FacilityAccessChecker {

  constructor(
    private readonly authorizationChecker: AuthorizationChecker,
    private readonly facilityAuthorizationChecker: FacilityAuthorizationChecker
  ) {
  }


  public canAccessToAdminList(): Promise<boolean> {
    return this.facilityAuthorizationChecker.canEdit();
  }


  public canAccessToAdminCreate(): Promise<boolean> {
    return this.facilityAuthorizationChecker.canCreate();
  }

  public async canAccessToPublicEdit(facility: Facility): Promise<boolean> {

    if (!(await this.facilityAuthorizationChecker.canEditAllProperties(facility))) {
      return this.facilityAuthorizationChecker.canEditOnlyRestrictedProperties(facility);
    }

    return true;
  }
}
