import { FileDepositRepository } from '@application/bundles/file-deposit/repositories';

import { FileDeposit } from '@domain/file-deposit';
import { EasyFileDeposit } from '@implementations/bundles/backend/easyhpad/interfaces/file-deposit/easy-file-deposit';
import { FileDepositSearchParams } from '@application/bundles/file-deposit';
import { RequestParams } from '@application/framework/http';
import { EasyEntityRepositoryV2 } from '@implementations/bundles/backend/easyhpad/repositories/base/easy-entity-repository-v2.abstract';
import { CreateFileDeposit } from '@application/bundles/file-deposit/contracts/create-file-deposit';

export class EasyFileDepositRepository
  extends EasyEntityRepositoryV2<FileDeposit, EasyFileDeposit, FileDeposit['id'], any, CreateFileDeposit>
  implements FileDepositRepository
{
  constructor() {
    super('file-deposits/entities', {
      from: input => input,
      to: output => output,
    });
  }

  public override async list(params?: FileDepositSearchParams): Promise<FileDeposit[]> {
    const easyEntities = await this.backend
      .get<{ entities: EasyFileDeposit[] }>(this.basePath, this.convertParams(params))
      .then(response => this.parseResponse(response))
      .then(body => body.entities);

    const entities: FileDeposit[] = [];

    for (const entity of easyEntities) {
      entities.push(await this.toEntity(entity));
    }

    return entities;
  }

  public override get(id: string | number): Promise<FileDeposit> {
    return this.backend
      .get<{ entity: EasyFileDeposit }>(`${this.basePath}/${id}`)
      .then(response => this.parseResponse(response))
      .then(body => this.toEntity(body.entity));
  }

  protected override convertParams(params?: FileDepositSearchParams): RequestParams {
    if (params === undefined) {
      return {};
    }

    return this.searchTransformer.toRequestParams(
      this.searchTransformer.fromSerializedFileDepositSearchParams(params.serialize()),
    );
  }
}
