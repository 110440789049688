<div *ngIf="form" [formGroup]="form" class="controls-container">
  <ng-container *ngFor="let field of fields">

    <ng-container [ngSwitch]="field.formElementType">

      <ehp-dynamic-stepper *ngSwitchCase="'stepper'" [field]="$any(field)"></ehp-dynamic-stepper>

      <ehp-dynamic-form-panels *ngSwitchCase="'panels'" [field]="$any(field)"></ehp-dynamic-form-panels>

      <ehp-dynamic-form-element *ngSwitchDefault [field]="field"></ehp-dynamic-form-element>

    </ng-container>

  </ng-container>

  <div *ngIf="actions && actions.length" class="actions">

    <ng-container *ngFor="let action of actions">

      <button (click)="action.onClick(form)" [disabled]="action.disabled" [ngClass]="action.class"
              [type]="action.type">
        <ehp-translatable-string [value]="action.label"></ehp-translatable-string>
      </button>
    </ng-container>

  </div>
</div>

<div *ngIf="hasMediaPreview && medias.size > 0" class="media-preview-container panel">
  <ehp-medias-viewer [medias]="medias"></ehp-medias-viewer>
</div>
