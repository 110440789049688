import { Facility } from '@domain/facility';
import { ResidentGIRList } from '@domain/gir/resident-gir.list';
import { UUID } from '@domain/lib';
import { Media } from '@domain/media';

export interface GMP {
  id: UUID;

  date: Date;

  year: number;

  value: number;

  residents: ResidentGIRList;

  pvId?: Media['id'];

  facilityId: Facility['id'];

  createdAt?: Date;

  updatedAt?: Date;
}
