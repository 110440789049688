import { ViewContainerRef } from '@angular/core';
import { TranslatableString } from '@application/framework/translation';
import { DocumentActionLink } from '@easyhpad-ui/app/bundles/documents/contracts/document-action';
import { DocumentViewProcessorProvider } from '@easyhpad-ui/app/bundles/documents/contracts/document-view';
import { Observable } from 'rxjs';

export interface DocumentListViewContainer {
  readonly quickFilterContainer: ViewContainerRef;

  readonly documentListContainer: ViewContainerRef;
}

export interface DocumentListViewProcessorInstance {
  /**
   * The title trails displayed in DocumentListViewContainer Component
   */
  title: Observable<Array<string | TranslatableString>>;

  /**
   * The list of action to display in DocumentListViewContainer Component
   */
  actions: Observable<{ create?: Omit<DocumentActionLink, 'classes' | 'label'> }>;

  /**
   * Call this method to destroy the component instance.
   */
  destroy: () => void;
}

export interface DocumentListViewProcessor {
  /**
   * Attach subcomponents in the parent view.
   * @param parent
   */
  buildListView: (parent: DocumentListViewContainer) => DocumentListViewProcessorInstance;
}

/**
 * Provide the processor to build a Document list page component for a type of document
 */
export type DocumentListViewProvider<C extends DocumentListViewProcessor> = DocumentViewProcessorProvider<'list', C>;
