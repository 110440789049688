<ng-container *ngIf="media">
    <ehp-media-icon [type]="media.type"></ehp-media-icon>

    <div class="name-container">
        <ng-content select=".pre-name"></ng-content>
        <span class="name">{{ media.name }}</span>
        <ng-content select=".post-name"></ng-content>
    </div>


    <div class="actions">
        <button (click)="openPreview()" *ngIf="preview" class="is-icon icon-eye ghost primary" type="button">
            <span class="show-for-sr">{{ "Ouvrir l'aperçu du média" | ehp_translate }}</span>
        </button>

        <a *ngIf="download && downloadUrl" [href]="downloadUrl" class="button is-icon icon-download ghost secondary"
           download=""
           target="_blank">
            <span class="show-for-sr">{{ 'Télécharger le média' | ehp_translate }}</span>
        </a>
    </div>
</ng-container>
