import {RefreshCurrentUserCommand} from "@application/bundles/user/commands/refresh-current-user.command";
import {CommandBus} from "@application/framework/command-query";
import {HttpAuthorizationTokenStore} from "@application/framework/http";
import {User} from "@domain/user";
import {EasyBackendApi} from "@implementations/bundles/backend/easyhpad/api";
import {EASYHPAD_BEARER_AUTH_TOKEN} from "@implementations/bundles/backend/easyhpad/backend.tokens";
import {
  EasyHpadBearerHttpTokenFactory
} from "@implementations/bundles/backend/easyhpad/factories/bearer-http-token-factory";
import {EasyhpadBackendAuthCredentials} from "@implementations/bundles/backend/easyhpad/interfaces";
import {
  HttpAuthorizationResponse
} from "@implementations/bundles/backend/easyhpad/interfaces/http-authorization-response.interface";

export class EasyHpadLoginUseCase {

  constructor(
    private readonly backend: EasyBackendApi,
    private readonly tokenFactory: EasyHpadBearerHttpTokenFactory,
    private readonly tokenStore: HttpAuthorizationTokenStore,
    private readonly commandBus: CommandBus
  ) {
  }

  public async execute(credentials: EasyhpadBackendAuthCredentials): Promise<User> {

    const response = await this.backend.post<HttpAuthorizationResponse>('auth/login', credentials);
    const token = this.tokenFactory.createFromHttpAuthorizationResponse(response.body);
    this.tokenStore.set(EASYHPAD_BEARER_AUTH_TOKEN, token);

    return this.commandBus.execute<User>(new RefreshCurrentUserCommand());
  }

}
