import { FundingAmountsByYear } from '@application/bundles/funding';
import { Facility } from '@domain/facility';

export abstract class FundingAmountRepository<F> {
  /**
   * Get latest stats.
   * @param facilityIds
   */
  public abstract findLatestOne(facilityIds?: Array<Facility['id']>): Promise<FundingAmountsByYear<F>>;
}
