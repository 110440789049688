import { Customer } from '@domain/customer';
import { UpdateUser, User } from '@domain/user';
import { IsArray, IsNotEmpty, IsOptional, IsUUID } from 'class-validator';
import { UserCommonDtoAbstract } from './user-common.dto.abstract';
import { Role } from '@domain/authorization';

export class UpdateUserDtoImpl extends UserCommonDtoAbstract implements UpdateUser {
  @IsUUID()
  public id!: User['id'];

  @IsOptional()
  @IsArray()
  @IsNotEmpty()
  public roles?: Array<Role> | undefined;

  public customer?: Customer;
}
