import { Injectable } from '@angular/core';
import { CPOMRepository, StoredCPOM } from '@application/bundles/cpom';
import { CPOMAuthorizationChecker } from '@application/bundles/cpom/cpom-authorization-checker';
import { joinPath } from '@application/framework/lib';

@Injectable()
export class CPOMUrlsProvider {
  public static readonly base = 'cpom';

  public static readonly publicSchema = {
    list: { path: '', title: 'C.P.O.M.' },
    create: { path: 'create', title: 'Créer un nouveau C.P.O.M.' },
    show: { path: ':id', title: 'Créer un nouveau C.P.O.M.' },
    edit: { path: ':id/edit', title: 'Modifier le C.P.O.M.' },
  };

  constructor(
    public readonly authorization: CPOMAuthorizationChecker,
    private readonly repository: CPOMRepository,
  ) {}

  public getPublicList(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList(): string {
    return joinPath('/', CPOMUrlsProvider.base);
  }

  public getPublicCreate(): Promise<string | undefined> {
    return this.authorization.canCreate().then(can => (can ? this.getUnsafePublicCreate() : undefined));
  }

  public getUnsafePublicCreate(): string {
    return joinPath('/', CPOMUrlsProvider.base, CPOMUrlsProvider.publicSchema.create.path);
  }

  public getPublicShow(id: StoredCPOM['id']): Promise<string | undefined> {
    return this.authorization.canRead().then(can => (can ? this.getUnsafePublicShow(id) : undefined));
  }

  public getUnsafePublicShow(id: StoredCPOM['id']): string {
    return joinPath('/', CPOMUrlsProvider.base, id);
  }

  public async getPublicUpdate(id: StoredCPOM['id']): Promise<string | undefined> {
    const cpom = await this.repository.get(id);

    return this.authorization.canUpdate(cpom).then(can => (can ? this.getUnsafePublicUpdate(id) : undefined));
  }

  public getUnsafePublicUpdate(id: StoredCPOM['id']): string {
    return joinPath('/', CPOMUrlsProvider.base, id.toString(), 'edit');
  }
}
