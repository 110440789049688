import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryBus } from '@application/framework/command-query';
import { Facility } from '@domain/facility';
import { Subscription } from '@application/framework/reactive';
import { StoredLocalCPOM } from '@application/bundles/cpom';
import { CPOMUrlsProvider } from '@easyhpad-ui/app/bundles/cpom';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { selectDashboardFacilities } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.selector';
import { GetCPOMWidgetQuery } from '@easyhpad-ui/app/bundles/cpom/queries';
import { CurrentCpomWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces/widgets/current-cpom-widget-content';

@Component({
  selector: 'ehp-widget-cpom',
  templateUrl: './widget-cpom.component.html',
  styleUrls: ['./widget-cpom.component.scss'],
})
export class WidgetCPOMComponent implements OnInit, OnDestroy {
  public url: string | undefined;

  public createUrl: string | undefined;

  public single: boolean = false;

  public average: { count: number; total: number } | undefined;

  public cpom: StoredLocalCPOM | undefined;

  private subscription: Subscription | undefined;

  constructor(
    private readonly store: Store<AppState>,
    private readonly queryBus: QueryBus,
    private readonly urls: CPOMUrlsProvider,
  ) {
    this.urls.getPublicCreate().then(url => (this.createUrl = url));
  }

  public ngOnInit() {
    this.subscription = this.store
      .select(selectDashboardFacilities)
      .subscribe(facilities => this.facilitiesChanges(facilities));
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private async facilitiesChanges(facilities: Array<Facility['id']>) {
    this.reset();

    this.single = facilities.length <= 1;

    const content = await this.queryBus.request<CurrentCpomWidgetContent>(new GetCPOMWidgetQuery(facilities));

    if (content.cpom) {
      this.cpom = content.cpom.children[0];
      this.url = this.urls.getUnsafePublicShow(content.cpom.id);
    }

    let count = facilities.length - content.count;

    if (count < 0) {
      count = 0;
    }

    this.average = { count, total: facilities.length };
  }

  private reset(): void {
    this.average = undefined;
    this.cpom = undefined;
    this.url = undefined;
  }
}
