import { Media } from '@domain/media';
import { Facility } from '@domain/facility';
import { EHPAD_DEMAIN_TYPE, EhpadDemainType } from '@domain/ehpad-demain/ehpad-demain-types';
import { UUID } from '@domain/lib';

export interface EhpadDemain {
  id: UUID;

  name: string;

  typeId: EHPAD_DEMAIN_TYPE;

  amount: number;

  comment: string | undefined;

  start: Date;

  end: Date;

  readonly hasConvention: boolean;

  conventionId: Media['id'] | undefined;

  otherConventionId: Media['id'] | undefined;

  facilitiesIds: Array<Facility['id']>;

  mediasIds: Array<Media['id']>;

  createdAt?: Date;

  updatedAt?: Date;

  type(): Promise<EhpadDemainType | undefined>;

  convention(): Promise<Media | undefined>;

  otherConvention(): Promise<Media | undefined>;

  facilities(): Promise<Facility[]>;

  medias(): Promise<Media[]>;
}
