import {Injectable} from "@angular/core";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  CreateLocalCPOMDto,
  StoredLocalCPOM,
  TariffOptionCollection,
  UpdateLocalCPOMDto
} from "@application/bundles/cpom";
import {Common} from "@application/framework/lib";
import {
  isEmptyCPOMFunding,
  isFundingCollection,
  isTariffOption,
  isValidTariffOptionType,
  LocalCPOM
} from "@domain/cpom";
import {
  CPOMFundingFormBuilder
} from "@easyhpad-ui/app/bundles/cpom/services/cpom-funding-form-builder/cpom-funding-form-builder";
import {isMaybeAFacility, isValidFacilityIdType} from "@application/bundles/facility";


type CommonProperties = Common<CreateLocalCPOMDto, UpdateLocalCPOMDto>;

@Injectable()
export class LocalCPOMFormBuilder {

  public static readonly SOCIAL_BED_CARE_KEY = 'bedSocialCare';

  public static readonly FUNDING_FORM_ARRAY_NAME = 'funding';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cpomFundingFormBuilder: CPOMFundingFormBuilder,
    private readonly tariffOptionCollection: TariffOptionCollection
  ) {
  }

  public buildCreationForm(localCPOM?: Partial<LocalCPOM | StoredLocalCPOM>): FormGroup<Record<keyof CreateLocalCPOMDto, any>> {

    let fundingControls: AbstractControl[] = [];

    if (localCPOM && Array.isArray(localCPOM.funding)) {
      fundingControls = localCPOM.funding.map(funding => this.cpomFundingFormBuilder.buildCreateFundingForm(funding));
    }

    const controls: Record<keyof CreateLocalCPOMDto, any> = {
      ...this.buildCommonForm(this.serializeFormValues(localCPOM)),
      funding: this.formBuilder.array(fundingControls)
    }

    return this.formBuilder.group(controls);
  }

  public buildUpdateForm(localCPOM?: Partial<LocalCPOM | StoredLocalCPOM>): FormGroup<Record<keyof Omit<UpdateLocalCPOMDto, 'id'>, any>> {

    let fundingControls: AbstractControl[] = [];

    if (localCPOM && Array.isArray(localCPOM.funding)) {
      fundingControls = localCPOM.funding.map(funding => this.cpomFundingFormBuilder.buildUpdateFundingForm(funding));
    }

    const controls: Record<keyof CreateLocalCPOMDto, any> = {
      ...this.buildCommonForm(this.serializeFormValues(localCPOM)),
      funding: this.formBuilder.array(fundingControls)
    }

    return this.formBuilder.group(controls);

  }

  public addCreateFundingFormRow(control: FormArray): void {
    if (!control) {
      return;
    }

    this.cpomFundingFormBuilder.addCreateFunding(control);
  }

  public addUpdateFundingFormRow(control: FormArray): void {
    if (!control) {
      return;
    }
    this.cpomFundingFormBuilder.addUpdateFunding(control);

  }

  public serializeFormValues(localCPOM?: Partial<LocalCPOM | StoredLocalCPOM>): Record<keyof CommonProperties, any> {

    const serialized: Record<keyof CommonProperties, any> = {
      bedSocialCare: localCPOM?.bedSocialCare,
      funding: [],
      tariffOption: localCPOM?.tariffOption?.type,
      facilityId: undefined
    }


    if (localCPOM) {

      if ('facilityId' in localCPOM) {
        serialized.facilityId = localCPOM.facilityId;
      }

      if (isFundingCollection(localCPOM.funding)) {
        serialized.funding = Array.from(localCPOM.funding).map(funding => this.cpomFundingFormBuilder.serializeFormValue(funding))
      }

    }

    return serialized;
  }

  public deserializeFormValues<T extends CreateLocalCPOMDto | UpdateLocalCPOMDto>(values: Record<keyof CreateLocalCPOMDto | keyof UpdateLocalCPOMDto, any>): T {

    const deserialized: Partial<Record<keyof CreateLocalCPOMDto | keyof UpdateLocalCPOMDto, any>> = {
      bedSocialCare: values.bedSocialCare,
      funding: [],
    };

    if (isTariffOption(values.tariffOption)) {
      deserialized.tariffOption = values.tariffOption;
    } else if (values.tariffOption.type && isValidTariffOptionType(values.tariffOption.type)) {
      deserialized.tariffOption = this.tariffOptionCollection.get(values.tariffOption.type);
    } else if (isValidTariffOptionType(values.tariffOption)) {
      deserialized.tariffOption = this.tariffOptionCollection.get(values.tariffOption);
    }

    if (values.facilityId) {

      if (isValidFacilityIdType(values.facilityId)) {
        deserialized.facilityId = values.facilityId;
      } else if (isMaybeAFacility(values.facilityId)) {
        deserialized.facilityId = values.facilityId.id;
      }
    }

    if (Array.isArray(values.funding)) {
      deserialized.funding = values.funding
        .map(funding => this.cpomFundingFormBuilder.deserializeFormValues(funding))
        .filter(funding => !isEmptyCPOMFunding(funding));
    }


    if ('id' in values) {
      deserialized.id = values.id;
    }

    return deserialized as T;
  }

  public rebuildFormValue(form: FormGroup, cpom?: Partial<LocalCPOM>) {
    
    const values = this.serializeFormValues(cpom);
    form.patchValue(values);
  }

  private buildCommonForm(values?: Record<keyof CommonProperties, any>): Record<keyof Omit<CommonProperties, 'funding'>, FormControl> {

    return {
      [LocalCPOMFormBuilder.SOCIAL_BED_CARE_KEY]: new FormControl(values?.bedSocialCare, [Validators.min(0)]),
      tariffOption: new FormControl<any>(values?.tariffOption, [Validators.required]),
      facilityId: new FormControl<any>(values?.facilityId, [Validators.required]),
    };
  }
}
