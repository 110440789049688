import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import {
  GetFirstJanuaryTariffsQuery,
  GetFirstJanuaryTariffsQueryResult,
} from '@application/bundles/dependency-funding';
import { DependencyFunding } from '@domain/dependency-funding';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';

@HandleQuery({
  query: GetFirstJanuaryTariffsQuery,
})
export class GetFirstJanuaryTariffsQueryHandler implements QueryHandler<GetFirstJanuaryTariffsQuery> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: GetFirstJanuaryTariffsQuery): Promise<GetFirstJanuaryTariffsQueryResult> {
    const tariffs = await this.backend
      .get<{ entity: { result: GetFirstJanuaryTariffsQueryResult } }>(this.buildEndpoint(query.id))
      .then(response => response.body)
      .then(body => body.entity.result);

    return tariffs ?? null;
  }

  private buildEndpoint(id: DependencyFunding['id']): string {
    return `/funding/dependency/entities/${id}/first-january-tariffs`;
  }
}
