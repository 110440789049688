import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
  CapacityAuthorizationAuthorizationChecker,
  CapacityAuthorizationUpdatedEvent,
  CapacityAuthorizationUpdateFailEvent,
  UpdateCapacityAuthorizationCommand,
} from '@application/bundles/capacity-authorization';
import { UpdateCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto';
import { HandleCommand } from '@application/framework/command-query';
import { CommandHandler } from '@application/framework/command-query/handler.interface';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { CapacityAuthorization, CapacityAuthorizationRepository } from '@domain/capacity-authorization';
import { isLocalMedia, isMedia, Media, MediaBucket, TemporaryMedia } from '@domain/media';

@HandleCommand({
  command: UpdateCapacityAuthorizationCommand,
})
export class UpdateCapacityAuthorizationCommandHandler
  implements CommandHandler<UpdateCapacityAuthorizationCommand, CapacityAuthorization>
{
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly authorization: CapacityAuthorizationAuthorizationChecker,
    private readonly sanitizer: SanitizerLibrary,
    private readonly validator: ObjectValidator,
    private readonly repository: CapacityAuthorizationRepository,
    private readonly mediaBucket: MediaBucket,
    private readonly eventDispatcher: EventDispatcher,
  ) {}

  public async handle(command: UpdateCapacityAuthorizationCommand): Promise<CapacityAuthorization> {
    const { id, dto } = command;

    const current = await this.repository.get(id);

    try {
      await this.checkAccess(current);

      const authorization = await this.sanitize(dto)
        .then(dto => this.validate(dto))
        .then(dto => this.storeMediaAndCreateAuthorization(current, dto));

      this.eventDispatcher.dispatch(new CapacityAuthorizationUpdatedEvent(authorization));

      return authorization;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(authorization: CapacityAuthorization): Promise<void> {
    if (!(await this.authorization.canEdit(authorization))) {
      this.logger.error('Capacity authorization update : Forbidden');
      throw new ForbiddenError();
    }
  }

  private async sanitize(dto: UpdateCapacityAuthorizationDto): Promise<UpdateCapacityAuthorizationDto> {
    try {
      dto = await this.sanitizer.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('Capacity authorization : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdateCapacityAuthorizationDto): Promise<UpdateCapacityAuthorizationDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('Capacity authorization : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à modifier cette autorisation de capacité.",
        {},
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new CapacityAuthorizationUpdateFailEvent(message));

    return error;
  }

  private async storeMediaAndCreateAuthorization(current: CapacityAuthorization, dto: UpdateCapacityAuthorizationDto) {
    const attachments: {
      authorization?: TemporaryMedia;
      pv?: TemporaryMedia;
    } = {};

    const deleteMedias: Array<Media['id'] | undefined> = [];

    if (
      dto.authorizationId !== current.authorizationId ||
      isLocalMedia(dto.authorization) ||
      isMedia(dto.authorization)
    ) {
      if (isLocalMedia(dto.authorization)) {
        attachments.authorization = await this.mediaBucket.add(dto.authorization);
        dto.authorizationId = attachments.authorization.id;
        deleteMedias.push(current.authorizationId);
      } else if (isMedia(dto.authorization)) {
        dto.authorizationId = dto.authorization.id;

        if (dto.authorizationId !== current.authorizationId) {
          deleteMedias.push(current.authorizationId);
        }
      }
    }

    if (dto.pvId !== current.pvId || isLocalMedia(dto.pv) || isMedia(dto.pv)) {
      if (isLocalMedia(dto.pv)) {
        attachments.pv = await this.mediaBucket.add(dto.pv);
        dto.pvId = attachments.pv.id;
        deleteMedias.push(current.pvId);
      } else if (isMedia(dto.pv)) {
        dto.pvId = dto.pv.id;

        if (dto.pvId !== current.pvId) {
          deleteMedias.push(current.pvId);
        }
      }
    }
    dto.inspectionReports = await current.inspectionReports();

    try {
      const updated = await this.repository.update(current.id, dto);

      for (const id of deleteMedias) {
        if (id) {
          await this.mediaBucket.delete(id);
        }
      }

      return updated;
    } catch (e) {
      if (attachments.authorization) {
        await attachments.authorization.markForDeletion();
      }

      if (attachments.pv) {
        await attachments.pv.markForDeletion();
      }

      throw e;
    }
  }
}
