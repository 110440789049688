import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[ehpSpreadsheetPreviewHost]'
})
export class SpreadsheetPreviewHostDirective {


  public get nativeElement() {
    return this.elementRef.nativeElement;
  }

  constructor(
    private readonly elementRef: ElementRef
  ) {
  }

}
