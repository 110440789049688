import {
  CPOMDtoFactory,
  CreateCPOMDto,
  CreateCPOMFundingDto,
  CreateLocalCPOMDto,
  UpdateCPOMDto,
  UpdateCPOMFundingDto,
  UpdateLocalCPOMDto
} from "@application/bundles/cpom";
import {
  CreateCPOMDtoImpl,
  CreateCPOMFundingDtoImpl,
  CreateLocalCPOMDtoImpl,
  UpdateCPOMDtoImpl,
  UpdateCPOMFundingDtoImpl,
  UpdateLocalCPOMDtoImpl
} from "@implementations/bundles/cpom";

export class CpomItemsDtoFactory implements CPOMDtoFactory {

  public buildCreateCPOMDtoFromUnsafeValues(values: Partial<CreateCPOMDto>): CreateCPOMDto {
    return new CreateCPOMDtoImpl(values);
  }

  public buildCreateCPOMFundingDtoFromUnsafeValues(values: Partial<CreateCPOMFundingDto>): CreateCPOMFundingDto {
    return new CreateCPOMFundingDtoImpl(values);
  }

  public buildCreateLocalCPOMDtoFromUnsafeValues(values: Partial<CreateLocalCPOMDto>): CreateLocalCPOMDto {
    return new CreateLocalCPOMDtoImpl(values);
  }

  public buildUpdateCPOMDtoFromUnsafeValues(values: Partial<UpdateCPOMDto>): UpdateCPOMDto {
    return new UpdateCPOMDtoImpl(values);
  }

  public buildUpdateCPOMFundingDtoFromUnsafeValues(values: Partial<UpdateCPOMFundingDto>): UpdateCPOMFundingDto {
    return new UpdateCPOMFundingDtoImpl(values);
  }

  public buildUpdateLocalCPOMDtoFromUnsafeValues(values: Partial<UpdateLocalCPOMDto>): UpdateLocalCPOMDto {
    return new UpdateLocalCPOMDtoImpl(values);
  }

}
