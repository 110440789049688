import {HttpStatusCode} from "@application/framework/http/http-code";

export enum REQUEST_METHOD {
  OPTIONS = 'OPTIONS',
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DElETE'
}

export type RequestParams = {
  [param: string]: string | number | boolean;
};

export interface Request<M = REQUEST_METHOD, B = any | null> {

  /**
   *
   */
  url: URL;

  method: M,

  body?: B;

  params?: RequestParams;
}

export interface Response<Body = unknown> {
  body: Body;

  status: HttpStatusCode;
}

