import {TariffOptionCollection} from "@application/bundles/cpom";
import {TARIFF_OPTIONS, TariffOption} from "@domain/cpom/tariff-option";
import {Service} from "@application/framework/di";
import {
  AgnosticTariffOption
} from "@application/bundles/cpom/implementations/agnostic-tariff-option/agnostic-tariff-option";

@Service()
export class AgnosticTariffOptionCollection implements TariffOptionCollection {

  private readonly collection: Map<TariffOption["type"], TariffOption> = new Map([
    [TARIFF_OPTIONS.PARTIAL_WO_PUI, new AgnosticTariffOption(TARIFF_OPTIONS.PARTIAL_WO_PUI, 'Partiel sans PUI')],
    [TARIFF_OPTIONS.PARTIAL_WI_PUI, new AgnosticTariffOption(TARIFF_OPTIONS.PARTIAL_WI_PUI, 'Partiel avec PUI')],
    [TARIFF_OPTIONS.GLOBAL_WO_PUI, new AgnosticTariffOption(TARIFF_OPTIONS.GLOBAL_WO_PUI, 'Global sans PUI')],
    [TARIFF_OPTIONS.GLOBAL_WI_PUI, new AgnosticTariffOption(TARIFF_OPTIONS.GLOBAL_WI_PUI, 'Global avec PUI')],
  ])

  public has(type: TariffOption["type"]): boolean {
    return this.collection.has(type);
  }

  public list(): TariffOption[] {
    return Array.from(this.collection.values());
  }

  public get(type: TariffOption["type"]): TariffOption | undefined {
    return this.collection.get(type);
  }

}
