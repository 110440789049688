import { GMP } from '@domain/gmp';
import { LocalMedia, Media } from '@domain/media';

export interface CreateGMPDto extends Omit<GMP, 'id' | 'pvId' | 'year' | 'createdAt' | 'updatedAt'> {
  pvId?: Media['id'];

  pv: LocalMedia;
}

export interface UpdateGMPDto extends Omit<GMP, 'id' | 'pvId' | 'year' | 'createdAt' | 'updatedAt'> {
  pvId?: Media['id'];

  pv?: LocalMedia | Media;
}
