import { ComputableResidentGirListImpl, ResidentGirListImplValues } from '@application/bundles/gir';
import { Service } from '@application/framework/di';
import { ComputableResidentGIRList, GIR, GIRValues } from '@domain/gir';
import { GMP } from '@domain/gmp';

@Service()
export class ResidentGirListFactory {
  /**
   * Create new ComputableResidentGIRList from G.M.P. resident list.
   * @param gmp - The G.M.P. that contains resident list.
   */
  public createComputableResidentGIRListFromGMP(gmp: GMP): ComputableResidentGIRList {
    const values: ResidentGirListImplValues = new Map<GIR, number>();

    for (const gir of GIRValues) {
      values.set(gir, gmp.residents[gir] ?? 0);
    }

    return this.createComputableResidentGIRList(values);
  }

  /**
   * Build new ComputableResidentGIRList
   * @param values
   */
  public createComputableResidentGIRList(values?: Map<GIR, number>): ComputableResidentGIRList {
    return new ComputableResidentGirListImpl(values);
  }
}
