import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { DeleteFacilityCommand } from '@application/bundles/facility/commands/delete-facility.command';
import { CommandBus } from '@application/framework/command-query';
import { ConfirmFacilityDeletionDialog } from '@easyhpad-ui/app/bundles/facility/components/confirm-facility-deletion-dialog/confirm-facility-deletion-dialog-data.interface';

@Component({
  selector: 'ehp-confirm-facility-deletion-dialog',
  templateUrl: './confirm-facility-deletion-dialog.component.html',
  styleUrls: ['./confirm-facility-deletion-dialog.component.scss'],
})
export class ConfirmFacilityDeletionDialogComponent {
  public control: FormControl<string | null>;

  public constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: ConfirmFacilityDeletionDialog,
    private readonly commandBus: CommandBus,
  ) {
    this.validate = this.validate.bind(this);

    if (!this.data.facility || !this.data.facility.name) {
      this.cancel();
    }

    this.control = new FormControl<string>('', {
      validators: [this.validate],
      updateOn: 'change',
    });
  }

  public confirm(): void {
    if (!this.control.valid) {
      return;
    }

    if (this.data.runDeletion === false) {
      this.dialogRef.close(true);
    } else {
      this.commandBus.execute(new DeleteFacilityCommand(this.data.facility)).then(deleted => {
        this.dialogRef.close(deleted);
      });
    }
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  private validate(control: AbstractControl): ValidationErrors | null {
    const isValid = this.data.facility && this.data.facility.name && control.value === this.data.facility.name;

    if (isValid) {
      return null;
    }
    return { customerFacilityMismatch: true };
  }
}
