import {Cache} from "./cache.abstract";

export abstract class CacheManager {

  public abstract get<T = unknown>(key: string | Symbol): Promise<Cache<T>>;

  public abstract get<T = unknown>(config: { key: string | Symbol, factory: () => Promise<Cache<T>> }): Promise<Cache<T>>;

  public abstract reset(key: string | Symbol): Promise<void>;

}
