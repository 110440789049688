import { Attribute, ATTRIBUTE_SCOPE, CRUD_ACTIONS } from '@application/bundles/authorization';
import { AbstractVoter } from '@application/bundles/authorization/voter';
import { AuthentificationToken } from '@application/bundles/authentification';

export type OwnerShipChecker<T> = (subject: T, token: AuthentificationToken) => Promise<boolean>;

export class CrudPermissionVoter<T = any> extends AbstractVoter {
  constructor(
    private readonly feature: Symbol | string,
    private readonly ownershipChecker: OwnerShipChecker<T>,
  ) {
    super();
  }

  protected supports(attribute: Attribute, subject: any): boolean {
    return attribute.feature === this.feature;
  }

  protected async voteOnAttribute(attribute: Attribute, subject: any, token: AuthentificationToken): Promise<boolean> {
    const permissions = token.permissions;

    let hasPermission = false;

    if (permissions.has(attribute.feature, CRUD_ACTIONS.MANAGE)) {
      hasPermission = true;
    } else {
      hasPermission = permissions.has(attribute.feature, attribute.value);
    }

    if (attribute.scope === ATTRIBUTE_SCOPE.OWNED) {
      return hasPermission && this.ownershipChecker(subject, token);
    }

    return hasPermission;
  }
}
