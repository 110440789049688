import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { QueryBus } from '@application/framework/command-query';
import { Provide } from '@application/framework/di';
import { Facility } from '@domain/facility';
import { Media, MediaBucket } from '@domain/media';
import { ActivityAnnex, isValidAnnexActivityDaysCount } from '@domain/activity-annex';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { isValidMediaIdType } from '@application/bundles/media';
import { isPercentage } from '@domain/lib/math/percentage/percentage';

export class ActivityAnnexImpl implements ActivityAnnex {
  public id!: ActivityAnnex['id'];

  public date!: Date;

  public depositDate: Date | undefined;

  public facilityId!: Facility['id'];

  public documentId!: Media['id'];

  public occupationRate: number = 0;

  public days: number = 0;

  @Provide()
  private readonly queryBus!: QueryBus;

  @Provide()
  private readonly mediaBucket!: MediaBucket;

  public get year() {
    return this.date.getFullYear();
  }

  constructor(values?: Omit<ActivityAnnex, 'year' | 'document' | 'facility'>) {
    if (values) {
      this.id = values.id;

      if (isValidFacilityIdType(values.facilityId)) {
        this.facilityId = values.facilityId;
      }

      if (values.date instanceof Date) {
        this.date = values.date;
      }

      if (values.depositDate instanceof Date) {
        this.depositDate = values.depositDate;
      }

      if (isValidMediaIdType(values.documentId)) {
        this.documentId = values.documentId;
      }

      if (isPercentage(values.occupationRate)) {
        this.occupationRate = values.occupationRate;
      }

      if (typeof values.days === 'number') {
        if (values.date instanceof Date && isValidAnnexActivityDaysCount(values.days, values.date.getFullYear())) {
          this.days = values.days;
        } else if (isValidAnnexActivityDaysCount(this.days)) {
          this.days = values.days;
        }
      }
    }
  }

  public facility(): Promise<Facility> {
    return this.queryBus.request(new GetFacilityQuery(this.facilityId));
  }

  public document(): Promise<Media> {
    return this.mediaBucket.get(this.documentId);
  }
}
