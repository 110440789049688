import {Injectable} from "@angular/core";
import {joinPath} from "@application/framework/lib";
import {ERRDAuthorizationChecker, ERRDRepository} from "@application/bundles/eprd-errd";
import {EPRD} from "@domain/eprd-errd";

@Injectable()
export class ERRDUrlsProvider {

  public static readonly base = 'errd';

  public static readonly publicSchema = {
    list: {path: '', title: 'E.R.R.D'},
    create: {path: 'create', title: 'Créer un nouvel E.R.R.D'},
    show: {path: ':id', title: 'E.R.R.D'},
    edit: {path: ':id/edit', title: 'Modifier l\'E.R.R.D'},
  }

  constructor(
    public readonly authorization: ERRDAuthorizationChecker,
    private readonly repository: ERRDRepository
  ) {
  }

  public getPublicList(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList(): string {
    return joinPath('/', ERRDUrlsProvider.base);
  }

  public getPublicCreate(): Promise<string | undefined> {
    return this.authorization.canCreate()
      .then(can => can ? this.getUnsafePublicCreate() : undefined);
  }

  public getUnsafePublicCreate(): string {
    return joinPath('/', ERRDUrlsProvider.base, ERRDUrlsProvider.publicSchema.create.path);
  }

  public getPublicShow(id: EPRD['id']): Promise<string | undefined> {
    return this.authorization.canRead()
      .then(can => can ? this.getUnsafePublicShow(id) : undefined);
  }

  public getUnsafePublicShow(id: EPRD['id']): string {
    return joinPath('/', ERRDUrlsProvider.base, id.toString());
  }

  public async getPublicUpdate(id: EPRD['id']): Promise<string | undefined> {
    const eprd = await this.repository.get(id);

    return this.authorization.canUpdate(eprd)
      .then(can => can ? this.getUnsafePublicUpdate(id) : undefined);
  }

  public getUnsafePublicUpdate(id: EPRD['id']): string {
    return joinPath('/', ERRDUrlsProvider.base, id.toString(), 'edit');
  }

}
