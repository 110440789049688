import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {Component, Inject} from '@angular/core';
import {Translator} from "@application/framework/translation";
import {SelectableTableColumnsDialogData} from "../../selectable-table-columns-dialog-data";
import {TableColumn} from "../../table-column";

@Component({
  selector: 'ehp-selectable-table-columns-dialog',
  templateUrl: './selectable-table-columns-dialog.component.html',
  styleUrls: ['./selectable-table-columns-dialog.component.scss']
})
export class SelectableTableColumnsDialogComponent {

  public columns: TableColumn[] = [];

  public title = 'Afficher / Masquer des colonnes';

  constructor(
    @Inject(DIALOG_DATA) private data: SelectableTableColumnsDialogData,
    private dialogRef: DialogRef,
    private translator: Translator
  ) {

    if (this.data.columns && this.data.columns.keys.length > 1) {
      this.columns = this.data.columns.columns.filter(column => column.maskable !== false);
      this.data.columns.columns.forEach(column => {
        this.translator.translate(column.label).then(translation => {
          const index = this.columns.findIndex(c => c.label === column.label);
          if (index !== -1) {
            this.columns[index].label = translation;
          }
        })
      });

    } else {
      this.dialogRef.close(undefined);
    }

    if (this.data.title) {
      this.title = this.data.title;
    }
  }

  public toggle(key: string) {
    return this.data.columns.toggle(key);
  }

  public isVisible(key: string) {
    return this.data.columns.selection.isSelected(key);
  }

  public close() {
    this.dialogRef.close();
  }
}
