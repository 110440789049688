import {ForbiddenError} from "@application/bundles/authorization/error";
import {
  ActivityAnnexDeletedEvent,
  ActivityAnnexDeletionFailEvent,
  ActivityAnnexRepository,
  DeleteActivityAnnexCommand
} from "@application/bundles/activity-annex";
import {ACTIVITY_ANNEX_TRANSLATE_CONTEXT} from "@application/bundles/activity-annex/activity-annex.token";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {TranslatableString} from "@application/framework/translation";
import {ActivityAnnex} from "@domain/activity-annex";
import {ActivityAnnexAuthChecker} from "@application/bundles/activity-annex/activity-annex-authorization-checker";


@HandleCommand({
  command: DeleteActivityAnnexCommand
})
export class DeleteActivityAnnexCommandHandler implements CommandHandler<DeleteActivityAnnexCommand, ActivityAnnex> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private repository: ActivityAnnexRepository,
    private authorization: ActivityAnnexAuthChecker,
    private eventDispatcher: EventDispatcher,
  ) {
  }

  public async handle(command: DeleteActivityAnnexCommand): Promise<ActivityAnnex> {

    const {activityAnnex} = command;
    try {
      const deleted = await this.checkAccess(activityAnnex)
        .then(() => this.repository.delete(activityAnnex.id));

      this.eventDispatcher.dispatch(new ActivityAnnexDeletedEvent(deleted));

      return deleted;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }


  private async checkAccess(activityAnnex: ActivityAnnex): Promise<ActivityAnnex> {

    if (!await this.authorization.canDelete(activityAnnex)) {
      this.logger.error('Activity annex deletion : Forbidden');
      throw new ForbiddenError();
    }

    return activityAnnex;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à supprimer cette annexe activité.",
        undefined,
        ACTIVITY_ANNEX_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new ActivityAnnexDeletionFailEvent(message));

    return error;
  }

}
