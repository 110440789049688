import { HealthFundingSearchParams } from '@application/bundles/health-funding';
import { DocumentListParamsImpl } from '@application/framework/search';
import { isValidYear, Year } from '@domain/lib';
import {
  DependencyFundingDocumentParams,
  SerializedDependencyFundingSearchParams,
} from '@application/bundles/dependency-funding';
import { SORT_ORDER } from '@application/framework/search/sort';

export class AgnosticHealthFundingSearchParams extends DocumentListParamsImpl implements HealthFundingSearchParams {
  private accountingYear: Year | undefined;

  constructor() {
    super();
    this.setSort({ key: 'accountingYear', order: SORT_ORDER.DESC });
  }

  public setAccountingYear(year: Year | undefined): this {
    if (isValidYear(year) || year === undefined) {
      this.accountingYear = year;
    }

    return this;
  }

  public override serialize(): SerializedDependencyFundingSearchParams {
    const serialized: SerializedDependencyFundingSearchParams = super.serialize();

    const params = serialized.params || ({} as DependencyFundingDocumentParams);

    if (isValidYear(this.accountingYear)) {
      params.accountingYear = this.accountingYear;
    }

    serialized.params = params;

    return serialized;
  }

  public override deserialize(params?: SerializedDependencyFundingSearchParams): this {
    super.deserialize(params);

    this.setAccountingYear(params?.params?.accountingYear);

    return this;
  }
}
