import { Component, Input } from '@angular/core';
import { TranslatableString } from '@application/framework/translation';
import { DOCUMENT_TYPES } from '@domain/document';
import { listDocumentsTypeLabels } from '@easyhpad-ui/app/bundles/documents/functions';

@Component({
  selector: 'ehp-document-type-label',
  templateUrl: './document-type-label.component.html',
  styleUrls: ['./document-type-label.component.scss'],
})
export class DocumentTypeLabelComponent {
  @Input() public type!: DOCUMENT_TYPES;

  private readonly labels = listDocumentsTypeLabels();

  public get label(): TranslatableString | string {
    return this.type && this.labels.get(this.type) ? (this.labels.get(this.type) as TranslatableString) : '';
  }
}
