import { Service } from '@application/framework/di';
import { GMP } from '@domain/gmp';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Service()
export class GMPAuthorizationChecker {
  constructor(private readonly authorization: DocumentAuthorization) {}

  public canRead(gmp?: GMP) {
    return this.authorization.canReadDocument(gmp);
  }

  public canCreate() {
    return this.authorization.canCreateDocument();
  }

  public canUpdate(gmp: GMP) {
    return this.authorization.canEditDocument(gmp);
  }

  public canDelete(gmp: GMP) {
    return this.authorization.canDeleteDocument(gmp);
  }
}
