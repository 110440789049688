<form (ngSubmit)="submit()" *ngIf="form" [formGroup]="form">

  <ehp-form-field class="form-field required">
    <label for="email">{{'E-mail'| ehp_translate}}</label>
    <input ehp-form-control formControlName="email" id="email" type="email">
  </ehp-form-field>

  <ehp-form-field>
    <label for="password">{{'Mot de passe' | ehp_translate}}</label>
    <ehp-password-input ehp-form-control formControlName="password" id="password"></ehp-password-input>
  </ehp-form-field>

  <div *ngIf="forgottenEnable" class="forgotten-container">
    <button (click)="forgotten()" class="link" type="button">{{'Mot de passe oublié ?' | ehp_translate}}</button>
  </div>


  <div class="actions">
    <button [disabled]="!form.valid" class="primary" type="submit">{{ 'Se connecter' | ehp_translate }}</button>
  </div>
</form>
