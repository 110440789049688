import { Attribute, CRUD_ACTIONS } from '@application/bundles/authorization';
import { ForbiddenError } from '@application/bundles/authorization/error';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import {
  GetPMPQuery,
  PMPRepository,
  PMPUpdatedEvent,
  PMPUpdateFailEvent,
  UpdatePMPCommand,
  UpdatePMPDto,
} from '@application/bundles/pmp';
import { PMPAuthorizationChecker } from '@application/bundles/pmp/pmp-authorization-checker';
import { PMP_FEATURE, PMP_TRANSLATE_CONTEXT } from '@application/bundles/pmp/pmp.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { Facility } from '@domain/facility';
import { isLocalMedia, isMedia, MediaBucket, TemporaryMedia } from '@domain/media';
import { PMP } from '@domain/pmp';

@HandleCommand({
  command: UpdatePMPCommand,
})
export class UpdatePMPCommandHandler implements CommandHandler<UpdatePMPCommand, PMP> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: PMPRepository,
    private readonly authorization: PMPAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: UpdatePMPCommand): Promise<PMP> {
    const { pmp } = command;
    try {
      const updated = await this.sanitize(pmp)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.updatePMP(dto));

      this.eventDispatcher.dispatch(new PMPUpdatedEvent(updated));

      return updated;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: UpdatePMPDto): Promise<UpdatePMPDto> {
    const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(dto.facilityId));
    const pmp = await this.queryBus.request<PMP>(new GetPMPQuery(dto.id));

    const attributes: Attribute[] = [{ feature: PMP_FEATURE, value: CRUD_ACTIONS.UPDATE }];

    if (!(await this.authorization.canUpdate(pmp))) {
      this.logger.error('P.M.P. update : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: UpdatePMPDto): Promise<UpdatePMPDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('P.M.P. update : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: UpdatePMPDto): Promise<UpdatePMPDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('P.M.P. update : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à mettre à jour ce P.M.P..",
        undefined,
        PMP_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new PMPUpdateFailEvent(message));

    return error;
  }

  private async updatePMP(dto: UpdatePMPDto): Promise<PMP> {
    const current = await this.queryBus.request(new GetPMPQuery(dto.id));
    const currentPvId = current.pvId;

    let pv: TemporaryMedia | undefined;

    if (dto.pvId !== current.pvId || isLocalMedia(dto.pv)) {
      if (dto.pv && isLocalMedia(dto.pv)) {
        pv = await this.mediaBucket.add(dto.pv);
        dto.pvId = pv.id;
      } else if (dto.pv && isMedia(dto.pv) && dto.pv.id !== current.pvId) {
        dto.pvId = dto.pv.id;
      }
    }

    try {
      return await this.repository.update(dto.id, dto);
    } catch (e) {
      if (pv !== undefined) {
        await pv.markForDeletion();
      }

      throw e;
    }
  }
}
