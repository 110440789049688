<ng-container *ngIf="stepperControl">

    <ehp-stepper (stepChange)="stepChanges($event)" cdkStepper
                 class="stepper form-parts">

        <ng-container *ngFor="let step of steps">
            <cdk-step [completed]="step.control.valid" [stepControl]="step.control">
                <ng-template cdkStepLabel>
                    <ehp-translatable-string [value]="step.label"></ehp-translatable-string>
                </ng-template>

                <ehp-dynamic-form-element *ngFor="let field of asArray(step.fields)"
                                          [field]="field"></ehp-dynamic-form-element>

                <div class="actions">
                    <button
                      *ngIf="!isLastStep"
                      [disabled]="!stepIsValid"
                      cdkStepperNext
                      class="primary"
                      type="button"
                    >
                        {{'Étape suivante'| ehp_translate}}
                    </button>

                    <button
                      (click)="submit()"
                      *ngIf="isLastStep"
                      [disabled]="!stepperControl.valid"
                      class="primary"
                      type="submit"
                    >
                        <ehp-translatable-string [value]="submitLabel"></ehp-translatable-string>
                    </button>

                    <button *ngIf="!isFirstStep" cdkStepperPrevious class="ghost" type="button">
                        {{"Précédente" | ehp_translate}}
                    </button>

                </div>

            </cdk-step>
        </ng-container>

    </ehp-stepper>

</ng-container>
