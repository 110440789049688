import {CreateFacilityDto, UpdateFacilityDto} from "@application/bundles/facility/dto";
import {FacilityDtoFactory} from "@application/bundles/facility/factories/facility-dto.factory";
import {CreateFacilityDtoImpl} from "@implementations/bundles/facility/dto/create-facility.dto";
import {UpdateFacilityDtoImpl} from "@implementations/bundles/facility/dto/update-facility.dto";

export class FacilityDtoFactoryImpl implements FacilityDtoFactory {

  public newCreateFacilityDtoFromUnsafeValues(values: Partial<Record<keyof CreateFacilityDto, any>>): CreateFacilityDto {
    return new CreateFacilityDtoImpl(values as CreateFacilityDto);
  }

  public newUpdateFacilityDtoFromUnsafeValues(values: Partial<Record<keyof UpdateFacilityDto, any>>): UpdateFacilityDto {
    return new UpdateFacilityDtoImpl(values as UpdateFacilityDto);
  }
}
