import { createAction, props } from '@ngrx/store';
import { TaxonomyTerm } from '@domain/taxonomy';
import { HealthTaxonomyType } from '@domain/health-funding';

export const healthFundingActions = {
  loadAllTerms: createAction(`[HealthFunding] Load all taxonomy terms`, props<{ taxonomy: HealthTaxonomyType }>()),
  allTermsLoaded: createAction(
    `[HealthFunding] All taxonomy terms loaded`,
    props<{ taxonomy: HealthTaxonomyType; terms: Array<TaxonomyTerm> }>(),
  ),
  loadTerm: createAction(
    `[HealthFunding] Load taxonomy term`,
    props<{ taxonomy: HealthTaxonomyType; id: TaxonomyTerm['id'] }>(),
  ),
  termLoaded: createAction(
    `[HealthFunding] Taxonomy term loaded`,
    props<{ taxonomy: HealthTaxonomyType; term: TaxonomyTerm }>(),
  ),
  createTerm: createAction(
    `[HealthFunding] Create new taxonomy term`,
    props<{ taxonomy: HealthTaxonomyType; payload: Omit<TaxonomyTerm, 'id'> }>(),
  ),
  termCreated: createAction(
    `[HealthFunding] Taxonomy term created`,
    props<{ taxonomy: HealthTaxonomyType; term: TaxonomyTerm }>(),
  ),
};
