import { Common, isValidObject } from '@application/framework/lib';
import { CreateHealthFundingDto, UpdateHealthFundingDto } from '@application/bundles/health-funding';
import { PMPValue } from '@domain/pmp/types';
import { GMPValue, isValidGMPValue } from '@domain/gmp';
import { IsDate, IsOptional } from 'class-validator';
import { IsValidFacilityId } from '@implementations/bundles/facility';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { isValidFundingAmount } from '@domain/funding';
import { IsFundingAmount } from '@implementations/bundles/funding/validators';
import { IsPMPValue } from '@implementations/bundles/pmp/validators';
import { IsGMPValue } from '@implementations/bundles/gmp/validators';
import { isValidPMPValue } from '@domain/pmp';
import { Media } from '@domain/media';
import { IsMediaId } from '@implementations/bundles/media';
import { isValidMediaIdType } from '@application/bundles/media';
import { isValidYear, Year } from '@domain/lib';
import { IsValidYear } from '@implementations/framework/date';
import { Facility } from '@domain/facility';

type CommonProperties = Omit<
  Common<CreateHealthFundingDto, UpdateHealthFundingDto>,
  'recurringFunding' | 'nonRecurringFunding' | 'exceptionalFunding' | 'ehpadDemainFunding' | 'document' | 'report'
>;

type AmountsProperties = Pick<
  CommonProperties,
  | 'total'
  | 'nonRecurringTotal'
  | 'permanentRecurringAmount'
  | 'temporaryRecurringAmount'
  | 'dayCareRecurringAmount'
  | 'supportRecurringAmount'
>;

const amounts: Array<keyof AmountsProperties> = [
  'total',
  'nonRecurringTotal',
  'permanentRecurringAmount',
  'temporaryRecurringAmount',
  'dayCareRecurringAmount',
  'supportRecurringAmount',
];

export abstract class CommonHealthFundingDtoAbstract implements CommonProperties {
  @IsDate()
  public date!: Date;

  @IsValidYear()
  public accountingYear!: Year;

  @IsGMPValue()
  public gmp!: GMPValue;

  @IsPMPValue()
  public pmp!: PMPValue;

  @IsFundingAmount()
  public total!: number;

  @IsFundingAmount()
  public nonRecurringTotal!: number;

  @IsOptional()
  @IsFundingAmount()
  public permanentRecurringAmount: number | undefined;

  @IsOptional()
  @IsFundingAmount()
  public temporaryRecurringAmount: number | undefined;

  @IsOptional()
  @IsFundingAmount()
  public dayCareRecurringAmount: number | undefined;

  @IsOptional()
  @IsFundingAmount()
  public supportRecurringAmount: number | undefined;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  @IsMediaId()
  @IsOptional()
  public documentId?: Media['id'];

  @IsMediaId()
  @IsOptional()
  public reportId?: Media['id'];

  protected constructor(values?: Partial<CommonProperties>) {
    if (isValidObject(values)) {
      if (values?.date instanceof Date) {
        this.date = values.date;
      }

      if (isValidYear(values?.accountingYear)) {
        this.accountingYear = values?.accountingYear;
      }

      if (isValidFacilityIdType(values?.facilityId)) {
        this.facilityId = values?.facilityId;
      }

      if (isValidGMPValue(values?.gmp)) {
        this.gmp = values?.gmp;
      }

      if (isValidPMPValue(values?.pmp)) {
        this.pmp = values?.pmp;
      }

      for (const key of amounts) {
        const amount = values[key];
        if (isValidFundingAmount(amount)) {
          this[key] = amount;
        }
      }

      if (isValidMediaIdType(values?.documentId)) {
        this.documentId = values?.documentId;
      }

      if (isValidMediaIdType(values?.reportId)) {
        this.reportId = values?.reportId;
      }
    }
  }
}
