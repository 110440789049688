<ng-container *ngIf="annex">
  <ehp-media-item *ngIf="document else noDocument" [media]="document">
    <span *ngIf="annex.name" [innerHTML]="annex.name" class="annex-name pre-name"></span>
  </ehp-media-item>

  <ng-template #noDocument>
    <span *ngIf="annex.name else noName" [innerHTML]="annex.name" class="annex-name"></span>
  </ng-template>

  <ng-template #noName>
    {{ 'Annexe' | ehp_translate }}
  </ng-template>
</ng-container>
