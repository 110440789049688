<header class="header document-list-header">
  <div class="title">{{'Nom du document' | ehp_translate}}</div>
  <div class="year">{{'Année' | ehp_translate}}</div>
  <div class="date">{{'Date' | ehp_translate}}</div>
  <div class="tariff-option">{{'Option tarifaire' | ehp_translate}}</div>
  <div class="facility">{{'Établissements' | ehp_translate}}</div>
  <div class="actions">{{'Actions' | ehp_translate}}</div>
</header>


<ng-container *ngIf="documents && documents.length > 0; else emptyList">
  <ng-container *ngFor="let cpom of documents">
    <ehp-cpom-list-item *ngIf="cpom" [cpom]="cpom"></ehp-cpom-list-item>
  </ng-container>
</ng-container>


<ng-template #emptyList>
  <ehp-empty-block>{{'Aucun C.P.O.M. archivé pour le moment.' | ehp_translate}}</ehp-empty-block>
</ng-template>
