import {CreateEhpadDemainDto} from "@application/bundles/ehpad-demain";
import {EhpadDemainCommonDtoAbstract} from "@implementations/bundles/ehpad-demain/dto/ehpad-demain-common.dto.abstract";
import {isLocalMedia, LocalMedia} from "@domain/media";
import {IsLocalMedia} from "@implementations/bundles/media";
import {IsArray, IsOptional} from "class-validator";

export class CreateEhpadDemainDtoImpl extends EhpadDemainCommonDtoAbstract implements CreateEhpadDemainDto {

  @IsArray()
  public medias: LocalMedia[] = [];

  @IsOptional()
  @IsLocalMedia()
  public convention?: LocalMedia;


  @IsOptional()
  @IsLocalMedia()
  public otherConvention?: LocalMedia;


  constructor(values?: Partial<CreateEhpadDemainDto>) {
    super(values);

    if (values) {

      if (Array.isArray(values.medias)) {
        this.medias = values.medias;
      }

      if (isLocalMedia(values.convention)) {
        this.convention = values.convention;
      }

      if (isLocalMedia(values.otherConvention)) {
        this.otherConvention = values.otherConvention;
      }
    }
  }
}
