import { ExceptionalTaxonomyTerm } from '@domain/health-funding';
import { UUID } from '@domain/lib';

export interface EasyExceptionalHealthFunding {
  id: UUID;

  categoryId: ExceptionalTaxonomyTerm['id'] | null | undefined;

  other?: string | null | undefined;

  value: number;
}

export interface CreateEasyExceptionalHealthFundingDto extends Omit<EasyExceptionalHealthFunding, 'id'> {}

export interface UpdateEasyExceptionalHealthFundingDto extends EasyExceptionalHealthFunding {}
