import { Component, ElementRef, forwardRef, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  Validators,
} from '@angular/forms';
import { Address } from '@domain/address';
import { NativeChangeObserver } from '@easyhpad-ui/app/framework/native-change-observer';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { isPostalCode, isValidAddress } from '@easyhpad-ui/app/bundles/address/validators';

const selector = 'ehp-address-input';

@Component({
  selector,
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
  ],
})
export class AddressInputComponent implements OnInit, ControlValueAccessor {
  public form: FormGroup<Record<keyof Address, AbstractControl>>;

  private control!: AbstractControl<any>;

  public get nodeName(): string {
    return selector;
  }

  public get value(): Address {
    return this.form.value as Address;
  }

  public set value(value: Partial<Address>) {
    this.writeValue(value);
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly elementRef: ElementRef,
    private readonly nativeChanges: NativeChangeObserver,
    private readonly injector: Injector,
  ) {
    this.form = this.formBuilder.group<Record<keyof Address, AbstractControl>>(
      {
        id: new FormControl(),
        addressLine1: new FormControl(),
        postalCode: new FormControl('', [isPostalCode]),
        locality: new FormControl(),
        countryCode: new FormControl('FR'),
      },
      {
        validators: [isValidAddress],
      },
    );

    this.form.valueChanges.subscribe(values => {
      const update = this.form.valid ? values : null;
      this.onChange(update);
    });

    this.nativeChanges.observe(this.elementRef, 'required').subscribe(change => {
      this.setRequired(coerceBooleanProperty(change.currentValue));
    });
  }

  public ngOnInit() {
    this.setComponentControl();

    if (this.control) {
      this.control.addValidators(isValidAddress);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  public writeValue(value: Partial<Address> | undefined): void {
    if (value?.id) {
      this.form.get('id')?.setValue(value?.id);
    }

    if (value?.addressLine1) {
      this.form.get('addressLine1')?.setValue(value?.addressLine1);
    }

    if (value?.postalCode) {
      this.form.get('postalCode')?.setValue(value?.postalCode);
    }

    if (value?.locality) {
      this.form.get('locality')?.setValue(value?.locality);
    }

    if (value?.countryCode) {
      this.form.get('countryCode')?.setValue(value?.countryCode);
    }
  }

  private onChange = (value: Partial<Address> | null | undefined): void => undefined;

  private setRequired(isRequired: boolean) {
    const properties: Array<keyof Address> = ['addressLine1', 'postalCode', 'locality'];

    properties.forEach(property => {
      const control = this.form.get(property);

      if (control) {
        if (isRequired && !control.hasValidator(Validators.required)) {
          control.addValidators(Validators.required);
        } else if (!isRequired && control.hasValidator(Validators.required)) {
          control.removeValidators(Validators.required);
        }
      }
    });
  }

  private setComponentControl(): void {
    const injectedControl = this.injector.get(NgControl);

    switch (injectedControl.constructor) {
      case NgModel: {
        const { control, update } = injectedControl as NgModel;

        this.control = control;

        /* this.control.valueChanges.pipe(
           tap((value: any) => update.emit(value)),
           takeUntil(this.destroy),
         ).subscribe();*/

        break;
      }
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as FormControl;
        break;
      }
    }
  }
}
