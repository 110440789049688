import {Transformer} from "@application/framework/core";
import {
  DEPENDENCIES_FUNDING_MODES,
  TEMPORARY_DEPENDENCIES_FUNDING_MODES,
  TemporaryDependencyFunding,
  TemporaryDependencyFundingMode,
} from "@domain/dependency-funding";
import {
  CreateTemporaryDependencyFundingDto,
  TemporaryDependencyFundingModeCollection,
  UpdateTemporaryDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {Common} from "@application/framework/lib";
import {GroupedGIRAmount, isGroupedGIR} from "@domain/gir";
import {Provide, Service} from "@application/framework/di";
import {isValidFundingAmount} from "@domain/funding";
import {
  CreateEasyTemporaryDependencyFundingDto,
  EasyTemporaryDependencyFunding,
  UpdateEasyTemporaryDependencyFundingDto
} from "@implementations/bundles/backend/easyhpad/interfaces/dependency-funding/easy-temporary-dependency-funding";


type CommonProperties = Omit<Common<TemporaryDependencyFunding<any, unknown>, Common<CreateTemporaryDependencyFundingDto<any, unknown>, UpdateTemporaryDependencyFundingDto<any, unknown>>>, 'mode' | 'value'>

type EasyCommonProperties = Omit<Common<EasyTemporaryDependencyFunding, Common<CreateEasyTemporaryDependencyFundingDto, UpdateEasyTemporaryDependencyFundingDto>>, 'funding_mode'>;

@Service()
export class EasyTemporaryDependencyFundingTransformer implements Transformer<EasyTemporaryDependencyFunding, TemporaryDependencyFunding<any, unknown>> {

  @Provide() private readonly modeCollection!: TemporaryDependencyFundingModeCollection;

  public transform(value: EasyTemporaryDependencyFunding): TemporaryDependencyFunding<any, unknown> {
    return {
      id: value.id,
      ...this.transformCommon(value),
      mode: this.modeCollection.get(value.funding_mode) as TemporaryDependencyFundingMode<TEMPORARY_DEPENDENCIES_FUNDING_MODES>,
      value: () => this.getValue(value.funding_mode, value)
    }
  }

  public flatTransform(value: EasyTemporaryDependencyFunding): Omit<TemporaryDependencyFunding<any, unknown>, 'value'> & { value: unknown } {
    return {
      id: value.id,
      ...this.transformCommon(value),
      mode: this.modeCollection.get(value.funding_mode) as TemporaryDependencyFundingMode<TEMPORARY_DEPENDENCIES_FUNDING_MODES>,
      value: this.getValue(value.funding_mode, value)
    }
  }

  public transformCreateDto(value: CreateEasyTemporaryDependencyFundingDto): CreateTemporaryDependencyFundingDto<any, unknown> {
    return {
      ...this.transformCommon(value),
      mode: value.funding_mode,
      value: this.getValue(value.funding_mode, value)
    }
  }

  public transformUpdateDto(value: UpdateEasyTemporaryDependencyFundingDto): UpdateTemporaryDependencyFundingDto<any, unknown> {
    return {
      ...this.transformCommon(value),
      id: value.id,
      mode: value.funding_mode,
      value: this.getValue(value.funding_mode, value)
    }
  }

  public reverseTransform(value: TemporaryDependencyFunding<any, unknown>): EasyTemporaryDependencyFunding {
    const funding: EasyTemporaryDependencyFunding = {
      ...this.reverseTransformCommon(value),
      id: value.id,
      funding_mode: value.mode.key
    }

    this.setEasyValue(value.mode.key, value.value() as any, funding);

    return funding;
  }

  public reverseTransformCreateDto(value: CreateTemporaryDependencyFundingDto<any, unknown>): CreateEasyTemporaryDependencyFundingDto {
    const dto = {
      ...this.reverseTransformCommon(value),
      funding_mode: value.mode
    }

    this.setEasyValue(value.mode, value.value as any, dto);

    return dto;
  }

  public reverseTransformUpdateDto(value: UpdateTemporaryDependencyFundingDto<any, unknown>): UpdateEasyTemporaryDependencyFundingDto {
    const dto = {
      ...this.reverseTransformCommon(value),
      id: value.id,
      funding_mode: value.mode
    }

    this.setEasyValue(value.mode, value.value as any, dto);

    return dto;
  }

  private transformCommon(value: EasyCommonProperties): CommonProperties {
    return {
      activityAllowance: value.activity_abattement || undefined,
    }
  }

  private reverseTransformCommon(value: CommonProperties): EasyCommonProperties {
    return {
      activity_abattement: value.activityAllowance,
      price_gir1_2: undefined,
      price_gir3_4: undefined,
      price_gir5_6: undefined,
      amount_package: 0,
    }
  }

  private getValue(mode: TEMPORARY_DEPENDENCIES_FUNDING_MODES, properties: EasyCommonProperties): GroupedGIRAmount | number | undefined {

    let value: GroupedGIRAmount | number | undefined;

    if (mode === DEPENDENCIES_FUNDING_MODES.GIR_GROUP) {
      value = {
        firstGIRGroup: properties.price_gir1_2 || 0,
        secondGIRGroup: properties.price_gir3_4 || 0,
        thirdGIRGroup: properties.price_gir5_6 || 0
      }

    } else if (mode === DEPENDENCIES_FUNDING_MODES.AMOUNT) {
      value = properties.amount_package || 0;
    }

    return value;
  }

  private setEasyValue(mode: TEMPORARY_DEPENDENCIES_FUNDING_MODES, value: GroupedGIRAmount | number | undefined, funding: EasyCommonProperties) {

    if (mode === DEPENDENCIES_FUNDING_MODES.GIR_GROUP && isGroupedGIR(value)) {
      funding.price_gir1_2 = value.firstGIRGroup;
      funding.price_gir3_4 = value.secondGIRGroup;
      funding.price_gir5_6 = value.thirdGIRGroup;

    } else if (mode === DEPENDENCIES_FUNDING_MODES.AMOUNT && isValidFundingAmount(value)) {
      funding.amount_package = value;
    }
  }
}
