import {CustomerCacheKey} from "@application/bundles/customer/customer-cache-key";
import {CUSTOMER_CACHE_ID, CUSTOMER_TRANSLATE_CONTEXT} from "@application/bundles/customer/customer.token";
import {
  CustomerUpdateFailEvent
} from "@application/bundles/customer/events/customer-updated/customer-update-fail.event";
import {
  CustomerUpdatedSuccessEvent
} from "@application/bundles/customer/events/customer-updated/customer-updated-success.event";
import {CacheManager} from "@application/framework/cache";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {NoticeStream, NoticeStreamLibrary, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";
import {Customer} from "@domain/customer";

@HasEventSubscribers()
export class OnCustomerUpdateEventSubscriber {

  @ProvideLogger() private readonly logger!: Logger

  private readonly noticeStream: NoticeStream;

  constructor(
    private readonly noticeStreamLibrary: NoticeStreamLibrary,
    private readonly cacheManager: CacheManager,
  ) {
    this.noticeStream = this.noticeStreamLibrary.getStream();
  }


  @OnEvent({
    event: CustomerUpdatedSuccessEvent,
  })
  public async pushNoticeOnCustomerUpdatedSuccess(event: CustomerUpdatedSuccessEvent): Promise<void> {

    const message = new TranslatableString(
      "Le client <strong>{{name}}</strong> a été mis à jour avec succès.",
      {name: event.customer.name},
      CUSTOMER_TRANSLATE_CONTEXT
    )

    this.noticeStream.push(
      this.noticeStreamLibrary.getTranslatableNotice(message, NoticeType.SUCCESS)
    );
  }

  @OnEvent({
    event: CustomerUpdatedSuccessEvent,
  })
  public async updateCustomerStoredInCache(event: CustomerUpdatedSuccessEvent) {
    try {
      const cache = await this.cacheManager.get<Customer>(CUSTOMER_CACHE_ID);
      await cache.set(new CustomerCacheKey(event.customer.id), event.customer);
    } catch (e) {
      this.logger.warning(`Error on Customer (${event.customer.id}) update in cache`, e);
    }
  }


  @OnEvent({
    event: CustomerUpdateFailEvent,
  })
  public async pushNoticeOnCustomerUpdateFail(event: CustomerUpdateFailEvent): Promise<void> {

    let message: TranslatableString;

    if (event.reason) {
      message = new TranslatableString(
        "Une erreur est survenue lors de la mise à jour du client.",
        undefined,
        CUSTOMER_TRANSLATE_CONTEXT
      );
    } else {
      message = new TranslatableString(event.reason);
    }


    this.noticeStream.push(
      this.noticeStreamLibrary.getTranslatableNotice(message, NoticeType.ERROR)
    );
  }


}
