import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { RequestResetPasswordCommand } from '@implementations/bundles/backend/easyhpad/commands';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { EventDispatcher } from '@application/framework/event';
import { ResetUserPasswordRequestSuccessEvent } from '@application/bundles/user/events/reset-password/reset-user-password-request-success.event';
import { ResetUserPasswordRequestFailEvent } from '@application/bundles/user/events/reset-password/reset-user-password-request-fail.event';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';

@HandleCommand({
  command: RequestResetPasswordCommand,
})
export class RequestPasswordResetCommandHandler implements CommandHandler<RequestResetPasswordCommand, boolean> {
  private readonly path = '/user/retrieve-password';

  private readonly errorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly eventDispatcher: EventDispatcher,
    private readonly backend: EasyBackendApi,
  ) {}

  public async handle(command: RequestResetPasswordCommand): Promise<boolean> {
    try {
      await this.backend.get<{ message?: string }>(this.path, command.credentials);
      this.eventDispatcher.dispatch(new ResetUserPasswordRequestSuccessEvent(command.credentials.email));
      return true;
    } catch (e: any) {
      const error = this.errorNormalizer.normalize(e);
      this.eventDispatcher.dispatch(new ResetUserPasswordRequestFailEvent(error.message));
    }

    return false;
  }
}
