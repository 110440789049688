import {
  CreateTemporaryDependencyFundingDto,
  TemporaryDependencyFundingDtoFactory,
  UpdateTemporaryDependencyFundingDto
} from "@application/bundles/dependency-funding";
import {
  CreateTemporaryDependencyFundingDtoImpl,
  UpdateTemporaryDependencyFundingDtoImpl
} from "@implementations/bundles/dependency-funding/dto";
import {DEPENDENCIES_FUNDING_MODES, TEMPORARY_DEPENDENCIES_FUNDING_MODES} from "@domain/dependency-funding";

export class TemporaryDependencyFundingDtoFactoryImpl implements TemporaryDependencyFundingDtoFactory {

  public buildCreateDtoFromUnsafeValues<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = unknown>(values: Partial<CreateTemporaryDependencyFundingDto<M, V>>): CreateTemporaryDependencyFundingDto<M, V> {
    return new CreateTemporaryDependencyFundingDtoImpl<M, V>(values);
  }

  public buildUpdateDtoFromUnsafeValues<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = unknown>(values: Partial<UpdateTemporaryDependencyFundingDto<M, V>>): UpdateTemporaryDependencyFundingDto<M, V> {
    return new UpdateTemporaryDependencyFundingDtoImpl<M, V>(values);
  }


}
