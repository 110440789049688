export function getSmaller(a: number, b: number): number {
  return a < b ? a : b;
}

export function getGreater(a: number, b: number): number {
  return a > b ? a : b;
}

export function getPreviousClosestNumber(needle: number, values: number[], acceptEquality = false): number | undefined {
  values.sort((a, b) => a - b).reverse();
  return values.find(value => (acceptEquality ? value <= needle : value < needle));
}

export function getNextClosestNumber(needle: number, values: number[], acceptEquality = false): number | undefined {
  values.sort((a, b) => a - b);
  return values.find(value => (acceptEquality ? value >= needle : value > needle));
}

export function differenceInPercentage(a: number, b: number, decimalPlaces: number = 2): number {
  return Number((((a - b) / b) * 100).toFixed(decimalPlaces));
}

export function isPositive(number: number, includeZero = false): boolean {
  return Number.isFinite(number) && (number > 0 || (includeZero && number === 0));
}

/**
 * Perform a safe division by returning 0 when the divisor is 0.
 * @param {number} dividend - The number to be divided.
 * @param {number} divisor - The number to divide by.
 * @returns {number} - The result of the division or the default value if the divisor is zero.
 */
export function safeDivide(dividend: number, divisor: number) {
  if (divisor === 0) {
    return 0;
  }

  return dividend / divisor;
}
