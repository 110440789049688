import { NgModule } from '@angular/core';
import {
  AccommodationFundingBundle,
  AccommodationFundingDtoFactory,
  AccommodationFundingRepository,
  AccommodationFundingUpdateRateRepository,
} from '@application/bundles/accommodation-funding';
import { Bundle } from '@application/framework/bundle';
import { extractDependencies } from '@application/framework/di';
import { OpenAccommodationFundingDeletionDialogCommandHandler } from '@easyhpad-ui/app/bundles/accommodation-funding/commands/handlers';
import { AccommodationFundingEffect } from '@easyhpad-ui/app/bundles/accommodation-funding/store/accommodation-funding.effect';
import { ACCOMMODATION_STORE_KEY } from '@easyhpad-ui/app/bundles/accommodation-funding/store/accommodation-funding.key';
import { accommodationFundingReducer } from '@easyhpad-ui/app/bundles/accommodation-funding/store/accommodation-funding.reducer';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator';
import {
  AccommodationFundingDtoFactoryImpl,
  CreateAccommodationFundingDtoImpl,
  UpdateAccommodationFundingDtoImpl,
} from '@implementations/bundles/accommodation-funding';
import {
  EasyAccommodationFundingRepository,
  EasyAccommodationFundingUpdateRateRepository,
} from '@implementations/bundles/backend/easyhpad';
import { validateClassValidatorObject } from '@implementations/framework/validator';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccommodationFundingUrlsProvider } from './providers';

@NgModule({
  imports: [
    ValidatorModule.forChild({
      validators: [
        { objectType: CreateAccommodationFundingDtoImpl, validator: validateClassValidatorObject },
        { objectType: UpdateAccommodationFundingDtoImpl, validator: validateClassValidatorObject },
      ],
    }),
    StoreModule.forFeature(ACCOMMODATION_STORE_KEY, accommodationFundingReducer),
    EffectsModule.forFeature([AccommodationFundingEffect]),
  ],
  providers: [
    AccommodationFundingUrlsProvider,
    {
      provide: AccommodationFundingDtoFactory,
      useClass: AccommodationFundingDtoFactoryImpl,
    },
    {
      provide: AccommodationFundingRepository,
      useClass: EasyAccommodationFundingRepository,
    },
    {
      provide: AccommodationFundingUpdateRateRepository,
      useClass: EasyAccommodationFundingUpdateRateRepository,
      deps: extractDependencies(EasyAccommodationFundingUpdateRateRepository),
    },
  ],
})
@Bundle({
  imports: [AccommodationFundingBundle],
  commandHandlers: [OpenAccommodationFundingDeletionDialogCommandHandler],
})
export class AccommodationFundingModule {}
