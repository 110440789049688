<form (submit)="submit()" *ngIf="authorization" [formGroup]="form" novalidate>

  <ehp-form-field>
    <label for="date">{{ "Date de l'inspection" | ehp_translate }}</label>
    <input ehp-form-control formControlName="date" id="date" type="date">
  </ehp-form-field>

  <ehp-media-uploader (onChange)="addMedia($event)" [medias]="currentMedias" [min]="1"
                      [multiple]="true" [requiredFileType]="[PDF]"></ehp-media-uploader>

  <div class="actions">
    <button [disabled]="!form.valid" class="primary" type="submit">
      <ehp-translatable-string [value]="buttonLabel"></ehp-translatable-string>
    </button>

    <button (click)="cancel()" class="ghost" type="button">
      {{ 'Annuler' | ehp_translate }}
    </button>
  </div>
</form>


