import {ChangeableAccessor} from "@application/framework/core";
import {Subscription} from "@application/framework/reactive";
import {Customer} from "@domain/customer";
import {Provide} from "@application/framework/di";
import {EventDispatcher} from "@application/framework/event";
import {CurrentCustomerChangeEvent} from "@application/bundles/customer";

export class CurrentCustomerAccessor implements ChangeableAccessor<Customer | undefined> {

  private customer: Customer | undefined;

  private readonly subscribers: Map<Function, Subscription> = new Map();

  @Provide() private eventDispatcher!: EventDispatcher;

  public get(): Customer | undefined {
    return this.customer;
  }

  public set(item: Customer | undefined): void {
    this.customer = item;

    for (const callable of this.subscribers.keys()) {
      callable(item);
    }

    this.eventDispatcher.dispatch(new CurrentCustomerChangeEvent(item));
  }

  public onChange(callable: (value: Customer | undefined) => void): Subscription {
    let subscription = this.subscribers.get(callable);

    if (subscription === undefined) {
      subscription = {
        unsubscribe: () => this.subscribers.delete(callable)
      };

      this.subscribers.set(callable, subscription);
    }

    return subscription;
  }


}
