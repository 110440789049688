<header class="dialog-header">
  <h2 class="title">{{'Suppression définitive' | ehp_translate}}</h2>
</header>

<div class="dialog-content">

  <p> {{ 'Vous êtes sur le point de supprimer définitivement le client'|ehp_translate }}
    <strong class="item">{{ data.customer.name }}</strong>.
  </p>


  <div class="cascade-list-container">
    {{ 'Cela entrainera la suppression des éléments suivants'|ehp_translate }} :

    <ul class="cascade-list">
      <li>{{"L'ensemble des utilisateurs associés à ce client" | ehp_translate }}</li>
      <li>{{"L'ensemble des établissement associés à ce client" | ehp_translate}}</li>
      <li>{{"L'ensemble des documents du client" | ehp_translate }}</li>
    </ul>
  </div>


  {{'Pour confirmer la suppression définitive, veuillez saisir texte suivant dans le champs si dessous : '|ehp_translate}}
  <strong class="match-item">{{ data.customer.name }}</strong>

  <ehp-form-field class="confirm-field">
    <input [formControl]="control" ehp-form-control type="text">
  </ehp-form-field>

</div>

<div class="dialog-actions">
  <button (click)="confirm()" [disabled]="!control.valid" class="delete"
          type="button">{{'Supprimer définitivement'| ehp_translate}}
  </button>

  <button (click)="cancel()" class="ghost" type="button">{{'Annuler'| ehp_translate}}</button>
</div>

