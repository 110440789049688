import {HealthFundingRepository} from "@application/bundles/health-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {HealthFunding} from "@domain/health-funding";
import {ListHealthFundingQuery} from "../../list-health-funding.query";
import {
  AgnosticHealthFundingSearchParams
} from "@application/bundles/health-funding/implementations/agnostic-health-funding-search-params/agnostic-health-funding-search-params";

@HandleQuery({
  query: ListHealthFundingQuery
})
export class ListHealthFundingQueryHandler implements QueryHandler<ListHealthFundingQuery, HealthFunding[]> {

  constructor(private readonly repository: HealthFundingRepository) {
  }

  public async handle(query: ListHealthFundingQuery): Promise<HealthFunding[]> {
    return this.repository.list(new AgnosticHealthFundingSearchParams().deserialize(query.params));
  }
}
