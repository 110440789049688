import {ActivityAnnexRepository, GetActivityAnnexQuery} from "@application/bundles/activity-annex";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {ActivityAnnex} from "@domain/activity-annex";

@HandleQuery({
  query: GetActivityAnnexQuery
})
export class GetActivityAnnexQueryHandler implements QueryHandler<GetActivityAnnexQuery, ActivityAnnex> {

  constructor(private readonly repository: ActivityAnnexRepository) {
  }

  public async handle(query: GetActivityAnnexQuery): Promise<ActivityAnnex> {
    return this.repository.get(query.id);
  }
}
