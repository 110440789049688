import {Component, HostListener, Input, OnChanges, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {GetGMPQuery} from "@application/bundles/gmp";
import {QueryBus} from "@application/framework/command-query";
import {GMP} from "@domain/gmp";
import {GmpUrlsProvider} from "@easyhpad-ui/app/bundles/gmp";

@Component({
  selector: 'ehp-gmp-link',
  templateUrl: './gmp-link.component.html',
  styleUrls: ['./gmp-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.link]': 'isLink',
    '[attr.role]': 'role',
  }
})
export class GmpLinkComponent implements OnChanges {

  @Input() public gmp!: GMP;

  @Input() public refId!: GMP['id'];

  private url: string | null | undefined;

  public get isLink(): boolean {
    return !!this.url;
  }

  public get role(): string | undefined {
    return this.isLink ? 'link' : undefined;
  }

  constructor(
    private readonly queryBus: QueryBus,
    private readonly urls: GmpUrlsProvider,
    private readonly router: Router
  ) {
  }

  @HostListener('click', ['$event'])
  public async click(event: MouseEvent) {
    if (!this.isLink) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.url !== undefined && this.url !== null) {
      return this.router.navigateByUrl(this.url);
    }

    return false;

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['refId']) {

      if (changes['refId'].previousValue !== changes['refId'].currentValue) {
        this.loadGMP();
      }
    }

    if (changes['gmp']) {
      if (changes['gmp'].previousValue !== changes['gmp'].currentValue) {
        this.gmpHasChange();
      }
    }
  }

  private loadGMP(): void {

    if (this.refId) {
      this.queryBus.request<GMP>(new GetGMPQuery(this.refId)).then(gmp => {
        this.gmp = gmp;
        this.gmpHasChange();
      });

    }
  }


  private setUrl(): void {
    this.url = null;

    if (this.gmp) {
      this.url = this.urls.getUnsafePublicShow(this.gmp.id);
    }
  }

  private gmpHasChange(): void {
    this.setUrl();
  }

}
