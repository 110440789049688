import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {Notice} from "@application/framework/notice";

@Component({
  selector: 'ehp-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {

  @Input() public notice: Notice | undefined;

  @Input() public closable = true;

  @Input('auto-close') public autoCloseDelay: number | undefined;

  @Output() public onClose = new EventEmitter<void>();

  @HostBinding('class') public classes = 'notice';

  constructor() {
  }


  public ngOnInit(): void {

    if (this.notice !== undefined) {
      this.setClasses(this.notice.type, false);
      if (this.notice.type) {
        this.classes = `notice type-${this.notice.type}`;
      }
    }

    if (this.autoCloseDelay) {
      const autoCloseTimer = setTimeout(() => {
        this.close();
        clearTimeout(autoCloseTimer);
      }, this.autoCloseDelay);
    }
  }

  public close() {
    this.classes += ' close';
    this.onClose.emit();
  }
  
  private setClasses(type?: string, close = false) {
    const classes = ['notice'];

    if (type) {
      classes.push(`type-${type}`);
    }

    if (close) {
      classes.push('closed');
    }

    this.classes = classes.join(' ');
  }


}
