import { ETPCategory } from '@domain/etp';

export interface TPER {
  category: ETPCategory;

  etp: number;

  amount: number;

  comment: string | undefined;
}
