import {InvalidGirListValuesError} from "@application/bundles/gir/errors";
import {GIR, isValidPointGIR, PointsGIRList} from "@domain/gir";

export class PointsGirListImpl implements PointsGIRList {

  public readonly year?: number;

  private values: Map<GIR, number> | undefined;

  constructor(year?: number, values?: Map<GIR, number>,) {
    this.year = year;
    if (values !== undefined) {
      this.setValues(values);
    }
  }

  public get(gir: GIR): number {

    if (this.values !== undefined) {
      return this.values.get(gir) || 0;
    }
    return 0;
  }

  public setValues(values: Map<GIR, number>): this {

    if (!(values instanceof Map)) {
      throw new InvalidGirListValuesError(
        `Values provided in ${this.constructor.name} must be a Map instance (GIR => number of point)`
      );
    }

    for (const [gir, value] of values) {
      this.validatePointValue(value, gir);
    }

    this.values = values;

    return this;
  }

  public isEmpty(): boolean {
    return this.values === undefined || this.values.size === 0;
  }

  private validatePointValue(value: number, gir?: GIR): void {

    if (!isValidPointGIR(value)) {
      throw new InvalidGirListValuesError(
        `${String(value)} is not a valid representation of resident's total ${gir ? String(gir) : ''}.`
      );
    }
  }

}
