import { CreateNonRecurringHealthFundingDtoImpl } from './create-non-recurring-health-funding.dto';
import {
  isValidNonRecurringHealthFundingIdType,
  UpdateNonRecurringHealthFundingDto,
} from '@application/bundles/health-funding';

export class UpdateNonRecurringHealthFundingDtoImpl
  extends CreateNonRecurringHealthFundingDtoImpl
  implements UpdateNonRecurringHealthFundingDto
{
  public id!: UpdateNonRecurringHealthFundingDto['id'];

  constructor(values?: Partial<UpdateNonRecurringHealthFundingDto>) {
    super(values);

    if (values && isValidNonRecurringHealthFundingIdType(values.id)) {
      this.id = values.id;
    }
  }
}
