import { Component, Input, OnInit } from '@angular/core';
import { LocalMedia, Media } from '@domain/media';
import {
  MEDIA_PREVIEW_FEATURE,
  PreviewComponentInterface,
} from '@easyhpad-ui/app/bundles/media/interfaces/preview-component.interface';

@Component({
  selector: 'ehp-preview-pdf',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.scss'],
})
export class PreviewPdfComponent implements OnInit, PreviewComponentInterface {
  @Input() public media?: Media | LocalMedia;

  public readonly supports: Set<MEDIA_PREVIEW_FEATURE> = new Set(['zoom', 'rotate']);

  public src: { url: string; withCredentials: boolean } | undefined;

  public zoom = 1;

  public rotation = 0;

  public zoomIn(): number {
    this.zoom = this.zoom + 0.2;
    return this.zoom;
  }

  public zoomOut(): number {
    this.zoom = this.zoom - 0.2;
    return this.zoom;
  }

  public rotateLeft(): number {
    this.rotation = this.rotation - 90;
    return this.rotation;
  }

  public rotateRight(): number {
    this.rotation = this.rotation - 90;
    return this.rotation;
  }

  public ngOnInit(): void {
    if (this.media) {
      this.media.buildUrl().then(url => {
        this.src = { url: url.toString(), withCredentials: false };
      });
    }
  }
}
