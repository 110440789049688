import {Component} from '@angular/core';

@Component({
  selector: 'ehp-actions-list',
  templateUrl: './actions-list.component.html',
  styleUrls: ['./actions-list.component.scss']
})
export class ActionsListComponent {
  public isOpen: boolean = false;

}
