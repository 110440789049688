import {Constructable} from "@application/framework/lib";

export type ValidateFunction<T> = (object: T, context?: Record<string, any>) => Promise<T>;

export abstract class ObjectValidatorRegistry {

  public abstract register<T>(objectType: Constructable<T>, validator: ValidateFunction<T>): void;

  public abstract get<T>(object: T): Set<ValidateFunction<T>>;
}
