export interface ValidateElement<T> {

  isValidate: boolean;

  isValid: boolean;

  element: T;

}

export interface SanitizedElement<T> {
  
  isSanitized: boolean;

  element: T;
}

export interface SanitizedAndValidateElement<T> extends ValidateElement<T>, SanitizedElement<T> {
}
