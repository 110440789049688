import { Transformer } from '@application/framework/core';
import { EPRD } from '@domain/eprd-errd';
import { CreateEasyEPRD, EasyEPRD, UpdateEasyEPRD } from '@implementations/bundles/backend/easyhpad';
import { LocalEprdTransformer } from '@implementations/bundles/backend/easyhpad/transformers/errd-errd/local-eprd.transformer';
import { Provide, Service } from '@application/framework/di';
import { EPRDImpl } from '@implementations/bundles/eprd-errd/implementations';
import { DateFormatter } from '@application/framework/date';
import { DateParser } from '@application/framework/date/date.parser';
import { CreateEPRDDto, UpdateEPRDDto } from '@application/bundles/eprd-errd';
import { Common } from '@application/framework/lib';

type CommonDtoProperties = Common<CreateEPRDDto, UpdateEPRDDto>;

type CommonEasyDtoProperties = Common<CreateEasyEPRD, UpdateEasyEPRD>;

type CommonProperties = Omit<Common<EPRD, CommonDtoProperties>, 'details'>;

type CommonEasyProperties = Omit<Common<EasyEPRD, CommonEasyDtoProperties>, 'details'>;

@Service()
export class EasyEPRDTransformer implements Transformer<EPRD, EasyEPRD> {
  @Provide()
  private readonly localTransformer!: LocalEprdTransformer;

  @Provide()
  private readonly dateParser!: DateParser;

  @Provide()
  private readonly dateFormatter!: DateFormatter;

  public transform(value: EPRD): EasyEPRD {
    return {
      ...this.getCommonProperties(value),
      id: value.id,
      details: value.details.map(local => this.localTransformer.transform(local)),
    };
  }

  public reverseTransform(value: EasyEPRD): EPRD {
    return new EPRDImpl({
      id: value.id,
      accountingYear: value.accountingYear,
      depositDate: value.depositDate ? this.dateParser.fromISOString(value.depositDate) : undefined,
      details: value.details.map(local => this.localTransformer.reverseTransform(local)),
      completeDocumentId: value.completeDocumentId,
      payrollDocumentId: value.payrollId || undefined,
      financialAnnexDocumentId: value.financialAnnexId || undefined,
      activityAnnexDocumentId: value.activityAnnexId || undefined,
      reportId: value.reportId || undefined,
    });
  }

  public createTransform(dto: CreateEPRDDto): CreateEasyEPRD {
    return {
      ...this.getCommonProperties(dto),
      details: dto.details.map(d => this.localTransformer.getLocalCreateDto(d)),
    };
  }

  public updateTransform(dto: UpdateEPRDDto): UpdateEasyEPRD {
    return {
      ...this.getCommonProperties(dto),
      id: dto.id,
      details: dto.details.map(d => this.localTransformer.getLocalUpdateDto(d)),
    };
  }

  private getCommonProperties(values: CommonProperties): CommonEasyProperties {
    return {
      accountingYear: values.accountingYear,
      depositDate:
        values.depositDate instanceof Date ? this.dateFormatter.toUTCIsoString(values.depositDate) : undefined,
      completeDocumentId: values.completeDocumentId || '',
      payrollId: values.payrollDocumentId || undefined,
      financialAnnexId: values.financialAnnexDocumentId || undefined,
      activityAnnexId: values.activityAnnexDocumentId || undefined,
      reportId: values.reportId || null,
    };
  }
}
