import {Container} from "@application/framework/di";
import {Provider} from "./provider";

export interface FactoryProvider<T> {
  useFactory: (container: Container) => T;
}

export function isFactoryProvider<T>(provider: Provider<T>): provider is FactoryProvider<any> {
  return !!(provider as FactoryProvider<T>).useFactory;
}
