import {
  CreateEhpadDemainDto,
  EhpadDemainListParams,
  EhpadDemainRepository,
  SerializedEhpadDemainDocumentParams,
  UpdateEhpadDemainDto,
} from '@application/bundles/ehpad-demain';
import { EasyEhpadDemainTransformer } from '@implementations/bundles/backend/easyhpad/transformers/ehpad-demain/easy-ehpad-demain.transformer';
import { Provide } from '@application/framework/di';
import { DocumentRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { EhpadDemain } from '@domain/ehpad-demain';
import { EasyEhpadDemain } from '@implementations/bundles/backend/easyhpad/interfaces/ehpad-demain/easy-ehpad-demain.interface';

export class EasyEhpadDemainRepository
  extends DocumentRepository<EhpadDemainListParams, SerializedEhpadDemainDocumentParams, EhpadDemain, EasyEhpadDemain>
  implements EhpadDemainRepository
{
  @Provide() private readonly entityTransformer!: EasyEhpadDemainTransformer;

  constructor() {
    super('ehpad-demain', {
      from: input => this.entityTransformer.reverseTransform(input),
      to: output => this.entityTransformer.transform(output),
      create: (input: CreateEhpadDemainDto) => this.entityTransformer.reverseCreateTransform(input),
      update: (input: UpdateEhpadDemainDto) => this.entityTransformer.reverseUpdateTransform(input),
    });
  }
}
