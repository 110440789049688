import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { safeDivide } from '@application/framework/lib';
import { ComputableResidentGIRList } from '@domain/gir';

@Component({
  selector: 'ehp-chart-resident-gir-list-line',
  templateUrl: './chart-resident-gir-list-line.component.html',
  styleUrls: ['./chart-resident-gir-list-line.component.scss'],
})
export class ChartResidentGirListLineComponent implements OnChanges {
  @Input() public residents!: ComputableResidentGIRList;

  @Input() public displayLabel: boolean = true;

  @Input() public displayTooltip: boolean = false;

  public data: { total: number; values: Array<{ value: number; percentage: number; label: string }> } = {
    total: 0,
    values: [],
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['residents']) {
      this.updateChartData();
    }
  }

  private updateChartData(): void {
    this.data = { total: 0, values: [] };

    if (this.residents) {
      const list = this.residents.all();
      this.data.total = list.reduce((accumulator, b) => accumulator + (b.value || 0), 0);

      this.data.values = list.map(item => {
        return {
          label: item.gir,
          value: item.value,
          percentage: safeDivide(item.value, this.data.total) * 100,
        };
      });
    }
  }
}
