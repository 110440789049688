import {Command} from "@application/framework/command-query/command.interface";
import {Query} from "@application/framework/command-query/query.interface";

export interface CommandHandler<C extends Command, R = any> {

  handle(command: C): Promise<R>;
}

export interface QueryHandler<Q extends Query<R>, R = any> {

  handle(query: Q): Promise<R>;
}
