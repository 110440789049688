import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import {
  AccommodationFundingLinkComponent,
  AccommodationFundingListComponent,
  AccommodationFundingListFiltersComponent,
  AccommodationFundingListItemComponent,
  AccommodationFundingNameComponent,
  OtherAccommodationFundingListComponent,
} from './components';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { EhpadDemainUiModule } from '@easyhpad-ui/app/bundles/ehpad-demain/modules/ui/ehpad-demain-ui.module';
import { FacilityUiModule } from '@easyhpad-ui/app/bundles/facility/modules/ui/facility-ui.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { FacilityFormModule } from '@easyhpad-ui/app/bundles/facility/modules/form/facility-form.module';
import { FilterModule } from '@easyhpad-ui/app/library/filter/filter.module';
import { EprdUiModule } from '@easyhpad-ui/app/bundles/eprd-errd/modules/eprd-ui/eprd-ui.module';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    ReusableComponentsModule,
    TooltipModule,
    TabViewModule,
    EhpadDemainUiModule,
    FacilityUiModule,
    FormModule,
    FacilityFormModule,
    FilterModule,
    EprdUiModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
  ],
  declarations: [
    AccommodationFundingNameComponent,
    AccommodationFundingLinkComponent,
    AccommodationFundingListComponent,
    AccommodationFundingListItemComponent,
    AccommodationFundingListFiltersComponent,
    OtherAccommodationFundingListComponent,
  ],
  exports: [
    AccommodationFundingNameComponent,
    AccommodationFundingLinkComponent,
    AccommodationFundingListComponent,
    AccommodationFundingListFiltersComponent,
    OtherAccommodationFundingListComponent,
  ],
})
export class AccommodationFundingUiModule {}
