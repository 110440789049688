import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { CreateTaxonomyCommand, CreateTaxonomyCommandResult } from '../../create-taxonomy';
import { isTaxonomyTerm, isValidTaxonomyTermIdType, TaxonomyTerm } from '@domain/taxonomy';
import { TaxonomyRepository } from '@application/bundles/taxonomy';

@HandleCommand({
  command: CreateTaxonomyCommand,
})
export class CreateTaxonomyCommandHandler implements CommandHandler<CreateTaxonomyCommand> {
  public async handle(command: CreateTaxonomyCommand): Promise<CreateTaxonomyCommandResult> {
    const { repository, term } = command;

    await this.preventMissingParent(term, repository);

    return repository.create(term);
  }

  private async preventMissingParent(term: Omit<TaxonomyTerm, 'id'>, repository: TaxonomyRepository) {
    if (isValidTaxonomyTermIdType(term.parentId)) {
      const parent = await repository.get(term.parentId);

      if (!isTaxonomyTerm(parent)) {
        throw new Error(`La catégorie parent du terme ${term.name} n'existe pas`);
      }
    }
  }
}
