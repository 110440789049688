import {FinancialAnnex, FinancialAnnexData} from "@domain/eprd-errd";
import {isValidObject} from "@application/framework/lib";


export function isFinancialAnnexData(data: any): data is FinancialAnnexData {
  return isValidObject(data)
    && typeof (data as FinancialAnnexData).permanentRevenue === 'number' && !isNaN(data.permanentRevenue)
    && typeof (data as FinancialAnnexData).healthRevenue === 'number' && !isNaN(data.healthRevenue)
    && typeof (data as FinancialAnnexData).dependencyRevenue === 'number' && !isNaN(data.dependencyRevenue)
    && typeof (data as FinancialAnnexData).permanentExpense === 'number' && !isNaN(data.permanentExpense)
    && typeof (data as FinancialAnnexData).healthExpense === 'number' && !isNaN(data.healthExpense)
    && typeof (data as FinancialAnnexData).dependencyExpense === 'number' && !isNaN(data.dependencyExpense)
    && (data.accommodationEmployeesExpense === undefined || (typeof data.accommodationEmployeesExpense === 'number' && !isNaN(data.accommodationEmployeesExpense)))
    && (data.healthEmployeesExpense === undefined || (typeof data.healthEmployeesExpense === 'number' && !isNaN(data.healthEmployeesExpense)))
    && (data.dependencyEmployeesExpense === undefined || (typeof data.dependencyEmployeesExpense === 'number' && !isNaN(data.dependencyEmployeesExpense)));
}

export function isFinancialAnnex(annex: any): annex is FinancialAnnex {
  return isValidObject(annex)
    && isFinancialAnnexData(annex)
    && typeof (annex as FinancialAnnex).permanentSubTotal === 'function'
    && typeof (annex as FinancialAnnex).dependencySubTotal === 'function'
    && typeof (annex as FinancialAnnex).healthSubTotal === 'function'
    && typeof (annex as FinancialAnnex).revenue === 'function'
    && typeof (annex as FinancialAnnex).expense === 'function';
}
