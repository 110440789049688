import {Injectable} from "@angular/core";
import {TranslatableString, TranslationReplacements, Translator} from "@application/framework/translation";
import {TranslateService} from "@ngx-translate/core";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TranslatorImpl implements Translator {

  constructor(private ngxTranslator: TranslateService) {
  }

  /**
   * @inheritDoc
   * @param message
   * @param replacement
   * @param context
   */
  public async translate(message: string | TranslatableString, replacement?: TranslationReplacements, context?: string): Promise<string> {

    if (typeof message === 'string') {
      return this.translateSingle(message, replacement, context);
    }

    replacement = replacement || {};

    if (message.replacement) {
      replacement = {...message.replacement, ...replacement}
    }
    
    return this.translateSingle(message.value, replacement, context ?? message.context);

  }

  /**
   * @inheritDoc
   * @param singular
   * @param plural
   * @param count
   * @param replacement
   * @param context
   */
  public plural(singular: string, plural: string, count: number, replacement?: TranslationReplacements, context?: string): Promise<string> {
    const message = count > 1 ? plural : singular;
    return this.translate(message, replacement, context);
  }


  private async translateSingle(message: string, replacement?: TranslationReplacements, context?: string): Promise<string> {
    const key = context ? `${context}.${message}` : message;

    if (key === '') {
      return '';
    }

    const translation = await firstValueFrom(this.ngxTranslator.get(key, replacement));

    return translation === key ? message : translation;
  }

}
