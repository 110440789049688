<header class="dialog-header">
  <h2 class="title">
    <ng-container [ngSwitch]="type">

      <ng-container *ngSwitchCase="'to_review'">
        {{'Demande de validation' | ehp_translate}}
      </ng-container>

      <ng-container *ngSwitchCase="'to_waiting_customer'">
        {{'Demander le dépôt client'| ehp_translate}}
      </ng-container>

      <ng-container *ngSwitchCase="'to_close'">
        {{'Fermeture du dépôt'| ehp_translate}}
      </ng-container>

      <ng-container *ngSwitchCase="'to_archive'">
        {{'Archivage'| ehp_translate}}
      </ng-container>

      <ng-container *ngSwitchDefault>
        {{'Changement d\'état' | ehp_translate}}
      </ng-container>

    </ng-container>
  </h2>
</header>

<div class="dialog-content">

  <ng-container [ngSwitch]="type">

    <ng-container *ngSwitchCase="'to_review'">
      <p>{{ 'Vous êtes sur le point de demander la revue des fichiers que vous avez déposés.'|ehp_translate }}
        {{ 'Si vous souhaitez apporter des informations à notre connaissance, veuillez nous laisser un commentaire ci-dessous.'|ehp_translate }}
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="'to_waiting_customer'">
      <p>{{ 'Vous êtes sur le point de donner l\'accès au chargement des fichiers au client.'|ehp_translate }}
        {{ 'Si vous souhaitez vous pouvez lui laisser un commentaire complémentaire.'|ehp_translate }}</p>
    </ng-container>

    <ng-container *ngSwitchCase="'to_close'">
      <p>{{ 'Vous êtes sur le point de valider et fermer ce dépôt. La fermeture du dépôt est définitive et celui-ci ne pourra pas être réouvert.'|ehp_translate }}</p>
    </ng-container>

    <ng-container *ngSwitchCase="'to_archive'">
      <p> {{ 'Vous êtes sur le point d\'archiver le dépôt. Les dépôts archivés ne seront plus visibles dans la liste des dépôts.'|ehp_translate }}</p>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <p>{{ 'Vous êtes sur le point de changer l\'état du dépôt. Vous pouvez laisser un commentaire si vous souhaitez apporter une précisions.'|ehp_translate }}</p>
    </ng-container>
  </ng-container>

  <ehp-form-field>
    <label for="comment">{{'Commentaire' | ehp_translate }}</label>
    <textarea [(ngModel)]="comment" ehp-form-control id="comment" rows="10"></textarea>
  </ehp-form-field>

</div>

<div class="dialog-actions">
  <button (click)="confirm()" class="primary" type="button">

    <ng-container [ngSwitch]="type">

      <ng-container *ngSwitchCase="'to_review'">
        {{'Demander la validation'| ehp_translate}}
      </ng-container>

      <ng-container *ngSwitchCase="'to_waiting_customer'">
        {{'Demander le dépôt client'| ehp_translate}}
      </ng-container>

      <ng-container *ngSwitchCase="'to_close'">
        {{'Fermer le dépôt'| ehp_translate}}
      </ng-container>

      <ng-container *ngSwitchCase="'to_archive'">
        {{'Archiver'| ehp_translate}}
      </ng-container>

      <ng-container *ngSwitchDefault>
        {{'Confirmer'| ehp_translate}}
      </ng-container>

    </ng-container>
  </button>
  <button (click)="cancel()" class="ghost" type="button">{{'Annuler'| ehp_translate}}</button>
</div>

