import { Service } from '@application/framework/di';
import { ActivityAnnex } from '@domain/activity-annex';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Service()
export class ActivityAnnexAuthChecker {
  constructor(private readonly authorization: DocumentAuthorization) {}

  public canRead(annex?: ActivityAnnex) {
    return this.authorization.canReadDocument(annex);
  }

  public canCreate() {
    return this.authorization.canCreateDocument();
  }

  public canUpdate(annex: ActivityAnnex) {
    return this.authorization.canEditDocument(annex);
  }

  public canDelete(annex: ActivityAnnex) {
    return this.authorization.canDeleteDocument(annex);
  }
}
