import {createReducer, on} from "@ngrx/store";
import {CalculatorStoreState} from "./calculator.state";
import {CalculatorActions} from "@easyhpad-ui/app/bundles/calculator/store/calculator.actions";

const initialState: CalculatorStoreState = {
  isOpen: false,
};

export const calculatorReducer = createReducer(
  initialState,
  on(CalculatorActions.isOpen, (state, {isOpen}) => ({
    ...state,
    isOpen
  })),
);
