import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, mergeMap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { balanceSheetActions } from './balance-sheet.actions';
import { ErrorActions } from '@easyhpad-ui/app/bundles/errors/store';
import { BalanceSheet } from '@domain/balance-sheet';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { DateParser } from '@application/framework/date';
import { BalanceSheetRepository } from '@application/bundles/balance-sheet';

@Injectable()
export class BalanceSheetEffects {
  loadBalanceSheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(balanceSheetActions.loadBalanceSheets),
      mergeMap(action => from(this.loadBalanceSheets(action.params))),
      map(sheets => balanceSheetActions.setBalanceSheets({ sheets })),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  loadBalanceSheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(balanceSheetActions.selectBalanceSheet),
      mergeMap(action => (action.id ? from(this.loadBalanceSheet(action.id)) : of(null))),
      map(sheet => balanceSheetActions.addBalanceSheets({ sheets: sheet ? [sheet] : [] })),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly backendApi: EasyBackendApi,
    private readonly parser: DateParser,
    private readonly repository: BalanceSheetRepository,
  ) {}

  private loadBalanceSheets(params?: any): Promise<BalanceSheet[]> {
    return this.repository.list().then(entities => entities.map(entity => this.transform(entity)));
  }

  private loadBalanceSheet(id: BalanceSheet['id']): Promise<BalanceSheet> {
    return this.repository.get(id).then(entity => this.transform(entity));
  }

  private transform(entity: BalanceSheet): BalanceSheet {
    if ((typeof entity.financialYearClosingDate as any) === 'string') {
      entity.financialYearClosingDate = this.parser.fromISOString(entity.financialYearClosingDate as any);
    }

    if ((typeof entity.date as any) === 'string') {
      entity.date = this.parser.fromISOString(entity.date as any);
    }

    return entity;
  }
}
