import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthentificationUrlsProvider, AuthGuard } from '@easyhpad-ui/app/bundles/authentification';
import { CPOMUrlsProvider } from '@easyhpad-ui/app/bundles/cpom';
import { GmpUrlsProvider } from '@easyhpad-ui/app/bundles/gmp';
import { PMPUrlsProvider } from '@easyhpad-ui/app/bundles/pmp';
import {
  ForgottenPasswordPageComponent,
  LoginPageComponent,
  LogoutPageComponent,
  ResetPasswordPageComponent,
} from './bundles/authentification/pages';
import { EhpadDemainUrlsProvider } from '@easyhpad-ui/app/bundles/ehpad-demain/provider/urls-provider/urls-provider';
import { HealthFundingUrlsProvider } from '@easyhpad-ui/app/bundles/health-funding/providers/url-provider/health-funding-urls.provider';
import { DependencyFundingUrlsProvider } from '@easyhpad-ui/app/bundles/dependency-funding/providers/url-provider/dependency-funding-urls.provider';
import { UserUrlsProvider } from '@easyhpad-ui/app/bundles/user/providers';
import { PublicCurrentUserProfileComponent } from '@easyhpad-ui/app/bundles/user/modules/public/pages';
import { EPRDUrlsProvider, ERRDUrlsProvider } from '@easyhpad-ui/app/bundles/eprd-errd';
import { ActivityAnnexUrlsProvider } from '@easyhpad-ui/app/bundles/activity-annex/providers/url-provider/activity-annex-urls.provider';
import { AccommodationFundingUrlsProvider } from '@easyhpad-ui/app/bundles/accommodation-funding/providers';
import { SupportUrlsProvider } from '@easyhpad-ui/app/bundles/support/providers';
import { BenchmarkingUrlProvider } from '@easyhpad-ui/app/bundles/benchmarking/providers';
import { SimulationUrlProvider } from '@easyhpad-ui/app/bundles/simulation/providers';
import { FileDepositUrlProvider } from '@easyhpad-ui/app/bundles/file-deposit/providers';
import { LibraryUrlsProvider } from '@easyhpad-ui/app/bundles/library/providers';
import { HomePageComponent } from '@easyhpad-ui/app/pages';
import { BalanceSheetUrlsProvider } from '@easyhpad-ui/app/bundles/balance-sheet/providers';

const routes: Routes = [
  {
    path: AuthentificationUrlsProvider.publicSchema.login.path,
    component: LoginPageComponent,
    title: AuthentificationUrlsProvider.publicSchema.login.title,
  },
  {
    path: AuthentificationUrlsProvider.publicSchema.logout.path,
    component: LogoutPageComponent,
    title: AuthentificationUrlsProvider.publicSchema.logout.title,
  },
  {
    path: AuthentificationUrlsProvider.publicSchema.onboarding.path,
    component: ResetPasswordPageComponent,
    title: AuthentificationUrlsProvider.publicSchema.onboarding.title,
    data: {
      title: 'Finalisation du compte',
    },
  },
  {
    path: AuthentificationUrlsProvider.publicSchema.forgottenPassword.path,
    component: ForgottenPasswordPageComponent,
    title: AuthentificationUrlsProvider.publicSchema.forgottenPassword.title,
  },
  {
    path: AuthentificationUrlsProvider.publicSchema.resetPassword.path,
    component: ResetPasswordPageComponent,
    title: AuthentificationUrlsProvider.publicSchema.resetPassword.title,
    data: {
      title: AuthentificationUrlsProvider.publicSchema.resetPassword.title,
    },
  },
  {
    path: UserUrlsProvider.publicSchema.current.path,
    component: PublicCurrentUserProfileComponent,
    canActivate: [AuthGuard],
    title: UserUrlsProvider.publicSchema.current.title,
  },
  {
    path: 'admin',
    loadChildren: () => import('./bundles/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'facility',
    loadChildren: () =>
      import('./bundles/facility/modules/public/facility-public.module').then(m => m.FacilityPublicModule),
  },
  {
    path: 'authorization',
    loadChildren: () =>
      import('./bundles/capacity-authorization/modules/public/capacity-authorization-public.module').then(
        m => m.CapacityAuthorizationPublicModule,
      ),
  },
  {
    path: GmpUrlsProvider.base,
    loadChildren: () => import('./bundles/gmp/modules/public/gmp-public.module').then(m => m.GMPPublicModule),
  },
  {
    path: PMPUrlsProvider.base,
    loadChildren: () => import('./bundles/pmp/modules/public/pmp-public.module').then(m => m.PMPPublicModule),
  },
  {
    path: CPOMUrlsProvider.base,
    loadChildren: () => import('./bundles/cpom/modules/public/cpom-public.module').then(m => m.CPOMPublicModule),
  },
  {
    path: EhpadDemainUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/ehpad-demain/modules/public/ehpad-demain-public.module').then(m => m.EhpadDemainPublicModule),
  },
  {
    path: HealthFundingUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/health-funding/modules/public/health-funding-public.module').then(
        m => m.HealthFundingPublicModule,
      ),
  },
  {
    path: DependencyFundingUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/dependency-funding/modules/public/dependency-funding-public.module').then(
        m => m.DependencyFundingPublicModule,
      ),
  },
  {
    path: EPRDUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/eprd-errd/modules/public-eprd/eprd-public.module').then(m => m.EprdPublicModule),
  },
  {
    path: ERRDUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/eprd-errd/modules/public-errd/errd-public.module').then(m => m.ErrdPublicModule),
  },
  {
    path: ActivityAnnexUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/activity-annex/modules/public/activity-annex-public.module').then(
        m => m.ActivityAnnexPublicModule,
      ),
  },
  {
    path: AccommodationFundingUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/accommodation-funding/modules/public/accommodation-funding-public.module').then(
        m => m.AccommodationFundingPublicModule,
      ),
  },
  {
    path: SupportUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/support/modules/public/support-public.module').then(m => m.SupportPublicModule),
  },
  {
    path: BenchmarkingUrlProvider.base,
    loadChildren: () =>
      import('./bundles/benchmarking/modules/public/benchmarking-public.module').then(m => m.BenchmarkingPublicModule),
  },
  {
    path: SimulationUrlProvider.base,
    loadChildren: () =>
      import('./bundles/simulation/modules/public/simulation-public.module').then(m => m.SimulationPublicModule),
  },
  {
    path: FileDepositUrlProvider.base,
    loadChildren: () =>
      import('./bundles/file-deposit/modules/public/file-deposit-public.module').then(m => m.FileDepositPublicModule),
  },
  {
    path: BalanceSheetUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/balance-sheet/modules/public/balance-sheet-public.module').then(
        m => m.BalanceSheetPublicModule,
      ),
  },
  {
    path: LibraryUrlsProvider.base,
    loadChildren: () =>
      import('./bundles/library/modules/public/library-public.module').then(m => m.LibraryPublicModule),
  },
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    title: 'Accueil',
    outlet: 'primary',
  },

  {
    path: '**',
    redirectTo: '',
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 64],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
