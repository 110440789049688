import {ServiceMetadata} from "@application/framework/di/interfaces/service-metadata.interface";
import {ContainedElement} from "@application/framework/di/types/contained-element.type";
import {ContainerIdentifier} from "@application/framework/di/types/container-identifier.type";
import {ServiceIdentifier} from "@application/framework/di/types/service-identifier";


export class Container {


  public id!: ContainerIdentifier;

  /**
   * Indicates if the container has been disposed or not.
   */
  protected disposed: boolean = false;

  protected metadataMaps: Map<ServiceIdentifier, ServiceMetadata> = new Map();

  constructor(id: ContainerIdentifier) {
    this.id = id;
  }

  /**
   * Indicates if the service with given identifier is registered service container.
   * @param identifier
   */
  public has<T = unknown>(identifier: ServiceIdentifier<T>): boolean {
    this.throwIfDisposed();
    throw new Error('Method "has" not implemented in Container');
  }

  /**
   * Register new service in container
   * @param metadata
   */
  public set<T = unknown>(metadata: ServiceMetadata<T>): boolean {
    this.throwIfDisposed();
    throw new Error('Method "set" not implemented in Container');
  }

  /**
   * Get service from container
   * @param identifier
   */
  public get<T = unknown>(identifier: ServiceIdentifier<T>): T {
    this.throwIfDisposed();
    throw new Error('Method "get" not implemented in Container');
  }


  /**
   * @deprecated use - @Service decorator instead
   * @param token
   * @param value
   * @param dependencies
   */
  public bind<T>(token: ContainedElement<T>, value: any, ...dependencies: any[]): void {
    this.throwIfDisposed();
    throw new Error(`${this.constructor.name}.bind() is deprecated. Please replace implementation by ${this.constructor.name}.set() or use @Service() decorator`);
  }

  /**
   * @deprecated use - @Service decorator instead
   * @param token
   * @param dependencies
   */
  public make<T>(token: any, ...dependencies: any[]): T {
    this.throwIfDisposed();
    throw new Error(`${this.constructor.name}.make() is deprecated. Please replace implementation by ${this.constructor.name}.set() or use @Service() decorator`);
  }

  /**
   * @deprecated use - @Service decorator instead
   * @param token
   * @param dependencies
   */
  public register<T extends new (...args: any) => any>(token: T, dependencies: any[]): void {
    this.throwIfDisposed();
    throw new Error(`${this.constructor.name}.register() is deprecated. Please replace implementation by ${this.constructor.name}.set() or use @Service() decorator`);

  }

  public async dispose(): Promise<void> {
    this.disposed = true;

    await Promise.resolve();
  }

  protected throwIfDisposed() {
    if (this.disposed) {
      throw new Error('Cannot use container after it has been disposed.');
    }
  }

}
