<ng-container [formGroup]="form">

    <ehp-form-field>
        <ehp-select [options]="propertyOptions" ehp-form-control formControlName="key"></ehp-select>
    </ehp-form-field>

    <ehp-form-field *ngIf="key">
        <ehp-select [options]="operatorOptions" ehp-form-control
                    formControlName="op">
        </ehp-select>
    </ehp-form-field>

    <ehp-form-field *ngIf="op">
        <input [type]="inputType" ehp-form-control formControlName="value">
    </ehp-form-field>

</ng-container>
