import { FacilitySearchParams } from '@application/bundles/facility/facility-search-params';
import { Facility } from '@domain/facility';
import { StoreSerializedFacility } from '@easyhpad-ui/app/bundles/facility/store/serializers/facility/facility-store.serializer';
import { createAction, props } from '@ngrx/store';

export enum FACILITY_ACTION_TYPES {
  ADMIN_LOAD = '[Facility Admin] Load facilities',
  ADMIN_LOADED = '[Facility Admin] Set facilities',

  LOAD_AVAILABLE = '[Facility] Load current account facilities',
  SET_ACCOUNT = '[Facility] Set current account facilities',
  SET_CURRENT = '[Facility] Set current selected facilities',

  SINGLE_LOAD = '[Facility] load single facility',
  SINGLE_LOADED = '[Facility] set single facility',

  NAME_LOAD = '[Facility] load facility name',
  NAME_LOADED = '[Facility] Set loaded facility name',

  RESET = '[Facility] Reset facility store',
}

const loadAdminFacilities = createAction(FACILITY_ACTION_TYPES.ADMIN_LOAD, props<{ params?: FacilitySearchParams }>());

const setAdminFacilities = createAction(
  FACILITY_ACTION_TYPES.ADMIN_LOADED,
  props<{ facilities: StoreSerializedFacility[] }>(),
);

const loadAccountFacilities = createAction(FACILITY_ACTION_TYPES.LOAD_AVAILABLE);

const setAccountFacilities = createAction(
  FACILITY_ACTION_TYPES.SET_ACCOUNT,
  props<{ facilities: StoreSerializedFacility[] }>(),
);

const selectAccountFacilities = createAction(
  FACILITY_ACTION_TYPES.SET_CURRENT,
  props<{ ids: Array<Facility['id']> }>(),
);

const loadFacility = createAction(
  FACILITY_ACTION_TYPES.SINGLE_LOAD,
  props<{ id: Facility['id']; useCache?: boolean }>(),
);

const setFacility = createAction(FACILITY_ACTION_TYPES.SINGLE_LOADED, props<{ facility: StoreSerializedFacility }>());

const loadFacilityName = createAction(FACILITY_ACTION_TYPES.NAME_LOAD, props<{ id: Facility['id'] }>());
const setFacilityForName = createAction(
  FACILITY_ACTION_TYPES.NAME_LOADED,
  props<{ id: Facility['id']; name: Facility['name'] }>(),
);

const resetFacilityStore = createAction(FACILITY_ACTION_TYPES.RESET);

export const FacilityActions = Object.freeze({
  loadAdminFacilities,
  setAdminFacilities,
  loadAccountFacilities,
  setAccountFacilities,
  selectAccountFacilities,
  loadFacility,
  setFacility,
  resetFacilityStore,
  loadFacilityName,
  setFacilityForName,
});
