import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { isPhoneNumber } from '@domain/phone';
import { PhoneRegionCode } from '@domain/phone/phone.interface';
import { PhoneInputFormat } from '@easyhpad-ui/app/bundles/phone/interfaces/phone-input-format.interface';
import { PhoneNumberValidator } from '@easyhpad-ui/app/bundles/phone/validators/phone-number/phone-number.validator';
import { configuration } from '@easyhpad-ui/environments/configuration';

const nodeName = 'ehp-phone-input';

@Component({
  selector: nodeName,
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
})
export class PhoneInputComponent implements ControlValueAccessor {
  @Input('input-id') public id: string | undefined;

  @Input() public phone: { number: string; country?: string } | undefined;

  public phoneControl: FormControl;

  public regionControl = new FormControl<string>(configuration.defaultPhoneNumberRegion, [Validators.required]);

  public get nodeName(): string {
    return nodeName;
  }

  public get value(): PhoneInputFormat {
    return { number: this.phoneControl.value ?? '', region: this.regionControl.value ?? undefined };
  }

  public set value(value: PhoneInputFormat) {
    this.writeValue(value);
  }

  constructor(phoneValidator: PhoneNumberValidator) {
    this.phoneControl = new FormControl('', {
      //validators: [Validators.required],
      asyncValidators: [phoneValidator.forRegion(PhoneRegionCode.FR).validate],
    });

    this.phoneControl.valueChanges.subscribe(() => {
      this.onChange(this.value);
    });
    this.regionControl.valueChanges.subscribe(() => this.onChange(this.value));
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneControl.disable();
      this.regionControl.disable();
    } else {
      this.phoneControl.enable();
      this.regionControl.enable();
    }
  }

  public writeValue(value: PhoneInputFormat | null | string | undefined): void {
    if (value === undefined || value === null || value === '') {
      this.phoneControl.setValue('');
    } else if (typeof value === 'string') {
      this.phoneControl.setValue(value);
    } else if (isPhoneNumber(value)) {
      this.phoneControl.setValue(value.toString());

      if (value.region) {
        this.regionControl.setValue(value.region);
      }
    } else {
      throw new Error(`Invalid value format in ${PhoneInputComponent.name}`);
    }
  }

  private onChange = (value: PhoneInputFormat): void => undefined;
}
