import {TARIFF_OPTIONS, TariffOption, TariffOptionTypes} from "@domain/cpom";


export function isValidTariffOptionType(type: any): type is TARIFF_OPTIONS {
  return TariffOptionTypes.has(type);
}

export function isTariffOption(option: any): option is TariffOption {
  return typeof option === "object"
    && option !== null
    && (option as Partial<TariffOption>).type !== undefined
    && TariffOptionTypes.has(option.type)
    && typeof (option as Partial<TariffOption>).label === "string"
}


