import { isValidCPOMFundingIdType, UpdateCPOMFundingDto } from '@application/bundles/cpom';
import { IsValidCPOMFundingId } from '@implementations/bundles/cpom/validators';
import { CreateCPOMFundingDtoImpl } from './create-cpom-funding.dto';

export class UpdateCPOMFundingDtoImpl extends CreateCPOMFundingDtoImpl implements UpdateCPOMFundingDto {
  @IsValidCPOMFundingId()
  public id!: UpdateCPOMFundingDto['id'];

  constructor(values?: Partial<UpdateCPOMFundingDto>) {
    super(values);

    if (values && isValidCPOMFundingIdType(values.id)) {
      this.id = values.id;
    }
  }
}
