import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BalanceSheetStoreState } from './balance-sheet.state';
import { adapter } from './balance-sheet.reducer';
import { values } from '@domain/lib';
import { BalanceSheet } from '@domain/balance-sheet';
import { BALANCE_SHEET_STORE_KEY } from './balance-sheet.key';

export const selectBalanceSheetState = createFeatureSelector<BalanceSheetStoreState>(BALANCE_SHEET_STORE_KEY);

/*
 if (state) {

      }
 */
export const selectBalanceSheetPublic = createSelector(
  selectBalanceSheetState,
  (state: BalanceSheetStoreState) => state.public,
);

const publicEntitiesSelectors = adapter.getSelectors(selectBalanceSheetPublic);

export const selectBalanceSheetLoading = createSelector(
  selectBalanceSheetPublic,
  (state: BalanceSheetStoreState['public']) => state.loading,
);

export const selectBalanceSheetEntities = createSelector(publicEntitiesSelectors.selectEntities, state => {
  return values(state).filter(entity => !!entity) as BalanceSheet[];
});

export const selectSelectedBalanceSheet = (id: BalanceSheet['id'] | null) =>
  createSelector(publicEntitiesSelectors.selectEntities, state => {
    if (id && state[id] !== undefined) {
      return state[id];
    }

    return null;
  });
