import { ListCPOMParams } from '@application/bundles/cpom';

import { DocumentListParamsImpl } from '@application/framework/search';
import { SORT_ORDER } from '@application/framework/search/sort';

export class ListCPOMParamsImpl extends DocumentListParamsImpl implements ListCPOMParams {
  constructor() {
    super();
    this.setSort({ key: 'beginAt', order: SORT_ORDER.DESC });
  }
}
