import { GetLatestPMPQuery, PMPRepository } from '@application/bundles/pmp';
import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { PMP } from '@domain/pmp';

@HandleQuery({
  query: GetLatestPMPQuery,
})
export class GetLatestPMPQueryHandler implements QueryHandler<GetLatestPMPQuery, PMP | undefined> {
  constructor(private readonly repository: PMPRepository) {}

  public async handle(query: GetLatestPMPQuery): Promise<PMP | undefined> {
    return this.repository.latestForFacility(query.facilityId);
  }
}
