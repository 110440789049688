<div *ngIf="eprd" class="document-list-item root-item">

  <h3 class="title item-title m0">
    <span [class.icon-file]="eprd.isSingle" [class.icon-folder]="!eprd.isSingle" class="icon ehp-icon"></span>

    <ehp-eprd-link [eprd]="eprd"></ehp-eprd-link>
  </h3>

  <span class="year">
    {{eprd.accountingYear}}
  </span>

  <span class="date">
    <ng-container *ngIf="eprd.depositDate">
          {{eprd.depositDate | date}}
    </ng-container>
  </span>

  <span class="expense">
    <ng-container *ngIf="eprd.singleChild?.expense">-{{eprd.singleChild?.expense | currency}}</ng-container>
  </span>

  <span class="revenue">
    <ng-container *ngIf="eprd.singleChild?.revenue">{{eprd.singleChild?.revenue | currency}}</ng-container>
  </span>


  <ehp-countable-label [count]="eprd.details.length || 0" [displaySingle]="false" class="facility">

    <ng-container plural>{{'établissements' | ehp_translate}}</ng-container>

    <ng-container *ngIf="eprd.singleChild" single>
      <ehp-facility-name [fid]="eprd.singleChild.facilityId"></ehp-facility-name>
    </ng-container>
  </ehp-countable-label>


  <div class="actions-list actions">

    <ehp-eprd-link [eprd]="eprd" class="is-icon icon-arrow ghost button" pTooltip="{{'Voir' | ehp_translate}}"
                   tooltipPosition="bottom">

      <span class="label">{{"Consulter l'E.R.R.D" | ehp_translate }}</span>
    </ehp-eprd-link>
  </div>

</div>

<div *ngIf="eprd && eprd.details && eprd.details.length > 1" class="children-items">

  <ng-container *ngFor="let local of eprd.details; let index = index">

    <div
      *ngIf="index <= 1 || open"
      [ngStyle]="{'--ehp-document-list-item--index': index + 1}"
      class="document-list-item child-item"
    >
      <h3 class="title item-title m0">
        <span class="icon ehp-icon icon-file"></span>

        <ehp-eprd-link [refId]="eprd.id">
          <ehp-facility-name [fid]="local.facilityId"></ehp-facility-name>
        </ehp-eprd-link>
      </h3>

      <span class="year">
        {{eprd.accountingYear}}
      </span>

      <span class="date">
         <ng-container *ngIf="eprd.depositDate">
          {{eprd.depositDate | date}}
        </ng-container>
      </span>


      <span class="expense">-{{local.expense | currency}}</span>

      <span class="revenue">{{local.revenue | currency}}</span>

      <div class="facility"></div>

      <div class="actions-list actions"></div>
    </div>

  </ng-container>


</div>

