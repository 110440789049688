import {
  AddCapacityAuthorizationDto
} from "@application/bundles/capacity-authorization/dtos/add-capacity-authorization.dto";
import {BuilderAssembler} from "@application/framework/builder/builder-assembler";
import {BuilderPiece} from "@application/framework/builder/builder.piece";
import {PropertyTypeOf} from "@application/framework/lib";

export const addCapacityAuthorizationAssembler: BuilderAssembler<AddCapacityAuthorizationDto> = (...pieces: BuilderPiece<PropertyTypeOf<AddCapacityAuthorizationDto>, AddCapacityAuthorizationDto>[]): AddCapacityAuthorizationDto => {
  throw new Error('missing addCapacityAuthorizationAssembler implementation');
}
