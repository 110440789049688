import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { ForecastCostByEtpRepository, ListLatestForecastCostByETPQuery } from '@application/bundles/etp';
import { ForecastCostByETP } from '@domain/etp';
import { Year } from '@domain/lib';

@HandleQuery({
  query: ListLatestForecastCostByETPQuery,
})
export class ListLatestForecastCostByEtpQueryHandler implements QueryHandler<ListLatestForecastCostByETPQuery> {
  constructor(private readonly repository: ForecastCostByEtpRepository) {}

  public handle(query: ListLatestForecastCostByETPQuery): Promise<{ entities: ForecastCostByETP[]; year: Year }> {
    const params = query.facilityIds ? { facilityIds: query.facilityIds } : {};
    return this.repository.listLatest(params);
  }
}
