import {DynamicField} from './dynamic-field';
import {AbstractControl} from '@angular/forms';
import {DynamicFormElementType} from '@easyhpad-ui/app/library/form/contracts/dynamic-form';
import {ObjectKey} from '@domain/lib';

/**
 *
 */
export type DynamicAbstractControlField<
  T extends DynamicFormElementType<any>,
  C extends AbstractControl = AbstractControl,
> = T & {
  /**
   * Field name
   */
  name: ObjectKey;

  /**
   * Field Control
   */
  control: C;
};

export type DynamicOptionalAbstractControlField<
  T extends DynamicFormElementType<any>,
  C extends AbstractControl = AbstractControl,
> = T & {
  /**
   * Field name
   */
  name: ObjectKey;

  /**
   * Field Control
   */
  control?: C;
};

export interface DynamicFormElementTypeComponent<T extends DynamicFormElementType<any>> {
  readonly field: DynamicAbstractControlField<T>;
}

/**
 * Define the required properties of a component used to create dynamic form field
 */
export interface HasDynamicField<T extends DynamicField> {
  readonly field: DynamicAbstractControlField<T>;
}
