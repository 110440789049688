import {Customer} from "@domain/customer";

export interface CustomerStoreState {
  current: {
    available: Customer[],
    selected?: Customer | undefined
  },
  admin: {
    loading: boolean,
    entities: Customer[],
  }
}

