import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FileDeposit } from '@domain/file-deposit';
import { Facility } from '@domain/facility';
import { QueryBus } from '@application/framework/command-query';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';

@Component({
  selector: 'ehp-file-deposit-name',
  templateUrl: './file-deposit-name.component.html',
  styleUrls: ['./file-deposit-name.component.scss'],
})
export class FileDepositNameComponent implements OnChanges {
  @Input() public deposit!: FileDeposit;

  public name: string | undefined;

  constructor(private readonly queryBus: QueryBus) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['deposit']) {
      this.updateNameFromAuthorization(changes['deposit'].currentValue);
    }
  }

  private updateNameFromAuthorization(deposit: FileDeposit | undefined): void {
    if (deposit === undefined) {
      this.name = '';
    } else {
      this.queryBus.request<Facility | undefined>(new GetFacilityQuery(deposit.facilityId)).then(facility => {
        if (facility) {
          this.name = facility.name;
        }
      });
    }
  }
}
