import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
  CapacityAuthorizationAuthorizationChecker,
  CapacityAuthorizationCreationFailEvent,
} from '@application/bundles/capacity-authorization';
import { AddCapacityAuthorizationCommand } from '@application/bundles/capacity-authorization/commands/add-capacity-authorization.command';
import { AddCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos';
import { CapacityAuthorizationCreatedEvent } from '@application/bundles/capacity-authorization/events/create-authorization/capacity-authorization-created.event';
import { HandleCommand } from '@application/framework/command-query';
import { CommandHandler } from '@application/framework/command-query/handler.interface';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { CapacityAuthorization, CapacityAuthorizationRepository } from '@domain/capacity-authorization';
import { Facility } from '@domain/facility';
import { MediaBucket, TemporaryMedia } from '@domain/media';

@HandleCommand({
  command: AddCapacityAuthorizationCommand,
})
export class AddCapacityAuthorizationCommandHandler
  implements CommandHandler<AddCapacityAuthorizationCommand, CapacityAuthorization>
{
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly authorization: CapacityAuthorizationAuthorizationChecker,
    private readonly sanitizer: SanitizerLibrary,
    private readonly validator: ObjectValidator,
    private readonly repository: CapacityAuthorizationRepository,
    private readonly mediaBucket: MediaBucket,
    private readonly eventDispatcher: EventDispatcher,
  ) {}

  public async handle(command: AddCapacityAuthorizationCommand): Promise<CapacityAuthorization> {
    const facility = command.facility;
    const dto = command.authorization;
    try {
      await this.checkAccess(facility, dto);

      const authorization = await this.sanitize(dto)
        .then(dto => this.validate(dto))
        .then(dto => this.storeMediaAndCreateAuthorization(facility, dto));

      this.eventDispatcher.dispatch(new CapacityAuthorizationCreatedEvent(authorization));

      return authorization;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(facility: Facility, dto: AddCapacityAuthorizationDto): Promise<void> {
    if (!(await this.authorization.canCreate(facility))) {
      this.logger.error('Capacity authorization creation : Forbidden');
      throw new ForbiddenError();
    }
  }

  private async sanitize(dto: AddCapacityAuthorizationDto): Promise<AddCapacityAuthorizationDto> {
    try {
      dto = await this.sanitizer.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('Capacity authorization : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: AddCapacityAuthorizationDto): Promise<AddCapacityAuthorizationDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('Capacity authorization : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à créer une autorisation de capacité.",
        {},
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new CapacityAuthorizationCreationFailEvent(message));

    return error;
  }

  private async storeMediaAndCreateAuthorization(facility: Facility, dto: AddCapacityAuthorizationDto) {
    dto.facilityId = facility.id;

    const medias: TemporaryMedia[] = [];

    if (dto.authorization) {
      const authorization = await this.mediaBucket.add(dto.authorization);
      medias.push(authorization);
      dto.authorizationId = authorization.id;
    }

    if (dto.pv) {
      const pv = await this.mediaBucket.add(dto.pv);
      medias.push(pv);
      dto.pvId = pv.id;
    }

    try {
      return this.repository.create(dto);
    } catch (e) {
      for (const media of medias) {
        await media.markForDeletion();
      }

      throw e;
    }
  }
}
