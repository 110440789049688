<header class="dialog-header">
  <h2 *ngIf="title" class="title">
    <ehp-translatable-string [value]="title"></ehp-translatable-string>
  </h2>
</header>

<div class="dialog-content">

  <ehp-inspection-report-edit-form
    (onCancel)="dismiss()"
    (onSubmit)="created($event)"
    [authorization]="data.authorization"
    [mode]="data.mode"
    [report]="data.report"
  ></ehp-inspection-report-edit-form>

</div>

