import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '@domain/customer';
import {
  CustomerActions,
  CustomerUrlsProvider,
  selectCustomerCurrentAvailable,
  selectCustomerCurrentSelected,
} from '@easyhpad-ui/app/bundles/customer';
import { AppState } from '@easyhpad-ui/app/store';
import { Store } from '@ngrx/store';
import { Listbox, ListboxChangeEvent } from 'primeng/listbox';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-current-customer-selector',
  templateUrl: './current-customer-selector.component.html',
  styleUrls: ['./current-customer-selector.component.scss'],
})
export class CurrentCustomerSelectorComponent implements OnDestroy {
  public customers: Customer[] = [];

  public current: Customer | undefined;

  public selected: Customer | undefined;

  public url: string = '';

  @ViewChild(OverlayPanel) private panel!: OverlayPanel;

  @ViewChild(Listbox) private list!: Listbox;

  private selectionIsDisable = false;

  private isOpen = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly urls: CustomerUrlsProvider,
    private readonly store: Store<AppState>,
  ) {
    this.store
      .select(selectCustomerCurrentAvailable)
      .pipe(takeUntil(this.destroy$))
      .subscribe(customers => this.setCustomers(customers));

    this.store
      .select(selectCustomerCurrentSelected)
      .pipe(takeUntil(this.destroy$))
      .subscribe(customer => this.setCurrent(customer));

    this.store.dispatch(CustomerActions.loadAvailableCustomers());
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public open(): void {
    if (this.selectionIsDisable) {
      if (this.url) {
        this.router.navigateByUrl(this.url);
      }
      return;
    }

    if (this.panel) {
      this.panel.toggle(new CustomEvent(''));

      if (this.isOpen) {
        this.validateSelection();
      }
    }
  }

  public async validateSelection(): Promise<void> {
    if (this.panel) {
      this.panel.hide();
    }

    this.store.dispatch(CustomerActions.setCurrentCustomer({ customer: this.selected }));
  }

  public updateSelection(selection: ListboxChangeEvent) {
    let customer: Customer | undefined = selection.value ?? undefined;

    if (this.selected !== undefined && customer !== undefined && this.selected.id === customer.id) {
      customer = undefined;
      this.current = undefined;
    }

    this.selected = customer;
  }

  private setCurrent(customer: Customer | undefined): void {
    this.current = customer;
    this.selected = customer;
    this.updateUrl();
  }

  private updateUrl(): void {
    this.url = this.current ? this.urls.getUnsafeAdminShow(this.current.id) : '';
  }

  private setCustomers(customers: Customer[]): void {
    this.customers = customers;

    if (this.customers.length < 1) {
      this.selectionIsDisable = true;
      this.setCurrent(undefined);
    } else {
      this.selectionIsDisable = false;
    }
  }
}
