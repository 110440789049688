<ng-container *ngIf="type === types.DEFAULT">
  <div class="line"></div>
</ng-container>

<ng-container *ngIf="type === types.DOCUMENT">

  <div class="title"></div>

  <div class="paragraph">
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
  </div>

  <div class="paragraph">
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
  </div>

  <div class="paragraph">
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
  </div>

  <div class="paragraph">
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
  </div>

</ng-container>

<ng-container *ngIf="type === types.FORM">

  <div class="form-field">
    <div class="label"></div>

    <div class="description">
      <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
      <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    </div>

    <div class="input"></div>
  </div>

  <div class="form-field">
    <div class="label"></div>

    <div class="description">
      <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
      <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    </div>

    <div class="input"></div>
  </div>

  <div class="form-field">
    <div class="label"></div>

    <div class="description">
      <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
      <div [ngStyle]="{'max-width.%': randomWidth()}" class="line"></div>
    </div>

    <div class="input"></div>
  </div>

  <div class="actions">
    <div class="button"></div>
  </div>

</ng-container>

<ng-container *ngIf="type===types.DOCUMENT_LIST_ITEM">
  <ng-container *ngTemplateOutlet="documentListItem"></ng-container>
</ng-container>

<ng-container *ngIf="type===types.DOCUMENT_LIST">
  <ng-container *ngTemplateOutlet="documentListItem"></ng-container>
  <ng-container *ngTemplateOutlet="documentListItem"></ng-container>
  <ng-container *ngTemplateOutlet="documentListItem"></ng-container>
  <ng-container *ngTemplateOutlet="documentListItem"></ng-container>
  <ng-container *ngTemplateOutlet="documentListItem"></ng-container>
</ng-container>

<ng-container *ngIf="type===types.TABLE">
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
  <ng-container *ngTemplateOutlet="tableRow"></ng-container>
</ng-container>


<ng-template #documentListItem>
  <div class="document-list-item">

    <div [ngStyle]="{'width.%': 40}" class="label-container">
      <div class="circle"></div>
      <div class="label"></div>
    </div>
    <div [ngStyle]="{'width.%': randomWidth(12, 15)}" class="label"></div>
    <div [ngStyle]="{'width.%': randomWidth(8, 10)}" class="label"></div>
    <div [ngStyle]="{'width.%': randomWidth(17, 20)}" class="label"></div>

    <div [ngStyle]="{'width.%': 5}" class="actions">
      <div class="button circle"></div>
    </div>

  </div>
</ng-template>


<ng-template #tableRow>
  <div class="table-row">

    <div [ngStyle]="{'width.%': 40}" class="label"></div>
    <div [ngStyle]="{'width.%': randomWidth(12, 15)}" class="label"></div>
    <div [ngStyle]="{'width.%': randomWidth(8, 10)}" class="label"></div>
    <div [ngStyle]="{'width.%': randomWidth(17, 20)}" class="label"></div>
    <div [ngStyle]="{'width.%': 15}" class="label"></div>


  </div>
</ng-template>
