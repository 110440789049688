import {ListFacilitiesQuery} from "@application/bundles/facility/query/list-facilities.query";
import {HandleQuery} from "@application/framework/command-query";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {Facility} from "@domain/facility";
import {FacilityRepository} from "@domain/facility/facility.repository";
import {
  AgnosticFacilitySearchParams
} from "@application/bundles/facility/implementations/agnostic-facility-search-params";

@HandleQuery({
  query: ListFacilitiesQuery
})
export class ListFacilitiesQueryHandler implements QueryHandler<ListFacilitiesQuery, Facility[]> {

  constructor(private readonly repository: FacilityRepository) {
  }

  public handle(query: ListFacilitiesQuery): Promise<Facility[]> {
    return this.repository.list(new AgnosticFacilitySearchParams().deserialize(query.params));
  }
}
