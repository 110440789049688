import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ehp-plural',
  templateUrl: './plural.component.html',
  styleUrls: ['./plural.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PluralComponent {
  
  @Input() count: number | null | undefined = 0;

}
