import {GroupedGIR, GroupedGIRAmount, GroupedGirAmountAndResidents} from "@domain/gir";
import {isValidObject} from "@application/framework/lib";

export function isGroupedGIR<T>(groupedGir: any): groupedGir is GroupedGIR<T> {
  return typeof groupedGir === 'object'
    && groupedGir !== null
    && 'firstGIRGroup' in groupedGir
    && 'secondGIRGroup' in groupedGir
    && 'thirdGIRGroup' in groupedGir;
}

export function isGroupedGIRAmount(value: any): value is GroupedGIRAmount {
  return isGroupedGIR(value)
    && typeof value.firstGIRGroup === 'number'
    && typeof value.secondGIRGroup === 'number'
    && typeof value.thirdGIRGroup === 'number';
}

export function isGirAmountAndResidents(value: any): value is { amount: number, residents: number | undefined } {
  return isValidObject(value)
    && typeof value.amount === 'number'
    && (typeof value.residents === 'number' || value.residents === undefined);
}

export function isGroupedGirAmountAndResidents(value: any): value is GroupedGirAmountAndResidents {
  return isValidObject(value)
    && isGirAmountAndResidents((value as GroupedGirAmountAndResidents).firstGIRGroup)
    && isGirAmountAndResidents((value as GroupedGirAmountAndResidents).secondGIRGroup)
    && isGirAmountAndResidents((value as GroupedGirAmountAndResidents).thirdGIRGroup);
}
