import {DI_PROVIDE_METADATA} from "@application/framework/di/tokens";
import {ProvideReflectionMetadata} from "@application/framework/di/types/provide-reflection-metadata.type";
import {MetadataReflection} from "@application/framework/lib";
import {DesignReflectionMetadataAccessor} from "@application/framework/reflection";

const replaceDependenciesWithMetadata = (dependencies: any[], metadata: ProvideReflectionMetadata, target?: Object): any[] => {

  metadata.forEach((m, index) => {

    if (m.id) {
      dependencies[index] = m.id;
    } else if (m.lazyType) {
      dependencies[index] = m.lazyType;
    } else {
      throw new Error(`Cannot find dependency type in "${target?.constructor.name} constructor at index ${index}".`);
    }
  });

  return dependencies;
}

export const extractDependencies = (target: Object): Array<any> => {

  const reflection = new MetadataReflection();
  const accessor = new DesignReflectionMetadataAccessor(reflection);

  let dependencies = accessor.getDesignParamTypes(target);

  if (reflection.hasMetadata(DI_PROVIDE_METADATA, target)) {
    const metadata: ProvideReflectionMetadata = reflection.getMetadata(DI_PROVIDE_METADATA, target) || new Map();
    dependencies = replaceDependenciesWithMetadata(dependencies, metadata, target);
  }

  return dependencies;
}
