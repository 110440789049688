import { createAction, props } from '@ngrx/store';
import { FileDeposit } from '@domain/file-deposit';
import { SerializedFileDepositSearchParams } from '@application/bundles/file-deposit';
import { CreateFileDeposit } from '@application/bundles/file-deposit/contracts/create-file-deposit';

export enum FILE_DEPOSIT_ACTION_TYPES {
  PUBLIC_LOAD = '[FileDeposit] Load deposits',
  PUBLIC_LOADED = '[FileDeposit] Set deposits',
  PUBLIC_LOAD_SINGLE = '[FileDeposit] Load single deposit',
  PUBLIC_SET_CURRENT = '[FileDeposit] Set current deposit',
  ONBOARDING_LOAD = '[FileDeposit] Load onboarding deposits',
  ONBOARDING_LOADED = '[FileDeposit] Set onboarding deposits',
}

const loadFileDeposits = createAction(
  FILE_DEPOSIT_ACTION_TYPES.PUBLIC_LOAD,
  props<{ params?: SerializedFileDepositSearchParams }>(),
);

const setFileDeposits = createAction(FILE_DEPOSIT_ACTION_TYPES.PUBLIC_LOADED, props<{ deposits: FileDeposit[] }>());

const loadSingleFileDeposit = createAction(
  FILE_DEPOSIT_ACTION_TYPES.PUBLIC_LOAD_SINGLE,
  props<{ id: FileDeposit['id'] }>(),
);

const setCurrentFileDeposit = createAction(
  FILE_DEPOSIT_ACTION_TYPES.PUBLIC_SET_CURRENT,
  props<{ deposit: FileDeposit | null }>(),
);

const loadOnboardingFileDeposit = createAction(
  FILE_DEPOSIT_ACTION_TYPES.ONBOARDING_LOAD,
  props<{ params?: SerializedFileDepositSearchParams }>(),
);

const setOnboardingFileDeposit = createAction(
  FILE_DEPOSIT_ACTION_TYPES.ONBOARDING_LOADED,
  props<{ deposits: FileDeposit[] }>(),
);

export const FileDepositActions = Object.freeze({
  loadFileDeposits,
  setFileDeposits,
  loadSingleFileDeposit,
  setCurrentFileDeposit,
  loadOnboardingFileDeposit,
  setOnboardingFileDeposit,

  /**
   * Create a new File Deposit entity
   */
  createFileDeposit: createAction('[File deposit] Create a new FileDeposit', props<{ payload: CreateFileDeposit }>()),
  fileDepositCreated: createAction('[File deposit] New FileDeposit created', props<{ fileDeposit: FileDeposit }>()),
});
