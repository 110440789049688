import {CommandHandlerRegistry} from "@application/framework/command-query/command-handler.registry";
import {
  CommandHandlersAgnosticRegistry
} from "@application/framework/command-query/implementations/command-agnostic-registry/command-handlers-agnostic.registry";
import {
  QueryHandlersAgnosticRegistry
} from "@application/framework/command-query/implementations/query-agnostic-registry/query-handlers-agnostic.registry";
import {QueryHandlerRegistry} from "@application/framework/command-query/query-handler.registry";
import {bindService} from "@application/framework/di";

export * from './command-bus.abstract';
export * from './query-bus.abstract';
export * from './command-handler.registry';
export * from './query-handler.registry';

export * from './errors/invalid-handler-type.error';
export * from './errors/missing-command-handler.error';
export * from './errors/missing-query-handler.error';

export * from './query.interface';
export * from './command.interface';
export * from './handler.interface';

export * from './decorators/handle-command.decorators';
export * from './decorators/handle-query.decorator';

bindService(CommandHandlerRegistry, CommandHandlersAgnosticRegistry);
bindService(QueryHandlerRegistry, QueryHandlersAgnosticRegistry);

