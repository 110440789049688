import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { UserUrlsProvider } from '@easyhpad-ui/app/bundles/user/providers';
import { FileDepositUrlProvider } from '@easyhpad-ui/app/bundles/file-deposit/providers';
import { environment } from '@easyhpad-ui/environments/environment';

const routes: Route[] = [
  {
    path: UserUrlsProvider.basePath,
    loadChildren: () => import('../user/modules/admin/user-admin.module').then(m => m.UserAdminSubModule),
  },
  {
    path: 'customer',
    loadChildren: () => import('../customer/modules/admin/customer-admin.module').then(m => m.CustomerAdminModule),
  },
  {
    path: 'facility',
    loadChildren: () => import('../facility/modules/admin/facility-admin.module').then(m => m.FacilityAdminModule),
  },
  {
    path: FileDepositUrlProvider.base,
    loadChildren: () =>
      import('../file-deposit/modules/admin/file-deposit-admin.module').then(m => m.FileDepositAdminModule),
  },
];

if (!environment.production) {
  routes.push({
    path: 'demo',
    loadChildren: () => import('../demo/modules/admin/demo-admin.module').then(m => m.DemoAdminModule),
  });
}

routes.push({ path: '', redirectTo: 'user', pathMatch: 'full' });

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
