export interface EasyPermanentDependencyFunding {
  id: number;

  price_gir1_2: number;

  price_gir3_4: number;

  price_gir5_6: number;

  num_gir1_2: number | null | undefined;

  num_gir3_4: number | null | undefined;

  num_gir5_6: number | null | undefined;

  gir_dep_value: number | null | undefined;

  activity_abattement: number | null | undefined;
}

export interface CreateEasyPermanentDependencyFundingDto extends Omit<EasyPermanentDependencyFunding, 'id'> {}

export interface UpdateEasyPermanentDependencyFundingDto extends EasyPermanentDependencyFunding {}
