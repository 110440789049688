import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { ListFileDepositQuery } from '@application/bundles/file-deposit/queries/list-file-deposit.query';

import { FileDepositRepository } from '@application/bundles/file-deposit/repositories';
import { AgnosticFileDepositSearchParams } from '@application/bundles/file-deposit/implementations/agnostic-file-deposit-search-params';

@HandleQuery({
  query: ListFileDepositQuery,
})
export class ListFileDepositQueryHandler implements QueryHandler<ListFileDepositQuery> {
  constructor(private readonly repository: FileDepositRepository) {}

  public async handle(query: ListFileDepositQuery): Promise<any> {
    return this.repository.list(new AgnosticFileDepositSearchParams().deserialize(query.params));
  }
}
