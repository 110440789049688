import { Injectable } from '@angular/core';
import { CapacityAuthorization } from '@domain/capacity-authorization';
import { joinPath } from '@application/framework/lib';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Injectable({
  providedIn: 'root',
})
export class CapacityAuthorizationUrlsProvider {
  public static readonly base = 'authorization';

  public static readonly publicSchema = {
    list: { path: '', title: 'Autorisations de capacité' },
    create: { path: 'create', title: 'Nouvelle autorisation de capacité' },
    show: { path: ':id', title: 'Autorisation de capacité' },
    update: { path: ':id/edit', title: "Modifier l'autorisation de capacité" },
  };

  constructor(public readonly authorization: DocumentAuthorization) {}

  public getPublicList(): Promise<string | null> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList() {
    return (
      '/' + joinPath(CapacityAuthorizationUrlsProvider.base, CapacityAuthorizationUrlsProvider.publicSchema.list.path)
    );
  }

  public async getPublicCreate(): Promise<string | null> {
    if (await this.authorization.canCreateDocument()) {
      return this.getUnsafePublicCreate();
    }

    return null;
  }

  public getUnsafePublicCreate() {
    return (
      '/' + joinPath(CapacityAuthorizationUrlsProvider.base, CapacityAuthorizationUrlsProvider.publicSchema.create.path)
    );
  }

  public getUnsafePublicShow(id: CapacityAuthorization['id']) {
    return '/' + joinPath(CapacityAuthorizationUrlsProvider.base, id.toString());
  }

  public async getPublicEdit(id: CapacityAuthorization['id']): Promise<string | null> {
    if (await this.authorization.canEditDocument()) {
      return this.getUnsafePublicEdit(id);
    }

    return null;
  }

  public getUnsafePublicEdit(id: CapacityAuthorization['id']) {
    return '/' + joinPath(CapacityAuthorizationUrlsProvider.base, id.toString(), 'edit');
  }
}
