import {Component, Input, ViewEncapsulation} from '@angular/core';
import {StoredCPOM} from "@application/bundles/cpom";

@Component({
  selector: 'ehp-cpom-list',
  templateUrl: './cpom-list.component.html',
  styleUrls: ['./cpom-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'document-list'
  }
})
export class CpomListComponent {


  @Input() public documents!: StoredCPOM[];
}
