import {TranslatableString} from "@application/framework/translation/translatable-string";
import {TranslationReplacements} from "./translation-replacement.interface";

export abstract class Translator {

  /**
   * Get string translation and replace dynamic
   * @param message
   * @param replacement
   * @param context - context of string translations
   */
  public abstract translate(message: string | TranslatableString, replacement?: TranslationReplacements, context?: string): Promise<string>;
  
  /**
   * Define singular and plural, and get the good translation based on count
   * @param singular
   * @param plural
   * @param count
   * @param replacement
   * @param context - context of string translations
   */
  public abstract plural(singular: string, plural: string, count: number, replacement?: TranslationReplacements, context?: string): Promise<string>;
}
