import {FacilityTypeRepository} from "@domain/facility/facility-type.repository";
import {FACILITY_TYPE, FacilityType} from "@domain/facility/types";

export class EasyFacilityTypeRepository implements FacilityTypeRepository {

  private types: Map<FACILITY_TYPE, FacilityType> = new Map([
    [FACILITY_TYPE.PUBLIC, {type: FACILITY_TYPE.PUBLIC, label: 'Établissement public'}],
    [FACILITY_TYPE.PRIVATE, {type: FACILITY_TYPE.PRIVATE, label: 'Établissement privé'}],
    [FACILITY_TYPE.NON_LUCRATIVE, {type: FACILITY_TYPE.NON_LUCRATIVE, label: 'Établissement à but non lucratif'}],
  ])

  public async list(): Promise<FacilityType[]> {
    return Array.from(this.types.values());
  }

  public async get(type: FACILITY_TYPE): Promise<FacilityType> {
    const facilityType = this.types.get(type);

    if (facilityType === undefined) {
      throw Error("Unknown facility type");
    }

    return facilityType;
  }

}
