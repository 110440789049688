import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Bundle } from '@application/framework/bundle';
import { ETPBundle } from '@application/bundles/etp/etp.bundle';
import { ForecastCostByEtpRepository, NationalCostByEtpRepository } from '@application/bundles/etp';
import {
  EasyForecastCostByEtpRepository,
  EasyNationalCostByEtpRepository,
} from '@implementations/bundles/backend/easyhpad';
import { extractDependencies } from '@application/framework/di';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: NationalCostByEtpRepository,
      useClass: EasyNationalCostByEtpRepository,
      deps: extractDependencies(EasyNationalCostByEtpRepository),
    },
    {
      provide: ForecastCostByEtpRepository,
      useClass: EasyForecastCostByEtpRepository,
      deps: extractDependencies(EasyForecastCostByEtpRepository),
    },
  ],
})
@Bundle({ imports: [ETPBundle] })
export class EtpModule {}
