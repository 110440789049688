import {Voter} from "@application/bundles/authorization";
import {VoterRegistry} from "@application/bundles/authorization/abstractions/voter.registry.abstract";
import {Container} from "@application/framework/di/container";


export class AbstractBundle {


  protected constructor(protected container: Container) {
    this.registerVoter();
  }


  protected getVoters(): Array<Voter> {
    return [];
  }


  private registerVoter(): void {
    const registry = this.container.get(VoterRegistry);

    registry.register(...this.getVoters());
  }
}
