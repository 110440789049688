import { Actions } from '@ngrx/effects';
import { accommodationActions } from '@easyhpad-ui/app/bundles/accommodation-funding/store/accommodation-funding.action';
import {
  GetAccommodationFundingQuery,
  ListAccommodationFundingQuery,
  SerializedAccommodationFundingSearchParams,
  UpdateAccommodationFundingCommand,
  UpdateAccommodationFundingCommandResult,
  UpdateAccommodationFundingDto,
} from '@application/bundles/accommodation-funding';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { CommandBus, QueryBus } from '@application/framework/command-query';
import { Injectable } from '@angular/core';
import { DocumentEffet } from '@easyhpad-ui/app/bundles/documents/store/document.effet';
import { DocumentStoreActions } from '@easyhpad-ui/app/bundles/documents';
import { from, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';

@Injectable()
export class AccommodationFundingEffect extends DocumentEffet<
  AccommodationFunding,
  any,
  UpdateAccommodationFundingDto
> {
  /**
   * @inheritDoc
   * @protected
   */
  protected get actions(): DocumentStoreActions<AccommodationFunding, AccommodationFunding['id']> {
    return accommodationActions;
  }

  constructor(
    private readonly commandBus: CommandBus,
    private readonly queryBus: QueryBus,
    store: Store<AppState>,
    actions$: Actions,
  ) {
    super(actions$, store);
  }

  protected loadDocuments(params?: SerializedAccommodationFundingSearchParams): Observable<AccommodationFunding[]> {
    return from(this.queryBus.request(new ListAccommodationFundingQuery(params)));
  }

  protected override loadDocument(id: AccommodationFunding['id']): Observable<AccommodationFunding> {
    return from(this.queryBus.request(new GetAccommodationFundingQuery(id)));
  }

  protected override updateDocument(
    id: AccommodationFunding['id'],
    update: UpdateAccommodationFundingDto,
  ): Observable<AccommodationFunding> {
    return from(
      this.commandBus.execute<UpdateAccommodationFundingCommandResult>(new UpdateAccommodationFundingCommand(update)),
    );
  }
}
