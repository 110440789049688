import {TARIFF_OPTIONS, TariffOptionAmount, TariffOptionAmountCollection} from "@domain/cpom";
import {Year} from "@domain/lib";
import {configuration} from "@easyhpad-ui/environments/configuration";
import {getClosestYear} from "@application/framework/lib";
import {
  AgnosticTariffOptionAmount
} from "@application/bundles/cpom/implementations/agnostic-tariff-option-amount/agnostic-tariff-option-amount";

export class AgnosticTariffOptionAmountCollection implements TariffOptionAmountCollection {


  private static readonly values = configuration.cpom.tariffOptionAmountValues;


  constructor(private readonly values: Map<Year, { mainland: number, overseas: number }> = new Map()) {
  }

  public getForYear(year: Year): Promise<TariffOptionAmount | undefined> {
    const closest = getClosestYear(year, Array.from(this.values.keys()));

    if (closest === undefined) {
      return Promise.resolve(undefined);
    }

    const value = this.values.get(closest) as { mainland: number, overseas: number };

    return Promise.resolve(new AgnosticTariffOptionAmount(closest, value.mainland, value.overseas));
    
  }

  public getCollection(type: TARIFF_OPTIONS): TariffOptionAmountCollection {
    const values = AgnosticTariffOptionAmountCollection.values.get(type) || new Map<Year, { mainland: number, overseas: number }>();

    return new AgnosticTariffOptionAmountCollection(values);
  }


}
