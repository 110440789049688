<div *ngIf="mode === 'dialog'" class="top-bar">

  <ng-container *ngIf="isHorizontalPosition" [ngTemplateOutlet]="titleTemplate"></ng-container>

  <div class="actions">
    <ng-container *ngIf="isHorizontalPosition" [ngTemplateOutlet]="fullscreenTpl"></ng-container>

    <button
      (click)="selfDestroy()"
      *ngIf="closable"
      [tooltipPosition]="fullscreenState.tooltipPositon"
      class="button is-icon icon-close ghost error"
      pTooltip="{{'Fermer la fenêtre' | ehp_translate }}"
    >
      <span class="label">{{'Fermer la fenêtre' | ehp_translate }}</span>
    </button>

  </div>
</div>

<div class="preview-container">

  <ng-container
    *ngIf="!isHorizontalPosition"
    [ngTemplateOutlet]="titleTemplate"
  >
  </ng-container>

  <div class="preview-host">
    <ng-template ehpPreviewHost></ng-template>
  </div>

  <div *ngIf="support('zoom')" class="zoom-controllers">
    <button (click)="zoomIn()" class="zoom-in control-button" type="button">
      <span class="label">{{ "Augmenter la taille d'affichage" | ehp_translate }}</span>
    </button>

    <button (click)="zoomOut()" class="zoom-out control-button" type="button">
      <span class="label">{{ "Diminuer la taille d'affichage" | ehp_translate }}</span>
    </button>
  </div>

  <div *ngIf="support('rotate')" class="rotation-controllers">
    <button (click)="rotateLeft()" class="rotate-left control-button" type="button">
      <span class="label">{{ "Tourner le media vers la gauche" | ehp_translate }}</span>
    </button>

    <button (click)="rotateLeft()" class="rotate-right control-button" type="button">
      <span class="label">{{ "Tourner le media vers la droite" | ehp_translate }}</span>
    </button>
  </div>
</div>



<!--<div class="switch-container">
  <button (click)="switchMode()">{{"Changer le mode d'affichage" | ehp_translate }}</button>
</div>-->

<ehp-resizable-grabber
  (onGrab)="onGrab()"
  (onMove)="onMove($event)"
  [position]="grabberPosition"
  class="grab"
></ehp-resizable-grabber>


<ng-template #titleTemplate>
  <div class="title-container">
    <div class="title-wrapper">

      <button (click)="toggleMediaList()" *ngIf="count > 1"
              class="is-icon icon-menu trigger-medias-list ghost small" type="button">
        <ehp-badge *ngIf="count > 1" [value]="count" class="badge"></ehp-badge>
        <span class="label">{{'Ouvrir / Fermer la liste des médias disponibles' | ehp_translate}}</span>
      </button>

      <span [innerHTML]="title" class="title"></span>
    </div>

    <div class="controls">
      <ng-container *ngIf="!isHorizontalPosition" [ngTemplateOutlet]="fullscreenTpl"></ng-container>
    </div>

  </div>

  <ul *ngIf="medias && medias.size > 1 && mediaListIsOpen" class="medias-list">
    <li *ngFor="let media of medias">
      <button (click)="open(media)" [innerHTML]="media.name" class="reset" type="button"></button>
    </li>
  </ul>
</ng-template>

<ng-template #fullscreenTpl>

  <button (click)="toggleFullscreen()" *ngIf="fullscreen"
          [ngClass]="{'is-fullscreen icon-reduce-screen': fullscreenState.isFullscreen, 'icon-fullscreen': !fullscreenState.isFullscreen }"
          [tooltipPosition]="fullscreenState.tooltipPositon"
          class="is-icon trigger-fullscreen ghost small secondary"
          pTooltip="{{fullscreenState.tooltip}}"
          type="button"
  >
    <span class="label">{{'Ouvrir / Fermer le mode plein écran' | ehp_translate}}</span>
  </button>
</ng-template>
