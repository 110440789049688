<header class="header document-list-header">
    <div class="title">{{'Nom du document' | ehp_translate}}</div>
    <div class="date">{{'Date' | ehp_translate}}</div>
    <div class="amount">{{"Tarif autorisé" | ehp_translate}}</div>
    <div class="occupation-rate">{{"Taux d'occupation" | ehp_translate}}</div>
    <div class="facility">{{'Établissement' | ehp_translate}}</div>
    <div class="actions">{{'Actions' | ehp_translate}}</div>
</header>

<ng-container *ngIf="list">
    <ng-container *ngIf="list.loading| async; else listDisplay">
        <ehp-skeleton [type]="SKELETON_TYPE"></ehp-skeleton>
    </ng-container>
</ng-container>

<ng-template #listDisplay>

    <ng-container *ngIf="list.documents | async as documents; else emptyList">

        <cdk-virtual-scroll-viewport class="list-viewport virtual-document-list" itemSize="50">

            <ehp-accommodation-funding-list-item
              *cdkVirtualFor="let document of documents"
              [funding]="document">
            </ehp-accommodation-funding-list-item>

        </cdk-virtual-scroll-viewport>
    </ng-container>

</ng-template>

<ng-template #emptyList>
    <ehp-empty-block>{{"Aucun financement hébergement archivé pour le moment." | ehp_translate}}</ehp-empty-block>
</ng-template>
