import { Year } from '@domain/lib';
import { Facility } from '@domain/facility';
import { Document } from '@domain/document';

export interface Funding extends Document {
  date: Date;

  accountingYear: Year;

  readonly year: Year;

  facilityId: Facility['id'];
}
