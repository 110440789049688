import {Media} from "@domain/media";
import {MediaTransfert} from "@domain/media/media-transfert";
import {Observable, Subscription} from "rxjs";

export class MediaTransfertImpl<T = Media | Media[]> implements MediaTransfert<T> {

  public state: "pending" | "complete" | "error" = 'pending';

  private progress: number = 0;

  private subscription: Subscription;

  constructor(private transfert: Observable<{ progress: number, result?: T }>) {


    this.subscription = this.transfert.subscribe(
      (value) => {
        if (value.progress !== this.progress) {
          this.progress = value.progress;
          this._onProgress(this.progress);
        }

        if (value.result) {
          this._onComplete(value.result);
          this.state = 'complete';
        }
      },
      (error) => {
        this.state = 'error';
        this._onError(error);
      },
      () => {
        this.state = 'complete';
      }
    );
  }

  public onComplete(fn: (media: T) => any): any {
    this._onComplete = fn;
  }

  public onError(fn: (e: any) => any): any {

    this._onError = fn;
  }

  public onProgress(fn: (progress: number) => any): any {
    this._onProgress = fn;
  }


  private _onProgress = (e: any) => {
  };

  private _onError = (e: any) => {
  };

  private _onComplete = (media: T) => {

  }

}
