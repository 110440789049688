import { CreateNonRecurringHealthFundingDto } from '@application/bundles/health-funding';
import { IsOptional, IsString } from 'class-validator';
import { isValidFundingAmount } from '@domain/funding';
import { IsFundingAmount } from '@implementations/bundles/funding/validators';
import { NonRecurringTaxonomyTerm } from '@domain/health-funding';
import { isUUID } from '@domain/lib';
import { IsHealthTaxonomyTermId } from '@implementations/bundles/health-funding/validators';

export class CreateNonRecurringHealthFundingDtoImpl implements CreateNonRecurringHealthFundingDto {
  @IsFundingAmount()
  public amount!: number;

  @IsOptional()
  @IsString()
  public comment: string | undefined;

  @IsOptional()
  @IsHealthTaxonomyTermId('nonRecurring')
  public categoryId: NonRecurringTaxonomyTerm['id'] | undefined;

  constructor(values?: Partial<CreateNonRecurringHealthFundingDto>) {
    if (values) {
      if (isValidFundingAmount(values.amount)) {
        this.amount = values.amount;
      }

      if (typeof values.comment === 'string' || values.comment === undefined) {
        this.comment = values.comment;
      }

      if (isUUID(values.categoryId)) {
        this.categoryId = values.categoryId;
      }
    }
  }
}
