import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { QueryBus } from '@application/framework/command-query';
import { Facility } from '@domain/facility';
import { NationalPMPIndicator } from '@domain/pmp';
import { DateFormatter } from '@application/framework/date';
import { PMPUrlsProvider } from '@easyhpad-ui/app/bundles/pmp';
import { differenceInPercentage } from '@application/framework/lib';
import { GetLatestNationalPMPIndicatorQuery } from '@application/bundles/pmp/queries/get-latest-national-pmp-indicator.query';
import { TranslatableString } from '@application/framework/translation';
import { PMP_TRANSLATE_CONTEXT } from '@application/bundles/pmp';
import { isPositive } from 'class-validator';
import { GetPmpWidgetContentQuery } from '@easyhpad-ui/app/bundles/dashboard/queries';
import { PMPWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';

@Component({
  selector: 'ehp-pmp-average',
  templateUrl: './pmp-average.component.html',
  styleUrls: ['./pmp-average.component.scss'],
})
export class PmpAverageComponent implements OnInit, OnChanges {
  @Input() public facilityIds: Array<Facility['id']> | undefined = [];

  public value: number = 0;

  public date: string | undefined;

  public url: string | undefined;

  public average: { total: number; value: number } | undefined;

  public national: NationalPMPIndicator | undefined;

  public difference: number | undefined;

  public differenceMessage: TranslatableString | undefined;

  public isPositiveDifference: boolean | undefined;

  constructor(
    private readonly queryBus: QueryBus,
    private readonly dateFormatter: DateFormatter,
    private readonly urls: PMPUrlsProvider,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('facilityIds' in changes) {
      this.loadContent();
    }
  }

  public ngOnInit(): void {
    this.loadNationalPMPIndicator();
    this.loadContent();
  }

  private loadNationalPMPIndicator(): void {
    this.queryBus
      .request<NationalPMPIndicator | undefined>(new GetLatestNationalPMPIndicatorQuery())
      .then(indicator => (this.national = indicator));
  }

  private loadContent(): void {
    const facilityIds = [...(this.facilityIds ?? [])];

    this.queryBus
      .request<PMPWidgetContent>(new GetPmpWidgetContentQuery(facilityIds))
      .then(response => this.updateValues(response));
  }

  private async updateValues(content: PMPWidgetContent) {
    this.value = content.value ?? 0;
    this.computeDifferenceWithNational();

    this.date = content.date ? this.dateFormatter.format(content.date, 'dd/MM/yyyy') : undefined;
    this.url = content.pmpId ? await this.urls.getPublicShow(content.pmpId) : undefined;

    this.average = undefined;

    if (content.average) {
      this.average = {
        value: content.average.value,
        total: content.average.total,
      };
    }
  }

  private computeDifferenceWithNational() {
    this.difference = undefined;
    this.differenceMessage = undefined;

    if (!this.national || !this.value) {
      return;
    }

    this.difference = differenceInPercentage(this.value, this.national.value);
    let message = 'Plus haut que la moyenne nationale';

    if (this.difference <= 0) {
      message = 'Plus bas que la moyenne nationale';
    }

    this.differenceMessage = new TranslatableString(message, {}, PMP_TRANSLATE_CONTEXT);
    this.isPositiveDifference = isPositive(this.difference);
  }
}
