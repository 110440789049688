import { DateString } from '@application/framework/date';
import { UUID } from '@domain/lib';
import { EasyFacility } from '@implementations/bundles/backend/easyhpad/interfaces';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';

export interface EasyGMP {
  id: UUID;

  pvId: EasyMedia['id'];

  facilityId: EasyFacility['id'];

  validationDate: DateString;

  value: number;

  gir1?: number | null;

  gir2?: number | null;

  gir3?: number | null;

  gir4?: number | null;

  gir5?: number | null;

  gir6?: number | null;

  createdAt?: DateString;

  updatedAt?: DateString;
}

export interface EasyGMPCreateDto extends Omit<EasyGMP, 'id'> {}

export interface EasyGMPUpdateDto extends Omit<EasyGMP, 'id' | 'pvId'> {
  pvId?: EasyMedia['id'];
}
