import { ETP_CATEGORIES } from '@domain/etp/etp-categories';

export interface EasyTPER {
  category: ETP_CATEGORIES;

  etp: number;

  amount: number;

  comment?: string;
}
