import { GMPRepository } from '@application/bundles/gmp';
import { GetLatestGMPQuery } from '@application/bundles/gmp/queries/get-latest-gmp.query';
import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GMP } from '@domain/gmp';

@HandleQuery({
  query: GetLatestGMPQuery,
})
export class GetCurrentGMPQueryHandler implements QueryHandler<GetLatestGMPQuery, GMP | undefined> {
  constructor(private readonly repository: GMPRepository) {}

  public handle(query: GetLatestGMPQuery): Promise<GMP | undefined> {
    return this.repository.getLatestForFacility(query.facilityId);
  }
}
