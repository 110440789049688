import {AddCapacityAuthorizationDto} from "@application/bundles/capacity-authorization/dtos";
import {
  UpdateCapacityAuthorizationDto
} from "@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto";
import {
  ListCapacityAuthorizationParams
} from "@application/bundles/capacity-authorization/list-capacity-authorization-params";
import {CapacityAuthorization} from "@domain/capacity-authorization/capacity-authorization";

export abstract class CapacityAuthorizationRepository {

  public abstract list(params?: ListCapacityAuthorizationParams): Promise<CapacityAuthorization[]>;

  public abstract get(id: CapacityAuthorization['id']): Promise<CapacityAuthorization>;

  public abstract create(authorization: AddCapacityAuthorizationDto): Promise<CapacityAuthorization>;

  public abstract update(id: CapacityAuthorization['id'], update: Partial<UpdateCapacityAuthorizationDto>): Promise<CapacityAuthorization>;

  public abstract delete(id: CapacityAuthorization['id']): Promise<CapacityAuthorization>;
}
