import {ListUsersQuery} from "@application/bundles/user/queries/list-users.query";
import {HandleQuery} from "@application/framework/command-query";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {Provide} from "@application/framework/di";
import {UserRepository} from "@domain/user";
import {AgnosticUserSearchParams} from "@application/bundles/user/implementations";

@HandleQuery({
  query: ListUsersQuery
})
export class ListUserHandler implements QueryHandler<ListUsersQuery> {

  @Provide()
  private readonly userRepository!: UserRepository;

  public handle(query: ListUsersQuery): Promise<any> {
    return this.userRepository.list(new AgnosticUserSearchParams().deserialize(query.params));
  }

}
