export interface GroupedGIR<T> {
  /**
   * Gir 1 & GIR 2
   */
  firstGIRGroup: T;

  /**
   * Gir 3 & GIR 4
   */
  secondGIRGroup: T;

  /**
   * Gir 5 & GIR 6
   */
  thirdGIRGroup: T;
}

export interface GroupedGIRAmount extends GroupedGIR<number> {}

export type GroupedGirAmountAndResidents = GroupedGIR<{ amount: number; residents: number | undefined }>;
