import {ApplicationComponents} from "@application/bundles/application";
import {ContainerRegistry, DependenciesInjectionConfiguration} from "@application/framework/di";
import {NullContainer} from "@application/framework/di/containers/null-container";
import {EventSubscribersRegistry} from "@application/framework/event/event-subscribers.registry";
import {PrioritizedSubscriberRegistry} from "@application/framework/event/implementations";
import {Logger} from "@application/framework/logger";
import {NullLogger} from "@application/framework/logger/implementations/null.logger";


const defaultComponents: ApplicationComponents = {
  eventSubscriberRegistry: {useClass: PrioritizedSubscriberRegistry},
  defaultContainer: new NullContainer('Null container'),
  logger: {useValue: new NullLogger()}

}

export function registerApplicationComponents(components: Partial<ApplicationComponents>) {
  components = {
    ...defaultComponents,
    ...components
  }

  if (components.defaultContainer) {
    ContainerRegistry.defaultContainer = components.defaultContainer;
  }

  if (components.provideDecorator) {
    DependenciesInjectionConfiguration.setProvideDecoratorAdapter(components.provideDecorator);
  }

  if (components.serviceDecorator) {
    DependenciesInjectionConfiguration.setServiceDecoratorAdapter(components.serviceDecorator);
  }

  if (components.logger) {
    ContainerRegistry.defaultContainer.set({...components.logger, id: Logger})
  }

  if (components.logger) {
    ContainerRegistry.defaultContainer.set({...components.logger, id: EventSubscribersRegistry})
  }
}
