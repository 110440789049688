import { EasyCRPP } from './easy-crpp';
import { EasyEPRD } from '@implementations/bundles/backend/easyhpad';
import { EasyFinancialDetail } from '@implementations/bundles/backend/easyhpad/interfaces/eprd-errd/easy-financial-detail';
import { Facility } from '@domain/facility';
import { UUID } from '@domain/lib';

export interface EasyLocalEPRD extends EasyFinancialDetail {
  id: UUID;

  facilityId: Facility['id'];

  parentId: EasyEPRD['id'];

  revenueAmount: number | null | undefined;

  chargeAmount: number | null | undefined;

  cafCapacity: number | null | undefined;

  crpp: EasyCRPP[];

  occupationRate?: number | null | undefined;

  actualDaysRealized?: number | null | undefined;
}

export interface CreateEasyLocalEPRD extends Omit<EasyLocalEPRD, 'id' | 'parentId'> {}

export interface UpdateEasyLocalEPRD extends Omit<EasyLocalEPRD, 'parentId'> {}
