import {LoginCommand} from "@application/bundles/authentification/commands/login.command";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {Authenticator, AuthentificationStrategy} from "@application/bundles/authentification/abstraction";

@HandleCommand({
  command: LoginCommand
})
export class LoginCommandHandler implements CommandHandler<LoginCommand<any>, void> {

  constructor(private readonly authenticator: Authenticator) {
  }

  public handle(command: LoginCommand<any>): Promise<void> {
    return this.execute(command.strategy, command.credentials);
  }

  public async execute<S extends Pick<AuthentificationStrategy, 'login' | 'buildToken'>, C extends any>(strategy: S, credentials?: C): Promise<any> {
    return this.authenticator.withStrategy(strategy).connect(credentials ?? {})
      .then((response) => response);
  }
}
