import {ERRDRepository, GetERRDQuery} from "@application/bundles/eprd-errd";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {ERRD} from "@domain/eprd-errd";

@HandleQuery({
  query: GetERRDQuery
})
export class GetERRDQueryHandler implements QueryHandler<GetERRDQuery, ERRD> {

  constructor(private readonly repository: ERRDRepository) {
  }

  public async handle(query: GetERRDQuery): Promise<ERRD> {
    return this.repository.get(query.id);
  }
}
