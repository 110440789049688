import {CreateEhpadDemainHealthFundingDto} from "@application/bundles/health-funding";
import {isValidObject} from "@application/framework/lib";
import {isValidFundingAmount} from "@domain/funding";
import {isValidEhpadDemainIdType} from "@application/bundles/ehpad-demain";
import {IsOptional, IsString} from "class-validator";
import {EhpadDemain} from "@domain/ehpad-demain";
import {IsFundingAmount} from "@implementations/bundles/funding/validators";
import {IsValidEhpadDemainId} from "@implementations/bundles/ehpad-demain/validators";

export class CreateEhpadDemainHealthFundingDtoImpl implements CreateEhpadDemainHealthFundingDto {

  @IsString()
  public name!: string;

  @IsFundingAmount()
  public amount!: number;

  @IsValidEhpadDemainId()
  @IsOptional()
  public ehpadDemainId: EhpadDemain["id"] | undefined;


  constructor(defaults?: Partial<CreateEhpadDemainHealthFundingDto>) {
    if (isValidObject(defaults)) {

      if (typeof defaults?.name === 'string') {
        this.name = defaults.name;
      }

      if (isValidFundingAmount(defaults?.amount)) {
        this.amount = defaults?.amount;
      }

      if (isValidEhpadDemainIdType(defaults?.ehpadDemainId)) {
        this.ehpadDemainId = defaults?.ehpadDemainId;
      }
    }
  }
}
