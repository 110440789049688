import {EventSubscribersRegistry} from "@application/framework/event/event-subscribers.registry";
import {DispatchableEvent, EventSubscriber} from './event.type';

export abstract class EventDispatcher {


  /**
   * Get associated registry
   */
  public abstract readonly registry: EventSubscribersRegistry;


  /**
   * Verify if subscriber listen event
   * @param event
   * @param subscriber
   * @return boolean
   */
  public abstract has<T extends DispatchableEvent>(event: DispatchableEvent, subscriber: EventSubscriber<T>): boolean;

  /**
   * Dispatch event
   * @param event
   */
  public abstract dispatch<T extends DispatchableEvent>(event: T): void;

}

