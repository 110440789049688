import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {CapacityAuthorization} from "@domain/capacity-authorization";

export class CapacityAuthorizationDeletedEvent extends AbstractDispatchableEvent implements DispatchableEvent {


  constructor(public readonly authorization: CapacityAuthorization) {
    super();
  }
}
