import { Component, ComponentRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  DynamicAbstractControlField,
  DynamicMediaField,
  HasDynamicField,
} from '@easyhpad-ui/app/library/form/contracts';
import { FormControl, Validators } from '@angular/forms';
import { MediaUploaderComponent } from '@easyhpad-ui/app/library/form/components/media-uploader/media-uploader.component';
import { FILE_TYPES, isValidMediaIdType } from '@application/bundles/media';
import { isLocalMedia, isMedia, isMediaRef, LocalMedia, Media, MediaRef } from '@domain/media';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ehp-dynamic-media-field',
  templateUrl: './dynamic-media-field.component.html',
  styleUrl: './dynamic-media-field.component.scss',
})
export class DynamicMediaFieldComponent implements HasDynamicField<DynamicMediaField>, OnInit, OnDestroy {
  @Input() public readonly field!: DynamicAbstractControlField<DynamicMediaField>;

  @ViewChild(MediaUploaderComponent, { static: true, read: ComponentRef }) public uploader!: ViewContainerRef;

  public min = 0;

  public max = 1;

  public allowedFileTypes: FILE_TYPES[] | undefined;

  public media: Array<Media | LocalMedia | MediaRef> = [];

  public hideFileList = false;

  private value: LocalMedia | MediaRef | null = null;

  private destroy$ = new Subject<void>();

  public get required(): boolean {
    return !!(this.control && this.control.hasValidator(Validators.required));
  }

  /**
   * Parent control
   * @private
   */
  private get control(): FormControl<any> | null {
    if (!this.field || !this.field.control) {
      return null;
    }

    return this.field.control as FormControl;
  }

  constructor() {}

  /**
   * @inheritDoc
   */
  public ngOnInit() {
    this.allowedFileTypes = this.field.fileTypes;

    if (this.field.min !== undefined && Number.isSafeInteger(this.field.min)) {
      this.min = this.field.min;
    }

    if (this.field.hideFileList === true) {
      this.hideFileList = true;
    }

    if (this.field.initialValue) {
      this.field.initialValue.pipe(takeUntil(this.destroy$)).subscribe(value => this.setInitialValue(value));
    }

    if (this.control) {
      this.control.valueChanges.subscribe(changes => this.controlValueChange(changes));
    }
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Update control value & emit the new media list in changes.
   * @param medias
   */
  public propagateChanges(medias: Array<LocalMedia | Media>) {
    this.control?.setValue(medias[0]);
    this.emitChanges(medias);
  }

  /**
   * Emit the removed media
   * @param media
   */
  public emitRemove(media: LocalMedia | Media) {
    if (typeof this.field.onRemove === 'function') {
      this.field.onRemove(media);
    }
  }

  /**
   * Emit the new media list
   * @param medias
   */
  public emitChanges(medias: Array<LocalMedia | Media>): void {
    if (typeof this.field.onChange === 'function') {
      this.field.onChange(medias);
    }
  }

  /**
   * Based on control value, create the media list.
   * @param value
   * @private
   */
  private controlValueChange(value: Media['id'] | Media | MediaRef | null | undefined) {
    if (isLocalMedia(this.value) && isLocalMedia(value) && this.value !== value) {
      this.value = value;
    }

    if (isMedia(value) || isLocalMedia(value) || isMediaRef(value)) {
      this.media = [value];
      return;
    }

    if (isValidMediaIdType(value)) {
      this.media = [{ isRef: true, id: value }];
      return;
    }

    if (!Array.isArray(this.media) || this.media.length > 0) {
      this.media = [];
    }
  }

  /**
   * Call `controlValueChange` method
   * @param value
   * @private
   */
  private setInitialValue(value: Media['id'] | undefined) {
    if (!this.control || this.control.touched || this.control.dirty) {
      return;
    }

    this.controlValueChange(value);
  }
}
