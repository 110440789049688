<ng-container [formGroup]="dates">

  <ehp-form-field>
    <label for="{{ id }}">{{'Date de début' | ehp_translate }}</label>
    <input [attr.readonly]="options?.startReadonly" [id]="id" ehp-form-control formControlName="start" type="date">
  </ehp-form-field>

  <ehp-form-field>
    <label for="end">{{'Date de fin' | ehp_translate }}</label>
    <input [attr.readonly]="options?.endReadonly" ehp-form-control formControlName="end" id="end" type="date">
  </ehp-form-field>


</ng-container>
