<header class="dialog-header">
  <h2 class="title">{{'Suppression définitive' | ehp_translate}}</h2>
</header>

<div class="dialog-content">

  <ng-container *ngIf="data.options && data.options.length > 1; else singleItem">
    {{ 'Vous êtes sur le point de supprimer définitivement les éléments suivants'|ehp_translate }} : <br/>
  </ng-container>

  <ng-template #singleItem>
    {{ 'Vous êtes sur le point de supprimer définitivement l\'élément suivant'|ehp_translate }} : <br/>
  </ng-template>


  <ul *ngIf="data.options" class="">
    <li *ngFor="let option of data.options">
      <ehp-translatable-string [value]="option.label"></ehp-translatable-string>
    </li>
  </ul>

</div>

<div class="dialog-actions">
  <button (click)="confirm()" class="delete" type="button">{{'Supprimer définitivement'| ehp_translate}}</button>
  <button (click)="cancel()" class="ghost" type="button">{{'Annuler'| ehp_translate}}</button>
</div>

