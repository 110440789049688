import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {
  DraggableHandleDirective
} from "@easyhpad-ui/app/library/draggable/directives/draggable-handle/draggable-handle.directive";
import {fromEvent, Observable, Subscription, takeUntil} from "rxjs";
import {WindowRef} from "@easyhpad-ui/app/framework/window";


type BourdaryConstaint = { x: { min: number, max: number }, y: { min: number, max: number } };

@Directive({
  selector: '[ehpDraggable]',
  standalone: true,
  host: {
    'class': 'draggable-element'
  }
})
export class DraggableDirective implements OnDestroy, AfterViewInit {

  @Input() boundarySelector!: string;

  @ViewChild(DraggableHandleDirective) public handler!: DraggableHandleDirective;

  public handlerElement!: HTMLElement;

  public draggingBoundaryElement!: HTMLElement | HTMLBodyElement;

  private readonly DEFAULT_DRAGGING_BOUNDARY_QUERY = "body";

  private element!: HTMLElement;

  private position = {
    initial: {x: 0, y: 0},
    current: {x: 0, y: 0}
  }

  private boundaryConstraints: BourdaryConstaint = {
    x: {min: 0, max: 0},
    y: {min: 0, max: 0}
  }

  private subscriptions: Subscription[] = [];

  private dragStartSource$!: Observable<MouseEvent>;

  private dragEndSource$!: Observable<MouseEvent>;

  private dragSource$!: Observable<MouseEvent>;

  private onDragStartSubscription: Subscription | undefined;

  private onDragSubscription: Subscription | undefined;


  constructor(
    private readonly elementRef: ElementRef,
    private readonly windowRef: WindowRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    /**
     * Set default boundary selector
     */
    this.boundarySelector = this.DEFAULT_DRAGGING_BOUNDARY_QUERY;

    /**
     * Define draggable element
     */
    this.element = this.elementRef.nativeElement as HTMLElement;
    
    /**
     * Define initial boundary element and compute boundary constraint for drag
     */
    this.setBoundaryElement(this.boundarySelector);

    /**
     * Create drag and drag end sources.
     */
    this.defineDragSources();

    /**
     * Set current element as drag launcher.
     */
    this.setDragHandler(this.element);


    /**
     * Compute new boundary constraint if window resize.
     */
    const window = this.windowRef.nativeWindow;

    if ('addEventListener' in window) {
      window.addEventListener('resize', () => window.requestAnimationFrame(this.boundaryElementResized.bind(this)));
    }
  }

  public ngAfterViewInit(): void {

    /**
     * If selector has changed, update boundary element.
     */
    this.setBoundaryElement(this.boundarySelector);

    /**
     * Update handler if provided in view
     */
    if (this.handler?.elementRef?.nativeElement) {
      this.setDragHandler(this.handler?.elementRef?.nativeElement);
    }

  }

  public ngOnDestroy(): void {

    if (this.onDragSubscription) {
      this.onDragSubscription.unsubscribe();
    }

    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  public moveTo(x: 'left' | 'right' | 'center', y: 'top' | 'bottom' | 'center') {

    // Run first change detection if is needed
    if (!this.element) {
      this.changeDetectorRef.detectChanges();
    }

    const window = this.windowRef.nativeWindow as Window;

    const move = {x: 0, y: 0};

    if ('innerWidth' in window && 'innerHeight' in window) {

      switch (x) {
        case "right":
          move.x = window.innerWidth;
          break
        case "center":
          move.x = window.innerWidth / 2;
      }

      switch (y) {
        case "bottom":
          move.y = window.innerHeight;
          break;
        case "center":
          move.y = window.innerHeight;
      }

      this.moveToPosition(move.x, move.y);
    }
  }

  public setDragHandler(handler: HTMLElement): void {

    if (this.handlerElement) {
      this.handlerElement.classList.remove('is-drag-handler');
    }

    this.handlerElement = handler || this.element;

    if (!this.handlerElement) {
      return;
    }

    this.handlerElement.classList.add('is-drag-handler');

    if (this.onDragStartSubscription) {
      this.onDragStartSubscription.unsubscribe();
    }

    this.dragStartSource$ = fromEvent<MouseEvent>(this.handlerElement, "mousedown");
    this.onDragStartSubscription = this.dragStartSource$.subscribe((event: MouseEvent) => this.onStart(event));

  }

  private defineDragSources(): void {
    this.dragEndSource$ = fromEvent<MouseEvent>(this.document, "mouseup");

    this.dragSource$ = fromEvent<MouseEvent>(this.document, "mousemove")
      .pipe(takeUntil(this.dragEndSource$));

    this.subscriptions.push(this.dragEndSource$.subscribe(() => this.onEnd()));

  }

  private onStart(event: MouseEvent): void {
    this.position.initial.x = event.clientX - this.position.current.x;
    this.position.initial.y = event.clientY - this.position.current.y;

    this.element.classList.add('grabbed');

    this.onDragSubscription = this.dragSource$.subscribe((event: MouseEvent) => this.onDrag(event));
  }

  private onDrag(event: MouseEvent): void {

    event.preventDefault();
    this.element.classList.add("move");

    const x = event.clientX - this.position.initial.x;
    const y = event.clientY - this.position.initial.y;

    this.moveToPosition(x, y);
  }

  private onEnd(): void {

    this.element.classList.remove('move', 'grabbed');

    this.setCurrentPositionAsInitial();

    if (this.onDragSubscription) {
      this.onDragSubscription.unsubscribe();
      this.onDragSubscription = undefined;
    }
  }

  private setBoundaryElement(selector: string): void {
    const element = this.document.querySelector<HTMLElement | HTMLBodyElement>(selector);

    if (!element) {
      throw new Error("Couldn't find any element with query: " + this.boundarySelector);
    }

    if (this.draggingBoundaryElement !== element) {
      this.draggingBoundaryElement = element;
    }

    this.updateBoundaryConstraints();
  }

  private boundaryElementResized(): void {
    this.updateBoundaryConstraints();

    if (this.position.current.x > this.boundaryConstraints.x.max) {
      this.moveToPosition(this.boundaryConstraints.x.max, this.position.current.y);
    }

    if (this.position.current.y > this.boundaryConstraints.y.max) {
      this.moveToPosition(this.position.current.x, this.boundaryConstraints.y.max);
    }
  }

  private updateBoundaryConstraints(): void {
    this.boundaryConstraints = this.getBoundConstraints();
  }

  private getBoundConstraints(): BourdaryConstaint {

    const minBoundX = this.draggingBoundaryElement.offsetLeft;
    const minBoundY = this.draggingBoundaryElement.offsetTop;

    return {
      x: {
        min: minBoundX,
        max: minBoundX + this.draggingBoundaryElement.offsetWidth - this.element.offsetWidth
      },
      y: {
        min: minBoundY,
        max: minBoundY + this.draggingBoundaryElement.offsetHeight - this.element.offsetHeight
      }
    }
  }

  private moveToPosition(x: number, y: number, updateInitial: boolean = false) {
    this.position.current.x = Math.max(this.boundaryConstraints.x.min, Math.min(x, this.boundaryConstraints.x.max));
    this.position.current.y = Math.max(this.boundaryConstraints.y.min, Math.min(y, this.boundaryConstraints.y.max));

    this.element.style.transform = "translate3d(" + this.position.current.x + "px, " + this.position.current.y + "px, 0)";

    if (updateInitial) {
      this.setCurrentPositionAsInitial();
    }
  }

  private setCurrentPositionAsInitial() {
    this.position.initial.x = this.position.current.x;
    this.position.initial.y = this.position.current.y;
  }
}
