import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HealthFunding} from "@domain/health-funding";

@Component({
  selector: 'ehp-heath-funding-funding-tabs',
  templateUrl: './heath-funding-funding-tabs.component.html',
  styleUrls: ['./heath-funding-funding-tabs.component.scss']
})
export class HeathFundingFundingTabsComponent implements OnChanges {


  @Input() public healthFunding!: HealthFunding;
  
  public fundingTabsDisplay = true;

  public fundingTabIndex: any;

  public ngOnChanges(changes: SimpleChanges) {

    if (changes['healthFunding']) {
      this.healthFundingHasChange();
    }
  }

  private healthFundingHasChange() {
    this.fundingTabsDisplay = false;


    if (this.healthFunding) {

      if (
        this.healthFunding.recurringFunding.size > 0
        || this.healthFunding.nonRecurringFunding.size > 0
        || this.healthFunding.exceptionalFunding.size > 0
      ) {
        this.fundingTabsDisplay = true;
      }

    }


  }
}
