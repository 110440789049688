import {ERRDRepository, ERRDSearchParams, SerializedERRDSearchParams} from "@application/bundles/eprd-errd";
import {Provide} from "@application/framework/di";
import {
  EasyERRDTransformer
} from "@implementations/bundles/backend/easyhpad/transformers/errd-errd/easy-errd.transformer";
import {DocumentRepository} from "@implementations/bundles/backend/easyhpad/repositories/base";
import {ERRD} from "@domain/eprd-errd";
import {EasyERRD} from "@implementations/bundles/backend/easyhpad";

export class EasyERRDRepository extends DocumentRepository<ERRDSearchParams, SerializedERRDSearchParams, ERRD, EasyERRD> implements ERRDRepository {

  @Provide() private readonly entityTransformer!: EasyERRDTransformer;

  constructor() {
    super('errd', {
      from: (input) => this.entityTransformer.transform(input),
      to: (output) => this.entityTransformer.reverseTransform(output),
      create: (input) => this.entityTransformer.createTransform(input),
      update: (input) => this.entityTransformer.updateTransform(input)
    });
  }
}
