import { LocalEPRD } from '@domain/eprd-errd/local-eprd';
import { Media } from '@domain/media';
import { UUID } from '@domain/lib';

export interface EPRD {
  id: UUID;

  accountingYear: number;

  depositDate?: Date | null;

  readonly isSingle: boolean;

  readonly singleChild: LocalEPRD | undefined;

  completeDocumentId: Media['id'];

  payrollDocumentId: Media['id'] | undefined;

  financialAnnexDocumentId: Media['id'] | undefined;

  activityAnnexDocumentId: Media['id'] | undefined;

  reportId: Media['id'] | undefined;

  details: LocalEPRD[];

  completeDocument(): Promise<Media>;

  payrollDocument(): Promise<Media | undefined>;

  financialAnnexDocument(): Promise<Media | undefined>;

  activityAnnexDocument(): Promise<Media | undefined>;

  reportDocument(): Promise<Media | undefined>;
}
