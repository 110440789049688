import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { DisconnectSuccessEvent } from '@application/bundles/authentification/events/disconnect-success.event';
import { Store } from '@ngrx/store';
import { CustomerActions } from '@easyhpad-ui/app/bundles/customer/store';
import { DisconnectFailEvent } from '@application/bundles/authentification/events/disconnect-fail.event';
import { CurrentCustomerChangeEvent } from '@application/bundles/customer/events/current-customer/current-customer-change.event';
import { AppState } from '@easyhpad-ui/app/store';
import { selectCustomerCurrentSelected } from '@easyhpad-ui/app/bundles/customer/store/customer.selector';
import { take } from 'rxjs';
import { CreateCustomerSuccessEvent } from '@application/bundles/customer/events/create-customer/create-customer-success.event';
import { CustomerUpdatedSuccessEvent } from '@application/bundles/customer/events/customer-updated/customer-updated-success.event';
import { CustomerDeletedEvent } from '@application/bundles/customer/events/delete-customer/customer-deleted.event';

@HasEventSubscribers()
export class CustomerStoreUpdateSubscriber {
  constructor(private readonly store: Store<AppState>) {}

  @OnEvent({ event: [DisconnectSuccessEvent, DisconnectFailEvent] })
  private resetCustomerStore(): void {
    this.store.dispatch(CustomerActions.resetCustomerStore());
  }

  @OnEvent({ event: CurrentCustomerChangeEvent })
  private setCurrentCustomerInStore(event: CurrentCustomerChangeEvent): void {
    this.store
      .select(selectCustomerCurrentSelected)
      .pipe(take(1))
      .subscribe(current => {
        if (current !== event.customer) {
          this.store.dispatch(CustomerActions.setCurrentCustomer({ customer: event.customer }));
        }
      });
  }

  @OnEvent({ event: [CreateCustomerSuccessEvent, CustomerUpdatedSuccessEvent, CustomerDeletedEvent] })
  private reloadAvailableCustomer(): void {
    this.store.dispatch(CustomerActions.loadAvailableCustomers());
  }
}
