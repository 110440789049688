import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {ERROR_STORE_STATE, ErrorEffects, errorReducer} from "./store";
import {EffectsModule} from "@ngrx/effects";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(ERROR_STORE_STATE, errorReducer),
    EffectsModule.forFeature([ErrorEffects])
  ]
})
export class ErrorsModule {
}
