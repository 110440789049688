<ng-container *ngIf="authentificationChecked; else waiting">
  <ehp-top-bar *ngIf="displayTopBar$ | async" class="top-bar"></ehp-top-bar>
  <ehp-menu-sidebar *ngIf="displayPrimaryMenu$ | async" class="sidebar"></ehp-menu-sidebar>

  <main class="main-content-container">
    <router-outlet></router-outlet>
  </main>

</ng-container>

<ehp-toast class="toast-container"></ehp-toast>

<ng-template #waiting>
  <div class="loading">
    <span class="loader"></span>
    {{'Chargement'}}
  </div>
</ng-template>
