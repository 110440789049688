import { FundingCollection, SubFunding } from '@domain/funding';

export class AgnosticFundingCollection<T extends SubFunding> implements FundingCollection<T> {
  private funding: T[];

  public get size(): number {
    return this.funding.length;
  }

  constructor(funding: T[]) {
    this.funding = funding;
  }

  public sum(): number {
    return this.funding.reduce((total, funding) => total + funding.amount, 0);
  }

  public sort(compareFn: (a: T, b: T) => number): void {
    this.funding.sort(compareFn);
  }

  public values(): Iterator<T> {
    return this[Symbol.iterator]();
  }

  public *[Symbol.iterator](): Iterator<T> {
    for (const funding of this.funding) {
      yield funding;
    }
  }

  public toArray(): Array<T> {
    return Array.from(this.funding);
  }
}
