import {isPercentage, Year} from "@domain/lib";
import {PartialActivityAnnex} from "@domain/eprd-errd";

export function isValidAnnexActivityDaysCount(value: any, year?: Year): value is number {
  return typeof value === 'number'
    && !isNaN(value)
    && value >= 0
  /* && (
     (!isValidYear(year) && value <= 366)
     || (isValidYear(year) && isLeapYear(year) && value <= 366)
     || (isValidYear(year) && !isLeapYear(year) && value <= 365)
   );*/
}


export function isPartialActivityAnnex(obj: any): obj is PartialActivityAnnex {
  return typeof obj === 'object'
    && obj !== null
    && (typeof obj.days === 'undefined' || isValidAnnexActivityDaysCount(obj.days))
    && (typeof obj.occupationRate === 'undefined' || isPercentage(obj.occupationRate));
}
