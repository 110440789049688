import { Component, OnDestroy } from '@angular/core';
import { QueryBus } from '@application/framework/command-query';
import { GetFullDataTableWidgetQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-full-data-table-widget.query';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { Subscription } from 'rxjs';
import { DASHBOARD_SELECTORS } from '@easyhpad-ui/app/bundles/dashboard/store';
import { Facility } from '@domain/facility';
import {
  FullDataTableWidgetContent,
  FullDataTableWidgetContentRow,
} from '@easyhpad-ui/app/bundles/dashboard/interfaces/widgets/full-data-table-widget';
import { isValidYear } from '@domain/lib';
import { Filter, FilterDefinition } from '@easyhpad-ui/app/library/filter/interfaces';
import { createDateFilterDefinition, createNumberFilterDefinition } from '@easyhpad-ui/app/library/filter/functions';

@Component({
  selector: 'ehp-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
})
export class DashboardTableComponent implements OnDestroy {
  public rows: FullDataTableWidgetContentRow[] = [];

  public displayableRows = 50;

  public columns: Array<{ property: keyof FullDataTableWidgetContentRow; label: string }> = [];

  public filters: Array<Filter<keyof FullDataTableWidgetContentRow>> = [];

  public filterDefinitions: Array<FilterDefinition>;

  public filtersValidity = false;

  public sidebarVisible = false;

  private facilities: Array<Facility['id']> = [];

  private subscription: Subscription;

  constructor(
    private readonly queryBus: QueryBus,
    private readonly store: Store<AppState>,
  ) {
    this.filterDefinitions = this.buildFilterDefinitions();
    this.defineColumns();

    this.subscription = this.store.select(DASHBOARD_SELECTORS.selectDashboardFacilities).subscribe(facilities => {
      this.facilities = facilities;
      this.updateData();
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public isNumber(value: any) {
    return typeof value === 'number';
  }

  public isNegative(value: number): boolean {
    return value < 0;
  }

  public submit(): void {}

  public openFilters(): void {
    this.sidebarVisible = true;
    return;
  }

  public updateFiltersValidity(isValid: boolean): void {
    this.filtersValidity = isValid;
  }

  public filtersChange(changes: Array<Filter<keyof FullDataTableWidgetContentRow>>) {
    this.filters = changes;
  }

  public applyFilters() {
    this.sidebarVisible = false;
    this.updateData();
  }

  private updateData(): void {
    this.queryBus
      .request<FullDataTableWidgetContent>(new GetFullDataTableWidgetQuery(this.facilities, this.filters))
      .then(data => {
        this.defineColumns(data.configuration);
        this.rows = this.addEmptyRows(data.results);
      });
  }

  private addEmptyRows(rows: FullDataTableWidgetContentRow[]): FullDataTableWidgetContentRow[] {
    rows = [...rows];

    const diff = this.displayableRows - rows.length;

    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        const row: FullDataTableWidgetContentRow = {
          authorizedCapacity: null,
          accommodationFundingAmount: null,
          bedSocialCareCapacity: undefined,
          capacityAuthorizationId: null,
          cpomDetailId: null,
          cpomEndDate: null,
          cpomId: null,
          cpomOption: null,
          daycareCapacity: null,
          dependencyFundingAmount: undefined,
          gmpId: null,
          gmpValue: null,
          healthFundingAmount: null,
          id: '',
          name: '',
          occupationRate: null,
          pasaCapacity: undefined,
          permanentCapacity: null,
          pmpId: null,
          pmpValue: null,
          supportCapacity: null,
          temporaryCapacity: null,
          uhrCapacity: undefined,
          eprdAmount: null,
          errdAmount: null,
          errdCAFCapacity: null,
          eprdCAFCapacity: null,
        };

        rows.push(row);
      }
    }

    return rows;
  }

  private defineColumns(configuration?: FullDataTableWidgetContent['configuration']): void {
    this.columns = [
      { property: 'name', label: 'Établissement' },
      { property: 'permanentCapacity', label: 'Hébergement permanent' },
      { property: 'temporaryCapacity', label: 'Hébergement temporaire' },
      { property: 'daycareCapacity', label: 'Accueil de jour' },
      { property: 'occupationRate', label: "Taux d'occupation" },
      { property: 'pasaCapacity', label: 'P.A.S.A.' },
      { property: 'uhrCapacity', label: 'U.H.R.' },
      { property: 'gmpValue', label: 'G.M.P.' },
      { property: 'pmpValue', label: 'P.M.P.' },
      { property: 'cpomOption', label: 'Option tarifaire' },
      { property: 'cpomEndDate', label: 'Date de fin du C.P.O.M.' },
      { property: 'healthFundingAmount', label: 'Soin' },
      { property: 'dependencyFundingAmount', label: 'Dépendance' },
      { property: 'accommodationFundingAmount', label: 'Hébergement' },
    ];

    if (configuration && isValidYear(configuration.eprdYear)) {
      this.columns.push({ property: 'eprdAmount', label: `Résultat E.P.R.D. ${configuration.eprdYear}` });
      this.columns.push({ property: 'eprdCAFCapacity', label: `C.A.F. prévisionnelle ${configuration.eprdYear}` });
    } else {
      this.columns.push({ property: 'eprdAmount', label: `Résultat E.P.R.D.` });
      this.columns.push({ property: 'eprdCAFCapacity', label: `C.A.F. prévisionnelle` });
    }

    if (configuration && isValidYear(configuration.errdYear)) {
      this.columns.push({ property: 'errdAmount', label: `Résultat E.R.R.D. ${configuration.errdYear}` });
      this.columns.push({ property: 'errdCAFCapacity', label: `C.A.F. réalisée ${configuration.errdYear}` });
    } else {
      this.columns.push({ property: 'errdAmount', label: `Résultat E.R.R.D.` });
      this.columns.push({ property: 'errdCAFCapacity', label: `C.A.F. réalisée` });
    }
  }

  private buildFilterDefinitions(): FilterDefinition[] {
    const list: Array<{ key: keyof FullDataTableWidgetContentRow; type: 'number' | 'date' | 'enum'; label: string }> = [
      { key: 'permanentCapacity', type: 'number', label: 'Hébergement permanente' },
      { key: 'temporaryCapacity', type: 'number', label: 'Hébergement temporaire' },
      { key: 'daycareCapacity', type: 'number', label: 'Accueil de jour' },
      { key: 'pasaCapacity', type: 'number', label: `P.A.S.A.` },
      { key: 'uhrCapacity', type: 'number', label: `U.H.R.` },
      { key: 'cpomEndDate', type: 'date', label: `Date de fin du C.P.O.M.` },
      { key: 'gmpValue', type: 'number', label: 'G.M.P.' },
      { key: 'pmpValue', type: 'number', label: 'P.M.P.' },
      { key: 'eprdAmount', type: 'number', label: `Résultat de l'E.P.R.D.` },
      { key: 'eprdAmount', type: 'number', label: `Résultat de l'E.R.R.D.` },
      { key: 'eprdCAFCapacity', type: 'number', label: 'C.A.F. prévisionnel' },
      { key: 'errdCAFCapacity', type: 'number', label: 'C.A.F. réalisée' },
    ];

    return list.map(item => {
      switch (item.type) {
        case 'number':
          return createNumberFilterDefinition({ key: item.key, label: item.label });
        case 'date':
          return createDateFilterDefinition({ key: item.key, label: item.label });
        default:
          throw new Error(
            `Unknown type ${item.type}. Filter definition for ${item.label} (${item.key}) cannot be build`,
          );
      }
    });
  }
}
