import { Common } from '@application/framework/lib';
import { CreateCustomerDto } from '@application/bundles/customer/dto/create-customer.dto';
import { UpdateCustomerDto } from '@application/bundles/customer/dto/update-customer.dto';
import { BillingMode, Customer } from '@domain/customer';
import { EasyCreateCustomerDto, EasyCustomer, EasyUpdateCustomerDto } from '@implementations/bundles/backend/easyhpad';
import { Provide, Service } from '@application/framework/di';
import { Transformer } from '@application/framework/core';
import { EasyAddressTransformer } from '@implementations/bundles/backend/easyhpad/transformers/easy-address/easy-address.transformer';
import { PhoneFromStringTransformer } from '@application/bundles/phone/transformer/phone-from-string.transformer';
import { BillingModeCollection } from '@implementations/bundles/customer/implementations';

type CommonProperties = Omit<
  Common<Customer, Common<CreateCustomerDto, UpdateCustomerDto>>,
  'address' | 'billingAddress'
>;

type EasyCommonProperties = Omit<
  Common<EasyCustomer, Common<EasyCreateCustomerDto, EasyUpdateCustomerDto>>,
  'address' | 'billingAddress'
>;

@Service()
export class EasyCommonCustomerTransformer implements Transformer<CommonProperties, EasyCommonProperties> {
  public readonly addressTransformer: EasyAddressTransformer = new EasyAddressTransformer();
  @Provide() private readonly phoneTransformer!: PhoneFromStringTransformer;
  @Provide() private readonly billingModeCollection!: BillingModeCollection;

  public transform(value: CommonProperties): EasyCommonProperties {
    return {
      name: value.name,
      billingMode: value.billingMode.mode,
      phone: value.phone ? this.phoneTransformer.reverseTransform(value.phone) : undefined,
      blocked: value.blocked,
    };
  }

  public reverseTransform(value: EasyCommonProperties): CommonProperties {
    return {
      name: value.name,
      billingMode: this.billingModeCollection.get(value.billingMode) as BillingMode,
      phone: value.phone ? this.phoneTransformer.transform(value.phone) : undefined,
      blocked: !!value.blocked,
    };
  }
}
