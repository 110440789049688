import {ForbiddenError} from "@application/bundles/authorization/error";
import {
  DeleteHealthFundingCommand,
  HEALTH_FUNDING_TRANSLATE_CONTEXT,
  HealthFundingDeletedEvent,
  HealthFundingDeletionFailEvent,
  HealthFundingRepository,
} from "@application/bundles/health-funding";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {TranslatableString} from "@application/framework/translation";
import {HealthFunding} from "@domain/health-funding";
import {MediaBucket} from "@domain/media";
import {isValidMediaIdType} from "@application/bundles/media";


@HandleCommand({
  command: DeleteHealthFundingCommand
})
export class DeleteHealthFundingCommandHandler implements CommandHandler<DeleteHealthFundingCommand, HealthFunding> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private repository: HealthFundingRepository,
    private eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket
  ) {
  }

  public async handle(command: DeleteHealthFundingCommand): Promise<HealthFunding> {

    const {healthFunding} = command;
    try {
      const deleted = await this.checkAccess(healthFunding)
        .then(() => this.delete(healthFunding));

      this.eventDispatcher.dispatch(new HealthFundingDeletedEvent(deleted));

      return deleted;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }


  private async checkAccess(healthFunding: HealthFunding): Promise<HealthFunding> {

    return healthFunding;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à supprimer ce financement soin.",
        undefined,
        HEALTH_FUNDING_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new HealthFundingDeletionFailEvent(message));

    return error;
  }

  private async delete(healthFunding: HealthFunding): Promise<HealthFunding> {

    const deleted = await this.repository.delete(healthFunding.id);

    try {
      if (isValidMediaIdType(healthFunding.documentId)) {
        await this.mediaBucket.delete(healthFunding.documentId);
      }

      if (isValidMediaIdType(healthFunding.reportId)) {
        await this.mediaBucket.delete(healthFunding.documentId);
      }

    } catch (e: any) {
      this.errorNormalizer.normalize(e);
      this.logger.warning(this.errorNormalizer.normalize(e).message);
    }

    return deleted;
  }

}
