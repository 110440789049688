import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
  CapacityAuthorizationAuthorizationChecker,
} from '@application/bundles/capacity-authorization';
import { AddInspectionReportCommand } from '@application/bundles/capacity-authorization/commands';
import { AddInspectionReportDto } from '@application/bundles/capacity-authorization/dtos';
import { UpdateCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto';
import { InspectionReportCreatedEvent } from '@application/bundles/capacity-authorization/events/create-inspection-report/inspection-report-created.event';
import { InspectionReportCreationFailEvent } from '@application/bundles/capacity-authorization/events/create-inspection-report/inspection-report-creation-fail.event';
import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { Translator } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import {
  CapacityAuthorization,
  CapacityAuthorizationRepository,
  InspectionReport,
} from '@domain/capacity-authorization';
import { MediaBucket, TemporaryMedia } from '@domain/media';

@HandleCommand({
  command: AddInspectionReportCommand,
})
export class AddInspectionReportCommandHandler implements CommandHandler<AddInspectionReportCommand, InspectionReport> {
  @ProvideLogger() private readonly logger!: Logger;

  private readonly errorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly authorizationChecker: CapacityAuthorizationAuthorizationChecker,
    private readonly authorizationRepository: CapacityAuthorizationRepository,
    private readonly mediaBucket: MediaBucket,
    private readonly sanitizer: SanitizerLibrary,
    private readonly validator: ObjectValidator,
    private readonly eventDispatcher: EventDispatcher,
    private readonly translator: Translator,
  ) {}

  public async handle(command: AddInspectionReportCommand): Promise<InspectionReport> {
    const { authorizationId } = command;
    try {
      const authorization = await this.checkAccessAndGetAuthorization(authorizationId);

      const report = await this.sanitize(command.report)
        .then(dto => this.validate(dto))
        .then(dto => this.addReportToAuthorization(authorization, dto));

      this.eventDispatcher.dispatch(new InspectionReportCreatedEvent(report));

      return report;
    } catch (e) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccessAndGetAuthorization(id: CapacityAuthorization['id']): Promise<CapacityAuthorization> {
    const authorization = await this.authorizationRepository.get(id);

    if (!(await this.authorizationChecker.canEdit(authorization))) {
      this.logger.error('Inspection report creation : Forbidden');
      throw new ForbiddenError();
    }

    return authorization;
  }

  private async sanitize(report: AddInspectionReportDto): Promise<AddInspectionReportDto> {
    try {
      report = await this.sanitizer.sanitize(report);
    } catch (e: any) {
      this.logger.warning('Inspection report creation : Sanitizer fail');
      throw e;
    }

    return report;
  }

  private async validate(report: AddInspectionReportDto): Promise<AddInspectionReportDto> {
    try {
      report = await this.validator.validate(report);
    } catch (e: any) {
      this.logger.warning('Inspection report creation : Validator fail');
      throw e;
    }

    return report;
  }

  private async addReportToAuthorization(
    authorization: CapacityAuthorization,
    report: AddInspectionReportDto,
  ): Promise<InspectionReport> {
    const currents = await authorization.inspectionReports();

    let medias: TemporaryMedia[] = [];

    if (report.medias && report.medias.length > 0) {
      medias = await this.mediaBucket.store(report.medias).then(collection => collection.all());
      report.mediaIds = medias.map(media => media.id);
    }

    const update: Pick<UpdateCapacityAuthorizationDto, 'inspectionReports'> = {
      inspectionReports: [
        ...currents,
        {
          id: undefined,
          date: report.date,
          mediaIds: report.mediaIds || [],
        },
      ],
    };

    let updated: CapacityAuthorization;

    try {
      updated = await this.authorizationRepository.update(authorization.id, update);
    } catch (e) {
      for (const media of medias) {
        await media.markForDeletion();
      }
      throw e;
    }

    const added = await updated.inspectionReports().then(reports => {
      const ids = currents.map(media => media.id);
      return reports.find(r => !ids.includes(r.id));
    });

    if (added === undefined) {
      throw new Error('Newer report cannot be found in inspection reports array');
    }

    return added;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);

    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = await this.translator.translate('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à ajouter un nouveau rapport d'inspection à cette autorisation de capacité.",
        undefined,
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new InspectionReportCreationFailEvent(message));

    return error;
  }
}
