import {
  DependencyFundingItem,
  dependencyFundingItemHasMode,
  isDependencyFundingMode,
  TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  TEMPORARY_DEPENDENCIES_FUNDING_MODES,
  TemporaryDependencyAsPermanentFunding,
  TemporaryDependencyFunding,
  TemporaryDependencyFundingMode,
  TemporaryDependencyFundingModeValues,
} from "@domain/dependency-funding";

export function isTemporaryDependencyFundingMode(mode: any): mode is TemporaryDependencyFundingMode {
  return isDependencyFundingMode<TEMPORARY_DEPENDENCIES_FUNDING_MODES>(mode)
    && TemporaryDependencyFundingModeValues.has(mode.key);
}

export function isTemporaryDependencyFundingModeValue(value: any): value is TEMPORARY_DEPENDENCIES_FUNDING_MODES {
  return TemporaryDependencyFundingModeValues.has(value);
}

export function hasTemporaryFundingMode<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = any, V = unknown>(funding: DependencyFundingItem<any, unknown>): funding is DependencyFundingItem<TemporaryDependencyFundingMode<M>, V> {
  return dependencyFundingItemHasMode(funding)
    && isTemporaryDependencyFundingModeValue(funding.mode.key);
}

export function useTemporaryFundingAsPermanentMode(funding: TemporaryDependencyFunding<any, unknown>): funding is TemporaryDependencyAsPermanentFunding {
  return hasTemporaryFundingMode(funding)
    && funding.mode.key === TEMPORARY_DEPENDENCIES_FUNDING_CUSTOM_MODES.SAME_PERMANENT
}
