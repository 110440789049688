import {NoticeStream, NoticeStreamLibrary} from "@application/framework/notice";
import {NoticeStreamImpl} from "@implementations/framework/notice/notice-stream.impl";

export class NoticeStreamLibraryImpl extends NoticeStreamLibrary implements NoticeStreamLibrary {


  private streams: Map<string | Symbol, NoticeStream> = new Map();
  
  public getStream(streamName?: string | Symbol): NoticeStream {

    if (!streamName) {
      return this.defaultStream;
    }

    let stream = this.streams.get(streamName);

    if (!stream) {
      stream = new NoticeStreamImpl();
      this.streams.set(streamName, stream);
    }

    return stream;
  }

}
