import { Transformer } from '@application/framework/core';
import { ExceptionalHealthFunding } from '@domain/health-funding';
import { Common } from '@application/framework/lib';
import {
  CreateExceptionalHealthFundingDto,
  ExceptionalHealthFundingDtoFactory,
  UpdateExceptionalHealthFundingDto,
} from '@application/bundles/health-funding';
import { Service } from '@application/framework/di';
import {
  CreateEasyExceptionalHealthFundingDto,
  EasyExceptionalHealthFunding,
  UpdateEasyExceptionalHealthFundingDto,
} from '@implementations/bundles/backend/easyhpad/interfaces/health-funding/easy-exceptional-health-funding';

type CommonProperties = Common<
  ExceptionalHealthFunding,
  Common<CreateExceptionalHealthFundingDto, UpdateExceptionalHealthFundingDto>
>;

type EasyCommonProperties = Common<
  EasyExceptionalHealthFunding,
  Common<CreateEasyExceptionalHealthFundingDto, UpdateEasyExceptionalHealthFundingDto>
>;

@Service()
export class ExceptionalHealthFundingTransformer
  implements Transformer<EasyExceptionalHealthFunding, ExceptionalHealthFunding>
{
  constructor(private readonly dtoFactory: ExceptionalHealthFundingDtoFactory) {}

  public transform(value: EasyExceptionalHealthFunding): ExceptionalHealthFunding {
    return {
      ...this.transformCommonProperties(value),
      id: value.id,
    };
  }

  public transformCreateDto(value: CreateEasyExceptionalHealthFundingDto): CreateExceptionalHealthFundingDto {
    return this.dtoFactory.buildCreateDtoFromUnsafeValues({
      ...this.transformCommonProperties(value),
    });
  }

  public transformUpdateDto(value: UpdateEasyExceptionalHealthFundingDto): UpdateExceptionalHealthFundingDto {
    return this.dtoFactory.buildUpdateDtoFromUnsafeValues({
      ...this.transformCommonProperties(value),
      id: value.id,
    });
  }

  public reverseTransform(value: ExceptionalHealthFunding): EasyExceptionalHealthFunding {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
    };
  }

  public reverseTransformCreateDto(value: CreateExceptionalHealthFundingDto): CreateEasyExceptionalHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value),
    };
  }

  public reverseTransformUpdateDto(value: UpdateExceptionalHealthFundingDto): UpdateEasyExceptionalHealthFundingDto {
    return {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
    };
  }

  private transformCommonProperties(value: EasyCommonProperties): CommonProperties {
    return {
      amount: value.value,
      other: value.other || undefined,
      categoryId: value.categoryId ?? undefined,
    };
  }

  private reverseTransformCommonProperties(value: CommonProperties): EasyCommonProperties {
    return {
      other: value.other,
      value: value.amount,
      categoryId: value.categoryId,
    };
  }
}
