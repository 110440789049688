<span *ngIf="name" class="name">
  <span class="label">{{'Source : ' | ehp_translate}}</span>

  <a *ngIf="url; else noUrl" [href]="url" [innerHTML]="name" class="value"
     rel="noopener nofollow noreferer" target="_blank">
  </a>

  <ng-template #noUrl>
    <span [innerHTML]="name" class="value"></span>
  </ng-template>
</span>

<span *ngIf="date" class="update">
  <span class="label">{{'Dernière mise à jour : '}}</span><span [innerHTML]="date" class="value"></span>
</span>
