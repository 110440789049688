import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import {
  FundingAmountsByYear,
  GetLatestHealthFundingAmountsQuery,
  HealthFundingAmountRepository,
} from '@application/bundles/funding';
import { HealthFunding } from '@domain/health-funding';

@HandleQuery({
  query: GetLatestHealthFundingAmountsQuery,
})
export class GetLatestHealthFundingAmountsQueryHandler implements QueryHandler<GetLatestHealthFundingAmountsQuery> {
  constructor(private readonly repository: HealthFundingAmountRepository) {}

  public handle(query: GetLatestHealthFundingAmountsQuery): Promise<FundingAmountsByYear<HealthFunding>> {
    return this.repository.findLatestOne(query.facilityIds);
  }
}
