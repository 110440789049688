import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressModule } from '@easyhpad-ui/app/bundles/address/address.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import {
  FacilitiesIdCardComponent,
  FacilityIdCardComponent,
  FacilityLinkComponent,
  FacilityNameComponent,
  FacilitySocialClearanceLabelComponent,
  FacilityStateComponent,
  FacilityTypeLabelComponent,
} from './components';

@NgModule({
  imports: [CommonModule, TranslationModule, AddressModule],
  declarations: [
    FacilitiesIdCardComponent,
    FacilityIdCardComponent,
    FacilityLinkComponent,
    FacilityNameComponent,
    FacilityStateComponent,
    FacilityTypeLabelComponent,
    FacilitySocialClearanceLabelComponent,
  ],

  exports: [
    FacilitiesIdCardComponent,
    FacilityIdCardComponent,
    FacilityLinkComponent,
    FacilityNameComponent,
    FacilityStateComponent,
    FacilitySocialClearanceLabelComponent,
    FacilityTypeLabelComponent,
  ],
})
export class FacilityUiModule {}
