import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {CreateUserDtoImpl} from "@implementations/bundles/user/dto/create-user.dto";
import {UpdateUserDtoImpl} from "@implementations/bundles/user/dto/update-user.dto";
import {validateOrReject} from "class-validator";

export const createUserDtoValidator: ValidateFunction<CreateUserDtoImpl> = async (dto: CreateUserDtoImpl) => {
  await validateOrReject(dto);

  return dto;
}

export const updateUserDtoValidator: ValidateFunction<UpdateUserDtoImpl> = async (dto: UpdateUserDtoImpl) => {
  await validateOrReject(dto);
  return dto;
}
