import { NgModule } from '@angular/core';
import { Bundle } from '@application/framework/bundle';
import { FileDepositBundle } from '@application/bundles/file-deposit/file-deposit.bundle';
import { FileDepositRepository } from '@application/bundles/file-deposit/repositories';
import { EasyFileDepositRepository } from '@implementations/bundles/backend/easyhpad/repositories/file-deposit/easy-file-deposit.repository';
import { StoreModule } from '@ngrx/store';
import {
  FILE_DEPOSIT_STORE_KEY,
  FileDepositEffects,
  fileDepositReducer,
} from '@easyhpad-ui/app/bundles/file-deposit/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    StoreModule.forFeature(FILE_DEPOSIT_STORE_KEY, fileDepositReducer),
    EffectsModule.forFeature([FileDepositEffects]),
  ],
  providers: [
    {
      provide: FileDepositRepository,
      useClass: EasyFileDepositRepository,
    },
  ],
})
@Bundle({ imports: [FileDepositBundle] })
export class FileDepositModule {}
