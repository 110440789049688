import { getTypeFromMimeType, LocalMedia, MEDIA_FILE_TYPES, MEDIA_PERSISTANCE_STATE } from '@domain/media';
import { Customer } from '@domain/customer';
import { isValidCustomerIdType } from '@application/bundles/customer';

export class LocalMediaImpl implements LocalMedia {
  public readonly persistance = MEDIA_PERSISTANCE_STATE.TEMPORARY;

  public readonly file!: File;

  public name!: string;

  public path!: string;

  public customerId: Customer['id'] | undefined;

  public get mimeType(): string {
    return this.file.type;
  }

  public get type(): MEDIA_FILE_TYPES {
    return getTypeFromMimeType(this.mimeType);
  }

  public constructor(file: File, customerId?: Customer['id']) {
    if (!(file instanceof File)) {
      throw new Error('A valide File is required to instantiate LocalMediaImpl');
    }

    this.file = file;
    this.name = file.name;
    this.path = file.name;

    if (isValidCustomerIdType(customerId)) {
      this.customerId = customerId;
    }
  }

  public buildUrl(): Promise<URL> {
    return Promise.resolve(new URL(URL.createObjectURL(this.file)));
  }
}
