import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FILE_DEPOT_STATES, FileDeposit, FileDepositItem, FileDepositTransition } from '@domain/file-deposit';
import { CommandBus } from '@application/framework/command-query';
import { LocalMedia, Media } from '@domain/media';
import {
  CreateNewFileDepositItemCommand,
  DeleteFileDepositItemCommand,
  RequestTransitionToReviewFileDepositCommand,
} from '@application/bundles/file-deposit/commands';
import { Dialog } from '@angular/cdk/dialog';
import { DialogConfiguration } from '@easyhpad-ui/app/library/dialog';
import { FileDepositTransitionRequestDialogComponent } from '@easyhpad-ui/app/bundles/file-deposit/modules/ui/components/file-deposit-transition-request-dialog/file-deposit-transition-request-dialog.component';
import { DOCUMENT_TYPES } from '@domain/document';

@Component({
  selector: 'ehp-file-deposit-load-form',
  templateUrl: './file-deposit-load-form.component.html',
  styleUrls: ['./file-deposit-load-form.component.scss'],
})
export class FileDepositLoadFormComponent implements OnChanges {
  @Input() public deposit!: FileDeposit;

  @Output() public onChange = new EventEmitter<void>();

  @Output() public onTransition = new EventEmitter<void>();

  public saving: boolean = false;

  public readonly STATES = FILE_DEPOT_STATES;

  public availableTypes: DOCUMENT_TYPES[] = [];

  public current: DOCUMENT_TYPES | undefined;

  public items: Map<DOCUMENT_TYPES, FileDepositItem[]> = new Map();

  public uploadList: Set<LocalMedia | Media> = new Set();

  public get currentItems(): FileDepositItem[] {
    let items: FileDepositItem[] = [];

    this.items.forEach(list => {
      items = [...items, ...list.filter(item => !item.lock)];
    });

    return items;
  }

  public get lockedItems(): FileDepositItem[] {
    let items: FileDepositItem[] = [];

    this.items.forEach(list => {
      items = [...items, ...list.filter(item => item.lock)];
    });

    return items;
  }

  constructor(
    private readonly commandBus: CommandBus,
    private readonly dialog: Dialog,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['deposit']) {
      this.rebuildForm();
    }
  }

  /**
   * Create a new deposit File deposit items for each media.
   * @param payload
   */
  public addMedias(payload: { type: DOCUMENT_TYPES; medias: Array<LocalMedia | Media> }) {
    const requests = payload.medias.map(async media => {
      this.uploadList.add(media);

      const item = await this.commandBus.execute<FileDepositItem>(
        new CreateNewFileDepositItemCommand({
          fileDepositId: this.deposit.id,
          type: payload.type,
          media: media as LocalMedia,
        }),
      );

      this.addItem(item);
      this.uploadList.delete(media);
    });

    this.saving = true;

    Promise.all(requests)
      .then(() => this.dispatchChange())
      .finally(() => (this.saving = false));
  }

  /**
   * Delete a FileDeposit item
   * @param item
   */
  public deleteItem(item: FileDepositItem) {
    this.saving = true;

    this.commandBus
      .execute(new DeleteFileDepositItemCommand(item.parentId, item.id))
      .then(() => {
        this.removeItem(item);
        this.dispatchChange();
      })
      .finally(() => (this.saving = false));
  }

  /**
   * Open confirmation dialog and request transition.
   */
  public requestReview() {
    const dialog = this.dialog.open<{
      submit: boolean;
      comment?: string;
    }>(FileDepositTransitionRequestDialogComponent, { ...DialogConfiguration, data: { type: 'to_review' } });

    dialog.closed.subscribe(response => {
      if (!response?.submit) {
        return;
      }

      this.requestReviewTransition(response.comment);
    });
  }

  /**
   * Emit a event in onChange emitter.
   * @private
   */
  private dispatchChange() {
    this.onChange.emit();
  }

  /**
   * Clear the list of items, and rebuild fom controls.
   * @private
   */
  private rebuildForm() {
    this.availableTypes = [];
    this.items = new Map();

    if (!this.deposit) {
      return;
    }

    const expected = this.deposit.list?.items ?? [];
    const items = this.deposit.items ?? [];

    this.availableTypes = expected.map(item => item.type).filter((type, i, types) => types.includes(type));

    // Reset the current type if it is not in available types
    if (this.current === undefined || !this.availableTypes.includes(this.current)) {
      this.current = this.availableTypes[0];
    }

    this.availableTypes.forEach(type => {
      const panelItems = items.filter(item => item.type === type);
      this.items.set(type, panelItems);
    });
  }

  /**
   * Add a file deposit item in the item list if it is not already set.
   * @param item
   * @private
   */
  private addItem(item: FileDepositItem): void {
    const list = this.getItems(item.type);

    if (list.findIndex(current => current.id === item.id) === -1) {
      this.items.set(item.type, [...list, item]);
    }
  }

  /**
   * Get the item list for a specific type. If the list is not defined, create a new empty array.
   * @param type
   * @private
   */
  private getItems(type: DOCUMENT_TYPES): FileDepositItem[] {
    let list = this.items.get(type);

    if (list === undefined) {
      list = [];
      this.items.set(type, list);
    }

    return list;
  }

  /**
   * Remove item from the current deposit item list
   * @param item
   * @private
   */
  private removeItem(item: FileDepositItem): void {
    let items = this.items.get(item.type);

    if (!Array.isArray(items)) {
      items = [];
    }

    const index = items.findIndex(i => i.id === item.id);

    if (index !== -1) {
      items.slice(index, 1);
    }

    this.items.set(item.type, [...items]);
  }

  /**
   * Dispatch transition request command and dispatch change on transition success.
   * @param comment
   * @private
   */
  private requestReviewTransition(comment: string | undefined) {
    const request = new RequestTransitionToReviewFileDepositCommand(this.deposit, comment);
    this.commandBus.execute<FileDepositTransition | undefined>(request).then(transition => {
      if (transition) {
        this.dispatchChange();
        this.onTransition.emit();
      }
    });
  }
}
