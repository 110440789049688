import { createReducer, on } from '@ngrx/store';
import { BalanceSheetStoreState } from './balance-sheet.state';
import { balanceSheetActions } from '@easyhpad-ui/app/bundles/balance-sheet/store/balance-sheet.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { BalanceSheet } from '@domain/balance-sheet';

export const adapter: EntityAdapter<BalanceSheet> = createEntityAdapter<BalanceSheet>();

const initialState: BalanceSheetStoreState = {
  public: {
    ...adapter.getInitialState(),
    loading: false,
    selected: null,
  },
};

export const balanceSheetReducer = createReducer(
  initialState,
  on(balanceSheetActions.loadBalanceSheets, state => ({
    ...state,
    public: {
      ...state.public,
      loading: true,
    },
  })),
  on(balanceSheetActions.setBalanceSheets, (state, { sheets }) => ({
    ...state,
    public: {
      ...adapter.setAll(sheets, state.public),
      loading: false,
    },
  })),
  on(balanceSheetActions.addBalanceSheets, (state, { sheets }) => ({
    ...state,
    public: {
      ...adapter.addMany(sheets, state.public),
      loading: false,
    },
  })),
  on(balanceSheetActions.selectBalanceSheet, (state, { id }) => ({
    ...state,
    public: {
      ...state.public,
      selected: id,
    },
  })),
  on(balanceSheetActions.resetBalanceSheetStore, () => ({
    ...initialState,
  })),
);
