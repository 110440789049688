import {FACILITY_TRANSLATE_CONTEXT} from "@application/bundles/facility";
import {FacilityUpdateFailEvent} from "@application/bundles/facility/events/facility-update/facility-update-fail.event";
import {FacilityUpdatedEvent} from "@application/bundles/facility/events/facility-update/facility-updated.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";

@HasEventSubscribers()
export class OnFacilityUpdateEventSubscriber {

  constructor(
    private readonly noticeStream: NoticeStream,
    private readonly translator: Translator
  ) {
  }


  @OnEvent({
    event: FacilityUpdatedEvent
  })
  public async pushNoticeOnFacilityUpdateSuccess(event: FacilityUpdatedEvent): Promise<void> {

    const message = await this.translator.translate(
      "L'établissement <strong>{{name}}</strong> a été mis à jour avec succès.",
      {name: event.facility.name},
      FACILITY_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({message, type: NoticeType.SUCCESS});
  }

  @OnEvent({
    event: FacilityUpdateFailEvent
  })
  public async pushNoticeOnFacilityUpdateFail(event: FacilityUpdateFailEvent): Promise<void> {
    let message = event.reason;

    if (!message) {
      message = await this.translator.translate(
        "Une erreur est survenue lors de la création du nouvel établissement.",
        undefined,
        FACILITY_TRANSLATE_CONTEXT
      );
    }

    this.noticeStream.push({message, type: NoticeType.ERROR});
  }
}
