import { SubFunding } from '@domain/funding';
import { UUID } from '@domain/lib';
import { ExceptionalTaxonomyTerm } from '@domain/health-funding/taxonomies';

export interface ExceptionalHealthFunding extends SubFunding {
  id: UUID;

  other?: string;

  categoryId?: ExceptionalTaxonomyTerm['id'];
}
