import {CommandBus} from "@application/framework/command-query";
import {AuthorizationTokenType, HttpAuthorizationToken} from "@application/framework/http";
import {EASYHPAD_PROVIDER} from "@implementations/bundles/backend/easyhpad/backend.tokens";
import {
  RefreshHttpAuthorizationTokenCommand
} from "@implementations/bundles/backend/easyhpad/commands/refresh-http-authorization-token.command";
import {EasyHpadBearerHttpValidator} from "@implementations/bundles/backend/easyhpad/validators/bearer-http.validator";

export class AutoRenewHttpBearerAuthorizationToken implements HttpAuthorizationToken<string> {

  public readonly type = AuthorizationTokenType.BEARER;

  public readonly provider = EASYHPAD_PROVIDER;

  private token?: string;

  private nextRefresh: any | undefined;

  public get value(): string {
    return this.token && EasyHpadBearerHttpValidator.isMaybeValid(this.token) ? this.token.toString() : '';
  }

  public set value(token: string) {
    this.token = EasyHpadBearerHttpValidator.isMaybeValid(token) ? token : undefined;
  }

  constructor(private commandBus: CommandBus) {
  }

  public setExpiresIn(expiresIn: number) {

    if (this.nextRefresh) {
      this.nextRefresh = undefined;
    }


    if (isFinite(expiresIn)) {
      // Remove time for network
      expiresIn = expiresIn - (250 * 2);

      if (expiresIn > 0) {
        this.nextRefresh = setTimeout(async () => {
          await this.commandBus.execute(new RefreshHttpAuthorizationTokenCommand(this));
          clearTimeout(this.nextRefresh);
          this.nextRefresh = null;
        }, expiresIn);
      }
    }

  }

  public toString(): string {
    return `Bearer ${this.value}`;
  }

  public destroy() {

    if (this.nextRefresh) {
      clearTimeout(this.nextRefresh);
      this.nextRefresh = null;
    }
  }

}
