import {AVAILABLE_ROLES, Role} from "@domain/authorization";


export const isRole = (role: any): role is Role => {
  return !!role && typeof role === "object" && typeof role.name !== "undefined";
}

export const isDefaultRole = (role: any): role is Role<AVAILABLE_ROLES.DEFAULT> => {
  return isRole(role) && role.name === AVAILABLE_ROLES.DEFAULT;
}

export const isAdminRole = (role: any): role is Role<AVAILABLE_ROLES.ADMIN> => {
  return isRole(role) && role.name === AVAILABLE_ROLES.ADMIN;
}

export const includeAdminRole = (roles: Array<any>): boolean | null => {
  let result: boolean | null = null;

  if (Array.isArray(roles)) {
    roles = roles.filter(role => isRole(role));

    if (roles.length > 0) {
      result = false;
    }

    for (const role of roles) {
      if (isAdminRole(role)) {
        return true;
      }
    }
  }

  return result;

}
