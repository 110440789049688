export class List<T> implements Omit<Set<T>, 'add' | 'delete' | 'clear'> {


  private readonly list: Set<T>;

  public get size(): number {
    return this.list.size;
  }

  public get [Symbol.toStringTag](): string {
    return this.constructor.name;
  }

  constructor(values?: readonly T[] | null) {
    this.list = new Set(values);
  }

  public has(value: T): boolean {
    return this.list.has(value);
  }

  public keys(): IterableIterator<T> {
    return this.list.keys();
  }

  public values(): IterableIterator<T> {
    return this.list.values();
  }

  public entries(): IterableIterator<[T, T]> {
    return this.list.entries();
  }

  public [Symbol.iterator](): IterableIterator<T> {
    return this.list[Symbol.iterator]();
  }

  public forEach(callbackfn: (value: T, value2: T, set: Set<T>) => void, thisArg?: any): void {
    return this.list.forEach(callbackfn, thisArg);
  }

}


