import {CreateCPOMFundingDto, CreateLocalCPOMDto} from "@application/bundles/cpom";
import {isValidFacilityIdType} from "@application/bundles/facility";
import {isTariffOption, TariffOption} from "@domain/cpom";
import {Facility} from "@domain/facility";
import {IsTariffOption} from "@implementations/bundles/cpom/validators";
import {IsValidFacilityId} from "@implementations/bundles/facility";
import {IsArray, IsNumber, IsOptional, IsPositive} from "class-validator";

export class CreateLocalCPOMDtoImpl implements CreateLocalCPOMDto {

  @IsTariffOption()
  public tariffOption!: TariffOption;

  @IsArray()
  public funding: CreateCPOMFundingDto[] = [];

  @IsOptional()
  @IsNumber()
  @IsPositive()
  public bedSocialCare: number | undefined;

  @IsValidFacilityId()
  public facilityId!: Facility['id'];

  constructor(values?: Partial<CreateLocalCPOMDto>) {

    if (values) {

      if (Array.isArray(values.funding)) {
        this.funding = values.funding;
      }

      if (isTariffOption(values.tariffOption)) {
        this.tariffOption = values.tariffOption;
      }

      if (isValidFacilityIdType(values.facilityId)) {
        this.facilityId = values.facilityId;
      }

      this.bedSocialCare = values.bedSocialCare;
    }
  }

}
