import { Service } from '@application/framework/di';
import { HealthFunding } from '@domain/health-funding';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Service()
export class HealthFundingAuthorizationChecker {
  constructor(private readonly authorization: DocumentAuthorization) {}

  public canRead(funding?: HealthFunding) {
    return this.authorization.canReadDocument(funding);
  }

  public canCreate() {
    return this.authorization.canCreateDocument();
  }

  public canUpdate(funding: HealthFunding) {
    return this.authorization.canEditDocument(funding);
  }

  public canDelete(funding: HealthFunding) {
    return this.authorization.canDeleteDocument(funding);
  }
}
