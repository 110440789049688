import {
  ERRD,
  FinancialAnnex,
  isFinancialAnnex,
  isFinancialAnnexData,
  isValidEstateDegradation,
  LocalERRD,
  PartialActivityAnnex,
  TPER,
} from '@domain/eprd-errd';
import { Facility } from '@domain/facility';
import { Provide } from '@application/framework/di';
import { GetERRDQuery, isValidERRDIdType, isValidLocalERRDIdType } from '@application/bundles/eprd-errd';
import { QueryBus } from '@application/framework/command-query';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { isPositiveFundingAmount, isValidFundingAmount } from '@domain/funding';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { isPercentage } from '@domain/lib/math/percentage/percentage';
import { FinancialAnnexImpl } from '@implementations/bundles/eprd-errd/implementations/financial-annex.implementation';
import { isPartialActivityAnnex } from '@domain/activity-annex';
import { UUID } from '@domain/lib';

export class LocalERRDImpl implements LocalERRD {
  public id!: UUID;

  public expense: number = 0;

  public revenue: number = 0;

  public CAF: number | undefined;

  public activityAnnex!: PartialActivityAnnex;

  public financialAnnex!: FinancialAnnex;

  public estateDegradation: { comment: string; rate: number } | undefined;

  public independenceRate: number | undefined;

  public usedTreasury: number | undefined;

  public tper: TPER[] = [];

  public parentId!: ERRD['id'];

  public facilityId!: Facility['id'];

  @Provide()
  private readonly queryBus!: QueryBus;

  private _parent: ERRD | undefined;

  public get parent(): ERRD {
    if (!this._parent) {
      throw new Error(`parent is missing in ${this.constructor.name} (${this.id})`);
    }
    return this._parent;
  }

  constructor(defaults?: Partial<Omit<LocalERRD, 'parent' | 'facility' | 'refreshParent' | 'occupationRate'>>) {
    if (defaults) {
      if (isValidLocalERRDIdType(defaults.id)) {
        this.id = defaults.id;
      }

      if (isPositiveFundingAmount(defaults.expense)) {
        this.expense = defaults.expense;
      }

      if (isPositiveFundingAmount(defaults.revenue)) {
        this.revenue = defaults.revenue;
      }

      if (isValidFundingAmount(defaults.CAF)) {
        this.CAF = defaults.CAF;
      }

      if (isPartialActivityAnnex(defaults.activityAnnex)) {
        this.activityAnnex = defaults.activityAnnex;
      }

      if (isFinancialAnnex(defaults.financialAnnex)) {
        this.financialAnnex = defaults.financialAnnex;
      } else if (isFinancialAnnexData(defaults.financialAnnex)) {
        this.financialAnnex = new FinancialAnnexImpl(defaults.financialAnnex);
      }

      if (isValidFacilityIdType(defaults.facilityId)) {
        this.facilityId = defaults.facilityId;
      }

      if (isValidERRDIdType(defaults.parentId)) {
        this.parentId = defaults.parentId;
      }

      if (isPercentage(defaults.independenceRate)) {
        this.independenceRate = defaults.independenceRate;
      }

      if (isPositiveFundingAmount(defaults.usedTreasury)) {
        this.usedTreasury = defaults.usedTreasury;
      }

      if (isValidEstateDegradation(defaults.estateDegradation)) {
        this.estateDegradation = defaults.estateDegradation;
      }

      if (Array.isArray(defaults.tper)) {
        this.tper = defaults.tper;
      }
    }
  }

  public facility(): Promise<Facility> {
    return this.queryBus.request(new GetFacilityQuery(this.facilityId));
  }

  public occupationRate(): number | undefined {
    return undefined;
  }

  public refreshParent(): Promise<ERRD> {
    if (this._parent) {
      return Promise.resolve(this._parent);
    }

    return this.queryBus.request<ERRD>(new GetERRDQuery(this.parentId)).then(parent => {
      this._parent = parent;
      return parent;
    });
  }
}
