import {Component, Inject} from '@angular/core';
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {
  ConfirmDeletionDialogData
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface";

@Component({
  selector: 'ehp-dialog-confirm-administrator-creation',
  templateUrl: './dialog-confirm-administrator-creation.component.html',
  styleUrls: ['./dialog-confirm-administrator-creation.component.scss']
})
export class ConfirmAdministratorCreationDialogComponent {

  public constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: ConfirmDeletionDialogData
  ) {

  }

  public confirm() {
    this.dialogRef.close(true);
  }

  public cancel() {
    this.dialogRef.close(false);
  }
}
