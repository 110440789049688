import {AuthentificationFailEvent} from '@application/bundles/authentification/events/authentification-fail.event';
import {AuthentificationSuccessEvent} from '@application/bundles/authentification/events/authentification-success.event';
import {DisconnectFailEvent} from '@application/bundles/authentification/events/disconnect-fail.event';
import {DisconnectSuccessEvent} from '@application/bundles/authentification/events/disconnect-success.event';
import {AnonymousToken} from '@application/bundles/authentification/token/anonymous.token';
import {HasEventSubscribers, OnEvent} from '@application/framework/event';
import {HttpAuthorizationTokenStore} from '@application/framework/http';
import {Logger, ProvideLogger} from '@application/framework/logger';
import {NoticeStream, NoticeType} from '@application/framework/notice';
import {Translator} from '@application/framework/translation';
import {AuthentificationTokenStore} from '@application/bundles/authentification';

/**
 * These subscribers set or remove authentification value in store
 * when login and logout request is sent
 *
 */
@HasEventSubscribers()
export class SetAccountOnAuthentificationSubscriber {
  @ProvideLogger() private readonly logger!: Logger;

  public constructor(
    private readonly tokenStore: AuthentificationTokenStore,
    private readonly httpAuthorizationTokenStore: HttpAuthorizationTokenStore,
    private readonly translator: Translator,
    private readonly noticeStream: NoticeStream,
  ) {}

  /**
   * Set authentification token from event in token store
   * @param event
   */
  @OnEvent({
    event: AuthentificationSuccessEvent,
  })
  public setAuthentificationToken(event: AuthentificationSuccessEvent) {
    this.logger.info(event.name + ' : Store new authentification token in store', event.token);
    this.tokenStore.setToken(event.token);
  }

  /**
   * Set anonymous token in token store
   * @param event
   */
  @OnEvent({
    event: [DisconnectSuccessEvent, DisconnectFailEvent, AuthentificationFailEvent],
  })
  public setAnonymousToken(event: DisconnectSuccessEvent | DisconnectFailEvent | AuthentificationFailEvent) {
    this.logger.info(event.name + ' : Store an anonymous token in store');
    this.tokenStore.setToken(new AnonymousToken());
  }

  /**
   * Push notice on authentification success
   * @param event
   */
  @OnEvent({
    event: AuthentificationSuccessEvent,
  })
  public async sendAuthentificationSuccessNotice(event: AuthentificationSuccessEvent) {
    const message = await this.translator.translate('Connecté en tant que <strong>{{name}}</strong> avec succès', {
      name: event.token.name,
    });

    this.noticeStream.push({ message, type: NoticeType.SUCCESS });
  }

  /**
   * Push notice on logout
   * @param event
   */
  @OnEvent({
    event: DisconnectSuccessEvent,
  })
  public async sendDisconnectSuccessNotice(event: DisconnectSuccessEvent) {
    const message = await this.translator.translate('Vous venez de vous déconnecter avec succès.');
    this.noticeStream.push({ message, type: NoticeType.SUCCESS });
  }

  @OnEvent({
    event: [DisconnectSuccessEvent, DisconnectFailEvent, AuthentificationFailEvent],
  })
  public clearHttpAuthorizationTokenStore() {
    this.httpAuthorizationTokenStore.clear();
  }
}
