import { ProvideIdMetadata } from '@application/framework/di/interfaces/provide-options.interface';
import { TypeOrIdentifier } from '@application/framework/di/types/type-or-identifier';
import { MetadataReflection } from '@application/framework/lib';
import { Token } from '@application/framework/lib/token';
import { DesignReflectionMetadataAccessor } from '@application/framework/reflection';
import { ServiceIdentifier } from '../types/service-identifier';

export function resolveToTypeWrapper(
  typeOrIdentifier: TypeOrIdentifier | undefined,
  target: Object,
  propertyName: string | symbol | undefined,
  index?: number,
): ProvideIdMetadata {
  let typeWrapper!: ProvideIdMetadata;

  const reflection = new MetadataReflection();
  const designMetadataAccessor = new DesignReflectionMetadataAccessor(reflection);

  /**
   * If requested type is explicitly set via a string ID or token, we set it explicitly
   */
  if (
    (typeOrIdentifier && (typeof typeOrIdentifier === 'string' || typeof typeOrIdentifier === 'symbol')) ||
    typeOrIdentifier instanceof Token
  ) {
    typeWrapper = { id: typeOrIdentifier, lazyType: () => typeOrIdentifier };
  }

  if (typeOrIdentifier && typeof typeOrIdentifier === 'function') {
    typeWrapper = { id: null, lazyType: () => typeOrIdentifier };
  }

  /**
   * If no explicit type is set and handler registered for a class property, we need to get the property type.
   */
  if (!typeOrIdentifier && propertyName) {
    const identifier = reflection.getMetadata('design:type', target, propertyName as string | symbol);

    typeWrapper = { id: identifier, lazyType: () => identifier };
  }

  /**
   * If no explicit type is set and handler registered for a constructor parameter, we need to get the parameter types.
   */
  if (!typeOrIdentifier && typeof index == 'number' && Number.isInteger(index)) {
    const paramTypes: ServiceIdentifier[] = designMetadataAccessor.getDesignParamTypes(target, propertyName as string);
    const identifier = paramTypes?.[index];

    typeWrapper = { id: identifier, lazyType: () => identifier };
  }

  return typeWrapper;
}
