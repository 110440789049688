import { InspectionReportImpl } from '@application/bundles/capacity-authorization/implementations/inspection-report';
import { GetCapacityAuthorizationQuery } from '@application/bundles/capacity-authorization/queries/get-capacity-authorization.query';
import { QueryBus } from '@application/framework/command-query';
import { DateFormatter } from '@application/framework/date';
import { DateParser } from '@application/framework/date/date.parser';
import { Container } from '@application/framework/di/container';
import { AsyncTransformer } from '@application/framework/core';
import { CapacityAuthorization, CapacityAuthorizationProperties } from '@domain/capacity-authorization';
import { MediaBucket } from '@domain/media';
import { CapacityAuthorizationImpl } from '@implementations/bundles/backend/easyhpad/implementations/capacity-authorization';
import { EasyCapacityAuthorization } from '@implementations/bundles/backend/easyhpad/interfaces/easy-capacity-authorization.interface';
import { EasyCapacityAuthorizationAbstractTransformer } from '@implementations/bundles/backend/easyhpad/transformers/capacity-authorization/easy-capacity-authorization.abstract.transformer';

export class EasyCapacityAuthorizationTransformer
  extends EasyCapacityAuthorizationAbstractTransformer
  implements AsyncTransformer<CapacityAuthorization, EasyCapacityAuthorization>
{
  private readonly queryBus: QueryBus;

  private readonly mediaBucket: MediaBucket;

  constructor(private readonly container: Container) {
    super(container.get(DateParser), container.get(DateFormatter));

    this.queryBus = this.container.get(QueryBus);
    this.mediaBucket = this.container.get(MediaBucket);
  }

  public async transform(value: CapacityAuthorization): Promise<EasyCapacityAuthorization> {
    const reports = await value.inspectionReports();

    return {
      ...this.transformCommonProperties(value),
      id: value.id,
      authorization: undefined,
      facility: undefined,
      updatedAt: value.updatedAt ? this.dateFormatter.toUTCIsoString(value.updatedAt) : undefined,
      createdAt: value.createdAt ? this.dateFormatter.toUTCIsoString(value.createdAt) : undefined,
      inspections: reports.map(report => ({
        id: report.id,
        date: this.dateFormatter.toUTCIsoString(report.date),
        mediaIds: report.mediaIds !== undefined ? report.mediaIds : [],
      })),
    };
  }

  public async reverseTransform(value: EasyCapacityAuthorization): Promise<CapacityAuthorization> {
    const properties: CapacityAuthorizationProperties = {
      ...this.reverseTransformCommonProperties(value),
      id: value.id,
      createdAt: value.createdAt ? this.dateParser.fromISOString(value.createdAt) : undefined,
      updatedAt: value.updatedAt ? this.dateParser.fromISOString(value.updatedAt) : undefined,
      authorizationId: value.authorizationId,
      pvId: value.pvId ? value.pvId : undefined,
    };

    const reports = value.inspections.map(report => {
      const values = {
        id: report.id,
        date: this.dateParser.fromISOString(report.date),
        mediaIds: report.mediaIds,
      };

      const authorizationFn = () => this.queryBus.request(new GetCapacityAuthorizationQuery(value.id));
      return new InspectionReportImpl(values, authorizationFn, this.mediaBucket, this.dateFormatter);
    });

    return new CapacityAuthorizationImpl(this.queryBus, this.mediaBucket, properties, reports);
  }
}
