import { PasswordCheckResult, PasswordStrengthChecker } from '@application/bundles/user';

export class AgnosticPasswordStrengthChecker implements PasswordStrengthChecker {
  public readonly specialChar = new Set(['!', '%', '&', '@', '#', '$', '^', '*', '?', '_', '~']);

  public readonly minLength = 8;

  public check(password: string): PasswordCheckResult {
    const result: PasswordCheckResult = {
      strength: 0,
      requirements: {
        lowUpper: false,
        number: false,
        length: false,
        specialChar: false,
      },
      valid: false,
    };

    if (typeof password !== 'string' || !password) {
      return result;
    }

    result.requirements.lowUpper = !!password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/);

    //If password contains both lower and uppercase characters
    if (result.requirements.lowUpper) {
      result.strength += 1;
    }

    //If it has numbers and characters
    result.requirements.number = !!password.match(/([0-9])/);

    if (result.requirements.number) {
      result.strength += 1;
    }

    result.requirements.specialChar = !!password.match(
      new RegExp(`([${Array.from(this.specialChar.values()).join(',')}])`),
    );
    //If it has one special character
    if (result.requirements.specialChar) {
      result.strength += 1;
    }

    //If password is greater than 8
    result.requirements.length = password.length >= this.minLength;

    if (result.requirements.length) {
      result.strength += 1;
    }

    result.valid = Object.values(result.requirements).filter((r) => !r).length === 0;
    return result;
  }

  public getProposal(): string {
    const password = this.generateProposal();

    if (!this.check(password).valid) {
      return this.getProposal();
    }

    return password;
  }

  private generateProposal(): string {
    const password: string[] = [];

    for (let i = 0; i < this.minLength; i += 2) {
      password.push(this.getRandomNumber());
      password.push(this.getRandomSymbol());
      password.push(this.getRandomLower());
      password.push(this.getRandomUpper());
    }

    return this.shuffle(password).join('').slice(0, this.minLength);
  }

  private getRandomLower() {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
  }

  private getRandomUpper() {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
  }

  private getRandomNumber() {
    return String.fromCharCode(Math.floor(Math.random() * 10) + 48);
  }

  private getRandomSymbol() {
    const symbols = Array.from(this.specialChar);

    return symbols[Math.floor(Math.random() * symbols.length)];
  }

  private shuffle(array: string[]): string[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  }
}
