<header class="header document-list-header">
  <div class="title">{{'Nom du document' | ehp_translate}}</div>
  <div class="accounting-year">{{'Année comptable' | ehp_translate}}</div>
  <div class="total">{{'Total' | ehp_translate}}</div>
  <div class="funding">{{'Crédits reconductibles' | ehp_translate}}</div>
  <div class="funding">{{'Crédits non reconductibles' | ehp_translate}}</div>
  <div class="facility">{{'Établissement' | ehp_translate}}</div>
  <div class="actions">{{'Actions' | ehp_translate}}</div>
</header>


<ng-container *ngIf="documents && documents.length > 0; else emptyList">

  <ng-container *ngFor="let document of documents">
    <ehp-health-funding-list-item [healthFunding]="document"></ehp-health-funding-list-item>
  </ng-container>
</ng-container>

<ng-template #emptyList>
  <ehp-empty-block>{{'Aucun financement soin archivé pour le moment.' | ehp_translate}}</ehp-empty-block>
</ng-template>
