import {CPOMRepository, GetCurrentCPOMQuery} from "@application/bundles/cpom";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {CPOM} from "@domain/cpom";

@HandleQuery({
  query: GetCurrentCPOMQuery
})
export class GetCurrentCPOMQueryHandler implements QueryHandler<GetCurrentCPOMQuery, CPOM | undefined> {


  constructor(private readonly repository: CPOMRepository) {
  }

  public handle(query: GetCurrentCPOMQuery): Promise<CPOM | undefined> {
    return this.repository.current(query.facilityId);
  }
}
