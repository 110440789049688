<ng-container *ngIf="field">

  <h2 *ngIf="field.title" class="title form-row-title">
    <ehp-translatable-string [value]="field.title"></ehp-translatable-string>
  </h2>

  <ehp-dynamic-notice-list *ngIf="notices" [notices]="notices"></ehp-dynamic-notice-list>

  <div [ngClass]="containerClass">
    <ehp-dynamic-form-element *ngFor="let f of fields" [field]="f"></ehp-dynamic-form-element>
  </div>

</ng-container>
