import {Dialog} from "@angular/cdk/dialog";
import {CommandBus, CommandHandler, HandleCommand} from "@application/framework/command-query";
import {
  ConfirmDeletionDialogComponent
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component";
import {
  ConfirmDeletionDialogData
} from "@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface";
import {DialogConfiguration} from "@easyhpad-ui/app/library/dialog";
import {
  OpenEhpadDemainDeletionDialogCommand
} from "@easyhpad-ui/app/bundles/ehpad-demain/commands/open-ehpad-demain-deletion-dialog.command";
import {EhpadDemain} from "@domain/ehpad-demain";
import {DeleteEhpadDemainCommand} from "@application/bundles/ehpad-demain";

@HandleCommand({
  command: OpenEhpadDemainDeletionDialogCommand
})
export class OpenGMPDeletionDialogCommandHandler implements CommandHandler<OpenEhpadDemainDeletionDialogCommand, void> {

  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus
  ) {
  }

  public async handle(command: OpenEhpadDemainDeletionDialogCommand): Promise<void> {
    const {document, onDeletion} = command;


    const data: ConfirmDeletionDialogData = {
      options: [{key: document.id, label: document.name}]
    }

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<EhpadDemain>(new DeleteEhpadDemainCommand(document));

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    }

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, {...DialogConfiguration, data});
    dialog.closed.subscribe(subscriber);
  }

}
