import { BehaviorSubject, Observable } from 'rxjs';

export interface DocumentList<D> {
  readonly count: number;

  readonly loading: Observable<boolean>;

  readonly documents: Observable<Array<D>>;
}

export interface WritableDocumentList<D> extends DocumentList<D>, Iterable<D> {
  set(documents: Array<D>): void;

  setLoading(loading: boolean): void;
}

export class DocumentListImpl<D> implements WritableDocumentList<D> {
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private documents$ = new BehaviorSubject<Array<D>>([]);

  private observables: { documents?: Observable<Array<D>>; loading?: Observable<boolean> } = {};

  public get loading(): Observable<boolean> {
    if (!this.observables.loading) {
      this.observables.loading = this.loading$.asObservable();
    }

    return this.observables.loading;
  }

  public get documents(): Observable<Array<D>> {
    if (!this.observables.documents) {
      this.observables.documents = this.documents$.asObservable();
    }

    return this.observables.documents;
  }

  public get count(): number {
    return Array.from(this.documents$.value).length;
  }

  public [Symbol.iterator](): Iterator<D> {
    return this.documents$.value[Symbol.iterator]();
  }

  public set(documents: Array<D>): void {
    this.documents$.next(documents);
  }

  public setLoading(loading: boolean) {
    if (loading !== this.loading$.value) {
      this.loading$.next(loading);
    }
  }
}
