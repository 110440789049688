import {
  ClassSansProvider,
  ConstructorSansProvider,
  FactorySansProvider,
  StaticClassSansProvider,
  ValueSansProvider
} from "@angular/core";
import {extractDependencies} from "@application/framework/di";
import {ServiceMetadata} from "@application/framework/di/interfaces/service-metadata.interface";
import {isClassProvider, isFactoryProvider, isValueProvider} from "@application/framework/di/providers";

type Provider =
  ValueSansProvider
  | StaticClassSansProvider
  | ConstructorSansProvider
  | FactorySansProvider
  | ClassSansProvider;


export const createInjectableProviderFromMetadata = (metadata: ServiceMetadata): Provider => {

  let token: Provider = {};
  let dependencies = extractDependencies(metadata.id);

  if (isClassProvider(metadata)) {
    dependencies = extractDependencies(metadata.useClass);

    token = {
      useClass: metadata.useClass,
      deps: metadata.dependencies || dependencies
    }
  } else if (isFactoryProvider(metadata)) {
    token = {
      useFactory: metadata.useFactory,
      deps: dependencies
    }
  } else if (isValueProvider(metadata)) {
    token = {
      useValue: metadata.useValue
    }

  } else {
    throw new Error(`InjectableProvider cannot be created. Please verify metadata (id: ${(metadata as any).id.toString()})`);
  }

  return token;
}
