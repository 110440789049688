import {CreateDaycareDependencyFundingDto} from "@application/bundles/dependency-funding";
import {
  DAYCARE_DEPENDENCIES_FUNDING_MODES,
  DEPENDENCIES_FUNDING_MODES,
  isDaycareDependencyFundingModeValue
} from "@domain/dependency-funding";
import {isNegativeFundingAmount} from "@domain/funding";
import {isValidObject} from "@application/framework/lib";
import {IsOptional, IsString} from "class-validator";
import {IsNegativeFundingAmount} from "@implementations/bundles/funding/validators";

export class CreateDaycareDependencyFundingDtoImpl<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = DEPENDENCIES_FUNDING_MODES.UNKNOWN, V = any> implements CreateDaycareDependencyFundingDto<M, V> {

  public mode!: DAYCARE_DEPENDENCIES_FUNDING_MODES;

  public value!: V;

  @IsNegativeFundingAmount()
  @IsOptional()
  public activityAllowance: number | undefined;

  @IsString()
  @IsOptional()
  public comment?: string | undefined;

  constructor(values?: Partial<CreateDaycareDependencyFundingDto<M, V>>) {

    if (isValidObject(values)) {

      this.value = values?.value as V;

      if (isDaycareDependencyFundingModeValue(values?.mode)) {
        this.mode = values?.mode;
      }

      if (isNegativeFundingAmount(values?.activityAllowance)) {
        this.activityAllowance = values?.activityAllowance;
      }

      if (typeof values?.comment === "string" && values.comment) {
        this.comment = values.comment;
      }

    }
  }

}
