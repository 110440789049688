import {EPRDSearchParams} from "../eprd-search-params";
import {EPRD} from "@domain/eprd-errd";
import {CreateEPRDDto, UpdateEPRDDto} from "../dto";

export abstract class EPRDRepository {

  public abstract list(params?: EPRDSearchParams): Promise<EPRD[]>;

  public abstract get(id: EPRD['id']): Promise<EPRD>;

  public abstract create(eprd: CreateEPRDDto): Promise<EPRD>;

  public abstract update(id: EPRD['id'], update: Partial<UpdateEPRDDto>): Promise<EPRD>;

  public abstract delete(id: EPRD['id']): Promise<EPRD>;
}
