<ng-container *ngIf="(options | async) as opts; else wait">
  <p-dropdown (onChange)="dropdownChange()" [(ngModel)]="selected" [options]="opts" [placeholder]="placeholder"
              optionLabel="label" optionValue="value">

    <ng-template pTemplate="selectedItem">
      <ng-container *ngIf="selectedOption">
        {{selectedOption.label}}
      </ng-container>
    </ng-template>

    <ng-template let-option pTemplate="item">
      {{ option.label }}
    </ng-template>

  </p-dropdown>

</ng-container>

<ng-template #wait>

  <div *ngIf="loading" class="form-element loading-element">
    <span class="loader"></span>
    {{ 'Chargement des données' | ehp_translate }}
  </div>

</ng-template>
