import { BuildMediaPublicUrlQuery } from '@application/bundles/media/queries';
import { CommandHandler, HandleQuery } from '@application/framework/command-query';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';

@HandleQuery({
  query: BuildMediaPublicUrlQuery,
})
export class BuildMediaPublicUrlQueryHandler implements CommandHandler<BuildMediaPublicUrlQuery, URL> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: BuildMediaPublicUrlQuery): Promise<URL> {
    const response = await this.backend.get<{ url: string }>(`media/${query.mediaId}/url`);
    return new URL(response.body.url);
  }
}
