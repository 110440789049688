import {NgModule} from "@angular/core";
import {EventDispatcher} from "@application/framework/event";
import {EventSubscribersRegistry} from "@application/framework/event/event-subscribers.registry";
import {PrioritizedSubscriberRegistry} from "@application/framework/event/implementations";
import {EventDispatcherImpl} from "@implementations/framework/events";

@NgModule({
  providers: [
    {
      provide: EventSubscribersRegistry,
      useClass: PrioritizedSubscriberRegistry,
    },
    {
      provide: EventDispatcher,
      useFactory: (registry: EventSubscribersRegistry) => new EventDispatcherImpl(registry),
      deps: [EventSubscribersRegistry]
    },
  ]
})
export class EventModule {

}
