import { Logger, LogLevel } from '@application/framework/logger';

export class ConsoleLogger implements Logger {
  private loggers: Map<string, Logger> = new Map();

  private chan: string | undefined;

  public constructor(
    private name: string,
    public level: LogLevel = LogLevel.DEBUG,
    private silent = false,
  ) {}

  public channel(name: string): Logger {
    if (!this.loggers.has(name)) {
      const logger = new ConsoleLogger(this.name, this.level, this.silent);
      logger.chan = name;
      this.loggers.set(name, logger);
    }

    return this.loggers.get(name) as Logger;
  }

  public info(message: string, ...args: any[]) {
    this.write(LogLevel.INFO, message, ...args);
  }

  public debug(message: string, ...args: any[]): void {
    this.write(LogLevel.DEBUG, message, ...args);
  }

  public warning(message: string, ...args: any[]): void {
    this.write(LogLevel.WARNING, message, ...args);
  }

  public error(message: string, ...args: any[]): void {
    this.write(LogLevel.ERROR, message, ...args);
  }

  private write(level: LogLevel, message: string, ...args: any[]): void {
    if (this.silent || this.level > level) {
      return;
    }

    switch (level) {
      case LogLevel.INFO:
        console.info(...this.getHeading('INFO'), message, ...args);
        break;
      case LogLevel.DEBUG:
        console.debug(...this.getHeading('DEBUG'), message, ...args);
        break;
      case LogLevel.WARNING:
        console.warn(...this.getHeading('WARNING'), message, ...args);
        break;
      case LogLevel.ERROR:
        console.error(...this.getHeading('ERROR'), message, ...args);
        break;
      case LogLevel.CRITICAL:
        console.error(...this.getHeading('CRITICAL'), message, ...args);
        break;
      default:
        console.log(...this.getHeading('LOG'), message, ...args);
    }
  }

  private getHeading(messageType: string, color?: string): string[] {
    let h = `%c[${this.name}] %c| ${new Date().toUTCString()} | `;
    const colors = ['color: #699E9E', 'color:inherit'];

    if (this.chan && this.chan !== '') {
      h = h + `%c( ${this.chan} )`;
      colors.push(`font-weight:bold`);
    }

    if (color) {
      h = h + `%c${messageType} :: `;
      colors.push(`color: ${color ? color : 'inherit'}`);
    }

    return [h, ...colors];
  }
}
