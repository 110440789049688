import { Query } from '@application/framework/command-query';
import { FullDataTableWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces/widgets/full-data-table-widget';
import { Facility } from '@domain/facility';

export class GetFullDataTableWidgetQuery implements Query<FullDataTableWidgetContent> {
  constructor(
    public readonly facilities?: Array<Facility['id']>,
    public readonly filters?: Array<{ key: string; value: string | number | Date | boolean; op: string }>,
  ) {}
}
