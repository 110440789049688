import {CommandBus} from "@application/framework/command-query";
import {Container} from "@application/framework/di/container";
import {HttpAuthorizationTokenStore} from "@application/framework/http";
import {EasyBackendApi} from "@implementations/bundles/backend/easyhpad/api";
import {
  EasyHpadBearerHttpTokenFactory
} from "@implementations/bundles/backend/easyhpad/factories/bearer-http-token-factory";
import {
  EasyHpadAutoReconnectUseCase
} from "@implementations/bundles/backend/easyhpad/use-cases/auto-reconnect/auto-reconnect.use-case";
import {EasyHpadLoginUseCase} from "@implementations/bundles/backend/easyhpad/use-cases/login/login.use-case";
import {
  EasyHpadLogoutUseCase
} from "@implementations/bundles/backend/easyhpad/use-cases/logout/easy-hpad-logout-use.case";

export class EasyHpadUseCasesLibrary {


  constructor(private container: Container) {
  }


  public getLoginUseCase(): EasyHpadLoginUseCase {

    return new EasyHpadLoginUseCase(
      this.container.get(EasyBackendApi),
      this.container.get(EasyHpadBearerHttpTokenFactory),
      this.container.get(HttpAuthorizationTokenStore),
      this.container.get(CommandBus)
    );
  }

  public getLogoutUseCase(): EasyHpadLogoutUseCase {
    return new EasyHpadLogoutUseCase(
      this.container.get(EasyBackendApi),
      this.container.get(HttpAuthorizationTokenStore),
    );
  }

  public getAutoReconnectUseCase(): EasyHpadAutoReconnectUseCase {
    return new EasyHpadAutoReconnectUseCase(
      this.container.get(EasyBackendApi),
      this.container.get(HttpAuthorizationTokenStore),
      this.container.get(CommandBus)
    );
  }
}
