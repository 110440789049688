export enum DOCUMENT_TYPES {
  CAPACITY_AUTHORIZATION = 'capacity_authorization',
  CPOM = 'cpom',
  GMP = 'gmp',
  PMP = 'pmp',
  EHPAD_DEMAIN = 'ehpad_demain',
  ACCOMMODATION_FUNDING = 'accommodation_funding',
  DEPENDENCY_FUNDING = 'dependency_funding',
  HEALTH_FUNDING = 'health_funding',
  EPRD = 'eprd',
  ERRD = 'errd',
  ACTIVITY_ANNEX = 'activity_annex',
}
