import {Customer} from "@domain/customer";
import {Sort} from "@application/framework/search/sort";
import {Serializable} from "@application/framework/lib";
import {PaginationParams} from "@application/framework/search";


export type CustomerSortKey = keyof Pick<Customer, 'id' | 'name' | 'blocked' | 'phone' | 'createdAt'>


export interface CustomerSearch {

  name?: string | undefined;
}


export interface SerializedCustomerSearchParams {

  sort?: Sort<CustomerSortKey>;

  search?: CustomerSearch;

  pagination?: PaginationParams;
}


export abstract class CustomerSearchParams implements Serializable<SerializedCustomerSearchParams> {


  public abstract setSort(option: Sort<CustomerSortKey> | null | undefined): this;

  public abstract searchName(name: string | null | undefined): this;

  public abstract setPagination(pagination: PaginationParams | null): this

  public abstract serialize(): SerializedCustomerSearchParams;
}
