import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EhpadDemain} from "@domain/ehpad-demain";
import {Facility} from "@domain/facility";
import {Media} from "@domain/media";

@Component({
  selector: 'ehp-ehpad-demain-list-item',
  templateUrl: './ehpad-demain-list-item.component.html',
  styleUrls: ['./ehpad-demain-list-item.component.scss'],
  host: {
    'class': 'document-list-item'
  }

})
export class EhpadDemainListItemComponent implements OnChanges {

  @Input() public document!: EhpadDemain;

  public facilities: Array<Facility> = [];

  private convention: Media | undefined;

  private otherConvention: Media | undefined;

  public ngOnChanges(changes: SimpleChanges) {

    if (changes['document']) {
      this.documentHasChange();
    }
  }

  private async documentHasChange() {

    if (this.document) {
      this.document.facilities().then(facilities => this.facilities = facilities);
      this.document.convention().then(convention => this.convention = convention);
      this.document.otherConvention().then(convention => this.otherConvention = convention);
    }
  }
}
