<ehp-form-field>
  <label *ngIf="label" [innerHTML]="label" for="filter-year"></label>
  <input
    (ngModelChange)="yearHasChange($event)"
    [(ngModel)]="year"
    ehp-form-control
    id="filter-year"
    min="0"
    placeholder="{{'Année' | ehp_translate }}"
    type="number"
  >
</ehp-form-field>
