import {HttpAuthorizationToken} from "@application/framework/http/authorization.token";
import {Store, StoreKey} from "@application/framework/store";


export abstract class HttpAuthorizationTokenStore implements Store<HttpAuthorizationToken<any>> {

  public abstract clear(): void;

  public abstract has(key: StoreKey): boolean;

  public abstract get(key: StoreKey): HttpAuthorizationToken<any> | undefined;

  public abstract set(key: StoreKey, value: HttpAuthorizationToken<any> | undefined): void;

  public abstract remove(key: StoreKey): void;

}
