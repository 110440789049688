import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CustomerModule} from "@easyhpad-ui/app/bundles/customer/customer.module";
import {AdminRoutingModule} from "./admin-routing.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AdminRoutingModule,
    CustomerModule
  ]
})
export class AdminModule {
}
