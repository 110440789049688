import {PhoneFormatter} from "@application/bundles/phone/formatter/phone.formatter";
import {configuration} from "@easyhpad-ui/environments/configuration";
import {PhoneNumberFormat, PhoneNumberUtil} from "google-libphonenumber";

export class GooglePhoneNumberFormatter implements PhoneFormatter {

  private readonly utils: PhoneNumberUtil = PhoneNumberUtil.getInstance();

  /**
   * @param number
   * @param region
   */
  public formatNumberString(number: string, region?: string): string {
    region = region ?? configuration.defaultPhoneNumberRegion;
    return this.utils.format(this.utils.parse(number, region), PhoneNumberFormat.NATIONAL);
  }

}
