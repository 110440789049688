import { AsyncTransformer } from '@application/framework/core';
import { CreateUser } from '@domain/user';
import { EasyCreateUser } from '@implementations/bundles/backend/easyhpad/interfaces';
import { EasyUserCommonTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-user-common.transformer';
import { Role } from '@domain/authorization';

export class EasyCreateUserTransformer implements AsyncTransformer<CreateUser, EasyCreateUser> {
  public constructor(private readonly commonTransformer: EasyUserCommonTransformer) {}

  public async transform(value: CreateUser): Promise<EasyCreateUser> {
    const common = await this.commonTransformer.transform(value);

    return {
      ...common,
      facilityIds: value.facilityIds ?? [],
      roles: Array.isArray(value.roles) ? value.roles.map(r => r.name) : undefined,
    };
  }

  public async reverseTransform(value: EasyCreateUser): Promise<CreateUser> {
    const common = await this.commonTransformer.reverseTransform(value);

    return {
      ...common,
      facilityIds: value.facilityIds,
      roles: Array.isArray(value.roles) ? value.roles.map(name => ({ name, label: name }) as Role) : [],
    };
  }
}
