import {Attribute, AuthorizationChecker} from "@application/bundles/authorization";
import {ForbiddenError} from "@application/bundles/authorization/error";
import {FACILITY_FEATURE, FACILITY_TRANSLATE_CONTEXT} from "@application/bundles/facility";
import {CreateFacilityCommand} from "@application/bundles/facility/commands/create-facility.command";
import {CreateFacilityDto} from "@application/bundles/facility/dto";
import {FacilityCreatedEvent} from "@application/bundles/facility/events/facility-created.event";
import {FacilityCreationFailEvent} from "@application/bundles/facility/events/facility-creation-fail.event";
import {HandleCommand} from "@application/framework/command-query";
import {CommandHandler} from "@application/framework/command-query/handler.interface";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {SanitizationFailError} from "@application/framework/sanitizer/errors";
import {SanitizerLibrary} from "@application/framework/sanitizer/sanitizer";
import {Translator} from "@application/framework/translation";
import {ValidationError} from "@application/framework/validator/errors/validation.error";
import {ObjectValidator} from "@application/framework/validator/object-validator";
import {Facility} from "@domain/facility";
import {FacilityRepository} from "@domain/facility/facility.repository";

@HandleCommand({
  command: CreateFacilityCommand
})
export class CreateFacilityCommandHandler implements CommandHandler<CreateFacilityCommand, Facility> {

  @ProvideLogger()
  private readonly logger!: Logger;

  private readonly errorNormalizer = new ErrorNormalizer();

  public constructor(
    private readonly repository: FacilityRepository,
    private readonly authorization: AuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizer: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly translator: Translator,
  ) {
  }

  public async handle(command: CreateFacilityCommand): Promise<Facility> {

    const {facility} = command;

    try {
      await this.checkAccess(facility);

      const created = await this.sanitize(facility)
        .then(facility => this.validate(facility))
        .then(facility => this.repository.create(facility));

      this.eventDispatcher.dispatch(new FacilityCreatedEvent(created));

      return created;

    } catch (e) {
      const error = this.catchError(e);
      return Promise.reject(error);
    }

  }

  private async checkAccess(facility: CreateFacilityDto): Promise<void> {

    const attributes: Attribute[] = [
      {feature: FACILITY_FEATURE, value: 'create'}
    ];

    if (!await this.authorization.isGranted(attributes, facility)) {
      this.logger.error('Facility creation : Forbidden');
      throw new ForbiddenError();
    }
  }

  private async sanitize(facility: CreateFacilityDto): Promise<CreateFacilityDto> {
    try {
      facility = await this.sanitizer.sanitize(facility);
    } catch (e: any) {
      this.logger.warning('Facility creation : Sanitizer fail');
      throw e;
    }

    return facility;
  }

  private async validate(facility: CreateFacilityDto): Promise<CreateFacilityDto> {
    try {
      facility = await this.validator.validate(facility);
    } catch (e: any) {
      this.logger.warning('Facility creation : Validator fail');
      throw e;
    }

    return facility;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = await this.translator.translate('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à créer un nouvel établissement.",
        undefined,
        FACILITY_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new FacilityCreationFailEvent(message));

    return error;
  }

}
