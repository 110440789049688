import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {DependencyFunding} from "@domain/dependency-funding";

export class DependencyFundingDeletedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly dependencyFunding: DependencyFunding) {
    super();
  }
}
