import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {
  AddressOneLineComponent
} from "@easyhpad-ui/app/bundles/address/components/address-one-line/address-one-line.component";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {FormModule} from "@easyhpad-ui/app/library/form/form.module";
import {AddressInputComponent} from './components/address-input/address-input.component';


@NgModule({
  declarations: [
    AddressOneLineComponent,
    AddressInputComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormModule,
    TranslationModule
  ],
  exports: [
    AddressOneLineComponent,
    AddressInputComponent
  ]
})
export class AddressModule {
}
