import { DeleteFileDepositItemCommand } from '@application/bundles/file-deposit/commands';
import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { joinPath } from '@application/framework/lib';
import { FileDeposit, FileDepositItem, isFileDepositId } from '@domain/file-deposit';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { FILE_DEPOSIT_URI } from '@implementations/bundles/backend/easyhpad/config/file-deposit';
import { parseBackendResponse } from '@implementations/bundles/backend/functions';

@HandleCommand({
  command: DeleteFileDepositItemCommand,
})
export class DeleteFileDepositItemCommandHandler implements CommandHandler<DeleteFileDepositItemCommand> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(command: DeleteFileDepositItemCommand): Promise<FileDepositItem> {
    const { id, itemId } = command;

    return this.backend
      .delete<{ entity: any }>(this.buildEndpoint(id, itemId))
      .then(response => parseBackendResponse(response))
      .then((body: any) => body.entity);
  }

  private buildEndpoint(id: FileDeposit['id'], itemId: FileDepositItem['id']): string {
    if (!isFileDepositId(id)) {
      throw new Error(`${id} is not a valid FileDeposit ID`);
    }

    return joinPath(FILE_DEPOSIT_URI, `${id}/items/${itemId}`);
  }
}
