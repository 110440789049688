import {SerializedEhpadDemainDocumentParams} from "@application/bundles/ehpad-demain";
import {Query} from "@application/framework/command-query";
import {EhpadDemain} from "@domain/ehpad-demain";

export class ListEhpadDemainQuery implements Query<EhpadDemain[]> {

  constructor(public readonly params?: SerializedEhpadDemainDocumentParams) {
  }

}
