import { Transformer } from '@application/framework/core';
import { isValidEstateDegradation, LocalERRD } from '@domain/eprd-errd';
import { EasyLocalERRD } from '@implementations/bundles/backend/easyhpad/interfaces/eprd-errd/easy-local-errd';
import { LocalERRDImpl } from '@implementations/bundles/eprd-errd/implementations';
import { EasyLocalCommonTransformer } from './common-local.abstract.transformer';
import { Provide, Service } from '@application/framework/di';
import { Common } from '@application/framework/lib';
import { CreateLocalERRDDto, UpdateLocalERRDDto } from '@application/bundles/eprd-errd';
import { CreateEasyLocalERRD, UpdateEasyLocalERRD } from '@implementations/bundles/backend/easyhpad';
import { EasyTPERTransformer } from '@implementations/bundles/backend/easyhpad/transformers/errd-errd/easy-ter.transformer';
import { isPercentage } from '@domain/lib/math/percentage/percentage';
import { isValidFundingAmount } from '@domain/funding';

type CommonLocalDtoProperties = Common<CreateLocalERRDDto, UpdateLocalERRDDto>;

type CommonEasyLocalDtoProperties = Common<CreateEasyLocalERRD, UpdateEasyLocalERRD>;

@Service()
export class EasyLocalErrdTransformer
  extends EasyLocalCommonTransformer
  implements Transformer<LocalERRD, EasyLocalERRD>
{
  @Provide()
  private readonly tperTransformer!: EasyTPERTransformer;

  public override transform(value: LocalERRD): EasyLocalERRD {
    return {
      ...super.transform(value),
      id: value.id,
      parentId: value.parentId,
      revenueAmount: value.expense,
      chargeAmount: value.revenue,
      financialIndependenceRate: value.independenceRate,
      realEstateDegradation: value.estateDegradation,
      tper: value.tper.map(item => this.tperTransformer.transform(item)),
      usedTreasury: value.usedTreasury,
      occupationRate: value.activityAnnex.occupationRate,
      actualDaysRealized: value.activityAnnex.days,
    };
  }

  public override reverseTransform(value: EasyLocalERRD): LocalERRD {
    const defaults: Omit<LocalERRD, 'parent' | 'facility' | 'refreshParent' | 'occupationRate'> = {
      ...super.reverseTransform(value),
      id: value.id,
      estateDegradation: isValidEstateDegradation(value.realEstateDegradation)
        ? value.realEstateDegradation
        : undefined,
      independenceRate: isPercentage(value.financialIndependenceRate) ? value.financialIndependenceRate : undefined,
      parentId: value.parentId,
      revenue: value.revenueAmount,
      usedTreasury: isValidFundingAmount(value.usedTreasury) ? value.usedTreasury : undefined,
      tper: Array.isArray(value.tper) ? value.tper.map(item => this.tperTransformer.reverseTransform(item)) : [],
    };

    return new LocalERRDImpl(defaults);
  }

  public getLocalCreateDto(dto: CreateLocalERRDDto): CreateEasyLocalERRD {
    return {
      ...this.getCommonLocalDtoProperties(dto),
    };
  }

  public getLocalUpdateDto(dto: UpdateLocalERRDDto): UpdateEasyLocalERRD {
    return {
      ...this.getCommonLocalDtoProperties(dto),
      id: dto.id,
    };
  }

  public getCommonLocalDtoProperties(dto: CommonLocalDtoProperties): CommonEasyLocalDtoProperties {
    return {
      ...super.transform(dto),
      financialIndependenceRate: dto.independenceRate,
      realEstateDegradation: dto.estateDegradation,
      tper: dto.tper.map(item => this.tperTransformer.transform(item)),
      usedTreasury: dto.usedTreasury,
      revenueAmount: dto.revenue || 0,
      chargeAmount: dto.expense || 0,
      occupationRate:
        typeof dto.activityAnnex?.occupationRate === 'number' ? dto.activityAnnex.occupationRate : undefined,
      actualDaysRealized: typeof dto.activityAnnex?.days === 'number' ? dto.activityAnnex.days : undefined,
    };
  }
}
