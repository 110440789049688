import { Service } from '@application/framework/di';
import { AsyncReadOnlyCollection } from '@application/framework/store/collection';
import { EHPAD_DEMAIN_TYPE, EhpadDemainType } from '@domain/ehpad-demain';

@Service()
export class EhpadDemainTypeCollection implements AsyncReadOnlyCollection<EhpadDemainType> {
  private readonly collection: Map<EHPAD_DEMAIN_TYPE, string> = new Map([
    [EHPAD_DEMAIN_TYPE.EHPAD_OUTSIDE, 'Ehpad hors les murs'],
    [EHPAD_DEMAIN_TYPE.TEMPORARY_LODGING, 'Hébergement temporaire pour sortie d’hospitalisation'],
    [EHPAD_DEMAIN_TYPE.THIRD_PLACE, 'Tiers lieu'],
    [EHPAD_DEMAIN_TYPE.TERRITORIAL_RESOURCE_CENTER, 'Centre de ressources territorial'],
    [EHPAD_DEMAIN_TYPE.OTHER, 'Autre'],
  ]);

  public async has(reference: EHPAD_DEMAIN_TYPE): Promise<boolean> {
    return this.collection.has(reference);
  }

  public async list(): Promise<EhpadDemainType[]> {
    return Array.from(this.collection.entries()).map(([type, label]) => ({ type, label }));
  }

  public async get(reference: EHPAD_DEMAIN_TYPE): Promise<EhpadDemainType | undefined> {
    if (!this.collection.has(reference)) {
      return;
    }

    return { type: reference, label: this.collection.get(reference) as string };
  }
}
