import {
  StartApplicationCommandHandler
} from "@application/bundles/application/commands/handlers/start-application/start-application.command.handler";
import {
  ApplicationStartEventSubscriber
} from "@application/bundles/application/events/subscribers/application-start/application-start.event.subscriber";
import {Bundle} from "@application/framework/bundle";
import {Container} from "@application/framework/di/container";

@Bundle({
  commandHandlers: [
    StartApplicationCommandHandler
  ],
  eventSubscribers: [
    ApplicationStartEventSubscriber
  ]
})
export class ApplicationBundle {

  private static instance: ApplicationBundle | undefined;

  private constructor(private container: Container) {
  }

  /**
   * Build new instance of bundle
   * @param container
   */
  public static build(container: Container): ApplicationBundle {

    if (!(ApplicationBundle.instance instanceof ApplicationBundle)) {
      ApplicationBundle.instance = new ApplicationBundle(container);
    }

    return ApplicationBundle.instance;
  }

}
