import { ERRD } from '@domain/eprd-errd';
import { Service } from '@application/framework/di';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Service()
export class ERRDAuthorizationChecker {
  constructor(private readonly authorization: DocumentAuthorization) {}

  public canRead(errd?: ERRD) {
    return this.authorization.canReadDocument(errd);
  }

  public canCreate() {
    return this.authorization.canCreateDocument();
  }

  public canUpdate(errd?: ERRD) {
    return this.authorization.canEditDocument(errd);
  }

  public canDelete(errd?: ERRD) {
    return this.authorization.canDeleteDocument(errd);
  }
}
