import {Component, Input} from '@angular/core';

@Component({
  selector: 'ehp-blocked-icon',
  templateUrl: './blocked-icon.component.html',
  styleUrls: ['./blocked-icon.component.scss']
})
export class BlockedIconComponent {

  @Input() active: boolean | undefined = false;

}
