import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {AuthorizationChecker} from "@application/bundles/authorization";
import {CapacityAuthorizationAuthorizationChecker} from "@application/bundles/capacity-authorization";
import {
  AddCapacityAuthorizationBuilder
} from "@application/bundles/capacity-authorization/builders/add-capacity-authorization.builder";
import {CapacityAuthorizationBundle} from "@application/bundles/capacity-authorization/capacity-authorization.bundle";
import {CapacityAuthorizationDtoFactory} from "@application/bundles/capacity-authorization/factories";
import {
  InspectionReportDtoFactory
} from "@application/bundles/capacity-authorization/factories/inspection-report.dto.factory";
import {Bundle} from "@application/framework/bundle";
import {
  InMemoryAgnosticCollection
} from "@application/framework/store/implementations/agnostic-collection/in-memory-agnostic.collection";
import {CapacityAuthorizationRepository} from "@domain/capacity-authorization/capacity-authorization.repository";
import {AddressModule} from "@easyhpad-ui/app/bundles/address/address.module";
import {
  ADD_CAPACITY_AUTHORIZATION_ASSEMBLER
} from "@easyhpad-ui/app/bundles/capacity-authorization/capacity-authorization-injection.token";
import {
  OpenDeleteCapacityAuthorizationDialogCommandHandler
} from "@easyhpad-ui/app/bundles/capacity-authorization/commands/handlers/open-delete-capacity-authorization-dialog/open-delete-inspection-report-dialog.command.handler";
import {
  OpenDeleteInspectionReportDialogCommandHandler
} from "@easyhpad-ui/app/bundles/capacity-authorization/commands/handlers/open-delete-inspection-report-dialog.command.handler";
import {
  OpenEditInspectionReportDialogCommandHandler
} from "@easyhpad-ui/app/bundles/capacity-authorization/commands/handlers/open-edit-inspection-report-dialog.command.handler";
import {
  CapacityAuthorizationValidators
} from "@easyhpad-ui/app/bundles/capacity-authorization/validators/capacity-authorization.validators";
import {ReusableComponentsModule} from "@easyhpad-ui/app/bundles/reusable-components/reusable-components.module";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {ValidatorModule} from "@easyhpad-ui/app/framework/validator/validator.module";
import {ResizableModule} from "@easyhpad-ui/app/library/resizable/resizable.module";
import {
  EasyCapacityAuthorizationRepository
} from "@implementations/bundles/backend/easyhpad/repositories/capacity-authorization/easy-capacity-authorization.repository";
import {CapacityAuthorizationDtoFactoryImpl} from "@implementations/bundles/capacity-authorization";
import {
  addCapacityAuthorizationAssembler
} from "@implementations/bundles/capacity-authorization/assemblers/add-capacity-authorization.assembler";
import {
  AddCapacityAuthorizationDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/add-capacity-authorization.dto";
import {
  AddInspectionReportDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/add-inspection-report.dto";
import {
  UpdateInspectionReportDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/update-inspection-report.dto";
import {
  InspectionReportDtoFactoryImpl
} from "@implementations/bundles/capacity-authorization/factories/inspection-report.dto.factory";
import {
  validateAddCapacityAuthorizationDtoImpl
} from "@implementations/bundles/capacity-authorization/validators/add-capacity-authorization.validator";
import {
  validateAddInspectionReportDtoImpl,
  validateUpdateInspectionReportDtoImpl
} from "@implementations/bundles/capacity-authorization/validators/inspection-report.validator";
import {MediaModule} from "../media/media.module";
import {
  FacilityCapacityAuthorizationWidgetComponent
} from './components/widget-facility-capacity-authorization/facility-capacity-authorization-widget.component';
import {
  CapacityAuthorizationUrlsProvider
} from "@easyhpad-ui/app/bundles/capacity-authorization/providers/capacity-authorization-urls/capacity-authorization-urls.provider";
import {
  validateUpdateCapacityAuthorizationDtoImpl
} from "@implementations/bundles/capacity-authorization/validators/capacity-authorization-update-dto.validator";
import {
  UpdateCapacityAuthorizationDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/update-capacity-authorization.dto";
import {TooltipModule} from "primeng/tooltip";
import {DialogModule} from "@angular/cdk/dialog";


@NgModule({
  imports: [
    CommonModule,
    ValidatorModule.forChild({
      validators: [
        {objectType: AddCapacityAuthorizationDtoImpl, validator: validateAddCapacityAuthorizationDtoImpl},
        {objectType: UpdateCapacityAuthorizationDtoImpl, validator: validateUpdateCapacityAuthorizationDtoImpl},
        {objectType: AddInspectionReportDtoImpl, validator: validateAddInspectionReportDtoImpl},
        {objectType: UpdateInspectionReportDtoImpl, validator: validateUpdateInspectionReportDtoImpl}
      ]
    }),
    ReusableComponentsModule,
    TranslationModule,
    MediaModule,
    AddressModule,
    ResizableModule,
    CdkTableModule,
    RouterModule,
    TooltipModule,
    DialogModule
  ],
  declarations: [
    FacilityCapacityAuthorizationWidgetComponent,
  ],
  providers: [
    CapacityAuthorizationValidators,
    CapacityAuthorizationUrlsProvider,
    {
      provide: CapacityAuthorizationRepository,
      useClass: EasyCapacityAuthorizationRepository
    },
    {
      provide: ADD_CAPACITY_AUTHORIZATION_ASSEMBLER,
      useValue: addCapacityAuthorizationAssembler,
    },
    {
      provide: AddCapacityAuthorizationBuilder,
      useClass: AddCapacityAuthorizationBuilder,
      deps: [new InMemoryAgnosticCollection(), ADD_CAPACITY_AUTHORIZATION_ASSEMBLER]
    },
    {
      provide: CapacityAuthorizationDtoFactory,
      useClass: CapacityAuthorizationDtoFactoryImpl
    },
    {
      provide: CapacityAuthorizationAuthorizationChecker,
      useClass: CapacityAuthorizationAuthorizationChecker,
      deps: [AuthorizationChecker]
    },
    {
      provide: InspectionReportDtoFactory,
      useClass: InspectionReportDtoFactoryImpl
    }
  ],
})
@Bundle({
  imports: [
    CapacityAuthorizationBundle
  ],
  commandHandlers: [
    OpenDeleteCapacityAuthorizationDialogCommandHandler,
    OpenEditInspectionReportDialogCommandHandler,
    OpenDeleteInspectionReportDialogCommandHandler,

  ]
})
export class CapacityAuthorizationModule {

}
