import {EPRDRepository} from "@application/bundles/eprd-errd";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {EPRD} from "@domain/eprd-errd";
import {AgnosticEPRDSearchParams} from "@application/bundles/eprd-errd/implementations";
import {ListEPRDForYearQuery} from "@application/bundles/eprd-errd/queries/list-eprd-for-year.query";
import {getDatesIntervalForYear} from "@domain/lib/date/year";

@HandleQuery({
  query: ListEPRDForYearQuery
})
export class ListEPRDForYearQueryHandler implements QueryHandler<ListEPRDForYearQuery> {

  constructor(private readonly repository: EPRDRepository) {
  }

  public async handle(query: ListEPRDForYearQuery): Promise<EPRD[]> {

    const dates = getDatesIntervalForYear(query.year);
    const params = new AgnosticEPRDSearchParams()
      .setDates(dates.start, dates.end);

    return await this.repository.list(params);
  }
}
