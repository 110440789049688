import {ETP_CATEGORIES} from '@domain/etp/etp-categories';
import {Year} from '@domain/lib';

/**
 * Represents the amount of an E.T.P. for an entire year.
 *
 * @since 1.1.0
 */
export interface CostByETP {
  category: ETP_CATEGORIES;

  year: Year;

  /**
   * The Amount must always be positive.
   */
  amount: number;
}
