import {Subscription} from "@application/framework/reactive";
import {AsyncCollection} from "@application/framework/store/collection";
import {Facility} from "@domain/facility";
import {Subject} from "rxjs";

export class DashboardFacilitiesStore {

  private readonly change$: Subject<Facility[]> = new Subject<Facility[]>();

  public constructor(private readonly collection: AsyncCollection<Facility>) {
  }
  
  public get(): Promise<Facility[]> {
    return this.collection.list();
  }


  public async set(...facilities: Facility[]): Promise<void> {
    await this.collection.clear();
    facilities.forEach(facility => this.collection.set(facility.id, facility));
    this.change$.next(facilities);
  }

  public async clear(): Promise<void> {
    await this.collection.clear();
    this.change$.next([]);
  }

  public onChange(subscriber: (facilities: Facility[]) => any): Subscription {
    return this.change$.subscribe(subscriber);
  }

}
