import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Facility } from '@domain/facility';
import { ListboxChangeEvent } from 'primeng/listbox';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isMaybeAFacility, isValidFacilityIdType } from '@application/bundles/facility';

@Component({
  selector: 'ehp-multi-facility-select',
  templateUrl: './multi-facility-select.component.html',
  styleUrls: ['./multi-facility-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiFacilitySelectComponent),
      multi: true,
    },
  ],
})
export class MultiFacilitySelectComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public mode: 'list' | 'dropdown' = 'dropdown';

  @Input() public disabled = false;

  @Input() public loading$: Observable<boolean> | undefined;

  @Input() public source$: Observable<Facility[]> | undefined;

  @Output('onChange') public onChange$ = new EventEmitter<Facility[]>();

  public facilities: Array<Facility> = [];

  public selection: Array<Facility['id']> = [];

  public readonly optionLabel = 'name';

  private sourceSubscription$: Subscription | undefined;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('source$' in changes) {
      this.sourceSubscription$?.unsubscribe();

      if (changes['source$'].currentValue instanceof Observable) {
        this.sourceSubscription$ = this.source$?.subscribe(facilities => this.updateFacilityOptions(facilities));
      }
    }
  }

  public ngOnDestroy(): void {
    this.sourceSubscription$?.unsubscribe();
  }

  public onChange = (value: Array<Facility['id']> | null | undefined): void => undefined;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched = (): void => undefined;

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: Facility['id'] | Array<Facility['id'] | Facility> | Facility | null | undefined): void {
    if (value === null || value === undefined) {
      this.selection = [];
      return;
    }

    if (!Array.isArray(value)) {
      value = [value];
    }

    this.selection = value
      .map(entry => {
        if (isValidFacilityIdType(entry)) {
          return entry;
        } else if (isMaybeAFacility(entry)) {
          return entry.id;
        }

        return null;
      })
      .filter(id => !!id) as Array<Facility['id']>;
  }

  public selectionChange(changes: ListboxChangeEvent | MultiSelectChangeEvent): void {
    const ids: Array<Facility['id']> = changes.value;
    const facilities = this.facilities.filter(facility => ids.includes(facility.id));

    this.onChange(ids);
    this.onChange$.emit(facilities);
  }

  private updateFacilityOptions(facilities: Array<Facility>): void {
    const ids = new Set(facilities.map(f => f.id));

    const remove = [...this.selection.filter(id => !ids.has(id))];

    if (remove.length > 0) {
      this.selection = this.selection.filter(current => !remove.findIndex(id => id === current));
    }

    this.facilities = facilities;
  }
}
