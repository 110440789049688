<ng-container *ngIf="collection && collection.size > 0">

  <thead>
  <tr class="total-container">
    <th class="name-cell">{{'Nom du financement' | ehp_translate}}</th>
    <th class="amount-cell">{{ 'Montant' | ehp_translate }}</th>
  </tr>
  </thead>

  <tbody>
  <tr *ngFor="let row of collection" class="row">

    <td class="name-cell">
      <span [innerHTML]="row.name" class="name"></span>

      <div *ngIf="row.comment" [innerHTML]="row.comment" class="comment"></div>
    </td>

    <td class="amount-cell">
      <span class="amount">{{row.amount| currency}}</span>
    </td>

  </tr>

  </tbody>

  <tfoot>
  <tr class="total-container">
    <td class="name-cell"></td>
    <td class="amount-cell">{{ collection.sum()| currency:'EUR' }}</td>
  </tr>
  </tfoot>

</ng-container>
