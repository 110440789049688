import {Command} from "@application/framework/command-query/command.interface";

export abstract class CommandBus {

  /**
   * Execute command
   * @param command
   */
  public abstract execute<R>(command: Command<R>): Promise<R>;
}
