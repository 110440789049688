import { Customer } from '@domain/customer';
import { CustomerSearchParams } from '@application/bundles/customer/repositories/customer-search-params';
import { EasyCustomer } from '@implementations/bundles/backend/easyhpad/interfaces/easy-customer.interface';
import {
  EasyCustomerCreateDtoTransformer,
  EasyCustomerTransformer,
} from '@implementations/bundles/backend/easyhpad/transformers';
import { EasyCustomerUpdateDtoTransformer } from '@implementations/bundles/backend/easyhpad/transformers/customer/easy-customer-update-dto/easy-customer-update-dto.transformer';
import { CustomerRepository } from '@application/bundles/customer';
import { RequestParams } from '@application/framework/http/request.interface';

import { Provide } from '@application/framework/di';
import { CreateCustomerDto } from '@application/bundles/customer/dto/create-customer.dto';
import { UpdateCustomerDto } from '@application/bundles/customer/dto/update-customer.dto';
import { EasyEntityRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';

export class EasyCustomerRepository
  extends EasyEntityRepository<Customer, EasyCustomer, Customer['id'], CustomerSearchParams, CreateCustomerDto>
  implements CustomerRepository
{
  @Provide() private entityTransformer!: EasyCustomerTransformer;
  @Provide() private createTransformer!: EasyCustomerCreateDtoTransformer;
  @Provide() private updateTransformer!: EasyCustomerUpdateDtoTransformer;

  public constructor() {
    super('customer', {
      from: input => this.entityTransformer.transform(input),
      to: output => this.entityTransformer.reverseTransform(output),
      create: (input: CreateCustomerDto) => this.createTransformer.transform(input),
      update: (input: UpdateCustomerDto) => this.updateTransformer.transform(input),
    });
  }

  protected override convertParams(params?: CustomerSearchParams): RequestParams {
    if (params === undefined) {
      return {};
    }
    return this.searchTransformer.toRequestParams(
      this.searchTransformer.fromSerializedCustomerSearchParams(params.serialize()),
    );
  }
}
