import {values} from "@domain/lib/object/object";

export enum CRPPAccountNumber {

  Account641 = '641',
  Account642 = '642',
  Account643 = '643',
  Account644 = '644',
  Account645 = '645',
  Account646 = '646',
  Account647 = '647',
  Account648 = '648',
}


export const CRRPAccountNumberValues = new Set<CRPPAccountNumber>(values(CRPPAccountNumber));

export interface CRPP {

  account: CRPPAccountNumber;

  value: number;
}
