import {VoteResult} from "@application/bundles/authorization/vote-results.type";
import {AuthentificationToken} from "@application/bundles/authentification";

export enum ATTRIBUTE_SCOPE {
  OWNED
}

export interface Attribute<A = any> {

  feature: string | Symbol;

  value: A;

  scope?: ATTRIBUTE_SCOPE
}


export interface Voter<A = any> {

  vote(token: AuthentificationToken, subject: any, attributes: Attribute[]): Promise<VoteResult>;
}
