import { GeoService } from '@application/bundles/geo/services/geo.service';
import { Facility } from '@domain/facility';
import { Department } from '@domain/geo';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { Service } from '@application/framework/di';

@Service()
export class EasyGeoService extends GeoService implements GeoService {
  @ProvideLogger()
  private readonly logger!: Logger;

  constructor(private readonly backend: EasyBackendApi) {
    super();
  }

  public async getDepartmentOfFacility(facility: Facility): Promise<Department | undefined> {
    const postalCode = facility.address.postalCode;

    return this.backend
      .get<{ entity: { department: Department | null } }>(`geo/postal-code/${postalCode}`)
      .then(response => response.body)
      .then(body => body.entity.department ?? undefined)
      .catch(error => {
        this.logger.error(error.message);
        return undefined;
      });
  }
}
