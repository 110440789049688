import { createAction, props } from '@ngrx/store';
import { Facility } from '@domain/facility';
import { DashboardStoreState } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.state';

export enum DASHBOARD_ACTION_TYPES {
  SET_VIEW_MODE = '[Dashboard] Set view mode',
  SET_FACILITIES = '[Dashboard] Set facilities',
  RESET = '[Dashboard] Reset state',
}

const setDashboardMode = createAction(
  DASHBOARD_ACTION_TYPES.SET_VIEW_MODE,
  props<{
    mode: DashboardStoreState['view'];
  }>(),
);

const setFacilities = createAction(
  DASHBOARD_ACTION_TYPES.SET_FACILITIES,
  props<{
    facilityIds: Array<Facility['id']>;
  }>(),
);

const reset = createAction(DASHBOARD_ACTION_TYPES.RESET);

export const DashboardActions = {
  setDashboardMode,
  setFacilities,
  reset,
};
