import {InvalidPhoneNumberError} from "@application/bundles/phone/errors";
import {PhoneValidator} from "@application/bundles/phone/validator/phone.validator";
import {PhoneNumber, PhoneRegionCode} from "@domain/phone/phone.interface";
import {configuration} from "@easyhpad-ui/environments/configuration";
import {PhoneNumberImpl} from "@implementations/bundles/phone/implementations/phone-number";
import {PhoneNumberUtil} from "google-libphonenumber";

export class GooglePhoneValidator implements PhoneValidator {

  private readonly utils: PhoneNumberUtil;

  constructor() {
    this.utils = PhoneNumberUtil.getInstance();
  }

  public async validate(phone: PhoneNumber): Promise<PhoneNumber> {

    const test = this.utils.parse(phone.number, phone.region);
    
    if (!this.utils.isValidNumber(test)) {
      throw new InvalidPhoneNumberError();
    }

    return phone;

  }

  public async validatePhoneString(phone: string, region: PhoneRegionCode = configuration.defaultPhoneNumberRegion): Promise<PhoneNumber> {

    return this.validate(new PhoneNumberImpl(phone, region));
  }


  public isValidPhone(phone: PhoneNumber): Promise<boolean> {
    return this.validate(phone)
      .then(() => true)
      .catch(() => false);
  }

}
