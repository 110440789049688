import {
  CurrentUserAccessor
} from "@application/bundles/user/implementations/current-user-accessor/current-user.accessor";
import {GetCurrentUserQuery} from "@application/bundles/user/queries/get-current-user.query";
import {HandleQuery} from "@application/framework/command-query";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {User} from "@domain/user";

@HandleQuery({
  query: GetCurrentUserQuery
})
export class GetCurrentUserQueryHandler implements QueryHandler<GetCurrentUserQuery, User | undefined> {


  constructor(private readonly accessor: CurrentUserAccessor) {
  }

  public async handle(query: GetCurrentUserQuery): Promise<User | undefined> {
    return this.accessor.get();
  }
}
