import {ResetRequestFailEvent} from "@application/bundles/authentification/events/reset-request-fail.event";
import {ResetRequestSuccessEvent} from "@application/bundles/authentification/events/reset-request-success.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";


@HasEventSubscribers()
export class OnResetPasswordRequestEventSubscriber {

  public constructor(
    private readonly noticeStream: NoticeStream,
  ) {
  }

  /**
   * Push notice on reset password request
   * @param event
   */
  @OnEvent({
    event: ResetRequestSuccessEvent
  })
  public async pushSuccessNotice(event: ResetRequestSuccessEvent) {

    const message = new TranslatableString(
      'Votre demande de réinitialisation de mot de passe à été fait. Un e-mail détaillant la procédure vient de vous être envoyé.'
    );

    this.noticeStream.push({message, type: NoticeType.SUCCESS});
  }

  /**
   * Push notice on reset password request failure
   * @param event
   */
  @OnEvent({
    event: ResetRequestFailEvent
  })
  public async pushFailNotice(event: ResetRequestFailEvent) {

    const message = new TranslatableString('Une erreur est survenue pendant la demande de réinitialisation. Merci de réessayer.')
    this.noticeStream.push({message, type: NoticeType.ERROR});
  }
}
