import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CPOMCreatedEvent,
  CPOMCreationFailEvent,
  CPOMRepository,
  CreateCPOMCommand,
  CreateCPOMDto,
} from '@application/bundles/cpom';
import { CPOMAuthorizationChecker } from '@application/bundles/cpom/cpom-authorization-checker';
import { CPOM_TRANSLATE_CONTEXT } from '@application/bundles/cpom/cpom.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { CPOM } from '@domain/cpom';
import { isLocalMedia, MediaBucket, TemporaryMedia } from '@domain/media';

@HandleCommand({
  command: CreateCPOMCommand,
})
export class CreateCPOMCommandHandler implements CommandHandler<CreateCPOMCommand, CPOM> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: CPOMRepository,
    private readonly authorization: CPOMAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: CreateCPOMCommand): Promise<CPOM> {
    const { cpom } = command;
    try {
      const created = await this.sanitize(cpom)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.storePvAndCreateCPOM(dto));

      this.eventDispatcher.dispatch(new CPOMCreatedEvent(created));

      return created;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: CreateCPOMDto): Promise<CreateCPOMDto> {
    if (!(await this.authorization.canCreate())) {
      this.logger.error('C.P.O.M. creation : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: CreateCPOMDto): Promise<CreateCPOMDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('C.P.O.M. creation : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: CreateCPOMDto): Promise<CreateCPOMDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('C.P.O.M. creation : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à créer un nouveau C.P.O.M..",
        undefined,
        CPOM_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new CPOMCreationFailEvent(message));

    return error;
  }

  private async storePvAndCreateCPOM(dto: CreateCPOMDto): Promise<CPOM> {
    const medias: Array<TemporaryMedia> = [];

    try {
      if (dto.document) {
        const document = await this.mediaBucket.add(dto.document);
        medias.push(document);
        dto.documentId = document.id;
      }

      if (Array.isArray(dto.annexes)) {
        for (const [index, annex] of dto.annexes.entries()) {
          if (isLocalMedia(annex.media)) {
            const media = await this.mediaBucket.add(annex.media);
            medias.push(media);
            dto.annexes[index].mediaId = media.id;
          }
        }
      }

      return this.repository.create(dto);
    } catch (e) {
      await Promise.all(medias.map(media => media.markForDeletion()));
      throw e;
    }
  }
}
