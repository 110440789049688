import { Service } from '@application/framework/di';
import { CPOM } from '@domain/cpom';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Service()
export class CPOMAuthorizationChecker {
  constructor(private readonly authorization: DocumentAuthorization) {}

  public canRead(cpom?: CPOM) {
    return this.authorization.canReadDocument(cpom);
  }

  public canCreate() {
    return this.authorization.canCreateDocument();
  }

  public canUpdate(cpom: CPOM) {
    return this.authorization.canEditDocument(cpom);
  }

  public canDelete(cpom: CPOM) {
    return this.authorization.canDeleteDocument(cpom);
  }
}
