import {AbstractControl, ValidatorFn} from "@angular/forms";

export const isValidAddress: ValidatorFn = (control: AbstractControl) => {

  if (!control.value) {
    return null;
  }

  if (control.value.addressLine1 && control.value.postalCode && control.value.locality) {
    return null;
  }

  return {invalidAddress: true};
}
