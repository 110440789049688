import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComputableResidentGirListImpl } from '@application/bundles/gir';
import { GMPCalculator } from '@application/bundles/gmp';
import { GIR, GIRLabels, GIRValues, isValidResidentTotal } from '@domain/gir';
import { configuration } from '@easyhpad-ui/environments/configuration';

@Component({
  selector: 'ehp-gmp-calculator',
  templateUrl: './gmp-calculator.component.html',
  styleUrls: ['./gmp-calculator.component.scss'],
})
export class GmpCalculatorComponent {
  public form: FormGroup<Record<GIR, FormControl<number | null>>>;

  public GIR = GIR;

  public value: number = 0;

  public residents: number = 0;

  public GIRValues = GIRValues;

  private readonly calculator: GMPCalculator = new GMPCalculator();

  constructor(private readonly formBuilder: FormBuilder) {
    this.calculator.setPoints(configuration.pointsGirList);

    this.form = this.formBuilder.group({
      [GIR.GIR1]: new FormControl(0, [Validators.required, Validators.min(0)]),
      [GIR.GIR2]: new FormControl(0, [Validators.required, Validators.min(0)]),
      [GIR.GIR3]: new FormControl(0, [Validators.required, Validators.min(0)]),
      [GIR.GIR4]: new FormControl(0, [Validators.required, Validators.min(0)]),
      [GIR.GIR5]: new FormControl(0, [Validators.required, Validators.min(0)]),
      [GIR.GIR6]: new FormControl(0, [Validators.required, Validators.min(0)]),
    });
    this.form.valueChanges.subscribe(() => this.updateResidentList());
  }

  public label(gir: GIR): string {
    return GIRLabels.get(gir) || gir;
  }

  private updateResidentList(): void {
    this.calculator.setResidents(
      new ComputableResidentGirListImpl(
        new Map([
          [GIR.GIR1, isValidResidentTotal(this.form.value.GIR1) ? (this.form.value.GIR1 as number) : 0],
          [GIR.GIR2, isValidResidentTotal(this.form.value.GIR2) ? (this.form.value.GIR2 as number) : 0],
          [GIR.GIR3, isValidResidentTotal(this.form.value.GIR3) ? (this.form.value.GIR3 as number) : 0],
          [GIR.GIR4, isValidResidentTotal(this.form.value.GIR4) ? (this.form.value.GIR4 as number) : 0],
          [GIR.GIR5, isValidResidentTotal(this.form.value.GIR5) ? (this.form.value.GIR5 as number) : 0],
          [GIR.GIR6, isValidResidentTotal(this.form.value.GIR6) ? (this.form.value.GIR6 as number) : 0],
        ]),
      ),
    );

    this.value = this.calculator.compute();
    this.residents = this.calculator.getResidentTotal();
  }
}
