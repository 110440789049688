<header class="widget-header">
  <h3 class="widget-title title">{{'E.P.R.D / E.R.R.D' | ehp_translate }}</h3>
</header>

<div class="widget-content">

  <div *ngFor="let column of columns" class="block {{column.type}}">
    <h4 class="title block-title">
      <ehp-translatable-string
        *ngIf="column.data && column.data.year"
        [replacement]="{year: column.data.year, type: column.name}"
        [value]="label">
      </ehp-translatable-string>
    </h4>

    <div class="data">
      <ng-container *ngTemplateOutlet="column.data ? dataTpl : emptyTpl; context: { $implicit: column }">
      </ng-container>
    </div>
  </div>
</div>

<div class="widget-footer"></div>


<ng-template #dataTpl let-column>

  <span [ngClass]="{'negative': column.data.value < 0}" class="amount">
    {{column.data.value | currency}}
  </span>

  <span [ngClass]="{'negative': column.data.caf < 0}" class="caf">
    <span class="label">{{'C.A.F.' | ehp_translate}}</span> :
    <span class="value">
      {{ column.data.caf | currency}}
    </span>
  </span>

  <ehp-average-message
    *ngIf="!isSingle && column.data.average"
    [total]="column.data.average.total"
    [value]="column.data.average.value"
    class="average-message">
  </ehp-average-message>

</ng-template>

<ng-template #emptyTpl let-column>

  <div class="empty">
    <ehp-plural [count]="count">

      <ehp-translatable-string
        [replacement]="{document: column.name}"
        [value]="empty.plural"
        plural></ehp-translatable-string>

      <ehp-translatable-string
        [replacement]="{document: column.name}"
        [value]="empty.single"
        single></ehp-translatable-string>

    </ehp-plural>

    <a *ngIf="actionLink(column.type) as link" [routerLink]="link" class="target-link">
      {{'Nouvel archivage' | ehp_translate}}
    </a>
  </div>

</ng-template>

