import {CrudPermissionVoter} from "@application/bundles/authorization/voters/crud-permission/crud-permission.voter";
import {
  CreateUserCommandHandler,
  DeleteUsersCommandHandler,
  StoreCurrentUserCommandHandler,
  UpdateCurrentUserCommandHandler,
  UpdateUserCommandHandler
} from "@application/bundles/user/commands/handlers";
import {
  OnUserAuthentificationSubscriber,
  OnUserCreationHandler,
  OnUserDeletionSubscriber,
  OnUserResetPasswordSubscriber,
  OnUserUpdateSubscriber,
  UpdateCurrentUserSubscriber
} from "@application/bundles/user/events/subscribers";

import {
  UserAuthorizationTokenFactory
} from "@application/bundles/user/factories/authorization-token/authorization-token.factory";

import {
  GetCurrentUserQueryHandler,
  GetInitialTrainingValuesHandler,
  GetJobValuesHandler,
  GetStudyLevelValuesQueryHandler,
  ListUserHandler
} from "@application/bundles/user/queries/handlers";
import {USER_FEATURE} from "@application/bundles/user/user.token";
import {Bundle} from "@application/framework/bundle";
import {AbstractBundle} from "@application/framework/bundle/bundle.abstract";
import {Container} from "@application/framework/di/container";
import {User} from "@domain/user";
import {UserAuthorizationChecker} from "@application/bundles/user/user-authorization-checker";

@Bundle({
  commandHandlers: [
    CreateUserCommandHandler,
    UpdateUserCommandHandler,
    DeleteUsersCommandHandler,
    StoreCurrentUserCommandHandler,
    UpdateCurrentUserCommandHandler
  ],
  queryHandlers: [
    GetInitialTrainingValuesHandler,
    GetJobValuesHandler,
    GetStudyLevelValuesQueryHandler,
    ListUserHandler,
    GetCurrentUserQueryHandler
  ],
  eventSubscribers: [
    OnUserAuthentificationSubscriber,
    OnUserDeletionSubscriber,
    OnUserCreationHandler,
    OnUserUpdateSubscriber,
    OnUserResetPasswordSubscriber,
    UpdateCurrentUserSubscriber
  ],
  providers: [
    {provide: UserAuthorizationChecker, useClass: UserAuthorizationChecker}
  ],
  voters: [
    new CrudPermissionVoter<User>(
      USER_FEATURE,
      (subject, token) => Promise.resolve(subject.id === token.id)
    )
  ]
})
export class UserBundle extends AbstractBundle {

  private static instance: UserBundle | undefined;

  private constructor(container: Container) {
    super(container);
    this.container.bind(UserAuthorizationTokenFactory, UserAuthorizationTokenFactory, ...[Container]);
  }

  public static build(container: Container): UserBundle {
    if (!(UserBundle.instance instanceof UserBundle)) {
      UserBundle.instance = new UserBundle(container);
    }

    return UserBundle.instance;
  }


}
