import {AuthorizationChecker, CRUD_ACTIONS} from "@application/bundles/authorization";
import {AssignRolesToUserCommand} from "@application/bundles/authorization/commands/assign-roles-to-user.command";
import {RoleRepository} from "@application/bundles/authorization/role.repository";
import {USER_FEATURE} from "@application/bundles/user/user.token";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {User, UserRepository} from "@domain/user";

@HandleCommand({
  command: AssignRolesToUserCommand
})
export class AssignRolesToUserCommandHandler implements CommandHandler<AssignRolesToUserCommand, User> {
  
  constructor(
    private readonly authorizationChecker: AuthorizationChecker,
    private readonly roleRepository: RoleRepository,
    private readonly userRepository: UserRepository
  ) {
  }

  public async handle(command: AssignRolesToUserCommand): Promise<User> {

    await this.authorizationChecker.isGranted([
      {feature: USER_FEATURE, value: CRUD_ACTIONS.UPDATE}
    ], command.user);

    await this.roleRepository.assignRolesToUser(command.user.id, command.roles);

    return await this.userRepository.get(command.user.id);
  }
}
