import { LocalCPOM } from '@domain/cpom';
import { StoredLocalCPOM } from '@application/bundles/cpom';
import { isValidFacilityIdType } from '@application/bundles/facility';
import { isUUID } from '@domain/lib';

export function isValidLocalCPOMIdType(id: any): id is StoredLocalCPOM['id'] {
  return isUUID(id);
}

export function hasFacilityReference(
  cpom: Partial<Pick<StoredLocalCPOM, 'facilityId'>>,
): cpom is LocalCPOM & Pick<StoredLocalCPOM, 'facilityId'> {
  return 'facilityId' in cpom && isValidFacilityIdType((cpom as any).facilityId);
}
