import {ClassProvider, isClassProvider} from "./class.provider";
import {FactoryProvider, isFactoryProvider} from "./factory.provider";
import {isValueProvider, ValueProvider} from "./value.provider";

export type Provider<T = any> =
  | ClassProvider<T>
  | ValueProvider<T>
  | FactoryProvider<T>;

export function isProvider(provider: any): provider is Provider {
  return (
    isClassProvider(provider) ||
    isValueProvider(provider) ||
    isFactoryProvider(provider)
  );
}

