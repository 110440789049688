import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RequestParams } from '@application/framework/http/request.interface';
import { PMPWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';
import { GetPmpWidgetContentQuery } from '@easyhpad-ui/app/bundles/dashboard/queries/get-pmp-widget-content.query';
import { DateParser } from '@application/framework/date';

type BackendWidgetContent = Omit<PMPWidgetContent, 'date'> & { date?: string };

@HandleQuery({
  query: GetPmpWidgetContentQuery,
})
export class GetPmpWidgetContentQueryHandler implements QueryHandler<GetPmpWidgetContentQuery, PMPWidgetContent> {
  constructor(
    private readonly backend: EasyBackendApi,
    private readonly parser: DateParser,
  ) {}

  public async handle(query: GetPmpWidgetContentQuery): Promise<PMPWidgetContent> {
    const params: RequestParams = {};

    if (Array.isArray(query.ids) && query.ids.length > 0) {
      params['facility_ids'] = query.ids.join(', ');
    }

    const response = await this.backend.get<BackendWidgetContent>('/widgets/pmp', params);
    return this.buildContent(response.body);
  }

  private buildContent(content: BackendWidgetContent): PMPWidgetContent {
    let date: Date | undefined;

    if (typeof content.date === 'string' && content.date) {
      date = this.parser.fromISOString(content.date);
    }

    return { ...content, date };
  }
}
