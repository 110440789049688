import {createAction, props} from '@ngrx/store';


export enum ERROR_ACTION_TYPES {
  DISPLAY_ERROR = '[Error] Display Error',
  CATCH_ERROR = '[Error] Catch Error'
}

const catchError = createAction(ERROR_ACTION_TYPES.CATCH_ERROR, props<{ error: Error }>());

const displayError = createAction(ERROR_ACTION_TYPES.DISPLAY_ERROR, props<{ error?: Error }>());

export const ErrorActions = Object.freeze({
  catchError,
  displayError,
})
