export function isValidFundingAmount(amount: any): amount is number {
  return typeof (amount as any) === 'number' && isFinite(amount);
}

export function isPositiveFundingAmount(amount: any): amount is number {
  return isValidFundingAmount(amount) && amount >= 0
}


export function isNegativeFundingAmount(amount: any): amount is number {
  return isValidFundingAmount(amount) && amount <= 0
}

