import {
  AuthentificationStrategyCredentials
} from "@application/bundles/authentification/abstraction/authentication-strategy";


export interface EasyhpadBackendAuthCredentials {
  email: string,
  password: string
}

export interface EasyBackendResetPasswordCredentials {
  email: string
}


export interface EasyhpadBackendAuthentificationStrategyCredentials extends AuthentificationStrategyCredentials<EasyhpadBackendAuthCredentials> {
}




