import {ComponentRef} from "@angular/core";
import {POSITION} from "@application/framework/lib";
import {LocalMedia, Media} from "@domain/media";
import {MediasViewerComponent} from "@easyhpad-ui/app/bundles/media/components/medias-viewer/medias-viewer.component";
import {MediaViewerMode} from "@easyhpad-ui/app/bundles/media/interfaces/media-viewer-options.interface";
import {Observable, Subscription} from "rxjs";
import {MediaViewerPreviewList} from "@easyhpad-ui/app/bundles/media";


export class MediaViewerRef {

  private destroySubscription: Subscription | undefined;

  constructor(private readonly componentRef: ComponentRef<MediasViewerComponent>) {
    this.componentRef.instance.destroyed.subscribe(() => this.close());
    this.componentRef.onDestroy(() => this.destroySubscription?.unsubscribe());
  }

  public setMode(mode: MediaViewerMode): this {
    this.componentRef.setInput('mode', mode);
    return this;
  }

  public setPosition(position: POSITION): this {
    this.componentRef.setInput('position', position);
    return this;
  }

  public setClosable(closable: boolean): this {
    this.componentRef.setInput('closable', closable);
    return this;
  }

  public setFullscreen(fullscreen: boolean): this {
    this.componentRef.setInput('fullscreen', fullscreen);
    return this;
  }

  public setMedias(medias: Array<LocalMedia | Media>): this {
    this.componentRef.setInput('medias', new MediaViewerPreviewList(medias));
    return this;
  }


  public dispatchChange(): this {
    this.componentRef.changeDetectorRef.detectChanges();
    return this;
  }

  public modeChange(): Observable<MediaViewerMode> {
    return this.componentRef.instance.modeChange.asObservable();
  }

  public openMedia(media: Media | LocalMedia) {
    this.componentRef.instance.open(media);
  }

  public close() {
    this.componentRef.destroy();
  }

  public closed(callback: Function) {
    this.componentRef.onDestroy(callback);
  }
}
