import { isValidLocalEPRDIdType, UpdateLocalEPRDDto } from '@application/bundles/eprd-errd';
import { CreateLocalEPRDDtoImpl } from './create-local-eprd.dto';
import { IsUUID } from 'class-validator';

export class UpdateLocalEPRDDtoImpl extends CreateLocalEPRDDtoImpl implements UpdateLocalEPRDDto {
  @IsUUID()
  public id!: UpdateLocalEPRDDto['id'];

  constructor(defaults?: Partial<UpdateLocalEPRDDto>) {
    super(defaults);

    if (defaults && isValidLocalEPRDIdType(defaults.id)) {
      this.id = defaults.id;
    }
  }
}
