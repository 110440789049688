import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { ValidateFileDepositItemsQuery } from '@application/bundles/file-deposit';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { FileDeposit, isFileDepositId } from '@domain/file-deposit';
import { ExpectedMediaList } from '@application/bundles/file-deposit/contracts';

@HandleQuery({
  query: ValidateFileDepositItemsQuery,
})
export class ValidateFileDepositItemsQueryHandler implements QueryHandler<ValidateFileDepositItemsQuery> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: ValidateFileDepositItemsQuery): Promise<any> {
    return this.backend
      .post<{ result: ExpectedMediaList }>(this.buildEndpoint(query.id), { items: query.items })
      .then(response => response.body)
      .then(body => body.result);
  }

  private buildEndpoint(id: FileDeposit['id']): string {
    if (!isFileDepositId(id)) {
      throw new Error(`${id} is not a valid FileDeposit ID`);
    }
    return `/file-deposits/entities/${id}/items/validate`;
  }
}
