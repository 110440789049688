import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from '@easyhpad-ui/app/store';
import { Store } from '@ngrx/store';
import { FILE_DEPOT_STATES, FileDeposit } from '@domain/file-deposit';
import { FileDepositActions, selectOnboardingEntities } from '@easyhpad-ui/app/bundles/file-deposit/store';
import { selectIsFacilityOnboardingValidated } from '@easyhpad-ui/app/store/global.selector';
import { GlobalActions } from '@easyhpad-ui/app/store/global.action';

@Component({
  selector: 'ehp-facility-onboarding-page',
  templateUrl: './facility-onboarding-page.component.html',
  styleUrls: [
    './facility-onboarding-page.component.scss',
    '../../../../../file-deposit/styles/file-deposit-list-item.scss',
    '../../../../../file-deposit/styles/file-deposit-list.scss',
  ],
})
export class FacilityOnboardingPageComponent implements OnDestroy {
  public state: 'list' | 'message' | 'form' = 'list';

  public selected: FileDeposit | undefined;

  public deposits: Array<FileDeposit> = [];

  public validationStep: boolean = false;

  protected readonly FILE_DEPOT_STATES = FILE_DEPOT_STATES;

  private destroy$ = new Subject<void>();

  @HostBinding('class')
  public get classes() {
    const classes: string[] = [];

    if (this.selected && !this.validationStep) {
      classes.push('has-form');
    }
    if (!this.selected && !this.validationStep && this.deposits) {
      classes.push('has-list');
    }

    return classes.join(' ');
  }

  constructor(private readonly store: Store<AppState>) {
    this.store
      .select(selectOnboardingEntities)
      .pipe(takeUntil(this.destroy$))
      .subscribe(deposits => this.setFileDeposits(deposits));

    this.load();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public load() {
    this.store.dispatch(
      FileDepositActions.loadOnboardingFileDeposit({
        params: { search: { states: [FILE_DEPOT_STATES.WAITING_CUSTOMER, FILE_DEPOT_STATES.REVIEW] } },
      }),
    );
  }

  public selectDeposit(deposit: FileDeposit) {
    if (deposit.state !== FILE_DEPOT_STATES.WAITING_CUSTOMER) {
      return;
    }
    this.selected = deposit;
    this.state = 'form';
  }

  public removeSelected() {
    this.selected = undefined;
    this.state = 'list';
  }

  public setListState() {
    this.removeSelected();
  }

  public startDeposit(deposit: FileDeposit) {
    this.store.dispatch(GlobalActions.validateFacilityOnboarding({ id: deposit.facilityId }));
    if (deposit.state === FILE_DEPOT_STATES.WAITING_CUSTOMER) {
      this.selected = deposit;
      this.state = 'form';
    }
  }

  private setFileDeposits(deposits: FileDeposit[]) {
    this.deposits = deposits;
    this.setDefaultSelected(deposits);
  }

  private setDefaultSelected(deposits: FileDeposit[]) {
    const allowed = this.deposits.filter(d => d.state === FILE_DEPOT_STATES.WAITING_CUSTOMER);

    if (this.deposits.length === 1 && (this.selected === undefined || this.selected.id === allowed[0].id)) {
      const deposit = this.deposits[0];

      if (deposit.state !== FILE_DEPOT_STATES.WAITING_CUSTOMER) {
        return;
      }

      this.store.select(selectIsFacilityOnboardingValidated(deposit.facilityId)).subscribe(isValidated => {
        this.validationStep = !isValidated;
        if (this.state === 'list' && !isValidated) {
          this.state = 'message';
        }
      });

      this.selected = deposit;
    }
  }
}
