import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GetLatestGMPCollectionQuery, GMPRepository } from '@application/bundles/gmp';

import { GMP } from '@domain/gmp';
import { GMPSearchParamsImpl } from '@application/bundles/gmp/implementations';

@HandleQuery({
  query: GetLatestGMPCollectionQuery,
})
export class GetLatestGmpCollectionQueryHandler implements QueryHandler<GetLatestGMPCollectionQuery> {
  constructor(private readonly repository: GMPRepository) {}

  public async handle(query: GetLatestGMPCollectionQuery): Promise<GMP[]> {
    const params = new GMPSearchParamsImpl().setFacilities(...(query.facilityIds ?? []));
    return this.repository.listLatest(params);
  }
}
