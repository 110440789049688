import { NgModule } from '@angular/core';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator/validator.module';

import { Bundle } from '@application/framework/bundle';
import {
  CreateDaycareDependencyFundingDtoImpl,
  CreateDependencyFundingDtoImpl,
  CreatePermanentDependencyFundingDtoImpl,
  CreateTemporaryDependencyFundingDtoImpl,
  UpdateDaycareDependencyFundingDtoImpl,
  UpdateDependencyFundingDtoImpl,
  UpdatePermanentDependencyFundingDtoImpl,
  UpdateTemporaryDependencyFundingDtoImpl,
} from '@implementations/bundles/dependency-funding/dto';
import { DependencyFundingUrlsProvider } from '@easyhpad-ui/app/bundles/dependency-funding/providers/url-provider/dependency-funding-urls.provider';
import {
  DaycareDependencyFundingDtoFactory,
  DependencyFundingBundle,
  DependencyFundingDtoFactory,
  DependencyFundingRepository,
  PermanentDependencyFundingDtoFactory,
  TemporaryDependencyFundingDtoFactory,
} from '@application/bundles/dependency-funding';
import {
  DaycareDependencyFundingDtoFactoryImpl,
  DependencyFundingDtoFactoryImpl,
  PermanentDependencyFundingDtoFactoryImpl,
  TemporaryDependencyFundingDtoFactoryImpl,
} from '@implementations/bundles/dependency-funding/factories';
import { validateDependencyFundingDto } from '@implementations/bundles/dependency-funding/validators';
import { EasyDependencyFundingRepository } from '@implementations/bundles/backend/easyhpad';
import { OpenDependencyFundingDeletionDialogCommandHandler } from '@easyhpad-ui/app/bundles/dependency-funding/commands/handlers';
import { StoreModule } from '@ngrx/store';
import { DEPENDENCY_FUNDING_STORE_KEY, DependencyFundingEffects, dependencyFundingReducer } from './store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    StoreModule.forFeature(DEPENDENCY_FUNDING_STORE_KEY, dependencyFundingReducer),
    EffectsModule.forFeature([DependencyFundingEffects]),
    ValidatorModule.forChild({
      validators: [
        { objectType: CreateDependencyFundingDtoImpl, validator: validateDependencyFundingDto },
        { objectType: UpdateDependencyFundingDtoImpl, validator: validateDependencyFundingDto },
        { objectType: CreatePermanentDependencyFundingDtoImpl, validator: validateDependencyFundingDto },
        { objectType: UpdatePermanentDependencyFundingDtoImpl, validator: validateDependencyFundingDto },
        { objectType: CreateTemporaryDependencyFundingDtoImpl, validator: validateDependencyFundingDto },
        { objectType: UpdateTemporaryDependencyFundingDtoImpl, validator: validateDependencyFundingDto },
        { objectType: CreateDaycareDependencyFundingDtoImpl, validator: validateDependencyFundingDto },
        { objectType: UpdateDaycareDependencyFundingDtoImpl, validator: validateDependencyFundingDto },
      ],
    }),
  ],
  providers: [
    DependencyFundingUrlsProvider,
    {
      provide: DependencyFundingDtoFactory,
      useClass: DependencyFundingDtoFactoryImpl,
    },
    {
      provide: PermanentDependencyFundingDtoFactory,
      useClass: PermanentDependencyFundingDtoFactoryImpl,
    },
    {
      provide: TemporaryDependencyFundingDtoFactory,
      useClass: TemporaryDependencyFundingDtoFactoryImpl,
    },
    {
      provide: DaycareDependencyFundingDtoFactory,
      useClass: DaycareDependencyFundingDtoFactoryImpl,
    },
    {
      provide: DependencyFundingRepository,
      useClass: EasyDependencyFundingRepository,
    },
  ],
})
@Bundle({
  imports: [DependencyFundingBundle],
  commandHandlers: [OpenDependencyFundingDeletionDialogCommandHandler],
})
export class DependencyFundingModule {}
