<div class="form-container">
  <form (submit)="submit()" *ngIf="form" [formGroup]="form" novalidate>

    <div class="form-row">
      <div class="row-content">

        <header class="row-header">
          <h2 class="title form-row-title">{{'Informations' | ehp_translate}}</h2>
        </header>


        <ehp-form-field class="required">
          <label for="document">{{'C.P.O.M. au format numérique' | ehp_translate}}</label>
          <ehp-media-uploader
            (onChange)="updateDocument($event)"
            (onRemove)="removeDocument()"
            [max]="1"
            [medias]="currentMedias"
            [min]="1"
            [requiredFileType]="[mimeType]" ehp-form-control
            id="document"></ehp-media-uploader>
        </ehp-form-field>

        <ehp-form-field>

          <label for="annexes">{{'Annexes complémentaires' | ehp_translate}}</label>

          <ehp-media-uploader
            (onChange)="updateAnnexesMedias($event)"
            [hideFileList]="true"
            [medias]=""
            [requiredFileType]="[mimeType]"
            ehp-form-control
            id="annexes">
          </ehp-media-uploader>

        </ehp-form-field>

        <div *ngIf="annexesRows" class="annexes-rows-container" formArrayName="annexes">

          <div *ngFor="let annexForm of annexesRows.controls; let index = index" [formGroup]="annexForm"
               class="annexes-row">

            <div class="file">

              <p class="is-form-label">{{'Fichier' | ehp_translate }}</p>

              <ng-container *ngIf="annexForm.get('mediaId') as mediaControl">
                <span *ngIf="mediaControl.value.name; else mediaName" [innerHTML]="mediaControl.value.name"
                      class="filename"></span>

                <ng-template #mediaName>
                  <ng-container *ngIf="annexMedias.get(mediaControl.value) as media">
                    {{media.name}}
                  </ng-container>
                </ng-template>

              </ng-container>
            </div>

            <ehp-form-field class="name">
              <label for="name-{{index}}">{{'Nom de l\'annexe' | ehp_translate }}</label>
              <input ehp-form-control formControlName="name" id="name-{{index}}" type="text">
            </ehp-form-field>

            <div class="actions-list">
              <button (click)="removeAnnexRow(index)" class="is-icon icon-delete delete" type="button"></button>
            </div>
          </div>
        </div>

        <ehp-form-field>
          <label for="date">{{"Date d'entrée en vigueur" | ehp_translate}}</label>
          <p>{{"Date à laquelle le C.P.O.M. est entré en vigueur" | ehp_translate }}</p>
          <input ehp-form-control formControlName="date" id="date" type="date">
        </ehp-form-field>


      </div>
    </div>

    <div class="form-row">

      <header class="row-header">
        <h2 class="title form-row-title">{{'Établissement concerné par ce C.P.O.M.' | ehp_translate}}</h2>
      </header>


      <ng-container *ngIf="localCPOMRows" formArrayName="children">

        <p-panel *ngFor="let localForm of localCPOMRows.controls; let index = index" [toggleable]="true">

          <ng-template pTemplate="header">
            <span class="index">#{{index + 1}}</span>

            <span *ngIf="getLocalFacility(index); else emptyTitle" class="title">
              {{ getLocalFacility(index)?.name }}
            </span>
            <ng-template #emptyTitle>
              <span class="title empty">{{ 'Nouvelles données liées à un établissement' | ehp_translate}}</span>
            </ng-template>
          </ng-template>

          <ng-template pTemplate="icons">
            <button (click)="deleteLocalCPOM(index)" class="is-icon  small icon-delete delete ghost" type="button">
              <span class="label">
                {{'Supprimer les informations de cet établissement du C.P.O.M.' | ehp_translate}}
              </span>
            </button>
          </ng-template>

          <ehp-form-local-cpom-inputs-row
            (facilityChange)="localFacilityHasChange(index, $event)"
            [form]="localForm"
            [mode]="mode"
            [year]="year">
          </ehp-form-local-cpom-inputs-row>
        </p-panel>

        <div>


        </div>
      </ng-container>

      <button (click)="addLocalCPOM()" class="primary" type="button">
        {{'Ajouter un établissement' | ehp_translate}}
      </button>

    </div>

    <div class="actions actions-list reverse">

      <button *ngIf="submitLabel" [disabled]="!form.valid" class="primary" type="submit">
        <ehp-translatable-string [value]="submitLabel"></ehp-translatable-string>
      </button>

      <button (click)="cancel()" class="ghost" type="button">
        {{ 'Annuler' | ehp_translate }}
      </button>

    </div>
  </form>
</div>

<div *ngIf="previewList && previewList.size > 0" class="media-preview-container panel">
  <ehp-medias-viewer [medias]="previewList"></ehp-medias-viewer>
</div>
