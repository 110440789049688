import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Skeleton } from '@easyhpad-ui/app/bundles/reusable-components/components/skeleton';
import { Observable } from 'rxjs';

@Component({
  selector: 'ehp-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'page-content',
    '[class.no-gutter]': '!gutter',
    '[class.content-loaded]': 'loaded',
  },
})
export class PageContentComponent implements OnInit, OnChanges {
  @Input('gutter') public gutter = true;

  @Input('loading$') public loading: Observable<any> | Promise<any> | boolean | undefined;

  @Input('skeleton') public skeleton: Skeleton = Skeleton.DEFAULT;

  public loaded = true;

  constructor() {
    this.setLoaded = this.setLoaded.bind(this);
  }

  public ngOnInit(): void {
    this.loaded = true;

    if (this.loading instanceof Observable) {
      this.loading.subscribe(this.setLoaded, undefined, () => this.setLoaded);
    } else if (this.loading instanceof Promise) {
      this.loading.finally(this.setLoaded);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['loading']) {
      if (typeof changes['loading'].currentValue === 'boolean') {
        this.loaded = this.loading !== undefined && this.loading !== true;
      }
    }
  }

  private setLoaded() {
    this.loaded = true;
  }
}
