import { ComputableResidentGIRList } from '@domain/gir';

export function isResidentGIRList(list: any): list is ComputableResidentGIRList {
  return (
    list !== null &&
    typeof list === 'object' &&
    !(list.get instanceof Map) &&
    typeof (list as ComputableResidentGIRList).get === 'function' &&
    !(list.sum instanceof Map) &&
    typeof (list as ComputableResidentGIRList).sum === 'function'
  );
}
