import { NationalCostByEtpRepository } from '@application/bundles/etp';
import { SourcedData } from '@application/bundles/sourced-data';
import { CostByETP } from '@domain/etp/entities/costs-by-etp';
import { EasyRepositoryAbstract } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { Service } from '@application/framework/di';

@Service()
export class EasyNationalCostByEtpRepository extends EasyRepositoryAbstract implements NationalCostByEtpRepository {
  constructor() {
    super('etp-costs');
  }

  public listLatest(): Promise<SourcedData<CostByETP>[]> {
    return this.backend
      .get<{ entities: SourcedData<CostByETP>[] }>(this.basePath, { latest: true })
      .then(response => this.parseResponse(response))
      .then(body => body.entities);
  }
}
