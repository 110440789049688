import { SelectionChange } from '@application/framework/core';

export class Selection<T> {
  private _selection = new Set<T>();

  private _selected: T[] | undefined;

  public get selected(): T[] {
    if (this._selected === undefined) {
      return this.updateSelectedCache();
    }
    return this._selected;
  }

  public get length(): number {
    return this.selected.length;
  }

  constructor(initiallySelectedValues?: T[]) {
    if (initiallySelectedValues && initiallySelectedValues.length) {
      initiallySelectedValues.forEach((value) => this.markSelected(value));
    }
  }

  /**
   * Selects a value or an array of values.
   * @param values The values to select
   */
  public select(...values: T[]): SelectionChange<T> {
    const previous = this.cloneSelected();
    values.forEach((value) => this.markSelected(value));
    return this.getNewSelectionChange(previous);
  }

  /**
   * Deselects a value or an array of values.
   * @param values The values to deselect
   * @return Whether the selection changed as a result of this call
   */
  public deselect(...values: T[]): SelectionChange<T> {
    const previous = this.cloneSelected();
    values.forEach((value) => this.unmarkSelected(value));
    return this.getNewSelectionChange(previous);
  }

  /**
   * Toggles a value between selected and deselected.
   * @param values The value to toggle
   * @return Whether the selection changed as a result of this call
   */
  public toggle(...values: T[]): SelectionChange<T> {
    const previous = this.cloneSelected();
    values.forEach((value) => (this.isSelected(value) ? this.unmarkSelected(value) : this.markSelected(value)));
    return this.getNewSelectionChange(previous);
  }

  /**
   * Clears all the selected values.
   *
   * @return Whether the selection changed as a result of this call
   */
  public clear(): SelectionChange<T> {
    const previous = this.cloneSelected();
    this.unmarkAll();
    return this.getNewSelectionChange(previous);
  }

  /**
   * Determines whether a value is selected.
   */
  public isSelected(value: T): boolean {
    return this._selection.has(value);
  }

  /**
   * Determines whether the model does not have a value.
   */
  public isEmpty(): boolean {
    return this._selection.size === 0;
  }

  /**
   * Determines whether the model has a value.
   */
  public hasValue(): boolean {
    return !this.isEmpty();
  }

  /**
   * Sorts the selected values based on a predicate function.
   */
  public sort(predicate?: (a: T, b: T) => number): void {
    if (this.selected && this._selected) {
      this._selected.sort(predicate);
    }
  }

  private markSelected(value: T) {
    this._selection.add(value);
    this.updateSelectedCache();
  }

  private unmarkSelected(value: T) {
    this._selection.delete(value);
    this.updateSelectedCache();
  }

  private unmarkAll() {
    if (!this.isEmpty()) {
      this._selection.forEach((value) => this.unmarkSelected(value));
    }
  }

  private cloneSelected(): T[] {
    return Array.from(this._selection);
  }

  private getNewSelectionChange(previous: T[]): SelectionChange<T> {
    const change = {
      ...this.compareWith(previous),
      current: this.selected,
      previous,
    };

    this.throwErrorOnPartialChange(change);

    return change;
  }

  private throwErrorOnPartialChange(change: Partial<SelectionChange<T>>) {
    if (!(change.added instanceof Array)) {
      throw new Error(`Invalid change.added type, Array required, ${typeof change.added} provided`);
    }

    if (!(change.removed instanceof Array)) {
      throw new Error(`Invalid change.removed type, Array required, ${typeof change.removed} provided`);
    }

    if (!(change.current instanceof Array)) {
      throw new Error(`Invalid change.currents type, Array required, ${typeof change.current} provided`);
    }

    if (!(change.previous instanceof Array)) {
      throw new Error(`Invalid change.previous type, Array required, ${typeof change.previous} provided`);
    }
  }

  private compareWith(selection: T[]): { added: T[]; removed: T[] } {
    return {
      added: this.selected.filter((value) => !selection.includes(value)),
      removed: selection.filter((value) => !this.selected.includes(value)),
    };
  }

  private updateSelectedCache(): T[] {
    this._selected = Array.from(this._selection.values());
    return this.selected;
  }
}
