import {Notice, NoticeType} from "@application/framework/notice";
import {TranslatableString, Translator} from "@application/framework/translation";

export class TranslatableNotice implements Notice {

  public message: string;

  constructor(
    private readonly translator: Translator,
    private _message: TranslatableString | string,
    public readonly type: NoticeType,
    public icon ?: string
  ) {

    if (typeof this._message === 'string') {
      this.message = this._message;
    } else {
      this.message = this._message.value;

      this.translator.translate(this._message.value, this._message.replacement, this._message.context)
        .then(translation => this.message = translation);
    }

  }
}
