import { BackendPropertyTransformers } from '@application/framework/backend/contracts';
import { DateFormatter, DateParser } from '@application/framework/date';
import { Provide } from '@application/framework/di';

export class BackendDateTransformer implements BackendPropertyTransformers<string, Date> {
  @Provide()
  private readonly dateParser!: DateParser;

  @Provide()
  private readonly dateFormatter!: DateFormatter;

  public from(value: string): Date {
    return this.dateParser.fromISOString(value);
  }

  public to(value: Date): string {
    return this.dateFormatter.toUTCIsoString(value);
  }
}
