import {TaxonomyTerm} from '@domain/taxonomy';
import {isUUID} from '@domain/lib';

/**
 * Check if id is a valid UUID.
 * @param id
 */
export function isValidTaxonomyTermIdType(id: any): id is TaxonomyTerm['id'] {
  return isUUID(id);
}

/**
 * Check if the term implements TaxonomyTerm interface without id property.
 * @param term
 */
export function isMaybeATaxonomyTerm(term: any): term is Omit<TaxonomyTerm, 'id'> {
  return (
    typeof term === 'object' &&
    term !== null &&
    'name' in term &&
    typeof term.name === 'string' &&
    ('parentId' in term
      ? isValidTaxonomyTermIdType(term.parentId) || term.parentId === null || term.parentId === undefined
      : true)
  );
}

/**
 * Check if the term implements TaxonomyTerm.
 * @param term
 */
export function isTaxonomyTerm(term: any): term is TaxonomyTerm {
  return isMaybeATaxonomyTerm(term) && 'id' in term && isValidTaxonomyTermIdType(term.id);
}
