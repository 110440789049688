import {ContainerRegistry} from "@application/framework/di/container.registry";
import {createServiceMetadata} from "@application/framework/di/functions/create-service-metadata.function";
import {
  ProvideDecoratorAdapter,
  ServiceDecoratorAdapter
} from "@application/framework/di/interfaces/decorator-adapters.interface";
import {ProvideMetadata, ProvideOptions} from "@application/framework/di/interfaces/provide-options.interface";
import {ServiceMetadata, ServiceOptions} from "@application/framework/di/interfaces/service-metadata.interface";

class InternalDependenciesInjectionConfig {

  private readonly lazyService: Map<Function, ServiceMetadata> = new Map();

  private readonly lazyProvide: Set<{ target: Object, metadata: ProvideMetadata }> = new Set();

  private serviceDecoratorAdapter!: ServiceDecoratorAdapter;

  private provideDecoratorAdapter!: ProvideDecoratorAdapter;

  public setServiceDecoratorAdapter(adapter: ServiceDecoratorAdapter) {

    if (this.serviceDecoratorAdapter !== adapter) {
      this.lazyService.forEach((options, target) => {
        adapter(target, options);
      });
      this.lazyService.clear();
    }
    this.serviceDecoratorAdapter = adapter;
  }

  public setProvideDecoratorAdapter(adapter: ProvideDecoratorAdapter) {

    if (this.provideDecoratorAdapter !== adapter) {

      this.lazyProvide.forEach((definition) => {
        adapter(definition.target, definition.metadata);
      });
      this.lazyProvide.clear();
    }

    this.provideDecoratorAdapter = adapter;
  }

  public serviceDecorator<T extends Function>(target: T, options: ServiceOptions): T | void {

    const metadata = createServiceMetadata(options);

    ContainerRegistry.defaultContainer.set(metadata);

    if (this.serviceDecoratorAdapter !== undefined) {
      return this.serviceDecoratorAdapter(target, metadata);
    }
    return this.lazyServiceDecoratorAdapter(target, metadata);
  }


  public provideDecorator<T extends Object>(target: T, options: ProvideOptions): T | void {
    if (this.provideDecoratorAdapter !== undefined) {
      return this.provideDecoratorAdapter(target, options);
    }

    return this.lazyProvideDecoratorAdapter(target, options);
  }

  private lazyServiceDecoratorAdapter: ServiceDecoratorAdapter = (target, metadata) => {
    this.lazyService.set(target, metadata);
  }

  private lazyProvideDecoratorAdapter: ProvideDecoratorAdapter = (target, metadata) => {
    this.lazyProvide.add({target, metadata})
  }

}

export const DependenciesInjectionConfiguration = new InternalDependenciesInjectionConfig();
