import { Transformer } from '@application/framework/core';
import { ActivityAnnex } from '@domain/activity-annex';
import {
  EasyActivityAnnex,
  EasyCreateActivityAnnexDto,
  EasyUpdateActivityAnnexDto,
} from '@implementations/bundles/backend/easyhpad/interfaces';
import { Common } from '@application/framework/lib';
import { CreateActivityAnnexDto, UpdateActivityAnnexDto } from '@application/bundles/activity-annex';
import { Service } from '@application/framework/di';
import { DateFormatter, DateParser } from '@application/framework/date';
import { ActivityAnnexImpl } from '@implementations/bundles/activity-annex';

type CommonProperties = Omit<Common<ActivityAnnex, Common<CreateActivityAnnexDto, UpdateActivityAnnexDto>>, 'document'>;

type EasyCommonProperties = Common<EasyActivityAnnex, Common<EasyCreateActivityAnnexDto, EasyUpdateActivityAnnexDto>>;

@Service()
export class EasyActivityAnnexTransformer implements Transformer<ActivityAnnex, EasyActivityAnnex> {
  constructor(
    private readonly dateFormatter: DateFormatter,
    private readonly dateParser: DateParser,
  ) {}

  public transform(value: ActivityAnnex): EasyActivityAnnex {
    return {
      ...this.transformCommon(value),
      id: value.id,
    };
  }

  public reverseTransform(value: EasyActivityAnnex): ActivityAnnex {
    return new ActivityAnnexImpl({
      ...this.reverseTransformCommon(value),
      documentId: value.document_id,
      id: value.id,
    });
  }

  public transformCreate(value: CreateActivityAnnexDto): EasyCreateActivityAnnexDto {
    return {
      ...this.transformCommon(value),
    };
  }

  public transformUpdate(value: UpdateActivityAnnexDto): EasyUpdateActivityAnnexDto {
    return {
      ...this.transformCommon(value),
      id: value.id,
    };
  }

  private transformCommon(value: CommonProperties): EasyCommonProperties {
    return {
      date: this.dateFormatter.toUTCIsoString(value.date),
      date_depot: value.depositDate instanceof Date ? this.dateFormatter.toUTCIsoString(value.depositDate) : undefined,
      days_realized: value.days,
      document_id: value.documentId || '',
      facilityId: value.facilityId,
      occupation_rate: value.occupationRate,
    };
  }

  private reverseTransformCommon(value: EasyCommonProperties): CommonProperties {
    return {
      date: this.dateParser.fromISOString(value.date),
      days: value.days_realized,
      depositDate: value.date_depot ? this.dateParser.fromISOString(value.date_depot) : undefined,
      documentId: value.document_id,
      facilityId: value.facilityId,
      occupationRate: value.occupation_rate,
    };
  }
}
