import { Service } from '@application/framework/di';
import { EhpadDemain } from '@domain/ehpad-demain';
import { DocumentAuthorization } from '@application/bundles/document/authorization/document-authorization';

@Service()
export class EhpadDemainAuthorizationChecker {
  constructor(private readonly authorization: DocumentAuthorization) {}

  public canRead(ehpadDemain?: EhpadDemain) {
    return this.authorization.canReadDocument(ehpadDemain);
  }

  public canCreate() {
    return this.authorization.canCreateDocument();
  }

  public canUpdate(ehpadDemain: EhpadDemain) {
    return this.authorization.canEditDocument(ehpadDemain);
  }

  public canDelete(ehpadDemain: EhpadDemain) {
    return this.authorization.canDeleteDocument(ehpadDemain);
  }
}
