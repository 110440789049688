import {ValidateFunction} from "@application/framework/validator/object-validator.registry";
import {
  AddCapacityAuthorizationDtoImpl
} from "@implementations/bundles/capacity-authorization/dtos/add-capacity-authorization.dto";
import {validateOrReject} from "class-validator";

export const validateAddCapacityAuthorizationDtoImpl: ValidateFunction<AddCapacityAuthorizationDtoImpl> = async (dto: AddCapacityAuthorizationDtoImpl) => {
  await validateOrReject(dto);

  return dto;
}
