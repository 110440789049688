<input (ngModelChange)="valuesHasChange($event)" [(ngModel)]="value" [id]="id"
       [type]="hidden ? 'password' : 'text'"
       autocomplete="off"
       ehp-form-control>

<button (click)="toggleVisibility()" [ngClass]="{'off':hidden}" class="suffix toggle" type="button">

  <i [ngClass]="hidden ? 'icon-eye-close' : 'icon-eye'" class="ehp-icon icon"></i>

  <span class="label">
    <ng-container
      *ngIf="hidden; else hideLabel">{{ 'Afficher le mot de passe'| ehp_translate}}</ng-container>
    <ng-template #hideLabel>{{ 'Afficher le mot de passe'| ehp_translate}}</ng-template>
  </span>


</button>
