import { Transformer } from '@application/framework/core';
import { Provide, Service } from '@application/framework/di';
import { Customer } from '@domain/customer';
import { EasyCustomer } from '@implementations/bundles/backend/easyhpad/interfaces/easy-customer.interface';
import { EasyCommonCustomerTransformer } from '@implementations/bundles/backend/easyhpad/transformers/customer/easy-common-customer/easy-common-customer.transformer';

@Service()
export class EasyCustomerTransformer implements Transformer<Customer, EasyCustomer> {
  @Provide() private readonly commonTransformer!: EasyCommonCustomerTransformer;

  public transform(customer: Customer): EasyCustomer {
    return {
      ...this.commonTransformer.transform(customer),
      id: customer.id,
      address: customer.address ? this.commonTransformer.addressTransformer.transform(customer.address) : undefined,
      billingAddress: customer.billingAddress
        ? this.commonTransformer.addressTransformer.transform(customer.billingAddress)
        : undefined,
      facilities: customer.facilities,
      createdAt: customer.createdAt,
      updatedAt: customer.updatedAt,
    };
  }

  public reverseTransform(easyCustomer: EasyCustomer): Customer {
    return {
      ...this.commonTransformer.reverseTransform(easyCustomer),
      id: easyCustomer.id,
      address: easyCustomer.address
        ? this.commonTransformer.addressTransformer.reverseTransform(easyCustomer.address)
        : undefined,
      billingAddress: easyCustomer.billingAddress
        ? this.commonTransformer.addressTransformer.reverseTransform(easyCustomer.billingAddress)
        : undefined,
      facilities: easyCustomer.facilities,
      createdAt: easyCustomer.createdAt,
      updatedAt: easyCustomer.createdAt,
    };
  }
}
