import {Component, Input, ViewEncapsulation} from '@angular/core';
import {GMP} from "@domain/gmp";
import {DocumentListImpl} from "@easyhpad-ui/app/library/document";

@Component({
  selector: 'ehp-gmp-other-list',
  templateUrl: './gmp-other-list.component.html',
  styleUrls: ['./gmp-other-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GmpOtherListComponent {

  @Input() public gmp!: GMP;

  public documents = new DocumentListImpl<GMP>();
}
