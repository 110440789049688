import { Department } from '../../entities/department';
import { isDepartmentCode } from '../is-deparment-code/is-department-code.validator';

/**
 * Check if an entity implements the Department interface.
 *
 * @param entity The entity to check.
 * @returns true if the entity implements Department, otherwise false.
 */
export function isMaybeADepartment(entity: any): entity is Department {

  return typeof entity === 'object'
    && entity !== null
    && typeof entity.name === 'string'
    && isDepartmentCode(entity.code);
}