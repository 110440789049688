import {IsUniqueEmailQuery} from "@application/bundles/user/queries";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {EasyBackendApi} from "@implementations/bundles/backend/easyhpad/api";

@HandleQuery({
  query: IsUniqueEmailQuery
})
export class IsUniqueEmailQueryHandler implements QueryHandler<IsUniqueEmailQuery, boolean> {

  private readonly path = 'user/email';

  constructor(private readonly backend: EasyBackendApi) {
  }

  public handle(query: IsUniqueEmailQuery): Promise<boolean> {
    return this.backend.post<{ exist: boolean | undefined }>(this.path, {email: query.email})
      .then(response => response.body)
      .then(res => res.exist === undefined || !res.exist);
  }


}
