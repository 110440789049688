import {MetadataReflection} from "@application/framework/lib";

export class DesignReflectionMetadataAccessor {

  public static readonly TYPE = 'design:type';

  public static readonly PARAM_TYPES = 'design:paramtypes';

  public static readonly RETURN_TYPE = 'design:returntype';

  private readonly reflection: MetadataReflection;

  private readonly types: { type: any, paramTypes: any, returnType: any };

  constructor(reflection?: MetadataReflection, types?: { type?: any, paramTypes?: any, returnType?: any }) {
    this.reflection = reflection ?? new MetadataReflection();
    this.types = {
      type: types?.type ?? DesignReflectionMetadataAccessor.TYPE,
      paramTypes: types?.paramTypes ?? DesignReflectionMetadataAccessor.PARAM_TYPES,
      returnType: types?.returnType ?? DesignReflectionMetadataAccessor.RETURN_TYPE
    }
  }

  public getDesignType<T extends Object>(target: T, propertyKey?: string | symbol): any[] {

    if (propertyKey !== undefined) {
      return this.reflection.getMetadata(this.types.type, target, propertyKey) || [];
    }

    return this.reflection.getMetadata(this.types.type, target) || [];
  }

  public getDesignParamTypes<T extends Object>(target: T, propertyKey?: string | symbol): any[] {

    if (propertyKey !== undefined) {
      return this.reflection.getMetadata(this.types.paramTypes, target, propertyKey) || [];
    }

    return this.reflection.getMetadata(this.types.paramTypes, target) || [];
  }

  public getDesignReturnType<T extends Object>(target: T, propertyKey?: string | symbol): any[] {

    if (propertyKey !== undefined) {
      return this.reflection.getMetadata(this.types.returnType, target, propertyKey) || [];
    }

    return this.reflection.getMetadata(this.types.returnType, target) || [];
  }
}
