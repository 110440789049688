import {Component, Input} from '@angular/core';
import {HealthFunding} from "@domain/health-funding";

@Component({
  selector: 'ehp-health-funding-list-item',
  templateUrl: './health-funding-list-item.component.html',
  styleUrls: ['./health-funding-list-item.component.scss'],
  host: {
    'class': 'document-list-item'
  }
})
export class HealthFundingListItemComponent {


  @Input() public healthFunding!: HealthFunding;
}
