import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PanelModule as PrimePanelModule} from "primeng/panel";
import {PanelComponent} from "@easyhpad-ui/app/library/panel/components";
import {PanelHeaderDirective} from "@easyhpad-ui/app/library/panel/directives/panel-header.directive";


@NgModule({
  imports: [
    CommonModule,
    PrimePanelModule
  ],
  declarations: [
    PanelComponent,
    PanelHeaderDirective
  ],
  exports: [
    PanelComponent,
    PanelHeaderDirective
  ]

})
export class PanelModule {
}
