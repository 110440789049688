import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {
  ListOtherDependencyFundingQuery
} from "@application/bundles/dependency-funding/queries/list-other-local-dependency-funding.query";
import {DependencyFundingRepository} from "@application/bundles/dependency-funding";
import {DependencyFunding} from "@domain/dependency-funding";

@HandleQuery({
  query: ListOtherDependencyFundingQuery
})
export class ListOtherDependencyFundingQueryHandler implements QueryHandler<ListOtherDependencyFundingQuery, DependencyFunding[]> {


  public constructor(private readonly repository: DependencyFundingRepository) {
  }

  public async handle(query: ListOtherDependencyFundingQuery): Promise<DependencyFunding[]> {
    return [];
  }

}
