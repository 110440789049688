<div [ngClass]="{'loading': loading, 'is-supported': isSupportedPreviewFile}" class="spreadsheet-preview"
     ehpSpreadsheetPreviewHost></div>

<div *ngIf="loading" class="spreadsheet-loading">
  <span class="loader"></span>
  <p>{{'Extraction des données depuis le tableur' | ehp_translate}}</p>
</div>

<div *ngIf="!loading && !isSupportedPreviewFile && media" class="unsupported-preview">
  {{ "Ce format de fichier n'est pour le moment pas disponible à la prévisualisation."| ehp_translate}}
  <a *ngIf="downloadUrl" [href]="downloadUrl" class="button primary" download="" target="_blank">
    <span class="label">{{'Télécharger le fichier' | ehp_translate}}</span>
  </a>
</div>




