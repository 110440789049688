import { SubFunding } from '@domain/funding';

export abstract class FundingCollection<T extends SubFunding> implements Iterable<T> {
  public abstract readonly size: number;

  public abstract sum(): number;

  public abstract sort(compareFn: (a: T, b: T) => number): void;

  public abstract values(): Iterator<T>;

  public abstract [Symbol.iterator](): Iterator<T>;

  public abstract toArray(): Array<T>;
}
