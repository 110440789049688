import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
  CapacityAuthorizationAuthorizationChecker,
} from '@application/bundles/capacity-authorization';
import { UpdateInspectionReportCommand } from '@application/bundles/capacity-authorization/commands';
import { UpdateCapacityAuthorizationDto } from '@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto';
import { UpdateInspectionReportDto } from '@application/bundles/capacity-authorization/dtos/update-inspection-report.dto';
import { InspectionReportUpdateFailEvent } from '@application/bundles/capacity-authorization/events/update-inspection-report/inspection-report-update-fail.event';
import { InspectionReportUpdatedEvent } from '@application/bundles/capacity-authorization/events/update-inspection-report/inspection-report-updated.event';
import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { Translator } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import {
  CapacityAuthorization,
  CapacityAuthorizationRepository,
  InspectionReport,
} from '@domain/capacity-authorization';
import { MediaBucket, TemporaryMedia } from '@domain/media';

@HandleCommand({
  command: UpdateInspectionReportCommand,
})
export class UpdateInspectionReportCommandHandler
  implements CommandHandler<UpdateInspectionReportCommand, InspectionReport>
{
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly authorizationChecker: CapacityAuthorizationAuthorizationChecker,
    private readonly authorizationRepository: CapacityAuthorizationRepository,
    private readonly mediaBucket: MediaBucket,
    private readonly sanitizer: SanitizerLibrary,
    private readonly validator: ObjectValidator,
    private readonly eventDispatcher: EventDispatcher,
    private readonly translator: Translator,
  ) {}

  public async handle(command: UpdateInspectionReportCommand): Promise<InspectionReport> {
    const { update, authorizationId } = command;

    try {
      const authorization = await this.checkAccessAndGetAuthorization(authorizationId);

      const report = await this.sanitize(update)
        .then(dto => this.validate(dto))
        .then(dto => this.addReportToAuthorization(authorization, dto));

      this.eventDispatcher.dispatch(new InspectionReportUpdatedEvent(report));

      return report;
    } catch (e) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccessAndGetAuthorization(id: CapacityAuthorization['id']): Promise<CapacityAuthorization> {
    const authorization = await this.authorizationRepository.get(id);

    if (!(await this.authorizationChecker.canEdit(authorization))) {
      this.logger.error('Inspection report creation : Forbidden');
      throw new ForbiddenError();
    }

    return authorization;
  }

  private async sanitize(report: UpdateInspectionReportDto): Promise<UpdateInspectionReportDto> {
    try {
      report = await this.sanitizer.sanitize(report);
    } catch (e: any) {
      this.logger.warning('Inspection report update : Sanitizer fail');
      throw e;
    }

    return report;
  }

  private async validate(report: UpdateInspectionReportDto): Promise<UpdateInspectionReportDto> {
    try {
      report = await this.validator.validate(report);
    } catch (e: any) {
      this.logger.warning('Inspection report update : Validator fail');
      throw e;
    }

    return report;
  }

  private async addReportToAuthorization(
    authorization: CapacityAuthorization,
    report: UpdateInspectionReportDto,
  ): Promise<InspectionReport> {
    const currents = await authorization.inspectionReports();
    const index = currents.findIndex(current => current.id === report.id);

    if (index === -1) {
      throw new ForbiddenError(
        `Report (${report.id}) is not associated to Capacity Authorization (${authorization.id})`,
      );
    }

    let medias: TemporaryMedia[] = [];

    if (Array.isArray(report.mediaToRemove) && report.mediaToRemove.length > 0) {
      await Promise.all(report.mediaToRemove.map(id => this.mediaBucket.delete(id)));
      report.mediaIds = report.mediaIds.filter(id => report.mediaToRemove.includes(id));
    }

    if (Array.isArray(report.mediaToAdd) && report.mediaToAdd.length > 0) {
      medias = await this.mediaBucket.store(report.mediaToAdd).then(collection => collection.all());
      report.mediaIds = medias.map(media => media.id);
    }

    currents.splice(index, 1);

    const authorizationUpdate: Pick<UpdateCapacityAuthorizationDto, 'inspectionReports'> = {
      inspectionReports: [
        ...currents,
        {
          id: report.id,
          mediaIds: report.mediaIds,
          date: report.date,
        },
      ],
    };
    let authorizationUpdated: CapacityAuthorization;

    try {
      authorizationUpdated = await this.authorizationRepository.update(authorization.id, authorizationUpdate);
    } catch (e) {
      for (const media of medias) {
        await media.markForDeletion();
      }
      throw e;
    }

    const updated = await authorizationUpdated.inspectionReports().then(reports => {
      const ids = currents.map(media => media.id);
      return reports.find(r => !ids.includes(r.id));
    });

    if (updated === undefined) {
      throw new Error('Updated report cannot be found in inspection reports array');
    }

    return updated;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);

    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = await this.translator.translate('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à modifier ce rapport d'inspection.",
        undefined,
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new InspectionReportUpdateFailEvent(message));

    return error;
  }
}
