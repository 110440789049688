import {
  CreateEhpadDemainHealthFundingDto,
  UpdateEhpadDemainHealthFundingDto
} from "@application/bundles/health-funding";

export abstract class EhpadDemainHealthFundingDtoFactory {

  public abstract buildCreateDtoFromUnsafeValues(values: Partial<CreateEhpadDemainHealthFundingDto>): CreateEhpadDemainHealthFundingDto;

  public abstract buildUpdateDtoFromUnsafeValues(values: Partial<UpdateEhpadDemainHealthFundingDto>): UpdateEhpadDemainHealthFundingDto;

}
