import { HealthTaxonomyRepositoryProvider } from '@application/bundles/health-funding';
import { HealthTaxonomyType } from '@domain/health-funding';
import { TaxonomyRepository } from '@application/bundles/taxonomy';
import { keys } from '@domain/lib';
import { joinPath } from '@application/framework/lib';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { EasyTaxonomyRepository } from '@implementations/bundles/backend/easyhpad/repositories/easy-taxonomy-repository';

export class EasyHealthTaxonomyRepositoryProvider implements HealthTaxonomyRepositoryProvider {
  private repositories = new Map<HealthTaxonomyType, TaxonomyRepository>();

  private base = 'funding/health/taxonomy';

  private paths: Record<HealthTaxonomyType, string> = {
    recurring: 'recurring',
    nonRecurring: 'non-recurring',
    exceptional: 'exceptional',
  };

  constructor(private readonly backend: EasyBackendApi) {}

  public provide(type: HealthTaxonomyType): TaxonomyRepository {
    let repository = this.repositories.get(type);

    if (repository === undefined) {
      repository = new (class extends EasyTaxonomyRepository implements TaxonomyRepository {})(
        this.getPath(type),
        this.backend,
      );
      this.repositories.set(type, repository);
    }
    return repository;
  }

  private getPath(type: HealthTaxonomyType): string {
    if (!keys(this.paths).includes(type)) {
      throw new Error(`Error on health taxonomy terms load. The taxonomy "${type}" is not a health taxonomy.`);
    }

    return joinPath(this.base, this.paths[type]);
  }
}
