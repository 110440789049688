import { DocumentStoreActions, DocumentStoreSelectors } from '@easyhpad-ui/app/bundles/documents';
import { BehaviorSubject, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { TranslatableString } from '@application/framework/translation';
import { Facility } from '@domain/facility';
import { Document } from '@domain/document';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { DocumentViewMediaReference } from '@easyhpad-ui/app/bundles/documents/contracts/document-view';
import { DocumentSingleViewProcessorInstance } from '@easyhpad-ui/app/bundles/documents/contracts/document-single-view';

export abstract class DocumentFromStoreSingleViewProcessorAbstract<D extends Document>
  implements DocumentSingleViewProcessorInstance
{
  /**
   * @inheritDoc
   */
  public readonly title: BehaviorSubject<Array<string | TranslatableString>>;

  /**
   * @inheritDoc
   */
  public readonly facilityId: Subject<Facility['id'] | Array<Facility['id']>> = new Subject();

  /**
   * @inheritDoc
   */
  public readonly medias: Subject<DocumentViewMediaReference[]> = new Subject();

  /**
   * Use this subject in `takeUntil` pipe to unsubscribe a subscription
   * when `this.destroy()` is called.
   */
  protected readonly destroy$ = new Subject<void>();

  /**
   * Current document observer to use in extended class.
   * @protected
   */
  protected document: Observable<D | undefined>;

  /**
   * Current document.
   * @private
   */
  private document$ = new BehaviorSubject<D | undefined>(undefined);

  /**
   * Document from store subscription
   * @private
   */
  private subscription: Subscription | undefined;

  protected constructor(protected readonly store: Store<AppState>) {
    this.document = this.document$.asObservable();
    this.title = new BehaviorSubject<Array<string | TranslatableString>>([]);
  }

  /**
   * The store actions for the document
   */
  public abstract storeActions(): Pick<DocumentStoreActions<D>, 'openDocument'>;

  /**
   * The store selector for the document
   */
  public abstract storeSelectors(): Pick<DocumentStoreSelectors<D, D['id']>, 'selectSingleDocument'>;

  /**
   * Get the document title trails.
   * @param document
   */
  public abstract getDocumentTitle(document?: D): Array<string | TranslatableString>;

  /**
   * Get the parent facility of the document.
   * @param document
   */
  public abstract getFacilityId(document?: D): Facility['id'] | Array<Facility['id']> | undefined;

  /**
   * @inheritDoc
   * @param id
   */
  public attachDocumentId(id: Observable<string | undefined>) {
    this.subscription = this.store
      .select(this.storeSelectors().selectSingleDocument)
      .pipe(takeUntil(this.destroy$))
      .subscribe(document => this.updateDocument(document));

    id.pipe(takeUntil(this.destroy$)).subscribe(id => this.loadDocument(id));
  }

  /**
   * @inheritDoc
   */
  public destroy(): void {
    this.destroy$.next();
    this.subscription?.unsubscribe();
  }

  /**
   * Dispatch store request to open the document.
   * @param id
   * @protected
   */
  protected loadDocument(id?: Document['id']): void {
    if (id) {
      return this.store.dispatch(this.storeActions().openDocument({ id }));
    }
  }

  /**
   * Dispatch all changes (title, medias, ...) based on the provided document.
   * @param document
   * @protected
   */
  protected updateDocument(document: D | null): void {
    this.title.next(this.getDocumentTitle(document ?? undefined));
    this.facilityId.next(this.getFacilityId(document ?? undefined) ?? []);
    this.document$.next(document ?? undefined);
  }
}
