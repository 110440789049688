import {
  isValidExceptionalHealthFundingIdType,
  UpdateExceptionalHealthFundingDto,
} from '@application/bundles/health-funding';
import { CreateExceptionalHealthFundingDtoImpl } from './create-exceptional-health-funding.dto';

export class UpdateExceptionalHealthFundingDtoImpl
  extends CreateExceptionalHealthFundingDtoImpl
  implements UpdateExceptionalHealthFundingDto
{
  public id!: UpdateExceptionalHealthFundingDto['id'];

  constructor(values?: Partial<UpdateExceptionalHealthFundingDto>) {
    super(values);

    if (values && isValidExceptionalHealthFundingIdType(values.id)) {
      this.id = values.id;
    }
  }
}
