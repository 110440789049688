import {Constructable} from "@application/framework/lib";
import {Provider} from "./provider";

export interface ClassProvider<T> {
  useClass: Constructable<T>;
  dependencies?: unknown[];
}

export function isClassProvider<T>(provider: Provider<T>): provider is ClassProvider<any> {
  return !!(provider as ClassProvider<T>).useClass;
}
