import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CpomFundingTableComponent } from '@easyhpad-ui/app/bundles/cpom/components/cpom-funding-table/cpom-funding-table.component';
import { CPOMLinkComponent } from '@easyhpad-ui/app/bundles/cpom/components/cpom-link/cpom-link.component';
import { FormCPOMEditComponent } from '@easyhpad-ui/app/bundles/cpom/components/form-cpom-edit/form-cpom-edit.component';
import { FormCpomFundingInputsRowComponent } from '@easyhpad-ui/app/bundles/cpom/components/form-cpom-funding-inputs-row/form-cpom-funding-inputs-row.component';
import { FormCpomFundingTableComponent } from '@easyhpad-ui/app/bundles/cpom/components/form-cpom-funding-table/form-cpom-funding-table.component';
import { FormCpomTariffOptionSelectComponent } from '@easyhpad-ui/app/bundles/cpom/components/form-cpom-tariff-option-select/form-cpom-tariff-option-select.component';
import { FormLocalCpomInputsRowComponent } from '@easyhpad-ui/app/bundles/cpom/components/form-local-cpom-inputs-row/form-local-cpom-inputs-row.component';
import { LocalCpomOthersTableComponent } from '@easyhpad-ui/app/bundles/cpom/components/local-cpom-others-table/local-cpom-others-table.component';
import { TariffOptionValueComponent } from '@easyhpad-ui/app/bundles/cpom/components/tariff-option-value/tariff-option-value.component';
import { CPOMFormBuilder } from '@easyhpad-ui/app/bundles/cpom/services/cpom-edit-form-builder/form-builder';
import { CPOMFundingFormBuilder } from '@easyhpad-ui/app/bundles/cpom/services/cpom-funding-form-builder/cpom-funding-form-builder';
import { LocalCPOMFormBuilder } from '@easyhpad-ui/app/bundles/cpom/services/local-cpom-form-builder/local-cpom-form-builder';
import { FacilityFormModule } from '@easyhpad-ui/app/bundles/facility/modules/form/facility-form.module';
import { FacilityUiModule } from '@easyhpad-ui/app/bundles/facility/modules/ui/facility-ui.module';
import { MediaModule } from '@easyhpad-ui/app/bundles/media/media.module';
import { ReusableComponentsModule } from '@easyhpad-ui/app/bundles/reusable-components/reusable-components.module';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { FilterModule } from '@easyhpad-ui/app/library/filter/filter.module';
import { FormModule } from '@easyhpad-ui/app/library/form/form.module';
import { InputNumberModule } from 'primeng/inputnumber';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import {
  CpomAnnexListItemComponent,
  CpomListComponent,
  CpomListFiltersComponent,
  CpomListItemComponent,
} from './components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationModule,
    FacilityFormModule,
    FormModule,
    MediaModule,
    PanelModule,
    ReusableComponentsModule,
    TooltipModule,
    FacilityUiModule,
    FilterModule,
    PanelModule,
    InputNumberModule,
  ],
  declarations: [
    FormCpomFundingInputsRowComponent,
    FormLocalCpomInputsRowComponent,
    FormCPOMEditComponent,
    CPOMLinkComponent,
    FormCpomFundingTableComponent,
    FormCpomTariffOptionSelectComponent,
    CpomFundingTableComponent,
    CpomListItemComponent,
    TariffOptionValueComponent,
    LocalCpomOthersTableComponent,
    CpomListComponent,
    CpomListFiltersComponent,
    CpomAnnexListItemComponent,
  ],
  exports: [
    FormCpomFundingInputsRowComponent,
    FormLocalCpomInputsRowComponent,
    FormCPOMEditComponent,
    CPOMLinkComponent,
    FormCpomTariffOptionSelectComponent,
    CpomFundingTableComponent,
    CpomListItemComponent,
    TariffOptionValueComponent,
    LocalCpomOthersTableComponent,
    CpomListComponent,
    CpomListFiltersComponent,
    CpomAnnexListItemComponent,
  ],
  providers: [CPOMFormBuilder, LocalCPOMFormBuilder, CPOMFundingFormBuilder],
})
export class CPOMUIModule {}
