import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidPMPValue} from "@domain/pmp";

@ValidatorConstraint({name: 'IsPMPValue', async: false})
export class IsPMPValueConstraint implements ValidatorConstraintInterface {

  public validate(value: any, args?: ValidationArguments): boolean {
    return isValidPMPValue(value);
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'Value is not a valid P.M.P..';
  }
}

export function IsPMPValue(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsPMPValueConstraint
    });
  };
}
