import { PhoneNumber } from '@domain/phone/phone.interface';
import { configuration } from '@easyhpad-ui/environments/configuration';
import { PhoneNumber as GooglePhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const util = PhoneNumberUtil.getInstance();

export class PhoneNumberImpl implements PhoneNumber {
  private readonly phoneNumber: GooglePhoneNumber;

  public get region(): string {
    return util.getRegionCodeForCountryCode(this.phoneNumber.getCountryCodeOrDefault());
  }

  public get number(): string {
    try {
      return util.format(this.phoneNumber, PhoneNumberFormat.NATIONAL);
    } catch (e) {
      console.error(e, this);
      return '';
    }
  }

  constructor(number: string, region: string = configuration.defaultPhoneNumberRegion) {
    try {
      this.phoneNumber = util.parse(number ?? undefined, region);
    } catch (e) {
      console.error(e, { number, region });
      this.phoneNumber = util.parse();
    }
  }

  public toString() {
    return this.number || '';
  }
}
