import { EPRD_ERRD_TRANSLATE_CONTEXT, EPRDCreatedEvent, EPRDCreationFailEvent } from '@application/bundles/eprd-errd';
import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';

@HasEventSubscribers()
export class OnEPRDCreationEventSubscriber {
  constructor(private readonly noticeStream: NoticeStream) {}

  @OnEvent({
    event: EPRDCreatedEvent,
  })
  private async pushNoticeOnEPRDCreationSuccess(event: EPRDCreatedEvent): Promise<void> {
    const message = new TranslatableString(
      "L'<strong>E.P.R.D. {{year}}</strong> a été créé avec succès.",
      { year: event.eprd.accountingYear },
      EPRD_ERRD_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: EPRDCreationFailEvent,
  })
  private pushNoticeOnEPRDCreationFail(event: EPRDCreationFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la création du nouvel E.P.R.D.',
        undefined,
        EPRD_ERRD_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }
}
