import {AppState} from "@easyhpad-ui/app/store";
import {CALCULATOR_STORE_KEY, CalculatorStoreState} from "@easyhpad-ui/app/bundles/calculator/store/index";
import {createSelector} from "@ngrx/store";

export class CalculatorSelectors {

  public static calculatorState = (state: AppState) => state[CALCULATOR_STORE_KEY];


  public static isOpen = createSelector(
    CalculatorSelectors.calculatorState,
    (state: CalculatorStoreState) => state.isOpen
  );
}
