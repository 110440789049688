export function isMaybeAFrenchPostalCode(value: string): boolean {
  const regex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
  return regex.test(value.toUpperCase());
}

export function isValidFrenchOverseasPostalCode(postalCode: string): boolean {
  const regex = /^(97[1-5]|98[4-7]|98[7-8]|986|987|988|989|99[0-5])\d{2}$/g;
  return isMaybeAFrenchPostalCode(postalCode) && regex.test(postalCode);
}



