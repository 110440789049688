import { Facility } from '@domain/facility';
import { Serializable } from '@application/framework/lib';
import { FileDeposit } from '@domain/file-deposit';
import { Customer } from '@domain/customer';

export interface SerializedFileDepositSearchParams {
  customerId?: Customer['id'];
  facilities?: Array<Facility['id']>;

  search?: {
    states?: FileDeposit['state'] | Array<FileDeposit['state']>;
  };
}

export abstract class FileDepositSearchParams implements Serializable<SerializedFileDepositSearchParams> {
  public abstract setCustomer(customer: Customer | Customer['id']): this;

  public abstract setFacilities(ids: Array<Facility['id']> | null): this;

  public abstract setStates(state: FileDeposit['state'] | Array<FileDeposit['state']>): this;

  public abstract serialize(): SerializedFileDepositSearchParams;

  public abstract deserialize(params?: SerializedFileDepositSearchParams): FileDepositSearchParams;
}
