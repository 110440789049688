import {AutoAuthentificationFailEvent} from "@application/bundles/user/events/auto-authentification-fail.event";
import {AutoAuthentificationSuccessEvent} from "@application/bundles/user/events/auto-authentification-success.event";
import {UserAuthentificationFailEvent} from "@application/bundles/user/events/user-authentification-fail.event";
import {UserAuthentificationSuccessEvent} from "@application/bundles/user/events/user-authentification-success.event";
import {
  CurrentUserAccessor
} from "@application/bundles/user/implementations/current-user-accessor/current-user.accessor";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {AuthentificationFailEvent} from "@application/bundles/authentification/events/authentification-fail.event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class OnUserAuthentificationSubscriber {


  public constructor(
    private readonly accessor: CurrentUserAccessor,
    private readonly noticeStream: NoticeStream
  ) {
  }

  /**
   * Store user from event in current user repository
   * @param event
   */
  @OnEvent({
    event: [
      UserAuthentificationSuccessEvent,
      AutoAuthentificationSuccessEvent
    ]
  })
  public setCurrentUserInRepository(event: UserAuthentificationSuccessEvent | AutoAuthentificationSuccessEvent) {
    this.accessor.set(event.user);
  }

  /**
   * If current user is valid, store undefined as current user
   * @param event
   */
  @OnEvent({
    event: [
      UserAuthentificationFailEvent,
      AutoAuthentificationFailEvent
    ]
  })
  public removeCurrentUserFromRepository(event: UserAuthentificationFailEvent | AutoAuthentificationFailEvent) {
    if (this.accessor.get()) {
      this.accessor.set(undefined);
    }

  }

  /**
   * Push notice on authentification fail
   * @param event
   */
  @OnEvent({
    event: UserAuthentificationFailEvent
  })
  public async sendAuthentificationFailNotice(event: AuthentificationFailEvent) {

    const message = new TranslatableString('Connexion refusée, merci de bien vouloir réessayer.');
    this.noticeStream.push({message, type: NoticeType.ERROR});
  }

}
