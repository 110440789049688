import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';
import {isValidYear} from "@domain/lib/date/year";

@ValidatorConstraint({name: 'IsValidYear', async: false})
export class IsValidYearConstraint implements ValidatorConstraintInterface {

  public validate(year: any, args?: ValidationArguments): boolean {
    return isValidYear(year);
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'year is not a valid year.';
  }
}

export function IsValidYear(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidYearConstraint
    });
  };
}
