import { FundingCollection, SubFunding } from '@domain/funding';

export function isFundingCollection<T extends SubFunding = any>(collection: any): collection is FundingCollection<T> {
  return (
    typeof collection === 'object' &&
    collection !== null &&
    typeof collection[Symbol.iterator] === 'function' &&
    typeof collection.sum === 'function' &&
    typeof collection.values === 'function' &&
    typeof collection.size === 'number' &&
    typeof collection.sort === 'function'
  );
}
