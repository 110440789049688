import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ComputableResidentGIRList, GIRLabels } from '@domain/gir';
import { LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { Color } from '@swimlane/ngx-charts/lib/utils/color-sets';

@Component({
  selector: 'ehp-resident-gir-list-chart-donut',
  templateUrl: './chart-resident-gir-list-donut.component.html',
  styleUrls: ['./chart-resident-gir-list-donut.component.scss'],
})
export class ChartResidentGirListDonutComponent implements OnChanges {
  @Input() public residents!: ComputableResidentGIRList;

  public data: Array<{ name: string; value: number }> = [];

  public colors: Color = {
    name: 'custom',
    selectable: false,
    group: ScaleType.Linear,
    domain: [
      'var(--ehp--color--secondary--50)',
      'var(--ehp--color--secondary--200)',
      'var(--ehp--color--secondary--400)',
      'var(--ehp--color--secondary--600)',
      'var(--ehp--color--secondary--800)',
      'var(--ehp--color--secondary--900)',
    ],
  };

  public legendOptions = {
    display: true,
    position: LegendPosition.Below,
    title: '',
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['residents']) {
      this.residentsHasChange();
    }
  }

  private residentsHasChange() {
    const data: Array<{ name: string; value: number }> = [];

    if (this.residents) {
      this.residents.all().forEach(item => {
        data.push({ name: `${GIRLabels.get(item.gir) || item.gir} : ${item.value}`, value: item.value });
      });
    }

    this.data = data;
  }
}
