import {ComponentRef, Directive, Input, OnChanges, OnDestroy, SimpleChanges, ViewContainerRef} from '@angular/core';
import {DashboardWidget} from "@easyhpad-ui/app/bundles/dashboard/interfaces/dashboard-widget-content.reference";
import {WidgetResolver} from "@easyhpad-ui/app/bundles/dashboard/services/widget-resolver/widget.resolver";

@Directive({
  selector: '[ehpWidgetContentResolver]',
})
export class WidgetContentResolverDirective implements OnChanges, OnDestroy {

  @Input('resolve') public resolver!: DashboardWidget<any>;

  private reference: ComponentRef<any> | undefined;
  
  constructor(
    public readonly viewContainerRef: ViewContainerRef,
    public readonly widgetResolver: WidgetResolver
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['resolver']) {
      this.resolve();
    }
  }

  public ngOnDestroy() {
    if (this.reference) {
      this.reference.destroy();
    }
  }

  private resolve() {
    this.viewContainerRef.clear();

    if (this.resolver !== undefined && this.resolver.content !== undefined) {
      this.reference = this.widgetResolver.injectContent(this.viewContainerRef, this.resolver);
    }

  }
}
