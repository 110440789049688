<ng-container *ngIf="document">

  <h3 class="title item-title m0">

    <span *ngIf="facilities && facilities.length > 1; else iconSingle"
          class="icon ehp-icon icon-folder"></span>

    <ng-template #iconSingle>
      <span class="icon ehp-icon icon-file"></span>
    </ng-template>

    <ehp-ehpad-demain-link [document]="document"></ehp-ehpad-demain-link>
  </h3>

  <div class="dates">
    <ehp-date-interval [dates]="{start: document.start, end: document.end}"></ehp-date-interval>
  </div>

  <div class="amount">
    {{document.amount | currency }}
  </div>

  <div class="facility">
    <ehp-countable-label *ngIf="facilities" [count]="facilities.length || 0" [displaySingle]="false" class="facilities">
      <ng-container plural>{{'établissements' | ehp_translate}}</ng-container>
      <ng-container *ngIf="facilities[0] as facility" single>{{facility.name}}</ng-container>
    </ehp-countable-label>
  </div>

  <div class="actions-list actions">

    <ehp-ehpad-demain-link
      [document]="document"
      class="is-icon icon-arrow ghost button"
      pTooltip="{{'Voir' | ehp_translate}}"
      tooltipPosition="bottom"
    >
      <span class="label">{{'Consulter l\'expériementation EHPAD de demain' | ehp_translate }}</span>
    </ehp-ehpad-demain-link>

  </div>

</ng-container>
