import { SelectionModel } from '@angular/cdk/collections';
import { Dialog } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CustomerSearchParams, CustomerSortKey } from '@application/bundles/customer';
import { DeleteCustomerCommand } from '@application/bundles/customer/commands/delete-customer.command';
import { AgnosticCustomerSearchParams } from '@application/bundles/customer/implementation/agnostic-customer-seach-params/agnostic-customer-search-params';
import { CommandBus } from '@application/framework/command-query';
import { PaginationParams } from '@application/framework/search';
import { Sort, SORT_ORDER } from '@application/framework/search/sort';
import { Customer } from '@domain/customer';
import { ConfirmCustomerDeletionDialogComponent } from '@easyhpad-ui/app/bundles/customer/modules/ui/components/confirm-customer-deletion-dialog/confirm-customer-deletion-dialog.component';
import { ConfirmCustomerDeletionDialog } from '@easyhpad-ui/app/bundles/customer/modules/ui/components/confirm-customer-deletion-dialog/confirm-customer-deletion-dialog.interface';
import { CUSTOMER_SELECTORS, CustomerActions } from '@easyhpad-ui/app/bundles/customer/store';
import { DialogConfiguration } from '@easyhpad-ui/app/library/dialog';
import { TableColumnsList } from '@easyhpad-ui/app/library/table/table-columns-list/table-columns-list';
import { AppState } from '@easyhpad-ui/app/store';
import { Store } from '@ngrx/store';
import { debounceTime, map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'ehp-admin-list',
  templateUrl: './admin-customer-list-page.component.html',
  styleUrls: ['./admin-customer-list-page.component.scss'],
})
export class AdminCustomerListPageComponent {
  public customers: Array<Customer> = [];

  public selection: SelectionModel<Customer['id']> = new SelectionModel();

  public selectionCount$: Observable<number>;

  public columns: TableColumnsList<keyof Customer | 'select' | 'actions'>;

  public filters: FormGroup<Record<'name', FormControl>>;

  public paginationResults: { result: number; page: number } | undefined;

  public loading = false;

  private pagination: PaginationParams = { limit: 50 };

  private sortOption: Sort<CustomerSortKey> = { key: 'createdAt', order: SORT_ORDER.DESC };

  constructor(
    private readonly commandBus: CommandBus,
    private readonly formBuilder: FormBuilder,
    private readonly dialog: Dialog,
    private readonly store: Store<AppState>,
  ) {
    this.columns = new TableColumnsList<keyof Customer | 'select' | 'actions'>(
      [
        { key: 'select', label: '', maskable: false },
        { key: 'name', label: 'Nom du client', sortable: true },
        { key: 'address', label: 'Adresse de facturation' },
        { key: 'phone', label: 'Numéro de téléphone' },
        { key: 'blocked', label: 'Client bloqué' },
        { key: 'createdAt', label: 'Date de création', sortable: true },
        { key: 'actions', label: 'Actions', maskable: false },
      ],
      'admin_customer_list',
    );

    this.store.select(CUSTOMER_SELECTORS.selectCustomerAdmin).subscribe(state => {
      this.loading = state.loading;
      this.customers = state.entities;
    });

    this.selectionCount$ = this.selection.changed.pipe(
      startWith(0),
      map(() => this.selection.selected.length),
    );

    this.filters = this.formBuilder.group({
      name: new FormControl(''),
    });

    this.filters.controls.name.valueChanges.pipe(debounceTime(200)).subscribe(() => this.search());

    this.getCustomers(this.createParams());
  }

  public isSelected(id: Customer['id']): boolean {
    return this.selection.isSelected(id);
  }

  public toggleSelection(id: Customer['id']): void {
    this.selection.toggle(id);
  }

  public delete(): void {
    if (!this.selection.hasValue()) {
      return;
    }

    const customer = this.customers
      .filter(customer => this.selection.selected.includes(customer.id))
      .filter(customer => !!customer)[0];

    if (!customer) {
      return;
    }

    const data: ConfirmCustomerDeletionDialog = {
      customer,
    };

    this.dialog
      .open(ConfirmCustomerDeletionDialogComponent, {
        ...DialogConfiguration,
        data,
      })
      .closed.subscribe(result => {
        if (result === true) {
          this.commandBus.execute(new DeleteCustomerCommand(customer)).then(deleted => {
            if (deleted) {
              this.selection.clear();
              this.getCustomers();
            }
          });
        }
      });
  }

  public search() {
    const params = this.createParams();
    this.getCustomers(params);
  }

  public changePagination(pagination: PaginationParams) {
    this.pagination = pagination;
    this.loadCustomer();
  }

  public sort(key: CustomerSortKey, order: SORT_ORDER) {
    this.sortOption = { key, order };
    this.loadCustomer();
  }

  private loadCustomer() {
    this.getCustomers(this.createParams());
  }

  private getCustomers(params?: CustomerSearchParams): void {
    this.loading = true;
    this.store.dispatch(CustomerActions.loadAdminCustomers({ params }));
  }

  private createParams(): CustomerSearchParams {
    const params = new AgnosticCustomerSearchParams();
    params.setSort(this.sortOption);
    params.searchName(this.filters.value.name);

    if (this.pagination) {
      params.setPagination(this.pagination);
    }
    return params;
  }
}
