import { DepartmentGMPIndicatorRepository } from '@application/bundles/gmp';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { DepartmentCode } from '@domain/geo';
import { ConsolidatedDepartmentGMPIndicator } from '@domain/gmp';
import { SourcedData } from '@application/bundles/sourced-data';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { Service } from '@application/framework/di';

import { EasyRepositoryAbstract } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { RequestParams } from '@application/framework/http';

@Service()
export class EasyDepartmentGMPIndicatorRepository
  extends EasyRepositoryAbstract
  implements DepartmentGMPIndicatorRepository
{
  @ProvideLogger()
  private readonly logger!: Logger;

  constructor(private readonly api: EasyBackendApi) {
    super('gmp-indicator');
  }

  public async listLatest(params?: {
    departmentCodes?: DepartmentCode[];
  }): Promise<ConsolidatedDepartmentGMPIndicator[]> {
    return this.backend
      .get<{ entities: SourcedData<ConsolidatedDepartmentGMPIndicator>[] }>(
        `${this.basePath}/latest`,
        this.convertParams(params),
      )
      .then(response => this.parseResponse(response))
      .then(body => body.entities);
  }

  public async getLatestForDepartment(
    departmentCode: DepartmentCode,
  ): Promise<ConsolidatedDepartmentGMPIndicator | undefined> {
    try {
      const response = await this.api.get<{ entity: SourcedData<ConsolidatedDepartmentGMPIndicator> | null }>(
        `${this.basePath}/${departmentCode}/latest`,
      );

      return response.body.entity ?? undefined;
    } catch (error: any) {
      this.logger.error(error.message);
      return undefined;
    }
  }

  protected override convertParams(params?: { departmentCodes?: DepartmentCode[] }): RequestParams {
    const p: RequestParams = {};

    if (params && params.departmentCodes) {
      p['departments'] = params.departmentCodes.join(', ');
    }

    return p;
  }
}
