import { Component, Input } from '@angular/core';
import { NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';
import { Observable } from 'rxjs';

@Component({
  selector: 'ehp-dynamic-notice-list',
  templateUrl: './dynamic-notice-list.component.html',
  styleUrl: './dynamic-notice-list.component.scss',
})
export class DynamicNoticeListComponent {
  @Input('notices') public notices$!: Observable<Array<{ message: string | TranslatableString; type: NoticeType }>>;
}
