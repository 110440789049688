import { LocalMedia, Media } from '@domain/media';
import { ERRD } from '@domain/eprd-errd';
import { EPRDERRDDocuments } from '@domain/eprd-errd/eprd-errd-documents';
import { CreateLocalERRDDto, UpdateLocalERRDDto } from '@application/bundles/eprd-errd';

export interface CreateERRDDto
  extends Omit<ERRD, keyof EPRDERRDDocuments | 'id' | 'details' | 'reportId' | 'report' | 'isSingle' | 'singleChild'> {
  details: CreateLocalERRDDto[];

  completeDocumentId?: Media['id'];

  payrollDocumentId?: Media['id'];

  financialAnnexDocumentId?: Media['id'];

  activityAnnexDocumentId?: Media['id'];

  reportId?: Media['id'];

  completeDocument: LocalMedia;

  payrollDocument: LocalMedia;

  financialAnnexDocument: LocalMedia;

  activityAnnexDocument: LocalMedia;

  reportDocument?: LocalMedia;
}

export interface UpdateERRDDto
  extends Omit<ERRD, keyof EPRDERRDDocuments | 'details' | 'reportId' | 'report' | 'isSingle' | 'singleChild'> {
  details: UpdateLocalERRDDto[];

  completeDocumentId?: Media['id'];

  payrollDocumentId?: Media['id'];

  financialAnnexDocumentId?: Media['id'];

  activityAnnexDocumentId?: Media['id'];

  completeDocument: LocalMedia | Media;

  payrollDocument: LocalMedia | Media;

  financialAnnexDocument: LocalMedia | Media;

  activityAnnexDocument: LocalMedia | Media;

  reportId?: Media['id'];

  reportDocument?: LocalMedia | Media;
}
