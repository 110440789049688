<div class="primary-container">
  <ng-content select="[primary-action]"></ng-content>

  <!-- This button triggers the overlay and is it's origin -->
  <button #trigger="cdkOverlayOrigin" (click)="isOpen = !isOpen" cdkOverlayOrigin type="button">
    {{isOpen ? "Close" : "Open"}}
  </button>
</div>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayOrigin]="trigger" cdkConnectedOverlay>
  <ng-content select="[actions-list]"></ng-content>
</ng-template>
