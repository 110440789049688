import { Stored } from '@application/framework/lib';
import { CPOM, CPOMFunding, LocalCPOM } from '@domain/cpom';
import { Media } from '@domain/media';
import { Facility } from '@domain/facility';
import { UUID } from '@domain/lib';

export interface StoredCPOM extends Stored<CPOM, UUID> {
  documentId: Media['id'];

  children: StoredLocalCPOM[];

  readonly singleChild: StoredLocalCPOM | undefined;
}

export interface StoredLocalCPOM extends Stored<LocalCPOM, UUID> {
  parent: StoredCPOM | undefined;

  facilityId: Facility['id'];
}

export interface StoredCPOMFunding extends Stored<CPOMFunding, UUID> {}
