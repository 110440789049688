import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslatableString } from '@application/framework/translation';
import { DOCUMENT_TYPES } from '@domain/document';

@Component({
  selector: 'ehp-file-deposit-media-type-label',
  templateUrl: './file-deposit-media-type-label.component.html',
  styleUrls: ['./file-deposit-media-type-label.component.scss'],
})
export class FileDepositMediaTypeLabelComponent implements OnChanges {
  @Input() public key!: string;

  @Input() public type!: DOCUMENT_TYPES;

  public label: TranslatableString | undefined;

  public ngOnChanges(changes: SimpleChanges): void {
    switch (this.key) {
      case 'arrete':
        this.label = this.getArreteLabel();
        break;

      case 'report':
        this.label = this.getReportLabel();
        break;

      case 'annexes':
        this.label = this.getAnnexesLabel();
        break;

      case 'authorization':
        this.label = this.getAuthorizationLabel();
        break;

      case 'pv':
        this.label = this.getPvLabel();
        break;

      case 'complete':
        this.label = this.getCompleteLabel();
        break;

      case 'document':
        this.label = new TranslatableString('Document');
        break;

      case 'payroll':
        this.label = new TranslatableString(`Masse salariale (T.E.R.)`);
        break;

      case 'activity_annex':
        this.label = new TranslatableString(`Annexe d'activité`);
        break;

      case 'financial_annex':
        this.label = new TranslatableString('Annexe financière');
        break;

      case 'contract':
        this.label = this.getContractLabel();
        break;

      default:
        this.label = this.key ? new TranslatableString(this.key) : undefined;
    }
  }

  private getArreteLabel(): TranslatableString {
    return new TranslatableString('Arrêté');
  }

  private getPvLabel(): TranslatableString {
    return new TranslatableString('Procès verbal');
  }

  private getReportLabel(): TranslatableString {
    return new TranslatableString('Rapport');
  }

  private getAuthorizationLabel() {
    return new TranslatableString('Autorisation');
  }

  private getCompleteLabel() {
    if (this.type === DOCUMENT_TYPES.EPRD) {
      return new TranslatableString(`E.P.R.D. complet`);
    } else if (this.type === DOCUMENT_TYPES.ERRD) {
      return new TranslatableString(`E.R.R.D. complet`);
    }

    return new TranslatableString(`Document complet`);
  }

  private getAnnexesLabel() {
    return new TranslatableString(`Annexes`);
  }

  private getContractLabel() {
    if (this.type === DOCUMENT_TYPES.CPOM) {
      return new TranslatableString('Document socle');
    }
    return new TranslatableString('Contrat');
  }
}
