import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {CPOMRepository, GetCurrentLocalCPOMQuery, StoredLocalCPOM} from "@application/bundles/cpom/";

@HandleQuery({
  query: GetCurrentLocalCPOMQuery
})
export class GetCurrentLocalCpomQueryHandler implements QueryHandler<GetCurrentLocalCPOMQuery, StoredLocalCPOM | undefined> {

  constructor(private readonly repository: CPOMRepository) {
  }

  public async handle(query: GetCurrentLocalCPOMQuery): Promise<StoredLocalCPOM | undefined> {

    const cpom = await this.repository.current(query.facilityId);

    if (cpom) {
      return cpom.children.find(child => child.facilityId === query.facilityId);
    }

    return undefined;
  }
}
