import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { isValidCustomerIdType } from '@application/bundles/customer';

@ValidatorConstraint({ name: 'IsValidCustomerId', async: true })
export class IsValidCustomerIdConstraint implements ValidatorConstraintInterface {
  public validate(id: any, args?: ValidationArguments): Promise<boolean> {
    return Promise.resolve(isValidCustomerIdType(id));
  }

  public defaultMessage(args?: ValidationArguments) {
    return 'ID is not a valid Customer ID.';
  }
}

export function IsValidCustomerId(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidCustomerIdConstraint,
    });
  };
}
