import {Injectable} from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from "@angular/router";
import {PageTitleAccessor} from "../../page-title.accessor";

@Injectable({
  providedIn: 'root'
})
export class EasyhpadTitleStrategy extends TitleStrategy {

  constructor(private title: PageTitleAccessor) {
    super();
  }

  public override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title !== undefined) {
      this.title.set(title);
    }
  }
}
