import { Component } from '@angular/core';

@Component({
  selector: 'ehp-access-image',
  templateUrl: './access-image.component.html',
  styleUrls: ['./access-image.component.scss']
})
export class AccessImageComponent {

}
