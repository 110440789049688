import { Command, Query, QueryHandler } from '@application/framework/command-query';
import { CommandHandler } from '@application/framework/command-query/handler.interface';
import { Class, Constructable } from '@application/framework/lib';
import { isProvider } from '@application/framework/di/providers/provider';
import { ServiceProvider } from '@application/framework/di/interfaces/service-metadata.interface';
import { DependenciesInjectionConfiguration } from '@application/framework/di';
import { Voter } from '@application/bundles/authorization';
import { VoterRegistry } from '@application/bundles/authorization/abstractions/voter.registry.abstract';

export interface BundleOptions {
  imports?: Array<Class<any>>;
  commandHandlers?: Constructable<CommandHandler<Command<unknown>>>[];
  queryHandlers?: Constructable<QueryHandler<Query<unknown>>>[];
  eventSubscribers?: Constructable<any>[];
  providers?: ServiceProvider[];
  voters?: Voter[];
}

export function Bundle(options?: BundleOptions): ClassDecorator {
  const imports = options?.providers;

  if (Array.isArray(imports)) {
    imports.filter(isProvider).forEach((provider) => {
      DependenciesInjectionConfiguration.serviceDecorator(provider.provide, { ...provider, id: provider.provide });
    });
  }

  const voters = options?.voters;

  if (Array.isArray(voters)) {
    voters.forEach((voter) => VoterRegistry.set(voter));
  }

  return (target) => {};
}

/**
 *
 * return function <T extends Constructable<any>>(constructor: T) {
 *     return class extends constructor {
 *       constructor(...args: any[]) {
 *         super(...args);
 *       }
 *     }
 *   };
 *
 *
 */
