<header class="widget-header">
  <h3 class="widget-title title">{{"Autorisation de capacité" | ehp_translate}}</h3>
</header>

<div class="widget-content">

  <div *ngIf="!authorization" class="empty">
    <p>{{'Aucune autorisation de capacité renseignée pour cet établissement' | ehp_translate}}</p>
    <a *ngIf="createUrl" [routerLink]="createUrl" class="target-link">{{'Nouvel archivage' | ehp_translate}}</a>
  </div>

</div>

<div class="widget-footer">

  <div class="dates-container">
    <ehp-date-interval *ngIf="authorization" [dates]="authorization.dates" format="yyyy"></ehp-date-interval>
  </div>

  <a *ngIf="url" [routerLink]="url" class="target-link">{{ "Voir l'autorisation" | ehp_translate }}</a>
</div>

