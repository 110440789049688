import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  EprdLinkComponent,
  EprdListComponent,
  EprdListItemComponent,
  EprdNameComponent,
  OtherLocalEprdListComponent
} from "./components";
import {GmpUIModule} from "@easyhpad-ui/app/bundles/gmp/modules/ui/gmp-ui.module";
import {ReusableComponentsModule} from "@easyhpad-ui/app/bundles/reusable-components/reusable-components.module";
import {TranslationModule} from "@easyhpad-ui/app/framework/translation/translation.module";
import {TooltipModule} from "primeng/tooltip";
import {FacilityUiModule} from "@easyhpad-ui/app/bundles/facility/modules/ui/facility-ui.module";


@NgModule({
  declarations: [
    EprdNameComponent,
    EprdLinkComponent,
    EprdListComponent,
    EprdListItemComponent,
    OtherLocalEprdListComponent
  ],
  imports: [
    CommonModule,
    GmpUIModule,
    ReusableComponentsModule,
    TranslationModule,
    TooltipModule,
    FacilityUiModule
  ],
  exports: [
    EprdNameComponent,
    EprdLinkComponent,
    EprdListComponent,
    OtherLocalEprdListComponent,
  ]
})
export class EprdUiModule {
}
