import { CreateEasyLocalEPRD, EasyLocalEPRD, UpdateEasyLocalEPRD } from './easy-local-eprd';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { UUID, Year } from '@domain/lib';
import { DateString } from '@application/framework/date';

export interface EasyEPRD {
  id: UUID;

  accountingYear: Year;

  depositDate: DateString | null | undefined;

  details: EasyLocalEPRD[];

  completeDocumentId: EasyMedia['id'];

  payrollId?: EasyMedia['id'] | null | undefined;

  financialAnnexId?: EasyMedia['id'] | null | undefined;

  activityAnnexId?: EasyMedia['id'] | null | undefined;

  reportId?: EasyMedia['id'] | null | undefined;
}

export interface CreateEasyEPRD extends Omit<EasyEPRD, 'id' | 'details'> {
  details: Array<CreateEasyLocalEPRD>;
}

export interface UpdateEasyEPRD extends Omit<EasyEPRD, 'details'> {
  details: Array<UpdateEasyLocalEPRD>;
}
