import { DepartmentCode } from '../../entities/department';

const FRENCH_DEPARTMENT_CODE_REGEX = /^(0[1-9]|[1-8]\d|9[0-5]|2[AB]|9[78]\d|97[1-6]|98[4689]|989|69|69D|69M)$/;

/**
 * Checks if the given code corresponds to a valid French department code.
 *
 * @param {string} code - The code to check.
 * @returns {boolean} True if the code is valid, otherwise false.
 *
 * @since 1.1.0
 */
export function isDepartmentCode(code: any): code is DepartmentCode {

  if (typeof (code as any) !== 'string' || code === '') {
    return false;
  }

  return FRENCH_DEPARTMENT_CODE_REGEX.test(code);
}