<ng-container *ngIf="state">

  <ng-container [ngSwitch]="state">
    <span *ngSwitchCase="states.DRAFT"> {{ 'Brouillon' | ehp_translate }}</span>
    <span *ngSwitchCase="states.ONBOARDING">{{ 'En création' | ehp_translate }}</span>
    <span *ngSwitchCase="states.ACTIVE">{{ 'Actif' | ehp_translate }}</span>
    <span *ngSwitchDefault [innerHTML]="state"></span>
  </ng-container>

</ng-container>
