import { Facility } from '@domain/facility';
import { ERRD } from './errd';
import { FinancialAnnex } from '@domain/eprd-errd/financial-annex';
import { PartialActivityAnnex } from '@domain/eprd-errd';
import { TPER } from '@domain/eprd-errd/tper';
import { UUID } from '@domain/lib';

export interface LocalERRD {
  id: UUID;

  expense: number;

  revenue: number;

  CAF: number | undefined;

  usedTreasury: number | undefined;

  independenceRate: number | undefined;

  estateDegradation: { comment: string; rate: number } | undefined;

  financialAnnex: FinancialAnnex;

  activityAnnex: PartialActivityAnnex;

  tper: TPER[];

  facilityId: Facility['id'];

  parentId: ERRD['id'];

  readonly parent: ERRD;

  occupationRate(): number | undefined;

  facility(): Promise<Facility>;

  refreshParent(): Promise<ERRD>;
}
