import { Transformer } from '@application/framework/core';
import { LocalEPRD } from '@domain/eprd-errd';
import { CreateEasyLocalEPRD, EasyLocalEPRD, UpdateEasyLocalEPRD } from '@implementations/bundles/backend/easyhpad';
import { LocalEPRDImpl } from '@implementations/bundles/eprd-errd/implementations';
import { CRPPTransformer } from '@implementations/bundles/backend/easyhpad/transformers/errd-errd/crpp.transformer';
import { Provide, Service } from '@application/framework/di';
import { EasyLocalCommonTransformer } from '@implementations/bundles/backend/easyhpad/transformers/errd-errd/common-local.abstract.transformer';
import { CreateLocalEPRDDto, UpdateLocalEPRDDto } from '@application/bundles/eprd-errd';
import { Common } from '@application/framework/lib';

type CommonLocalDtoProperties = Common<CreateLocalEPRDDto, UpdateLocalEPRDDto>;

type CommonEasyLocalDtoProperties = Common<CreateEasyLocalEPRD, UpdateEasyLocalEPRD>;

@Service()
export class LocalEprdTransformer extends EasyLocalCommonTransformer implements Transformer<LocalEPRD, EasyLocalEPRD> {
  @Provide()
  private readonly crppTransformer!: CRPPTransformer;

  public override transform(value: LocalEPRD): EasyLocalEPRD {
    const base = super.transform(value);
    return {
      ...base,
      id: value.id,
      parentId: value.parentId,
      crpp: Array.isArray(value.CRPP) ? value.CRPP.map(crpp => this.crppTransformer.transform(crpp)) : [],
    };
  }

  public override reverseTransform(value: EasyLocalEPRD): LocalEPRD {
    const defaults: Omit<LocalEPRD, 'facility' | 'parent' | 'refreshParent' | 'occupationRate'> = {
      ...super.reverseTransform(value),
      id: value.id,
      parentId: value.parentId,
      CRPP: Array.isArray(value.crpp) ? value.crpp.map(crpp => this.crppTransformer.reverseTransform(crpp)) : [],
    };

    return new LocalEPRDImpl(defaults);
  }

  public getLocalCreateDto(dto: CreateLocalEPRDDto): CreateEasyLocalEPRD {
    return {
      ...this.getCommonLocalDtoProperties(dto),
    };
  }

  public getLocalUpdateDto(dto: UpdateLocalEPRDDto): UpdateEasyLocalEPRD {
    return {
      ...this.getCommonLocalDtoProperties(dto),
      id: dto.id,
    };
  }

  public getCommonLocalDtoProperties(dto: CommonLocalDtoProperties): CommonEasyLocalDtoProperties {
    return {
      ...super.transform(dto),
      crpp: Array.isArray(dto.CRPP) ? dto.CRPP : [],
    };
  }
}
