import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FacilityPMPWidgetComponent } from '@easyhpad-ui/app/bundles/pmp/modules/dashboard/components/widget-facility-pmp/facility-pmp-widget.component';
import { TranslationModule } from '@easyhpad-ui/app/framework/translation/translation.module';
import { PMPUIModule } from '@easyhpad-ui/app/bundles/pmp/modules/ui/pmp-ui.module';

@NgModule({
  imports: [CommonModule, RouterModule, TranslationModule, PMPUIModule],
  declarations: [FacilityPMPWidgetComponent],
  exports: [FacilityPMPWidgetComponent],
})
export class PMPDashboardModule {}
