import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { ListLatestCostByEtpIndicatorsQuery } from '@application/bundles/etp/queries/list-latest-cost-by-etp-indicators.query';

import { NationalCostByEtpRepository } from '@application/bundles/etp/repositories/national-cost-by-etp/national-cost-by-etp.repository';

@HandleQuery({
  query: ListLatestCostByEtpIndicatorsQuery,
})
export class ListLatestCostByEtpIndicatorsQueryHandler implements QueryHandler<ListLatestCostByEtpIndicatorsQuery> {
  constructor(private readonly repository: NationalCostByEtpRepository) {}

  public handle(query: ListLatestCostByEtpIndicatorsQuery): Promise<any> {
    return this.repository.listLatest();
  }
}
