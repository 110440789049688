import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, mergeMap, Observable, of, switchMap, take } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { CommandBus, QueryBus } from '@application/framework/command-query';
import { ErrorActions } from '@easyhpad-ui/app/bundles/errors/store';
import { FileDepositActions } from './file-deposit.actions';
import {
  GetFileDepositQuery,
  ListFileDepositQuery,
  SerializedFileDepositSearchParams,
} from '@application/bundles/file-deposit';
import { FILE_DEPOT_STATES, FileDeposit } from '@domain/file-deposit';
import { Store } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { selectSelectedFacilitiesForAccount } from '@easyhpad-ui/app/bundles/facility/store';
import { Facility } from '@domain/facility';
import { CreateFileDepositCommand, CreateFileDepositCommandResult } from '@application/bundles/file-deposit/commands';
import { CreateFileDeposit } from '@application/bundles/file-deposit/contracts/create-file-deposit';

@Injectable()
export class FileDepositEffects {
  public loadFileDeposits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileDepositActions.loadFileDeposits),
      withLatestFrom(this.store.select(selectSelectedFacilitiesForAccount).pipe(take(1))),
      mergeMap(([action, facilities]) =>
        from(
          this.queryBus.request<FileDeposit[]>(
            new ListFileDepositQuery(this.setDefaultParams(action.params, facilities)),
          ),
        ),
      ),
      map(deposits => FileDepositActions.setFileDeposits({ deposits })),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  public loadOnboardingFileDeposits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileDepositActions.loadOnboardingFileDeposit),
      withLatestFrom(this.store.select(selectSelectedFacilitiesForAccount).pipe(take(1))),
      mergeMap(([action, facilities]) =>
        from(
          this.queryBus.request<FileDeposit[]>(
            new ListFileDepositQuery(this.setDefaultParams(action.params, facilities)),
          ),
        ),
      ),
      map(deposits => FileDepositActions.setOnboardingFileDeposit({ deposits })),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  public loadSingleFileDeposits = createEffect(() =>
    this.actions$.pipe(
      ofType(FileDepositActions.loadSingleFileDeposit),
      mergeMap(action => from(this.queryBus.request<FileDeposit | undefined>(new GetFileDepositQuery(action.id)))),
      map(deposit => FileDepositActions.setCurrentFileDeposit({ deposit: deposit ?? null })),
      catchError(error => of(ErrorActions.catchError(error))),
    ),
  );

  public createFileDeposit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FileDepositActions.createFileDeposit),
        switchMap(action => this.createFileDeposit(action.payload)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private queryBus: QueryBus,
    private readonly commandBus: CommandBus,
  ) {}

  private setDefaultParams(
    current?: SerializedFileDepositSearchParams,
    facilities: Facility[] = [],
  ): SerializedFileDepositSearchParams {
    let params: SerializedFileDepositSearchParams = {};

    if (current) {
      params = { ...current };
    }

    if (!current?.facilities) {
      params = {
        ...params,
        facilities: facilities.map(f => f.id),
      };
    }

    if (!current?.search?.states) {
      params = {
        ...params,
        search: {
          ...(params.search ?? {}),
          states: FILE_DEPOT_STATES.WAITING_CUSTOMER,
        },
      };
    }

    return params;
  }

  private createFileDeposit(payload: CreateFileDeposit): Observable<FileDeposit> {
    return from(this.commandBus.execute<CreateFileDepositCommandResult>(new CreateFileDepositCommand(payload)));
  }
}
