<table *ngIf="funding" class="is-table funding-table">
  <thead>
  <tr>
    <th></th>
    <th>{{'Nom' | ehp_translate}}</th>
    <th>{{'Expérimentation liée' | ehp_translate}}</th>
    <th>{{'Montant' | ehp_translate}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of funding; let index = index">
    <td class="index">{{index + 1}}</td>
    <td [innerHTML]="row.name" class="n"></td>
    <td class="reference">
      <ehp-ehpad-demain-link *ngIf="row.ehpadDemainId" [refId]="row.ehpadDemainId"></ehp-ehpad-demain-link>
    </td>
    <td class="amount">{{row.amount | currency}}</td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td></td>
    <td></td>
    <td class="total-label"></td>
    <td class="total-cell">
      <span class="label">{{'Total' | ehp_translate}}:</span>
      <span class="total">{{funding.sum() | currency}}</span>
    </td>
  </tr>
  </tfoot>
</table>
