import {EHPAD_DEMAIN_TYPE, EhpadDemainType, EhpadDemainTypes} from "@domain/ehpad-demain/ehpad-demain-types";

export function isValidEhpadDemainTypeValue(value: any): value is EHPAD_DEMAIN_TYPE {
  return typeof value === "string" && EhpadDemainTypes.has(value as EHPAD_DEMAIN_TYPE);
}

export function isValidEhpadDemainType(type: any): type is EhpadDemainType {
  return typeof type === 'object'
    && type !== null
    && typeof type.type === 'string'
    && Object.values(EHPAD_DEMAIN_TYPE).includes(type.type)
    && typeof type.label === 'string';
}

