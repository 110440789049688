import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getPMPYear, NationalPMPIndicator, PMP } from '@domain/pmp';
import { NationalPMPIndicatorRepository } from '@application/bundles/pmp';

@Component({
  selector: 'ehp-pmp-list-item',
  templateUrl: './pmp-list-item.component.html',
  styleUrls: ['./pmp-list-item.component.scss'],
  host: {
    class: 'document-list-item',
  },
})
export class PmpListItemComponent implements OnChanges {
  @Input() public pmp!: PMP;

  public national: NationalPMPIndicator | undefined;

  constructor(private readonly nationalRepository: NationalPMPIndicatorRepository) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['pmp']) {
      this.pmpChange();
    }
  }

  private pmpChange() {
    this.national = undefined;

    if (this.pmp) {
      this.nationalRepository.getLatest(getPMPYear(this.pmp)).then(national => (this.national = national));
    }
  }
}
