import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QueryBus } from '@application/framework/command-query';
import { DocumentListImpl } from '@easyhpad-ui/app/library/document';
import { EPRD, LocalEPRD } from '@domain/eprd-errd';
import { Skeleton } from '@easyhpad-ui/app/bundles/reusable-components/components/skeleton';

@Component({
  selector: 'ehp-other-local-eprd-list',
  templateUrl: './other-local-eprd-list.component.html',
  styleUrls: ['./other-local-eprd-list.component.scss'],
})
export class OtherLocalEprdListComponent implements OnChanges {
  @Input() public localEPRD!: LocalEPRD;

  public list = new DocumentListImpl<EPRD>();

  public SKELETON_TYPE = Skeleton.DOCUMENT_LIST_ITEM;
  protected readonly document = document;

  constructor(private readonly queryBus: QueryBus) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.localEPRD) {
      this.list.setLoading(true);
      /** this.queryBus.request(new ListO(this.localCPOM)).then(documents => {
        this.others = documents;
      });**/

      this.list.setLoading(false);
    }
  }
}
