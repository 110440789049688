import { MEDIA_PERSISTANCE_STATE } from '@domain/media/persistance';
import { MEDIA_FILE_TYPES } from '@domain/media/types';
import { UUID } from '@domain/lib';
import { Facility } from '@domain/facility';
import { Customer } from '@domain/customer';

export interface Media {
  persistance: MEDIA_PERSISTANCE_STATE;

  id: UUID;

  name: string;

  path: string;

  type: MEDIA_FILE_TYPES;

  mimeType: string;

  facilityId?: Facility['id'];

  customerId?: Customer['id'];

  buildUrl: () => Promise<URL>;
}

export interface LocalMedia extends Omit<Media, 'id' | 'scope'> {
  file: File;
}

export interface MediaRef extends Pick<Media, 'id'> {
  isRef: true;
}

export interface TemporaryMedia extends Media {
  persistance: MEDIA_PERSISTANCE_STATE.TEMPORARY;

  media: () => Media | undefined;

  markForDeletion(): Promise<void>;

  markForPersist(): Promise<void>;
}
