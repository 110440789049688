import {LazyContainer} from "@application/framework/di/containers/lazy-container";
import {ServiceMetadata} from "@application/framework/di/interfaces/service-metadata.interface";
import {ServiceIdentifier} from "@application/framework/di/types/service-identifier";
import {Container} from "../container";

export class NullContainer extends LazyContainer implements Container {

  public override get<T = unknown>(token: ServiceIdentifier<T>): T {
    if (this.container !== undefined) {
      return this.container.get(token);
    }

    this.dispose();
    throw new Error('NullContainer used before this.container is mounted.');
  }

  public override set<T = unknown>(metadata: ServiceMetadata<T>): boolean {
    this.metadataMaps.set(metadata.id, metadata);

    return true;
  }

}
