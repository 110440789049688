import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Facility } from '@domain/facility';
import { TranslatableString } from '@application/framework/translation';
import { QueryBus } from '@application/framework/command-query';
import { DateFormatter } from '@application/framework/date';
import { PMP_TRANSLATE_CONTEXT } from '@application/bundles/pmp';
import { differenceInPercentage } from '@application/framework/lib';
import { isPositive } from 'class-validator';
import { Year } from '@domain/lib';
import { GmpUrlsProvider } from '@easyhpad-ui/app/bundles/gmp';
import { LatestGMPWidgetContent } from '@easyhpad-ui/app/bundles/dashboard/interfaces';
import { GetLatestGMPWidgetContentQuery } from '@easyhpad-ui/app/bundles/dashboard/queries';

@Component({
  selector: 'ehp-gmp-average',
  templateUrl: './gmp-average.component.html',
  styleUrls: ['./gmp-average.component.scss'],
})
export class GmpAverageComponent implements OnChanges {
  @Input() public facilityIds: Array<Facility['id']> | undefined = [];

  public value: number = 0;

  public date: string | undefined;

  public url: string | undefined;

  public average: { total: number; value: number } | undefined;

  public indicator: number | undefined;

  public indicatorLocation: TranslatableString | string | undefined;

  public indicatorYear: Year | undefined;

  public difference: number | undefined;

  public differenceMessage: TranslatableString | undefined;

  public isPositiveDifference: boolean | undefined;

  constructor(
    private readonly queryBus: QueryBus,
    private readonly dateFormatter: DateFormatter,
    private readonly urls: GmpUrlsProvider,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('facilityIds' in changes) {
      this.loadContent();
    }
  }

  private loadContent(): void {
    const facilityIds = [...(this.facilityIds ?? [])];

    this.queryBus
      .request<LatestGMPWidgetContent>(new GetLatestGMPWidgetContentQuery(facilityIds))
      .then(response => this.updateValues(response));
  }

  private async updateValues(content: LatestGMPWidgetContent) {
    this.value = content.value ?? 0;
    this.updateDepartmentIndicator(content);
    this.computeDifferenceWithIndicator();

    this.date = content.date ? this.dateFormatter.format(content.date, 'dd/MM/yyyy') : undefined;
    this.url = content.gmpId ? await this.urls.getPublicShow(content.gmpId) : undefined;

    this.average = undefined;

    if (content.average) {
      this.average = {
        value: content.average.value,
        total: content.average.total,
      };
    }
  }

  private updateDepartmentIndicator(content: LatestGMPWidgetContent) {
    this.indicatorLocation = undefined;
    this.indicatorYear = undefined;
    this.indicator = content.departmentValue;

    if (!this.indicator) {
      return;
    }

    if (content.department) {
      this.indicatorLocation = content.department.name;
      this.indicatorYear = content.departmentYear;
    } else if (Array.isArray(content.departments) && content.departments.length > 0) {
      this.indicatorLocation = new TranslatableString('{{count}} départements', { count: content.departments.length });
      this.indicatorYear = content.departmentYear;
    }
  }

  private computeDifferenceWithIndicator() {
    this.difference = undefined;
    this.differenceMessage = undefined;

    if (!this.indicator || !this.value) {
      return;
    }

    this.difference = differenceInPercentage(this.value, this.indicator);
    let message = 'Plus haut que la moyenne départementale';

    if (this.difference <= 0) {
      message = 'Plus bas que la moyenne départementale';
    }

    this.differenceMessage = new TranslatableString(message, {}, PMP_TRANSLATE_CONTEXT);
    this.isPositiveDifference = isPositive(this.difference);
  }
}
