import {
  DaycareDependencyFunding,
  DependencyFunding,
  PermanentDependencyFunding,
  TemporaryDependencyFunding,
} from '@domain/dependency-funding';
import { Provide } from '@application/framework/di';
import { QueryBus } from '@application/framework/command-query';
import { Facility } from '@domain/facility';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';
import { isPercentage, isValidYear, Year } from '@domain/lib';
import { isValidObject } from '@application/framework/lib';
import { isValidFacilityIdType } from '@application/bundles/facility';
import {
  isDaycareDependencyFunding,
  isPermanentDependencyFunding,
  isTemporaryDependencyFunding,
  isValidDependencyFundingIdType,
} from '@application/bundles/dependency-funding';
import { isPositiveFundingAmount } from '@domain/funding';
import { isValidMediaIdType } from '@application/bundles/media';
import { Media, MediaBucket } from '@domain/media';

export class DependencyFundingImpl implements DependencyFunding {
  public id!: DependencyFunding['id'];

  public date!: Date;

  public implementationDate!: Date;

  public accountingYear!: Year;

  public amount: number | undefined;

  public occupationRate!: number;

  public girValuation: number | undefined;

  public apa: number | undefined;

  public outsideDepartment: number | undefined;

  public moderatorTicket: number | undefined;

  public permanent!: PermanentDependencyFunding;

  public temporary!: TemporaryDependencyFunding<any, unknown>;

  public daycare!: DaycareDependencyFunding<any, unknown>;

  public facilityId!: Facility['id'];

  public documentId!: Media['id'];

  public reportId: Media['id'] | undefined;

  @Provide() private readonly queryBus!: QueryBus;

  @Provide() private readonly mediaBucket!: MediaBucket;

  public get year(): Year {
    return this.date.getFullYear();
  }

  constructor(defaults?: Partial<Omit<DependencyFunding, 'document' | 'report' | 'facility' | 'hasReport' | 'year'>>) {
    if (isValidObject(defaults)) {
      if (isValidDependencyFundingIdType(defaults?.id)) {
        this.id = defaults.id;
      }

      if (defaults?.date instanceof Date) {
        this.date = defaults.date;
      }

      if (isValidYear(defaults?.accountingYear)) {
        this.accountingYear = defaults?.accountingYear;
      }

      if (defaults?.implementationDate instanceof Date) {
        this.implementationDate = defaults.implementationDate;
      }

      if (isValidFacilityIdType(defaults?.facilityId)) {
        this.facilityId = defaults?.facilityId;
      }

      if (isPermanentDependencyFunding(defaults?.permanent)) {
        this.permanent = defaults?.permanent;
      }

      if (isTemporaryDependencyFunding(defaults?.temporary)) {
        this.temporary = defaults?.temporary;
      }

      if (isDaycareDependencyFunding(defaults?.daycare)) {
        this.daycare = defaults?.daycare;
      }

      if (isPositiveFundingAmount(defaults?.amount)) {
        this.amount = defaults?.amount;
      }

      if (isPositiveFundingAmount(defaults?.apa)) {
        this.apa = defaults?.apa;
      }

      if (isPositiveFundingAmount(defaults?.outsideDepartment)) {
        this.outsideDepartment = defaults?.outsideDepartment;
      }

      if (isPositiveFundingAmount(defaults?.moderatorTicket)) {
        this.moderatorTicket = defaults?.moderatorTicket;
      }
      if (isPercentage(defaults?.occupationRate)) {
        this.occupationRate = defaults.occupationRate;
      }

      if (isValidMediaIdType(defaults?.documentId)) {
        this.documentId = defaults?.documentId;
      }

      if (isValidMediaIdType(defaults?.reportId)) {
        this.reportId = defaults?.reportId;
      }

      if (typeof defaults?.girValuation === 'number') {
        this.girValuation = defaults.girValuation;
      }
    }
  }

  public facility(): Promise<Facility> {
    return this.queryBus.request<Facility>(new GetFacilityQuery(this.facilityId));
  }

  public document(): Promise<Media> {
    return this.mediaBucket.get(this.documentId);
  }

  public hasReport(): boolean {
    return isValidMediaIdType(this.reportId);
  }

  public report(): Promise<Media | undefined> {
    if (!isValidMediaIdType(this.reportId)) {
      return Promise.resolve(undefined);
    }

    return this.mediaBucket.get(this.reportId);
  }
}
