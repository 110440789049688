import {LocalMedia, Media} from "@domain/media";
import {EhpadDemain} from "@domain/ehpad-demain";


interface EhpadDemainWithoutMedia extends Omit<EhpadDemain, | 'hasConvention' | 'convention' | 'otherConvention' | 'medias' | 'type' | 'facilities' | 'createdAt' | 'updatedAt'> {
}

export interface CreateEhpadDemainDto extends Omit<EhpadDemainWithoutMedia, 'id' | 'mediasIds' | 'conventionId' | 'otherConventionId'> {

  mediaIds?: Array<Media['id']>;

  conventionId?: Media['id'];

  otherConventionId?: Media['id'];

  medias: LocalMedia[];

  convention?: LocalMedia;

  otherConvention?: LocalMedia;

}

export interface UpdateEhpadDemainDto extends Omit<EhpadDemainWithoutMedia, | 'mediasIds' | 'conventionId' | 'otherConventionId'> {

  mediaIds?: Array<Media['id']>;

  conventionId?: Media['id'];

  otherConventionId?: Media['id'];

  medias: Array<Media | LocalMedia>;

  convention?: LocalMedia | Media;

  otherConvention?: LocalMedia | Media;
}
