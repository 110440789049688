import {EPRDDtoFactory} from "@application/bundles/eprd-errd/factories/eprd-dto/eprd-dto.factory";
import {CreateEPRDDto, UpdateEPRDDto} from "@application/bundles/eprd-errd";
import {
  CreateEPRDDtoImpl,
  CreateLocalEPRDDtoImpl,
  UpdateEPRDDtoImpl,
  UpdateLocalEPRDDtoImpl
} from "@implementations/bundles/eprd-errd";

export class EprdDtoFactoryImpl implements EPRDDtoFactory {

  public buildCreateDtoFromUnsafeValues(values: Partial<CreateEPRDDto>): CreateEPRDDto {
    values.details = Array.isArray(values.details) ? values.details.map(d => new CreateLocalEPRDDtoImpl(d)) : [];
    return new CreateEPRDDtoImpl(values);
  }

  public buildUpdateDtoFromUnsafeValues(values: Partial<UpdateEPRDDto>): UpdateEPRDDto {
    values.details = Array.isArray(values.details) ? values.details.map(d => new UpdateLocalEPRDDtoImpl(d)) : [];

    return new UpdateEPRDDtoImpl(values);
  }

}
