import { TransformationFailedError, Transformer } from '@application/framework/core';
import { FACILITY_TYPE, FacilityType } from '@domain/facility/types';
import { SelectOption } from '@implementations/forms/select-option.interface';

export class FacilityTypeSelectOptionTransformer implements Transformer<FacilityType, SelectOption> {
  public transform(value: FacilityType): SelectOption {
    if (!value) {
      throw new TransformationFailedError('Missing Facility type');
    }

    if (!value.type) {
      throw new TransformationFailedError('Missing property "type" in FacilityType');
    }

    if (!value.label) {
      throw new TransformationFailedError('Missing property "label" in FacilityType');
    }

    return { value: value.type, label: value.label };
  }

  public reverseTransform(option: SelectOption): FacilityType {
    if (!option) {
      throw new TransformationFailedError('Missing option');
    }

    if (!option.value) {
      throw new TransformationFailedError('Missing property "value" in SelectOption');
    }

    if (!option.label) {
      throw new TransformationFailedError('Missing property "label" in SelectOption');
    }

    return { type: option.value as FACILITY_TYPE, label: option.label };
  }
}
