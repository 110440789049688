import {
  AccommodationFundingRepository,
  GetAccommodationFundingForYearQuery
} from "@application/bundles/accommodation-funding";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {AccommodationFunding} from "@domain/accommodation-funding";
import {getYearDateInterval} from "@application/framework/date";
import {ListAccommodationFundingParamsImpl} from "@application/bundles/accommodation-funding/implementations";

@HandleQuery({
  query: GetAccommodationFundingForYearQuery
})
export class HasAccommodationFundingForYearQueryHandler implements QueryHandler<GetAccommodationFundingForYearQuery, AccommodationFunding | undefined> {

  constructor(private readonly repository: AccommodationFundingRepository) {
  }

  public async handle(query: GetAccommodationFundingForYearQuery): Promise<AccommodationFunding | undefined> {

    const {start, end} = getYearDateInterval(query.year);

    return this.repository.list(new ListAccommodationFundingParamsImpl().setDates(start, end).setFacilities(query.facilityId))
      .then(list => list[0]);
  }
}
