import {Cache, CacheKey, CacheStore, Ttl} from "@application/framework/cache";

export class AgnosticCache<T> implements Cache<T> {

  private resolvers: Map<string, Promise<T>> = new Map();

  constructor(private readonly store: CacheStore) {
  }

  public has(key: CacheKey): Promise<boolean> {
    return this.store.has(key);
  }

  public async get(key: CacheKey): Promise<T | undefined> {
    const value = await this.store.get<T>(key);
    if (value === undefined && this.resolvers.has(key.toString())) {
      return await this.resolvers.get(key.toString());
    }

    return value;
  }

  public pick<V extends keyof T>(key: CacheKey, name: V): Promise<T[V] | undefined> {
    return this.store.pick<T, V>(key, name);
  }

  public async set(key: CacheKey, value: T, ttl: Ttl | undefined): Promise<void> {
    await this.store.set<T>(key, value, ttl);
  }

  public delete(...keys: CacheKey[]): Promise<void> {
    return this.store.delete(...keys);
  }

  public reset(): Promise<void> {
    return this.store.reset();
  }

  public wrap(key: CacheKey, fn: () => Promise<T>, ttl: Ttl | undefined): Promise<T> {
    return this.store.resolve<T>(key, fn, ttl);
  }

}
