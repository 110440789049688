import { EPRD } from '@domain/eprd-errd';
import { EPRDERRDDocuments } from '@domain/eprd-errd/eprd-errd-documents';
import { CreateLocalEPRDDto, UpdateLocalEPRDDto } from '@application/bundles/eprd-errd';
import { LocalMedia, Media } from '@domain/media';

export interface CreateEPRDDto
  extends Omit<EPRD, keyof EPRDERRDDocuments | 'id' | 'details' | 'isSingle' | 'singleChild'> {
  details: Array<CreateLocalEPRDDto>;

  completeDocumentId?: Media['id'];

  payrollDocumentId?: Media['id'];

  financialAnnexDocumentId?: Media['id'];

  activityAnnexDocumentId?: Media['id'];

  completeDocument?: LocalMedia;

  payrollDocument?: LocalMedia;

  financialAnnexDocument?: LocalMedia;

  activityAnnexDocument?: LocalMedia;

  reportId?: Media['id'];

  reportDocument?: LocalMedia;
}

export interface UpdateEPRDDto extends Omit<EPRD, keyof EPRDERRDDocuments | 'details' | 'isSingle' | 'singleChild'> {
  details: Array<UpdateLocalEPRDDto>;

  reportId?: Media['id'];

  completeDocumentId?: Media['id'];

  payrollDocumentId?: Media['id'];

  financialAnnexDocumentId?: Media['id'];

  activityAnnexDocumentId?: Media['id'];

  completeDocument?: LocalMedia | Media;

  payrollDocument?: LocalMedia | Media;

  financialAnnexDocument?: LocalMedia | Media;

  activityAnnexDocument?: LocalMedia | Media;

  reportDocument?: LocalMedia | Media;
}
