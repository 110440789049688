import { GMPRepository } from '@application/bundles/gmp';
import { ListGMPQuery } from '@application/bundles/gmp/queries/list-gmp.query';
import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { GMP } from '@domain/gmp';
import { GMPSearchParamsImpl } from '@application/bundles/gmp/implementations';

@HandleQuery({
  query: ListGMPQuery,
})
export class ListGMPQueryHandler implements QueryHandler<ListGMPQuery, GMP[]> {
  constructor(private readonly repository: GMPRepository) {}

  public async handle(query: ListGMPQuery): Promise<GMP[]> {
    return this.repository.list(new GMPSearchParamsImpl().deserialize(query.params));
  }
}
