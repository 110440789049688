import { ResidentGirListImplValues } from '@application/bundles/gir';
import { InvalidResidentListValuesError } from '@application/bundles/gir/errors';
import { ComputableResidentGIRList, GIR, GIRValues, isValidResidentTotal } from '@domain/gir';

export class ComputableResidentGirListImpl implements ComputableResidentGIRList {
  private values: ResidentGirListImplValues | undefined;

  constructor(values?: ResidentGirListImplValues) {
    if (values !== undefined) {
      this.setValues(values);
    }
  }

  public setValues(values: ResidentGirListImplValues): this {
    if (!(values instanceof Map)) {
      throw new InvalidResidentListValuesError(
        `Values provided in ${this.constructor.name} must be a Map instance (GIR => total of residents in GIR)`,
      );
    }

    for (const [gir, value] of values) {
      this.validateResidentValue(value, gir);
    }

    this.values = values;
    return this;
  }

  /**
   * Get the number of resident for a GIR, or 0 if this GIR is not in the list.
   * @param level
   */
  public get(level: GIR): number {
    if (this.values !== undefined) {
      return this.values.get(level) || 0;
    }

    return 0;
  }

  /**
   * Get the number of resident for a GIR.
   * If GIR is not in the list or if values is undefined, return value can be undefined.
   * @param level
   */
  public unsafe(level: GIR): number | any {
    return this.values?.get(level);
  }

  public sum(): number {
    if (this.values !== undefined) {
      return Array.from(this.values.values())
        .filter(value => isValidResidentTotal(value))
        .reduce((a, b) => a + b, 0);
    }

    return 0;
  }

  public isEmpty(): boolean {
    return this.values === undefined || this.values.size === 0;
  }

  public all(): Array<{ gir: GIR; value: number }> {
    return Array.from(GIRValues).map(gir => ({ gir, value: this.get(gir) }));
  }

  private validateResidentValue(value: number, gir?: GIR) {
    if (!isValidResidentTotal(value)) {
      throw new InvalidResidentListValuesError(
        `${String(value)} is not a valid representation of resident's total ${gir ? String(gir) : ''}.`,
      );
    }
  }
}
