import {Year} from "@domain/lib";
import {NationalPMPIndicator} from "@domain/pmp";

export abstract class NationalPMPIndicatorRepository {

  public abstract list(): Promise<NationalPMPIndicator[]>;

  public abstract getLatest(needle?: Year): Promise<NationalPMPIndicator | undefined>;

  public abstract getForYear(year: Year): Promise<NationalPMPIndicator | undefined>;


}
