<header class="widget-header">
  <h3 class="widget-title title">{{"P.M.P." | ehp_translate }}</h3>
</header>

<div class="widget-content">
  <ehp-pmp-value *ngIf="pmp; else empty" [pmp]="pmp"></ehp-pmp-value>
</div>

<div class="widget-footer">
  <p *ngIf="pmp">{{'Validé le'}} {{pmp.date | date:'dd/MM/YYYY'}}</p>

  <a *ngIf="pmp && url" [routerLink]="url" class="target-link is-icon small icon-small-arrow button">
    <span class="label">{{ "Voir le P.M.P." }}</span>
  </a>
</div>


<ng-template #empty>
  <p>{{ 'Aucun P.M.P. archivé pour cet établissement' | ehp_translate}}.</p>

  <a *ngIf="url" [routerLink]="url" class="target-link">
    <span class="label">{{ "Ajouter un P.M.P." | ehp_translate}}</span>
  </a>
</ng-template>
