import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ehp-countable-label',
  templateUrl: './countable-label.component.html',
  styleUrls: ['./countable-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': 'classes'
  }
})
export class CountableLabelComponent {

  @Input() public count!: number;

  @Input() public position: 'before' | 'after' = 'before';

  @Input() public displaySingle: boolean = true;

  public get displayable(): boolean {
    return typeof (this.count as any) === 'number' && !isNaN(this.count) && isFinite(this.count);
  }

  public get classes(): string {
    return `position-${this.position}`;
  }
}
