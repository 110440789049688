import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, ElementRef, forwardRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GetAssignableRolesQuery } from '@application/bundles/authorization/queries';
import { QueryBus } from '@application/framework/command-query';
import { isRole, Role, RoleCollection } from '@domain/authorization';
import { NativeChangeObserver } from '@easyhpad-ui/app/framework/native-change-observer';
import { AsyncSelectComponent } from '@easyhpad-ui/app/library/form/components/aync-select/async-select.component';
import { SelectOption } from '@implementations/forms/select-option.interface';
import { from, map, Observable, tap } from 'rxjs';
import { isSelectOption } from '@implementations/forms';

@Component({
  selector: 'ehp-user-roles-input',
  templateUrl: './user-roles-input.component.html',
  styleUrls: ['./user-roles-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserRolesInputComponent),
      multi: true,
    },
  ],
})
export class UserRolesInputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('select') public select!: AsyncSelectComponent;

  public options$: Observable<SelectOption[]>;

  public required: boolean = false;

  public disabled: boolean = false;

  private roles!: RoleCollection;

  private _value: SelectOption | null | undefined;

  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    this.writeValue(value);
  }

  public get nodeName(): string {
    return 'ehp-user-roles-input';
  }

  constructor(
    private readonly queryBus: QueryBus,
    private readonly elementRef: ElementRef,
    private readonly nativeChanges: NativeChangeObserver,
  ) {
    this.options$ = from(this.queryBus.request<RoleCollection>(new GetAssignableRolesQuery())).pipe(
      tap(roles => (this.roles = roles)),
      map(roles => roles.all().map((role: Role) => ({ value: role.name, label: role.label }))),
      tap(() => this.redispatchValueFromSelect()),
    );

    this.nativeChanges.observe(this.elementRef, 'required').subscribe(change => {
      this.required = coerceBooleanProperty(change.currentValue);
    });

    this.selectHasChange = this.selectHasChange.bind(this);
    this.onTouched = this.onTouched.bind(this);
  }

  public ngAfterViewInit(): void {
    if (this.select) {
      this.select.value = this._value;
      this.select.registerOnChange(this.selectHasChange);
      this.select.registerOnTouched(this.onTouched);
    }
  }

  public onTouched = (): void => undefined;

  /**
   * @inheritDoc
   * @param fn
   */
  public registerOnChange(fn: any): void {
    this._onChange = fn;

    if (this.select) {
      this.select.registerOnChange(this.selectHasChange);
    }
  }

  /**
   * @inheritDoc
   * @param fn
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;

    if (this.select) {
      this.select.registerOnTouched(fn);
    }
  }

  /**
   * @inheritDoc
   * @param isDisabled
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: Role | Array<Role> | SelectOption[] | SelectOption | null | undefined): void {
    let opt: SelectOption | null = null;

    if (Array.isArray(value)) {
      value = value[0];
    }

    if (value !== null && value !== undefined) {
      if (!isSelectOption(value)) {
      }

      if (isRole(value)) {
        opt = { value: value.name, label: value.label };
      } else if (isSelectOption(value)) {
        opt = value;
      } else {
        throw new Error('Invalid option type');
      }
    }

    this._value = opt;

    if (this.select) {
      this.select.value = opt;
    }
  }

  private _onChange = (value: Role[] | null | undefined): void => undefined;

  private selectHasChange(value: SelectOption[] | SelectOption | null | undefined) {
    this._value = Array.isArray(value) ? value[0] : value;

    if (value === null || value === undefined) {
      this._onChange(value);
      return;
    }

    if (this.roles === undefined) {
      return;
    }

    if (Array.isArray(value)) {
      const roles = value.map(option => this.roles.find(role => option && role.name === option.value)).filter(r => !!r);
      this._onChange(roles as Role[]);
      return;
    }

    const role = this.roles.find(role => role.name === value.value);
    this._onChange(role ? [role] : []);
  }

  private redispatchValueFromSelect() {
    if (this.select) {
      this.selectHasChange(this.select.value);
    }
  }
}
