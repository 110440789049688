import { Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StoredCPOM } from '@application/bundles/cpom';

@Component({
  selector: 'ehp-cpom-list-item',
  templateUrl: './cpom-list-item.component.html',
  styleUrls: ['./cpom-list-item.component.scss'],
  host: {
    '[class.collapsable]': 'collapsable',
    '[attr.aria-expanded]': 'open.toString()',
  },
})
export class CpomListItemComponent implements OnChanges {
  @Input() public cpom!: StoredCPOM;

  public open: boolean = false;

  public collapsable: boolean = false;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['cpom']) {
      this.cpomHasChange();
    }
  }

  @HostListener('click')
  private clicked() {
    if (this.collapsable) {
      this.open = !this.open;
    }
  }

  private cpomHasChange() {
    if (!this.cpom) {
      return;
    }

    if (this.cpom.children && this.cpom.children.length > 1) {
      this.collapsable = true;
    }
  }
}
