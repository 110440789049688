import {Request, REQUEST_METHOD} from "@application/framework/http/request.interface";

export class DeleteRequest implements Request<REQUEST_METHOD.DELETE> {


  public readonly method = REQUEST_METHOD.DELETE;

  public url: URL;


  constructor(url: URL) {
    this.url = url;
  }
}
