import {AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {
  DashboardWidgetContentReference
} from "@easyhpad-ui/app/bundles/dashboard/interfaces/dashboard-widget-content.reference";
import {
  GridsterDashboardWidgetAdapterInterface
} from "@easyhpad-ui/app/bundles/dashboard/interfaces/gridster-dashboard-widget-adapter.interface";
import {GridsterComponent, GridsterConfig, GridsterItemComponentInterface} from "angular-gridster2";
import {DashboardConfiguration} from "@easyhpad-ui/app/bundles/dashboard/interfaces/dashboard-configuration.interface";

@Component({
  selector: 'ehp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit, OnChanges {

  @Input() widgets: DashboardWidgetContentReference[] = [];

  @Input('config') configuration: DashboardConfiguration | undefined;

  @ViewChild(GridsterComponent) public dashboard!: GridsterComponent;

  public items: GridsterDashboardWidgetAdapterInterface[] = [];

  public options: GridsterConfig;

  public minHeight = 0;


  constructor() {
    this.options = this.getGridsterOptions();
  }

  static itemChange(item: any, itemComponent: GridsterItemComponentInterface) {

    //itemComponent.gridster.options.fixedRowHeight = 400;

    //itemComponent.gridster.updateGrid();
    //itemComponent.gridster.setGridDimensions();

    //@ts-ignore
    //itemComponent.gridster.optionsChanged();
    
  }

  static itemResize(item: any, itemComponent: GridsterItemComponentInterface) {
  }

  public ngAfterViewInit(): void {
    this.widgetsHaveChange();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['widgets']) {
      this.widgetsHaveChange();
    }

    if (changes['configuration']) {
      this.options = this.getGridsterOptions();

      if (this.dashboard) {
        this.dashboard.optionsChanged();
      }
    }
  }

  private widgetsHaveChange(): void {

    if (this.widgets === undefined) {
      this.items = [];
      return;
    }

    this.items = this.widgets.map(reference => {

      const item: GridsterDashboardWidgetAdapterInterface = {
        item: {
          x: reference.position?.x || 0,
          y: reference.position?.y || 0,
          cols: reference.size.columns,
          rows: reference.size.rows || 1,
        },
        content: reference.widget,
      }
      return item;
    });
  }

  private getGridsterOptions(): GridsterConfig {
    return {
      gridType: 'verticalFixed',
      //compactType: "compactLeft&Up",
      minCols: this.configuration?.columns || 8,
      maxCols: this.configuration?.columns || 8,
      setGridSize: true,
      margin: 16,
      itemChangeCallback: DashboardComponent.itemChange,
      itemResizeCallback: DashboardComponent.itemResize,
      //fixedRowHeight: 400
    }
  }
}
