import {Query} from "@application/framework/command-query";
import {SerializedERRDSearchParams} from "@application/bundles/eprd-errd";
import {ERRD} from "@domain/eprd-errd";

export class ListERRDQuery implements Query<ERRD[]> {

  constructor(public readonly params?: Partial<SerializedERRDSearchParams>) {
  }

}
