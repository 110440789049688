import { DOCUMENT_TYPES } from '@domain/document';
import { TranslatableString } from '@application/framework/translation';

export function listDocumentsTypeLabels(): Map<DOCUMENT_TYPES, TranslatableString> {
  return new Map([
    [DOCUMENT_TYPES.CAPACITY_AUTHORIZATION, new TranslatableString('Autorisation de capacité')],
    [DOCUMENT_TYPES.CPOM, new TranslatableString('C.P.O.M')],
    [DOCUMENT_TYPES.GMP, new TranslatableString('G.M.P.')],
    [DOCUMENT_TYPES.PMP, new TranslatableString('P.M.P')],
    [DOCUMENT_TYPES.EHPAD_DEMAIN, new TranslatableString('Ehpad de demain')],
    [DOCUMENT_TYPES.HEALTH_FUNDING, new TranslatableString('Financement soin')],
    [DOCUMENT_TYPES.DEPENDENCY_FUNDING, new TranslatableString('Financement dépendance')],
    [DOCUMENT_TYPES.ACCOMMODATION_FUNDING, new TranslatableString('Financement hébergement')],
    [DOCUMENT_TYPES.EPRD, new TranslatableString('E.P.R.D.')],
    [DOCUMENT_TYPES.ERRD, new TranslatableString('E.R.R.D.')],
    [DOCUMENT_TYPES.ACTIVITY_ANNEX, new TranslatableString('Annexe activité')],
  ]);
}
