import {SettingsStore} from "@application/bundles/application/store/settings-store";
import {CurrentFacilitiesStore} from "@application/bundles/facility/store";
import {Subscription} from "@application/framework/reactive";
import {AsyncCollection} from "@application/framework/store/collection";
import {Facility} from "@domain/facility";
import {Subject} from "rxjs";
import {QueryBus} from "@application/framework/command-query";
import {ListAuthorizedFacilitiesQuery} from "@application/bundles/facility/query/list-authorized-facilities.query";
import {isValidFacilityIdType} from "@application/bundles/facility";
import {GetFacilityQuery} from "@application/bundles/facility/query/get-facility.query";
import {Logger, ProvideLogger} from "@application/framework/logger";

export class CurrentFacilitiesStoreImpl implements CurrentFacilitiesStore {

  private static INIT = false;
  private readonly settingKey = 'current_facilities';

  private readonly change$: Subject<Facility[]> = new Subject<Facility[]>();

  @ProvideLogger() private readonly logger!: Logger;

  public constructor(
    private readonly collection: AsyncCollection<Facility>,
    private readonly queryBus: QueryBus,
    private readonly settings: SettingsStore
  ) {
  }

  public available(): Promise<Facility[]> {
    return this.queryBus.request(new ListAuthorizedFacilitiesQuery());
  }

  public get(): Promise<Facility[]> {
    return this.collection.list();
  }

  public async add(facility: Facility): Promise<void> {
    await this.collection.set(facility.id, facility)
      .then(() => this.collection.list())
      .then((facilities) => {
        this.change$.next(facilities);
        this.storeSettings(facilities)
      });
  }

  public async set(...facilities: Facility[]): Promise<void> {
    await this.collection.clear();
    for (const facility of facilities) {
      await this.collection.set(facility.id, facility);
    }
    this.change$.next(facilities);
    await this.storeSettings(facilities);
  }

  public async clear(): Promise<void> {
    await this.collection.clear()
      .then(() => this.change$.next([]))
      .then(() => this.storeSettings([]))
  }

  public onChange(subscriber: (facilities: Facility[]) => any): Subscription {
    return this.change$.subscribe(subscriber);
  }

  public async hydrate() {

    if (CurrentFacilitiesStoreImpl.INIT) {
      return;
    }

    let ids = this.settings.get<Array<Facility['id']>>(this.settingKey);
    if (Array.isArray(ids)) {
      ids = ids.filter(id => isValidFacilityIdType(id));

      const facilities: Facility[] = [];

      for (const id of ids) {
        try {
          const facility = await this.queryBus.request<Facility>(new GetFacilityQuery(id));
          facilities.push(facility);
        } catch (e) {
          this.logger.error('Error on facility fetch', e);
        }
      }

      await this.collection.clear();

      for (const facility of facilities) {
        await this.collection.set(facility.id, facility);
      }

      this.change$.next(facilities);
    }

    CurrentFacilitiesStoreImpl.INIT = true;
  }

  private async storeSettings(facilities: Facility[]): Promise<void> {
    const ids = facilities.map(facility => facility.id);
    this.settings.set(this.settingKey, ids);
  }
}
