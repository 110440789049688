import { EPRD_ERRD_TRANSLATE_CONTEXT, ERRDDeletedEvent, ERRDDeletionFailEvent } from '@application/bundles/eprd-errd';
import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';

@HasEventSubscribers()
export class OnERRDDeletionEventSubscriber {
  constructor(private readonly noticeStream: NoticeStream) {}

  @OnEvent({
    event: ERRDDeletedEvent,
  })
  private async pushNoticeOnERRDDeletionSuccess(event: ERRDDeletedEvent): Promise<void> {
    const message = new TranslatableString(
      "L'<strong>E.R.R.D. {{year}}</strong> a été supprimé avec succès.",
      { year: event.errd.accountingYear },
      EPRD_ERRD_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: ERRDDeletionFailEvent,
  })
  private pushNoticeOnERRDDeletionFail(event: ERRDDeletionFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        "Une erreur est survenue lors de la suppression de l'E.R.R.D.",
        undefined,
        EPRD_ERRD_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }
}
