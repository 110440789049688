import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslatableString, TranslationReplacements, Translator} from "@application/framework/translation";

@Pipe({
  name: 'ehp_translate',
  pure: false,
})
export class EhpTranslatePipe implements PipeTransform {

  private translations: Map<string, string> = new Map();

  public constructor(private translator: Translator, private readonly ref: ChangeDetectorRef) {
  }

  public transform(value: string | TranslatableString | undefined, params?: TranslationReplacements, context?: string): string | undefined {

    if (value === undefined) {
      return undefined;
    }

    if (value instanceof TranslatableString) {
      return this.translateTranslatableString(value as TranslatableString);
    }

    return this.translate(value, params, context);

  }

  private translateTranslatableString(string: TranslatableString, params?: TranslationReplacements) {

    params = params || {};

    if (string.replacement) {
      params = {...string.replacement, ...params}
    }

    return this.translate(string.value, params, string.context);
  }

  private translate(value: string | undefined, params?: TranslationReplacements, context?: string) {
    if (value === undefined) {
      return undefined;
    }

    const key = context ? `${context}.${value}` : value;

    /**
     * Warning: dynamic values can be break
     */
    if (!this.translations.has(key)) {
      this.translator.translate(value, params, context).then(translated => {

        this.translations.set(key, translated);
        this.ref.markForCheck();
      });
    }


    return this.translations.get(key) as string;
  }
}
