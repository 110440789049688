import {Injectable} from "@angular/core";
import {PMPRepository} from "@application/bundles/pmp";
import {PMPAuthorizationChecker} from "@application/bundles/pmp/pmp-authorization-checker";
import {joinPath} from "@application/framework/lib";
import {PMP} from "@domain/pmp";

@Injectable()
export class PMPUrlsProvider {

  public static readonly base = 'pmp';

  public static readonly publicSchema = {
    list: {path: '', title: 'P.M.P.'},
    create: {path: 'create', title: 'Créer un nouveau P.M.P.'},
    show: {path: ':id', title: 'Créer un nouveau P.M.P.'},
    edit: {path: ':id/edit', title: 'Modifier le P.M.P.'},
  }

  constructor(
    public readonly authorization: PMPAuthorizationChecker,
    private readonly repository: PMPRepository
  ) {
  }

  public getPublicList(): Promise<string | undefined> {
    return Promise.resolve(this.getUnsafePublicList());
  }

  public getUnsafePublicList(): string {
    return joinPath('/', PMPUrlsProvider.base);
  }

  public getPublicCreate(): Promise<string | undefined> {
    return this.authorization.canCreate()
      .then(can => can ? this.getUnsafePublicCreate() : undefined);
  }

  public getUnsafePublicCreate(): string {
    return joinPath('/', PMPUrlsProvider.base, PMPUrlsProvider.publicSchema.create.path);
  }

  public getPublicShow(id: PMP['id']): Promise<string | undefined> {
    return this.authorization.canRead()
      .then(can => can ? this.getUnsafePublicShow(id) : undefined);
  }

  public getUnsafePublicShow(id: PMP['id']): string {
    return joinPath('/', PMPUrlsProvider.base, id.toString());
  }

  public async getPublicUpdate(id: PMP['id']): Promise<string | undefined> {
    const pmp = await this.repository.get(id);

    return this.authorization.canUpdate(pmp)
      .then(can => can ? this.getUnsafePublicUpdate(id) : undefined);
  }

  public getUnsafePublicUpdate(id: PMP['id']): string {
    return joinPath('/', PMPUrlsProvider.base, id.toString(), 'edit');
  }

}
