import { UpdateFileDepositItemCommand } from '@application/bundles/file-deposit/commands';
import { UpdateFileDepositItem } from '@application/bundles/file-deposit/contracts';
import { CommandHandler, HandleCommand } from '@application/framework/command-query';
import { joinPath } from '@application/framework/lib';
import { FileDeposit, FileDepositItem, isFileDepositId } from '@domain/file-deposit';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { FILE_DEPOSIT_URI } from '@implementations/bundles/backend/easyhpad/config/file-deposit';
import { parseBackendResponse } from '@implementations/bundles/backend/functions';

@HandleCommand({
  command: UpdateFileDepositItemCommand,
})
export class UpdateFileDepositItemCommandHandler implements CommandHandler<UpdateFileDepositItemCommand> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(command: UpdateFileDepositItemCommand): Promise<FileDepositItem> {
    const { id, update } = command;

    return this.backend
      .patch<{
        entity: FileDepositItem;
      }>(this.buildEndpoint(update.fileDepositId, id), this.buildUpdate(update))
      .then(response => parseBackendResponse(response))
      .then(body => body.entity);
  }

  private buildUpdate(update: UpdateFileDepositItem): Omit<UpdateFileDepositItem, 'fileDepositId'> {
    return {
      type: update.type,
      subType: update.subType,
      year: update.year,
    };
  }

  private buildEndpoint(id: FileDeposit['id'], itemId: FileDepositItem['id']): string {
    if (!isFileDepositId(id)) {
      throw new Error(`${id} is not a valid FileDeposit ID`);
    }
    return joinPath(FILE_DEPOSIT_URI, `${id}/items/${itemId}`);
  }
}
