import {
  AccommodationFundingDeletedEvent,
  AccommodationFundingDeletionFailEvent,
} from '@application/bundles/accommodation-funding';
import { ACCOMMODATION_FUNDING_TRANSLATE_CONTEXT } from '@application/bundles/accommodation-funding/accommodation-funding.token';
import { HasEventSubscribers, OnEvent } from '@application/framework/event';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';
import { QueryBus } from '@application/framework/command-query';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';

@HasEventSubscribers()
export class OnAccommodationFundingDeletionEventSubscriber {
  constructor(
    private readonly noticeStream: NoticeStream,
    private readonly queryBus: QueryBus,
  ) {}

  @OnEvent({
    event: AccommodationFundingDeletedEvent,
  })
  private async pushNoticeOnAccommodationFundingDeletionSuccess(
    event: AccommodationFundingDeletedEvent,
  ): Promise<void> {
    const facility = await this.queryBus.request(new GetFacilityQuery(event.funding.id));

    const message = new TranslatableString(
      "Le <strong>Financement hébergement {{ year }}</strong> pour l'établissement <strong>{{ name }}</strong> a été supprimé avec succès.",
      { year: event.funding.year, name: facility.name },
      ACCOMMODATION_FUNDING_TRANSLATE_CONTEXT,
    );

    this.noticeStream.push({ type: NoticeType.SUCCESS, message });
  }

  @OnEvent({
    event: AccommodationFundingDeletionFailEvent,
  })
  private pushNoticeOnAccommodationFundingDeletionFail(event: AccommodationFundingDeletionFailEvent): void {
    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        'Une erreur est survenue lors de la suppression du financement hébergement.',
        undefined,
        ACCOMMODATION_FUNDING_TRANSLATE_CONTEXT,
      );
    }

    this.noticeStream.push({ type: NoticeType.ERROR, message });
  }
}
