import {
  CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
  CapacityAuthorizationUpdatedEvent,
  CapacityAuthorizationUpdateFailEvent
} from "@application/bundles/capacity-authorization";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {TranslatableString} from "@application/framework/translation";

@HasEventSubscribers()
export class CapacityAuthorizationUpdateEventSubscriber {

  constructor(
    private readonly noticeStream: NoticeStream,
  ) {
  }

  @OnEvent({
    event: CapacityAuthorizationUpdatedEvent
  })
  public async pushNoticeOnCapacityAuthorizationUpdateSuccess(event: CapacityAuthorizationUpdatedEvent): Promise<void> {

    const facility = await event.authorization.facility();

    const message = new TranslatableString(
      "L'autorisation de capacité pour l'établissement <strong>{{name}}</strong> a été mise à jour avec succès.",
      {name: facility.name},
      CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT
    );

    this.noticeStream.push({message, type: NoticeType.SUCCESS});
  }

  @OnEvent({
    event: CapacityAuthorizationUpdateFailEvent
  })
  public async pushNoticeOnCapacityAuthorizationUpdateFail(event: CapacityAuthorizationUpdateFailEvent): Promise<void> {

    let message: string | TranslatableString = event.reason;

    if (!message) {
      message = new TranslatableString(
        "Une erreur est survenue lors de la mise à jour de l'autorisation de capacité.",
        undefined,
        CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT
      )
    }

    this.noticeStream.push({message, type: NoticeType.ERROR});
  }
}
