import {LocalCommonAbstractDto} from "@implementations/bundles/eprd-errd/dto/local-common.abstract.dto";
import {CreateLocalERRDDto} from "@application/bundles/eprd-errd";
import {isPercentage} from "@domain/lib/math/percentage/percentage";
import {isValidObject} from "@application/framework/lib";
import {isValidEstateDegradation, TPER} from "@domain/eprd-errd";
import {isPositiveFundingAmount} from "@domain/funding";
import {IsArray, IsNumber, IsOptional} from "class-validator";

export class CreateLocalERRDDtoImpl extends LocalCommonAbstractDto implements CreateLocalERRDDto {

  public estateDegradation: { comment: string; rate: number } | undefined;

  @IsNumber()
  @IsOptional()
  public independenceRate: number | undefined;

  @IsNumber()
  @IsOptional()
  public usedTreasury: number | undefined;

  @IsArray()
  public tper: TPER[] = [];

  constructor(defaults?: Partial<CreateLocalERRDDto>) {
    super(defaults);


    if (isValidObject(defaults)) {

      if (isPercentage(defaults.independenceRate)) {
        this.independenceRate = defaults.independenceRate;
      }

      if (isValidEstateDegradation(defaults.estateDegradation)) {
        this.estateDegradation = defaults.estateDegradation;
      }

      if (isPositiveFundingAmount(defaults.usedTreasury)) {
        this.usedTreasury = defaults.usedTreasury;
      }
      
      if (Array.isArray(defaults?.tper)) {
        this.tper = defaults?.tper;
      }
    }

  }
}
