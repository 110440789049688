import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SocialClearanceRepository } from '@domain/facility/social-clearance.repository';
import { SOCIAL_CLEARANCE } from '@domain/facility/types';

@Component({
  selector: 'ehp-facility-social-clearance-label',
  templateUrl: './facility-social-clearance-label.component.html',
  styleUrl: './facility-social-clearance-label.component.scss',
})
export class FacilitySocialClearanceLabelComponent implements OnChanges {
  @Input() public value: SOCIAL_CLEARANCE | undefined;

  public label: string | undefined;

  constructor(private readonly repository: SocialClearanceRepository) {}

  public ngOnChanges(changes: SimpleChanges) {
    if ('value' in changes) {
      this.updateLabel();
    }
  }

  private updateLabel() {
    if (!this.value) {
      this.label = undefined;
      return;
    }

    this.repository.get(this.value).then(clearance => (this.label = clearance.label));
  }
}
