import {Attribute, AuthorizationChecker} from "@application/bundles/authorization";
import {ForbiddenError} from "@application/bundles/authorization/error";
import {CreateNewCustomerCommand} from "@application/bundles/customer/commands/create-new-customer.command";
import {CUSTOMER_FEATURE, CUSTOMER_TRANSLATE_CONTEXT} from "@application/bundles/customer/customer.token";
import {CreateCustomerDto} from "@application/bundles/customer/dto/create-customer.dto";
import {CreateCustomerFailEvent} from "@application/bundles/customer/events/create-customer/create-customer-fail.event";
import {
  CreateCustomerSuccessEvent
} from "@application/bundles/customer/events/create-customer/create-customer-success.event";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {SanitizationFailError} from "@application/framework/sanitizer/errors";
import {SanitizerLibrary} from "@application/framework/sanitizer/sanitizer";
import {Translator} from "@application/framework/translation";
import {ValidationError} from "@application/framework/validator/errors/validation.error";
import {ObjectValidator} from "@application/framework/validator/object-validator";
import {Customer} from "@domain/customer";
import {CustomerRepository} from "@application/bundles/customer";

@HandleCommand({
  command: CreateNewCustomerCommand
})
export class CreateCustomerCommandHandler implements CommandHandler<CreateNewCustomerCommand, Customer> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private repository: CustomerRepository,
    private authorization: AuthorizationChecker,
    private validator: ObjectValidator,
    private sanitizers: SanitizerLibrary,
    private eventDispatcher: EventDispatcher,
    private translator: Translator,
  ) {
  }

  public async handle(command: CreateNewCustomerCommand): Promise<Customer> {
    const {dto} = command;
    try {
      await this.checkAccess(dto);

      const customer = await this.sanitize(dto)
        .then(dto => this.validate(dto))
        .then(dto => this.repository.create(dto));

      this.eventDispatcher.dispatch(new CreateCustomerSuccessEvent(customer));

      return customer;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: CreateCustomerDto): Promise<void> {
    const attributes: Attribute[] = [
      {feature: CUSTOMER_FEATURE, value: 'create'}
    ];

    if (!await this.authorization.isGranted(attributes, dto)) {
      this.logger.error('Customer creation : Forbidden');
      throw new ForbiddenError();
    }
  }

  private async sanitize(dto: CreateCustomerDto): Promise<CreateCustomerDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('Customer creation : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: CreateCustomerDto): Promise<CreateCustomerDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('Customer creation : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = await this.translator.translate('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = await this.translator.translate(
        "Vous n'êtes pas autorisé à créer un nouvel établissement.",
        undefined,
        CUSTOMER_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new CreateCustomerFailEvent(message));

    return error;
  }
}
