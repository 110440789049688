import { ForbiddenError } from '@application/bundles/authorization/error';
import {
  CreatePMPCommand,
  CreatePMPDto,
  PMPCreatedEvent,
  PMPCreationFailEvent,
  PMPRepository,
} from '@application/bundles/pmp';
import { PMPAuthorizationChecker } from '@application/bundles/pmp/pmp-authorization-checker';
import { PMP_TRANSLATE_CONTEXT } from '@application/bundles/pmp/pmp.token';
import { CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { Logger, ProvideLogger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import { SanitizationFailError } from '@application/framework/sanitizer/errors';
import { SanitizerLibrary } from '@application/framework/sanitizer/sanitizer';
import { TranslatableString } from '@application/framework/translation';
import { ValidationError } from '@application/framework/validator/errors/validation.error';
import { ObjectValidator } from '@application/framework/validator/object-validator';
import { MediaBucket, TemporaryMedia } from '@domain/media';
import { PMP } from '@domain/pmp';

@HandleCommand({
  command: CreatePMPCommand,
})
export class CreatePMPCommandHandler implements CommandHandler<CreatePMPCommand, PMP> {
  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private readonly repository: PMPRepository,
    private readonly authorization: PMPAuthorizationChecker,
    private readonly validator: ObjectValidator,
    private readonly sanitizers: SanitizerLibrary,
    private readonly eventDispatcher: EventDispatcher,
    private readonly mediaBucket: MediaBucket,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: CreatePMPCommand): Promise<PMP> {
    const { pmp } = command;
    try {
      const created = await this.sanitize(pmp)
        .then(dto => this.validate(dto))
        .then(dto => this.checkAccess(dto))
        .then(dto => this.storePvAndCreatePMP(dto));

      this.eventDispatcher.dispatch(new PMPCreatedEvent(created));

      return created;
    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }

  private async checkAccess(dto: CreatePMPDto): Promise<CreatePMPDto> {
    if (!(await this.authorization.canCreate())) {
      this.logger.error('P.M.P. creation : Forbidden');
      throw new ForbiddenError();
    }

    return dto;
  }

  private async sanitize(dto: CreatePMPDto): Promise<CreatePMPDto> {
    try {
      dto = await this.sanitizers.sanitize(dto);
    } catch (e: any) {
      this.logger.warning('P.M.P. creation : Sanitizer fail');
      throw e;
    }

    return dto;
  }

  private async validate(dto: CreatePMPDto): Promise<CreatePMPDto> {
    try {
      dto = await this.validator.validate(dto);
    } catch (e: any) {
      this.logger.warning('P.M.P. creation : Validator fail');
      throw e;
    }

    return dto;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ValidationError || error instanceof SanitizationFailError) {
      message = new TranslatableString('Une erreur est survenue lors de la vérification des données.');
    } else if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à créer un nouveau P.M.P..",
        undefined,
        PMP_TRANSLATE_CONTEXT,
      );
    }

    this.eventDispatcher.dispatch(new PMPCreationFailEvent(message));

    return error;
  }

  private async storePvAndCreatePMP(dto: CreatePMPDto): Promise<PMP> {
    let pv: TemporaryMedia | undefined;

    try {
      if (dto.pv) {
        pv = await this.mediaBucket.add(dto.pv);
        dto.pvId = pv.id;
      }

      return this.repository.create(dto);
    } catch (e) {
      if (pv !== undefined) {
        await pv.markForDeletion();
      }

      throw e;
    }
  }
}
