import { LoadFileDepositHistoryQuery } from '@application/bundles/file-deposit/queries/load-file-deposit-history.query';
import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { joinPath } from '@application/framework/lib';
import { FileDeposit, FileDepositTransition, isFileDepositId } from '@domain/file-deposit';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { FILE_DEPOSIT_URI } from '@implementations/bundles/backend/easyhpad/config/file-deposit';

@HandleQuery({
  query: LoadFileDepositHistoryQuery,
})
export class LoadFileDepositHistoryQueryHandler implements QueryHandler<LoadFileDepositHistoryQuery> {
  constructor(private readonly backend: EasyBackendApi) {}

  public async handle(query: LoadFileDepositHistoryQuery): Promise<FileDepositTransition[]> {
    return this.backend
      .get<{ entities: FileDepositTransition[] }>(this.buildEndpoint(query.id))
      .then(response => response.body)
      .then(body => body.entities);
  }

  private buildEndpoint(id: FileDeposit['id']): string {
    if (!isFileDepositId(id)) {
      throw new Error(`${id} is not a valid FileDeposit ID`);
    }
    return joinPath(FILE_DEPOSIT_URI, `${id}/transitions`);
  }
}
