import { DateString } from '@application/framework/date';
import {
  CreateEasyDaycareDependencyFundingDto,
  EasyDaycareDependencyFunding,
  UpdateEasyDaycareDependencyFundingDto,
} from './easy-daycare-dependency-funding';
import {
  CreateEasyPermanentDependencyFundingDto,
  EasyPermanentDependencyFunding,
  UpdateEasyPermanentDependencyFundingDto,
} from './easy-permanent-dependency-funding';
import {
  CreateEasyTemporaryDependencyFundingDto,
  EasyTemporaryDependencyFunding,
  UpdateEasyTemporaryDependencyFundingDto,
} from './easy-temporary-dependency-funding';
import { EasyMedia } from '@implementations/bundles/backend/easyhpad/interfaces/easy-media.interface';
import { EasyFacility } from '@implementations/bundles/backend/easyhpad';
import { UUID, Year } from '@domain/lib';

export interface EasyDependencyFunding {
  id: UUID;

  occupationRate?: number | null | undefined;

  date: DateString;

  dateStart: DateString;

  girValuation: number | null | undefined;

  amount: number | null | undefined;

  apa: number | null | undefined;

  outsideDepartment: number | null | undefined;

  moderatorTicket: number | null | undefined;

  accountingYear: Year;

  facilityId: EasyFacility['id'];

  arreteId: EasyMedia['id'];

  reportId: EasyMedia['id'] | null | undefined;

  daycare: EasyDaycareDependencyFunding | null | undefined;

  permanent: EasyPermanentDependencyFunding | null | undefined;

  temporary: EasyTemporaryDependencyFunding | null | undefined;
}

export interface CreateEasyDependencyFunding
  extends Omit<EasyDependencyFunding, 'id' | 'permanent' | 'temporary' | 'daycare'> {
  daycare: CreateEasyDaycareDependencyFundingDto | null | undefined;

  permanent: CreateEasyPermanentDependencyFundingDto | null | undefined;

  temporary: CreateEasyTemporaryDependencyFundingDto | null | undefined;
}

export interface UpdateEasyDependencyFunding
  extends Omit<EasyDependencyFunding, 'permanent' | 'temporary' | 'daycare'> {
  permanent: CreateEasyPermanentDependencyFundingDto | UpdateEasyPermanentDependencyFundingDto | null | undefined;

  temporary: CreateEasyTemporaryDependencyFundingDto | UpdateEasyTemporaryDependencyFundingDto | null | undefined;

  daycare: CreateEasyDaycareDependencyFundingDto | UpdateEasyDaycareDependencyFundingDto | null | undefined;
}
