import {createEntityAdapter} from '@ngrx/entity';
import {createDocumentActions, createDocumentReducer, createDocumentsSelectors, createInitialDocumentState, DocumentStoreState,} from '@easyhpad-ui/app/bundles/documents';
import {PMP} from '@domain/pmp';

/**
 * Key used to define the P.M.P sub-store.
 */
export const PMP_STORE_KEY = '@app/pmp';

/**
 * Store state
 */
export type PMPStoreState = DocumentStoreState<PMP>;

const pmpStoreEntityAdapter = createEntityAdapter<PMP>();

export const pmpActions = createDocumentActions<PMP>('P.M.P.');

const initialState: PMPStoreState = createInitialDocumentState(pmpStoreEntityAdapter);

export const pmpReducer = createDocumentReducer<PMP>(pmpStoreEntityAdapter, initialState, pmpActions);

export const pmpSelectors = createDocumentsSelectors(state => state[PMP_STORE_KEY], pmpStoreEntityAdapter);
