import { Provide } from '@application/framework/di';
import { Media, MEDIA_PERSISTANCE_STATE, MediaBucket, TemporaryMedia } from '@domain/media';
import { MEDIA_FILE_TYPES } from '@domain/media/types';

export class TemporaryMediaImpl implements TemporaryMedia {
  @Provide()
  private readonly mediaBucket!: MediaBucket;

  private readonly _media: Media;

  private _deleted = false;

  public get deleted(): boolean {
    return this._deleted;
  }

  public get persistance(): MEDIA_PERSISTANCE_STATE.TEMPORARY {
    return MEDIA_PERSISTANCE_STATE.TEMPORARY;
  }

  public get id() {
    return this._media.id;
  }

  public get name(): string {
    return this._media.name;
  }

  public get path(): string {
    return this._media.path;
  }

  public get type(): MEDIA_FILE_TYPES {
    return this._media.type;
  }

  public get mimeType(): string {
    return this._media.mimeType;
  }

  constructor(media: Media) {
    this._media = media;
  }

  public buildUrl(): Promise<URL> {
    return this._media.buildUrl();
  }

  public media(): Media | undefined {
    return !this.deleted ? this._media : undefined;
  }

  public async markForDeletion(): Promise<void> {
    await this.mediaBucket.delete(this._media.id);
    this._deleted = true;
  }

  public async markForPersist(): Promise<void> {
    if (this.deleted) {
      throw new Error(`Media (${this.id}) can't be persisted, media is deleted`);
    }

    return undefined;
  }
}
