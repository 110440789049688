<div class="authentification-container">
  <ehp-logo></ehp-logo>

  <h1 class="title page-title">{{'Réinitialisation de mot de passe' | ehp_translate }}</h1>

  <ehp-forgotten-password-form
    (requestLoginMove)="moveToLogin()"
    [loginEnable]="true"
    class="auth-form">

  </ehp-forgotten-password-form>
</div>

<div class="image-container">
  <ehp-access-image></ehp-access-image>
</div>
