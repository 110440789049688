import {DeleteUsersCommand} from "@application/bundles/user/commands/delete-users.command";
import {UsersDeletionSuccessEvent} from "@application/bundles/user/events/users-deletion-success.event";
import {UsersDeletionFailEvent} from "@application/bundles/user/events/users-deletion.fail.event";
import {HandleCommand} from "@application/framework/command-query";
import {CommandHandler} from "@application/framework/command-query/handler.interface";
import {EventDispatcher} from "@application/framework/event";
import {User, UserRepository} from "@domain/user";

@HandleCommand({
  command: DeleteUsersCommand
})
export class DeleteUsersCommandHandler implements CommandHandler<DeleteUsersCommand, User[]> {


  constructor(
    private readonly repository: UserRepository,
    private readonly eventDispatcher: EventDispatcher
  ) {
  }

  public async handle(command: DeleteUsersCommand): Promise<User[]> {

    const {users} = command;

    try {
      await Promise.all(users.map(user => this.repository.delete(user.id)));
      this.eventDispatcher.dispatch(new UsersDeletionSuccessEvent(users));
      return users;

    } catch (e: any) {
      this.eventDispatcher.dispatch(new UsersDeletionFailEvent(e.toString()));
    }

    return await Promise.reject();
  }


}
