import { RecurringTaxonomyTerm } from '@domain/health-funding';
import { UUID } from '@domain/lib';

export interface EasyRecurringHealthFunding {
  id: UUID;

  categoryId: RecurringTaxonomyTerm['id'] | null | undefined;

  name: string | null | undefined;

  amount: number;
}

export interface CreateEasyRecurringHealthFundingDto extends Omit<EasyRecurringHealthFunding, 'id'> {}

export interface UpdateEasyRecurringHealthFundingDto extends EasyRecurringHealthFunding {}
