import { CreateUser, UpdateUser, User, UserRepository } from '@domain/user';
import { EasyCreateUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-create-user.transformer';
import { EasyUpdateUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-update-user.transformer';
import { EasyUserTransformer } from '@implementations/bundles/backend/easyhpad/transformers/user/easy-user.transformer';
import { Provide } from '@application/framework/di';
import { EasyUser } from '@implementations/bundles/backend/easyhpad';
import { EasyEntityRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { UserSearchParams } from '@application/bundles/user/user-search.params';
import { RequestParams } from '@application/framework/http/request.interface';

export class EasyUserRepository
  extends EasyEntityRepository<User, EasyUser, User['id'], UserSearchParams, CreateUser, UpdateUser>
  implements UserRepository
{
  @Provide() private readonly entityTransformer!: EasyUserTransformer;
  @Provide() private readonly createTransformer!: EasyCreateUserTransformer;
  @Provide() private readonly updateTransformer!: EasyUpdateUserTransformer;

  constructor() {
    super('user', {
      from: input => this.entityTransformer.transform(input),
      to: output => this.entityTransformer.reverseTransform(output),
      create: (input: CreateUser) => this.createTransformer.transform(input),
      update: (input: UpdateUser) => this.updateTransformer.transform(input),
    });
  }

  protected override convertParams(params?: UserSearchParams): RequestParams {
    if (params === undefined) {
      return {};
    }

    return this.searchTransformer.toRequestParams(
      this.searchTransformer.fromSerializedUserSearchParams(params.serialize()),
    );
  }
}
