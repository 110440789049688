import {AddCapacityAuthorizationDto} from "@application/bundles/capacity-authorization/dtos";
import {
  UpdateCapacityAuthorizationDto
} from "@application/bundles/capacity-authorization/dtos/update-capacity-authorization.dto";

export abstract class CapacityAuthorizationDtoFactory {

  public abstract createAddCapacityAuthorizationDtoFromUnSafeValues(values: Partial<AddCapacityAuthorizationDto>): AddCapacityAuthorizationDto;

  public abstract createUpdateCapacityAuthorizationDtoFromUnSafeValues(values: Partial<UpdateCapacityAuthorizationDto>): UpdateCapacityAuthorizationDto;
}
