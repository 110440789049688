import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { ListLibraryQuery } from '@application/bundles/document/queries';
import { EasyBackendApi } from '@implementations/bundles/backend/easyhpad/api';
import { RequestParams } from '@application/framework/http';
import { DocumentMediaList } from '@application/bundles/document/contracts';
import { parseBackendResponse } from '@implementations/bundles/backend/functions';

@HandleQuery({
  query: ListLibraryQuery,
})
export class ListLibraryQueryHandler implements QueryHandler<ListLibraryQuery> {
  private readonly path = 'media';

  constructor(private readonly backend: EasyBackendApi) {}

  public handle(query: ListLibraryQuery): Promise<any> {
    return this.backend
      .get<{ entity: DocumentMediaList }>(this.path, this.convertParams(query.search))
      .then(response => parseBackendResponse(response))
      .then(body => body.entity);
  }

  private convertParams(search?: ListLibraryQuery['search']): RequestParams {
    const params: RequestParams = {};

    if (search) {
      if (search.customer) {
        params['customer'] = search.customer;
      }

      if (search.facilities && search.facilities.length > 0) {
        params['facilities'] = search.facilities.join(',');
      }

      if (search.scopes && search.scopes.length > 0) {
        params['scopes'] = search.scopes.join(',');
      }
    }

    return params;
  }
}
