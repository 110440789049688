import { NgModule } from '@angular/core';
import { DepartmentGMPIndicatorRepository, GmpBundle, GMPDtoFactory, GMPRepository } from '@application/bundles/gmp';
import { Bundle } from '@application/framework/bundle';
import { extractDependencies } from '@application/framework/di';
import { OpenGMPDeletionDialogCommandHandler } from '@easyhpad-ui/app/bundles/gmp/commands/handlers/open-gmp-deletion-dialog/open-gmp-deletion-dialog.command.handler';
import { GmpDashboardModule } from '@easyhpad-ui/app/bundles/gmp/modules/dashboard/gmp-dashboard.module';
import { GmpUrlsProvider } from '@easyhpad-ui/app/bundles/gmp/providers/url-provider/gmp-urls.provider';
import { ValidatorModule } from '@easyhpad-ui/app/framework/validator/validator.module';
import { EasyGMPRepository } from '@implementations/bundles/backend/easyhpad';
import { EasyDepartmentGMPIndicatorRepository } from '@implementations/bundles/backend/easyhpad/repositories/department-gmp-indicator/easy-department-gmp-indicator.repository';
import { CreateGmpDtoImpl, GMPDtoFactoryImpl } from '@implementations/bundles/gmp';
import { UpdateGMPDtoImpl } from '@implementations/bundles/gmp/dto/update-gmp.dto';
import { createGMPDtoValidator } from '@implementations/bundles/gmp/validators/create-gmp-dto-impl.validator';
import { updateGMPDtoValidator } from '@implementations/bundles/gmp/validators/update-gmp-dto-impl.validator';

@Bundle({
  imports: [GmpBundle],
  commandHandlers: [OpenGMPDeletionDialogCommandHandler],
})
@NgModule({
  imports: [
    GmpDashboardModule,
    ValidatorModule.forChild({
      validators: [
        { objectType: CreateGmpDtoImpl, validator: createGMPDtoValidator },
        { objectType: UpdateGMPDtoImpl, validator: updateGMPDtoValidator },
      ],
    }),
  ],
  providers: [
    {
      provide: GmpUrlsProvider,
    },
    {
      provide: GMPRepository,
      useClass: EasyGMPRepository,
      deps: extractDependencies(EasyGMPRepository),
    },
    {
      provide: GMPDtoFactory,
      useClass: GMPDtoFactoryImpl,
    },
    {
      provide: DepartmentGMPIndicatorRepository,
      useClass: EasyDepartmentGMPIndicatorRepository,
      deps: extractDependencies(EasyDepartmentGMPIndicatorRepository),
    },
  ],
})
export class GMPModule {}
