import {GetStudyLevelValuesQuery} from "@application/bundles/user/queries/get-study-level-values.query";
import {HandleQuery} from "@application/framework/command-query";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {Provide} from "@application/framework/di";
import {StudyLevel} from "@domain/user/study-level.interface";
import {StudyLevelRepository} from "@domain/user/study-level.repository";

@HandleQuery({
  query: GetStudyLevelValuesQuery
})
export class GetStudyLevelValuesQueryHandler implements QueryHandler<GetStudyLevelValuesQuery, StudyLevel[]> {

  @Provide()
  private readonly repository!: StudyLevelRepository;

  public handle(query: GetStudyLevelValuesQuery): Promise<StudyLevel[]> {
    return this.repository.list();
  }

}
