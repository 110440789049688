import { DynamicHTMLGroup, WithActivation } from '@easyhpad-ui/app/library/form/contracts';
import { isObservable, map, merge, Observable } from 'rxjs';
import { entries, keys, ObjectKey, values } from '@domain/lib';

export function createHtmlGroupActivationObservable(group: DynamicHTMLGroup<any>): Observable<boolean> | undefined {
  const obs: Map<ObjectKey, Observable<boolean>> = new Map();

  entries(group.fields).forEach(([key, field]) => {
    const activation = (field as WithActivation).activation;

    if (isObservable(activation)) {
      obs.set(key, activation);
    }
  });

  // One of the subfields doesn't have an activation
  if (obs.size === 0 || obs.size !== keys(group.fields).length) {
    return;
  }

  let states: Record<ObjectKey, boolean> = {};

  // Define default state.
  Array.from(obs.keys()).forEach(key => {
    states = { ...states, [key]: true };
  });

  // Get all observers
  const observers = Array.from(obs.entries()).map(([key, observer]) => {
    return observer.pipe(
      map(isActivate => {
        states[key] = isActivate;
        return states;
      }),
    );
  });

  return merge(...observers).pipe(map(s => values(s).some(state => state)));
}
