import {UsersDeletionSuccessEvent} from "@application/bundles/user/events/users-deletion-success.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {Notice, NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";
import {User} from "@domain/user";

@HasEventSubscribers()
export class OnUserDeletionSubscriber {


  constructor(private readonly translator: Translator, private readonly noticeStream: NoticeStream) {

  }

  @OnEvent({
    event: UsersDeletionSuccessEvent
  })
  private async pushNoticeOnMultipleUserDeletion(event: UsersDeletionSuccessEvent) {
    const users = event.users;

    const notice = users.length === 1 ? await this.getSingleUserDeletionNotice(users[0]) : await this.getMultipleUserDeletionNotice(users);
    this.noticeStream.push(notice);

  }

  private async getSingleUserDeletionNotice(user: User): Promise<Notice> {
    const message = await this.translator.translate(`L'utilisateur <strong>${user.displayName}</strong> a été supprimé avec succès`);
    return {type: NoticeType.SUCCESS, message};
  }

  private async getMultipleUserDeletionNotice(users: User[]) {
    const message = await this.translator.translate(`<strong>{{count}}</strong> utilisateurs supprimés avec succès`, {count: users.length});
    return {type: NoticeType.SUCCESS, message};
  }
}
