import { Command } from '@application/framework/command-query';
import { TaxonomyRepository } from '@application/bundles/taxonomy';
import { TaxonomyTerm } from '@domain/taxonomy';

export type CreateTaxonomyCommandResult = TaxonomyTerm;

export class CreateTaxonomyCommand implements Command {
  constructor(
    public readonly repository: TaxonomyRepository,
    public readonly term: Omit<TaxonomyTerm, 'id'>,
  ) {}
}
