import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, from, map, mergeMap, Observable } from 'rxjs';
import { TaxonomyTerm } from '@domain/taxonomy';
import { healthFundingActions } from '@easyhpad-ui/app/bundles/health-funding/store/health-funding.actions';
import { CommandBus, QueryBus } from '@application/framework/command-query';
import { HealthTaxonomyType } from '@domain/health-funding';
import {
  GetHealthTaxonomyTermQuery,
  GetHealthTaxonomyTermResult,
  HealthTaxonomyRepositoryProvider,
  ListHealthTaxonomyTermsQuery,
  ListHealthTaxonomyTermsQueryResult,
} from '@application/bundles/health-funding';
import { Injectable } from '@angular/core';
import { CreateTaxonomyCommand, CreateTaxonomyCommandResult } from '@application/bundles/taxonomy';
import { NoticeStream, NoticeType } from '@application/framework/notice';
import { TranslatableString } from '@application/framework/translation';

@Injectable()
export class HealthFundingEffects {
  /**
   * Load all terms for Health taxonomy
   */
  public loadTaxonomyTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthFundingActions.loadAllTerms),
      mergeMap(action => this.loadTerms(action.taxonomy).pipe(map(terms => ({ taxonomy: action.taxonomy, terms })))),
      map(payload => healthFundingActions.allTermsLoaded(payload)),
    ),
  );

  /**
   * Load a single term for a Health taxonomy
   */
  public loadSingleTaxonomyTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthFundingActions.loadTerm),
      mergeMap(action =>
        this.loadTerm(action.taxonomy, action.id).pipe(map(term => ({ taxonomy: action.taxonomy, term }))),
      ),
      map(payload => healthFundingActions.termLoaded(payload)),
    ),
  );

  public createTaxonomyTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthFundingActions.createTerm),
      mergeMap(action =>
        this.createTerm(action.taxonomy, action.payload).pipe(map(term => ({ taxonomy: action.taxonomy, term }))),
      ),
      filter(payload => !!payload.term),
      map(payload => healthFundingActions.termCreated(payload as any)),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly queryBus: QueryBus,
    private readonly commandBus: CommandBus,
    private readonly noticeStream: NoticeStream,
    private readonly provider: HealthTaxonomyRepositoryProvider,
  ) {}

  /**
   * Load multiple taxonomy terms.
   *
   * @protected
   * @param taxonomy
   */
  protected loadTerms(taxonomy: HealthTaxonomyType): Observable<TaxonomyTerm[]> {
    return from(this.queryBus.request<ListHealthTaxonomyTermsQueryResult>(new ListHealthTaxonomyTermsQuery(taxonomy)));
  }

  /**
   * Load single taxonomy term.
   *
   * @param taxonomy
   * @param id
   * @protected
   */
  protected loadTerm(taxonomy: HealthTaxonomyType, id: TaxonomyTerm['id']): Observable<TaxonomyTerm> {
    return from(this.queryBus.request<GetHealthTaxonomyTermResult>(new GetHealthTaxonomyTermQuery(taxonomy, id))).pipe(
      filter(term => !!term),
    ) as Observable<TaxonomyTerm>;
  }

  protected createTerm(
    taxonomy: HealthTaxonomyType,
    payload: Omit<TaxonomyTerm, 'id'>,
  ): Observable<TaxonomyTerm | null> {
    const repository = this.provider.provide(taxonomy);

    const execution = this.commandBus
      .execute<CreateTaxonomyCommandResult>(new CreateTaxonomyCommand(repository, payload))
      .then(term => {
        this.sendCreationSuccessNotice(term);
        return term;
      })
      .catch(() => {
        this.sendCreationErrorNotice(payload);
        return null;
      });

    return from(execution);
  }

  private sendCreationErrorNotice(term: Pick<TaxonomyTerm, 'name'>) {
    this.noticeStream.push({
      type: NoticeType.ERROR,
      message: new TranslatableString(`Une erreur est survenue lors de la création de la catégorie {{name}}.`, {
        name: term.name,
      }),
    });
  }

  private sendCreationSuccessNotice(term: TaxonomyTerm) {
    this.noticeStream.push({
      type: NoticeType.SUCCESS,
      dismiss: 2000,
      message: new TranslatableString(`La catégorie {{name}} vient d'être crée avec succès.`, { name: term.name }),
    });
  }
}
