import {DispatchableEvent, EventSubscriberMetadata} from "@application/framework/event";
import {getEmptySubscriberMetadata} from "@application/framework/event/functions/get-empty-subscriber-metadata";
import {registerMethodAsEventSubscriber} from "@application/framework/event/functions/on-event-method-decoration";
import "reflect-metadata";

export function OnEvent(options: { event: DispatchableEvent } & EventSubscriberMetadata): Function;
export function OnEvent(options: { event: DispatchableEvent[] } & EventSubscriberMetadata): Function;
export function OnEvent<T extends any>(options: { event: DispatchableEvent | DispatchableEvent[] } & EventSubscriberMetadata): MethodDecorator {

  return (target: Object, propertyKey: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor | void => {
    const method = descriptor.value;

    if (typeof method !== 'function') {
      console.warn(`OnEvent Decorator cannot be apply to ${propertyKey.toString()}. ${propertyKey.toString()} must be a function, ${typeof method} provided`);
      return descriptor;
    }


    if (!Array.isArray(options.event)) {
      options.event = [options.event];
    }

    const metadata = getEmptySubscriberMetadata();
    Object.keys(options).filter(k => k !== 'event').forEach(k => {
      // @ts-ignore
      metadata[k] = options[k];
    });

    registerMethodAsEventSubscriber(target.constructor, propertyKey, options.event, metadata);

    return descriptor;

  };

}
