import {
  DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  DAYCARE_DEPENDENCIES_FUNDING_MODES,
  DaycareDependencyFundingMode,
  DaycareDependencyFundingModeValues
} from "@domain/dependency-funding/daycare-dependency-funding.types";
import {
  DaycareDependencyFunding,
  DayCareDependencyOtherFunding,
  DayCareDependencySingleGIRAmountFunding,
  DependencyFundingItem,
  isDependencyFundingMode
} from "@domain/dependency-funding";
import {isValidObject} from "@application/framework/lib";

export function isDaycareDependencyFundingMode(mode: any): mode is DaycareDependencyFundingMode {
  return isValidObject(mode)
    && isDependencyFundingMode<DAYCARE_DEPENDENCIES_FUNDING_MODES>(mode)
    && DaycareDependencyFundingModeValues.has(mode.key);
}

export function isDaycareDependencyFundingModeValue(value: any): value is DAYCARE_DEPENDENCIES_FUNDING_MODES {
  return DaycareDependencyFundingModeValues.has(value);
}

export function hasDaycareFundingMode<M extends DAYCARE_DEPENDENCIES_FUNDING_MODES = any, V = unknown>(funding: DependencyFundingItem<any, unknown>): funding is DependencyFundingItem<DaycareDependencyFundingMode<M>, V> {
  return isValidObject(funding)
    && isDaycareDependencyFundingMode(funding.mode)
    && isDaycareDependencyFundingModeValue(funding.mode.key);
}


export function useDaycareFundingSingleGIRAmountMode(funding: DaycareDependencyFunding<any, unknown>): funding is DayCareDependencySingleGIRAmountFunding {
  return isValidObject(funding)
    && hasDaycareFundingMode(funding)
    && funding.mode.key === DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.SINGLE_GIR_AMOUNT;
}

export function useDaycareFundingOtherMode(funding: DaycareDependencyFunding<any, unknown>): funding is DayCareDependencyOtherFunding {
  return isValidObject(funding)
    && hasDaycareFundingMode(funding)
    && funding.mode.key === DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.OTHER;
}
