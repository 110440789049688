import { StoredCPOM } from '@application/bundles/cpom';
import { CPOM } from '@domain/cpom';
import { isUUID } from '@domain/lib';

export function isValidCPOMIdType(id: any): id is StoredCPOM['id'] {
  return isUUID(id);
}

export function isMaybeAStoredCPOM(cpom: any): cpom is { id: StoredCPOM['id'] } {
  return 'id' in cpom && isValidCPOMIdType((cpom as StoredCPOM).id);
}

export function isStoredCPOM(cpom: Partial<CPOM> | Partial<StoredCPOM>): cpom is StoredCPOM {
  return 'id' in cpom && isValidCPOMIdType((cpom as StoredCPOM).id);
}
