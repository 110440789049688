import {UserCreationFailEvent} from "@application/bundles/user/events/user-creation-fail.event";
import {UserCreationSuccessEvent} from "@application/bundles/user/events/user-creation-success.event";
import {HasEventSubscribers, OnEvent} from "@application/framework/event";
import {NoticeStream, NoticeType} from "@application/framework/notice";
import {Translator} from "@application/framework/translation";

@HasEventSubscribers()
export class OnUserCreationHandler {


  constructor(private readonly translator: Translator, private readonly noticeStream: NoticeStream) {
  }


  @OnEvent({
    event: UserCreationSuccessEvent,
  })
  public async pushCreationSuccessNotice(event: UserCreationSuccessEvent) {
    const message = await this.translator.translate(
      "L'utilisateur <strong>{{name}}</strong> a été créé avec succès.",
      {name: event.user.displayName}
    );

    this.noticeStream.push({type: NoticeType.SUCCESS, message});
  }

  
  @OnEvent({
    event: UserCreationFailEvent,
  })
  public async pushCreationFailNotice(event: UserCreationFailEvent) {
    let message = event.reason;

    if (!message) {
      message = await this.translator.translate('Un erreur est survenue lors de la création du nouvel utilisateur');
    }

    this.noticeStream.push({message, type: NoticeType.ERROR});
  }


}
