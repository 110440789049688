import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Facility } from '@domain/facility';
import { isValidYear, Year } from '@domain/lib';
import { HealthFundingListFilters } from '@easyhpad-ui/app/bundles/health-funding/interfaces';
import { debounce, ObservableInput, Subject, timer } from 'rxjs';

@Component({
  selector: 'ehp-pmp-list-filters',
  templateUrl: './pmp-list-filters.component.html',
  styleUrls: ['./pmp-list-filters.component.scss'],
  host: {
    class: 'filters-host filters',
  },
})
export class PmpListFiltersComponent implements OnDestroy {
  @Input() public debounce: number | undefined;

  @Output() public onChange: EventEmitter<HealthFundingListFilters> = new EventEmitter();

  private value: HealthFundingListFilters = {};

  private filterDispatcher: Subject<HealthFundingListFilters> = new Subject();

  private destroy$: Subject<void> = new Subject();

  constructor() {
    this.durationSelector = this.durationSelector.bind(this);
    this.filterDispatcher.pipe(debounce(this.durationSelector)).subscribe(filters => this.onChange.emit(filters));
  }

  public ngOnDestroy(): void {
    this.filterDispatcher.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setYear(year?: Year | null) {
    year = this.formatYear(year);

    if (year !== this.value.year) {
      this.value = { ...this.value, year };
      this.dispatch();
    }
  }

  public setFacilities(facilities: Facility[]): void {
    this.value = { ...this.value, facilities };
    this.dispatch();
  }

  private durationSelector(): ObservableInput<any> {
    return timer(this.debounce || 0);
  }

  private formatYear(year?: Year | null): Year | undefined {
    if (year === undefined || year === null) {
      return undefined;
    }

    return isValidYear(year) ? year : undefined;
  }

  private dispatch(): void {
    this.filterDispatcher.next(this.value);
  }
}
