import {Common} from "@application/framework/lib";
import {CreateEhpadDemainDto, UpdateEhpadDemainDto} from "@application/bundles/ehpad-demain";
import {EHPAD_DEMAIN_TYPE, isValidEhpadDemainTypeValue} from "@domain/ehpad-demain";
import {Facility} from "@domain/facility";
import {ArrayMinSize, IsArray, IsDate, IsOptional, IsString} from "class-validator";
import {
  IsEhpadDemainType
} from "@implementations/bundles/ehpad-demain/validators/class-validator/is-ehpad-demain-type.validator";
import {Media} from "@domain/media";
import {IsPositiveFundingAmount} from "@implementations/bundles/funding/validators";
import {isPositiveFundingAmount} from "@domain/funding";

type EhpadDemainCommonDto = Omit<Common<CreateEhpadDemainDto, UpdateEhpadDemainDto>, 'medias'>;

export abstract class EhpadDemainCommonDtoAbstract implements EhpadDemainCommonDto {

  @IsString()
  public name!: string;


  @IsPositiveFundingAmount()
  public amount!: number;

  @IsString()
  @IsOptional()
  public comment: string | undefined;

  @IsDate()
  public start!: Date;

  @IsDate()
  public end!: Date;

  @IsEhpadDemainType()
  public typeId!: EHPAD_DEMAIN_TYPE;

  @IsArray()
  @ArrayMinSize(1)
  public facilitiesIds: Array<Facility["id"]> = [];

  @IsOptional()
  @IsArray()
  public mediaIds?: Array<Media['id']> = [];

  constructor(values?: Partial<EhpadDemainCommonDto>) {

    if (values) {

      if (values.name) {
        this.name = values.name;
      }

      if (isPositiveFundingAmount(values.amount)) {
        this.amount = values.amount;
      }

      if (values.comment) {
        this.comment = values.comment;
      }
      if (values.start) {
        this.start = values.start;
      }

      if (values.end) {
        this.end = values.end;
      }

      if (isValidEhpadDemainTypeValue(values.typeId)) {
        this.typeId = values.typeId;
      }

      if (Array.isArray(values.facilitiesIds)) {
        this.facilitiesIds = values.facilitiesIds;
      }

      if (Array.isArray(values.mediaIds)) {
        this.mediaIds = values.mediaIds;
      }
    }
  }
}
