import { AuthentificationToken } from '@application/bundles/authentification';
import { Attribute, CRUD_ACTIONS } from '@application/bundles/authorization';
import { AbstractVoter } from '@application/bundles/authorization/voter';
import { FACILITY_FEATURE } from '@application/bundles/facility';
import { AVAILABLE_ROLES } from '@domain/authorization';
import { Facility, FACILITY_STATE } from '@domain/facility';

export class FacilityVoter extends AbstractVoter {
  constructor() {
    super();
  }

  protected supports(attribute: Attribute): boolean {
    return attribute.feature === FACILITY_FEATURE;
  }

  protected async voteOnAttribute(
    attribute: Attribute,
    subject: Facility | null | undefined,
    token: AuthentificationToken,
  ): Promise<boolean> {
    const permissions = token.permissions;

    let hasPermission: boolean;

    if (permissions.has(attribute.feature, CRUD_ACTIONS.MANAGE)) {
      hasPermission = true;
    } else {
      hasPermission = permissions.has(attribute.feature, attribute.value);
    }

    if (
      subject &&
      subject.state !== FACILITY_STATE.ACTIVE &&
      (attribute.value === CRUD_ACTIONS.UPDATE || attribute.value === CRUD_ACTIONS.DELETE)
    ) {
      hasPermission = token.roles.contain({ name: AVAILABLE_ROLES.ADMIN });
    }

    return hasPermission;
  }
}
