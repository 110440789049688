import { CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT } from '@application/bundles/capacity-authorization';
import { DateFormatter } from '@application/framework/date';
import { TranslatableString } from '@application/framework/translation';
import { CapacityAuthorization, InspectionReport } from '@domain/capacity-authorization';
import { Media, MediaBucket } from '@domain/media';
import { UUID } from '@domain/lib';

export class InspectionReportImpl implements InspectionReport {
  public id: UUID;

  public date: Date;

  public mediaIds: Array<Media['id']>;

  public get name(): TranslatableString {
    let date = '';

    try {
      date = this.dateFormatter.format(this.date, 'd MMMM y');
    } catch (e) {
      console.error(e);
    }

    return new TranslatableString(
      `Rapport d'inspection du {{date}}`,
      { date },
      CAPACITY_AUTHORIZATION_TRANSLATE_CONTEXT,
    );
  }

  constructor(
    value: Omit<InspectionReport, 'name' | 'medias' | 'authorization'>,
    authorization: () => Promise<CapacityAuthorization>,
    private readonly bucket: MediaBucket,
    private readonly dateFormatter: DateFormatter,
  ) {
    this.id = value.id;
    this.date = value.date;
    this.mediaIds = value.mediaIds;
    this.authorization = authorization;
  }

  public async medias(): Promise<Media[]> {
    if (!this.mediaIds || this.mediaIds.length === 0) {
      return [];
    }

    return await Promise.all(this.mediaIds.map(id => this.bucket.get(id)));
  }

  public authorization(): Promise<CapacityAuthorization> {
    return Promise.reject();
  }
}
