export enum FACILITY_TYPE {
  PUBLIC = "public",
  PRIVATE = "private",
  NON_LUCRATIVE = "non_lucrative",
}

// SocialClearance

export enum SOCIAL_CLEARANCE {
  NONE = "none",
  PARTIAL_LOW = "partial_low",
  PARTIAL_HIGH = "partial_high",
  TOTAL = "total",
}


export interface FacilityType {
  type: FACILITY_TYPE,
  label: string
}

export interface SocialClearance {
  type: SOCIAL_CLEARANCE;
  label: string
}


