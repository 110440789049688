import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { ListAuthorizedFacilitiesQuery } from '@application/bundles/facility/query/list-authorized-facilities.query';
import { FacilityRepository } from '@domain/facility/facility.repository';
import { Facility } from '@domain/facility';
import { AgnosticFacilitySearchParams } from '@application/bundles/facility/implementations/agnostic-facility-search-params';
import { CurrentCustomerAccessor } from '@application/bundles/customer/implementation/current-customer-accessor/current-customer.accessor';
import { isValidCustomerIdType } from '@application/bundles/customer';
import { AuthentificationTokenStore } from '@application/bundles/authentification';

@HandleQuery({
  query: ListAuthorizedFacilitiesQuery,
})
export class ListAuthorizedFacilitiesQueryHandler implements QueryHandler<ListAuthorizedFacilitiesQuery, Facility[]> {
  constructor(
    private readonly repository: FacilityRepository,
    private readonly currentCustomer: CurrentCustomerAccessor,
    private readonly authentificationTokenStore: AuthentificationTokenStore,
  ) {}

  public async handle(query: ListAuthorizedFacilitiesQuery): Promise<Facility[]> {
    const params = new AgnosticFacilitySearchParams();

    if (!isValidCustomerIdType(query.customerId)) {
      const id = this.authentificationTokenStore.getToken().customerReference;
      const current = this.currentCustomer.get();

      if (isValidCustomerIdType(id)) {
        params.setCustomer(id);
      } else if (current) {
        params.setCustomer(current);
      }
    } else {
      params.setCustomer(query.customerId);
    }

    if (query.includeInactive === true) {
      params.includeInactive(true);
    }

    return this.repository.list(params);
  }
}
