import {PMPRepository} from "@application/bundles/pmp";
import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {PMP} from "@domain/pmp";
import {ListPMPQuery} from "../../list-pmp.query";
import {ListPMPParamsImpl} from "@application/bundles/pmp/implementations";

@HandleQuery({
  query: ListPMPQuery
})
export class ListPMPQueryHandler implements QueryHandler<ListPMPQuery, PMP[]> {

  constructor(private readonly repository: PMPRepository) {
  }

  public async handle(query: ListPMPQuery): Promise<PMP[]> {
    return this.repository.list(new ListPMPParamsImpl().deserialize(query.params));
  }
}
