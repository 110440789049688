import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {AccommodationFunding} from "@domain/accommodation-funding";

export class AccommodationFundingCreatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly funding: AccommodationFunding) {
    super();
  }
}
