import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {ERRD} from "@domain/eprd-errd";

export class ERRDDeletedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly errd: ERRD) {
    super();
  }
}
