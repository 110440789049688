/**
 * Provide a wrapper around Reflect to get object metadata.
 * This wrapper need to import 'reflect-metadata' in your project and activate Experimental Decorator.
 */
export class MetadataReflection {

  public decorate(decorators: ClassDecorator[], target: Function): Function;
  public decorate(decorators: (PropertyDecorator | MethodDecorator)[], target: Object, propertyKey: string | symbol, attributes?: PropertyDescriptor): PropertyDescriptor;
  public decorate(decorators: (PropertyDecorator | MethodDecorator)[] | ClassDecorator[], target: Object & Function, propertyKey?: string | symbol, attributes?: PropertyDescriptor): PropertyDescriptor | Function {

    if (typeof target === 'function' || (propertyKey === undefined && attributes === undefined)) {
      return (Reflect as any).decorate(decorators as ClassDecorator[], target);
    }

    return (Reflect as any).decorate(decorators as (PropertyDecorator | MethodDecorator)[], target, propertyKey, attributes);

  }

  public metadata(metadataKey: any, metadataValue: any): { (target: Function): void; (target: Object, propertyKey: string | symbol): void; } {
    return (Reflect as any).metadata(metadataKey, metadataValue);
  }

  public defineMetadata(metadataKey: any, metadataValue: any, target: Object): void;
  public defineMetadata(metadataKey: any, metadataValue: any, target: Object, propertyKey: string | symbol): void;
  public defineMetadata(metadataKey: any, metadataValue: any, target: Object, propertyKey?: string | symbol): void {
    if (propertyKey !== undefined) {
      return (Reflect as any).defineMetadata(metadataKey, metadataValue, target, propertyKey);
    }

    return (Reflect as any).defineMetadata(metadataKey, metadataValue, target);
  }

  public hasMetadata(metadataKey: any, target: Object): boolean;
  public hasMetadata(metadataKey: any, target: Object, propertyKey: string | symbol): boolean
  public hasMetadata(metadataKey: any, target: Object, propertyKey?: string | symbol): boolean {

    if (propertyKey !== undefined) {
      return (Reflect as any).hasMetadata(metadataKey, target, propertyKey);
    }

    return (Reflect as any).hasMetadata(metadataKey, target);
  }

  public hasOwnMetadata(metadataKey: any, target: Object): boolean;
  public hasOwnMetadata(metadataKey: any, target: Object, propertyKey: string | symbol): boolean;
  public hasOwnMetadata(metadataKey: any, target: Object, propertyKey?: string | symbol): boolean {
    if (propertyKey !== undefined) {
      return (Reflect as any).hasOwnMetadata(metadataKey, target, propertyKey);
    }

    return (Reflect as any).hasOwnMetadata(metadataKey, target);
  }

  public getMetadata(metadataKey: any, target: Object): any;
  public getMetadata(metadataKey: any, target: Object, propertyKey: string | symbol): any;
  public getMetadata(metadataKey: any, target: Object, propertyKey?: string | symbol): any {
    if (propertyKey !== undefined) {
      return (Reflect as any).getMetadata(metadataKey, target, propertyKey);
    }

    return (Reflect as any).getMetadata(metadataKey, target);
  }

  public getOwnMetadata(metadataKey: any, target: Object): any;
  public getOwnMetadata(metadataKey: any, target: Object, propertyKey: string | symbol): any;
  public getOwnMetadata(metadataKey: any, target: Object, propertyKey?: string | symbol): any {
    if (propertyKey !== undefined) {
      return (Reflect as any).getOwnMetadata(metadataKey, target, propertyKey);
    }

    return (Reflect as any).getOwnMetadata(metadataKey, target);
  }

  public getMetadataKeys(target: Object): any[];
  public getMetadataKeys(target: Object, propertyKey: string | symbol): any[];
  public getMetadataKeys(target: Object, propertyKey?: string | symbol): any[] {
    if (propertyKey !== undefined) {
      return (Reflect as any).getMetadataKeys(target, propertyKey);
    }

    return (Reflect as any).getMetadataKeys(target);
  }

  public getOwnMetadataKeys(target: Object): any[];
  public getOwnMetadataKeys(target: Object, propertyKey: string | symbol): any[];
  public getOwnMetadataKeys(target: Object, propertyKey?: string | symbol): any[] {
    if (propertyKey !== undefined) {
      return (Reflect as any).getOwnMetadataKeys(target, propertyKey);
    }

    return (Reflect as any).getOwnMetadataKeys(target);
  }

  public deleteMetadata(metadataKey: any, target: Object): boolean;
  public deleteMetadata(metadataKey: any, target: Object, propertyKey: string | symbol): boolean;
  public deleteMetadata(metadataKey: any, target: Object, propertyKey?: string | symbol): boolean {
    if (propertyKey !== undefined) {
      return (Reflect as any).deleteMetadata(metadataKey, target, propertyKey);
    }

    return (Reflect as any).deleteMetadata(metadataKey, target);
  }

}
