import { AbstractVoter } from '@application/bundles/authorization/voter';
import { Attribute, Voter } from '@application/bundles/authorization';
import { AuthentificationToken } from '@application/bundles/authentification';
import { DEMO_FEATURE } from '../tokens';
import { AVAILABLE_ROLES } from '@domain/authorization';

export class DemoVoter extends AbstractVoter implements Voter {
  protected supports(attribute: Attribute, subject: any): boolean {
    return attribute.feature === DEMO_FEATURE;
  }

  protected async voteOnAttribute(attribute: Attribute, subject: any, token: AuthentificationToken): Promise<boolean> {
    const roles = token.roles;
    return roles.contain({ name: AVAILABLE_ROLES.ADMIN });
  }
}
