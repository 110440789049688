import { createSelector } from '@ngrx/store';
import { FILE_DEPOSIT_STORE_KEY } from './file-deposit.key';
import { FileDepositStoreState } from './file-deposit.state';

interface AppState {
  [FILE_DEPOSIT_STORE_KEY]: FileDepositStoreState;
}

export const selectFileDepositState = (state: AppState) => state[FILE_DEPOSIT_STORE_KEY];

export const selectFileDepositLoading = createSelector(selectFileDepositState, state => state.loading);

export const selectFileDepositEntities = createSelector(selectFileDepositState, state => state.entities);

export const selectCurrentFileDeposit = createSelector(selectFileDepositState, state => state.current);

export const selectOnboardingEntities = createSelector(selectFileDepositState, state => state.onboarding);
