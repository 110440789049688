import {CacheKey, CacheStore, Ttl} from "@application/framework/cache";
import * as LRUCache from "lru-cache";

export class MemoryCacheStore implements CacheStore {

  private cache: LRUCache<string, any>;

  private resolvers: Map<string, Promise<any>> = new Map();

  constructor() {
    this.cache = new LRUCache({allowStale: false, max: 100, ttl: 60 * 60 * 24 * 1000});
  }

  public async has(key: CacheKey): Promise<boolean> {
    return this.cache.has(key.toString());
  }

  public get<T>(key: CacheKey): Promise<T | undefined> {
    return Promise.resolve(this.cache.get(key.toString()));
  }

  public async pick<T, V extends keyof T>(key: CacheKey, name: V): Promise<T[V] | undefined> {
    const value = this.cache.get(key.toString());

    return value === undefined ? undefined : value[name];
  }

  public set<T>(key: CacheKey, data: T, ttl?: Ttl): Promise<CacheStore> {
    this.cache.set(key.toString(), data);
    return Promise.resolve(this);
  }

  public delete(...keys: Array<CacheKey>): Promise<void> {
    keys.forEach(key => this.cache.delete(key.toString()));
    return Promise.resolve();
  }

  public reset(): Promise<void> {
    this.cache.clear();
    return Promise.resolve();
  }

  public ttl(key: string): Promise<number> {
    return Promise.resolve(this.cache.getRemainingTTL(key));
  }

  public resolve<T>(key: CacheKey, fn: () => Promise<T>, ttl: Ttl | undefined): Promise<T> {

    if (this.cache.has(key.toString())) {
      return Promise.resolve(this.cache.get<T>(key.toString()) as T);
    }

    let resolver = this.resolvers.get(key.toString());

    if (resolver === undefined) {

      resolver = fn().then(async (value) => {
        await this.cache.set(key.toString(), value);
        this.resolvers.delete(key.toString());
        return value;
      });

      this.resolvers.set(key.toString(), resolver);
    }

    return resolver;

  }

}
