import {AbstractControl, ValidationErrors, Validator} from "@angular/forms";
import {bedCareValidator} from "@application/bundles/capacity-authorization/validators";
import {computeAuthorizedCapacity} from "@domain/capacity-authorization";

/**
 * @deprecated Bed social care must be less than authorized capacity AND Temporary capacity
 */
export class BedCareValidator implements Validator {


  constructor(
    private readonly bedCareControlName: string,
    private readonly permanentCapacityControlName: string,
    private readonly temporaryCapacityControlName: string
  ) {
    this.validate = this.validate.bind(this);

    if (!this.bedCareControlName) {
      throw new Error(`bedCareControlName cannot be empty in ${this.constructor.name}`);
    }

    if (!this.permanentCapacityControlName) {
      throw new Error(`permanentCapacityControlName cannot be empty in ${this.constructor.name}`);
    }

    if (!this.temporaryCapacityControlName) {
      throw new Error(`temporaryCapacityControlName cannot be empty in ${this.constructor.name}`);
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const bedCare = control.get(this.bedCareControlName);
    const permanent = control.get(this.permanentCapacityControlName);
    const temporary = control.get(this.temporaryCapacityControlName);
    
    if (!permanent || !temporary || !bedCare) {
      return null;
    }

    const authorized = computeAuthorizedCapacity({
      permanentCapacity: permanent.value,
      temporaryCapacity: temporary.value
    });


    if (bedCare && typeof bedCare.value === "number" && !bedCareValidator.validate(bedCare.value, authorized)) {

      const error = {bedCare: {max: authorized, actual: bedCare.value}}
      bedCare.setErrors(error);
      return error;
    }

    return null;
  }

}
