import { FinancialAnnex } from '@domain/eprd-errd/financial-annex';
import { PartialActivityAnnex } from '@domain/eprd-errd';
import { Facility } from '@domain/facility';
import { EPRD } from '@domain/eprd-errd/eprd';
import { CRPP } from '@domain/eprd-errd/crpp';
import { UUID } from '@domain/lib';

export interface LocalEPRD {
  id: UUID;

  expense: number;

  revenue: number;

  financialAnnex: FinancialAnnex;

  activityAnnex: PartialActivityAnnex;

  CAF: number | undefined;

  facilityId: Facility['id'];

  parentId: EPRD['id'];

  readonly parent: EPRD;

  CRPP: CRPP[];

  facility(): Promise<Facility>;

  refreshParent(): Promise<EPRD>;
}
