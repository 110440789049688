import {createReducer, on} from "@ngrx/store";
import {EhpadDemainActions} from './ehpad-demain.actions';
import {EhpadDemainStoreState} from "@easyhpad-ui/app/bundles/ehpad-demain/store/ehpad-demain.state";
import {ehpadDemainStateAdapter} from "@easyhpad-ui/app/bundles/ehpad-demain/store/ehpad-demain.adapter";

const initialState: EhpadDemainStoreState = {
  current: undefined,
  list: ehpadDemainStateAdapter.getInitialState({
    selected: null,
    loading: false,
    loaded: false,
  })
};


export const ehpadDemainReducer = createReducer(
  initialState,
  on(EhpadDemainActions.loadEhpadDemain, state => ({
    ...state,
    list: {...state.list, loading: true, loaded: false}
  })),
  on(EhpadDemainActions.setEhpadDemain, (state, {documents}) => ({
    ...state,
    list: {...ehpadDemainStateAdapter.upsertMany(documents, state.list), loading: false}
  })),
  on(EhpadDemainActions.setCurrentEhpadDemain, (state, {id}) => ({
    ...state,
    list: {...state.list, selected: id}
  })),
  on(EhpadDemainActions.resetEhpadDemainStore, () => ({
    ...initialState
  }))
);
