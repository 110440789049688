import { LoginCommandHandler } from '@application/bundles/authentification/commands/handlers/login/login-command.handler';
import { LogoutCommandHandler } from '@application/bundles/authentification/commands/handlers/logout/logout.command.handler';
import { OnResetPasswordRequestEventSubscriber } from '@application/bundles/authentification/events/subscribers/on-reset-password-request/on-reset-password-request.event.subscriber';
import { SetAccountOnAuthentificationSubscriber } from '@application/bundles/authentification/events/subscribers/set-account-on-authentification/set-account-on-authentification.subscriber';
import { Bundle } from '@application/framework/bundle';

@Bundle({
  commandHandlers: [LoginCommandHandler, LogoutCommandHandler],
  eventSubscribers: [SetAccountOnAuthentificationSubscriber, OnResetPasswordRequestEventSubscriber],
})
export class AuthentificationBundle {}
