import { Dialog } from '@angular/cdk/dialog';
import { DeletePMPCommand } from '@application/bundles/pmp';
import { CommandBus, CommandHandler, HandleCommand, QueryBus } from '@application/framework/command-query';
import { getPMPYear, PMP } from '@domain/pmp';
import { OpenPMPDeletionDialogCommand } from '@easyhpad-ui/app/bundles/pmp/commands/open-pmp-deletion-dialog.command';
import { ConfirmDeletionDialogComponent } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.component';
import { ConfirmDeletionDialogData } from '@easyhpad-ui/app/bundles/reusable-components/components/confirm-deletion-dialog/confirm-deletion-dialog.interface';
import { DialogConfiguration } from '@easyhpad-ui/app/library/dialog';
import { GetFacilityQuery } from '@application/bundles/facility/query/get-facility.query';

@HandleCommand({
  command: OpenPMPDeletionDialogCommand,
})
export class OpenPMPDeletionDialogCommandHandler implements CommandHandler<OpenPMPDeletionDialogCommand, void> {
  constructor(
    private readonly dialog: Dialog,
    private readonly commandBus: CommandBus,
    private readonly queryBus: QueryBus,
  ) {}

  public async handle(command: OpenPMPDeletionDialogCommand): Promise<void> {
    const { pmp, onDeletion } = command;

    const facility = await this.queryBus.request(new GetFacilityQuery(pmp.facilityId));

    const data: ConfirmDeletionDialogData = {
      options: [{ key: pmp.id, label: `P.M.P. ${getPMPYear(pmp)} - ${facility.name}` }],
    };

    const subscriber = async (canBeDelete: boolean | undefined) => {
      if (canBeDelete === true) {
        const deleted = await this.commandBus.execute<PMP>(new DeletePMPCommand(pmp));

        if (typeof onDeletion === 'function') {
          onDeletion(deleted);
        }
      }
    };

    const dialog = this.dialog.open<boolean>(ConfirmDeletionDialogComponent, { ...DialogConfiguration, data });
    dialog.closed.subscribe(subscriber);
  }
}
