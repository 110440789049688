import {ReadOnlyCollection} from "@application/framework/store/collection";
import {
  DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES,
  DAYCARE_DEPENDENCIES_FUNDING_MODES,
  DaycareDependencyFundingMode,
  DaycareDependencyFundingModeValues,
  DEPENDENCIES_FUNDING_MODES
} from "@domain/dependency-funding";
import {Service} from "@application/framework/di";
import {
  commonDependencyFundingModes
} from "@application/bundles/dependency-funding/collections/common-dependency-funding-modes";

@Service()
export class DaycareDependencyFundingModeCollection implements ReadOnlyCollection<DaycareDependencyFundingMode<any>> {

  // @ts-ignore
  private readonly collection: Map<DAYCARE_DEPENDENCIES_FUNDING_MODES, string> = new Map([
    [DEPENDENCIES_FUNDING_MODES.UNKNOWN, commonDependencyFundingModes[DEPENDENCIES_FUNDING_MODES.UNKNOWN]],
    [DEPENDENCIES_FUNDING_MODES.EMPTY, commonDependencyFundingModes[DEPENDENCIES_FUNDING_MODES.EMPTY]],
    [DEPENDENCIES_FUNDING_MODES.GIR_GROUP, commonDependencyFundingModes[DEPENDENCIES_FUNDING_MODES.GIR_GROUP]],
    [DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.SINGLE_GIR_AMOUNT, "Tarif unique pour l’ensemble des groupes de G.I.R"],
    [DEPENDENCIES_FUNDING_MODES.AMOUNT, commonDependencyFundingModes[DEPENDENCIES_FUNDING_MODES.AMOUNT]],
    [DAYCARE_DEPENDENCIES_FUNDING_CUSTOM_MODES.OTHER, "Autre type de financement"],
  ]);

  public get<K extends DAYCARE_DEPENDENCIES_FUNDING_MODES>(key: K): DaycareDependencyFundingMode<K> | undefined {

    if (!DaycareDependencyFundingModeValues.has(key)) {
      return undefined;
    }

    const label = this.collection.get(key) || key;
    return {key, label};
  }

  public has(key: DAYCARE_DEPENDENCIES_FUNDING_MODES): boolean {
    return this.collection.has(key);
  }

  public list(): DaycareDependencyFundingMode<any>[] {
    return Array.from(this.collection.entries()).map(([key, label]) => ({key, label}));
  }

}
