import {
  InvalidQueryConstructorError,
  InvalidQueryHandlerFactoryError
} from "@application/framework/command-query/errors";
import {QueryHandler} from "@application/framework/command-query/handler.interface";
import {Query} from "@application/framework/command-query/query.interface";

export type QueryHandlerFactory<Q extends Query = Query, R = any> = (query: Q) => QueryHandler<Q, R>;

export type QueryConstructor<Q> = { name: string };

export abstract class QueryHandlerRegistry {

  private static readonly store: Map<string, QueryHandlerFactory> = new Map();

  /**
   * Get global Registry
   */
  public get store(): Map<string, QueryHandlerFactory> {
    return QueryHandlerRegistry.store;
  }

  /**
   * Register new handler for query
   * @param query
   * @param handlerFactory
   * @static
   */
  public static register<Q extends Query, R = any>(query: QueryConstructor<Q>, handlerFactory: QueryHandlerFactory<Q, R>) {

    if (!query || !query.name) {
      throw new InvalidQueryConstructorError();
    }

    if (typeof handlerFactory !== 'function') {
      throw new InvalidQueryHandlerFactoryError();
    }

    this.store.set(query.name, handlerFactory as QueryHandlerFactory);
  }

  /**
   * Register new handler for query
   * @param query
   * @param handlerFactory
   */
  public abstract register<Q extends Query, R = any>(query: QueryConstructor<Q>, handlerFactory: QueryHandlerFactory<Q, R>): void;

  /**
   * Get handler for query
   * @param query
   */
  public abstract get<Q extends Query, R = any>(query: Q): QueryHandler<Q, R> | undefined;

}
