import { HandleQuery, QueryHandler } from '@application/framework/command-query';
import { UpdateRate } from '@domain/funding';
import {
  DependencyFundingUpdateRateRepository,
  ListDependencyFundingUpdateRatesQuery,
} from '@application/bundles/funding';

@HandleQuery({
  query: ListDependencyFundingUpdateRatesQuery,
})
export class ListDependencyFundingUpdateRateQueryHandler
  implements QueryHandler<ListDependencyFundingUpdateRatesQuery>
{
  constructor(private readonly repository: DependencyFundingUpdateRateRepository) {}

  public async handle(query: ListDependencyFundingUpdateRatesQuery): Promise<UpdateRate[]> {
    const rates = await this.repository.find();

    return rates.sort((a, b) => {
      return a.date.getTime() < b.date.getTime() ? 1 : -1;
    });
  }
}
