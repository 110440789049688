import {LogoutCommand} from "@application/bundles/authentification/commands/logout.command";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {Authenticator, AuthentificationStrategy} from "@application/bundles/authentification/abstraction";

@HandleCommand({
  command: LogoutCommand
})
export class LogoutCommandHandler implements CommandHandler<LogoutCommand, void> {


  constructor(private readonly authenticator: Authenticator) {
  }

  public handle(command: LogoutCommand): Promise<void> {
    return this.execute(command.strategy);
  }

  public async execute<S extends AuthentificationStrategy, C = any>(authentificationStrategy: Pick<AuthentificationStrategy, 'logout'>, credentials?: C): Promise<any> {
    return this.authenticator.withStrategy(authentificationStrategy).disconnect(credentials ?? {})
      .then(response => response.extras);
  }
}
