import {ForbiddenError} from "@application/bundles/authorization/error";
import {DeleteGMPCommand, GMPDeletedEvent, GMPDeletionFailEvent, GMPRepository} from "@application/bundles/gmp";
import {GMPAuthorizationChecker} from "@application/bundles/gmp/gmp-authorization-checker";
import {GMP_TRANSLATE_CONTEXT} from "@application/bundles/gmp/gmp.token";
import {CommandHandler, HandleCommand} from "@application/framework/command-query";
import {EventDispatcher} from "@application/framework/event";
import {Logger, ProvideLogger} from "@application/framework/logger";
import {ErrorNormalizer} from "@application/framework/normalizers/error.normalizer";
import {TranslatableString} from "@application/framework/translation";
import {GMP} from "@domain/gmp";


@HandleCommand({
  command: DeleteGMPCommand
})
export class DeleteGMPCommandHandler implements CommandHandler<DeleteGMPCommand, GMP> {

  @ProvideLogger() private readonly logger!: Logger;

  private errorNormalizer: ErrorNormalizer = new ErrorNormalizer();

  constructor(
    private repository: GMPRepository,
    private authorization: GMPAuthorizationChecker,
    private eventDispatcher: EventDispatcher
  ) {
  }

  public async handle(command: DeleteGMPCommand): Promise<GMP> {

    const {gmp} = command;
    try {
      const deleted = await this.checkAccess(gmp)
        .then(() => this.repository.delete(gmp.id));

      this.eventDispatcher.dispatch(new GMPDeletedEvent(deleted));

      return deleted;

    } catch (e: any) {
      const error = await this.catchError(e);
      return Promise.reject(error);
    }
  }


  private async checkAccess(gmp: GMP): Promise<GMP> {

    if (!await this.authorization.canDelete(gmp)) {
      this.logger.error('G.M.P. deletion : Forbidden');
      throw new ForbiddenError();
    }

    return gmp;
  }

  private async catchError(e: any): Promise<Error> {
    const error = this.errorNormalizer.normalize(e);
    let message: string | TranslatableString = '';

    if (error instanceof ForbiddenError) {
      message = new TranslatableString(
        "Vous n'êtes pas autorisé à supprimer ce G.M.P..",
        undefined,
        GMP_TRANSLATE_CONTEXT
      );
    }

    this.eventDispatcher.dispatch(new GMPDeletionFailEvent(message));

    return error;
  }

}
