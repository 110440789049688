import {DOCUMENT_TYPES} from '@domain/document';
import {values} from '@domain/lib';

/**
 * Determine of the document type is a valid type
 * @param type
 */
export function isDocumentType(type: any): type is DOCUMENT_TYPES {
  return typeof type === 'string' && new Set(values(DOCUMENT_TYPES)).has(type as DOCUMENT_TYPES);
}
