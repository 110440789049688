import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandBus } from '@application/framework/command-query';
import { EventDispatcher } from '@application/framework/event';
import { RouteSailor } from '@easyhpad-ui/app/framework/routing';
import { AuthentificationUrlsProvider } from '@easyhpad-ui/app/bundles/authentification/providers/urls/authentification-urls.provider';
import { AuthentificationSuccessEvent } from '@application/bundles/authentification/events/authentification-success.event';
import { EMAIL_PASSWORD_AUTH_STRATEGY } from '@easyhpad-ui/app/bundles/user/user.tokens';
import { AuthentificationStrategy } from '@application/bundles/authentification/abstraction';
import { LogoutCommand } from '@application/bundles/authentification';
import { configuration } from '@easyhpad-ui/environments/configuration';

@Component({
  selector: 'ehp-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
  host: {
    class: 'authentification-page',
  },
})
export class ResetPasswordPageComponent implements OnInit, OnDestroy {
  public token: string | undefined;

  public title = 'Réinitialiser le mot de passe';

  public readonly urls = {
    forgottenPassword: '',
  };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly eventDispatcher: EventDispatcher,
    private readonly routeSailor: RouteSailor,
    private readonly urlsProvider: AuthentificationUrlsProvider,
    private readonly commandBus: CommandBus,
    @Inject(EMAIL_PASSWORD_AUTH_STRATEGY) private strategy: AuthentificationStrategy,
  ) {
    this.redirect = this.redirect.bind(this);
    this.urls.forgottenPassword = this.urlsProvider.getForgottenPassword();

    this.commandBus.execute(new LogoutCommand(this.strategy)).finally(() =>
      this.route.queryParams.subscribe(params => {
        this.token = params['token'];
      }),
    );

    this.route.data.subscribe(data => {
      if (data['title']) {
        this.title = data['title'];
      }
    });
  }

  public ngOnInit(): void {
    this.eventDispatcher.registry.set(AuthentificationSuccessEvent, this.redirect);
  }

  public ngOnDestroy(): void {
    this.eventDispatcher.registry.remove(AuthentificationSuccessEvent, this.redirect);
  }

  public redirect(): void {
    this.routeSailor.catchAndRedirect(this.route, configuration.homePath);
  }
}
