import {
  ActivityAnnexDtoFactory,
  CreateActivityAnnexDto,
  UpdateActivityAnnexDto
} from "@application/bundles/activity-annex";
import {CreateActivityAnnexDtoImpl, UpdateActivityAnnexDtoImpl} from "@implementations/bundles/activity-annex";

export class ActivityAnnexDtoFactoryImpl implements ActivityAnnexDtoFactory {

  public buildCreateActivityAnnexDtoFromUnsafeValues(values: Partial<CreateActivityAnnexDto>): CreateActivityAnnexDto {
    return new CreateActivityAnnexDtoImpl(values);
  }

  public buildUpdateActivityAnnexDtoFromUnsafeValues(values: Partial<UpdateActivityAnnexDto>): UpdateActivityAnnexDto {
    return new UpdateActivityAnnexDtoImpl(values);
  }

}
