import {CapacityAuthorization} from "@domain/capacity-authorization";
import {isValidObject} from "@application/framework/lib";

export function computeAuthorizedCapacity(authorization: Partial<CapacityAuthorization>): number {
  if (!isValidObject(authorization)) {
    return 0;
  }
  return (authorization.permanentCapacity || 0) + (authorization.temporaryCapacity || 0);
}


export function roundCapacity(capacity: number): number {
  return Math.round(capacity);
}
