import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { FACILITY_SELECTORS, selectActiveFacilitiesForAccount } from '@easyhpad-ui/app/bundles/facility/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DashboardActions } from '@easyhpad-ui/app/bundles/dashboard/store/dashboard.actions';
import { AppState } from '@easyhpad-ui/app/store';

@Injectable()
export class DashboardEffects {
  private setFacilities$ = createEffect(() =>
    this.store.select(FACILITY_SELECTORS.selectSelectedFacilitiesForAccount).pipe(
      withLatestFrom(this.store.select(selectActiveFacilitiesForAccount)),
      map(([selected, available]) => {
        const facilities = selected.length > 0 ? selected : available;
        return DashboardActions.setFacilities({ facilityIds: [...facilities.map(f => f.id)] });
      }),
    ),
  );

  constructor(private store: Store<AppState>) {}
}
