import {HandleQuery, QueryHandler} from "@application/framework/command-query";
import {Facility} from "@domain/facility";
import {FacilityRepository} from "@domain/facility/facility.repository";
import {ListCustomerFacilitiesQuery} from "@application/bundles/facility/query/list-customer-facilities.query";
import {
  AgnosticFacilitySearchParams
} from "@application/bundles/facility/implementations/agnostic-facility-search-params";

@HandleQuery({
  query: ListCustomerFacilitiesQuery
})
export class ListCustomerFacilitiesQueryHandler implements QueryHandler<ListCustomerFacilitiesQuery, Facility[]> {

  constructor(private readonly repository: FacilityRepository) {
  }

  public handle(query: ListCustomerFacilitiesQuery): Promise<Facility[]> {
    const params = new AgnosticFacilitySearchParams().setCustomer(query.customerId);
    return this.repository.list(params);
  }


}
