import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { NativeChangeObserver } from '@easyhpad-ui/app/framework/native-change-observer';

type SelectorElement = HTMLElement;

@Directive({
  selector: '[ehp-form-control]',
  host: {
    class: 'form-control',
  },
})
export class FormControlDirective implements OnInit {
  public readonly stateChanges: Subject<void> = new Subject<void>();

  /**
   * Native Html node tag name
   * @private
   */
  private readonly elementNodeName: string;
  /**
   * Native element attributes binding
   * @private
   */
  private readonly attributes: Map<string, any> = new Map();
  private control: AbstractControl | undefined;

  @Input()
  public get value() {
    return 'value' in this.elementRef.nativeElement ? (this.elementRef.nativeElement as any).value : undefined;
  }

  public set value(value: any) {
    if (value !== this.value && 'value' in this.elementRef.nativeElement) {
      (this.elementRef.nativeElement as any).value = value;
      this.stateChanges.next();
    }
  }

  @Input()
  public get required(): boolean {
    return this.attributes.get('required') ?? this.control?.hasValidator(Validators.required) ?? false;
  }

  public set required(value: BooleanInput) {
    this.attributes.set('required', coerceBooleanProperty(value));
  }

  @Input()
  public get readonly(): boolean {
    return this.attributes.get('readonly');
  }

  public set readonly(value: BooleanInput) {
    this.attributes.set('readonly', coerceBooleanProperty(value));
  }

  @Input()
  public get disabled(): boolean {
    return this.control?.disabled ?? false;
  }

  public set disabled(disabled: boolean | string | undefined) {
    disabled = coerceBooleanProperty(disabled);

    if (this.control) {
      if (disabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  public get pending(): boolean {
    return this.control?.pending ?? false;
  }

  public get type(): string {
    if (this.isInput()) {
      return 'input';
    } else if (this.isSelect()) {
      return 'select';
    } else if (this.isTextarea()) {
      return 'textarea';
    }
    return '';
  }

  public get async(): boolean {
    return !!(this.control && this.control.asyncValidator);
  }

  public get dirty(): boolean | null {
    return this.control?.dirty ?? null;
  }

  public get touched(): boolean | null {
    return this.control?.touched ?? null;
  }

  public get invalid(): boolean | null {
    return this.control?.invalid ?? null;
  }

  public get errors(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly elementRef: ElementRef<SelectorElement>,
    private readonly nativeObserver: NativeChangeObserver,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    this.elementNodeName = this.elementRef.nativeElement.nodeName.toLowerCase();
    this.nativeObserver.observe(elementRef, 'required').subscribe(change => (this.required = change.currentValue));
  }

  public ngOnInit() {
    if (this.ngControl && this.ngControl.control) {
      this.attachControl(this.ngControl.control);
    }
  }

  public attachControl(control: AbstractControl): void {
    this.control = control;
    this.changeDetector.detectChanges();
  }

  private isSelect(): boolean {
    return this.elementNodeName === 'select' || this.elementNodeName === 'ehp-async-select';
  }

  private isInput(): boolean {
    return this.elementNodeName === 'input' || this.elementNodeName === 'ehp-toggle';
  }

  private isTextarea(): boolean {
    return this.elementNodeName === 'textarea';
  }
}
