<div class="launcher button secondary">
  <div (click)="open()" class="label-container">
    <i aria-hidden="true" class="icon icon-customer ehp-icon"></i>

    <span class="label">
      <ng-container *ngIf="current; else emptySelection">
       {{ current.name }}
      </ng-container>

      <ng-template #emptySelection>
          {{ 'Clients' | ehp_translate }}
      </ng-template>
    </span>
  </div>

  <div *ngIf="url" class="link-container">
    <a [routerLink]="url" class="button is-icon icon-arrow small single-link">
      <span class="label">{{"Accéder à la page de l'établissement" | ehp_translate}}</span>
    </a>
  </div>

</div>

<div #selector>
</div>

<p-overlayPanel #op [showCloseIcon]="false" appendTo="selector" styleClass="current-customer-selector-panel">
  <ng-template pTemplate="">
    <p-listbox
      (onChange)="updateSelection($event)"
      [(ngModel)]="current"
      [checkbox]="true"
      [filter]="true"
      [listStyle]="{ 'max-height': '250px' }"
      [multiple]="false"
      [options]="customers"
      optionLabel="name"
    ></p-listbox>

    <button (click)="validateSelection()" class="secondary validate" type="button">
      {{'Valider la selection' | ehp_translate}}
    </button>

  </ng-template>
</p-overlayPanel>
