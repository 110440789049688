import {DispatchableEvent} from "@application/framework/event";
import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {PMP} from "@domain/pmp";

export class PMPCreatedEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly pmp: PMP) {
    super();
  }
}
