import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FileDeposit, FileDepositItem, FileDepositItemsValidationResult } from '@domain/file-deposit';
import { QueryBus } from '@application/framework/command-query';
import {
  ExpectedMediaDefinition,
  ExpectedMediaList,
  ExpectedMediaListItem,
} from '@application/bundles/file-deposit/contracts';
import {
  GetExpectedMediaListQuery,
  GetFileDepositQuery,
  ValidateFileDepositItemsQuery,
} from '@application/bundles/file-deposit';
import { isValidYear, Year } from '@domain/lib';
import { DOCUMENT_TYPES } from '@domain/document';

type ValidateItem = {
  isValid: boolean;
  years: Set<Year>;
  items: Array<{ subType: string; year: Year }>;
};

@Component({
  selector: 'ehp-file-deposit-expected-media-list',
  templateUrl: './file-deposit-expected-media-list.component.html',
  styleUrls: ['./file-deposit-expected-media-list.component.scss'],
})
export class FileDepositExpectedMediaListComponent implements OnChanges {
  @Input() public fileDepositId!: FileDeposit['id'];

  @Input() public items: FileDepositItem[] | undefined;

  public expected: Array<{ type: DOCUMENT_TYPES; expected: ExpectedMediaListItem[] }> = [];

  public openPanelsIndex: number | Array<number> = [0];

  private fileDeposit: FileDeposit | undefined;

  private expectedMediaList: ExpectedMediaList | undefined;

  private validationList: FileDepositItemsValidationResult | undefined;

  private counts: Map<DOCUMENT_TYPES, { current: number; min?: number; max?: number }> = new Map();

  private validateTree: Map<DOCUMENT_TYPES, ValidateItem> = new Map();

  constructor(private readonly queryBus: QueryBus) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileDepositId']) {
      this.reloadExpectedMediaList();
    }

    if (changes['items']) {
      this.validateItemsAndUpdateList();
    }
  }

  /**
   * Get the count of items for a type.
   * @param type
   */
  public count(type: DOCUMENT_TYPES): { current: number; min?: number; max?: number } | undefined {
    return this.counts.get(type);
  }

  public isValidateMedia(type: DOCUMENT_TYPES, media: ExpectedMediaDefinition, year?: Year) {
    const item = this.validateTree.get(type);

    if (!item || item.items.length === 0) {
      return false;
    }

    if (!isValidYear(year)) {
      return item.items.findIndex(validate => validate.subType === media.type) !== -1;
    }

    return item.items.findIndex(validate => validate.subType === media.type && validate.year === year) !== -1;
  }

  private async reloadExpectedMediaList() {
    this.fileDeposit = undefined;
    this.expectedMediaList = undefined;

    if (!this.fileDepositId) {
      return;
    }

    this.fileDeposit = await this.queryBus.request<FileDeposit | undefined>(
      new GetFileDepositQuery(this.fileDepositId),
    );

    this.expectedMediaList = await this.queryBus.request<ExpectedMediaList>(
      new GetExpectedMediaListQuery(this.fileDepositId),
    );

    this.buildDisplayedExpectedArray();
  }

  private async validateItemsAndUpdateList() {
    this.counts = new Map();

    if (!Array.isArray(this.items)) {
      this.validationList = undefined;
      return;
    }

    if (!this.fileDepositId) {
      return;
    }

    this.items.forEach(item => {
      let count = this.counts.get(item.type);

      if (count === undefined) {
        count = { current: 0 };
      }
      count.current += 1;

      this.counts.set(item.type, count);
    });

    this.validationList = await this.queryBus.request<FileDepositItemsValidationResult>(
      new ValidateFileDepositItemsQuery(this.fileDepositId, this.items),
    );

    this.buildDisplayedExpectedArray();
  }

  private buildDisplayedExpectedArray() {
    this.expected = [];

    if (!this.fileDeposit) {
      return;
    }

    this.fileDeposit.list.items.forEach(item => {
      if (!this.expectedMediaList) {
        return;
      }

      const expected = this.expectedMediaList[item.type];

      if (!expected) {
        return;
      }

      this.expected.push({ type: item.type, expected });

      let min = 0;
      let max = 0;

      expected.forEach(expectedItem => {
        expectedItem.medias.forEach(media => {
          ++max;
          if (media.required) {
            ++min;
          }
        });
      });

      let count = this.counts.get(item.type);

      if (!count) {
        count = { current: 0 };
      }

      count.min = min;
      count.max = max;

      this.counts.set(item.type, count);
    });
    this.buildValidateTree();
  }

  private buildValidateTree(): void {
    if (!this.expected || this.expected.length === 0 || !this.validationList) {
      return;
    }

    this.validateTree = new Map();

    this.expected.forEach(item => {
      const branch: ValidateItem = {
        isValid: false,
        years: new Set(),
        items: [],
      };

      this.validateTree.set(item.type, branch);

      if (this.validationList) {
        const documents = this.validationList.documents.filter(document => document.type === item.type);

        if (documents.length === 0) {
          return;
        }

        branch.years = new Set(documents.map(document => document.year));

        documents.forEach(document => {
          document.items.forEach(item => branch.items.push({ subType: item.subType as string, year: document.year }));
        });

        item.expected.forEach(expected => {
          //  expected.medias.map(def => ({year: expected.year, subtype: def.type}))
        });
      }
    });
  }
}
