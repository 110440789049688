import { createSelector } from '@ngrx/store';
import { AppState } from '@easyhpad-ui/app/store';
import { GLOBAL_STORE_KEY } from './global.keys';
import { GlobalStoreState } from '@easyhpad-ui/app/store/global.state';
import {
  selectActiveFacilitiesForAccount,
  selectAllFacilitiesForAccount,
} from '@easyhpad-ui/app/bundles/facility/store';
import { Facility, FACILITY_STATE } from '@domain/facility';

export const selectGlobalState = (state: AppState) => state[GLOBAL_STORE_KEY];

export const selectInitProcessValue = createSelector(
  selectGlobalState,
  (state: GlobalStoreState) => state.isInitProcess,
);

export const selectIsMonoFacility = createSelector(
  selectActiveFacilitiesForAccount,
  (state: Array<Facility>) => !Array.isArray(state) || state.length <= 1,
);

export const selectIsMultiFacilities = createSelector(selectIsMonoFacility, state => !state);

/**
 * Check if all account facilities have the onboarding state.
 */
export const selectIsFacilityOnboarding = createSelector(selectAllFacilitiesForAccount, state => {
  return state.length > 0 && state.every(facility => facility.state === FACILITY_STATE.ONBOARDING);
});

export const selectFacilityOnboardingState = createSelector(selectGlobalState, state => state.facilityOnboarding);

export const selectFacilityOnboardingValidated = createSelector(
  selectFacilityOnboardingState,
  state => state.validated,
);

export const selectIsFacilityOnboardingValidated = (id: Facility['id']) =>
  createSelector(selectFacilityOnboardingValidated, state => state.findIndex(validated => validated === id) !== -1);
