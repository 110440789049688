import { MediaCollection } from '@domain/media/media-collection';
import { LocalMedia, Media, TemporaryMedia } from '@domain/media/media.interface';

export abstract class MediaBucket {
  public abstract get(id: Media['id']): Promise<Media>;

  public abstract add(media: LocalMedia): Promise<TemporaryMedia>;

  public abstract store(medias: LocalMedia[]): Promise<MediaCollection<TemporaryMedia>>;

  public abstract delete(id: Media['id']): Promise<Media>;
}
