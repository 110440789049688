import {QueryConstructor, QueryHandlerRegistry} from "@application/framework/command-query";
import {ContainerRegistry, DependenciesInjectionConfiguration} from "@application/framework/di";


export function HandleQuery(provide: { query: QueryConstructor<unknown> }): Function;
export function HandleQuery(provide: { query: QueryConstructor<unknown>[] }): Function;
export function HandleQuery(provide: { query: QueryConstructor<unknown> | QueryConstructor<unknown>[] }): ClassDecorator {


  return target => {

    if (!Array.isArray(provide.query)) {
      provide.query = [provide.query];
    }

    provide.query.forEach(query => {
      QueryHandlerRegistry.register(query, () => ContainerRegistry.defaultContainer.get(target) as any);
    });


    DependenciesInjectionConfiguration.serviceDecorator(target, {
      id: target,
      useClass: target as any
    });
  };

}
