import {Year} from "@domain/lib";
import {TariffOptionAmountCollection} from "@domain/cpom/tariff-option-amount-collection";

export enum TARIFF_OPTIONS {
  PARTIAL_WI_PUI = "partial_wi_PUI",
  PARTIAL_WO_PUI = "partial_wo_PUI",
  GLOBAL_WO_PUI = "global_wo_PUI",
  GLOBAL_WI_PUI = "global_wi_PUI",
}

export const TariffOptionTypes: Set<TARIFF_OPTIONS> = new Set(Object.values(TARIFF_OPTIONS));


export interface TariffOptionAmount {
  year: Year;

  mainland: number;

  overseas: number;
  
  value(postalCode: string): { value: number, type: 'mainland' | 'overseas' }
}

export interface TariffOption {
  type: TARIFF_OPTIONS;
  label: string
  readonly amounts: TariffOptionAmountCollection;
}
