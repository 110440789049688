import { AutoAuthentificationFailEvent } from '@application/bundles/user/events/auto-authentification-fail.event';
import { AutoAuthentificationSuccessEvent } from '@application/bundles/user/events/auto-authentification-success.event';
import { UserAuthorizationTokenFactory } from '@application/bundles/user/factories/authorization-token/authorization-token.factory';
import { EventDispatcher } from '@application/framework/event';
import { Logger } from '@application/framework/logger';
import { ErrorNormalizer } from '@application/framework/normalizers/error.normalizer';
import {
  AuthentificationStrategy,
  StrategyResult,
} from '@application/bundles/authentification/abstraction/authentication-strategy';
import { User } from '@domain/user';
import { EasyHpadUseCasesLibrary } from '@implementations/bundles/backend/easyhpad/use-cases/use-cases.library';
import { AuthentificationToken } from '@application/bundles/authentification';

export class EasyBackendReconnectStrategy implements Omit<AuthentificationStrategy, 'logout' | 'reset'> {
  private logger: Logger;

  public constructor(
    private useCaseLibrary: EasyHpadUseCasesLibrary,
    private tokenFactory: UserAuthorizationTokenFactory,
    private eventDispatcher: EventDispatcher,
    logger: Logger,
  ) {
    this.logger = logger.channel(this.constructor.name);
  }

  /**
   * @inheritDoc
   */
  public async login(): Promise<StrategyResult<User | undefined>> {
    try {
      const useCase = this.useCaseLibrary.getAutoReconnectUseCase();
      const user = await useCase.execute();

      this.eventDispatcher.dispatch(new AutoAuthentificationSuccessEvent(user));

      return { success: true, result: user };
    } catch (e) {
      this.catchLoginError(e);
    }

    return { success: false, result: undefined };
  }

  /**
   * @inheritDoc
   * @param result
   */
  public async buildToken(result: StrategyResult<User>): Promise<AuthentificationToken> {
    return this.tokenFactory.createAuthorizationTokenForUser(result.result);
  }

  private catchLoginError(e: any): void {
    const normalized = new ErrorNormalizer().normalize(e);
    this.logger.error(e);
    this.eventDispatcher.dispatch(new AutoAuthentificationFailEvent(normalized.message, normalized.stack, normalized));
  }
}
