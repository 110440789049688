<h3 class="title">{{ 'Répartition des usagers par groupes de G.I.R' | ehp_translate }}&nbsp;:</h3>

<div *ngIf="form && GIRValues" [formGroup]="form" class="fields-container">

  <ehp-form-field *ngFor="let gir of GIRValues; let index = index">
    <label [innerHTML]="label(gir)" for="gir-{{index}}"></label>
    <input [formControlName]="gir" ehp-form-control id="gir-{{index}}" min="0" step="1" type="number">
  </ehp-form-field>

</div>

<div class="results">
  <div class="result residents">
    <span class="label">{{ 'Résidents' | ehp_translate }}</span>
    <span class="value">{{ residents }}</span>
  </div>

  <div class="result gmp">
    <span class="label">{{ 'G.M.P.' | ehp_translate }}</span>
    <span class="value">{{ value }}</span>
  </div>
</div>
