import {UpdateCurrentUser} from "@domain/user";
import {IsNotEmpty, IsOptional, IsString} from "class-validator";

export class UpdateCurrentUserDtoImpl implements UpdateCurrentUser {

  @IsString()
  @IsNotEmpty()
  public firstname: string = '';

  @IsString()
  @IsNotEmpty()
  public lastname: string = '';


  @IsString()
  @IsOptional()
  public password?: string;


  constructor(values?: Partial<UpdateCurrentUser>) {

    if (values) {

      if (values.firstname) {
        this.firstname = values.firstname;
      }
      
      if (values.lastname) {
        this.lastname = values.lastname;
      }

      if (values.password) {
        this.password = values.password;
      }

    }

  }
}
