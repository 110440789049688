import {GroupedGIRAmount} from "@domain/gir";

export enum DEPENDENCIES_FUNDING_MODES {
  UNKNOWN = "unknown",
  EMPTY = "no_funding",
  GIR_GROUP = "gir_group",
  AMOUNT = "package",
}


export type DependencyFundingItem<M extends DependencyFundingMode<T>, V, T extends string = string> = {

  mode: M;

  value(): V;
}

export type DependencyFundingMode<M extends string> = {

  key: M;

  label: string;
}


export interface dependencyUnknownFunding extends DependencyFundingItem<DependencyFundingMode<DEPENDENCIES_FUNDING_MODES.UNKNOWN>, undefined> {
}

export interface dependencyEmptyFunding extends DependencyFundingItem<DependencyFundingMode<DEPENDENCIES_FUNDING_MODES.EMPTY>, undefined> {
}

export interface dependencyGIRGroupFunding extends DependencyFundingItem<DependencyFundingMode<DEPENDENCIES_FUNDING_MODES.GIR_GROUP>, GroupedGIRAmount> {
}

export interface dependencyAmountFunding extends DependencyFundingItem<DependencyFundingMode<DEPENDENCIES_FUNDING_MODES.AMOUNT>, number> {
}

