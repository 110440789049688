import { Transformer } from '@application/framework/core';
import { FinancialAnnex } from '@domain/eprd-errd';
import { EasyFinancialDetail } from '@implementations/bundles/backend/easyhpad/interfaces/eprd-errd/easy-financial-detail';
import { FinancialAnnexImpl } from '@implementations/bundles/eprd-errd/implementations';
import { Service } from '@application/framework/di';
import { isValidFundingAmount } from '@domain/funding';

@Service()
export class EasyFinancialAnnexTransformer implements Transformer<FinancialAnnex, EasyFinancialDetail> {
  public transform(value: FinancialAnnex): EasyFinancialDetail {
    const detail: EasyFinancialDetail = {
      accommodationRevenue: value.permanentRevenue || 0,
      accommodationCharge: value.permanentExpense || 0,
      dependencyRevenue: value.dependencyRevenue || 0,
      dependencyCharge: value.dependencyExpense || 0,
      healthRevenue: value.healthRevenue || 0,
      healthCharge: value.healthExpense || 0,
    };

    if (isValidFundingAmount(value.accommodationEmployeesExpense)) {
      detail.accommodationEmployeeCharge = value.accommodationEmployeesExpense;
    }

    if (isValidFundingAmount(value.dependencyEmployeesExpense)) {
      detail.dependencyEmployeeCharge = value.dependencyEmployeesExpense;
    }

    if (isValidFundingAmount(value.healthEmployeesExpense)) {
      detail.healthEmployeeCharge = value.healthEmployeesExpense;
    }

    return detail;
  }

  public reverseTransform(value: EasyFinancialDetail): FinancialAnnex {
    return new FinancialAnnexImpl({
      permanentRevenue: value.accommodationRevenue || 0,
      permanentExpense: value.accommodationCharge || 0,
      dependencyRevenue: value.dependencyRevenue || 0,
      dependencyExpense: value.dependencyCharge || 0,
      healthRevenue: value.healthRevenue || 0,
      healthExpense: value.healthCharge || 0,
      accommodationEmployeesExpense: value.accommodationEmployeeCharge || 0,
      healthEmployeesExpense: value.healthEmployeeCharge || 0,
      dependencyEmployeesExpense: value.dependencyEmployeeCharge || 0,
    });
  }
}
