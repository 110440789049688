<header class="header document-list-header">
  <div class="title">{{'Nom du document' | ehp_translate}}</div>
  <div class="date">{{'Date de validation' | ehp_translate}}</div>
  <div class="amount">{{'Valeur du G.M.P.' | ehp_translate}}</div>
  <div class="facility">{{'Établissement' | ehp_translate}}</div>
  <div class="actions">{{'Actions' | ehp_translate}}</div>
</header>


<ng-container *ngIf="list">

  <ng-container *ngIf="list.loading| async; else listDisplay">
    <ehp-skeleton [type]="SKELETON_TYPE"></ehp-skeleton>
  </ng-container>

</ng-container>


<ng-template #listDisplay>

  <ng-container *ngIf="list.count > 0 ; else emptyList">
    <ng-container *ngIf="list.documents | async as documents; else emptyList">

      <ng-container *ngFor="let gmp of documents">
        <ehp-gmp-list-item *ngIf="gmp" [gmp]="gmp"></ehp-gmp-list-item>
      </ng-container>

    </ng-container>
  </ng-container>


</ng-template>

<ng-template #emptyList>
  <ehp-empty-block>{{'Aucun G.M.P. archivé pour le moment.' | ehp_translate}}</ehp-empty-block>
</ng-template>
