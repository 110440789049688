import {AddInspectionReportDto} from "@application/bundles/capacity-authorization/dtos";
import {UpdateInspectionReportDto} from "@application/bundles/capacity-authorization/dtos/update-inspection-report.dto";

export abstract class InspectionReportDtoFactory {

  public abstract createAddInspectionReportDtoFromUnSafeValues(values: Partial<AddInspectionReportDto>): AddInspectionReportDto;

  public abstract createUpdateInspectionReportDtoFromUnSafeValues(values: Partial<UpdateInspectionReportDto>): UpdateInspectionReportDto;

}
