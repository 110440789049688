import { Repository } from '@application/framework/repository';
import { EasyRepositoryAbstract } from './easy.repository.abstract';
import { EasyRepositoryTransformer } from './easy-repository-transform';

export abstract class EasyEntityRepositoryV2<
    Entity = any,
    EasyEntity = Entity,
    ID = string | number,
    P = any,
    C = Entity,
    U = Entity,
  >
  extends EasyRepositoryAbstract<Entity, EasyEntity, P>
  implements Repository<Entity, ID, P, C, U>
{
  constructor(basePath: string, transformer?: EasyRepositoryTransformer<Entity, EasyEntity, C, U>) {
    super(basePath, transformer);
  }

  public async list(params?: P): Promise<Entity[]> {
    const easyEntities = await this.backend
      .get<{ entities: EasyEntity[] }>(this.basePath, this.convertParams(params))
      .then(response => this.parseResponse(response))
      .then(body => body.entities);

    const entities: Entity[] = [];

    for (const entity of easyEntities) {
      entities.push(await this.toEntity(entity));
    }

    return entities;
  }

  public get(id: ID): Promise<Entity> {
    return this.backend
      .get<{ entity: EasyEntity }>(`${this.basePath}/${id}`)
      .then(response => this.parseResponse(response))
      .then(body => this.toEntity(body.entity));
  }

  public async create(create: C): Promise<Entity> {
    const data = typeof this.transformer?.create === 'function' ? await this.transformer.create(create) : create;

    return this.backend
      .post<{ entity: EasyEntity }, C>(this.basePath, data)
      .then(response => this.parseResponse(response))
      .then(body => this.toEntity(body.entity));
  }

  public async update(id: ID, update: U): Promise<Entity> {
    const data = typeof this.transformer?.update === 'function' ? await this.transformer.update(update) : update;

    return this.backend
      .patch<{ entity: EasyEntity }, U>(`${this.basePath}/${id}`, data)
      .then(response => this.parseResponse(response))
      .then(body => this.toEntity(body.entity));
  }

  public async delete(id: ID): Promise<Entity> {
    const entity = await this.get(id);

    await this.backend.delete(`${this.basePath}/${id}`).then(response => this.parseResponse(response));

    return entity;
  }

  protected async fromEntity(entity: Entity): Promise<EasyEntity> {
    if (typeof this.transformer?.from === 'function') {
      return this.transformer.from(entity);
    }

    return entity as unknown as EasyEntity;
  }

  protected async toEntity(easyEntity: EasyEntity): Promise<Entity> {
    if (typeof this.transformer?.to === 'function') {
      return this.transformer.to(easyEntity);
    }

    return easyEntity as unknown as Entity;
  }
}
