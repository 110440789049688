import { DocumentRepository } from '@implementations/bundles/backend/easyhpad/repositories/base';
import { Provide } from '@application/framework/di';
import {
  AccommodationFundingRepository,
  AccommodationFundingSearchParams,
  SerializedAccommodationFundingSearchParams,
} from '@application/bundles/accommodation-funding';
import { EasyAccommodationFunding } from '@implementations/bundles/backend/easyhpad';
import { AccommodationFunding } from '@domain/accommodation-funding';
import { EasyAccommodationFundingTransformer } from '@implementations/bundles/backend/easyhpad/transformers/accommodation-funding/easy-accommodation-funding.transformer';

export class EasyAccommodationFundingRepository
  extends DocumentRepository<
    AccommodationFundingSearchParams,
    SerializedAccommodationFundingSearchParams,
    AccommodationFunding,
    EasyAccommodationFunding
  >
  implements AccommodationFundingRepository
{
  @Provide() private readonly entityTransformer!: EasyAccommodationFundingTransformer;

  constructor() {
    super('funding/accommodation/entities', {
      from: input => this.entityTransformer.transform(input),
      to: output => this.entityTransformer.reverseTransform(output),
      create: input => this.entityTransformer.transformCreate(input),
      update: input => this.entityTransformer.transformUpdate(input),
    });
  }
}
