import {AbstractDispatchableEvent} from "@application/framework/event/abstract-event";
import {User} from "@domain/user";
import {DispatchableEvent} from '../../../framework/event';

export class UserAuthentificationSuccessEvent extends AbstractDispatchableEvent implements DispatchableEvent {

  constructor(public readonly user: User) {
    super();
  }
}
