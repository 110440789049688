import { UpdateInspectionReportDto } from '@application/bundles/capacity-authorization/dtos/update-inspection-report.dto';
import { LocalMedia, Media } from '@domain/media';
import { Type } from 'class-transformer';
import { IsArray, IsDate, IsUUID } from 'class-validator';
import { UUID } from '@domain/lib';

export class UpdateInspectionReportDtoImpl implements UpdateInspectionReportDto {
  @IsUUID()
  public id!: UUID;

  @IsDate()
  @Type(() => Date)
  public date!: Date;

  @IsArray()
  public mediaIds!: Array<Media['id']>;

  @IsArray()
  public mediaToAdd: Array<LocalMedia> = [];

  @IsArray()
  public mediaToRemove: Array<Media['id']> = [];
}
