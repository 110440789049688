import { Component, HostBinding, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'ehp-file-deposit-transition-request-dialog',
  templateUrl: './file-deposit-transition-request-dialog.component.html',
  styleUrls: ['./file-deposit-transition-request-dialog.component.scss'],
})
export class FileDepositTransitionRequestDialogComponent {
  @HostBinding('class.dialog') public has = true;

  public comment: string | undefined;

  public get type() {
    return this.data && this.data.type ? this.data.type : '';
  }

  public constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: { type?: string },
  ) {}

  public confirm() {
    this.dialogRef.close({ submit: true, comment: this.comment ?? undefined });
  }

  public cancel() {
    this.dialogRef.close({ submit: false });
  }
}
