import {ModuleWithProviders, NgModule} from '@angular/core';
import {Constructable} from "@application/framework/lib";
import {Logger} from "@application/framework/logger";
import {
  AgnosticObjectValidator
} from "@application/framework/validator/implementations/agnostic-object-validator/agnostic-object-validator";
import {
  AgnosticValidatorRegistry
} from "@application/framework/validator/implementations/agnostic-registry/agnostic-registry";
import {ObjectValidator} from "@application/framework/validator/object-validator";
import {ObjectValidatorRegistry, ValidateFunction} from "@application/framework/validator/object-validator.registry";


@NgModule()
export class ValidatorModule {

  private static created = false;

  constructor() {
  }

  public static forRoot(): ModuleWithProviders<ValidatorModule> {

    if (ValidatorModule.created) {
      throw new Error('ValidatorModule.forRoot() used more than once');
    }

    return {
      ngModule: ValidatorModule,
      providers: [
        {
          provide: ObjectValidatorRegistry,
          useValue: new AgnosticValidatorRegistry()
        },
        {
          provide: ObjectValidator,
          useFactory: (registry: ObjectValidatorRegistry, logger: Logger) => {
            return new AgnosticObjectValidator(registry, logger, false);
          },
          deps: [ObjectValidatorRegistry, Logger]
        },
      ]
    };
  }

  public static forChild(config: { validators?: Array<{ objectType: Constructable<any>, validator: ValidateFunction<any> }> }): ModuleWithProviders<ValidatorModule> {

    AgnosticValidatorRegistry.fromProviders(config.validators);
    return {
      ngModule: ValidatorModule,
    };
  }

}
