import {CreateCustomerDto} from "@application/bundles/customer/dto/create-customer.dto";
import {UpdateCustomerDto} from "@application/bundles/customer/dto/update-customer.dto";

export abstract class CustomerDtoFactory {

  public abstract newCreateCustomerDtoFromUnsafeValues(values: Partial<Record<keyof CreateCustomerDto, any>>): CreateCustomerDto;

  public abstract newUpdateCustomerDtoFromUnsafeValues(values: Partial<Record<keyof UpdateCustomerDto, any>>): UpdateCustomerDto;

}
