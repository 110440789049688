import { Observable } from 'rxjs';
import {
  DocumentViewMediaReference,
  DocumentViewProcessorProvider,
} from '@easyhpad-ui/app/bundles/documents/contracts/document-view';
import { Facility } from '@domain/facility';
import { ViewContainerRef } from '@angular/core';
import { DocumentActionButton, DocumentActionLink } from '@easyhpad-ui/app/bundles/documents/contracts/document-action';
import {
  DocumentSingleViewContainer,
  DocumentSingleViewProcessorInstance,
} from '@easyhpad-ui/app/bundles/documents/contracts/document-single-view';

export interface DocumentShowViewContainer extends DocumentSingleViewContainer {
  /**
   * zone displayed after the title
   */
  readonly afterTitleContainer: ViewContainerRef;

  /**
   * Content zone container
   */
  readonly contentContainer: ViewContainerRef;
}

export interface ShowDocumentActions {
  create?: Omit<DocumentActionLink, 'label' | 'classes'> | Omit<DocumentActionButton, 'label' | 'classes'>;

  edit?: Omit<DocumentActionLink, 'classes'> | Omit<DocumentActionButton, 'classes'>;

  delete?: Omit<DocumentActionLink, 'classes'> | Omit<DocumentActionButton, 'classes'>;
}

export interface DocumentShowViewProcessorInstance extends DocumentSingleViewProcessorInstance {
  /**
   * The list of medias with title
   */
  medias: Observable<DocumentViewMediaReference[]>;

  /**
   * The associated facility ID.
   */
  facilityId: Observable<Facility['id'] | Array<Facility['id']>>;

  /**
   * The list of action to display in DocumentListViewContainer Component
   */
  actions: Observable<ShowDocumentActions>;
}

export interface DocumentShowViewProcessor {
  /**
   * Attach subcomponents in the parent view.
   * @param parent
   */
  buildShowView: (parent: DocumentShowViewContainer) => DocumentShowViewProcessorInstance;
}

/**
 * Provide the processor to build a Document list page component for a type of document
 */
export type DocumentShowViewProvider<C extends DocumentShowViewProcessor> = DocumentViewProcessorProvider<'show', C>;
