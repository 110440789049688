import { DateParser } from '@application/framework/date';
import { Service } from '@application/framework/di';
import { EasyFundingUpdateRateAbstractRepository } from './easy-funding-update-rate.abstract.repository';
import { HealthFundingUpdateRateRepository } from '@application/bundles/funding';

@Service()
export class EasyHealthFundingUpdateRateRepository
  extends EasyFundingUpdateRateAbstractRepository
  implements HealthFundingUpdateRateRepository
{
  constructor(dateParser: DateParser) {
    super('funding/health/update-rates', dateParser);
  }
}
