import {ServiceMetadata} from "@application/framework/di/interfaces/service-metadata.interface";
import {Container} from "../container";

export class LazyContainer extends Container implements Container {

  protected container!: Container;

  public containerIsAlreadyMounted(): boolean {
    return this.container !== undefined;
  }

  public mountContainer(container: Container): void {
    
    if (this.container !== undefined) {
      throw new Error(`${this.constructor.name}.container is already mounted.`);
    }

    this.metadataMaps.forEach((metadata: ServiceMetadata) => {
      container.set(metadata);
    });

    this.container = container;
  }

  public getContainer(): Container {
    if (this.container === undefined) {
      throw new Error(`Container is not mounted in ${this.constructor.name}`);
    }

    return this.container;
  }
}
