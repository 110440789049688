import '@angular/compiler';
import {ContainerRegistry, DependenciesInjectionConfiguration} from "@application/framework/di";
import {ServiceIdentifier} from "@application/framework/di/types/service-identifier";
import {EventSubscribersRegistry} from "@application/framework/event/event-subscribers.registry";
import {getMethodRegisteredAsEventSubscriber} from "@application/framework/event/functions/on-event-method-decoration";
import {Constructable} from "@application/framework/lib";


export function HasEventSubscribers<T = unknown>(): Function;

export function HasEventSubscribers<T = unknown>(options: { id?: ServiceIdentifier<T> }): Function;

export function HasEventSubscribers<T>(options: { id?: ServiceIdentifier<T> } = {}): ClassDecorator {

  return (target) => {

    const methods = getMethodRegisteredAsEventSubscriber(target);

    if (methods instanceof Map) {

      methods.forEach((options, methodName) => {

        //@ts-ignore
        const original = target.prototype[methodName];

        if (typeof original === 'function') {
          const subscriber = function (...args: any[]) {

            // @ts-ignore
            const instance = ContainerRegistry.defaultContainer.get(target);
            return original.apply(instance, args);
          }

          options.events.forEach(event => EventSubscribersRegistry.registerSubscriber(event, subscriber, options.metadata));
        }
      });
    }

    DependenciesInjectionConfiguration.serviceDecorator(target, {
      id: options.id || target,
      useClass: target as unknown as Constructable<any>
    });

    return target;
  }


}
