import { Injectable, InjectableProvider } from '@angular/core';
import { ContainerRegistry } from '@application/framework/di';
import { ServiceDecoratorAdapter } from '@application/framework/di/interfaces/decorator-adapters.interface';
import { ServiceMetadata } from '@application/framework/di/interfaces/service-metadata.interface';
import { createInjectableProviderFromMetadata } from '@easyhpad-ui/app/framework/di/functions/create-injectable-provider-from-metadata';

export const angularInjectableServiceDecoratorAdapter: ServiceDecoratorAdapter = <T extends Function>(
  target: T,
  metadata: ServiceMetadata,
) => {
  metadata.id = target;
  ContainerRegistry.defaultContainer.set(metadata);

  const token: InjectableProvider = createInjectableProviderFromMetadata(metadata);

  return Injectable({ providedIn: 'root', ...token })(target);
};
