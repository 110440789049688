import { isValidObject } from '@application/framework/lib';
import {
  hasTemporaryFundingMode,
  TEMPORARY_DEPENDENCIES_FUNDING_MODES,
  TemporaryDependencyFunding,
} from '@domain/dependency-funding';
import { isUUID } from '@domain/lib';

export function isValidTemporaryDependencyFundingIdType<
  M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES = any,
  V = unknown,
>(id: any): id is TemporaryDependencyFunding<M, V>['id'] {
  return isUUID(id);
}

export function isMaybeTemporaryDependencyFunding<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES, V>(
  funding: any,
): funding is Omit<TemporaryDependencyFunding<M, V>, 'id'> {
  return (
    isValidObject(funding) &&
    hasTemporaryFundingMode(funding) &&
    (typeof (funding as TemporaryDependencyFunding<M, V>).activityAllowance === 'number' ||
      (funding as TemporaryDependencyFunding<M, V>).activityAllowance === undefined)
  );
}

export function isTemporaryDependencyFunding<M extends TEMPORARY_DEPENDENCIES_FUNDING_MODES, V>(
  funding: any,
): funding is TemporaryDependencyFunding<M, V> {
  return (
    isMaybeTemporaryDependencyFunding(funding) &&
    isValidTemporaryDependencyFundingIdType((funding as TemporaryDependencyFunding<M, V>).id)
  );
}
