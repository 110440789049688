import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { isValidYear, Year } from '@domain/lib';
import { Facility } from '@domain/facility';
import { AccommodationFundingListFilters } from '@easyhpad-ui/app/bundles/accommodation-funding/interfaces';

@Component({
  selector: 'ehp-accommodation-funding-list-filters',
  templateUrl: './accommodation-funding-list-filters.component.html',
  styleUrls: ['./accommodation-funding-list-filters.component.scss'],
  host: {
    class: 'filters-host filters',
  },
})
export class AccommodationFundingListFiltersComponent implements OnDestroy {
  @Output() public onChange: EventEmitter<AccommodationFundingListFilters> = new EventEmitter();

  private value: AccommodationFundingListFilters = {};

  private filterDispatcher: Subject<AccommodationFundingListFilters> = new Subject();

  private destroy$: Subject<void> = new Subject();

  constructor() {
    this.filterDispatcher.subscribe(filters => this.onChange.emit(filters));
  }

  public ngOnDestroy(): void {
    this.filterDispatcher.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setYear(year?: Year | null) {
    year = this.formatYear(year);

    if (year !== this.value.year) {
      this.value = { ...this.value, year };
      this.dispatch();
    }
  }

  public setFacilities(facilities: Facility[]): void {
    this.value = { ...this.value, facilities: facilities.map(f => f.id) };
    this.dispatch();
  }

  private formatYear(year?: Year | null): Year | undefined {
    if (year === undefined || year === null) {
      return undefined;
    }

    return isValidYear(year) ? year : undefined;
  }

  private dispatch(): void {
    this.filterDispatcher.next(this.value);
  }
}
