import {NgModule} from '@angular/core';
import {CUSTOMER_CACHE_ID, CustomerDtoFactory, CustomerRepository} from "@application/bundles/customer";
import {CustomerBundle} from "./customer.bundle";
import {CacheModule} from "@easyhpad-ui/app/framework/cache";
import {ValidatorModule} from "@easyhpad-ui/app/framework/validator";
import {EasyCustomerRepository} from "@implementations/bundles/backend/easyhpad/repositories";
import {CreateCustomerDtoImpl, UpdateCustomerDtoImpl} from "@implementations/bundles/customer/dto";
import {CustomerDtoFactoryImpl} from "@implementations/bundles/customer/factories/dto.factory";
import {
  validateCreateCustomerDtoImpl,
  validateUpdateCustomerDtoImpl
} from "@implementations/bundles/customer/validators";
import {AgnosticCache, MemoryCacheStore} from "@implementations/framework/cache";
import {CustomerUrlsProvider} from "@easyhpad-ui/app/bundles/customer/providers";
import {CurrentCustomerAccessor} from "@application/bundles/customer/implementation";
import {StoreModule} from "@ngrx/store";
import {CUSTOMER_STORE_KEY, CustomerEffects, customerReducer} from "@easyhpad-ui/app/bundles/customer/store";
import {EffectsModule} from "@ngrx/effects";
import {Bundle} from "@application/framework/bundle";
import {CustomerStoreUpdateSubscriber} from "@easyhpad-ui/app/bundles/customer/events/subscribers";
import {CustomerBundle as ApplicationCustomerBundle} from '@application//bundles/customer/customer.bundle';


@NgModule({
  imports: [
    StoreModule.forFeature(CUSTOMER_STORE_KEY, customerReducer),
    EffectsModule.forFeature([CustomerEffects]),
    ValidatorModule.forChild({
      validators: [
        {objectType: CreateCustomerDtoImpl, validator: validateCreateCustomerDtoImpl},
        {objectType: UpdateCustomerDtoImpl, validator: validateUpdateCustomerDtoImpl}
      ]
    }),
    CacheModule.forChild({
      caches: [{key: CUSTOMER_CACHE_ID, cache: new AgnosticCache(new MemoryCacheStore())}]
    }),
  ],
  providers: [
    CustomerBundle,
    CustomerUrlsProvider,
    {
      provide: CustomerRepository,
      useClass: EasyCustomerRepository,
    },
    {
      provide: CustomerDtoFactory,
      useClass: CustomerDtoFactoryImpl
    },
    {
      provide: CurrentCustomerAccessor,
      useValue: new CurrentCustomerAccessor()
    }
  ]
})
@Bundle({
  imports: [
    ApplicationCustomerBundle
  ],
  eventSubscribers: [
    CustomerStoreUpdateSubscriber
  ]
})
export class CustomerModule {

  constructor(
    private readonly bundle: CustomerBundle,
  ) {
    this.bundle.build();
  }
}
