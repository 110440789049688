import {DispatchableEvent} from "@application/framework/event";
import {AuthentificationToken} from "@application/bundles/authentification";

export class AuthentificationSuccessEvent implements DispatchableEvent {

  public get name() {
    return this.constructor.name;
  }

  constructor(public readonly token: AuthentificationToken) {
  }
}
